import { jaarMaandInMaanden, mapLocalDateToString } from "adviesbox-shared";
import {
  Kapitaalverzekering as KapitaalverzekeringDlEntry,
  KapitaalverzekeringExtraPremiestortingInJaar,
  KapitaalverzekeringKapitaalopbouw,
  SoortBerekeningOptionsKv,
  SoortKapitaalverzekeringsrekeningOptions,
  KapitaalverzekeringOverlijdensrisicodekking,
  BasisdekkingOptions,
  KapitaalverzekeringPartnerrente,
  KapitaalverzekeringPolis,
  KapitaalverzekeringPoliswaarde,
  KapitaalverzekeringPremiedepot,
  PremiedepotUitgangspunt,
  KapitaalverzekeringPremiegegevens,
  BetalingsTermijnType,
  KapitaalverzekeringProduct,
  KapitaalverzekeringFiscaleRegeling,
  SoortVermogensrekeningOptions
} from "../../.generated/forms/formstypes";
import { KapitaalverzekeringKenmerken } from "../../producten-overzicht/infra/product-kenmerken-types";
import { premiedepotModalSchema } from "../../producten-overzicht/infra/producten-overzicht-schema";
import {
  AanvangExtraPremieStortingenModalType,
  KapitaalopbouwType
} from "../../producten-overzicht/infra/producten-overzicht-types";
import { mapVerpandingUiToDl } from "../../producten-overzicht/infra/verpanding-mapper-ui-2-dl";
import { mapAanvragerKeuzeNaarKlanten } from "../../shared/generic-parts/klantnaam/map-dl-2-ui";
import { mapKlantnaamUi2Dl } from "../../shared/generic-parts/klantnaam/map-ui-2-dl";
import { KlantnaamType } from "../../shared/generic-parts/klantnaam/schema";
import { mapKredietenUi2Dl } from "../../shared/generic-parts/krediet/map-ui-2-dl";
import { mapLeningdelenUi2Dl } from "../../shared/generic-parts/leningdeel/map-ui-2-dl";
import { AanvragerKeuze } from "../../shared/types";
import { createMapToDl } from "../../shared/utils/create-map-to-dl";
import {
  fiscaleRegelingSchema,
  kapitaalverzekeringenSchema,
  kapitaalVerzekeringKapitaalOpbouwSchema,
  kapitaalverzekeringSchema,
  overlijdensrisicodekkingSchema,
  poliswaardeSchema,
  ExtendedBasisdekkingOptions
} from "./kapitaalverzekering-schema";
import { OverlijdensrisicodekkingType } from "./kapitaalverzekering-types";

export const mapPolisUiToDl = createMapToDl(kapitaalverzekeringSchema).to<KapitaalverzekeringPolis>({
  maatschappijCode: v => v.partijCode,
  productcode: v => +v.productCode,
  productnummer: v => v.product.productNummer,
  maatschappijOmschrijving: ({ product }) => product.partijNaam,
  maatschappijCodeHdn: ({ product }) => product.partijCodeSelectie,
  productnaam: ({ product }) => product.productNaam,
  ingangsdatum: ({ product }) => mapLocalDateToString(product.ingangsdatum) ?? /* istanbul ignore next */ "",
  looptijdInMaanden: ({ product }) => jaarMaandInMaanden(product.looptijd) ?? /* istanbul ignore next */ 0,
  uwBemiddeling: ({ product }) => product.uwBemiddeling
});

const getVoorbeeldKapitaalBedrag = (
  values: KapitaalopbouwType,
  context: { opslag: boolean; kenmerken: KapitaalverzekeringKenmerken | null }
): number | null => {
  if (values.soortRekening === "Spaar") return null;

  if (!context.opslag) {
    return context.kenmerken?.kapitaalopbouw.voorbeeldkapitaalBedragEnabled ? values.voorbeeldkapitaalBedrag : null;
  }

  return values.voorbeeldkapitaalBedrag;
};

const getVoorbeeldRendementPercentage = (
  values: KapitaalopbouwType,
  context: { opslag: boolean; kenmerken: KapitaalverzekeringKenmerken | null }
): number | null => {
  if (values.soortRekening === "Spaar") return null;

  if (!context.opslag) {
    return context.kenmerken?.kapitaalopbouw.voorbeeldkapitaalPercentageEnabled
      ? values.voorbeeldrendementPercentage
      : null;
  }

  return values.voorbeeldrendementPercentage;
};

export const mapKapitaalopbouwUiToDl = createMapToDl(kapitaalVerzekeringKapitaalOpbouwSchema)
  .with<{ opslag: boolean; kenmerken: KapitaalverzekeringKenmerken | null }>()
  .to<KapitaalverzekeringKapitaalopbouw>({
    soortRekening: v => v.soortRekening as SoortKapitaalverzekeringsrekeningOptions,
    soortBerekening: v => v.soortBerekening as SoortBerekeningOptionsKv,
    doelkapitaalBedrag: v => v.doelkapitaalBedrag,
    doelrendementPercentage: v => v.doelrendementPercentage,
    voorbeeldkapitaalBedrag: (v, c) => getVoorbeeldKapitaalBedrag(v, c),
    voorbeeldrendementPercentage: (v, c) => getVoorbeeldRendementPercentage(v, c),
    garantiekapitaalBedrag: v =>
      v.soortRekening !== SoortVermogensrekeningOptions.Belegging ? v.garantiekapitaalBedrag : null,
    garantierendementPercentage: v =>
      v.soortRekening !== SoortVermogensrekeningOptions.Belegging ? v.garantierendementPercentage : null
  });

function mapExtraPremiestortingJarenUiToDl(
  premiedepot: AanvangExtraPremieStortingenModalType
): KapitaalverzekeringExtraPremiestortingInJaar[] | null {
  return premiedepot.scenario.map((e, i) => ({
    jaar: i + 1,
    bedrag: e.bedrag ?? 0
  }));
}

export const mapPremiedepotUiToDl = createMapToDl(premiedepotModalSchema).to<KapitaalverzekeringPremiedepot>({
  uitgangspunt: v => v.uitgangspunt as PremiedepotUitgangspunt,
  opnameLagePremies: v => v.lagePremies,
  opnameHogePremies: v => v.hogePremies,
  opnameAanvangsstorting: v => v.aanvangsstorting,
  opnameExtraStortingen: v => v.extraStortingen,
  vergoeding: v => v.vergoeding,
  premiedepotBedrag: v => v.bedrag,
  premiedepotDuurInMaanden: v => v.duur
});

export const mapPartnerrenteUiToDl = createMapToDl(overlijdensrisicodekkingSchema).to<KapitaalverzekeringPartnerrente>({
  partnerrentePerJaarBedrag: v => v.partnerrentePerJaarBedrag ?? /* istanbul ignore next */ 0,
  levenslang: v => v.levenslang,
  maxDuurInJaren: v => (v.partnerrentePerJaarBedrag ? v.maxDuurInJaren : /* istanbul ignore next */ null),
  directIngaandeRente: v => v.directIngaandeRente,
  uitsteldatum: v => mapLocalDateToString(v.uitsteldatum)
});

export function mapBasisdekkingUiToDl(basisdekking: ExtendedBasisdekkingOptions): BasisdekkingOptions {
  if (basisdekking === "90%" || basisdekking === "110%") {
    return BasisdekkingOptions.PercentageOpgebouwdeWaarde;
  } else {
    return basisdekking;
  }
}

function mapWaardePercentageUiToDl(
  basisdekking: ExtendedBasisdekkingOptions,
  waardePercentage: number | null
): number | null {
  switch (basisdekking) {
    case "90%":
      return 90;
    case "110%":
      return 110;
    default:
      return waardePercentage;
  }
}

function mapVerzekerde1OverlijdensrisicodekkingUiToDl(
  isAanvrager: boolean,
  overlijdensrisicodekking: OverlijdensrisicodekkingType,
  partnerAanwezig: boolean
): KapitaalverzekeringOverlijdensrisicodekking | null {
  if (!isAanvrager) {
    return null;
  }

  const basisdekking = mapBasisdekkingUiToDl(overlijdensrisicodekking.basisdekking);
  const partnerrente = partnerAanwezig ? mapPartnerrenteUiToDl(overlijdensrisicodekking) : null;
  const waardePercentage = mapWaardePercentageUiToDl(
    overlijdensrisicodekking.basisdekking,
    overlijdensrisicodekking.waardePercentage
  );

  return {
    basisdekking: basisdekking,
    verzekerdKapitaalBedrag: overlijdensrisicodekking.verzekerdKapitaalBedrag,
    soortRisicodekking: overlijdensrisicodekking.soortRisicodekking,
    annuiteitsPercentage: overlijdensrisicodekking.annuiteitsPercentage,
    dekkingDaaltTotBedrag: overlijdensrisicodekking.dekkingDaaltTotBedrag,
    duurdalingInJaren: overlijdensrisicodekking.duurdalingInJaren,
    waardePercentage: waardePercentage,
    partnerrente
  };
}

export const mapPremiegegevensUiToDl = createMapToDl(kapitaalverzekeringSchema)
  .with<{ kenmerken: KapitaalverzekeringKenmerken | null; lijfrenteclausule: boolean | null }>()
  .to<KapitaalverzekeringPremiegegevens>({
    hooglaagVerhouding: ({ premieGegevens }) => premieGegevens.hoogLaagVerhouding,
    duurInMaanden: ({ premieGegevens }) => jaarMaandInMaanden(premieGegevens.looptijd),
    duurHooglaagInMaanden: ({ premieGegevens }) => jaarMaandInMaanden(premieGegevens.hoogLaagLooptijd),
    betalingstermijn: ({ premieGegevens }) => premieGegevens.betalingstermijn as BetalingsTermijnType,
    beleggingsinlegBedrag: ({ premieGegevens }, c) =>
      c.kenmerken?.premie.spaarpremieEnabled ? premieGegevens.spaarPremieLaag : null,
    hogeBeleggingsinlegBedrag: ({ premieGegevens }, c) =>
      c.kenmerken?.premie.premieHoogEnabled ? premieGegevens.spaarPremieHoog : null,
    risicopremieBedrag: ({ premieGegevens }) => premieGegevens.risicoPremieLaag,
    hogeRisicopremieBedrag: ({ premieGegevens }) => premieGegevens.risicoPremieHoog,
    totaalPremieBedrag: ({ premieGegevens }, c) => premieGegevens.totalePremieLaag,
    hogeTotaalPremieBedrag: ({ premieGegevens }, c) =>
      c.kenmerken?.premie.totalePremieEnabled ? premieGegevens.totalePremieHoog : null,
    aanvangsstortingBedrag: ({ premieGegevens }) => premieGegevens.aanvangExtraPremieStortingenBedrag,
    premiedepot: ({ premieGegevens }) =>
      premieGegevens.premiedepot ? mapPremiedepotUiToDl(premieGegevens.premiedepot) : null,
    extraPremiestortingJaren: ({ premieGegevens }) =>
      mapExtraPremiestortingJarenUiToDl(premieGegevens.aanvangExtraPremieStortingen),
    aanvangsstortingAftrekbaarBedrag: ({ premieGegevens }) => premieGegevens.aanvangExtraPremieStortingenAftrekbaar,
    premieAftrekbaarBedrag: ({ premieGegevens }, c) =>
      c.lijfrenteclausule ? premieGegevens.spaarPremieAftrekbaar || 0 : null
  });

export const mapPoliswaardeUiToDl = createMapToDl(poliswaardeSchema).to<KapitaalverzekeringPoliswaarde>({
  reedsOpgebouwdBedrag: v => v.reedsOpgebouwdBedrag,
  reedsOpgebouwdDatum: v => mapLocalDateToString(v.reedsOpgebouwdDatum),
  afkoopwaardeBedrag: v => v.afkoopwaardeBedrag,
  afkoopwaardeDatum: v => mapLocalDateToString(v.afkoopwaardeDatum),
  waardeopbouwBedrag: v => v.waardeopbouwBedrag,
  waardeopbouwJaren: v => v.poliswaardeModal.waardeopbouwJaren,
  waardeopbouwMaanden: v => v.poliswaardeModal.waardeopbouwMaanden
});

export const mapFiscaleRegelingUiToDl = createMapToDl(fiscaleRegelingSchema).to<KapitaalverzekeringFiscaleRegeling>({
  orv: v => v.orv,
  lijfrenteclausule: v => v.lijfrenteclausule,
  kapitaalopbouw: v => v.kapitaalopbouw,
  productId: v => v.productId,
  fiscaleVoortzetting: v => v.fiscaleVoortzetting,
  externeMaatschappijCode: v => v.externeMaatschappijCode,
  externeMaatschappijOmschrijving: v => v.externeMaatschappijOmschrijving,
  lijfrenteclausuleOrigineel: v => v.lijfrenteclausuleOrigineel,
  fiscaleTypering: v => v.fiscaleTypering,
  garantieverzekering: v => v.garantieverzekering,
  fiscaalRegime: v => v.fiscaalRegime,
  oorspronkelijkeIngangsdatum: v => mapLocalDateToString(v.oorspronkelijkeIngangsdatum),
  ingangsdatumBox1: v => mapLocalDateToString(v.ingangsdatumBox1),
  einddatum: v => mapLocalDateToString(v.einddatum),
  doelkapitaalBedrag: v => v.doelkapitaalBedrag,
  laagstePremieooitBedrag: v => v.laagstePremieooitBedrag,
  hoogstePremieOoitBedrag: v => v.hoogstePremieOoitBedrag,
  huidigeJaarPremieBedrag: v => v.huidigeJaarPremieBedrag,
  premieLopendJaarBedrag: v => v.premieLopendJaarBedrag,
  originelePolisnummer: v => v.originelePolisnummer,
  polisnummer: v => v.polisnummer,
  eerdereUitkeringenBedrag: v => v.eerdereUitkeringenBedrag,
  ingebrachteWaardeBedrag: v => v.ingebrachteWaardeBedrag
});

export const mapKapitaalverzekeringUiToDl = createMapToDl(kapitaalverzekeringSchema)
  .with<{
    opslag: boolean;
    aanvrager1: KlantnaamType | null;
    aanvrager2: KlantnaamType | null;
    kenmerken: KapitaalverzekeringKenmerken | null;
  }>()
  .to<KapitaalverzekeringProduct>({
    fiscaleRegeling: v => mapFiscaleRegelingUiToDl(v.fiscaleRegeling),
    kapitaalopbouw: (v, c) => mapKapitaalopbouwUiToDl({ opslag: c.opslag, kenmerken: c.kenmerken })(v.kapitaalopbouw),
    polis: v => mapPolisUiToDl(v),
    poliswaarde: v => mapPoliswaardeUiToDl(v.poliswaarde),
    premiegegevens: (v, c) =>
      mapPremiegegevensUiToDl({ kenmerken: c.kenmerken, lijfrenteclausule: v.fiscaleRegeling?.lijfrenteclausule })(v),
    premiesplitsing: v => v.verzekerden.premiesplitsing,
    productId: v => v.productId,
    soortProduct: v => v.soortProduct,
    verpanding: v => mapVerpandingUiToDl(v.verpanding),
    verzekerdeKlantIds: (v, { aanvrager1, aanvrager2 }) =>
      mapAanvragerKeuzeNaarKlanten(v.verzekerden.verzekerden, aanvrager1, aanvrager2),
    verzekeringnemerKlantIds: (v, { aanvrager1, aanvrager2 }) =>
      mapAanvragerKeuzeNaarKlanten(v.verzekeringnemers.verzekeringnemers, aanvrager1, aanvrager2),
    wijzigingenInDoorlopendeProductenOvernemen: v =>
      v.product.wijzigingenInDoorlopendProductOvernemen ?? /* istanbul ignore next */ true,
    doorlopend: v => v.product.doorlopend,
    meenemen: v => v.meenemen,
    verzekerde1Overlijdensrisicodekking: (v, { aanvrager2 }) =>
      mapVerzekerde1OverlijdensrisicodekkingUiToDl(
        v.verzekerden.verzekerden !== AanvragerKeuze.Aanvrager2,
        v.verzekerde1Overlijdensrisicodekking,
        !!aanvrager2
      ),
    verzekerde2Overlijdensrisicodekking: (v, { aanvrager1 }) =>
      mapVerzekerde1OverlijdensrisicodekkingUiToDl(
        v.verzekerden.verzekerden !== AanvragerKeuze.Aanvrager1,
        v.verzekerde2Overlijdensrisicodekking,
        !!aanvrager1
      )
  });

export const mapKapitaalverzekeringenUiToDl = createMapToDl(kapitaalverzekeringenSchema).to<KapitaalverzekeringDlEntry>(
  {
    producten: v =>
      v.producten.map(kapitaalverzekering =>
        mapKapitaalverzekeringUiToDl({
          opslag: true,
          aanvrager1: v.aanvrager1,
          aanvrager2: v.aanvrager2,
          kenmerken: null
        })(kapitaalverzekering)
      ),
    aanvrager1: v => mapKlantnaamUi2Dl(v.aanvrager1),
    aanvrager2: v => mapKlantnaamUi2Dl(v.aanvrager2),
    leningdelen: v => mapLeningdelenUi2Dl(v.leningdelen),
    kredieten: v => mapKredietenUi2Dl(v.kredieten),
    dekkingen: _ => null,
    geldverstrekkerNaam: v => v.geldverstrekkerNaam,
    ingangsdatumVoorstel: v => mapLocalDateToString(v.ingangsdatumVoorstel)
  }
);
