import { Context } from "vm";
import { GedeeltelijkeUitkeringAoOpties } from "../../.generated/forms/formstypes";
import { createISWSideEffect, initISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";
import { AoUitkeringswijze, SpecificatieAoUitkeringModalType } from "../infra/specificatie-ao-uitkering-schema";

const indexeringenProRataStaffelSideEffects = createISWSideEffect<SpecificatieAoUitkeringModalType, Context>(
  ({ draft, context }) => {
    if (!context.touchedIndexeringen) return;
    for (let i = 0; i < draft.indexeringen.length; i++) {
      const touched = context.touchedIndexeringen[i] || {};
      if (
        touched.ondergrensPercentage === true ||
        touched.bovengrensPercentage === true ||
        touched.uitkeringPercentage === true
      ) {
        touched.ondergrensPercentage = false;
        touched.bovengrensPercentage = false;
        touched.uitkeringPercentage = false;
        draft.indexeringen.sort((a, b): number => {
          if (a.ondergrensPercentage !== null && b.ondergrensPercentage !== null)
            return a.ondergrensPercentage - b.ondergrensPercentage;
          return 0;
        });
      }
    }
    const length = draft.indexeringen.length;
    for (let i = 0; i < length; i++) {
      const currIndexering = draft.indexeringen[i];
      const nextIndexering = i + 1 < length ? draft.indexeringen[i + 1] : null;
      currIndexering.error = false;
      if (
        (nextIndexering && currIndexering.bovengrensPercentage !== nextIndexering.ondergrensPercentage) ||
        draft.indexeringen[length - 1].bovengrensPercentage !== 100
      ) {
        currIndexering.error = true;
      }
    }
  }
);

const indexeringenSideEffects = createISWSideEffect<SpecificatieAoUitkeringModalType, Context>(bag => {
  if (
    bag.context.aoUitkeringswijze === AoUitkeringswijze.ProRata &&
    bag.draft.gedeeltelijkeUitkeringObv === GedeeltelijkeUitkeringAoOpties.Staffel
  ) {
    indexeringenProRataStaffelSideEffects(bag);
    return;
  }
});
export const determineSpecificatieAoUitkeringModalSideEffects = initISWSideEffect(indexeringenSideEffects);
