import React, { ReactElement } from "react";
import { Modal as ModalBootstrap } from "react-bootstrap";
import MotiveringTextInput from "./MotiveringTextInput";

type ModalProps = {
  name: string;
  title?: string;
  afwijkendeKeuze?: boolean;
};

const MotiveringTextInputModal = ({ name, title, afwijkendeKeuze }: ModalProps): ReactElement => {
  return (
    <>
      <ModalBootstrap.Header closeButton>
        <div className="d-flex w-100">
          <ModalBootstrap.Title>{title}</ModalBootstrap.Title>
        </div>
      </ModalBootstrap.Header>
      <ModalBootstrap.Body>
        <MotiveringTextInput
          name={name}
          afwijkendeKeuze={!!afwijkendeKeuze}
          title={title}
          rows={15}
          withModal={false}
        />
      </ModalBootstrap.Body>
    </>
  );
};

MotiveringTextInputModal.displayName = "Modal";

export default MotiveringTextInputModal;
