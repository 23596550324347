import { LabeledCurrencyInput, LabeledRadioInput, LabeledSelectInput } from "adviesbox-shared";
import { useFormikContext } from "formik";
import React, { ReactElement } from "react";
import {
  AoBeoordelingscriterium,
  AovVerzekerdeDekkingenOptions,
  AovVoornaamsteInkomstenbronOptions
} from "../../.generated/forms/formstypes";
import { AoUitkeringswijze } from "../../producten-overzicht/infra/specificatie-ao-uitkering-schema";
import { LabelValuePairs } from "../../shared/types";
import { enum2options } from "../../shared/utils/enum-to-options";
import { AovsType, dekkingGegevensVerzekerdeSchema } from "../infra/aov-schema";
import { mapPartijProduct } from "../infra/map-aov-dl-to-ui";
import { dekkingOptions, DekkingProps, eigenRisicoOptions } from "./dekking";

export const DekkingDazure = ({ selected, kenmerken, verzekerde }: DekkingProps): ReactElement => {
  const claimbeoordelingOptions = (): LabelValuePairs => {
    return [
      { label: "Gangbare arbeid", value: AoBeoordelingscriterium.GangbareArbeid },
      { label: "Beroepsarbeid", value: AoBeoordelingscriterium.Beroeps },
      { label: "Geen", value: AoBeoordelingscriterium.Geen },
      { label: "Passende arbeid", value: AoBeoordelingscriterium.PassendeArbeid }
    ];
  };

  const { values } = useFormikContext<AovsType>();
  const dekkingGegevens =
    verzekerde === 1
      ? values.producten[selected].dekking.dekkingGegevensVerzekerde1
      : values.producten[selected].dekking.dekkingGegevensVerzekerde2 ??
        /* istanbul ignore next*/ dekkingGegevensVerzekerdeSchema.default(); //TODO: test schrijven

  const partijProductCode = mapPartijProduct(
    values.producten[selected].partijCode,
    values.producten[selected].productCode
  );

  return (
    <>
      {kenmerken.verzekerdeDekkingTonen && (
        <LabeledRadioInput
          caption={"Dekking"}
          name={`producten[${selected}].dekking.dekkingGegevensVerzekerde${verzekerde}.dekking`}
          options={dekkingOptions(
            kenmerken,
            dekkingGegevens.voornaamsteInkomstenbron !== AovVoornaamsteInkomstenbronOptions.Zelfstandige,
            partijProductCode
          )}
        />
      )}

      <LabeledCurrencyInput
        name={`producten[${selected}].dekking.dekkingGegevensVerzekerde${verzekerde}.ao.verzekerdBedrag`}
        caption="Verzekerd maandbedrag AO"
      />

      <LabeledSelectInput
        options={eigenRisicoOptions(kenmerken)}
        readonly={true}
        caption="Eigen risico"
        name={`producten[${selected}].dekking.dekkingGegevensVerzekerde${verzekerde}.ao.eigenRisico`}
      />

      <LabeledSelectInput
        options={claimbeoordelingOptions()}
        readonly={true}
        caption="Claimbeoordeling"
        name={`producten[${selected}].dekking.dekkingGegevensVerzekerde${verzekerde}.beoordelingscriterium`}
      />

      {kenmerken.uitkeringGedeeltelijkAoTonen && (
        <LabeledSelectInput
          options={enum2options(AoUitkeringswijze)}
          readonly={true}
          caption="Uitkering gedeeltelijke AO"
          name={`producten[${selected}].dekking.dekkingGegevensVerzekerde${verzekerde}.ao.uitkeringGedeeltelijkAo`}
        />
      )}
      {dekkingGegevens.dekking === AovVerzekerdeDekkingenOptions.AoWw && (
        <LabeledCurrencyInput
          readonly={true}
          name={`producten[${selected}].dekking.dekkingGegevensVerzekerde${verzekerde}.ww.verzekerdBedrag`}
          caption="Te verzekeren maandlast WW"
        />
      )}
    </>
  );
};
