import { AdviesBoxFormik, LabeledCurrencyInput } from "adviesbox-shared";
import React, { ReactElement } from "react";
import { specificatieGevraagdeHypotheekSchema, SpecificatieHypotheekState } from "../infra/acceptatie-schema";
import Modal from "../../../../../shared/components/modal/Modal";

export type SpecificatieHypotheekProps = {
  data: SpecificatieHypotheekState;
  closeModal?: () => void;
};

export const SpecificatieHypotheekModal = ({ data, closeModal }: SpecificatieHypotheekProps): ReactElement => {
  const body = (
    <>
      <LabeledCurrencyInput name="doorlopendeHypotheken" caption="Doorlopende hypotheken" readonly />
      <LabeledCurrencyInput name="gewensteHypotheek" caption="Gewenste hypotheek" readonly />
      <LabeledCurrencyInput name="totaalGevraagd" caption="Totaal gevraagde hypotheek" readonly />
    </>
  );

  return (
    <AdviesBoxFormik<SpecificatieHypotheekState>
      initialValues={data}
      validationSchema={specificatieGevraagdeHypotheekSchema}
      closeModal={closeModal}
      render={(): ReactElement => (
        <div className="modal-with-cards">
          <Modal
            title="Specificatie totaal gevraagde hypotheek"
            body={body}
            noEditableData
            onCancelClick={closeModal}
          />
        </div>
      )}
    />
  );
};
