import { ErrorPage, PageLoading, useRequestInit, withErrorBoundary } from "adviesbox-shared";
import React, { ReactElement, useMemo } from "react";
import useAbortableFetch from "use-abortable-fetch";
import { ProductkenmerkenOutput, TaxatievormBasisOutput } from "../.generated/producten/productentypes";
import { InvalidAdviesboxResultErrorPage } from "../shared/components/fetch-error-page/fetch-error-page";
import { initializeSoortAanvraagOpties } from "./determine-taxatie-async-side-effects";
import { editKenmerken } from "./infra/taxatie-bureaus-helper";
import {
  taxatieBaseKenmerken,
  TaxatieSchermProps
} from "./infra/taxatie-scherm-schema";
import Taxatie from "./taxatie";
import { TaxatieSoortAanvraagOptions } from "../.generated/forms/formstypes";

type DetermineTaxatieObjectProps = {
  props: TaxatieSchermProps;
  resKenmerken: ProductkenmerkenOutput | null | string;
  resVormen: TaxatievormBasisOutput | null | string;
};

const DetermineTaxatieObject = ({
  props,
  resKenmerken,
  resVormen
}: DetermineTaxatieObjectProps): TaxatieSchermProps | null => {
  const taxatieObject = { ...props };

  if (
    !resVormen ||
    !resKenmerken ||
    typeof resKenmerken === "string" ||
    typeof resVormen === "string" ||
    !taxatieObject ||
    !taxatieObject.taxatiebureau
  ) {
    return null;
  }

  if (resKenmerken.productkenmerkCategorieen?.length) {
    taxatieObject.taxatieKenmerken = editKenmerken(
      taxatieObject.taxatiebureau.maatschappijCode,
      taxatieObject.taxatiebureau.soortAanvraag,
      {
        ...taxatieBaseKenmerken,
        ...resKenmerken.productkenmerkCategorieen[0].productkenmerken
      }
    );
  }

  if (resVormen.taxatievormen) {
    const vormen = resVormen.taxatievormen;
    taxatieObject.TaxatieSoortAanvraagOptionsOpties = initializeSoortAanvraagOpties(vormen);
    if (taxatieObject.TaxatieSoortAanvraagOptionsOpties.length === 1) {
      taxatieObject.taxatiebureau = {
        ...taxatieObject.taxatiebureau,
        soortAanvraag: taxatieObject.TaxatieSoortAanvraagOptionsOpties[0].value as TaxatieSoortAanvraagOptions,
        productCode: taxatieObject.TaxatieSoortAanvraagOptionsOpties[0].productCode,
        taxatieProductNaam: taxatieObject.TaxatieSoortAanvraagOptionsOpties[0].label
      };
    }
  }

  return taxatieObject;
};

const TaxatieKenmerkenAjax = (props: TaxatieSchermProps): ReactElement => {
  const { settings, requestInit } = useRequestInit();
  
  // Taxatievormen opvragen
  const productCode =props.taxatiebureau?.productCode;
  const urlvormen = `${settings?.productenOrigin}/Maatschappijcodes/${props.taxatiebureau?.maatschappijCode}/Taxatievormen`;
  const resVormen = useAbortableFetch<TaxatievormBasisOutput>(urlvormen, requestInit);

  // Productkenmerken voor taxatievorm opvragen
  const url = `${settings.productenOrigin}/Maatschappijcodes/${props.taxatiebureau?.maatschappijCode}/Productcodes/${productCode || "1"}/Taxatievormen/Productkenmerken`;
  const resKenmerken = useAbortableFetch<ProductkenmerkenOutput>(url, requestInit);

  const taxatieObject = useMemo(
    () => DetermineTaxatieObject({ props, resKenmerken: resKenmerken?.data, resVormen: resVormen?.data }),
    [props, resKenmerken, resVormen]
  );

  if (resKenmerken.error) {
    return <ErrorPage error={resKenmerken.error} data={resKenmerken.data} />;
  }

  if (resVormen.error) {
    return <ErrorPage error={resVormen.error} data={resVormen.data} />;
  }

  if (resKenmerken.loading || resVormen.loading) {
    return <PageLoading />;
  }

  if (!taxatieObject) {
    return <InvalidAdviesboxResultErrorPage />;
  }

  return <Taxatie {...taxatieObject} />;
};

TaxatieKenmerkenAjax.displayName = "TaxatieKenmerkenAjax";
export default withErrorBoundary(TaxatieKenmerkenAjax);
