import { LabeledBevestigingInput } from "adviesbox-shared";
import { connect, FormikContextType } from "formik";
import React, { ReactElement } from "react";
import { Direction, SelectedProps } from "../../shared/types";
import { PensioenenState } from "../infra/pensioen-schema";

const Werkgever = ({
  selected,
  formik: {
    values: { pensioenen }
  }
}: SelectedProps & {
  formik: FormikContextType<PensioenenState>;
}): ReactElement => {
  if (selected >= pensioenen.length) return <></>;

  return (
    <>
      <LabeledBevestigingInput
        caption="Huidige of vorige werkgever"
        name={`pensioenen[${selected}].werkgever.huidigeWerkgever`}
        labels={{ true: "Huidige werkgever", false: "Vorige werkgever" }}
        layout={Direction.Horizontal}
      />
    </>
  );
};

export default connect<SelectedProps, PensioenenState>(Werkgever);
