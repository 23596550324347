import { FormikTouched } from "formik";

import { SoortCorrectieLastOptions, SoortCorrectieFiscaalOptions } from "../../.generated/forms/formstypes";
import { KlantnaamType } from "../../shared/generic-parts/klantnaam/schema";

import { OverigePostType, OverigePostenType } from "./overige-posten-types";
import { bepaalEindleeftijd, bepaalAanvrager } from "./helpers";
import { productDraftSideEffects } from "../../producten-overzicht/product/product-side-effects";
import { initISWSideEffect, createISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";
import { getCurrentFocusedId } from "../../shared/utils/current-focus";

const detailGegevensSideEffects = createISWSideEffect<OverigePostType>(({ has, draft }): void => {
  const { overigePostenGegevens, correctie } = draft;

  if (has.correctie.correctieLast.changed) {
    // Correctie last !== Bijdrage -> inkomenUitArbeid = nee
    if (correctie.correctieLast === SoortCorrectieLastOptions.Bijdrage) {
      overigePostenGegevens.inkomenUitArbeid = false;
    } else {
      overigePostenGegevens.inkomenUitArbeid = null;
    }
  }

  if (has.overigePostenGegevens.inkomenUitArbeid.changed) {
    // Inkomen uit arbeid = nee -> Onderdeel dagloon = nee
    if (overigePostenGegevens.inkomenUitArbeid) {
      overigePostenGegevens.onderdeelDagloon = false;
    } else {
      overigePostenGegevens.onderdeelDagloon = null;
    }
  }

  if (has.overigePostenGegevens.bedrag.changed) {
    draft.overigePostenGegevens.bedragScenario.scenario.forEach(s => (s.bedrag = draft.overigePostenGegevens.bedrag));
  }
});

const correctieSideEffects = createISWSideEffect<OverigePostType>(({ has, draft }): void => {
  const { correctie } = draft;

  if (has.correctie.correctieLast.changed) {
    // Zet default als Correctie last aangepast wordt
    switch (correctie.correctieLast) {
      case SoortCorrectieLastOptions.Last:
        correctie.correctieFiscaal = SoortCorrectieFiscaalOptions.Aftrekpost;
        break;
      case SoortCorrectieLastOptions.Bijdrage:
        correctie.correctieFiscaal = SoortCorrectieFiscaalOptions.Bijtelling;
        break;
      case SoortCorrectieLastOptions.Neutraal:
        correctie.correctieFiscaal = SoortCorrectieFiscaalOptions.Aftrekpost;
        break;
    }
  }
});

const verrekenenSideEffects = createISWSideEffect<OverigePostType, Context>(({ has, draft, context }): void => {
  if (context) {
    const {
      verrekenen,
      heffing: { heffingBij }
    } = draft;
    const currentFocusId = getCurrentFocusedId();

    if (/^producten\[(\d+)\].verrekenen.overgangOpPartnerEinddatum$/.test(currentFocusId)) {
      if (has.verrekenen.overgangOpPartnerEinddatum.changed) {
        if (verrekenen.overgangOpPartnerEinddatum) {
          // Einddatum aanpassen is Eindleeftijd bijwerken
          verrekenen.eindleeftijd = bepaalEindleeftijd(
            verrekenen.overgangOpPartnerEinddatum,
            heffingBij,
            context.aanvrager1,
            context.aanvrager2
          );
        } else {
          verrekenen.eindleeftijd = null;
        }
      }
    } else if (/^producten\[(\d+)\].verrekenen.eindleeftijd$/.test(currentFocusId)) {
      if (has.verrekenen.eindleeftijd.changed) {
        // Eindleeftijd aanpassen is Einddatum bijwerken
        if (verrekenen.eindleeftijd) {
          const { geboortedatum } = bepaalAanvrager(heffingBij, context.aanvrager1, context.aanvrager2);
          const overgangOpPartnerEinddatum = geboortedatum.plusYears(verrekenen.eindleeftijd);

          verrekenen.overgangOpPartnerEinddatum = overgangOpPartnerEinddatum;
        } else {
          verrekenen.overgangOpPartnerEinddatum = null;
        }
      }
    } else {
      if (has.verrekenen.overlijden.changed && !verrekenen.overlijden) {
        // Overlijden uit zetten is Overgang naar partner wissen
        verrekenen.overgangOpPartnerPercentage = null;
        verrekenen.overgangOpPartnerEinddatum = null;
        verrekenen.eindleeftijd = null;
      }
    }
  }
});

type Context = {
  selected: number;
  aanvrager1: KlantnaamType | null;
  aanvrager2: KlantnaamType | null;
  touched: FormikTouched<OverigePostType> | null;
};

export const determineOverigePostDetailsSideEffects = initISWSideEffect<OverigePostenType, Context>((bag): void => {
  const { context, subset } = bag;
  const selected = subset.producten[context.selected].create();
  productDraftSideEffects(selected.subset.product.create());
  detailGegevensSideEffects(selected);
  correctieSideEffects(selected);
  verrekenenSideEffects(selected);
});
