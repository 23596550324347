import { KlantprofielVraagOptions } from "../../../.generated/forms/formstypes";
import { QuestionType, CardSpec, TitleType } from "../../infra/klantprofiel-schema";

//aanvrager2 options will be removed if the dossier doesnot have aanvrager2

export const toekomstPerspectiefQuestionSpecs: CardSpec[] = [
  {
    title: "Wat is de verwachte inkomensontwikkeling?",
    sections: [
      {
        title: TitleType.none,
        questions: [
          {
            title: TitleType.aanvrager,
            question: KlantprofielVraagOptions.VerwachteInkomensontwikkelingAanvrager1,
            type: QuestionType.radio
          },
          {
            question: KlantprofielVraagOptions.WaaromZalInkomenStijgenAanvrager1,
            type: QuestionType.radio,
            condition: [
              {
                question: KlantprofielVraagOptions.VerwachteInkomensontwikkelingAanvrager1,
                answer: 20103
              }
            ]
          },
          {
            question: KlantprofielVraagOptions.WaaromZalInkomenDalenAanvrager1,
            type: QuestionType.radio,
            condition: [
              {
                question: KlantprofielVraagOptions.VerwachteInkomensontwikkelingAanvrager1,
                answer: 20102
              }
            ]
          }
        ]
      },
      {
        title: TitleType.none,
        questions: [
          {
            title: TitleType.aanvrager,
            question: KlantprofielVraagOptions.VerwachteInkomensontwikkelingAanvrager2,
            type: QuestionType.radio
          },
          {
            question: KlantprofielVraagOptions.WaaromZalInkomenStijgenAanvrager2,
            type: QuestionType.radio,
            condition: [
              {
                question: KlantprofielVraagOptions.VerwachteInkomensontwikkelingAanvrager2,
                answer: 20203
              }
            ]
          },
          {
            question: KlantprofielVraagOptions.WaaromZalInkomenDalenAanvrager2,
            type: QuestionType.radio,
            condition: [
              {
                question: KlantprofielVraagOptions.VerwachteInkomensontwikkelingAanvrager2,
                answer: 20202
              }
            ]
          }
        ]
      }
    ]
  },
  {
    title: "Wanneer wilt u stoppen met werken?",
    questions: [
      {
        title: TitleType.aanvrager,
        question: KlantprofielVraagOptions.WanneerStoppenMetWerkenAanvrager1,
        type: QuestionType.radioMetJaarMaanden
      },

      {
        title: TitleType.aanvrager,
        question: KlantprofielVraagOptions.WanneerStoppenMetWerkenAanvrager2,
        type: QuestionType.radioMetJaarMaanden
      }
    ]
  },
  {
    questions: [
      {
        question: KlantprofielVraagOptions.WijzigingGezinssituatieVerwacht,
        type: QuestionType.radio,
        showVerplichtToelichting: {
          question: KlantprofielVraagOptions.WijzigingGezinssituatieVerwacht,
          answer: 20901
        }
      }
    ]
  },
  {
    questions: [
      {
        question: KlantprofielVraagOptions.HoelangBlijvenWonen,
        type: QuestionType.jaar
      }
    ]
  },
  {
    questions: [
      {
        question: KlantprofielVraagOptions.FinancieleMeevallerVerwacht,
        type: QuestionType.radio,
        showVerplichtToelichting: {
          question: KlantprofielVraagOptions.FinancieleMeevallerVerwacht,
          answer: 21101
        }
      }
    ]
  },
  {
    questions: [
      {
        question: KlantprofielVraagOptions.GroteUitgavenVerwacht,
        type: QuestionType.radio,
        showVerplichtToelichting: {
          question: KlantprofielVraagOptions.GroteUitgavenVerwacht,
          answer: 21201
        }
      }
    ]
  }
];
