import { FormikContextType, connect } from "formik";

import React, { ReactElement } from "react";
import TotaleRestschuldModal from "../totale-restschuld-modal/totale-restschuld-modal";
import { Icon } from "adviesbox-shared";
import { bedragFormat, optellen } from "../../shared/utils/currency";
import { ModalButton, LabeledResult } from "adviesbox-shared";
import { FinancieringsbehoefteState } from "../infra/financieringsbehoefte-types";

const TotaleRestschuld = ({
  formik: {
    values,
    setFieldValue,
    values: { financieringsopzet }
  }
}: {
  formik: FormikContextType<FinancieringsbehoefteState>;
}): ReactElement => {
  const { totaleRestschuldModal } = financieringsopzet;

  return (
    <LabeledResult
      caption="Totale restschuld"
      name="totaleRestschuld"
      alignRight={true}
      result={(): string =>
        bedragFormat(
          optellen([
            totaleRestschuldModal.restschuldHuidigeWoning.berekenen === null ||
            totaleRestschuldModal.restschuldHuidigeWoning.berekenen
              ? totaleRestschuldModal.restschuldHuidigeWoning.berekendBedrag
              : totaleRestschuldModal.restschuldHuidigeWoning.bedrag,
            totaleRestschuldModal.extraKostenHuidigeHypotheek
          ])
        )
      }
      appendChildren={
        <ModalButton
          parent="financieringsopzet.totaleRestschuldModal"
          aria-label="Totale restschuld button"
          content={<Icon name="specificatie" alt="Totale restschuld" />}
        >
          <TotaleRestschuldModal
            data={{
              ...values.financieringsopzet.totaleRestschuldModal,
              inbrengOpgebouwdeWaarde:
                (values.financieringsopzet.inbrengEigenGeldModal.inbrengOpgebouwdeWaarde || 0) +
                (values.financieringsopzet.inbrengEigenGeldModal.inbrengPolisBedrag || 0)
            }}
            eigenGeldInbrengOpgebouwdeWaardeTotaal={
              (values.financieringsopzet.inbrengEigenGeldModal.inbrengOpgebouwdeWaarde || 0) +
              (values.financieringsopzet.inbrengEigenGeldModal.inbrengPolisBedrag || 0)
            }
            onSave={(result): void => {
              setFieldValue(
                "financieringsopzet.inbrengEigenGeldModal.inbrengVoorVerlagenRestschuld",
                result.inbrengEigenGeldVoorVerlagenRestschuld ? result.inbrengEigenGeldVoorVerlagenRestschuld : null
              );
              setFieldValue(
                "financieringsopzet.inbrengEigenGeldModal.waarvanVerlagenRestschuld",
                result.inbrengOpgebouwdeWaardeRestschuld.bedrag
              );
              setFieldValue("financieringsopzet.totaleRestschuldModal", result);
            }}
          />
        </ModalButton>
      }
    />
  );
};

export default connect<{}, FinancieringsbehoefteState>(TotaleRestschuld);
