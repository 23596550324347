import { SettingsType, ValidationResultModelType, hasJSONResponse } from "adviesbox-shared";
import { User } from "oidc-client";
import { Taxatie } from "../../.generated/forms/formstypes";

export type TaxatieAanvraagResponse = {
  validationResults: ValidationResultModelType[],
  redirectUrl: string
}
export const taxatieAanvragenApi = async (
  settings: SettingsType,
  user: User | null,
  vestigingId: string,
  adviesdossierId: string,
  body: Taxatie | null
): Promise<TaxatieAanvraagResponse> => {
  const url = `${settings.klantdossiersFormsOrigin}/Adviesdossiers/${adviesdossierId}/Aanvraag`;
  if (!user) throw Error("Er is onvoldoende data om verder te gaan");
  const rsp = await fetch(url, {
    method: "POST",
    headers: {
      authorization: `${user.token_type} ${user.access_token}`,
      "Ocp-Apim-Subscription-Key": settings.OcpApimSubscriptionKey,
      "Content-Type": "application/json;charset=UTF-8",
      vestigingId
    },
    body: JSON.stringify(body)
  });

  if (rsp?.ok && hasJSONResponse(rsp)) {
    const jsonResult = await rsp.json();
    return { validationResults: jsonResult.validationResults ?? [], redirectUrl: jsonResult.message };
  }
  else {
    const jsonResult = await rsp.json();
    return { validationResults: jsonResult?.validationResults ?? [{ code: "ERR", target: "", message: "Er is een onherstelbare fout opgetreden" }], redirectUrl: "" };
  }
};
