import { connect, FormikContextType } from "formik";
import React, { ReactElement } from "react";
import { HdnaanvraagKenmerken } from "../../producten-overzicht/infra/product-kenmerken-types";
import { OrvsState } from "../infra/orv-schema";

type HdnAanvraagProps = {
  kenmerken: HdnaanvraagKenmerken;
};
const HdnAanvraag = ({
  kenmerken,
  formik: {
    setFieldValue,
    values: { producten }
  }
}: HdnAanvraagProps & {
  formik: FormikContextType<OrvsState>;
}): ReactElement => {
  return (
    <div className="px-03">
      <p>{kenmerken.hintHDNAanvraagNietAanwezig}</p>
    </div>
  );
};

export default connect<HdnAanvraagProps, OrvsState>(HdnAanvraag);
