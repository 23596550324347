import React, { ReactElement } from "react";
import { LabeledBevestigingInput } from "adviesbox-shared";
import { useFormikContext } from "formik";
import { AovsType } from "../infra/aov-schema";
import { AanvragerKeuze } from "../../shared/types";
import { getAovTextResources } from "../infra/aov-resources";

type AanvullendeVragenProps = { selected: number };

export const AanvullendeVragen = ({ selected }: AanvullendeVragenProps): ReactElement => {
  const { values } = useFormikContext<AovsType>();
  const selectedProduct = values.producten[selected];
  return (
    <>
        <LabeledBevestigingInput
          caption={getAovTextResources("CaptionReorganisatieWerkgever")}
          name={
            selectedProduct.verzekerden.verzekerden === AanvragerKeuze.Aanvrager1
              ? `producten[${selected}].aanvullendeVragen.reorganisatieWerkgeverAanvrager1`
              : `producten[${selected}].aanvullendeVragen.reorganisatieWerkgeverAanvrager2`
          }
        />
        <LabeledBevestigingInput
        caption={getAovTextResources("CaptionVerwachtingOntslag")}
          name={
            selectedProduct.verzekerden.verzekerden === AanvragerKeuze.Aanvrager1
              ? `producten[${selected}].aanvullendeVragen.verwachtingOntslagAanvrager1`
              : `producten[${selected}].aanvullendeVragen.verwachtingOntslagAanvrager2`
          }
        />
    </>
  );
};
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production")
  AanvullendeVragen.displayName = "AanvullendeVragen";
