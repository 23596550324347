import * as Yup from "yup";
import {
  KlantDetails,
  VerblijfsVergunningType,
  Personalia as PersonaliaDlEntry,
  PersonaliaAanvrager,
  PersonaliaKind,
  Schooltype,
  PersoonDetails,
  GeslachtOpties
} from "../../.generated/forms/formstypes";
import { mapAdresUiNaarDl } from "../../shared/generic-parts/adres/map-ui-2-dl";
import { createMapEnum } from "../../shared/utils/create-map-enum";
import { createMapToDl } from "../../shared/utils/create-map-to-dl";
import {
  AantalKeer,
  aanvrager1Schema,
  aanvragerAdresSchema,
  aanvragerBurgerlijkeStatusSchema,
  aanvragerExtraSchema,
  aanvragerLevensgeschiedenisSchema,
  Geslacht,
  kindSchema,
  medeAanvragerOptiesSchema,
  personaliaSchema,
  SchoolType,
  SoortVerblijfsvergunning
} from "./personalia-schema";
import { mapLocalDateToString } from "adviesbox-shared";

const mapSchoolType = createMapEnum(SchoolType).to({
  BovenbouwHAVOVWO: Schooltype.BovenbouwHavoVwo,
  BovenbouwVMBO: Schooltype.BovenbouwVmbo,
  GeenSchool: Schooltype.GeenSchool,
  OnderbouwHAVOVWO: Schooltype.OnderbouwHavoVwo,
  OnderbouwVMBO: Schooltype.OnderbouwVmbo,
  BasisSchool: Schooltype.Basisschool,
  NietErkendeOpleiding: Schooltype.NietErkendeOpleiding,
  Hbo: Schooltype.Hbo,
  Mbo: Schooltype.Mbo,
  Wo: Schooltype.Wo
});

const mapGeslachtOpties = createMapEnum(Geslacht).to({
  Geen: GeslachtOpties.Geen,
  Man: GeslachtOpties.Man,
  Vrouw: GeslachtOpties.Vrouw
});

const mapAantalKeerKinderbijslag = createMapEnum(AantalKeer).to({ x1: 1, x2: 2 });

const mapSoortVerblijfsvergunning = createMapEnum(SoortVerblijfsvergunning).to({
  Geen: VerblijfsVergunningType.Geen,
  BepaaldeTijd: VerblijfsVergunningType.BepaaldeTijd,
  BepaaldeTijdAsielDocumentIII: VerblijfsVergunningType.BepaaldeTijdAsiel_DocumentIII_,
  Onbekend: VerblijfsVergunningType.Onbekend,
  OnbepaaldeTijd: VerblijfsVergunningType.OnbepaaldeTijd,
  OnbepaaldeTijdAsielIV: VerblijfsVergunningType.OnbepaaldeTijdAsiel_DocumentIV_,
  BepaaldeTijdNietTijdelijkVerblijfsdoel: VerblijfsVergunningType.BepaaldeTijdNietTijdelijkVerblijfsdoel
});

const aanvragerInfoSchema = Yup.object({
  aanvragerVolgnummer: Yup.number(),
  medeAanvragerOpties: medeAanvragerOptiesSchema,
  aanvrager: aanvrager1Schema,
  extra: aanvragerExtraSchema,
  adres: aanvragerAdresSchema,
  burgerlijkeStatus: aanvragerBurgerlijkeStatusSchema,
  levensgeschiedenis: aanvragerLevensgeschiedenisSchema
});
const mapPersoonDetails = createMapToDl(aanvragerInfoSchema).to<PersoonDetails>({
  bsn: v => null,
  geboortedatum: v => mapLocalDateToString(v.extra.geboortedatum),
  geslacht: v => mapGeslachtOpties(v.extra.geslacht) || GeslachtOpties.Geen,
  geboortelandCode: v => v.adres.geboortelandCode,
  geboorteplaats: v => v.burgerlijkeStatus.geboorteplaats,
  nationaliteitCode: v => v.adres.nationaliteitCode,

  voorvoegsel: v => v.aanvrager.tussenvoegselAchternaam,
  achternaam: v => v.aanvrager.achternaam,
  geboorteVoorvoegsel: v => v.aanvrager.tussenvoegselGeboortenaam,
  geboortenaam: v => v.aanvrager.geboortenaam,
  roepnaam: v => v.aanvrager.roepnaam,
  voornamen: v => v.aanvrager.voornamen,
  voorletters: v => v.aanvrager.voorletters,

  telefoonMobiel: v => v.adres.telefoonnummerMobiel,
  telefoonPrive: v => v.adres.telefoonnummerPrive,
  telefoonWerk: v => v.adres.telefoonnummerWerk,

  email: v => (v.adres.emailAdres.trim().length > 0 ? v.adres.emailAdres : null)
});

const mapKlantDetails = createMapToDl(aanvragerInfoSchema).to<KlantDetails>({
  volgnummer: v => v.aanvragerVolgnummer,

  fiscaleWoonstaatCode: v => v.adres.fiscaleWoonstaatCode,
  gescheiden: v => v.levensgeschiedenis.ooitGescheiden,
  titel: v => (v.aanvrager.titel ? parseInt(v.aanvrager.titel, 10) : null), // TODO: formstypes zou string moeten zijn voor HDN

  gescheidenPerDatum: v =>
    v.levensgeschiedenis.ooitGescheiden ? mapLocalDateToString(v.levensgeschiedenis.datumEchtscheiding) : null,
  hoofdelijkAansprakelijk: v => v.levensgeschiedenis.hoofdelijkAansprakelijk,
  opleidingsNiveau: v => Number(v.levensgeschiedenis.hoogstGenotenOpleiding),

  legitimatiebewijsSoort: v => v.burgerlijkeStatus.legitimatiebewijs.soortLegitimatiebewijs || null,
  legitimatieLandCode: v =>
    v.burgerlijkeStatus.legitimatiebewijs.soortLegitimatiebewijs
      ? v.burgerlijkeStatus.legitimatiebewijs.landAfgifte
      : null,
  legitimatiebewijsAfgiftedatum: v =>
    v.burgerlijkeStatus.legitimatiebewijs.soortLegitimatiebewijs
      ? mapLocalDateToString(v.burgerlijkeStatus.legitimatiebewijs.datumAfgifte)
      : null,
  legitimatiebewijsAfgifteplaats: v =>
    v.burgerlijkeStatus.legitimatiebewijs.soortLegitimatiebewijs
      ? v.burgerlijkeStatus.legitimatiebewijs.plaatsnaamAfgifte
      : null,
  legitimatiebewijsGeldTotDatum: v =>
    v.burgerlijkeStatus.legitimatiebewijs.soortLegitimatiebewijs
      ? mapLocalDateToString(v.burgerlijkeStatus.legitimatiebewijs.geldigTot)
      : null,
  legitimatiebewijsNummer: v =>
    v.burgerlijkeStatus.legitimatiebewijs.soortLegitimatiebewijs
      ? v.burgerlijkeStatus.legitimatiebewijs.nummerLegitimatiebewijs
      : null,

  verblijfsvergunningCode: v =>
    mapSoortVerblijfsvergunning(v.burgerlijkeStatus.verblijfsvergunning.soortVerblijfsvergunning),

  verblijfsvergunningGeldigTotDatum: v => mapLocalDateToString(v.burgerlijkeStatus.verblijfsvergunning.geldigTot),
  verblijfsvergunningNummer: v => v.burgerlijkeStatus.verblijfsvergunning.nummerVerblijfsvergunning,

  burgerlijkeStaat: v => +v.burgerlijkeStatus.burgerlijkeStaat,
  gescheidenPartnerschap: v => (v.levensgeschiedenis.ooitGescheiden ? +v.levensgeschiedenis.scheiding : null),
  roken: v => v.extra.roker
});

const mapPersonaliaKind = createMapToDl(kindSchema)
  .with(medeAanvragerOptiesSchema)
  .to<PersonaliaKind>({
    kindId: v => v.kindId,
    voornamen: v => v.naam,
    coOuderschap: v => v.coOuderschap,
    gezamenlijkKind: (v, x) => (x.medeAanvrager ? v.gezamenlijkKind : null),
    inwonend: v => v.inwonend,
    kinderbijslagPerJaar: v => v.kinderbijslagPerJaar,
    geboortedatum: v => mapLocalDateToString(v.geboortedatum) || "",
    schooltype: v => mapSchoolType(v.schooltype) || Schooltype.Geen,
    aantalKeerKinderbijslag: v => mapAantalKeerKinderbijslag(v.aantalKeer) || 0
  });

const mapAanvrager = createMapToDl(aanvragerInfoSchema).to<PersonaliaAanvrager>({
  klantId: v => v.aanvrager.klantId,
  persoonsgegevens: mapPersoonDetails,
  klantgegevens: mapKlantDetails,

  woonadres: v => mapAdresUiNaarDl(v.adres.adres),

  rekening: v => ({
    rekeningId: v.burgerlijkeStatus.rekeningId,
    iban: v.burgerlijkeStatus.iban.toUpperCase().replace(/\s*/gm, ""),
    gezamenlijkeRekening: v.burgerlijkeStatus.gezamelijkeRekening
  }),

  uitsluitingen: v =>
    v.levensgeschiedenis.uitsluitingenVerzekeringen
      .filter(u => u.maatschappijCode)
      .map((u, i) => ({
        ...u,
        uitsluitingsdatum: mapLocalDateToString(u.uitsluitingsdatum),
        volgnummer: i + 1
      })),

  weigeringen: v =>
    v.levensgeschiedenis.weigeringenVerzekeringen
      .filter(w => w.maatschappijCode)
      .map((w, i) => ({
        ...w,
        weigeringDatum: mapLocalDateToString(w.weigeringDatum),
        volgnummer: i + 1
      })),

  kinderen: v => v.burgerlijkeStatus.kinderen.map(kind => mapPersonaliaKind(v.medeAanvragerOpties)(kind))
});

export const mapPersonaliaUiToDl = createMapToDl(personaliaSchema).to<PersonaliaDlEntry>({
  herberekeningkeuze: v => (v.herberekeningskeuze ? Number(v.herberekeningskeuze) : null),
  adviesMetMedeaanvrager: v => v.medeAanvragerOpties.medeAanvrager,
  aanvrager2HeeftAfwijkendAdres: v =>
    v.medeAanvragerOpties.medeAanvrager ? v.medeAanvragerOpties.afwijkendAdres : null,

  aanvrager1: v =>
    mapAanvrager({
      aanvragerVolgnummer: 1,
      aanvrager: v.aanvrager1,
      adres: v.aanvrager1Adres,
      burgerlijkeStatus: v.aanvrager1BurgerlijkeStatus,
      extra: v.aanvrager1Extra,
      levensgeschiedenis: v.aanvrager1Levensgeschiedenis,
      medeAanvragerOpties: v.medeAanvragerOpties
    }),

  aanvrager2: v =>
    v.medeAanvragerOpties.medeAanvrager
      ? mapAanvrager({
          aanvragerVolgnummer: 2,
          aanvrager: v.aanvrager2,
          adres: v.aanvrager2Adres,
          burgerlijkeStatus: v.aanvrager2BurgerlijkeStatus,
          // TODO: Dit lijkt niet te kloppen voor aanvrager 2 omdat die optioneel is
          extra: v.aanvrager2Extra as any,
          levensgeschiedenis: v.aanvrager2Levensgeschiedenis,
          medeAanvragerOpties: v.medeAanvragerOpties
        })
      : null
});
