import * as Yup from "yup";
import { LocalDate } from "@js-joda/core";
import { GeslachtOpties } from "../../../.generated/forms/formstypes";
import { nullableLocalDateSchema, localDateSchema } from "adviesbox-shared";

export const klantnaamSchema = Yup.object().shape({
  klantId: Yup.string().default(""),
  achternaam: Yup.string().default(""),
  voorvoegsel: Yup.string().default(""),
  voorletters: Yup.string().default(""),
  voornamen: Yup.string().default(""),
  geboortedatum: localDateSchema.default(LocalDate.of(1900, 1, 1)),
  roker: Yup.boolean()
    .nullable()
    .default(null),
  geslacht: Yup.mixed<GeslachtOpties>()
    .nullable()
    .default(null),
  aowdatum: nullableLocalDateSchema
});

export const klantnaamMetKinderenSchema = klantnaamSchema.shape({
  heeftKinderen: Yup.boolean()
    .nullable()
    .default(null)
});

export type KlantnaamType = Yup.InferType<typeof klantnaamSchema>;
export type KlantnaamMetKinderenType = Yup.InferType<typeof klantnaamMetKinderenSchema>;
