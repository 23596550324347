import { ErrorPage, PageLoading, SettingsType, withErrorBoundary } from "adviesbox-shared";
import React, { ReactElement } from "react";
import { mapDlTargetToKlantProfielUiField, mapKlantprofielDlToUi } from "../../infra/map-klantprofiel-scherm-dl-to-ui";

import { InvalidAdviesboxResultErrorPage } from "../../../shared/components/fetch-error-page/fetch-error-page";
import { KlantprofielOnderwerpOptions } from "../../../.generated/forms/formstypes";
import RenderKlantProfielScherm from "../../infra/render-klantprofiel-scherm";
import { eigenGeldQuestionSpecs } from "./eigen-geld-question-specs";
import { mapKlantprofielUiToDl } from "../../infra/map-klantprofiel-scherm-ui-to-dl";
import { useAdviesboxData } from "../../../shared/hooks/use-adviesbox-data";

type RouteParams = {
  vestiging: string;
  adviesdossier: string;
};

const createUrl = (s: SettingsType, p: RouteParams): string =>
  `${s.klantdossiersFormsOrigin}/Adviesdossiers/${p.adviesdossier}/Klantprofiel/${KlantprofielOnderwerpOptions.HypotheekEigenGeld}`;

const HypotheekEigenGeldAjax = (): ReactElement | null => {
  const { loading, error, data, platformData, saveData } = useAdviesboxData(
    createUrl,
    p => p.adviesdossier,
    mapKlantprofielDlToUi,
    mapKlantprofielUiToDl,
    mapDlTargetToKlantProfielUiField
  );

  if (loading) {
    return <PageLoading />;
  }

  if (error) {
    return <ErrorPage error={error} data={platformData} />;
  }

  if (!data) {
    return <InvalidAdviesboxResultErrorPage />;
  }

  return (
    <RenderKlantProfielScherm {...data} schermTitle="Eigen geld" specs={eigenGeldQuestionSpecs} saveData={saveData} />
  );
};

HypotheekEigenGeldAjax.displayName = "HypotheekEigenGeldAjax";

export default withErrorBoundary(HypotheekEigenGeldAjax);
