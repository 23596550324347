import React, { ReactElement } from "react";
import { FormikProps } from "formik";
import { determineExtraAflossingenModalSideEffects } from "./determine-extra-aflossingen-modal-side-effects";
import AdviesBoxFormik from "../../shared/utils/adviesbox-formik";
import { optellen } from "../../shared/utils/currency";
import Modal from "../../shared/components/modal/Modal";
import { ExtraAflossingenModalType, ExtraAflossingen } from "../infra/hypotheek-types";
import { getHypotheekTextResources } from "../infra/hypotheek-resources";
import { extraAflossingenModalSchema, ExtraAflossingeModalSchemaContextType } from "../infra/hypotheek-schema";
import { Column } from "react-table-6";
import { LocalDate } from "@js-joda/core";
import { DataGrid } from "adviesbox-shared";
import { ISWSideEffects } from "../../shared/components/isw-side-effects/isw-side-effects";

export type ExtraAflossingenModalData = {
  data: ExtraAflossingenModalType;
};

export type ExtraAflossingenBenodigdheden = {
  leningdeelBedrag: number | null;
  ingangsdatum: LocalDate | null;
  einddatum: LocalDate | null;
};

type ExtraAflossingenModalProps = {
  benodigdheden: ExtraAflossingenBenodigdheden;
  onSave?: (data: ExtraAflossingenModalType) => void;
  closeModal?: () => void;
};

const extraAflossingenColumns: Column[] = [
  {
    Header: "Datum",
    accessor: "datum",
    Cell: "DateInput"
  },
  {
    Header: "Bedrag",
    accessor: "bedrag",
    Cell: "CurrencyInput",
    width: 100
  },
  { Cell: "DeleteButton" }
];

const ExtraAflossingenModal = ({
  benodigdheden: { leningdeelBedrag, ingangsdatum, einddatum },
  data,
  onSave,
  closeModal
}: ExtraAflossingenModalData & ExtraAflossingenModalProps): ReactElement => {
  const body = (values: ExtraAflossingenModalType, opgeteldeExtraAflossingen: number): ReactElement => {
    return (
      <>
        <div>
          <div className="px-1 pb-4">{getHypotheekTextResources("ExtraAflossingenTekst")}</div>
          <DataGrid
            columns={extraAflossingenColumns}
            name="extraAflossingen"
            showButtonDelete={values.extraAflossingen.length > 0}
            rowCaption="extra aflossing"
            getNewRowValues={(): ExtraAflossingen => ({
              datum: ingangsdatum,
              bedrag: 0
            })}
            className="editable"
          />

          {leningdeelBedrag && opgeteldeExtraAflossingen > leningdeelBedrag && (
            <div className="input-rood">{getHypotheekTextResources("ErrorExtraAflossingHogerDanLeningdeel")}</div>
          )}
        </div>
      </>
    );
  };

  return (
    <AdviesBoxFormik<ExtraAflossingenModalType>
      initialValues={{ ...data }}
      validationSchema={extraAflossingenModalSchema}
      createValidationContext={(): ExtraAflossingeModalSchemaContextType => ({
        productIngangsdatum: ingangsdatum,
        productEinddatum: einddatum
      })}
      onSave={onSave}
      closeModal={closeModal}
      render={({ values, submitForm }: FormikProps<ExtraAflossingenModalType>): ReactElement => {
        const opgeteldeExtraAflossingenBedrag =
          optellen(values.extraAflossingen.map((x): number | null => x.bedrag)) || 0;

        return (
          <>
            <ISWSideEffects sync={determineExtraAflossingenModalSideEffects} />
            <Modal
              title="Extra aflossingen"
              body={body(values, opgeteldeExtraAflossingenBedrag)}
              invalid={leningdeelBedrag ? opgeteldeExtraAflossingenBedrag > leningdeelBedrag : true}
              onSubmitClick={submitForm}
              onCancelClick={closeModal}
            />
          </>
        );
      }}
    />
  );
};

ExtraAflossingenModal.displayName = "ExtraAflossingenModal";

export default ExtraAflossingenModal;
