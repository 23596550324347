import { initISWSideEffect } from "../../../shared/components/isw-side-effects/create-isw-helpers";
import { syncControleNormSideEffects } from "../../components/controle-norm/infra/controle-norm-sideeffects";
import { DashboardState } from "../../infra/dashboard-types";
import { updateDataOutdated } from "../../infra/scenario-side-effects";
import {
  syncScenarioMinimaalUitgangspuntSideEffects,
  syncScenarioVanSideEffects
} from "../../infra/scenario-van-side-effects";

export const syncScenarioOverlijdenModalSideEffects = initISWSideEffect<DashboardState>(({ has, draft, subset }) => {
  syncControleNormSideEffects(subset.overlijden.controleNorm.create());

  if (has.overlijden.changed) {
    updateDataOutdated(draft, draft.overlijden.scenarioVan, has.overlijden.scenarioVan);
  }

  syncScenarioVanSideEffects(subset.overlijden.scenarioVan.create());

  subset.overlijdenUitgangspunten !== null &&
    syncScenarioMinimaalUitgangspuntSideEffects(subset.overlijden.controleNorm.create());
})();
