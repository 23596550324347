import { Card, CardWrapper, ErrorPage, PageLoading, useRequestInit } from "adviesbox-shared";
import { connect, FormikContextType } from "formik";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import {
  KapitaalopbouwOptions,
  SoortBerekeningOptionsKv,
  SoortKapitaalverzekeringsrekeningOptions
} from "../../.generated/forms/formstypes";
import {
  isKenmerkError,
  KapitaalverzekeringKenmerken,
  KenmerkenError
} from "../../producten-overzicht/infra/product-kenmerken-types";
import { ProductkenmerkenContext } from "../../producten-overzicht/infra/producten-context";
import { SituatieSoort } from "../../producten-overzicht/infra/producten-overzicht-types";
import Kapitaalopbouw from "../../producten-overzicht/kapitaalopbouw/kapitaalopbouw";
import PremieGegevens from "../../producten-overzicht/premie-gegevens/premie-gegevens";
import Product from "../../producten-overzicht/product/product";
import Verpanding from "../../producten-overzicht/verpanding/verpanding";
import Verzekerden from "../../producten-overzicht/verzekerden/verzekerden";
import Verzekeringnemers from "../../producten-overzicht/verzekering-nemers/verzekering-nemers";
import { ISWSideEffects } from "../../shared/components/isw-side-effects/isw-side-effects";
import { AanvragerKeuze } from "../../shared/types";
import { getNaam } from "../../shared/utils/helpers";
import FiscaleRegeling from "../fiscale-regeling/fiscale-regeling";
import { determineKapitaalverzekeringDetailsSideEffects } from "../infra/determine-kapitaalverzekering-details-side-effects";
import { kapitaalverzekeringPremieBerekenen } from "../infra/kapitaalverzekering-api";
import { KapitaalverzekeringenType } from "../infra/kapitaalverzekering-types";
import { mapBerekendePremieDl2Ui } from "../infra/map-kapitaalverzekering-dl-2-ui";
import { mapKapitaalverzekeringUiToDl } from "../infra/map-kapitaalverzekering-ui-2-dl";
import Overlijdensrisicodekking from "../overlijdensrisicodekking/overlijdensrisicodekking";
import Poliswaarde from "../poliswaarde/poliswaarde";
import { getKapitaalverzekeringKenmerkenVanSoortBerekening } from "../../producten-overzicht/infra/producten-helper";

type KapitaalverzekeringDetailsProps = { selected: number; situatie: SituatieSoort };

const KapitaalverzekeringDetails = ({
  selected,
  situatie,
  formik: { values, setFieldValue }
}: { formik: FormikContextType<KapitaalverzekeringenType> } & KapitaalverzekeringDetailsProps): ReactElement => {
  const { user, params, settings } = useRequestInit<{ vestiging: string; adviesdossier: string }>();
  const { producten, aanvrager1, aanvrager2 } = values;
  const selectedProduct = producten && producten[selected];
  const { verzekerden } = selectedProduct.verzekerden;
  const { soortRekening } = selectedProduct.kapitaalopbouw;
  const { waardeopbouwBedrag } = selectedProduct.poliswaarde;
  const premieNietAftrekbaar = !(
    selectedProduct.fiscaleRegeling.lijfrenteclausule &&
    selectedProduct.fiscaleRegeling.kapitaalopbouw === KapitaalopbouwOptions.Box1
  );

  const kenmerkenContext = useContext(ProductkenmerkenContext);
  const [kenmerken, setKenmerken] = useState<KapitaalverzekeringKenmerken | null | KenmerkenError>(null);
  const [extraKenmerken, setExtraKenmerken] = useState<KapitaalverzekeringKenmerken | null>(null);
  const [abortController, setAbortController] = useState(new AbortController());

  useEffect(
    () =>
      setKenmerken(
        kenmerkenContext.getProductKenmerken(
          "Kapitaalverzekering",
          selectedProduct.partijCode,
          selectedProduct.productCode
        )
      ),
    [setKenmerken, kenmerkenContext, selectedProduct.productCode, selectedProduct.partijCode]
  );

  useEffect(() => {
    setExtraKenmerken(
      getKapitaalverzekeringKenmerkenVanSoortBerekening(
        kenmerken as KapitaalverzekeringKenmerken | null,
        selectedProduct.partijCode,
        selectedProduct.productCode,
        selectedProduct.kapitaalopbouw.soortBerekening
      )
    );
  }, [
    kenmerken,
    selectedProduct.partijCode,
    selectedProduct.productCode,
    selectedProduct.kapitaalopbouw.soortBerekening,
    setExtraKenmerken
  ]);

  if (!kenmerken || !extraKenmerken) return <PageLoading />;

  if (kenmerken && isKenmerkError(kenmerken)) {
    return <ErrorPage error={new Error(`Fout bij het ophalen van producteigenschappen: ${kenmerken.reden}`)} />;
  }

  return (
    <CardWrapper className="px-3 mb-5 pb-5" maxRowCount={7}>
      <ISWSideEffects<KapitaalverzekeringenType> sync={determineKapitaalverzekeringDetailsSideEffects(selected)} />

      <Card
        title={
          situatie === "voorstel"
            ? `${selectedProduct.product.doorlopend ? "Doorlopend" : "Nieuw"} product`
            : "Huidig product"
        }
      >
        <Product
          selected={selected}
          situatie={situatie}
          kenmerken={extraKenmerken.product}
          productSoort="vermogen"
          isStarterslening={false}
        />
      </Card>

      <Card title="Fiscale regeling">
        <FiscaleRegeling selected={selected} />
      </Card>

      <Card title="Verzekeringnemer(s)">
        <Verzekeringnemers selected={selected} kenmerken={extraKenmerken.verzekeringnemer} />
      </Card>

      <Card title="Verzekerde(n)">
        <Verzekerden selected={selected} kenmerken={extraKenmerken.verzekerden} />
      </Card>

      {(verzekerden === AanvragerKeuze.Aanvrager1 || verzekerden === AanvragerKeuze.Beiden) && (
        <Card title={`Overlijdensrisicodekking ${getNaam(aanvrager1, "Aanvrager 1")}`}>
          <Overlijdensrisicodekking selected={selected} propName="verzekerde1Overlijdensrisicodekking" />
        </Card>
      )}

      {(verzekerden === AanvragerKeuze.Aanvrager2 || verzekerden === AanvragerKeuze.Beiden) && (
        <Card title={`Overlijdensrisicodekking ${getNaam(aanvrager2, "Aanvrager 2")}`}>
          <Overlijdensrisicodekking selected={selected} propName="verzekerde2Overlijdensrisicodekking" />
        </Card>
      )}

      <Card title="Kapitaalopbouw">
        <Kapitaalopbouw gebruik="kapitaalverzekering" selected={selected} kenmerken={extraKenmerken.kapitaalopbouw} />
      </Card>

      <Card title="Premiegegevens">
        <PremieGegevens
          premieNietAftrekbaar={premieNietAftrekbaar}
          calculate={async () => {
            if (!user) return null;
            const newController = new AbortController();
            abortController.abort();
            setAbortController(newController);

            const result = await kapitaalverzekeringPremieBerekenen(
              settings,
              user,
              params.vestiging,
              params.adviesdossier,
              {
                kapitaalverzekeringProduct: mapKapitaalverzekeringUiToDl({
                  opslag: false,
                  aanvrager1,
                  aanvrager2,
                  kenmerken: extraKenmerken
                })(selectedProduct)
              },
              newController.signal
            );

            if (!result) return null;
            setFieldValue(
              `producten[${selected}]`,
              mapBerekendePremieDl2Ui(selectedProduct, result, {
                voorbeeldbedragUitBerekening: !extraKenmerken.kapitaalopbouw.voorbeeldkapitaalBedragEnabled,
                voorbeeldpercentageUitBerekening: !extraKenmerken.kapitaalopbouw.voorbeeldkapitaalPercentageEnabled
              })
            );
            return null;
          }}
          selected={selected}
          kenmerken={extraKenmerken.premie}
          validatieKenmerken={null}
          isSpaarProduct={soortRekening === SoortKapitaalverzekeringsrekeningOptions.Spaar}
          isBeleggingProduct={soortRekening === SoortKapitaalverzekeringsrekeningOptions.Belegging}
          isHybrideProduct={soortRekening === SoortKapitaalverzekeringsrekeningOptions.Hybride}
          spaarPremieLaagEnabled={selectedProduct.kapitaalopbouw.soortBerekening !== SoortBerekeningOptionsKv.Premie}
          berekenKnopText={"Berekenen"}
        />
      </Card>

      <Card title="Poliswaarde">
        <Poliswaarde situatie={situatie} selected={selected} kenmerken={extraKenmerken.poliswaarde} />
      </Card>

      {extraKenmerken.verpanding.verpandingTonen && (
        <Card title="Verpanding">
          <Verpanding
            selected={selected}
            situatie={situatie}
            kenmerken={extraKenmerken.verpanding}
            waardeopbouwBedrag={waardeopbouwBedrag}
            verpandAanGeldverstrekkerTonen
          />
        </Card>
      )}
    </CardWrapper>
  );
};

KapitaalverzekeringDetails.displayName = "KapitaalverzekeringDetails";

export default connect<KapitaalverzekeringDetailsProps, KapitaalverzekeringenType>(KapitaalverzekeringDetails);
