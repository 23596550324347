import { InkomenFiscus as InkomenEnFiscusDlEntry } from "../../.generated/forms/formstypes";
import { UiName, FieldMap } from "../../shared/types";
import { target2field } from "../../shared/utils/target-to-field";

export function mapDlTargetToInkomenEnFiscusUiField(target: string): UiName | null {
  const map: FieldMap<InkomenEnFiscusDlEntry> = {
    aanvrager1: {
      aowData: {
        aowDatum: { field: "aanvrager1Aow.ingangsdatum.datum", label: "Aanvrager 1 ingangsdatum AOW" }
      }
    },
    aanvrager2: {
      aowData: {
        aowDatum: { field: "aanvrager2Aow.ingangsdatum.datum", label: "Aanvrager 2 ingangsdatum AOW" }
      }
    }
  };

  return target2field(map, target);
}
