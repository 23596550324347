import React, { ReactElement } from "react";
import { connect, FormikContextType } from "formik";
import { LabeledRadioInput, LabeledResult } from "adviesbox-shared";
import { Direction, SelectedProps } from "../../shared/types";
import { enum2options } from "../../shared/utils/enum-to-options";
import { getNaam } from "../../shared/utils/helpers";
import { WoonsituatieListState, SoortWoonsituatie, Bewoners } from "../infra/woonsituatie-schema";

const HuidigeWoonsituatie = ({
  selected,
  formik: {
    values: { woonsituaties, persoonsgegevens }
  }
}: SelectedProps & {
  formik: FormikContextType<WoonsituatieListState>;
}): ReactElement => {
  const selectedWoonsituatie = woonsituaties ? woonsituaties[selected] : null;
  if (!selectedWoonsituatie) return <></>;

  const hasTweedeAanvrager = persoonsgegevens.naamPartner && persoonsgegevens.naamPartner.klantId;

  return (
    <>
      <LabeledRadioInput
        caption="Woonsituatie"
        name={`woonsituaties[${selected}].huidigeWoonsituatie.woonsituatie`}
        options={enum2options(SoortWoonsituatie)}
      />
      {hasTweedeAanvrager ? (
        <LabeledRadioInput
          caption="Bewoner(s)"
          name={`woonsituaties[${selected}].huidigeWoonsituatie.bewoners`}
          options={[
            {
              label: getNaam(persoonsgegevens.naamAanvrager, "Aanvrager"),
              value: Bewoners.Aanvrager1
            },
            {
              label: getNaam(persoonsgegevens.naamPartner, "Partner"),
              value: Bewoners.Aanvrager2
            },
            {
              label: "Beiden",
              value: Bewoners.Beiden
            }
          ]}
          layout={Direction.Vertical}
        />
      ) : (
        <LabeledResult
          caption="Bewoner(s)"
          name={`woonsituaties[${selected}].huidigeWoonsituatie.bewoners`}
          result={(): string => getNaam(persoonsgegevens.naamAanvrager, "Aanvrager")}
          readonly
        />
      )}
    </>
  );
};

export default connect<SelectedProps, WoonsituatieListState>(HuidigeWoonsituatie);
