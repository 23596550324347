import {
  AdviesBoxFormik,
  bedragFormat,
  createSpanWithId,
  DataGrid,
  LabeledCurrencyInput,
  LabeledPercentageInput
} from "adviesbox-shared";
import React, { ReactElement, useState } from "react";
import { Column } from "react-table-6";
import {
  specificatieGevraagdeHypotheekSchema,
  SpecificatieIndicatieMeerMinderLenenState
} from "../infra/acceptatie-schema";
import classes from "./specificatie-indicatie.module.scss";
import Modal from "../../../../../shared/components/modal/Modal";

export type SpecificatieIndicatieProps = {
  data: SpecificatieIndicatieMeerMinderLenenState;
  closeModal?: () => void;
};

const uitwerkingColumns: Column[] = [
  {
    Header: "Periode (van t/m maand)",
    id: "periode",
    Cell: (c): ReactElement => createSpanWithId(c.index, 0, c.original.periode),
    width: 150
  },
  {
    Header: "Toegestane maandlast",
    id: "toegestaneMaandlast",
    Cell: (c): ReactElement => createSpanWithId(c.index, 1, bedragFormat(c.original.toegestaneMaandlast, 2, false)),
    width: 133,
    className: "text-right"
  },
  {
    Header: "Fictieve Maandlast",
    id: "totaleMaandlastVoorstel",
    Cell: (c): ReactElement => createSpanWithId(c.index, 2, bedragFormat(c.original.totaleMaandlastVoorstel, 2, false)),
    width: 112,
    className: "text-right"
  },
  {
    Header: "Ruimte",
    id: "beschikbareRuimte",
    Cell: (c): ReactElement => createSpanWithId(c.index, 3, bedragFormat(c.original.beschikbareRuimte, 2, false)),
    width: 75,
    className: "text-right"
  },
  {
    Header: "Werkelijke Maandlast",
    id: "totaleMaandlastVoorstel",
    Cell: (c): ReactElement => createSpanWithId(c.index, 2, bedragFormat(c.original.werkelijkeMaandlast, 2, false)),
    width: 125,
    className: "text-right"
  },
  {
    Header: "Ink. aanvrager",
    id: "verplichteExtraAflossing",
    Cell: (c): ReactElement => createSpanWithId(c.index, 4, bedragFormat(c.original.inkomenAanvrager1, 2, false)),
    width: 88,
    className: "text-right"
  },
  {
    Header: "Ink. partner",
    id: "verplichteExtraAflossing",
    Cell: (c): ReactElement => createSpanWithId(c.index, 5, bedragFormat(c.original.inkomenAanvrager2, 2, false)),
    width: 90,
    className: "text-right"
  }
];

export const SpecificatieIndicatieModal = ({ data, closeModal }: SpecificatieIndicatieProps): ReactElement => {
  const rowSelected = useState(0);
  const [selected] = rowSelected;
  const body = (
    <>
      <LabeledPercentageInput
        name="toetsrente"
        caption="Toetsrente"
        className={classes.percentage_offset}
        decimalen={3}
        readonly
      />
      <LabeledPercentageInput
        name="woonquote"
        caption="Woonquote"
        className={classes.percentage_offset}
        decimalen={2}
        readonly
      />
      <LabeledCurrencyInput name="fictieveJaarlast" caption="Fictieve jaarlast" readonly />
      <LabeledCurrencyInput name="toegestaneJaarlast" caption="Toegestane jaarlast" readonly />
      <LabeledCurrencyInput
        name="constanteWaardeMeerMinderLenen"
        caption="Contante waarde indicatie meer/minder lenen"
        readonly
      />

      <h2>Uitwerking meer/minder lenen</h2>
      <DataGrid name="uitwerkingen" masterDetail columns={uitwerkingColumns} rowSelected={rowSelected} />
      {data.uitwerkingen && data.uitwerkingen[selected] && (
        <>
          <div className={"form-group form-row mt-5"}>
            <div className="col-6">
              <label className={"col-form-label"}>
                <h2>Periode van {data.uitwerkingen[selected].periode}</h2>
              </label>
            </div>
          </div>
          <div className="col-6"></div>
          <LabeledCurrencyInput
            name={`uitwerkingen[${selected}].inkomenAanvrager1`}
            caption={`Inkomen ${data.naamAanvrager}`}
            readonly
            decimalen={2}
            readonlyRounding={false}
          />
          {data.naamPartner && (
            <LabeledCurrencyInput
              name={`uitwerkingen[${selected}].inkomenAanvrager2`}
              caption={`Inkomen ${data.naamPartner}`}
              readonly
              decimalen={2}
              readonlyRounding={false}
            />
          )}
          <LabeledCurrencyInput
            name={`uitwerkingen[${selected}].onverpandVermogen`}
            caption="Onverpand vermogen"
            readonly
          />
          <LabeledPercentageInput
            name={`uitwerkingen[${selected}].toetsrente`}
            decimalen={3}
            caption="Toetsrente"
            className={classes.percentage_offset}
            readonly
          />
          <LabeledPercentageInput
            name={`uitwerkingen[${selected}].woonquoteBox1`}
            decimalen={2}
            caption="Woonquote box 1"
            className={classes.percentage_offset}
            readonly
          />
          <LabeledPercentageInput
            name={`uitwerkingen[${selected}].woonquoteBox3`}
            decimalen={2}
            caption="Woonquote box 3"
            className={classes.percentage_offset}
            readonly
          />
          <LabeledCurrencyInput
            name={`uitwerkingen[${selected}].toegestaneMaandlast`}
            caption="Toegestane maandlast"
            readonly
            fontWeight={"bold"}
            decimalen={2}
            readonlyRounding={false}
          />
          <LabeledCurrencyInput
            name={`uitwerkingen[${selected}].maandlastVoorstelBox1`}
            caption="Maandlast uit voorstel box 1"
            readonly
            decimalen={2}
            readonlyRounding={false}
          />
          <LabeledCurrencyInput
            name={`uitwerkingen[${selected}].maandlastVoorstelBox3`}
            caption="Maandlast uit voorstel box 3"
            readonly
            decimalen={2}
            readonlyRounding={false}
          />
          <LabeledCurrencyInput
            name={`uitwerkingen[${selected}].totaleMaandlastVoorstel`}
            caption="Totale maandlast uit voorstel"
            readonly
            fontWeight={"bold"}
            decimalen={2}
            readonlyRounding={false}
          />
          <LabeledCurrencyInput
            name={`uitwerkingen[${selected}].beschikbareRuimte`}
            caption="Beschikbare ruimte"
            readonly
            decimalen={2}
            readonlyRounding={false}
          />
          <LabeledCurrencyInput
            name={`uitwerkingen[${selected}].werkelijkeMaandlast`}
            caption="Werkelijke maandlast"
            readonly
            decimalen={2}
            readonlyRounding={false}
          />
        </>
      )}
    </>
  );

  return (
    <AdviesBoxFormik<SpecificatieIndicatieMeerMinderLenenState>
      initialValues={data}
      validationSchema={specificatieGevraagdeHypotheekSchema}
      closeModal={closeModal}
      render={(): ReactElement => (
        <div className="modal-with-cards">
          <Modal
            title="Specificatie indicatie meer/minder lenen"
            body={body}
            noEditableData
            onCancelClick={closeModal}
          />
        </div>
      )}
    />
  );
};
