import { createSpanWithId } from "adviesbox-shared";
import moment from "moment";
import React, { ReactElement } from "react";
import { CellInfo, Column } from "react-table-6";
import { TableColumnId } from "../../infra/zoekscherm-schema";
import { ZoekenDeleteClientButton } from "./zoeken-delete-client-button";
import classes from "./zoeken-delete.module.scss";

export const ZoekResultatenColumns = (deleteClient: (klantdossierId: string) => () => void): Column[] => {
  const createDeleteButton = (c: CellInfo): ReactElement => (
    <div className={classes.delete}>
      <ZoekenDeleteClientButton cellInfo={c} deleteClientFunc={deleteClient} />
    </div>
  );

  return [
    {
      headerStyle: { overflow: "initial" },
      Header: "Laatst geopend",
      accessor: "laatstGeopend",
      id: "laatstGeopend",
      Cell: (c): ReactElement => {
        const laatstGeopend = c.original.laatstGeopend
          ? moment(c.original.laatstGeopend.toJSON()).format("DD-MM-YYYY HH:mm")
          : null;
        return createSpanWithId(c.index, 0, laatstGeopend, laatstGeopend || "", "zoekresultaat");
      }
    },
    {
      headerStyle: { overflow: "initial" },
      Header: "Klant",
      accessor: "klant",
      id: "klantNaam",
      minWidth: 180,
      Cell: (c): ReactElement => createSpanWithId(c.index, 1, c.original.klant, c.original.klant, "zoekresultaat")
    },
    {
      headerStyle: { overflow: "initial" },
      Header: "Aantal dossiers",
      accessor: "aantalDossiers",
      id: "aantalDossiers",
      maxWidth: 100,
      Cell: (c): ReactElement =>
        createSpanWithId(c.index, 2, c.original.aantalDossiers, c.original.aantalDossiers, "zoekresultaat")
    },
    {
      headerStyle: { overflow: "initial" },
      Header: "Geboortedatum",
      accessor: "geboorteData",
      id: "geboorteData",
      Cell: (c): ReactElement => {
        const aanvrager1 = c.original.geboorteData[0]
          ? moment(c.original.geboorteData[0].toJSON()).format("DD-MM-YYYY")
          : "";
        const aanvrager2 = c.original.geboorteData[1]
          ? moment(c.original.geboorteData[1].toJSON()).format("DD-MM-YYYY")
          : "";
        return createSpanWithId(
          c.index,
          3,
          `${aanvrager1}${aanvrager2 ? ", " + aanvrager2 : ""}`,
          `${aanvrager1}${aanvrager2 ? ", " + aanvrager2 : ""}`,
          "zoekresultaat"
        );
      }
    },
    {
      headerStyle: { overflow: "initial" },
      Header: "Postcode",
      accessor: "postcode",
      id: "postcode",
      maxWidth: 75,
      Cell: (c): ReactElement => createSpanWithId(c.index, 4, c.original.postcode, c.original.postcode, "zoekresultaat")
    },
    {
      headerStyle: { overflow: "initial" },
      Header: "Woonplaats",
      accessor: "woonplaats",
      id: "woonplaats",
      Cell: (c): ReactElement =>
        createSpanWithId(c.index, 5, c.original.woonplaats, c.original.woonplaats, "zoekresultaat")
    },
    {
      headerStyle: { overflow: "initial" },
      Header: "Adviseur",
      accessor: "adviseur",
      id: "adviseur",
      Cell: (c): ReactElement => createSpanWithId(c.index, 6, c.original.adviseur, c.original.adviseur, "zoekresultaat")
    },
    {
      headerStyle: { overflow: "initial" },
      Header: "Vestiging",
      accessor: "vestiging",
      id: "vestiging",
      Cell: (c): ReactElement =>
        createSpanWithId(c.index, 7, c.original.vestiging, c.original.vestiging, "zoekresultaat")
    },
    {
      Cell: createDeleteButton,
      id: TableColumnId.DeleteClientButton,
      width: 30
    }
  ];
};
