import { AanvragerKeuze } from "../../shared/types";
import { KlantnaamType } from "../../shared/generic-parts/klantnaam/schema";

export function mapAanvragers(
  keuze: AanvragerKeuze,
  aanvrager1: KlantnaamType | null,
  aanvrager2: KlantnaamType | null
): string[] {
  const returnValue: string[] = [];

  switch (keuze) {
    case AanvragerKeuze.Aanvrager1:
      if (aanvrager1) {
        returnValue.push(aanvrager1.klantId);
      }
      break;

    case AanvragerKeuze.Aanvrager2:
      if (aanvrager2?.klantId) {
        returnValue.push(aanvrager2.klantId);
      }
      break;
    case AanvragerKeuze.Beiden:
      if (aanvrager1) {
        returnValue.push(aanvrager1.klantId);
      }
      if (aanvrager2?.klantId) {
        returnValue.push(aanvrager2.klantId);
      }
      break;
    default:
      if (aanvrager1) {
        returnValue.push(aanvrager1.klantId);
      }
  }
  return returnValue;
}
