import { hasValue, mapStringToLocalDate } from "adviesbox-shared";
import {
  Adres,
  Bankgarantie as BankgarantieDlEntry,
  BankgarantieAlgemeen,
  BankgarantieGarantie,
  BankgarantieOutput as BankgarantieDlOutput,
  BankgarantiePand,
  BankgarantieVerkoper,
  KlantnaamReadOnly,
  GeslachtOpties,
  Financieringsoort
} from "../../.generated/forms/formstypes";
import { Adres as AdresInstelingen } from "../../.generated/instellingen-forms/instellingen-formstypes";
import adresSchema from "../../shared/generic-parts/adres/schema";
import { mapBerekenDate } from "../../shared/generic-parts/bereken-date/map";
import { createMapEnum } from "../../shared/utils/create-map-enum";
import { createMapToUi } from "../../shared/utils/create-map-to-ui";
import {
  aanvrager1Schema,
  aanvrager2Schema,
  bankgarantieKaartSchema,
  bankgarantieSchema,
  BankgarantieState,
  Geslacht,
  InstellingCodes,
  notarisSchema,
  onroerendeZaakSchema,
  pandSchema,
  productSchema,
  verkoperSchema,
  verkopersSchema
} from "./bankgarantie-schema";

const mapGeslacht = createMapEnum(GeslachtOpties).to({
  Geen: Geslacht.Geen,
  Man: Geslacht.Man,
  Vrouw: Geslacht.Vrouw
});

export const mapAdresInstellingen = createMapToUi(adresSchema).from<AdresInstelingen>({
  huisnummer: v => v.huisnummer,
  landCode: v => v.landCode,
  locatie: v => "",
  plaats: v => v.plaats,
  postcode: v => {
    if (v.postcode && v.landCode === "NL") {
      const postcode = v.postcode.toUpperCase().replace(/\s/g, "");
      const letters = postcode.slice(4, 6);
      const numbers = postcode.slice(0, 4);
      if (numbers.charAt(0) !== "") {
        return `${numbers} ${letters}`;
      }
    }
    return v.postcode;
  },
  straat: v => v.straat,
  toevoeging: v => v.toevoeging
});

export const mapAanvrager1 = createMapToUi(aanvrager1Schema).from<KlantnaamReadOnly>({
  klantId: v => v.klantId,
  voorvoegsel: v => v.voorvoegsel,
  voornamen: v => v.voornamen,
  voorletters: v => v.voorletters,
  achternaam: v => v.achternaam,
  geslacht: v => mapGeslacht(v.geslacht),
  geboortedatum: v => mapStringToLocalDate(v.geboortedatum),
  aowdatum: v => mapStringToLocalDate(v.geboortedatum),
  roker: v => v.roker
});

export const mapAanvrager2 = createMapToUi(aanvrager2Schema).from<KlantnaamReadOnly>({
  klantId: v => v.klantId,
  voorvoegsel: v => v.voorvoegsel,
  voornamen: v => v.voornamen,
  voorletters: v => v.voorletters,
  achternaam: v => v.achternaam,
  geslacht: v => mapGeslacht(v.geslacht),
  geboortedatum: v => mapStringToLocalDate(v.geboortedatum),
  aowdatum: v => mapStringToLocalDate(v.geboortedatum),
  roker: v => v.roker
});

const mapProduct = createMapToUi(productSchema).from<BankgarantieAlgemeen>({
  aanvraagVia: v => v.aanvraagViaArrangementId,
  instelling: v => (v.instellingMaatschappijCode ? v.instellingMaatschappijCode : InstellingCodes.Geen),
  productkeuze: v => v.arrangementsnaam,
  tussenpersoonnummer: v => v.tussenpersoonnummer,
  agentnummer: v => (v.agentnummer ? v.agentnummer : null)
});

const mapVerkoper = createMapToUi(verkoperSchema).from<BankgarantieVerkoper>({
  achternaam: v => v.achternaam,
  tussenvoegsel: v => v.voorvoegsel,
  voorletters: v => v.voorletters,
  woonplaats: v => v.woonplaats
});

const mapVerkopers = createMapToUi(verkopersSchema).from<BankgarantieDlEntry>({
  bedrijfsnaam: v => v.verkopers?.bedrijfsnaam,
  verkoper1: v => (v.verkopers?.verkoper1 ? mapVerkoper(v.verkopers.verkoper1) : null),
  verkoper2: v => (v.verkopers?.verkoper2 ? mapVerkoper(v.verkopers.verkoper2) : null)
});

const mapAdres = createMapToUi(adresSchema).from<Adres>({
  straat: v => v.straat,
  huisnummer: v => v.huisnummer,
  toevoeging: v => v.toevoeging,
  locatie: v => v.locatie,
  plaats: v => v.plaats,
  landCode: v => v.landCode,
  postcode: v => {
    if (v.postcode && v.landCode === "NL") {
      const postcode = v.postcode.toUpperCase().replace(/\s/g, "");
      const letters = postcode.slice(4, 6);
      const numbers = postcode.slice(0, 4);
      if (numbers.charAt(0) !== "") {
        return `${numbers} ${letters}`;
      }
    }
    return v.postcode;
  }
});

const mapOnroerendeZaak = createMapToUi(onroerendeZaakSchema).from<BankgarantieDlEntry>({
  isNieuwbouw: v => (v.soortFinanciering === Financieringsoort.AankoopNieuwbouw ? true : false),
  adres: v => (v.onroerendeZaak ? mapAdres(v.onroerendeZaak.nieuwWoonadres) : null),
  bouwnummer: v => v.onroerendeZaak?.bouwnummer
});

const mapPand = createMapToUi(pandSchema).from<BankgarantiePand>({
  aankoopprijs: v => v.aankoopprijsBedrag,
  gehanteerdeMarktwaarde: v => v.marktwaardeBedrag,
  hypotheekbedrag: v => v.hypotheekbedrag,
  nhg: v => v.nhg,
  eigenMiddelen: v => v.eigenMiddelenBedrag,
  overbrugging: v => v.overbruggingBedrag,
  geldverstrekker: v => v.geldverstrekkerNaam
});

const mapNotaris = createMapToUi(notarisSchema).from<BankgarantieDlEntry>({
  gekozenNotariskantoorId: v => (v.notaris?.gekozenNotariskantoorId ? v.notaris.gekozenNotariskantoorId : "Anders"),
  kantoornaam: v => v.notaris?.kantoornaam,
  adres: v => (v.notaris?.adres ? mapAdres(v.notaris.adres) : null),
  typeNotaris: v => v.notaris?.typeNotaris,
  telefoonnummer: v => v.notaris?.telefoonNummer
});

const mapBankgarantieKaart = createMapToUi(bankgarantieKaartSchema)
  .with<number | null>()
  .from<BankgarantieGarantie>({
    afgiftedatumBankgarantie: v => mapStringToLocalDate(v.afgiftedatum),
    garantiebedrag: v => v.premieBedrag,
    passeerdatum: v =>
      mapBerekenDate(
        v.passeerdatum,
        v.passeerdatum && hasValue(v.passeerdatumOvernemen) ? v.passeerdatumOvernemen : true,
        v.passeerdatumStandaard
      ),
    vergoedingKorting: v => v.vergoedingCode,
    verzekerdeBankgarantie: v => v.verzekerdeBankgarantie,
    garantieKostenberekenen: (v, c) => {
      return {
        bedrag: v.bankgarantiekostenBedrag,
        berekenen: v.bankgarantiekostenEigenInvoer ? !v.bankgarantiekostenEigenInvoer : null,
        berekendBedrag: v.premieBedrag ? v.premieBedrag * 0.01 : null
      };
    },
    datumOndertekeningKoopakte: v => mapStringToLocalDate(v.ondertekeningKoopakteDatum),
    ingangsdatumGarantie: v => mapStringToLocalDate(v.ingangsdatum),
    einddatumGarantie: v => mapStringToLocalDate(v.einddatum),
    readOnlyInstelling: _ => InstellingCodes.Geen
  });

function dl2ui(values: BankgarantieDlEntry): BankgarantieState {
  const bankgarantie = {
    aanvrager1: mapAanvrager1(values.aanvrager1),
    aanvrager2: hasValue(values.aanvrager2?.achternaam) ? mapAanvrager2(values.aanvrager2) : null,
    product: mapProduct(values.algemeen),
    verkopers: mapVerkopers(values),
    onroerendeZaak: mapOnroerendeZaak(values),
    pand: mapPand(values.pand),
    notaris: mapNotaris(values),
    bankgarantieKaart: mapBankgarantieKaart(values.pand?.garantiebedrag ?? null)(values.garantie),
    soortFinanciering: values.soortFinanciering
  };
  return bankgarantieSchema.cast(bankgarantie);
}

export function mapBankgarantieDlToUi(bankgarantieId: string, data: BankgarantieDlOutput): BankgarantieState | null {
  const bankgarantie = data && data.bankgaranties ? data.bankgaranties[bankgarantieId] : null;

  if (!bankgarantie) return null;

  return dl2ui(bankgarantie);
}
