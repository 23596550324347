import { FormikProps } from "formik";
import React, { ReactElement } from "react";
import Modal from "../../../shared/components/modal/Modal";
import AdviesBoxFormik from "../../../shared/utils/adviesbox-formik";
import { aanleidingSchema, AanleidingState } from "../../infra/aanleiding-schema";
import { SimpleModal } from "adviesbox-shared";


type FinancieringWijzigenModalProps = {
  data: AanleidingState;  
  onSave?: (data: AanleidingState) => void;
  closeModal?: () => void;
  onCancel?: () => void;
};

export const FinancieringWijzigenModal = ({
  data,
  onSave,
  closeModal,
  onCancel
}: FinancieringWijzigenModalProps): ReactElement => {
  return (
    <SimpleModal closeModal={closeModal}>
        <AdviesBoxFormik<AanleidingState>
        initialValues={data}
        validationSchema={aanleidingSchema}
        onSave={onSave}
        closeModal={closeModal}
        render={({ submitForm }: FormikProps<AanleidingState>): ReactElement => (
            <Modal
            onSubmitClick={submitForm}
            submitButtonVariant="danger"
            saveButtonTekst="Ja, wijziging(en) behouden"
            onCancelClick={onCancel}
            title="Wijzigen soort financiering"
            body={
                <div>
                    Door de wijziging(en) worden de gegevens in de tabbladen &rsquo;Financieringsbehoefte&rsquo; bijgewerkt en Voorstel 1 en eventueel Voorstel 2 en 3 gewist.
                    <br />
                    Wilt u de wijziging(en) behouden?
                </div>
            }
            />
        )}
        />
    </SimpleModal>
  );
};
