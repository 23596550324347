import { LocalDate } from "@js-joda/core";
import {
  KlantnaamReadOnly,
  RecentGeopendOutput,
  ZoekenAdviesdossierMetKlantInfo,
  Dossierstatus,
  ZoekresultaatAdviseur
} from "../../.generated/forms/formstypes";
import { createMapEnum } from "../../shared/utils/create-map-enum";
import { createMapToUi } from "../../shared/utils/create-map-to-ui";
import { AdviesDossierStatus, laatsteDossierSchema, zoekschermSchema, ZoekschermState } from "./zoekscherm-schema";
import { convertUtcToTimeZone, mapStringToLocalDateTime } from "adviesbox-shared";

export enum AdviesdossierStatusOptions {
  Geen = "Geen",
  InBehandeling = "InBehandeling",
  Actief = "Actief",
  NietActief = "NietActief"
}

function getNaamAanvrager(aanvrager: KlantnaamReadOnly): string {
  return `${(aanvrager.voornamen || "") + "\xa0"}${
    aanvrager.voorvoegsel ? aanvrager.voorvoegsel + "\xa0" : ""
  }${aanvrager.achternaam || ""}`.trim();
}

export function mapKlantNamen(aanvrager1: KlantnaamReadOnly, aanvrager2?: KlantnaamReadOnly | null): string {
  const naamAanvrager = getNaamAanvrager(aanvrager1);
  const naamPartner =
    aanvrager2 && (aanvrager2.achternaam || aanvrager2.voornamen)
      ? `,\xa0${getNaamAanvrager(aanvrager2)}`
      : aanvrager2?.geboortedatum
      ? ",\xa0Aanvrager\xa02"
      : "";
  return naamAanvrager + naamPartner;
}

export function mapGeboorteData(aanvrager1: KlantnaamReadOnly, aanvrager2?: KlantnaamReadOnly | null): LocalDate[] {
  const geboortDataArray: LocalDate[] = [];
  
  if (aanvrager1?.geboortedatum) {
    const gebAanvrager = convertUtcToTimeZone(aanvrager1?.geboortedatum)?.toLocalDate();

    if (gebAanvrager) {
      geboortDataArray.push(gebAanvrager);
    }
  }
  
  if (aanvrager2?.geboortedatum) {
    const gebAanvrager = convertUtcToTimeZone(aanvrager2?.geboortedatum)?.toLocalDate();

    if (gebAanvrager) {
      geboortDataArray.push(gebAanvrager);
    }
  }
  return geboortDataArray;
}

export const mapBemiddelingDossierStatus = createMapEnum(Dossierstatus).to({
  Geen: AdviesDossierStatus.Geen,
  InBehandeling: AdviesDossierStatus.InBehandeling,
  Actief: AdviesDossierStatus.Actief,
  NietActief: AdviesDossierStatus.NietActief
});

export const mapStatus2ReadableString = {
  Geen: "Geen",
  InBehandeling: "In behandeling",
  Actief: "Actief",
  NietActief: "Niet actief"
};

export const mapAanleidingen2ReadableString = {
  AankoopNieuwbouw: "Aankoop van een nieuwbouw woning",
  AankoopBestaandeBouw: "Aankoop van een bestaande woning",
  HuidigeHypotheekWijzigen: "Huidige hypotheek wijzigen",
  GeenFinanciering: "Geen financiering"
};

export const dl2ui = createMapToUi(zoekschermSchema).from<ZoekenAdviesdossierMetKlantInfo[]>({
  zoeken: _ => null,
  nieuweKlant: _ => null,
  laatsteDossiers: v => ({
    inView: 10,
    aantalDossiers: v.length,
    laatsteDossiers: v.map(
      (val): ReturnType<typeof laatsteDossierSchema.cast> => {
        const { aanvrager1, laatstBewerktDatum, laatstBewerktTijd, aanvrager2 } = val;
        if (!aanvrager1) {
          throw new Error("geen aanvrager bij het mappen");
        }
        if (!laatstBewerktDatum || !laatstBewerktTijd) {
          throw new Error("geen laatstBewerkt bij het mappen");
        }
        return {
          aanleiding: val.aanleiding ? mapAanleidingen2ReadableString[val.aanleiding] : "",
          adviesdossierId: `${val.adviesdossierId}`,
          klant: mapKlantNamen(aanvrager1, aanvrager2),
          laatstGeopend: mapStringToLocalDateTime(laatstBewerktDatum, laatstBewerktTijd),
          omschrijving: `${val.omschrijving || ""}`,
          status: mapStatus2ReadableString[val.status || ""]
        };
      }
    )
  }),
  recentGeopendError: _ => false,
  totalNumberOfPages: _ => 0,
  totalNumberOfResults: _ => 0,
  externeDossiers: _ => [],
});

export function mapZoekschermDlToUi(data?: RecentGeopendOutput | string | null): ZoekschermState | null {
  if (!data || typeof data === "string" || !data.zoekresultaten || !data.isValid) {
    return null;
  }

  return dl2ui(data.zoekresultaten);
}

export function getNaamPersoon(client?: KlantnaamReadOnly | ZoekresultaatAdviseur | null): string {
  if (!client) return "";
  return `${client.voornamen || ""}${client.voorvoegsel ? "\xa0" + client.voorvoegsel : ""} ${client.achternaam ||
    ""}`.trim();
}
