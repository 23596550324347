import { LabeledRadioInput, LabeledTextInput, FormFirstFocus } from "adviesbox-shared";
import { FormikProps } from "formik";
import React, { ReactElement } from "react";
import Modal from "../../../shared/components/modal/Modal";
import { LabelValuePair } from "../../../shared/types";
import AdviesBoxFormik from "../../../shared/utils/adviesbox-formik";
import { aanleidingSchema, AanleidingState, VoorstelType } from "../../infra/aanleiding-schema";

type VoorstelkopierenModalProps = {
  data: AanleidingState;
  element: VoorstelType;
  voorstellen: LabelValuePair[];
  onSave?: (data: AanleidingState) => void;
  closeModal?: () => void;
};

const handleFocus = (event: React.ChangeEvent<HTMLInputElement>): void => event.target.select();
export const VoorstelkopierenModal = ({
  data,
  element,
  voorstellen,
  onSave,
  closeModal
}: VoorstelkopierenModalProps): ReactElement => {
  return (
    <AdviesBoxFormik<AanleidingState>
      initialValues={data}
      validationSchema={aanleidingSchema}
      onSave={onSave}
      closeModal={closeModal}
      render={({ submitForm }: FormikProps<AanleidingState>): ReactElement => (
        <Modal
          onSubmitClick={submitForm}
          submitButtonVariant="danger"
          saveButtonTekst="Voorstel overschrijven"
          onCancelClick={closeModal}
          title="Voorstel overschrijven"
          body={
            <FormFirstFocus selector={`input[name="newKopieOptieNaam"]`}>
              <div className="w-100 px-3">
                <LabeledRadioInput
                  caption={`Welk voorstel wil je overschrijven met de gegegevens van ${element.omschrijving ||
                    `Voorstel #${1 + element.index}`}?`}
                  numericalId={true}
                  options={voorstellen}
                  name={`kopieOptie`}
                />
                <LabeledTextInput caption="Naam nieuw voorstel" onFocus={handleFocus} name="newKopieOptieNaam" />
              </div>
            </FormFirstFocus>
          }
        />
      )}
    />
  );
};
