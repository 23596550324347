/* TODO Istanbul weghalen */
/* istanbul ignore file */

import { createGetTextResources } from "../../shared/utils/text-resources-algemeen";

export const getAovTextResources = createGetTextResources({
  uitgebreidToolTip:
    "Selecteer ‘Ja’ als de WGA-gat verzekering aanvult tot het uitkeringspercentage van het laatstverdiende loon. De standaardwaarde is 70% van het laatstverdiende loon. Als je ‘Nee’ selecteert, dan vult de WGA-gat verzekering aan tot het uitkeringspercentage van het laatstverdiende loon vermenigvuldigd met het percentage arbeidsongeschiktheid. Bij bijvoorbeeld 50% arbeidsongeschiktheidscenario wordt het dan standaard 70% * laatstverdiende loon * 50%. Het uitkeringspercentage is in te stellen achter de knop bij het veld 'Uitkering gedeeltelijk AO'. Let op: het laatstverdiende loon wordt gemaximeerd op het maximum dagloon ({tariefMaxDagInkomen}).",
  TooltipCollectief: `Selecteer ‘Ja’ als het om een collectieve verzekering gaat. De premie van de verzekering wordt dan niet meer meegenomen als last in de overzichten. Het heeft verder geen invloed op de hoogte van de uitkering.`,
  CaptionReorganisatieWerkgever: `Weet of verwacht de verzekeringnemer dat in de directe werkomgeving saneringen, fusies, reorganisaties of overheidsmaatregelen gaan plaatsvinden? En dat dit gevolgen heeft voor de verzekeringnemer zelf of zijn afdeling/team/enz.`,
  CaptionVerwachtingOntslag: `Weet of verwacht de verzekeringnemer dat het dienstverband beëindigd gaat worden.`,
  TooltipKeuzeDekking: `Vul hier in het percentage van het inkomen uit arbeid boven het maximum dagloon dat wordt verzekerd.`,
  TooltipUitkeringGedeeltijlijkeAO: `Je kunt het uitkeringspercentage bij gedeeltelijk arbeidsongeschiktheid instellen. Bepaal of er sprake is van een ‘Volledige uitkering’ of een ‘Uitkering naar rato’ bij gedeeltelijk arbeidsongeschiktheid. Bij een ‘Uitkering naar rato’? Dan kun je in het specificatiescherm aangegeven of er wordt uitgekeerd op basis van een ‘Staffel’ of het ‘Werkelijk arbeidsongeschiktheidspercentage’. De standaard arbeidsongeschiktheidsgrenzen en het uitkeringspercentages zijn nog aan te passen. In het arbeidsongeschiktheidsscenario wordt aan de hand van het percentage arbeidsongeschiktheid uit het scenario het juiste 'Uitkeringspercentage' opgehaald. Dit 'Uitkeringspercentage' wordt met de verzekerde dekking van de verzekering vermenigvuldigd.`,
  CheckLeeftijdRubriekA: `Vul een eindleeftijd in die na de leeftijd bij aanvang van de dekking valt.`,
  DekkingPercentageTooltip: `Vul hier in het percentage van het inkomen uit arbeid boven het maximum dagloon dat wordt verzekerd.`
});
