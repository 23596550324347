import { ChronoUnit, LocalDate } from "@js-joda/core";
import * as Yup from "yup";
import { InferType } from "yup";
import { BurgerlijkeStaatType } from "../../.generated/forms/formstypes";
import {
  AoUitkeringswijze,
  specificatieAoUitkeringModalSchema
} from "../../producten-overzicht/infra/specificatie-ao-uitkering-schema";
import { berekenInputSchema } from "../../shared/types";
import { bedragFormat } from "../../shared/utils/currency";
import { decimalFormat } from "../../shared/utils/decimal-format";
import { yupEnum } from "../../shared/utils/yup-enum";
import { getPensioenTextResources } from "./pensioen-resources";
import { nullableLocalDateSchema } from "adviesbox-shared";

export enum Pensioenregelingsoort {
  Geen = "",
  Eindloon = "Eindloonregeling",
  Middelloon = "Middelloonregeling",
  BeschikbarePremie = "Beschikbare premieregeling"
}

export type TariefEntry = {
  jaar: number;
  maand: number;
};

export const ouderdomspensioenModalEntrySchema = Yup.object({
  volgnummer: Yup.number()
    .nullable()
    .default(null),
  ingangsdatum: nullableLocalDateSchema,
  bedrag: Yup.number()
    .nullable()
    .default(null)
});
export type OuderdomspensioenModalEntryType = InferType<typeof ouderdomspensioenModalEntrySchema>;

export const ouderdomspensioenModalSchema = Yup.object({
  // Als hier iets mis gaat, dit was nullable
  indexeringen: Yup.array(ouderdomspensioenModalEntrySchema).default([ouderdomspensioenModalEntrySchema.default()])
});
export type OuderdomspensioenModalType = InferType<typeof ouderdomspensioenModalSchema>;

export const werkgeverSchema = Yup.object({
  huidigeWerkgever: Yup.boolean().default(true)
});
export type WerkgeverType = InferType<typeof werkgeverSchema>;

export const pensioenuitvoerderSchema = Yup.object({
  naamPensioenuitvoerder: Yup.string().default(""),
  brancheSector: Yup.string().default("")
});
export type PensioenuitvoerderType = InferType<typeof pensioenuitvoerderSchema>;

export const loondienstenSchema = Yup.object({
  loondienstId: Yup.string()
    .nullable()
    .default(null),
  beroepsfunctie: Yup.string()
    .nullable()
    .default(null),
  totaalBrutoInkomenBedrag: Yup.number()
    .nullable()
    .default(null),
  meetellenTotDatum: nullableLocalDateSchema
});
export type LoondienstType = InferType<typeof loondienstenSchema>;

export const deelnemerSchema = Yup.object({
  loondiensten: Yup.array(loondienstenSchema).default([loondienstenSchema.default()]),
  klantId: Yup.string().default(""),
  naam: Yup.string().default(""),
  geboortedatum: nullableLocalDateSchema,
  burgerlijkeStaat: yupEnum(BurgerlijkeStaatType).default(BurgerlijkeStaatType.Alleenstaand)
});
export type PensioenAanvragerType = InferType<typeof deelnemerSchema>;

export const pensioenregelingSchema = Yup.object({
  soortPensioenregeling: yupEnum(Pensioenregelingsoort)
    .default(Pensioenregelingsoort.Geen)
    .meta({ testdata: Pensioenregelingsoort }),
  eindloonregelingPercentage: Yup.number()
    .nullable()
    .default(null),
  middelloonregelingPercentage: Yup.number()
    .nullable()
    .default(null),
  beschikbarePremie: Yup.number()
    .nullable()
    .default(null),
  eindloonregelingPercentageWettelijk: Yup.number()
    .nullable()
    .default(null),
  middelloonregelingPercentageWettelijk: Yup.number()
    .nullable()
    .default(null)
});
export type PensioenregelingType = InferType<typeof pensioenregelingSchema>;

export const pensioengrondslagSchema = Yup.object({
  pensioengevendSalaris: berekenInputSchema,
  franchise: berekenInputSchema,
  pensioengrondslag: berekenInputSchema,
  pensioenaangroei: berekenInputSchema
    .default(berekenInputSchema.default())
    .test("max-check", "", function(): true | Yup.ValidationError {
      const valid =
        this.parent.pensioenaangroei.bedrag <= this.parent.pensioenaangroei.berekendBedrag ||
        this.parent.pensioenaangroei.bedrag === undefined ||
        this.parent.pensioenaangroei.bedrag === null;

      if (valid) {
        return true;
      }

      let message = "";

      if (this.parent.selectedPensioenregeling === Pensioenregelingsoort.Middelloon) {
        message = getPensioenTextResources("validatiePensioenaangroei")
          .replace("-replacePercentage-", `${decimalFormat(this.parent.middelloonregelingPercentage, 3)}`)
          .replace("-replaceIngevuldBedrag-", `${bedragFormat(this.parent.pensioengrondslag.bedrag, 0)}`)
          .replace("-replaceBerekendBedrag-", `${bedragFormat(this.parent.pensioenaangroei.berekendBedrag, 0)}`);
      }
      if (this.parent.selectedPensioenregeling === Pensioenregelingsoort.Eindloon) {
        message = getPensioenTextResources("validatiePensioenaangroei")
          .replace("-replacePercentage-", `${decimalFormat(this.parent.eindloonregelingPercentage, 3)}`)
          .replace("-replaceIngevuldBedrag-", `${bedragFormat(this.parent.pensioengrondslag.bedrag, 0)}`)
          .replace("-replaceBerekendBedrag-", `${bedragFormat(this.parent.pensioenaangroei.berekendBedrag, 0)}`);
      }
      return this.createError({
        message
      });
    }),
  huidigOpgebouwdBedrag: Yup.number()
    .nullable()
    .default(null),
  datumOverzicht: nullableLocalDateSchema.test({
    message: getPensioenTextResources("DatumIsInToekomst"),
    test: function(val: LocalDate): boolean {
      return val ? val <= LocalDate.now() : true;
    }
  }),
  rekenrenteOpbouwfase: Yup.number()
    .nullable()
    .default(null),
  rekenrenteUitkeringfase: Yup.number()
    .nullable()
    .default(null),
  doelvermogen: Yup.number()
    .nullable()
    .default(null)
    .test({
      message: getPensioenTextResources("validatieBedragIsRequired"),
      test: function(val: number): boolean {
        return this.parent.selectedPensioenregeling === Pensioenregelingsoort.BeschikbarePremie &&
          this.parent.huidigeWerkgever
          ? !!val
          : true;
      }
    }),
  selectedPensioenregeling: yupEnum(Pensioenregelingsoort)
    .default(Pensioenregelingsoort.Geen)
    .meta({ testdata: Pensioenregelingsoort }),
  eindloonregelingPercentage: Yup.number()
    .nullable()
    .default(null),
  middelloonregelingPercentage: Yup.number()
    .nullable()
    .default(null),
  huidigeWerkgever: Yup.boolean().default(true)
});
export type PensioengrondslagType = InferType<typeof pensioengrondslagSchema>;

export const werknemersbijdragenSchema = Yup.object({
  bijdragePrePensioen: Yup.number()
    .nullable()
    .default(null),
  bijdrageOuderdomspensioen: Yup.number()
    .nullable()
    .default(null)
});
export type WerknemersbijdragenType = InferType<typeof werknemersbijdragenSchema>;

export const pensioentoezeggingenSchema = Yup.object({
  ouderdomspensioenIsEnabled: Yup.boolean().default(false),
  ouderdomspensioenBedrag: Yup.number()
    .nullable()
    .default(null)
    .test({
      message: getPensioenTextResources("validatieBedragIsRequired"),
      test: function(val: number | null): boolean {
        if (this.parent.ouderdomspensioenIsEnabled && val === null) {
          return false;
        }
        return true;
      }
    }),
  ouderdomspensioenModal: ouderdomspensioenModalSchema,
  // Bij refactor pensioenscherm het schema voor ouderdomspensioen en prepensioen fixen, ik heb het idee dat ie
  // nooit voorbij de 3e if komt.
  ouderdomspensioenIngangsdatum: nullableLocalDateSchema.test({
    message: getPensioenTextResources("validatieIngangsleeftijdOuderdomspensioenIngangsdatum"),
    test: function(val: LocalDate | null | undefined): boolean {
      if (this.parent.ouderdomspensioenIsEnabled && !val) {
        return false;
      }

      if (!this.parent.ouderdomspensioenIsEnabled) {
        return true;
      }
      if (this.parent.ouderdomspensioenLeeftijd.jaren >= 60 && this.parent.ouderdomspensioenLeeftijd.jaren <= 99) {
        return true;
      }

      const minDagen = 21914;
      const maxDagen = 36524;
      const dagen = this.parent.geboortedatum.until(val, ChronoUnit.DAYS);
      return dagen >= minDagen && dagen <= maxDagen;
    }
  }),
  ouderdomspensioenLeeftijd: Yup.object({
    jaren: Yup.number()
      .nullable()
      .default(null)
      .test({
        message: getPensioenTextResources("validatieIngangsleeftijdOuderdomspensioenLeeftijd"),
        test: function(val: number): boolean {
          if (this.parent.ouderdomspensioenIsEnabled && val === null) {
            return false;
          }
          return val ? val >= 60 && val <= 99 : true;
        }
      }),
    maanden: Yup.number()
      .lessThan(12, getPensioenTextResources("validatieMaanden"))
      .nullable()
      .default(null)
  }),
  ouderdomspensioenIngangsdag: Yup.number()
    .nullable()
    .default(null),
  prePensioenIsEnabled: Yup.boolean().default(false),
  prePensioenBedrag: Yup.number()
    .nullable()
    .default(null)
    .test({
      message: getPensioenTextResources("validatieprePensioenBedragIsRequired"),
      test: function(val: number | null): boolean {
        if (this.parent.prePensioenIsEnabled && val === null) {
          return false;
        }
        return true;
      }
    }),

  prePensioenIngangsdatum: nullableLocalDateSchema.test({
    message: getPensioenTextResources("validatieIngangsleeftijdPrepensioenIngangsdatum"),
    test: function(val: LocalDate | null | undefined): boolean {
      if (this.parent.prePensioenIsEnabled && !val) {
        return false;
      }

      if (!this.parent.prePensioenIsEnabled) {
        return true;
      }
      if (this.parent.prePensioenLeeftijd.jaren >= 40 && this.parent.prePensioenLeeftijd.jaren <= 99) {
        return true;
      }

      const minDagen = 14609;
      const maxDagen = 36524;
      function dateDiffInDays(geb: LocalDate | null | undefined): number {
        if (!geb || !val) return 0;

        return geb.until(val, ChronoUnit.DAYS);
      }
      const dagen = dateDiffInDays(this.parent.geboortedatum);
      return dagen >= minDagen && dagen <= maxDagen;
    }
  }),
  prePensioenLeeftijd: Yup.object({
    jaren: Yup.number()
      .default(null)
      .nullable()
      .test({
        message: getPensioenTextResources("validatieIngangsleeftijdPrepensioenLeeftijd"),
        test: function(val: number): boolean {
          if (this.parent.prePensioenIsEnabled && val === null) {
            return false;
          }
          return val ? val >= 40 && val <= 99 : true;
        }
      }),
    maanden: Yup.number()
      .lessThan(12, getPensioenTextResources("validatieMaanden"))
      .nullable()
      .default(null)
  }),
  prePensioenIngangsdag: Yup.number()
    .nullable()
    .default(null),
  nabestaandenpensioenTotAowOverlijdenVoorAowIsEnabled: Yup.boolean().default(false),
  nabestaandenpensioenTotAowOverlijdenVoorAowBedrag: Yup.number()
    .nullable()
    .default(null)
    .test({
      message: getPensioenTextResources("validatieBedragIsRequired"),
      test: function(val: number | null): boolean {
        if (this.parent.nabestaandenpensioenTotAowOverlijdenVoorAowIsEnabled && val === null) {
          return false;
        }
        return true;
      }
    }),
  nabestaandenpensioenTotAowOverlijdenVoorAowPercentage: Yup.number()
    .nullable()
    .default(null),
  nabestaandenpensioenNaAowOverlijdenVoorAowIsEnabled: Yup.boolean().default(false),
  nabestaandenpensioenNaAowOverlijdenVoorAowBedrag: Yup.number()
    .default(null)
    .nullable()
    .test({
      message: getPensioenTextResources("validatieBedragIsRequired"),
      test: function(val: number | null): boolean {
        if (this.parent.nabestaandenpensioenNaAowOverlijdenVoorAowIsEnabled && val === null) {
          return false;
        }
        return true;
      }
    }),
  nabestaandenpensioenNaAowOverlijdenVoorAowPercentage: Yup.number()
    .nullable()
    .default(null),
  nabestaandenpensioenTotAowOverlijdenNaAowIsEnabled: Yup.boolean().default(false),
  nabestaandenpensioenTotAowOverlijdenNaAowBedrag: Yup.number()
    .default(null)
    .nullable()
    .test({
      message: getPensioenTextResources("validatieBedragIsRequired"),
      test: function(val: number | null): boolean {
        if (this.parent.nabestaandenpensioenTotAowOverlijdenNaAowIsEnabled && val === null) {
          return false;
        }
        return true;
      }
    }),
  nabestaandenpensioenTotAowOverlijdenNaAowPercentage: Yup.number()
    .nullable()
    .default(null),
  nabestaandenpensioenNaAowOverlijdenNaAowIsEnabled: Yup.boolean().default(false),
  nabestaandenpensioenNaAowOverlijdenNaAowBedrag: Yup.number()
    .default(null)
    .nullable()
    .test({
      message: getPensioenTextResources("validatieBedragIsRequired"),
      test: function(val: number | null): boolean {
        if (this.parent.nabestaandenpensioenNaAowOverlijdenNaAowIsEnabled && val === null) {
          return false;
        }
        return true;
      }
    }),
  nabestaandenpensioenNaAowOverlijdenNaAowPercentage: Yup.number()
    .nullable()
    .default(null),
  anwCompensatieIsEnabled: Yup.boolean().default(false),
  anwCompensatie: Yup.number()
    .default(null)
    .nullable()
    .test({
      message: getPensioenTextResources("validatieBedragIsRequired"),
      test: function(val: number | null): boolean {
        if (this.parent.anwCompensatieIsEnabled && val === null) {
          return false;
        }
        return true;
      }
    }),
  wezenpensioenIsEnabled: Yup.boolean().default(false),
  wezenpensioenBedrag: Yup.number()
    .default(null)
    .nullable()
    .test({
      message: getPensioenTextResources("validatieBedragIsRequired"),
      test: function(val: number | null): boolean {
        if (this.parent.wezenpensioenIsEnabled && val === null) {
          return false;
        }
        return true;
      }
    }),
  wezenpensioenPercentage: Yup.number()
    .nullable()
    .default(null),
  wezenpensioenEindleeftijd: Yup.number()
    .default(null)
    .nullable()
    .test({
      message: getPensioenTextResources("validatieEindleeftijdWezenpensioen"),
      test: function(val: number): boolean {
        if (this.parent.wezenpensioenIsEnabled && val === null) {
          return false;
        }
        return val ? val >= 10 && val <= 30 : true;
      }
    }),
  invaliditeitspensioenBedrag: Yup.number()
    .default(null)
    .nullable()
    .test({
      message: getPensioenTextResources("validatieBedragIsRequired"),
      test: function(val: number | null): boolean {
        if (this.parent.invaliditeitspensioenIsEnabled && val === null) {
          return false;
        }
        return true;
      }
    }),
  invaliditeitspensioenIsEnabled: Yup.boolean().default(false),
  invaliditeitspensioenPercentage: Yup.number()
    .nullable()
    .default(70),
  invaliditeitspensioenWijze: yupEnum(AoUitkeringswijze)
    .default(AoUitkeringswijze.Volledig)
    .meta({ testdata: AoUitkeringswijze }),
  invaliditeitspensioenModal: specificatieAoUitkeringModalSchema,
  geboortedatum: nullableLocalDateSchema,
  pensioengevendSalaris: Yup.number()
    .nullable()
    .default(null)
});
export type PensioentoezeggingenType = InferType<typeof pensioentoezeggingenSchema>;
export const pensioenSchema = Yup.object({
  id: Yup.string()
    .nullable()
    .default(null),
  loondienstId: Yup.string()
    .nullable()
    .default(null),
  selectedDeelnemer: Yup.string().default(""),
  werkgever: werkgeverSchema,
  pensioenuitvoerder: pensioenuitvoerderSchema,
  pensioenregeling: pensioenregelingSchema,
  pensioengrondslag: pensioengrondslagSchema,
  werknemersbijdragen: werknemersbijdragenSchema,
  pensioentoezeggingen: pensioentoezeggingenSchema
});
export type PensioenState = InferType<typeof pensioenSchema>;

export const deelnemersSchema = Yup.array(deelnemerSchema).default([deelnemerSchema.default()]);

export const pensioenenSchema = Yup.object({
  pensioenen: Yup.array(pensioenSchema).default([pensioenSchema.default()]),
  deelnemers: deelnemersSchema
});
export type PensioenenState = InferType<typeof pensioenenSchema>;
export type PensioenenProps = InferType<typeof pensioenenSchema>;
export type PensioenDeelnemerType = InferType<typeof deelnemersSchema>;
