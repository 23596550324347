import { AdviesBoxFormik, LabeledSelectInput, VoorstelKeuzeType } from "adviesbox-shared";
import { useFormikContext } from "formik";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import { AppDataContext } from "../navigation/appdata-context";
import { SnelInzichtProduct } from "../.generated/rapportage/rapportagetypes";
import Modal from "../shared/components/modal/Modal";
import { MaandlastenOverzichtPdf } from "./maandlast-overzicht/maandlast-overzicht";
import { MaandlastOverzichtProducten } from "./maandlast-overzicht/maandlast-overzicht-producten";

const snelInzichtSchema = Yup.object({
  producten: Yup.mixed<SnelInzichtProduct[]>().default([]),
  voorstelKeuze: Yup.string()
});

export type SnelInzichtModalState = Yup.InferType<typeof snelInzichtSchema>;

type SnelInzichtModalProps = { closeModal?: () => void };

const Body = (): ReactElement => {
  const { values, setFieldValue } = useFormikContext<SnelInzichtModalState>();
  const { voorstellen } = useContext(AppDataContext);
  const [productenAanwezig, setProductenAanwezig] = useState(false);
  const voorstelKeuze = React.useMemo<VoorstelKeuzeType[]>(
    () =>
      (voorstellen || []).concat([
        {
          naam: "Huidige situatie",
          selected: false,
          voorstelId: "Huidig"
        }
      ]),
    [voorstellen]
  );
  const pdfButtonState = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_showPdfButton, setShowPdfButton] = pdfButtonState;

  useEffect(() => {
    if (!values.voorstelKeuze && !voorstellen.length) {
      setFieldValue("voorstelKeuze", "Huidig");
    }
    if (!values.voorstelKeuze && voorstellen.length) {
      setFieldValue("voorstelKeuze", voorstellen[0].voorstelId);
    }
  }, [setFieldValue, voorstellen, values.voorstelKeuze]);

  const geselecteerdVoorstelKeuze = voorstelKeuze.find(c => c.voorstelId === values.voorstelKeuze);

  return (
    <>
      <LabeledSelectInput
        caption={"Maandlastenoverzicht van"}
        name={"voorstelKeuze"}
        options={voorstelKeuze.map(c => ({
          label: c.naam,
          value: c.voorstelId
        }))}
        onChange={
          /* istanbul ignore next */ event => {
            setFieldValue("voorstelKeuze", event.target.value);
            setShowPdfButton(true);
            event.preventDefault();
            event.stopPropagation();
          }
        }
      />
      <h2>Producten aanwezig in {`${geselecteerdVoorstelKeuze?.naam}`}</h2>
      <MaandlastOverzichtProducten productenAanwezigCallback={setProductenAanwezig} />

      <h2>Maandlastenoverzicht {`${geselecteerdVoorstelKeuze?.naam}`}</h2>

      <MaandlastenOverzichtPdf
        voorstelId={values.voorstelKeuze === "Huidig" ? null : values.voorstelKeuze}
        productenAanwezig={productenAanwezig}
        pdfButtonState={pdfButtonState}
      />
    </>
  );
};

export const SnelInzichtModal = ({ closeModal }: SnelInzichtModalProps): ReactElement => {
  return (
    <AdviesBoxFormik<SnelInzichtModalState>
      initialValues={{
        ...snelInzichtSchema.default()
      }}
      validationSchema={snelInzichtSchema}
      closeModal={closeModal}
      render={(): ReactElement => (
        <Modal title="Snel inzicht" body={<Body />} noEditableData onCancelClick={closeModal} />
      )}
    />
  );
};
