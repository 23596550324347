import { DekkingProps } from "./dekking";
import React, { ReactElement } from "react";
import { LabeledBevestigingInput } from "adviesbox-shared";
import { getAovTextResources } from "../infra/aov-resources";
import { UitkeringGedeeltelijkeAo } from "./uitkering-gedeeltelijke-ao";
import { bedragFormat } from "../../shared/utils/currency";

export const DekkingWgaGat = ({ selected, kenmerken, verzekerde, tarieven }: DekkingProps): ReactElement => {
  return (
    <>
      <UitkeringGedeeltelijkeAo kenmerken={kenmerken} selected={selected} verzekerde={verzekerde} />
      <LabeledBevestigingInput
        caption="Uitgebreid"
        name={`producten[${selected}].dekking.dekkingGegevensVerzekerde${verzekerde}.uitgebreid`}
        tooltip={getAovTextResources("uitgebreidToolTip").replace(
          "{tariefMaxDagInkomen}",
          tarieven && tarieven.sociaal ? bedragFormat(tarieven.sociaal.bedragMaxSvwDagInkomen) : "onbekend"
        )}
      />
    </>
  );
};
