import { LabeledPostcodeInput, LabeledRadioInput, LabeledSelectInput, LabeledTextInput } from "adviesbox-shared";
import { connect, FormikContextType } from "formik";
import React, { ReactElement } from "react";
import { TaxatieContactPersoonOptions, GeslachtOpties } from "../../.generated/forms/formstypes";
import { Direction } from "../../shared/types";
import { TaxatieKenmerkenType, TaxatieSchermType } from "../infra/taxatie-scherm-schema";
import { FitrexCode, CalcasaCode } from "../infra/taxatie-bureaus-helper";

const Contactpersoon = ({
  formik: {
    values: { taxatieKenmerken, taxatiebureau }
  }
}: {
  formik: FormikContextType<TaxatieSchermType>;
}): ReactElement => {
  const kenmerken = taxatieKenmerken as TaxatieKenmerkenType;
  const typeContactpersoonTonen = kenmerken?.ContactpersoonTypeTonen;
  const naamMakelaarTonen = kenmerken?.MakelaarNaamTonen;
  const woonplaatsTonen = kenmerken?.ContactpersoonPlaatsTonen;
  const adresTonen = kenmerken?.ContactpersoonAdresTonen;
  const telefoonWerkTonen = kenmerken?.ContactpersoonTelefoonWerkTonen;
  const telefoonMobielTonen = kenmerken?.ContactpersoonTelefoonMobielTonen;
  const contactpersoonGeslachtTonen = kenmerken?.ContactpersoonGeslachtTonen;
  const telefoonPriveTonen = kenmerken?.ContactpersoonTelefoonPriveTonen;

  return (
    <>
      {typeContactpersoonTonen && (
        <LabeledSelectInput
          caption="Type contactpersoon"
          name="contactpersoon.typeContactpersoon"
          options={[
            { label: "Makelaar", value: TaxatieContactPersoonOptions.Makelaar },
            { label: "Eigenaar", value: TaxatieContactPersoonOptions.Eigenaar },
            { label: "Opdrachtgever", value: TaxatieContactPersoonOptions.Opdrachtgever },
            { label: "Anders", value: TaxatieContactPersoonOptions.Anders }
          ]}
        />
      )}
      {naamMakelaarTonen && <LabeledTextInput caption="Naam makelaar" name="contactpersoon.naamMakelaar" />}
      <LabeledTextInput caption="Contactpersoon naam" name="contactpersoon.naam" />
      {contactpersoonGeslachtTonen && (
        <LabeledRadioInput
          name="contactpersoon.geslacht"
          caption="Contactpersoon geslacht"
          layout={Direction.Vertical}
          options={[
            { label: "Man", value: GeslachtOpties.Man },
            { label: "Vrouw", value: GeslachtOpties.Vrouw }
          ]}
        />
      )}
      {adresTonen && <LabeledPostcodeInput caption="Postcode" name="contactpersoon.postcode" />}

      {woonplaatsTonen && <LabeledTextInput caption="Woonplaats" name="contactpersoon.woonplaats" />}
      {telefoonPriveTonen && <LabeledTextInput caption="Telefoonnummer (privé)" name="contactpersoon.telefoonPrive" />}
      {telefoonMobielTonen && (
        <LabeledTextInput caption="Telefoonnummer (mobiel)" name="contactpersoon.telefoonMobiel" />
      )}
      {telefoonWerkTonen && <LabeledTextInput caption="Telefoonnummer (werk)" name="contactpersoon.telefoonWerk" />}
      {(taxatiebureau?.maatschappijCode === FitrexCode || taxatiebureau?.maatschappijCode === CalcasaCode ) && <LabeledTextInput caption="Email-adres" name="contactpersoon.email" />}

    </>
  );
};

export default connect<{}, TaxatieSchermType>(Contactpersoon);
