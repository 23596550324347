import { logErrorToApplicationInsights } from "adviesbox-shared";
import { Dashboard } from "../../.generated/forms/formstypes";
import { hasJSONResponse } from "../../shared/utils/ajax";

export type OnderwerpenType = {
  overlijden: boolean;
  arbeidsongeschiktheid: boolean;
  werkloosheid: boolean;
  pensioen: boolean;
};
export const dashboardInit = async (
  url: string,
  request: RequestInit
): Promise<OnderwerpenType | null | string> => {
  let fouttekst = null;
  try {
    const rspDashboards = await fetch(url, {
      ...request
    });

    const jsonResult = await rspDashboards.json();
    if (rspDashboards.ok && hasJSONResponse(rspDashboards)) {
      return jsonResult.onderwerpen;
    }

    if (!(rspDashboards.ok && hasJSONResponse(rspDashboards))) {

      fouttekst = "Er is een fout opgetreden bij het ophalen van de dashboard gegevens.";
    }
  } catch (error) {

    fouttekst = `Er is een fout opgetreden bij het ophalen van de dashboard gegevens. ${error}`;
  }
  if (fouttekst) logErrorToApplicationInsights(Error(`Fout bij aanroep van web-api '${url}'. ${fouttekst}`));

  return fouttekst;
};

export const berekenScenarios = async (urlScenarios: string, request: RequestInit): Promise<Dashboard[] | string> => {
  try {
    const rspDashboards = await fetch(urlScenarios, {
      ...request
    });

    if (!(rspDashboards.ok && hasJSONResponse(rspDashboards))) {
      logErrorToApplicationInsights(Error(`Fout bij aanroep van web-api '${urlScenarios}'.`));

      const error = `Fout bij aanroep van web-api '${urlScenarios}'. Resultaat bevat geen JSON.`;
      return error;
    }

    const jsonDashboards = await rspDashboards.json();
    if (!jsonDashboards.isValid) {
      logErrorToApplicationInsights(Error(`Fout bij aanroep van web-api '${urlScenarios}'.`));

      const error = `Fout bij aanroep van web-api '${urlScenarios}'. Input is niet valid`;
      return error;
    }

    return Object.values(jsonDashboards.dashboards);
  } catch (error) {
    logErrorToApplicationInsights(Error(`Fout bij aanroep van web-api '${urlScenarios}'.`));

    const retError = `Fout bij aanroep van web-api '${urlScenarios}, ${error}'. `;
    return retError;
  }
};
