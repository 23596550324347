import { Card, CardWrapper, ErrorPage, PageLoading } from "adviesbox-shared";
import { useFormikContext } from "formik";
import React, { ReactElement, useContext } from "react";
import { AppDataContext } from "../../navigation/appdata-context";
import { AovVerzekerdeDekkingenOptions } from "../../.generated/forms/formstypes";
import { Algemeen } from "../../.generated/tarieven/tarieventypes";
import { AovKenmerken, isKenmerkError, KenmerkenError } from "../../producten-overzicht/infra/product-kenmerken-types";
import { ProductkenmerkenContext } from "../../producten-overzicht/infra/producten-context";
import { SituatieSoort } from "../../producten-overzicht/infra/producten-overzicht-types";
import Product from "../../producten-overzicht/product/product";
import Verpanding from "../../producten-overzicht/verpanding/verpanding";
import Verzekerden from "../../producten-overzicht/verzekerden/verzekerden";
import { ISWSideEffects } from "../../shared/components/isw-side-effects/isw-side-effects";
import { AanvragerKeuze } from "../../shared/types";
import { getNaam } from "../../shared/utils/helpers";
import { AanvullendeVragen } from "../aanvullende-vragen/aanvullende-vragen";
import { Dekking } from "../dekking/dekking";
import { InformatieVoorAanvraag } from "../informatie-voor-aanvraag/informatie-voor-aanvraag";
import { AovsType, PartijProduct } from "../infra/aov-schema";
import { asyncAovSideEffects } from "../infra/determine-async-aov-side-effects";
import { syncAovSideEffects } from "../infra/determine-sync-aov-side-effects";
import { mapPartijProduct } from "../infra/map-aov-dl-to-ui";
import { Premiegegevens } from "../premiegegevens/premiegegevens";
import { PremiegegevensDazure } from "../premiegegevens/premiegegevens-dazure-inkomensbeschermer";

type Props = { selected: number; situatie: SituatieSoort; tarieven?: Algemeen; kenmerken?: AovKenmerken | null };

export const AovDetails = ({ selected, situatie, tarieven }: Props): ReactElement => {
  const formik = useFormikContext<AovsType>();
  const { values } = formik;
  const kenmerkenContext = useContext(ProductkenmerkenContext);
  const { setSErunning } = useContext(AppDataContext);

  let kenmerken: AovKenmerken | null | KenmerkenError = null;
  const selectedProduct = values.producten && values.producten[selected] ? values.producten[selected] : null;

  if (!selectedProduct) {
    return <ErrorPage error={new Error(`Geselecteerde product is leeg`)} />;
  }

  kenmerken = kenmerkenContext.getProductKenmerken("Aov", selectedProduct.partijCode, selectedProduct.productCode);

  if (!kenmerken) return <PageLoading />;

  if (kenmerken && isKenmerkError(kenmerken)) {
    return <ErrorPage error={new Error(`Fout bij het ophalen van producteigenschappen: ${kenmerken.reden}`)} />;
  }

  const partijCode = selectedProduct.partijCode;
  const productCode = selectedProduct.productCode;
  const partijProduct: PartijProduct = mapPartijProduct(partijCode, productCode);

  const informatieVoorAanvraagTonen =
    values.producten[selected].dekking.dekkingGegevensVerzekerde1.dekking === AovVerzekerdeDekkingenOptions.AoWw &&
    (kenmerken.aanvraag.voorkennisOntslagTonen ||
      kenmerken.aanvraag.faillissementTonen ||
      kenmerken.aanvraag.reorganisatieTonen ||
      kenmerken.aanvraag.sprakeVanStrafrechtelijkVerledenTonen ||
      kenmerken.aanvraag.toelichtingGelezenAkkoordMetSlotverklaringTonen);

  return (
    <>
      <ISWSideEffects<AovsType>
        sync={syncAovSideEffects({
          selected: selected,
          kenmerken: kenmerken,
          soortProduct: selectedProduct.soortProduct
        })}
        async={asyncAovSideEffects({ selected, productkenmerken: kenmerken })}
        asyncStartStopCallback={setSErunning}
      />
      <CardWrapper maxRowCount={12} className="px-3 mb-5 pb-5">
        <Card
          title={
            situatie === "voorstel"
              ? `${selectedProduct.product.doorlopend ? "Doorlopend" : "Nieuw"} product`
              : "Huidig product"
          }
        >
          <Product
            selected={selected}
            situatie={situatie}
            kenmerken={kenmerken.product}
            productSoort="aov"
            isStarterslening={false}
          />
        </Card>
        <Card title="Verzekerde(n)">
          <Verzekerden
            selected={selected}
            kenmerken={{
              ...kenmerken.persoonkeuze,
              premiesplitsingTonen: false //Weghalen als kenmerk goed is gezet, anders werkt callas niet
            }}
          />
        </Card>

        <Card
          title={`Dekking ${
            values.producten[selected].verzekerden.verzekerden === AanvragerKeuze.Aanvrager2
              ? getNaam(values.aanvrager2, "Aanvrager 2")
              : getNaam(values.aanvrager1, "Aanvrager 1")
          }`}
        >
          <Dekking selected={selected} kenmerken={kenmerken.dekking} verzekerde={1} tarieven={tarieven} />
        </Card>
        {values.producten[selected].verzekerden.verzekerden === AanvragerKeuze.Beiden && (
          <div data-testid="Dekking-twee">
            <Card title={`Dekking ${getNaam(values.aanvrager2, "Aanvrager 2")}`}>
              <Dekking selected={selected} kenmerken={kenmerken.dekking} verzekerde={2} tarieven={tarieven} />
            </Card>
          </div>
        )}

        {partijProduct !== PartijProduct.DazureInkomensbeschermer && (
          <Card title="Premiegegevens">
            <Premiegegevens selected={selected} kenmerken={kenmerken.premie} dekkingKenmerken={kenmerken.dekking} />
          </Card>
        )}
        {partijProduct === PartijProduct.DazureInkomensbeschermer && (
          <Card title="Premiegegevens">
            <PremiegegevensDazure
              selected={selected}
              kenmerken={kenmerken.premie}
              dekkingKenmerken={kenmerken.dekking}
            />
          </Card>
        )}
        {(kenmerken.verpanding.bedoeldAflossingTonen ||
          kenmerken.verpanding.inkomensaanvullingTonen ||
          kenmerken.verpanding.lijfrenteUitkeringTonen ||
          kenmerken.verpanding.verpandingTonen) && (
          <Card title="Aflossing">
            <Verpanding
              selected={selected}
              kenmerken={kenmerken.verpanding}
              verpandAanGeldverstrekkerTonen={kenmerken.verpanding.verpandingTonen}
              waardeopbouwBedrag={null}
              showOnHavingItems={true}
            />
          </Card>
        )}

        {partijProduct === PartijProduct.CallasWoonlasten &&
          (values.producten[selected].dekking.dekkingGegevensVerzekerde1.dekking ===
            AovVerzekerdeDekkingenOptions.AoWw ||
            values.producten[selected].dekking.dekkingGegevensVerzekerde1.dekking ===
              AovVerzekerdeDekkingenOptions.Ww) && (
            <Card title="Aanvullende vragen WW">
              <AanvullendeVragen selected={selected} />
            </Card>
          )}
        {informatieVoorAanvraagTonen &&
          partijProduct !== PartijProduct.CallasWoonlasten &&
          partijProduct !== PartijProduct.CreditLifeWoonlastenOngevallen && (
            <Card title="Informatie voor aanvraag">
              <InformatieVoorAanvraag kenmerken={kenmerken.aanvraag} selected={selected} />
            </Card>
          )}
        {kenmerken.dekking.aanvullendeInformatie && (
          <Card title="Informatie">
            <div className="px-03">
              <p>{kenmerken.dekking.aanvullendeInformatie}</p>
            </div>
          </Card>
        )}

      </CardWrapper>
    </>
  );
};
