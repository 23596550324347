import { ChronoUnit } from "@js-joda/core";
import * as Yup from "yup";
import {
  Vermogen as VermogenDlEntry,
  SoortDepotOptions,
  VermogenExtraOpnameInJaar,
  VermogenFiscaleRegeling,
  VermogenInleggegevens,
  VermogenKapitaalopbouw,
  VermogenOutput as VermogenDlOutput,
  VermogenProduct,
  WaardeopbouwInJaar
} from "../../.generated/forms/formstypes";
import { VermogensrekeningInput } from "../../.generated/vermogen/vermogentypes";
import { mapProductDl2Ui } from "../../producten-overzicht/infra/product-mapper-dl-2-ui";
import {
  kapitaalopbouwSchema,
  verzekeringNemersSchema
} from "../../producten-overzicht/infra/producten-overzicht-schema";
import { mapVerpandingDl2Ui } from "../../producten-overzicht/infra/verpanding-mapper-dl-2-ui";
import { mapJaarMaandInputFromLooptijdDl2Ui } from "../../shared/generic-parts/jaar-maand/map-dl-2-ui";
import { mapKlantenNaarAanvragerKeuze, mapKlantnaamDl2Ui } from "../../shared/generic-parts/klantnaam/map-dl-2-ui";
import { klantnaamSchema, KlantnaamType } from "../../shared/generic-parts/klantnaam/schema";
import { mapKredietenDl2Ui } from "../../shared/generic-parts/krediet/map-dl-2-ui";
import { mapVemogenLeningdelenDl2Ui } from "../../shared/generic-parts/leningdeel/map-dl-2-ui";
import { FieldMap, UiName } from "../../shared/types";
import { createMapToUi } from "../../shared/utils/create-map-to-ui";
import { afronden } from "../../shared/utils/currency";
import { prefixWithZero } from "../../shared/utils/helpers";
import { target2field } from "../../shared/utils/target-to-field";
import {
  depotSchema,
  extraOpnameSchema,
  extraOpnamesSchema,
  inleggegevensSchema,
  onttrekkingenSchema,
  vermogenFiscaleRegelingSchema,
  vermogenSchema,
  vermogensSchema,
  waardeopbouwJaarSchema
} from "./vermogen-schema";
import { VermogensType } from "./vermogen-types";
import { mapStringToLocalDate } from "adviesbox-shared";

const aanvragerContextSchema = Yup.object({
  aanvrager1: klantnaamSchema.nullable(),
  aanvrager2: klantnaamSchema.nullable().default(null)
});
type AanvragerContextType = Yup.InferType<typeof aanvragerContextSchema>;

const mapContext = createMapToUi(aanvragerContextSchema).from<VermogenDlEntry>({
  aanvrager1: v => mapKlantnaamDl2Ui(v.aanvrager1),
  aanvrager2: v => (v.aanvrager2 ? mapKlantnaamDl2Ui(v.aanvrager2) : null)
});

const mapFiscaleRegelingDl2Ui = createMapToUi(vermogenFiscaleRegelingSchema).from<VermogenFiscaleRegeling>({
  polisnummer: v => v.polisnummer,
  originelePolisnummer: v => v.originelePolisnummer,
  kapitaalopbouw: v => v.kapitaalopbouw,
  doelkapitaalBedrag: v => v.doelkapitaalBedrag,
  eerdereUitkeringenBedrag: v => v.eerdereUitkeringenBedrag,
  einddatum: v => mapStringToLocalDate(v.einddatum),
  externeMaatschappijCode: v => v.externeMaatschappijCode,
  externeMaatschappijOmschrijving: v => v.externeMaatschappijOmschrijving,
  fiscaalRegime: v => v.fiscaalRegime,
  fiscaleTypering: v => v.fiscaleTypering,
  fiscaleVoortzetting: v => v.fiscaleVoortzetting,
  garantieverzekering: v => v.garantieverzekering,
  hoogstePremieOoitBedrag: v => v.hoogstePremieOoitBedrag,
  huidigeJaarPremieBedrag: v => v.huidigeJaarPremieBedrag,
  ingangsdatumBox1: v => mapStringToLocalDate(v.ingangsdatumBox1),
  ingebrachteWaardeBedrag: v => v.ingebrachteWaardeBedrag,
  laagstePremieooitBedrag: v => v.laagstePremieooitBedrag,
  lijfrenteclausule: v => v.lijfrenteclausule,
  lijfrenteclausuleOrigineel: v => v.lijfrenteclausuleOrigineel,
  oorspronkelijkeIngangsdatum: v => mapStringToLocalDate(v.oorspronkelijkeIngangsdatum),
  premieLopendJaarBedrag: v => v.premieLopendJaarBedrag,
  productId: v => v.productId
});

const mapVerzekeringnemersDl2Ui = createMapToUi(verzekeringNemersSchema)
  .with<{ aanvrager1: KlantnaamType | null; aanvrager2: KlantnaamType | null }>()
  .from<VermogenProduct>({
    verzekeringnemers: (v, { aanvrager1, aanvrager2 }) =>
      mapKlantenNaarAanvragerKeuze(v.contractantKlantIds, aanvrager1, aanvrager2)
  });

const mapWaardeopbouwJaarDl2Ui = createMapToUi(waardeopbouwJaarSchema).from<WaardeopbouwInJaar>({
  jaar: v => v.jaar,
  beginwaardeBedrag: v => afronden(v.beginwaardeBedrag, 2),
  stortingenBedrag: v => afronden(v.stortingenBedrag, 2),
  onttrekkingenBedrag: v => afronden(v.onttrekkingenBedrag, 2),
  eindwaardeBedrag: v => afronden(v.eindwaardeBedrag, 2)
});

const mapDepotDl2Ui = createMapToUi(depotSchema).from<VermogenProduct>({
  soortDepot: v => v.depot?.soortDepot ?? SoortDepotOptions.OpbouwDepot,
  bestedingsdoel: v => v.depot?.bestedingsdoel,
  aankoopkostenPercentage: v => v.depot?.aankoopkostenPercentage,
  verkoopkostenPercentage: v => v.depot?.verkoopkostenPercentage,
  beheerkostenPercentage: v => v.depot?.beheerkostenPercentage,
  waardeopbouwBedrag: v => v.productwaarde?.waardeopbouwBedrag,
  waardeopbouwNa5Jaar: v => {
    let maand = 61;
    if (v.polis?.ingangsdatum && v.productwaarde?.reedsOpgebouwdDatum) {
      maand += mapStringToLocalDate(v.polis?.ingangsdatum).until(
        mapStringToLocalDate(v.productwaarde?.reedsOpgebouwdDatum),
        ChronoUnit.MONTHS
      );
    }
    return v.productwaarde?.waardeopbouwMaanden?.find(x => x.maand === maand)?.eindwaardeBedrag;
  },
  waardeopbouwMaanden: v => v.productwaarde?.waardeopbouwMaanden,
  waardeopbouwJaren: v => v.productwaarde?.waardeopbouwJaren?.map(mapWaardeopbouwJaarDl2Ui),
  reedsOpgebouwdBedrag: v => v.productwaarde?.reedsOpgebouwdBedrag,
  reedsOpgebouwdDatum: v => mapStringToLocalDate(v.productwaarde?.reedsOpgebouwdDatum),
  afkoopwaardeBedrag: v => v.productwaarde?.afkoopwaardeBedrag,
  afkoopwaardeDatum: v => mapStringToLocalDate(v.productwaarde?.afkoopwaardeDatum)
});

const mapKapitaalopbouwDl2Ui = createMapToUi(kapitaalopbouwSchema).from<VermogenKapitaalopbouw>({
  soortRekening: v => v.soortRekening ?? null,
  soortBerekening: v => v.soortBerekening ?? null,
  beleggersprofiel: v => v.beleggersprofiel ?? null,
  doelkapitaalBedrag: v => v.doelkapitaalBedrag ?? null,
  doelrendementPercentage: v => v.doelrendementPercentage ?? null,
  voorbeeldkapitaalBedrag: v => v.voorbeeldkapitaalBedrag ?? null,
  voorbeeldrendementPercentage: v => v.voorbeeldrendementPercentage ?? null,
  garantiekapitaalBedrag: v => v.garantiekapitaalBedrag ?? null,
  garantierendementPercentage: v => v.garantierendementPercentage ?? null,
  bedoeldVoorAflossing: () => false
});

const mapInleggegevensDl2Ui = createMapToUi(inleggegevensSchema).from<VermogenInleggegevens>({
  duur: v => mapJaarMaandInputFromLooptijdDl2Ui(v.duurInMaanden),
  duurHoogLaag: v => mapJaarMaandInputFromLooptijdDl2Ui(v.duurHooglaagInMaanden),
  stortingstermijn: v => v.stortingstermijn,
  inleg: v => v.inlegBedrag,
  aftrekbaar: v => v.premieAftrekbaarBedrag,
  inlegHoog: v => v.hogeInlegBedrag,
  eersteInleg: v => v.eersteInlegBedrag,
  extraInlegJaren: v => ({ scenario: v?.extraInlegJaren?.map(inleg => ({ bedrag: inleg.bedrag })) ?? [] })
});

const mapExtraOpnameDl2Ui = createMapToUi(extraOpnameSchema).from<VermogenExtraOpnameInJaar>({
  jaar: v => v.jaar,
  extraInlegBedrag: v => null,
  voorbeeldBedrag: v => 0,
  maxOpnameBedrag: v => null,
  opnameBedrag: v => v.opnameBedrag
});

const mapExtraOpnamesDl2Ui = createMapToUi(extraOpnamesSchema).from<VermogenProduct>({
  scenario: v => {
    const extraOpnames = v.onttrekkingen?.extraOpnameJaren
      ? v.onttrekkingen.extraOpnameJaren.map(opname => mapExtraOpnameDl2Ui(opname))
      : [];

    v.inleggegevens?.extraInlegJaren &&
      v.inleggegevens.extraInlegJaren.forEach(inleg => {
        let opname = extraOpnames.find(opname => opname.jaar === inleg.jaar);
        if (!opname) {
          opname = extraOpnameSchema.default();
          opname.jaar = inleg.jaar;
          extraOpnames.push(opname);
        }
        opname.extraInlegBedrag = inleg.bedrag;
      });

    if (extraOpnames.length) {
      const maxJaar = extraOpnames
        .map(opname => opname.jaar)
        .reduce((max, current) => Math.max(max, current), Number.MIN_VALUE);

      for (let jaar = 1; jaar < maxJaar; jaar++) {
        let opname = extraOpnames.find(opname => opname.jaar === jaar);
        if (!opname) {
          opname = extraOpnameSchema.default();
          opname.jaar = jaar;
          extraOpnames.push(opname);
        }
      }

      extraOpnames.sort((x, y) => x.jaar - y.jaar);
    }
    return extraOpnames;
  }
});

const mapOnttrekkingenDl2Ui = createMapToUi(onttrekkingenSchema).from<VermogenProduct>({
  onttrekkingenAanwezig: v => true,
  begindatum: v => mapStringToLocalDate(v.onttrekkingen?.begindatum),
  duur: v => mapJaarMaandInputFromLooptijdDl2Ui(v.onttrekkingen?.duurInMaanden),
  einddatum: v =>
    v.onttrekkingen?.begindatum
      ? mapStringToLocalDate(v.onttrekkingen.begindatum).plusMonths(v.onttrekkingen?.duurInMaanden ?? 0)
      : null,
  onttrekkingstermijn: v => v.onttrekkingen?.onttrekkingstermijn,
  onttrekkingBedrag: v => v.onttrekkingen?.onttrekkingBedrag,
  extraOpnames: v => mapExtraOpnamesDl2Ui(v)
});

const mapVermogenDl2Ui = createMapToUi(vermogenSchema)
  .with<AanvragerContextType>()
  .from<VermogenProduct>({
    productId: v => v.productId,
    partijCode: v => v.polis.maatschappijCode ?? "",
    productCode: v => prefixWithZero(v.polis.productcode),
    soortProduct: v => v.soortProduct,
    product: v => {
      const result = mapProductDl2Ui(v.polis);
      result.doorlopend = v.doorlopend ?? false;
      result.wijzigingenInDoorlopendProductOvernemen = v.wijzigingenInDoorlopendeProductenOvernemen;
      return result;
    },
    fiscaleRegeling: v => mapFiscaleRegelingDl2Ui(v.fiscaleRegeling),
    verzekeringnemers: (v, c) => mapVerzekeringnemersDl2Ui(c)(v),
    depot: v => mapDepotDl2Ui(v),
    kapitaalopbouw: (v, c) => mapKapitaalopbouwDl2Ui(v.kapitaalopbouw),
    partijCodeSelectie: _ => null,
    inleggegevens: v => mapInleggegevensDl2Ui(v.inleggegevens),
    onttrekkingen: v => {
      if (
        v.onttrekkingen &&
        (v.onttrekkingen.begindatum !== null ||
          v.onttrekkingen.duurInMaanden !== null ||
          !!v.onttrekkingen.extraOpnameJaren?.length ||
          v.onttrekkingen.onttrekkingBedrag !== null ||
          v.onttrekkingen.onttrekkingstermijn !== null)
      ) {
        return mapOnttrekkingenDl2Ui(v);
      }
      return onttrekkingenSchema.default();
    },
    verpanding: v => mapVerpandingDl2Ui(v.verpanding),
    dataHasChanged: v => false
  });

const dl2ui = createMapToUi(vermogensSchema)
  .with<AanvragerContextType>()
  .from<VermogenDlEntry>({
    producten: (v, c) => v.producten.map(product => mapVermogenDl2Ui(c)(product)),
    aanvrager1: (_, c) => c.aanvrager1,
    aanvrager2: (_, c) => c.aanvrager2,
    leningdelen: v => mapVemogenLeningdelenDl2Ui(v.leningdelen),
    kredieten: v => mapKredietenDl2Ui(v.kredieten),
    geldverstrekkerNaam: v => v.geldverstrekkerNaam,
    ingangsdatumVoorstel: v => mapStringToLocalDate(v.ingangsdatumVoorstel),
    platformApiFouten: () => null
  });

export function mapVermogensDlToUi(vermogenId: string, data: VermogenDlOutput): VermogensType | null {
  const vermogen = data && data.vermogens ? data.vermogens[vermogenId] : null;

  if (vermogen) {
    return dl2ui(mapContext(vermogen))(vermogen);
  }
  return null;
}

export function mapDlTargetToVermogenUiField(target: string): UiName | null {
  const map: FieldMap<VermogenDlEntry> = {
    producten: {
      polis: {
        ingangsdatum: {
          field: "producten[i].product.ingangsdatum",
          label: "Product ingangsdatum"
        }
      },
      inleggegevens: {
        duurHooglaagInMaanden: {
          field: "producten[i].inleggegevens.duurHoogLaag.jaren",
          label: "Inleggegevens duur hoog"
        }
      },
      productwaarde: {
        reedsOpgebouwdBedrag: {
          field: "producten[i].depot.reedsOpgebouwdBedrag",
          label: "Depotwaarde reeds opgebouwd"
        },
        reedsOpgebouwdDatum: {
          field: "producten[i].depot.reedsOpgebouwdDatum",
          label: "Depotwaarde reeds opgebouwd per datum"
        },
        afkoopwaardeDatum: {
          field: "producten[i].depot.afkoopwaardeDatum",
          label: "Depotwaarde afkoopwaarde per datum"
        },
        afkoopwaardeBedrag: {
          field: "producten[i].depot.afkoopwaardeBedrag",
          label: "Depotwaarde afkoopwaarde"
        }
      },
      kapitaalopbouw: {
        garantiekapitaalBedrag: {
          field: "producten[i].kapitaalopbouw.garantiekapitaalBedrag",
          label: "Kapitaalopbouw garantiekapitaal"
        },
        voorbeeldkapitaalBedrag: {
          field: "producten[i].kapitaalopbouw.voorbeeldkapitaalBedrag",
          label: "Kapitaalopbouw voorbeeldkapitaal"
        }
      }
    }
  };

  const regExp = /Producten\[(\d+)\]\.?(\w+)?\.(\w+)/;
  const match = regExp.exec(target);

  if (match) {
    const index = +match[1];
    const kaart = match[2];
    const veld = match[3];

    switch (kaart) {
      case undefined:
        switch (veld) {
          case "Begindatum":
            return {
              field: `producten[${index}].onttrekkingen.begindatum`,
              label: `Vermogen ${index + 1}: Begindatum`
            };
          case "DuurInMaanden":
            return {
              field: `producten[${index}].onttrekkingen.duur`,
              label: `Vermogen ${index + 1}: Duur`
            };
          case "Onttrekkingstermijn":
            return {
              field: `producten[${index}].onttrekkingen.onttrekkingstermijn`,
              label: `Vermogen ${index + 1}: Onttrekkingstermijn`
            };
          case "GarantiekapitaalBedrag":
            return {
              field: `producten[${index}].kapitaalopbouw.garantiekapitaalBedrag`,
              label: `Vermogen ${index + 1}: Garantiekapitaal`
            };
          case "VoorbeeldkapitaalBedrag":
            return {
              field: `producten[${index}].kapitaalopbouw.voorbeeldkapitaalBedrag`,
              label: `Vermogen ${index + 1}: Voorbeeldkapitaal`
            };
        }
        break;
      case "Kapitaalopbouw":
        switch (veld) {
          case "DoelkapitaalBedrag":
            return {
              field: `producten[${index}].kapitaalopbouw.doelkapitaalBedrag`,
              label: `Vermogen ${index + 1}: Doelkapitaal`
            };
        }
        break;
    }
  }

  return target2field(map, target);
}

export const mapDlTargetBerekenenVermogensRekeningToVermogenUiField = (selected: number) => (
  target: string
): UiName | null => {
  const map: FieldMap<VermogensrekeningInput> = {
    inleg: {
      bedrag: { field: `producten[${selected}].inleggegevens.inleg`, label: "Inleggegevens inleg" }
    },
    eersteInleg: { field: `producten[${selected}].inleggegevens.eersteInleg`, label: "Inleggegevens eerste inleg" },
    opgebouwdewaarde: {
      field: `producten[${selected}].depot.reedsOpgebouwdBedrag`,
      label: "Productwaarde reeds opgebouwd"
    },
    doelkapitaal: {
      field: `producten[${selected}].kapitaalopbouw.doelkapitaalBedrag`,
      label: "Kapitaalopbouw doelkapitaal"
    },
    garantiekapitaal: {
      field: `producten[${selected}].kapitaalopbouw.garantiekapitaalBedrag`,
      label: "Kapitaalopbouw garantiekapitaal"
    },
    hoogLaagInlegHoog: {
      field: `producten[${selected}].inleggegevens.inlegHoog`,
      label: "Inleggegevens inleg duur hoog"
    }
  };

  return target2field(map, target);
};
