import { RadioInputGroup } from "adviesbox-shared";
import { connect, FormikContextType } from "formik";
import React, { ReactElement } from "react";
import { FinancieringOptions } from "../../.generated/forms/formstypes";
import { Adviesonderwerpen } from "../../.generated/instellingen-forms/instellingen-formstypes";
import { Direction, GebruikWoning, LabelValuePairs } from "../../shared/types";
import { getAanleidingTextResources } from "../infra/aanleiding-resources";
import { AanleidingState } from "../infra/aanleiding-schema";

const GebruikWoningOptions = [
  { label: "Primair (Box 1)", value: GebruikWoning.Primair },
  { label: "Tweede woning (Box 3)", value: GebruikWoning.TweedeWoning }
];

type FinancieringkeuzeProps = {
  instellingen: Adviesonderwerpen;
};

const FinancieringKeuze = ({
  formik,
  instellingen
}: FinancieringkeuzeProps & { formik: FormikContextType<AanleidingState> }): ReactElement => {
  const financieringsKeuzesOptions: LabelValuePairs = [];
  if (instellingen.onderwerpHypotheekTonen) {
    financieringsKeuzesOptions.push({
      value: FinancieringOptions.AankoopBestaandeBouw,
      label: "Aankoop van een bestaande woning"
    });

    financieringsKeuzesOptions.push({
      value: FinancieringOptions.AankoopNieuwbouw,
      label: "Aankoop van een nieuwbouw woning",
      tooltip: getAanleidingTextResources("ToolTipAankoopNieuwbouw")
    });

    financieringsKeuzesOptions.push({
      value: FinancieringOptions.HuidigeHypotheekWijzigen,
      label: "Huidige hypotheek wijzigen"
    });
  }

  financieringsKeuzesOptions.push({
    value: FinancieringOptions.GeenFinanciering,
    label: "Geen financiering"
  });

  const financiering: any = formik.values.aanleiding.financiering;
  return (
    <>
      <div className="px-1">
        <RadioInputGroup
          options={financieringsKeuzesOptions}
          name="aanleiding.financiering"
          layout={Direction.Vertical}
        />
      </div>

      {(financiering === FinancieringOptions.AankoopNieuwbouw ||
        financiering === FinancieringOptions.AankoopBestaandeBouw) && (
        <>
          <div className="pt-4">
            <div>
              <h2>Gebruik woning</h2>
            </div>
            <div className="px-1">
              <RadioInputGroup
                options={GebruikWoningOptions}
                name="aanleiding.gebruikWoning"
                layout={Direction.Vertical}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default connect<any, AanleidingState>(FinancieringKeuze);
