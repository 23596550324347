import React, { ReactElement } from "react";
import { connect, FormikContextType } from "formik";
import { InkomenEnFiscusState } from "../infra/inkomen-en-fiscus-schema";
import { Direction } from "../../shared/types";
import { LabeledBevestigingInput, LabeledPercentageInput } from "adviesbox-shared";

const FiscaleVerdeling = ({
  formik: {
    values: { fiscaleVerdeling }
  }
}: {
  formik: FormikContextType<InkomenEnFiscusState>;
}): ReactElement => {
  const showPercentages = !fiscaleVerdeling.optimaleVerdeling;
  return (
    <>
      <LabeledBevestigingInput
        caption="Verdeling"
        name="fiscaleVerdeling.optimaleVerdeling"
        labels={{ true: "Optimale verdeling", false: "Opgave" }}
        layout={Direction.Horizontal}
      />
      {showPercentages && (
        <LabeledPercentageInput caption="Aanvrager1" name="fiscaleVerdeling.aanvrager1" decimalen={2} />
      )}

      {showPercentages && (
        <LabeledPercentageInput caption="Aanvrager2" name="fiscaleVerdeling.aanvrager2" decimalen={2} />
      )}
    </>
  );
};

export default connect<{}, InkomenEnFiscusState>(FiscaleVerdeling);
