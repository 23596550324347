import React, { ReactElement } from "react";
import { connect, useFormikContext } from "formik";
import { LabeledDateInput, LabeledBerekenDate, LabeledCurrencyInput } from "adviesbox-shared";
import { BankgarantieState, InstellingCodes } from "../infra/bankgarantie-schema";
import { getBankgarantieTextResources } from "../infra/bankgarantie-resources";

const BankgarantieKaart = (): ReactElement => {
  const formik = useFormikContext<BankgarantieState>();
  const instelling = formik.values.product.instelling;
  return (
    <>
      {instelling === InstellingCodes.CA && (
        <>
          <LabeledCurrencyInput caption="Garantiebedrag" name="bankgarantieKaart.garantiebedrag" verplicht={true} />
          <LabeledCurrencyInput caption="Koopsom" name="pand.aankoopprijs" readonly/>

          </>
      )}
      {instelling === InstellingCodes.NWB && (
        <>
          <LabeledDateInput
            caption="Afgiftedatum bankgarantie"
            name="bankgarantieKaart.afgiftedatumBankgarantie"
            tooltip={getBankgarantieTextResources("tooltipAfgiftedatum")}
          />
          <LabeledBerekenDate caption="Passeerdatum" name="bankgarantieKaart.passeerdatum" />
        </>
      )}
    </>
  );
};

export default connect<{}, BankgarantieState>(BankgarantieKaart);
