import React, { ReactElement } from "react";
import { FormikProps } from "formik";

import { LabeledCurrencyInput } from "adviesbox-shared";
import AdviesBoxFormik from "../../shared/utils/adviesbox-formik";
import Modal from "../../shared/components/modal/Modal";
import { OverigeFinancieringskostenModal as OverigeFinancieringskostenModalState } from "../infra/financieringsbehoefte-types";
import { getFinancieringsbehoefteTextResources } from "../infra/financieringsbehoefte-resources";
import { overigeFinancieringskostenModalSchema } from "../infra/financieringsbehoefte-schema";

export type OverigeFinancieringskostenModalData = {
  data: OverigeFinancieringskostenModalState;
};

type OverigeFinancieringskostenModalProps = {
  onSave?: (data: OverigeFinancieringskostenModalState) => void;
  closeModal?: () => void;
};

const OverigeFinancieringskostenModal = ({
  data,
  onSave,
  closeModal
}: OverigeFinancieringskostenModalData & OverigeFinancieringskostenModalProps): ReactElement => {
  const body = (
    <>
      <LabeledCurrencyInput
        caption="Afsluitkosten SVn starterslening"
        name="afsluitkostenSVnStarterslening"
        readonly={true}
        tooltip={getFinancieringsbehoefteTextResources("TooltipAfsluitprovisie")}
      />
      <LabeledCurrencyInput caption="Bereidstellingskosten" name="bereidstellingskosten" />
    </>
  );

  return (
    <AdviesBoxFormik<OverigeFinancieringskostenModalState>
      initialValues={{ ...data }}
      validationSchema={overigeFinancieringskostenModalSchema}
      onSave={onSave}
      closeModal={closeModal}
      render={({ submitForm }: FormikProps<OverigeFinancieringskostenModalState>): ReactElement => (
        <>
          <Modal
            title="Overige Financieringskosten"
            body={body}
            onSubmitClick={submitForm}
            onCancelClick={closeModal}
          />
        </>
      )}
    />
  );
};

export default OverigeFinancieringskostenModal;
