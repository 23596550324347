import {
  AdviesBoxColumn,
  DataGrid,
  LabeledBevestigingInput,
  LabeledCurrencyInput,
  LabeledPercentageInput,
  LabeledRadioInput,
  PercentageInput,
  TooltipWrap
} from "adviesbox-shared";
import { connect, FormikContextType, FormikProps } from "formik";
import React, { ReactElement } from "react";
import { CellInfo } from "react-table-6";
import { HypotheeklabelDetails, HypotheeklabelDetailsOutput } from "../../.generated/producten/productentypes";
import { AanpassingRenteOptions } from "../../.generated/forms/formstypes";
import { LeninggegevensKenmerken } from "../../producten-overzicht/infra/product-kenmerken-types";
import { SituatieSoort } from "../../producten-overzicht/infra/producten-overzicht-types";
import Modal from "../../shared/components/modal/Modal";
import { LabelValuePairs } from "../../shared/types";
import AdviesBoxFormik from "../../shared/utils/adviesbox-formik";
import { getHypotheekTextResources } from "../infra/hypotheek-resources";
import { rentedalingObvMarktwaardePercentageModalSchema } from "../infra/hypotheek-schema";
import {
  HypothekenState,
  RentedalingObvMarktwaardePercentageModalType,
  RentedalingPercentagesType
} from "../infra/hypotheek-types";

export type RentedalingObvMarktwaardePercentageModalProps = {
  situatie: SituatieSoort;
  kenmerken: LeninggegevensKenmerken;
  hypotheekDetailData: HypotheeklabelDetailsOutput | null;
  leningDeelHoofdsom: number;
  initialdata: RentedalingObvMarktwaardePercentageModalType;
  onSave?: (initialdata: RentedalingObvMarktwaardePercentageModalType) => void;
  closeModal?: () => void;
};

const CellPercentage = (c: CellInfo): ReactElement => (
  <PercentageInput
    decimalen={2}
    dataGrid={true}
    fieldSize="grid-cell"
    name={`rentedalingPercentages[${c.index}].renteopslagPercentage`}
  ></PercentageInput>
);

const aanpassingRenteOptions: LabelValuePairs = [
  {
    label: "Aanpassing rente direct bij lagere LTV",
    value: AanpassingRenteOptions.DirectBijLagereLtv
  },
  {
    label: "Aanpassing rente na einde rentevastperiode",
    value: AanpassingRenteOptions.NaEindeRentevastperiode
  }
];

const rentedalingObvMarktwaardePercentageVoorstelColumns: (columnConfig: {
  totalLength: number;
  secondLastValue: number;
}) => AdviesBoxColumn[] = columnConfig => [
  {
    Header: "% Marktwaarde t/m",
    accessor: "marktwaardePercentageTotEnMet",
    Cell: c =>
      c.viewIndex === columnConfig.totalLength - 1
        ? `>\xa0${columnConfig.secondLastValue}%`
        : `${c.row.marktwaardePercentageTotEnMet}%`,
    config: { readonly: true, decimalen: 2 }
  },
  {
    Header: "Renteopslag",
    accessor: "renteopslagPercentage",
    Cell: "PercentageInput",
    config: { readonly: true, decimalen: 2 }
  },
  {
    Cell: "DeleteButton"
  }
];

const rentedalingObvMarktwaardePercentageHuidigColumns: AdviesBoxColumn[] = [
  {
    Header: "% Marktwaarde t/m",
    accessor: "marktwaardePercentageTotEnMet",
    Cell: "PercentageInput",
    config: { decimalen: 2, allowAllValues: true, allowNegative: true }
  },
  {
    Header: "Renteopslag",
    accessor: "renteopslagPercentage",
    Cell: CellPercentage
  },
  {
    Cell: "DeleteButton"
  }
];

const RentedalingObvMarktwaardePercentageModal = ({
  situatie,
  leningDeelHoofdsom,
  kenmerken,
  hypotheekDetailData,
  initialdata,
  onSave,
  closeModal
}: RentedalingObvMarktwaardePercentageModalProps & {
  formik: FormikContextType<RentedalingObvMarktwaardePercentageModalType>;
}): ReactElement => {
  const body = (values: RentedalingObvMarktwaardePercentageModalType): ReactElement => {
    const IsVoorstel = situatie === "voorstel";
    const IsHuidig = situatie === "huidig";
    const heeftScenarioKorting = kenmerken?.heeftScenarioKorting;
    const DirecteBijLagereLtv = values.aanpassingRente === AanpassingRenteOptions.DirectBijLagereLtv;
    const modalTonen = heeftScenarioKorting && IsVoorstel && DirecteBijLagereLtv;

    const productenDl =
      hypotheekDetailData?.isValid &&
      (hypotheekDetailData?.producten as { [key: string]: HypotheeklabelDetails } | null);
    let automatischeRentedalingEnabled = true;
    if (productenDl && Object.keys(productenDl).length > 0) {
      const data = Object.keys(productenDl).map(c => productenDl[c])[0];
      automatischeRentedalingEnabled = !data.renteAutoUpdate || !data.renteUpdateDirect;
    }

    return (
      <>
        {IsHuidig && (
          <LabeledCurrencyInput
            caption="Gehanteerde marktwaarde"
            name="marktwaardeRenteBedrag"
            tooltip={getHypotheekTextResources("rentedalingObvMarktwaardePercentageModalGehanteerdeMarktwaarde")}
            defaultValue={leningDeelHoofdsom}
          />
        )}
        <div className="mb-3">
          <LabeledRadioInput
            caption={heeftScenarioKorting ? "Automatische rentedaling" : "Rentedaling op verzoek"}
            name="aanpassingRente"
            options={aanpassingRenteOptions}
            tooltip={getHypotheekTextResources("rentedalingObvMarktwaardePercentageModalAutomaticheRentedaling")}
            disabled={!automatischeRentedalingEnabled}
            appendChildren={
              modalTonen && (
                <div className="d-inline-flex align-items-start">
                  <TooltipWrap
                    name="automatische-rentedaling"
                    warningText={getHypotheekTextResources("automatischeRentedalingTriggerTooltip")}
                    placement="bottom"
                    iconType="waarschuwing"
                    tooltipClasses="mt-1"
                  />
                </div>
              )
            }
          />
        </div>

        <div className="my-4">
          <DataGrid
            className={heeftScenarioKorting && IsVoorstel ? "readonly" : "editable"}
            columns={
              heeftScenarioKorting && IsVoorstel
                ? rentedalingObvMarktwaardePercentageVoorstelColumns({
                    totalLength: values.rentedalingPercentages.length,
                    secondLastValue:
                      values.rentedalingPercentages.find((_, i) => i === values.rentedalingPercentages.length - 2)
                        ?.marktwaardePercentageTotEnMet ?? 0
                  })
                : rentedalingObvMarktwaardePercentageHuidigColumns
            }
            name="rentedalingPercentages"
            showButtonDelete={heeftScenarioKorting && IsVoorstel ? false : true}
            showButtonAddRow={heeftScenarioKorting && IsVoorstel ? false : true}
            rowCaption={"Renteopslag"}
            getNewRowValues={(): RentedalingPercentagesType => ({
              renteopslagPercentage: 0,
              marktwaardePercentageTotEnMet: 0
            })}
          />
        </div>

        <LabeledBevestigingInput
          caption="Rentescenario"
          name="renteScenario"
          tooltip={getHypotheekTextResources("rentedalingObvMarktwaardePercentageModalRenteScenario")}
        />

        {values.renteScenario && (
          <LabeledPercentageInput
            caption="Opslag/ afslag na rentevastperiode"
            name="opslagAfslagNaRentevastperiode"
            fieldSize="s"
            allowNegative={true}
            decimalen={2}
          />
        )}
      </>
    );
  };

  return (
    <AdviesBoxFormik<RentedalingObvMarktwaardePercentageModalType>
      initialValues={{ ...initialdata }}
      validationSchema={rentedalingObvMarktwaardePercentageModalSchema}
      onSave={onSave}
      closeModal={closeModal}
      render={({ values, submitForm }: FormikProps<RentedalingObvMarktwaardePercentageModalType>): ReactElement => (
        <>
          <Modal
            title="Rentedaling o.b.v marktwaardepercentage"
            body={body(values)}
            onSubmitClick={submitForm}
            onCancelClick={closeModal}
          />
        </>
      )}
    />
  );
};

RentedalingObvMarktwaardePercentageModal.displayName = "RentedalingObvMarktwaardePercentageModal";

export default connect<any, HypothekenState>(RentedalingObvMarktwaardePercentageModal);
