import { Button, Modal as ModalBootstrap, OverlayTrigger, Tooltip } from "react-bootstrap";
import React, { ReactElement } from "react";

import { DevDebug } from "../dev-debug/dev-debug";
import { ErrorBoundary } from "adviesbox-shared";

type ModalProps = {
  title?: string;
  body?: JSX.Element;
  onCancelClick?: () => void;
  onSubmitClick?: () => void;
  isSubmitting?: boolean;
  noEditableData?: boolean;
  modalIndex?: number;
  invalid?: boolean;
  tooltip?: string | ReactElement;
  saveButtonTekst?: string;
  prependToFooter?: React.ReactNode;
  appendToFooter?: React.ReactNode;
  submitDisabled?: boolean;
  submitButtonVariant?: "primary" | "danger";
};

const Modal = ({
  title,
  body,
  onCancelClick,
  onSubmitClick,
  isSubmitting,
  noEditableData,
  tooltip,
  invalid = false,
  submitButtonVariant = "primary",
  saveButtonTekst = "Bevestigen",
  prependToFooter = <></>,
  appendToFooter = <></>,
  submitDisabled
}: ModalProps): ReactElement => {
  return (
    <>
      <ModalBootstrap.Header>
        <div className="d-flex w-100">
          <ModalBootstrap.Title>
            <>
              {title}
              {tooltip && (
                <OverlayTrigger
                  trigger="click"
                  key={`${title}_tooltip`}
                  rootClose={true}
                  placement="bottom"
                  overlay={<Tooltip id={`${title}-tooltip`}>{tooltip}</Tooltip>}
                >
                  <Button className="question-tip" id={`${title}-tooltip`} />
                </OverlayTrigger>
              )}
            </>
          </ModalBootstrap.Title>
          <button type="button" className={"close ml-auto"} onClick={onCancelClick} id={`btnClose-${title}`}>
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
        </div>
      </ModalBootstrap.Header>
      <ModalBootstrap.Body>
        <>
          <ErrorBoundary>
            <>{body}</>
          </ErrorBoundary>
        </>
        <DevDebug />
      </ModalBootstrap.Body>
      <ModalBootstrap.Footer>
        {!noEditableData && (
          <>
            {prependToFooter}
            {onCancelClick && (
              <Button
                disabled={isSubmitting}
                id="btnannuleren"
                data-testid="btnannuleren"
                variant="link"
                onClick={onCancelClick}
              >
                Annuleren
              </Button>
            )}
            <Button
              disabled={invalid || isSubmitting || submitDisabled}
              id="btnopslaan"
              data-testid="btnopslaan"
              variant={submitButtonVariant}
              onClick={onSubmitClick}
            >
              {saveButtonTekst}
            </Button>
            {appendToFooter}
          </>
        )}
        {noEditableData && (
          <Button id="btnsluiten" data-testid="btnsluiten" variant="primary" onClick={onCancelClick}>
            Sluiten
          </Button>
        )}
      </ModalBootstrap.Footer>
    </>
  );
};

Modal.displayName = "Modal";

export default Modal;
