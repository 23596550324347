import React, { ReactElement } from "react";
import { FormikProps } from "formik";
import { HypotheekOptieType } from "../../hypotheek/infra/hypotheek-types";
import { LabeledBevestigingInput } from "adviesbox-shared";
import AdviesBoxFormik from "../../shared/utils/adviesbox-formik";
import Modal from "../../shared/components/modal/Modal";
import { hypotheekOptieSchema } from "../infra/hypotheek-schema";

export type HypotheekOptiesModalData = {
  data: HypotheekOptieType;
};

type HypotheekoptiesModalProps = {
  onSave?: (data: HypotheekOptieType) => void;
  closeModal?: () => void;
};

const HypotheekOptiesModal = ({
  data,
  onSave,
  closeModal
}: HypotheekOptiesModalData & HypotheekoptiesModalProps): ReactElement => {
  const body = (
    <>
      {data.hypotheekOpties.map((c, index) => (
        <LabeledBevestigingInput
          caption={`${c.omschrijving}`}
          name={`hypotheekOpties[${index}].geselecteerd`}
          tooltip={`${c.toelichting}`}
          key={index}
          readonly={c.verplicht || false}
        />
      ))}
    </>
  );

  return (
    <>
      <AdviesBoxFormik<HypotheekOptieType>
        initialValues={{ ...data }}
        validationSchema={hypotheekOptieSchema}
        onSave={onSave}
        closeModal={closeModal}
        render={({ submitForm }: FormikProps<HypotheekOptieType>): ReactElement => (
          <>
            <Modal title="Hypotheekopties" body={body} onSubmitClick={submitForm} onCancelClick={closeModal} />
          </>
        )}
      />
    </>
  );
};

HypotheekOptiesModal.displayName = "HypotheekOptiesModal";

export default HypotheekOptiesModal;
