import { LocalDate } from "@js-joda/core";
import * as Yup from "yup";
import { InferType } from "yup";
import { adresSchema } from "../../shared/generic-parts/adres/schema";
import { klantnaamSchema } from "../../shared/generic-parts/klantnaam/schema";
import { onderpandSchema } from "../../shared/generic-parts/onderpand/schema";
import { berekenInputSchema } from "../../shared/types";
import { yupEnum } from "../../shared/utils/yup-enum";
import { getWoonsituatieTextResources } from "./woonsituatie-resources";
import { nullableLocalDateSchema } from "adviesbox-shared";

export const HdnAnders = 99;

export enum SoortWoonsituatie {
  Eigendom = "Eigendom",
  Huur = "Huurwoning",
  Inwonend = "Inwonend"
}

export enum Bewoners {
  Aanvrager1 = "Aanvrager",
  Aanvrager2 = "Partner",
  Beiden = "Beiden"
}

export enum GebruikPand {
  PrimaireWoning = "Primair (box 1)",
  TweedeWoning = "Tweede woning (box 3)"
}

export type WoonsituatieEnPersoonsgegevensType = {
  woonsituatie: WoonsituatieType;
  persoonsgegevens: PersoonsgegevensType;
};

export const persoonsgegevensSchema = Yup.object({
  naamAanvrager: klantnaamSchema.nullable().default(null),
  naamPartner: klantnaamSchema.nullable().default(null),
  adresAanvrager: adresSchema,
  adresPartner: adresSchema
});
export type PersoonsgegevensType = InferType<typeof persoonsgegevensSchema>;

export const eigenwoningschuldModalEntrySchema = Yup.object({
  bedrag: Yup.number()
    .nullable()
    .default(null),
  begindatum: nullableLocalDateSchema
    .test({
      message: getWoonsituatieTextResources("ErrorDatumToekomst"),
      test: function(val: LocalDate): boolean {
        return !!val ? val <= LocalDate.now() : true;
      }
    })
    .when("bedrag", {
      is: (value): boolean => !!value && value > 0,
      then: nullableLocalDateSchema
        .test({
          message: getWoonsituatieTextResources("ErrorDatumToekomst"),
          test: function(val: LocalDate): boolean {
            return !!val ? val <= LocalDate.now() : true;
          }
        })
        .required(getWoonsituatieTextResources("ErrorDatumVerplicht"))
    }),
  einddatum: nullableLocalDateSchema
    .test({
      message: getWoonsituatieTextResources("ErrorDatumToekomst"),
      test: function(val: LocalDate): boolean {
        return !!val ? val <= LocalDate.now() : true;
      }
    })
    .when("bedrag", {
      is: (value): boolean => !!value && value > 0,
      then: nullableLocalDateSchema
        .test({
          message: getWoonsituatieTextResources("ErrorDatumToekomst"),
          test: function(val: LocalDate): boolean {
            return !!val ? val <= LocalDate.now() : true;
          }
        })
        .required(getWoonsituatieTextResources("ErrorDatumVerplicht"))
    }),
  renteaftrekId: Yup.string()
    .nullable()
    .default(null)
});
export type EigenwoningschuldModalEntryType = InferType<typeof eigenwoningschuldModalEntrySchema>;

export const eigenwoningschuldModalSchema = Yup.object({
  entries: Yup.array(eigenwoningschuldModalEntrySchema).default([])
});
export type EigenwoningschuldModalType = InferType<typeof eigenwoningschuldModalSchema>;

export const huidigeWoonsituatieSchema = Yup.object({
  woonsituatie: yupEnum(SoortWoonsituatie)
    .default(SoortWoonsituatie.Inwonend)
    .meta({ WoonsituatiesoortType: SoortWoonsituatie }),
  bewoners: yupEnum(Bewoners)
    .default(Bewoners.Aanvrager1)
    .meta({ HuidigeWoonsituatieBewonersType: Bewoners })
});
export type HuidigeWoonsituatieType = InferType<typeof huidigeWoonsituatieSchema>;

export const woningInEigendomSchema = Yup.object({
  gebruikWoning: yupEnum(GebruikPand)
    .default(GebruikPand.PrimaireWoning)
    .meta({ GebruikPandSoortType: GebruikPand }),
  gebruikWoningVoorstel: yupEnum(GebruikPand)
    .default(GebruikPand.PrimaireWoning)
    .meta({ GebruikPandSoortType: GebruikPand }),
  marktwaarde: Yup.number()
    .nullable()
    .default(null),
  wozWaarde: Yup.number()
    .nullable()
    .default(null),
  wozWaardeloket: Yup.string().default("https://www.wozwaardeloket.nl/"),
  eigenwoningforfait: berekenInputSchema,
  percentageAanvrager: Yup.number()
    .nullable()
    .default(100),
  percentagePartner: Yup.number()
    .nullable()
    .default(0)
});
export type WoningInEigendomType = InferType<typeof woningInEigendomSchema>;

export const huurwoningSchema = Yup.object({
  huurPerMaand: Yup.number()
    .nullable()
    .default(null),
  stijging: Yup.number()
    .nullable()
    .default(null),
  stijgingPercentageDefault: Yup.number()
    .nullable()
    .default(null)
});
export type HuurwoningType = InferType<typeof huurwoningSchema>;

export const huidigPandSchema = Yup.object({
  onderpand: onderpandSchema,
  energielabel: Yup.string().nullable().default(null),
  energieklasseDatum: nullableLocalDateSchema,
  adresIsWoonadres: Yup.boolean().default(true),
  adres: adresSchema
})
export type HuidigPandType = InferType<typeof huidigPandSchema>;

export const eigenwoningreserveSchema = Yup.object({
  eigenwoningreserveToggle: Yup.boolean().default(false),
  ewrVerleden: Yup.number()
    .nullable()
    .default(null),
  ewsOvergangsrecht: Yup.number()
    .nullable()
    .default(null),
  ewsAflossing: Yup.number()
    .nullable()
    .default(null),
  ewsOvergangsrechtModal: eigenwoningschuldModalSchema,
  ewsAflossingModal: eigenwoningschuldModalSchema
});
export type EigenwoningreserveType = InferType<typeof eigenwoningreserveSchema>;

export const hypotheekSchema = Yup.object({
  hasHypotheek: Yup.boolean().default(true),
  oorspronkelijk: Yup.number()
    .nullable()
    .default(null),
  inschrijving: berekenInputSchema
});
export type HypotheekType = InferType<typeof hypotheekSchema>;

export const erfpachtSchema = Yup.object({
  hasErfpacht: Yup.boolean().default(false),
  canon: Yup.number()
    .nullable()
    .default(null),
  particulierErfpacht: Yup.boolean().default(false),
  eeuwigdurendErfpacht: Yup.boolean().default(false),
  einddatum: nullableLocalDateSchema
});
export type ErfpachtType = InferType<typeof erfpachtSchema>;

export const woonsituatieSchema = Yup.object({
  huidigeWoonsituatie: huidigeWoonsituatieSchema,
  woningInEigendom: woningInEigendomSchema,
  huurwoning: huurwoningSchema,
  huidigPand: huidigPandSchema.default(null).nullable(),
  eigenwoningreserve: eigenwoningreserveSchema,
  hypotheek: hypotheekSchema,
  erfpacht: erfpachtSchema,
  woonsituatieId: Yup.string()
    .nullable()
    .default(null)
});
export type WoonsituatieType = InferType<typeof woonsituatieSchema>;

export const woonsituatieListSchema = Yup.object({
  woonsituaties: Yup.array(woonsituatieSchema).default([woonsituatieSchema.default()]),
  persoonsgegevens: persoonsgegevensSchema
});
export type WoonsituatieListProps = InferType<typeof woonsituatieListSchema>;
export type WoonsituatieListState = InferType<typeof woonsituatieListSchema>;
