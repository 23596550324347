import React, { useMemo, useCallback } from 'react';
import { Formik } from 'formik';
import HypotheekTotalen from '../../../hypotheek/hypotheek-totalen/hypotheek-totalen';
import { useRequestInit } from 'adviesbox-shared';
import { useFetchData } from '../../../shared/components/dossier/dossier-wijzig-adviseur-modal/usefetchdata';
import {
  HypotheekHuidigeSituatieOutput,
  HypotheekVoorstelOutput
} from '../../../.generated/forms/formstypes';
import { mapHypotheekDlToUi } from '../../../hypotheek/infra/map-hypotheek-dl-2-ui';
import { HypothekenState } from '../../../hypotheek/infra/hypotheek-types';
import { hypothekenBaseSchema } from '../../../hypotheek/infra/hypotheek-schema';

export const TotalenWrapper: React.FC = () => {
  const { params, settings, requestInit } = useRequestInit();

  const createUrl = useMemo(() => {
    const baseUrl = settings.klantdossiersFormsOrigin;
    return params.voorstel
      ? `${baseUrl}/Voorstellen/${params.voorstel}/Hypotheek`
      : `${baseUrl}/Adviesdossiers/${params.adviesdossier}/Hypotheek`;
  }, [params, settings]);

  const getRequestInit = useCallback(
    () => requestInit,
    [requestInit]
  );
  
  const { data, error } = useFetchData<HypotheekHuidigeSituatieOutput | HypotheekVoorstelOutput>(
    createUrl,
    getRequestInit
  );


  if (error) return <div>Geen hypotheek gegevens beschikbaar.</div>;

  const hypotheekKey = data?.hypotheken ?  Object.keys(data.hypotheken)[0] : null;
  const hypotheek: HypothekenState | null = hypotheekKey && data ? mapHypotheekDlToUi(hypotheekKey, data) : null;

  if (!hypotheek) return <></>;

  return (
    <Formik onSubmit={/* istanbul ignore next */ () => {}} initialValues={hypotheek ? hypotheek : hypothekenBaseSchema.default()} enableReinitialize>
      <HypotheekTotalen situatie={params.voorstel ? 'voorstel' : 'huidig'} selected={0} />
    </Formik>
  );
};