import { QuestionType, CardSpec } from "../../infra/klantprofiel-schema";
import { KlantprofielVraagOptions } from "../../../.generated/forms/formstypes";

export const hypotheekvormQuestionSpecs: CardSpec[] = [
  {
    questions: [
      {
        question: KlantprofielVraagOptions.WensAflossenHypotheek,
        type: QuestionType.checkbox
      }
    ]
  },
  {
    questions: [
      {
        question: KlantprofielVraagOptions.VanPlanTussentijdsAflossen,
        type: QuestionType.radio
      },
      {
        question: KlantprofielVraagOptions.DoelExtraAflossen,
        type: QuestionType.radio,
        condition: {
          question: KlantprofielVraagOptions.VanPlanTussentijdsAflossen,
          answer: 100201
        }
      }
    ]
  }
];
