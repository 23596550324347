/* istanbul ignore file */
import {
  LabeledCurrencyInput,
  LabeledSelectInput
} from "adviesbox-shared";
import { LabelValuePairs } from "adviesbox-shared/utils/types";
import { useFormikContext } from "formik";
import React, { ReactElement } from "react";
import { Button } from "react-bootstrap";
import { DekkingKenmerken, PremieKenmerken } from "../../producten-overzicht/infra/product-kenmerken-types";
import { AovsType, PremieBetalingstermijn } from "../infra/aov-schema";

type PremiegegevensProps = {
  selected: number;
  kenmerken: PremieKenmerken;
  dekkingKenmerken: DekkingKenmerken;
};

function getBetalingsTermijnOptions(kenmerken: PremieKenmerken, dekkingKenmerken: DekkingKenmerken): LabelValuePairs {
  const options: LabelValuePairs = [];
  if (kenmerken.heeftBetalingstermijnMaand) {
    options.push({
      label: "Maand",
      value: PremieBetalingstermijn.Maand
    });
  }

  if (kenmerken.heeftBetalingstermijnTweeMaanden) {
    options.push({
      label: "2 Maanden",
      value: PremieBetalingstermijn.TweeMaanden
    });
  }

  if (kenmerken.heeftBetalingstermijnKwartaal) {
    options.push({
      label: "Kwartaal",
      value: PremieBetalingstermijn.Kwartaal
    });
  }

  if (kenmerken.heeftBetalingstermijnHalfJaar) {
    options.push({
      label: "Half Jaar",
      value: PremieBetalingstermijn.HalfJaar
    });
  }

  if (kenmerken.heeftBetalingstermijnJaar) {
    options.push({
      label: "Jaar",
      value: PremieBetalingstermijn.Jaar
    });
  }

  if (kenmerken.heeftBetalingstermijnKoopsom) {
    options.push({
      label: "Koopsom",
      value: PremieBetalingstermijn.Eenmalig
    });
  }

  return options;
}

export const PremiegegevensDazure = ({ selected, kenmerken, dekkingKenmerken }: PremiegegevensProps): ReactElement => {
  const { values, setFieldValue } = useFormikContext<AovsType>();
  return (
    <>
      {(kenmerken.betalingstermijnTonen || dekkingKenmerken.heeftBetalingstermijnCombinatie) && (
        <>
            <LabeledSelectInput
              options={getBetalingsTermijnOptions(kenmerken, dekkingKenmerken)}
              name={`producten[${selected}].premiegegevens.betalingsTermijn`}
              caption="Betalingstermijn"
              readonly={!kenmerken.betalingstermijnEnabled}
            />
        </>
      )}

      <LabeledCurrencyInput
        name={`producten[${selected}].premiegegevens.premie`}
        caption="Premie totaal"
        decimalen={2}
        placeholder="-"
        readonly={kenmerken.berekenPremieKnopTonen}
      />

      {kenmerken.berekenPremieKnopTonen && (
        <div className="button-container">
          <Button
            id={`producten[${selected}].premieGegevens.premieBerekenen-button`}
            variant="primary"
            onClick={(): void => {
               setFieldValue(`producten[${selected}].toggleBerekenen`, !values.producten[selected].toggleBerekenen);
            }}
          >
            Premie berekenen
          </Button>
        </div>
      )}
    </>
  );
};
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production") PremiegegevensDazure.displayName = "Premiegegevens";
