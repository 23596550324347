import {
  RapportSamenstellenElement,
  SoortElementOptions,
  RapportSamenstellenStructuur
} from "../../.generated/rapportage/rapportagetypes";
import {
  RapportageElementenType,
  RapportageElementSoort
} from "../../shared/components/rapportage/infra/rapportage-structuur-types";
import { rapportageSamenstellenSchema } from "../../shared/components/rapportage/rapportage-samenstellen-schema";
import { createMapToDl } from "../../shared/utils/create-map-to-dl";

export function mapElementSoort(soort: RapportageElementSoort): SoortElementOptions {
  switch (soort) {
    case RapportageElementSoort.Grafiek:
      return SoortElementOptions.Grafiek;
    case RapportageElementSoort.Hoofdstuk:
      return SoortElementOptions.Hoofdstuk;
    case RapportageElementSoort.Koptekst:
      return SoortElementOptions.Koptekst;
    case RapportageElementSoort.Onderdeel:
      return SoortElementOptions.Onderdeel;
    case RapportageElementSoort.Tabel:
      return SoortElementOptions.Tabel;
    case RapportageElementSoort.Tekstblok:
      return SoortElementOptions.Tekstblok;
  }
}

function mapElement(element: RapportageElementenType): RapportSamenstellenElement {
  return {
    code: element.code,
    naam: element.naam,
    geselecteerd: element.geselecteerd,
    soortElement: mapElementSoort(element.soortElement),
    volgnummer: element.volgnummer,
    elementen: element.elementen.map(v => mapElement(v))
  };
}

export const mapRapportageSamenstellenUiToDl = createMapToDl(rapportageSamenstellenSchema).to<
  RapportSamenstellenStructuur
>({
  elementen: c => {
    return c.selectedStructuur?.elementen.map(v => mapElement(v)) ?? [];
  },
  naam: c => "rapport", // todo: name cannot be specified in frontend yet.
  structuurId: c => c.rapportstructuur,
  volgnummer: _ => 0
});
