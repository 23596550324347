import {
  Orv as OrvDlEntry,
  OrvAanvraag,
  OrvDekking,
  OrvPolis,
  OrvPremie,
  BetalingsTermijnType,
  OrvProduct,
  PremieverloopOptions,
  SoortOrvProductOptions,
  OrvVergelijkerInput,
  OrvUitkeringssoortOptions,
  OrvVerpanding,
  PremiePerJaar,
  Indexatiesoort
} from "../../.generated/forms/formstypes";
import { mapAanvragers } from "../../producten-overzicht/infra/map-aanvragers";
import { partijOnafhankelijk } from "../../producten-overzicht/infra/product-constanten";
import {
  dekkingSchema,
  informatieVoorVerzendingAanvraagSchema,
  productSchema
} from "../../producten-overzicht/infra/producten-overzicht-schema";
import { DekkingType, SituatieSoort, VerpandingType } from "../../producten-overzicht/infra/producten-overzicht-types";
import { mapAflosProductenUiToDl } from "../../producten-overzicht/infra/verpanding/map-verpanding-ui-to-dl";
import { jaarMaandInMaanden } from "../../shared/generic-parts/jaar-maand/map-ui-2-dl";
import { mapAanvragerKeuzeNaarKlanten } from "../../shared/generic-parts/klantnaam/map-dl-2-ui";
import { KlantnaamMetKinderenType } from "../../shared/generic-parts/klantnaam/schema";
import { AanvragerKeuze } from "../../shared/types";
import { createMapToDl } from "../../shared/utils/create-map-to-dl";
import {
  orvPremieGegevensSchema,
  orvSchema,
  orvsSchemaBase,
  OrvsState,
  orvVergelijkenModalSchema,
  orvVerpandingSchema
} from "./orv-schema";
import { mapLocalDateToString } from "adviesbox-shared";

export const mapPolisUi2Dl = createMapToDl(productSchema)
  .with<{ partijCode: string; productCode: string }>()
  .to<OrvPolis>(
    // TODO: PartijCodeSelectie
    {
      productnummer: v => v.productNummer,
      maatschappijCodeHdn: (v, { partijCode }) => (partijCode === partijOnafhankelijk ? v.partijCodeSelectie : ""), // TODO: de maatschappijCodeHdn is niet gelijk aan de maatschappijCode met bijv. ASR en Callas
      maatschappijCode: (_, { partijCode }) => partijCode,
      maatschappijOmschrijving: v => v.partijNaam,
      productcode: (_, { productCode }) => Number(productCode),
      productnaam: v => v.productNaam,
      ingangsdatum: v => mapLocalDateToString(v.ingangsdatum) ?? "",
      looptijdInMaanden: v => jaarMaandInMaanden(v.looptijd) ?? 0,
      uwBemiddeling: v => v.uwBemiddeling
    }
  );

export const mapDekkingUi2Dl = createMapToDl(dekkingSchema)
  .with<{
    aanvrager1: KlantnaamMetKinderenType | null;
    aanvrager2: KlantnaamMetKinderenType | null;
    verzekerden: AanvragerKeuze;
  }>()
  .to<OrvDekking>({
    basisdekking: v => v.basisdekking,
    einddatum: v => (v.basisdekking === Indexatiesoort.VerzekerdBedrag ? mapLocalDateToString(v.einddatum) : null),
    verzekerdMaandbedrag: v => v.verzekerdMaandbedrag,
    annuiteits1Percentage: v => v.annuiteitspercentage1,
    annuiteits2Percentage: v => v.annuiteitspercentage2,
    dekkingDaaltTot1Bedrag: v => v.dekkingDaaltTotAanvrager1,
    dekkingDaaltTot2Bedrag: v => v.dekkingDaaltTotAanvrager2,
    reservewaarde: v => v.reservewaarde,
    verzekerdKapitaal1Bedrag: v => v.verzekerdKapitaalAanvrager1,
    verzekerdKapitaal2Bedrag: v => v.verzekerdKapitaalAanvrager2,
    verzekeringsmotief: v => v.verzekeringsmotief,
    doelVerzekering: v => v.doelVerzekering,
    eindleeftijd: v => v.eindleeftijd,
    uitkeringBij: (v, c) =>
      v.basisdekking === Indexatiesoort.VerzekerdBedrag
        ? c.verzekerden === AanvragerKeuze.Aanvrager1 && c.aanvrager1?.heeftKinderen
          ? v.uitkeringBij
          : c.verzekerden === AanvragerKeuze.Aanvrager2 && c.aanvrager2?.heeftKinderen
          ? v.uitkeringBij
          : null
        : null,
    duurDalingInJaren1: v => v.duurDalingInJaren1,
    duurDalingInJaren2: v => v.duurDalingInJaren2
  });

export const mapOrvModalUi2Dl = createMapToDl(orvVergelijkenModalSchema).to<OrvVergelijkerInput>({
  aanvangsdatum: v => v.verzekering.aanvangsdatum.toString(),
  duurInJaren: v => v.verzekering.duurInJaren,
  verpand: v => v.verzekering.verpand,
  verzekerdKapitaalBedrag: v => v.verzekering.verzekerdKapitaalBedrag ?? 0,
  verzekerde1Roker: v => v.verzekerden.verzekerde1Roker,
  verzekerde2Roker: v => v.verzekerden.verzekerde2Roker,
  verzekerdeKlantIds: v =>
    mapAanvragerKeuzeNaarKlanten(v.verzekerden.verzekerden, v.verzekerden.aanvrager1, v.verzekerden.aanvrager2),
  verzekeringsvorm: v => OrvUitkeringssoortOptions[v.verzekering.verzekeringsvorm],
  annuiteitsPercentage: v =>
    v.verzekering.verzekeringsvorm === OrvUitkeringssoortOptions.AnnuïtairDalend
      ? v.verzekering.annuiteitsPercentage
      : null
});

export const mapPremieUi2Dl = createMapToDl(orvPremieGegevensSchema)
  .with<DekkingType>()
  .to<OrvPremie>({
    premieAftrekbaar: (v, dekking) =>
      dekking.basisdekking === Indexatiesoort.VerzekerdBedrag ? v.premieAftrekbaar : null,
    verkortePremieduur: v => v.verkortePremieduur,
    premieduurInMaanden: v => jaarMaandInMaanden(v.looptijd),
    betalingsTermijn: v => v.betalingstermijn as BetalingsTermijnType,
    premieBedrag: v => v.risicoPremieLaag,
    premiesPerJaar: v =>
      v.premiespecificatie.termijnPremie.length
        ? v.premiespecificatie.termijnPremie.map(
            (premie, index): PremiePerJaar => {
              return { jaar: index, premieTotaalBedrag: premie.bedrag || 0 };
            }
          )
        : null
  });

export const mapVerpandingUi2Dl = createMapToDl(orvVerpandingSchema)
  .with<{ situatie?: SituatieSoort }>()
  .to<OrvVerpanding>({
    verpandAanGeldverstrekker: v => v.verpandAanGeldverstrekker,
    bedoeldVoorAflossing: v => v.bedoeldVoorAflossing,
    aflosproducten: v => mapAflosProductenUiToDl(v.bedoeldVoorAflossingSpecificatie.aflosproducten),
    inkomensaanvulling: v => v.inkomensaanvulling,
    inkomensaanvullingAanvrager1: v =>
      v.inkomensaanvullingSpecificatie.aanvullingInkomenBijOverlijdenAanvrager1
        ? {
            gehanteerdTariefPercentage: v.inkomensaanvullingSpecificatie.gehanteerdTariefAanvrager1,
            gewensteUitkeringBedrag: v.inkomensaanvullingSpecificatie.gewensteUitkeringAanvrager1,
            uitkeringstermijn: v.inkomensaanvullingSpecificatie.gewensteUitkeringPerPeriodeAanvrager1,
            duurInMaanden: null
          }
        : null,
    inkomensaanvullingAanvrager2: v =>
      v.inkomensaanvullingSpecificatie.aanvullingInkomenBijOverlijdenAanvrager2
        ? {
            gehanteerdTariefPercentage: v.inkomensaanvullingSpecificatie.gehanteerdTariefAanvrager2,
            gewensteUitkeringBedrag: v.inkomensaanvullingSpecificatie.gewensteUitkeringAanvrager2,
            uitkeringstermijn: v.inkomensaanvullingSpecificatie.gewensteUitkeringPerPeriodeAanvrager2,
            duurInMaanden: null
          }
        : null
  });

export const mapAanvraagUi2Dl = createMapToDl(informatieVoorVerzendingAanvraagSchema).to<OrvAanvraag>({
  akkoordMetDigitaleCommunicatie: v => v.akkoordMetDigitaleCommunicatie,
  akkoordMetDigitalePolis: v => v.akkoordMetDigitalePolis,
  strafrechtelijkVerledenAanvrager1: v => v.strafrechtelijkVerledenAanvrager1,
  strafrechtelijkVerledenAanvrager2: v => v.strafrechtelijkVerledenAanvrager2,
  toelichtingGelezenAkkoordMetSlotverklaring: v => v.toelichtingGelezenAkkoordMetSlotverklaring,
  eldersLopendeAanvraag: _ => null,
  voldoetAanAcceptatiecriteria: _ => null,
  voorlopigeIngangsdatum: _ => null
});

export const mapProductUi2Dl = createMapToDl(orvSchema)
  .with<{
    aanvrager1: KlantnaamMetKinderenType | null;
    aanvrager2: KlantnaamMetKinderenType | null;
    dekking: DekkingType;
    situatie?: SituatieSoort;
  }>()
  .to<OrvProduct>({
    soortProduct: v => v.product.soortProduct,
    uitkeringssoort: v => (v.product.soortProduct === SoortOrvProductOptions.Orv ? v.product.uitkeringssoort : null),
    productId: v => v.product.productId,
    polis: v => mapPolisUi2Dl({ partijCode: v.partijCode, productCode: v.productCode })(v.product),
    verzekeringnemerKlantIds: (v, { aanvrager1, aanvrager2 }) =>
      v.product.soortProduct === SoortOrvProductOptions.Orv
        ? mapAanvragers(v.verzekeringnemers.verzekeringnemers, aanvrager1, aanvrager2)
        : [],
    verzekerdeKlantIds: (v, { aanvrager1, aanvrager2 }) =>
      mapAanvragers(v.verzekerden.verzekerden, aanvrager1, aanvrager2),
    premiesplitsing: v =>
      v.verzekeringnemers.verzekeringnemers === AanvragerKeuze.Beiden ? v.verzekerden.premiesplitsing : null,
    dekking: (v, c) =>
      mapDekkingUi2Dl({
        aanvrager1: c.aanvrager1,
        aanvrager2: c.aanvrager2,
        verzekerden: v.verzekerden.verzekerden
      })(v.dekking),
    premie: (v, { dekking }) => mapPremieUi2Dl(dekking)(v.premieGegevens),
    verpanding: (v, c) =>
      v.product.soortProduct !== SoortOrvProductOptions.AnwHiaatVerzekering
        ? mapVerpandingUi2Dl(c)(v.verpanding as VerpandingType)
        : null,
    aanvraag: (v, c) =>
      v.informatieVoorVerzendingAanvraag && c.situatie === "voorstel"
        ? mapAanvraagUi2Dl(v.informatieVoorVerzendingAanvraag)
        : null,
    doorlopend: v => v.product.doorlopend,
    wijzigingenInDoorlopendeProductenOvernemen: v => v.product.wijzigingenInDoorlopendProductOvernemen ?? true,
    premieverloop: v => v.premieverloop || PremieverloopOptions.Gelijkblijvend,
    meenemen: v => false // TODO zie #91236
  });

export const ui2dl = createMapToDl(orvsSchemaBase)
  .with<{ situatie?: SituatieSoort }>()
  .to<OrvDlEntry>({
    producten: (v, c) =>
      v.producten.map(
        (productUi): OrvProduct => {
          return mapProductUi2Dl({
            aanvrager1: v.aanvrager1,
            aanvrager2: v.aanvrager2,
            dekking: productUi.dekking,
            situatie: c.situatie
          })(productUi);
        }
      ),
    aanvrager1: v => null, // TODO CONTROLEREN!!
    aanvrager2: v => null // TODO CONTROLEREN!!
  });

export const mapOrvUiToDl = (situatie?: SituatieSoort) => (orv: OrvsState): OrvDlEntry => {
  return ui2dl({ situatie })(orv);
};
