import { Card, CardWrapper, createSpanWithId, DataGrid, PageLoading, useRequestInit, FormFirstFocus, PlatformFoutenSamenvatting, insightsReactPlugin } from "adviesbox-shared";
import { Form, FormikContextType } from "formik";
import React, { ReactElement, useContext, useState } from "react";
import { Column } from "react-table-6";
import { AppDataContext } from "../navigation/appdata-context";
import { DevDebug } from "../shared/components/dev-debug/dev-debug";
import { ISWSideEffects } from "../shared/components/isw-side-effects/isw-side-effects";
import { SaveButton } from "../shared/components/save-button/save-button";
import OpenDossierLogContext from "../shared/open-dossier-log/open-dossier-log-context";
import { bedragFormat } from "../shared/utils/currency";
import { getNaam } from "../shared/utils/helpers";
import { WithSaveData } from "../shared/utils/save-data";
import { withAdviesboxFormik } from "../shared/utils/with-adviesbox-formik";
import Eigenwoningreserve from "./eigenwoningreserve/eigenwoningreserve";
import Erfpacht from "./erfpacht/erfpacht";
import HuidigPand from "./huidig-pand/huidig-pand";
import HuidigeWoonsituatie from "./huidige-woonsituatie/huidige-woonsituatie";
import Huurwoning from "./huurwoning/huurwoning";
import Hypotheek from "./hypotheek/hypotheek";
import { determineWoonsituatieAsyncSideEffects } from "./infra/determine-woonsituatie-async-side-effects";
import { determineWoonsituatieSideEffects } from "./infra/determine-woonsituatie-side-effects";
import {
  Bewoners,
  huidigeWoonsituatieSchema,
  SoortWoonsituatie,
  WoonsituatieListProps,
  WoonsituatieListState,
  woonsituatieSchema,
  WoonsituatieType
} from "./infra/woonsituatie-schema";
import WoningInEigendom from "./woning-in-eigendom/woning-in-eigendom";
import { withAITracking } from "@microsoft/applicationinsights-react-js";

const Woonsituatie = (
  formikContext: FormikContextType<WoonsituatieListState> & WoonsituatieListProps & WithSaveData<WoonsituatieListState>
): ReactElement => {
  const {
    menuInfo: { adviseurIds }
  } = useContext(AppDataContext);
  const { setSErunning } = useContext(AppDataContext);
  const selectedState = useState(0);
  const [selected] = selectedState;
  const {
    isSubmitting,
    saveData,
    values: { woonsituaties, persoonsgegevens }
  } = formikContext;
  const {
    lezenEnSchrijvenRechtenVestigingen: lezenEnSchrijvenRechten,
    getAdviseurIds,
    adviseurIds: adviseurIdsHuidigeGebruiker,
    adviseurIdsOphalen
  } = useContext(OpenDossierLogContext);
  const { params } = useRequestInit<{ vestiging: string }>();

  if (adviseurIdsOphalen) {
    getAdviseurIds(params.vestiging);
  }

  /* istanbul ignore next */
  const huidigeGebruiker = adviseurIdsHuidigeGebruiker && adviseurIdsHuidigeGebruiker.map(e => e);
  const gebruikersEigenAangemaaktDossier = adviseurIds && huidigeGebruiker.includes(adviseurIds.toString());

  const opslaanRechten =
    lezenEnSchrijvenRechten === "LezenEnSchrijven" || gebruikersEigenAangemaaktDossier || !lezenEnSchrijvenRechten;

  const isHuurwoning = woonsituaties[selected]
    ? woonsituaties[selected].huidigeWoonsituatie.woonsituatie === SoortWoonsituatie.Huur
    : false;
  const isEigendom = woonsituaties[selected]
    ? woonsituaties[selected].huidigeWoonsituatie.woonsituatie === SoortWoonsituatie.Eigendom
    : false;

  const setBewoner = (bewoners: Bewoners): string => {
    switch (bewoners) {
      case Bewoners.Aanvrager1:
        return getNaam(persoonsgegevens.naamAanvrager, "Aanvrager");
      case Bewoners.Aanvrager2:
        return getNaam(persoonsgegevens.naamPartner, "Partner");
      case Bewoners.Beiden:
        return Bewoners.Beiden;
    }
  };

  const setPlaats = (r: WoonsituatieType): string => {
    switch (r.huidigeWoonsituatie.bewoners) {
      case Bewoners.Aanvrager1:
        return r.huidigPand?.adres.plaats ? r.huidigPand.adres.plaats : persoonsgegevens.adresAanvrager.plaats;
      case Bewoners.Beiden:
        return r.huidigPand?.adres.plaats ? r.huidigPand.adres.plaats : persoonsgegevens.adresAanvrager.plaats;
      case Bewoners.Aanvrager2:
        return r.huidigPand?.adres.plaats ? r.huidigPand.adres.plaats : persoonsgegevens.adresPartner.plaats;
    }
  };

  const columns: Column[] = [
    {
      Header: "Woonsituatie",
      id: "Woonsituatie",
      Cell: (c): ReactElement => createSpanWithId(c.index, 0, c.original.huidigeWoonsituatie.woonsituatie)
    },
    {
      Header: "Bewoner(s)",
      id: "Bewoner",
      Cell: (c): ReactElement =>
        createSpanWithId(
          c.index,
          1,
          setBewoner(c.original.huidigeWoonsituatie.bewoners),
          setBewoner(c.original.huidigeWoonsituatie.bewoners)
        )
    },
    {
      Header: "Hypotheek(bedrag)",
      id: "Hypotheek",
      Cell: (c): ReactElement =>
        createSpanWithId(
          c.index,
          2,
          (() => {
            if (
              c.original.hypotheek.oorspronkelijk &&
              c.original.huidigeWoonsituatie.woonsituatie === SoortWoonsituatie.Eigendom
            ) {
              return bedragFormat(c.original.hypotheek.oorspronkelijk);
            }
            if (c.original.huidigeWoonsituatie.woonsituatie !== SoortWoonsituatie.Eigendom) return "n.v.t.";
            return null;
          })()
        )
    },
    {
      Header: "Marktwaarde",
      id: "Marktwaarde",
      Cell: (c): ReactElement =>
        createSpanWithId(
          c.index,
          3,
          c.original.woningInEigendom.marktwaarde ? bedragFormat(c.original.woningInEigendom.marktwaarde) : null
        )
    },
    {
      Header: "WOZ-waarde",
      id: "WOZ-waarde",
      Cell: (c): ReactElement =>
        createSpanWithId(
          c.index,
          4,
          c.original.woningInEigendom.wozWaarde ? bedragFormat(c.original.woningInEigendom.wozWaarde) : null
        )
    },
    {
      Header: "Huur(bedrag)",
      id: "Huur",
      Cell: (c): ReactElement =>
        createSpanWithId(
          c.index,
          5,
          (() => {
            if (
              c.original.huurwoning.huurPerMaand &&
              c.original.huidigeWoonsituatie.woonsituatie === SoortWoonsituatie.Huur
            )
              return bedragFormat(c.original.huurwoning.huurPerMaand);
            if (c.original.huidigeWoonsituatie.woonsituatie !== SoortWoonsituatie.Huur) return "n.v.t.";
            return null;
          })()
        )
    },
    {
      Header: "Erfpacht",
      id: "Erfpacht",
      Cell: (c): ReactElement =>
        createSpanWithId(c.index, 6, !c.original.erfpacht.hasErfpacht ? "Nee" : bedragFormat(c.original.erfpacht.canon))
    },
    {
      Header: "Plaats",
      id: "Plaats",
      Cell: (c): ReactElement => createSpanWithId(c.index, 7, setPlaats(c.original), setPlaats(c.original))
    },
    {
      Cell: "DeleteButton"
    }
  ];

  return (
    <FormFirstFocus>
      <Form>
        {isSubmitting && <PageLoading />}
        <ISWSideEffects<WoonsituatieListState>
          runOnFirstRender
          sync={determineWoonsituatieSideEffects}
          async={determineWoonsituatieAsyncSideEffects({ selected, saveData, opslaanRechten })}
          asyncStartStopCallback={setSErunning}
        />
        <CardWrapper className="px-3">
          <div className="text-container">
            <h2>Woonsituatie</h2>
            <div className="save-btn-position">
              <div className="button-container">
                <SaveButton context={formikContext} />
              </div>
            </div>
          </div>
        </CardWrapper>

        <PlatformFoutenSamenvatting />

        <div className="d-flex flex-wrap flex-row flex-grow-1">
          <CardWrapper className="px-3 master-detail-card flex-grow-1" maxRowCount={4}>
            <Card className="w-xl-100 w-lg-100 w-md-50 w-50">
              <DataGrid
                masterDetail
                columns={columns}
                name="woonsituaties"
                rowSelected={selectedState}
                validationSchema={woonsituatieSchema}
                rowCaption="Woonsituatie"
                getNewRowValues={(): WoonsituatieType => ({
                  ...woonsituatieSchema.default(),
                  huidigeWoonsituatie: {
                    ...huidigeWoonsituatieSchema.default(),
                    bewoners: persoonsgegevens.naamPartner ? Bewoners.Beiden : Bewoners.Aanvrager1
                  }
                })}
                showButtonDelete={woonsituaties.length > 1}
              />
            </Card>
          </CardWrapper>

          {woonsituaties.length > 0 && (
            <>
              <CardWrapper flexType="flex-row" className="px-3">
                <Card title="Woonsituatie">
                  <HuidigeWoonsituatie selected={selected} />
                </Card>
                {isEigendom && (
                  <Card title="Woning in eigendom">
                    <WoningInEigendom selected={selected} />
                  </Card>
                )}

                {isHuurwoning && (
                  <Card title="Huurwoning">
                    <Huurwoning selected={selected} />
                  </Card>
                )}

                {isEigendom && (
                  <Card title="Huidig pand">
                    <HuidigPand selected={selected} />
                  </Card>
                )}

                <Card title="Eigenwoningreserve / -schuld">
                  <Eigenwoningreserve selected={selected} />
                </Card>
                {isEigendom && (
                  <>
                    <Card title="Hypotheek">
                      <Hypotheek selected={selected} />
                    </Card>

                    <Card title="Erfpacht">
                      <Erfpacht selected={selected} />
                    </Card>
                  </>
                )}
              </CardWrapper>
            </>
          )}
        </div>
        <DevDebug />
      </Form>
    </FormFirstFocus>
  );
};

Woonsituatie.displayName = "Woonsituatie";

export default withAdviesboxFormik<WoonsituatieListProps & WithSaveData<WoonsituatieListState>, WoonsituatieListState>({
  mapPropsToValues: (e: WoonsituatieListProps): WoonsituatieListState => e,
  validationSchema: woonsituatieSchema
})(withAITracking(insightsReactPlugin, Woonsituatie));
