import { Aov as AovDlEntry } from "../../.generated/forms/formstypes";
import { UiName, FieldMap } from "../../shared/types";
import { target2field } from "../../shared/utils/target-to-field";

export function mapDlTargetToAovUiField(target: string): UiName | null {
  const map: FieldMap<AovDlEntry> = {
    producten: {
      beroep: { field:"producten[i].beroep", label:"Beroep" },
      polis: {
        productnaam: { field: "producten[i].product.productNaam", label: "Productnaam" },
        ingangsdatum: { field: "producten[i].product.ingangsdatum", label: "Ingangsdatum" },
        productnummer: { field: "producten[i].product.productNummer", label: "Productnummer" },
        maatschappijOmschrijving: { field: "producten[i].product.partijNaam", label: "Partijnaam" }
      },
      dekkingVerzekerde1: {
        rubriekA: {
          eigenRisicoInMaanden: {
            field: "producten[i].dekking.dekkingGegevensVerzekerde1.rubriekA.eigenRisicoInMaanden",
            label: "Aanvrager 1 Dekking Rubriek A jaarlijkse indexatie van totaal maanden"
          }
        },
        vastBedrag: {
          field: "producten[i].dekking.dekkingGegevensVerzekerde1.vastBedrag",
          label: "Dekking vast bedrag"
        },
        dekkingAo: {
          looptijdInMaanden: {
            field: "producten[i].dekking.dekkingGegevensVerzekerde1.ao.looptijd.jaren",
            label: "Aanvrager 1 Dekking looptijd"
          }
        },
        rubriekB: {
          eindleeftijd: {
            field: "producten[i].dekking.dekkingGegevensVerzekerde1.rubriekB.eindLeeftijd",
            label: "Rubriek B Eindleeftijd"
          }
        }
      },
      dekkingVerzekerde2: {
        rubriekA: {
          eigenRisicoInMaanden: {
            field: "producten[i].dekking.dekkingGegevensVerzekerde1.rubriekA.eigenRisicoInMaanden",
            label: "Aanvrager 2 Dekking Rubriek A jaarlijkse indexatie van totaal maanden"
          }
        },
        vastBedrag: {
          field: "producten[i].dekking.dekkingGegevensVerzekerde1.vastBedrag",
          label: "Dekking vast bedrag"
        },
        dekkingAo: {
          looptijdInMaanden: {
            field: "producten[i].dekking.dekkingGegevensVerzekerde2.ao.looptijd.jaren",
            label: "Aanvrager 2 Dekking looptijd"
          }
        },
        rubriekB: {
          eindleeftijd: {
            field: "producten[i].dekking.dekkingGegevensVerzekerde2.rubriekB.eindLeeftijd",
            label: "Rubriek B Eindleeftijd"
          }
        }
      }
    }
  };

  return target2field(map, target);
}
