import React, { ReactElement } from "react";
import { getAanvragerOpties } from "../../producten-overzicht/infra/producten-helper";
import { AanvragerKeuze } from "../../shared/types";
import { ModalVerzekerdenType } from "../infra/orv-schema";
import { LabeledRadioInput, LabeledDateInput, LabeledBevestigingInput } from "adviesbox-shared";
import { getNaam } from "../../shared/utils/helpers";

const VerzekerdenPremievergelijker = ({ verzekerden }: { verzekerden: ModalVerzekerdenType }): ReactElement => {
  const verzekerdenOpties = getAanvragerOpties(verzekerden.aanvrager1, verzekerden.aanvrager2, true);
  const aanvragerEenNaam = getNaam(verzekerden.aanvrager1, "Aanvrager1");
  const aanvragerTweeNaam = getNaam(verzekerden.aanvrager2, "Aanvrager2");

  return (
    <>
      <LabeledRadioInput caption={"Verzekerde(n)"} name={`verzekerden.verzekerden`} options={verzekerdenOpties} />

      {(verzekerden.verzekerden === AanvragerKeuze.Aanvrager1 || verzekerden.verzekerden === AanvragerKeuze.Beiden) && (
        <>
          <h2>{aanvragerEenNaam}</h2>
          <LabeledDateInput caption="Geboortedatum" name="verzekerden.aanvrager1.geboortedatum" readonly={true} />
          <LabeledBevestigingInput caption="Roker" name="verzekerden.verzekerde1Roker" readonly={true} />
        </>
      )}

      {(verzekerden.verzekerden === AanvragerKeuze.Aanvrager2 || verzekerden.verzekerden === AanvragerKeuze.Beiden) && (
        <>
          <h2>{aanvragerTweeNaam}</h2>
          <LabeledDateInput caption="Geboortedatum" name="verzekerden.aanvrager2.geboortedatum" readonly={true} />
          <LabeledBevestigingInput caption="Roker" name="verzekerden.verzekerde2Roker" readonly={true} />
        </>
      )}
    </>
  );
};

export default VerzekerdenPremievergelijker;
