import React, { ReactElement, ReactNode, InputHTMLAttributes } from "react";
import { LabelValuePair } from "../../types";
import { RentevariantOptions } from "../../../.generated/forms/formstypes";
import {
  RentevariantMetRenteperiodesOutput,
  RenteperiodeMetRentepercentage,
  RentevariantMetRenteperiodesRenteafspraak
} from "../../../.generated/hypotheekrentes/hypotheekrentestypes";
import { connect, FormikContextType } from "formik";
import { HypothekenState } from "../../../hypotheek/infra/hypotheek-types";
import { getHypotheekTextResources } from "../../../hypotheek/infra/hypotheek-resources";
import { LabeledSelectInput, LabeledRadioInput, LabeledResult } from "adviesbox-shared";
import { percentageFormat } from "../../utils/percentage-format";

type RenteVariantPresentationProps = {
  rentevarianten: RentevariantMetRenteperiodesOutput | null;
  name: string;
  selected: number;
  appendChildren?: ReactNode;
  readonly?: boolean;
  fieldSize?: "xl" | "l" | "m" | "s";
  emptyValue?: string;
  onChangeCustom: (
    jaren: number,
    percentage: number,
    renteVariant: RentevariantOptions,
    rentebedenktijdInMaanden: number
  ) => void;
};

const RentevariantPresentation = ({
  rentevarianten,
  selected,
  name,
  appendChildren,
  fieldSize,
  emptyValue,
  formik: { setFieldValue, values },
  onChangeCustom
}: { formik: FormikContextType<HypothekenState> } & RenteVariantPresentationProps &
  InputHTMLAttributes<HTMLSelectElement>): ReactElement => {
  const product = values.producten[selected];

  const getRenteVariant = (renteAfspraak: RentevariantMetRenteperiodesRenteafspraak | null): string => {
    return renteAfspraak === RentevariantMetRenteperiodesRenteafspraak._1
      ? RentevariantOptions.Rentevast
      : renteAfspraak === RentevariantMetRenteperiodesRenteafspraak._3
        ? RentevariantOptions.Variabel
        : "";
  };

  const getLabelRvp = (rentePct: RenteperiodeMetRentepercentage, renteVariant: RentevariantOptions): string => {
    const termijn = renteVariant === RentevariantOptions.Rentevast ? 12 : 1;
    const termijnTekst = renteVariant === RentevariantOptions.Rentevast ? " jaar" : "-maands";

    if (renteVariant === "Rentevast") {
      const rentebedenktijdminus =
        rentePct.rentevastAantalMaanden && rentePct.rentebedenktijdInMaanden
          ? (rentePct.rentevastAantalMaanden - rentePct.rentebedenktijdInMaanden) / termijn
          : 0;

      return `${rentePct.rentevastAantalMaanden ? rentePct.rentevastAantalMaanden / termijn : ""}${termijnTekst} ${
        rentePct.rentebedenktijdInMaanden
          ? `(${rentebedenktijdminus}+${rentePct.rentebedenktijdInMaanden / termijn} jaar RBT)`
          : ""
        } (Rente: ${
        rentePct.rentepercentage?.percentage ? percentageFormat(rentePct.rentepercentage?.percentage, 2) + "%)" : ""
        }`;
    } // "Variabel
    else {
      return `${rentePct.rentevastAantalMaanden}${termijnTekst} (Rente: ${
        rentePct.rentepercentage?.percentage ? percentageFormat(rentePct.rentepercentage?.percentage, 2) + "%)" : ""
        }`;
    }
  };

  const heeftHypotheekOpties = values?.hypotheekOptie?.hypotheekOpties?.length > 0;
  const tooltip = heeftHypotheekOpties ? getHypotheekTextResources("RentePercentageTooltip") : undefined;

  // Labels voor rentevariant vullen (Vast, Variabel)
  const renteVariantOptions =
    rentevarianten?.rentevarianten?.map(
      (c): LabelValuePair => ({
        label: c.omschrijving || "",
        value: getRenteVariant(c.renteafspraak) || ""
      })
    ) || [];

  // Labels voor rentevastperiodes vullen
  const renteVastPeriodeOptions =
    rentevarianten?.rentevarianten
      ?.filter(c => getRenteVariant(c.renteafspraak) === product.leningdeelgegevens.renteVariant)
      ?.map(c => c.renteperiodes)[0]
      ?.map(p => {
        return {
          label: getLabelRvp(p, product.leningdeelgegevens.renteVariant),
          value: `${p.rentevastAantalMaanden ? p.rentevastAantalMaanden / 12 : 0}`
        } as LabelValuePair;
      }) || [];

  return (
    <>
      <LabeledRadioInput
        caption={"Rentevariant"}
        options={renteVariantOptions}
        name={`${name}.renteVariant`}
        appendChildren={renteVastPeriodeOptions.length < 1 && <div>{appendChildren}</div>}
        onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
          // Geef de juiste rentepercentage door bij het aanpassen van de rentevariant buttons
          const renteperiodes = rentevarianten?.rentevarianten
            ?.filter(c => getRenteVariant(c.renteafspraak) === e.target.value)
            ?.map(c => c.renteperiodes)[0];

          /* istanbul ignore else */
          if (renteperiodes) {
            if (e.target.value === RentevariantOptions.Variabel) {
              onChangeCustom(
                10,
                renteperiodes[0].rentepercentage?.percentage || 0,
                RentevariantOptions.Variabel,
                renteperiodes[0].rentebedenktijdInMaanden || 0
              );
            } else {
              onChangeCustom(
                10,
                renteperiodes.find(c => (c.rentevastAantalMaanden ? c.rentevastAantalMaanden / 12 : null) === 10)
                  ?.rentepercentage?.percentage || 0,
                RentevariantOptions.Rentevast,
                renteperiodes[0].rentebedenktijdInMaanden || 0
              );
            }
          }
        }}
      />

      {renteVastPeriodeOptions.length > 1 && (
        <LabeledSelectInput
          caption={"Rentevastperiode"}
          name={`${name}.rentevastperiodeKeuze`}
          tooltip={tooltip}
          options={renteVastPeriodeOptions}
          appendChildren={appendChildren}
          fieldSize={fieldSize}
          emptyValue={emptyValue}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>): void => {
            // Geeft de juiste rentepercentage door bij het aanpassen van de rentevastperiode
            onChangeCustom(
              parseFloat(e.target.value) || /* istanbul ignore next */ 0,
              rentevarianten?.rentevarianten
                ?.filter(c => getRenteVariant(c.renteafspraak) === product.leningdeelgegevens.renteVariant)
                ?.map(c => c.renteperiodes)[0]
                ?.find(
                  c => (c.rentevastAantalMaanden ? c.rentevastAantalMaanden / 12 : null) === parseFloat(e.target.value)
                )?.rentepercentage?.percentage || /* istanbul ignore next */ 0,
              product.leningdeelgegevens.renteVariant,
              rentevarianten?.rentevarianten
                ?.filter(c => getRenteVariant(c.renteafspraak) === product.leningdeelgegevens.renteVariant)
                ?.map(c => c.renteperiodes)[0]
                ?.find(
                  c => (c.rentevastAantalMaanden ? c.rentevastAantalMaanden / 12 : null) === parseFloat(e.target.value)
                )?.rentebedenktijdInMaanden || /* istanbul ignore next */ 0
            );
          }}
        />
      )}

      {renteVastPeriodeOptions.length === 1 && (
        <LabeledResult
          caption={"Rentevastperiode"}
          name={`${name}.rentevastPeriodeJaarTekst`}
          appendChildren={appendChildren}
          tooltip={tooltip}
          result={(): string => {
            const rvpPeriode = rentevarianten?.rentevarianten
              ?.filter(c => getRenteVariant(c.renteafspraak) === product.leningdeelgegevens.renteVariant)
              ?.map(c => c.renteperiodes)[0]
              ?.map(p => p)[0];
            return rvpPeriode ? getLabelRvp(rvpPeriode, product.leningdeelgegevens.renteVariant) : "";
          }}
        />
      )}
    </>
  );
};

RentevariantPresentation.displayName = "RentevariantPresentation";

export default connect<RenteVariantPresentationProps & InputHTMLAttributes<HTMLSelectElement>, HypothekenState>(
  RentevariantPresentation
);
