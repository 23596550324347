import { Schema } from "yup";

/**
 * Helpers to filter readonly properties
 */

type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <T>() => T extends Y ? 1 : 2 ? A : B;

type WritableKeys<T> = {
  [P in keyof T]-?: IfEquals<{ [Q in P]: T[P] }, { -readonly [Q in P]: T[P] }, P>;
}[keyof T];

type ReadonlyKeys<T> = {
  [P in keyof T]-?: IfEquals<{ [Q in P]: T[P] }, { -readonly [Q in P]: T[P] }, never, P>;
}[keyof T];

type PickWritable<T> = T extends object
  ? Pick<
      {
        [P in keyof T]: SetReadonlyOptional<T[P]>;
      },
      WritableKeys<T>
    >
  : T;

type PickReadonly<T> = T extends object
  ? Pick<
      {
        [P in keyof T]+?: SetReadonlyOptional<T[P]>;
      },
      ReadonlyKeys<T>
    >
  : T;

type SetReadonlyOptional<T> = PickWritable<T> & PickReadonly<T>;

/**
 * S = Source
 * D = Destination
 * C = Context
 */

type MapWithContext<S, D, C> = {
  [P in keyof D]: (from: S, context: C, index?: number) => D[P];
};

type MapWithoutContext<S, D> = {
  [P in keyof D]: (from: S, index?: number) => D[P];
};

type Mapper<S, D, C> = Exclude<C, undefined> extends never
  ? (source: S, index?: number) => D
  : (context: C) => (source: S, index?: number) => D;

type CreateMapToDlReturnType<S> = {
  with: <C>(_?: Schema<C>) => { to: <D = never>(map: MapWithContext<S, SetReadonlyOptional<D>, C>) => Mapper<S, D, C> };
  to: <D = never>(map: MapWithoutContext<S, SetReadonlyOptional<D>>) => Mapper<S, D, undefined>;
};

export const createMapToDl = <F, S>(_: Schema<S>): CreateMapToDlReturnType<S> => {
  const withoutContext = <D = never>(map: MapWithoutContext<S, SetReadonlyOptional<D>>): Mapper<S, D, undefined> => {
    const mapper = (source: S, index?: number): D => {
      const keys = Object.keys(map);
      const result: any = {};
      for (let i = 0; i < keys.length; i++) {
        const prop = keys[i];
        result[prop] = (map as any)[prop](source, index);
      }
      return result;
    };
    return mapper as Mapper<S, D, undefined>;
  };

  const withContext = <D = never, C = undefined>(
    map: MapWithContext<S, SetReadonlyOptional<D>, C>
  ): Mapper<S, D, C> => {
    const mapper = (context: C) => (source: S, index?: number): D => {
      const keys = Object.keys(map);
      const result: any = {};
      for (let i = 0; i < keys.length; i++) {
        const prop = keys[i];
        result[prop] = (map as any)[prop](source, context, index);
      }
      return result;
    };
    return mapper as Mapper<S, D, C>;
  };

  return {
    with: (): { to: typeof withContext } => ({ to: withContext }),
    to: withoutContext
  };
};
