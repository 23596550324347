import React from "react";
import { Table } from "react-bootstrap";
import { bedragFormatNull } from "../infra/scenario-utils";

export interface BenodigdKapitaalTableProps {
  benodigdKapitaal: number | null;
  benodigdKapitaalOmschrijving: string;
}

export const BenodigdKapitaalTable: React.FC<{
  benodigdKapitaal: number | null;
  benodigdKapitaalOmschrijving: string;
}> = ({ benodigdKapitaal, benodigdKapitaalOmschrijving }: any) => (
  <Table striped bordered hover responsive className="scenario__table mb-4">
    <colgroup>
      <col style={{ width: "40%" }} />
      <col style={{ width: "20%" }} />
      <col style={{ width: "20%" }} />
      <col style={{ width: "20%" }} />
    </colgroup>
    <thead>
      <tr>
        <th>Uw risico o.b.v. uw gewenste inkomen</th>
        <th></th>
        <th></th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{benodigdKapitaalOmschrijving}</td>
        <td>{bedragFormatNull(benodigdKapitaal)}</td>
        <td></td>
        <td></td>
      </tr>
    </tbody>
  </Table>
);
