import React, { ReactElement } from "react";
import { FormikProps } from "formik";
import { Label, CurrencyInput } from "adviesbox-shared";

import AdviesBoxFormik from "../../shared/utils/adviesbox-formik";
import {
  ScenarioCard,
  ScenarioInputParams,
  ScenarioWrappers
} from "../../shared/components/scenario-input/scenario-card";
import Modal from "../../shared/components/modal/Modal";

import { AanvangExtraPremieStortingenModalType } from "../infra/producten-overzicht-types";
import { aanvangExtraPremieStortingenModalSchema } from "../infra/producten-overzicht-schema";

export type AanvangExtraPremieStortingenModalData = {
  data: AanvangExtraPremieStortingenModalType;
  entries: number | null;
};

type AanvangExtraPremieStortingenModalProps = {
  onSave?: (data: AanvangExtraPremieStortingenModalType) => void;
  closeModal?: () => void;
};

const scenarioCardWrapper = (
  itemIndex: number,
  entries: number,
  scenarieInputParams: ScenarioInputParams
): ReactElement => {
  return (
    <div className="d-flex flex-wrap scenario-container" key={itemIndex}>
      <div className="pr-2 col-3">
        <Label caption={scenarieInputParams.captionStart + itemIndex} name={`scenario[${itemIndex}].bedrag`} />
      </div>
      <div className="pr-1">
        <CurrencyInput name={`scenario[${itemIndex}].bedrag`} fieldSize="m" />
      </div>
    </div>
  );
};

const scenarioHeaderWrapper = (columnIndex: number): ReactElement => {
  return (
    <div className="d-flex flex-wrap scenario-container" key={`header-${columnIndex}`}>
      <div className="pr-2 col-3">Jaar</div>
      <div className="pr-1">Bedrag</div>
    </div>
  );
};

const scenarieWrappers: ScenarioWrappers = {
  scenarioCardWrapper: scenarioCardWrapper,
  scenarioHeaderWrapper: scenarioHeaderWrapper
};

const AanvangExtraPremieStortingenModal = ({
  data,
  entries,
  onSave,
  closeModal
}: AanvangExtraPremieStortingenModalData & AanvangExtraPremieStortingenModalProps): ReactElement => (
  <AdviesBoxFormik<AanvangExtraPremieStortingenModalType>
    initialValues={{ ...data }}
    validationSchema={aanvangExtraPremieStortingenModalSchema}
    onSave={onSave}
    closeModal={closeModal}
    render={({ submitForm }: FormikProps<AanvangExtraPremieStortingenModalType>): ReactElement => (
      <Modal
        title="Extra inleg"
        body={
          <ScenarioCard
            name="scenario"
            scenarioWrappers={scenarieWrappers}
            entries={entries ?? 0}
            vervolgjarenAutomatischVullen={false}
          />
        }
        onSubmitClick={submitForm}
        onCancelClick={closeModal}
      />
    )}
  />
);

AanvangExtraPremieStortingenModal.displayName = "AanvangExtraPremieStortingenModal";

export default AanvangExtraPremieStortingenModal;
