import { ToelichtingRegel, ToelichtingOptions } from "../../../.generated/rapportage/rapportagetypes";
import React, { createContext, useMemo, useContext, useCallback, ReactNode } from "react";

// Context to avoid prop drilling
interface CitationContextType {
  voetnoten: ToelichtingRegel[];
  excludedFootnotes: string[];
  activeRef: string | null;
  setActiveRef: (ref: string | null) => void;
}

export const CitationContext = createContext<CitationContextType | null>(null);
export const excludedFootnotes = ["ExtraAflossingen"];

// Provider component
export const CitationProvider: React.FC<{
  children: React.ReactNode;
  voetnoten: ToelichtingRegel[];
  excludedFootnotes: string[];
}> = ({ children, voetnoten, excludedFootnotes }: {children:ReactNode, voetnoten: ToelichtingRegel[], excludedFootnotes: string[]}) => {
  const [activeRef, setActiveRef] = React.useState<string | null>(null);

  const value = useMemo(() => ({
    voetnoten,
    excludedFootnotes,
    activeRef,
    setActiveRef
  }), [voetnoten, excludedFootnotes, activeRef]);

  return (
    <CitationContext.Provider value={value}>
      {children}
    </CitationContext.Provider>
  );
};

// Custom hook for citations
const useCitations = ():CitationContextType => {
  const context = useContext(CitationContext);
  if (!context) {
    throw new Error('useCitations must be used within a CitationProvider');
  }
  return context;
};

// Optimized utility functions
const scrollToElement = (elementId: string):void => {
  document.getElementById(elementId)?.scrollIntoView({
    behavior: 'smooth',
    block: 'center'
  });
};

export const useFootnoteHandlers = (): { handleReference:(type: string) => void, handleFootnote:(type: string) => void } => {
  const { setActiveRef } = useCitations();

  const handleReference = useCallback((type: string) => {
    setActiveRef(type);
    scrollToElement(`footnote-${type}`);
  }, [setActiveRef]);

  const handleFootnote = useCallback((type: string) => {
    setActiveRef(type);
    scrollToElement(`ref-${type}`);
  }, [setActiveRef]);

  return { handleReference, handleFootnote };
};

// Memoized citation number calculator
export const useCitationNumber = (type: ToelichtingOptions): number | null => {
  const { voetnoten, excludedFootnotes } = useCitations();

  return useMemo(() => {
    const countExcluded = voetnoten?.filter(v => excludedFootnotes.includes(v.type)).length;
    const index = voetnoten?.findIndex(v => v.type === type);
    return index !== -1 ? index + 1 - countExcluded : null;
  }, [voetnoten, excludedFootnotes, type]);
};

// Optimized components
export const CitationRef: React.FC<{
  type: ToelichtingOptions;
}> = React.memo(({ type }:{type:ToelichtingOptions}) => {
  const number = useCitationNumber(type);
  const { handleReference } = useFootnoteHandlers();

  if (number === null) return null;

  return (
    <sup className="reference">
      <a
        href={`#footnote-${type}`}
        id={""}
        onClick={(e) => {
          e.preventDefault();
          handleReference(type);
        }}
        className="inline-flex text-blue-600 hover:text-blue-800"
      >
        <span className="cite-bracket">[</span>
        {number}
        <span className="cite-bracket">]</span>
      </a>
    </sup>
  );
});

CitationRef.displayName = 'CitationRef';

export const CitationNote: React.FC<{
  type: ToelichtingOptions;
  text: string;
}> = React.memo(({ type, text }:{
  type: ToelichtingOptions;
  text: string;
}) => {
  const { excludedFootnotes } = useCitations();
  const number = useCitationNumber(type);
  const { handleFootnote } = useFootnoteHandlers();

  if (excludedFootnotes.includes(type)) {
    return (
      <div id={`footnote-${type}`} className="reference group">
        <span className="text-sm text-gray-700">{text}</span>
      </div>
    );
  }

  return (
    <div id={`footnote-${type}`} className="reference group">
      <span className="font-mono text-sm mr-2">
        <a
          href={`#ref-${type}`}
          onClick={(e) => {
            e.preventDefault();
            handleFootnote(type);
          }}
          className="inline-flex items-center text-blue-600 hover:text-blue-800"
        >
          <span className="cite-bracket">[</span>
          {number}
          <span className="cite-bracket">]</span>
        </a>
      </span>
      <span className="text-sm text-gray-700">{text}</span>
    </div>
  );
});

CitationNote.displayName = 'CitationNote';