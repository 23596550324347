/* istanbul ignore file */
/* eslint-disable react/prop-types */
import React, { Suspense } from "react";
import { DataDisplay, Loading } from "../data-display/data-display";
import {
  DashboardOutput,
  DashboardTekort,
  DashboardScenarioOverlijden,
  KlantnaamReadOnly
} from "../../../.generated/forms/formstypes";
import { Tekort } from "../../components/tekort/tekort";
import { uitgangspuntenSchema, tekortSchema } from "../../infra/dashboard-schema";
import { klantnaamSchema } from "../../../shared/generic-parts/klantnaam/schema";
import { LoadingSpinner } from "adviesbox-shared";

interface OverlijdenResultaatProps {
  onClick: () => void;
  situatie: string;
}

const getAanvrager = (data: DashboardOutput, partner = false): KlantnaamReadOnly => {
  const dash = data.dashboards?.[Object.keys(data.dashboards)[0]];
  return (partner ? (dash?.aanvrager2 as any) : (dash?.aanvrager1 as any)) ?? klantnaamSchema.default();
};

const getOverlijden = (data: DashboardOutput): DashboardScenarioOverlijden | null => {
  const dash = data.dashboards?.[Object.keys(data.dashboards)[0]];
  return dash?.overlijden ?? null;
};

const getTekort = (data: DashboardOutput): DashboardTekort => {
  return getOverlijden(data)?.tekort ?? tekortSchema.default();
};

export const OverlijdenResultaat: React.FC<OverlijdenResultaatProps> = ({ onClick, situatie }) => {
  return (
    <Suspense fallback={<Loading />}>
      <button
        type="button"
        className="new-dashboard__entry-btn"
        data-testid="button-testid"
        onClick={onClick}
      >
        <h2 className="mb-2">OVERLIJDEN</h2>
        <DataDisplay
          endpoint="Overlijden?berekenTekort=true"
          autostart={false}
          isVoorstel={situatie === "voorstel"}
          loader={() => <LoadingSpinner size="S" />}
          render={(data: DashboardOutput) => {
            if (data?.dashboards?.[Object.keys(data.dashboards)[0]].overlijden === null) return <></>;
            if (data?.dashboards?.[Object.keys(data.dashboards)[0]].overlijden !== null) {
              return (
                <div>
                  <Tekort
                    header="Overlijden"
                    tekort={getTekort(data)}
                    uitgangspunten={getOverlijden(data)?.uitgangspunten ?? uitgangspuntenSchema.default()}
                    aanvrager={getAanvrager(data) as any}
                    partner={getAanvrager(data, true) as any}
                  />
                </div>
              );
            }
          }}
        />
      </button>
    </Suspense>
  );
};
