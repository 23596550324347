import { SettingsType } from "adviesbox-shared";
import { FormikContextType } from "formik";
import { User } from "oidc-client";
import { Bankgarantie } from "../../.generated/forms/formstypes";
import { validationResultsToUiError } from "../../shared/utils/validation-results-to-ui-error";
import { BankgarantieState } from "./bankgarantie-schema";
import { mapDlTargetToBankgarantieUiField } from "./map-bankgarantie-dl-target-to-ui-field";

export const aanvraagVerzenden = async (
  settings: SettingsType,
  user: User | null,
  vestigingId: string,
  adviesdossierId: string,
  aanvraag: Bankgarantie,
  setFieldValue: FormikContextType<BankgarantieState>["setFieldValue"]
): Promise<any | string> => {
  const url = `${settings.klantdossiersFormsOrigin}/Adviesdossiers/${adviesdossierId}/BankgarantieAanvraag`;
  const rsp = await fetch(url, {
    method: "POST",
    headers: {
      authorization: `${user?.token_type} ${user?.access_token}`,
      "Ocp-Apim-Subscription-Key": settings.OcpApimSubscriptionKey,
      "Content-Type": "application/json;charset=UTF-8",
      vestigingId
    },
    body: JSON.stringify(aanvraag)
  });

  setFieldValue("aanvraagVerzendenError", false);

  if (!rsp.ok) {
    const jsonConceptAanvraag = await rsp.json();

    if (jsonConceptAanvraag && jsonConceptAanvraag.validationResults) {
      validationResultsToUiError(jsonConceptAanvraag.validationResults, mapDlTargetToBankgarantieUiField);
    }

    setFieldValue("aanvraagVerzendenError", true);

    const error = `Fout bij aanroep van web-api '${url}'. Resultaat bevat geen JSON.`;
    return error;
  }

  const jsonConceptAanvraag = await rsp.json();
  if (!jsonConceptAanvraag.isValid) {
    const error = `Fout bij aanroep van web-api '${url}'. Input is niet valid`;
    return error;
  }

  setFieldValue("aanvraagVerzendenError", false);
  return jsonConceptAanvraag.nwbAanvraag ?? jsonConceptAanvraag.bnpAanvraag;
};
