import React, { ReactElement } from "react";
import { FormikProps } from "formik";
import AdviesBoxFormik from "../../shared/utils/adviesbox-formik";
import Modal from "../../shared/components/modal/Modal";
import {
  EigenwoningschuldModalType,
  EigenwoningschuldModalEntryType,
  eigenwoningschuldModalEntrySchema,
  eigenwoningschuldModalSchema
} from "../infra/woonsituatie-schema";
import { getWoonsituatieTextResources } from "../infra/woonsituatie-resources";
import { AdviesBoxColumn, DataGrid } from "adviesbox-shared";

export type EigenwoningschuldModalData = {
  data: EigenwoningschuldModalType;
};

type EigenwoningschuldModalProps = {
  onSave?: (data: EigenwoningschuldModalType) => void;
  closeModal?: () => void;
};

const renteaftrekColumns: AdviesBoxColumn[] = [
  {
    Header: "Bedrag",
    accessor: "bedrag",
    Cell: "CurrencyInput"
  },
  {
    Header: "Begindatum",
    accessor: "begindatum",
    Cell: "DateInput"
  },
  {
    Header: "Einddatum",
    accessor: "einddatum",
    Cell: "DateInput"
  },
  {
    Cell: "DeleteButton"
  }
];

const EigenwoningschuldModal = ({
  data,
  onSave,
  closeModal
}: EigenwoningschuldModalData & EigenwoningschuldModalProps): ReactElement => {
  const body = (
    <div className="px-03">
      <div className="pb-4">{getWoonsituatieTextResources("modalEwsTekst")}</div>
      <DataGrid
        name="entries"
        columns={renteaftrekColumns}
        rowCaption={"Renteaftrek"}
        getNewRowValues={(): EigenwoningschuldModalEntryType => ({
          ...eigenwoningschuldModalEntrySchema.default()
        })}
        className="editable"
      />
    </div>
  );

  return (
    <AdviesBoxFormik<EigenwoningschuldModalType>
      initialValues={{ ...data }}
      validationSchema={eigenwoningschuldModalSchema}
      onSave={onSave}
      closeModal={closeModal}
      render={({ submitForm }: FormikProps<EigenwoningschuldModalType>): ReactElement => (
        <>
          <Modal
            title="Specificatie genoten renteaftrek"
            body={body}
            onSubmitClick={submitForm}
            onCancelClick={closeModal}
          />
        </>
      )}
    />
  );
};

EigenwoningschuldModal.displayName = "EigenwoningschuldModal";

export default EigenwoningschuldModal;
