import React, { ReactElement } from "react";
import { FormikProps } from "formik";

import { CurrencyInput, Label } from "adviesbox-shared";
import {
  ScenarioInputParams,
  ScenarioWrappers,
  ScenarioCard
} from "../../shared/components/scenario-input/scenario-card";
import AdviesBoxFormik from "../../shared/utils/adviesbox-formik";
import Modal from "../../shared/components/modal/Modal";
import { ExtraInlegSpecificatieModalType } from "../infra/vermogen-types";
import { extraInlegJarenSchema } from "../infra/vermogen-schema";

type ExtraInlegSpecificatieModalProps = {
  data: ExtraInlegSpecificatieModalType;
  entries: number | null;
  onSave?: (data: ExtraInlegSpecificatieModalType) => void;
  closeModal?: () => void;
};

const scenarioCardWrapper = (
  itemIndex: number,
  entries: number,
  scenarieInputParams: ScenarioInputParams
): ReactElement => {
  return (
    <div className="d-flex flex-wrap scenario-container" key={itemIndex}>
      <div className="pr-2 col-3">
        <Label caption={scenarieInputParams.captionStart + itemIndex} name={`scenario[${itemIndex}].bedrag`} />
      </div>
      <div className="pr-1">
        <CurrencyInput name={`scenario[${itemIndex}].bedrag`} fieldSize="m" />
      </div>
    </div>
  );
};

const scenarioHeaderWrapper = (columnIndex: number): ReactElement => {
  return (
    <div className="d-flex flex-wrap scenario-container" key={`header-${columnIndex}`}>
      <div className="pr-2 col-3">Jaar</div>
      <div className="pr-1">Bedrag</div>
    </div>
  );
};

const scenarieWrappers: ScenarioWrappers = {
  scenarioCardWrapper: scenarioCardWrapper,
  scenarioHeaderWrapper: scenarioHeaderWrapper
};

const ExtraInlegSpecificatieModal = ({
  data,
  entries,
  onSave,
  closeModal
}: ExtraInlegSpecificatieModalProps): ReactElement => (
  <AdviesBoxFormik<ExtraInlegSpecificatieModalType>
    initialValues={data}
    validationSchema={extraInlegJarenSchema}
    onSave={onSave}
    closeModal={closeModal}
    render={({ submitForm }: FormikProps<ExtraInlegSpecificatieModalType>): ReactElement => (
      <Modal
        title="Extra inleg"
        body={
          <ScenarioCard
            name="scenario"
            scenarioWrappers={scenarieWrappers}
            entries={entries ?? 0}
            vervolgjarenAutomatischVullen={true}
          />
        }
        onSubmitClick={submitForm}
        onCancelClick={closeModal}
      />
    )}
  />
);

ExtraInlegSpecificatieModal.displayName = "ExtraInlegSpecificatieModal";

export default ExtraInlegSpecificatieModal;
