import { ReactElement } from "react";
import { Column } from "react-table-6";
import { createSpanWithId } from "adviesbox-shared";
import { castUitslag2Icon } from "../data-grid-icons/data-grid-icons";
import { maandenNaarJrMnd } from "../../../../shared/generic-parts/jaar-maand/helpers";
import { decimalFormat } from "../../../../shared/utils/decimal-format";
import { bedragFormat } from "../../../../shared/utils/currency";
import { HypotheekOversluitenDataGridTemplates } from "../../../infra/hypotheek-oversluiten-schema";

export const getHypotheekOversluitenColumnTemplates: { [key: string]: Column } = {
  [HypotheekOversluitenDataGridTemplates.akkoordOversluiten]: {
    Header: "Advies",
    accessor: "akkoordOversluiten",
    id: "iconColumn",
    Cell: (c): ReactElement => createSpanWithId(c.index, 0, castUitslag2Icon(c.original.akkoordOversluiten)),
    width: 75
  },
  [HypotheekOversluitenDataGridTemplates.maatschappijNaam]: {
    Header: "Maatschappij",
    accessor: "maatschappijNaam",
    id: "maatschappijNaam",
    Cell: (c): ReactElement => createSpanWithId(c.index, 1, c.original.maatschappijNaam),
    width: 250
  },
  [HypotheekOversluitenDataGridTemplates.rentePercentage]: {
    Header: "Rente",
    accessor: "rentePercentage",
    id: "rentePercentage",
    Cell: (c): ReactElement => createSpanWithId(c.index, 2, `${decimalFormat(c.original.rentePercentage, 3)}%`),
    width: 100
  },
  [HypotheekOversluitenDataGridTemplates.brutoMaandlast]: {
    Header: "Bruto maandlast",
    accessor: "brutoMaandlast",
    id: "brutoMaandlast",
    Cell: (c): ReactElement => createSpanWithId(c.index, 3, bedragFormat(c.original.brutoMaandlast, 2)),
    width: 110
  },
  [HypotheekOversluitenDataGridTemplates.besparingMaand]: {
    Header: "Besparing maand",
    accessor: "besparingMaand",
    id: "besparingMaand",
    Cell: (c): ReactElement => createSpanWithId(c.index, 4, bedragFormat(c.original.besparingMaand, 2)),
    width: 110
  },
  [HypotheekOversluitenDataGridTemplates.oversluitkosten]: {
    Header: "Oversluitkosten",
    accessor: "oversluitkosten",
    id: "oversluitkosten",
    Cell: (c): ReactElement => createSpanWithId(c.index, 5, bedragFormat(c.original.oversluitkosten)),
    width: 100
  },
  [HypotheekOversluitenDataGridTemplates.besparingInRvp]: {
    Header: "Besparing in rvp",
    accessor: "besparingInRvp",
    id: "besparingInRvp",
    Cell: (c): ReactElement => createSpanWithId(c.index, 6, bedragFormat(c.original.besparingInRvp, 0)),
    width: 110
  },
  [HypotheekOversluitenDataGridTemplates.terugverdienPeriodeInMaanden]: {
    Header: "Terugverdienperiode",
    accessor: "terugverdienPeriodeInMaanden",
    id: "terugverdienPeriodeInMaanden",
    Cell: (c): ReactElement => createSpanWithId(c.index, 7, maandenNaarJrMnd(c.original.terugverdienPeriodeInMaanden)),
    width: 130
  },
  [HypotheekOversluitenDataGridTemplates.terugverdienPeriodePercentage]: {
    Header: "Terugverdienpercentage",
    accessor: "terugverdienPeriodePercentage",
    id: "terugverdienPeriodePercentage",
    Cell: (c): ReactElement =>
      createSpanWithId(c.index, 8, `${decimalFormat(c.original.terugverdienPeriodePercentage, 3)}%`),
    width: 145
  }
};
