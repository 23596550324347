import { AdviesBoxFormik, ProgressBar, StepType } from "adviesbox-shared";
import { FormikProps } from "formik";
import React, { MutableRefObject, ReactElement, useState } from "react";
import Modal from "../../shared/components/modal/Modal";
import { getFooterElement, getHypotheekVergelijkenButtonText, onSubmitWizard } from "./hypotheek-vergelijken-helper";
import { checkTotalen } from "./hypotheek-vergelijken-modal-utils";
import classes from "./hypotheek-vergelijken-modal.module.scss";
import { hypotheekVergelijkenSchema } from "./hypotheek-vergelijken-schema";
import { Stap1 } from "./hypotheek-vergelijken-stap1";
import { Stap2 } from "./hypotheek-vergelijken-stap2";
import { Stap3 } from "./hypotheek-vergelijken-stap3";
import { HypotheekVergelijkenModalType } from "./hypotheek-vergelijken-types";
import HypotheekTotalen from "../hypotheek-totalen/hypotheek-totalen";

type HypotheekVergelijkenModalProps = {
  data: HypotheekVergelijkenModalType;
  selected: number;
  onSave?: (data: HypotheekVergelijkenModalType) => void;
  closeModal?: () => void;
  situatie: "voorstel" | "huidig";
  initialStep?: number;
  abortController?: AbortController;
  marktwaardeLtv?: MutableRefObject<
    | {
        bedrag: number | null;
        berekenen: boolean | null;
        berekendBedrag: number | null;
      }
    | undefined
  >;
};

export type HypotheekVergelijkenSchemaContextType = {
  leningdeelHoofdsomTotaalError: boolean;
};

export const HypotheekvergelijkenModal = ({
  data,
  onSave,
  closeModal: closeModalParent,
  situatie,
  initialStep = 1,
  marktwaardeLtv,
  abortController = new AbortController()
}: HypotheekVergelijkenModalProps): ReactElement => {
  const VergelijkenModal = ({
    submitForm,
    values: formikValues,
    isValid,
    closeModal: closeModalFromProps
  }: FormikProps<HypotheekVergelijkenModalType> & { closeModal?: () => void }): ReactElement => {
    const progressState = useState(initialStep);
    const [currentStep, setCurrentStep] = progressState;
    const steps: { [key: string]: StepType } = {
      Hypotheeksamenstelling: { component: <Stap1 />, type: "full" },
      Voorwaardenselectie: { component: <Stap2 />, type: "full" },
      Resultaat: { component: <Stap3 />, type: "full" }
    };

    return (
      <div className={"modal-with-cards"}>
        <Modal
          onSubmitClick={() => {
            return onSubmitWizard(
              formikValues,
              currentStep,
              Object.keys(steps).length,
              setCurrentStep,
              submitForm,
              closeModalFromProps
            );
          }}
          submitDisabled={
            !formikValues.hypotheekSamenstelling.length ||
            !isValid ||
            (currentStep === 3 && !formikValues.selectedResultaat)
          }
          onCancelClick={
            /* istanbul ignore next */ () => {
              abortController.abort();
              if (marktwaardeLtv) {
                marktwaardeLtv.current = formikValues.panden.find(() => true)?.marktwaardeLtv;
              }

              closeModalFromProps && closeModalFromProps();
            }
          }
          saveButtonTekst={getHypotheekVergelijkenButtonText(currentStep)}
          title="Hypotheekvergelijker"
          body={
            <>
              <div className={classes.progressBar}>
                <ProgressBar progressState={progressState} steps={steps} />
              </div>
              <div className="d-flex mt-4">
                <HypotheekTotalen situatie={situatie} />
              </div>
              <div className={"mt-4"}>{Object.values(steps)[currentStep - 1].component}</div>
            </>
          }
          prependToFooter={getFooterElement(currentStep, setCurrentStep, formikValues)}
        />
      </div>
    );
  };

  return (
    <AdviesBoxFormik<HypotheekVergelijkenModalType>
      initialValues={data}
      validationSchema={hypotheekVergelijkenSchema}
      onSave={onSave}
      createValidationContext={(props, values): HypotheekVergelijkenSchemaContextType => {
        return checkTotalen(values);
      }}
      validateOnMount={false}
      render={(props: FormikProps<HypotheekVergelijkenModalType>) => (
        <VergelijkenModal closeModal={closeModalParent} {...props} />
      )}
    />
  );
};
