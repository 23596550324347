import { LabeledRadioInput, LabeledSelectInput } from "adviesbox-shared";
import { connect, FormikContextType } from "formik";
import React, { ReactElement } from "react";
import LabeledHdnKeuzelijst from "../../shared/components/hdn-keuzelijst/LabeledHdnKeuzelijst";
import { getAanvraagLeningTextResources } from "../infra/aanvraag-lening-resources";
import {
  AanleidingGesprek,
  AanvraagLeningState,
  RedenAankoop,
  SoortFinanciering,
  SoortOversluiting
} from "../infra/aanvraag-lening-schema";

const Financiering = ({
  formik: {
    values: { financiering, lening }
  }
}: {
  formik: FormikContextType<AanvraagLeningState>;
}): ReactElement => {
  const oversluiten = lening.geselecteerdVoorstel?.soortFinanciering === SoortFinanciering.Oversluiten;

  let redenAanvraagDisabled = false;

  if (
    oversluiten &&
    financiering.aanleidingGesprek === AanleidingGesprek.Echtscheiding &&
    lening.geselecteerdVoorstel?.nhg &&
    financiering.verbouwingskosten !== null &&
    financiering.verbouwingskosten > 0
  ) {
    financiering.redenAanvraag = "28";
    redenAanvraagDisabled = true;
  }

  if (
    oversluiten &&
    financiering.aanleidingGesprek === AanleidingGesprek.Echtscheiding &&
    lening.geselecteerdVoorstel?.nhg &&
    financiering.verbouwingskosten === 0
  ) {
    financiering.redenAanvraag = "31";
    redenAanvraagDisabled = true;
  }

  return (
    <>
      {!!lening.geselecteerdVoorstel?.nhg && (
        <LabeledHdnKeuzelijst
          caption="Reden aanvraag"
          name="financiering.redenAanvraag"
          berichtSoortType="AX"
          keuzelijst="MutatieType"
          readonly={redenAanvraagDisabled}
        />
      )}
      {/* De Hdn_Lst_Code_Aankoop wordt niet gevonden en de requirements stelt dat dan Axshare gevolgd moet worden. 
      Daarom onderstaande implementetie gebruikt i.p.v. <LabeledHdnKeuzelijst> */}
      <LabeledSelectInput
        caption="Reden"
        name="financiering.redenAankoop"
        options={[
          { label: "100% verhuurd ", value: RedenAankoop.GeheelVerhuurd },
          { label: "Deels verhuurd", value: RedenAankoop.DeelsVerhuurd },
          { label: "Tweede woning", value: RedenAankoop.TweedeWoning },
          { label: "Woning eigen gebruik (primaire woning)", value: RedenAankoop.WoningEigenGebruik }
        ]}
      />
      {oversluiten && (
        <LabeledRadioInput
          caption="Soort oversluiting"
          name="financiering.soortOversluiting"
          options={[
            { label: "Interne oversluiting", value: SoortOversluiting.InterneOversluiting },
            {
              label: "Oversluiting andere geldgever",
              value: SoortOversluiting.OversluitingAndereGeldgever
            }
          ]}
          tooltip={getAanvraagLeningTextResources("financieringSoortOversluiting")}
        />
      )}
    </>
  );
};

export default connect<{}, AanvraagLeningState>(Financiering);
