import { mapBerekenInput } from "../../shared/types";
import {
  MaximaleHypotheek as MaximaleHypotheekDlEntry,
  MaximaleHypotheekOutput as MaximaleHypotheekDlOutput,
  MaximaleHypotheek,
  ToetsrenteBerekeningOptions,
  AflossingsVormType,
  Leningdeel
} from "../../.generated/forms/formstypes";

import {
  maximaleHypotheekSchema,
  modalLeningdelenSchema,
  MaximaleHypotheekState,
  ToetsrenteBerekeningObv as TypesToetsrenteBerekeningObv,
  uitgangspuntenSchema,
  toetsrenteSchema,
  inkomenSchema,
  leningdelenUitHetVerledenSchema,
  ModalAflossingsVormType,
  persoonsgegevensSchema,
  organisatieSchema,
  OrganisatieState
} from "./maximale-hypotheek-schema";

import { InferType } from "prop-types";
import { mapKlantnaamDl2Ui } from "../../shared/generic-parts/klantnaam/map-dl-2-ui";
import { maandenNaarJaarMaand } from "../../shared/generic-parts/jaar-maand/helpers";
import { createMapToUi } from "../../shared/utils/create-map-to-ui";
import { Organisatie, OrganisatiesOutput } from "../../.generated/licenties/licentiestypes";
import { prefixWithZero } from "adviesbox-shared";

const mapToetsrenteBerekenenObv: Record<ToetsrenteBerekeningOptions, TypesToetsrenteBerekeningObv> &
  Record<TypesToetsrenteBerekeningObv, ToetsrenteBerekeningOptions> = {
  SpecificatieToetsrente: TypesToetsrenteBerekeningObv.SpecificatieToetsrente,
  GewensteRentevastperiode: TypesToetsrenteBerekeningObv.GewensteRentevastperiode,
  "Gewenste rentevastperiode": ToetsrenteBerekeningOptions.GewensteRentevastperiode,
  "Specificatie toetsrente": ToetsrenteBerekeningOptions.SpecificatieToetsrente
};

const mapUitgangspunten = createMapToUi(uitgangspuntenSchema).from<MaximaleHypotheek>({
  andereFinanciering: v => v.andereFinancieringBedrag,
  gewensteHypotheek: v => v.gewensteHypotheekBedrag,
  gewensteRentevastperiode: v =>
    v.gewensteRentevastperiodeInMaanden ? v.gewensteRentevastperiodeInMaanden / 12 : null,
  looptijd: v => (v.hypotheekLooptijdInMaanden ? v.hypotheekLooptijdInMaanden / 12 : null),
  marktwaarde: v => v.marktwaardeBedrag,
  nhg: v => v.nhg,
  totaleLening: v => (v.andereFinancieringBedrag || 0) + (v.gewensteHypotheekBedrag || 0),
  objectSoort: v => prefixWithZero(v.objectSoort),
  recreatieveBewoning: v => v.recreatieveBewoning
});

const mapToetsrente = createMapToUi(toetsrenteSchema).from<MaximaleHypotheek>({
  berekeningOpBasisVan: v => mapToetsrenteBerekenenObv[v.toetsrenteBerekeningOpBasisVan],
  gewensteRentevastperiode: v =>
    v.gewensteRentevastperiodeInMaanden ? v.gewensteRentevastperiodeInMaanden / 12 : null,
  kredietToetslast: v =>
    mapBerekenInput(
      (v.kredietToetslastOvernemen ? v.kredietToetslastBerekendBedrag : v.kredietToetslastBedrag) ?? 0,
      v.kredietToetslastOvernemen,
      v.kredietToetslastBerekendBedrag
    ),
  looptijd: v => (v.hypotheekLooptijdInMaanden ? v.hypotheekLooptijdInMaanden / 12 : null),
  toetsrente: v => v.specificatieToetsrentePercentage,
  vrijVermogen: v => {
    if (v.vrijVermogenBedrag) {
      return Math.max(v.vrijVermogenBedrag, 0);
    }

    return null;
  }
});

const mapInkomen = createMapToUi(inkomenSchema).from<MaximaleHypotheek>({
  aowleeftijdAanvrager: v => maandenNaarJaarMaand(v.aanvrager1?.aowLeeftijdInMaanden || 0),
  aowleeftijdPartner: v => maandenNaarJaarMaand(v.aanvrager2?.aowLeeftijdInMaanden || 0),
  pensioeninkomenAanvrager: v =>
    mapBerekenInput(
      (v.aanvrager1?.pensioeninkomenOvernemen
        ? v.aanvrager1?.pensioeninkomenBerekendBedrag
        : v.aanvrager1?.pensioeninkomenBedrag) ?? 0,
      v.aanvrager1?.pensioeninkomenOvernemen,
      v.aanvrager1?.pensioeninkomenBerekendBedrag
    ),
  pensioeninkomenPartner: v =>
    mapBerekenInput(
      (v.aanvrager2?.pensioeninkomenOvernemen
        ? v.aanvrager2?.pensioeninkomenBerekendBedrag
        : v.aanvrager2?.pensioeninkomenBedrag) ?? 0,
      v.aanvrager2?.pensioeninkomenOvernemen,
      v.aanvrager2?.pensioeninkomenBerekendBedrag
    ),
  toetsinkomenAanvrager: v =>
    mapBerekenInput(
      (v.aanvrager1?.toetsinkomenOvernemen
        ? v.aanvrager1?.toetsinkomenBerekendBedrag
        : v.aanvrager1?.toetsinkomenBedrag) ?? 0,
      v.aanvrager1?.toetsinkomenOvernemen,
      v.aanvrager1?.toetsinkomenBerekendBedrag
    ),
  toetsinkomenPartner: v =>
    mapBerekenInput(
      (v.aanvrager2?.toetsinkomenOvernemen
        ? v.aanvrager2?.toetsinkomenBerekendBedrag
        : v.aanvrager2?.toetsinkomenBedrag) ?? 0,
      v.aanvrager2?.toetsinkomenOvernemen,
      v.aanvrager2?.toetsinkomenBerekendBedrag
    )
});

const mapLeningdelenUitHetVerleden = createMapToUi(leningdelenUitHetVerledenSchema).from<MaximaleHypotheek>({
  leningdelenMeenemen: v => v.leningdelenUitVerledenMeenemen,
  meeTeNemenLeningdelen: v => {
    const leningdelenToConcat =
      v.leningdelenUitVerleden && v.leningdelenUitVerleden.some(ld => !checkIfLeningIsEditable(ld, v))
        ? []
        : v.huidigeLeningdelen ?? [];
    return (
      v.leningdelenUitVerleden &&
      v.leningdelenUitVerleden.concat(leningdelenToConcat).map(
        (ld): InferType<typeof modalLeningdelenSchema> => ({
          leningdeelId: ld.leningdeelId,
          hypotheekvorm: ModalAflossingsVormType[ld.aflossingsvorm as AflossingsVormType],
          opgebouwdeWaarde: ld.opgebouwdeWaardeBedrag,
          hypotheekbedrag: ld.leningdeelBedrag,
          deelBox3: ld.box3Bedrag,
          editable: checkIfLeningIsEditable(ld, v),
          looptijd: ld.restantLooptijdInMaanden
            ? {
                jaren: Math.trunc(ld.restantLooptijdInMaanden / 12),
                maanden: ld.restantLooptijdInMaanden - Math.trunc(ld.restantLooptijdInMaanden / 12) * 12
              }
            : null
        })
      )
    );
  },
  // Copy van leningdelen uit het verleden die gekoppeld zijn aan huidige leningdelen
  readOnlyLeningDelen: v =>
    v.huidigeLeningdelen && v.huidigeLeningdelen.length > 0
      ? v.huidigeLeningdelen.map(
          (ld): InferType<typeof modalLeningdelenSchema> => ({
            leningdeelId: ld.leningdeelId,
            hypotheekvorm: ModalAflossingsVormType[ld.aflossingsvorm as AflossingsVormType],
            opgebouwdeWaarde: ld.opgebouwdeWaardeBedrag,
            hypotheekbedrag: ld.leningdeelBedrag,
            deelBox3: ld.box3Bedrag,
            editable: false,
            looptijd: ld.restantLooptijdInMaanden
              ? {
                  jaren: Math.trunc(ld.restantLooptijdInMaanden / 12),
                  maanden: ld.restantLooptijdInMaanden - Math.trunc(ld.restantLooptijdInMaanden / 12) * 12
                }
              : null
          })
        )
      : []
});

function checkIfLeningIsEditable(lening: Leningdeel, values: MaximaleHypotheek): boolean {
  let propertiesAreTheSame = false;
  values.huidigeLeningdelen?.forEach(ld => {
    if (
      lening.aflossingsvorm === ld.aflossingsvorm &&
      lening.box3Bedrag === ld.box3Bedrag &&
      Math.round(lening.leningdeelBedrag ?? 0) === Math.round(ld.leningdeelBedrag ?? 0) &&
      ld.opgebouwdeWaardeBedrag === lening.opgebouwdeWaardeBedrag
    ) {
      propertiesAreTheSame = true;
    }
  });
  return !propertiesAreTheSame;
}

const mapPersoonsgegevens = createMapToUi(persoonsgegevensSchema).from<MaximaleHypotheek>({
  naamAanvrager: v => mapKlantnaamDl2Ui(v.aanvrager1?.aanvrager),
  naamPartner: v => mapKlantnaamDl2Ui(v.aanvrager2?.aanvrager)
});

function mapMaximaleHypotheek(values: MaximaleHypotheek): MaximaleHypotheekState {
  return {
    inkomen: mapInkomen(values),
    toetsrente: mapToetsrente(values),
    uitgangspunten: mapUitgangspunten(values),
    leningdelenUitHetVerleden: mapLeningdelenUitHetVerleden(values),
    persoonsgegevens: mapPersoonsgegevens(values),
    dataHasChanged: false,
    maximaleHypotheken: [],
    sortedMaximaleHypotheken: [],
    orgData: null
  };
}

function dl2ui(values: MaximaleHypotheekDlEntry): MaximaleHypotheekState {
  const maximaleHypotheek = mapMaximaleHypotheek(values);

  return maximaleHypotheekSchema.cast(maximaleHypotheek);
}

/* istanbul ignore next */
const mapOrganisatie = createMapToUi(organisatieSchema)
  .with<string>()
  .from<Organisatie>({
    id: (_, c) => c,
    bedrijfsnaam: v => v.naam,
    iban: v => v.iban,
    kvkNummer: v => v.kvKnummer,
    logo: v => v.logo,
    platformId: v => v.platformId
  });

/* istanbul ignore next */
export function mapOrganisatieDlToUi(data: OrganisatiesOutput, id: string): OrganisatieState | null {
  // && data.isValid && data.Contract ? data.Contract[contractId] : null;
  const organisatie = data && data.isValid && data.organisaties ? data.organisaties[id] : null;

  if (organisatie) {
    return mapOrganisatie(id)(organisatie);
  }

  return null;
}

export function mapMaximaleHypotheekDlToUi(
  maximaleHypotheekId: string,
  data: MaximaleHypotheekDlOutput
): MaximaleHypotheekState | null {
  const maximaleHypotheek = data && data.maximaleHypotheken ? data.maximaleHypotheken[maximaleHypotheekId] : null;

  if (!maximaleHypotheek) {
    return null;
  }

  return dl2ui(maximaleHypotheek);
}
