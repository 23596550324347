import { assertValue } from "adviesbox-shared";
import { createISWSideEffect, initISWSideEffect } from "../shared/components/isw-side-effects/create-isw-helpers";
import { mapVerbouwingdataDl2Ui } from "./infra/map-taxatie-scherm-dl-to-ui";
import {
  editKenmerken,
  FitrexCode,
  NationaleTaxatieCode,
  NWWICode,
  TaxatiepuntCode
} from "./infra/taxatie-bureaus-helper";
import { taxatieBaseKenmerken, TaxatieSchermType } from "./infra/taxatie-scherm-schema";

const taxatieAanvragenSideEffects = createISWSideEffect<TaxatieSchermType>(({ draft }): void => {
  draft.dataOutDated = true;
  draft.aanvragenInvalidText = [];
});

const isLoadingSideEffects = createISWSideEffect<TaxatieSchermType>(({ draft }): void => {
  draft.isLoading = false;
});

const taxatieAlgemeenSideEffects = createISWSideEffect<TaxatieSchermType>(({ has, draft }): void => {
  if (
    draft.aanvullendeInformatie &&
    draft.taxatieAlgemeen &&
    has.taxatieAlgemeen.taxateurNwwiKeuze?.selectedResultaat.changed &&
    draft.taxatieAlgemeen.taxateurNwwiKeuze?.selectedResultaat
  ) {
    draft.aanvullendeInformatie.taxateur = draft.taxatieAlgemeen.taxateurNwwiKeuze?.selectedResultaat?.bedrijfsnaam;
    draft.aanvullendeInformatie.taxateurnummer =
      draft.taxatieAlgemeen.taxateurNwwiKeuze?.selectedResultaat?.taxatiebureauId;
  }
  if (
    draft.aanvullendeInformatie &&
    draft.taxatieAlgemeen &&
    has.taxatieAlgemeen.geldverstrekkerNwwiKeuze?.selectedResultaat.changed &&
    draft.taxatieAlgemeen.geldverstrekkerNwwiKeuze?.selectedResultaat
  ) {
    draft.taxatieAlgemeen.geldverstrekker =
      draft.taxatieAlgemeen.geldverstrekkerNwwiKeuze?.selectedResultaat?.bedrijfsnaam;
    draft.aanvullendeInformatie.geldverstrekker =
      draft.taxatieAlgemeen.geldverstrekkerNwwiKeuze?.selectedResultaat?.contractId;
  }
});

const taxatieBureauSideEffects = createISWSideEffect<TaxatieSchermType>(({ has, draft }): void => {
  if (has.taxatiebureau.soortAanvraag.changed && draft.TaxatieSoortAanvraagOptionsOpties.length > 1) {
    assertValue(draft.taxatiebureau);
    draft.taxatiebureau.productCode =
      draft.TaxatieSoortAanvraagOptionsOpties.find(v => v.value === draft.taxatiebureau?.soortAanvraag)?.productCode ??
      null;
  }
});

export const syncTaxatieSideEffects = initISWSideEffect<TaxatieSchermType>(bag => {
  const { has, draft, prev } = bag;
  if (
    has.aanvullendeInformatie.changed ||
    has.bouwkundigeKeuring.changed ||
    has.contactpersoon.changed ||
    has.opdrachtgever.changed ||
    has.partner.changed ||
    has.taxatieAlgemeen.changed ||
    has.taxatieObject.changed ||
    has.taxatiebureau.changed
  ) {
    taxatieAanvragenSideEffects(bag);
  }
  if (has.taxatiebureau.changed && draft.taxatiebureau) taxatieBureauSideEffects(bag);
  if (has.taxatieAlgemeen.changed && draft.taxatieAlgemeen) taxatieAlgemeenSideEffects(bag);
  if (draft.isLoading && has.taxatieKenmerken.changed) {
    isLoadingSideEffects(bag);
  }

  if (
    prev.taxatiebureau?.maatschappijCode === NWWICode &&
    draft.taxatiebureau?.maatschappijCode !== NWWICode &&
    draft.taxatiebureau?.maatschappijCode &&
    draft.taxatieAlgemeen
  ) {
    draft.taxatieAlgemeen.geldverstrekker = draft.taxatieAlgemeenReadOnly?.geldverstrekker ?? "";
  }

  if (
    prev.taxatiebureau?.maatschappijCode === NationaleTaxatieCode &&
    draft.taxatiebureau?.maatschappijCode !== NationaleTaxatieCode &&
    draft.taxatiebureau?.maatschappijCode &&
    draft.taxatieAlgemeen
  ) {
    draft.taxatieAlgemeen.verbouwingSpecificatiesTotaal = draft.taxatieAlgemeenReadOnly?.verbouwingBedrag ?? 0;
    draft.taxatieAlgemeen.verbouwingSpecificaties =
      draft.taxatieAlgemeenReadOnly?.verbouwingSpecificaties?.map(v => mapVerbouwingdataDl2Ui(v)) ?? [];
  }

  //to be removed when platform fix the kenmerken problem
  if (
    has.taxatiebureau.soortAanvraag.changed &&
    draft.taxatiebureau?.soortAanvraag &&
    (draft.taxatiebureau.maatschappijCode === FitrexCode || draft.taxatiebureau.maatschappijCode === TaxatiepuntCode)
  ) {
    draft.taxatieKenmerken = editKenmerken(draft.taxatiebureau.maatschappijCode, draft.taxatiebureau.soortAanvraag, {
      ...taxatieBaseKenmerken,
      ...draft.taxatieKenmerken
    });
  }
});
