import { Draft } from "immer";

import { createISWSideEffect, initISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";
import { HasChanged } from "../../shared/components/isw-side-effects/has-changed-helper";

import { productDraftSideEffects } from "../../producten-overzicht/product/product-side-effects";
import { verpandingSideEffects } from "../../producten-overzicht/verpanding/determine-verpanding-side-effects";

import { VermogenType, VermogensType } from "./vermogen-types";
import {
  KapitaalopbouwOptions,
  SoortVermogenProductOptions,
  SoortDepotOptions,
  FiscaleVoortzettingOptions,
  BetalingsTermijnType
} from "../../.generated/forms/formstypes";
import { SoortBerekeningOptions } from "../../.generated/forms/formstypes";
import { assertNever } from "../../shared/utils/helpers";
import { jaarMaandInMaanden } from "../../shared/generic-parts/jaar-maand/map-ui-2-dl";
import { getDifferenceYearsMonths } from "../../shared/utils/dates";
import { mapJaarMaandInputFromLooptijdDl2Ui } from "../../shared/generic-parts/jaar-maand/map-dl-2-ui";
import { extraOpnameSchema } from "./vermogen-schema";

export const maakBerekendeWaardesLeeg = (draft: Draft<VermogenType>, has: HasChanged<VermogenType>): void => {
  if (!has.depot.waardeopbouwBedrag.changed) {
    draft.depot.waardeopbouwBedrag = null;
    draft.depot.waardeopbouwMaanden = [];
    draft.depot.waardeopbouwJaren = [];
    draft.depot.waardeopbouwNa5Jaar = null;
  }

  draft.onttrekkingen.extraOpnames.scenario = draft.onttrekkingen.extraOpnames.scenario.map(opname => ({
    ...opname,
    extraInlegBedrag: null,
    voorbeeldBedrag: 0,
    maxOpnameBedrag: null
  }));

  const soortBerekening = draft.kapitaalopbouw.soortBerekening as SoortBerekeningOptions | null;
  switch (soortBerekening) {
    case SoortBerekeningOptions.EersteInleg:
      if (!has.inleggegevens.eersteInleg.changed) {
        draft.inleggegevens.eersteInleg = null;
      }
      break;
    case SoortBerekeningOptions.Inleg:
      if (!has.inleggegevens.inleg.changed) {
        draft.inleggegevens.inleg = null;
      }
      break;
    case SoortBerekeningOptions.Onttrekking:
      if (!has.onttrekkingen.onttrekkingBedrag.changed) {
        draft.onttrekkingen.onttrekkingBedrag = null;
      }
      break;
    case SoortBerekeningOptions.Voorbeeldkapitaal:
      if (!has.kapitaalopbouw.voorbeeldkapitaalBedrag.changed) {
        draft.kapitaalopbouw.voorbeeldkapitaalBedrag = null;
      }
      if (!has.kapitaalopbouw.garantiekapitaalBedrag.changed) {
        draft.kapitaalopbouw.garantiekapitaalBedrag = null;
      }

      break;
    case SoortBerekeningOptions.Voorbeeldrendement:
      if (!has.kapitaalopbouw.voorbeeldrendementPercentage.changed) {
        draft.kapitaalopbouw.voorbeeldrendementPercentage = null;
      }
      break;
    case SoortBerekeningOptions.EigenInvoer:
      break;
    case null:
      break;
    default:
      assertNever(soortBerekening);
  }
};

export const fiscaleRegelingSideEffects = createISWSideEffect<VermogenType>(({ has, draft }): void => {
  if (
    (has.fiscaleRegeling.kapitaalopbouw.changed || has.fiscaleRegeling.lijfrenteclausule.changed) &&
    (draft.fiscaleRegeling.kapitaalopbouw !== KapitaalopbouwOptions.Box1 || draft.fiscaleRegeling.lijfrenteclausule)
  ) {
    draft.inleggegevens.aftrekbaar = null;
  }

  if (
    draft.soortProduct !== SoortVermogenProductOptions.Effectenlease &&
    has.fiscaleRegeling.lijfrenteclausule.changed
  ) {
    // Als lijfrenteclausule veranderd is dan ook indicatieveUitkerendeFase van verpanding zetten
    draft.verpanding.indicatieveUitkerendeFase = draft.fiscaleRegeling.lijfrenteclausule ?? false;
  }

  if (
    has.fiscaleRegeling.fiscaleVoortzetting.changed &&
    draft.fiscaleRegeling.fiscaleVoortzetting === FiscaleVoortzettingOptions.EigenInvoer
  ) {
    // Fiscale voortzetting is EigenInvoer => kapitaalopbouw wordt Box1 en soortDepot wordt OpbouwDepot
    draft.fiscaleRegeling.kapitaalopbouw = KapitaalopbouwOptions.Box1;
    draft.depot.soortDepot = SoortDepotOptions.OpbouwDepot;
  }
  if (
    has.fiscaleRegeling.kapitaalopbouw.changed &&
    (draft.soortProduct === SoortVermogenProductOptions.Beleggingsdepot ||
      draft.soortProduct === SoortVermogenProductOptions.Spaarrekening) &&
    draft.fiscaleRegeling.kapitaalopbouw === KapitaalopbouwOptions.Box1
  ) {
    // kapitaalopbouw is Box1 => soortDepot wordt OpbouwDepot
    draft.depot.soortDepot = SoortDepotOptions.OpbouwDepot;
  }

  if (has.fiscaleRegeling.changed && draft.fiscaleRegeling.kapitaalopbouw !== KapitaalopbouwOptions.Box3) {
    // Kapitaalopbouw gewijzigd naar niet Box3 => onttrekkingen mag niet aanwezig zijn
    draft.onttrekkingen.onttrekkingenAanwezig = false;
  }

  if (has.fiscaleRegeling.changed && draft.fiscaleRegeling.kapitaalopbouw === KapitaalopbouwOptions.Box1) {
    // Fiscale regeling gewijzigd => Berekende velden op null
    maakBerekendeWaardesLeeg(draft, has);
  }
});

export const depotSideEffects = createISWSideEffect<VermogenType>(({ has, draft }): void => {
  if (has.depot.soortDepot.changed) {
    // Depot gewijzigd => Berekende velden op null
    maakBerekendeWaardesLeeg(draft, has);
  }

  if (has.depot.reedsOpgebouwdBedrag.changed) {
    // Reeds opgebouwd bedrag gewijzigd => Berekende velden op null
    maakBerekendeWaardesLeeg(draft, has);
  }

  if (has.depot.reedsOpgebouwdDatum.changed) {
    // Reeds opgebouwd de datum gewijzigd => Berekende velden op null
    maakBerekendeWaardesLeeg(draft, has);
  }

  // Soort berekening gewijzigd => Berekende velden op null
});

export const onttrekkingenSideEffects = createISWSideEffect<VermogenType>(({ has, draft }): void => {
  if (draft.onttrekkingen.onttrekkingenAanwezig) {
    if (has.onttrekkingen.onttrekkingenAanwezig.changed) {
      // Onttrekkingen aanwezig gaat aan. => default begin en eind datum
      if (!draft.onttrekkingen.begindatum && draft.soortProduct !== SoortVermogenProductOptions.Betaalrekening) {
        draft.onttrekkingen.begindatum = draft.product.ingangsdatum;
      }
      if (!draft.onttrekkingen.einddatum && draft.soortProduct !== SoortVermogenProductOptions.Betaalrekening) {
        draft.onttrekkingen.einddatum = draft.product.ingangsdatum;
      }

      if (draft.soortProduct === SoortVermogenProductOptions.Betaalrekening) {
        draft.onttrekkingen.onttrekkingstermijn = null;
      } else {
        draft.onttrekkingen.onttrekkingstermijn = BetalingsTermijnType.Maand;
      }
    }
    if (has.onttrekkingen.duur.changed) {
      // Looptijd gewijzigd met begindatum => Einddatum bepalen
      draft.onttrekkingen.einddatum =
        draft.onttrekkingen.begindatum?.plusMonths(jaarMaandInMaanden(draft.onttrekkingen.duur) ?? 0) ?? null;
    }

    if (has.onttrekkingen.begindatum.changed) {
      // Begindatum gewijzigd met looptijd => Eindatum bepalen
      draft.onttrekkingen.einddatum =
        draft.onttrekkingen.begindatum?.plusMonths(jaarMaandInMaanden(draft.onttrekkingen.duur) ?? 0) ?? null;
    }

    if (has.onttrekkingen.einddatum.changed) {
      // Einddatum gewijzigd met begindatum => Looptijd bepalen

      if (draft.onttrekkingen.begindatum && draft.onttrekkingen.einddatum) {
        const difference = getDifferenceYearsMonths(draft.onttrekkingen.begindatum, draft.onttrekkingen.einddatum);

        draft.onttrekkingen.duur.jaren = difference.year;
        draft.onttrekkingen.duur.maanden = difference.month;

        draft.onttrekkingen.einddatum =
          draft.onttrekkingen.begindatum.plusMonths(jaarMaandInMaanden(draft.onttrekkingen.duur) ?? 0) ?? null;
      }
    }

    if (
      has.inleggegevens.stortingstermijn.changed &&
      (draft.soortProduct === SoortVermogenProductOptions.Beleggingsdepot ||
        draft.soortProduct === SoortVermogenProductOptions.Spaarrekening)
    ) {
      // Inleggegeven stortingstermijn gewijzigd => onttrekkingstermijn wijzigen
      draft.onttrekkingen.onttrekkingstermijn = draft.inleggegevens.stortingstermijn as BetalingsTermijnType | null;
    }
  } else {
    if (has.onttrekkingen.onttrekkingenAanwezig.changed) {
      // Onttrekkingen aanwezig gaat uit. => Waardes leeg maken
      draft.onttrekkingen.begindatum = null;
      draft.onttrekkingen.einddatum = null;
      draft.onttrekkingen.duur.jaren = null;
      draft.onttrekkingen.duur.maanden = null;
      draft.onttrekkingen.onttrekkingstermijn = null;
      draft.onttrekkingen.onttrekkingBedrag = null;
      draft.onttrekkingen.extraOpnames.scenario = [];
    }
  }
});

export const inleggegevensSideEffects = createISWSideEffect<VermogenType>(({ has, draft }): void => {
  if (has.product.looptijd.changed) {
    // Product looptijd veranderd => duur inleg = looptijd + Duur hoog
    const looptijdInMaanden = jaarMaandInMaanden(draft.product.looptijd) ?? 0;
    const duurHoogLaagMaanden = jaarMaandInMaanden(draft.inleggegevens.duurHoogLaag) ?? 0;
    draft.inleggegevens.duur = mapJaarMaandInputFromLooptijdDl2Ui(looptijdInMaanden + duurHoogLaagMaanden);
  }

  if (has.inleggegevens.duurHoogLaag.changed) {
    const duurHoogLaagMaanden = jaarMaandInMaanden(draft.inleggegevens.duurHoogLaag);
    if (!duurHoogLaagMaanden) {
      // Duur hoog leeg => inleg hoog leeg
      draft.inleggegevens.inlegHoog = null;
    }
  }

  if (has.inleggegevens.extraInlegJaren.changed) {
    // alert("has.inleggegevens.extraInlegJaren.changed");

    if (draft.onttrekkingen.onttrekkingenAanwezig) {
      draft.inleggegevens.extraInlegJaren.scenario.forEach((inleg, index) => {
        let extraOpname = draft.onttrekkingen.extraOpnames.scenario[index];
        if (!extraOpname) {
          extraOpname = extraOpnameSchema.default();
          extraOpname.jaar = index + 1;
          draft.onttrekkingen.extraOpnames.scenario[index] = extraOpname;
        }
        extraOpname.extraInlegBedrag = inleg.bedrag;
      });
    }
  }

  const berekendeWaardesLeegMaken =
    has.inleggegevens.duur.changed ||
    has.inleggegevens.duurHoogLaag.changed ||
    has.inleggegevens.stortingstermijn.changed ||
    (draft.kapitaalopbouw.soortBerekening !== SoortBerekeningOptions.Inleg && has.inleggegevens.inleg.changed) ||
    has.inleggegevens.inlegHoog.changed ||
    has.inleggegevens.eersteInleg.changed ||
    has.inleggegevens.extraInlegJaren.scenario.changed;

  if (berekendeWaardesLeegMaken) {
    maakBerekendeWaardesLeeg(draft, has);
  }
});

export const determineVermogenDetailsSideEffects = initISWSideEffect<VermogensType, number>(({ context, subset }) => {
  const selected = subset.producten[context].create();
  const bag = subset.create();
  const { subset: selectedSubset, has, draft } = selected;

  /* istanbul ignore next */
  if (
    has.kapitaalopbouw.doelkapitaalBedrag.changed &&
    bag.draft.platformApiFouten?.find(item => item.field === `producten[${context}].kapitaalopbouw.doelkapitaalBedrag`)
  ) {
    bag.draft.platformApiFouten =
      bag.draft.platformApiFouten?.filter(
        item => item.field !== `producten[${context}].kapitaalopbouw.doelkapitaalBedrag`
      ) ?? null;
  }

  productDraftSideEffects(selectedSubset.product.create());
  fiscaleRegelingSideEffects(selected);
  depotSideEffects(selected);
  verpandingSideEffects(selected);
  inleggegevensSideEffects(selected);
  onttrekkingenSideEffects(selected);

  if (has.kapitaalopbouw.changed && !has.kapitaalopbouw.bedoeldVoorAflossing.changed) {
    maakBerekendeWaardesLeeg(draft, has);
  }
});
