import { QuestionType, CardSpec } from "../../infra/klantprofiel-schema";
import { KlantprofielVraagOptions } from "../../../.generated/forms/formstypes";

export const fiscaalQuestionSpecs: CardSpec[] = [
  {
    questions: [
      {
        question: KlantprofielVraagOptions.WensFiscaleAftrekHypotheek,
        type: QuestionType.checkbox
      }
    ]
  }
];
