import { ErrorPage, PageLoading, SettingsType, useRequestInit, withErrorBoundary } from "adviesbox-shared";
import React, { ReactElement, useContext } from "react";
import useAbortableFetch from "use-abortable-fetch";
import { AppDataContext } from "../navigation/appdata-context";
import { FinancieringOptions } from "../.generated/forms/formstypes";
import { MedewerkerOrganisatieOutput, RechtenOutPut } from "../.generated/licenties/licentiestypes";
import { useAdviesboxData } from "../shared/hooks/use-adviesbox-data";
import { AanvraagCheckAanleidingAjax } from "./aanvraag-check-aanleiding-ajax";
import { mapDlTargetToAanvraagUiField } from "./infra/map-aanvraag-dl-target-to-ui-field";
import { mapAanvraagDlToUi } from "./infra/map-aanvraag-dl-to-ui";
import { mapAanvraagUiToDl } from "./infra/map-aanvraag-ui-to-dl";

type RouteParams = {
  vestiging: string;
  adviesdossier: string;
};

const createUrl = (s: SettingsType, p: RouteParams): string =>
  `${s.klantdossiersFormsOrigin}/Adviesdossiers/${p.adviesdossier}/Aanleiding`;

const AanvraagAjaxComponent = (): ReactElement => {
  const { loading, error, data, platformData } = useAdviesboxData(
    createUrl,
    p => p.adviesdossier,
    mapAanvraagDlToUi,
    mapAanvraagUiToDl,
    mapDlTargetToAanvraagUiField
  );

  const { user, settings, requestInit } = useRequestInit();
  const medewerkerUrl = user && user.profile ? `${settings.licentiesOrigin}/Medewerkers/current` : null;
  const medewerker = useAbortableFetch<MedewerkerOrganisatieOutput>(medewerkerUrl, requestInit);
  const rechtenUrl =
    medewerker.data && typeof medewerker.data !== "string"
      ? `${settings.licentiesOrigin}/Medewerkers/${medewerker.data.medewerkerId}/Rechten`
      : null;
  const authorization = useAbortableFetch<RechtenOutPut>(rechtenUrl, requestInit);
  const { menuInfo } = useContext(AppDataContext);

  if (error) {
    return <ErrorPage error={error} data={platformData} />;
  }

  if (authorization.error) {
    return <ErrorPage error={authorization.error} data={platformData} />;
  }

  if (loading || !data || !authorization || !authorization.data) {
    return <PageLoading />;
  }

  if (typeof authorization.data !== "string" && authorization?.data && authorization?.data?.rechten) {
    data.authorized = authorization.data.rechten.filter(item => item === "BEM").length >= 1;
  }

  if (menuInfo?.adviseurIds?.length) data.adviseurId = menuInfo?.adviseurIds[0];

  if (data.financiering === FinancieringOptions.GeenFinanciering)
    return <AanvraagCheckAanleidingAjax aanvraagData={{ ...data }} checkGewensteHypotheek={false} />;
  else {
    return <AanvraagCheckAanleidingAjax aanvraagData={{ ...data }} checkGewensteHypotheek={true} />;
  }
};

export const AanvraagAjax = withErrorBoundary(AanvraagAjaxComponent);
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production") AanvraagAjax.displayName = "AanvraagAjax";
