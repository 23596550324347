import { JaarMaandInputType } from "./schema";

export function mapJaarMaandInputDl2Ui(jaren?: number | null, maanden?: number | null): JaarMaandInputType {
  return {
    jaren: typeof jaren === "number" ? jaren : null,
    maanden: typeof maanden === "number" ? maanden : null
  };
}

export function mapPeriodeInMaandenDl2Ui(maanden?: number | null): JaarMaandInputType {
  maanden = maanden || 0;
  return mapJaarMaandInputDl2Ui(Math.floor((maanden || 0) / 12), (maanden || 0) % 12);
}

export function mapJaarMaandInputFromLooptijdDl2Ui(maanden?: number | null): JaarMaandInputType {
  if (typeof maanden === "number") {
    return {
      jaren: Math.floor(maanden / 12),
      maanden: maanden % 12
    };
  }
  return {
    jaren: null,
    maanden: null
  };
}
