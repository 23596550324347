import { FormikContextType, connect } from "formik";

import React, { ReactElement } from "react";
import AdvieskostenBemiddelingsvergoedingModal from "../advieskosten-bemiddelingsvergoeding-modal/advieskosten-bemiddelingsvergoeding-modal";
import { advieskostenBerekening } from "./financieringsopzet-helper";
import { LabeledCurrencyInput, Icon } from "adviesbox-shared";
import { ModalButton } from "adviesbox-shared";
import { FinancieringsbehoefteState } from "../infra/financieringsbehoefte-types";
import { getFinancieringsbehoefteTextResources } from "../infra/financieringsbehoefte-resources";

const AdvieskostenBemiddelingsvergoeding = ({
  formik: { values, setFieldValue }
}: {
  formik: FormikContextType<FinancieringsbehoefteState>;
}): ReactElement => {
  return (
    <LabeledCurrencyInput
      caption="Advieskosten/bemiddelingsvergoeding"
      name="financieringsopzet.advieskosten"
      readonly={true}
      tooltip={getFinancieringsbehoefteTextResources("TooltipAdvieskostenBemiddeling")}
      appendChildren={
        <ModalButton
          parent="financieringsopzet.advieskostenBemiddelingsvergoedingModal"
          aria-label="Advieskosten/bemiddelingsvergoeding button"
          content={<Icon name="specificatie" alt="Advieskosten/bemiddelingsvergoeding" />}
        >
          <AdvieskostenBemiddelingsvergoedingModal
            data={values.financieringsopzet.advieskostenBemiddelingsvergoedingModal}
            onSave={(result): void => {
              setFieldValue("financieringsopzet.advieskosten", advieskostenBerekening(result));
              setFieldValue("financieringsopzet.advieskostenBemiddelingsvergoedingModal", result);
            }}
          />
        </ModalButton>
      }
    />
  );
};

export default connect<{}, FinancieringsbehoefteState>(AdvieskostenBemiddelingsvergoeding);
