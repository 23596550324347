/* istanbul ignore file */
import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import useAbortableFetch from "use-abortable-fetch";
import { useRequestInit, ForceRerenderContext } from "adviesbox-shared";
import { RapportSamenstellen, RapportSamenstellenOutput } from "../../.generated/rapportage/rapportagetypes";
import { RouteParams } from "../../shared/paramrouting/paramrouting-context";

export type UseInstellingenDataResult<T> = {
  loading: boolean;
  error: null | Error;
  data: T;
};

export function useInstellingenRapportageStructurenData(): UseInstellingenDataResult<RapportSamenstellen[] | null> & {
  settings?: any;
  user?: any;
  params?: any;
  url?: string;
} {
  const { settings, user, params, requestInit } = useRequestInit();
  const { adviesdossier } = useParams<RouteParams>();
  const url = `${settings.rapportageOrigin}/Adviesdossiers/${adviesdossier}/RapportSamenstellen`;
  const loadingDone = useContext(ForceRerenderContext);

  const { loading, error, data } = useAbortableFetch<RapportSamenstellenOutput>(url, requestInit);

  useEffect((): void => {
    if (!loading && !!data && loadingDone) {
      loadingDone();
    }
  }, [loading, data, loadingDone]);

  if (typeof data === "string") {
    return { error: new Error("Fout bij het laden van instellingen rapportage data"), data: null, loading: false };
  }

  return {
    loading,
    error,
    data: data ? Object.values(data.rapportenSamenstellen ?? []) : null,
    user,
    params,
    settings,
    url
  };
}
