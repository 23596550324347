import { UiError, UiName } from "../shared/types";
import { validationResultsToUiError } from "../shared/utils/validation-results-to-ui-error";
import { User } from "oidc-client";

export function contentTypeIsJson(rsp: Response): boolean {
  const contentType = rsp.headers.get("content-type");
  return !!(contentType && contentType.indexOf("application/json") !== -1);
}

export function ImportOpslaanEnBevestigen<T>(
  ocpApimSubscriptionKey: string,
  user: User | null,
  koppelingKey: string,
  vestigingId: string,
  url: string,
  mapper: (values: T) => any,
  mapperDlNameToUiName: (target: string) => UiName | null,
  subscribeCorrelationId: (correlationId: string) => void,
  setCorrelationId: React.Dispatch<React.SetStateAction<string | null>>
) {
  return async (values: T, preventReload?: boolean): Promise<UiError[] | null> => {
    const mappedValues = mapper(values);
    const rsp = await fetch(url, {
      headers: {
        authorization: `${user?.token_type} ${user?.access_token}`,
        "Ocp-Apim-Subscription-Key": ocpApimSubscriptionKey,
        "Content-Type": "application/json",
        koppelingKey,
        vestigingId
      },
      method: "PATCH",
      body: JSON.stringify(mappedValues)
    });

    const isJson = contentTypeIsJson(rsp);
    const data = isJson ? await rsp.json() : null;

    /* istanbul ignore next */
    if (!rsp.ok && isJson) {
      if (data && data.validationResults) {
        return validationResultsToUiError(data.validationResults, mapperDlNameToUiName);
      }
    }
    if (data?.correlationId) {
      subscribeCorrelationId(data.correlationId);
      setCorrelationId(data.correlationId);
    }
    return null;
  };
}

export function ImportBevestigen<T>(
  ocpApimSubscriptionKey: string,
  user: User | null,
  koppelingKey: string,
  vestigingId: string,
  url: string,
  mapperDlNameToUiName: (target: string) => UiName | null,
  subscribeCorrelationId: (correlationId: string) => void,
  setCorrelationId: React.Dispatch<React.SetStateAction<string | null>>
) {
  return async (values: T, preventReload?: boolean): Promise<UiError[] | null> => {
    const rsp = await fetch(url, {
      headers: {
        authorization: `${user?.token_type} ${user?.access_token}`,
        "Ocp-Apim-Subscription-Key": ocpApimSubscriptionKey,
        "Content-Type": "application/json",
        koppelingKey,
        vestigingId
      },
      method: "PUT"
    });

    const isJson = contentTypeIsJson(rsp);
    const data = isJson ? await rsp.json() : null;

    /* istanbul ignore next */
    if (!rsp.ok && isJson) {
      if (data && data.validationResults) {
        return validationResultsToUiError(data.validationResults, mapperDlNameToUiName);
      }
    }

    if (data?.id) {
      subscribeCorrelationId(data.id);
      setCorrelationId(data.id);
    }

    return null;
  };
}
