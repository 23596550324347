import * as Yup from "yup";
import { InferType } from "yup";
import { LocalDate } from "@js-joda/core";
import { GebruikPandSoort } from "../../../.generated/forms/formstypes";
import { getOnderpandTextResources } from "./onderpand-resources";
import { yupEnum } from "../../utils/yup-enum";
import { nullableLocalDateSchema, localDateSchema } from "adviesbox-shared";

//todo: new date pakt altijd de datum van vandaag. Dit is een probleem als je de advies al een dag open hebt staan
// let today = LocalDate.now();
// let tomorrow = LocalDate.now();
// tomorrow.setHours(0, 0, 0, 0);
// tomorrow.setDate(tomorrow.getDate() + 1);
// today.setDate(today.getDate());

export const HdnAnders = 99;

export const onderpandSchema = Yup.object().shape({
  gebruikPand: yupEnum(GebruikPandSoort)
    .nullable()
    .default(GebruikPandSoort.PrimaireWoning),
  bouwjaar: Yup.number()
    .moreThan(999, getOnderpandTextResources("ErrorBouwjaar"))
    .max(9999, getOnderpandTextResources("ErrorBouwjaar"))
    .nullable()
    .default(null),
  bouwaard: Yup.string()
    .nullable()
    .default(""),
  bouwPlan: Yup.string()
    .nullable()
    .max(35, getOnderpandTextResources("ErrorMaxLengte").replace("{lengte}", "35"))
    .default(""),
  bouwNummer: Yup.string()
    .max(50, getOnderpandTextResources("ErrorMaxLengte").replace("{lengte}", "50"))
    .nullable()
    .default(""),
  opleverDatum: nullableLocalDateSchema.test({
    message: getOnderpandTextResources("ErrorOpleverdatum"),
    test: function(value: LocalDate): boolean {
      return value ? value > LocalDate.of(1900, 1, 1) && value < LocalDate.of(3000, 1, 1) : true;
    }
  }),
  kadastraleSectie: Yup.string()
    .max(2, getOnderpandTextResources("ErrorMaxLengte").replace("{lengte}", "2"))
    .nullable()
    .default(""),
  kadastraleNummer: Yup.string()
    .max(15, getOnderpandTextResources("ErrorMaxLengte").replace("{lengte}", "15"))
    .test({
      message: getOnderpandTextResources("ErrorKadastraleNummer"),
      test: function(value: string): boolean {
        try {
          const parsedString = Number.parseFloat(value);
          if (parsedString < 0) return false;
          else return true;
        } catch (e) {
          return false;
        }
      }
    })
    .nullable()
    .default(""),
  kadastraleGemeente: Yup.string()
    .max(24, getOnderpandTextResources("ErrorMaxLengte").replace("{lengte}", "24"))
    .nullable()
    .default(""),

  serviceKosten: Yup.number()
    .min(0, getOnderpandTextResources("ErrorBedragPostief"))
    .nullable()
    .default(null),
  taxatieAanwezig: Yup.boolean()
    .test({
      message: getOnderpandTextResources("ErrorDatumLeeg"),
      test: function(value): boolean {
        return value || this.parent.taxatieDatum === null || this.parent.taxatieDatum === undefined;
      }
    })
    .default(false),

  taxatieDatum: nullableLocalDateSchema.when("taxatieAanwezig", {
    is: true,
    then: localDateSchema.required(getOnderpandTextResources("ErrorTaxatieDatum")).test({
      message: getOnderpandTextResources("ErrorDatumTaxatieToekomst"),
      test: function(value: LocalDate) {
        return value <= LocalDate.now();
      }
    })
  }),

  gedeeltelijkePerceel: Yup.boolean().default(false),
  appartementsRecht: Yup.boolean().default(false),
  staatVanOnderhoud: Yup.string()
    .nullable()
    .default(""),
  verkoopOnderVoorwaarden: Yup.boolean().default(false),
  constructie: Yup.string()
    .nullable()
    .default(null),
  constructieOmschrijving: Yup.string()
    .max(100, "De maximale toegestane lengte is 100 karakters.")
    .test("constructieOmschrijving", "Verplicht veld", function(this: Yup.TestContext, input: string): boolean {
      if (this.parent.constructie === `${HdnAnders}`) {
        return !!input && input.trim().length > 0;
      }

      return true;
    })
    .nullable()
    .default(null),
  erfpachtConstructieAnders: Yup.string()
    .nullable()
    .default(null),
  koopAannemingsOvMetVormAfbouwGarantie: Yup.boolean().default(false),
  ontbindendeVoorwaardenTot: nullableLocalDateSchema,
  eigendomsVerhoudingAanvrager: Yup.number().nullable(),
  eigendomsVerhoudingPartner: Yup.number().nullable(),
  objectSoort: Yup.string()
    .nullable()
    .default(null),
  bijzonderObjectSoort: Yup.string()
    .nullable()
    .default(""),
  heeftGarage: Yup.boolean()
    .nullable()
    .default(false),
  recreatieveBewoning: Yup.boolean()
    .nullable()
    .default(false)
});
export type OnderpandType = InferType<typeof onderpandSchema>;
