import React, { ReactElement } from "react";
import { connect, FormikContextType} from "formik";


import { SelectedProps } from "../../shared/types";
import { LabeledCurrencyInput, LabeledDateInput, LabeledBevestigingInput  } from "adviesbox-shared";
import { WoonsituatieListState } from "../infra/woonsituatie-schema";

const Erfpacht = ({
  selected,
  formik: {
    values: { woonsituaties }
  }
}: SelectedProps & {
  formik: FormikContextType<WoonsituatieListState>;
}): ReactElement => {
  const selectedWoonsituatie = woonsituaties ? woonsituaties[selected] : null;
  if (!selectedWoonsituatie) return <></>;

  return (
    <>
      <LabeledBevestigingInput caption="Erfpacht" name={`woonsituaties[${selected}].erfpacht.hasErfpacht`} />
      {selectedWoonsituatie.erfpacht.hasErfpacht && (
        <>
          <LabeledCurrencyInput caption="Jaarlijkse canon" name={`woonsituaties[${selected}]erfpacht.canon`} />

          <LabeledBevestigingInput
            caption="Particuliere erfpacht"
            name={`woonsituaties[${selected}].erfpacht.particulierErfpacht`}
          />

          <LabeledBevestigingInput
            caption="Eeuwigdurende erfpacht"
            name={`woonsituaties[${selected}].erfpacht.eeuwigdurendErfpacht`}
          />
          {!selectedWoonsituatie.erfpacht.eeuwigdurendErfpacht && (
            <LabeledDateInput caption="Einddatum" name={`woonsituaties[${selected}].erfpacht.einddatum`} />
          )}
        </>
      )}
    </>
  );
};

export default connect<SelectedProps, WoonsituatieListState>(Erfpacht);
