import {
  getRapportageResource,
  hasJSONResponse,
  logErrorToApplicationInsights,
  logEvent,
  SettingsType
} from "adviesbox-shared";
import { User } from "oidc-client";
import { OverzichtSubscription } from "../../.generated/rapportage/rapportagetypes";
import { overzichtSubscriptionInput } from "./rapportage-schema";

/* 
  Geeft een SAS-token en subscription ID terug waarmee geabboneerd kan worden op een topic.
  (waarop vervolgens een bericht op terugkomt met URL naar een rapport / resultaat).
*/
export const getOverzichtSubscription = async (
  overzichtSubscription: overzichtSubscriptionInput,
  params: {
    vestiging: string;
  },
  user: User | null,
  settings: SettingsType
): Promise<OverzichtSubscription | null> => {
  const startTime = new Date().getTime();

  logEvent(getRapportageResource("FRL004"), {
    rapportNaam: overzichtSubscription.rapportageNaam || "rapportage-onbekend",
    gebruiker: user?.profile.name,
    vestigingId: params.vestiging,
    requestBody: JSON.stringify(overzichtSubscription.body),
    requestUrl: overzichtSubscription.url
  });

  const requestInit: RequestInit = {
    method: "POST",
    headers: {
      "Ocp-Apim-Subscription-Key": `${settings.OcpApimSubscriptionKey}`,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      authorization: `${user!.token_type} ${user!.access_token}`,
      vestigingId: params.vestiging,
      "Content-Type": "application/json;charset=UTF-8"
    }
  };

  if (overzichtSubscription?.body) {
    requestInit.body = overzichtSubscription.body;
  }

  const rsp = await fetch(overzichtSubscription?.url, requestInit);

  const elapsed = new Date().getTime() - startTime;

  /* istanbul ignore next */ if (rsp === null) return rsp;

  if (!(rsp.ok && hasJSONResponse(rsp))) {
    logErrorToApplicationInsights(
      Error(`Fout bij aanroep van web-api '${overzichtSubscription?.url}'. Resultaat bevat geen JSON.`)
    );
    logEvent(getRapportageResource("FRL005"), {
      rapportNaam: overzichtSubscription.rapportageNaam || "rapportage-onbekend",
      gebruiker: user?.profile.name,
      resultaat: "fout",
      requestBody: JSON.stringify(overzichtSubscription.body),
      requestDuur: elapsed,
      requestStatuscode: rsp.status
    });
    return null;
  }

  const json = await rsp.json();

  /* istanbul ignore next */
  if (!json.isValid) {
    logErrorToApplicationInsights(
      Error(`Fout bij aanroep van web-api '${overzichtSubscription?.url}'. Resultaat is niet valide.`)
    );
    logEvent(getRapportageResource("FRL005"), {
      rapportNaam: overzichtSubscription.rapportageNaam || "rapportage-onbekend",
      gebruiker: user?.profile.name,
      resultaat: "fout",
      requestBody: JSON.stringify(overzichtSubscription.body),
      requestDuur: elapsed,
      requestBericht: json.message,
      validatiemeldingen: json.validationResults
    });
    return null;
  }

  logEvent(getRapportageResource("FRL006"), {
    rapportNaam: overzichtSubscription.rapportageNaam || "rapportage-onbekend",
    gebruiker: user?.profile.name,
    resultaat: "succes",
    requestDuur: elapsed,
    requestBericht: json
  });

  return { sasToken: json.subscription.sasToken, overzichtId: json.subscription.overzichtId };
};
