import { AdviesBoxColumn } from "adviesbox-shared";

export const staffelColumns: AdviesBoxColumn[] = [
  {
    Header: "Percentage AO van",
    accessor: "ondergrensPercentage",
    Cell: "PercentageInput"
  },
  {
    Header: "Percentage AO tot",
    accessor: "bovengrensPercentage",
    Cell: "PercentageInput"
  },
  {
    Header: "Percentage uitkering",
    accessor: "uitkeringPercentage",
    Cell: "PercentageInput"
  },
  {
    Cell: "DeleteButton"
  }
];

export const indexeringenColumns: AdviesBoxColumn[] = [
  {
    Header: "Percentage AO van",
    accessor: "ondergrensPercentage",
    Cell: "PercentageInput",
    config: { readonly: true }
  },
  {
    Header: "Percentage AO tot",
    accessor: "bovengrensPercentage",
    Cell: "PercentageInput",
    config: { readonly: true }
  },
  {
    Header: "Percentage uitkering",
    accessor: "uitkeringPercentage",
    Cell: "TextInput",
    config: { readonly: true }
  }
];
