import React from "react";
import { Table } from "react-bootstrap";
import { LastenOverzicht, Lasten, Totalen } from "../../../.generated/rapportage/rapportagetypes";
import { bedragFormatNull } from "../scenarios/infra/scenario-utils";
import "./lastenoverzicht-table.scss";
import classNames from "classnames";

type LastenOverzichtTableProps = {
  data: LastenOverzicht | null | undefined;
};

type ColumnConfig = {
  headerTitle: string;
  isCurrency?: boolean;
  isLeeftijd?: true;
  showTotal?: boolean;
  showAccented?: boolean;
};

const lastenOverzichtColumnConfig: Record<string, ColumnConfig> = {
  jaar: { headerTitle: "Jaar" },
  leeftijdAanvrager: { headerTitle: "Leeftijd aanvrager", isLeeftijd: true },
  leeftijdPartner: { headerTitle: "Leeftijd partner", isLeeftijd: true },
  brutoInkomen: { headerTitle: "Bruto inkomen", isCurrency: true },
  waardeopbouw: { headerTitle: "Waardeopbouw", isCurrency: true },
  somHypotheken: { headerTitle: "Som hypotheken", isCurrency: true, showTotal: false },
  rente: { headerTitle: "Rente", isCurrency: true },
  hypotheekAflossing: { headerTitle: "Hypotheek aflossing", isCurrency: true },
  uitkeringKapitaalverzekeringen: { headerTitle: "Uitkering kapitaalverz.", isCurrency: true },
  verzekeringspremies: { headerTitle: "Verzekering premies", isCurrency: true },
  spaarEnBeleggingsdepots: { headerTitle: "Spaar- en beleggingsdepots", isCurrency: true },
  opnameUitDepot: { headerTitle: "Opname uit depot", isCurrency: true },
  kredietlast: { headerTitle: "Kredietlast", isCurrency: true },
  overigeUitgaven: { headerTitle: "Overige uitgaven", isCurrency: true },
  huur: { headerTitle: "Huur", isCurrency: true },
  brutoLast: { headerTitle: "Bruto Last", isCurrency: true, showAccented: true },
  fiscaalVoordeel: { headerTitle: "Fiscaal voordeel", isCurrency: true },
  vrh: { headerTitle: "Belasting box 3", isCurrency: true },
  nettoLast: { headerTitle: "Netto last", isCurrency: true }
};

const LastenOverzichtTable: React.FC<LastenOverzichtTableProps> = ({ data }: LastenOverzichtTableProps) => {
  // Helper function to format values and div 12
  const formatValue = (value: number | null, col: string, div12?: boolean): string | number => {
    if (value === null) return "";
    return lastenOverzichtColumnConfig[col].isCurrency
      ? bedragFormatNull(
          value,
          x => {
            return x * (div12 ? 12 : 1);
          },
          0
        )
      : value;
  };

  // Filter columns with data in either jaarGegevens or totalen
  const columnsWithData = Object.keys(lastenOverzichtColumnConfig).filter(
    col =>
      data?.jaarGegevens?.some(jaarData => jaarData[col as keyof Lasten] !== null && !!jaarData[col as keyof Lasten]) ||
      (data?.totalen?.[col as keyof Totalen] != null && !!data?.totalen?.[col as keyof Totalen])
  );

  return (
    <div className="lastenoverzicht-table" data-testid="lastenoverzicht">
      {!data?.jaarGegevens ||
        data?.jaarGegevens.length === 0 ||
        (!data?.totalen && <>Te weinig gegevens beschikbaar voor volledig overzicht.</>)}
      <Table striped bordered hover responsive className="scenario-table strong_last">
        <thead>
          <tr>
            {columnsWithData.map((col, index) => (
              <th key={col} className={classNames({ "accent-column": lastenOverzichtColumnConfig[col].showAccented })}>
                {lastenOverzichtColumnConfig[col].headerTitle}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.jaarGegevens?.map((jaarData: Lasten) => (
            <tr key={jaarData.jaar}>
              {columnsWithData.map((col, index) => (
                <td
                  key={col}
                  className={classNames(
                    { "accent-column": lastenOverzichtColumnConfig[col].showAccented },
                    { "text-right": lastenOverzichtColumnConfig[col].isCurrency },
                    { "text-center": lastenOverzichtColumnConfig[col].isLeeftijd }
                  )}
                >
                  {formatValue(jaarData[col as keyof Lasten] as number | null, col)}
                </td>
              ))}
            </tr>
          ))}
          <tr>
            {columnsWithData.map((col, index) => (
              <td
                key={col}
                className={classNames(
                  { "accent-column": lastenOverzichtColumnConfig[col].showAccented },
                  { "text-right": lastenOverzichtColumnConfig[col].isCurrency },
                  { }
                )}
              >
                {index === 0
                  ? "Totaal"
                  : lastenOverzichtColumnConfig[col].showTotal === false
                  ? ""
                  : formatValue(data?.totalen?.[col as keyof Totalen] as number | null, col, true)}
              </td>
            ))}
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default LastenOverzichtTable;
