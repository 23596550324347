import { LabeledSelectInput } from "adviesbox-shared";
import { useFormikContext } from "formik";
import React, { ReactElement, useEffect, useMemo } from "react";
import { WijzigGeldverstrekkerInputLeningdeel } from "../../.generated/forms/formstypes";
import { SituatieSoort } from "../../producten-overzicht/infra/producten-overzicht-types";
import {
  HypotheekProductSelectieStateType,
  HypotheekSelectieSamenstellingType,
  HypotheekType
} from "../infra/hypotheek-types";
import { mapHypotheekSelectieGenereerSamenstellingToUi } from "./hypotheek-selectie-genereer-samenstelling";
import { getOptions } from "./hypotheek-selectie-helper";
import { useHypotheekSamenstellingWijzigData } from "./use-hypotheek-selectie-wijzig-samenstelling-data";

export type HypotheekSelectieWijzigSamenstellingProps = {
  situatie: SituatieSoort;
  leningdelen: WijzigGeldverstrekkerInputLeningdeel[];
  setHypotheekSamenstellingLoadingState: (value: boolean) => void;
  setHypotheekSamenstellingWijzigingState: (value: any) => void;
};

export function mapSamenstellingLeningdelen(leningdeel: HypotheekType, nieuwLeningdeel: HypotheekType): HypotheekType {
  return {
    ...leningdeel,
    leningdeelgegevens: {
      ...leningdeel.leningdeelgegevens,
      rentevastPeriodeJaar: 10,
      renteScenarioModal: leningdeel.leningdeelgegevens.automatischeRentedaling
        ? { renteScenario: [], vervolgjarenAutomatischInvullen: false }
        : { ...leningdeel.leningdeelgegevens.renteScenarioModal },
      rentePercentage: leningdeel.leningdeelgegevens.rentePercentage
        ? {
          bedrag: leningdeel.leningdeelgegevens.rentePercentage.bedrag,
          berekendBedrag: leningdeel.leningdeelgegevens.rentePercentage.berekendBedrag,
          berekenen: true
        }
        : null
    },
    labelCode: nieuwLeningdeel.labelCode,
    hypotheekVorm: nieuwLeningdeel.hypotheekVorm,
    product: nieuwLeningdeel.product,
    labelNaam: nieuwLeningdeel.labelNaam,
    partijCode: nieuwLeningdeel.partijCode,
    productCode: nieuwLeningdeel.productCode,
    premieGegevens: nieuwLeningdeel.premieGegevens,
    nearMatchHypotheekvormen: null
  };
}

const HypotheekSelectieWijzigSamenstelling = ({
  leningdelen,
  setHypotheekSamenstellingLoadingState,
  setHypotheekSamenstellingWijzigingState
}: HypotheekSelectieWijzigSamenstellingProps): ReactElement => {
  const { values, setFieldValue, isSubmitting, submitForm } = useFormikContext<HypotheekProductSelectieStateType>();
  const { maatschappijCode, labelCode } = values.maatschappijProductCode;
  const {
    data: hypotheekSamenstellingDataState,
    error: hypotheekSamenstellingDataErrorState,
    loading
  } = useHypotheekSamenstellingWijzigData(maatschappijCode, labelCode, leningdelen);

  useEffect(() => setHypotheekSamenstellingLoadingState(loading), [loading, setHypotheekSamenstellingLoadingState]);

  const nieuweLeningdelen = useMemo((): HypotheekSelectieSamenstellingType[] => {
    if (
      !loading &&
      hypotheekSamenstellingDataState &&
      typeof hypotheekSamenstellingDataState !== "string" &&
      hypotheekSamenstellingDataState.resultaat?.leningdelen?.length
    ) {
      const mappedSamenstelling = mapHypotheekSelectieGenereerSamenstellingToUi(
        hypotheekSamenstellingDataState.resultaat.leningdelen
      );

      return mappedSamenstelling;
    }
    return [];
  }, [hypotheekSamenstellingDataState, loading]);

  useEffect(() => {
    setHypotheekSamenstellingWijzigingState(nieuweLeningdelen);
    setFieldValue(
      "nearMatchHypotheekvorm",
      nieuweLeningdelen.map((c: any) => {
        const option = getOptions(c)[0] || undefined;
        return option && option.value;
      })
    );
  }, [nieuweLeningdelen, setHypotheekSamenstellingWijzigingState, setFieldValue]);

  const hasNothingToMatch = nieuweLeningdelen.every(c => !c.nearMatchHypotheekvormen && !c.matchHypotheekvormen);

  useEffect(() => {
    if (!loading && hasNothingToMatch && !isSubmitting && hypotheekSamenstellingDataState) {
      /* eslint-disable-next-line @typescript-eslint/no-floating-promises */
      submitForm();
    }
  });

  const list = nieuweLeningdelen
    .filter((c: HypotheekSelectieSamenstellingType) => c.nearMatchHypotheekvormen || c.matchHypotheekvormen)
    .map((v: HypotheekSelectieSamenstellingType, i: number): ReactElement | undefined => {
      if (!v.nearMatchHypotheekvormen && !v.matchHypotheekvormen) {
        return undefined;
      }
      return (
        <LabeledSelectInput
          caption={`Leningdeel omzetten naar:`}
          name={`nearMatchHypotheekvorm[${i}]`}
          key={i}
          options={getOptions(v)}
        />
      );
    });

  if (loading || !hypotheekSamenstellingDataState) {
    return <p className="px-1">Laden...</p>;
  } else if (hypotheekSamenstellingDataErrorState) {
    return <p className="px-1">Er is iets fout gegaan bij het ophalen van de data</p>;
  } else if (!loading && hasNothingToMatch) {
    return <></>;
  }

  return (
    <>
      <h3>Aflossingsvorm</h3>
      <p className="px-1">
        De onderstaande aflossingsvorm heeft bij de gekozen maatschappij meerdere producten met deze aflossingsvorm.
        Kies hieronder het gewenste product.
      </p>
      {list}
    </>
  );
};
export default HypotheekSelectieWijzigSamenstelling;
