import { createSpanWithId } from "adviesbox-shared";
import { ReactElement } from "react";
import { Column } from "react-table-6";
import { bedragFormat } from "../../../../shared/utils/currency";
import { decimalFormat } from "../../../../shared/utils/decimal-format";
import { MaximaleHypotheekDataGridTemplates } from "../../../infra/maximale-hypotheek-schema";
import { castUitslag2Icon } from "../maximale-hypotheek-data-grid-icons/maximale-hypotheek-data-grid-icons";

export const getMaximaleHypotheekColumnTemplates = (nhg: boolean): { [key: string]: Column } => {
  return {
    [MaximaleHypotheekDataGridTemplates.uitslag]: {
      Header: "Max. hyp.",
      accessor: "uitslag",
      id: "iconColumn",
      Cell: (c): ReactElement => createSpanWithId(c.index, 0, castUitslag2Icon(c.original.uitslag)),
      width: 70
    },
    [MaximaleHypotheekDataGridTemplates.productnaam]: {
      Header: "Maatschappij",
      accessor: "productnaam",
      Cell: (c): ReactElement => createSpanWithId(c.index, 1, c.original.productnaam, c.original.productnaam)
    },
    [MaximaleHypotheekDataGridTemplates.toetsrente]: {
      Header: nhg ? "NHG%" : "Toetsrente",
      id: "toetsrente",
      accessor: "toetsrente",
      Cell: (c): ReactElement =>
        createSpanWithId(
          c.index,
          2,
          `${decimalFormat(c.original.toetsrente || 0, 3)}%`,
          `${decimalFormat(c.original.toetsrente || 0, 3)}%`
        ),
      width: 60
    },
    [MaximaleHypotheekDataGridTemplates.looptijd]: {
      Header: "Looptijd",
      id: "looptijd",
      accessor: "looptijd",
      Cell: (c): ReactElement => createSpanWithId(c.index, 4, `${c.original.looptijd} jr`, `${c.original.looptijd} jr`)
    },
    [MaximaleHypotheekDataGridTemplates.maximaleHypotheek]: {
      Header: nhg ? "NHG Toetsing" : "Maximale hyp.",
      id: "maximaleHypotheek",
      accessor: "maximaleHypotheek",
      Cell: (c): ReactElement =>
        createSpanWithId(
          c.index,
          5,
          bedragFormat(c.original.maximaleHypotheek || 0),
          bedragFormat(c.original.maximaleHypotheek || 0)
        ),
      width: 80
    },
    [MaximaleHypotheekDataGridTemplates.maximaleHypotheekOnderpand]: {
      Header: nhg ? "NHG Onderpand/lening" : "Onderpand",
      id: "maximaleHypotheekOnderpand",
      accessor: "maximaleHypotheekOnderpand",
      Cell: (c): ReactElement =>
        createSpanWithId(
          c.index,
          6,
          bedragFormat(c.original.maximaleHypotheekOnderpand || 0),
          bedragFormat(c.original.maximaleHypotheekOnderpand || 0)
        ),
      width: 125
    },
    [MaximaleHypotheekDataGridTemplates.inkomstenVerklaring1]: {
      Header: "Inkomensverklaring aan. 1",
      accessor: "inkomstenVerklaring1",
      Cell: (c): ReactElement =>
        createSpanWithId(c.index, 7, c.original.inkomstenVerklaring1, c.original.inkomstenVerklaring1)
    },
    [MaximaleHypotheekDataGridTemplates.inkomstenVerklaring2]: {
      Header: "Inkomensverklaring aan. 2",
      accessor: "inkomstenVerklaring2",
      Cell: (c): ReactElement =>
        createSpanWithId(c.index, 8, c.original.inkomstenVerklaring2, c.original.inkomstenVerklaring2)
    }
  };
};
