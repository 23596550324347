import { LocalDate } from "@js-joda/core";
import { AdviesBoxColumn, CheckBox, createSpanWithId, DataGrid, Icon } from "adviesbox-shared";
import { FormikProps } from "formik";
import React, { ReactElement } from "react";
import { CellInfo, RowInfo } from "react-table-6";
import Modal from "../../shared/components/modal/Modal";
import { UseHdnDataResult } from "../../shared/hooks/use-hdn-data";
import AdviesBoxFormik from "../../shared/utils/adviesbox-formik";
import { getProductenOverzichtTextResources } from "../infra/producten-overzicht-resources";
import { afTeLossenLeningdelenModalSchema } from "../infra/producten-overzicht-schema";
import { AflosproductType, AfTeLossenLeningdelenModalType } from "../infra/producten-overzicht-types";
import classes from "./af-te-lossen-leningdelen-modal.module.scss";

enum ColumnId {
  AflossenCheckBox = "AflossenCheckBox"
}

export type AfTeLossenLeningdelenModalData = {
  data: AfTeLossenLeningdelenModalType;
  einddatum: LocalDate | null;
};

type AfTeLossenLeningdelenModalProps = {
  onSave?: (data: AfTeLossenLeningdelenModalType) => void;
  closeModal?: () => void;
  list?: UseHdnDataResult;
};

const CorrectedMarginCheckBox = (c: RowInfo): ReactElement => (
  <div className={classes.margin_correction}>
    <CheckBox caption="" name={`aflosproducten[${c.index}].aflossen`} />
  </div>
);

/* istanbul ignore next */
const LeningdeelWithWarningName = (
  einddatum: LocalDate | null,
  list?: UseHdnDataResult
): ((c: CellInfo) => ReactElement) => (c: CellInfo): ReactElement => {
  const maatschappijNaam = list?.hdnKeuzelijst[c.original.maatschappijCode];
  const showWarning = valtEindatumBuitenLooptijd(einddatum, [c.original]);

  if (maatschappijNaam || !!c.original.leningdeelBedrag) {
    const elem = createSpanWithId(
      c.index,
      0,
      `${c.original.doorlopend ? "Doorlopend " : ""}\xa0${c.original.leningdeelNummer} - ${
        c.original.omschrijving
      } - €${c.original.leningdeelBedrag.toFixed(0)}`
    );

    return (
      <>
        {showWarning && (
          <span className="ml-1 mr-2 d-inline-flex">
            <Icon name="waarschuwing" alt="waarschuwing" multiColor />
          </span>
        )}
        {elem}
      </>
    );
  }

  const elem = createSpanWithId(
    c.index,
    0,
    `${c.original.doorlopend ? "Doorlopend " : ""}${
      c.original.doorlopend
        ? `${c.original.soortAflosproduct[0].toLowerCase()}${c.original.soortAflosproduct.substring(1)}`
        : c.original.soortAflosproduct
    } ${c.original.leningdeelNummer} - ${c.original.omschrijving}`
  );

  return (
    <>
      {showWarning && (
        <span className="ml-1 mr-2 d-inline-flex">
          <Icon name="waarschuwing" alt="waarschuwing" multiColor />
        </span>
      )}
      {elem}
    </>
  );
};

function getColumns(
  aflosproducten: AflosproductType[],
  einddatumOrv: LocalDate | null,
  list?: UseHdnDataResult
): AdviesBoxColumn[] {
  if (checkIfMultipleAflossenSelected(aflosproducten)) {
    return [
      {
        Header: "Leningdeel",
        accessor: "omschrijving",
        id: "Leningdeel",
        Cell: LeningdeelWithWarningName(einddatumOrv, list)
      },
      {
        Header: "Aflossen",
        accessor: "aflossen",
        id: ColumnId.AflossenCheckBox,
        Cell: CorrectedMarginCheckBox,
        width: 65
      },
      {
        Header: "Prioriteit",
        accessor: "prioriteit",
        Cell: "Prioriteit"
      }
    ];
  }

  return [
    {
      Header: "Leningdeel",
      accessor: "omschrijving",
      Cell: LeningdeelWithWarningName(einddatumOrv, list)
    },
    {
      Header: "Aflossen",
      accessor: "aflossen",
      Cell: CorrectedMarginCheckBox,
      width: 65
    }
  ];
}

export function valtEindatumBuitenLooptijd(einddatum: LocalDate | null, aflosproducten: AflosproductType[]): boolean {
  if (einddatum) {
    return !!aflosproducten
      .filter(p => p.aflossen)
      .filter(p => p.ingangsdatum !== null)
      .filter(p => p.looptijd !== null)
      .find(p =>
        p.ingangsdatum
          ?.plusMonths(p.looptijd ?? 0)
          .atStartOfDay()
          .isAfter(einddatum.atStartOfDay())
      );
  }

  return false;
}

function checkIfMultipleAflossenSelected(aflosproducten: AflosproductType[]): boolean {
  return aflosproducten.filter(p => p.aflossen === true).length > 1;
}

const AfTeLossenLeningdelenModal = ({
  data,
  einddatum,
  onSave,
  closeModal,
  list
}: AfTeLossenLeningdelenModalData & AfTeLossenLeningdelenModalProps): ReactElement => {
  return (
    <AdviesBoxFormik<AfTeLossenLeningdelenModalType>
      initialValues={{ ...data }}
      validationSchema={afTeLossenLeningdelenModalSchema}
      onSave={onSave}
      closeModal={closeModal}
      render={({ values, submitForm, setFieldValue }: FormikProps<AfTeLossenLeningdelenModalType>): ReactElement => {
        let afTeLossenLeningdelenWarning = "";

        if (valtEindatumBuitenLooptijd(einddatum, values.aflosproducten)) {
          afTeLossenLeningdelenWarning = getProductenOverzichtTextResources("afTeLossenLeningdelenWarning");
        }

        return (
          <Modal
            title="Af te lossen leningdelen"
            body={
              <div className="px-1">
                <p>
                  Hieronder kun je één of meerdere leningdelen of kredieten selecteren die bij uitkering van de dekking
                  worden afgelost.
                </p>
                <p className="mb-5">
                  <i>Let op!</i>
                  <br />
                  Indien je de aanvraag via HDN wilt verzenden is het belangrijk eerst het leningdeel te selecteren waar
                  de dekking aan gekoppeld moet worden.
                </p>

                <DataGrid
                  getTrProps={(_: any, rowInfo?: RowInfo) => ({
                    onClick: () => {
                      if (!rowInfo) return;
                      setFieldValue(
                        `aflosproducten[${rowInfo.index}].aflossen`,
                        !values.aflosproducten[rowInfo.index].aflossen
                      );
                    }
                  })}
                  name="aflosproducten"
                  columns={getColumns(values.aflosproducten, einddatum, list)}
                />

                {afTeLossenLeningdelenWarning && (
                  <div className="pt-1">
                    <div className="d-inline-block">{afTeLossenLeningdelenWarning}</div>
                  </div>
                )}
              </div>
            }
            onSubmitClick={submitForm}
            onCancelClick={closeModal}
          />
        );
      }}
    />
  );
};

AfTeLossenLeningdelenModal.displayName = "AfTeLossenLeningdelenModal";

export default AfTeLossenLeningdelenModal;
