import _ from "lodash";
import { SituatieSoort } from "../../producten-overzicht/infra/producten-overzicht-types";
import { HypotheekType, PandRestantType, PandType } from "./hypotheek-types";
import { HypothekenKenmerken, KenmerkenError } from "../../producten-overzicht/infra/product-kenmerken-types";
import { Return } from "../../shared/hooks/use-map";

export const calculateBelastingVerhouding = (
  isTweedeWoning: boolean,
  aftrekbaarBedrag: number,
  leningbedrag: number
): {
  box1Bedrag: number;
  box3Bedrag: number;
  box1Percentage: number;
  box3Percentage: number;
} => {
  // Bepaal box-1 gemaximeerd op eigenwoningschuld
  let deelBox1Bedrag = Math.min(leningbedrag, aftrekbaarBedrag);
  deelBox1Bedrag = isTweedeWoning ? 0 : deelBox1Bedrag;

  // Box-3 bevat bij tweedewoning het gehele leningbedrag, en anders het restant van leningbedrag - box1
  const deelBox3Bedrag = isTweedeWoning ? leningbedrag : leningbedrag - deelBox1Bedrag;

  let deelBox1Percentage = 0;
  if (isTweedeWoning) deelBox1Percentage = 0;

  if (!isTweedeWoning && deelBox1Bedrag && leningbedrag) {
    deelBox1Percentage = (deelBox1Bedrag / leningbedrag) * 100;
  }
  const deelBox3Percentage = 100 - deelBox1Percentage;

  return {
    box1Bedrag: deelBox1Bedrag,
    box3Bedrag: deelBox3Bedrag,
    box1Percentage: deelBox1Percentage,
    box3Percentage: deelBox3Percentage
  };
};

export function getPandRestant(
  panden: PandType[],
  producten: HypotheekType[],
  situatie: SituatieSoort
): PandRestantType {
  if (panden.length === 0) {
    throw new Error("Er kan geen hypotheek bestaan zonder pand");
  }

  let opgeteldeLeningbedrag = null;
  const hypotheekOpWoning = panden.find(pand => {
    const gerelateerdeLeningdelen =
      situatie === "huidig"
        ? producten.filter(
            l => l.hypotheekProductDetails && l.hypotheekProductDetails.hypotheekOpWoning === pand.pandId
          )
        : producten.filter(l => !l.product.doorlopend && !l.hypotheekVorm.isRestschuldLening);
    if (gerelateerdeLeningdelen.length > 0) {
      opgeteldeLeningbedrag = gerelateerdeLeningdelen
        .map(
          p =>
            (situatie === "huidig"
              ? p.leningdeelgegevens.oorspronkelijkeHoofdsom
              : p.hypotheekVorm.isStartersLening // starterslening is al van het gewenstehypotheekbedrag afgetrokken
              ? 0
              : p.leningdeelgegevens.leningdeelHoofdsom.bedrag) || 0
        )
        .reduce((a, b): number => a + b);

      return pand.totaleHypotheekBedrag && opgeteldeLeningbedrag < pand.totaleHypotheekBedrag;
    }

    opgeteldeLeningbedrag = null;
    return true;
  });

  if (situatie === "huidig" && !hypotheekOpWoning) {
    return {
      gelinktePand: panden[0],
      opgeteldeLeningdrag: null
    };
  }

  if (hypotheekOpWoning) {
    if (situatie !== "huidig") {
      return {
        gelinktePand: hypotheekOpWoning,
        opgeteldeLeningdrag: opgeteldeLeningbedrag
      };
    }

    const opgeteldeLeningbedragRestantWoningen = producten
      .filter(
        h => h.hypotheekProductDetails && h.hypotheekProductDetails.hypotheekOpWoning === hypotheekOpWoning.pandId
      )
      .map(p => p.leningdeelgegevens.oorspronkelijkeHoofdsom || 0)
      .reduce((a, b): number => a + b, 0);

    return {
      gelinktePand: hypotheekOpWoning,
      opgeteldeLeningdrag:
        hypotheekOpWoning.totaleHypotheekBedrag &&
        hypotheekOpWoning.totaleHypotheekBedrag > opgeteldeLeningbedragRestantWoningen
          ? hypotheekOpWoning.totaleHypotheekBedrag - opgeteldeLeningbedragRestantWoningen
          : null
    };
  }

  return {
    gelinktePand: panden[0],
    opgeteldeLeningdrag: opgeteldeLeningbedrag
  };
}

export function getLeningdeelSom(
  situatie: string,
  gewensteHypotheekBedrag: number | null,
  opgeteldeLeningdeelBedrag: number | null,
  totaalRestschuld: number,
  gelinktePand: PandRestantType,
  producten: HypotheekType[],
  maatschappijCode: string | null
): number | null {
  return opgeteldeLeningdeelBedrag === null
    ? situatie === "voorstel"
      ? gewensteHypotheekBedrag
      : null
    : opgeteldeLeningdeelBedrag !== null &&
      gewensteHypotheekBedrag &&
      opgeteldeLeningdeelBedrag < gewensteHypotheekBedrag + totaalRestschuld
    ? situatie === "voorstel"
      ? gewensteHypotheekBedrag - opgeteldeLeningdeelBedrag
      : opgeteldeLeningdeelBedrag
    : producten.filter(
        l =>
          !l.product.doorlopend &&
          !l.hypotheekVorm.isRestschuldLening &&
          (maatschappijCode === null || l.partijCode === maatschappijCode) &&
          (situatie === "voorstel" ||
            (situatie === "huidig" &&
              l.hypotheekProductDetails?.hypotheekOpWoning === gelinktePand.gelinktePand.pandId))
      ).length
    ? null
    : gewensteHypotheekBedrag;
}

export function getRestschuld(leningdelen: HypotheekType[]): number {
  return leningdelen
    .filter(l => l.hypotheekVorm.isRestschuldLening)
    .map(
      l =>
        (l.leningdeelgegevens.leningdeelHoofdsom.berekenen
          ? l.leningdeelgegevens.leningdeelHoofdsom.berekendBedrag
          : l.leningdeelgegevens.leningdeelHoofdsom.bedrag) ?? 0
    )
    .reduce((a, b) => a + b, 0);
}

export function getAfrekbaarBedrag(
  eigenwoningschuldBedrag: number,
  leningdelen: HypotheekType[],
  maatschappijCode: string | null
): number {
  return (
    eigenwoningschuldBedrag -
    _.sum(
      leningdelen
        .filter(
          l =>
            !l.product.doorlopend &&
            !l.hypotheekVorm.isRestschuldLening &&
            (maatschappijCode === null || l.partijCode === maatschappijCode)
        )
        .map(c => c.fiscalegegevens.deelBox1Bedrag)
    )
  );
}

export const getKenmerkCodeForProduct = (product: HypotheekType): string =>
  `${product.partijCode}-${product.productCode.padStart(2, "0")}`;

export const getHuidigeProductKenmerken = (
  partijCode: string, 
  productCode: string, 
  productKenmerken: Return<string, HypothekenKenmerken | KenmerkenError>): HypothekenKenmerken | KenmerkenError | undefined => {
  const prodCode =  `${partijCode}-${productCode.padStart(2, "0")}`;
  return productKenmerken[0].get(prodCode);
}

export const getGelinktPand = (situatie: SituatieSoort, leningdeel: HypotheekType, panden: PandType[]): PandType | undefined => {
  if (situatie === "huidig" && leningdeel.hypotheekProductDetails){
    return panden.find(p => p.pandId === leningdeel.hypotheekProductDetails?.hypotheekOpWoning);
  }
  return panden.find(p => p);
}