import { LabelValuePair } from "../../shared/types";
import { getFormattedDate } from "../../shared/utils/dates";
import { bedragFormat } from "../../shared/utils/currency";
import { LoondienstType, PensioenAanvragerType, PensioenState } from "../infra/pensioen-schema";

export const getDienstverbandOptions = (
  deelnemer: PensioenAanvragerType,
  pensioenen: PensioenState[],
  selected: number
): LabelValuePair[] => {
  let availableLoondiensten: LoondienstType[] = [];
  const usedLoondiesntIds: string[] = [];
  let selectedLoonDienstId: string | null = "";

  pensioenen.forEach(pensioen => {
    if (pensioen.loondienstId !== null) {
      if (pensioenen[selected] && pensioen.id === pensioenen[selected].id) {
        selectedLoonDienstId = pensioenen[selected].loondienstId;
      }
      usedLoondiesntIds.push(pensioen.loondienstId);
    }
  });

  availableLoondiensten = deelnemer.loondiensten.filter((loondienst: LoondienstType) => {
    if (loondienst.loondienstId !== null) {
      if (selectedLoonDienstId === loondienst.loondienstId) {
        return true;
      }
      return !usedLoondiesntIds.includes(loondienst.loondienstId);
    }
    return false;
  });

  return availableLoondiensten.map(
    (loondienst: LoondienstType): LabelValuePair => {
      const bedrag = loondienst.totaalBrutoInkomenBedrag === null ? 0 : loondienst.totaalBrutoInkomenBedrag;
      return {
        label: loondienst.meetellenTotDatum
          ? `${bedragFormat(bedrag)}, meetellen tot ${getFormattedDate(loondienst.meetellenTotDatum)}`
          : `${bedragFormat(bedrag)}`,
        value: loondienst.loondienstId || ""
      };
    }
  );
};
