import {
  AuthContext,
  LabeledBevestigingInput,
  LabeledDateInput,
  LabeledTextInput,
  PageLoading,
  SettingsContext,
  validationResultsToUiError,
  PlatformFoutenSamenvatting,
  FormFirstFocus
} from "adviesbox-shared";
import { UiError } from "adviesbox-shared/utils/types";
import classnames from "classnames";
import { connect, Form, FormikContextType } from "formik";
import React, { ReactElement, useContext } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Debug } from "../../shared/components/formik/Debug";
import ParamRouteContext from "../../shared/paramrouting/paramrouting-context";
import { setFormikUiErrors } from "../../shared/utils/set-formik-ui-errors";
import { createNewClient } from "../infra/zoekscherm-api";
import { nieuweKlantSchema, ZoekschermState } from "../infra/zoekscherm-schema";
import zoekSchermClasses from "../zoekscherm.module.scss";
import { mapDlTargetToNewClientUiField } from "./infra/map-nieuwe-klant-dl-to-ui";
import { mapNieuwKlantUiToDl } from "./infra/map-nieuwe-klant-ui-to-dl";
import nieuweKlantModalClasses from "./nieuweKlant.module.scss";

type NieuweKlantModalProps = {
  closeModal?: () => void;
};

const NieuweKlantModal = ({
  closeModal,
  history,
  formik
}: NieuweKlantModalProps & RouteComponentProps & { formik: FormikContextType<ZoekschermState> }): ReactElement => {
  const params = useContext(ParamRouteContext);
  const { user } = useContext(AuthContext);
  const settings = useContext(SettingsContext);

  const closeFunc = (): void => {
    formik.setFieldValue("nieuweKlant", nieuweKlantSchema.default());
    formik.setFieldTouched("nieuweKlant.aanvrager1.achternaam", false);
    formik.setFieldTouched("nieuweKlant.aanvrager1.geboorteDatum", false);
    formik.setFieldTouched("nieuweKlant.aanvrager2.achternaam", false);
    formik.setFieldTouched("nieuweKlant.aanvrager2.geboorteDatum", false);
    closeModal && closeModal();
  };

  const addClient = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> => {
    event.preventDefault();
    if (!user) {
      return;
    }

    const res = await createNewClient(mapNieuwKlantUiToDl(formik.values.nieuweKlant), params.vestiging, settings, user);

    /* istanbul ignore next*/
    if (res.validationResults?.length) {
      const uiErrors: UiError[] = validationResultsToUiError(res.validationResults, mapDlTargetToNewClientUiField);
      setFormikUiErrors(uiErrors, formik);

      return;
    }

    history.push(`/vestiging/${params.vestiging}/adviesdossier/${res.nieuwKlantdossier?.adviesdossierId}/personalia`);
  };

  const closeOnEnterPress = (event: React.KeyboardEvent<HTMLButtonElement>): void => {
    if (event.key === "Enter") {
      closeFunc();
    }
  };

  return (
    <Form data-testid="nieuwe-klant-modal-form">
      {formik.isSubmitting && (
        <div className={zoekSchermClasses.loader_correction}>
          <PageLoading />
        </div>
      )}
      <FormFirstFocus>
        <div>
          <div className={"pb-2 d-flex pt-4 px-4"}>
            <h3 className="px-1">Nieuwe klant</h3>

            <div className={"ml-auto"}>
              <button
                type="button"
                className={"close"}
                onClick={closeFunc}
                id="annuleer-klant-aanmaken-icon"
                data-testid="close-icon"
                onKeyUp={closeOnEnterPress}
              >
                <span aria-hidden="true">×</span>
                <span className="sr-only">Close</span>
              </button>
            </div>
          </div>

          <PlatformFoutenSamenvatting />

          <div className="px-4">
            <LabeledBevestigingInput caption="Advies met medeaanvrager" name="nieuweKlant.hasPartner" />
          </div>
          <div className={classnames("px-4 pb-4", nieuweKlantModalClasses.p_top)}>
            <div>
              <h3 className="px-1">Aanvrager 1</h3>
              <LabeledTextInput verplicht={true} caption={"Achternaam"} name={"nieuweKlant.aanvrager1.achternaam"} />
              <LabeledTextInput caption={"Tussenvoegsel"} name={"nieuweKlant.aanvrager1.tussenVoegsel"} />
              <LabeledTextInput caption={"Roepnaam"} name={"nieuweKlant.aanvrager1.voornaam"} />
              <LabeledDateInput
                verplicht={true}
                caption={"Geboortedatum"}
                name={"nieuweKlant.aanvrager1.geboorteDatum"}
              />
            </div>
            {formik.values.nieuweKlant.hasPartner && (
              <div className={nieuweKlantModalClasses.p_top}>
                <h3 className="px-1">Aanvrager 2</h3>
                <LabeledTextInput verplicht={true} caption={"Achternaam"} name={"nieuweKlant.aanvrager2.achternaam"} />
                <LabeledTextInput caption={"Tussenvoegsel"} name={"nieuweKlant.aanvrager2.tussenVoegsel"} />
                <LabeledTextInput caption={"Roepnaam"} name={"nieuweKlant.aanvrager2.voornaam"} />
                <LabeledDateInput
                  verplicht={true}
                  caption={"Geboortedatum"}
                  name={"nieuweKlant.aanvrager2.geboorteDatum"}
                />
              </div>
            )}
          </div>
          <div className={classnames("d-flex", nieuweKlantModalClasses.modal_footer)}>
            <div className="ml-auto px-4">
              <button
                onKeyUp={closeOnEnterPress}
                className="btn btn-link"
                id="annuleer-klant-aanmaken-button"
                type="button"
                onClick={closeFunc}
              >
                Annuleren
              </button>
              <button
                className="btn btn-primary ml-2"
                id="klant-aanmaken-button"
                type="submit"
                disabled={formik.isSubmitting || formik.isValidating}
                onClick={addClient}
              >
                Klant toevoegen en openen
              </button>
            </div>
          </div>
        </div>
        <Debug />
      </FormFirstFocus>
    </Form>
  );
};

export default connect<NieuweKlantModalProps, ZoekschermState>(withRouter(NieuweKlantModal));
