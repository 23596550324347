import { createGetTextResources } from "../../shared/utils/text-resources-algemeen";

export const getHypotheekTextResources = createGetTextResources({
  geenProductenWaarschuwing: "Geen producten beschikbaar",
  renteVastPeriodeWaarschuwing:
    "Let op! Voor dit bevoorschottingspercentage zijn geen rentevarianten beschikbaar. Bij de invoer zal worden uitgegaan van het hoogst mogelijke bevoorschottingspercentage",
  renteVastPeriodeWaarschuwingExtended:
    "Let op! Voor dit bevoorschottingspercentage zijn geen rentevarianten beschikbaar. Bij de invoer zal worden uitgegaan van het hoogst mogelijke bevoorschottingspercentage.\n\nVoor de gekozen hypotheekvorm zijn in combinatie met NHG geen rentetarieven beschikbaar.",
  rentedalingObvMarktwaardePercentageModalGehanteerdeMarktwaarde:
    "Vul hier de marktwaarde in die bij de geldverstrekker bekend is en waarop de geldverstrekker de rentedaling op basis van lagere LTV zal toepassen.",
  rentedalingObvMarktwaardePercentageModalAutomaticheRentedaling:
    "Rentedaling o.b.v. marktwaardepercentage zorgt ervoor dat de hypotheekrente verlaagd wordt met onderstaande renteopslag, indien de hypotheek in een lagere risicoklasse (verhouding hypotheek en marktwaarde van de woning) terecht komt. Bij 'Automatische rentedaling' wordt de hypotheekrente automatisch verlaagd door de geldverstrekker. Bij 'Op verzoek' wordt de hypotheekrente alleen verlaagd indien er een verzoek bij de geldverstrekker wordt ingediend. De acceptatie en verwerking van de renteverlaging is aan de geldverstrekker. Indien gekozen wordt voor 'Op verzoek' wordt het scenario van rentedaling o.b.v. marktwaardepercentage doorgerekend in het voorstel.",
  rentedalingObvMarktwaardePercentageModalRenteScenario:
    "Met dit rentescenario kan na het verlopen van de rentevastperiode een renteopslag of renteafslag worden toegepast voor de resterende jaren van dit leningdeel. Deze rentewijziging heeft invloed op de rente en periodieke aflossing, maar geen invloed op eventuele premies of inleg.",
  marktwaardeLTV:
    "'Marktwaarde rente' is de getaxeerde marktwaarde. Deze is in te voeren op scherm Financieringsbehoefte bij aankoop of scherm Inventarisatie - Woonsituatie bij het wijzigen van een huidige hypotheek. Op basis hiervan wordt het bevoorschottingspercentage berekend. 'Marktwaarde LTV' is de marktwaarde zoals de gekozen maatschappij deze hanteert als basis voor de maximale hypotheek op basis van onderpand. Adviesbox berekent deze op basis van andere invoer. Je kunt de waarde hier handmatig aanpassen.",
  Box3NietMogelijk: "Deze aflossingsvorm kan alleen in box 1 geplaatst worden.",
  ErrorDatumOpgaveEinddatum: "Datum opgave restant hoofdsom mag niet in de toekomst liggen.",
  RentevastPeriodeJaarError: "Vul een periode in van maximaal 30 jaar.",

  // Lendingdeelgegevens
  RentePercentageTooltip:
    "Het uitgangspunt bij de getoonde rentes is dat alle eventuele hypotheekopties zijn geselecteerd voor het gekozen label/tarief. Deze hypotheekopties zijn in te stellen met de knop naast de naam van het productlabel.",
  IngRenteWaarschuwing:
    "De rentekeuze is gewijzigd. Ga naar de knop 'Hypotheekopties' bovenin dit scherm om opnieuw de actuele rente bij ING op te halen.",
  TridiosRenteWaarschuwing:
    "Het rentetarief is gekoppeld aan het energielabel van het onderpand. Dit kunt u aanpassen op het scherm Analyse - Woning - Onderpand. Afwijken van het rentetarief is mogelijk, echter deze zal dan niet meer gekoppeld zijn aan het betreffende energielabel.",
  ASNRenteWaarschuwing:
    "Als er ook een ASN Duurzaam Wonen leningdeel wordt afgesloten, dan hoeft er geen renteopslag op het reguliere leningdeel worden toegepast. Pas de hypotheekrente indien nodig handmatig aan.",
  LeningdeelbedragTotaleHypotheekError: "Leningdeelbedrag mag niet hoger zijn dan de totale hypotheek.",
  OorspronkelijkeHoofdsomTotaleHypotheekError: "Oorspronkelijke hoofdsom mag niet hoger zijn dan de totale hypotheek.",
  RestantHoofdsomOorspronkelijkeHoofdsomError:
    "De restant hoofdsom mag niet hoger zijn dan de oorspronkelijke hoofdsom.",
  RenteaftrekSpecificatieTekst:
    "Wanneer een samenvoeging van leningdelen noodzakelijk is kan er sprake zijn van een verschillen begin- en einddata binnen het fiscaal aftrekbare gedeelte van het leningdeel. In onderstaande tabel kunt u per deelbedrag een fiscale begin- en einddatum aangeven.",
  ErrorRenteAftrekkenBedragGelijkaanBox1:
    "Het bedrag 'Deel box 1' moet gelijk zijn aan het totaal van alle renteaftrek-bedragen.",
  ErrorRenteAftrekBedrag: "Renteaftrek bedrag moet minimaal 1 zijn",
  ExtraAflossingenTekst: "Aflossingsdata wordt altijd afgerond op de eerste dag van de maand.",
  ErrorExtraAflossingHogerDanLeningdeel:
    "Het totaal aan extra aflossingen kan niet meer zijn dan het (resterende) hypotheekbedrag.",
  ErrorExtraAflossingDatum:
    "De extra aflossing moet binnen de ingangsdatum en einddatum van de hypotheek plaatsvinden.",
  ErrorRentePercentageMax: "Percentage mag maximaal 20 zijn.",
  ErrorProductLooptijd: "De looptijd is minimaal 1 en maximaal 50 jaar.",
  ErrorProductLooptijdMinimaleOpnameDuurMaanden: "De looptijd in jaren mag niet kleiner zijn dan de minimale opnameduur van {0} maanden.",
  RenteScenarioTooltip:
    "Het rentescenario vanaf jaar 2 heeft geen invloed op verzekeringspremies of spaarinleg. Alleen de waarde in jaar 1 wordt gebruikt voor de berekening van de premie of inleg gedurende de looptijd.",
  HypotheekOptiesTooltip: "Geselecteerde hypotheekopties heeft invloed op alle leningdelen.",
  ErrorDoelkapitaalBedragMin: "DoelkapitaalBedrag moet minimaal 1 zijn",
  ErrorDoelkapitaalBedragMax: "DoelkapitaalBedrag mag maximaal 99999999 zijn",
  ErrorDoelKapitaalPercentageMin: "DoelkapitaalPercentage moet minimaal 0 zijn",
  ErrorDoelKapitaalPercentageMax: "DoelkapitaalPercentage mag maximaal 20 zijn",
  ErrorVoorbeeldKapitaalBedragMin: "DoelkapitaalBedrag moet minimaal 1 zijn",
  ErrorVoorbeeldKapitaalBedragMax: "DoelkapitaalBedrag mag maximaal 99999999 zijn",
  ErrorVoorbeeldKapitaalPercentageMin: "DoelkapitaalPercentage moet minimaal 0 zijn",
  ErrorVoorbeeldKapitaalPercentageMax: "DoelkapitaalPercentage mag maximaal 20 zijn",
  ErrorBedragLeningdeel: "Oorspronkelijke hoofdsom is verplicht",
  ErrorAflosvormSelectie: "Aflosvorm is verplicht",
  MaxPercentageRenteopslag: "Percentage opslag invullen.",
  MinMaxPercentageRenteopslag: "Percentage opslag/afslag invullen.",
  MinMaxMarktwaardePercentage: "Percentage marktwaarde invullen.",
  OorspronkelijkeIngangsdatumTooltip:
    "De oorspronkelijke aanvangsdatum van de in te brengen lopende dekking ongeacht de fiscale plaatsing.",
  IngangsdatumBox1Tooltip: "Vul hier de datum in waarop het product (voor de eerste keer) een box 1 product werd.",
  EerdereUitkeringenTooltip:
    "Het bedrag uit originele dekking dat eerder aan de aanvrager is uitgekeerd en welke de vrijstelling in box 1 heeft aangetast.",

  automatischeRentedalingTriggerTooltip:
    "Voor een rentedaling direct bij een lagere LTV moet op dat moment een verzoek ingediend worden. Na de rentevastperiode wordt wel automatisch de lagere LTV toegepast.",
  ErrorOpnameAowBeide: `Voor dit product moeten beide aanvragers reeds de {minimaleLeeftijdInJaren} jarige leeftijd hebben bereikt.`,
  ErrorOpnameAowAanvrager: `Voor dit product moet de aanvrager reeds de {minimaleLeeftijdInJaren} jarige leeftijd hebben bereikt.`
});

export const getHypotheekVergelijkenTextResources = createGetTextResources({
  OnbepaaldeOfferteDuur: "Vul een duur in van maximaal 12 maanden.",
  DoorNaarVoorwaardenselectie: "Door naar voorwaardenselectie",
  ToonResultaat: "Toon resultaat",
  AdviesOverschrijven: "Advies overschrijven met selectie",
  GeenResultaten: "Er zijn nog geen leningdelen toegevoegd."
});

export const getHypotheekIngPricetoolTextResources = createGetTextResources({
  tooltipTekstDagrente:
    "Bij de keuze voor dagrente geldt een renteopslag. Dan geldt de offerterente of de actuele rente op moment van passeren indien deze lager is dan de offerterente.",
  tooltipTekstActieveBetaalrekening:
    "Bij de keuze voor een ING Actieve betaalrekening geldt een rentekorting op de hypotheek.",
  tooltipTekstLoyaliteitsbonus: "Vul hier de door de klant totaal opgebouwde loyaliteiteitsbonus in.",
  tooltipTekstMeenemen:
    "Vraag het rentemiddelingstarief op bij de ING hypothekendesk en vul deze in op het hypotheekscherm bij Rentepercentage."
});
