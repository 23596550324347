import { Pdf, useRequestInit, withErrorBoundary, FetchDataButton } from "adviesbox-shared";
import { useFormikContext } from "formik";
import React, { ReactElement, useRef, useState } from "react";
import { DashboardState } from "../dashboard/infra/dashboard-types";
import { motiveringRapportage } from "../motivering/infra/motivering-rapportage-api";
import { MotiveringOnderwerpsoort } from "../.generated/forms/formstypes";

export type InkomensAnalysePdfType = {
  klantId?: string | null;
  motivering: MotiveringOnderwerpsoort;
  onRefresh?: () => Promise<void>;
  voorstelId?: string;
  dataOutdated: boolean;
  setDataOutdated(value: boolean): void;
};

export const InkomensAnalysePdfComponent = ({
  motivering,
  klantId,
  onRefresh,
  voorstelId,
  dataOutdated,
  setDataOutdated
}: InkomensAnalysePdfType): ReactElement => {
  const [pdfData, setPdfData] = useState<Blob | null | void>();
  const viewer = useRef<HTMLDivElement | null>(null);
  const { user, params, settings } = useRequestInit<{ vestiging: string; adviesdossier: string; voorstel: string }>();
  const abortController = new AbortController();
  const formik = useFormikContext<DashboardState>();

  const reFetchData = () => async (): Promise<void> => {
    onRefresh && (await onRefresh());

    const data = await motiveringRapportage(
      settings,
      user,
      params.vestiging,
      params.adviesdossier,
      abortController.signal,
      motivering,
      klantId,
      voorstelId ?? params.voorstel
    );
    setPdfData(data);

    setDataOutdated(false);
  };

  return (
    <>
      <div className={"d-flex justify-content-center"}>
        <FetchDataButton
          className={"mt-4"}
          onClick={reFetchData()}
          dataOutDated={dataOutdated}
          initialText={"PDF ophalen"}
          invalid={!formik.isValid}
          id="fetch-button-pdf-inkomensanalyse"
          data-testid="fetch-button-pdf-inkomensanalyse"
        />
      </div>
      <>
        {/* display none unless we have data, but make sure to do render -> otherwise the ref isnt satisfied when needed */}
        <div
          id="pdf-viewer-inkomensanalyse"
          data-testid="pdf-viewer-inkomensanalyse"
          ref={viewer}
          style={{ height: "1200px", display: pdfData ? "block" : "none" }}
        />
        {pdfData && /* istanbul ignore next */ <Pdf viewer={viewer.current} blob={pdfData} />}
        {pdfData === null && <p>De PDF kan niet geladen worden.</p>}
      </>
      <br />
    </>
  );
};

export const InkomensAnalysePdf = withErrorBoundary(InkomensAnalysePdfComponent);
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production")
  InkomensAnalysePdf.displayName = "InkomensAnalysePdfComponent";
