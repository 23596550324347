import { AdviesBoxColumn, AdviesBoxFormik, createSpanWithId, DataGrid, FilterType, PlatformFoutenSamenvatting } from "adviesbox-shared";
import { FormikContextType, FormikProps } from "formik";
import React, { ReactElement, useContext, useState } from "react";
import { ISWSideEffects } from "../../../shared/components/isw-side-effects/isw-side-effects";
import Modal from "../../../shared/components/modal/Modal";
import { afronden } from "../../../shared/utils/currency";
import {
  TaxateurNwwiModalSchema,
  TaxateurNwwiModalType,
  TaxateurNwwiResultaatType
} from "../../infra/taxatie-scherm-schema";
import { determineTaxatieAsyncSideEffects } from "./determine-taxateur-async-side-effects";
import classes from "../algemeen-taxatie-data-grid.module.scss";
import { AppDataContext } from "../../../navigation/appdata-context";

export type TaxateurData = {
  data: TaxateurNwwiModalType;
};

type TaxateurModalProps = {
  onSave?: (data: TaxateurNwwiModalType) => void;
  closeModal?: () => void;
};

const TaxateurModalColumns: AdviesBoxColumn[] = [
  {
    Header: "Taxateur",
    accessor: "bedrijfsnaam",
    Cell: (c): ReactElement => createSpanWithId(c.index, 1, c.original.bedrijfsnaam, c.original.bedrijfsnaam)
  },
  {
    Header: "Plaatsnaam",
    accessor: "plaats",
    Cell: (c): ReactElement =>
      createSpanWithId(
        c.index,
        2,
        c.original.plaats?.charAt(0).toUpperCase() + c.original.plaats?.slice(1).toLowerCase(),
        c.original.plaats?.charAt(0).toUpperCase() + c.original.plaats?.slice(1).toLowerCase()
      )
  },
  {
    Header: "E-mail",
    accessor: "emailadres",
    Cell: (c): ReactElement => createSpanWithId(c.index, 3, c.original.emailadres, c.original.emailadres)
  },
  {
    Header: "Telefoon",
    accessor: "telefoonnummer",
    Cell: (c): ReactElement => createSpanWithId(c.index, 4, c.original.telefoonnummer, c.original.telefoonnummer)
  },
  {
    Header: "Afstand tot object",
    accessor: "afstand",
    Cell: (c): ReactElement =>
      createSpanWithId(
        c.index,
        5,
        c.original.afstand
          ? `ca ${afronden(Number(c.original.afstand), 2)
              .toString()
              .replace(".", ",")} km`
          : null
      ),
    maxWidth: 300
  }
];

const TaxateurModal = ({ data, closeModal, onSave }: TaxateurData & TaxateurModalProps): ReactElement => {
  const { setSErunning } = useContext(AppDataContext);
  const selectedRow = useState(0);
  const [, setSelectedRow] = selectedRow;
  const body = (
    resultaat: TaxateurNwwiResultaatType[],
    resultaatIsLeeg: boolean | null,
    setFieldValue: FormikContextType<TaxateurNwwiModalType>["setFieldValue"],
    selectedResultaat: TaxateurNwwiResultaatType | null,
    restObject: any
  ): ReactElement => {
    const onDataGridChange = (data?: TaxateurNwwiResultaatType[]): void => {
      if (!selectedResultaat || !data?.length) return;
      const indexOfSelected = data?.indexOf(selectedResultaat);
      if (indexOfSelected === -1) {
        setFieldValue("selectedResultaat", data[0]);
        setSelectedRow(0);
        return;
      }
      setSelectedRow(indexOfSelected);
    };

    return (
      <>
        <PlatformFoutenSamenvatting className={classes.platform_errors} />
        <div className="px-2">
          {!resultaat?.length && !resultaatIsLeeg && !restObject?.platformApiFouten?.length && (
            <div className="loader" data-testid="loader"></div>
          )}
          <DataGrid
            masterDetail
            className={classes.bordered_result}
            name="resultaat"
            columns={TaxateurModalColumns}
            filterable={true}
            rowSelected={selectedRow}
            filteredCallback={onDataGridChange}
            sortedCallback={onDataGridChange}
            filterConfig={{
              plaats: FilterType.Text
            }}
            getTrProps={(_: any, rowInfo: any) => {
              if (rowInfo && rowInfo.row) {
                return {
                  onClick: () => {
                    setFieldValue("selectedResultaat", rowInfo.original);
                  }
                };
              }
            }}
          />
        </div>
      </>
    );
  };

  return (
    <AdviesBoxFormik<TaxateurNwwiModalType>
      initialValues={{ ...data }}
      validationSchema={TaxateurNwwiModalSchema}
      closeModal={closeModal}
      onSave={(values): void => onSave && onSave(values)}
      render={({
        values: { contractId, postcode, huisnummer, resultaat, resultaatIsLeeg, selectedResultaat, ...restObject },
        setFieldValue,
        submitForm
      }: FormikProps<TaxateurNwwiModalType>): ReactElement => {
        return (
          <>
            <ISWSideEffects<TaxateurNwwiModalType>
              async={determineTaxatieAsyncSideEffects({ contractId, huisnummer, postcode })}
              runOnFirstRender
              asyncStartStopCallback={setSErunning}
            />
            <Modal
              title="Taxateur"
              body={body(resultaat, resultaatIsLeeg, setFieldValue, selectedResultaat, restObject)}
              onSubmitClick={submitForm}
              onCancelClick={closeModal}
              saveButtonTekst="Selecteren"
            />
          </>
        );
      }}
    />
  );
};

TaxateurModal.displayName = "TaxateurModal";
export default TaxateurModal;
