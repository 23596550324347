import React, { ReactElement } from "react";
import { CardWrapper } from "adviesbox-shared";
import Modal from "../../../shared/components/modal/Modal";
import "./klantprofiel-classic-viewer.scss"
import { CategoryOnderdeel } from "./categorie-onderdeel";

type BoeterenteModalProps = {
  closeModal?: () => void;
  profiel: Klantprofiel | null;
};

const KlantprofielClassicModal = ({ closeModal, profiel }: BoeterenteModalProps): ReactElement => {
  const renderBody = (): ReactElement => {
    return (
      <div className="limiter">
        <CardWrapper className="px-3 mt-2" >
          {profiel?.categorieen.map((category, index) => {
            return (
              <CategoryOnderdeel category={category} key={`cat-onderdeel-${index}`} />
            )
          })}
        </CardWrapper>
      </div>
    );
  };

  return (
    <Modal
      title="Klantprofiel - Adviesbox (uit HBX)"
      body={renderBody()}
      onCancelClick={closeModal}
      onSubmitClick={closeModal}
    />
  );
};

export default KlantprofielClassicModal;  