import * as Yup from "yup";
import { getBerekenDateTextResources } from "./bereken-date-resources";
import { LocalDate } from "@js-joda/core";
import { nullableLocalDateSchema } from "adviesbox-shared";

export const berekenDateSchema = Yup.object({
  datum: nullableLocalDateSchema
    .test({
      message: getBerekenDateTextResources("ErrorDateCheckToekomstMax"),
      test: function(value: LocalDate): boolean {
        return !!value ? value.year() < LocalDate.now().year() + 100 : true;
      }
    })
    .test({
      message: getBerekenDateTextResources("ErrorDateCheckToekomstMin"),
      test: function(value: LocalDate): boolean {
        return !!value ? value > LocalDate.now() : true;
      }
    })
    .default(null),
  berekenen: Yup.boolean().default(true),
  berekendeDatum: nullableLocalDateSchema
});

export type BerekenDateType = Yup.InferType<typeof berekenDateSchema>;
