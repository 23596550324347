import { hasValue, mapStringToLocalDate, mapLocalDateToString } from "adviesbox-shared";
import {
  FinancieringOptions,
  HuidigeWoning as HuidigeWoningDl,
  Woning as WoningDl,
  WoningAankoop,
  WoningenOutput as WoningenOutputDl,
  WoningPand as PandDl,
  WoningVerkoop,
  GebruikPandSoort,
  Verkoopstatus
} from "../../.generated/forms/formstypes";
import { mapAdresDlNaarUi } from "../../shared/generic-parts/adres/map-dl-2-ui";
import { mapAdresUiNaarDl } from "../../shared/generic-parts/adres/map-ui-2-dl";
import { onderpandSchema, OnderpandType } from "../../shared/generic-parts/onderpand/schema";
import { UiName } from "../../shared/types";
import { prefixWithZero } from "../../shared/utils/helpers";
import {
  aankoopWoningSchema,
  AankoopWoningType,
  HdnAnders,
  huidigeWoningSchema,
  HuidigeWoningType,
  SoortFoto,
  verkoopWoningSchema,
  VerkoopWoningType,
  woningSchema,
  WoningState
} from "./woning-schema";

function mapTeVerkopenWoningDlNaarUi(values: WoningVerkoop): VerkoopWoningType {
  return verkoopWoningSchema.cast({
    volgNummer: values.volgnummer,
    pandId: values.pandId,
    gebruik: values.gebruikPand || GebruikPandSoort.Geen,
    gebruikPandVoorstel: values.gebruikPandVoorstel || GebruikPandSoort.Geen,
    verkoopstatus: values.verkoopstatus || Verkoopstatus.Geen,
    marktwaarde: values.marktwaardeBedrag,
    verkoopstatusDatum: mapStringToLocalDate(values.verkoopdatum),
    ontbindendeVoorwaardenDatum: mapStringToLocalDate(values.ontbindendeVoorwaardenDatum),
    verkoopprijs: values.verkoopopbrengstBedrag,
    verkoopkosten: values.verkoopkostenBedrag,
    berekenen: values.verkoopkostenOvernemen ?? false,
    verkoopondervoorwaarden: values.verkoopOnderVoorwaarden,
    constructie: prefixWithZero(values.verkoopOnderVoorwaardenConstructie),
    constructieOmschrijving: values.verkoopOnderVoorwaardenConstructieOmschrijving,
    erfpachtConstructieAnders: prefixWithZero(values.soortConstructieAnders),
    adres: mapAdresDlNaarUi(values.adres)
  });
}

function mapHuidigeWoningenDlNaarUi(values: HuidigeWoningDl): HuidigeWoningType {
  return huidigeWoningSchema.cast({
    volgNummer: values.volgnummer,
    pandId: values.pandId,
    marktwaardeBedrag: values.marktwaardeBedrag,
    adres: mapAdresDlNaarUi(values.adres)
  });
}

function getFotoSoortGebruik(
  locatie: string | null | undefined,
  eigenfoto: boolean | null | undefined
): SoortFoto | null {
  if (eigenfoto === true && !!locatie) {
    return SoortFoto.Eigen;
  }
  if (eigenfoto === false) {
    return SoortFoto.GoogleMaps;
  }
  return SoortFoto.Geen;
}

function eigenFotoWaarde(aanTeKopenWoning: AankoopWoningType): boolean | null {
  if (aanTeKopenWoning.soortFoto === SoortFoto.Eigen && !!aanTeKopenWoning.locatieFoto) return true;

  if (aanTeKopenWoning.soortFoto === SoortFoto.GoogleMaps && !!aanTeKopenWoning.locatieFoto) return false;

  return null;
}

function mapOnderpandDlNaarUi(valuesDl?: PandDl | null): OnderpandType {
  const values = valuesDl || ({} as PandDl);
  return onderpandSchema.cast({
    gebruikPand: values.gebruikPand,
    bouwjaar: values.bouwjaar,
    opleverDatum: mapStringToLocalDate(values.opleverdatum),
    bouwPlan: values.bouwplan,
    bouwNummer: values.bouwnummer || "",
    kadastraleGemeente: values.kadasterGemeente || "",
    kadastraleSectie: values.kadasterSectie || "",
    kadastraleNummer: values.kadasterNummer || "",
    gedeeltelijkePerceel: values.gedeeld ?? false,
    appartementsRecht: values.appartement ?? false,
    serviceKosten: values.servicekosten,
    bouwaard: prefixWithZero(values.bouwaard),
    staatVanOnderhoud: prefixWithZero(values.staatOnderhoud),
    verkoopOnderVoorwaarden: values.verkoopOnderVoorwaarden ?? false,
    constructie: prefixWithZero(values.verkoopOnderVoorwaardenConstructie),
    constructieOmschrijving: values.verkoopOnderVoorwaardenConstructieOmschrijving,
    erfpachtConstructieAnders: values.soortConstructieAnders,
    koopAannemingsOvMetVormAfbouwGarantie: values.afbouwgarantie ?? false,
    taxatieAanwezig: values.taxatieAanwezig ?? false,
    taxatieDatum: mapStringToLocalDate(values.taxatiedatum),
    ontbindendeVoorwaardenTot: mapStringToLocalDate(values.ontbindendeVoorwaardenDatum),
    eigendomsVerhoudingAanvrager: values.eigendom1Percentage,
    eigendomsVerhoudingPartner: values.eigendom2Percentage,
    objectSoort: prefixWithZero(values.objectSoort),
    bijzonderObjectSoort: prefixWithZero(values.bijzonderObjectSoort),
    heeftGarage: values.heeftGarage,
    recreatieveBewoning: values.recreatieveBewoning
  });
}

function mapAanTeKopenWoningDlNaarUi(valuesDl?: WoningAankoop | null): AankoopWoningType | null {
  if (valuesDl === null) {
    return null;
  }

  const values = valuesDl || ({} as WoningAankoop);
  return aankoopWoningSchema.cast({
    energieLabel: values.pand && prefixWithZero(values.pand.energieklasse),
    energieklasseDatum: values.pand?.energieklasseDatum ? mapStringToLocalDate(values.pand?.energieklasseDatum) : null,
    adresGelijkAanWoonadres: values.gelijkAanWoonadres ?? false,
    adres: mapAdresDlNaarUi(values.gelijkAanWoonadres ? values.adresVan1eKlant : values.adres),
    woonAdres: mapAdresDlNaarUi(values.adresVan1eKlant),
    onderpand: mapOnderpandDlNaarUi(values.pand),
    // indien deze op 'nee', false gezet wordt dient locatie foto leeg gemaakt te worden.
    fotoGebruikRapportage: typeof values.pand?.eigenFoto === "boolean",
    soortFoto: getFotoSoortGebruik(values.pand?.locatieFoto, values.pand?.eigenFoto),
    locatieFoto: values.pand?.locatieFoto ?? null
  });
}

export function mapWoningDlNaarUi(woningId: string, data: WoningenOutputDl): WoningState | null {
  const woning = data.woningen && data.woningen[woningId];

  if (!woning) {
    return null;
  }

  return woningSchema.cast({
    financiering: woning.financiering || FinancieringOptions.AankoopBestaandeBouw,
    hypotheekOpWoning: woning.hypotheekOpWoning,
    huidigeWoningen: woning.huidigeWoningen
      ? woning.huidigeWoningen.map((huidigeWoning): HuidigeWoningType => mapHuidigeWoningenDlNaarUi(huidigeWoning))
      : [],
    teVerkopenWoningen: woning.teVerkopenWoningen
      ? woning.teVerkopenWoningen.map(
          (teVerkopenWoning): VerkoopWoningType => mapTeVerkopenWoningDlNaarUi(teVerkopenWoning)
        )
      : [],
    aanTeKopenWoning: mapAanTeKopenWoningDlNaarUi(woning.aanTeKopenWoning)
  });
}

function mapPandUiNaarDl(aanTeKopenWoning: AankoopWoningType, pand: OnderpandType): PandDl {
  return {
    gebruikPand: pand.gebruikPand,
    energieklasse: aanTeKopenWoning.energieLabel ? parseInt(aanTeKopenWoning.energieLabel) : null,
    energieklasseDatum: aanTeKopenWoning.energieklasseDatum
      ? mapLocalDateToString(aanTeKopenWoning.energieklasseDatum)
      : null,
    locatieFoto: aanTeKopenWoning.soortFoto === SoortFoto.Eigen ? aanTeKopenWoning.locatieFoto : null,
    typePand: null, // TODO: is obsolete geworden met HDN 24. Dient nog verwijderd te worden (als in backend verwijderd)
    bouwjaar: pand.bouwjaar,
    opleverdatum: mapLocalDateToString(pand.opleverDatum),
    bouwplan: pand.bouwPlan,
    bouwnummer: pand.bouwNummer,
    kadasterGemeente: pand.kadastraleGemeente,
    kadasterSectie: pand.kadastraleSectie,
    kadasterNummer: pand.kadastraleNummer,
    gedeeld: pand.gedeeltelijkePerceel,
    appartement: pand.appartementsRecht,
    servicekosten: pand.serviceKosten,
    bouwaard: pand.bouwaard ? parseInt(pand.bouwaard) : null,
    staatOnderhoud: pand.staatVanOnderhoud ? parseInt(pand.staatVanOnderhoud) : null,
    verkoopOnderVoorwaarden: pand.verkoopOnderVoorwaarden,
    verkoopOnderVoorwaardenConstructie:
      pand.verkoopOnderVoorwaarden && hasValue(pand.constructie) ? parseInt(pand.constructie) : null,
    verkoopOnderVoorwaardenConstructieOmschrijving: pand.constructieOmschrijving,
    soortConstructieAnders:
      pand.verkoopOnderVoorwaarden && hasValue(pand.erfpachtConstructieAnders)
        ? parseInt(pand.erfpachtConstructieAnders)
        : null,
    afbouwgarantie: pand.koopAannemingsOvMetVormAfbouwGarantie,
    taxatieAanwezig: pand.taxatieAanwezig,
    taxatiedatum: mapLocalDateToString(pand.taxatieDatum),
    ontbindendeVoorwaardenDatum: mapLocalDateToString(pand.ontbindendeVoorwaardenTot),
    huidigePandId: null,
    eigenBewoning: null,
    erfpacht: null,
    erfpachtAfgekocht: null,
    erfpachtEeuwigdurend: null,
    erfpachtEinddatum: null,
    erfpachtBedrag: null,
    wozWaarde: null,
    eigenWoningForfaitBedrag: null,
    taxatieBedrag: null,
    zelfbouw: null,
    marktwaardeNaVerbouwingBedrag: null,
    bedoeldVoorVerhuur: null,
    duurBouwInMaanden: null,
    eigendom1Percentage: pand.eigendomsVerhoudingAanvrager,
    eigendom2Percentage: pand.eigendomsVerhoudingPartner,
    marktwaardeBedrag: null,
    marktwaardeOvernemen: null,
    verkoopstatus: null,
    verkoopopbrengstBedrag: null,
    verkoopdatum: null,
    verkoopkostenBedrag: null,
    verkoopkostenOvernemen: null,
    kavelGrootteInM2: null,
    hypotheekOpPand: null,
    oorspronkelijkHypotheekbedrag: null,
    bankgarantieDatum: null,
    erfpachtParticulier: null,
    eigenFoto: eigenFotoWaarde(aanTeKopenWoning),
    bijzonderObjectSoort: pand.bijzonderObjectSoort ? parseInt(pand.bijzonderObjectSoort) : null,
    heeftGarage: pand.heeftGarage,
    objectSoort: pand.objectSoort ? parseInt(pand.objectSoort) : null,
    recreatieveBewoning: pand.recreatieveBewoning
  };
}

function mapAanTeKopenWoningUiNaarDl(value: AankoopWoningType): WoningAankoop {
  return {
    pand: mapPandUiNaarDl(value, value.onderpand),
    gelijkAanWoonadres: value.adresGelijkAanWoonadres,
    adres: mapAdresUiNaarDl(value.adres),
    adresVan1eKlant: mapAdresUiNaarDl(value.woonAdres)
  };
}

function mapHuidigeWoningenUiNaarDl(values: HuidigeWoningType[]): HuidigeWoningDl[] {
  const huidigeWoningen = Array<HuidigeWoningDl>();
  values.forEach((item): void => {
    huidigeWoningen.push({
      pandId: item.pandId,
      volgnummer: item.volgNummer,
      marktwaardeBedrag: item.marktwaardeBedrag,
      adres: mapAdresUiNaarDl(item.adres)
    });
  });
  return huidigeWoningen;
}

function mapTeVerkopenWoningenUiNaarDl(values: VerkoopWoningType[]): WoningVerkoop[] {
  const teVerkopenWoningen = Array<WoningVerkoop>();
  values.forEach((woning): void => {
    teVerkopenWoningen.push({
      volgnummer: woning.volgNummer,
      pandId: woning.pandId,
      gebruikPand: woning.gebruik,
      gebruikPandVoorstel: woning.gebruikPandVoorstel,
      marktwaardeBedrag: woning.marktwaarde,
      verkoopstatus: woning.verkoopstatus,
      verkoopdatum: mapLocalDateToString(woning.verkoopstatusDatum),
      ontbindendeVoorwaardenDatum: mapLocalDateToString(woning.ontbindendeVoorwaardenDatum),
      verkoopopbrengstBedrag: woning.verkoopprijs,
      verkoopkostenBedrag: woning.verkoopkosten,
      verkoopOnderVoorwaarden: woning.verkoopstatus === "BlijftInBezit" ? null : woning.verkoopondervoorwaarden,
      verkoopOnderVoorwaardenConstructie:
        woning.verkoopondervoorwaarden && woning.constructie ? parseInt(woning.constructie) : null,
      verkoopOnderVoorwaardenConstructieOmschrijving:
        woning.verkoopondervoorwaarden && woning.constructie && woning.constructie === `${HdnAnders}`
          ? woning.constructieOmschrijving
          : null,
      soortConstructieAnders: hasValue(woning.erfpachtConstructieAnders)
        ? parseInt(woning.erfpachtConstructieAnders)
        : null,
      verkoopkostenOvernemen: woning.verkoopstatus !== Verkoopstatus.BlijftInBezit ? woning.berekenen : null,
      adres: mapAdresUiNaarDl(woning.adres)
    });
  });

  return teVerkopenWoningen;
}

export function mapWoningUiNaarDl(woning: WoningState): WoningDl {
  return {
    aanTeKopenWoning: woning.aanTeKopenWoning ? mapAanTeKopenWoningUiNaarDl(woning.aanTeKopenWoning) : null,
    teVerkopenWoningen: mapTeVerkopenWoningenUiNaarDl(woning.teVerkopenWoningen),
    huidigeWoningen: mapHuidigeWoningenUiNaarDl(woning.huidigeWoningen),
    hypotheekOpWoning: woning.hypotheekOpWoning,

    financiering: null
  };
}

export function mapWoningDlNameToUiName(dlName: string): UiName | null {
  const teVerkopenWoningenRegExp = /TeVerkopenWoningen\[(\d+)\].(\w+)/;

  const teVerkopenWoningenMatch = teVerkopenWoningenRegExp.exec(dlName);

  if (teVerkopenWoningenMatch) {
    const index = +teVerkopenWoningenMatch[1];
    const veld = teVerkopenWoningenMatch[2];

    switch (veld) {
      case "VerkoopOnderVoorwaardenConstructie":
        return { field: `teVerkopenWoningen[${index}].constructie`, label: null };
      case "VerkoopOnderVoorwaarden":
        return { field: `teVerkopenWoningen[${index}].verkoopondervoorwaarden`, label: null };
      default:
        return null;
    }
  } else if (dlName === "Financiering") {
    return { field: "financiering", label: null };
  }

  return null;
}
