import { ReactElement } from "react";
import { BetalingsTermijnType } from "../.generated/forms/formstypes";
import { AdviesBoxColumn, createSpanWithId } from "adviesbox-shared";
import { KlantnaamType } from "../shared/generic-parts/klantnaam/schema";
import { jaarMaandInputNaarJaarMaand } from "../shared/generic-parts/jaar-maand/helpers";
import { SituatieSoort } from "../producten-overzicht/infra/producten-overzicht-types";
import { bepaalAanvragerLabelValuePairs, getBetalingsTermijnLabel } from "./infra/helpers";

export const bepaalOverigePostenKolommen = (
  situatie: SituatieSoort,
  aanvrager1: KlantnaamType | null,
  aanvrager2: KlantnaamType | null
): AdviesBoxColumn[] => {
  const aanvragers = bepaalAanvragerLabelValuePairs(aanvrager1, aanvrager2);

  return [
    {
      Header: "Overige",
      Cell: (c): ReactElement =>
        createSpanWithId(
          c.index,
          0,
          situatie === "huidig" ? "Bestaand" : c.original.product.doorlopend ? "Doorlopend" : "Nieuw"
        )
    },
    {
      Header: "Omschrijving",
      Cell: (c): ReactElement =>
        createSpanWithId(c.index, 1, c.original.product.omschrijving, c.original.product.omschrijving)
    },
    {
      Header: "Heffing bij",
      Cell: "SelectInput",
      accessor: "heffing.heffingBij",
      config: {
        options: aanvragers,
        readonly: true
      }
    },
    {
      Header: "Correctie last",
      Cell: "TextInput",
      accessor: "correctie.correctieLast",
      config: { readonly: true }
    },
    {
      Header: "Correctie fiscaal",
      Cell: "TextInput",
      accessor: "correctie.correctieFiscaal",
      config: { readonly: true }
    },
    {
      Header: "Aanvang",
      Cell: "DateInput",
      accessor: "product.ingangsdatum",
      config: { readonly: true }
    },
    {
      Header: "Looptijd",
      Cell: (c): ReactElement => {
        let title = jaarMaandInputNaarJaarMaand(c.original.product.looptijd);
        if (c.original.overigePostenGegevens.termijn === BetalingsTermijnType.Eenmalig) {
          title = getBetalingsTermijnLabel(BetalingsTermijnType.Eenmalig);
        }
        return createSpanWithId(c.index, 6, title, title);
      }
    },
    {
      Header: "Bedrag",
      Cell: "CurrencyInput",
      accessor: "overigePostenGegevens.bedrag",
      config: { readonly: true }
    },
    {
      Header: "Termijn",
      Cell: (c): ReactElement =>
        createSpanWithId(c.index, 8, getBetalingsTermijnLabel(c.original.overigePostenGegevens.termijn))
    },
    {
      Cell: "DeleteButton"
    }
  ];
};
