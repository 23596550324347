import React, { ReactElement } from "react";
import { connect, FormikContextType } from "formik";


import { getAanvragerOpties } from "../infra/producten-helper";
import { AanvragerKeuze } from "../../shared/types";
import { VerzekerdenKenmerken } from "../infra/product-kenmerken-types";
import { LabeledRadioInput, LabeledBevestigingInput  } from "adviesbox-shared";
import { ProductenState } from "../infra/producten-overzicht-types";

type VerzekerdenProps = {
  selected: number;
  caption?: string | null;
  kenmerken: VerzekerdenKenmerken;
};

const Verzekerden = ({
  selected,
  caption,
  kenmerken,
  formik: {
    values: { producten, aanvrager1, aanvrager2 }
  }
}: {
  formik: FormikContextType<ProductenState>;
} & VerzekerdenProps): ReactElement => {
  const verzekerdenOpties = getAanvragerOpties(aanvrager1, aanvrager2, kenmerken.meerdereVerzekerdeOptiesMogelijk);

  return (
    <>
      <LabeledRadioInput
        caption={caption ?? (verzekerdenOpties.length > 1 ? "Verzekerde(n)" : "Verzekerde")}
        name={`producten[${selected}].verzekerden.verzekerden`}
        options={verzekerdenOpties}
      />

      {kenmerken.premiesplitsingTonen &&
        producten[selected].verzekeringnemers.verzekeringnemers === AanvragerKeuze.Beiden && (
          <LabeledBevestigingInput
            caption="Premiesplitsing"
            name={`producten[${selected}].verzekerden.premiesplitsing`}
          />
        )}
    </>
  );
};

export default connect<VerzekerdenProps, ProductenState>(Verzekerden);
