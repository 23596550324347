import { createISWSideEffect, initISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";
import { Verblijfsvergunning } from "../infra/personalia-schema";

export const verblijfsvergunningSideEffects = createISWSideEffect<Verblijfsvergunning>(({ draft, prev }): void => {
  if (draft.soortVerblijfsvergunning !== prev.soortVerblijfsvergunning && prev.soortVerblijfsvergunning !== null) {
    draft.geldigTot = null;
    draft.nummerVerblijfsvergunning = null;
  }
});
export const determineVerblijfsvergunningSideEffects = initISWSideEffect(verblijfsvergunningSideEffects)();
