import { FormikContextType } from "formik";
import React, { Dispatch, ReactElement, SetStateAction, useContext, useState, useEffect } from "react";
import { Modal as DisplayModal } from "react-bootstrap";
import DossierAanvraagFoutenContext from "../../../dossier-aanvraag-fouten/dossier-aanvraag-fouten-context";
import { withAdviesboxFormik } from "../../../utils/with-adviesbox-formik";
import AanvullendeGegevensModal from "./aanvullende-gegevens-modal";
import { aanvullendeGegevensSchema, AanvullendeGegevensType, ErrorType } from "./aanvullende-gegevens-schema";
import DossierbalkFoutEntry from "./dossierbalk-fout-entry";
import classes from "./DossierbalkFoutenDropdown.module.scss";
import { MeerGegevensNodigModal } from "./meer-gegevens-nodig-modal";

type DossierbalkFoutenDropdownProps = AanvullendeGegevensType & {
  reloadNavigation: () => void;
  openModalIsClicked: boolean;
  setOpenModalIsClicked: React.Dispatch<React.SetStateAction<boolean>>;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
};

/* istanbul ignore next */
export const removeFoutEntity = (
  aanvullendeGegevens: AanvullendeGegevensType,
  setAanvullendeGegevens: (aanvullendeGegevens: AanvullendeGegevensType) => void,
  [dropdownFoutenArray, setDropdownFoutenArray]: [ErrorType[], Dispatch<SetStateAction<ErrorType[]>>]
) => (id: string): void => {
  aanvullendeGegevens.modalFoutenArray = aanvullendeGegevens.modalFoutenArray.filter(v => v.errorId !== id);
  setAanvullendeGegevens(aanvullendeGegevens);
  setDropdownFoutenArray(dropdownFoutenArray?.filter(v => v.errorId !== id));
};

const DossierbalkFoutenDropdown = (
  props: FormikContextType<AanvullendeGegevensType> & DossierbalkFoutenDropdownProps
): ReactElement => {
  const { values, reloadNavigation, setOpenModalIsClicked, openModalIsClicked, setShowModal, showModal } = props;
  const { setAanvullendeGegevens } = useContext(DossierAanvraagFoutenContext);
  const dossierFouten = values.modalFoutenArray ?? [];

  const errorsMetUrl = useState<ErrorType[]>([]);
  const [dropdownFoutenArray, setDropdownFoutenArray] = errorsMetUrl;
  useEffect(() => {
    setDropdownFoutenArray(dossierFouten.filter(v => v.locatie && v.app && v.relativeUrl));
  }, [dossierFouten, setDropdownFoutenArray]);

  return (
    <div className={classes.dossier_fouten}>
      {!!dossierFouten.length && (
        <>
          <div className={classes.dropdown_title}>
            <div>
              <span>Fouten in dit dossier ({dossierFouten.length})</span>
            </div>
          </div>
          <div className={classes.dropdown_body}>
            {dropdownFoutenArray.map((item, i) => {
              return (
                <DossierbalkFoutEntry
                  key={i}
                  id={i}
                  item={item}
                  removeItem={
                    /* istanbul ignore next */
                    removeFoutEntity({ ...values }, setAanvullendeGegevens, errorsMetUrl)
                  }
                  vestiging={values.vestigingId}
                  adviesdossier={values.adviesdossierId}
                  voorstel={values.analyseId}
                  reloadNavigation={reloadNavigation}
                />
              );
            })}
            {dropdownFoutenArray.length < dossierFouten.length && (
              <div className={classes.entry} data-testid="aanvullende-gegevens" id="aanvullende-gegevens">
                <div className={classes.entry_title}>
                  <h4>{`Aanvullende gegevens moeten ingevuld worden (${dossierFouten.length -
                    dropdownFoutenArray.length})`}</h4>
                </div>

                <div data-testid="aanvullende_text" className={classes.entry_body_i}>
                  <div>- Aanvullende velden moeten ingevuld worden.</div>
                  <div>De aanvullende velden kunnen ingevuld worden door op onderstaande link te klikken.</div>

                  <div
                    onClick={() => setOpenModalIsClicked(true)}
                    className={classes.link}
                    data-testid={`fouten-aanvullende-gegevens`}
                    id={`fouten-aanvullende-gegevens`}
                  >
                    Velden invullen
                  </div>
                </div>
              </div>
            )}
          </div>
          <DisplayModal show={openModalIsClicked}>
            <AanvullendeGegevensModal
              closeModal={
                /* istanbul ignore next */ () => {
                  if (
                    !!dossierFouten.some(x => !(x.locatie || x.app || x.relativeUrl) && x.FEVeldInfo?.waarde === null)
                  ) {
                    setShowModal(true);
                  }
                  setOpenModalIsClicked(false);
                }
              }
            />
          </DisplayModal>
          <DisplayModal show={showModal}>
            <MeerGegevensNodigModal
              closeModal={
                /* istanbul ignore next */ () => {
                  setShowModal(false);
                }
              }
            />
          </DisplayModal>
        </>
      )}
    </div>
  );
};

export default withAdviesboxFormik<DossierbalkFoutenDropdownProps, AanvullendeGegevensType>({
  mapPropsToValues: (e: DossierbalkFoutenDropdownProps): AanvullendeGegevensType => e,
  validationSchema: aanvullendeGegevensSchema,
  enableReinitialize: true
})(DossierbalkFoutenDropdown);
