import React, { ReactElement, useContext } from "react";
import { FormikProps } from "formik";

import { Financieringsoort } from "../../.generated/forms/formstypes";
import { bedragFormat } from "../../shared/utils/currency";
import memoizeOne from "memoize-one";
import { eigenWoningSchuldAsyncSideEffects } from "./eigenwoningschuld-modal-async-side-effects";
import { getInvesteringBedrag } from "../infra/financieringsbehoefte-berekenen-helper";
import { ISWSideEffects } from "../../shared/components/isw-side-effects/isw-side-effects";
import {
  LabeledCurrencyInput,
  BerekenCurrencyButton,
  PercentageInput,
  BerekenCurrencyInput,
  LabeledResult
} from "adviesbox-shared";
import { decimalFormat } from "../../shared/utils/decimal-format";
import AdviesBoxFormik from "../../shared/utils/adviesbox-formik";
import Modal from "../../shared/components/modal/Modal";
import { EigenwoningschuldModalCompleet, FinancieringsbehoefteState } from "../infra/financieringsbehoefte-types";
import { getFinancieringsbehoefteTextResources } from "../infra/financieringsbehoefte-resources";
import { eigenwoningschuldCompleetModalSchema } from "../infra/financieringsbehoefte-schema";
import classes from "./eigenwoningschuld-modal.module.scss";
import { AppDataContext } from "../../navigation/appdata-context";

export type EigenwoningschuldModalData = {
  data: EigenwoningschuldModalCompleet;
  formValues: FinancieringsbehoefteState;
};

type EigenwoningschuldModalProps = {
  onSave?: (data: EigenwoningschuldModalCompleet) => void;
  closeModal?: () => void;
};

export const getFinancieringsKostenTooltip = (
  soortFinanciering: Financieringsoort,
  gewensteLening: number,
  investeringsBedrag: number,
  financieringsKosten: number,
  eigenWoningReserveVervreemdingSaldo: number,
  financieringsKostenPercentage: number
): string => {
  let tooltip = "";

  if (
    soortFinanciering === Financieringsoort.AankoopBestaandeBouw ||
    soortFinanciering === Financieringsoort.AankoopNieuwbouw
  ) {
    tooltip = `${getFinancieringsbehoefteTextResources(
      "TooltipFinancieringsKostenAankoopBestaandeBouwAankoopNieuwbouw"
    )} (${bedragFormat(investeringsBedrag)} + ${bedragFormat(financieringsKosten)} -/- ${bedragFormat(
      eigenWoningReserveVervreemdingSaldo
    )}) / ${bedragFormat(gewensteLening)} = ${decimalFormat(financieringsKostenPercentage, 4)}%. `;
  }

  if (soortFinanciering === Financieringsoort.Omzetting) {
    tooltip = `${getFinancieringsbehoefteTextResources("TooltipFinancieringsKostenOmzetting")} ${bedragFormat(
      investeringsBedrag
    )} / (${bedragFormat(gewensteLening)} -/- ${bedragFormat(financieringsKosten)}) = ${decimalFormat(
      financieringsKostenPercentage,
      4
    )}%. `;
  }

  return tooltip;
};

const memoizedTooltip = memoizeOne(getFinancieringsKostenTooltip);

const body = (values: EigenwoningschuldModalCompleet, formValues: FinancieringsbehoefteState): ReactElement => {
  return (
    <>
      <div className="input-right-allign">
        {values.koopsomWoning !== 0 && <LabeledCurrencyInput caption="Koopsom woning" name="koopsomWoning" readonly />}
        {values.bouwrente !== 0 && <LabeledCurrencyInput caption="Bouwrente" name="bouwrente" readonly />}
        {values.verbouwing !== 0 && <LabeledCurrencyInput caption="Verbouwing" name="verbouwing" readonly />}

        {values.meerwerk !== 0 && <LabeledCurrencyInput caption="Totaal meerwerk" name="meerwerk" readonly />}

        {values.overdrachtsbelasting !== 0 && (
          <LabeledCurrencyInput caption="Overdrachtsbelasting" name="overdrachtsbelasting" readonly />
        )}

        {values.makelaarsCourtage !== 0 && (
          <LabeledCurrencyInput caption="Makelaarscourtage" name="makelaarsCourtage" readonly />
        )}

        {values.leveringsakte !== 0 && <LabeledCurrencyInput caption="Leveringsakte" name="leveringsakte" readonly />}

        {values.afkoopErfpacht !== 0 && (
          <LabeledCurrencyInput caption="Afkoop erfpacht" name="afkoopErfpacht" readonly />
        )}

        {values.vergoedingPartnerAkteVerdeling !== 0 && (
          <LabeledCurrencyInput
            caption="Vergoeding partner akte verdeling"
            name="vergoedingPartnerAkteVerdeling"
            readonly
          />
        )}
        <LabeledResult
          caption="Investering"
          name="investeringBedrag"
          fontWeight="bold"
          alignRight={true}
          result={(): string => bedragFormat(getInvesteringBedrag(formValues))}
        />

        <br />

        {values.eigenWoningReserveVervreemdingSaldo !== 0 && (
          <>
            <LabeledCurrencyInput
              caption="Eigenwoningreserve en vervreemdingssaldo"
              name="eigenWoningReserveVervreemdingSaldo"
              readonly
            />
          </>
        )}

        {values.doorlopendeLeningdelenBox1Bedrag !== 0 && (
          <LabeledCurrencyInput
            caption="Doorlopende hypothe(e)k(en) box 1"
            name="doorlopendeLeningdelenBox1Bedrag"
            readonly
          />
        )}

        {values.aftrekbareAdvieskosten !== 0 && (
          <LabeledCurrencyInput caption="Aftrekbaar deel advieskosten" name="aftrekbareAdvieskosten" readonly />
        )}

        {values.nhgKosten !== 0 && (
          <LabeledCurrencyInput caption="Nationale Hypotheek Garantie" name="nhgKosten" readonly />
        )}

        {values.arbeidskostenNotaris !== 0 && (
          <LabeledCurrencyInput caption="Arbeidskosten notaris" name="arbeidskostenNotaris" readonly />
        )}
        {values.hypotheekakte !== 0 && <LabeledCurrencyInput caption="Hypotheekakte" name="hypotheekakte" readonly />}
        {values.taxatieKosten !== 0 && <LabeledCurrencyInput caption="Taxatie" name="taxatieKosten" readonly />}
        {values.afsluitkostenSVnStarterslening !== 0 && (
          <LabeledCurrencyInput
            caption="Afsluitkosten SVn Starterslening"
            name="afsluitkostenSVnStarterslening"
            readonly
          />
        )}
        {values.afsluitkostenOverbruggingsKrediet !== 0 && (
          <LabeledCurrencyInput
            caption="Afsluitkosten overbruggingskrediet"
            name="afsluitkostenOverbruggingsKrediet"
            readonly
          />
        )}
        {values.bereidstellingsKosten !== 0 && (
          <LabeledCurrencyInput caption="Bereidstellingskosten" name="bereidstellingsKosten" readonly />
        )}
        {values.omzettingLeningdeelKosten !== 0 && (
          <LabeledCurrencyInput caption="Omzetting leningdeel" name="omzettingLeningdeelKosten" readonly />
        )}
        {values.aftrekbareBoeterente !== 0 && (
          <LabeledCurrencyInput caption="Boeterente" name="aftrekbareBoeterente" readonly />
        )}
        {values.overigekostenBox1 !== 0 && (
          <LabeledCurrencyInput caption="Overige kosten box1" name="overigekostenBox1" readonly />
        )}

        <LabeledCurrencyInput
          caption="Financieringskosten"
          name="financieringsKosten"
          decimalen={2}
          disabled={true}
          readonly
          fontWeight="bold"
          tooltip={memoizedTooltip(
            values.soortFinanciering,
            formValues.financieringsopzet.gewensteHypotheek || 0,
            getInvesteringBedrag(formValues) || 0,
            values.financieringsKosten,
            values.eigenWoningReserveVervreemdingSaldo,
            values.financieringsKostenPercentage
          )}
          appendChildren={
            <div className="d-flex flex-wrap three-column-layout">
              <div className="px-2 mt-1">X</div>
              <div className="px-1">
                <PercentageInput
                  name="financieringsKostenPercentage"
                  decimalen={2}
                  fieldSize={"s"}
                  className={`d-flex align-items-center ${classes.custom_percentage_input}`}
                  readonly={values.financieringskostenAftrekbaarOvernemen}
                  appendChildren={
                    <BerekenCurrencyButton
                      name="financieringskostenAftrekbaarOvernemen"
                      berekenen={values.financieringskostenAftrekbaarOvernemen}
                    />
                  }
                />
              </div>

              <div className="form-group form-row border-0">
                <div className="medium">
                  <div
                    id={"totaalFinanciering"}
                    data-testid={"totaalFinanciering"}
                    className="col-form-label totaal-container"
                    aria-label={"Totale financiering"}
                    style={{ fontWeight: "normal" }}
                  >
                    {values.financieringsKosten > 0 && values.financieringsKostenPercentage > 0
                      ? bedragFormat(values.financieringsKosten * (values.financieringsKostenPercentage / 100), 0)
                      : bedragFormat(0)}
                  </div>
                </div>
              </div>
            </div>
          }
        />

        {values.leningdelenWordenAfgelost && (
          <LabeledCurrencyInput
            readonly={true}
            caption="Af te lossen bestaande hypothe(e)k(en)"
            name={
              values.afTeLossenBestaandeHypotheken.berekenen
                ? "afTeLossenBestaandeHypotheken.berekendBedrag"
                : "afTeLossenBestaandeHypotheken.bedrag"
            }
            disabled={true}
          />
        )}

        {values.aflossingOpEigenwoningschuld !== 0 && (
          <LabeledCurrencyInput caption="Aflossing op eigenwoningschuld" name="aflossingOpEigenwoningschuld" readonly />
        )}
        <LabeledCurrencyInput
          caption="Maximaal te financieren in box1"
          name="maximaalTeFinancierenBox1"
          readonly
          fontWeight="bold"
        />
        <LabeledCurrencyInput
          caption="Maximaal gelijk aan gewenste hypotheek"
          name="maximaalGelijkAanGewensteHypotheek"
          readonly
        />
      </div>
      <div className="totaal">
        <BerekenCurrencyInput caption="Eigenwoningschuld" name="eigenwoningschuld" />
      </div>
    </>
  );
};

const EigenwoningschuldModal = ({
  data,
  formValues,
  onSave,
  closeModal
}: EigenwoningschuldModalData & EigenwoningschuldModalProps): ReactElement => {
  const { setSErunning } = useContext(AppDataContext);

  return (
    <AdviesBoxFormik<EigenwoningschuldModalCompleet>
      initialValues={{ ...data }}
      validationSchema={eigenwoningschuldCompleetModalSchema}
      onSave={onSave}
      closeModal={closeModal}
      render={({ values, submitForm }: FormikProps<EigenwoningschuldModalCompleet>): ReactElement => {
        return (
          <>
            <ISWSideEffects<EigenwoningschuldModalCompleet>
              async={eigenWoningSchuldAsyncSideEffects({ financieringsBehoefte: formValues })}
              asyncStartStopCallback={setSErunning}
            />
            <div className="modal-eigenwoningschuld">
              <Modal
                title="Eigenwoningschuld"
                body={body(values, formValues)}
                onSubmitClick={submitForm}
                onCancelClick={closeModal}
              />
            </div>
          </>
        );
      }}
    />
  );
};

export default EigenwoningschuldModal;
