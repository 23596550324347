import { createISWSideEffect, initISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";
import { HypothekenState } from "../infra/hypotheek-types";

export const HypotheekOptiesIngSideEffects = createISWSideEffect<HypothekenState, { selected: number }>((bag): void => {
  const { draft, has, context } = bag;
  const selectedProduct = draft.producten[context.selected];

  if (
    has.hypotheekOptiesIngPriceTool.changed &&
    selectedProduct &&
    selectedProduct.hypotheekOptiesIngPriceToolLeningdeel &&
    !!selectedProduct.hypotheekOptiesIngPriceToolLeningdeel.loyaliteitsbonusBedrag &&
    draft.hypotheekOptiesIngPriceTool &&
    !draft.hypotheekOptiesIngPriceTool.loyaliteitsbonus
  ) {
    selectedProduct.hypotheekOptiesIngPriceToolLeningdeel.loyaliteitsbonusBedrag = null;
  }
});

export const determineHypotheekOptiesIngSideEffects = initISWSideEffect<HypothekenState, { selected: number }>(
  HypotheekOptiesIngSideEffects
);
