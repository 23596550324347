import * as Yup from "yup";
import { DuurPensioenuitkeringOptions } from "../../../.generated/forms/formstypes";
import { berekenInputSchema } from "../../../shared/types";
import { yupEnum } from "../../../shared/utils/yup-enum";
import { controleNormSchema, controleNormVeldSchema } from "../../components/controle-norm/infra/controle-norm-schema";
import { scenarioVanSchema } from "../../components/scenario-van/scenario-van-schema";

export const beschikbareJaarruimteSchema = Yup.object({
  winstOndernemingsaftrek: berekenInputSchema,
  inkomenHuidigeDienstbetrekking: berekenInputSchema,
  inkomenVroegereDienstbetrekking: berekenInputSchema,
  buitenlandseInkomsten: Yup.number().nullable(),
  reisaftrekWoonWerkverkeer: Yup.number().nullable(),
  resultaatOverigeWerkzaamheden: berekenInputSchema,
  periodiekeUitkering: Yup.number().nullable(),
  premiegrondslagBedrag: Yup.number(),
  maxPremieGrondslag: Yup.number(),
  franchiseBedrag: Yup.number(),
  subtotaalFranchise1Bedrag: Yup.number(),
  premiegrondslagPercentage: Yup.number(),
  subtotaalFranchise2Bedrag: Yup.number(),
  pensioenaangroeiBedrag: Yup.number().nullable(),
  pensioenaangroeiPercentage: Yup.number(),
  subtotaalPensioenaangroeiBedrag: Yup.number(),
  toevoegingOudedagreserve: Yup.number().nullable(),
  jaarruimteBedrag: Yup.number(),
  benutteJaarruimteBedrag: berekenInputSchema,
  beschikbareJaarruimteBedrag: Yup.number(),
  gewenstePensioenLeeftijd: Yup.number().nullable(),
  gewenstePensioenUitkering: yupEnum(DuurPensioenuitkeringOptions).default(DuurPensioenuitkeringOptions.Levenslang)
});

export type BeschikbareJaarruimteType = Yup.InferType<typeof beschikbareJaarruimteSchema>;

export const scenarioPensioenModalSchema = controleNormVeldSchema.shape({
  scenarioVan: scenarioVanSchema,
  controleNorm: controleNormSchema,
  beschikbareJaarruimteAanvrager: beschikbareJaarruimteSchema.nullable(),
  beschikbareJaarruimtePartner: beschikbareJaarruimteSchema.nullable()
});

export type ScenarioPensioenModalType = Yup.InferType<typeof scenarioPensioenModalSchema>;
export type ScenarioPensioenModalProps = {
  inVoorstel?: boolean;
  closeModal?: () => void;
  callbackOnClose?: () => void;
};
