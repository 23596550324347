import React, { ReactElement, useCallback } from "react";
import { connect, FormikContextType } from "formik";
import FiscaleRegelingModal from "../fiscale-regeling-modal/fiscale-regeling-modal";
import { FiscaleRegelingKenmerken } from "../infra/product-kenmerken-types";
import { LabelValuePairs } from "../../shared/types";
import { FiscaleVoortzettingOptions } from "../../.generated/forms/formstypes";

import { HypothekenState } from "../../hypotheek/infra/hypotheek-types";

import { LabeledRadioInput, Icon, LabeledResult } from "adviesbox-shared";
import { bindSaveFunction } from "../../shared/utils/bind-save-function";
import { ModalButton } from "adviesbox-shared";
import { bedragFormat } from "../../shared/utils/currency";
import { getFormattedDate } from "../../shared/utils/dates";
import { ISWSideEffects } from "../../shared/components/isw-side-effects/isw-side-effects";
import { FiscaleRegelingSideEffects } from "./fiscale-regeling-side-effects";

type FiscaleRegelingProps = {
  selected: number;
  kenmerken: FiscaleRegelingKenmerken;
  fiscaleVoortzettingKeuzes?: LabelValuePairs;
};

const FiscaleRegeling = ({
  selected,
  kenmerken,
  formik: { setFieldValue, validateForm, values },
  fiscaleVoortzettingKeuzes
}: {
  formik: FormikContextType<HypothekenState>;
} & FiscaleRegelingProps): ReactElement | null => {
  const createSaveFunction = useCallback(bindSaveFunction(setFieldValue), [setFieldValue]);
  const fiscaleRegeling = values.producten[selected].fiscaleRegeling;
  if (!fiscaleRegeling) {
    return null;
  }

  const fiscaleRegelingNietGeen = fiscaleRegeling.fiscaleVoortzetting !== FiscaleVoortzettingOptions.Geen;
  const fiscaleRegelingEinddatum = fiscaleRegeling.einddatum;
  const fiscaleRegelingWaarde = fiscaleRegeling.ingebrachteWaardeBedrag ?? null;

  const validateCurrentProduct = async (): Promise<void> => {
    await validateForm();
  };

  return (
    <>
      <ISWSideEffects<HypothekenState> sync={FiscaleRegelingSideEffects(selected)} />
      <LabeledRadioInput
        caption="Fiscale voortzetting"
        name={`producten[${selected}].fiscaleRegeling.fiscaleVoortzetting`}
        options={fiscaleVoortzettingKeuzes || []}
      />
      {fiscaleRegelingNietGeen && (
        <LabeledResult
          caption="Kenmerken"
          name={`producten[${selected}].fiscaleRegeling.fiscaleVoortzetting.Kenmerken`}
          fieldSize="full-size"
          appendChildren={
            <div className="d-inline-flex float-right">
              <ModalButton
                parent={`producten[${selected}].fiscaleRegeling`}
                content={<Icon name="specificatie" alt="fiscaleVoorzettingModal" />}
              >
                <FiscaleRegelingModal
                  data={fiscaleRegeling}
                  onSave={createSaveFunction(`producten[${selected}].fiscaleRegeling`, validateCurrentProduct)}
                  kenmerken={kenmerken}
                  fiscaleOption={fiscaleRegelingNietGeen}
                />
              </ModalButton>
            </div>
          }
          result={(): string => {
            const waarde = bedragFormat(fiscaleRegelingWaarde);
            const einddatum = fiscaleRegelingEinddatum ? getFormattedDate(fiscaleRegelingEinddatum) : "?";

            return `Waarde: ${waarde} Einddatum: ${einddatum}`;
          }}
        />
      )}
    </>
  );
};

FiscaleRegeling.displayName = "Fiscale regeling";

export default connect<FiscaleRegelingProps, HypothekenState>(FiscaleRegeling);
