import { HypotheekFiscaleRegelingType } from "../../hypotheek/infra/hypotheek-types";
import { FiscaleTyperingOptions } from "../../.generated/forms/formstypes";
import { createISWSideEffect, initISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";

const FiscaleRegelingDraftSideEffects = createISWSideEffect<HypotheekFiscaleRegelingType>((bag): void => {
  const { has, draft } = bag;

  if (has.fiscaleTypering.changed && draft.fiscaleTypering === FiscaleTyperingOptions.SpaarrekeningEigenWoningSew) {
    draft.garantieverzekering = true;
  } else if (
    has.fiscaleTypering.changed &&
    draft.fiscaleTypering === FiscaleTyperingOptions.BeleggingsrekeningEigenWoningBew
  ) {
    draft.garantieverzekering = false;
  }
});

export const FiscaleRegelingModalSideEffects = initISWSideEffect(FiscaleRegelingDraftSideEffects)();
