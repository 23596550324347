import React, { ReactElement } from "react";
import AdviseurNaam from "../../../auth/AdviseurNaam";
import classes from "./orv-vergelijker-pdf.module.scss";
import { getCurrentDateFormatted } from "./orv-vergelijker-pdf-helpers/orv-vergelijker-pdf-helpers";

type OrvPDFFooterProps = {
  organisatieNaam?: string;
};

export const renderOrvPDFFooter = ({ organisatieNaam }: OrvPDFFooterProps): ReactElement => {
  return (
    <>
      <div style={{ flexGrow: 1 }} />
      <div className={classes.pdf_footer}>
        <div className={classes.pdf_footer_header}>
          <span>
            Deze bedragen zijn slechts een indicatie van wat werkelijk mogelijk is. Controle van de acceptatienormen van
            de gekozen maatschappij dient te geschieden op het tabblad &apos;acceptatie&apos;. Definitieve aceptatie
            geschiedt altijd door de gekozen maatschappij zelf.
          </span>
        </div>
        <div className={classes.pdf_footer_sub}>
          <span>
            Overzicht verzorgd door&nbsp;
            <span>
              <AdviseurNaam />
            </span>
            &nbsp; van <span>{organisatieNaam}</span> op {getCurrentDateFormatted()}.
          </span>
          <span className={classes.pdf_footer_sub_rights}>Aan dit overzicht kunnen geen rechten worden ontleend.</span>
        </div>
      </div>
    </>
  );
};
