import { AsyncSideEffect } from "use-formik-side-effects";
import { Financieringsoort } from "../../.generated/forms/formstypes";
import {
  WaardeberekeningParameters,
  SoortWaardeberekeningOptions
} from "../../.generated/hypotheek/hypotheektypes";
import { optellen } from "../../shared/utils/currency";
import {
  FinancieringsbehoefteState,
  Financieringsopzet,
  OverigeKostenSpecificatie
} from "./financieringsbehoefte-types";

export const bepaalWaardeBerekeningSoort = (
  berekenen: boolean | null,
  soortWaardeberekening: "IndicatieveWaarde" | "VastBedrag" | "Percentage"
): SoortWaardeberekeningOptions => {
  return !berekenen && berekenen !== null
    ? SoortWaardeberekeningOptions.VastBedrag
    : soortWaardeberekening === "IndicatieveWaarde"
    ? SoortWaardeberekeningOptions.Default
    : (soortWaardeberekening as SoortWaardeberekeningOptions);
};

export const berekenBeschikbaarEigenGeld = (finOpzetCurr: Financieringsopzet): number | null => {
  return finOpzetCurr.gewensteHypotheekBerekenen
    ? optellen([
        finOpzetCurr.inbrengEigenGeldModal.inbrengEigenGeld,
        finOpzetCurr.inbrengEigenGeldModal.eigenGeldOverwaarde.bedrag,
        finOpzetCurr.inbrengEigenGeldModal.extraAflossing,
        finOpzetCurr.inbrengEigenGeldModal.inbrengEigengeldBedrag1,
        finOpzetCurr.inbrengEigenGeldModal.inbrengEigengeldBedrag2,
        finOpzetCurr.inbrengEigenGeldModal.inbrengOpgebouwdeWaarde,
        finOpzetCurr.inbrengEigenGeldModal.inbrengPolisBedrag,
        finOpzetCurr.inbrengEigenGeldModal.inbrengVoorVerlagenRestschuld
      ])
    : null;
};

export const bepaalWaardeBerekeningParametersOpBasisTarieven = (
  berekenen: boolean | null,
  bedrag: number | null,
  soortWaardeberekening: "IndicatieveWaarde" | "VastBedrag" | "Percentage",
  bedragInstellingenTarieven: number | null,
  percentage?: number | null
): WaardeberekeningParameters => ({
  soortWaardeberekening: bepaalWaardeBerekeningSoort(berekenen, soortWaardeberekening),
  bedrag:
    !berekenen && berekenen !== null
      ? bedrag || 0
      : bepaalWaardeBerekeningSoort(berekenen, soortWaardeberekening) === "VastBedrag"
      ? bedragInstellingenTarieven
      : null,
  percentage:
    !berekenen && berekenen !== null
      ? null
      : bepaalWaardeBerekeningSoort(berekenen, soortWaardeberekening) === "Percentage"
      ? percentage || null
      : null
});

// Bepaal WaardeBerekeningParameters a.d.h.v. berekenen (bool) veld en bedrag
// Berekenen: null of true is berekenen, false is eigen ingevoerde waarde. Dus VastBedrag
export const bepaalWaardeBerekeningParameters = (
  berekenen: boolean | null,
  bedrag: number | null,
  percentage: number | null
): WaardeberekeningParameters => {
  return {
    soortWaardeberekening:
      berekenen || berekenen === null
        ? SoortWaardeberekeningOptions.Default
        : SoortWaardeberekeningOptions.VastBedrag,
    bedrag: berekenen || berekenen === null ? null : bedrag || 0,
    percentage: percentage
  };
};

export const bepaalWaardeBerekeningParametersAlleenBedrag = (bedrag: number | null): WaardeberekeningParameters => {
  return {
    soortWaardeberekening:
      bedrag && bedrag > 0
        ? SoortWaardeberekeningOptions.VastBedrag
        : SoortWaardeberekeningOptions.Default,
    bedrag: bedrag && bedrag > 0 ? bedrag : null,
    percentage: null
  };
};

export const getArrayValue = (array: AsyncSideEffect<FinancieringsbehoefteState>[], value: string): any => {
  const searchValue = array.find((x): boolean => x.field === value);
  return searchValue ? searchValue.value : null;
};

// Helper functie om te checken of voor een specifiek veld een berekening nodig is
export const checkBerekenenNodigDetail = (
  currValue: number | null,
  prevValue: number | null,
  berekenenBool?: boolean | null
): boolean => {
  if (berekenenBool || (!berekenenBool && currValue !== prevValue)) {
    return true;
  }
  return false;
};

export const overigeKostenBedrag = (overigeKostenSpecificatie: OverigeKostenSpecificatie[], index: number): number => {
  return overigeKostenSpecificatie &&
    overigeKostenSpecificatie.length > index &&
    overigeKostenSpecificatie[index].fiscalePlaatsing === "Box1"
    ? overigeKostenSpecificatie[index].bedrag || 0
    : 0;
};

export const getKoopsomBedrag = (values: FinancieringsbehoefteState): number | null => {
  const koopsom = values.financieringsopzet.koopsom;

  if (values.financiering.soortFinanciering === Financieringsoort.AankoopNieuwbouw && koopsom.grondReedsInBezit) {
    return (koopsom.koopsomBedrag || 0) - (koopsom.kostenGrondBedrag || 0);
  }
  return koopsom.koopsomBedrag;
};

// Helper om het totaal voor de financieringsopzet initieel te berekenen
export const getTotaalFinancieringsbehoefte = (values: FinancieringsbehoefteState): number | null => {
  const { financieringsopzet } = values;

  return optellen([
    financieringsopzet.afTeLossenBestaandeHypotheken.bedrag,
    getKoopsomBedrag(values),
    financieringsopzet.koopsomRoerendeZaken,
    financieringsopzet.makelaarscourtage.bedrag,
    financieringsopzet.leveringsakte.bedrag,
    financieringsopzet.verbouwingVerbeteringModal.totaalVerbouwingVerbetering,
    financieringsopzet.afkoopErfpacht,
    financieringsopzet.uitkoopPartner,
    financieringsopzet.bouwrentePeriodeVoorAankoop,
    financieringsopzet.bouwrentePeriodeNaAankoop,
    financieringsopzet.overdrachtsbelasting.bedrag,
    financieringsopzet.hypotheekakte.bedrag,
    financieringsopzet.taxatie.bedrag,
    financieringsopzet.nationaleHypotheekGarantie,
    financieringsopzet.advieskosten,
    financieringsopzet.bankgarantie,
    financieringsopzet.renteverliesTijdensBouw,
    financieringsopzet.overigeFinancieringskostenModal.afsluitkostenSVnStarterslening,
    financieringsopzet.overigeFinancieringskostenModal.bereidstellingskosten,
    financieringsopzet.boeterenteBedrag,
    financieringsopzet.totaleRestschuldModal.restschuldHuidigeWoning.berekenen === null ||
    financieringsopzet.totaleRestschuldModal.restschuldHuidigeWoning.berekenen
      ? financieringsopzet.totaleRestschuldModal.restschuldHuidigeWoning.berekendBedrag
      : financieringsopzet.totaleRestschuldModal.restschuldHuidigeWoning.bedrag,
    financieringsopzet.totaleRestschuldModal.extraKostenHuidigeHypotheek,
    financieringsopzet.overigeKostenInHypotheekModal.lastenOverbruggingskrediet,
    financieringsopzet.overigeKostenInHypotheekModal.gewenstConsumptiefBedrag,
    financieringsopzet.arbeidskostenNotaris,
    ...financieringsopzet.overigeKostenInHypotheekModal.overigeKostenSpecificaties.map((x): number | null => x.bedrag),
    financieringsopzet.afTeLossenoverigeLeningen,
    financieringsopzet.overigeKostenInHypotheekModal.royementskostenBedrag,
    financieringsopzet.kostenOmzettingBedrag
  ]);
};

export const getInvesteringBedrag = (values: FinancieringsbehoefteState): number | null => {
  const { financieringsopzet } = values;

  return optellen([
    getKoopsomBedrag(values),
    financieringsopzet.bouwrentePeriodeVoorAankoop,
    financieringsopzet.verbouwingVerbeteringModal.totaalVerbouwingVerbetering,
    financieringsopzet.overdrachtsbelasting.bedrag,
    financieringsopzet.makelaarscourtage.bedrag,
    financieringsopzet.leveringsakte.bedrag,
    financieringsopzet.afkoopErfpacht,
    financieringsopzet.uitkoopPartner
  ]);
};
