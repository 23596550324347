import { ErrorPage, PageLoading, SettingsType, withErrorBoundary } from "adviesbox-shared";
import React, { ReactElement, useCallback } from "react";
import {
  mapDlTargetToUitkerendeLijfrenteUiField,
  mapUitkerendeLijfrentesDlToUi
} from "./infra/map-uitkerende-lijfrente-dl-2-ui";

import { InvalidAdviesboxResultErrorPage } from "../shared/components/fetch-error-page/fetch-error-page";
import { RouteParams } from "../shared/paramrouting/paramrouting-context";
import { SituatieSoort } from "../producten-overzicht/infra/producten-overzicht-types";
import UitkerendeLijfrente from "./uitkerende-lijfrente";
import { mapUitkerendeLijfrentesUiToDl } from "./infra/map-uitkerende-lijfrente-ui-2-dl";
import { useAdviesboxData } from "../shared/hooks/use-adviesbox-data";

type UitkerendeLijfrenteAjaxProps = { situatie: SituatieSoort };

const UitkerendeLijfrenteAjax = ({ situatie }: UitkerendeLijfrenteAjaxProps): ReactElement => {
  const createUrl = useCallback(
    (s: SettingsType, p: RouteParams): string =>
      situatie === "huidig"
        ? `${s.klantdossiersFormsOrigin}/Adviesdossiers/${p.adviesdossier}/Lijfrente`
        : `${s.klantdossiersFormsOrigin}/Voorstellen/${p.voorstel}/Lijfrente`,
    [situatie]
  );

  const createDataId = useCallback((p: RouteParams) => (situatie === "huidig" ? p.adviesdossier : p.voorstel), [
    situatie
  ]);

  const { loading, error, data, platformData, saveData } = useAdviesboxData(
    createUrl,
    createDataId,
    mapUitkerendeLijfrentesDlToUi,
    mapUitkerendeLijfrentesUiToDl,
    mapDlTargetToUitkerendeLijfrenteUiField
  );

  if (loading) {
    return <PageLoading />;
  }

  if (error) {
    return <ErrorPage error={error} data={platformData} />;
  }

  if (!data) {
    return <InvalidAdviesboxResultErrorPage />;
  }

  return <UitkerendeLijfrente saveData={saveData} {...data} situatie={situatie} />;
};

UitkerendeLijfrenteAjax.displayName = "UitkerendeLijfrenteAjax";

export default withErrorBoundary(UitkerendeLijfrenteAjax);
