import React from "react";
import { Table } from "react-bootstrap";
import { bedragFormatNull, monthDivision } from "../infra/scenario-utils";

export interface BrutoInkomensWensenTableProps {
  brutoInkomensWensPercentage: number | null;
  jaarlijkseAnalyse?: {
    maandelijksTekort: number | null;
  };
  gewenstInkomenBedrag: number | null;
}

export const BrutoInkomensWensenTable: React.FC<BrutoInkomensWensenTableProps> = ({
  brutoInkomensWensPercentage,
  jaarlijkseAnalyse,
  gewenstInkomenBedrag
}: any) => (
  <Table striped bordered hover responsive className="scenario__table mb-4">
    <colgroup>
      <col style={{ width: "70%" }} />
      <col style={{ width: "30%", textAlign: "right" }} />
    </colgroup>
    <tbody>
      <tr>
        <td>Gewenst inkomen van minimaal {brutoInkomensWensPercentage}% van huidig bruto inkomen</td>
        <td>{bedragFormatNull(gewenstInkomenBedrag, monthDivision)}</td>
      </tr>
      <tr className={"table-danger font-weight-bold"}>
        <td>Maandelijks tekort aan gewenste inkomsten</td>
        <td>{bedragFormatNull(Math.abs(jaarlijkseAnalyse?.maandelijksTekort), undefined, 0, true)}</td>
      </tr>
    </tbody>
  </Table>
);
