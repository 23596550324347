import { LocalDate } from "@js-joda/core";
import { GedeeltelijkeUitkeringAoOpties } from "../../.generated/forms/formstypes";
import {
  aoIndexeringenSchema,
  AoUitkeringswijze,
  specificatieAoUitkeringModalSchema
} from "../../producten-overzicht/infra/specificatie-ao-uitkering-schema";
import { createISWSideEffect, initISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";
import {
  ouderdomspensioenModalSchema,
  PensioenAanvragerType,
  PensioenenState,
  Pensioenregelingsoort,
  pensioengrondslagSchema,
  pensioenregelingSchema,
  pensioenSchema,
  PensioenState,
  pensioentoezeggingenSchema,
  pensioenuitvoerderSchema,
  werknemersbijdragenSchema
} from "./pensioen-schema";

const berekenAowBedrag = (pensioen: PensioenState, aowPercentage: number | null): number | null => {
  if (aowPercentage === null) {
    return null;
  }
  const currentAowPercentage = aowPercentage / 100;
  const currentOuderdomsBedrag = pensioen.pensioentoezeggingen.ouderdomspensioenBedrag;

  return currentOuderdomsBedrag !== null ? currentAowPercentage * currentOuderdomsBedrag : null;
};

// allow (re)-calculation of aow-percentage only if greater > 1% ouderdomspensioenBedrag
const shouldCalculateAowPercentage = (pensioen: PensioenState, aowBedrag: number | null): boolean => {
  if (aowBedrag === null) {
    return false;
  }

  const currentOuderdomsBedrag = pensioen.pensioentoezeggingen.ouderdomspensioenBedrag;
  return currentOuderdomsBedrag !== null ? (aowBedrag / currentOuderdomsBedrag) * 100 > 1 : false;
};

const berekenAowPercentage = (pensioen: PensioenState, aowBedrag: number | null): number | null => {
  if (aowBedrag === null) {
    return null;
  }

  const currentOuderdomsBedrag = pensioen.pensioentoezeggingen.ouderdomspensioenBedrag;

  return currentOuderdomsBedrag !== null ? (aowBedrag / currentOuderdomsBedrag) * 100 : null;
};

// Onderstaande twee functies moeten refactored worden, bij voorkeur waarbij de ingangsDag functionaliteit wordt behouden.
// Het levert echter heel snel een infinite loop op, terwijl het op onderstaande manier functioneert.
const berekenJaarMaandenUitIngangsdatum = (
  pensioen: PensioenState,
  ingangsdatum: LocalDate | null
): [number | null, number | null, number | null] => {
  const geboorteJaar = pensioen.pensioentoezeggingen.geboortedatum
    ? pensioen.pensioentoezeggingen.geboortedatum.year()
    : null;
  const geboorteMaand = pensioen.pensioentoezeggingen.geboortedatum
    ? pensioen.pensioentoezeggingen.geboortedatum.monthValue()
    : null;

  const ingangsJaar = ingangsdatum ? ingangsdatum.year() : null;
  const ingangsMaand = ingangsdatum ? ingangsdatum.monthValue() : null;
  const ingangsDag = ingangsdatum ? ingangsdatum.dayOfMonth() : null;

  const totalMonthsBetween =
    geboorteJaar !== null && geboorteMaand !== null && ingangsJaar !== null && ingangsMaand !== null
      ? ingangsJaar * 12 - geboorteJaar * 12 + (ingangsMaand - geboorteMaand)
      : null;

  // Eerste index = jaren, tweede index = maanden, derde index = dag
  return [
    totalMonthsBetween ? Math.floor(totalMonthsBetween / 12) : null,
    totalMonthsBetween ? totalMonthsBetween - Math.floor(totalMonthsBetween / 12) * 12 : null,
    ingangsDag ? ingangsDag : null
  ];
};

const berekenIngangdatumsUitJaarMaanden = (
  pensioen: PensioenState,
  ingangsJaar: number | null,
  ingangsMaand: number | null,
  ingangsDag: number | null
): [LocalDate, number | null] | null => {
  const geboorteJaar = pensioen.pensioentoezeggingen.geboortedatum
    ? pensioen.pensioentoezeggingen.geboortedatum.year()
    : null;
  const geboorteMaand = pensioen.pensioentoezeggingen.geboortedatum
    ? pensioen.pensioentoezeggingen.geboortedatum.monthValue()
    : null;
  let geboorteDag = ingangsDag
    ? ingangsDag
    : pensioen.pensioentoezeggingen.geboortedatum
    ? pensioen.pensioentoezeggingen.geboortedatum.dayOfMonth()
    : null;

  let newYear = ingangsJaar !== null && geboorteJaar !== null ? ingangsJaar + geboorteJaar : null;

  let newMonth =
    ingangsMaand !== null && geboorteMaand !== null
      ? ingangsMaand + geboorteMaand
      : geboorteMaand
      ? geboorteMaand
      : null;

  if (newMonth !== null && newYear !== null && newMonth >= 13) {
    newYear++;
    newMonth -= 12;
  }

  // Onderstaande zorgt dat alles goed berekend wordt wanneer de geboortedag een dag is die niet in die maand zit (bijv. 31 april).
  if (newMonth !== null && geboorteDag !== null && geboorteDag >= 29) {
    if (newMonth === 1) {
      geboorteDag = 28;
    }
    if ((newMonth === 3 || newMonth === 5 || newMonth === 8 || newMonth === 10) && geboorteDag === 31) {
      geboorteDag = 30;
    }
  }

  return geboorteJaar !== null && geboorteDag !== null && newYear !== null && newMonth !== null
    ? [LocalDate.of(newYear, newMonth, geboorteDag), geboorteDag]
    : null;
};

const werkgeverSideEffects = createISWSideEffect<PensioenState>(({ has, draft }): void => {
  if (has.werkgever.huidigeWerkgever.changed) {
    draft.pensioenuitvoerder = pensioenuitvoerderSchema.default();
    draft.pensioenregeling = pensioenregelingSchema.default();
    draft.pensioengrondslag = pensioengrondslagSchema.default();
    draft.werknemersbijdragen = werknemersbijdragenSchema.default();
    draft.pensioentoezeggingen = pensioentoezeggingenSchema.default();
    draft.pensioentoezeggingen.ouderdomspensioenModal = ouderdomspensioenModalSchema.default();
    draft.pensioentoezeggingen.invaliditeitspensioenModal = specificatieAoUitkeringModalSchema.default();

    if (draft.werkgever.huidigeWerkgever) {
      draft.pensioengrondslag.huidigeWerkgever = true;
    } else {
      draft.pensioengrondslag.huidigeWerkgever = false;
    }
  }
  return;
});

const pensioenregelingSideEffects = createISWSideEffect<PensioenState>(({ has, draft }): void => {
  if (has.pensioenregeling.soortPensioenregeling.changed) {
    switch (draft.pensioenregeling.soortPensioenregeling) {
      case Pensioenregelingsoort.BeschikbarePremie:
        draft.pensioengrondslag.rekenrenteOpbouwfase = 4;
        draft.pensioengrondslag.rekenrenteUitkeringfase = 4;
        draft.pensioengrondslag.selectedPensioenregeling = Pensioenregelingsoort.BeschikbarePremie;
        break;
      case Pensioenregelingsoort.Middelloon:
        draft.pensioengrondslag.selectedPensioenregeling = Pensioenregelingsoort.Middelloon;
        draft.pensioenregeling.middelloonregelingPercentage =
          draft.pensioenregeling.middelloonregelingPercentageWettelijk;
        draft.pensioengrondslag.middelloonregelingPercentage =
          draft.pensioenregeling.middelloonregelingPercentageWettelijk;
        break;
      case Pensioenregelingsoort.Eindloon:
        draft.pensioengrondslag.selectedPensioenregeling = Pensioenregelingsoort.Eindloon;
        draft.pensioenregeling.eindloonregelingPercentage = draft.pensioenregeling.eindloonregelingPercentageWettelijk;
        draft.pensioengrondslag.eindloonregelingPercentage = draft.pensioenregeling.eindloonregelingPercentageWettelijk;
        break;
      case Pensioenregelingsoort.Geen:
        break;
    }
  }

  if (
    !draft.pensioenregeling.eindloonregelingPercentage &&
    draft.pensioenregeling.eindloonregelingPercentageWettelijk
  ) {
    draft.pensioenregeling.eindloonregelingPercentage = draft.pensioenregeling.eindloonregelingPercentageWettelijk;
    draft.pensioengrondslag.eindloonregelingPercentage = draft.pensioenregeling.eindloonregelingPercentageWettelijk;
  }

  if (
    !draft.pensioenregeling.middelloonregelingPercentage &&
    draft.pensioenregeling.middelloonregelingPercentageWettelijk
  ) {
    draft.pensioenregeling.middelloonregelingPercentage = draft.pensioenregeling.middelloonregelingPercentageWettelijk;
    draft.pensioengrondslag.middelloonregelingPercentage = draft.pensioenregeling.middelloonregelingPercentageWettelijk;
  }
});

const pensioenaangroeiChainSideEffects = createISWSideEffect<
  PensioenState,
  { deelnemers: PensioenAanvragerType[]; unAvailableLoondiensten: (string | null)[] }
>(({ has, draft, context }): void => {
  const setPensioengevendSalaris = (): void => {
    const deelnemer = context.deelnemers.find((deelnemer): boolean => deelnemer.klantId === draft.selectedDeelnemer);

    if (!deelnemer) {
      if (has.selectedDeelnemer.changed) {
        draft = pensioenSchema.default();
      }
      return;
    }

    const loondienst = deelnemer.loondiensten.find(
      (loondienst): boolean => loondienst.loondienstId === draft.loondienstId
    );

    if (!loondienst) {
      draft.pensioenregeling.soortPensioenregeling = pensioenregelingSchema.default().soortPensioenregeling;
      draft.pensioengrondslag = pensioengrondslagSchema.default();
      return;
    }

    draft.pensioengrondslag.pensioengevendSalaris.bedrag = loondienst.totaalBrutoInkomenBedrag;
    draft.pensioengrondslag.pensioengevendSalaris.berekenen = true;
    draft.pensioengrondslag.pensioengevendSalaris.berekendBedrag = loondienst.totaalBrutoInkomenBedrag;
  };

  const setPensioengrondslag = (franchiseHandmatigGewijzigd?: boolean): void => {
    if (!franchiseHandmatigGewijzigd) {
      const currFranchiseBerekendbedrag = draft.pensioengrondslag.franchise.berekendBedrag || 0;
      const currPensioengevendSalarisBedrag = draft.pensioengrondslag.pensioengevendSalaris.bedrag || 0;
      const newGrondslagBedrag =
        currPensioengevendSalarisBedrag - currFranchiseBerekendbedrag > 0
          ? currPensioengevendSalarisBedrag - currFranchiseBerekendbedrag
          : 0;

      draft.pensioengrondslag.selectedPensioenregeling = draft.pensioenregeling.soortPensioenregeling;
      draft.pensioengrondslag.pensioengrondslag.berekenen = true;
      draft.pensioengrondslag.pensioengrondslag.bedrag = newGrondslagBedrag;
      draft.pensioengrondslag.pensioengrondslag.berekendBedrag = newGrondslagBedrag;
    }

    if (franchiseHandmatigGewijzigd) {
      const currFranchiseBedrag = draft.pensioengrondslag.franchise.bedrag || 0;
      const currPensioengevendSalarisBedrag = draft.pensioengrondslag.pensioengevendSalaris.bedrag || 0;
      const newGrondslagBedrag =
        currPensioengevendSalarisBedrag - currFranchiseBedrag > 0
          ? currPensioengevendSalarisBedrag - currFranchiseBedrag
          : 0;

      draft.pensioengrondslag.selectedPensioenregeling = draft.pensioenregeling.soortPensioenregeling;
      draft.pensioengrondslag.pensioengrondslag.berekenen = true;
      draft.pensioengrondslag.pensioengrondslag.bedrag = newGrondslagBedrag;
      draft.pensioengrondslag.pensioengrondslag.berekendBedrag = newGrondslagBedrag;
    }
  };

  const setPensioenaangroei = (): void => {
    const currentGrondslag = draft.pensioengrondslag.pensioengrondslag.bedrag || 0;
    let currGroeiPercentage;

    switch (draft.pensioengrondslag.selectedPensioenregeling) {
      case Pensioenregelingsoort.Eindloon:
        currGroeiPercentage = draft.pensioenregeling.eindloonregelingPercentage || 0;
        break;
      case Pensioenregelingsoort.Middelloon:
        currGroeiPercentage = draft.pensioenregeling.middelloonregelingPercentage || 0;
        break;
      case Pensioenregelingsoort.BeschikbarePremie:
        currGroeiPercentage = draft.pensioengrondslag.rekenrenteOpbouwfase || 0;
        break;
      default:
        currGroeiPercentage = 0;
        break;
    }

    draft.pensioengrondslag.pensioenaangroei.bedrag = currentGrondslag * (currGroeiPercentage / 100);
    draft.pensioengrondslag.pensioenaangroei.berekenen = true;
    draft.pensioengrondslag.pensioenaangroei.berekendBedrag = currentGrondslag * (currGroeiPercentage / 100);

    if (draft.pensioenregeling.soortPensioenregeling === Pensioenregelingsoort.BeschikbarePremie) {
      const pensioengevendSalaris = draft.pensioengrondslag.pensioengevendSalaris.bedrag || 0;

      draft.pensioengrondslag.pensioenaangroei.bedrag = pensioengevendSalaris * (currGroeiPercentage / 100);
      draft.pensioengrondslag.pensioenaangroei.berekenen = true;
      draft.pensioengrondslag.pensioenaangroei.berekendBedrag = pensioengevendSalaris * (currGroeiPercentage / 100);
    }
  };
  const currDeelnemer = context.deelnemers.find((deelnemer): boolean => deelnemer.klantId === draft.selectedDeelnemer);
  const currLoondienst = currDeelnemer
    ? currDeelnemer.loondiensten.find((loondienst): boolean => loondienst.loondienstId === draft.loondienstId)
    : null;

  if (!draft.loondienstId) {
    const selectableLoondienst =
      currDeelnemer?.loondiensten.find(
        ld => !context.unAvailableLoondiensten.includes(ld.loondienstId) && ld.loondienstId !== null
      )?.loondienstId ?? null;
    draft.loondienstId = selectableLoondienst;
  }

  if (
    !draft.pensioengrondslag.pensioengevendSalaris.bedrag &&
    currLoondienst &&
    currLoondienst.totaalBrutoInkomenBedrag
  ) {
    setPensioengevendSalaris();
    setPensioengrondslag();
    setPensioenaangroei();
  }

  if (draft.pensioentoezeggingen.pensioengevendSalaris && !draft.pensioengrondslag.pensioengevendSalaris.bedrag) {
    setPensioengevendSalaris();
    setPensioengrondslag();
    setPensioenaangroei();
  }

  if (
    has.pensioenregeling.soortPensioenregeling.changed &&
    draft.pensioenregeling.soortPensioenregeling === Pensioenregelingsoort.BeschikbarePremie
  ) {
    setPensioengrondslag();
    setPensioenaangroei();
  }

  if (has.selectedDeelnemer.changed && draft.selectedDeelnemer) {
    setPensioengevendSalaris();
    setPensioengrondslag();
    setPensioenaangroei();
  }

  if (has.loondienstId.changed && draft.loondienstId) {
    setPensioengevendSalaris();
    setPensioengrondslag();
    setPensioenaangroei();
  }

  if (has.pensioengrondslag.franchise.berekendBedrag.changed && draft.pensioengrondslag.franchise.berekendBedrag) {
    setPensioengrondslag();
    setPensioenaangroei();
  }

  if (
    has.pensioenregeling.eindloonregelingPercentage.changed &&
    draft.pensioenregeling.soortPensioenregeling === Pensioenregelingsoort.Eindloon
  ) {
    setPensioenaangroei();
    draft.pensioengrondslag.eindloonregelingPercentage = draft.pensioenregeling.eindloonregelingPercentage;
  }

  if (
    has.pensioenregeling.middelloonregelingPercentage.changed &&
    draft.pensioenregeling.soortPensioenregeling === Pensioenregelingsoort.Middelloon
  ) {
    setPensioenaangroei();
    draft.pensioengrondslag.middelloonregelingPercentage = draft.pensioenregeling.middelloonregelingPercentage;
  }

  if (
    has.pensioengrondslag.pensioengevendSalaris.bedrag.changed &&
    draft.pensioengrondslag.pensioengevendSalaris.berekenen !== null
  ) {
    setPensioengrondslag();
    setPensioenaangroei();
  }

  if (
    has.pensioengrondslag.franchise.bedrag.changed &&
    draft.pensioengrondslag.pensioengevendSalaris.berekenen !== null
  ) {
    setPensioengrondslag(true);
    setPensioenaangroei();
  }

  if (
    has.pensioengrondslag.selectedPensioenregeling.changed &&
    (draft.pensioengrondslag.selectedPensioenregeling === Pensioenregelingsoort.Middelloon ||
      draft.pensioengrondslag.selectedPensioenregeling === Pensioenregelingsoort.Eindloon)
  ) {
    setPensioenaangroei();
  }

  if (
    has.pensioengrondslag.pensioengrondslag.bedrag.changed &&
    draft.pensioengrondslag.pensioengevendSalaris.berekenen !== null
  ) {
    setPensioenaangroei();
  }
});

const pensioentoezeggingenSideEffects = createISWSideEffect<PensioenState, PensioenAanvragerType[]>(
  ({ has, draft, context }): void => {
    const klantId = draft.selectedDeelnemer;

    if (has.selectedDeelnemer.changed || !draft.pensioentoezeggingen.geboortedatum) {
      const deelnemer = context.find((aanvrager): boolean => !!klantId && aanvrager.klantId === klantId) || null;
      if (deelnemer) {
        if (deelnemer.geboortedatum === null) {
          draft.pensioentoezeggingen.geboortedatum = null;
          return;
        }
        draft.pensioentoezeggingen.geboortedatum = deelnemer.geboortedatum;
      }
    }
  }
);

const ouderdomspensioenSideEffects = createISWSideEffect<PensioenState>(({ has, draft, prev }): void => {
  if (has.pensioentoezeggingen.ouderdomspensioenIsEnabled.changed) {
    const def = pensioentoezeggingenSchema.default();
    draft.pensioentoezeggingen.ouderdomspensioenModal = ouderdomspensioenModalSchema.default();
    draft.pensioentoezeggingen.ouderdomspensioenBedrag = def.ouderdomspensioenBedrag;
    draft.pensioentoezeggingen.ouderdomspensioenIngangsdatum = def.ouderdomspensioenIngangsdatum;
    draft.pensioentoezeggingen.ouderdomspensioenLeeftijd.jaren = def.ouderdomspensioenLeeftijd.jaren;
    draft.pensioentoezeggingen.ouderdomspensioenLeeftijd.maanden = def.ouderdomspensioenLeeftijd.maanden;
    draft.pensioentoezeggingen.ouderdomspensioenIngangsdag = def.ouderdomspensioenIngangsdag;

    if (!draft.pensioentoezeggingen.ouderdomspensioenIsEnabled) {
      draft.pensioentoezeggingen.nabestaandenpensioenNaAowOverlijdenNaAowIsEnabled =
        def.nabestaandenpensioenNaAowOverlijdenNaAowIsEnabled;
      draft.pensioentoezeggingen.nabestaandenpensioenNaAowOverlijdenNaAowBedrag =
        def.nabestaandenpensioenNaAowOverlijdenNaAowBedrag;
      draft.pensioentoezeggingen.nabestaandenpensioenNaAowOverlijdenNaAowPercentage =
        def.nabestaandenpensioenNaAowOverlijdenNaAowPercentage;

      draft.pensioentoezeggingen.nabestaandenpensioenNaAowOverlijdenVoorAowIsEnabled =
        def.nabestaandenpensioenNaAowOverlijdenVoorAowIsEnabled;
      draft.pensioentoezeggingen.nabestaandenpensioenNaAowOverlijdenVoorAowBedrag =
        def.nabestaandenpensioenNaAowOverlijdenVoorAowBedrag;
      draft.pensioentoezeggingen.nabestaandenpensioenNaAowOverlijdenVoorAowPercentage =
        def.nabestaandenpensioenNaAowOverlijdenVoorAowPercentage;

      draft.pensioentoezeggingen.nabestaandenpensioenTotAowOverlijdenNaAowIsEnabled =
        def.nabestaandenpensioenTotAowOverlijdenNaAowIsEnabled;
      draft.pensioentoezeggingen.nabestaandenpensioenTotAowOverlijdenNaAowBedrag =
        def.nabestaandenpensioenTotAowOverlijdenNaAowBedrag;
      draft.pensioentoezeggingen.nabestaandenpensioenTotAowOverlijdenNaAowPercentage =
        def.nabestaandenpensioenTotAowOverlijdenNaAowPercentage;

      draft.pensioentoezeggingen.nabestaandenpensioenTotAowOverlijdenVoorAowIsEnabled =
        def.nabestaandenpensioenTotAowOverlijdenVoorAowIsEnabled;
      draft.pensioentoezeggingen.nabestaandenpensioenTotAowOverlijdenVoorAowBedrag =
        def.nabestaandenpensioenTotAowOverlijdenVoorAowBedrag;
      draft.pensioentoezeggingen.nabestaandenpensioenTotAowOverlijdenVoorAowPercentage =
        def.nabestaandenpensioenTotAowOverlijdenVoorAowPercentage;

      draft.pensioentoezeggingen.prePensioenIsEnabled = def.prePensioenIsEnabled;
      draft.pensioentoezeggingen.prePensioenBedrag = def.prePensioenBedrag;
      draft.pensioentoezeggingen.prePensioenIngangsdatum = def.prePensioenIngangsdatum;
      draft.pensioentoezeggingen.prePensioenLeeftijd.jaren = def.prePensioenLeeftijd.jaren;
      draft.pensioentoezeggingen.prePensioenLeeftijd.maanden = def.prePensioenLeeftijd.maanden;
      draft.pensioentoezeggingen.prePensioenIngangsdag = def.prePensioenIngangsdag;

      draft.pensioentoezeggingen.wezenpensioenBedrag = def.wezenpensioenBedrag;
      draft.pensioentoezeggingen.wezenpensioenEindleeftijd = def.wezenpensioenEindleeftijd;
      draft.pensioentoezeggingen.wezenpensioenIsEnabled = def.wezenpensioenIsEnabled;
      draft.pensioentoezeggingen.wezenpensioenPercentage = def.wezenpensioenPercentage;

      draft.pensioentoezeggingen.invaliditeitspensioenBedrag = def.invaliditeitspensioenBedrag;
      draft.pensioentoezeggingen.invaliditeitspensioenIsEnabled = def.invaliditeitspensioenIsEnabled;
      draft.pensioentoezeggingen.invaliditeitspensioenModal = def.invaliditeitspensioenModal;
      draft.pensioentoezeggingen.invaliditeitspensioenPercentage = def.invaliditeitspensioenPercentage;
      draft.pensioentoezeggingen.invaliditeitspensioenWijze = def.invaliditeitspensioenWijze;
    }
  }

  if (has.pensioentoezeggingen.ouderdomspensioenBedrag.changed) {
    if (
      draft.pensioentoezeggingen.ouderdomspensioenModal.indexeringen &&
      draft.pensioentoezeggingen.ouderdomspensioenModal.indexeringen[0]
    ) {
      draft.pensioentoezeggingen.ouderdomspensioenModal.indexeringen[0].bedrag =
        draft.pensioentoezeggingen.ouderdomspensioenBedrag;
    }

    if (draft.pensioentoezeggingen.nabestaandenpensioenTotAowOverlijdenVoorAowIsEnabled) {
      draft.pensioentoezeggingen.nabestaandenpensioenTotAowOverlijdenVoorAowBedrag = berekenAowBedrag(
        draft,
        draft.pensioentoezeggingen.nabestaandenpensioenTotAowOverlijdenVoorAowPercentage
      );
    }

    if (draft.pensioentoezeggingen.nabestaandenpensioenNaAowOverlijdenVoorAowIsEnabled) {
      draft.pensioentoezeggingen.nabestaandenpensioenNaAowOverlijdenVoorAowBedrag = berekenAowBedrag(
        draft,
        draft.pensioentoezeggingen.nabestaandenpensioenNaAowOverlijdenVoorAowPercentage
      );
    }

    if (draft.pensioentoezeggingen.nabestaandenpensioenTotAowOverlijdenNaAowIsEnabled) {
      draft.pensioentoezeggingen.nabestaandenpensioenTotAowOverlijdenNaAowBedrag = berekenAowBedrag(
        draft,
        draft.pensioentoezeggingen.nabestaandenpensioenTotAowOverlijdenNaAowPercentage
      );
    }

    if (draft.pensioentoezeggingen.nabestaandenpensioenNaAowOverlijdenNaAowIsEnabled) {
      draft.pensioentoezeggingen.nabestaandenpensioenNaAowOverlijdenNaAowBedrag = berekenAowBedrag(
        draft,
        draft.pensioentoezeggingen.nabestaandenpensioenNaAowOverlijdenNaAowPercentage
      );
    }

    if (draft.pensioentoezeggingen.wezenpensioenIsEnabled) {
      draft.pensioentoezeggingen.wezenpensioenBedrag = berekenAowBedrag(
        draft,
        draft.pensioentoezeggingen.wezenpensioenPercentage
      );
    }

    if (draft.pensioentoezeggingen.invaliditeitspensioenIsEnabled) {
      draft.pensioentoezeggingen.invaliditeitspensioenBedrag = berekenAowBedrag(
        draft,
        draft.pensioentoezeggingen.invaliditeitspensioenPercentage
      );
    }
  }

  if (
    (draft.pensioentoezeggingen.ouderdomspensioenIngangsdatum &&
      (has.pensioentoezeggingen.ouderdomspensioenIngangsdatum.changed || has.selectedDeelnemer.changed)) ||
    // Onderstaande check vangt het opslaan af; jaar/maand wordt niet meegestuurd, maar moet wel zichtbaar zijn wanneer gebruiker terug naar scherm navigeert.
    (draft.pensioentoezeggingen.ouderdomspensioenIngangsdatum &&
      !draft.pensioentoezeggingen.ouderdomspensioenLeeftijd.jaren &&
      !prev)
  ) {
    if (
      draft.pensioentoezeggingen.ouderdomspensioenModal.indexeringen &&
      draft.pensioentoezeggingen.ouderdomspensioenModal.indexeringen[0]
    ) {
      draft.pensioentoezeggingen.ouderdomspensioenModal.indexeringen[0].ingangsdatum =
        draft.pensioentoezeggingen.ouderdomspensioenIngangsdatum;
    }

    const berekendeDatum = berekenJaarMaandenUitIngangsdatum(
      draft,
      draft.pensioentoezeggingen.ouderdomspensioenIngangsdatum
    );

    draft.pensioentoezeggingen.ouderdomspensioenLeeftijd.jaren = berekendeDatum[0];
    draft.pensioentoezeggingen.ouderdomspensioenLeeftijd.maanden = berekendeDatum[1];
    draft.pensioentoezeggingen.ouderdomspensioenIngangsdag = berekendeDatum[2];
  }

  if (
    (has.pensioentoezeggingen.ouderdomspensioenLeeftijd.jaren.changed &&
      draft.pensioentoezeggingen.ouderdomspensioenLeeftijd.jaren) ||
    (has.pensioentoezeggingen.ouderdomspensioenLeeftijd.maanden.changed &&
      draft.pensioentoezeggingen.ouderdomspensioenLeeftijd.jaren)
  ) {
    // Onderstaande if zet curr.maanden terug op de vorige value als een waarde groter dan 12 is ingevoerd.
    // Dit is nodig omdat anders een extra getal toevoegen aan een bestaande invoer van maanden een herberekening triggert, terwijl je dit niet wilt.
    if (
      draft.pensioentoezeggingen.ouderdomspensioenLeeftijd.maanden &&
      draft.pensioentoezeggingen.ouderdomspensioenLeeftijd.maanden >= 12
    ) {
      draft.pensioentoezeggingen.ouderdomspensioenLeeftijd.maanden =
        prev.pensioentoezeggingen.ouderdomspensioenLeeftijd.maanden;
    }
    // Onderstaande if vangt af wanneer een gebruiker het maandenveld leegmaakt dat de herberekening gedaan wordt alsof de gebruiker hier 0 heeft ingevuld.
    if (!draft.pensioentoezeggingen.ouderdomspensioenLeeftijd.maanden) {
      draft.pensioentoezeggingen.ouderdomspensioenLeeftijd.maanden = 0;
    }

    const result = berekenIngangdatumsUitJaarMaanden(
      draft,
      draft.pensioentoezeggingen.ouderdomspensioenLeeftijd.jaren,
      draft.pensioentoezeggingen.ouderdomspensioenLeeftijd.maanden,
      draft.pensioentoezeggingen.ouderdomspensioenIngangsdag
    );

    draft.pensioentoezeggingen.ouderdomspensioenIngangsdatum = result ? result[0] : null;
    draft.pensioentoezeggingen.ouderdomspensioenIngangsdag = result ? result[1] : null;

    if (
      draft.pensioentoezeggingen.ouderdomspensioenModal.indexeringen &&
      draft.pensioentoezeggingen.ouderdomspensioenModal.indexeringen[0]
    ) {
      draft.pensioentoezeggingen.ouderdomspensioenModal.indexeringen[0].ingangsdatum =
        draft.pensioentoezeggingen.ouderdomspensioenIngangsdatum;
    }
  }
});

const prePensioenSideEffects = createISWSideEffect<PensioenState>(({ has, draft, prev }): void => {
  if (has.pensioentoezeggingen.prePensioenIsEnabled.changed && !draft.pensioentoezeggingen.prePensioenIsEnabled) {
    const def = pensioentoezeggingenSchema.default();
    draft.pensioentoezeggingen.prePensioenBedrag = def.prePensioenBedrag;
    draft.pensioentoezeggingen.prePensioenIngangsdatum = def.prePensioenIngangsdatum;
    draft.pensioentoezeggingen.prePensioenLeeftijd.jaren = def.prePensioenLeeftijd.jaren;
    draft.pensioentoezeggingen.prePensioenLeeftijd.maanden = def.prePensioenLeeftijd.maanden;
  }

  if (
    (has.pensioentoezeggingen.prePensioenIngangsdatum.changed && draft.pensioentoezeggingen.prePensioenIngangsdatum) ||
    has.selectedDeelnemer.changed ||
    // Onderstaande check vangt het opslaan af; jaar/maand wordt niet meegestuurd, maar moet wel zichtbaar zijn wanneer gebruiker terug naar scherm navigeert.
    (draft.pensioentoezeggingen.prePensioenIngangsdatum && !draft.pensioentoezeggingen.prePensioenLeeftijd.jaren)
  ) {
    const berekendeDatum = berekenJaarMaandenUitIngangsdatum(draft, draft.pensioentoezeggingen.prePensioenIngangsdatum);
    draft.pensioentoezeggingen.prePensioenLeeftijd.jaren = berekendeDatum[0];
    draft.pensioentoezeggingen.prePensioenLeeftijd.maanden = berekendeDatum[1];
    draft.pensioentoezeggingen.prePensioenIngangsdag = berekendeDatum[2];
  }

  if (
    (has.pensioentoezeggingen.prePensioenLeeftijd.jaren.changed &&
      draft.pensioentoezeggingen.prePensioenLeeftijd.jaren) ||
    (has.pensioentoezeggingen.prePensioenLeeftijd.maanden.changed &&
      draft.pensioentoezeggingen.prePensioenLeeftijd.jaren)
  ) {
    // Onderstaande if zet curr.maanden terug op de vorige value als een waarde groter dan 12 is ingevoerd.
    // Dit is nodig omdat anders een extra getal toevoegen aan een bestaande invoer van maanden een herberekening triggert, terwijl je dit niet wilt.
    if (
      draft.pensioentoezeggingen.prePensioenLeeftijd.maanden &&
      draft.pensioentoezeggingen.prePensioenLeeftijd.maanden >= 12
    ) {
      draft.pensioentoezeggingen.prePensioenLeeftijd.maanden = prev.pensioentoezeggingen.prePensioenLeeftijd.maanden;
    }
    // Onderstaande if vangt af wanneer een gebruiker het maandenveld leegmaakt dat de herberekening gedaan wordt alsof de gebruiker hier 0 heeft ingevuld.
    if (!draft.pensioentoezeggingen.prePensioenLeeftijd.maanden) {
      draft.pensioentoezeggingen.prePensioenLeeftijd.maanden = 0;
    }
    const result = berekenIngangdatumsUitJaarMaanden(
      draft,
      draft.pensioentoezeggingen.prePensioenLeeftijd.jaren,
      draft.pensioentoezeggingen.prePensioenLeeftijd.maanden,
      draft.pensioentoezeggingen.prePensioenIngangsdag
    );
    draft.pensioentoezeggingen.prePensioenIngangsdatum = result ? result[0] : null;
    draft.pensioentoezeggingen.prePensioenIngangsdag = result ? result[1] : null;
  }
});

const totAowVoorOverlijdenSideEffects = createISWSideEffect<PensioenState>(({ has, draft }): void => {
  if (
    has.pensioentoezeggingen.nabestaandenpensioenTotAowOverlijdenVoorAowIsEnabled.changed &&
    !draft.pensioentoezeggingen.nabestaandenpensioenTotAowOverlijdenVoorAowIsEnabled
  ) {
    const def = pensioentoezeggingenSchema.default();
    draft.pensioentoezeggingen.nabestaandenpensioenTotAowOverlijdenVoorAowBedrag =
      def.nabestaandenpensioenTotAowOverlijdenVoorAowBedrag;
    draft.pensioentoezeggingen.nabestaandenpensioenTotAowOverlijdenVoorAowPercentage =
      def.nabestaandenpensioenTotAowOverlijdenVoorAowPercentage;
  }

  if (
    (has.pensioentoezeggingen.nabestaandenpensioenTotAowOverlijdenVoorAowIsEnabled.changed &&
      draft.pensioentoezeggingen.nabestaandenpensioenTotAowOverlijdenVoorAowIsEnabled) ||
    (has.pensioentoezeggingen.ouderdomspensioenBedrag.changed &&
      !draft.pensioentoezeggingen.ouderdomspensioenBedrag &&
      draft.pensioentoezeggingen.ouderdomspensioenIsEnabled)
  ) {
    draft.pensioentoezeggingen.nabestaandenpensioenTotAowOverlijdenVoorAowPercentage = 70;
  }

  if (
    has.pensioentoezeggingen.nabestaandenpensioenTotAowOverlijdenVoorAowPercentage.changed &&
    draft.pensioentoezeggingen.ouderdomspensioenBedrag
  ) {
    draft.pensioentoezeggingen.nabestaandenpensioenTotAowOverlijdenVoorAowBedrag = berekenAowBedrag(
      draft,
      draft.pensioentoezeggingen.nabestaandenpensioenTotAowOverlijdenVoorAowPercentage
    );
  }

  if (
    has.pensioentoezeggingen.nabestaandenpensioenTotAowOverlijdenVoorAowBedrag.changed &&
    draft.pensioentoezeggingen.ouderdomspensioenBedrag &&
    shouldCalculateAowPercentage(draft, draft.pensioentoezeggingen.nabestaandenpensioenTotAowOverlijdenVoorAowBedrag)
  ) {
    draft.pensioentoezeggingen.nabestaandenpensioenTotAowOverlijdenVoorAowPercentage = berekenAowPercentage(
      draft,
      draft.pensioentoezeggingen.nabestaandenpensioenTotAowOverlijdenVoorAowBedrag
    );
  }
});

const naAowVoorOverlijdenSideEffects = createISWSideEffect<PensioenState>(({ has, draft }): void => {
  if (
    has.pensioentoezeggingen.nabestaandenpensioenNaAowOverlijdenVoorAowIsEnabled.changed &&
    !draft.pensioentoezeggingen.nabestaandenpensioenNaAowOverlijdenVoorAowIsEnabled
  ) {
    const def = pensioentoezeggingenSchema.default();

    draft.pensioentoezeggingen.nabestaandenpensioenNaAowOverlijdenVoorAowBedrag =
      def.nabestaandenpensioenNaAowOverlijdenVoorAowBedrag;
    draft.pensioentoezeggingen.nabestaandenpensioenNaAowOverlijdenVoorAowPercentage =
      def.nabestaandenpensioenNaAowOverlijdenVoorAowPercentage;
  }

  if (
    (has.pensioentoezeggingen.nabestaandenpensioenNaAowOverlijdenVoorAowIsEnabled.changed &&
      draft.pensioentoezeggingen.nabestaandenpensioenNaAowOverlijdenVoorAowIsEnabled) ||
    (has.pensioentoezeggingen.ouderdomspensioenBedrag.changed &&
      !draft.pensioentoezeggingen.ouderdomspensioenBedrag &&
      draft.pensioentoezeggingen.ouderdomspensioenIsEnabled)
  ) {
    draft.pensioentoezeggingen.nabestaandenpensioenNaAowOverlijdenVoorAowPercentage = 70;
  }

  if (
    has.pensioentoezeggingen.nabestaandenpensioenNaAowOverlijdenVoorAowPercentage.changed &&
    draft.pensioentoezeggingen.ouderdomspensioenBedrag
  ) {
    draft.pensioentoezeggingen.nabestaandenpensioenNaAowOverlijdenVoorAowBedrag = berekenAowBedrag(
      draft,
      draft.pensioentoezeggingen.nabestaandenpensioenNaAowOverlijdenVoorAowPercentage
    );
  }

  if (
    has.pensioentoezeggingen.nabestaandenpensioenNaAowOverlijdenVoorAowBedrag.changed &&
    draft.pensioentoezeggingen.ouderdomspensioenBedrag &&
    shouldCalculateAowPercentage(draft, draft.pensioentoezeggingen.nabestaandenpensioenNaAowOverlijdenVoorAowBedrag)
  ) {
    draft.pensioentoezeggingen.nabestaandenpensioenNaAowOverlijdenVoorAowPercentage = berekenAowPercentage(
      draft,
      draft.pensioentoezeggingen.nabestaandenpensioenNaAowOverlijdenVoorAowBedrag
    );
  }
});

const totAowNaOverlijdenSideEffects = createISWSideEffect<PensioenState>(({ has, draft }): void => {
  if (
    has.pensioentoezeggingen.nabestaandenpensioenTotAowOverlijdenNaAowIsEnabled.changed &&
    !draft.pensioentoezeggingen.nabestaandenpensioenTotAowOverlijdenNaAowIsEnabled
  ) {
    const def = pensioentoezeggingenSchema.default();
    draft.pensioentoezeggingen.nabestaandenpensioenTotAowOverlijdenNaAowBedrag =
      def.nabestaandenpensioenTotAowOverlijdenNaAowBedrag;
    draft.pensioentoezeggingen.nabestaandenpensioenTotAowOverlijdenNaAowPercentage =
      def.nabestaandenpensioenTotAowOverlijdenNaAowPercentage;
  }

  if (
    (has.pensioentoezeggingen.nabestaandenpensioenTotAowOverlijdenNaAowIsEnabled.changed &&
      draft.pensioentoezeggingen.nabestaandenpensioenTotAowOverlijdenNaAowIsEnabled) ||
    (has.pensioentoezeggingen.ouderdomspensioenBedrag.changed &&
      !draft.pensioentoezeggingen.ouderdomspensioenBedrag &&
      draft.pensioentoezeggingen.ouderdomspensioenIsEnabled)
  ) {
    draft.pensioentoezeggingen.nabestaandenpensioenTotAowOverlijdenNaAowPercentage = 70;
  }

  if (
    has.pensioentoezeggingen.nabestaandenpensioenTotAowOverlijdenNaAowPercentage.changed &&
    draft.pensioentoezeggingen.ouderdomspensioenBedrag
  ) {
    draft.pensioentoezeggingen.nabestaandenpensioenTotAowOverlijdenNaAowBedrag = berekenAowBedrag(
      draft,
      draft.pensioentoezeggingen.nabestaandenpensioenTotAowOverlijdenNaAowPercentage
    );
  }

  if (
    has.pensioentoezeggingen.nabestaandenpensioenTotAowOverlijdenNaAowBedrag.changed &&
    draft.pensioentoezeggingen.ouderdomspensioenBedrag &&
    shouldCalculateAowPercentage(draft, draft.pensioentoezeggingen.nabestaandenpensioenTotAowOverlijdenNaAowBedrag)
  ) {
    draft.pensioentoezeggingen.nabestaandenpensioenTotAowOverlijdenNaAowPercentage = berekenAowPercentage(
      draft,
      draft.pensioentoezeggingen.nabestaandenpensioenTotAowOverlijdenNaAowBedrag
    );
  }
});

const naAowNaOverlijdenSideEffects = createISWSideEffect<PensioenState>(({ has, draft }): void => {
  if (
    has.pensioentoezeggingen.nabestaandenpensioenNaAowOverlijdenNaAowIsEnabled.changed &&
    !draft.pensioentoezeggingen.nabestaandenpensioenNaAowOverlijdenNaAowIsEnabled
  ) {
    const def = pensioentoezeggingenSchema.default();

    draft.pensioentoezeggingen.nabestaandenpensioenNaAowOverlijdenNaAowBedrag =
      def.nabestaandenpensioenNaAowOverlijdenNaAowBedrag;
    draft.pensioentoezeggingen.nabestaandenpensioenNaAowOverlijdenNaAowPercentage =
      def.nabestaandenpensioenNaAowOverlijdenNaAowPercentage;
  }

  if (
    (has.pensioentoezeggingen.nabestaandenpensioenNaAowOverlijdenNaAowIsEnabled.changed &&
      draft.pensioentoezeggingen.nabestaandenpensioenNaAowOverlijdenNaAowIsEnabled) ||
    (has.pensioentoezeggingen.ouderdomspensioenBedrag.changed &&
      !draft.pensioentoezeggingen.ouderdomspensioenBedrag &&
      draft.pensioentoezeggingen.ouderdomspensioenIsEnabled)
  ) {
    draft.pensioentoezeggingen.nabestaandenpensioenNaAowOverlijdenNaAowPercentage = 70;
  }

  if (
    has.pensioentoezeggingen.nabestaandenpensioenNaAowOverlijdenNaAowPercentage.changed ||
    (has.pensioentoezeggingen.ouderdomspensioenBedrag.changed &&
      draft.pensioentoezeggingen.nabestaandenpensioenNaAowOverlijdenNaAowIsEnabled)
  ) {
    draft.pensioentoezeggingen.nabestaandenpensioenNaAowOverlijdenNaAowBedrag = berekenAowBedrag(
      draft,
      draft.pensioentoezeggingen.nabestaandenpensioenNaAowOverlijdenNaAowPercentage
    );
  }

  if (
    has.pensioentoezeggingen.nabestaandenpensioenNaAowOverlijdenNaAowBedrag.changed &&
    draft.pensioentoezeggingen.ouderdomspensioenBedrag &&
    shouldCalculateAowPercentage(draft, draft.pensioentoezeggingen.nabestaandenpensioenNaAowOverlijdenNaAowBedrag)
  ) {
    draft.pensioentoezeggingen.nabestaandenpensioenNaAowOverlijdenNaAowPercentage = berekenAowPercentage(
      draft,
      draft.pensioentoezeggingen.nabestaandenpensioenNaAowOverlijdenNaAowBedrag
    );
  }
});

const anwSideEffects = createISWSideEffect<PensioenState>(({ has, draft }): void => {
  if (has.pensioentoezeggingen.anwCompensatieIsEnabled.changed && !draft.pensioentoezeggingen.anwCompensatieIsEnabled) {
    const def = pensioentoezeggingenSchema.default();
    draft.pensioentoezeggingen.anwCompensatie = def.anwCompensatie;
  }
});

const wezenpensioenSideEffects = createISWSideEffect<PensioenState>(({ has, draft }): void => {
  if (has.pensioentoezeggingen.wezenpensioenIsEnabled.changed && !draft.pensioentoezeggingen.wezenpensioenIsEnabled) {
    const def = pensioentoezeggingenSchema.default();

    draft.pensioentoezeggingen.wezenpensioenBedrag = def.wezenpensioenBedrag;
    draft.pensioentoezeggingen.wezenpensioenEindleeftijd = def.wezenpensioenEindleeftijd;
    draft.pensioentoezeggingen.wezenpensioenPercentage = def.wezenpensioenPercentage;
  }

  if (has.pensioentoezeggingen.wezenpensioenIsEnabled.changed && draft.pensioentoezeggingen.wezenpensioenIsEnabled) {
    draft.pensioentoezeggingen.wezenpensioenPercentage = 14;
  }

  if (
    has.pensioentoezeggingen.wezenpensioenPercentage.changed &&
    draft.pensioentoezeggingen.wezenpensioenIsEnabled &&
    draft.pensioentoezeggingen.ouderdomspensioenIsEnabled &&
    draft.pensioentoezeggingen.ouderdomspensioenBedrag
  ) {
    draft.pensioentoezeggingen.wezenpensioenBedrag = berekenAowBedrag(
      draft,
      draft.pensioentoezeggingen.wezenpensioenPercentage
    );
  }

  if (
    !(has.pensioentoezeggingen.wezenpensioenIsEnabled.changed && draft.pensioentoezeggingen.wezenpensioenIsEnabled) &&
    has.pensioentoezeggingen.wezenpensioenBedrag.changed &&
    shouldCalculateAowPercentage(draft, draft.pensioentoezeggingen.wezenpensioenBedrag) &&
    draft.pensioentoezeggingen.ouderdomspensioenIsEnabled
  ) {
    draft.pensioentoezeggingen.wezenpensioenPercentage = berekenAowPercentage(
      draft,
      draft.pensioentoezeggingen.wezenpensioenBedrag
    );
  }
});

const invaliditeitsSideEffects = createISWSideEffect<PensioenState>(({ has, prev, draft }): void => {
  const def = pensioentoezeggingenSchema.default();
  if (
    has.pensioentoezeggingen.invaliditeitspensioenIsEnabled.changed &&
    !draft.pensioentoezeggingen.invaliditeitspensioenIsEnabled
  ) {
    draft.pensioentoezeggingen.invaliditeitspensioenBedrag = null;
    draft.pensioentoezeggingen.invaliditeitspensioenPercentage = def.invaliditeitspensioenPercentage;
    draft.pensioentoezeggingen.invaliditeitspensioenWijze = def.invaliditeitspensioenWijze;
    draft.pensioentoezeggingen.invaliditeitspensioenModal = specificatieAoUitkeringModalSchema.default();
  }

  if (
    (has.pensioentoezeggingen.invaliditeitspensioenIsEnabled.changed &&
      draft.pensioentoezeggingen.ouderdomspensioenIsEnabled &&
      draft.pensioentoezeggingen.invaliditeitspensioenIsEnabled) ||
    (has.pensioentoezeggingen.invaliditeitspensioenPercentage.changed &&
      draft.pensioentoezeggingen.invaliditeitspensioenIsEnabled &&
      draft.pensioentoezeggingen.ouderdomspensioenIsEnabled &&
      draft.pensioentoezeggingen.ouderdomspensioenBedrag)
  ) {
    draft.pensioentoezeggingen.invaliditeitspensioenBedrag = berekenAowBedrag(
      draft,
      draft.pensioentoezeggingen.invaliditeitspensioenPercentage
    );
  }

  if (
    has.pensioentoezeggingen.invaliditeitspensioenBedrag.changed &&
    draft.pensioentoezeggingen.ouderdomspensioenIsEnabled &&
    draft.pensioentoezeggingen.ouderdomspensioenBedrag
  ) {
    if (
      !draft.pensioentoezeggingen.invaliditeitspensioenPercentage &&
      draft.pensioentoezeggingen.invaliditeitspensioenIsEnabled
    ) {
      draft.pensioentoezeggingen.invaliditeitspensioenPercentage = 0;
    }
    if (
      draft.pensioentoezeggingen.invaliditeitspensioenPercentage &&
      shouldCalculateAowPercentage(draft, draft.pensioentoezeggingen.invaliditeitspensioenBedrag)
    ) {
      draft.pensioentoezeggingen.invaliditeitspensioenPercentage = berekenAowPercentage(
        draft,
        draft.pensioentoezeggingen.invaliditeitspensioenBedrag
      );
    }
  }

  if (has.pensioentoezeggingen.invaliditeitspensioenWijze.changed) {
    if (draft.pensioentoezeggingen.invaliditeitspensioenWijze === AoUitkeringswijze.Volledig) {
      draft.pensioentoezeggingen.invaliditeitspensioenModal.indexeringen = [
        {
          ...aoIndexeringenSchema.default(),
          uitkeringPercentage: "0%"
        },
        {
          ...aoIndexeringenSchema.default(),
          uitkeringPercentage: "100%"
        }
      ];
    }

    if (
      draft.pensioentoezeggingen.invaliditeitspensioenWijze !== AoUitkeringswijze.Volledig &&
      draft.pensioentoezeggingen.invaliditeitspensioenModal.gedeeltelijkeUitkeringObv !==
        GedeeltelijkeUitkeringAoOpties.Staffel
    ) {
      if (draft.pensioentoezeggingen.invaliditeitspensioenModal.afwijkendRestverdienPercentage === null) {
        draft.pensioentoezeggingen.invaliditeitspensioenModal.afwijkendRestverdienPercentage = 50;
      }

      draft.pensioentoezeggingen.invaliditeitspensioenModal.indexeringen = [
        {
          ...aoIndexeringenSchema.default(),
          uitkeringPercentage: "0%"
        },
        {
          ...aoIndexeringenSchema.default(),
          uitkeringPercentage: "AO%"
        },
        {
          ...aoIndexeringenSchema.default(),
          bovengrensPercentage: 100,
          uitkeringPercentage: "100%"
        }
      ];
    }

    if (
      draft.pensioentoezeggingen.invaliditeitspensioenWijze !== AoUitkeringswijze.Volledig &&
      draft.pensioentoezeggingen.invaliditeitspensioenModal.gedeeltelijkeUitkeringObv ===
        GedeeltelijkeUitkeringAoOpties.Staffel
    ) {
      if (draft.pensioentoezeggingen.invaliditeitspensioenModal.afwijkendRestverdienPercentage === null) {
        draft.pensioentoezeggingen.invaliditeitspensioenModal.afwijkendRestverdienPercentage = 50;
      }

      if (
        prev.pensioentoezeggingen.invaliditeitspensioenModal.gedeeltelijkeUitkeringObv !==
          GedeeltelijkeUitkeringAoOpties.Staffel &&
        draft.pensioentoezeggingen.invaliditeitspensioenModal.gedeeltelijkeUitkeringObv ===
          GedeeltelijkeUitkeringAoOpties.Staffel
      ) {
        draft.pensioentoezeggingen.invaliditeitspensioenModal.indexeringen = [
          {
            ...aoIndexeringenSchema.default(),
            ondergrensPercentage: 0,
            bovengrensPercentage: draft.pensioentoezeggingen.invaliditeitspensioenModal.proRataMinPercentage,
            uitkeringPercentage: 0
          },
          {
            ...aoIndexeringenSchema.default(),
            ondergrensPercentage: draft.pensioentoezeggingen.invaliditeitspensioenModal.proRataMinPercentage,
            bovengrensPercentage: draft.pensioentoezeggingen.invaliditeitspensioenModal.proRataTotPercentage,
            uitkeringPercentage: 0
          },
          {
            ...aoIndexeringenSchema.default(),
            ondergrensPercentage: draft.pensioentoezeggingen.invaliditeitspensioenModal.proRataTotPercentage,
            bovengrensPercentage: 100,
            uitkeringPercentage: 100
          }
        ];
      }
    }
  }
});

const pensioenSideEffects = createISWSideEffect<PensioenenState, { selected: number }>((bag): void => {
  const { draft, prev, has, context } = bag;
  if (prev.pensioenen.length !== draft.pensioenen.length) {
    return;
  }

  if (has.pensioenen[context.selected].werkgever.huidigeWerkgever.changed) {
    const currPensioen = draft.pensioenen[context.selected];
    const pensioenDeelnemer = draft.deelnemers.find(d => d.klantId === currPensioen.selectedDeelnemer);
    const availableLoondienst = pensioenDeelnemer?.loondiensten.find(
      ld => !draft.pensioenen.map(p => p.loondienstId).includes(ld.loondienstId)
    );
    currPensioen.loondienstId = availableLoondienst ? availableLoondienst.loondienstId : null;
  }

  for (let i = 0; i < draft.pensioenen.length; i++) {
    const pensioen = bag.subset.pensioenen[i].createWithContext(draft.deelnemers);
    werkgeverSideEffects(pensioen);
    pensioenregelingSideEffects(pensioen);
    if (draft.pensioenen[i].werkgever.huidigeWerkgever) {
      pensioenaangroeiChainSideEffects(
        bag.subset.pensioenen[i].createWithContext({
          deelnemers: draft.deelnemers,
          unAvailableLoondiensten: draft.pensioenen.map(p => p.loondienstId)
        })
      );
    }

    if (!draft.pensioenen[i].werkgever.huidigeWerkgever) {
      draft.pensioenen[i].loondienstId = null;
    }

    pensioentoezeggingenSideEffects(pensioen);
    ouderdomspensioenSideEffects(pensioen);
    prePensioenSideEffects(pensioen);
    totAowVoorOverlijdenSideEffects(pensioen);
    naAowVoorOverlijdenSideEffects(pensioen);
    totAowNaOverlijdenSideEffects(pensioen);
    naAowNaOverlijdenSideEffects(pensioen);
    anwSideEffects(pensioen);
    wezenpensioenSideEffects(pensioen);
    invaliditeitsSideEffects(pensioen);
  }
});

export const determinePensioenSideEffects = initISWSideEffect(pensioenSideEffects);
