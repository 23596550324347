import { initISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";

import { determineIndicatieveUitkerendeFaseSideEffects } from "./determine-indicatieve-uitkerende-fase-side-effects";
import { IndicatieveUitkerendeFaseModalType } from "../infra/producten-overzicht-types";

export const determineIndicatieveUitkerendeFaseModalSideEffects = initISWSideEffect<IndicatieveUitkerendeFaseModalType>(
  ({ subset }) => {
    const indicatieveUitkerendeFase = subset.values.create();

    determineIndicatieveUitkerendeFaseSideEffects(indicatieveUitkerendeFase);
  }
);
