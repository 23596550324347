import { PensioenenState, Pensioenregelingsoort, pensioenenSchema, TariefEntry } from "./pensioen-schema";
import {
  initISWAsyncSideEffect,
  createISWAsyncSideEffect
} from "../../shared/components/isw-side-effects/create-isw-helpers";
import {
  AowFranchiseOutput,
  AowFranchiseInput,
  BurgerlijkeStaatType,
  Algemeen
} from "../../.generated/tarieven/tarieventypes";
import { Pensioenregelingsoort as FormPensioenregelingsoort } from "../../.generated/forms/formstypes";

import { ISWAsyncSideEffectBag } from "../../shared/components/isw-side-effects/isw-types";
import { createMapToDl } from "../../shared/utils/create-map-to-dl";
import { returnErrorBerekening } from "../../shared/utils/berekenen";

type AsyncContext = { selected: number };

const mapSoortPensioenRegeling = (soortPensioenRegeling: Pensioenregelingsoort): FormPensioenregelingsoort => {
  switch (soortPensioenRegeling) {
    case Pensioenregelingsoort.BeschikbarePremie:
      return FormPensioenregelingsoort.BeschikbarePremie;
    case Pensioenregelingsoort.Middelloon:
      return FormPensioenregelingsoort.Middelloon;
    case Pensioenregelingsoort.Eindloon:
      return FormPensioenregelingsoort.Eindloon;
    default:
      return FormPensioenregelingsoort.Geen;
  }
};

export const mapAowFranchiseInput = createMapToDl(pensioenenSchema)
  .with<number>()
  .to<AowFranchiseInput>({
    burgerlijkeStaat: (v, i) =>
      (v.deelnemers.find(d => d.klantId === v.pensioenen[i].selectedDeelnemer)?.burgerlijkeStaat ??
        BurgerlijkeStaatType.Geen) as BurgerlijkeStaatType,
    soortPensioenregeling: (v, i) =>
      mapSoortPensioenRegeling(v.pensioenen[i].pensioenregeling.soortPensioenregeling),
    tarievenNorm: v => null
  });

export const berekenPensioenFranchiseAsync = createISWAsyncSideEffect<PensioenenState, AsyncContext>(
  async ({ draft, settings, fetchData, context }) => {
    const input = mapAowFranchiseInput(context.selected);
    const result = await fetchData<AowFranchiseOutput, AowFranchiseInput>({
      url: `${settings.accTarievenOrigin}/Berekeningen/AowFranchise`,
      body: input
    });

    if (result.isValid) {
      draft.pensioenen[context.selected].pensioengrondslag.franchise.berekendBedrag = result.aowFranchiseBedrag;
    }
  }
);

export const getTarief = async ({
  draft,
  settings,
  fetchData
}: ISWAsyncSideEffectBag<PensioenenState, AsyncContext>): Promise<TariefEntry> => {
  const tariefUrl = `${settings.accTarievenOrigin}/api/Toetsing`;
  const tarieven = await fetchData<TariefEntry[]>({
    url: tariefUrl,
    method: "GET"
  });
  if (!tarieven) {
    throw returnErrorBerekening(
      `api.laatsteTarief.error`,
      `Fout bij aanroep van web-api '${tariefUrl}'. Resultaat bevat geen JSON.`
    )[0].value;
  }
  return tarieven.reduce(
    (prev: TariefEntry, current: TariefEntry): TariefEntry => (prev.jaar > current.jaar ? prev : current)
  );
};

export const getMiddelEindloonPercentageAsync = createISWAsyncSideEffect<PensioenenState, AsyncContext>(async bag => {
  const { draft, fetchData, context, settings } = bag;
  const latestTarief = await getTarief(bag);

  const result = await fetchData<Algemeen>({
    url: `${settings.accTarievenOrigin}/api/Fiscaal/${latestTarief.jaar}/${latestTarief.maand}`,
    method: "GET"
  });

  if (result) {
    if (
      draft.pensioenen[context.selected].pensioenregeling.soortPensioenregeling === Pensioenregelingsoort.Middelloon
    ) {
      draft.pensioenen[context.selected].pensioenregeling.middelloonregelingPercentage =
        result.sociaal?.middelloonOpbouwMaxPercentage ?? null;
    } else if (
      draft.pensioenen[context.selected].pensioenregeling.soortPensioenregeling === Pensioenregelingsoort.Eindloon
    ) {
      draft.pensioenen[context.selected].pensioenregeling.eindloonregelingPercentage =
        result.sociaal?.eindloonOpbouwMaxPercentage ?? null;
    }
  }
});

export const pensioenAsyncSideEffects = initISWAsyncSideEffect<PensioenenState, AsyncContext>(bag => {
  const { has, curr, runAsync, context } = bag;
  if (curr.pensioenen[context.selected]) {
    const currSoortPensioenregeling = curr.pensioenen[context.selected].pensioenregeling.soortPensioenregeling;

    if (has.pensioenen[context.selected].pensioenregeling.soortPensioenregeling.changed) {
      if (
        currSoortPensioenregeling === Pensioenregelingsoort.Middelloon &&
        curr.pensioenen[0].pensioenregeling.middelloonregelingPercentageWettelijk === null
      ) {
        runAsync(getMiddelEindloonPercentageAsync(context));
      } else if (
        currSoortPensioenregeling === Pensioenregelingsoort.Eindloon &&
        curr.pensioenen[0].pensioenregeling.eindloonregelingPercentageWettelijk === null
      ) {
        runAsync(getMiddelEindloonPercentageAsync(context));
      }
      if (
        currSoortPensioenregeling === Pensioenregelingsoort.Middelloon ||
        currSoortPensioenregeling === Pensioenregelingsoort.Eindloon
      ) {
        runAsync(berekenPensioenFranchiseAsync(context));
      }
    }
  }
});
