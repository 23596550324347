import { HypotheekOversluitenState } from "./hypotheek-oversluiten-schema";
import { createISWSideEffect, initISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";

const hypotheekOversluitenSideEffects = createISWSideEffect<HypotheekOversluitenState>((bag): void => {
  const { has, draft } = bag;

  if (has.toetsrente.gewensteRentevastperiode.changed) {
    draft.dataHasChanged = true;
  }
});

export const determineHypotheekOversluitenSideEffects = initISWSideEffect(hypotheekOversluitenSideEffects)();
