import { LocalDate } from "@js-joda/core";
import { BoeterenteBerekeningswijzeOpties } from "../../.generated/forms/formstypes";
import {
  BoeterenteContanteWaardeInput,
  AflossingsVormType,
  BoeterenteContanteWaardeOutput
} from "../../.generated/hypotheek/hypotheektypes";
import {
  createISWAsyncSideEffect,
  initISWAsyncSideEffect
} from "../../shared/components/isw-side-effects/create-isw-helpers";
import { mapPeriodeInMaandenDl2Ui } from "../../shared/generic-parts/jaar-maand/map-dl-2-ui";
import { jaarMaandInMaanden } from "../../shared/generic-parts/jaar-maand/map-ui-2-dl";
import { createMapToDl } from "../../shared/utils/create-map-to-dl";
import { addMonths, addYears } from "../../shared/utils/dates";
import { boeterenteModalSchema } from "../infra/financieringsbehoefte-schema";
import { BoeterenteModalType } from "../infra/financieringsbehoefte-types";
import { mapLocalDateToString } from "adviesbox-shared";

type AsyncContext = { selected: number };

export const mapBoeterenteInput = createMapToDl(boeterenteModalSchema)
  .with<number>()
  .to<BoeterenteContanteWaardeInput>({
    aflossingsVorm: (v, i) =>
      AflossingsVormType[
        v.modal[i].aflosvorm as keyof typeof AflossingsVormType
      ],
    oorspronkelijkeHoofdsom: (v, i) => v.modal[i].oorspronkelijkeHoofdsom || 0,
    restantHoofdsom: (v, i) => (v.modal[i].restantHoofdsom ? Math.round(v.modal[i].restantHoofdsom || 0) : 0),
    boetevrijPercentage: (v, i) => v.modal[i].boetevrijePercentage || 0,
    datumBoeterente: (v, i) => mapLocalDateToString(v.modal[i].datumBoeterente) || "",
    einddatumRentevastperiode: (v, i) =>
      v.modal[i].resterendePeriodeWeergeven
        ? mapLocalDateToString(v.modal[i].einddatumRentevastperiode) || ""
        : mapLocalDateToString(
            addMonths(
              addYears(
                v.modal[i].datumBoeterente ??
                  LocalDate.now()
                    .plusMonths(1)
                    .withDayOfMonth(1),
                v.modal[i].resterendePeriode.jaren || 0
              ),
              v.modal[i].resterendePeriode.maanden || 0
            )
          ),
    opgebouwdeWaarde: (v, i) => v.modal[i].opgewbouwdewaardeBedrag || 0,
    huidigRentePercentage: (v, i) => v.modal[i].huidigeContractrente || 0,
    nieuwRentePercentage: (v, i) => v.modal[i].nieuwRentepercentage || 0,
    restantLooptijdInMaanden: (v, i) => jaarMaandInMaanden(v.modal[i].restantLooptijd ) || 0
  });

export const boeterenteAsyncBerekening = createISWAsyncSideEffect<BoeterenteModalType, AsyncContext>(
  async ({ draft, settings, fetchData, context, formik }) => {
    // boeterente alleen laten berekenen als input valid is
    if (formik.isValid){
    const result = await fetchData<BoeterenteContanteWaardeOutput, BoeterenteContanteWaardeInput>({
      url: `${settings.hypotheekOrigin}/Boeterente/ContanteWaarde`,
      body: mapBoeterenteInput(context.selected)
    });

    if (result?.isValid) {
      draft.modal[context.selected].boeterente = result.resultaat?.contanteWaarde ?? 0;
      if (draft.modal[context.selected].resterendePeriodeWeergeven) {
        draft.modal[context.selected].resterendePeriode = mapPeriodeInMaandenDl2Ui(
          result.resultaat?.restantRentevastperiodeInMaanden
        );
      }
    }   
  }
  }
);

export const boeterenteAsyncSideEffects = initISWAsyncSideEffect<BoeterenteModalType, AsyncContext>(
  ({ curr, runAsync, context }) => {
    const boeterente = curr.modal[context.selected];
    const rekenen: boolean =
      boeterente.berekenwijzeBoeterente === BoeterenteBerekeningswijzeOpties.Contant &&
      boeterente.datumBoeterente !== null &&
      boeterente.huidigeContractrente !== null && 
      boeterente.datumBoeterente !== null &&
      boeterente.einddatumRentevastperiode !== null &&
      boeterente.restantLooptijd !== null;
    if (rekenen) {
      runAsync(boeterenteAsyncBerekening(context));
    }
  }
);
