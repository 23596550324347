import React, { ReactElement } from "react";
import { getHypotheekVergelijkenTextResources } from "../infra/hypotheek-resources";
import { Button } from "react-bootstrap";
import { AflossingsVormType } from "../../.generated/forms/formstypes";
import { LabelValuePairs } from "../../shared/types";
import { HypotheekVormType } from "../infra/hypotheek-types";
import { HypotheekType } from "../infra/hypotheek-types";
import { hypotheekSchema } from "../infra/hypotheek-schema";
import { HypotheekVergelijkenModalType } from "./hypotheek-vergelijken-types";
import classes from "./infra/hypotheek-vergelijken-helper.module.scss";
import { HypotheekVergelijkerPDFGenerator } from "./hypotheek-vergelijker-pdf/hypotheek-vergelijker-pdf-generator";

export const getHypotheekVergelijkenButtonText = (step: number): string => {
  switch (step) {
    case 1:
      return getHypotheekVergelijkenTextResources("DoorNaarVoorwaardenselectie");
    case 2:
      return getHypotheekVergelijkenTextResources("ToonResultaat");
    case 3:
      return getHypotheekVergelijkenTextResources("AdviesOverschrijven");
    default:
      return "Volgende";
  }
};

export const getFooterElement = (
  step: number,
  setStep: React.Dispatch<React.SetStateAction<number>>,
  values: HypotheekVergelijkenModalType
): ReactElement => {
  switch (step) {
    case 2:
      return (
        <div className="mr-auto">
          <Button name="backToHypotheeksamenstelling" variant={"link"} onClick={() => setStep(1)}>
            Terug naar hypotheeksamenstelling
          </Button>
        </div>
      );
    case 3:
      return (
        <>
          <div className="mr-auto">
            <Button name="backtoVoorwaardenselectie" variant={"link"} onClick={() => setStep(2)}>
              Terug naar voorwaardenselectie
            </Button>
          </div>
          <div className={classes.flex}>
            {!!values.resultaten.length && <HypotheekVergelijkerPDFGenerator values={values} />}
          </div>
        </>
      );
    default:
      return <></>;
  }
};

export const onSubmitWizard = async (
  values: HypotheekVergelijkenModalType,
  currentStep: number,
  totalSteps: number,
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>,
  onSave?: (values: HypotheekVergelijkenModalType) => void,
  closeModal?: () => void
): Promise<void> => {
  if (currentStep === totalSteps) {
    onSave && onSave(values);
    closeModal && closeModal();
  } else {
    if (currentStep < totalSteps) {
      setCurrentStep(currentStep + 1);
    }
  }
};

export const HypotheekVergelijkenAflossingsvormRestschuldOptions = [
  {
    label: "Annuïteit",
    value: AflossingsVormType.Annuïteit
  },
  {
    label: "Lineair",
    value: AflossingsVormType.Lineair
  }
];

export function HypotheekVergelijkenAflossingsvormOptions(hypotheekVorm: HypotheekVormType): LabelValuePairs {
  const isRestschuldEnKrediet =
    hypotheekVorm.isRestschuldLening && hypotheekVorm.aflossingsvorm === AflossingsVormType.Krediet;

  const isHybride = hypotheekVorm.aflossingsvorm === AflossingsVormType.Hybride;
  const aflossingsOptions = [
    {
      label: "Annuïteit",
      value: AflossingsVormType.Annuïteit
    },
    {
      label: "Lineair",
      value: AflossingsVormType.Lineair
    },
    {
      label: "Aflosvrij",
      value: AflossingsVormType.Aflosvrij
    },
    {
      label: "Bankspaar",
      value: AflossingsVormType.Spaarrekening
    },
    {
      label: "Spaar",
      value: AflossingsVormType.Spaar
    },
    {
      label: "Leven",
      value: AflossingsVormType.Levensverzekering
    },
    {
      label: "Belegging",
      value: AflossingsVormType.Belegging
    }
  ] as LabelValuePairs;

  switch (true) {
    case isHybride:
      aflossingsOptions.push({
        label: "Hybride",
        value: AflossingsVormType.Hybride
      });
      break;

    case isRestschuldEnKrediet:
      aflossingsOptions.push({
        label: "Krediet",
        value: AflossingsVormType.Krediet
      });
      break;
  }
  return aflossingsOptions;
}
export const leningdeelToevoegenFactory = (bestaande: HypotheekType): HypotheekType => {
  const nieuwLeningdeel: HypotheekType = {
    ...hypotheekSchema.default(),
    product: {
      ...bestaande.product
    }
  };

  return nieuwLeningdeel;
};

export const getAflosvormText = (
  aflosvorm: AflossingsVormType,
  restschuldLening?: boolean,
  startersLening?: boolean
): string => {
  if (startersLening) {
    return "Starterslening";
  }
  switch (aflosvorm) {
    case AflossingsVormType.Aflosvrij:
      return "Aflosvrij";
    case AflossingsVormType.Annuïteit:
      return restschuldLening ? "Restschuld Annuïtair " : "Annuïteit";
    case AflossingsVormType.AnnuïteitBlok:
      return "Annuïteit blok";
    case AflossingsVormType.AnnuïteitUitgesteld:
      return "Annuïteit uitgesteld";
    case AflossingsVormType.Belegging:
      return "Belegging";
    case AflossingsVormType.Geen:
      return "Geen";
    case AflossingsVormType.Hybride:
      return "Hybride";
    case AflossingsVormType.Krediet:
      return "Krediet";
    case AflossingsVormType.Levensverzekering:
      return "Levensverzekering";
    case AflossingsVormType.Lineair:
      return restschuldLening ? "Restschuld Lineair" : "Lineair";
    case AflossingsVormType.Spaar:
      return "Spaar";
    case AflossingsVormType.Spaarrekening:
      return "Spaarrekening";
    case AflossingsVormType.UnitLinked:
      return "Unit Linked";
    default:
      return "Lineair";
  }
};
