import React, { ReactElement } from "react";
import { FormikProps } from "formik";
import { FormikSideEffects } from "use-formik-side-effects";

import { determinePoliswaardeModalSideEffects } from "./determine-poliswaarde-modal-side-effects";
import AdviesBoxFormik from "../../shared/utils/adviesbox-formik";
import Modal from "../../shared/components/modal/Modal";
import { PoliswaardeModalType } from "../infra/kapitaalverzekering-types";
import { poliswaardeModalSchema } from "../infra/kapitaalverzekering-schema";
import { ScenarioCard, ScenarioInputParams } from "../../shared/components/scenario-input/scenario-card";
import { Label, CurrencyInput } from "adviesbox-shared";
import { LocalDate } from "@js-joda/core";

export type PoliswaardeModalData = {
  data: PoliswaardeModalType;
};

type PoliswaardeModalProps = {
  onSave?: (data: PoliswaardeModalType) => void;
  closeModal?: () => void;
};

type ScenarioFunc = (itemIndex: number, entries: number, scenarioInputParams: ScenarioInputParams) => ReactElement;

const scenarioCardWrapper = (begin: number): ScenarioFunc => (
  itemIndex,
  entries,
  scenarioInputParams
): ReactElement => {
  return (
    <div className="d-flex flex-wrap scenario-container" key={itemIndex}>
      <div className="pr-2 col-6">
        <Label
          caption={`${itemIndex + 1} (${begin + (itemIndex + 1)})`}
          name={`${scenarioInputParams.name}[${itemIndex}].eindwaardeBedrag`}
        />
      </div>
      <div className="pr-1 col-6">
        <CurrencyInput
          name={`${scenarioInputParams.name}[${itemIndex}].eindwaardeBedrag`}
          fieldSize="m"
          readonly={true}
        />
      </div>
    </div>
  );
};

const scenarioHeaderWrapper = (columnIndex: number): ReactElement => {
  return (
    <div className="d-flex flex-wrap scenario-container" key={`header-${columnIndex}`}>
      <div className="pr-2 col-6">Jaar</div>
      <div className="pr-1 col-6">Eindwaarde</div>
    </div>
  );
};

const scenarioWrappers = (
  beginJaar: number
): { scenarioCardWrapper: ScenarioFunc; scenarioHeaderWrapper: (columnIndex: number) => ReactElement } => ({
  scenarioCardWrapper: scenarioCardWrapper(beginJaar),
  scenarioHeaderWrapper
});

const PoliswaardeModal = ({ data, onSave, closeModal }: PoliswaardeModalData & PoliswaardeModalProps): ReactElement => {
  const begin = data.beginJaar || LocalDate.now().year();
  const body = (
    <div>
      <ScenarioCard
        name="waardeopbouwJaren"
        scenarioWrappers={scenarioWrappers(begin)}
        vervolgjarenAutomatischVullen={false}
      />
    </div>
  );

  return (
    <AdviesBoxFormik<PoliswaardeModalType>
      initialValues={{ ...data }}
      validationSchema={poliswaardeModalSchema}
      onSave={onSave}
      closeModal={closeModal}
      render={({ submitForm }: FormikProps<PoliswaardeModalType>): ReactElement => (
        <>
          <FormikSideEffects<PoliswaardeModalType> determineSideEffects={determinePoliswaardeModalSideEffects} />

          <Modal title="Poliswaarde" body={body} onSubmitClick={submitForm} onCancelClick={closeModal} />
        </>
      )}
    />
  );
};

PoliswaardeModal.displayName = "PoliswaardeModal";

export default PoliswaardeModal;
