import { SettingsType } from "adviesbox-shared";
import { FormikContextType } from "formik";
import { User } from "oidc-client";
import { NieuwAdviesdossierOutput } from "../../../../.generated/forms/formstypes";
import { hasJSONResponse } from "../../../utils/ajax";
import { OmschrijvingType } from "../schema-and-mappers";

export const dossierKopierenModalApi = async (
  settings: SettingsType,
  user: User | null,
  vestigingId: string,
  adviesdossierId: string,
  data: OmschrijvingType,
  setFieldValue: FormikContextType<OmschrijvingType>["setFieldValue"]
): Promise<NieuwAdviesdossierOutput> => {
  const url = `${settings.klantdossiersFormsOrigin}/Adviesdossiers/${adviesdossierId}/Kopie`;
  const rsp = await fetch(url, {
    method: "POST",
    headers: {
      authorization: `${user?.token_type} ${user?.access_token}`,
      "Ocp-Apim-Subscription-Key": settings.OcpApimSubscriptionKey,
      "Content-Type": "application/json;charset=UTF-8",
      vestigingId,
      adviesdossierId
    },
    body: JSON.stringify({ korteOmschrijving: data?.korteOmschrijving, langeOmschrijving: data?.langeOmschrijving })
  });

  if (!(rsp.ok && hasJSONResponse(rsp))) {
    setFieldValue("platformApiFouten", [
      {
        field: "",
        label: `Fout: ${rsp.status}`,
        error: "Fout bij aanroep van web-api"
      }
    ]);
    throw Error(`Fout bij aanroep van web-api '${url}'. Resultaat bevat geen JSON.`);
  }

  const json = await rsp.json();

  if (!json.isValid) {
    setFieldValue("platformApiFouten", [
      {
        field: "",
        label: `Fout: ${rsp.status}`,
        error: json.message
      }
    ]);
    throw Error(`Fout bij aanroep van web-api '${url}'. Input is niet valid.`);
  }
  return json;
};
