import { AdviesBoxFormik, Card, CardWrapper, ErrorPage, PageLoading, SettingsType } from "adviesbox-shared";
import { FormikContextType, FormikHelpers, FormikProps, useFormikContext } from "formik";
import React, { ReactElement, useState } from "react";
import { Collapse } from "react-bootstrap";
import { ScenarioVan } from "../../dashboard/components/scenario-van/scenario-van";
import { InkomensAnalysePdf } from "../../inkomens-analyse/inkomens-analyse-pdf";
import { GeslachtOpties, MotiveringOnderwerpsoort } from "../../.generated/forms/formstypes";
import { InvalidAdviesboxResultErrorPage } from "../../shared/components/fetch-error-page/fetch-error-page";
import { ISWSideEffects } from "../../shared/components/isw-side-effects/isw-side-effects";
import Modal from "../../shared/components/modal/Modal";
import { useAdviesboxData } from "../../shared/hooks/use-adviesbox-data";
import { useSaveBeforeLoad } from "../../shared/hooks/use-save-before-load";
import { RouteParams } from "../../shared/paramrouting/paramrouting-context";
import { UiError } from "../../shared/types";
import { getNaam } from "../../shared/utils/helpers";
import { setFormikUiErrors } from "../../shared/utils/set-formik-ui-errors";
import { BeschikbareJaarruimte } from "../components/beschikbare-jaarruimte/beschikbare-jaarruimte";
import { ControleNorm } from "../components/controle-norm/controle-norm";
import { dashboardSchema } from "../infra/dashboard-schema";
import { DashboardState } from "../infra/dashboard-types";
import { mapDashboardDlTargetToUiField, mapDashboardOutput } from "../infra/map-dashboard-dl-2-ui";
import { mapDashboardUiToDl } from "../infra/map-dashboard-ui-2-dl";
import { initScenarioPensioenModalSideEffects } from "./infra/determine-sync-scenario-pensioen-modal-side-effects";
import { ScenarioPensioenModalProps } from "./infra/scenario-pensioen-modal-schema";

export const ScenarioPensioenModal = (props: ScenarioPensioenModalProps): ReactElement => {
  /* istanbul ignore next*/
  const createUrl = (invoorstel: boolean) => (s: SettingsType, p: RouteParams): string =>
    `${s.klantdossiersFormsOrigin}/${invoorstel ? "Voorstellen" : "Adviesdossiers"}/${invoorstel ? p.voorstel : p.adviesdossier
    }/Dashboard/Pensioen?berekenTekort=false`;

  const { loading, error, data, platformData, saveData } = useAdviesboxData(
    createUrl(props.inVoorstel || false),
    p => (props.inVoorstel ? p.voorstel : p.adviesdossier),
    mapDashboardOutput,
    (values: DashboardState): any => {
      const mapped = mapDashboardUiToDl(values);

      return {
        ...mapped,
        arbeidsongeschiktheid: null,
        werkloosheid: null,
        overlijden: null
      };
    },
    mapDashboardDlTargetToUiField,
    true
  );

  const { loading: saveBeforeLoading } = useSaveBeforeLoad(true);

  const ModalBody = (): ReactElement => {
    const [showInstellingen, setInstellingenVisible] = useState(true);

    const formik = useFormikContext<DashboardState>();
    const { values } = formik;

    /* istanbul ignore next */
    const saveAndSetErrors = async (): Promise<void> => {
      const result = await saveData(values);
      if (result !== null && result instanceof Array) {
        setFormikUiErrors(result, formik as FormikContextType<DashboardState>);
      } else {
        setFormikUiErrors([], formik as FormikContextType<DashboardState>);
      }
    };

    return (
      <>
        <ISWSideEffects<DashboardState> sync={initScenarioPensioenModalSideEffects} />

        <Collapse in={showInstellingen}>
          <div id="instellingen-penioen" data-testid="instellingen-pensioen-collapse">
            <CardWrapper>
              <Card title="Controle op norm">
                <ControleNorm namePrefix="pensioen" />
              </Card>
              {values.aanvrager2 && (
                <Card title="Scenario van">
                  <ScenarioVan namePrefix="pensioen" />
                </Card>
              )}
              {values.pensioen?.beschikbareJaarruimteAanvrager && (
                <Card
                  title={`Beschikbare jaarruimte ${values.aanvrager1.geslacht === GeslachtOpties.Man ? "dhr." : "mvr."
                    } ${values.aanvrager1.voorletters} ${values.aanvrager1.voorvoegsel} ${values.aanvrager1.achternaam}`}
                >
                  <BeschikbareJaarruimte
                    beschikbareJaarruimteValues={values.pensioen.beschikbareJaarruimteAanvrager}
                    namePrefix="beschikbareJaarruimteAanvrager"
                  />
                </Card>
              )}
              {values.pensioen?.beschikbareJaarruimtePartner && values.aanvrager2 && (
                <Card
                  title={`Beschikbare jaarruimte ${values.aanvrager2.geslacht === GeslachtOpties.Man ? "dhr." : "mvr."
                    } ${values.aanvrager2.voorletters} ${values.aanvrager2.voorvoegsel} ${values.aanvrager2.achternaam}`}
                >
                  <BeschikbareJaarruimte
                    beschikbareJaarruimteValues={values.pensioen.beschikbareJaarruimtePartner}
                    namePrefix="beschikbareJaarruimtePartner"
                  />
                </Card>
              )}
            </CardWrapper>
          </div>
        </Collapse>

        <CardWrapper className="px-3">
          <div className="text-container" style={{ textAlign: "right", width: "100%", minHeight: "70px" }}>
            <button
              id="opslaan"
              onClick={() => {
                setInstellingenVisible(!showInstellingen);
              }}
              className="btn btn-light ml-1"
              type="button"
              aria-controls="instellingen-penioen"
              aria-expanded={showInstellingen}
            >
              {showInstellingen ? <>Instellingen verbergen</> : <>Instellingen tonen</>}
            </button>
          </div>
        </CardWrapper>

        {values.aanvrager1 && values.pensioen.scenarioVan.aanvrager1Geselecteerd && (
          <CardWrapper flexType={"flex-column"} maxRowCount={1}>
            <Card
              title="Inkomensanalyse"
              fullscreenTonen
              subtitle={`Jouw inkomen bij pensioen van ${getNaam(values.aanvrager1, "Aanvrager")}`}
              subtitleOnSeparateLine
              subtitleSplit
              className="full-width-card"
              collapseExpandOption
            >
              <InkomensAnalysePdf
                motivering={MotiveringOnderwerpsoort.Pensioenvoorziening}
                klantId={values.aanvrager1.klantId}
                onRefresh={saveAndSetErrors}
                dataOutdated={values.data1Outdated}
                setDataOutdated={/* istanbul ignore next */ value => formik.setFieldValue("data1Outdated", value)}
              />
            </Card>
          </CardWrapper>
        )}
        {values.aanvrager2 && values.pensioen.scenarioVan.aanvrager2Geselecteerd && (
          <CardWrapper flexType={"flex-column"} maxRowCount={1}>
            <Card
              title="Inkomensanalyse"
              fullscreenTonen
              subtitle={`Jouw inkomen bij pensioen van ${getNaam(values.aanvrager2, "Partner")}`}
              subtitleOnSeparateLine
              subtitleSplit
              className="full-width-card"
              collapseExpandOption
            >
              <InkomensAnalysePdf
                motivering={MotiveringOnderwerpsoort.Pensioenvoorziening}
                klantId={values.aanvrager2.klantId}
                onRefresh={saveAndSetErrors}
                dataOutdated={values.data2Outdated}
                setDataOutdated={/* istanbul ignore next */ value => formik.setFieldValue("data2Outdated", value)}
              />
            </Card>
          </CardWrapper>
        )}
      </>
    );
  };

  if (saveBeforeLoading) {
    return <></>;
  }

  if (loading) {
    return <PageLoading />;
  }

  if (error) {
    return <ErrorPage error={error} data={platformData as any} />;
  }

  if (!data) {
    return <InvalidAdviesboxResultErrorPage />;
  }

  return (
    <AdviesBoxFormik<DashboardState>
      initialValues={data}
      validationSchema={dashboardSchema}
      onSaveAsync={async (
        values: DashboardState,
        formikHelpers: FormikHelpers<
          DashboardState & {
            platformApiFouten?: UiError[] | null | undefined;
          }
        >
      ): Promise<boolean> => {
        const result = await saveData(values);
        if (result !== null && result instanceof Array)
          /* istanbul ignore next */
          setFormikUiErrors(result, formikHelpers as FormikContextType<DashboardState>);

        /* istanbul ignore next */ props.callbackOnClose && props.callbackOnClose();

        return result === null ? true : false;
      }}
      closeModal={props.closeModal}
      render={({ submitForm }: FormikProps<DashboardState>): ReactElement => (
        <div className="modal-with-cards">
          <Modal
            title="Scenario pensioen"
            body={<ModalBody />}
            onSubmitClick={submitForm}
            onCancelClick={props.closeModal}
          />
        </div>
      )}
    />
  );
};
