import React, { ReactElement } from "react";
import { FormikProps } from "formik";
import { LabeledCurrencyInput } from "adviesbox-shared";
import {
  fiscaleBijtelpostenModalSchema,
  FiscaleBijtelpostenModalType,
} from "../infra/inkomen-en-fiscus-schema";

import { determineFiscaleBijtelpostenModalSideEffects } from "./determine-fiscale-bijtelposten-modal-side-effects";
import AdviesBoxFormik from "../../shared/utils/adviesbox-formik";
import Modal from "../../shared/components/modal/Modal";
import { ISWSideEffects } from "../../shared/components/isw-side-effects/isw-side-effects";

export type FiscaleBijtelpostenModalData = {
  data: FiscaleBijtelpostenModalType;
};

type FiscaleBijtelpostenModalProps = {
  onSave?: (data: FiscaleBijtelpostenModalType) => void;
  closeModal?: () => void;
};

const FiscaleBijtelpostenModal = ({
  data,
  onSave,
  closeModal
}: FiscaleBijtelpostenModalData & FiscaleBijtelpostenModalProps): ReactElement => {
  const body = (
    <>
      <div>
        <LabeledCurrencyInput caption="Bijtelling bedraagt" name="bijtellingBedraagt" fieldSize="m" />
      </div>
      <div>
        <LabeledCurrencyInput caption="Eigen bijdrage op jaarbasis (optioneel)" name="eigenBijdrage" fieldSize="m" />
      </div>
      <LabeledCurrencyInput
        caption="Bijtelling op inkomen"
        name="bijtellingOpInkomen"
        readonly
        fieldSize="m"
        placeholder="-"
      />
    </>
  );

  return (
    <AdviesBoxFormik<FiscaleBijtelpostenModalType>
      initialValues={{ ...data }}
      validationSchema={fiscaleBijtelpostenModalSchema}
      onSave={onSave}
      closeModal={closeModal}
      render={({ submitForm }: FormikProps<FiscaleBijtelpostenModalType>): ReactElement => (
        <>
          <ISWSideEffects<FiscaleBijtelpostenModalType> sync={determineFiscaleBijtelpostenModalSideEffects} />

          <Modal title="Fiscale bijtelposten" body={body} onSubmitClick={submitForm} onCancelClick={closeModal} />
        </>
      )}
    />
  );
};

export default FiscaleBijtelpostenModal;
