/* istanbul ignore file */
/* eslint-disable */
import { useState, useEffect, useCallback, useRef } from 'react';
import { useSemaphore } from '../../../../dashboard/new/semaphore-context-provider';

const logDebug = true;

const hasJSONResponse = (response: Response): boolean => {
  const contentType: string | null = response.headers.get("content-type");
  return !!(contentType && contentType.includes("application/json"));
};

type FetchDataReturn<T> = {
  data: T | null | undefined;
  dataUrl: string | null; // New field to store the URL associated with the data
  loading: boolean;
  error: string | null;
  refetch: (requestInit?: RequestInit) => Promise<void>;
  setData: (data: T | null, url: string | null) => void;
}

export const useFetchData = <T,>(
  url: string,
  getRequestInit: () => RequestInit,
  enabled: boolean = true,
  lowPriority: boolean = false,
  autostart: boolean=true
): FetchDataReturn<T> => {
  const [{ data, dataUrl }, setDataState] = useState<{ data: T | null | undefined, dataUrl: string | null }>({ data: undefined, dataUrl: null });
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const semaphore = useSemaphore();
  const currentUrl = useRef(url);
  const isMounted = useRef(true);
  
  const fetchData = useCallback(async (overWriteRequestInit?: RequestInit): Promise<void> => {
    if (!enabled || !isMounted.current) {
      logDebug && console.log(`[useFetchData] Fetch not enabled or component unmounted for URL: ${url}`);
      return;
    }

    logDebug && console.log(`[useFetchData] Starting fetch for URL: ${url}`);
    setLoading(true);
    setError(null);

    try {
      const requestInit = !!overWriteRequestInit ? overWriteRequestInit : typeof(getRequestInit) === "function" ? getRequestInit() : {};

      logDebug && console.log(`[useFetchData] Running fetch through semaphore for URL: ${url}`);
      const response = await semaphore.run(async () => {
        logDebug && console.log(`[useFetchData] Executing fetch for URL: ${url}`);
        return fetch(url, requestInit);
      }, lowPriority);
      
      if (!isMounted.current) {
        logDebug && console.log(`[useFetchData] Component unmounted during fetch for URL: ${url}`);
        return;
      }

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      if (!hasJSONResponse(response)) {
        throw new Error(`Error fetching data from ${url}.`);
      }

      const result: T = await response.json();
      logDebug && console.log(`[useFetchData] Fetch completed successfully for URL: ${url}`);
      
      if (isMounted.current && url === currentUrl.current) {
        setDataState({ data: result, dataUrl: url });
        logDebug && console.log(`[useFetchData] Data set for URL: ${url}`, result);
      } else {
        logDebug && console.log(`[useFetchData] Data not set. Component unmounted or URL changed. Current URL: ${currentUrl.current}`);
      }
    } catch (error) {
      logDebug && console.error(`[useFetchData] Error fetching data for URL: ${url}`, error);
      if (isMounted.current && url === currentUrl.current) {
        setError(error instanceof Error ? error.message : String(error));
      }
    } finally {
      if (isMounted.current && url === currentUrl.current) {
        setLoading(false);
      }
      logDebug && console.log(`[useFetchData] Fetch process finished for URL: ${url}`);
    }
  }, [url, getRequestInit, semaphore, enabled, lowPriority]);

  useEffect(() => {
    logDebug && console.log(`[useFetchData] useEffect triggered for URL: ${url}`);
    currentUrl.current = url;
    isMounted.current = true;
    if (autostart){
      fetchData();
    }

    return () => {
      isMounted.current = false;
    };
  }, [fetchData, url]);

  const refetch = useCallback((requestInit: RequestInit): Promise<void> => {
    logDebug && console.log(`[useFetchData] Refetch called for URL: ${url}`);
    return fetchData(requestInit);
  }, [fetchData, url]);

  const setData = useCallback((newData: T | null, newUrl: string | null) => {
    setDataState({ data: newData, dataUrl: newUrl });
  }, []);

  // Check for URL mismatch
  if (dataUrl && dataUrl !== url) {
    logDebug && console.warn(`[useFetchData] URL mismatch detected. Current URL: ${url}, Data URL: ${dataUrl}`);
  }

  return { data, dataUrl, loading, error, refetch, setData };
};