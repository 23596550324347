import { SettingsType } from "adviesbox-shared";
import { User } from "oidc-client";
import { LaatstVerzondenAanvraagOutput } from "../../../.generated/hdndossier/hdndossiertypes";
import { ZoekenAdviesDossierType } from "../../infra/zoekscherm-schema";

export const laatstVerzondenAanragenKlantdossier = async (  
  settings: SettingsType,  
  user: User | null,  
  vestigingId: string,  
  adviesDossierIds: ZoekenAdviesDossierType[]  
): Promise<LaatstVerzondenAanvraagOutput[]> => {  
  const results: LaatstVerzondenAanvraagOutput[] = [];  
    
  try {  
    await Promise.all(  
      adviesDossierIds.map(async (adviesdossier) => {  
        const url = `${settings.hdnDossierOrigin}/adviesdossier/${adviesdossier.adviesdossierId}/laatstverzondenaanvraag`;  
        const rsp = await fetch(url, {  
          method: "GET",  
          headers: {  
            authorization: `${user?.token_type} ${user?.access_token}`,  
            "Ocp-Apim-Subscription-Key": settings.OcpApimSubscriptionKey,  
            "Content-Type": "application/json;charset=UTF-8",  
            vestigingId,  
          },  
        });  
  
        if (!rsp.ok) {  
          throw Error(`Fout bij aanroep van web-api '${url}'.`);  
        }  
  
        const result = await rsp.json();  
        results.push(result);  
      })  
    );  
  } catch (error) {
    throw Error(`Fout bij aanroep van web-api.`);  
  }  
    
  return results;  
};  
