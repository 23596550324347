import { LocalDate } from "@js-joda/core";
import {
  AovEigenRisicoOptions,
  AovUitkeringsduurOptions,
  AoBeoordelingscriterium,
  AovKeuzeDekkingOptions,
  AovVoornaamsteInkomstenbronOptions,
  AovBetalingMiddelsOptions,
  AovSoortProductOptions
} from "../../.generated/forms/formstypes";
import { productSchema } from "../../producten-overzicht/infra/producten-overzicht-schema";
import { ProductType, SituatieSoort } from "../../producten-overzicht/infra/producten-overzicht-types";
import { AoUitkeringswijze } from "../../producten-overzicht/infra/specificatie-ao-uitkering-schema";
import { addMonths, addYears } from "../../shared/utils/dates";
import {
  AovJaarlijkseIndexatieVan,
  dekkingGegevensAoSchema,
  dekkingGegevensVerzekerdeSchema,
  DekkingGegevensVerzekerdeType,
  dekkingGegevensWwSchema,
  DekkingGegevensWwType,
  dekkingVerzekerdeRubriekASchema,
  dekkingVerzekerdeRubriekBSchema,
  PartijProduct,
  PremieBetalingstermijn,
  premiegegevensSchema,
  PremiegegevensType
} from "./aov-schema";

type ProductDefaultsProps = {
  ingangsdatumVoorstel: LocalDate | null;
  situatie: SituatieSoort;
};

export const getProductDefaults = (props: ProductDefaultsProps): ProductType => {
  const { ingangsdatumVoorstel, situatie } = props;
  const product = productSchema.default();
  if (situatie === "voorstel") {
    product.ingangsdatum =
      ingangsdatumVoorstel ??
      LocalDate.now()
        .plusMonths(1)
        .withDayOfMonth(1);
    product.einddatum = addMonths(
      addYears(product.ingangsdatum, product.looptijd.jaren || 0),
      product.looptijd.maanden || 0
    );
  }
  product.uwBemiddeling = situatie === "voorstel";
  return product;
};

export const getDekkingGegevensWwDefaults = (partijProduct?: PartijProduct): DekkingGegevensWwType => {
  const result = { ...dekkingGegevensWwSchema.default() };

  switch (partijProduct) {
    case PartijProduct.CallasWoonlasten:
    case PartijProduct.CallasInkomensGarantie:
    case PartijProduct.CreditLifeWoonlastenOngevallen:
    case PartijProduct.TafCreditLife:
    case PartijProduct.TafQuantumLeben:
    case PartijProduct.TafMaandlastenbeschermerIPTIQ:
    case PartijProduct.TafInkomensBeschermer:
      result.uitkeringsDuur = { jaren: 1, maanden: 0 };
      result.uitkeringsDuurInMaanden = "12";
      break;
  }
  return result;
};

export const getDekkingGegevensVerzekerDefaults = (
  partijProduct?: PartijProduct,
  situatie?: SituatieSoort
): DekkingGegevensVerzekerdeType => {
  const dekkingGegevensVerzekerde = dekkingGegevensVerzekerdeSchema.default();

  if (situatie !== "huidig") {
    dekkingGegevensVerzekerde.ao = dekkingGegevensAoSchema.default();
  }

  switch (partijProduct) {
    case PartijProduct.OnafhankelijkWoonlasten:
      dekkingGegevensVerzekerde.voornaamsteInkomstenbron = AovVoornaamsteInkomstenbronOptions.Loondienst;
      dekkingGegevensVerzekerde.ao = {
        ...dekkingGegevensAoSchema.default(),
        looptijd: { jaren: 30, maanden: 0 },
        eigenRisico: AovEigenRisicoOptions.Dagen30,
        uitkeringGedeeltelijkAo: AoUitkeringswijze.Volledig
      };
      break;
    case PartijProduct.CallasWoonlasten:
    case PartijProduct.CreditLifeWoonlastenOngevallen:
      dekkingGegevensVerzekerde.ao = {
        ...dekkingGegevensAoSchema.default(),
        looptijd: { jaren: 30, maanden: 0 },
        eigenRisico: AovEigenRisicoOptions.Dagen365,
        uitkeringGedeeltelijkAo: AoUitkeringswijze.Volledig
      };
      dekkingGegevensVerzekerde.beoordelingscriterium = AoBeoordelingscriterium.GangbareArbeid;
      break;
    case PartijProduct.OnafhankelijkWgaGat:
      dekkingGegevensVerzekerde.uitgebreid = false;
      dekkingGegevensVerzekerde.ao = {
        ...dekkingGegevensAoSchema.default(),
        uitkeringGedeeltelijkAo: AoUitkeringswijze.Volledig
      };
      break;
    case PartijProduct.OnafhankelijkWiaExcedent:
      dekkingGegevensVerzekerde.ao = {
        ...dekkingGegevensAoSchema.default(),
        uitkeringGedeeltelijkAo: AoUitkeringswijze.Volledig
      };
      dekkingGegevensVerzekerde.keuzeDekking = AovKeuzeDekkingOptions.VastBedrag;
      break;
    case PartijProduct.CallasInkomensGarantie:
      break;
    case PartijProduct.TafMaandlastenbeschermerZelfstandige:
      dekkingGegevensVerzekerde.ao = {
        ...dekkingGegevensAoSchema.default(),
        eigenRisico: AovEigenRisicoOptions.Dagen90,
        uitkeringsduurAo: AovUitkeringsduurOptions.Jaren2,
        uitkeringGedeeltelijkAo: AoUitkeringswijze.ProRata
      };
      dekkingGegevensVerzekerde.beoordelingscriterium = AoBeoordelingscriterium.PassendeArbeid;

      break;
    case PartijProduct.TafInkomensBeschermer:
      break;
    case PartijProduct.DazureInkomensbeschermer:
      dekkingGegevensVerzekerde.ao = {
        ...dekkingGegevensAoSchema.default(),
        eigenRisico: AovEigenRisicoOptions.Dagen365,
        uitkeringGedeeltelijkAo: AoUitkeringswijze.Volledig,
        verzekerdBedrag: 0
      };
      dekkingGegevensVerzekerde.beoordelingscriterium = AoBeoordelingscriterium.GangbareArbeid;
      break;
    case PartijProduct.OnafhankelijkAo:
      dekkingGegevensVerzekerde.rubriekA = {
        ...dekkingVerzekerdeRubriekASchema.default(),
        jaarlijkseIndexatieVan: AovJaarlijkseIndexatieVan.Uitkering,
        uitkeringGedeeltelijkAo: AoUitkeringswijze.Volledig,
        beoordelingscriterium: AoBeoordelingscriterium.GangbareArbeid
      };
      dekkingGegevensVerzekerde.rubriekB = {
        ...dekkingVerzekerdeRubriekBSchema.default(),
        beoordelingscriterium: AoBeoordelingscriterium.PassendeArbeid
      };
      dekkingGegevensVerzekerde.dekking = null;
      break;
    case PartijProduct.TafCreditLife:
    case PartijProduct.TafQuantumLeben:
    case PartijProduct.TafMaandlastenbeschermerIPTIQ:
      dekkingGegevensVerzekerde.ao = {
        ...dekkingGegevensAoSchema.default(),
        eigenRisico: AovEigenRisicoOptions.Dagen180,
        uitkeringGedeeltelijkAo: AoUitkeringswijze.Volledig
      };
      dekkingGegevensVerzekerde.beoordelingscriterium = AoBeoordelingscriterium.GangbareArbeid;
      break;
    case PartijProduct.DazureLastenbeschermer:
      dekkingGegevensVerzekerde.ao = {
        ...dekkingGegevensAoSchema.default(),
        eigenRisico: AovEigenRisicoOptions.Dagen180,
        uitkeringGedeeltelijkAo: AoUitkeringswijze.Volledig
      };
      dekkingGegevensVerzekerde.beoordelingscriterium = AoBeoordelingscriterium.Beroeps;
      break;
      case PartijProduct.DazureWoonlasten:
      dekkingGegevensVerzekerde.ao = {
        ...dekkingGegevensAoSchema.default(),
        eigenRisico: AovEigenRisicoOptions.Dagen730,
        uitkeringGedeeltelijkAo: AoUitkeringswijze.Volledig
      };
      dekkingGegevensVerzekerde.beoordelingscriterium = AoBeoordelingscriterium.GangbareArbeid;
      break;
  }
  return dekkingGegevensVerzekerde;
};

export const getPremiegegevensDefaults = (partijProduct: PartijProduct): PremiegegevensType => {
  const premiegegevens = premiegegevensSchema.default();
  premiegegevens.betalingsTermijn = PremieBetalingstermijn.Maand;
  switch (partijProduct) {
    case PartijProduct.OnafhankelijkWoonlasten:
      premiegegevens.betalingMiddels = AovBetalingMiddelsOptions.Maandpremie;
      premiegegevens.betalingsTermijn = PremieBetalingstermijn.Maand;
      break;
    case PartijProduct.TafInkomensBeschermer:
    case PartijProduct.TafInkomensbeschermerIPTIQ:
    case PartijProduct.TafMaandlastenbeschermerZelfstandige:
      premiegegevens.premieAftrekbaarAO = true;
      break;
    case PartijProduct.TafCreditLife:
    case PartijProduct.TafQuantumLeben:
    case PartijProduct.TafMaandlastenbeschermerIPTIQ:
      premiegegevens.premieAftrekbaarAO = true;
      break;
    case PartijProduct.CallasInkomensGarantie:
    case PartijProduct.CreditLifeInkomensgarantieOngevallen:
      premiegegevens.premieAftrekbaarAO = true;
      break;
    case PartijProduct.OnafhankelijkIgv:
      premiegegevens.betalingMiddels = AovBetalingMiddelsOptions.Maandpremie;
      premiegegevens.betalingsTermijn = PremieBetalingstermijn.Maand;
      premiegegevens.premieAftrekbaarAO = true;
      break;
    case PartijProduct.OnafhankelijkHav:
      premiegegevens.betalingMiddels = AovBetalingMiddelsOptions.Maandpremie;
      premiegegevens.betalingsTermijn = PremieBetalingstermijn.Maand;
      break;
    case PartijProduct.OnafhankelijkWgaGat:
      premiegegevens.collectief = false;
      break;
    case PartijProduct.OnafhankelijkWiaExcedent:
      premiegegevens.collectief = false;
      break;
  }
  return premiegegevens;
};

export const getProductSoortDefaults = (partijProduct: PartijProduct): AovSoortProductOptions => {
  switch (partijProduct) {
    case PartijProduct.OnafhankelijkWoonlasten:
      return AovSoortProductOptions.Woonlastenverzekering;
    case PartijProduct.OnafhankelijkAo:
      return AovSoortProductOptions.Arbeidsongeschiktheidverzekering;
    case PartijProduct.OnafhankelijkWgaGat:
      return AovSoortProductOptions.WgaGatVerzekering;
    case PartijProduct.OnafhankelijkWiaExcedent:
      return AovSoortProductOptions.WiaExcedentVerzekering;
    case PartijProduct.OnafhankelijkIgv:
      return AovSoortProductOptions.Inkomensgarantieverzekering;
    case PartijProduct.OnafhankelijkHav:
      return AovSoortProductOptions.Hypotheekaflosverzekering;
    default:
      return AovSoortProductOptions.Product;
  }
};
