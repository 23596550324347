import React, { ReactElement } from "react";
import { FormikProps } from "formik";
import { getFinancieringsbehoefteTextResources } from "../infra/financieringsbehoefte-resources";
import { advieskostenBemiddelingsvergoedingSideEffects } from "./advieskosten-bemiddelingsvergoeding-modal-side-effects";
import { ISWSideEffects } from "../../shared/components/isw-side-effects/isw-side-effects";
import { LabeledCurrencyInput, ResetButton, LabeledBevestigingInput, LabeledNumberInput } from "adviesbox-shared";
import AdviesBoxFormik from "../../shared/utils/adviesbox-formik";
import Modal from "../../shared/components/modal/Modal";
import { AdvieskostenBemiddelingsvergoedingModal as AdvieskostenBemiddelingsvergoedingModalState } from "../infra/financieringsbehoefte-types";
import { advieskostenBemiddelingsvergoedingModalSchema } from "../infra/financieringsbehoefte-schema";

export type AdvieskostenBemiddelingsvergoedingModalData = {
  data: AdvieskostenBemiddelingsvergoedingModalState;
};

type AdvieskostenBemiddelingsvergoedingModalProps = {
  onSave?: (data: AdvieskostenBemiddelingsvergoedingModalState) => void;
  closeModal?: () => void;
};

const AdvieskostenBemiddelingsvergoedingModal = ({
  data,
  onSave,
  closeModal
}: AdvieskostenBemiddelingsvergoedingModalData & AdvieskostenBemiddelingsvergoedingModalProps): ReactElement => {
  const body = (values: AdvieskostenBemiddelingsvergoedingModalState): ReactElement => (
    <>
      <LabeledCurrencyInput
        caption="Advieskosten"
        name="advieskosten"
        readonly={true}
        tooltip={getFinancieringsbehoefteTextResources("TooltipAdvieskosten")}
      />
      <LabeledCurrencyInput
        caption="Bemiddelingsvergoeding geldlening"
        name="bemiddelingsvergoedingGeldlening"
        readonly={true}
        tooltip={getFinancieringsbehoefteTextResources("TooltipBemiddelingsvergoeding")}
      />

      <LabeledCurrencyInput
        caption="Overige bemiddelingsvergoeding"
        name="overigeBemiddelingsvergoeding"
        readonly={true}
        tooltip={getFinancieringsbehoefteTextResources("TooltipOverigeBemiddeling")}
      />
      <LabeledCurrencyInput
        caption="Dossiervergoeding geldlening"
        name="dossiervergoedingGeldlening"
        readonly={true}
        tooltip={getFinancieringsbehoefteTextResources("TooltipDossiervergoeding")}
      />
      <LabeledBevestigingInput caption="Gespreid betalen" name="gespreidBetalen" />
      {values.gespreidBetalen && (
        <>
          <LabeledCurrencyInput
            caption="Advieskosten"
            name="advieskostenGespreid"
            appendChildren={<ResetButton name="advieskostenGespreid" defaultValue={values.advieskosten} />}
          />
          <LabeledCurrencyInput
            caption="Bemiddelingsvergoeding geldlening"
            name="bemiddelingsvergoedingGeldleningGespreid"
            appendChildren={
              <ResetButton
                name="bemiddelingsvergoedingGeldleningGespreid"
                defaultValue={values.bemiddelingsvergoedingGeldlening}
              />
            }
          />
          <LabeledCurrencyInput
            caption="Overige bemiddelingsvergoeding"
            name="overigeBemiddelingsvergoedingGespreid"
            appendChildren={
              <ResetButton
                name="overigeBemiddelingsvergoedingGespreid"
                defaultValue={values.overigeBemiddelingsvergoeding}
              />
            }
          />

          <LabeledNumberInput
            caption="Gespreid betalen over"
            name="gespreidBetalenOver"
            appendChildren={<span>maanden</span>}
          />
        </>
      )}
    </>
  );
  return (
    <AdviesBoxFormik<AdvieskostenBemiddelingsvergoedingModalState>
      initialValues={{ ...data }}
      validationSchema={advieskostenBemiddelingsvergoedingModalSchema}
      onSave={onSave}
      closeModal={closeModal}
      render={({ values, submitForm }: FormikProps<AdvieskostenBemiddelingsvergoedingModalState>): ReactElement => (
        <>
          <ISWSideEffects<AdvieskostenBemiddelingsvergoedingModalState>
            sync={advieskostenBemiddelingsvergoedingSideEffects}
          />

          <Modal
            title="Advieskosten / bemiddelingsvergoeding"
            body={body(values)}
            onSubmitClick={submitForm}
            onCancelClick={closeModal}
          />
        </>
      )}
    />
  );
};

export default AdvieskostenBemiddelingsvergoedingModal;
