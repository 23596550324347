import { Card, CardWrapper, hasValue } from "adviesbox-shared";
import { connect, FormikContextType } from "formik";
import React, { ReactElement, useMemo } from "react";
import { Button } from "react-bootstrap";
import { GebruikPandSoort, Financieringsoort, AflossingsVormType } from "../.generated/forms/formstypes";
import { HypotheeklabelDetailsOutput } from "../.generated/producten/productentypes";
import Fiscalegegevens from "../producten-overzicht/fiscalegegevens/fiscalegegevens";
import { HypothekenKenmerken } from "../producten-overzicht/infra/product-kenmerken-types";
import { SituatieSoort } from "../producten-overzicht/infra/producten-overzicht-types";
import Product from "../producten-overzicht/product/product";
import { useHypotheekrentesData } from "../shared/hooks/use-hypotheekrentes-data";
import { UseHypotheekvormenResult } from "../shared/hooks/use-hypotheekvorm-data";
import GekoppeldProduct from "./gekoppeld-product/gekoppeld-product";
import HypotheekProductDetails from "./hypotheek-product-details/hypotheek-product-details";
import { HypothekenState, showEdit, SoortOnderpand } from "./infra/hypotheek-types";
import Leningdeelgegevens from "./leningdeelgegevens/leningdeelgegevens";
import Opmerking from "./opmerking/opmerking";
import { Opnames } from "./opnames/opnames";
import Schuldenaars from "./schuldenaars/schuldenaars";
import SpaarProduct from "./spaar-product/spaar-product";
import { getGelinktPand } from "./infra/hypotheek-utils";

type HypotheekDetailProps = {
  selected: number;
  berekenState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  situatie: SituatieSoort;
  kenmerken: HypothekenKenmerken;
  hypotheekvormen: UseHypotheekvormenResult;
  hypotheekDetailData: HypotheeklabelDetailsOutput | null;
  setShowWijzigen: React.Dispatch<React.SetStateAction<showEdit>>;
};

const HypotheekDetails = ({
  selected,
  berekenState,
  situatie,
  kenmerken,
  hypotheekvormen,
  hypotheekDetailData,
  setShowWijzigen,
  formik: {
    values: { producten, panden, eigenwoningschuldBedrag, nhg, soortFinanciering }
  }
}: HypotheekDetailProps & { formik: FormikContextType<HypothekenState> }): ReactElement => {
  const selectedProduct = producten && producten[selected];
  const soortOnderpand =
    selectedProduct.soortOnderpand === Financieringsoort.AankoopBestaandeBouw
      ? SoortOnderpand.BestaandeBouw
      : selectedProduct.soortOnderpand === Financieringsoort.AankoopNieuwbouw
      ? SoortOnderpand.Nieuwbouw
      : SoortOnderpand.Geen;

  const marktwaardePercentage = useMemo(() => {
    return panden.find(p => p)?.bevoorschottingspercentage ?? 100;
  }, [panden]);
  const hypotheekVorm = useMemo(() => {
    return hypotheekvormen?.hypotheekvormen;
  }, [hypotheekvormen]);

  const renteboxCode = useMemo(() => {
    if (hasValue(selectedProduct.product.renteboxCode)) return selectedProduct.product.renteboxCode;
    if (!hypotheekVorm) return null;
    const foundRenteboxCode = Object.values(hypotheekVorm).find(c => c.code === selectedProduct.hypotheekVorm.code)
      ?.renteboxCode;
    if (foundRenteboxCode === undefined) return null;
    return foundRenteboxCode;
  }, [selectedProduct.product.renteboxCode, hypotheekVorm, selectedProduct.hypotheekVorm.code]);

  const renteboxMaatschappijCode = useMemo(() => {
    return (
      selectedProduct.renteBoxMaatschappijCode ||
      selectedProduct.product.partijCodeSelectie ||
      selectedProduct.partijCode
    );
  }, [
    selectedProduct.renteBoxMaatschappijCode,
    selectedProduct.product.partijCodeSelectie,
    selectedProduct.partijCode
  ]);

  const { rentevarianten } = useHypotheekrentesData(
    renteboxMaatschappijCode,
    selectedProduct.labelCode,
    soortOnderpand,
    marktwaardePercentage,
    nhg || false,
    renteboxCode
  );

  const gelinktePand = useMemo(() => getGelinktPand(situatie, selectedProduct, panden), [
    situatie,
    selectedProduct,
    panden
  ]);

  const isTweedeWoning = useMemo(() => gelinktePand?.gebruikPand === GebruikPandSoort.TweedeWoning, [gelinktePand]);

  const aflosvormIsAnnuiteitBlok = selectedProduct.hypotheekVorm.aflossingsvorm === AflossingsVormType.AnnuïteitBlok;
  const isBankspaar = selectedProduct.hypotheekVorm.aflossingsvorm === AflossingsVormType.Spaarrekening;
  const isOpnameMogelijk = selectedProduct.hypotheekVorm.aflossingsvorm === AflossingsVormType.KredietNoPay;
  const isPeriodiekeOpnameMogelijk = kenmerken.leninggegeven.heeftPeriodiekeOpname;
  return (
    <div className="d-flex flex-wrap flex-row flex-grow-1">
      <CardWrapper className="px-3" maxRowCount={7}>
        <Card
          title={
            situatie === "voorstel"
              ? `${selectedProduct.product.doorlopend ? "Doorlopend" : "Nieuw"} product`
              : "Huidig product"
          }
        >
          <Product
            selected={selected}
            situatie={situatie}
            kenmerken={kenmerken.product}
            isStarterslening={selectedProduct.hypotheekVorm.isStartersLening}
            productSoort="hypotheek"
          />
          {soortFinanciering === Financieringsoort.Omzetting && selectedProduct.product.doorlopend && (
            <div className={" button-container"}>
              <Button
                onClick={() =>
                  setShowWijzigen({
                    edit: true,
                    productOnly: false,
                    visible: true,
                    omzetten: true
                  })
                }
              >
                Leningdeel omzetten
              </Button>
            </div>
          )}
        </Card>
        {situatie === "huidig" && (
          <>
            <Card title="Productgegevens">
              <HypotheekProductDetails selected={selected} />
            </Card>

            <Card title="Schuldenaars">
              <Schuldenaars selected={selected} />
            </Card>
          </>
        )}
      </CardWrapper>
      <CardWrapper className="px-3 mb-5 pb-5" maxRowCount={7}>
        <Card title="Leningdeelgegevens">
          <Leningdeelgegevens
            renteVarianten={rentevarianten}
            selected={selected}
            situatie={situatie}
            hypotheekDetailData={hypotheekDetailData}
            kenmerken={kenmerken.leninggegeven}
          />
        </Card>
        <Card title="Fiscale gegevens">
          <Fiscalegegevens
            selected={selected}
            situatie={situatie}
            aflosvormIsAnnuiteitBlok={aflosvormIsAnnuiteitBlok}
            isTweedeWoning={isTweedeWoning}
            eigenWoningSchuldBedrag={eigenwoningschuldBedrag}
            leningdeelBedrag={selectedProduct.leningdeelgegevens.leningdeelHoofdsom.bedrag}
            consumptiefBedragTonen
          />
        </Card>
        {isOpnameMogelijk && situatie === "voorstel" && (
          <Opnames selected={selected} periodiekeOpname={isPeriodiekeOpnameMogelijk} />
        )}
        {isBankspaar && situatie === "voorstel" && (
          <SpaarProduct selected={selected} kenmerken={kenmerken} berekenState={berekenState} />
        )}

        {kenmerken.gekoppeldProduct.gekoppeldProductTonen && (
          <Card title="Gekoppeld product">
            <GekoppeldProduct kenmerken={kenmerken.gekoppeldProduct} />
          </Card>
        )}

        {kenmerken.opmerking.opmerkingTonen && (
          <Card title="Opmerking">
            <Opmerking kenmerken={kenmerken.opmerking} />
          </Card>
        )}
      </CardWrapper>
    </div>
  );
};

export default connect<HypotheekDetailProps, HypothekenState>(HypotheekDetails);
