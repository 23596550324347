import { useRequestInit } from "adviesbox-shared";
import { useFormikContext } from "formik";
import React, { ReactElement, useCallback, useContext, useEffect, useRef, useState } from "react";
import { AflossingsVormType } from "../../.generated/forms/formstypes";
import {
  HypotheekvergelijkerStatusOptions,
  UitslagOptions
} from "../../.generated/hypotheek-vergelijker/hypotheek-vergelijkertypes";
import { OrganisatieEx } from "../../.generated/licenties/licentiestypes";
import UserDetailsContext from "../../shared/user-details/user-details-context";
import { HypotheekResultaatType, HypotheekVergelijkenModalType } from "./hypotheek-vergelijken-types";
import classes from "./hypotheek-vergelijken.module.scss";
import { HypotheekVergelijkerResultatenDataGrid } from "./hypotheek-vergelijker-data-grid/hypotheek-vergelijker-resultaat-data-grid";
import {
  getHypotheekVergelijkerQueue,
  initHypotheekVergelijkerQueue,
  organisatieDataApi
} from "./infra/hypotheek-vergelijken-vergelijker-api";
import { mapHypotheekVergelijkerUiToDl } from "./infra/map-hypotheek-genereer-leningdelen-ui-2-dl";

export const Stap3 = (): ReactElement => {
  const { userDetails } = useContext(UserDetailsContext);
  const usersOrganisatieId = userDetails.organisatieId ? userDetails.organisatieId : null;
  const { values, setFieldValue } = useFormikContext<HypotheekVergelijkenModalType>();
  const { settings, params, user } = useRequestInit<{ vestiging: string; adviesdossier: string; voorstel: string }>();
  const [fetching, setFetching] = useState(false);
  const [fetchComplete, setFetchComplete] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const initialValues = useRef<typeof values>(values);
  const prevResults = useRef<HypotheekResultaatType[]>([]);
  const timer = useRef<NodeJS.Timer | null>(null);
  const queueAbort = useRef(new AbortController());
  const fetchAbort = useRef(new AbortController());
  const [initialCallCalled, setInitialCallCalled] = useState(false);

  const sorteerBinnengekomenResultaten = (a: HypotheekResultaatType, b: HypotheekResultaatType): number => {
    const maxHypInterneSorteringen = (): number => {
      switch (true) {
        case a.UitslagVoorwaarden === b.UitslagVoorwaarden:
          return a.NettoMaandlastAanvangBedrag - b.NettoMaandlastAanvangBedrag;
        case a.UitslagVoorwaarden === UitslagOptions.Groen:
          return -1;
        case b.UitslagVoorwaarden === UitslagOptions.Groen:
          return 1;
        case a.UitslagVoorwaarden === UitslagOptions.Rood:
          return 1;
        case b.UitslagVoorwaarden === UitslagOptions.Rood:
          return -1;
        default:
          return a.NettoMaandlastAanvangBedrag - b.NettoMaandlastAanvangBedrag;
      }
    };

    switch (true) {
      case a.UitslagMaximaleHypotheek === b.UitslagMaximaleHypotheek:
        return maxHypInterneSorteringen();
      case a.UitslagMaximaleHypotheek === UitslagOptions.Groen:
        return -1;
      case b.UitslagMaximaleHypotheek === UitslagOptions.Groen:
        return 1;
      case a.UitslagMaximaleHypotheek === UitslagOptions.Rood:
        return 1;
      case b.UitslagMaximaleHypotheek === UitslagOptions.Rood:
        return -1;
      default:
        return 0;
    }
  };

  const setTimer = (queueId: string, selectedResult?: boolean): void => {
    setFetching(true);
    timer.current = setTimeout(async (): Promise<void> => {
      if (!user) return;
      const newController = new AbortController();
      fetchAbort.current.abort();
      fetchAbort.current = newController;

      const res = await getHypotheekVergelijkerQueue(user, settings, params.vestiging, queueId, newController.signal);

      if (!res || !res.resultaten) throw Error("no data received");
      if (!res.resultaten.length && res.status === HypotheekvergelijkerStatusOptions.Bezig) {
        setTimer(queueId);
        return;
      }

      const currResults = res.resultaten
        .filter(r => !!r)
        .map(
          (r): HypotheekResultaatType => {
            const hypotheekvorm = r.leningdelen?.find(_ => true)?.hypotheekvorm;

            return {
              AutomatischeRentedalingDirect: r.automatischeRentedalingDirect ?? true,
              AutomatischeRentedalingNaRvp: r.automatischeRentedalingNaRvp ?? true,
              BrutoMaandlastAanvangBedrag: r.brutoMaandlastAanvangBedrag ?? 0,
              IndicatieMeerLenenBedrag: r.indicatieMeerLenenBedrag ?? 0,
              MaatschappijCode: r.maatschappijCode ?? "",
              MaatschappijNaam: r.maatschappijNaam ?? "",
              leningdelen:
                r.leningdelen?.map(
                  /* istanbul ignore next */ ld => ({
                    aflossingsvorm: ld.hypotheekvorm?.aflossingsvorm ?? AflossingsVormType.Annuïteit,
                    code: ld.hypotheekvorm?.code ?? 1,
                    omschrijving: ld.hypotheekvorm?.omschrijving ?? "",
                    isStartersLening: ld.hypotheekvorm?.starterslening ?? false,
                    isRestschuldLening: ld.hypotheekvorm?.restschuldFinanciering ?? false,
                    renteboxCode: ld.hypotheekvorm?.renteboxCode ?? 0
                  })
                ) ?? null,
              MaximaleHypotheekBedrag: r.maximaleHypotheekBedrag ?? 0,
              MaximaleHypotheekNhgBedrag: r.maximaleHypotheekNhgBedrag ?? 0,
              MaximaleHypotheekOnderpandBedrag: r.maximaleHypotheekOnderpandBedrag ?? 0,
              NettoLastTotaalBedrag: r.nettoLastTotaalBedrag ?? 0,
              hypotheekvormCode: hypotheekvorm?.code ?? 0,
              NettoMaandlastAanvangBedrag: r.nettoMaandlastAanvangBedrag ?? 0,
              ProductCode: r.productCode ?? 0,
              Productnaam: r.productnaam ?? "",
              ToetsrentePercentage: r.toetsrentePercentage ?? 0,
              hypotheekomschrijving: hypotheekvorm?.omschrijving ?? "",
              aflossingsVorm: hypotheekvorm?.aflossingsvorm ?? AflossingsVormType.Annuïteit,
              UitslagMaximaleHypotheek: r.uitslagMaximaleHypotheek ?? UitslagOptions.Rood,
              UitslagVoorwaarden: r.uitslagVoorwaarden ?? UitslagOptions.Rood,
              VerplichteAflossingBedrag: r.verplichteAflossingBedrag ?? 0,
              VoorwaardenToelichtingregels: { Toelichting: r.voorwaardenToelichtingregels?.pop()?.toelichting ?? "" },
              WerkelijkRentePercentage: r.werkelijkRentePercentage ?? 0
            };
          }
        )
        .concat(prevResults.current);

      const sortedResultaten = currResults.sort(sorteerBinnengekomenResultaten);

      if (sortedResultaten.length && !selectedResult) {
        setFieldValue(
          "selectedResultaat",
          sortedResultaten.find(_ => true)
        );
      }

      setFieldValue("resultaten", sortedResultaten);
      prevResults.current = sortedResultaten;

      if (res.status === HypotheekvergelijkerStatusOptions.Bezig) {
        setTimer(queueId, !!sortedResultaten.length);
        return;
      }

      setFetching(false);
      setFetchComplete(true);
    }, 2500);
  };

  const getVergelijkerResults = async (): Promise<void> => {
    if (!user) return;
    setErrorMessage(null);
    const newController = new AbortController();
    queueAbort.current.abort();
    queueAbort.current = newController;
    setFetchComplete(false);
    setFetching(true);

    const org: OrganisatieEx = await organisatieDataApi(settings, user, usersOrganisatieId);

    const res = await initHypotheekVergelijkerQueue(
      user,
      settings,
      params.vestiging,
      params.voorstel,
      mapHypotheekVergelijkerUiToDl(initialValues.current),
      newController.signal
    );

    if (typeof res === "string") {
      setErrorMessage(res);
      return;
    }

    if (!res || !org || !res.queueId || typeof res.aantalResultaten !== "number") throw Error("no data received");

    setFieldValue("verwachteTotaal", res.aantalResultaten);
    setFieldValue("organisatieData", org);
    setTimer(res.queueId, true);
  };

  const init = (): void => {
    /* eslint-disable-next-line @typescript-eslint/no-floating-promises */
    getVergelijkerResults();
  };

  const cleanup = useCallback((): void => {
    if (timer.current) {
      clearTimeout(timer.current);
    }

    queueAbort.current.abort();
    fetchAbort.current.abort();
    setFieldValue("resultaten", []);
  }, [setFieldValue]);

  useEffect((): typeof cleanup => cleanup, [cleanup]);

  if (!initialCallCalled) {
    init();
    setInitialCallCalled(true);
  }

  return (
    <div className={"mt-5"}>
      {errorMessage && <span className={classes.errorMessage}>{errorMessage}</span>}
      {!fetchComplete && !errorMessage && (
        <div className={"button-container"}>
          <button
            data-testid="btn-fetcher"
            type="button"
            id="fetch-button"
            className={`btn btn-primary ${classes.transition} ${fetching && classes.active} ${classes.btn_loader}`}
          >
            <div className={classes.loader}></div>
            <div className={classes.display_text}>
              {values.resultaten.length} /&nbsp;
              {values.verwachteTotaal}
            </div>
          </button>
        </div>
      )}
      {!!values.resultaten.length && <HypotheekVergelijkerResultatenDataGrid />}
      {!values.nhg && (
        <div className="mt-4 mb-5 pb-5">
          <span className={"d-block"}>
            Aan dit overzicht kunnen geen rechten worden ontleend. Dit overzicht is onder voorbehoud van goedkeuring
            door de maatschappij.
          </span>
          <span className={"d-block mt-2"}>
            * Rente daalt na de rentevastperiode automatisch bij lagere schuld-marktwaarde verhouding.
          </span>
          <span className={"d-block"}>** Rente daalt direct bij lagere schuld-marktwaardeverhouding</span>
        </div>
      )}
    </div>
  );
};
