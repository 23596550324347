import React, { ReactElement } from "react";
import { LocalDate } from "@js-joda/core";
import { connect, FormikContextType, getIn } from "formik";
import {
  LabeledRadioInput,
  LabeledSelectInput,
  LabeledBevestigingInput,
  LabeledNumberInput,
  LabeledPercentageInput,
  LabeledJaarMaandInput,
  BerekenCurrencyInput,
  LabeledResult
} from "adviesbox-shared";

import {
  BelastingBoxOptions,
  SoortLijfrenteUitkeringOptions,
  BetalingsTermijnType
} from "../../.generated/forms/formstypes";

import { AanvragerKeuze } from "../../shared/types";

import { IndicatieveUitkerendeFaseKenmerken } from "../infra/product-kenmerken-types";
import { JaarMaandInputType } from "../../shared/generic-parts/jaar-maand/schema";
import { jaarMaandInMaanden } from "../../shared/generic-parts/jaar-maand/map-ui-2-dl";
import { getFormattedDate } from "../../shared/utils/dates";
import { bedragFormat } from "../../shared/utils/currency";
import verhoudingStylingClass from "../verhouding.module.scss";

const termijnOptions = [
  { label: "Maand", value: BetalingsTermijnType.Maand },
  { label: "Kwartaal", value: BetalingsTermijnType.Kwartaal },
  { label: "Halfjaar", value: BetalingsTermijnType.HalfJaar },
  { label: "Jaar", value: BetalingsTermijnType.Jaar }
];

type IndicatieveUitkerendeFaseProps = {
  baseName: string;
  ingangsdatum: LocalDate | null;
  verzekerden: AanvragerKeuze;
  kenmerken: IndicatieveUitkerendeFaseKenmerken;
};

const IndicatieveUitkerendeFase = (
  props: { formik: FormikContextType<unknown> } & IndicatieveUitkerendeFaseProps
): ReactElement => {
  const {
    baseName,
    ingangsdatum,
    verzekerden,
    kenmerken,
    formik: { values }
  } = props;

  const soortLijfrenteUitkering = getIn(
    values,
    `${baseName}.soortLijfrenteUitkering`
  ) as SoortLijfrenteUitkeringOptions;
  const overgangOpTweedeVerzekerde = getIn(values, `${baseName}.overgangOpTweedeVerzekerde`) as boolean;
  const lijfrenteUitkering = getIn(values, `${baseName}.lijfrenteUitkering.bedrag`) as number;
  const hoogLaagVerhouding = getIn(values, `${baseName}.hoogLaagVerhouding`) as number;
  const duurUitkeringHoog = getIn(values, `${baseName}.duurUitkeringHoog`) as JaarMaandInputType;
  const duurUitkeringHoogInMaanden = jaarMaandInMaanden(duurUitkeringHoog);

  let eindDatumUitkering = "";
  let eindDatumUitkeringHoog = "";
  if (ingangsdatum) {
    const duurUitkering = getIn(values, `${baseName}.duurUitkering`) as JaarMaandInputType;
    const duurUitkeringInMaanden = jaarMaandInMaanden(duurUitkering);
    if (duurUitkeringInMaanden) {
      eindDatumUitkering = getFormattedDate(ingangsdatum.plusMonths(duurUitkeringInMaanden));
    }

    if (duurUitkeringHoogInMaanden) {
      eindDatumUitkeringHoog = getFormattedDate(ingangsdatum.plusMonths(duurUitkeringHoogInMaanden));
    }
  }

  return (
    <>
      {kenmerken.belastingBoxTonen && (
        <LabeledRadioInput
          caption="Valt fiscaal in"
          name={`${baseName}.belastingBox`}
          options={[
            { label: "Box 1", value: BelastingBoxOptions.Box1 },
            { label: "Box 3", value: BelastingBoxOptions.Box3 }
          ]}
        />
      )}

      {kenmerken.soortLijfrenteUitkeringTonen && (
        <LabeledRadioInput
          caption="Soort lijfrente"
          name={`${baseName}.soortLijfrenteUitkering`}
          options={[
            { label: "Levenslange lijfrente", value: SoortLijfrenteUitkeringOptions.Levenslang },
            { label: "Tijdelijke lijfrente", value: SoortLijfrenteUitkeringOptions.Tijdelijk }
          ]}
        />
      )}

      {soortLijfrenteUitkering !== SoortLijfrenteUitkeringOptions.Levenslang && (
        <>
          <LabeledJaarMaandInput caption="Duur uitkering" name={`${baseName}.duurUitkering`} />
          {eindDatumUitkering && (
            <LabeledResult
              name={`${baseName}.eindDatumUitkering`}
              caption="Einddatum uitkering"
              result={(): string => eindDatumUitkering}
              readonly
            />
          )}
        </>
      )}

      <LabeledJaarMaandInput caption="Duur uitkering hoog" name={`${baseName}.duurUitkeringHoog`} />
      {eindDatumUitkeringHoog && (
        <LabeledResult
          name={`${baseName}.eindDatumUitkeringHoog`}
          caption="Einddatum uitkering hoog"
          result={(): string => eindDatumUitkeringHoog}
          readonly
        />
      )}

      {!!duurUitkeringHoogInMaanden && (
        <LabeledNumberInput
          caption="Verhouding hoog / laag"
          name={`${baseName}.hoogLaagVerhouding`}
          prependChildren={
              <input type="text" className={`${ verhoudingStylingClass.input_control}`} value="1 : " readOnly={true} />
          }
          decimalen={2}
        />
      )}

      <LabeledPercentageInput caption="Gehanteerd lijfrentetarief" name={`${baseName}.lijfrenteTarief`} decimalen={2} />

      {kenmerken.overgangOpTweedeVerzekerdeTonen && verzekerden === AanvragerKeuze.Beiden && (
        <>
          <LabeledBevestigingInput
            caption="Overgang op tweede verzekerde"
            name={`${baseName}.overgangOpTweedeVerzekerde`}
          />

          {overgangOpTweedeVerzekerde && (
            <LabeledPercentageInput
              caption="Overgangspercentage"
              name={`${baseName}.overgangOpTweedeVerzekerdePercentage`}
              decimalen={2}
            />
          )}
        </>
      )}

      <BerekenCurrencyInput caption="Lijfrente-uitkering" name={`${baseName}.lijfrenteUitkering`} decimalen={2} />

      <LabeledSelectInput caption="Termijn" name={`${baseName}.termijn`} options={termijnOptions} />

      {!!duurUitkeringHoogInMaanden && (
        <LabeledResult
          caption="Uitkering hoog"
          name={`${baseName}.lijfrenteUitkeringHoog`}
          result={(): string => bedragFormat(hoogLaagVerhouding * lijfrenteUitkering, 2, Number.MAX_VALUE)}
          readonly
        />
      )}
    </>
  );
};

export default connect<IndicatieveUitkerendeFaseProps, unknown>(IndicatieveUitkerendeFase);
