import { getNaamAdviseur } from "adviesbox-shared";
import React, { ReactElement, useContext } from "react";
import UserDetailsContext from "../shared/user-details/user-details-context";

const AdviseurNaam = (): ReactElement | null => {
  const { userDetails } = useContext(UserDetailsContext);
  /*istanbul ignore next*/ if (!userDetails) {
    return null;
  }

  return userDetails && <span>{getNaamAdviseur(userDetails)}</span>;
};

export default AdviseurNaam;
