import { Notarissen } from "../../.generated/instellingen-forms/instellingen-formstypes";
import { createISWSideEffect, initISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";
import { BankgarantieState } from "./bankgarantie-schema";
import { mapAdresInstellingen } from "./map-bankgarantie-dl-to-ui";

export type bankgarantieSideEffectsContext = {
  notarissen: Notarissen;
};

const bankgarantieSideEffects = createISWSideEffect<BankgarantieState, bankgarantieSideEffectsContext>((bag): void => {
  const { has, draft, context } = bag;
  if (has.notaris.gekozenNotariskantoorId.changed && draft.notaris.gekozenNotariskantoorId !== "Anders") {
    const selectedNotaris = context.notarissen.partijen?.find(
      x => x.notariskantoorId === draft.notaris.gekozenNotariskantoorId
    );
    draft.notaris.adres = mapAdresInstellingen(selectedNotaris?.adres);
    draft.notaris.kantoornaam = selectedNotaris?.kantoornaam ?? "";
    draft.notaris.telefoonnummer = selectedNotaris?.telefoonnummer ?? "";
  }

  if (has.notaris.gekozenNotariskantoorId.changed && draft.notaris.gekozenNotariskantoorId === "Anders") {
    draft.notaris.adres = mapAdresInstellingen(null);
    draft.notaris.kantoornaam = "";
    draft.notaris.telefoonnummer = "";
  }

  if (
    has.bankgarantieKaart.garantiebedrag.changed &&
    draft.bankgarantieKaart.garantieKostenberekenen.berekenen &&
    draft.bankgarantieKaart.garantiebedrag
  ) {
    draft.bankgarantieKaart.garantieKostenberekenen.berekendBedrag = draft.bankgarantieKaart.garantiebedrag * 0.01;
  }

  if (has.product.instelling.changed && draft.product.instelling) {
    draft.bankgarantieKaart.readOnlyInstelling = draft.product.instelling;
  }
});

export const syncBankgarantieSideEffects = initISWSideEffect<BankgarantieState, bankgarantieSideEffectsContext>(bag => {
  const { context, subset } = bag;
  bankgarantieSideEffects(subset.createWithContext(context));
});
