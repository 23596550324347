import {
  Icon,
  LabeledCurrencyInput,
  LabeledDateInput,
  ModalButton,
  PercentageInput,
  TooltipWrap
} from "adviesbox-shared";
import { connect, FormikContextType } from "formik";
import React, { ReactElement, useCallback } from "react";
import SpecificatieRenteaftrekModal from "../../hypotheek/specificatie-renteaftrek-modal/specificatie-renteaftrek-modal";
import { bindSaveFunction } from "../../shared/utils/bind-save-function";
import { optellen } from "../../shared/utils/currency";
import { getProductenOverzichtTextResources } from "../infra/producten-overzicht-resources";
import { ProductenState, SituatieSoort } from "../infra/producten-overzicht-types";

type FiscalegegevensProps = {
  selected: number;
  situatie: SituatieSoort;
  aflosvormIsAnnuiteitBlok: boolean;
  isTweedeWoning: boolean;
  eigenWoningSchuldBedrag: number | null;
  leningdeelBedrag: number | null;
  consumptiefBedragTonen?: boolean;
};

const Fiscalegegevens = ({
  selected,
  situatie,
  aflosvormIsAnnuiteitBlok,
  isTweedeWoning,
  leningdeelBedrag,
  eigenWoningSchuldBedrag,
  consumptiefBedragTonen,
  formik: {
    setFieldValue,
    values: { producten }
  }
}: FiscalegegevensProps & {
  formik: FormikContextType<ProductenState>;
}): ReactElement => {
  const createSaveFunction = useCallback(bindSaveFunction(setFieldValue), [setFieldValue]);
  const selectedProduct = producten[selected];
  const readonly = selectedProduct.product.doorlopend || aflosvormIsAnnuiteitBlok || isTweedeWoning;
  const box1Aanwezig = selectedProduct.fiscalegegevens.deelBox1Bedrag
    ? selectedProduct.fiscalegegevens.deelBox1Bedrag > 0
    : false;
  const box3Doorlopend =
    selectedProduct.product.doorlopend && selectedProduct.fiscalegegevens.deelBox3Percentage === 100;
  const renteAftrekken = selectedProduct.fiscalegegevens.renteaftrekSpecificatie.renteAftrekken;
  const meerdereRenteaftrekken = renteAftrekken && renteAftrekken.length > 1;
  const opgeteldeBox1Bedrag = optellen(
    producten.filter(c => c.product.doorlopend === false).map(c => c.fiscalegegevens.deelBox1Bedrag)
  );
  const box1HogerdanEigenWoningSchuld =
    situatie === "voorstel" && opgeteldeBox1Bedrag && eigenWoningSchuldBedrag
      ? Math.round(opgeteldeBox1Bedrag) !== Math.round(eigenWoningSchuldBedrag)
      : false;

  return (
    <>
      {!box3Doorlopend && (
        <LabeledCurrencyInput
          caption="Deel box 1"
          name={`producten[${selected}].fiscalegegevens.deelBox1Bedrag`}
          allowNull={false}
          readonly={readonly || meerdereRenteaftrekken}
          appendChildren={
            <div className="d-inline-flex px-1">
              <PercentageInput
                name={`producten[${selected}].fiscalegegevens.deelBox1Percentage`}
                decimalen={2}
                allowNull={false}
                readonly={readonly || meerdereRenteaftrekken}
              />

              {box1HogerdanEigenWoningSchuld && (
                <TooltipWrap
                  name={`deelBox1Bedrag`}
                  warningText={getProductenOverzichtTextResources("WarningBovenEigenWoningSchuld")}
                  placement="bottom"
                  iconType="waarschuwing"
                  tooltipClasses="verpanding-warning d-inline-flex"
                />
              )}
            </div>
          }
        />
      )}

      <LabeledCurrencyInput
        caption="Deel box 3"
        name={`producten[${selected}].fiscalegegevens.deelBox3Bedrag`}
        allowNull={false}
        readonly={readonly || meerdereRenteaftrekken}
        appendChildren={
          <div className="d-inline-flex px-1">
            <PercentageInput
              name={`producten[${selected}].fiscalegegevens.deelBox3Percentage`}
              decimalen={2}
              allowNull={false}
              readonly={readonly || meerdereRenteaftrekken}
            />
            {aflosvormIsAnnuiteitBlok && (
              <TooltipWrap
                name={`producten[${selected}].fiscalegegevens.deelBox3`}
                warningText={getProductenOverzichtTextResources("Box3NietMogelijk")}
                placement="bottom"
                iconType="waarschuwing"
                tooltipClasses="fiscalegegevens-warning d-inline-flex p-1"
              />
            )}
          </div>
        }
      />

      {consumptiefBedragTonen && producten[selected].fiscalegegevens.deelBox3Bedrag > 0 && (
        <LabeledCurrencyInput
          caption="Waarvan consumptief"
          name={`producten[${selected}].fiscalegegevens.consumptiefBedrag`}
        />
      )}

      {box1Aanwezig && (
        <>
          <LabeledDateInput
            caption="Begindatum renteaftrek"
            name={`producten[${selected}].fiscalegegevens.begindatumRenteaftrek`}
            readonly={readonly || meerdereRenteaftrekken}
            verplicht
            appendChildren={
              <ModalButton
                parent={`producten[${selected}].fiscalegegevens.renteaftrekSpecificatie`}
                size="lg"
                content={<Icon name="specificatie" alt="Specificatie renteaftrek" />}
                disabled={readonly}
              >
                <SpecificatieRenteaftrekModal
                  benodigdheden={{
                    leningdeelBedrag: leningdeelBedrag,
                    box1Bedrag: selectedProduct.fiscalegegevens.deelBox1Bedrag,
                    ingangsdatum: selectedProduct.product.ingangsdatum,
                    einddatum: selectedProduct.product.einddatum
                  }}
                  data={producten[selected].fiscalegegevens.renteaftrekSpecificatie}
                  onSave={createSaveFunction(`producten[${selected}].fiscalegegevens.renteaftrekSpecificatie`)}
                />
              </ModalButton>
            }
          />

          <LabeledDateInput
            caption="Einddatum renteaftrek"
            name={`producten[${selected}].fiscalegegevens.einddatumRenteaftrek`}
            readonly={readonly || meerdereRenteaftrekken}
            appendChildren={
              <>
                {meerdereRenteaftrekken && (
                  <TooltipWrap
                    name={`producten[${selected}].fiscalegegevens.einddatumRenteaftrek`}
                    warningText={getProductenOverzichtTextResources("WarningMeerdereRenteaftrekken")}
                    placement="bottom"
                    iconType="waarschuwing"
                    tooltipClasses="meerdere-renteaftrekken-warning d-inline-flex"
                  />
                )}
              </>
            }
          />
        </>
      )}
    </>
  );
};

export default connect<FiscalegegevensProps, ProductenState>(Fiscalegegevens);
