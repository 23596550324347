import { LabelValuePairs, LabelValuePair } from "../../../shared/types";
import {
  SelectieType,
  ProductSelectieType,
  AlleMogelijkeProductvormen,
  AlleMogelijkeProductBasisTypes
} from "../producten-overzicht-types";
import { prefixWithZero } from "../../../shared/utils/helpers";

const stringCompareNL = new Intl.Collator("nl").compare;

export function mapProductSelectie(
  data: { [key: string]: AlleMogelijkeProductBasisTypes } | null,
  filter: (product: AlleMogelijkeProductBasisTypes) => boolean = (): boolean => true
): SelectieType[] {
  if (!data) {
    throw new Error("Geen product data gevonden.");
  }
  const guids = Object.keys(data);

  const maatschappijen = guids
    .map((guid): AlleMogelijkeProductBasisTypes => data[guid])
    .filter(filter)
    .sort((a, b): number =>
      stringCompareNL(`${a.maatschappijNaamKort}-${a.productnaam}`, `${b.maatschappijNaamKort}-${b.productnaam}`)
    )
    .map(
      (product): SelectieType => {
        let productvorm: AlleMogelijkeProductvormen = null;
        if ("productvorm" in product) {
          productvorm = product.productvorm;
        } else if ("soortProduct" in product) {
          productvorm = product.soortProduct;
        } else if ("soortKredietProduct" in product) {
          productvorm = product.soortKredietProduct;
        } else if ("soortLijfrenteProduct" in product) {
          productvorm = product.soortLijfrenteProduct;
        }

        return {
          value: product.maatschappijCode ?? "",
          label: product.maatschappijNaamKort ?? "",
          producten: [
            {
              value: product.code ?? 0,
              label: product.productnaam ?? "",
              productvorm
            }
          ]
        };
      }
    )
    .reduce((previous: SelectieType[], current: SelectieType): SelectieType[] => {
      const maatschappij = previous.find((p): boolean => p.value === current.value);
      if (maatschappij) {
        maatschappij.producten.push(current.producten[0]);
      } else {
        previous.push(current);
      }

      return previous;
    }, []);

  return maatschappijen;
}

export function getProductParameters(
  producten: SelectieType[],
  maatschappijCode: string,
  productCode: string
): ProductSelectieType {
  const maatschappij = producten.find((c): boolean => c.value === maatschappijCode);
  const product = maatschappij
    ? maatschappij.producten.find((c): boolean => prefixWithZero(c.value) === productCode)
    : null;

  if (!maatschappij) throw new Error("Maatschappij kon niet gevonden worden.");
  if (!product) throw new Error("Product kon niet gevonden worden.");

  return {
    maatschappijNaam: maatschappij.label,
    productNaam: product.label,
    codes: {
      maatschappijCode: maatschappijCode,
      productCode: productCode,
      productVorm: product.productvorm
    }
  };
}

export function getMaatschappijLabel(verzekeringSelectie: SelectieType[], maatschappijCode: string): string {
  const maatschappij = verzekeringSelectie.find((c): boolean => c.value === maatschappijCode);

  if (maatschappij) return maatschappij.label;
  return "";
}

export function getProductLabel(
  verzekeringSelectie: SelectieType[],
  maatschappijCode: string,
  productCode: string
): string {
  const maatschappij = verzekeringSelectie.find((c): boolean => c.value === maatschappijCode);
  if (maatschappij === undefined) return "";

  const product = maatschappij.producten.find((c): boolean => prefixWithZero(c.value) === productCode);
  if (product) return product.label;
  return "";
}

export function getProducten(verzekeringSelectie: SelectieType[], maatschappijCode: string): LabelValuePairs {
  const maatschappij = verzekeringSelectie.find((c): boolean => c.value === maatschappijCode);
  if (maatschappij === undefined) return [];

  const productenLijst: LabelValuePairs = maatschappij.producten.map(
    (c): LabelValuePair => ({ label: c.label, value: prefixWithZero(c.value) })
  );

  return productenLijst;
}
