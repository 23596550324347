import React, { ReactElement } from "react";
import { connect, FormikContextType } from "formik";

import { LabeledRadioInput } from "adviesbox-shared";
import { getAanvragerOpties } from "../../producten-overzicht/infra/producten-helper";
import { KredietenType } from "../infra/kredieten-types";


type Props = { selected: number };

const Geldnemers = ({
  formik: {
    values: { aanvrager1, aanvrager2 }
  },
  selected
}: Props & {
  formik: FormikContextType<KredietenType>;
}): ReactElement => {
  const verzekeringnemersOpties = React.useMemo(() => getAanvragerOpties(aanvrager1, aanvrager2, true), [
    aanvrager1,
    aanvrager2
  ]);

  return (
    <>
      <LabeledRadioInput
        caption={verzekeringnemersOpties.length > 1 ? "Geldnemer(s)" : "Geldnemer"}
        name={`producten[${selected}].geldnemers.geldnemers`}
        options={verzekeringnemersOpties}
      />
    </>
  );
};

Geldnemers.displayName = "Geldnemers";

export default connect<Props, KredietenType>(Geldnemers);
