import React, { ReactElement } from "react";
import { connect, FormikContextType } from "formik";
import { LabeledRadioInput } from "adviesbox-shared";

import { getAanvragerOpties } from "../infra/producten-helper";
import { VerzekeringnemerKenmerken } from "../infra/product-kenmerken-types";
import { ProductenState } from "../infra/producten-overzicht-types";

type VerzekeringnemersProps = {
  selected: number;
  kenmerken: VerzekeringnemerKenmerken;
  caption?: string | null;
};

const Verzekeringnemers = ({
  selected,
  kenmerken,
  caption,
  formik: {
    values: { aanvrager1, aanvrager2 }
  }
}: VerzekeringnemersProps & {
  formik: FormikContextType<ProductenState>;
}): ReactElement => {
  const verzekeringnemersOpties = getAanvragerOpties(
    aanvrager1,
    aanvrager2?.klantId ? aanvrager2 : null,
    kenmerken.meerdereVerzekeringnemerOptiesMogelijk
  );

  return (
    <>
      <LabeledRadioInput
        caption={caption ?? (verzekeringnemersOpties.length > 1 ? "Verzekeringnemer(s)" : "Verzekeringnemer")}
        name={`producten[${selected}].verzekeringnemers.verzekeringnemers`}
        options={verzekeringnemersOpties}
      />
    </>
  );
};

export default connect<VerzekeringnemersProps, ProductenState>(Verzekeringnemers);
