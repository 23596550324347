import { BerekeningenOutput, Tarieven } from "../.generated/instellingen-forms/instellingen-formstypes";
import { ErrorPage, PageLoading, SettingsType } from "adviesbox-shared";
import React, { ReactElement } from "react";
import {
  mapFinancieringsbehoefteDlNameToUiName,
  mapFinancieringsbehoefteUiToDl
} from "./infra/map-financieringsbehoefte-ui-to-dl";

import Financieringsbehoefte from "./financieringsbehoefte";
import { InvalidAdviesboxResultErrorPage } from "../shared/components/fetch-error-page/fetch-error-page";
import { RouteParams } from "../shared/paramrouting/paramrouting-context";
import { mapFinancieringsbehoefteDlToUi } from "./infra/map-financieringsbehoefte-dl-to-ui";
import { useAdviesboxData } from "../shared/hooks/use-adviesbox-data";

const createUrl = (s: SettingsType, p: RouteParams): string =>
  `${s.klantdossiersFormsOrigin}/Voorstellen/${p.voorstel}/financieringsbehoefte`;

const FinancieringsbehoefteAjax = ({
  bekeningenInstellingenData,
  instellingenTarieven
}: {
  bekeningenInstellingenData: BerekeningenOutput;
  instellingenTarieven: Tarieven;
}): ReactElement => {
  const { loading, error, data, platformData, saveData } = useAdviesboxData(
    createUrl,
    p => p.voorstel,
    mapFinancieringsbehoefteDlToUi(bekeningenInstellingenData?.berekeningen || null, instellingenTarieven),
    mapFinancieringsbehoefteUiToDl,
    mapFinancieringsbehoefteDlNameToUiName
  );

  if (loading) {
    return <PageLoading />;
  }

  if (error) {
    return <ErrorPage error={error} data={platformData} />;
  }

  if (!data || !bekeningenInstellingenData || !instellingenTarieven) {
    return <InvalidAdviesboxResultErrorPage />;
  }

  return <Financieringsbehoefte saveData={saveData} {...data} />;
};

FinancieringsbehoefteAjax.displayName = "FinancieringsbehoefteAjax";

export default FinancieringsbehoefteAjax;
