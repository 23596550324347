import React, { ReactElement } from "react";
import "./waardeopbouw-jaren-modal.scss";
import Modal from "../../shared/components/modal/Modal";
import { DataGrid, AdviesBoxColumn, createSpanWithId } from "adviesbox-shared";

type WaardeopbouwJarenModalProps = {
  name: string;
  closeModal?: () => void;
};

const columns: AdviesBoxColumn[] = [
  {
    Header: "Jaar",
    Cell: (c): ReactElement => createSpanWithId(c.index, 0, c.original.jaar),
    width: 50
  },
  {
    Header: "Beginwaarde",
    Cell: "CurrencyInput",
    accessor: "beginwaardeBedrag",
    config: { readonly: true }
  },
  {
    Header: "Storting(en)",
    Cell: "CurrencyInput",
    accessor: "stortingenBedrag",
    config: { readonly: true }
  },
  {
    Header: "Onttrekking(en)",
    Cell: "CurrencyInput",
    accessor: "onttrekkingenBedrag",
    config: { readonly: true }
  },
  {
    Header: "Eindwaarde",
    Cell: "CurrencyInput",
    accessor: "eindwaardeBedrag",
    config: { readonly: true }
  }
];

const WaardeopbouwJarenModal = ({ name, closeModal }: WaardeopbouwJarenModalProps): ReactElement => {
  return (
    <Modal
      title="Waardeopbouw"
      body={
        <DataGrid columns={columns} name={name} defaultPageSize={Number.MAX_VALUE} className="waardeopbouw-table" />
      }
      noEditableData
      onCancelClick={closeModal}
    />
  );
};

export default WaardeopbouwJarenModal;
