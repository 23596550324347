import { LocalDate } from "@js-joda/core";
import { LabeledSelectInput } from "adviesbox-shared";
import { Formik, FormikProps } from "formik";
import React, { ReactElement } from "react";
import * as Yup from "yup";
import Modal from "../../shared/components/modal/Modal";
import { ScenarioCard } from "../../shared/components/scenario-input/scenario-card";
import { LabelValuePair, scenarioCardSchema } from "../../shared/types";
import { birthdate2age } from "../../shared/utils/birthdate-to-age";
import { InkomenUitArbeidModalType } from "../infra/inkomen-en-fiscus-schema";

export type InkomenUitArbeidScenarioData = {
  geboorteDatum: LocalDate | null | undefined;
  data: InkomenUitArbeidModalType[];
};

type InkomenUitArbeidScenarioProps = {
  onSave?: (data: InkomenUitArbeidModalType[]) => void;
  closeModal?: () => void;
};

const InkomenUitArbeidScenario = ({
  geboorteDatum,
  data,
  onSave,
  closeModal
}: InkomenUitArbeidScenarioData & InkomenUitArbeidScenarioProps): ReactElement => {
  const age = geboorteDatum ? birthdate2age(geboorteDatum) : 18;

  return (
    <Formik
      initialValues={{ selected: 0, inkomenUitArbeid: data }}
      validationSchema={Yup.object({
        inkomenUitArbeid: Yup.array(
          Yup.object({
            scenario: scenarioCardSchema
          })
        )
      })}
      onSubmit={(values): void => {
        onSave && onSave(values.inkomenUitArbeid);
        closeModal && closeModal();
      }}
    >
      {(
        formik: FormikProps<{
          selected: number;
          inkomenUitArbeid: InkomenUitArbeidModalType[];
        }>
      ): ReactElement => {
        const {
          values: { selected, inkomenUitArbeid }
        } = formik;

        const body = (
          <>
            <div className="d-flex flex-wrap">
              <LabeledSelectInput
                caption="Scenario inkomen van werkgever"
                name="selected"
                options={inkomenUitArbeid.map(
                  (iua, i): LabelValuePair => ({
                    label: iua.naam || /*istanbul ignore next*/ `Werkgever ${i + 1}`,
                    value: i.toString()
                  })
                )}
              />
            </div>

            {inkomenUitArbeid.length > 0 ? (
              <>
                <ScenarioCard
                  name={`inkomenUitArbeid[${selected}].scenario`}
                  startBedrag={inkomenUitArbeid[selected].totaalBruto || 0}
                  captionStart={age}
                  captionSuffix={" jr"}
                  entries={inkomenUitArbeid[selected].scenario.length}
                />
              </>
            ) : (
              /*istanbul ignore next */
              <></>
            )}
          </>
        );

        return (
          <>
            <Modal
              title="Scenario bruto salaris uit dienstverband"
              body={body}
              onSubmitClick={formik.submitForm}
              onCancelClick={closeModal}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default InkomenUitArbeidScenario;
