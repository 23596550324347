import { SoortLijfrenteUitkeringOptions } from "../../.generated/forms/formstypes";
import { createISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";
import { jaarMaandInMaanden } from "../../shared/generic-parts/jaar-maand/map-ui-2-dl";
import { IndicatieveUitkerendeFaseType } from "../infra/producten-overzicht-schema";

export const determineIndicatieveUitkerendeFaseSideEffects = createISWSideEffect<IndicatieveUitkerendeFaseType>(
  ({ has, draft }): void => {
    if (
      has.soortLijfrenteUitkering.changed &&
      draft.soortLijfrenteUitkering === SoortLijfrenteUitkeringOptions.Levenslang
    ) {
      // Soort van Tijdelijk naar Levenslang => duurUitkering leeg
      draft.duurUitkering.jaren = null;
      draft.duurUitkering.maanden = null;
    }

    if (has.duurUitkeringHoog.changed) {
      const duurUitkeringHoog = jaarMaandInMaanden(draft.duurUitkeringHoog);
      if (!duurUitkeringHoog) {
        //   Duur hoog wordt leeg => Verhouding leeg
        draft.hoogLaagVerhouding = null;
      } else if (!draft.hoogLaagVerhouding) {
        // Duur hoog wordt gevuld  => Verhouding vullen met 1
        draft.hoogLaagVerhouding = 1;
      }
    }

    if (has.overgangOpTweedeVerzekerde.changed) {
      if (draft.overgangOpTweedeVerzekerde) {
        // Overgang op tweede wordt ja => Overgangspercentage default 70
        draft.overgangOpTweedeVerzekerdePercentage = 70;
      } else {
        // Overgang op tweede wordt nee => Overgangspercentage leeg
        draft.overgangOpTweedeVerzekerdePercentage = null;
      }
    }
  }
);
