/* istanbul ignore file */
import { RapportageElement } from "../../.generated/instellingen-forms/instellingen-formstypes";
import {
  RapportSamenstellen,
  RapportSamenstellenElement,
  SoortElementOptions,
  RapportSamenstellenStructuur,
  RapportSamenstellenStructuurKeuze,
  RapportSamenstellenStructuurOutput,
  RapportSamenstellenVoorstel
} from "../../.generated/rapportage/rapportagetypes";
import {
  RapportageSamenstellenState,
  RapportStructuurType
} from "../../shared/components/rapportage/infra/rapportage-samenstellen-types";
import { RapportageElementSoort } from "../../shared/components/rapportage/infra/rapportage-structuur-types";
import {
  rapportageElementSchema,
  rapportageSamenstellenSchema,
  rapportSamenstellenSchema,
  rapportSamenstellenStructuurKeuzesSchema,
  rapportSamenstellenVoorstelSchema,
  rapportStructuurSchema
} from "../../shared/components/rapportage/rapportage-samenstellen-schema";
import { UiName, FieldMap } from "../../shared/types";
import { createMapToDl } from "../../shared/utils/create-map-to-dl";
import { createMapToUi } from "../../shared/utils/create-map-to-ui";
import { target2field } from "../../shared/utils/target-to-field";

const mapRapportageElementenToUi: any = createMapToUi(rapportageElementSchema).from<RapportageElement>({
  code: v => v.code,
  geselecteerd: v => v.geselecteerd,
  naam: v => v.naam,
  soortElement: v => (v.soortElement as unknown) as RapportageElementSoort,
  volgnummer: v => v.volgnummer,
  elementen: v => v.elementen?.map(c => mapRapportageElementenToUi(c))
});

const mapStructuurKeuzesToDl = createMapToUi(rapportSamenstellenStructuurKeuzesSchema).from<
  RapportSamenstellenStructuurKeuze
>({
  naam: v => v.naam,
  structuurId: v => v.structuurId,
  volgnummer: v => v.volgnummer
});

const mapVoorstellenToDL = createMapToUi(rapportSamenstellenVoorstelSchema).from<RapportSamenstellenVoorstel>({
  omschrijving: v => v.omschrijving,
  volgnummer: v => v.volgnummer,
  voorstelId: v => v.voorstelId
});

const mapRapportageDataToDl = createMapToUi(rapportSamenstellenSchema).from<RapportSamenstellen>({
  structuurKeuzes: v => v.structuurKeuzes?.map(c => mapStructuurKeuzesToDl(c)),
  voorstellen: v => v.voorstellen?.map(c => mapVoorstellenToDL(c))
});

const dl2ui = createMapToUi(rapportageSamenstellenSchema).from<{
  voorstelId: string;
  rapportageData: RapportSamenstellen[];
}>({
  rapportstructuur: v =>
    v.rapportageData.length > 0 &&
    (v.rapportageData[0].structuurKeuzes?.length ?? 0) > 0 &&
    v.rapportageData[0].structuurKeuzes
      ? v.rapportageData[0].structuurKeuzes[0].structuurId
      : null,
  rapportSamenstellenOpBasisVan: v => v.voorstelId,
  rapportageData: v => v.rapportageData.map(v => mapRapportageDataToDl(v)),
  selectedStructuur: _ => null
});

export const mapRapportageSamenstellenDlToUi = (
  voorstelId: string,
  rapportageData: RapportSamenstellen[]
): RapportageSamenstellenState => {
  const res = dl2ui({ voorstelId, rapportageData });

  return res;
};

const mapElementenToUi: any = createMapToUi(rapportageElementSchema).from<RapportSamenstellenElement>({
  code: v => v.code,
  geselecteerd: v => v.geselecteerd,
  naam: v => v.naam,
  soortElement: v => (v.soortElement as unknown) as RapportageElementSoort,
  volgnummer: v => v.volgnummer,
  elementen: v => v.elementen?.map(c => mapElementenToUi(c))
});

export const mapRapportToUi = createMapToUi(rapportStructuurSchema).from<RapportSamenstellenStructuur>({
  elementen: v => v.elementen.map(c => mapElementenToUi(c)),
  naam: v => v.naam,
  structuurId: v => v.structuurId,
  volgnummer: v => v.volgnummer
});

export const mapRapportStructuurToUi = (
  dataId: string,
  data: RapportSamenstellenStructuurOutput
): RapportStructuurType | null => {
  if (!data.structuur) {
    return null;
  }

  return mapRapportToUi(data.structuur);
};

export const mapSoortElement = (soortElement: RapportageElementSoort): SoortElementOptions => {
  switch (soortElement) {
    case RapportageElementSoort.Grafiek:
      return SoortElementOptions.Grafiek;
    case RapportageElementSoort.Hoofdstuk:
      return SoortElementOptions.Hoofdstuk;
    case RapportageElementSoort.Koptekst:
      return SoortElementOptions.Koptekst;
    case RapportageElementSoort.Onderdeel:
      return SoortElementOptions.Onderdeel;
    case RapportageElementSoort.Tabel:
      return SoortElementOptions.Tabel;
    case RapportageElementSoort.Tekstblok:
      return SoortElementOptions.Tekstblok;
  }
};

export const mapRapportageElementToDl: any = createMapToDl(rapportageElementSchema).to<RapportSamenstellenElement>({
  code: v => v.code,
  elementen: v => v.elementen.map(c => mapRapportageElementToDl(c)),
  geselecteerd: v => v.geselecteerd,
  naam: v => v.naam,
  soortElement: v => mapSoortElement(v.soortElement),
  volgnummer: v => v.volgnummer
});

export const mapRapportStructuurToDl = createMapToDl(rapportStructuurSchema).to<RapportSamenstellenStructuur>({
  elementen: v => v.elementen.map(c => mapRapportageElementToDl(c)),
  naam: v => v.naam,
  structuurId: v => v.structuurId,
  volgnummer: v => v.volgnummer
});

export function mapDlTargetToRapportStructuur(target: string): UiName | null {
  const map: FieldMap<RapportageSamenstellenState> = {};

  return target2field(map, target);
}
