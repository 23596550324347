import { SettingsType, hasJSONResponse } from "adviesbox-shared";
import { FormikContextType } from "formik";
import { User } from "oidc-client";
import { DossierStatusType } from "../schema-and-mappers";
import { DossierWijzigAdviseurType } from "./dossier-wijzig-adviseur-types";

/* istanbul ignore next */
export const wijzigAdviseurPOST =  async (
  settings: SettingsType,
  user: User | null,
  wijzigState: DossierWijzigAdviseurType,
  vestigingId: string,
  adviesdossierId: string,
  setFieldValue: FormikContextType<DossierStatusType>["setFieldValue"]
): Promise<boolean> => {
  const url = `${settings.klantdossiersFormsOrigin}/Adviesdossier/WijzigEigenaar?huidigAdviseurId=${wijzigState.huidigeAdviseurId}&adviesdossierId=${adviesdossierId}&licentiesMedewerkerId=${wijzigState.doelAdviseurId}`;

  const options: RequestInit = {
    headers: {
      authorization: `${user?.token_type} ${user?.access_token}`,
      "Ocp-Apim-Subscription-Key": settings.OcpApimSubscriptionKey,
      "Content-Type": "application/json;charset=UTF-8",
      vestigingId
    },
    method: "POST"
  };

  setFieldValue("platformApiFouten", null);

  const rsp = await fetch(url, options);

  if (!rsp.ok) { throw Error(`Fout bij aanroep van web-api '${url}'.`); }
  if (hasJSONResponse(rsp) === false) { throw Error(`Fout bij aanroep van web-api '${url}'.`); }

  const data = await rsp.json();

 if (!data.isValid) {   setFieldValue("platformApiFouten", "Er is een fout opgetreden bij het wijzigen van de eigenaar van dit advies."); }

 return true;
}

export const getHuidigeAdviseur = async (settings: SettingsType, vestigingId: string, user: User | null,
  adviesdossierId: string): Promise<{ adviseurId: string, naam: string }> => {
  const url = `${settings.klantdossiersFormsOrigin}/Adviesdossiers/Eigenaar?adviesdossierId=${adviesdossierId}`;

  const options: RequestInit = {
    headers: {
      authorization: `${user?.token_type} ${user?.access_token}`,
      "Ocp-Apim-Subscription-Key": settings.OcpApimSubscriptionKey,
      "Content-Type": "application/json;charset=UTF-8",
      vestigingId
    }
  };

  const rsp = await fetch(url, options);

  if (!rsp.ok) { throw Error(`Fout bij aanroep van web-api '${url}'.`); }
  if (hasJSONResponse(rsp) === false) { throw Error(`Fout bij aanroep van web-api '${url}'.`); }

  const data = await rsp.json();

  return { adviseurId: data.id, naam: data.naam };
}