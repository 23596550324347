import React, { ReactElement, Suspense, useMemo } from "react";
import { DataDisplay, Loading } from "../data-display/data-display";
import { useRequestInit, bedragFormat } from "adviesbox-shared";
import { LastenOverzicht } from "../../../.generated/rapportage/rapportagetypes";

export const SnelInzichtResultaat = ({ onClick, situatie }: { onClick: () => void, situatie: string }): ReactElement => {
  const {settings, params} = useRequestInit();

  const getRapportageLastenoverzicht = useMemo(() => {
    const baseUrl = `${settings.rapportageOrigin}`;
    const url = params.voorstel 
      ? `${baseUrl}/Voorstellen/${params.voorstel}/SnelInzicht/LastenOverzicht?alleenEersteJaar=true` 
      : `${baseUrl}/Adviesdossiers/${params.adviesdossier}/SnelInzicht/LastenOverzicht?alleenEersteJaar=true`;
    return url;
  }, [params.adviesdossier, params.voorstel, settings.rapportageOrigin]);

  return (
    <Suspense fallback={<Loading />}>
      <button type="button" className="new-dashboard__entry-btn" data-testid="button-testid" onClick={onClick}>
        <h2>SNEL INZICHT</h2>
        <DataDisplay
          endpoint={getRapportageLastenoverzicht}
          autostart={false}
          method="POST"
          render={(data: LastenOverzicht) => {
            
            if (!data || !Array.isArray(data.jaarGegevens) || data.jaarGegevens.length === 0) {
              return <div>Geen jaargegevens beschikbaar.</div>;
            }

            const eersteJaar = data.jaarGegevens[data.jaarGegevens.length - 1];
            return (
              <>
                <div>Bruto {bedragFormat(eersteJaar.brutoLast, 0)} p.m.</div>
                <div>Netto {bedragFormat(eersteJaar.nettoLast, 0)} p.m.</div>
              </>
            );
          }}
        />
      </button>
    </Suspense>
  );
};