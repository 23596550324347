/* istanbul ignore file */
import { ForceRerenderContext, useRequestInit } from "adviesbox-shared";
import { useContext, useEffect } from "react";
import useAbortableFetch from "use-abortable-fetch";
import { Documenten, DocumentenOutput } from "../../.generated/rapportage/rapportagetypes";

export type useDocumentenAanwezigDataResult = {
  error: Error | null;
  documentenLijst: { [key: string]: Documenten } | null;
  loading?: boolean;
};

export function UseDocumentenAanwezig(voorstelId: string | null): useDocumentenAanwezigDataResult {
  const loadingDone = useContext(ForceRerenderContext);

  const {
    requestInit,
    settings: { rapportageOrigin }
  } = useRequestInit();

  let url = "";
  if (voorstelId) {
    url = `${rapportageOrigin}/Voorstellen/${voorstelId}/Documenten`;
  }
  const { error, data, loading } = useAbortableFetch<DocumentenOutput>(url, requestInit);

  useEffect((): void => {
    if (!loading && !!data && loadingDone) {
      loadingDone();
    }
  }, [loading, data, loadingDone]);

  if (typeof data === "string") {
    return { error: new Error("Fout bij het laden van producten data"), documentenLijst: {} };
  }

  return {
    error,
    documentenLijst: !data || !data.documenten ? null : data.documenten,
    loading
  };
}
