import React, { ReactElement } from "react";
import { FormikProps } from "formik";
import Modal from "../../shared/components/modal/Modal";
import { LabeledCurrencyInput, LabeledBevestigingInput } from "adviesbox-shared";
import AdviesBoxFormik from "../../shared/utils/adviesbox-formik";
import { BankgarantieModal as BankgarantieModalState } from "../infra/financieringsbehoefte-types";
import { bankgarantieModalSchema } from "../infra/financieringsbehoefte-schema";

export type BankgarantieModalData = {
  data: BankgarantieModalState;
};

type BankgarantieModalProps = {
  onSave?: (data: BankgarantieModalState) => void;
  closeModal?: () => void;
};

const BankgarantieModal = ({
  data,
  onSave,
  closeModal
}: BankgarantieModalData & BankgarantieModalProps): ReactElement => {
  const body = (
    <>
      <LabeledCurrencyInput caption="Garantiebedrag" name="garantiebedrag" />
      <LabeledBevestigingInput caption="Aanvragen bij geldverstrekker" name="aanvragenBijGeldverstrekker" />
      <LabeledCurrencyInput caption="Kosten bankgarantie" name="kostenBankgarantie" />
    </>
  );

  return (
    <AdviesBoxFormik<BankgarantieModalState>
      initialValues={{ ...data }}
      validationSchema={bankgarantieModalSchema}
      onSave={onSave}
      closeModal={closeModal}
      render={({ submitForm }: FormikProps<BankgarantieModalState>): ReactElement => (
        <>
          <>
            <Modal title="Bankgarantie" body={body} onSubmitClick={submitForm} onCancelClick={closeModal} />
          </>
        </>
      )}
    />
  );
};

export default BankgarantieModal;
