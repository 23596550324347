import { LabeledCurrencyInput, LabeledRadioInput } from "adviesbox-shared";
import { useFormikContext } from "formik";
import React, { ReactElement } from "react";
import { AovVerzekerdeDekkingenOptions, AovVoornaamsteInkomstenbronOptions } from "../../.generated/forms/formstypes";
import { AovsType } from "../infra/aov-schema";
import { mapPartijProduct } from "../infra/map-aov-dl-to-ui";
import { claimBeoordelingOptions, dekkingOptions, DekkingProps, eigenRisicoOptions, uitkeringsduurOptions } from "./dekking";
import { UitkeringGedeeltelijkeAo } from "./uitkering-gedeeltelijke-ao";

export const DekkingCreditLife = ({ selected, kenmerken, verzekerde }: DekkingProps): ReactElement => {
  const { values } = useFormikContext<AovsType>();
  const dekkingGegevens = values.producten[selected].dekking.dekkingGegevensVerzekerde1;
  const partijProductCode = mapPartijProduct(
    values.producten[selected].partijCode,
    values.producten[selected].productCode
  );

  return (
    <>
      <h2>Arbeidsongeschiktheid(AO)</h2>
      {kenmerken.verzekerdeDekkingTonen && (
        <LabeledRadioInput
          caption={"Dekking"}
          name={`producten[${selected}].dekking.dekkingGegevensVerzekerde${verzekerde}.dekking`}
          options={dekkingOptions(
            kenmerken,
            dekkingGegevens.voornaamsteInkomstenbron !== AovVoornaamsteInkomstenbronOptions.Zelfstandige,
            partijProductCode
          )}
        />
      )}
      {kenmerken.heeftVerzekerdeDekkingAo &&
        (dekkingGegevens.dekking === AovVerzekerdeDekkingenOptions.Ao ||
          dekkingGegevens.dekking === AovVerzekerdeDekkingenOptions.AoWw) && (
          <div>
            <LabeledCurrencyInput
              caption="Verzekerd maandbedrag AO"
              name={`producten[${selected}].dekking.dekkingGegevensVerzekerde${verzekerde}.ao.verzekerdBedrag`}
              readonly={!kenmerken.verzekerdBedragAoEnabled}
            />
            {kenmerken.eigenRisicoAoTonen && (
              <LabeledRadioInput
                caption="Eigen risico"
                name={`producten[${selected}].dekking.dekkingGegevensVerzekerde${verzekerde}.ao.eigenRisico`}
                options={eigenRisicoOptions(kenmerken)}
              />
            )}
            {kenmerken.claimBeoordelingAoTonen && (
              <LabeledRadioInput
                caption="Claimbeoordeling"
                name={`producten[${selected}].dekking.dekkingGegevensVerzekerde${verzekerde}.beoordelingscriterium`}
                options={claimBeoordelingOptions()}
              />
            )}
            {kenmerken.uitkeringGedeeltelijkAoTonen && (
              <UitkeringGedeeltelijkeAo kenmerken={kenmerken} selected={selected} verzekerde={verzekerde} />
            )}
            {kenmerken.aoUitkeringsduurTonen && (
              <LabeledRadioInput
                caption="Uitkeringsduur"
                name={`producten[${selected}].dekking.dekkingGegevensVerzekerde${verzekerde}.ao.uitkeringsduurAo`}
                options={uitkeringsduurOptions(kenmerken)}
              />
            )}
          </div>
        )}
      {kenmerken.heeftVerzekerdeDekkingWw &&
        (dekkingGegevens.dekking === AovVerzekerdeDekkingenOptions.Ww ||
          dekkingGegevens.dekking === AovVerzekerdeDekkingenOptions.AoWw) && (
          <div>
            <h2>Werkeloosheid (WW)</h2>
            {kenmerken.verzekerdBedragWwEnabled && (
              <LabeledCurrencyInput
                caption="Te verzekeren maandlast"
                name={`producten[${selected}].dekking.dekkingGegevensVerzekerde${verzekerde}.ww.verzekerdBedrag`}
                readonly={!kenmerken.verzekerdBedragWwEnabled}
              />
            )}
            {(kenmerken.heeftWwUitkeringdduur12Maanden || kenmerken.HeeftWwUitkeringdduur24Maanden) && (
              <LabeledRadioInput
                caption="Uitkeringsduur"
                name={`producten[${selected}].dekking.dekkingGegevensVerzekerde${verzekerde}.ww.uitkeringsDuurInMaanden`}
                options={[
                  { label: "12 maanden", value: "12" },
                  { label: "24 maanden", value: "24" }
                ]}
              />
            )}
          </div>
        )}
    </>
  );
};
