import {
  WoonsituatieListState,
  GebruikPand,
  SoortWoonsituatie,
  Bewoners,
  woningInEigendomSchema,
  huurwoningSchema,
  eigenwoningreserveSchema,
  huidigPandSchema,
  hypotheekSchema,
  erfpachtSchema,
  WoonsituatieType,
  PersoonsgegevensType,
  EigenwoningschuldModalEntryType
} from "./woonsituatie-schema";
import { initISWSideEffect, createISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";
import adresSchema from "../../shared/generic-parts/adres/schema";
import { getSum } from "adviesbox-shared";

const erfpachtSideEffects = createISWSideEffect<WoonsituatieType>(({ has, draft }): void => {
  if (has.erfpacht.hasErfpacht.changed && !draft.erfpacht.hasErfpacht) {
    draft.erfpacht = erfpachtSchema.default();
  }
});

const hypotheekSideEffects = createISWSideEffect<WoonsituatieType>((bag): void => {
  const has = bag.has.hypotheek;
  const draft = bag.draft.hypotheek;

  if (has.hasHypotheek.changed && !draft.hasHypotheek) {
    const defaults = hypotheekSchema.default();

    draft.inschrijving = defaults.inschrijving;
    draft.oorspronkelijk = defaults.oorspronkelijk;
  }

  if (
    has.oorspronkelijk.changed &&
    !has.inschrijving.berekenen.changed &&
    (draft.inschrijving.berekenen || draft.inschrijving.berekenen === null)
  ) {
    draft.inschrijving.berekendBedrag = draft.oorspronkelijk;
    draft.inschrijving.bedrag = draft.oorspronkelijk;
  }

  if (
    draft.oorspronkelijk !== draft.inschrijving.berekendBedrag &&
    has.inschrijving.berekenen.changed &&
    draft.inschrijving.berekenen
  ) {
    draft.inschrijving.berekendBedrag = draft.oorspronkelijk;
  }
});

const calcSom = (entries: EigenwoningschuldModalEntryType[] | null): number | null => {
  if (!entries || entries.length === 0) return null;
  return getSum(entries.map(x => x.bedrag || 0));
}

export const eigenwoningreserveSideEffects = createISWSideEffect<WoonsituatieType>(({ has, draft }): void => {
  if (has.eigenwoningreserve.eigenwoningreserveToggle.changed && !draft.eigenwoningreserve.eigenwoningreserveToggle) {
    draft.eigenwoningreserve = eigenwoningreserveSchema.default();
    return;
  }
  if (has.eigenwoningreserve.ewsOvergangsrechtModal.changed) {
    draft.eigenwoningreserve.ewsOvergangsrecht = calcSom(draft.eigenwoningreserve.ewsOvergangsrechtModal.entries);
  }
  if (has.eigenwoningreserve.ewsAflossingModal.changed) {
    draft.eigenwoningreserve.ewsAflossing = calcSom(draft.eigenwoningreserve.ewsAflossingModal.entries);
  }
});

const huidigPandSideEffects = createISWSideEffect<WoonsituatieType, PersoonsgegevensType>(
  ({ has, draft, context }): void => {

    if (has.huidigeWoonsituatie.woonsituatie.changed && draft.woningInEigendom && draft.huidigPand==null){
      draft.huidigPand = huidigPandSchema.default();
    }

    if (has.huidigeWoonsituatie.woonsituatie.changed && !draft.woningInEigendom && draft.huidigPand!=null){
      draft.huidigPand = null;
    }


    if (
      draft.huidigPand?.adresIsWoonadres &&
      (draft.huidigeWoonsituatie.bewoners === Bewoners.Aanvrager1 ||
        draft.huidigeWoonsituatie.bewoners === Bewoners.Beiden)
    ) {
      draft.huidigPand.adres = {
        ...context.adresAanvrager
      };
    }

    if (
      draft.huidigPand?.adresIsWoonadres &&
      draft.huidigeWoonsituatie.bewoners === Bewoners.Aanvrager2
    ) {
      draft.huidigPand.adres = {
        ...context.adresPartner
      };
    }

    if (has.huidigPand.adresIsWoonadres.changed && draft.huidigPand && !draft.huidigPand.adresIsWoonadres) {
      draft.huidigPand.adres = {
        ...adresSchema.default()
      };
    }
  }
);

const woningInEigendomSideEffects = createISWSideEffect<WoonsituatieType>(({ has, draft }): void => {
  const defaults = eigenwoningreserveSchema.default();

  if (has.woningInEigendom.gebruikWoning.changed) {
    draft.eigenwoningreserve.eigenwoningreserveToggle = defaults.eigenwoningreserveToggle;
    draft.eigenwoningreserve.ewrVerleden = defaults.ewrVerleden;
    draft.eigenwoningreserve.ewsAflossing = defaults.ewsAflossing;
    draft.eigenwoningreserve.ewsAflossingModal = defaults.ewsAflossingModal;
    draft.eigenwoningreserve.ewsOvergangsrecht = defaults.ewsOvergangsrecht;
    draft.eigenwoningreserve.ewsOvergangsrechtModal = defaults.ewsOvergangsrechtModal;
  }

  if (
    has.woningInEigendom.gebruikWoning.changed &&
    draft.woningInEigendom.gebruikWoning === GebruikPand.TweedeWoning &&
    draft.woningInEigendom.eigenwoningforfait
  ) {
    draft.woningInEigendom.eigenwoningforfait.bedrag = null;
    draft.woningInEigendom.eigenwoningforfait.berekendBedrag = null;
    draft.woningInEigendom.eigenwoningforfait.berekenen = true;
  }

  if (
    has.woningInEigendom.wozWaarde.changed &&
    (draft.woningInEigendom.wozWaarde === 0 || draft.woningInEigendom.wozWaarde === null) &&
    draft.woningInEigendom.eigenwoningforfait &&
    (draft.woningInEigendom.eigenwoningforfait.bedrag || draft.woningInEigendom.eigenwoningforfait.berekendBedrag)
  ) {
    draft.woningInEigendom.eigenwoningforfait.bedrag = null;
    draft.woningInEigendom.eigenwoningforfait.berekendBedrag = null;
    draft.woningInEigendom.eigenwoningforfait.berekenen = true;
  }

  const totaalPercentage = (draft.woningInEigendom.percentageAanvrager ?? 0) + (draft.woningInEigendom.percentagePartner ?? 0);
  if (has.woningInEigendom.percentageAanvrager.changed && totaalPercentage > 100) {
    draft.woningInEigendom.percentagePartner = 100 - (draft.woningInEigendom.percentageAanvrager ?? 0);
  }

  if (has.woningInEigendom.percentagePartner.changed && totaalPercentage > 100) {
    draft.woningInEigendom.percentageAanvrager = 100 - (draft.woningInEigendom.percentagePartner ?? 0);
  }
});

const huidigeWoonsituatieSideEffects = createISWSideEffect<WoonsituatieType>(({ draft, has }): void => {
  if (has.huidigeWoonsituatie.woonsituatie.changed) {
    switch (draft.huidigeWoonsituatie.woonsituatie) {
      case SoortWoonsituatie.Eigendom:
        draft.huurwoning = huurwoningSchema.default();
        draft.eigenwoningreserve = eigenwoningreserveSchema.default();
        // default berekenen op true
        draft.woningInEigendom.eigenwoningforfait.berekenen = true;
        draft.hypotheek.inschrijving.berekenen = true;
        switch (draft.huidigeWoonsituatie.bewoners) {
          case Bewoners.Aanvrager1:
            draft.woningInEigendom.percentageAanvrager = 100;
            draft.woningInEigendom.percentagePartner = 0;
            break;
          case Bewoners.Aanvrager2:
            draft.woningInEigendom.percentageAanvrager = 0;
            draft.woningInEigendom.percentagePartner = 100;
            break;
          case Bewoners.Beiden:
            draft.woningInEigendom.percentageAanvrager = 50;
            draft.woningInEigendom.percentagePartner = 50;
            break;
        }
        break;
      case SoortWoonsituatie.Huur:
        draft.woningInEigendom = woningInEigendomSchema.default();
        draft.huidigPand = huidigPandSchema.default();
        draft.hypotheek = hypotheekSchema.default();
        draft.erfpacht = erfpachtSchema.default();
        draft.eigenwoningreserve = eigenwoningreserveSchema.default();
        break;
      case SoortWoonsituatie.Inwonend:
        draft.woningInEigendom = woningInEigendomSchema.default();
        draft.huidigPand = huidigPandSchema.default();
        draft.hypotheek = hypotheekSchema.default();
        draft.erfpacht = erfpachtSchema.default();
        draft.huurwoning = huurwoningSchema.default();
        draft.eigenwoningreserve = eigenwoningreserveSchema.default();
        break;
    }
  }

  if (has.huidigeWoonsituatie.bewoners.changed && draft.huidigeWoonsituatie.bewoners === Bewoners.Aanvrager1) {
    draft.eigenwoningreserve = eigenwoningreserveSchema.default();
    draft.erfpacht = erfpachtSchema.default();
    draft.huidigPand = huidigPandSchema.default();
    draft.huurwoning = huurwoningSchema.default();
    draft.hypotheek = hypotheekSchema.default();
    draft.woningInEigendom = {
      ...woningInEigendomSchema.default(),
      percentageAanvrager: 100,
      percentagePartner: 0
    };
  }

  if (has.huidigeWoonsituatie.bewoners.changed && draft.huidigeWoonsituatie.bewoners === Bewoners.Aanvrager2) {
    draft.eigenwoningreserve = eigenwoningreserveSchema.default();
    draft.erfpacht = erfpachtSchema.default();
    draft.huidigPand = huidigPandSchema.default();
    draft.huurwoning = huurwoningSchema.default();
    draft.hypotheek = hypotheekSchema.default();
    draft.woningInEigendom = {
      ...woningInEigendomSchema.default(),
      percentageAanvrager: 0,
      percentagePartner: 100
    };
  }

  if (has.huidigeWoonsituatie.bewoners.changed && draft.huidigeWoonsituatie.bewoners === Bewoners.Beiden) {
    draft.eigenwoningreserve = eigenwoningreserveSchema.default();
    draft.erfpacht = erfpachtSchema.default();
    draft.huidigPand = huidigPandSchema.default();
    draft.huurwoning = huurwoningSchema.default();
    draft.hypotheek = hypotheekSchema.default();
    draft.woningInEigendom = {
      ...woningInEigendomSchema.default(),
      percentageAanvrager: 50,
      percentagePartner: 50
    };
  }
});

export const determineWoonsituatieSideEffects = initISWSideEffect<WoonsituatieListState>(bag => {
  if (!bag.has.woonsituaties.changed) {
    return;
  }

  for (let i = 0; i < bag.draft.woonsituaties.length; i++) {
    const woonsituatie = bag.subset.woonsituaties[i].createWithContext(bag.draft.persoonsgegevens);
    if (!woonsituatie.has.changed) continue;

    huidigeWoonsituatieSideEffects(woonsituatie);
    woningInEigendomSideEffects(woonsituatie);
    huidigPandSideEffects(woonsituatie);

    eigenwoningreserveSideEffects(woonsituatie);
    hypotheekSideEffects(woonsituatie);
    erfpachtSideEffects(woonsituatie);
  }
})();
