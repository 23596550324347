import { ChronoUnit, LocalDate } from "@js-joda/core";
import { FormikHelpers } from "formik";
import { SoortOrvProductOptions } from "../.generated/forms/formstypes";
import { UitkeringTypeOptions } from "../.generated/producten/productentypes";
import {
  AflosproductType,
  ProductSelectieType,
  SituatieSoort
} from "../producten-overzicht/infra/producten-overzicht-types";
import { addMonths, addYears, getDifferenceYearsMonths } from "../shared/utils/dates";
import { mapOrvVergelijkerResultaat2OrvType } from "./infra/map-orv-dl-2-ui";
import { getUitkeringSoort } from "./infra/orv-helper";
import {
  OrvProps,
  orvSchema,
  OrvSchemaContextType,
  OrvsState,
  OrvType,
  OrvVergelijkenModalType
} from "./infra/orv-schema";

export const bepaalEindleeftijdAanvrager1 = (values: OrvsState): number | null => {
  const nieuweOrv = orvSchema.default();
  const geboortedatumAanvrager1 = values.aanvrager1 ? values.aanvrager1.geboortedatum : null;
  nieuweOrv.product.ingangsdatum =
    values.ingangsdatumVoorstel ??
    LocalDate.now()
      .plusMonths(1)
      .withDayOfMonth(1);
  nieuweOrv.product.einddatum = addMonths(
    addYears(nieuweOrv.product.ingangsdatum, nieuweOrv.product.looptijd.jaren || 0),
    nieuweOrv.product.looptijd.maanden || 0
  );

  return geboortedatumAanvrager1 ? geboortedatumAanvrager1.until(nieuweOrv.product.einddatum, ChronoUnit.YEARS) : null;
};

export const newOrvObjectFactory = (
  productSelectie: ProductSelectieType,
  ingangsdatumVoorstel: LocalDate | null,
  situatie: SituatieSoort,
  aflosProducten: AflosproductType[],
  values: OrvsState
): OrvType => {
  const nieuweOrv = orvSchema.default();

  nieuweOrv.product.ingangsdatum =
    ingangsdatumVoorstel ??
    LocalDate.now()
      .plusMonths(1)
      .withDayOfMonth(1);

  const duurVerzekering = getDifferenceYearsMonths(
    nieuweOrv.product.ingangsdatum,
    nieuweOrv.product.ingangsdatum.plusYears(30)
  );
  nieuweOrv.product.looptijd = {
    jaren: duurVerzekering.year,
    maanden: duurVerzekering.month
  };

  nieuweOrv.product.einddatum = addMonths(
    addYears(nieuweOrv.product.ingangsdatum, nieuweOrv.product.looptijd.jaren || 0),
    nieuweOrv.product.looptijd.maanden || 0
  );

  nieuweOrv.premieverloop = null;
  nieuweOrv.dekking.einddatum = nieuweOrv.product.einddatum;
  nieuweOrv.dekking.eindleeftijd = !values.aanvrager1?.klantId ? null : bepaalEindleeftijdAanvrager1(values);
  nieuweOrv.product.uitkeringssoort = getUitkeringSoort(productSelectie.codes.productVorm as UitkeringTypeOptions);
  nieuweOrv.product.wijzigingenInDoorlopendProductOvernemen = null;
  nieuweOrv.premieGegevens.looptijd = nieuweOrv.product.looptijd;
  nieuweOrv.product.uwBemiddeling = situatie === "voorstel";
  nieuweOrv.verpanding.bedoeldVoorAflossingSpecificatie.aflosproducten = aflosProducten;
  if (productSelectie.codes.productVorm === SoortOrvProductOptions.AnwHiaatVerzekering) {
    nieuweOrv.product.soortProduct = SoortOrvProductOptions.AnwHiaatVerzekering;
    nieuweOrv.dekking.einddatum = null;
    nieuweOrv.dekking.eindleeftijd = values.aanvrager1
      ? values.aanvrager1.geboortedatum
          .until(values.aanvrager1?.aowdatum || values.aanvrager1.geboortedatum.plusYears(67))
          .years()
      : 67;
  }

  return nieuweOrv;
};

export const onSaveOrvFunction = (
  result: OrvVergelijkenModalType,
  setFieldValue: FormikHelpers<OrvVergelijkenModalType>["setFieldValue"],
  values: OrvsState,
  situatie: SituatieSoort,
  setSelected: (n: number) => void
): void => {
  const nieuweProduct = mapOrvVergelijkerResultaat2OrvType(result);
  /* istanbul ignore else reason: else path is not posible since result cant be null and mapOrvVergelijkerResultaat2OrvType only returns falsy if result is null*/
  if (nieuweProduct) {
    nieuweProduct.product.uwBemiddeling = situatie === "voorstel";
    nieuweProduct.verpanding.bedoeldVoorAflossingSpecificatie.aflosproducten = values.aflosProductNew;
    const update = values.producten.concat([nieuweProduct]);
    setFieldValue(`producten`, update);
    setSelected(update.length - 1);
  }
};

export const createFormValidationContext = (props: OrvProps, values: OrvsState): OrvSchemaContextType => ({
  values,
  situatie: props.situatie
});
