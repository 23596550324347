import {
  initISWAsyncSideEffect,
  createISWAsyncSideEffect
} from "../../shared/components/isw-side-effects/create-isw-helpers";

import { EwsInput, EwsOutput } from "../../.generated/forms/formstypes";
import { mapEigenWoningSchuldInput } from "../infra/map-financieringsbehoefte-berekenen";
import { mapDraftEwsResultaatDlToUi } from "../infra/map-eigenwoningschuld";
import { berekenInputToDraftSideEffectResult } from "../../shared/types";
import { FinancieringsbehoefteState, EigenwoningschuldModalCompleet } from "../infra/financieringsbehoefte-types";

type AsyncContext = { financieringsBehoefte: FinancieringsbehoefteState };

export const mapEwsInput = (
  draft: EigenwoningschuldModalCompleet,
  financieringsBehoefte: FinancieringsbehoefteState
): EwsInput => {
  const ewsInput = mapEigenWoningSchuldInput(financieringsBehoefte);
  ewsInput.financieringskostenAftrekbaarPercentage = draft.financieringsKostenPercentage;
  ewsInput.financieringskostenAftrekbaarOvernemen = draft.financieringskostenAftrekbaarOvernemen;
  return ewsInput;
};

export const mapEwsOutput = (curr: EigenwoningschuldModalCompleet, result: EwsOutput): void => {
  if (result.isValid && result.resultaat) {
    mapDraftEwsResultaatDlToUi(curr, result.resultaat);
    if (curr.eigenwoningschuld.berekenen || curr.eigenwoningschuld.berekenen === null) {
      berekenInputToDraftSideEffectResult(curr.eigenwoningschuld, result.resultaat.ewsBedrag);
    }
  }
};

export const eigenWoningSchuldAsyncBerekening = createISWAsyncSideEffect<EigenwoningschuldModalCompleet, AsyncContext>(
  async ({ draft, settings, fetchData, params, context }) => {
    const result = await fetchData<EwsOutput, EwsInput>({
      url: `${settings.klantdossiersFormsOrigin}/Voorstellen/${params.voorstel}/Financieringsbehoefte/Eigenwoningschuld`,
      body: mapEwsInput(draft, context.financieringsBehoefte)
    });
    mapEwsOutput(draft, result);
  }
);

export const eigenWoningSchuldAsyncSideEffects = initISWAsyncSideEffect<EigenwoningschuldModalCompleet, AsyncContext>(
  ({ runAsync, context }) => {
    runAsync(eigenWoningSchuldAsyncBerekening(context));
  }
);
