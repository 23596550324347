import {
  BerekenCurrencyInput,
  LabeledBevestigingInput,
  LabeledCurrencyInput,
  LabeledJaarMaandInput,
  LabeledRadioInput,
  LabeledSelectInput
} from "adviesbox-shared";
import { useFormikContext } from "formik";
import React, { ReactElement } from "react";
import { AovVerzekerdeDekkingenOptions, AovVoornaamsteInkomstenbronOptions } from "../../.generated/forms/formstypes";
import { enum2options } from "../../shared/utils/enum-to-options";
import { AovsType, PartijProduct } from "../infra/aov-schema";
import { getDekkingGegevensVerzekerDefaults } from "../infra/defaults";
import { mapPartijProduct } from "../infra/map-aov-dl-to-ui";
import { claimBeoordelingOptions, dekkingOptions, DekkingProps, eigenRisicoOptions } from "./dekking";
import { UitkeringGedeeltelijkeAo } from "./uitkering-gedeeltelijke-ao";

export const DekkingWoonlasten = ({ selected, kenmerken, verzekerde }: DekkingProps): ReactElement => {
  const { values } = useFormikContext<AovsType>();
  const partijProductCode = mapPartijProduct(
    values.producten[selected].partijCode,
    values.producten[selected].productCode
  );
  const dekkingGegevens =
    verzekerde === 1
      ? values.producten[selected].dekking.dekkingGegevensVerzekerde1
      : values.producten[selected].dekking.dekkingGegevensVerzekerde2 ??
        getDekkingGegevensVerzekerDefaults(
          mapPartijProduct(values.producten[selected].partijCode, values.producten[selected].productCode)
        );

  return (
    <>
      {kenmerken.maandinkomenTonen && (
        <BerekenCurrencyInput
          caption="Maandinkomen"
          name={`producten[${selected}].dekking.maandinkomen`}
          readonly={!kenmerken.maandinkomenEnabled}
        />
      )}
      {kenmerken.gewenstGegarandeerdMaandinkomenTonen && (
        <LabeledCurrencyInput
          caption="Gewenst gegarandeerd maandinkomen"
          name={`producten[${selected}].dekking.maandinkomenGegarandeerd`}
        />
      )}

      {kenmerken.voornaamsteInkomstenbronTonen && (
        <LabeledRadioInput
          caption="Voornaamste inkomstenbron"
          name={`producten[${selected}].dekking.dekkingGegevensVerzekerde${verzekerde}.voornaamsteInkomstenbron`}
          options={enum2options(AovVoornaamsteInkomstenbronOptions)}
        />
      )}
      {kenmerken.verzekerdeDekkingTonen && (
        <LabeledSelectInput
          caption={partijProductCode === PartijProduct.OnafhankelijkWoonlasten ? "Verzekerde dekking(en)" : "Dekking"}
          name={`producten[${selected}].dekking.dekkingGegevensVerzekerde${verzekerde}.dekking`}
          readonly={
            dekkingOptions(
              kenmerken,
              dekkingGegevens.voornaamsteInkomstenbron !== AovVoornaamsteInkomstenbronOptions.Zelfstandige,
              partijProductCode
            ).length === 1
          }
          options={dekkingOptions(
            kenmerken,
            dekkingGegevens.voornaamsteInkomstenbron !== AovVoornaamsteInkomstenbronOptions.Zelfstandige,
            partijProductCode
          )}
        />
      )}
      {kenmerken.heeftVerzekerdeDekkingAo &&
        (dekkingGegevens.dekking === AovVerzekerdeDekkingenOptions.Ao ||
          dekkingGegevens.dekking === AovVerzekerdeDekkingenOptions.AoEa ||
          dekkingGegevens.dekking === AovVerzekerdeDekkingenOptions.AoWw ||
          dekkingGegevens.dekking === AovVerzekerdeDekkingenOptions.AoWwEa) && (
          <div>
            <h2 className="ml-3 mt-2">Arbeidsongeschiktheid</h2>
            <LabeledCurrencyInput
              caption="Verzekerd maandbedrag AO"
              name={`producten[${selected}].dekking.dekkingGegevensVerzekerde${verzekerde}.ao.verzekerdBedrag`}
              readonly={!kenmerken.verzekerdBedragAoEnabled}
            />
            {kenmerken.indexatieAoTonen && (
              <LabeledBevestigingInput
                caption={"Indexatie tijdens uitkering"}
                name={`producten[${selected}].dekking.indexatieTijdensUitkering`}
              />
            )}
            {kenmerken.looptijdAoTonen && (
              <LabeledJaarMaandInput
                caption="Looptijd"
                name={`producten[${selected}].dekking.dekkingGegevensVerzekerde${verzekerde}.ao.looptijd`}
              />
            )}
            {kenmerken.uitkeringsduurAoTonen && (
              <LabeledJaarMaandInput
                caption="Uitkeringsduur"
                name={`producten[${selected}].dekking.dekkingGegevensVerzekerde${verzekerde}.ao.uitkeringsDuur`}
              />
            )}
            {kenmerken.eigenRisicoAoTonen && (
              <LabeledRadioInput
                caption="Eigen risico"
                name={`producten[${selected}].dekking.dekkingGegevensVerzekerde${verzekerde}.ao.eigenRisico`}
                options={eigenRisicoOptions(kenmerken)}
              />
            )}
            {kenmerken.claimBeoordelingAoTonen && (
              <LabeledRadioInput
                caption="Claimbeoordeling"
                name={`producten[${selected}].dekking.dekkingGegevensVerzekerde${verzekerde}.beoordelingscriterium`}
                options={claimBeoordelingOptions()}
              />
            )}
            {kenmerken.uitkeringGedeeltelijkAoTonen && (
              <UitkeringGedeeltelijkeAo kenmerken={kenmerken} selected={selected} verzekerde={verzekerde} />
            )}
          </div>
        )}
      {kenmerken.heeftVerzekerdeDekkingWw &&
        (dekkingGegevens.dekking === AovVerzekerdeDekkingenOptions.Ww ||
          dekkingGegevens.dekking === AovVerzekerdeDekkingenOptions.AoWw ||
          dekkingGegevens.dekking === AovVerzekerdeDekkingenOptions.AoWwEa) && (
          <div>
            <h2 className="ml-3 mt-2">Werkloosheid (WW)</h2>
            {kenmerken.verzekerdBedragWwEnabled && (
              <LabeledCurrencyInput
                caption="Verzekerd maandbedrag"
                name={`producten[${selected}].dekking.dekkingGegevensVerzekerde${verzekerde}.ww.verzekerdBedrag`}
                readonly={!kenmerken.verzekerdBedragWwEnabled}
              />
            )}
            {kenmerken.looptijdWwTonen && (
              <LabeledJaarMaandInput
                caption="Looptijd"
                name={`producten[${selected}].dekking.dekkingGegevensVerzekerde${verzekerde}.ww.looptijd`}
                readonly={!kenmerken.looptijdWwEnabled}
              />
            )}
            {kenmerken.wwUitkeringdduurTonen &&
              (kenmerken.heeftWwUitkeringdduur12Maanden || kenmerken.HeeftWwUitkeringdduur24Maanden ? (
                <LabeledRadioInput
                  caption="Uitkeringsduur"
                  name={`producten[${selected}].dekking.dekkingGegevensVerzekerde${verzekerde}.ww.uitkeringsDuurInMaanden`}
                  options={[
                    { label: "12 maanden", value: "12" },
                    { label: "24 maanden", value: "24" }
                  ]}
                />
              ) : (
                <LabeledJaarMaandInput
                  caption="Uitkeringsduur"
                  name={`producten[${selected}].dekking.dekkingGegevensVerzekerde${verzekerde}.ww.uitkeringsDuur`}
                  readonly={!kenmerken.uitkeringsduurWwEnabled}
                />
              ))}
          </div>
        )}
      {kenmerken.heeftVerzekerdeDekkingEa &&
        (dekkingGegevens.dekking === AovVerzekerdeDekkingenOptions.Ea ||
          dekkingGegevens.dekking === AovVerzekerdeDekkingenOptions.AoEa ||
          dekkingGegevens.dekking === AovVerzekerdeDekkingenOptions.AoWwEa) && (
          <div>
            <h2>Ernstige aandoeningen (EA)</h2>
            <LabeledCurrencyInput
              caption="Verzekerd maandbedrag"
              name={`producten[${selected}].dekking.dekkingGegevensVerzekerde${verzekerde}.ea.verzekerdBedrag`}
            />
            <LabeledJaarMaandInput
              caption="Looptijd"
              name={`producten[${selected}].dekking.dekkingGegevensVerzekerde${verzekerde}.ea.looptijd`}
            />
          </div>
        )}
    </>
  );
};
