import React, { ReactElement } from "react";
import Aanleiding from "./aanleiding";
import { useAdviesboxData } from "../shared/hooks/use-adviesbox-data";
import { mapAanleidingDlNaarUi } from "./infra/map-aanleiding-dl-to-ui";
import { mapAanleidingUiNaarDl } from "./infra/map-aanleiding-ui-to-dl";
import { mapAanleidingDlTargetToUiField } from "./infra/map-aanleiding-dl-target-to-ui-field";
import { PageLoading } from "adviesbox-shared";
import { ErrorPage } from "adviesbox-shared";
import { useInstellingenAdviesonderwerpenData } from "../shared/hooks/use-instellingen-adviesonderwerpen-data";
import { createAanleidingUrl, getDossierId } from "./infra/aanleiding-url";
import { InvalidAdviesboxResultErrorPage } from "../shared/components/fetch-error-page/fetch-error-page";

const AanleidingAjax = (): ReactElement | null => {
  const {
    data: instellingenAdviesonderwerpenData,
    error: errorInstellingenAdviesonderwerpen,
    loading: loadingInstellingenAdviesonderwerpen
  } = useInstellingenAdviesonderwerpenData();

  const { loading, error, data, platformData, saveData } = useAdviesboxData(
    createAanleidingUrl,
    getDossierId,
    mapAanleidingDlNaarUi(instellingenAdviesonderwerpenData),
    mapAanleidingUiNaarDl,
    mapAanleidingDlTargetToUiField
  );

  if (loading || loadingInstellingenAdviesonderwerpen) {
    return <PageLoading />;
  }

  if (error) {
    return <ErrorPage error={error} data={platformData} />;
  }

  if (errorInstellingenAdviesonderwerpen) {
    return (
      <ErrorPage error={errorInstellingenAdviesonderwerpen} data={JSON.stringify(instellingenAdviesonderwerpenData)} />
    );
  }

  if (!data || !instellingenAdviesonderwerpenData) {
    return <InvalidAdviesboxResultErrorPage />;
  }

  return (
    <Aanleiding saveData={saveData} data={data} adviesonderwerpenInstellingenData={instellingenAdviesonderwerpenData} />
  );
};

AanleidingAjax.displayName = "AanleidingAjax";

export default AanleidingAjax;
