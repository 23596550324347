import { AcceptatieAanvrager } from "../../../.generated/forms/formstypes";
import { KlantnaamType } from "../../generic-parts/klantnaam/schema";
import { AanvragerKeuze } from "../../types";
import { getNaam } from "../../utils/helpers";

export function getAanvragersNaam(
  aanvragerKeuze: AanvragerKeuze | null,
  aanvrager1?: KlantnaamType | AcceptatieAanvrager | null,
  aanvrager2?: KlantnaamType | AcceptatieAanvrager | null
): string {
  const a1 = aanvrager1 === undefined ? null : aanvrager1;
  const a2 = aanvrager2 === undefined ? null : aanvrager2;
  switch (aanvragerKeuze) {
    case AanvragerKeuze.Beiden:
      return "Beiden";
    case AanvragerKeuze.Aanvrager1:
      return getNaam(a1, "Aanvrager 1");
    case AanvragerKeuze.Aanvrager2:
      return getNaam(a2, "Aanvrager 2");
  }
  return "niet geselecteerd";
}
