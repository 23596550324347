// Fiscale gegevens verplaatst naar hypotheek (wordt alleen daar gebruikt). TODO: Fiscale gegevens uit productoverzicht halen (en waar nodig verplaatsen naar hypotheek)
import { FiscalegegevensType } from "./producten-overzicht-types";

import {
  bepaalFiscaleGegevensEinddatum,
  adjustPercentageToBedrag,
  adjustBedragToPercentage,
  adjustPercentageToPercentage
} from "./producten-helper";
import { LocalDate } from "@js-joda/core";
import { getRenteaftrekAanvangstdatum, getRenteaftrekEinddatum } from "./determine-producten-overzicht-side-effects";
import { createISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";
import { hasValue } from "../../shared/utils/helpers";
import { optellen } from "../../shared/utils/currency";

export type FiscaleBenodigdheden = {
  leningBedrag: number | null;
  leningBedragGewijzigd: boolean;
  eigenwoningSchuld: number | null;
  ingangsdatum: LocalDate | null;
};

const initializeNumbers = (fiscalegegevens: FiscalegegevensType): void => {
  if (!hasValue(fiscalegegevens.deelBox1Percentage)) {
    fiscalegegevens.deelBox1Percentage = 0;
  }
  if (!hasValue(fiscalegegevens.deelBox1Bedrag)) {
    fiscalegegevens.deelBox1Bedrag = 0;
  }
  if (!hasValue(fiscalegegevens.deelBox3Percentage)) {
    fiscalegegevens.deelBox3Percentage = 0;
  }
  if (!hasValue(fiscalegegevens.deelBox3Bedrag)) {
    fiscalegegevens.deelBox3Bedrag = 0;
  }
};

const calcBox1Box3 = (fiscalegegevens: FiscalegegevensType, leningdeelBedrag: number): void => {
  fiscalegegevens.deelBox1Percentage = (fiscalegegevens.deelBox1Bedrag / leningdeelBedrag) * 100;
  if (fiscalegegevens.deelBox1Percentage > 100) {
    fiscalegegevens.deelBox1Percentage = 100;
  }
  fiscalegegevens.deelBox3Percentage = 100 - fiscalegegevens.deelBox1Percentage;
  fiscalegegevens.deelBox3Bedrag = (leningdeelBedrag / 100) * fiscalegegevens.deelBox3Percentage;
};

export const fiscaleGegevensSideEffects = createISWSideEffect<FiscalegegevensType, FiscaleBenodigdheden>(
  ({ has, draft, prev, context }) => {
    if (context === undefined || prev === undefined) {
      return;
    }
    const leningBedrag = context.leningBedrag;
    const ingangsdatum = context.ingangsdatum;

    if (leningBedrag === null) {
      return;
    }

    if (
      has.begindatumRenteaftrek.changed &&
      draft.begindatumRenteaftrek &&
      ingangsdatum &&
      draft.renteaftrekSpecificatie.renteAftrekken &&
      draft.renteaftrekSpecificatie.renteAftrekken.length === 1
    ) {
      if (draft.begindatumRenteaftrek > ingangsdatum) {
        draft.begindatumRenteaftrek = ingangsdatum;
      }
      if (draft.renteaftrekSpecificatie.renteAftrekken[0].aanvangsdatum !== draft.begindatumRenteaftrek) {
        draft.renteaftrekSpecificatie.renteAftrekken[0].aanvangsdatum = draft.begindatumRenteaftrek;
        draft.renteaftrekSpecificatie.renteAftrekken[0].einddatum = draft.einddatumRenteaftrek = bepaalFiscaleGegevensEinddatum(
          draft.begindatumRenteaftrek
        );
      }
    }

    if (
      has.renteaftrekSpecificatie.renteAftrekken.changed &&
      draft.renteaftrekSpecificatie.renteAftrekken &&
      prev.renteaftrekSpecificatie.renteAftrekken
    ) {
      draft.begindatumRenteaftrek = getRenteaftrekAanvangstdatum(draft.renteaftrekSpecificatie.renteAftrekken);
      draft.einddatumRenteaftrek = getRenteaftrekEinddatum(draft.renteaftrekSpecificatie.renteAftrekken);

      const opgeteldeRenteAftrekken = optellen(draft.renteaftrekSpecificatie.renteAftrekken.map(c => c.bedrag)) || 0;
      draft.deelBox1Bedrag =
        draft.deelBox1Bedrag !== opgeteldeRenteAftrekken && opgeteldeRenteAftrekken > 0
          ? opgeteldeRenteAftrekken
          : draft.deelBox1Bedrag;
    }

    if (context.leningBedragGewijzigd) {
      // Box 1 maximaliseren op eigenwoningschuld
      if ((context.leningBedrag || 0) > (context.eigenwoningSchuld || 0)) {
        draft.deelBox1Bedrag = context.eigenwoningSchuld || 0;
      } else {
        draft.deelBox1Bedrag = context.leningBedrag || 0;
      }
      calcBox1Box3(draft, leningBedrag);
    } else {
      initializeNumbers(draft);
      if (
        typeof draft.deelBox3Percentage === "number" &&
        leningBedrag &&
        Math.abs(draft.deelBox3Percentage - (prev.deelBox3Percentage || 0)) >= 0.01
      ) {
        draft.deelBox3Bedrag = adjustPercentageToBedrag(draft.deelBox3Bedrag, draft.deelBox3Percentage, leningBedrag);
      }
      if (
        typeof draft.deelBox1Percentage === "number" &&
        leningBedrag &&
        Math.abs(draft.deelBox1Percentage - (prev.deelBox1Percentage || 0)) >= 0.01
      ) {
        draft.deelBox1Bedrag = adjustPercentageToBedrag(draft.deelBox1Bedrag, draft.deelBox1Percentage, leningBedrag);
      }
      if (leningBedrag && Math.abs(draft.deelBox1Bedrag - (prev.deelBox1Bedrag || 0)) >= 1) {
        if (draft.deelBox1Bedrag > leningBedrag) {
          draft.deelBox1Bedrag = leningBedrag;
        }
        draft.deelBox3Bedrag = leningBedrag - draft.deelBox1Bedrag;
        draft.deelBox1Percentage = adjustBedragToPercentage(
          draft.deelBox1Percentage,
          draft.deelBox1Bedrag,
          leningBedrag
        );
      }
      if (leningBedrag && Math.abs(draft.deelBox3Bedrag - (prev.deelBox3Bedrag || 0)) >= 1) {
        if (draft.deelBox3Bedrag > leningBedrag) {
          draft.deelBox3Bedrag = leningBedrag;
        }
        draft.deelBox3Percentage = adjustBedragToPercentage(
          draft.deelBox3Percentage,
          draft.deelBox3Bedrag,
          leningBedrag
        );
        draft.deelBox1Percentage = adjustPercentageToPercentage(draft.deelBox1Percentage, draft.deelBox3Percentage);
        draft.deelBox1Bedrag = adjustPercentageToBedrag(draft.deelBox1Bedrag, draft.deelBox1Percentage, leningBedrag);
      }
    }

    if (
      prev.deelBox1Bedrag === 0 &&
      has.deelBox1Bedrag.changed &&
      draft.renteaftrekSpecificatie.renteAftrekken &&
      draft.renteaftrekSpecificatie.renteAftrekken.length === 0
    ) {
      const einddatum = ingangsdatum ? bepaalFiscaleGegevensEinddatum(ingangsdatum) : LocalDate.of(2031, 1, 1);
      draft.renteaftrekSpecificatie.renteAftrekken.push({
        aanvangsdatum: ingangsdatum,
        einddatum: einddatum,
        bedrag: (draft.deelBox1Bedrag || 0) + (draft.deelBox3Bedrag || 0)
      });
      draft.begindatumRenteaftrek = draft.renteaftrekSpecificatie.renteAftrekken[0].aanvangsdatum;
      draft.einddatumRenteaftrek = draft.renteaftrekSpecificatie.renteAftrekken[0].einddatum;
    }
    if (
      draft.deelBox1Bedrag === 0 &&
      has.deelBox1Bedrag.changed &&
      draft.renteaftrekSpecificatie.renteAftrekken.length > 0
    ) {
      draft.renteaftrekSpecificatie.renteAftrekken = [];
      draft.begindatumRenteaftrek = null;
      draft.einddatumRenteaftrek = null;
    }
  }
);
