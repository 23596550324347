import { createISWSideEffect } from "../../../shared/components/isw-side-effects/create-isw-helpers";
import { hasValue } from "../../../shared/utils/helpers";
import { InkomensverledenType } from "../inkomen-en-fiscus-schema";

export const determineGemiddeldInkomensVerleden = createISWSideEffect<InkomensverledenType>(({ has, draft }): void => {
  if ((has.resultaat1.changed || has.resultaat2.changed || has.resultaat3.changed) && hasValue(draft.resultaat1)) {
    let gemiddelde =
      ((!draft.resultaat1 ? 0 : draft.resultaat1) +
        (!draft.resultaat2 ? 0 : draft.resultaat2) +
        (!draft.resultaat3 ? 0 : draft.resultaat3)) /
      3;
      
    gemiddelde = draft.resultaat1 < gemiddelde ? draft.resultaat1 : gemiddelde;
    draft.gemiddeld = gemiddelde;
  }

  if (
    ((has.resultaat1.changed || has.resultaat2.changed || has.resultaat3.changed) && !hasValue(draft.resultaat1)) ||
    !hasValue(draft.resultaat1)
  ) {
    draft.gemiddeld = null;
  }
});
