import React, { ReactElement, useContext } from "react";
import { FormikProps } from "formik";

import { aanvullingInkomenOverlijdenModalAsyncSideEffects } from "./determine-async-aanvulling-inkomen-bij-overlijden-modal-side-effects";
import {
  LabeledCurrencyInput,
  LabeledSelectInput,
  LabeledBevestigingInput,
  LabeledPercentageInput,
  LabeledJaarMaandInput
} from "adviesbox-shared";
import AdviesBoxFormik from "../../shared/utils/adviesbox-formik";
import Modal from "../../shared/components/modal/Modal";
import { AanvullingInkomenBijOverlijdenModalType } from "../infra/producten-overzicht-types";
import { aanvullingInkomenBijOverlijdenModalSchema } from "../infra/producten-overzicht-schema";
import { ISWSideEffects } from "../../shared/components/isw-side-effects/isw-side-effects";
import { determineAanvullingInkomenSideEffects } from "./determine-aanvulling-inkomen-bij-overlijden-modal-side-effects";
import { AppDataContext } from "../../navigation/appdata-context";

export type AanvullingInkomenBijOverlijdenModalData = {
  data: AanvullingInkomenBijOverlijdenModalType;
  verzekerdKapitaalAanvrager1: number | null;
  verzekerdKapitaalAanvrager2: number | null;
};

type AanvullingInkomenBijOverlijdenModalProps = {
  onSave?: (data: AanvullingInkomenBijOverlijdenModalType) => void;
  closeModal?: () => void;
};

type AanvullingInkomenBijOverlijdenPerAanvragerProps = {
  values: AanvullingInkomenBijOverlijdenModalType;
  verzekerdKapitaalAanvrager: number | null;
  aanvrager: 1 | 2;
};

const AanvullingInkomenBijOverlijdenPerAanvrager = ({
  values,
  verzekerdKapitaalAanvrager,
  aanvrager
}: AanvullingInkomenBijOverlijdenPerAanvragerProps): ReactElement => {
  const aanvullingInkomenBijOverlijdenAanvrager =
    aanvrager === 1 ? values.aanvullingInkomenBijOverlijdenAanvrager1 : values.aanvullingInkomenBijOverlijdenAanvrager2;

  return (
    <>
      <LabeledBevestigingInput
        caption={`Aanvulling bij overlijden van aanvrager ${aanvrager}`}
        disabled={!verzekerdKapitaalAanvrager}
        name={`aanvullingInkomenBijOverlijdenAanvrager${aanvrager}`}
      />

      {aanvullingInkomenBijOverlijdenAanvrager && (
        <>
          <LabeledPercentageInput
            caption="Gehanteerd tarief"
            name={`gehanteerdTariefAanvrager${aanvrager}`}
            decimalen={2}
          />

          <LabeledCurrencyInput caption="Gewenste uitkering" name={`gewensteUitkeringAanvrager${aanvrager}`} />

          <LabeledSelectInput
            caption="Gewenste uitkering per"
            name={`gewensteUitkeringPerPeriodeAanvrager${aanvrager}`}
            options={[
              { label: "Maand", value: "Maand" },
              { label: "Twee maanden", value: "TweeMaanden" },
              { label: "Kwartaal", value: "Kwartaal" },
              { label: "Half jaar", value: "HalfJaar" },
              { label: "Jaar", value: "Jaar" }
            ]}
          />

          <LabeledJaarMaandInput caption="Duur uitkering" name={`duurUitkeringAanvrager${aanvrager}`} readonly />
        </>
      )}
    </>
  );
};

type AanvullingInkomenBijOverlijdenProps = {
  values: AanvullingInkomenBijOverlijdenModalType;
  verzekerdKapitaalAanvrager1: number | null;
  verzekerdKapitaalAanvrager2: number | null;
};

const AanvullingInkomenBijOverlijden = ({
  values,
  verzekerdKapitaalAanvrager1,
  verzekerdKapitaalAanvrager2
}: AanvullingInkomenBijOverlijdenProps): ReactElement => (
  <>
    <AanvullingInkomenBijOverlijdenPerAanvrager
      values={values}
      aanvrager={1}
      verzekerdKapitaalAanvrager={verzekerdKapitaalAanvrager1}
    />
    <AanvullingInkomenBijOverlijdenPerAanvrager
      values={values}
      aanvrager={2}
      verzekerdKapitaalAanvrager={verzekerdKapitaalAanvrager2}
    />
  </>
);

const AanvullingInkomenBijOverlijdenModal = ({
  data,
  verzekerdKapitaalAanvrager1,
  verzekerdKapitaalAanvrager2,
  onSave,
  closeModal
}: AanvullingInkomenBijOverlijdenModalData & AanvullingInkomenBijOverlijdenModalProps): ReactElement => {
  const { setSErunning } = useContext(AppDataContext);

  return (
    <AdviesBoxFormik<AanvullingInkomenBijOverlijdenModalType>
      initialValues={{ ...data }}
      validationSchema={aanvullingInkomenBijOverlijdenModalSchema}
      onSave={onSave}
      closeModal={closeModal}
      render={({ values, submitForm }: FormikProps<AanvullingInkomenBijOverlijdenModalType>): ReactElement => (
        <>
          <ISWSideEffects<AanvullingInkomenBijOverlijdenModalType>
            sync={determineAanvullingInkomenSideEffects}
            async={aanvullingInkomenOverlijdenModalAsyncSideEffects}
            asyncStartStopCallback={setSErunning}
          />
          <Modal
            title="Aanvulling inkomen bij overlijden"
            body={
              <AanvullingInkomenBijOverlijden
                values={values}
                verzekerdKapitaalAanvrager1={verzekerdKapitaalAanvrager1}
                verzekerdKapitaalAanvrager2={verzekerdKapitaalAanvrager2}
              />
            }
            onSubmitClick={submitForm}
            onCancelClick={closeModal}
          />
        </>
      )}
    />
  );
};

AanvullingInkomenBijOverlijdenModal.displayName = "AanvullingInkomenBijOverlijdenModal";

export default AanvullingInkomenBijOverlijdenModal;
