import React, { ReactElement, Suspense, useMemo } from "react";
import { DataDisplay, Loading } from "../data-display/data-display";
import { useRequestInit, bedragFormat } from "adviesbox-shared";
import { AcceptatieOutput, Acceptatie } from "../../../.generated/forms/formstypes";
import "./hypotheek-acceptatie.scss";
import classNames from "classnames";

function extractFirstProperty<T>(obj: any): { guid: string; result: T } | null {
  const guids = Object.keys(obj);
  if (guids.length === 0) return null;
  const guid = guids[0];
  return { guid, result: obj[guid] };
}

function getHypotheekAcceptatieUrl(origin: string, voorstel: string): string {
  return `${origin}/Voorstellen/${voorstel}/Acceptatie`;
}

interface AcceptatieRowProps {
  title: string;
  isPositive: boolean | undefined;
  label: string;
}

function AcceptatieRow({ title, isPositive, label }: AcceptatieRowProps): ReactElement {
  return (
    <div className="row" title={title}>
      <div className="col-1">
        <span className={classNames({ "acceptatie-status": !isPositive })}>{isPositive ? "✅" : "🟥"}</span>
      </div>
      <div className="col-8">
        <span>{label}</span>
      </div>
    </div>
  );
}

function AcceptatieContent({ acceptatie }: { acceptatie?: { guid: string; result: Acceptatie } | null }): ReactElement {
  return (
    <>
      <AcceptatieRow
        title="maximale hypotheek op basis van inkomen"
        isPositive={acceptatie?.result.hypotheek?.maximaleHypotheekInkomenIndicatie}
        label={`LTI ${bedragFormat(acceptatie?.result.hypotheek?.maximaleHypotheekInkomenBedrag ?? 0, 0)}`}
      />
      <AcceptatieRow
        title="maximale hypotheek op basis van marktwaarde"
        isPositive={acceptatie?.result.hypotheek?.maximaleHypotheekOnderpandIndicatie}
        label={`LTV ${bedragFormat(acceptatie?.result.hypotheek?.maximaleHypotheekOnderpandBedrag ?? 0, 0)}`}
      />
      <AcceptatieRow
        title="Indicatie"
        isPositive={acceptatie?.result.indicatie?.indicatie === "Goedgekeurd"}
        label="Indicatie"
      />
    </>
  );
}

export const HypotheekAcceptatieResultaat = ({
  onClick,
  situatie
}: {
  onClick: () => void;
  situatie: string;
}): ReactElement => {
  const { settings, params } = useRequestInit();

  const getHypotheekAcceptatie = useMemo(
    () => getHypotheekAcceptatieUrl(settings.klantdossiersFormsOrigin, params.voorstel || ""),
    [settings.klantdossiersFormsOrigin, params.voorstel]
  );

  return (
    <Suspense fallback={<Loading />}>
      <button type="button" className="new-dashboard__entry-btn" data-testid="button-testid" onClick={onClick}>
        <h2 className="pt-1 mt-0">HYPOTHEEKACCEPTATIE</h2>
        <DataDisplay
          endpoint={getHypotheekAcceptatie}
          autostart={false}
          isVoorstel={situatie === "voorstel"}
          render={(data: AcceptatieOutput) => {
            const acceptatie = extractFirstProperty<Acceptatie>(data?.acceptaties);
            return <AcceptatieContent acceptatie={acceptatie} />;
          }}
        />
      </button>
    </Suspense>
  );
};
