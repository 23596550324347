import { AuthContext } from "adviesbox-shared";
import React, { ReactElement } from "react";
import AuthenticatedAdviesBoxApp from "./AuthenticatedAdviesBoxApp";
import UnauthenticatedAdviesBoxApp from "./UnauthenticatedAdviesBoxApp";

const AdviesboxApp = (): ReactElement => {
  const { user } = React.useContext(AuthContext);

  if (user) {
    return <AuthenticatedAdviesBoxApp />;
  }

  return <UnauthenticatedAdviesBoxApp />;
};

AdviesboxApp.displayName = "AdviesBoxApp";

export default AdviesboxApp;
