/* istanbul ignore file */
import {
  Card,
  CardWrapper,
  DataGrid,
  ErrorPage,
  hasValue,
  ModalButton,
  PageLoading,
  useRequestInit,
  PlatformFoutenSamenvatting,
  FormFirstFocus,
  insightsReactPlugin
} from "adviesbox-shared";
import { Form, FormikContextType, FormikHelpers, useFormikContext } from "formik";
import * as React from "react";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AppDataContext } from "../navigation/appdata-context";
import { partijOnafhankelijk } from "../producten-overzicht/infra/product-constanten";
import { HypothekenKenmerken } from "../producten-overzicht/infra/product-kenmerken-types";
import { ProductWijzigingenMeenemenButton } from "../producten-overzicht/product-wijzigingen-meenemen-button/product-wijzigingen-meenemen-button";
import { DevDebug } from "../shared/components/dev-debug/dev-debug";
import { ISWSideEffects } from "../shared/components/isw-side-effects/isw-side-effects";
import { SaveButton } from "../shared/components/save-button/save-button";
import { useHdnData, UseHdnDataResult } from "../shared/hooks/use-hdn-data";
import { useHypotheekDetailsData } from "../shared/hooks/use-hypotheek-details-data";
import { useHypotheekVormData } from "../shared/hooks/use-hypotheekvorm-data";
import { WithSaveData } from "../shared/utils/save-data";
import { withAdviesboxFormik } from "../shared/utils/with-adviesbox-formik";
import { hypotheekColumns } from "./hypotheek-columns";
import HypotheekDetails from "./hypotheek-details";
import HypotheekSelectieAjax from "./hypotheek-selectie/hypotheek-selectie-ajax";
import { HypotheekTotaleHypotheekModal } from "./hypotheek-totale-hypotheek-modal/hypotheek-totale-hypotheek-modal";
import HypotheekTotalen from "./hypotheek-totalen/hypotheek-totalen";
import { HypotheekvergelijkenModal } from "./hypotheek-vergelijken-modal/hypotheek-vergelijken-modal";
import { newHypotheekVergelijkenModalFactory } from "./hypotheek-vergelijken-modal/hypotheek-vergelijken-modal-utils";
import { HypotheekVergelijkenModalType } from "./hypotheek-vergelijken-modal/hypotheek-vergelijken-types";
import { hypotheekAsyncSideEffects } from "./infra/determine-hypotheek-async-side-effects";
import { determineHypotheekDetailsSideEffects } from "./infra/determine-hypotheek-side-effects";
import { HypotheekSchemaContextType, hypothekenBaseSchema, kapitaalopbouwSchema } from "./infra/hypotheek-schema";
import { HypotheekType, HypothekenProps, HypothekenState, showEdit, HypotheeklabelType } from "./infra/hypotheek-types";
import { getKenmerkCodeForProduct } from "./infra/hypotheek-utils";
import { checkTotalen } from "./infra/hypotheek-validations";
import { useEffect } from "react";
import { premieGegevensSchema } from "../producten-overzicht/infra/producten-overzicht-schema";
import { AflossingsVormType, Financieringsoort } from "../.generated/forms/formstypes";
import { useFeature } from "adviesbox-shared";
import { Dashboard } from "../dashboard/dashboard";
import { withAITracking } from "@microsoft/applicationinsights-react-js";

export const onSaveHypotheekFunction = (
  result: HypotheekVergelijkenModalType,
  setFieldValue: FormikHelpers<HypothekenState>["setFieldValue"],
  values: HypothekenState
): void => {
  const nieuweHypotheek: HypotheekType[] = result.hypotheekSamenstelling
    .filter(c => !c.product.doorlopend && !c.hypotheekVorm.isStartersLening)
    .map((c, i) => {
      if (!result.selectedResultaat || !result.selectedResultaat.leningdelen) return c;
      const leningdelen = result.selectedResultaat.leningdelen.filter(x => !x.isStartersLening);
      const isSpaarrekening = result.selectedResultaat.aflossingsVorm === AflossingsVormType.Spaarrekening;
      return {
        ...c,
        partijCode: result.selectedResultaat.MaatschappijCode || "",
        productCode: result.selectedResultaat.hypotheekvormCode.toString() || "",
        leningdeelgegevens: {
          ...c.leningdeelgegevens,
          rentePercentage: {
            bedrag: null,
            berekendBedrag: null,
            berekenen: true
          },
          automatischeRentedaling: !values.nhg // indien nhg is er geen automatischerentedaling mogelijk
            ? !!result.selectedResultaat.AutomatischeRentedalingDirect ||
              !!result.selectedResultaat.AutomatischeRentedalingNaRvp
            : false
        },
        hypotheekVorm: {
          isRestschuldLening: leningdelen[i].isRestschuldLening,
          isStartersLening: leningdelen[i].isStartersLening,
          aflossingsvorm: leningdelen[i].aflossingsvorm,
          omschrijving: leningdelen[i].omschrijving,
          code: leningdelen[i].code
        },
        fiscalegegevens: {
          ...c.fiscalegegevens,
          renteaftrekSpecificatie: {
            renteAftrekken: !c.hypotheekVorm.isRestschuldLening // bij restschuld = box3, maken we geen renteaftrek aan initieel
              ? [
                  {
                    aanvangsdatum: c.fiscalegegevens.begindatumRenteaftrek,
                    einddatum: c.fiscalegegevens.einddatumRenteaftrek,
                    bedrag: c.fiscalegegevens.deelBox1Bedrag
                  }
                ]
              : []
          }
        },
        labelCode: result.selectedResultaat.ProductCode,
        labelNaam: result.selectedResultaat.Productnaam,
        volgnummer: i + 1,
        product: {
          ...c.product,
          partijNaam: result.selectedResultaat.MaatschappijNaam,
          doorlopend: false,
          looptijd: c.product.looptijd || 30,
          renteboxCode: leningdelen[i].renteboxCode,
          uwBemiddeling: c.product.uwBemiddeling,
          omschrijving: result.selectedResultaat.hypotheekomschrijving,
          ingangsdatum: c.product.ingangsdatum,
          einddatum: c.product.einddatum,
          partijCodeSelectie: result.selectedResultaat.MaatschappijCode,
          productNaam: result.selectedResultaat.Productnaam
        },
        hypotheekProductDetails: null,
        premieGegevens: isSpaarrekening
          ? { ...premieGegevensSchema.default(), looptijd: c.product.looptijd || 30 }
          : null,
        kapitaalopbouw: isSpaarrekening ? kapitaalopbouwSchema.default() : null
      };
    }) as HypotheekType[];

  const behoudBestaandeLeningdelen = values.producten.filter(
    c => c.product.doorlopend || c.hypotheekVorm.isStartersLening
  );

  if (nieuweHypotheek) {
    setFieldValue("producten", [...behoudBestaandeLeningdelen, ...nieuweHypotheek]);
  }
};

const Hypotheek = (props: FormikContextType<HypothekenState> & HypothekenProps): React.ReactElement => {
  const { hdnKeuzelijst }: UseHdnDataResult = useHdnData("AX", "CodeDeelMijType");
  const ingHdnData: [string, string][] =
    Object.keys(hdnKeuzelijst).length > 1 ? Object.entries(hdnKeuzelijst).filter(([k, _]) => k.includes("IN0")) : [];

  const [showWarningModal, setShowWarningModal] = React.useState(false);
  const { params } = useRequestInit<{ adviesdossier: string; voorstel?: string }>();
  const [productKenmerken, productKenmerkenMapActions] = props.productKenmerkenMap;

  const { values, setFieldValue } = useFormikContext<HypothekenState>();
  const selectedState = React.useState(0);
  const [selected, setSelectedState] = selectedState;
  const berekenState = React.useState(false);
  const { situatie, values: hypotheek, isSubmitting } = props;
  const selectedProduct = hypotheek && hypotheek.producten[selected];
  const huidigProductKenmerken = selectedProduct && productKenmerken.get(getKenmerkCodeForProduct(selectedProduct));
  const featureNewDashboard = useFeature("FeatureNewDashboard");

  // Als de geselecteerde row niet meer bestaat zet deze dan op 0
  useEffect(() => {
    if (!props.values.producten[selected] && !!props.values.producten.length) {
      setSelectedState(0);
    }
  });
  // Bepalen van de renteboxcode + rentevarianten

  const hypotheekvormenData = useHypotheekVormData(selectedProduct?.partijCode);

  const hypotheekDetailData = useHypotheekDetailsData(selectedProduct?.partijCode, selectedProduct?.labelCode);

  const [showWijzigen, setShowWijzigen] = React.useState<showEdit>({
    edit: false,
    visible: false,
    productOnly: false,
    omzetten: false
  });
  const { setSErunning } = React.useContext(AppDataContext);

  const [previousPath, setPreviousPath] = React.useState<string | null>(null);
  const [isCanceled, setIsCanceled] = React.useState<boolean>(false);
  const history = useHistory();
  const marktwaardeLtvRef = React.useRef(values.panden.find(() => true)?.marktwaardeLtv);

  const showLoading = isSubmitting || (values.producten[selected] && !productKenmerken.entries());
  const nieuwProduct = values.producten.find(p => p.partijCode !== partijOnafhankelijk) ?? null;

  useEffect(() => {
    if (previousPath && isCanceled) {
      history.push(previousPath);
    }
  }, [history, previousPath, isCanceled]);

  const additionalButton =
    situatie === "huidig" &&
    selectedProduct &&
    selectedProduct.product.wijzigingenInDoorlopendProductOvernemen !== null ? (
      <ProductWijzigingenMeenemenButton
        name={`producten[${selected}].product.wijzigingenInDoorlopendProductOvernemen`}
      />
    ) : situatie === "voorstel" ? (
      <>
        <div>
          <ModalButton
            className={"btn btn-primary ml-2"}
            parent={"hypotheekSamenstelling"}
            content={"Hypotheekvergelijker"}
            closeModal={() => {
              setFieldValue(`panden[0].marktwaardeLtv`, marktwaardeLtvRef.current);
            }}
            size="lg"
            disabled={
              !values.panden.every(c => hasValue(c.totaleHypotheekBedrag) && (c.totaleHypotheekBedrag ?? 0) > 0)
            }
          >
            <HypotheekvergelijkenModal
              data={newHypotheekVergelijkenModalFactory(values)}
              situatie={situatie}
              selected={selected}
              marktwaardeLtv={marktwaardeLtvRef}
              onSave={(result): void => {
                productKenmerkenMapActions.reset();
                onSaveHypotheekFunction(result, setFieldValue, values);
              }}
            />
          </ModalButton>
        </div>
        {!!values.producten.length && !values.producten.some(c => !c.product.doorlopend) && (
          <div>
            <ModalButton
              className={"btn btn-primary ml-2"}
              parent={"hypotheekLabel"}
              content={"Selecteer geldverstrekker"}
            >
              <HypotheekSelectieAjax
                situatie={situatie}
                hypotheek={hypotheek}
                onSaveProduct={(result: HypotheekType | Array<HypotheekType>): void => {
                  if (Array.isArray(result)) {
                    const nieuw = result.find(c => c.isNieuw === true);
                    if (!nieuw) return;
                    const hypotheeklabel: HypotheeklabelType = {
                      partijCode: nieuw.partijCode,
                      labelCode: nieuw.labelCode,
                      labelNaam: nieuw.labelNaam
                    };
                    setFieldValue(`hypotheeklabel`, hypotheeklabel);
                  }
                }}
                selectedNieuwProduct={nieuwProduct ? values.producten.indexOf(nieuwProduct) : undefined}
                geldverstrekkerOnly={true}
              />
            </ModalButton>
          </div>
        )}
      </>
    ) : (
      undefined
    );

  const preSaveConfirmation = (): void => {
    setShowWarningModal(true);
  };

  const corrigeerSelectedHypotheekLeningdeelHoofdsom = (hypotheek: HypotheekType): void => {
    const remaning =
      values.producten.reduce((prev, curr) => {
        return prev + (curr.leningdeelgegevens.leningdeelHoofdsom.bedrag ?? 0);
      }, 0) - (hypotheek.leningdeelgegevens.leningdeelHoofdsom.bedrag ?? 0);

    const correctedValue =
      (values.panden.reduce((prev, curr) => {
        return prev + (curr.totaleHypotheekBedrag ?? 0);
      }, 0) ?? null) - remaning;

    const selectedIndex = values.producten.indexOf(hypotheek);
    setFieldValue(`producten[${selectedIndex}].leningdeelgegevens.leningdeelHoofdsom.bedrag`, correctedValue);
    setShowWarningModal(false);
  };

  const triggerPreSaveConfirmation =
    !isCanceled &&
    situatie === "voorstel" &&
    values.producten.length &&
    values.producten.some(element => !element.product.doorlopend && !element.hypotheekVorm.isStartersLening)
      ? Math.round(
          values.producten.reduce((prev, curr) => {
            return prev + (curr.leningdeelgegevens.leningdeelHoofdsom.bedrag ?? 0);
          }, 0)
        ) !==
        Math.round(
          values.panden.reduce((prev, curr) => {
            return prev + (curr.totaleHypotheekBedrag ?? 0);
          }, 0)
        )
      : false;

  return (
    <FormFirstFocus>
      <Form>
        <div className="d-flex content_wrapper">
          <div className="content">
            {showLoading && <PageLoading />}

            {!showLoading &&
              !values.benodigdHypotheekbedragIngevuld &&
              values.benodigdHypotheekbedragIngevuld !== null && (
                <CardWrapper className="px-3 master-detail-card flex-grow-1">
                  <Card title="Overzicht" className="w-xl-100 w-lg-100 w-md-50 w-50">
                    <p className="p-3">
                      Als er in de huidige situatie een hypotheek op de woning zit, dan geef je dit aan op het scherm
                      &apos;Woonsituatie&apos; en vervolgens kun je hier de hypotheek toevoegen.
                    </p>
                  </Card>
                </CardWrapper>
              )}

            {!showLoading &&
              (values.benodigdHypotheekbedragIngevuld || values.benodigdHypotheekbedragIngevuld === null) && (
                <>
                  {!hypotheekDetailData.loading && !hypotheekvormenData.loading && (
                    <ISWSideEffects<HypothekenState>
                      sync={determineHypotheekDetailsSideEffects({
                        selected,
                        kenmerken: huidigProductKenmerken as HypothekenKenmerken,
                        nhg: hypotheek.nhg,
                        situatie
                      })}
                      async={hypotheekAsyncSideEffects({
                        selected,
                        berekenState,
                        situatie,
                        ingHdnData,
                        voorstelId: params.voorstel || "",
                        kenmerken: huidigProductKenmerken as HypothekenKenmerken,
                        kenmerkenMapActions: props.productKenmerkenMap,
                        hypotheekvormen: hypotheekvormenData?.hypotheekvormen,
                        hypotheekDetailData: hypotheekDetailData.data
                      })}
                      runOnFirstRender
                      asyncStartStopCallback={setSErunning}
                    />
                  )}
                  {hypotheekDetailData.error ? (
                    <ErrorPage error={hypotheekDetailData.error} />
                  ) : hypotheekvormenData.error ? (
                    <ErrorPage error={hypotheekvormenData.error} />
                  ) : (
                    <>
                      <div className="save-btn-position">
                        <div className="button-container">
                          <SaveButton
                            context={{
                              ...props
                            }}
                            triggerPreSaveConfirmation={triggerPreSaveConfirmation}
                            preSaveConfirmation={preSaveConfirmation}
                            setPreviousPath={setPreviousPath}
                            isCanceled={isCanceled}
                          />
                        </div>
                      </div>
                      <PlatformFoutenSamenvatting />
                      {(selectedProduct || situatie === "voorstel") && (
                        /* istanbul ignore next */ <HypotheekTotalen situatie={situatie} selected={selected} />
                      )}
                      <CardWrapper className="px-3 master-detail-card flex-grow-1" maxRowCount={4}>
                        <Card className="w-xl-100 w-lg-100 w-md-50 w-50">
                          <DataGrid
                            masterDetail={true}
                            rowCaption="Hypotheek"
                            popup={
                              <HypotheekSelectieAjax
                                situatie={situatie}
                                hypotheek={hypotheek}
                                onSaveProduct={() => {}} // Wordt in shared - datagrid.tsx afgehandeld
                                selectedNieuwProduct={nieuwProduct ? values.producten.indexOf(nieuwProduct) : undefined}
                              />
                            }
                            columns={hypotheekColumns({ setSelectedState, setShowWijzigen, situatie })}
                            rowSelected={selectedState}
                            name="producten"
                            additionalButton={additionalButton}
                          />
                        </Card>
                      </CardWrapper>
                      {selected > 0 &&
                        !huidigProductKenmerken &&
                        hypotheekDetailData.loading &&
                        hypotheekvormenData.loading && <PageLoading />}

                      {huidigProductKenmerken && values.producten[selected] && (
                        <HypotheekDetails
                          setShowWijzigen={setShowWijzigen}
                          selected={selected}
                          berekenState={berekenState}
                          situatie={situatie}
                          kenmerken={huidigProductKenmerken as HypothekenKenmerken}
                          hypotheekvormen={hypotheekvormenData}
                          hypotheekDetailData={hypotheekDetailData.data}
                        />
                      )}

                      <Modal keyboard={false} show={showWijzigen.visible} backdrop={"static"} size={"lg"}>
                        <HypotheekSelectieAjax
                          situatie={situatie}
                          hypotheek={values}
                          selectedNieuwProduct={selected}
                          closeModal={(): void => {
                            setShowWijzigen({ edit: false, visible: false, productOnly: false, omzetten: false });
                          }}
                          selectedRow={selected}
                          updateLeningdeel={true}
                          productOnly={showWijzigen.productOnly}
                          omzetten={showWijzigen.omzetten}
                          onSaveProduct={(result: HypotheekType | Array<HypotheekType>): void => {
                            productKenmerkenMapActions.reset();
                            if (Array.isArray(result)) {
                              setFieldValue("producten", result);
                              return;
                            }
                            if (result.soortOnderpand === Financieringsoort.Omzetting && !result.isNieuw) {
                              const hypotheeklabel: HypotheeklabelType = {
                                partijCode: result.partijCode,
                                labelCode: result.labelCode,
                                labelNaam: result.labelNaam
                              };
                              setFieldValue(`hypotheeklabel`, hypotheeklabel);
                            }

                            setFieldValue(`producten[${selected}]`, result);
                          }}
                        />
                      </Modal>
                    </>
                  )}
                </>
              )}
            <DevDebug />
          </div>
          {!featureNewDashboard && <Dashboard situatie={situatie} saveData={props.saveData} formikParent={props} />}
        </div>
      </Form>
      <HypotheekTotaleHypotheekModal
        setSelected={selectedState}
        onClose={() => {
          setIsCanceled(true);
          setShowWarningModal(false);
        }}
        onSubmit={selectedHypotheek => {
          setSelectedState(values.producten.indexOf(selectedHypotheek));
          corrigeerSelectedHypotheekLeningdeelHoofdsom(selectedHypotheek);
        }}
        situatie={situatie}
        values={values}
        showModal={showWarningModal}
      />
    </FormFirstFocus>
  );
};

Hypotheek.displayName = "Hypotheek";

export default withAdviesboxFormik<HypothekenProps & WithSaveData<HypothekenState>, HypothekenState>({
  mapPropsToValues: (e: HypothekenProps): HypothekenState => e,
  validationSchema: hypothekenBaseSchema,
  createValidationContext: (props, values): HypotheekSchemaContextType => {
    return {
      ...checkTotalen(values, props.situatie),
      geboorteDatumAanvrager1: values.aanvrager1?.geboortedatum || null,
      geboorteDatumAanvrager2: values.aanvrager2?.geboortedatum || null,
      productKenmerken: props.productKenmerkenMap
    };
  }
})(withAITracking(insightsReactPlugin, Hypotheek));
