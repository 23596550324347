/* istanbul ignore file */
import { ErrorPage, PageLoading, useRequestInit } from "adviesbox-shared";
import React, { ReactElement, useContext } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import AanleidingAjax from "../aanleiding/aanleiding-ajax";
import aanvraagLeningAjax from "../aanvraag-lening/aanvraag-lening-ajax";
import { AanvraagAjax } from "../aanvraag/aanvraag-ajax";
import { AovAjax } from "../aov/aov-ajax";
import bankgarantieAjax from "../bankgarantie/bankgarantie-ajax";
import DashboardProvider from "../dashboard/infra/dashboard-provider";
import FinancieringsbehoefteBerekeningenAjax from "../financieringsbehoefte/financieringsbehoefte-berekeningen-ajax";
import HypotheekOversluitenAjax from "../hypotheek-oversluiten/hypotheek-oversluiten-ajax";
import HypotheekAjax from "../hypotheek/hypotheek-ajax";
import InkomenEnFiscusAjax from "../inkomen-en-fiscus/inkomen-en-fiscus-ajax";
import KapitaalverzekeringAjax from "../kapitaalverzekering/kapitaalverzekering-ajax";
import AlgemeenDoelstellingAjax from "../klantprofiel/algemeen/doelstelling/doelstelling-ajax";
import AlgemeenRisicoEnKennisAjax from "../klantprofiel/algemeen/risico-en-kennis/risico-en-kennis-ajax";
import AlgemeenToekomstperspectiefAjax from "../klantprofiel/algemeen/toekomstperspectief/toekomstperspectief-ajax";
import HypotheekDubbeleWoonlastenAjax from "../klantprofiel/hypotheek/dubbele-woonlasten/dubbele-woonlasten-ajax";
import HypotheekEigenGeldAjax from "../klantprofiel/hypotheek/eigen-geld/eigen-geld-ajax";
import HypotheekFiscaalAjax from "../klantprofiel/hypotheek/fiscaal/fiscaal-ajax";
import HypotheekGeldverstrekkerAjax from "../klantprofiel/hypotheek/geldverstrekker/geldverstrekker-ajax";
import HypotheekalgemeenAjax from "../klantprofiel/hypotheek/hypotheek-algemeen/hypotheek-algemeen-ajax";
import HypotheekvormAjax from "../klantprofiel/hypotheek/hypotheekvorm/hypotheekvorm-ajax";
import HypotheekRentevastperiodeAjax from "../klantprofiel/hypotheek/rentevastperiode/rentevastperiode-ajax";
import HypotheekVerantwoordeWoonlastenAjax from "../klantprofiel/hypotheek/verantwoorde-woonlasten/verantwoorde-woonlasten-ajax";
import HypotheekVerduurzamingAjax from "../klantprofiel/hypotheek/verduurzaming/verduurzaming-ajax";
import RisicoArbeidsongeschiktheidAjax from "../klantprofiel/risicos/arbeidsongeschiktheid/arbeidsongeschiktheid-ajax";
import RisicoOverlijdenAjax from "../klantprofiel/risicos/overlijden/overlijden-ajax";
import RisicoPensioenAjax from "../klantprofiel/risicos/pensioen/pensioen-ajax";
import RisicoWerkloosheidAjax from "../klantprofiel/risicos/werkloosheid/werkloosheid-ajax";
import OpbouwDoelvermogenAjax from "../klantprofiel/vermogensopbouw/opbouw-doelvermogen/opbouw-doelvermogen-ajax";
import RisicoprofielAjax from "../klantprofiel/vermogensopbouw/risicoprofiel/risicoprofiel-ajax";
import KredietenAjax from "../kredieten/kredieten-ajax";
import MaximaleHypotheekAjax from "../maximale-hypotheek/maximale-hypotheek-ajax";
import { MotiveringAjax } from "../motivering/motivering-ajax";
import { AppDataContext } from "../navigation/appdata-context";
import OrvAjax from "../orv/orv-ajax";
import OverigePostenAjax from "../overige-posten/overige-posten-ajax";
import PensioenAjax from "../pensioen/pensioen-ajax";
import PersonaliaAjax from "../personalia/personalia-ajax";
import productenOverzichtAjax from "../producten-overzicht/producten-overzicht-ajax";
import { lezenEnSchrijvenRechten } from "../shared/components/lees-en-schrijf-rechten-helper";
import OpenDossierLogContext from "../shared/open-dossier-log/open-dossier-log-context";
import TaxatieAjax from "../taxatie/taxatie-ajax";
import UitkerendeLijfrenteAjax from "../uitkerende-lijfrente/uitkerende-lijfrente-ajax";
import VergoedingenOverzichtAjax from "../vergoedingen-overzicht/vergoedingen-overzicht-ajax";
import VermogenAjax from "../vermogen/vermogen-ajax";
import WoningAjax from "../woning/woning-ajax";
import WoonsituatieAjax from "../woonsituatie/woonsituatie-ajax";
import "./AuthenticatedAdviesBoxSchermen.scss";
import { AcceptatieAjax } from "../dashboard/new/hypotheek-acceptatie/acceptatie/acceptatie-ajax";

const KlantprofielSchermen = (): ReactElement => {
  return (
    <>
      <Switch>
        <Route
          path="/vestiging/:vestiging/adviesdossier/:adviesdossier/klantprofiel/doelstelling"
          component={AlgemeenDoelstellingAjax}
        />
        <Route
          path="/vestiging/:vestiging/adviesdossier/:adviesdossier/klantprofiel/toekomstperspectief"
          component={AlgemeenToekomstperspectiefAjax}
        />
        <Route
          path="/vestiging/:vestiging/adviesdossier/:adviesdossier/klantprofiel/risico-en-kennis"
          component={AlgemeenRisicoEnKennisAjax}
        />
        <Route
          path="/vestiging/:vestiging/adviesdossier/:adviesdossier/klantprofiel/hypotheek-algemeen"
          component={HypotheekalgemeenAjax}
        />
        <Route
          path="/vestiging/:vestiging/adviesdossier/:adviesdossier/klantprofiel/verduurzaming"
          component={HypotheekVerduurzamingAjax}
        />
        <Route
          path="/vestiging/:vestiging/adviesdossier/:adviesdossier/klantprofiel/verantwoorde-woonlasten"
          component={HypotheekVerantwoordeWoonlastenAjax}
        />
        <Route
          path="/vestiging/:vestiging/adviesdossier/:adviesdossier/klantprofiel/eigen-geld"
          component={HypotheekEigenGeldAjax}
        />
        <Route
          path="/vestiging/:vestiging/adviesdossier/:adviesdossier/klantprofiel/dubbele-woonlasten"
          component={HypotheekDubbeleWoonlastenAjax}
        />
        <Route
          path="/vestiging/:vestiging/adviesdossier/:adviesdossier/klantprofiel/fiscaal"
          component={HypotheekFiscaalAjax}
        />
        <Route
          path="/vestiging/:vestiging/adviesdossier/:adviesdossier/klantprofiel/hypotheekvorm"
          component={HypotheekvormAjax}
        />
        <Route
          path="/vestiging/:vestiging/adviesdossier/:adviesdossier/klantprofiel/rentevastperiode"
          component={HypotheekRentevastperiodeAjax}
        />
        <Route
          path="/vestiging/:vestiging/adviesdossier/:adviesdossier/klantprofiel/geldverstrekker"
          component={HypotheekGeldverstrekkerAjax}
        />
        <Route
          path="/vestiging/:vestiging/adviesdossier/:adviesdossier/klantprofiel/overlijden"
          component={RisicoOverlijdenAjax}
        />
        <Route
          path="/vestiging/:vestiging/adviesdossier/:adviesdossier/klantprofiel/arbeidsongeschiktheid"
          component={RisicoArbeidsongeschiktheidAjax}
        />
        <Route
          path="/vestiging/:vestiging/adviesdossier/:adviesdossier/klantprofiel/werkloosheid"
          component={RisicoWerkloosheidAjax}
        />
        <Route
          path="/vestiging/:vestiging/adviesdossier/:adviesdossier/klantprofiel/pensioen"
          component={RisicoPensioenAjax}
        />
        <Route
          path="/vestiging/:vestiging/adviesdossier/:adviesdossier/klantprofiel/opbouw-doelvermogen"
          component={OpbouwDoelvermogenAjax}
        />
        <Route
          path="/vestiging/:vestiging/adviesdossier/:adviesdossier/klantprofiel/risicoprofiel"
          component={RisicoprofielAjax}
        />
      </Switch>
    </>
  );
};

const HuidigeProductSchermen = (): ReactElement => {
  return (
    <>
      <DashboardProvider situatie={"huidig"}>
        <Switch>
          <Route path="/vestiging/:vestiging/adviesdossier/:adviesdossier/huidige/hypotheek">
            <HypotheekAjax situatie="huidig" />
          </Route>
          <Route path="/vestiging/:vestiging/adviesdossier/:adviesdossier/huidige/kapitaalverzekering">
            <KapitaalverzekeringAjax situatie="huidig" />
          </Route>
          <Route path="/vestiging/:vestiging/adviesdossier/:adviesdossier/huidige/orv">
            <OrvAjax situatie="huidig" />
          </Route>
          <Route path="/vestiging/:vestiging/adviesdossier/:adviesdossier/huidige/aov">
            <AovAjax situatie="huidig" />
          </Route>
          <Route path="/vestiging/:vestiging/adviesdossier/:adviesdossier/huidige/vermogen">
            <VermogenAjax situatie="huidig" />
          </Route>
          <Route path="/vestiging/:vestiging/adviesdossier/:adviesdossier/huidige/krediet">
            <KredietenAjax situatie="huidig" />
          </Route>
          <Route path="/vestiging/:vestiging/adviesdossier/:adviesdossier/huidige/uitkerende-lijfrente">
            <UitkerendeLijfrenteAjax situatie="huidig" />
          </Route>
          <Route path="/vestiging/:vestiging/adviesdossier/:adviesdossier/huidige/overige-posten">
            <OverigePostenAjax situatie="huidig" />
          </Route>
        </Switch>
      </DashboardProvider>
    </>
  );
};

const VoorstelProductSchermen = (): ReactElement => {
  return (
    <>
      <DashboardProvider situatie={"voorstel"}>
        <Switch>
          <Route path="/vestiging/:vestiging/adviesdossier/:adviesdossier/voorstel/:voorstel/producten/hypotheek">
            <HypotheekAjax situatie="voorstel" />
          </Route>
          <Route path="/vestiging/:vestiging/adviesdossier/:adviesdossier/voorstel/:voorstel/producten/kapitaalverzekering">
            <KapitaalverzekeringAjax situatie="voorstel" />
          </Route>
          <Route path="/vestiging/:vestiging/adviesdossier/:adviesdossier/voorstel/:voorstel/producten/orvs">
            <OrvAjax situatie="voorstel" />
          </Route>
          <Route path="/vestiging/:vestiging/adviesdossier/:adviesdossier/voorstel/:voorstel/producten/orv">
            <OrvAjax situatie="voorstel" />
          </Route>
          <Route path="/vestiging/:vestiging/adviesdossier/:adviesdossier/voorstel/:voorstel/producten/aov">
            <AovAjax situatie="voorstel" />
          </Route>
          <Route path="/vestiging/:vestiging/adviesdossier/:adviesdossier/voorstel/:voorstel/producten/vermogen">
            <VermogenAjax situatie="voorstel" />
          </Route>
          <Route path="/vestiging/:vestiging/adviesdossier/:adviesdossier/voorstel/:voorstel/producten/krediet">
            <KredietenAjax situatie="voorstel" />
          </Route>
          <Route path="/vestiging/:vestiging/adviesdossier/:adviesdossier/voorstel/:voorstel/producten/uitkerende-lijfrente">
            <UitkerendeLijfrenteAjax situatie="voorstel" />
          </Route>
          <Route path="/vestiging/:vestiging/adviesdossier/:adviesdossier/voorstel/:voorstel/producten/overige-posten">
            <OverigePostenAjax situatie="voorstel" />
          </Route>
        </Switch>
      </DashboardProvider>
    </>
  );
};

const AuthenticatedAdviesBoxSchermen = (props: {
  gebruikersVestigingIds?: string[];
  organisatieVestigingIds?: string[];
}): ReactElement => {
  const {
    menuInfo: { klantdossierId, adviseurIds },
    error
  } = useContext(AppDataContext);
  const {
    lezenEnSchrijvenRechtenVestigingen,
    lezenEnSchrijvenRechtenOrganisatie,
    getAdviseurIds,
    adviseurIds: adviseurIdsHuidigeGebruiker,
    adviseurIdsOphalen
  } = useContext(OpenDossierLogContext);
  const { params } = useRequestInit<{ vestiging: string }>();
  const vestigingId = params.vestiging;

  if (adviseurIdsOphalen) {
    getAdviseurIds(vestigingId);
  }

  if (!klantdossierId && !error && !adviseurIdsHuidigeGebruiker.length) {
    return <PageLoading />;
  }

  const huidigeGebruiker = adviseurIdsHuidigeGebruiker && adviseurIdsHuidigeGebruiker.map(e => e);
  const gebruikersEigenAangemaaktDossier = adviseurIds && huidigeGebruiker.includes(adviseurIds.toString());

  if (!klantdossierId && !error) {
    return <PageLoading />;
  }

  if (
    lezenEnSchrijvenRechten(
      params.vestiging,
      lezenEnSchrijvenRechtenVestigingen,
      lezenEnSchrijvenRechtenOrganisatie,
      props.gebruikersVestigingIds,
      props.organisatieVestigingIds
    ) === "Geen" &&
    !gebruikersEigenAangemaaktDossier
  ) {
    return <ErrorPage error={{ ...Error("Geen rechten toegekend voor deze gebruiker"), status: 403 }} data={null} />;
  }

  return (
    <>
      <fieldset
        className="authenticated-schermen"
        disabled={
          lezenEnSchrijvenRechten(
            params.vestiging,
            lezenEnSchrijvenRechtenVestigingen,
            lezenEnSchrijvenRechtenOrganisatie,
            props.gebruikersVestigingIds,
            props.organisatieVestigingIds
          ) === "AlleenLezen" && !gebruikersEigenAangemaaktDossier
        }
      >
        <Switch>
          <Route path="/vestiging/:vestiging/adviesdossier/:adviesdossier/personalia">
            <PersonaliaAjax />
          </Route>
          <Route path="/vestiging/:vestiging/adviesdossier/:adviesdossier/inkomen-en-fiscus">
            <InkomenEnFiscusAjax />
          </Route>
          <Route path="/vestiging/:vestiging/adviesdossier/:adviesdossier/pensioen">
            <PensioenAjax />
          </Route>
          <Route path="/vestiging/:vestiging/adviesdossier/:adviesdossier/woonsituatie">
            <WoonsituatieAjax />
          </Route>
          <Route path="/vestiging/:vestiging/adviesdossier/:adviesdossier/aanleiding" component={AanleidingAjax} />

          <Route path="/vestiging/:vestiging/adviesdossier/:adviesdossier/woning" component={WoningAjax} />

          <Route path="/vestiging/:vestiging/adviesdossier/:adviesdossier/klantprofiel">
            <KlantprofielSchermen />
          </Route>

          <Route path="/vestiging/:vestiging/adviesdossier/:adviesdossier/voorstel/:voorstel/uitgangspunten">
            <VergoedingenOverzichtAjax />
          </Route>
          <Route path="/vestiging/:vestiging/adviesdossier/:adviesdossier/voorstel/:voorstel/financieringsbehoefte">
            <FinancieringsbehoefteBerekeningenAjax />
          </Route>
          <Route path="/vestiging/:vestiging/adviesdossier/:adviesdossier/voorstel/:voorstel/maximale-hypotheek">
            <MaximaleHypotheekAjax />
          </Route>
          <Route path="/vestiging/:vestiging/adviesdossier/:adviesdossier/voorstel/:voorstel/hypotheek-oversluiten">
            <HypotheekOversluitenAjax />
          </Route>
          <Route
            path="/vestiging/:vestiging/adviesdossier/:adviesdossier/producten/:producten"
            component={productenOverzichtAjax}
          />

          <Route
            path="/vestiging/:vestiging/adviesdossier/:adviesdossier/aanvraag-lening"
            component={aanvraagLeningAjax}
          />
          <Route path="/vestiging/:vestiging/adviesdossier/:adviesdossier/bankgarantie" component={bankgarantieAjax} />

          <Route path="/vestiging/:vestiging/adviesdossier/:adviesdossier/taxatie" component={TaxatieAjax} />

          <Route path="/vestiging/:vestiging/adviesdossier/:adviesdossier/voorstel/:voorstel/acceptatie">
            <AcceptatieAjax />
          </Route>

          <Route
            path="/vestiging/:vestiging/adviesdossier/:adviesdossier/motivering/:onderwerp"
            render={(): ReactElement => <MotiveringAjax />}
          />
          <Route
            path="/vestiging/:vestiging/adviesdossier/:adviesdossier/aanvraag"
            render={(): ReactElement => <AanvraagAjax />}
          />
          <Route path="/vestiging/:vestiging/adviesdossier/:adviesdossier/voorstel/:voorstel">
            <VoorstelProductSchermen />
          </Route>
          <Route path="/vestiging/:vestiging/adviesdossier/:adviesdossier">
            <HuidigeProductSchermen />
          </Route>

          {process.env.NODE_ENV === "development" && (
            <Route render={(): ReactElement => <Redirect to="/zoeken/0ac20c96-ae06-4e5d-acb8-d32b27677c78" />} />
          )}

          <Route
            render={(): ReactElement => (
              <Route render={(): ReactElement => <Redirect to="/zoeken/0ac20c96-ae06-4e5d-acb8-d32b27677c78" />} />
            )}
          />
        </Switch>
      </fieldset>
    </>
  );
};

export default AuthenticatedAdviesBoxSchermen;
