import React, { ReactElement } from "react";
import HypotheekNormPresentation from "./hypotheek-norm-presentation";
import { useTarievenData } from "../../shared/hooks/use-tarieven-data";

export type HypotheekNormProps = {
  name: string;
  caption: string;
  tooltip?: string;
};

const HypotheekNorm = ({ name, caption, tooltip }: HypotheekNormProps): ReactElement | null => {
  const { loading, error, tariefJarenInfo } = useTarievenData("Toetsing");

  if (loading || tariefJarenInfo.length < 1) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (tariefJarenInfo.length > 1) {
    return (
      <HypotheekNormPresentation name={name} toetsinglijst={tariefJarenInfo} caption={caption} tooltip={tooltip} />
    );
  }

  return <></>;
};

export default HypotheekNorm;
