import React, { ReactElement, ReactNode } from "react";
import MaatschappijKeuzelijstPresentation from "./MaatschappijKeuzelijstPresentation";
import { useMaatschappijData, Soort } from "../../hooks/use-maatschappij-data";

export type MaatschappijKeuzelijstProps = {
  name: string;
  soort: Soort;
  appendChildren?: ReactNode;
  readonly?: boolean;
  visible?: boolean;
  fieldSize?: "xl" | "l" | "m" | "s";
  emptyValue?: string;
};

const MaatschappijKeuzeLijst = ({
  soort,
  name,
  appendChildren,
  readonly,
  fieldSize,
  emptyValue
}: MaatschappijKeuzelijstProps): ReactElement | null => {
  const { error, maatschappijen } = useMaatschappijData(soort);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <MaatschappijKeuzelijstPresentation
      name={name}
      maatschappijen={maatschappijen}
      appendChildren={appendChildren}
      readonly={readonly}
      fieldSize={fieldSize}
      emptyValue={emptyValue}
    />
  );
};

MaatschappijKeuzeLijst.displayName = "MaatschappijKeuzelijst";

export default MaatschappijKeuzeLijst;
