import React, { ReactElement } from "react";

import /*:COMPONENT:IMPORT:*/
"adviesbox-shared";
import MotiveringTextInput from "../../shared/components/motivering-input/MotiveringTextInput";

/*:IMPORT:*/

type AdviesProps = {
  aanvrager: number;
  title?: string;
};

export const Advies = ({ aanvrager, title }: AdviesProps): ReactElement => {
  /*:CODE:*/
  return <>{<MotiveringTextInput name={`advies.adviesAanvrager${aanvrager}`} title={title} />}</>;
};
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production") Advies.displayName = "Advies";
