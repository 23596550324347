import { createSpanWithId } from "adviesbox-shared";
import { ReactElement } from "react";
import { Column } from "react-table-6";
import { bedragFormat } from "../shared/utils/currency";
import { PensioenDeelnemerType } from "./infra/pensioen-schema";

export const getPensioenColumns = (deelnemers: PensioenDeelnemerType): Column[] => {
  const columns: Column[] = [
    {
      minWidth: 1,
      Header: "Werkgever",
      id: "Werkgever",
      Cell: (c): ReactElement =>
        createSpanWithId(c.index, 0, c.original.werkgever.huidigeWerkgever ? "Huidige werkgever" : "Vorige werkgever")
    },
    {
      minWidth: 1,
      Header: "Pensioenuitvoerder",
      id: "Pensioenuitvoerder",
      Cell: (c): ReactElement =>
        createSpanWithId(
          c.index,
          1,
          c.original.pensioenuitvoerder.naamPensioenuitvoerder,
          c.original.pensioenuitvoerder.naamPensioenuitvoerder
        )
    },
    {
      minWidth: 1,
      Header: "Pensioenregeling",
      id: "Pensioenregeling",
      Cell: (c): ReactElement =>
        createSpanWithId(
          c.index,
          2,
          c.original.pensioenregeling.soortPensioenregeling,
          c.original.pensioenregeling.soortPensioenregeling
        )
    },
    {
      minWidth: 1,
      Header: "Deelnemer",
      id: "Deelnemer",
      Cell: (c): ReactElement => {
        const deelnemer = deelnemers.find((deelnemer): boolean => deelnemer?.klantId === c.original.selectedDeelnemer);
        return createSpanWithId(c.index, 3, deelnemer ? deelnemer?.naam : null);
      }
    },
    {
      minWidth: 1,
      Header: "PP",
      id: "PP",
      Cell: (c): ReactElement =>
        createSpanWithId(
          c.index,
          4,
          c.original.pensioentoezeggingen.prePensioenBedrag
            ? bedragFormat(c.original.pensioentoezeggingen.prePensioenBedrag)
            : null
        )
    },
    {
      minWidth: 1,
      Header: "OP",
      id: "OP",
      Cell: (c): ReactElement =>
        createSpanWithId(
          c.index,
          5,
          c.original.pensioentoezeggingen.ouderdomspensioenBedrag
            ? bedragFormat(c.original.pensioentoezeggingen.ouderdomspensioenBedrag)
            : null
        )
    },
    {
      minWidth: 1,
      Header: "NP",
      id: "NP",
      Cell: (c): ReactElement =>
        createSpanWithId(
          c.index,
          6,
          c.original.pensioentoezeggingen.nabestaandenpensioenTotAowOverlijdenVoorAowBedrag
            ? bedragFormat(c.original.pensioentoezeggingen.nabestaandenpensioenTotAowOverlijdenVoorAowBedrag)
            : null
        )
    },
    {
      minWidth: 1,
      Header: "IP",
      id: "IP",
      Cell: (c): ReactElement =>
        createSpanWithId(
          c.index,
          7,
          c.original.pensioentoezeggingen.invaliditeitspensioenBedrag
            ? bedragFormat(c.original.pensioentoezeggingen.invaliditeitspensioenBedrag)
            : null
        )
    },
    {
      Cell: "DeleteButton"
    }
  ];
  return columns;
};
