import React, { ReactElement } from "react";
import { connect, FormikContextType } from "formik";
import EigenwoningschuldModal from "../eigenwoningschuld-modal/eigenwoningschuld-modal";
import { SelectedProps } from "../../shared/types";
import { LabeledCurrencyInput, Icon, LabeledBevestigingInput } from "adviesbox-shared";
import { ModalButton } from "adviesbox-shared";
import {
  EigenwoningschuldModalType,
  WoonsituatieListState,
  eigenwoningschuldModalSchema,
  eigenwoningschuldModalEntrySchema
} from "../infra/woonsituatie-schema";
import { getWoonsituatieTextResources } from "../infra/woonsituatie-resources";

const Eigenwoningreserve = ({
  selected,
  formik: {
    setFieldValue,
    values: { woonsituaties }
  }
}: SelectedProps & {
  formik: FormikContextType<WoonsituatieListState>;
}): ReactElement => {
  const selectedWoonsituatie = woonsituaties ? woonsituaties[selected] : null;
  if (!selectedWoonsituatie) return <></>;

  const eigenwoningreserve = selectedWoonsituatie.eigenwoningreserve;
  const showEigenwoningreserve = eigenwoningreserve.eigenwoningreserveToggle;
  const getModalValues = (
    eigenwoningreserveModal: EigenwoningschuldModalType,
    bedrag: number | null
  ): EigenwoningschuldModalType => {
    const entries = eigenwoningreserveModal.entries;
    if (entries.length === 0) {
      return {
        ...eigenwoningschuldModalSchema.default(),
        entries: [{ ...eigenwoningschuldModalEntrySchema.default(), bedrag: bedrag }]
      };
    }

    return entries.length === 1 && (!entries[0].bedrag || entries[0].bedrag !== bedrag)
      ? {
        entries: [
          {
            bedrag: bedrag,
            begindatum: entries[0].begindatum,
            einddatum: entries[0].einddatum,
            renteaftrekId: entries[0].renteaftrekId
          }
        ]
      }
      : eigenwoningreserveModal;
  };

  return (
    <>
      <LabeledBevestigingInput
        caption="Eigenwoningreserve of -schuld aanwezig?"
        name={`woonsituaties[${selected}].eigenwoningreserve.eigenwoningreserveToggle`}
      />

      {showEigenwoningreserve && (
        <>
          <div className="tooltip_container">
            <LabeledCurrencyInput
              caption={
                <>
                  Eigenwoningreserve (EWR) uit het <br /> verleden
                </>
              }
              name={`woonsituaties[${selected}].eigenwoningreserve.ewrVerleden`}
              tooltip={getWoonsituatieTextResources("eigenwoningreserveEwrVerleden")}
              fieldSize={"l"}
            />
          </div>

          <div className="tooltip_container">
            <LabeledCurrencyInput
              caption={
                <>
                  Eigenwoningschuld (EWS)
                  <br /> overgangsrecht
                </>
              }
              name={`woonsituaties[${selected}].eigenwoningreserve.ewsOvergangsrecht`}
              tooltip={getWoonsituatieTextResources("eigenwoningreserveEwsOvergangsrecht")}
              fieldSize={"l"}
              readonly
              appendChildren={
                <ModalButton
                  parent={`woonsituaties[${selected}].eigenwoningreserve.ewsOvergangsrechtModal`}
                  content={<Icon name="specificatie" alt="Eigenwoningschuld" />}
                >
                  <EigenwoningschuldModal
                    data={getModalValues(
                      eigenwoningreserve.ewsOvergangsrechtModal,
                      eigenwoningreserve.ewsOvergangsrecht
                    )}
                    onSave={(data) => {
                      setFieldValue(`woonsituaties[${selected}].eigenwoningreserve.ewsOvergangsrechtModal.entries`, data.entries);
                    }}
                  />
                </ModalButton>
              }
            />
          </div>
          <div className="tooltip_container">
            <LabeledCurrencyInput
              caption={
                <>
                  Eigenwoningschuld (EWS) verplichte
                  <br />
                  aflossing
                </>
              }
              name={`woonsituaties[${selected}].eigenwoningreserve.ewsAflossing`}
              tooltip={getWoonsituatieTextResources("eigenwoningreserveEwsAflossing")}
              fieldSize={"l"}
              key={"testId"}
              readonly
              appendChildren={
                <ModalButton
                  parent={`woonsituaties[${selected}].eigenwoningreserve.ewsAflossingModal`}
                  content={<Icon name="specificatie" alt="Eigenwoningschuld" />}
                >
                  <EigenwoningschuldModal
                    data={getModalValues(eigenwoningreserve.ewsAflossingModal, eigenwoningreserve.ewsAflossing)}
                    onSave={(data) => {
                      setFieldValue(`woonsituaties[${selected}].eigenwoningreserve.ewsAflossingModal.entries`, data.entries);
                    }}
                  />
                </ModalButton>
              }
            />
          </div>
        </>
      )}
    </>
  );
};

export default connect<SelectedProps, WoonsituatieListState>(Eigenwoningreserve);
