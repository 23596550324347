import { KlantprofielVraagOptions } from "../../../.generated/forms/formstypes";
import { CardSpec, QuestionType, TitleType } from "../../infra/klantprofiel-schema";
import { WelkeUitgavenBeperkenExtra } from "../welkeuitgavenbeperken-extra";

export const werkloosheidQuestionSpecs: CardSpec[] = [
  {
    sections: [
      {
        title: TitleType.custom,
        customTitle: "Kennisniveau van onderwerpen die te maken hebben met werkloosheid",
        subtitle: "Geef per onderwerp het kennisniveau aan door het bolletje te slepen.",
        questions: [
          {
            question: KlantprofielVraagOptions.KennisInkomenssituatieNaWw,
            type: QuestionType.slider
          },
          {
            question: KlantprofielVraagOptions.KennisWwVerzekeringen,
            type: QuestionType.slider
          },
          {
            question: KlantprofielVraagOptions.KennisWwVoorziening,
            type: QuestionType.slider
          }
        ]
      }
    ]
  },
  {
    title: "Welke maatregelen wenst u te treffen voor als u of uw partner werkloos wordt?",
    questions: [
      {
        title: TitleType.aanvrager,
        question: KlantprofielVraagOptions.WensMaatregelenWwAanvrager1,
        type: QuestionType.checkbox
      },
      {
        title: TitleType.aanvrager,
        question: KlantprofielVraagOptions.WensMaatregelenWwAanvrager2,
        type: QuestionType.checkbox
      },
      {
        question: KlantprofielVraagOptions.WaarvoorInkomensaanvullingWw,
        type: QuestionType.checkbox,
        condition: [
          {
            question: KlantprofielVraagOptions.WensMaatregelenWwAanvrager1,
            answer: 150401
          },
          {
            question: KlantprofielVraagOptions.WensMaatregelenWwAanvrager2,
            answer: 150501
          }
        ]
      },
      {
        question: KlantprofielVraagOptions.HoelangInkomensaanvullingWw,
        type: QuestionType.maand,
        condition: [
          {
            question: KlantprofielVraagOptions.WensMaatregelenWwAanvrager1,
            answer: 150401
          },
          {
            question: KlantprofielVraagOptions.WensMaatregelenWwAanvrager2,
            answer: 150501
          }
        ]
      }
    ]
  },
  {
    title: "In hoeveel tijd verwacht u een nieuwe baan te hebben?",
    // subtitle: "Antwoord in hele maanden",
    questions: [
      {
        title: TitleType.aanvrager,
        question: KlantprofielVraagOptions.HoeveelTijdNieuweBaanAanvrager1,
        type: QuestionType.jaarMaanden
      },
      {
        title: TitleType.aanvrager,
        question: KlantprofielVraagOptions.HoeveelTijdNieuweBaanAanvrager2,
        type: QuestionType.jaarMaanden
      }
    ]
  },
  {
    questions: [
      {
        question: KlantprofielVraagOptions.MaatregelenFinancieleGevolgenWw,
        type: QuestionType.radio
      },
      {
        question: KlantprofielVraagOptions.UitgavenBeperkenWw,
        type: QuestionType.radio,
        condition: [
          {
            question: KlantprofielVraagOptions.MaatregelenFinancieleGevolgenWw,
            answer: 151003
          }
        ]
      },
      {
        question: KlantprofielVraagOptions.WelkeUitgavenBeperkenWw,
        type: QuestionType.toggleCheckbox,
        extra: WelkeUitgavenBeperkenExtra,
        condition: [
          {
            question: KlantprofielVraagOptions.UitgavenBeperkenWw,
            answer: 151101
          }
        ]
      }
    ]
  }
];
