import * as Yup from "yup";
import {
  DuurOverzichtOptions,
  ControleGewenstInkomenOptions,
  ControleGewenstNbiOptions,
  ControleMaandlastOptions
} from "../../.generated/forms/formstypes";
import { klantnaamSchema } from "../../shared/generic-parts/klantnaam/schema";
import { yupEnum, yupNullableEnum } from "../../shared/utils/yup-enum";
import { scenarioArbeidsongeschiktheidModalSchema } from "../scenario-arbeidsongeschiktheid-modal/infra/scenario-arbeidsongeschiktheid-modal-schema";
import { scenarioOverlijdenModalSchema } from "../scenario-overlijden-modal/infra/scenario-overlijden-modal-schema";
import { scenarioPensioenModalSchema } from "../scenario-pensioen-modal/infra/scenario-pensioen-modal-schema";
import { scenarioWerkloosheidModalSchema } from "../scenario-werkloosheidheid-modal/infra/scenario-werkloosheid-modal-schema";

const motiveringControleSchema = Yup.object({
  controleMaandlast: yupNullableEnum(ControleMaandlastOptions)
    .nullable()
    .default(ControleMaandlastOptions.BenodigdInkomen),
  controleerMaximaleHypotheek: Yup.bool()
});

const motiveringControleInkomenSchema = Yup.object({
  controleGewenstInkomen: yupNullableEnum(ControleGewenstInkomenOptions).default(ControleGewenstInkomenOptions.Nbi),
  brutoInkomenswensPercentage: Yup.number().nullable(),
  controleGewenstNbi: yupNullableEnum(ControleGewenstNbiOptions),
  margeOndergrensPercentage: Yup.number().nullable(),
  margeBovengrensPercentage: Yup.number().nullable(),
  vastBedrag: Yup.number().nullable(),
  huidigNbiPercentage: Yup.number().nullable(),
  gewijzigdeJarenTonen: Yup.bool(),
  benodigdKapitaalTonen: Yup.bool()
});

export const tekortSchema = Yup.object({
  tekortHypotheek1Bedrag: Yup.number().nullable(),
  tekortHypotheek2Bedrag: Yup.number().nullable(),
  tekortHypotheekBeideBedrag: Yup.number().nullable(),
  tekortInkomen1Bedrag: Yup.number().nullable(),
  tekortInkomen2Bedrag: Yup.number().nullable(),
  tekortInkomenBeideBedrag: Yup.number().nullable()
});

export const uitgangspuntenSchema = Yup.object({
  scenarioVanKlantIds: Yup.array(Yup.string()).default([]),

  controleHypotheeknorm: motiveringControleSchema.nullable(),
  controleGewenstInkomen: motiveringControleInkomenSchema.nullable()
});

export const overlijdenUitgangspunten = uitgangspuntenSchema.shape({
  werkenNaOverlijdenPartnerAanvrager1Percentage: Yup.number().nullable(),
  werkenNaOverlijdenPartnerAanvrager2Percentage: Yup.number().nullable(),
  duurOverzicht: yupEnum(DuurOverzichtOptions).nullable(),
  overzichtDuurInJaren: Yup.number().nullable(),
  overzichtTotLeeftijdInMaanden: Yup.number().nullable()
});

const aoUitgangspuntenSchema = uitgangspuntenSchema.shape({
  scenarioDoorrekeningExtra1: Yup.bool().nullable(),
  scenarioDoorrekeningExtra2: Yup.bool().nullable(),
  arbeidsongeschiktheidStandaardPercentage: Yup.number().nullable(),
  arbeidsongeschiktheidExtra1Percentage: Yup.number().nullable(),
  arbeidsongeschiktheidExtra2Percentage: Yup.number().nullable(),
  duurzaamArbeidsongeschiktStandaard: Yup.bool().nullable(),
  duurzaamArbeidsongeschiktExtra1: Yup.bool().nullable(),
  duurzaamArbeidsongeschiktExtra2: Yup.bool().nullable(),
  benuttingVerdiencapaciteitStandaardPercentage: Yup.number().nullable(),
  benuttingVerdiencapaciteitExtra1Percentage: Yup.number().nullable(),
  benuttingVerdiencapaciteitExtra2Percentage: Yup.number().nullable(),
  toetsjaar: Yup.number().nullable()
});

export const dashboardSchema = Yup.object({
  overlijden: scenarioOverlijdenModalSchema,
  arbeidsongeschiktheid: scenarioArbeidsongeschiktheidModalSchema,
  werkloosheid: scenarioWerkloosheidModalSchema,
  pensioen: scenarioPensioenModalSchema,

  overlijdenWeergeven: Yup.bool().nullable(),
  overlijdenTekort: tekortSchema.nullable(),
  overlijdenUitgangspunten: uitgangspuntenSchema.nullable(),

  arbeidsongeschiktheidWeergeven: Yup.bool().nullable(),
  arbeidsongeschiktheidTekort: tekortSchema.nullable(),
  arbeidsongeschiktheidUitgangspunten: aoUitgangspuntenSchema.nullable(),

  werkloosheidWeergeven: Yup.bool().nullable(),
  werkloosheidTekort: tekortSchema.nullable(),
  werkloosheidUitgangspunten: uitgangspuntenSchema.nullable(),

  pensioenWeergeven: Yup.bool().nullable(),
  pensioenTekort: tekortSchema.nullable(),
  pensioenUitgangspunten: uitgangspuntenSchema.nullable(),

  initErrors: Yup.string().nullable(),
  aanvrager1: klantnaamSchema,
  aanvrager2: klantnaamSchema.nullable().default(null),
  // dataHasChanged: Yup.bool()

  data1Outdated: Yup.bool(),
  data2Outdated: Yup.bool(),
  dataBeideOutdated: Yup.bool(),

  version: Yup.number()
});
