import { useHistory, useParams } from "react-router";
import React, { useEffect, useState, useContext, ReactElement } from "react";
import { AppDataContext } from "../../navigation/appdata-context";
import { PageLoading } from "adviesbox-shared";
import { RouteParams } from "../paramrouting/paramrouting-context";

export const useNavigateBack = (): (() => ReactElement) => {
  const navigate = useHistory();

  const [goback, setGoBack] = useState(false);
  const { vestiging, adviesdossier } = useParams<RouteParams>();
  const { reloadCount, reloadNavigation } = useContext(AppDataContext);
  const prevIsOwn = reloadCount > 1 || document.referrer.indexOf(window.location.host) > -1;

  useEffect(() => {
    if (goback) {
      const unsub = navigate.listen(() => {
        reloadNavigation();
        unsub();
      });
      if (prevIsOwn) {
        navigate.goBack();
      } else {
        navigate.replace(`/vestiging/${vestiging}/adviesdossier/${adviesdossier}/personalia`);
      }
    }
  }, [goback, prevIsOwn, navigate, vestiging, adviesdossier, reloadNavigation]);

  const goBackFunction = (): ReactElement => {
    if (!goback) {
      setGoBack(true);
    }

    return <PageLoading />;
  };
  return goBackFunction;
};
