import { KlantnaamReadOnly, GeslachtOpties } from "../../../.generated/forms/formstypes";
import { KlantnaamType } from "./schema";
import { mapLocalDateToString } from "adviesbox-shared";

export function mapKlantnaamUi2Dl(values: KlantnaamType | null): KlantnaamReadOnly | null {
  if (!values) {
    return null;
  }

  const geslachtValue = GeslachtOpties[values.geslacht || "Geen"];
  return {
    klantId: values.klantId,
    achternaam: values.achternaam,
    voorletters: values.voorletters,
    voorvoegsel: values.voorvoegsel,
    geboortedatum: mapLocalDateToString(values.geboortedatum),
    geslacht: values.geslacht ? geslachtValue : null,
    voornamen: values.voornamen,
    roker: values.roker,
    aowdatum: mapLocalDateToString(values.aowdatum)
  };
}
