/* istanbul ignore file */

import { ErrorPage, PageLoading, SettingsType, withErrorBoundary } from "adviesbox-shared";
import React, { ReactElement, useCallback } from "react";
import {
  mapDlTargetToKapitaalverzekeringUiField,
  mapKapitaalverzekeringenDlToUi
} from "./infra/map-kapitaalverzekering-dl-2-ui";

import { InvalidAdviesboxResultErrorPage } from "../shared/components/fetch-error-page/fetch-error-page";
import Kapitaalverzekering from "./kapitaalverzekering";
import { RouteParams } from "../shared/paramrouting/paramrouting-context";
import { SituatieSoort } from "../producten-overzicht/infra/producten-overzicht-types";
import { mapKapitaalverzekeringenUiToDl } from "./infra/map-kapitaalverzekering-ui-2-dl";
import { useAdviesboxData } from "../shared/hooks/use-adviesbox-data";

type KapitaalverzekeringAjaxProps = { situatie: SituatieSoort };

const KapitaalverzekeringAjax = ({ situatie }: KapitaalverzekeringAjaxProps): ReactElement => {
  const createUrl = useCallback(
    (s: SettingsType, p: RouteParams): string =>
      situatie === "huidig"
        ? `${s.klantdossiersFormsOrigin}/Adviesdossiers/${p.adviesdossier}/Kapitaalverzekering`
        : `${s.klantdossiersFormsOrigin}/Voorstellen/${p.voorstel}/Kapitaalverzekering`,
    [situatie]
  );

  const createDataId = useCallback((p: RouteParams) => (situatie === "huidig" ? p.adviesdossier : p.voorstel), [
    situatie
  ]);

  const { loading, error, data, platformData, saveData } = useAdviesboxData(
    createUrl,
    createDataId,
    mapKapitaalverzekeringenDlToUi,
    mapKapitaalverzekeringenUiToDl,
    mapDlTargetToKapitaalverzekeringUiField
  );

  if (loading) {
    return <PageLoading />;
  }

  if (error) {
    return <ErrorPage error={error} data={platformData} />;
  }

  if (!data) {
    return <InvalidAdviesboxResultErrorPage />;
  }

  return <Kapitaalverzekering situatie={situatie} saveData={saveData} {...data} />;
};

KapitaalverzekeringAjax.displayName = "KapitaalverzekeringAjax";

export default withErrorBoundary(KapitaalverzekeringAjax);
