import { LocalDate, ChronoUnit } from "@js-joda/core";

export const birthdate2age = (
  birthdateDateOrString: string | LocalDate,
  ageAtDate: string | LocalDate = LocalDate.now()
): number => {
  if (typeof birthdateDateOrString === "string") {
    birthdateDateOrString = birthdateDateOrString.length > 10 ? birthdateDateOrString.substr(0,10) : birthdateDateOrString;
    birthdateDateOrString = LocalDate.parse(birthdateDateOrString.substring(0,10));
  }
  if (typeof ageAtDate === "string") {
    ageAtDate = LocalDate.parse(ageAtDate);
  }
  return birthdateDateOrString.until(ageAtDate, ChronoUnit.YEARS);
};
