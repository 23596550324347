import { Personalia } from "../../.generated/forms/formstypes";
import { FieldMap, UiName } from "../../shared/types";
import { target2field } from "../../shared/utils/target-to-field";

export function mapPersonaliaDlTargetToUiField(target: string): UiName | null {
  const map: FieldMap<Personalia> = {
    aanvrager1: {
      woonadres: {
        plaats: { field: "aanvrager1Adres.adres.plaats", label: "Aanvrager 1 plaats" },
        straat: { field: "aanvrager1Adres.adres.straat", label: "Aanvrager 1 straat" }
      },
      persoonsgegevens: {
        achternaam: { field: "aanvrager1.achternaam", label: "Aanvrager 1 achternaam" },
        email: { field: "aanvrager1Adres.emailAdres", label: "Aanvrager 1 E-mailadres" },
        geboortedatum: { field: "aanvrager1Extra.geboortedatum", label: "Aanvrager 1 geboortedatum" },
        geboortenaam: { field: "aanvrager1.geboortenaam", label: "Aanvrager 1 geboortenaam" },
        geboorteplaats: { field: "aanvrager1BurgerlijkeStatus.geboorteplaats", label: "Aanvrager 1 geboorteplaats" }
      },
      klantgegevens: {
        volgnummer: { field: "aanvrager1.volgnummer", label: "Aanvrager 1 volgnummer" }
      }
    },
    aanvrager2: {
      woonadres: {
        plaats: { field: "aanvrager2Adres.adres.plaats", label: "Aanvrager 2 plaats" },
        straat: { field: "aanvrager2Adres.adres.straat", label: "Aanvrager 2 straat" }
      },
      persoonsgegevens: {
        achternaam: { field: "aanvrager2.achternaam", label: "Aanvrager 2 achternaam" },
        email: { field: "aanvrager2Adres.emailAdres", label: "Aanvrager 2 E-mailadres" },
        geboortedatum: { field: "aanvrager2Extra.geboortedatum", label: "Aanvrager 2 geboortedatum" },
        geboortenaam: { field: "aanvrager2.geboortenaam", label: "Aanvrager 2 geboortenaam" },
        geboorteplaats: { field: "aanvrager2BurgerlijkeStatus.geboorteplaats", label: "Aanvrager 2 geboorteplaats" }
      },
      klantgegevens: {
        volgnummer: { field: "aanvrager2.volgnummer", label: "Aanvrager 2 volgnummer" }
      }
    }
  };

  return target2field(map, target);
}
