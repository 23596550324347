import { LabelValuePairs } from "adviesbox-shared/utils/types";
import {
  TaxatieInhoudOptions,
  TaxatieTenBehoevenVanOptions,
  TaxatieBenodigdeKeuringOptions,
  TaxatieGestapeldeBouwOptions,
  TaxatieJaNeeOnbekendOptions
} from "../../.generated/forms/formstypes";

export const inhoudWoningOpties: LabelValuePairs = [
  {
    label: "tot 800m3",
    value: TaxatieInhoudOptions.Tot800
  },
  {
    label: "> 800m3",
    value: TaxatieInhoudOptions.Groter800
  },
  {
    label: "Onbekend",
    value: TaxatieInhoudOptions.Onbekend
  }
];

export const bouwkundigeKeuringTenBehoeveOpties: LabelValuePairs = [
  {
    label: "Aankoop",
    value: TaxatieTenBehoevenVanOptions.Aankoop
  },
  {
    label: "Verkoop",
    value: TaxatieTenBehoevenVanOptions.Verkoop
  },
  {
    label: "NHG ivm. financiering",
    value: TaxatieTenBehoevenVanOptions.NhgFinanciering
  },
  {
    label: "Anders",
    value: TaxatieTenBehoevenVanOptions.Anders
  }
];

export const benodigdeKeuringOpties: LabelValuePairs = [
  {
    label: "Pluskeuring met NHG keuring",
    value: TaxatieBenodigdeKeuringOptions.PlusKeuringMetNhgKeuring
  },
  {
    label: "NHG keuring",
    value: TaxatieBenodigdeKeuringOptions.NhgKeuring
  }
];

export const gestapeldeBouwOpties: LabelValuePairs = [
  {
    label: "Appartement",
    value: TaxatieGestapeldeBouwOptions.Appartement
  },
  {
    label: "Anders",
    value: TaxatieGestapeldeBouwOptions.Anders
  }
];

export const woningMonumentOpties: LabelValuePairs = [
  {
    label: "Ja",
    value: TaxatieJaNeeOnbekendOptions.Ja
  },
  {
    label: "Nee",
    value: TaxatieJaNeeOnbekendOptions.Nee
  },
  {
    label: "Onbekend",
    value: TaxatieJaNeeOnbekendOptions.Onbekend
  }
];

export const bouwkundigeKeuringVveOpties: LabelValuePairs = [
  {
    label: "Ja",
    value: TaxatieJaNeeOnbekendOptions.Ja
  },
  {
    label: "Nee",
    value: TaxatieJaNeeOnbekendOptions.Nee
  },
  {
    label: "Onbekend",
    value: TaxatieJaNeeOnbekendOptions.Onbekend
  }
];
