import { KlantprofielVraagOptions } from "../../../.generated/forms/formstypes";
import { CardSpec, QuestionType, TitleType } from "../../infra/klantprofiel-schema";
import { WelkeUitgavenBeperkenExtra } from "../welkeuitgavenbeperken-extra";

export const risicoOverlijdenQuestionSpecs: CardSpec[] = [
  {
    sections: [
      {
        title: TitleType.custom,
        customTitle: "Kennisniveau van onderwerpen die te maken hebben met overlijden",
        subtitle: "Geef per onderwerp het kennisniveau aan door het bolletje te slepen.",
        questions: [
          {
            question: KlantprofielVraagOptions.KennisInkomenssituatieNaOvl,
            type: QuestionType.slider
          },
          {
            question: KlantprofielVraagOptions.KennisOrv,
            type: QuestionType.slider
          },
          {
            question: KlantprofielVraagOptions.KennisNabestaandenvoorzieningPensioenregeling,
            type: QuestionType.slider
          },
          {
            question: KlantprofielVraagOptions.KennisNabestaandenvoorzieningAnw,
            type: QuestionType.slider
          },
          {
            question: KlantprofielVraagOptions.KennisNabestaandenvoorzieningUitkering,
            type: QuestionType.slider
          }
        ]
      }
    ]
  },
  {
    title: "Welke maatregelen wenst u te treffen bij het overlijden van u of uw partner?",
    questions: [
      {
        title: TitleType.aanvrager,
        question: KlantprofielVraagOptions.WensMaatregelenOvlAanvrager1,
        type: QuestionType.checkbox
      },
      {
        title: TitleType.aanvrager,
        question: KlantprofielVraagOptions.WensMaatregelenOvlAanvrager2,
        type: QuestionType.checkbox
      },
      {
        question: KlantprofielVraagOptions.VerzorgdAchterlaten,
        type: QuestionType.checkbox,
        condition: [
          {
            question: KlantprofielVraagOptions.WensMaatregelenOvlAanvrager1,
            answer: 130601
          },
          {
            question: KlantprofielVraagOptions.WensMaatregelenOvlAanvrager2,
            answer: 130701
          }
        ]
      }
    ]
  },
  {
    questions: [
      {
        question: KlantprofielVraagOptions.MaatregelenFinancieleGevolgenOvl,
        type: QuestionType.radio
      },
      {
        question: KlantprofielVraagOptions.NabestaandenUitgavenBeperken,
        type: QuestionType.radio,
        condition: [
          {
            question: KlantprofielVraagOptions.MaatregelenFinancieleGevolgenOvl,
            answer: 130903
          }
        ]
      },
      {
        question: KlantprofielVraagOptions.WelkeUitgavenBeperkenOvl,
        type: QuestionType.toggleCheckbox,
        extra: WelkeUitgavenBeperkenExtra,
        condition: [
          {
            question: KlantprofielVraagOptions.NabestaandenUitgavenBeperken,
            answer: 131001
          }
        ]
      }
    ]
  }
];
