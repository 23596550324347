import { Card, CardWrapper, CheckBox, LabeledBevestigingInput, LabeledNumberInput } from "adviesbox-shared";
import { useFormikContext } from "formik";
import React, { ReactElement } from "react";
import { ISWSideEffects } from "../../shared/components/isw-side-effects/isw-side-effects";
import { syncHypotheekVergelijkenModalSideEffects } from "./determine-sync-hypotheek-vergelijken-modal-side-effects";
import { HypotheekVergelijkenModalType } from "./hypotheek-vergelijken-types";

export const Stap2 = (): ReactElement => {
  const { values: formikValues } = useFormikContext<HypotheekVergelijkenModalType>();

  return (
    <>
      <ISWSideEffects<HypotheekVergelijkenModalType> sync={syncHypotheekVergelijkenModalSideEffects(null)} />
      <div className="d-flex flex-wrap flex-row mt-4">
        <CardWrapper maxRowCount={0}>
          <Card>
            <h2 className="mx-0">Verklaring inkomen</h2>
            <div className="d-flex flex-row no-gutters">
              <div className="col-6">
                <label htmlFor="voorwaardeSelectie.verklaringInkomen.werkgever">Verklaring inkomen</label>
              </div>
              <div className="col-6">
                <CheckBox caption="Werkgever" name="voorwaardeSelectie.verklaringInkomen.werkgever" />
                <CheckBox caption="UWV" name="voorwaardeSelectie.verklaringInkomen.uwv" />
                <CheckBox caption="Perspectief" name="voorwaardeSelectie.verklaringInkomen.perspectief" />
                <CheckBox
                  caption="Accountantsverklaring"
                  name="voorwaardeSelectie.verklaringInkomen.accountantsverklaring"
                />
                <CheckBox caption="IB stukken" name="voorwaardeSelectie.verklaringInkomen.ibStukken" />
              </div>
            </div>
          </Card>
          <Card>
            <h2>Offerte</h2>
            <LabeledBevestigingInput
              caption="Minimale offertegeldigheidsduur"
              name="voorwaardeSelectie.offerte.minimaleOfferteGeldigheidsDuur"
            />
            {formikValues.voorwaardeSelectie.offerte?.minimaleOfferteGeldigheidsDuur && (
              <>
                <LabeledNumberInput
                  caption="Geldigheidsduur minimaal"
                  name="voorwaardeSelectie.offerte.geldigheidsduurMinimaal"
                  appendChildren={<>maanden</>}
                />
                <LabeledBevestigingInput
                  caption="Inclusief verlengingsduur"
                  name="voorwaardeSelectie.offerte.inclusiefVerlengingsduur"
                />
              </>
            )}
          </Card>

          <Card>
            <h2 className="mx-0">Contractrente</h2>
            <div className="d-flex flex-wrap flex-row no-gutters">
              <div className="col-6">
                <label htmlFor="voorwaardeSelectie.contractRente.offerteRente">Contractrente</label>
              </div>
              <div className="col-6">
                <CheckBox caption="Offerterente" name="voorwaardeSelectie.contractRente.offerteRente" />
                <CheckBox caption="Dagrente" name="voorwaardeSelectie.contractRente.dagRente" />
                <CheckBox caption="Dalrente" name="voorwaardeSelectie.contractRente.dalRente" />
              </div>
            </div>
          </Card>
          <Card>
            <h2>Overig</h2>
            <LabeledBevestigingInput
              caption="Annuleringskosten offerte acceptabel"
              name="voorwaardeSelectie.overige.annuleringsKostenAcceptabel"
            />
            <LabeledBevestigingInput
              caption="Verplichte ORV boven marktwaarde %"
              name="voorwaardeSelectie.overige.verplichteOrv"
            />
            <LabeledBevestigingInput caption="Verhuisregeling" name="voorwaardeSelectie.overige.verhuisRegeling" />
          </Card>
        </CardWrapper>
      </div>
    </>
  );
};
