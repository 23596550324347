  
import { CellInfo } from "react-table-6";  
import React, { ReactElement, useContext } from "react";
import classnames from "classnames";
import { ZoekschermState, ZoekenAdviesDossierType } from "../../infra/zoekscherm-schema";
import { useFormikContext } from "formik";
import { useRequestInit, AuthContext, SettingsContext, ErrorPage, PageLoading } from "adviesbox-shared";
import { deleteDossierCall } from "../../infra/zoekscherm-api";
import useAbortableFetch from "use-abortable-fetch";
import { LaatstVerzondenAanvraagOutput } from "../../../.generated/hdndossier/hdndossiertypes";
import classes from "./confirm-delete-dossier-modal.module.scss";

export const ConfirmDeleteDossierModal = ({  
  cellInfo,  
  closeModal,
  setErrorDifferentVestiging
}: {  
  cellInfo: CellInfo;  
  closeModal?: () => void;
  setErrorDifferentVestiging: (value: boolean) => void;
}): ReactElement => {  
  const { values, setFieldValue } = useFormikContext<ZoekschermState>();
  const { params, requestInit } = useRequestInit();
  const { user } = useContext(AuthContext);
  const settings = useContext(SettingsContext);
  const { original, index } = cellInfo;  
  const { omschrijving } = original;  
  const inBemiddeling = !!original.bemiddelingDossierStatus;
  const selectedClient = values.zoeken.selectedClient;
  const selectedAdviesdossier = selectedClient?.adviesDossiers[index];
  const laatstVerzondenAanvraagUrl = selectedAdviesdossier?.adviesdossierId ? 
    `${settings.hdnDossierOrigin}/adviesdossier/${selectedAdviesdossier.adviesdossierId}/laatstverzondenaanvraag` : 
    null;
  const { data, loading, error } = useAbortableFetch<LaatstVerzondenAanvraagOutput>(laatstVerzondenAanvraagUrl, requestInit);

  /* istanbul ignore next */
  if (error) {
    return <ErrorPage error={error} />;
  }

  /* istanbul ignore next */
  if (typeof data === "string") {
    return <ErrorPage error={new Error("type is string")} data={data} />;
  }

  /* istanbul ignore next */
  if (loading || !data) {
    return <PageLoading />;
  }

  const deleteDossier = (index: number, closeModal?: () => void): (() => void) => async (): Promise<void> => {
    if (!selectedClient || !selectedClient.adviesDossiers || !closeModal || !user) return;
    const selectedAdviesdossier: ZoekenAdviesDossierType = selectedClient.adviesDossiers[index];

    if (!selectedAdviesdossier || !selectedAdviesdossier.adviesdossierId) return;
    if (selectedClient?.vestigingId !== params?.vestiging) {
      closeModal();
      return setErrorDifferentVestiging(true);
    }
    
    await deleteDossierCall(selectedAdviesdossier.adviesdossierId, settings, params.vestiging, user);
    selectedClient.adviesDossiers.splice(index, 1);
    selectedClient.aantalDossiers = selectedClient.adviesDossiers.length;
    closeModal();
    setFieldValue("zoeken.selectedClient", { ...selectedClient });
  };

  const getModalMessage = (): ReactElement => {
    const isDeletable = (!data.laatstVerzondenAanvraag || !!data.laatstVerzondenAanvraag?.verwijderDrempelOverschreden) || inBemiddeling;
    const message = (): string => {
      if (data.laatstVerzondenAanvraag) {
        if (!data.laatstVerzondenAanvraag?.verwijderDrempelOverschreden) {
          return `U kunt dit advies nog niet verwijderen omdat er voor dit advies een HDN bericht is verstuurd. \
Binnen ${data.laatstVerzondenAanvraag?.aantalDagenVerwijderDrempel} dagen na versturen kunnen hierop nog retourberichten binnenkomen.`;
        } else {
          return `Let op! Voor dit advies is een HDN bericht verstuurd. Met het verwijderen van het advies kan een retourbericht \
(offerte, status) van de maatschappij niet meer aan de aanvrager gekoppeld worden. Wilt u doorgaan?`;
        }
      } 
      
      if (inBemiddeling) {
        return `Dit adviesdossier is momenteel gekoppeld aan producten in aanvragen. Zolang het dossier nog niet is \
afgerond in de HDN-dossier applicatie, kan het dossier niet worden verwijderd.`;
      } 
      
      return `Het dossier wordt definitief verwijderd.`;
    }
    
    return (
      <div data-testid={`confirm-delete-dossier-modal`}>  
        <div className={"pb-2 d-flex px-4 pt-3"}>  
          <h3 className="px-1">
            {isDeletable 
              ? "Weet u zeker dat u het adviesdossier wilt verwijderen?"
              : "Het adviesdossier kan niet verwijderd worden"}
          </h3>  
          <div className={"ml-auto"}>  
            <button type="button" className={"close"} onClick={closeModal} id={`Dossier-verwijderen-sluiten`}>  
              <span aria-hidden="true">×</span>  
              <span className="sr-only">Close</span>  
            </button>  
          </div>  
        </div>  

        <p className="px-4 pb-2">  
          {isDeletable && (<>Weet u zeker dat u het adviesdossier met de korte omschrijving <strong>{`" ${omschrijving} "`}</strong> wilt verwijderen? </>)}
          {message()}
        </p>  
        <div className={classnames("d-flex align-items-center justify-content-end", classes.modal_footer)}>  
          <button id={`Dossier-verwijderen-annuleren`} onClick={closeModal} className={"btn btn-link mx-1"}>  
            Annuleren  
          </button>  
          {isDeletable && (<button  
            id={`Dossier-verwijderen`}  
            onClick={deleteDossier(index, closeModal)}  
            className="btn btn-delete mx-1 mr-4"  
          >  
            Dossier verwijderen  
          </button>)} 
        </div>  
      </div>  
    )
  }

  return (  
    <> 
      {getModalMessage()}
    </>  
  );  
}; 