import {
  KlantnaamReadOnly,
  KlantgegevensReadOnly,
  GeslachtOpties,
  OrvAanvrager,
  SoortLijfrenteProductOptions
} from "../../../.generated/forms/formstypes";
import { KlantnaamMetKinderenType, KlantnaamType } from "./schema";
import { AanvragerKeuze } from "../../types";
import { createMapEnum } from "../../utils/create-map-enum";
import { mapStringToLocalDate } from "adviesbox-shared";

export function mapKlantnaamDl2Ui(values?: KlantnaamReadOnly | OrvAanvrager | null): KlantnaamType | null {
  if (values) {
    return {
      klantId: values.klantId || "",
      achternaam: values.achternaam || "",
      voorletters: values.voorletters || "",
      voornamen: values.voornamen || "",
      voorvoegsel: values.voorvoegsel || "",
      geboortedatum: mapStringToLocalDate(values.geboortedatum || "1900-01-01"),
      roker: values.roker,
      geslacht: values.geslacht,
      aowdatum: mapStringToLocalDate(values.aowdatum)
    };
  }

  return null;
}

export function mapKlantnaamDl2UiMetKinderen(values?: OrvAanvrager | null): KlantnaamMetKinderenType | null {
  if (values) {
    return {
      klantId: values.klantId || "",
      achternaam: values.achternaam || "",
      voorletters: values.voorletters || "",
      voornamen: values.voornamen || "",
      voorvoegsel: values.voorvoegsel || "",
      geboortedatum: mapStringToLocalDate(values.geboortedatum || "1900-01-01"),
      roker: values.roker,
      geslacht: values.geslacht,
      aowdatum: mapStringToLocalDate(values.aowdatum),
      heeftKinderen: values.heeftKinderen
    };
  }

  return null;
}

const mapGeslachtOptiesToGeslachtOpties = createMapEnum(GeslachtOpties).to<GeslachtOpties>({
  Geen: GeslachtOpties.Geen,
  Man: GeslachtOpties.Man,
  Vrouw: GeslachtOpties.Vrouw
});

export function mapKlantgegevensDl2Ui(values?: KlantgegevensReadOnly | null): KlantnaamType | null {
  if (values) {
    return {
      klantId: values.klantId || "",
      achternaam: values.achternaam || "",
      voorletters: values.voorletters || "",
      voornamen: values.voornamen || "",
      voorvoegsel: values.voorvoegsel || "",
      geboortedatum: mapStringToLocalDate(values.geboortedatum || "1900-01-01"),
      roker: values.roker,
      geslacht: mapGeslachtOptiesToGeslachtOpties(values.geslacht),
      aowdatum: mapStringToLocalDate(values.aowdatum)
    };
  }

  return null;
}

export function mapKlantenNaarAanvragerKeuze(
  klantIds: Readonly<string[]>,
  aanvrager1: Readonly<KlantnaamType> | null,
  aanvrager2: Readonly<KlantnaamType> | null
): AanvragerKeuze {
  let verzekeringnemers = AanvragerKeuze.Aanvrager1;

  if (klantIds.length > 1) {
    verzekeringnemers = AanvragerKeuze.Beiden;
  } else if (klantIds[0] === aanvrager1?.klantId) {
    verzekeringnemers = AanvragerKeuze.Aanvrager1;
  } else if (klantIds[0] === aanvrager2?.klantId) {
    verzekeringnemers = AanvragerKeuze.Aanvrager2;
  }

  return verzekeringnemers;
}

export function mapAanvragerKeuzeNaarKlanten(
  aanvrager: Readonly<AanvragerKeuze>,
  aanvrager1: Readonly<KlantnaamType> | null,
  aanvrager2: Readonly<KlantnaamType> | null
): string[] {
  const klantIds: string[] = [];

  switch (aanvrager) {
    case AanvragerKeuze.Aanvrager1:
      if (aanvrager1) {
        klantIds.push(aanvrager1.klantId);
      }
      break;

    case AanvragerKeuze.Aanvrager2:
      if (aanvrager2?.klantId) {
        klantIds.push(aanvrager2.klantId);
      }
      break;
    case AanvragerKeuze.Beiden:
      if (aanvrager1) {
        klantIds.push(aanvrager1.klantId);
      }
      if (aanvrager2?.klantId) {
        klantIds.push(aanvrager2.klantId);
      }
      break;
    default:
      if (aanvrager1) {
        klantIds.push(aanvrager1.klantId);
      }
  }

  return klantIds;
}


export function mapAanvragerKeuzeNaarVerzekeringnemers(
  aanvrager: Readonly<AanvragerKeuze>,
  aanvrager1: Readonly<KlantnaamType> | null,
  aanvrager2: Readonly<KlantnaamType> | null,
  soortLijfrenteProductOptions: SoortLijfrenteProductOptions | null,
  verzekerden: Readonly<AanvragerKeuze>
): string[] {
  // Bij BancaireLijfrente moet verzekeringnemers gelijk zijn aan verzekerden, maar het formulier heeft deze optie niet
  if (soortLijfrenteProductOptions === SoortLijfrenteProductOptions.BancaireLijfrente ) aanvrager = verzekerden;
  return mapAanvragerKeuzeNaarKlanten(aanvrager, aanvrager1, aanvrager2);
}