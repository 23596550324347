import { useRequestInit, UseRequestInitOptions, ForceRerenderContext } from "adviesbox-shared";
import { useCallback, useContext, useEffect, useMemo } from "react";
import useAbortableFetch from "use-abortable-fetch";
import { Algemeen, JaarMaand } from "../../.generated/tarieven/tarieventypes";
import { JaarMaandNoNull } from "../types";
import { hasValue } from "../utils/helpers";

const invalidJarenResult = {
  loading: false,
  error: new Error("Invalid jaren"),
  data: null,
  tariefJarenInfo: [],
  laatsteTariefJaar: null,
  laatsteTariefMaand: null
};

const invalidDataResult = {
  loading: false,
  error: new Error("Invalid data"),
  data: null,
  tariefJarenInfo: [],
  laatsteTariefJaar: null,
  laatsteTariefMaand: null
};

type UseTarievenData = {
  loading: boolean;
  error: Error | null;
  data: Algemeen | null;
  abort: () => void;
  laatsteTariefJaar: number | null;
  laatsteTariefMaand: number | null;
  tariefJarenInfo: JaarMaandNoNull[];
};

export type TariefSoorten = "Fiscaal" | "Toetsing";

export const sortJaarMaand = (a: JaarMaandNoNull, b: JaarMaandNoNull): number =>
  a.jaar - b.jaar === 0 ? a.maand - b.maand : a.jaar - b.jaar;

export const filterJaarMaand = (tariefJaar: JaarMaand): tariefJaar is JaarMaandNoNull =>
  hasValue(tariefJaar.jaar) && hasValue(tariefJaar.maand);

export const useTarievenData = (soort: TariefSoorten): UseTarievenData => {
  const loadingDone = useContext(ForceRerenderContext);

  const extraHeaders = useMemo((): UseRequestInitOptions => {
    return {
      method: "GET",
      extraHeaders: {
        "content-type": "application/json"
      }
    };
  }, []);

  const {
    requestInit,
    settings: { accTarievenOrigin }
  } = useRequestInit(extraHeaders);

  const tariefJarenUrl = `${accTarievenOrigin}/api/${soort}${soort === "Toetsing" ? "/toetsnormen" : ""}`;
  const {
    loading: tariefJarenLoading,
    error: tariefJarenError,
    data: tariefJarenInfo,
    abort: abortTariefJaren
  } = useAbortableFetch<JaarMaand[]>(tariefJarenUrl, requestInit);

  const sortedTariefJarenInfo = useMemo(
    () =>
      tariefJarenInfo && typeof tariefJarenInfo !== "string"
        ? tariefJarenInfo.filter<JaarMaandNoNull>(filterJaarMaand).sort(sortJaarMaand)
        : [],
    [tariefJarenInfo]
  );

  const laatsteTariefJaar = useMemo(
    () => (sortedTariefJarenInfo.length > 0 ? sortedTariefJarenInfo[sortedTariefJarenInfo.length - 1].jaar : null),
    [sortedTariefJarenInfo]
  );
  const laatsteTariefMaand = useMemo(
    () => (sortedTariefJarenInfo.length > 0 ? sortedTariefJarenInfo[sortedTariefJarenInfo.length - 1].maand : null),
    [sortedTariefJarenInfo]
  );

  const tarievenUrl =
    laatsteTariefJaar && laatsteTariefMaand
      ? `${accTarievenOrigin}/api/${soort}/${laatsteTariefJaar}/${laatsteTariefMaand}`
      : null;
  const { loading: tarievenLoading, error: tarievenError, data: tarieven, abort: abortTarieven } = useAbortableFetch<
    Algemeen
  >(tarievenUrl, requestInit);

  const abort = useCallback(() => {
    abortTariefJaren();
    abortTarieven();
  }, [abortTariefJaren, abortTarieven]);

  const loading = tariefJarenLoading || tarievenLoading;
  const error = tariefJarenError || tarievenError;

  useEffect((): void => {
    if (!loading && tarieven && typeof tarieven !== "string" && loadingDone) {
      loadingDone();
    }
  }, [loading, tarieven, loadingDone]);

  if (!loading && !error && tariefJarenInfo && sortedTariefJarenInfo.length < 1) {
    return {
      ...invalidJarenResult,
      abort
    };
  }

  if (typeof tarieven === "string") {
    return {
      ...invalidDataResult,
      abort
    };
  }

  return {
    loading,
    error,
    data: tarieven,
    laatsteTariefJaar: laatsteTariefJaar,
    laatsteTariefMaand: laatsteTariefMaand,
    tariefJarenInfo: sortedTariefJarenInfo,
    abort
  };
};
