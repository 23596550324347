/* istanbul ignore file */
import { bedragFormat, createSpanWithId } from "adviesbox-shared";
import { ReactElement } from "react";
import { Column } from "react-table-6";
import { RentevariantOptions } from "../.generated/forms/formstypes";
import { partijOnafhankelijk } from "../producten-overzicht/infra/product-constanten";
import { SituatieSoort } from "../producten-overzicht/infra/producten-overzicht-types";
import { jaarMaandInputNaarJaarMaand } from "../shared/generic-parts/jaar-maand/helpers";
import { decimalFormat } from "../shared/utils/decimal-format";
import { DeleteButtonHypotheek, EditButtonHypotheek } from "./hypotheek-data-grid-components";
import { showEdit } from "./infra/hypotheek-types";

type hypotheekColumnConfig = {
  situatie: SituatieSoort;
  setShowWijzigen?: React.Dispatch<React.SetStateAction<showEdit>>;
  setSelectedState?: React.Dispatch<React.SetStateAction<number>>;
  warningModal?: boolean;
};

export const hypotheekColumns: (props: hypotheekColumnConfig) => Column[] = ({
  situatie,
  setShowWijzigen,
  setSelectedState,
  warningModal = false
}) => [
  {
    Header: "Hypotheek",
    id: "Hypotheek",
    Cell: (c): ReactElement =>
      createSpanWithId(
        c.index,
        0,
        situatie === "huidig"
          ? "Bestaand"
          : c.original.product.doorlopend
          ? c.original.partijCode === partijOnafhankelijk &&
            c.original.product.partijCodeSelectie !== partijOnafhankelijk // Bij een omzetting kunnen Partijcode en PartijCodeSelectie nooit beiden op "XX" staan
            ? "Doorlopend"
            : "Omgezet"
          : "Nieuw"
      ),
    maxWidth: 120
  },
  {
    Header: "Partij",
    id: "Partij",
    Cell: (c): ReactElement =>
      createSpanWithId(c.index, 1, c.original.product.partijNaam, c.original.product.partijNaam)
  },
  {
    Header: "Hypotheekvorm",
    id: "hypotheekvorm",
    Cell: (c): ReactElement =>
      createSpanWithId(
        c.index,
        2,
        situatie === "huidig" ? c.original.hypotheekVorm.aflossingsvorm : c.original.hypotheekVorm.omschrijving,
        situatie === "huidig" ? c.original.hypotheekVorm.aflossingsvorm : c.original.hypotheekVorm.omschrijving
      )
  },
  {
    Header: "Box 1",
    id: "Box 1",
    Cell: (c): ReactElement => createSpanWithId(c.index, 3, bedragFormat(c.original.fiscalegegevens.deelBox1Bedrag, 0))
  },
  {
    Header: "Box 3",
    id: "Box 3",
    Cell: (c): ReactElement => createSpanWithId(c.index, 4, bedragFormat(c.original.fiscalegegevens.deelBox3Bedrag, 0))
  },
  {
    Header: "Looptijd",
    id: "Looptijd",
    Cell: (c): ReactElement => createSpanWithId(c.index, 5, jaarMaandInputNaarJaarMaand(c.original.product.looptijd))
  },
  {
    Header: "Rente",
    id: "Rente",
    Cell: (c): ReactElement =>
      createSpanWithId(
        c.index,
        6,
        c.original.leningdeelgegevens.rentePercentage
          ? decimalFormat(
              c.original.leningdeelgegevens.rentePercentage.berekenen
                ? c.original.leningdeelgegevens.rentePercentage.berekendBedrag
                : c.original.leningdeelgegevens.rentePercentage.bedrag,
              2
            ) + "%"
          : null
      )
  },
  {
    Header: "Rentesoort",
    id: "Rentesoort",
    Cell: (c): ReactElement =>
      createSpanWithId(
        c.index,
        7,
        c.original.leningdeelgegevens.renteVariant === RentevariantOptions.Variabel
          ? c.original.leningdeelgegevens.renteVariant
          : `${c.original.leningdeelgegevens.renteVariant} ${c.original.leningdeelgegevens.rentevastPeriodeJaar} jr`
      )
  },
  {
    Cell: warningModal
      ? null
      : EditButtonHypotheek(
          c => setShowWijzigen && setShowWijzigen({ edit: true, visible: true, productOnly: false, omzetten: false }),
          setSelectedState
        ),
    maxWidth: warningModal ? undefined : 20
  },
  {
    Cell: warningModal
      ? null
      : DeleteButtonHypotheek({ situatie, arrayName: "producten", rowSelected: setSelectedState }),
    maxWidth: warningModal ? undefined : 30
  }
];
