import { optellen } from "../../shared/utils/currency";
import {
  InbrengEigenGeldModal,
  TotaleRestschuldModal,
  Financieringsopzet,
  AdvieskostenBemiddelingsvergoedingModal
} from "../infra/financieringsbehoefte-types";

export function opgebouwdeWaardeBijRestschuldBerekening(
  inbrengEigenGeldModal: InbrengEigenGeldModal,
  totaleRestschuldModal: TotaleRestschuldModal
): number {
  const inbrengOpgebouwdeWaardeRestschuld = Math.max(
    Math.min(
      (inbrengEigenGeldModal.inbrengPolisBedrag || 0) + (inbrengEigenGeldModal.inbrengOpgebouwdeWaarde || 0),
      (totaleRestschuldModal.restschuldHuidigeWoning.berekenen === null ||
      totaleRestschuldModal.restschuldHuidigeWoning.berekenen
        ? totaleRestschuldModal.restschuldHuidigeWoning.berekendBedrag || 0
        : totaleRestschuldModal.restschuldHuidigeWoning.bedrag || 0) +
        (totaleRestschuldModal.extraKostenHuidigeHypotheek || 0) -
        (totaleRestschuldModal.inbrengEigenGeldVoorVerlagenRestschuld || 0)
    ),
    0
  );

  return inbrengOpgebouwdeWaardeRestschuld;
}

export const mapInbrengEigengeldOutput = (
  finOpzetCurr: Financieringsopzet,
  eigenGeldResult: number | null
): number | null => {
  const totaalEigenGeldModal = optellen([
    finOpzetCurr.inbrengEigenGeldModal.eigenGeldOverwaarde.bedrag,
    finOpzetCurr.inbrengEigenGeldModal.extraAflossing,
    finOpzetCurr.inbrengEigenGeldModal.inbrengEigengeldBedrag1,
    finOpzetCurr.inbrengEigenGeldModal.inbrengEigengeldBedrag2,
    finOpzetCurr.inbrengEigenGeldModal.inbrengOpgebouwdeWaarde,
    finOpzetCurr.inbrengEigenGeldModal.inbrengPolisBedrag
  ]);

  const inbrengEigengeld = (eigenGeldResult || 0) - (totaalEigenGeldModal || 0);
  return inbrengEigengeld !== 0 ? inbrengEigengeld : null;
};

export function advieskostenBerekening(
  advieskostenBemiddelingsvergoeding: AdvieskostenBemiddelingsvergoedingModal
): number {
  return [
    advieskostenBemiddelingsvergoeding.advieskostenGespreid && advieskostenBemiddelingsvergoeding.advieskosten
      ? advieskostenBemiddelingsvergoeding.advieskosten - advieskostenBemiddelingsvergoeding.advieskostenGespreid
      : advieskostenBemiddelingsvergoeding.advieskosten,
    advieskostenBemiddelingsvergoeding.bemiddelingsvergoedingGeldleningGespreid &&
    advieskostenBemiddelingsvergoeding.bemiddelingsvergoedingGeldlening
      ? advieskostenBemiddelingsvergoeding.bemiddelingsvergoedingGeldlening -
        advieskostenBemiddelingsvergoeding.bemiddelingsvergoedingGeldleningGespreid
      : advieskostenBemiddelingsvergoeding.bemiddelingsvergoedingGeldlening,
    advieskostenBemiddelingsvergoeding.overigeBemiddelingsvergoedingGespreid &&
    advieskostenBemiddelingsvergoeding.overigeBemiddelingsvergoeding
      ? advieskostenBemiddelingsvergoeding.overigeBemiddelingsvergoeding -
        advieskostenBemiddelingsvergoeding.overigeBemiddelingsvergoedingGespreid
      : advieskostenBemiddelingsvergoeding.overigeBemiddelingsvergoeding,
    advieskostenBemiddelingsvergoeding.dossiervergoedingGeldlening
  ]
    .map(value => value || 0)
    .reduce((x, y): number => x + y, 0);
}
