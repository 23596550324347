import React, { ReactElement, useContext } from "react";
import { FormikProps } from "formik";
import {
  JaarMaandInputType,
  LabeledBevestigingInput,
  LabeledCurrencyInput,
  LabeledSelectInput,
  LabeledPercentageInput,
  LabeledJaarMaandInput
} from "adviesbox-shared";

import { PremiedepotUitgangspunt } from "../../.generated/forms/formstypes";

import { LabelValuePairs } from "../../shared/types";
import AdviesBoxFormik from "../../shared/utils/adviesbox-formik";
import Modal from "../../shared/components/modal/Modal";

import { PremieDepotModalType } from "../infra/producten-overzicht-types";
import { premiedepotModalSchema, PremiedepotModalContextType } from "../infra/producten-overzicht-schema";

import { determinePremieDepotModalSideEffects } from "./determine-premie-depot-modal-side-effects";
import { ISWSideEffects } from "../../shared/components/isw-side-effects/isw-side-effects";
import { determinePremieDepotModalAsyncSideEffects } from "./determine-premie-depot-modal-async-side-effects";
import { AppDataContext } from "../../navigation/appdata-context";

type PremieDepotModalProps = {
  data: PremieDepotModalType;
  looptijd: JaarMaandInputType;
  onSave?: (data: PremieDepotModalType) => void;
  closeModal?: () => void;
};

const uitgangspuntOptions: LabelValuePairs = [
  { label: "Geen premiedepot", value: PremiedepotUitgangspunt.Geen },
  { label: "Bedrag premiedepot", value: PremiedepotUitgangspunt.BedragDepot },
  { label: "Duur premiedepot", value: PremiedepotUitgangspunt.DuurDepot },
  { label: "Optimaal premiedepot", value: PremiedepotUitgangspunt.VolledigDepot }
];

const PremieDepotModal = ({ data, looptijd, onSave, closeModal }: PremieDepotModalProps): ReactElement => {
  const { setSErunning } = useContext(AppDataContext);
  return (
    <AdviesBoxFormik<PremieDepotModalType>
      initialValues={{ ...data }}
      validationSchema={premiedepotModalSchema}
      createValidationContext={(): PremiedepotModalContextType => ({ looptijd })}
      onSave={onSave}
      closeModal={closeModal}
      render={({ values, submitForm }: FormikProps<PremieDepotModalType>): ReactElement => (
        <>
          <ISWSideEffects<PremieDepotModalType>
            sync={determinePremieDepotModalSideEffects({ looptijd })}
            async={determinePremieDepotModalAsyncSideEffects}
            asyncStartStopCallback={setSErunning}
          />

          <Modal
            title="Premiedepot"
            body={
              <>
                <LabeledSelectInput caption="Uitgangspunt" name="uitgangspunt" options={uitgangspuntOptions} />

                {values.uitgangspunt !== PremiedepotUitgangspunt.Geen && (
                  <>
                    {<LabeledBevestigingInput caption="Lage premies opnemen" name="lagePremies" disabled={!data.lagePremies} />}
                    { <LabeledBevestigingInput caption="Hoge premies opnemen" name="hogePremies" disabled={!data.hogePremies} /> }
                    { <LabeledBevestigingInput caption="Aanvangsstorting opnemen" name="aanvangsstorting" disabled={!data.aanvangsstortingBedrag}/>}
                    { <LabeledBevestigingInput caption="Extra storting(en) opnemen" name="extraStortingen" disabled={!data.extraPremieStortingen?.length}/>}

                    <LabeledPercentageInput caption="Vergoeding" name="vergoeding" decimalen={2} />

                    <LabeledCurrencyInput
                      caption="Bedrag"
                      name="bedrag"
                      readonly={values.uitgangspunt !== PremiedepotUitgangspunt.BedragDepot}
                    />

                    <LabeledJaarMaandInput 
                      caption="Duur"
                      name="premieDuur"
                      readonly={
                        values.uitgangspunt === PremiedepotUitgangspunt.VolledigDepot ||
                        values.uitgangspunt === PremiedepotUitgangspunt.BedragDepot
                      }
                    />
                  </>
                )}
              </>
            }
            onSubmitClick={submitForm}
            onCancelClick={closeModal}
          />
        </>
      )}
    />
  );
};

PremieDepotModal.displayName = "PremieDepotModal";

export default PremieDepotModal;
