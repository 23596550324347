import { ErrorPage, PageLoading, useRequestInit, withErrorBoundary } from "adviesbox-shared";
import React, { ReactElement } from "react";
import useAbortableFetch from "use-abortable-fetch";
import { LeningOutput } from "../.generated/forms/formstypes";
import { Aanvraag } from "./aanvraag";
import { AanvraagOpBasisVanType, AanvraagState } from "./infra/aanvraag-schema";

type Props = {
  aanvraagData?: AanvraagState;
  checkGewensteHypotheek?: boolean;
};

export const AanvraagCheckAanleidingAjaxComponent = ({ aanvraagData, checkGewensteHypotheek }: Props): ReactElement => {
  const { params, settings, requestInit } = useRequestInit();
  const leningUrl =
    params && params.adviesdossier
      ? `${settings.klantdossiersFormsOrigin}/Adviesdossiers/${params.adviesdossier}/Lening`
      : null;
  const { error, data, loading } = useAbortableFetch<LeningOutput>(leningUrl, requestInit);

  if (error) {
    return <ErrorPage error={error} data={data} />;
  }

  if (typeof data === "string") {
    return <ErrorPage error={new Error("type is string")} data={data} />;
  }

  if (loading || !data || !data.leningen || !aanvraagData?.voorstelArray) {
    return <PageLoading />;
  }

  const leningen = data.leningen;

  if (checkGewensteHypotheek) {
    const gewensteVoorstelIds = Object.keys(leningen).map(key => leningen[key].algemeen?.gewensteHypotheekVoorstelId);

    const newVoorstelArray: AanvraagOpBasisVanType[] =
      aanvraagData.voorstelArray?.filter(v => v.id === gewensteVoorstelIds[0]) ?? [];
    aanvraagData.voorstelArray = newVoorstelArray.length ? newVoorstelArray : [aanvraagData.voorstelArray[0]];
  }

  if (aanvraagData.voorstelArray?.length === 1) {
    aanvraagData.aanvraagOpBasisVan = aanvraagData.voorstelArray[0].id;
    aanvraagData.fetchProducten = true;
    aanvraagData.isLoadingAanvraag = true;
  }

  return <Aanvraag {...aanvraagData} />;
};

export const AanvraagCheckAanleidingAjax = withErrorBoundary(AanvraagCheckAanleidingAjaxComponent);
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production")
  AanvraagCheckAanleidingAjax.displayName = "AanvraagCheckAanleidingAjax";
