import {
  Personalia as PersonaliaDlEntry,
  PersonaliaOutput as PersonaliaDlOutput,
  GeslachtOpties,
  PersonaliaKind,
  Schooltype,
  PersonaliaAanvrager,
  KlantDetails,
  Uitsluiting,
  Weigering,
  VerblijfsVergunningType
} from "../../.generated/forms/formstypes";

import {
  PersonaliaState,
  Geslacht,
  AantalKeer,
  SchoolType,
  aanvragerLevensgeschiedenisSchema,
  uitsluitingSchema,
  weigeringSchema,
  personaliaSchema,
  aanvrager1Schema,
  medeAanvragerOptiesSchema,
  aanvragerAdresSchema,
  legitimatiebewijsSchema,
  verblijfsvergunningSchema,
  kindSchema,
  aanvragerBurgerlijkeStatusSchema,
  aanvragerExtraSchema,
  aanvrager2ExtraSchema,
  SoortVerblijfsvergunning
} from "./personalia-schema";

import { InferType } from "yup";
import { mapAdresDlNaarUi } from "../../shared/generic-parts/adres/map-dl-2-ui";
import { createMapEnum } from "../../shared/utils/create-map-enum";
import { createMapToUi } from "../../shared/utils/create-map-to-ui";
import { hasValue, prefixWithZero } from "../../shared/utils/helpers";
import { sortVolgnummer } from "../../shared/utils/sort-volgnummer";
import { LocalDate } from "@js-joda/core";
import { mapStringToLocalDate } from "adviesbox-shared";

const mapSchooltype = createMapEnum(Schooltype).to<SchoolType>({
  Basisschool: SchoolType.BasisSchool,
  BovenbouwHavoVwo: SchoolType.BovenbouwHAVOVWO,
  BovenbouwVmbo: SchoolType.BovenbouwVMBO,
  Geen: SchoolType.GeenSchool, // TODO: klopt dit?
  GeenSchool: SchoolType.GeenSchool, // TODO: klopt dit?
  Hbo: SchoolType.Hbo,
  Mbo: SchoolType.Mbo,
  NietErkendeOpleiding: SchoolType.NietErkendeOpleiding,
  OnderbouwHavoVwo: SchoolType.OnderbouwHAVOVWO,
  OnderbouwVmbo: SchoolType.OnderbouwVMBO,
  Wo: SchoolType.Wo
});

const mapGeslachtOpties = createMapEnum(GeslachtOpties).to({
  Geen: Geslacht.Geen,
  Man: Geslacht.Man,
  Vrouw: Geslacht.Vrouw
});

const mapVerblijfsVergunningType = createMapEnum(VerblijfsVergunningType).to({
  BepaaldeTijd: SoortVerblijfsvergunning.BepaaldeTijd,
  // eslint-disable-next-line @typescript-eslint/camelcase
  BepaaldeTijdAsiel_DocumentIII_: SoortVerblijfsvergunning.BepaaldeTijdAsielDocumentIII,
  Geen: SoortVerblijfsvergunning.Geen,
  Onbekend: SoortVerblijfsvergunning.Onbekend,
  OnbepaaldeTijd: SoortVerblijfsvergunning.OnbepaaldeTijd,
  // eslint-disable-next-line @typescript-eslint/camelcase
  OnbepaaldeTijdAsiel_DocumentIV_: SoortVerblijfsvergunning.OnbepaaldeTijdAsielIV,
  BepaaldeTijdNietTijdelijkVerblijfsdoel: SoortVerblijfsvergunning.BepaaldeTijd
});

const mapMedeAanvragerOpties = createMapToUi(medeAanvragerOptiesSchema).from<PersonaliaDlEntry>({
  medeAanvrager: v => v.adviesMetMedeaanvrager,
  afwijkendAdres: v => v.aanvrager2HeeftAfwijkendAdres
});

const mapAanvrager = createMapToUi(aanvrager1Schema)
  .with<PersonaliaDlEntry>()
  .from<PersonaliaAanvrager>({
    hasTweedeAanvrager: (_, v) => v.adviesMetMedeaanvrager,
    klantId: v => v.klantId,
    achternaam: v => v.persoonsgegevens.achternaam,
    voorletters: v => v.persoonsgegevens.voorletters,
    tussenvoegselAchternaam: v => v.persoonsgegevens.voorvoegsel,
    titel: v => (hasValue(v.klantgegevens.titel) ? prefixWithZero(v.klantgegevens.titel) : null),
    voornamen: v => v.persoonsgegevens.voornamen,
    roepnaam: v => v.persoonsgegevens.roepnaam,
    geboortenaam: v => v.persoonsgegevens.geboortenaam,
    tussenvoegselGeboortenaam: v => v.persoonsgegevens.geboorteVoorvoegsel
  });

const mapAanvragerAdres = createMapToUi(aanvragerAdresSchema).from<PersonaliaAanvrager>({
  adres: v => mapAdresDlNaarUi(v.woonadres),
  geboortelandCode: v => v.persoonsgegevens.geboortelandCode,
  nationaliteitCode: v => v.persoonsgegevens.nationaliteitCode,
  fiscaleWoonstaatCode: v => v.klantgegevens.fiscaleWoonstaatCode,
  telefoonnummerPrive: v => v.persoonsgegevens.telefoonPrive,
  telefoonnummerWerk: v => v.persoonsgegevens.telefoonWerk,
  telefoonnummerMobiel: v => v.persoonsgegevens.telefoonMobiel,
  emailAdres: v => v.persoonsgegevens.email
});

const mapLegitimatieBewijs = createMapToUi(legitimatiebewijsSchema).from<KlantDetails>({
  soortLegitimatiebewijs: v => v.legitimatiebewijsSoort,
  nummerLegitimatiebewijs: v => v.legitimatiebewijsNummer,
  plaatsnaamAfgifte: v => v.legitimatiebewijsAfgifteplaats,
  landAfgifte: v => v.legitimatieLandCode,
  datumAfgifte: v => mapStringToLocalDate(v.legitimatiebewijsAfgiftedatum),
  geldigTot: v => mapStringToLocalDate(v.legitimatiebewijsGeldTotDatum)
});

const mapVerblijfsVergunning = createMapToUi(verblijfsvergunningSchema).from<KlantDetails>({
  soortVerblijfsvergunning: v => mapVerblijfsVergunningType(v.verblijfsvergunningCode),
  nummerVerblijfsvergunning: v => v.verblijfsvergunningNummer,
  geldigTot: v => mapStringToLocalDate(v.verblijfsvergunningGeldigTotDatum)
});

const mapPersonaliaKind = createMapToUi(kindSchema)
  .with<number>()
  .from<PersonaliaKind>({
    kindId: v => v.kindId,
    internalAanvragerNummer: (_, aanvragerNummer) => aanvragerNummer,
    naam: v => v.voornamen,
    coOuderschap: v => v.coOuderschap,
    gezamenlijkKind: v => v.gezamenlijkKind,
    inwonend: v => v.inwonend,
    kinderbijslagPerKwartaal: v => v.kinderbijslagPerKwartaal,
    kinderbijslagPerJaar: v => v.kinderbijslagPerJaar,
    geboortedatum: v => mapStringToLocalDate(v.geboortedatum),
    schooltype: v => mapSchooltype(v.schooltype),
    aantalKeer: v => (v.aantalKeerKinderbijslag === 1 ? AantalKeer.x1 : AantalKeer.x2)
  });

const mapAanvragerBurgerlijkeStatus = createMapToUi(aanvragerBurgerlijkeStatusSchema)
  .with<1 | 2>()
  .from<PersonaliaAanvrager>({
    rekeningId: v => (v.rekening ? v.rekening.rekeningId : null),
    iban: v => (v.rekening ? v.rekening.iban : null),
    gezamelijkeRekening: v => (v.rekening ? v.rekening.gezamenlijkeRekening : null),
    burgerservicenummer: v => null,
    burgerlijkeStaat: v => prefixWithZero(v.klantgegevens.burgerlijkeStaat),
    geboorteplaats: v => v.persoonsgegevens.geboorteplaats,
    legitimatiebewijs: v => mapLegitimatieBewijs(v.klantgegevens),
    verblijfsvergunning: v => mapVerblijfsVergunning(v.klantgegevens),
    kinderen: (v, aanvragerNummer) =>
      v.kinderen ? v.kinderen.map(kind => mapPersonaliaKind(aanvragerNummer)(kind)) : null
  });

const mapPersonaliaAanvrager1Extra = createMapToUi(aanvragerExtraSchema).from<PersonaliaAanvrager>({
  geboortedatum: v => mapStringToLocalDate(v.persoonsgegevens.geboortedatum),
  geslacht: v => mapGeslachtOpties(v.persoonsgegevens.geslacht),
  roker: v => v.klantgegevens.roken
});

const mapPersonaliaAanvrager2Extra = createMapToUi(aanvrager2ExtraSchema)
  .with<PersonaliaDlEntry>()
  .from<PersonaliaAanvrager>({
    geboortedatum: v => mapStringToLocalDate(v.persoonsgegevens.geboortedatum),
    geslacht: v => mapGeslachtOpties(v.persoonsgegevens.geslacht),
    roker: v => v.klantgegevens.roken,
    hasTweedeAanvrager: (_, v) => v.adviesMetMedeaanvrager
  });

const mapUitsluiting = createMapToUi(uitsluitingSchema).from<Uitsluiting>({
  uitsluitingId: v => v.uitsluitingId,
  maatschappijCode: v => v.maatschappijCode,
  maatschappijOmschrijving: v => v.maatschappijOmschrijving,
  uitsluitingsdatum: v => mapStringToLocalDate(v.uitsluitingsdatum),
  reden: v => v.reden
});

const mapWeigering = createMapToUi(weigeringSchema).from<Weigering>({
  weigeringId: v => v.weigeringId,
  maatschappijCode: v => v.maatschappijCode,
  maatschappijOmschrijving: v => v.maatschappijOmschrijving,
  weigeringDatum: v => mapStringToLocalDate(v.weigeringDatum),
  reden: v => v.reden
});

const mapPersonaliaLevensgeschiedenis = createMapToUi(aanvragerLevensgeschiedenisSchema).from<PersonaliaAanvrager>({
  hoogstGenotenOpleiding: v =>
    hasValue(v.klantgegevens.opleidingsNiveau) ? prefixWithZero(v.klantgegevens.opleidingsNiveau) : null,
  ooitGescheiden: v => v.klantgegevens.gescheiden,
  datumEchtscheiding: v => mapStringToLocalDate(v.klantgegevens.gescheidenPerDatum),
  scheiding: v => prefixWithZero(v.klantgegevens.gescheidenPartnerschap),
  hoofdelijkAansprakelijk: v => v.klantgegevens.hoofdelijkAansprakelijk,
  uitsluitingenVerzekeringen: v => {
    let uitsluitingen: InferType<typeof uitsluitingSchema>[] = [];
    if (v.uitsluitingen) {
      v.uitsluitingen.sort(sortVolgnummer);
      uitsluitingen = v.uitsluitingen.map(mapUitsluiting);
    }

    while (uitsluitingen.length < 9) {
      uitsluitingen.push(uitsluitingSchema.default());
    }

    return uitsluitingen;
  },
  weigeringenVerzekeringen: v => {
    let weigeringen: InferType<typeof weigeringSchema>[] = [];
    if (v.weigeringen) {
      v.weigeringen.sort(sortVolgnummer);
      weigeringen = v.weigeringen.map(mapWeigering);
    }

    while (weigeringen.length < 9) {
      weigeringen.push(weigeringSchema.default());
    }

    return weigeringen;
  }
});

const mapPersonalia = createMapToUi(personaliaSchema).from<PersonaliaDlEntry>({
  medeAanvragerOpties: mapMedeAanvragerOpties,
  aanvrager1: v => mapAanvrager(v)(v.aanvrager1),
  aanvrager2: v => mapAanvrager(v)(v.aanvrager2),
  aanvrager1Adres: v => mapAanvragerAdres(v.aanvrager1),
  aanvrager2Adres: v => mapAanvragerAdres(v.aanvrager2),
  aanvrager1BurgerlijkeStatus: v => mapAanvragerBurgerlijkeStatus(1)(v.aanvrager1),
  aanvrager2BurgerlijkeStatus: v => mapAanvragerBurgerlijkeStatus(2)(v.aanvrager2),
  aanvrager1Extra: v => mapPersonaliaAanvrager1Extra(v.aanvrager1),
  aanvrager2Extra: v => mapPersonaliaAanvrager2Extra(v)(v.aanvrager2),
  aanvrager1Levensgeschiedenis: v => mapPersonaliaLevensgeschiedenis(v.aanvrager1),
  aanvrager2Levensgeschiedenis: v => mapPersonaliaLevensgeschiedenis(v.aanvrager2),
  preventSave: _ => false,
  herberekeningskeuze: v => String(v.herberekeningkeuze ?? 1),
  initWaarschuwingen: v => ({
    aanvrager1GeboorteDatum: mapStringToLocalDate(v.aanvrager1.persoonsgegevens.geboortedatum),
    aanvrager2GeboorteDatum: v.aanvrager2
      ? mapStringToLocalDate(v.aanvrager2.persoonsgegevens.geboortedatum)
      : LocalDate.now()
          .minusYears(18)
          .minusMonths(2),
    aanvrager1Geslacht: mapGeslachtOpties(v.aanvrager1.persoonsgegevens.geslacht),
    aanvrager1roker: v.aanvrager1.klantgegevens.roken,
    aanvrager2Geslacht: v.aanvrager2 ? mapGeslachtOpties(v.aanvrager2.persoonsgegevens.geslacht) : Geslacht.Man,
    aanvrager2roker: v.aanvrager2 ? v.aanvrager2.klantgegevens.roken : false,
    medeAanvrager: v.adviesMetMedeaanvrager
  }),
  platformApiFouten: () => []
});

// geboortedatum: v => mapStringToLocalDate(v.persoonsgegevens.geboortedatum),
// geslacht: v => mapGeslachtOpties(v.persoonsgegevens.geslacht),
// roker: v => v.klantgegevens.roken

export function mapPersonaliaDlToUi(personaliaId: string, data: PersonaliaDlOutput): PersonaliaState | null {
  const personalia = data && data.isValid && data.personalia ? data.personalia[personaliaId] : null;

  if (personalia) {
    return personaliaSchema.cast(mapPersonalia(personalia));
  }

  return null;
}
