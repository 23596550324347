import { QuestionType, CardSpec, TitleType } from "../../infra/klantprofiel-schema";
import { KlantprofielVraagOptions } from "../../../.generated/forms/formstypes";

export const risicoprofielQuestionSpecs: CardSpec[] = [
  {
    questions: [
      {
        question: KlantprofielVraagOptions.BelangrijksteBronInkomen,
        type: QuestionType.radio
      }
    ],
    toelichting: false
  },
  {
    questions: [
      {
        question: KlantprofielVraagOptions.LevensonderhoudReguliereInkomsten,
        type: QuestionType.radio
      }
    ],
    toelichting: false
  },
  {
    questions: [
      {
        question: KlantprofielVraagOptions.WelkDeelVermogenBeleggen,
        type: QuestionType.radio
      }
    ],
    toelichting: false
  },
  {
    sections: [
      {
        title: TitleType.custom,
        customTitle: "Uw ervaring met beleggen in aandelen en/of obligaties is?",
        subtitle: "Geef het kennisniveau aan door met het bolletje te slepen",
        toelichting: false,
        questions: [
          {
            question: KlantprofielVraagOptions.ErvaringBeleggenAandelenObligaties,
            type: QuestionType.slider
          }
        ]
      }
    ]
  },
  {
    questions: [
      {
        question: KlantprofielVraagOptions.KennisBeleggenAandelenObligaties,
        type: QuestionType.radio
      }
    ],
    toelichting: false
  },
  {
    questions: [
      {
        question: KlantprofielVraagOptions.BeleggingsgedragVolgensStelling,
        type: QuestionType.radio
      }
    ],
    toelichting: false
  },
  {
    questions: [
      {
        question: KlantprofielVraagOptions.AlsWaardeVermogenAanzienlijkDaalt,
        type: QuestionType.radio
      }
    ],
    toelichting: false
  },
  {
    questions: [
      {
        question: KlantprofielVraagOptions.RisicoLopenVermogensopbouw,
        type: QuestionType.radio
      }
    ],
    toelichting: false
  },
  {
    questions: [
      {
        question: KlantprofielVraagOptions.HoeBelangrijkBereikenDoel,
        type: QuestionType.radio
      }
    ],
    toelichting: false
  }
];
