import { PremiedepotUitgangspunt } from "../../.generated/forms/formstypes";

import { initISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";

import { PremieDepotModalType } from "../infra/producten-overzicht-types";
import { JaarMaandInputType } from "../../shared/generic-parts/jaar-maand/schema";
import { jaarMaandInMaanden } from "adviesbox-shared";

type PremieDepotModalContext = { looptijd: JaarMaandInputType };

export const determinePremieDepotModalSideEffects = initISWSideEffect<PremieDepotModalType, PremieDepotModalContext>(
  ({ draft, has, context }) => {
    if (has.uitgangspunt.changed) {
      switch (draft.uitgangspunt) {
        case PremiedepotUitgangspunt.Geen:
          // Uitgangspunt is Geen => Alle velden leeg maken
          draft.lagePremies = false;
          draft.hogePremies = false;
          draft.aanvangsstorting = false;
          draft.extraStortingen = false;
          draft.vergoeding = null;
          draft.bedrag = null;
          draft.duur = null;
          break;

        case PremiedepotUitgangspunt.BedragDepot:
          // Uitgangspunt is Bedrag => Duur leeg maken
          draft.duur = null;
          break;

        case PremiedepotUitgangspunt.DuurDepot:
          // Uitgangspunt is Duur => Bedrag leeg maken
          draft.bedrag = null;
          break;
        case PremiedepotUitgangspunt.VolledigDepot:
          // Uitgangspunt is Optimaal => Duur is looptijd product
          draft.duur = jaarMaandInMaanden(context.looptijd);

          break;
      }
    }
  }
);
