import { createContext } from "react";

export type RouteParams = {
  vestiging: string;
  voorstel?: string;
  adviesdossier: string;
  organisatie?: string;
};

const defaultMatch: RouteParams = {
  vestiging: "",
  voorstel: "",
  adviesdossier: "",
  organisatie: ""
};

const ParamRouteContext = createContext(defaultMatch);

export default ParamRouteContext;
