import { LabeledNumberInput } from "adviesbox-shared";
import classNames from "classnames";
import { useFormikContext } from "formik";
import React, { ReactElement } from "react";
import { QuestionSpecCombined, QuestionType } from "../../klantprofiel-schema";

type QuestionJaarProps = {
  data: QuestionSpecCombined;
};

export const QuestionJaar = ({ data }: QuestionJaarProps): ReactElement => {
  const { setFieldValue } = useFormikContext();

  if (data.type !== QuestionType.jaar) {
    return <>{`invalid QuestionType: ${data.type}`}</>;
  }
  return (
    <>
      <div className={classNames({ "mt-3": data.schema.omschrijving })}>
        {data.schema.omschrijving && <h2 className="mb-3">{data.schema.omschrijving}</h2>}
        <LabeledNumberInput
          caption="Aantal jaar"
          name={`vragen[${data.index}].antwoorden[0].waarde1`}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
            if (!e.target.value) {
              return setFieldValue(`vragen[${data.index}].antwoorden[0].waarde1`, null);
            }
          }}
          data-testid={`labeled-input-${data.schema.code}-${data.index}`}
          key={`key-${data.index}`}
          fieldSize="s"
          appendChildren={<span>Jaar</span>}
        />
      </div>
    </>
  );
};
