import { hasJSONResponse, setFormikUiErrors, SettingsType, validationResultsToUiError } from "adviesbox-shared";
import { FormikContextType } from "formik";
import { User } from "oidc-client";
import { WoningenOutput } from "../../.generated/forms/formstypes";
import { target2field } from "../../shared/utils/target-to-field";
import { WoningState } from "../infra/woning-schema";
export const uploadFotoVanWoningApi = async (
  settings: SettingsType,
  user: User | null,
  vestigingId: string,
  adviesdossierId: string,
  body: FormData,
  formik: FormikContextType<WoningState>
): Promise<any> => {
  const url = `${settings.klantdossiersFormsOrigin}/Woning/UploadFotoWoning?adviesdossierId=${adviesdossierId}`;
  const rsp = await fetch(url, {
    method: "POST",
    body,
    headers: {
      Accept: "*/*",
      "Ocp-Apim-Subscription-Key": settings.OcpApimSubscriptionKey,
      authorization: `${user?.token_type} ${user?.access_token}`,
      vestigingId
    }
  });
  formik.setFieldValue("platformApiFouten", null);

  if (!rsp.ok) {
    const json = await rsp?.json();

    if (json.validationResults?.length)
      setFormikUiErrors(
        validationResultsToUiError(json.validationResults, (target: any) => target2field({}, target)),
        formik
      );
    else
      formik.setFieldValue("platformApiFouten", [
        {
          field: "",
          label: "Op te nemen foto",
          error: json.message ?? "Fout bij aanroep van web-api"
        }
      ]);

    throw Error(`Fout bij aanroep van web-api '${url}'.`);
  }

  return true;
};

export const getBlobUrlApi = async (
  settings: SettingsType,
  user: User | null,
  vestigingId: string,
  adviesdossierId: string
): Promise<string> => {
  const url = `${settings.klantdossiersFormsOrigin}/Adviesdossiers/${adviesdossierId}/Woning`;
  const rsp = await fetch(url, {
    method: "GET",
    headers: {
      authorization: `${user?.token_type} ${user?.access_token}`,
      "Ocp-Apim-Subscription-Key": settings.OcpApimSubscriptionKey,
      "Content-Type": "application/json;charset=UTF-8",
      vestigingId
    }
  });

  if (!(rsp.ok && hasJSONResponse(rsp))) {
    throw Error(`Fout bij aanroep van web-api '${url}'. Resultaat bevat geen JSON.`);
  }

  const json: WoningenOutput = await rsp.json();

  if (!json.isValid) {
    throw Error(`Fout bij aanroep van web-api '${url}'. Input is niet valid.`);
  }
  return (json?.woningen && Object.values(json.woningen)[0].aanTeKopenWoning?.pand?.locatieFoto) ?? "";
};
