import {
  LabeledAfbeelding,
  LabeledBevestigingInput,
  LabeledDateInput,
  LabeledJaarMaandInput,
  LabeledTextInput,
  SettingsContext
} from "adviesbox-shared";
import { connect, FormikContextType } from "formik";
import React, { ReactElement, useContext } from "react";
import { SoortKredietProductOptions } from "../../.generated/forms/formstypes";
import LabeledHdnKeuzelijst from "../../shared/components/hdn-keuzelijst/LabeledHdnKeuzelijst";
import LabeledMaatschappijKeuzelijst from "../../shared/components/maatschappij-keuzelijst/LabeledMaatschappijKeuzelijst";
import { Soort } from "../../shared/hooks/use-maatschappij-data";
import { partijAnders, partijOnafhankelijk } from "../infra/product-constanten";
import { ProductKenmerken } from "../infra/product-kenmerken-types";
import { getProductenOverzichtTextResources } from "../infra/producten-overzicht-resources";
import { ProductDetailType, ProductenState, ProductSoort, SituatieSoort } from "../infra/producten-overzicht-types";

type ProductProps = {
  selected: number;
  situatie: SituatieSoort;
  kenmerken: ProductKenmerken;
  productSoort: ProductSoort;
  isStarterslening: boolean;
};

function bepaalMaatschappijKeuzelijst(productSoort: ProductSoort): Soort {
  switch (productSoort) {
    case "vermogen":
      return "Vermogensbeheerders";
    case "hypotheek":
      return "Geldverstrekkers";
  }

  return "Verzekeringsmaatschappijen";
}

const Product = ({
  selected,
  situatie,
  kenmerken,
  productSoort,
  isStarterslening,
  formik: {
    setFieldValue,
    values: { producten }
  }
}: ProductProps & {
  formik: FormikContextType<ProductenState>;
}): ReactElement => {
  const { productenOrigin, OcpApimSubscriptionKey } = useContext(SettingsContext);

  /* istanbul ignore next */
  if (selected >= producten.length) return <></>;
  const product = producten[selected];

  const isDoorlopendInVoorstel = product.product.doorlopend && situatie === "voorstel";
  const isVoorstelHypotheek = productSoort === "hypotheek" && situatie === "voorstel";
  const isHuidigHypotheek = productSoort === "hypotheek" && situatie === "huidig";

  return (
    <>
      {kenmerken.productnummerTonen && (
        <LabeledTextInput caption="Productnummer" name={`producten[${selected}].product.productNummer`} />
      )}

      {(isVoorstelHypotheek ||
        (productSoort !== "overige" &&
          productSoort !== "hypotheek" &&
          producten[selected].partijCode !== partijOnafhankelijk)) && (
        <LabeledMaatschappijKeuzelijst
          caption="Partij"
          name={
            producten[selected].product.doorlopend && producten[selected].product.partijNaam
              ? `producten[${selected}].product.partijNaam`
              : `producten[${selected}].partijCode`
          }
          soort={bepaalMaatschappijKeuzelijst(productSoort)}
          readonly
        />
      )}

      {kenmerken.maatschappijkeuzeEnabled && (
        <>
          {(isHuidigHypotheek ||
            (productSoort !== "hypotheek" && producten[selected].partijCode === partijOnafhankelijk)) && (
            <LabeledHdnKeuzelijst
              berichtSoortType="AX"
              caption="Partij"
              name={`producten[${selected}].product.partijCodeSelectie`}
              keuzelijst={"MaatschappijType"}
              readonly={productSoort !== "orv" && productSoort !== "aov" && isDoorlopendInVoorstel}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>): void => {
                setFieldValue(`producten[${selected}].product.partijCodeSelectie`, e.target.value);
                if (e.target.selectedIndex !== -1) {
                  setFieldValue(
                    `producten[${selected}].product.partijNaam`,
                    e.target[e.target.selectedIndex].textContent
                  );
                }
              }}
            />
          )}
        </>
      )}

      {kenmerken.logoTonen &&
        producten[selected].partijCode &&
        situatie !== "huidig" &&
        producten[selected].partijCode !== partijOnafhankelijk && (
          //TODO: Goede location en name
          <LabeledAfbeelding
            caption="Partijlogo"
            name={`producten[${selected}].product.partij`}
            location={`${productenOrigin}/Logos/Logo/${producten[selected].partijCode}?subscription-key=${OcpApimSubscriptionKey}`}
          />
        )}

      {producten[selected].product.partijCodeSelectie === partijAnders && !isStarterslening && (
        <LabeledTextInput
          caption="Partijnaam"
          name={`producten[${selected}].product.partijNaam`}
          readonly={productSoort !== "orv" && productSoort !== "aov" && isDoorlopendInVoorstel}
        />
      )}

      {producten[selected].partijCode !== partijOnafhankelijk && isVoorstelHypotheek && !isStarterslening && (
        <>
          <LabeledTextInput caption="Productlabel" name={`producten[${selected}].labelNaam`} readonly />
        </>
      )}

      {kenmerken.productnaamTonen && (
        <LabeledTextInput
          caption="Productnaam"
          name={
            productSoort === "hypotheek"
              ? `producten[${selected}].hypotheekVorm.omschrijving`
              : `producten[${selected}].product.productNaam`
          }
          readonly={!kenmerken.productnaamEnabled}
        />
      )}

      {productSoort === "overige" && (
        <LabeledTextInput caption="Omschrijving" name={`producten[${selected}].product.omschrijving`} />
      )}

      {productSoort === "hypotheek" && (
        <LabeledTextInput
          caption="Hypotheekvorm"
          name={`producten[${selected}].hypotheekVorm.aflossingsvorm`}
          readonly
        />
      )}

      {kenmerken.ingangsdatumTonen && (
        <LabeledDateInput
          caption="Ingangsdatum"
          name={`producten[${selected}].product.ingangsdatum`}
          readonly={
            (productSoort !== "orv" && productSoort !== "aov" && (isDoorlopendInVoorstel || isVoorstelHypotheek)) ||
            producten[selected].partijCode === "CA" // Er is geen productkenmerk voor het disablen van ingangsdatum. Alleen bij Cardif moet dit veld altijd disabled zijn.
          }
          verplicht
        />
      )}
      {kenmerken.einddatumTonen && !isDoorlopendKredietZonderEinddatum(productSoort, product) && (
        <LabeledDateInput
          caption={productSoort === "krediet" && product.productCode === "02" ? "Theoretische einddatum" : "Einddatum"}
          name={`producten[${selected}].product.einddatum`}
          readonly={!kenmerken.einddatumEnabled || (productSoort !== "aov" && situatie === "voorstel")}
          verplicht
        />
      )}

      {kenmerken.looptijdTonen && !isDoorlopendKredietZonderLooptijd(productSoort, product) && (
        <LabeledJaarMaandInput
          caption="Looptijd"
          name={`producten[${selected}].product.looptijd`}
          readonly={productSoort !== "orv" && productSoort !== "aov" && isDoorlopendInVoorstel}
          jarenreadonly={!kenmerken.looptijdJaarEnabled}
          maandenreadonly={!kenmerken.looptijdMaandEnabled}
        />
      )}

      {kenmerken.uwBemiddelingTonen && (
        <LabeledBevestigingInput
          caption="Jouw bemiddeling"
          name={`producten[${selected}].product.uwBemiddeling`}
          tooltip={getProductenOverzichtTextResources("productUwBemiddeling")}
        />
      )}
    </>
  );
};

Product.displayName = "Product";

export default connect<ProductProps, ProductenState>(Product);

function isDoorlopendKredietZonderLooptijd(productSoort: ProductSoort, product: ProductDetailType): boolean {
  return (
    isDoorlopendKrediet(productSoort, product) &&
    product.product.looptijd.jaren === null &&
    product.product.looptijd.maanden === null
  );
}

function isDoorlopendKredietZonderEinddatum(productSoort: ProductSoort, product: ProductDetailType): boolean {
  return isDoorlopendKrediet(productSoort, product) && product.product.einddatum === null;
}

function isDoorlopendKrediet(productSoort: ProductSoort, product: ProductDetailType): boolean {
  return productSoort === "krediet" && product.soortProduct === SoortKredietProductOptions.DoorlopendKrediet;
}
