import { LabeledSelectInput, LabeledResult } from "adviesbox-shared";
import React, { ReactElement } from "react";
import { ControleMaandlastOptions, MotiveringOnderwerpsoort } from "../../../.generated/forms/formstypes";
import { LabelValuePairs } from "../../../shared/types";
import { MotiveringControleHypotheeknormType } from "../../infra/motivering-schema";
import { UitgangspuntenProperties } from "../uitgangspunten";

type ControleOpHypotheeknormProps = {
  type: MotiveringOnderwerpsoort;
  properties: UitgangspuntenProperties;
  controleHypotheeknorm: MotiveringControleHypotheeknormType;
};

const controleMaandlastOptions = (): LabelValuePairs => {
  return [
    {
      label: "Benodigd inkomen voor hypotheek",
      value: ControleMaandlastOptions.BenodigdInkomen
    },
    { label: "Werkelijke last toets", value: ControleMaandlastOptions.WerkelijkeLast }
  ];
};

export const ControleOpHypotheeknorm = ({
  type,
  properties,
  controleHypotheeknorm
}: ControleOpHypotheeknormProps): ReactElement => {
  return (
    <>
      <h2>Controle op hypotheeknorm</h2>
      {!!controleHypotheeknorm?.controleMaandlast && (
        <LabeledSelectInput
          options={controleMaandlastOptions()}
          readonly={true}
          caption="Controle op maandlast"
          name={`${properties.name}.controleHypotheeknorm.controleMaandlast`}
        />
      )}
      {!!controleHypotheeknorm?.controleerMaximaleHypotheek && (
        <LabeledResult
          caption="Maximale hypotheeksom"
          name={`${properties.name}.controleHypotheeknorm.controleerMaximaleHypotheek`}
          fieldSize="no-size"
          result={() => (controleHypotheeknorm?.controleerMaximaleHypotheek ? "Ja" : "Nee")}
        />
      )}
    </>
  );
};
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production")
  ControleOpHypotheeknorm.displayName = "ControleOpHypotheeknorm";
