import { ControleGewenstInkomenOptions } from "../../.generated/forms/formstypes";
import { createISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";
import { ControleNormType } from "../components/controle-norm/infra/controle-norm-schema";
import { ScenarioVanType } from "../components/scenario-van/scenario-van-schema";

export const syncScenarioVanSideEffects = createISWSideEffect<ScenarioVanType>((bag): void => {
  const { has, draft } = bag;

  if (has.changed) {
    if (!draft.aanvrager1Geselecteerd && !draft.aanvrager2Geselecteerd) {
      if (has.aanvrager1Geselecteerd.changed) {
        draft.aanvrager1Geselecteerd = true;
      }

      if (has.aanvrager2Geselecteerd.changed) {
        draft.aanvrager2Geselecteerd = true;
      }

      if (has.beiden.changed) {
        draft.aanvrager1Geselecteerd = true;
      }
    }
  }
});

export const syncScenarioMinimaalUitgangspuntSideEffects = createISWSideEffect<ControleNormType>((bag): void => {
  const { has, draft } = bag;
  if (has.controleMaandlast.changed || has.maximaleHypotheeksom.changed || has.controleGewenstInkomen.changed) {
    if (!draft.controleMaandlast && !draft.maximaleHypotheeksom && draft.controleGewenstInkomen === "Geen") {
      draft.controleGewenstInkomen = ControleGewenstInkomenOptions.Nbi;
    }
  }
});
