/* istanbul ignore file */
import { useRequestInit } from "adviesbox-shared";
import useAbortableFetch from "use-abortable-fetch";
import { HypotheeklabelDetailsOutput } from "../../.generated/producten/productentypes";
import { partijOnafhankelijk } from "../../producten-overzicht/infra/product-constanten";

export type useHypotheekDetailsResult = {
  loading: boolean;
  error: null | Error;
  data: HypotheeklabelDetailsOutput | null;
};

export function useHypotheekDetailsData(
  geldverstrekkerCode: string,
  hypotheeklabelCode: number | null
): useHypotheekDetailsResult {
  const {
    requestInit,
    settings: { productenOrigin }
  } = useRequestInit();
  const hypotheeklabel = geldverstrekkerCode === partijOnafhankelijk ? 0 : hypotheeklabelCode;
  const url = geldverstrekkerCode && hypotheeklabelCode
    ? `${productenOrigin}/MaatschappijCodes/${geldverstrekkerCode}/Hypotheeklabels/${hypotheeklabel}/Details`
    : null;
  const { error, data, loading } = useAbortableFetch<HypotheeklabelDetailsOutput>(url, requestInit);

  if (typeof data === "string") {
    return { error: new Error("Fout bij het laden van hypotheekvorm"), data: null, loading: false };
  }

  return {
    loading,
    error,
    data: data || null
  };
}
