import React, { ReactElement, useContext, useEffect, useMemo } from "react";

import { ErrorPage, PageLoading, SettingsType, useRequestInit, withErrorBoundary, ForceRerenderContext } from "adviesbox-shared";

import MaximaleHypotheek from "./maximale-hypotheek";

import { mapOrganisatieDlToUi } from "./infra/map-maximale-hypotheek-dl-to-ui";
import { InvalidAdviesboxResultErrorPage } from "../shared/components/fetch-error-page/fetch-error-page";
import UserDetailsContext from "../shared/user-details/user-details-context";
import useAbortableFetch from "use-abortable-fetch";
import { OrganisatiesOutput } from "../.generated/licenties/licentiestypes";
import { MaximaleHypotheekState } from "./infra/maximale-hypotheek-schema";

/* istanbul ignore file */
const createOrganisatieUrl = (s: SettingsType, organisatieId: string): string =>
  `${s.licentiesOrigin}/Organisaties/${organisatieId}`;

type MaximaleHypotheekOrganisatieAjaxprops = {
  data: MaximaleHypotheekState;
  saveData: any;
};

export const MaximaleHypotheekOrganisatieAjax = ({
  data,
  saveData
}: MaximaleHypotheekOrganisatieAjaxprops): ReactElement => {
  const { userDetails } = useContext(UserDetailsContext);
  const loadingDone = useContext(ForceRerenderContext);
  const { requestInit: requestInitORG, settings: settingsORG } = useRequestInit();
  const urlORG = useMemo(() => createOrganisatieUrl(settingsORG, userDetails.organisatieId), [
    settingsORG,
    userDetails
  ]);
  const { loading: loadingORG, error: platformErrorORG, data: platformDataORG } = useAbortableFetch<OrganisatiesOutput>(
    urlORG,
    requestInitORG
  );

  const dataORG = useMemo(
    () =>
      platformDataORG && typeof platformDataORG !== "string"
        ? mapOrganisatieDlToUi(platformDataORG, userDetails.organisatieId)
        : null,
    [platformDataORG, userDetails]
  );

  useEffect((): void => {
    if (loadingORG && platformDataORG && dataORG && loadingDone) {
      loadingDone();
    }
  }, [loadingORG, platformDataORG, loadingDone, dataORG]);

  if (loadingORG) {
    return <PageLoading />;
  }

  if (platformErrorORG) {
    return <ErrorPage error={platformErrorORG} data={platformDataORG} />;
  }

  if (!dataORG) {
    return <InvalidAdviesboxResultErrorPage />;
  }

  data.orgData = dataORG;
  return <MaximaleHypotheek saveData={saveData} {...data} />;
};

MaximaleHypotheekOrganisatieAjax.displayName = "MaximaleHypotheeOrganisatiekAjax";

export default withErrorBoundary(MaximaleHypotheekOrganisatieAjax);
