import React, { ReactElement, useContext } from "react";
import Modal from "../../shared/components/modal/Modal";
import { FormikProps } from "formik";
import * as Yup from "yup";

import { GebruikPandSoort } from "../../.generated/forms/formstypes";
import { overbruggingSideEffects } from "./overbruggingskrediet-modal-side-effects";
import { getFinancieringsbehoefteTextResources } from "../infra/financieringsbehoefte-resources";
import { ISWSideEffects } from "../../shared/components/isw-side-effects/isw-side-effects";
import { overbruggingskredietModalAsyncSideEffects } from "./determine-async-overbruggingskrediet-side-effects";
import {
  LabeledCurrencyInput,
  BerekenCurrencyButton,
  CurrencyInput,
  LabeledDateInput,
  LabeledPercentageInput,
  LabeledResult,
  LabeledNumberInput
} from "adviesbox-shared";
import AdviesBoxFormik from "../../shared/utils/adviesbox-formik";
import { OverbruggingskredietModal as OverbruggingskredietModalState } from "../infra/financieringsbehoefte-types";
import { OverbruggingskredietModalSchemaInPopup } from "../infra/financieringsbehoefte-schema";
import { bedragFormat } from "../../shared/utils/currency";
import { AppDataContext } from "../../navigation/appdata-context";

export type OverbruggingskredietModalData = {
  data: OverbruggingskredietModalState;
  pandGebruik: GebruikPandSoort;
};

type OverbruggingskredietModalProps = {
  onSave?: (data: OverbruggingskredietModalState) => void;
  closeModal?: () => void;
};

const OverbruggingskredietModal = ({
  data,
  pandGebruik,
  onSave,
  closeModal
}: OverbruggingskredietModalData & OverbruggingskredietModalProps): ReactElement => {
  const { setSErunning } = useContext(AppDataContext);

  const body = (values: OverbruggingskredietModalState): ReactElement => {
    return (
      <>
        <LabeledCurrencyInput
          caption="Overwaarde huidige woning(en)"
          name="data.overwaardeHuidigeWoningen"
          readonly={true}
          tooltip={getFinancieringsbehoefteTextResources("TooltipOverwaardeHuidigeWoningen")}
        />
        <LabeledCurrencyInput
          caption="Meenemen in overbruggingskrediet"
          name="data.meenemenInOverbruggingskrediet"
          disabled={values.overwaardeMeenemen}
          tooltip={getFinancieringsbehoefteTextResources("TooltipMeenemenInOverbruggingskrediet")}
          appendChildren={
            <BerekenCurrencyButton name="data.overwaardeMeenemen" berekenen={values.overwaardeMeenemen} />
          }
        />
        <LabeledPercentageInput
          caption="Rente overbruggingskrediet"
          name="data.renteOverbruggingskrediet"
          decimalen={2}
        />

        <LabeledNumberInput
          caption="Rentevastperiode"
          name={`data.rentevastAantalMaanden`}
          appendChildren={<div className="p-1">Maanden</div>}
        />

        <LabeledCurrencyInput caption="Afsluitkosten" name="data.afsluitkosten" />

        <LabeledDateInput
          caption="Ingangsdatum"
          name="data.ingangsdatum"
          tooltip={getFinancieringsbehoefteTextResources("TooltipIngangsdatumOverbruggingskrediet")}
          readonly={true}
        />
        <LabeledDateInput caption="Einddatum" name="data.einddatum" />
        <h3 className="pl-1 pt-3">Fiscale gegevens</h3>
        <LabeledPercentageInput
          caption="Deel box 1"
          name="data.fiscalegegevensDeelBox1Percentage"
          decimalen={2}
          readonly={true}
          appendChildren={<CurrencyInput name="data.fiscalegegevensDeelBox1Bedrag" className="pl-2" readonly={true} />}
        />

        <LabeledPercentageInput
          caption="Deel box 3"
          name="data.fiscalegegevensDeelBox3Percentage"
          decimalen={2}
          readonly={true}
          appendChildren={<CurrencyInput name="data.fiscalegegevensDeelBox3Bedrag" className="pl-2" readonly={true} />}
        />
        <h3 className="pl-1 pt-3">Totalen</h3>

        <LabeledResult
          caption="Hoogte overbruggingskrediet"
          name="hoogteOverbrugginskrediet"
          alignRight={true}
          result={() => {
            return bedragFormat(
              (values.fiscalegegevensDeelBox1Bedrag ?? 0) + (values.fiscalegegevensDeelBox3Bedrag ?? 0),
              0
            );
          }}
        />
        <LabeledCurrencyInput caption="Bruto rentelast per maand" name="data.brutoRentelastPerMaand" readonly={true} />
        <LabeledCurrencyInput
          caption="Netto rentelast per maand"
          name="data.nettoRentelastPerMaand"
          readonly={true}
          tooltip={getFinancieringsbehoefteTextResources("TooltipNettoRentelastPerMaand")}
        />
        <LabeledCurrencyInput caption="Bruto totale rentelast" name="data.brutoTotaleRentelast" readonly={true} />
        <LabeledCurrencyInput
          caption="Netto totale rentelast"
          name="data.nettoTotaleRentelast"
          readonly={true}
          tooltip={getFinancieringsbehoefteTextResources("TooltipNettoRentelastTotaal")}
        />
      </>
    );
  };

  return (
    <AdviesBoxFormik<OverbruggingskredietModalData>
      initialValues={{ data, pandGebruik }}
      validationSchema={Yup.object({
        data: OverbruggingskredietModalSchemaInPopup
      })}
      onSave={(state): void => onSave && onSave(state.data)}
      closeModal={closeModal}
      render={({ values, submitForm }: FormikProps<OverbruggingskredietModalData>): ReactElement => (
        <>
          <ISWSideEffects<OverbruggingskredietModalData>
            sync={overbruggingSideEffects}
            async={overbruggingskredietModalAsyncSideEffects}
            asyncStartStopCallback={setSErunning}
          />

          <Modal title="Overbrugging" body={body(values.data)} onSubmitClick={submitForm} onCancelClick={closeModal} />
        </>
      )}
    />
  );
};

export default OverbruggingskredietModal;
