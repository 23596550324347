import React, { ReactElement } from "react";
import { OrvUitkeringssoortOptions } from "../../.generated/forms/formstypes";
import { LabelValuePairs } from "../../shared/types";
import {
  LabeledRadioInput,
  LabeledCurrencyInput,
  LabeledDateInput,
  LabeledBevestigingInput,
  LabeledNumberInput,
  LabeledPercentageInput
} from "adviesbox-shared";
import { ModalVerzekeringType } from "../infra/orv-schema";

const OrvUitkeringssoortOptionsOptions: LabelValuePairs = [
  {
    label: "Annuïtair dalend",
    value: OrvUitkeringssoortOptions.AnnuïtairDalend
  },
  {
    label: "Gelijkblijvend",
    value: OrvUitkeringssoortOptions.Gelijkblijvend
  },
  {
    label: "Lineair dalend",
    value: OrvUitkeringssoortOptions.LineairDalend
  }
];

const VerzekeringPremievergelijker = ({ verzekering }: { verzekering: ModalVerzekeringType }): ReactElement => {
  return (
    <>
      <div className="pb-4">
        <LabeledRadioInput
          caption="Verzekeringsvorm"
          name={`verzekering.verzekeringsvorm`}
          options={OrvUitkeringssoortOptionsOptions}
        />
      </div>
      <LabeledDateInput caption="Aanvangsdatum" name={`verzekering.aanvangsdatum`} readonly={true} />

      <LabeledNumberInput
        verplicht={true}
        caption="Duur"
        name="verzekering.duurInJaren"
        allowNull={false}
        appendChildren={<span>Jaar</span>}
      />
      <LabeledCurrencyInput
        verplicht={true}
        caption="Verzekerd kapitaal"
        name="verzekering.verzekerdKapitaalBedrag"
        allowNull={false}
      />
      {verzekering.verzekeringsvorm === OrvUitkeringssoortOptions.AnnuïtairDalend && (
        <LabeledPercentageInput caption="Annuïteitspercentage" name="verzekering.annuiteitsPercentage" decimalen={2} />
      )}
      <LabeledBevestigingInput caption="Verpand" name="verzekering.verpand" />
    </>
  );
};

export default VerzekeringPremievergelijker;
