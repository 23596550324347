import { ErrorPage, PageLoading, SettingsType, withErrorBoundary } from "adviesbox-shared";
import React, { ReactElement, useCallback, useContext } from "react";

import { Aov } from "./aov";
import { InvalidAdviesboxResultErrorPage } from "../shared/components/fetch-error-page/fetch-error-page";
import { SituatieSoort } from "../producten-overzicht/infra/producten-overzicht-types";
import { mapAovDlToUi, AovDoToUiMapper } from "./infra/map-aov-dl-to-ui";
import { mapAovUiToDl, AovUiToDlMapper } from "./infra/map-aov-ui-to-dl";
import { mapDlTargetToAovUiField } from "./infra/map-aov-dl-target-to-ui-field";
import { useAdviesboxData } from "../shared/hooks/use-adviesbox-data";
import { useTarievenData } from "../shared/hooks/use-tarieven-data";
import { ProductkenmerkenContext } from "../producten-overzicht/infra/producten-context";
import { isKenmerkError } from "../producten-overzicht/infra/product-kenmerken-types";

type RouteParams = {
  vestiging: string;
  voorstel?: string;
  adviesdossier: string;
};

const AovAjaxComponent = ({ situatie }: { situatie: SituatieSoort }): ReactElement => {
  const { getProductKenmerken} = useContext(ProductkenmerkenContext);
  const { loading: tarievenLoading, error: tarievenError, data: tarieven } = useTarievenData("Fiscaal");
  const createUrl = useCallback(
    (s: SettingsType, p: RouteParams): string =>
      situatie === "huidig"
        ? `${s.klantdossiersFormsOrigin}/adviesdossiers/${p.adviesdossier}/Aov`
        : `${s.klantdossiersFormsOrigin}/voorstellen/${p.voorstel}/Aov`,
    [situatie]
  );

  const createDataId = useCallback((p: RouteParams) => (situatie === "huidig" ? p.adviesdossier : p.voorstel), [
    situatie
  ]);

  const mapDlToUi = useCallback((): AovDoToUiMapper=> {
    return mapAovDlToUi;
  }, []);

  const mapUiToDl = useCallback((): AovUiToDlMapper => {
    return mapAovUiToDl({ getProductkenmerken: (partijcode: string, productcode: string) => {
      const kenmerken = getProductKenmerken("Aov", partijcode, productcode, true);
      if (kenmerken === null) return null;
      if (isKenmerkError(kenmerken)) return null;
      return kenmerken;
    }});
  }, [getProductKenmerken])
  
  const { loading, error, data, platformData, saveData } = useAdviesboxData(
    createUrl,
    createDataId,
    mapDlToUi(),
    mapUiToDl(),
    mapDlTargetToAovUiField
  );

  if (loading || tarievenLoading ) {
    return <PageLoading />;
  }

  if (error) {
    return <ErrorPage error={error} data={platformData} />;
  }

  if (tarievenError) {
    return <ErrorPage error={tarievenError} data={JSON.stringify(tarieven)} />;
  }

  if (!data || !tarieven ) {
    return <InvalidAdviesboxResultErrorPage />;
  }

  return <Aov situatie={situatie} tarieven={tarieven}   saveData={saveData} {...data} />;
};

export const AovAjax = withErrorBoundary(AovAjaxComponent);
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production") AovAjax.displayName = "AovAjax";
