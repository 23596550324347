import { createGetTextResources } from "../../shared/utils/text-resources-algemeen";

export const getVermogenTextResources = createGetTextResources({
  ErrorBegindatumVoorAanvangdatum:
    "De begindatum van de onttrekking kan niet voor de aanvangdatum van de verzekering liggen.",
  ErrorBegindatumGeheelAantalMaandenVanAanvangdatum:
    "De begindatum van de onttrekking mag slechts een geheel aantal maanden met de aanvangdatum van de verzekering verschillen.",
  ErrorDuurInlegGroterDanLooptijd: "Duur inleg mag niet groter dan de looptijd zijn.",
  ErrorDuurHoogGroterDanLooptijd: "Duur hoge periode mag niet groter dan de looptijd zijn.",
  ErrorDuurOnttrekkingGroterDanLooptijd:
    "De duur van de onttrekking mag niet buiten de looptijd van de verzekering vallen.",
  ErrorEinddatumOnttrekkingVoorBegindatum: "De einddatum van de onttrekking kan niet voor de begindatum liggen.",
  ErrorEinddatumOnttrekkingenNaEinddatumProduct:
    "De einddatum van de onttrekkingen mag niet na de einddatum de verzekering liggen.",
  ErrorOpnameMaximaal: "Opname maximaal",
  TooltipKapitaalopbouwBox1:
    "Let op! Controle op de wettelijk maximaal toegestane bandbreedte van 1:10 dien je zelf uit te voeren."
});
