import { createMapToUi } from "../../shared/utils/create-map-to-ui";
import {
  Klantprofiel,
  KlantprofielVraag,
  KlantprofielAntwoord,
  KlantprofielOutput,
  KlantprofielVraagOptions
} from "../../.generated/forms/formstypes";
import {
  KlantprofielOptieType,
  klantprofielOptieSchema,
  klantprofielVragenSchema,
  klantprofielAntwoordenSchema,
  QuestionType
} from "./klantprofiel-schema";
import { mapKlantnaamDl2Ui } from "../../shared/generic-parts/klantnaam/map-dl-2-ui";
import { FieldMap, UiName } from "../../shared/types";
import { target2field } from "../../shared/utils/target-to-field";
import { getQuestionType } from "./question-helpers";
import { mapJaarMaandInputFromLooptijdDl2Ui } from "adviesbox-shared";

export function mapDlTargetToKlantProfielUiField(target: string): UiName | null {
  const map: FieldMap<Klantprofiel> = {};
  return target2field(map, target);
}

export const mapKlantprofielAntwoorden = createMapToUi(klantprofielAntwoordenSchema)
  .with<{ sliderIndex: boolean | null; vraag: KlantprofielVraagOptions }>()
  .from<KlantprofielAntwoord>({
    vraagCode: (_, { vraag }) => vraag,
    code: v => v.code.toString(),
    omschrijving: v => v.omschrijving,
    waarde1: (v, { sliderIndex }) => v.waarde1 ?? sliderIndex,
    waarde2: v => v.waarde2,
    waarde3: v => v.waarde3
  });

export const mapKlantprofielVraag = createMapToUi(klantprofielVragenSchema).from<KlantprofielVraag>({
  omschrijving: v => v.omschrijving,
  code: v => v.code,
  toelichting: v => v.toelichting,
  antwoorden: v => {
    if (getQuestionType(v.code) === QuestionType.slider) {
      let trueIndex = v.antwoorden.findIndex(a => a.waarde1 === true);
      if (trueIndex < 0) trueIndex = 0;
      return v.antwoorden.map((a, i) => mapKlantprofielAntwoorden({ sliderIndex: i === trueIndex, vraag: v.code })(a));
    }
    if (getQuestionType(v.code) === QuestionType.radioMetJaarMaanden) {
      return v.antwoorden.map(a =>
        mapKlantprofielAntwoorden({ sliderIndex: null, vraag: v.code })({
          ...a,
          waarde2: mapJaarMaandInputFromLooptijdDl2Ui(a.waarde2).jaren,
          waarde3: mapJaarMaandInputFromLooptijdDl2Ui(a.waarde2).maanden
        })
      );
    }
    if (getQuestionType(v.code) === QuestionType.jaarMaanden) {
      return v.antwoorden.map(a =>
        mapKlantprofielAntwoorden({ sliderIndex: null, vraag: v.code })({
          ...a,
          waarde1: mapJaarMaandInputFromLooptijdDl2Ui(a.waarde1).jaren,
          waarde2: mapJaarMaandInputFromLooptijdDl2Ui(a.waarde1).maanden
        })
      );
    }
    return v.antwoorden.map(a => mapKlantprofielAntwoorden({ sliderIndex: null, vraag: v.code })(a));
  },
  gekozenAntwoord: v =>
    [
      QuestionType.radio,
      QuestionType.radioMetJaarMaanden,
      QuestionType.radioMetText,
      QuestionType.radioMetJaar
    ].includes(getQuestionType(v.code))
      ? v.antwoorden.find(a => typeof a.waarde1 === "boolean" && a.waarde1)?.code.toString()
      : null
});

export const mapKlantprofielScherm = createMapToUi(klantprofielOptieSchema).from<Klantprofiel>({
  aanvrager1: v => mapKlantnaamDl2Ui(v.aanvrager1),
  aanvrager2: v => mapKlantnaamDl2Ui(v.aanvrager2),
  vragen: v => v.vragen.map(e => mapKlantprofielVraag(e)),
  risicoprofielStatus: _ => null
});

export function mapKlantprofielDlToUi(dataId: string, data: KlantprofielOutput): KlantprofielOptieType | null {
  const klantprofiel = data && data.klantprofielen ? data.klantprofielen[dataId] : null;
  if (klantprofiel && data.isValid) {
    return mapKlantprofielScherm(klantprofiel);
  }
  return null;
}
