/* istanbul ignore file */
import {
  Card,
  CardWrapper,
  FormFirstFocus,
  PageLoading,
  setFormikUiErrors,
  PlatformFoutenSamenvatting,
  MotiveringHeader,
  insightsReactPlugin
} from "adviesbox-shared";
import classnames from "classnames";
import { Form, FormikContextType, FormikProps, useFormikContext } from "formik";
import { toLower } from "lodash";
import React, { ReactElement } from "react";
import { InkomensAnalysePdf } from "../inkomens-analyse/inkomens-analyse-pdf";
import { MotiveringOnderwerpsoort } from "../.generated/forms/formstypes";
import { UitgangspuntenAdvies } from "../.generated/instellingen-forms/instellingen-formstypes";
import { Debug } from "../shared/components/formik/Debug";
import { ISWSideEffects } from "../shared/components/isw-side-effects/isw-side-effects";
import { SaveButton } from "../shared/components/save-button/save-button";
import { getNaam } from "../shared/utils/helpers";
import { WithSaveData } from "../shared/utils/save-data";
import { withAdviesboxFormik } from "../shared/utils/with-adviesbox-formik";
import { Advies } from "./advies/advies";
import { AfwijkendeKeuze } from "./afwijkende-keuze/afwijkende-keuze";
import { syncMotiveringSideEffects } from "./determine-motivering-sync-side-effects";
import {
  MotiveringProps,
  motiveringSchema,
  MotiveringState,
  uitgangspuntenCardType,
  VoorstelType,
} from "./infra/motivering-schema";
import classes from "./Motivering.module.scss";
import { DubbeleWoonlasten } from "./uitgangspunten/dubbele-woonlasten";
import { Uitgangspunten } from "./uitgangspunten/uitgangspunten";
import { Wens } from "./wens/wens";
import { determineTitleScherm, determineAanvragerHeader, determineTitleDubbeleWoonlastenCard } from "./determine-motivering-helper";
import { withAITracking } from "@microsoft/applicationinsights-react-js";

const determineUitgangspuntenCard = (
  values: MotiveringState,
  motiveringType: MotiveringOnderwerpsoort
): uitgangspuntenCardType | null => {
  const overlijdensrisicoVoorstelId = values.uitgangspuntenOverlijden?.voorstellen.find(
      v => v.voorstelId === values.uitgangspuntenOverlijden?.motiveringVoorVoorstelId
  );
  const pensioenVoorstelId = values.uitgangspuntenPensioen?.voorstellen.find(
      v => v.voorstelId === values.uitgangspuntenPensioen?.motiveringVoorVoorstelId
  );
  const werkloosheidVoorstelId = values.uitgangspuntenWerkloosheid?.voorstellen.find(
      v => v.voorstelId === values.uitgangspuntenWerkloosheid?.motiveringVoorVoorstelId
  );
  const arbeidsongeschiktheidVoorstelId = values.uitgangspuntenArbeidsongeschiktheid?.voorstellen.find(
      v => v.voorstelId === values.uitgangspuntenArbeidsongeschiktheid?.motiveringVoorVoorstelId
  );

  const voorstelType: VoorstelType | undefined =
      overlijdensrisicoVoorstelId ?? pensioenVoorstelId ?? werkloosheidVoorstelId ?? arbeidsongeschiktheidVoorstelId;
  switch (motiveringType) {
      case MotiveringOnderwerpsoort.AfdekkenWW:
          return {
              namePrefixInkomenAnalyseCard: "uitgangspuntenWerkloosheid",
              omschrijving: voorstelType?.omschrijving,
              volgnummer: voorstelType?.volgnummer,
              voorstelId: voorstelType?.voorstelId
          };
      case MotiveringOnderwerpsoort.AfdekkenAOWW:
          return {
              namePrefixInkomenAnalyseCard: "uitgangspuntenArbeidsongeschiktheid",
              omschrijving: voorstelType?.omschrijving,
              volgnummer: voorstelType?.volgnummer,
              voorstelId: voorstelType?.voorstelId
          };
      case MotiveringOnderwerpsoort.AfdekkenOverlijdensrisico:
          return {
              namePrefixInkomenAnalyseCard: "uitgangspuntenOverlijden",
              omschrijving: voorstelType?.omschrijving,
              volgnummer: voorstelType?.volgnummer,
              voorstelId: voorstelType?.voorstelId
          };
      case MotiveringOnderwerpsoort.Pensioenvoorziening:
          return {
              namePrefixInkomenAnalyseCard: "uitgangspuntenPensioen",
              omschrijving: voorstelType?.omschrijving,
              volgnummer: voorstelType?.volgnummer,
              voorstelId: voorstelType?.voorstelId
          };
      default:
          return null;
  }
};

const MotiveringComponent = (
  props: FormikProps<MotiveringState> & MotiveringProps & { motiveringType: MotiveringOnderwerpsoort }
): ReactElement => {
  const { isSubmitting, motiveringType } = props;
  const formik = useFormikContext<MotiveringState>();
  const values = formik.values;

  const hasSecondAanvrager = !!values.aanvrager2?.klantId;

  const tonenTweedeAanvrager =
    motiveringType !== MotiveringOnderwerpsoort.Algemeen &&
    motiveringType !== MotiveringOnderwerpsoort.Energie &&
    motiveringType !== MotiveringOnderwerpsoort.InbrengEigenGeld &&
    motiveringType !== MotiveringOnderwerpsoort.FiscaleAftrek &&
    motiveringType !== MotiveringOnderwerpsoort.HypotheekAflossen &&
    motiveringType !== MotiveringOnderwerpsoort.Rentevastperiode &&
    motiveringType !== MotiveringOnderwerpsoort.Geldverstrekker &&
    motiveringType !== MotiveringOnderwerpsoort.VerantwoordeWoonlasten &&
    motiveringType !== MotiveringOnderwerpsoort.WijzeOpbouwDoelvermogen &&
    motiveringType !== MotiveringOnderwerpsoort.Risicoprofiel;
  const pdfVoorstelId =
    values.uitgangspuntenHypotheek?.voorstellen.find(
      v => v.voorstelId === values.uitgangspuntenHypotheek?.motiveringVoorVoorstelId
    ) ||
    values.uitgangspuntenVermogen?.voorstellen.find(
      v => v.voorstelId === values.uitgangspuntenVermogen?.motiveringVoorVoorstelId
    );

  const uitgangspuntenCardGegevens = determineUitgangspuntenCard(values, motiveringType);

  // Bij Risico-kaarten moeten de kaarten Wens, Advies en Afwijkende keuze afhankelijk worden van 'ScenarioVan' uit Dashboard
  const scenarioVanKlantIds =
    uitgangspuntenCardGegevens?.namePrefixInkomenAnalyseCard &&
    values[uitgangspuntenCardGegevens?.namePrefixInkomenAnalyseCard]?.scenarioVanKlantIds;

  const aanvrager1UitDashboard = scenarioVanKlantIds?.find(id => id === values.aanvrager1?.klantId);
  const aanvrager2UitDashboard = scenarioVanKlantIds?.find(id => id === values.aanvrager2?.klantId);
  const beideAanvragersUitDashboard =
    uitgangspuntenCardGegevens?.namePrefixInkomenAnalyseCard &&
    values[uitgangspuntenCardGegevens?.namePrefixInkomenAnalyseCard]?.overzichtBeideTonen;

  const renderUitgangspunten = (
    motiveringType: MotiveringOnderwerpsoort,
    hasSecondAanvrager: boolean,
    instellingen: UitgangspuntenAdvies
  ): ReactElement => {
    return (
      <Card
        className={classnames(classes.margin_b_5)}
        title={`Uitgangspunten (${uitgangspuntenCardGegevens?.omschrijving ||
          "Voorstel #" + uitgangspuntenCardGegevens?.volgnummer})`}
        subtitle="De uitgangspunten die gebruikt zijn bij de inkomensanalyse"
        collapseExpandOption
        subtitleOnSeparateLine
        id="uitgangspunten-card"
      >
        <div className={classes.padding_b_10}>
          <Uitgangspunten
            instellingen={instellingen}
            hasSecondAanvrager={hasSecondAanvrager}
            type={motiveringType}
            financiering={values.financiering}
            namePrefix={uitgangspuntenCardGegevens?.namePrefixInkomenAnalyseCard ?? /* istanbul ignore next */ "uitgangspuntenWerkloosheid"}
          />
        </div>
      </Card>
    );
  };

  /* istanbul ignore next */
  const saveAndSetErrors = async (): Promise<void> => {
    const result = await props.saveData(values);
    if (result !== null && result instanceof Array) {
      setFormikUiErrors(result, formik as FormikContextType<MotiveringState>);
    } else {
      setFormikUiErrors([], formik as FormikContextType<MotiveringState>);
    }
  };

  /* istanbul ignore next */
  const getNaamScenarioKlant = (klantId: string): string => {
    if (values.aanvrager1.klantId === klantId) return getNaam(values.aanvrager1, "aanvrager 1");
    if (values.aanvrager2?.klantId === klantId) return getNaam(values.aanvrager2, "aanvrager 2");
    return "aanvrager";
  };

  return (
    <FormFirstFocus>
      <Form>
        {isSubmitting && <PageLoading />}

        <CardWrapper className="px-3">
          <div className="text-container">
            <h2>{determineTitleScherm(motiveringType)}</h2>
            {hasSecondAanvrager}
            <div className="save-btn-position">
              <div className="button-container">
                <SaveButton context={props} />
              </div>
            </div>
          </div>
        </CardWrapper>
        <PlatformFoutenSamenvatting />
        <ISWSideEffects<MotiveringState> sync={syncMotiveringSideEffects()} />
        <div>
          {scenarioVanKlantIds === undefined || scenarioVanKlantIds.length === 0 ? (
            <>
              {
                <>
                  { determineAanvragerHeader(motiveringType) && (
                    <MotiveringHeader title={hasSecondAanvrager ? `Wens ${getNaam(values.aanvrager1, "aanvrager 1")}` : "Wens"}>
                      <Wens aanvrager={1} />
                    </MotiveringHeader>
                  )}
                  <div className="px-3">
                    <CardWrapper maxRowCount={2}>
                      <Card
                        className={classnames("all-cards-same-size", classes.margin_b_5)}
                        title={
                          !determineAanvragerHeader(motiveringType) 
                            ? "Advies"
                            : (hasSecondAanvrager
                            ? `Advies ${getNaam(values.aanvrager1, "aanvrager 1")}`
                            : "Advies")
                        }
                      >
                        <Advies
                          aanvrager={1}
                          title={
                            !determineAanvragerHeader(motiveringType) 
                              ? "Advies"
                              : (hasSecondAanvrager
                              ? `Advies ${getNaam(values.aanvrager1, "aanvrager 1")}`
                              : "Advies")
                          }
                        />
                      </Card>
                      <Card
                        className={classnames("all-cards-same-size", classes.margin_b_5)}
                        title={
                          !determineAanvragerHeader(motiveringType) 
                            ? "Afwijkende keuze"
                            : (hasSecondAanvrager
                            ? `Afwijkende keuze ${getNaam(values.aanvrager1, "aanvrager 1")}`
                            : "Afwijkende keuze")
                        }
                      >
                        <AfwijkendeKeuze
                          aanvrager={1}
                          title={
                            hasSecondAanvrager
                              ? `Afwijkende keuze ${getNaam(values.aanvrager1, "aanvrager 1")}`
                              : "Afwijkende keuze"
                          }
                        />
                      </Card>
                    </CardWrapper>
                  </div>
                </>
              }
              {hasSecondAanvrager && tonenTweedeAanvrager && (
                <>
                  {determineAanvragerHeader(motiveringType) && (
                    <MotiveringHeader title={`Wens ${getNaam(values.aanvrager2, "aanvrager 2")}`}>
                      <Wens aanvrager={2} />
                    </MotiveringHeader>
                  )}
                  <div className="px-3">
                    <CardWrapper maxRowCount={2}>
                      <Card
                        className={classnames("all-cards-same-size", classes.margin_b_5)}
                        title={
                          !determineAanvragerHeader(motiveringType) ? "Advies" : `Advies ${getNaam(values.aanvrager2, "aanvrager 2")}`
                        }
                      >
                        <Advies
                          aanvrager={2}
                          title={
                            !determineAanvragerHeader(motiveringType) ? "Advies" : `Advies ${getNaam(values.aanvrager2, "aanvrager 2")}`
                          }
                        />
                      </Card>
                      <Card
                        className={classnames("all-cards-same-size", classes.margin_b_5)}
                        title={
                          !determineAanvragerHeader(motiveringType)
                            ? "Afwijkende Keuze"
                            : `Afwijkende Keuze ${getNaam(values.aanvrager2, "aanvrager 2")}`
                        }
                      >
                        <AfwijkendeKeuze
                          aanvrager={2}
                          title={
                            !determineAanvragerHeader(motiveringType)
                              ? "Afwijkende Keuze"
                              : `Afwijkende Keuze ${getNaam(values.aanvrager2, "aanvrager 2")}`
                          }
                        />
                      </Card>
                    </CardWrapper>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              {(aanvrager1UitDashboard || beideAanvragersUitDashboard) && (
                <>
                  <MotiveringHeader
                    title={hasSecondAanvrager ? `Wens ${getNaam(values.aanvrager1, "aanvrager 1")}` : "Wens"}
                  >
                    <Wens aanvrager={1} />
                  </MotiveringHeader>
                  <div className="px-3">
                    <CardWrapper maxRowCount={2}>
                      <Card
                        className={classnames("all-cards-same-size", classes.margin_b_5)}
                        title={hasSecondAanvrager ? `Advies ${getNaam(values.aanvrager1, "aanvrager 1")}` : "Advies"}
                      >
                        <Advies
                          aanvrager={1}
                          title={hasSecondAanvrager ? `Advies ${getNaam(values.aanvrager1, "aanvrager 1")}` : "Advies"}
                        />
                      </Card>
                      <Card
                        className={classnames("all-cards-same-size", classes.margin_b_5)}
                        title={
                          hasSecondAanvrager
                            ? `Afwijkende keuze ${getNaam(values.aanvrager1, "aanvrager 1")}`
                            : "Afwijkende keuze"
                        }
                      >
                        <AfwijkendeKeuze
                          aanvrager={1}
                          title={
                            hasSecondAanvrager
                              ? `Afwijkende keuze ${getNaam(values.aanvrager1, "aanvrager 1")}`
                              : "Afwijkende keuze"
                          }
                        />
                      </Card>
                    </CardWrapper>
                  </div>
                </>
              )}
              {tonenTweedeAanvrager && (aanvrager2UitDashboard || beideAanvragersUitDashboard) ? (
                <>
                  <MotiveringHeader title={`Wens ${getNaam(values.aanvrager2, "aanvrager 2")}`}>
                    <Wens aanvrager={2} />
                  </MotiveringHeader>
                  <div className="px-3">
                    <CardWrapper maxRowCount={2}>
                      <Card
                        className={classnames("all-cards-same-size", classes.margin_b_5)}
                        title={`Advies ${getNaam(values.aanvrager2, "aanvrager 2")}`}
                      >
                        <Advies aanvrager={2} title={`Advies ${getNaam(values.aanvrager2, "aanvrager 2")}`} />
                      </Card>
                      <Card
                        className={classnames("all-cards-same-size", classes.margin_b_5)}
                        title={`Afwijkende Keuze ${getNaam(values.aanvrager2, "aanvrager 2")}`}
                      >
                        <AfwijkendeKeuze
                          aanvrager={2}
                          title={`Afwijkende Keuze ${getNaam(values.aanvrager2, "aanvrager 2")}`}
                        />
                      </Card>
                    </CardWrapper>
                  </div>
                </>
              ) : null}
            </>
          )}
          {!!determineTitleDubbeleWoonlastenCard(motiveringType) && !!pdfVoorstelId && (
            <CardWrapper flexType={"flex-column"} className={`flex-nowrap pb-0`} maxRowCount={2}>
              <Card
                className={classnames(classes.margin_b_5)}
                title={`${determineTitleDubbeleWoonlastenCard(motiveringType)} (${pdfVoorstelId.omschrijving ||
                  "Voorstel #" + pdfVoorstelId.volgnummer})`}
                fullscreenTonen
                subtitleOnSeparateLine
                subtitleSplit
                collapseExpandOption
              >
                <div className={classes.padding_b_10}>
                  <DubbeleWoonlasten
                    namePrefix={values.uitgangspuntenVermogen ? "uitgangspuntenVermogen" : "uitgangspuntenHypotheek"}
                    motiveringType={motiveringType}
                  />
                  <InkomensAnalysePdf
                    motivering={motiveringType}
                    voorstelId={pdfVoorstelId.voorstelId}
                    onRefresh={saveAndSetErrors}
                    dataOutdated={values.dataHasChanged}
                    setDataOutdated={/* istanbul ignore next */ value => formik.setFieldValue("dataHasChanged", value)}
                  />
                </div>
              </Card>
            </CardWrapper>
          )}

          {(motiveringType === MotiveringOnderwerpsoort.AfdekkenAOWW ||
            motiveringType === MotiveringOnderwerpsoort.AfdekkenWW ||
            motiveringType === MotiveringOnderwerpsoort.Pensioenvoorziening ||
            motiveringType === MotiveringOnderwerpsoort.AfdekkenOverlijdensrisico) && (
            <>
              <CardWrapper flexType={"flex-column"} className={`flex-nowrap pb-0`} maxRowCount={2}>
                {renderUitgangspunten(motiveringType, hasSecondAanvrager, props.instellingenUitgangspuntenAdviesData)}
              </CardWrapper>
              {[
                ...(values.uitgangspuntenArbeidsongeschiktheid?.scenarioVanKlantIds || /* istanbul ignore next */ []),
                ...(values.uitgangspuntenOverlijden?.scenarioVanKlantIds || /* istanbul ignore next */ []),
                ...(values.uitgangspuntenWerkloosheid?.scenarioVanKlantIds || /* istanbul ignore next */ []),
                ...(values.uitgangspuntenPensioen?.scenarioVanKlantIds || /* istanbul ignore next */ [])
              ].map((c, index) => (
                <CardWrapper flexType={"flex-column"} maxRowCount={1} key={index}>
                  <Card
                    title="Inkomensanalyse"
                    fullscreenTonen
                    subtitle={`Jouw inkomen bij ${toLower(
                      determineTitleScherm(motiveringType)
                    )} van ${getNaamScenarioKlant(c)}`}
                    collapseExpandOption
                    subtitleOnSeparateLine
                    subtitleSplit
                  >
                    <InkomensAnalysePdf
                      motivering={motiveringType}
                      voorstelId={uitgangspuntenCardGegevens?.voorstelId}
                      klantId={c}
                      dataOutdated={values.dataHasChanged}
                      setDataOutdated={/* istanbul ignore next */ value => formik.setFieldValue("dataHasChanged", value)}
                    />
                  </Card>
                </CardWrapper>
              ))}
              {(values.uitgangspuntenArbeidsongeschiktheid?.overzichtBeideTonen ||
                values.uitgangspuntenOverlijden?.overzichtBeideTonen ||
                values.uitgangspuntenWerkloosheid?.overzichtBeideTonen ||
                values.uitgangspuntenPensioen?.overzichtBeideTonen) && (
                <CardWrapper flexType={"flex-column"} maxRowCount={1}>
                  <Card
                    title="Inkomensanalyse"
                    fullscreenTonen
                    subtitle={`Jouw inkomen bij ${toLower(determineTitleScherm(motiveringType))} van beiden`}
                    collapseExpandOption
                    subtitleOnSeparateLine
                    subtitleSplit
                  >
                    <InkomensAnalysePdf
                      motivering={motiveringType}
                      voorstelId={uitgangspuntenCardGegevens?.voorstelId}
                      dataOutdated={values.dataHasChanged}
                      setDataOutdated={/* istanbul ignore next */ value => formik.setFieldValue("dataHasChanged", value)}
                    />
                  </Card>
                </CardWrapper>
              )}
            </>
          )}
        </div>
        <Debug />
      </Form>
    </FormFirstFocus>
  );
};

export const Motivering = withAdviesboxFormik<MotiveringProps & WithSaveData<MotiveringState>, MotiveringState>({
  // Transform outer props into form values
  mapPropsToValues: (e: MotiveringProps): MotiveringState => ({
    aanvrager1: e.data.aanvrager1,
    aanvrager2: e.data.aanvrager2,
    advies: e.data.advies,
    afwijkendeKeuze: e.data.afwijkendeKeuze,
    arbeidsongeschiktheidDatagrid: e.data.arbeidsongeschiktheidDatagrid,
    dubbeleWoonlasten: e.data.dubbeleWoonlasten,
    uitgangspunten: e.data.uitgangspunten,
    uitgangspuntenArbeidsongeschiktheid: e.data.uitgangspuntenArbeidsongeschiktheid,
    uitgangspuntenOverlijden: e.data.uitgangspuntenOverlijden,
    uitgangspuntenPensioen: e.data.uitgangspuntenPensioen,
    uitgangspuntenWerkloosheid: e.data.uitgangspuntenWerkloosheid,
    uitgangspuntenHypotheek: e.data.uitgangspuntenHypotheek,
    uitgangspuntenVermogen: e.data.uitgangspuntenVermogen,
    wens: e.data.wens,
    tabellenResourceUrl: e.data.tabellenResourceUrl,
    uitgangspuntenDubbeleWoonlasten: e.data.uitgangspuntenDubbeleWoonlasten,
    financiering: e.data.financiering,
    dataHasChanged: e.data.dataHasChanged
  }),
  validationSchema: motiveringSchema
})(withAITracking(insightsReactPlugin, MotiveringComponent));
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production") Motivering.displayName = "Motivering";
