import React, { ReactElement } from "react";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";

import { OverigeWerkzaamhedenModalType } from "../infra/inkomen-en-fiscus-schema";

import { scenarioCardSchema } from "../../shared/types";
import { LocalDate } from "@js-joda/core";
import { birthdate2age } from "../../shared/utils/birthdate-to-age";
import { ScenarioCard } from "../../shared/components/scenario-input/scenario-card";
import Modal from "../../shared/components/modal/Modal";

export type OverigeWerkzaamhedenScenarioData = {
  geboorteDatum: LocalDate | null | undefined;
  data: OverigeWerkzaamhedenModalType;
};

type OverigeWerkzaamhedenScenarioProps = {
  onSave?: (data: OverigeWerkzaamhedenModalType) => void;
  closeModal?: () => void;
};

const OverigeWerkzaamhedenScenario = ({
  geboorteDatum,
  data,
  onSave,
  closeModal
}: OverigeWerkzaamhedenScenarioData & OverigeWerkzaamhedenScenarioProps): ReactElement => {
  const age = geboorteDatum ? birthdate2age(geboorteDatum) : 18;
  const entries = data.scenario.length;

  return (
    <Formik
      initialValues={{ overigeWerkzaamheden: data }}
      validationSchema={Yup.object({
        overigeWerkzaamheden: Yup.object({
          scenario: scenarioCardSchema
        })
      })}
      onSubmit={(values): void => {
        onSave && onSave(values.overigeWerkzaamheden);
        closeModal && closeModal();
      }}
    >
      {(formik: FormikProps<{ overigeWerkzaamheden: OverigeWerkzaamhedenModalType }>): ReactElement => {
        const {
          values: { overigeWerkzaamheden }
        } = formik;

        const body = (
          <ScenarioCard
            name={`overigeWerkzaamheden.scenario`}
            startBedrag={overigeWerkzaamheden.inkomensverleden.gemiddeld || 0}
            captionStart={age}
            captionSuffix={" jr"}
            entries={entries}
          />
        );

        return (
          <>
            <Modal
              title="Scenario overige werkzaamheden"
              body={body}
              onSubmitClick={formik.submitForm}
              onCancelClick={closeModal}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default OverigeWerkzaamhedenScenario;
