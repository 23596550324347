import { createSpanWithId, DataGrid, Label, LabeledRadioInput, TextInput } from "adviesbox-shared";
import { FormikProps } from "formik";
import React, { ReactElement, useMemo, useState } from "react";
import { Column } from "react-table-6";
import { getAanvragerOpties } from "../../producten-overzicht/infra/producten-helper";
import HdnKeuzelijst from "../../shared/components/hdn-keuzelijst/HdnKeuzelijst";
import Modal from "../../shared/components/modal/Modal";
import { KlantnaamType } from "../../shared/generic-parts/klantnaam/schema";
import { UseHdnDataResult } from "../../shared/hooks/use-hdn-data";
import AdviesBoxFormik from "../../shared/utils/adviesbox-formik";
import {
  maatwerkoplossingenEntrySchema,
  MaatwerkoplossingenEntryType,
  maatwerkoplossingModalValuesSchema,
  MaatwerkoplossingModalValuesType
} from "../infra/aanvraag-lening-schema";

export type MaatwerkoplossingenModalData = {
  maatwerkLijst: {
    [index: string]: string;
  };
  maatschappijCode: string;
  aanvrager1: KlantnaamType | null;
  aanvrager2: KlantnaamType | null;
  data: MaatwerkoplossingenEntryType[];
};

export type MaatwerkoplossingenModalProps = {
  onSave?: (data: MaatwerkoplossingenEntryType[]) => void;
  closeModal?: () => void;
};

const MaatwerkoplossingenModal = ({
  maatwerkLijst,
  maatschappijCode,
  aanvrager1,
  aanvrager2,
  data,
  onSave,
  closeModal
}: MaatwerkoplossingenModalData & MaatwerkoplossingenModalProps): ReactElement => {
  const mogelijkeMaatwerkoplossingen: string[] = useMemo(
    () => Object.keys(maatwerkLijst).filter(key => key.startsWith(maatschappijCode)),
    [maatwerkLijst, maatschappijCode]
  );

  const hdnKeuzelijstVoorDitVoorstel: UseHdnDataResult = useMemo(
    () =>
      mogelijkeMaatwerkoplossingen.reduce(
        (acc, key): UseHdnDataResult => {
          const reducedOptions = {
            error: null,
            hdnKeuzelijst: {
              ...acc.hdnKeuzelijst,
              [key]: maatwerkLijst[key]
            }
          };

          return reducedOptions;
        },
        { error: null as Error | null, hdnKeuzelijst: {} }
      ),
    [maatwerkLijst, mogelijkeMaatwerkoplossingen]
  );

  const selectedState = useState(0);
  const [selected] = selectedState;
  const columns: Column[] = [
    {
      id: "modal",
      Cell: c => createSpanWithId(c.index, 0, maatwerkLijst[c.original.reden])
    },
    {
      Cell: "DeleteButton"
    }
  ];

  const options = getAanvragerOpties(aanvrager1, aanvrager2, false);

  const body = (
    setFieldValue: FormikProps<MaatwerkoplossingenEntryType>["setFieldValue"],
    values: MaatwerkoplossingModalValuesType
  ): JSX.Element => (
    <>
      <DataGrid
        masterDetail
        columns={columns}
        name="modal"
        rowSelected={selectedState}
        validationSchema={maatwerkoplossingenEntrySchema}
        rowCaption="maatwerkoplossing"
        getNewRowValues={
          /* istanbul ignore next */ (): MaatwerkoplossingenEntryType => ({
            ...maatwerkoplossingenEntrySchema.default(),
            betrekkingOpKlantId: aanvrager1?.klantId ?? ""
          })
        }
      />

      {values.modal.length > 0 && (
        <>
          <LabeledRadioInput
            caption="Betrekking op"
            name={`modal[${selected}].betrekkingOpKlantId`}
            options={options}
          />

          <div className="form-group form-row">
            <div className={`col-12`}>
              <Label caption="Specificeer a.u.b. de reden voor de maatwerkoplossing" />
            </div>
            <div className={`col-6`}>
              <Label caption="Type" />
            </div>
            <div className={`col-6`}>
              <HdnKeuzelijst
                keuzelijst="ExplainRedenType"
                berichtSoortType="AX"
                name={`modal[${selected}].seniorType`}
              />
            </div>

            <div className={`col-6`}>
              <Label caption="Reden" />
            </div>
            <div className={`col-6`}>
              <HdnKeuzelijst
                emptyValue={"Selecteer een optie"}
                name={`modal[${selected}].reden`}
                hdnData={hdnKeuzelijstVoorDitVoorstel}
                berichtSoortType="AX"
                keuzelijst="MaatwerkOplCodeMijType"
              />
            </div>

            <div className={`col-6`}>
              <Label caption="Toelichting" />
            </div>
            <div className={`col-3`}>
              <TextInput name={`modal[${selected}].toelichting`} textarea={4} fullWidth />
            </div>
          </div>
        </>
      )}
    </>
  );

  return (
    <AdviesBoxFormik<{ modal: MaatwerkoplossingenEntryType[] }>
      initialValues={{ modal: data }}
      validationSchema={maatwerkoplossingModalValuesSchema}
      onSave={(values): void => onSave && onSave(values.modal)}
      closeModal={closeModal}
      render={({ submitForm, setFieldValue, values }: FormikProps<MaatwerkoplossingModalValuesType>): ReactElement => (
        <>
          <Modal
            title="Maatwerkoplossingen"
            body={body(setFieldValue, values)}
            onSubmitClick={submitForm}
            onCancelClick={closeModal}
          />
        </>
      )}
    />
  );
};

MaatwerkoplossingenModal.displayName = "MaatwerkoplossingenModal";

export default MaatwerkoplossingenModal;
