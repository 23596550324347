import { SoortProductOptions } from "../../.generated/rapportage/rapportagetypes";

export function mapProductSoortToDisplayname(input: SoortProductOptions | null): string {
  switch (input) {
    case SoortProductOptions.Hypotheek:
      return "Hypotheek";
    case SoortProductOptions.Kapitaalverzekering:
      return "Kapitaalverzekering";
    case SoortProductOptions.Krediet:
      return "Krediet";
    case SoortProductOptions.Lijfrente:
      return "Uitkerende lijfrente";
    case SoortProductOptions.Overig:
      return "Overige financiële posten";
    case SoortProductOptions.Overlijdensrisicoverzekering:
      return "ORV";
    case SoortProductOptions.Vermogensrekening:
      return "Vermogen";
    case SoortProductOptions.Woonlastenverzekering:
      return "AOV";
    default:
      return input ? SoortProductOptions[input] : "Productnaam onbekend";
  }
}
