import {
  Adres,
  AdresReadOnly,
  KlantgegevensReadOnly,
  Taxatie,
  TaxatieAanvullendeInformatie,
  TaxatieAlgemeen,
  TaxatieBouwkundigeKeuring,
  Taxatiebureau,
  TaxatieContactpersoon,
  TaxatieNwwiGeldverstrekkerOutput,
  TaxatieObject,
  TaxatieOutput,
  TaxatiePersoon,
  TaxatieVerbouwingSpecificatie
} from "../../.generated/forms/formstypes";
import adresSchema from "../../shared/generic-parts/adres/schema";
import { mapKlantgegevensDl2Ui } from "../../shared/generic-parts/klantnaam/map-dl-2-ui";
import { AdresType, FieldMap, UiName } from "../../shared/types";
import { createMapToUi } from "../../shared/utils/create-map-to-ui";
import { optellen } from "../../shared/utils/currency";
import { target2field } from "../../shared/utils/target-to-field";
import {
  AanvullendeInformatieSchema,
  BouwkundigeKeuringSchema,
  ContactpersoonSchema,
  GeldverstrekkerNwwiResultaat,
  OpdrachtgeverSchema,
  PartnerSchema,
  TaxatieAlgemeenSchema,
  taxatieBaseKenmerken,
  TaxatiebureauSchema,
  TaxatieObjectSchema,
  taxatieSchermSchema,
  TaxatieSchermType,
  TaxatieVerbouwingSpecificatieSchema
} from "./taxatie-scherm-schema";
import { mapStringToLocalDate } from "adviesbox-shared";

export function mapDlTargetToTaxatieUiField(target: string): UiName | null {
  const map: FieldMap<Taxatie> = {};
  return target2field(map, target);
}

export const mapVerbouwingdataDl2Ui = createMapToUi(TaxatieVerbouwingSpecificatieSchema).from<
  TaxatieVerbouwingSpecificatie
>({
  verbouwingId: v => v.verbouwingId,
  volgnummer: v => v.volgnummer,
  werkzaamheid: v => v.werkzaamheid,
  bedrag: v => v.bedrag,
  eigenBeheer: v => v.eigenBeheer ?? false,
  verbouwingType: _ => null
});

const mapTaxatiebureauDl2Ui = createMapToUi(TaxatiebureauSchema).from<Taxatiebureau>({
  maatschappijCode: v => v.maatschappijCode,
  soortAanvraag: v => v.soortAanvraag,
  andersTeWeten: v => v.andersTeWeten,
  agentnummer: v => v.agentnummer,
  productCode: v => v.productCode,
  taxatieProductNaam: v => v.taxatieProductNaam
});

export const mapAdresTaxatieDl2Ui = createMapToUi(adresSchema).from<Adres>({
  huisnummer: v => v.huisnummer,
  landCode: v => v.landCode,
  locatie: v => v.locatie,
  plaats: v => v.plaats,
  postcode: v => {
    if (v.postcode && v.landCode === "NL") {
      const postcode = v.postcode.toUpperCase().replace(/\s/g, "");
      const letters = postcode.slice(4, 6);
      const numbers = postcode.slice(0, 4);
      if (numbers.charAt(0) !== "") {
        return `${numbers} ${letters}`;
      }
    }
    return v.postcode;
  },
  straat: v => v.straat,
  toevoeging: v => v.toevoeging
});

const mapTaxatieObjectDl2Ui = createMapToUi(TaxatieObjectSchema).from<TaxatieObject>({
  adresPand: v => mapAdresTaxatieDl2Ui(v.adresPand),
  aankoopprijsBedrag: v => v.aankoopprijsBedrag,
  nieuwbouw: v => v.nieuwbouw,
  bouwplan: v => v.bouwplan,
  bouwnummer: v => v.bouwnummer,
  gebruikMomenteel: v => v.gebruikMomenteel,
  huurovereenkomst: v => v.huurovereenkomst,
  opdrachtgever: v => v.opdrachtgever,
  gebruikToekomst: v => v.gebruikToekomst,
  gebruikToekomstAnders: v => v.gebruikToekomstAnders
});

export const mapTaxatieVerbouwingSpecificaties = createMapToUi(TaxatieVerbouwingSpecificatieSchema).from<
  TaxatieVerbouwingSpecificatie
>({
  verbouwingId: v => v.verbouwingId,
  volgnummer: v => v.volgnummer,
  werkzaamheid: v => v.werkzaamheid,
  bedrag: v => v.bedrag,
  eigenBeheer: v => v.eigenBeheer,
  verbouwingType: v => v.verbouwingType
});

const mapTaxatieAlgemeenDl2Ui = createMapToUi(TaxatieAlgemeenSchema).from<TaxatieAlgemeen>({
  doelTaxatie: v => v.doelTaxatie,
  geldverstrekker: v => v.geldverstrekker,
  geldverstrekkerCode: v => v.geldverstrekker,
  nhg: v => v.nhg,
  referentieGeldverstrekker: v => v.referentieGeldverstrekker,
  herbouwwaarde: v => v.herbouwwaarde,
  nwwiAanvraag: v => v.nwwiAanvraag,
  validatieVerplicht: v => v.validatieVerplicht,
  energiePrestatieAdvies: v => v.energiePrestatieAdvies,
  bouwtechnischeKeuring: v => v.bouwtechnischeKeuring,
  aanvraagdatum: v => mapStringToLocalDate(v.aanvraagdatum),
  gereedDatum: v => mapStringToLocalDate(v.gereedDatum),
  passeerdatum: v => mapStringToLocalDate(v.passeerdatum),
  voortaxatieGewenst: v => v.voortaxatieGewenst,
  faxVerstuurd: v => v.faxVerstuurd,
  recenteVerkoop: v => v.recenteVerkoop,
  bemiddelingsKostenBedrag: v => v.bemiddelingsKostenBedrag,
  verbouwing: v => v.verbouwing,
  voorVerkrijgenLening: v => v.voorVerkrijgenLening,
  beinvloedingWaarde: v => v.beinvloedingWaarde,
  toelichtingBeinvloedingWaarde: v => v.toelichtingBeinvloedingWaarde,
  verbouwingSpecificaties: v => v.verbouwingSpecificaties?.map(mapTaxatieVerbouwingSpecificaties),
  verbouwingSpecificatiesTotaal: v => optellen(v.verbouwingSpecificaties?.map(r => r.bedrag) ?? []),
  taxateurNwwiKeuze: _ => null,
  geldverstrekkerNwwiKeuze: _ => null,
  geldverstrekkerWaarschuwingTonen: _ => false,
  taxateurWaarschuwingTonen: _ => false,
  betreftAankoop: v => v.betreftAankoop,
  verbouwingTonen: v => v.verbouwing,
  voorVerkrijgenLeningTonen: v => v.voorVerkrijgenLening
});

const mapContactpersoonDl2Ui = createMapToUi(ContactpersoonSchema).from<TaxatieContactpersoon>({
  typeContactpersoon: v => v.typeContactpersoon,
  naam: v => v.naam,
  naamMakelaar: v => v.naamMakelaar,
  telefoonPrive: v => v.telefoonPrive,
  telefoonWerk: v => v.telefoonWerk,
  telefoonMobiel: v => v.telefoonMobiel,
  woonplaats: v => v.woonplaats,
  geslacht: v => v.geslacht,
  postcode: v => v.postcode,
  email: v => v.email
});

const mapAanvullendeInformatieDl2Ui = createMapToUi(AanvullendeInformatieSchema).from<TaxatieAanvullendeInformatie>({
  opmerkingen: v => v.opmerkingen,
  spoed: v => v.spoed,
  naamEigenaar: v => v.naamEigenaar,
  telefoonnummer: v => v.telefoonnummer,
  taxateurnummer: v => v.taxateurnummer,
  geldverstrekker: v => v.geldverstrekker,
  taxateur: v => v.taxateur,
  opdrachtnummer: v => v.opdrachtnummer,
  externReferentienummer: v => v.externReferentienummer,
  betalingViaNotaris: v => v.betalingViaNotaris,
  tussenpersoonNaam: v => v.tussenpersoonNaam,
  tussenpersoonNummer: v => v.tussenpersoonNummer
});

const mapBouwkundigeKeuringDl2Ui = createMapToUi(BouwkundigeKeuringSchema).from<TaxatieBouwkundigeKeuring>({
  bouwjaar: v => v.bouwjaar,
  inhoudwoning: v => v.inhoudwoning,
  keuringTenBehoevenVan: v => v.keuringTenBehoevenVan,
  keuringTenBehoevenVanAnders: v => v.keuringTenBehoevenVanAnders,
  benodigdeKeuring: v => v.benodigdeKeuring,
  monument: v => v.monument,
  gestapeldeBouw: v => v.gestapeldeBouw,
  vve: v => v.vve,
  inhoud: v => v.inhoud,
  soortWoning: v => v.soortWoning
});

export function mapTaxatieAdres(
  valuesDl?: Adres | AdresReadOnly | null,
  aanvragerAdres?: Adres | AdresReadOnly | null
): AdresType {
  if (!valuesDl) valuesDl = {} as Adres | AdresReadOnly;

  let postcode = valuesDl.postcode || (aanvragerAdres?.postcode ?? "");
  if (valuesDl.landCode === "NL") {
    postcode = postcode.replace(" ", "");
  }

  const result: AdresType = {
    straat: valuesDl.straat || aanvragerAdres?.straat || "",
    huisnummer: valuesDl.huisnummer || aanvragerAdres?.huisnummer || null,
    toevoeging: valuesDl.toevoeging || aanvragerAdres?.toevoeging || "",
    locatie: valuesDl.locatie || aanvragerAdres?.locatie || "",
    plaats: valuesDl.plaats || aanvragerAdres?.plaats || "",
    landCode: valuesDl.landCode || aanvragerAdres?.landCode || "NL",
    postcode: postcode
  };

  return adresSchema.cast(result);
}

const mapOpdrachtgeverToUi = createMapToUi(OpdrachtgeverSchema)
  .with<KlantgegevensReadOnly | null>()
  .from<TaxatiePersoon>({
    achternaam: (v, aanvrager1) => v.achternaam ?? aanvrager1?.achternaam,
    emailAdres: v => v.email,
    geboortedatum: (v, aanvrager1) => mapStringToLocalDate(v.geboortedatum ?? aanvrager1?.geboortedatum),
    geboortelandCode: v => v.geboortelandCode,
    geslacht: v => v.geslacht,
    voorletters: (v, aanvrager1) => v.voorletters ?? aanvrager1?.voorletters,
    voornamen: (v, aanvrager1) => v.voornamen ?? aanvrager1?.voornamen,
    voorvoegsel: (v, aanvrager1) => v.voorvoegsel ?? aanvrager1?.voorvoegsel,
    roepnaam: v => v.roepnaam,
    telefoonnummerMobiel: v => v.telefoonMobiel,
    telefoonnummerPrive: v => v.telefoonPrive,
    telefoonnummerWerk: v => v.telefoonWerk,
    adres: (v, aanvrager1) =>
      mapTaxatieAdres(
        v.adres,
        aanvrager1 && {
          straat: aanvrager1.straat,
          huisnummer: aanvrager1.huisnummer,
          toevoeging: aanvrager1.toevoeging,
          postcode: aanvrager1.postcode,
          plaats: aanvrager1.plaats,
          locatie: "",
          landCode: ""
        }
      ),
    iban: v => v.iban
  });

const mapPartnerDl2Ui = createMapToUi(PartnerSchema)
  .with<KlantgegevensReadOnly | null>()
  .from<TaxatiePersoon>({
    achternaam: (v, aanvrager2) => v.achternaam ?? aanvrager2?.achternaam,
    emailAdres: v => v.email,
    geboortedatum: (v, aanvrager2) => mapStringToLocalDate(v.geboortedatum ?? aanvrager2?.geboortedatum),
    geboortelandCode: v => v.geboortelandCode,
    geslacht: v => v.geslacht,
    voorletters: (v, aanvrager2) => v.voorletters ?? aanvrager2?.voorletters,
    voornamen: (v, aanvrager2) => v.voornamen ?? aanvrager2?.voornamen,
    voorvoegsel: (v, aanvrager2) => v.voorvoegsel ?? aanvrager2?.voorvoegsel,
    roepnaam: v => v.roepnaam,
    telefoonnummerMobiel: v => v.telefoonMobiel,
    telefoonnummerPrive: v => v.telefoonPrive,
    telefoonnummerWerk: v => v.telefoonWerk,
    adres: (v, aanvrager2) =>
      mapTaxatieAdres(
        v.adres,
        aanvrager2 && {
          straat: aanvrager2.straat,
          huisnummer: aanvrager2.huisnummer,
          toevoeging: aanvrager2.toevoeging,
          postcode: aanvrager2.postcode,
          plaats: aanvrager2.plaats,
          locatie: "",
          landCode: ""
        }
      ),
    iban: v => v.iban
  });

export const mapTaxatieScherm = createMapToUi(taxatieSchermSchema).from<Taxatie>({
  aanvrager1: v => mapKlantgegevensDl2Ui(v.aanvrager1),
  aanvrager2: v => mapKlantgegevensDl2Ui(v.aanvrager2),
  taxatiebureau: v => mapTaxatiebureauDl2Ui(v.taxatiebureau),
  opdrachtgever: v => mapOpdrachtgeverToUi(v.aanvrager1)(v.opdrachtgever),
  taxatieObject: v => mapTaxatieObjectDl2Ui(v.taxatieObject),
  partner: v => mapPartnerDl2Ui(v.aanvrager2)(v.partner),
  bouwkundigeKeuring: v => mapBouwkundigeKeuringDl2Ui(v.bouwkundigeKeuring),
  taxatieAlgemeen: v => mapTaxatieAlgemeenDl2Ui(v.taxatieAlgemeen),
  contactpersoon: v => mapContactpersoonDl2Ui(v.contactpersoon),
  aanvullendeInformatie: v => mapAanvullendeInformatieDl2Ui(v.aanvullendeInformatie),
  aanvragenInvalidText: _ => [],
  dataOutDated: _ => false,
  taxatieKenmerken: _ => taxatieBaseKenmerken,
  isLoading: _ => false,
  TaxatieSoortAanvraagOptionsOpties: _ => [],
  gewenstehypotheekVoorstelId: _ => null,
  taxatieAlgemeenReadOnly: v => v.taxatieAlgemeenReadOnly
});

export function mapTaxatieDlToUi(dataId: string, data: TaxatieOutput): TaxatieSchermType | null {
  const taxatie = data && data.taxaties ? data.taxaties[dataId] : null;
  if (taxatie && data.isValid) {
    return mapTaxatieScherm(taxatie);
  }
  return null;
}

export const mapGeldverstrekkerDl2Ui = createMapToUi(GeldverstrekkerNwwiResultaat).from<
  TaxatieNwwiGeldverstrekkerOutput
>({
  bedrijfsnaam: v => v.contactpersoon?.bedrijfsnaam,
  contractId: v => v.contractId,
  titel: v => v.contactpersoon?.titel,
  contractType: v => v.contractType,
  plaats: v => v.adres?.plaats
});
