import React, { ReactElement, useCallback } from "react";
import { connect, FormikContextType } from "formik";
import WeigeringenModal from "../weigeringen-modal/weigeringen-modal";
import UitsluitingenModal from "../uitsluitingen-modal/uitsluitingen-modal";
import { Icon, LabeledDateInput, LabeledBevestigingInput, LabeledResult, SyncHeightProps, SyncHeight } from "adviesbox-shared";
import { bindSaveFunction } from "../../shared/utils/bind-save-function";
import LabeledHdnKeuzelijst from "../../shared/components/hdn-keuzelijst/LabeledHdnKeuzelijst";
import { ModalButton } from "adviesbox-shared";
import { PersonaliaState } from "../infra/personalia-schema";

const Aanvrager2Levensgeschiedenis = ({
  formik: {
    setFieldValue,
    values: { aanvrager2Levensgeschiedenis }
  },
  targetRef,
  sourceRef
}: {
  formik: FormikContextType<PersonaliaState>;
} & SyncHeightProps): ReactElement => {
  const createSaveFunction = useCallback(bindSaveFunction(setFieldValue), [setFieldValue]);

  const uitsluitingen = aanvrager2Levensgeschiedenis.uitsluitingenVerzekeringen;
  const weigeringen = aanvrager2Levensgeschiedenis.weigeringenVerzekeringen;

  return (
    <>
      <LabeledHdnKeuzelijst
        caption="Hoogst genoten opleiding"
        name="aanvrager2Levensgeschiedenis.hoogstGenotenOpleiding"
        berichtSoortType="AX"
        keuzelijst="OpleidingsNiveauType"
      />
      <SyncHeight targetRef={targetRef} sourceRef={sourceRef}>
        <LabeledBevestigingInput caption="Ooit gescheiden" name="aanvrager2Levensgeschiedenis.ooitGescheiden" />
        {aanvrager2Levensgeschiedenis.ooitGescheiden && (
          <LabeledDateInput
            caption="Datum echtscheiding"
            name="aanvrager2Levensgeschiedenis.datumEchtscheiding"
            hidden={!!aanvrager2Levensgeschiedenis.ooitGescheiden}
          />
        )}

        {aanvrager2Levensgeschiedenis.ooitGescheiden && (
          <LabeledHdnKeuzelijst
            caption="Scheiding"
            name="aanvrager2Levensgeschiedenis.scheiding"
            berichtSoortType="AX"
            keuzelijst="SoortOntbondenType"
          />
        )}
      </SyncHeight>
      <LabeledBevestigingInput
        caption="Hoofdelijk aansprakelijk"
        name="aanvrager2Levensgeschiedenis.hoofdelijkAansprakelijk"
      />
      <LabeledResult
        caption="Uitsluiting(en) verzekeringen"
        result={(): string =>
          uitsluitingen.filter(uitsluiting => uitsluiting.maatschappijCode.length).length.toString()
        }
        name="aanvrager2Levensgeschiedenis.uitsluitingenVerzekeringen"
        readonly
        appendChildren={
          <ModalButton
            parent="aanvrager2Levensgeschiedenis.uitsluitingenVerzekeringen"
            content={<Icon name="specificatie" alt="Uitsluitingen" />}
          >
            <UitsluitingenModal
              data={aanvrager2Levensgeschiedenis.uitsluitingenVerzekeringen}
              onSave={createSaveFunction("aanvrager2Levensgeschiedenis.uitsluitingenVerzekeringen")}
            />
          </ModalButton>
        }
      />
      <LabeledResult
        caption="Weigering(en) verzekeringen"
        result={(): string => weigeringen.filter(weigering => weigering.maatschappijCode.length).length.toString()}
        name="aanvrager2Levensgeschiedenis.weigeringenVerzekeringen"
        readonly
        appendChildren={
          <ModalButton
            parent="aanvrager2Levensgeschiedenis.weigeringenVerzekeringen"
            content={<Icon name="specificatie" alt="Weigeringen" />}
          >
            <WeigeringenModal
              data={aanvrager2Levensgeschiedenis.weigeringenVerzekeringen}
              onSave={createSaveFunction("aanvrager2Levensgeschiedenis.weigeringenVerzekeringen")}
            />
          </ModalButton>
        }
      />
    </>
  );
};

export default connect<SyncHeightProps, PersonaliaState>(Aanvrager2Levensgeschiedenis);
