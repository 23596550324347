import { beschikbareHypotheekProducten } from "adviesbox-shared";
import {
  GeldverstrekkerPartij,
  VerzekeraarPartij
} from "../../.generated/instellingen-forms/instellingen-formstypes";
import { HypotheekvormBasis } from "../../.generated/producten/productentypes";
import { partijFilter } from "./partij-filter";

//Filter vanuit instellingenbeheer partijen
export const partijInstellingenFilter = (
  beschikbareGeldverstrekkers: GeldverstrekkerPartij[] | VerzekeraarPartij[],
  maatschappijCode: string
): boolean => beschikbareGeldverstrekkers.find(c => c?.code && c.code === maatschappijCode && c.actief) !== undefined;

export const productenFilter = (
  beschikbareGeldverstrekkers: GeldverstrekkerPartij[] | VerzekeraarPartij[],
  maatschappijCode: string,
  labelCode: number | null
): boolean =>
  partijInstellingenFilter(beschikbareGeldverstrekkers, maatschappijCode) &&
  partijFilter(
    { maatschappijCode: maatschappijCode, code: labelCode } as HypotheekvormBasis,
    beschikbareHypotheekProducten
  );
