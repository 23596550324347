import { Icon, LabeledText, LabeledTextInput, ModalButton, SyncHeight, SyncMultipleHeightProps } from "adviesbox-shared";
import { connect, FormikContextType } from "formik";
import React, { ReactElement, useCallback } from "react";
import { Algemeen } from "../../.generated/tarieven/tarieventypes";
import LabeledHdnKeuzelijst from "../../shared/components/hdn-keuzelijst/LabeledHdnKeuzelijst";
import { bindSaveFunction } from "../../shared/utils/bind-save-function";
import classes from "../aanvrager-burgelijke-status.module.scss";
import { BurgerlijkeStatusSyncHeightIndex, PersonaliaState } from "../infra/personalia-schema";
import { bindSaveKinderenFunction } from "../kinderen-modal/bind-save-kinderen-function";
import KinderenModal from "../kinderen-modal/kinderen-modal";
import LegitimatiebewijsModal from "../legitimatiebewijs-modal/legitimatiebewijs-modal";
import VerblijfsvergunningModal from "../verblijfsvergunning-modal/verblijfsvergunning-modal";

const Aanvrager2BurgerlijkeStatus = ({
  tarieven,
  formik: { values, setFieldValue },
  sourceRef,
  targetRef
}: {
  tarieven: Algemeen;
  formik: FormikContextType<PersonaliaState>;
} & SyncMultipleHeightProps): ReactElement => {
  const createSaveFunction = useCallback(bindSaveFunction(setFieldValue), [setFieldValue]);
  const saveKinderenFunction = useCallback(bindSaveKinderenFunction(setFieldValue, 2), [setFieldValue]);

  const alleKinderen = values.aanvrager2BurgerlijkeStatus.kinderen.concat(
    values.aanvrager1BurgerlijkeStatus.kinderen.filter(kind => kind.gezamenlijkKind)
  );
  return (
    <SyncHeight
      sourceRef={sourceRef && sourceRef[BurgerlijkeStatusSyncHeightIndex.All]}
      targetRef={targetRef && targetRef[BurgerlijkeStatusSyncHeightIndex.All]}
    >
      <LabeledHdnKeuzelijst
        caption="Legitimatiebewijs"
        name="aanvrager2BurgerlijkeStatus.legitimatiebewijs.soortLegitimatiebewijs"
        berichtSoortType="AX"
        keuzelijst="LegitimatieSoortType"
        appendChildren={
          <ModalButton
            parent="aanvrager2BurgerlijkeStatus.legitimatiebewijs"
            content={<Icon name="specificatie" alt="Legitimatiebewijs" />}
          >
            <LegitimatiebewijsModal
              data={values.aanvrager2BurgerlijkeStatus.legitimatiebewijs}
              onSave={createSaveFunction("aanvrager2BurgerlijkeStatus.legitimatiebewijs")}
            />
          </ModalButton>
        }
      />
      <LabeledHdnKeuzelijst
        caption="Verblijfsvergunning"
        name="aanvrager2BurgerlijkeStatus.verblijfsvergunning.soortVerblijfsvergunning"
        berichtSoortType="AX"
        keuzelijst="VerblijfsVergunningType"
        appendChildren={
          <ModalButton
            parent="aanvrager2BurgerlijkeStatus.verblijfsvergunning"
            content={<Icon name="specificatie" alt="Verblijfsvergunning" />}
          >
            <VerblijfsvergunningModal
              data={values.aanvrager2BurgerlijkeStatus.verblijfsvergunning}
              onSave={createSaveFunction("aanvrager2BurgerlijkeStatus.verblijfsvergunning")}
            />
          </ModalButton>
        }
      />
      <SyncHeight
        sourceRef={sourceRef && sourceRef[BurgerlijkeStatusSyncHeightIndex.Iban]}
        targetRef={targetRef && targetRef[BurgerlijkeStatusSyncHeightIndex.Iban]}
      >
        <LabeledTextInput caption="IBAN" name="aanvrager2BurgerlijkeStatus.iban" className={classes.ibanuppercase} />
      </SyncHeight>
      {/* <LabeledBevestigingInput
        caption="Gezamelijke rekening"
        name="aanvrager2BurgerlijkeStatus.gezamelijkeRekening"
      /> */}
      <LabeledHdnKeuzelijst
        caption="Burgerlijke staat"
        name="aanvrager2BurgerlijkeStatus.burgerlijkeStaat"
        berichtSoortType="AX"
        keuzelijst="BurgerlijkeStaatType"
      />
      <LabeledTextInput caption="Geboorteplaats" name="aanvrager2BurgerlijkeStatus.geboorteplaats" />
      <LabeledText
        caption="Kinderen"
        value={`${alleKinderen.length}`}
        fieldSize="no-size"
        name="aanvrager2BurgelijkeStatus.kinderen"
        appendChildren={
          <ModalButton
            parent="aanvrager2BurgerlijkeStatus.kinderen"
            content={<Icon name="specificatie" alt="Kinderen" />}
          >
            <KinderenModal tarieven={tarieven} data={alleKinderen} onSave={saveKinderenFunction(values)} medeaanvrager={values.medeAanvragerOpties.medeAanvrager} />
          </ModalButton>
        }
      />
    </SyncHeight>
  );
};

export default connect<{ tarieven: Algemeen } & SyncMultipleHeightProps, PersonaliaState>(Aanvrager2BurgerlijkeStatus);
