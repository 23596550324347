import React, { ReactElement } from "react";
import { connect, FormikContextType } from "formik";

import { LabeledRadioInput } from "adviesbox-shared";
import { KlantnaamType } from "../../shared/generic-parts/klantnaam/schema";
import { LabelValuePairs } from "../../shared/types";

import { getNaam } from "../../shared/utils/helpers";
import { OverigePostenType } from "../infra/overige-posten-types";

function getAanvragerOpties(aanvrager1: KlantnaamType | null, aanvrager2: KlantnaamType | null): LabelValuePairs {
  const aanvragerLijst = [];

  if (aanvrager1) {
    aanvragerLijst.push({
      label: getNaam(aanvrager1, "Aanvrager 1"),
      value: aanvrager1.klantId
    });
  }

  if (aanvrager2?.klantId) {
    aanvragerLijst.push({
      label: getNaam(aanvrager2, "Aanvrager 1"),
      value: aanvrager2.klantId
    });
  }

  return aanvragerLijst;
}

type Props = {
  selected: number;
};

const Heffing = ({
  selected,
  formik: {
    values: { aanvrager1, aanvrager2 }
  }
}: Props & { formik: FormikContextType<OverigePostenType> }): ReactElement => {
  const verzekerdenOpties = getAanvragerOpties(aanvrager1, aanvrager2);

  return (
    <LabeledRadioInput
      caption="Heffing bij"
      name={`producten[${selected}].heffing.heffingBij`}
      options={verzekerdenOpties}
    />
  );
};

export default connect<Props, OverigePostenType>(Heffing);
