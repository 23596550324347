/* istanbul ignore file */
import { createGetTextResources } from "../../shared/utils/text-resources-algemeen";

export const getTaxatieResources = createGetTextResources({
  GeldigEmail: "Vul een geldig e-mailadres in.",
  GeldigTelefoon: "Vul een geldig telefoonnummer in.",
  SoortAanvraagLeeg: "Vul de 'Soort aanvraag' in.",
  EPA: "EPA",
  Taxatie: "Taxatie",
  BouwkundigeKeuring: "Bouwkundige keuring",
  DoelEPA: "Doel EPA",
  Doeltaxatie: "Doel taxatie",
  NietGeldigeAgentnummer:
    "Je hebt voor NWWI geen (geldig) agentnummer ingevuld. Dit nummer vul je op het scherm ‘Beheer partijen - Taxatiebureaus' in de instellingen-app.",
  KiesGeldverstrekker: "Kies een geldverstrekker.",
  KiesDoelTaxatie: "Kies een doel taxatie.",
  ontbrekenVelden:
    "Om de gegevens in dit specificatiescherm in te kunnen vullen, moet je nog een of meerdere velden invullen. Vul de rood gemarkeerde velden op het hoofdscherm ‘Taxatie’ in. Open daarna opnieuw dit specificatiescherm."
});
