import { aflosproductSchema } from "../producten-overzicht-schema";
import { Aflosproduct, SoortAflosproductOptions } from "../../../.generated/forms/formstypes";
import { createMapToDl } from "../../../shared/utils/create-map-to-dl";
import { AflosproductType } from "../producten-overzicht-types";
import { mapLocalDateToString } from "adviesbox-shared";

export const mapAflosproductUiToDl = createMapToDl(aflosproductSchema).to<Aflosproduct>({
  soortAflosproduct: v => v.soortAflosproduct ?? SoortAflosproductOptions.Leningdeel,
  aflossen: v => v.aflossen,
  productId: v => v.productId,
  ingangsdatum: v => mapLocalDateToString(v.ingangsdatum),
  looptijdInMaanden: v => v.looptijd,
  omschrijving: v => v.omschrijving,
  prioriteit: v => v.prioriteit,
  doorlopend: v => v.doorlopend,
  volgnummer: v => v.volgnummer
});

export const mapAflosProductenUiToDl = (aflosproducten: AflosproductType[]): Aflosproduct[] => {
  const aflosProducten: Aflosproduct[] = [];
  aflosproducten.forEach(x => {
    aflosProducten.push(mapAflosproductUiToDl(x));
  });
  return aflosProducten;
};
