import { ErrorPage, PageLoading, SettingsType, useRequestInit, withErrorBoundary } from "adviesbox-shared";
import { OrganisatieEx, OrganisatiesExOutput } from "../.generated/licenties/licentiestypes";
import React, { ReactElement, useContext } from "react";
import {
  mapDlTargetToHypotheekOversluitenUiField,
  mapHypotheekOversluitenDlToUi,
  mapHypotheekOversluitenUiToDl
} from "./infra/map-hypotheek-oversluiten";

import HypotheekOversluiten from "./hypotheek-oversluiten";
import { InvalidAdviesboxResultErrorPage } from "../shared/components/fetch-error-page/fetch-error-page";
import { RouteParams } from "../shared/paramrouting/paramrouting-context";
import UserDetailsContext from "../shared/user-details/user-details-context";
import useAbortableFetch from "use-abortable-fetch";
import { useAdviesboxData } from "../shared/hooks/use-adviesbox-data";

const createUrl = (s: SettingsType, p: RouteParams): string =>
  `${s.klantdossiersFormsOrigin}/Voorstellen/${p.voorstel}/HypotheekOversluiten`;

const HypotheekOversluitenAjax = (): ReactElement => {
  const { settings, requestInit } = useRequestInit();
  const { userDetails } = useContext(UserDetailsContext);
  const usersOrganisatieId = userDetails.organisatieId ? userDetails.organisatieId : null;
  const url = `${settings?.licentiesOrigin}/Organisaties/${usersOrganisatieId}`;
  const { data: orgData, error: orgErr, loading: orgLoading } = useAbortableFetch<OrganisatiesExOutput>(
    url,
    requestInit
  );

  const { loading, error, data, platformData, saveData } = useAdviesboxData(
    createUrl,
    p => p.voorstel,
    mapHypotheekOversluitenDlToUi,
    mapHypotheekOversluitenUiToDl,
    mapDlTargetToHypotheekOversluitenUiField
  );

  if (loading || orgLoading || !usersOrganisatieId) {
    return <PageLoading />;
  }

  if (error) {
    return <ErrorPage error={error} data={platformData} />;
  }

  if (orgErr) {
    return <ErrorPage error={orgErr} data={orgData} />;
  }

  if (typeof data === "string" || typeof orgData === "string")
    return <ErrorPage error={new Error(`Returned data type is string`)} />;

  if (!data || !orgData || !orgData.organisaties) {
    return <InvalidAdviesboxResultErrorPage />;
  }

  const organisatieData: OrganisatieEx = orgData.organisaties[usersOrganisatieId];

  return <HypotheekOversluiten saveData={saveData} {...data} organisatieData={organisatieData} />;
};

HypotheekOversluitenAjax.displayName = "HypotheekOversluitenAjax";

export default withErrorBoundary(HypotheekOversluitenAjax);
