import { getIn } from "formik";
import * as Yup from "yup";
import { berekenJaarMaanden } from "../../pensioen/infra/map-pensioen-dl-to-ui";
import { Beroep } from "../../.generated/aov/aovtypes";
import {
  AovEigenRisicoOptions,
  AovUitkeringsduurOptions,
  AoBeoordelingscriterium,
  AovKeuzeDekkingOptions,
  AovVerzekerdeDekkingenOptions,
  AovVoornaamsteInkomstenbronOptions,
  AovBetalingMiddelsOptions,
  AovSoortProductOptions
} from "../../.generated/forms/formstypes";
import { Algemeen } from "../../.generated/tarieven/tarieventypes";
import { getProductenOverzichtTextResources } from "../../producten-overzicht/infra/producten-overzicht-resources";
import {
  aflosproductSchema,
  afTeLossenLeningdelenModalSchema,
  productSchema
} from "../../producten-overzicht/infra/producten-overzicht-schema";
import { SituatieSoort } from "../../producten-overzicht/infra/producten-overzicht-types";
import {
  AoUitkeringswijze,
  specificatieAoUitkeringModalSchema
} from "../../producten-overzicht/infra/specificatie-ao-uitkering-schema";
import { jaarMaandInputSchema } from "../../shared/generic-parts/jaar-maand/schema";
import { klantnaamSchema } from "../../shared/generic-parts/klantnaam/schema";
import { AanvragerKeuze, berekenInputSchema, scenarioCardInputSchema } from "../../shared/types";
import { WithSaveData } from "../../shared/utils/save-data";
import { yupEnum, yupNullableEnum } from "../../shared/utils/yup-enum";
import { getAovTextResources } from "./aov-resources";
import { nullableLocalDateSchema } from "adviesbox-shared";

export enum PartijProduct {
  OnafhankelijkWoonlasten,
  OnafhankelijkWgaGat,
  OnafhankelijkWiaExcedent,
  OnafhankelijkAo,
  OnafhankelijkIgv,
  OnafhankelijkHav,
  CardifHypotheekAflosVerzekering,
  CallasWoonlasten,
  CallasInkomensGarantie,
  DazureWoonlasten,
  DazureInkomensbeschermer,
  DazureLastenbeschermer,
  TafInkomensBeschermer,
  TafQuantumLeben,
  TafCreditLife,
  TafMaandlastenbeschermerIPTIQ,
  TafMaandlastenbeschermerZelfstandige,
  TafInkomensbeschermerIPTIQ,
  TafBnpHypotheekbeschermer,
  Onbekend,
  CreditLifeWoonlastenOngevallen,
  CreditLifeInkomensgarantieOngevallen,
  CreditLifeArbeidsongeschiktheid
}

export enum PremieBetalingstermijn {
  Maand = "Maand",
  TweeMaanden = "2 Maanden",
  Kwartaal = "Kwartaal",
  HalfJaar = "Half Jaar",
  Jaar = "Jaar",
  Eenmalig = "Koopsom"
}

export enum AovJaarlijkseIndexatieVan {
  Uitkering = "Uitkering",
  VerzekerdBedrag = "Uitkering en verzekerd bedrag"
}

export const aovAanvragerSchema = klantnaamSchema.shape({
  brutoJaarinkomen: Yup.number()
    .nullable()
    .default(null),
  indicatiefJaarInkomen: Yup.number()
    .nullable()
    .default(null),
  indicatiefMaandInkomen: Yup.number()
    .nullable()
    .default(null)
});
export type AovAanvragerType = Yup.InferType<typeof aovAanvragerSchema>;

export enum looptijdInMaanden {
  twaalfMaanden = 12,
  vierentwintigMaanden = 24
}

export const aovScenarioCardInputSchema = scenarioCardInputSchema.shape({
  jaartal: Yup.number()
    .nullable()
    .default(null)
});

export const verzekerdBedragModalSchema = Yup.object({
  scenario: Yup.array(aovScenarioCardInputSchema).default([])
});
export type VerzekerdBedragModalType = Yup.InferType<typeof verzekerdBedragModalSchema>;
export type AovScenarioCardInputType = Yup.InferType<typeof aovScenarioCardInputSchema>;

export const verzekerdenSchema = Yup.object({
  verzekerden: Yup.mixed<AanvragerKeuze>()
    .oneOf(Object.values(AanvragerKeuze), getProductenOverzichtTextResources("ErrorOnbekendeWaarde"))
    .default(AanvragerKeuze.Aanvrager1)
});
export type VerzekerdenType = Yup.InferType<typeof verzekerdenSchema>;

export const dekkingGegevensAoSchema = Yup.object({
  beroep: Yup.string(),
  risicoklasse: Yup.string(),
  beroepnaam: Yup.string(),
  verzekerdBedrag: Yup.number()
    .nullable()
    .default(null),
  verzekerdBedragGedeeltelijkAo: Yup.number()
    .nullable()
    .default(null),
  looptijd: jaarMaandInputSchema.nullable().default(null),
  uitkeringsDuur: jaarMaandInputSchema.nullable().default(null),
  eigenRisico: yupNullableEnum(AovEigenRisicoOptions)
    .nullable()
    .default(null),
  uitkeringsduurAo: yupNullableEnum(AovUitkeringsduurOptions)
    .nullable()
    .default(null),
  uitkeringGedeeltelijkAo: yupNullableEnum(AoUitkeringswijze)
    .nullable()
    .default(null),
  uitkeringGedeeltelijkeAoModal: specificatieAoUitkeringModalSchema,
  annuiteitsPercentage: Yup.number()
    .nullable()
    .default(null)
});
export type DekkingGegevensAoType = Yup.InferType<typeof dekkingGegevensAoSchema>;

export const dekkingGegevensEaSchema = Yup.object({
  verzekerdBedrag: Yup.number()
    .nullable()
    .default(null),
  looptijd: jaarMaandInputSchema.nullable().default(null)
});
export type DekkingGegevensEaType = Yup.InferType<typeof dekkingGegevensEaSchema>;

export const dekkingGegevensWwSchema = Yup.object({
  verzekerdBedrag: Yup.number()
    .nullable()
    .default(null),
  looptijd: jaarMaandInputSchema.nullable().default(null),
  uitkeringsDuur: jaarMaandInputSchema.nullable().default(null),
  uitkeringsDuurInMaanden: Yup.string().nullable()
});
export type DekkingGegevensWwType = Yup.InferType<typeof dekkingGegevensWwSchema>;

export const dekkingVerzekerdeRubriekASchema = Yup.object({
  beoordelingscriterium: yupNullableEnum(AoBeoordelingscriterium)
    .nullable()
    .default(null),
  eigenRisicoInMaanden: Yup.number()
    .nullable()
    .default(null),
  jaarlijkseIndexatiePercentage: Yup.number().nullable(),
  jaarlijkseIndexatieVan: yupNullableEnum(AovJaarlijkseIndexatieVan)
    .nullable()
    .default(null),
  uitkeringGedeeltelijkAo: yupNullableEnum(AoUitkeringswijze)
    .nullable()
    .default(null),
  uitkeringGedeeltelijkeAoModal: specificatieAoUitkeringModalSchema,
  verzekerdBedrag: Yup.number()
    .nullable()
    .default(null),
  percentageVanInkomen: Yup.number()
    .nullable()
    .default(null)
});
export type DekkingVerzekerdeRubriekAType = Yup.InferType<typeof dekkingVerzekerdeRubriekASchema>;

export const dekkingVerzekerdeRubriekBSchema = Yup.object({
  beoordelingscriterium: yupNullableEnum(AoBeoordelingscriterium)
    .nullable()
    .default(null),
  eindLeeftijd: Yup.number()
    .nullable()
    .default(null)
    .test({
      test: function(value: number) {
        const context = this.options.context as AovsSchemaContextType;
        let row = context.values.producten.find(p => p.dekking.dekkingGegevensVerzekerde1.rubriekB === this.parent);
        if (!row) {
          // Het fiscaleGegevens object is al gekloond door Formik, dan via het path
          const rowPath = this.path.replace(".dekking.dekkingGegevensVerzekerde1.rubriekB.eindLeeftijd", "");
          row = getIn(context.values, rowPath);
        }
        if (row?.product && row.product.ingangsdatum) {
          const leeftijdJaren = berekenJaarMaanden(
            (row?.verzekerden.verzekerden === AanvragerKeuze.Aanvrager1
              ? context.values.aanvrager1?.geboortedatum
              : context.values.aanvrager2?.geboortedatum) || null,
            row.product.ingangsdatum
          ).jaren;
          if (leeftijdJaren && value < leeftijdJaren) {
            return this.createError({
              message: getAovTextResources("CheckLeeftijdRubriekA")
            });
          }
        }
        return true;
      }
    }),
  verzekerdBedrag: Yup.number()
    .nullable()
    .default(null),
  percentageVanInkomen: Yup.number()
    .nullable()
    .default(null)
});
export type DekkingVerzekerdeRubriekBType = Yup.InferType<typeof dekkingVerzekerdeRubriekBSchema>;

export const dekkingGegevensVerzekerdeSchema = Yup.object({
  ao: dekkingGegevensAoSchema.nullable().default(null),
  ea: dekkingGegevensEaSchema.nullable().default(null),
  ww: dekkingGegevensWwSchema.nullable().default(null),
  rubriekA: dekkingVerzekerdeRubriekASchema.nullable().default(null),
  rubriekB: dekkingVerzekerdeRubriekBSchema.nullable().default(null),
  beoordelingscriterium: yupNullableEnum(AoBeoordelingscriterium)
    .nullable()
    .default(null),
  dekking: yupNullableEnum(AovVerzekerdeDekkingenOptions)
    .nullable()
    .default(AovVerzekerdeDekkingenOptions.Ao),
  uitgebreid: Yup.boolean()
    .nullable()
    .default(null),
  keuzeDekking: yupNullableEnum(AovKeuzeDekkingOptions)
    .nullable()
    .default(null),
  vastBedrag: Yup.number()
    .nullable()
    .default(null),
  percentage: Yup.number()
    .nullable()
    .default(null),
  voornaamsteInkomstenbron: yupNullableEnum(AovVoornaamsteInkomstenbronOptions).default(null)
});
export type DekkingGegevensVerzekerdeType = Yup.InferType<typeof dekkingGegevensVerzekerdeSchema>;

export const dekkingSchema = Yup.object({
  marktwaarde: Yup.number()
    .nullable()
    .default(null),
  hypotheekbedrag: Yup.number()
    .nullable()
    .default(null),
  brutoJaarinkomen: Yup.number()
    .nullable()
    .default(null),
  jaarinkomenIndicatief: Yup.number()
    .nullable()
    .default(null),
  maandinkomen: berekenInputSchema,
  indexatieTijdensUitkering: Yup.boolean()
    .nullable()
    .default(null),
  maandinkomenGegarandeerd: Yup.number()
    .nullable()
    .default(null),
  nettoMaandinkomen: Yup.number()
    .nullable()
    .default(null),
  dekkingGegevensVerzekerde1: dekkingGegevensVerzekerdeSchema,
  dekkingGegevensVerzekerde2: dekkingGegevensVerzekerdeSchema.nullable().default(null),
  verzekerdBedragVerzekerdBedragModalValues: verzekerdBedragModalSchema,
  aanvullendeInformatie: Yup.string(),
  verzekerdeKredietsom: Yup.number()
  .nullable()
  .default(null),
});
export type DekkingType = Yup.InferType<typeof dekkingSchema>;

export const premiegegevensSchema = Yup.object({
  betalingMiddels: yupNullableEnum(AovBetalingMiddelsOptions).default(null),
  betalingsTermijn: yupNullableEnum(PremieBetalingstermijn).default(null),
  premieAo: Yup.number()
    .nullable()
    .default(null),
  premieTop: Yup.number()
    .nullable()
    .default(null),
  koopsomAO: Yup.number()
    .nullable()
    .default(null),
  koopsomBedrag: Yup.number()
    .nullable()
    .default(null),
  koopsomPeriodeInMaanden: Yup.number()
    .nullable()
    .default(null),
  collectief: Yup.boolean()
    .nullable()
    .default(null),
  jaarlijksIndexatiePercentage: Yup.number()
    .nullable()
    .default(null),
  premieAftrekbaarAO: Yup.boolean()
    .nullable()
    .default(null),
  premieAftrekbaarBedrag: Yup.number()
    .nullable()
    .default(null),
  premie: Yup.number()
    .nullable()
    .default(null),
  premieWw: Yup.number()
    .nullable()
    .default(null)
});
export type PremiegegevensType = Yup.InferType<typeof premiegegevensSchema>;

export const aanvullendeVragenSchema = Yup.object({
  reorganisatieWerkgeverAanvrager1: Yup.boolean()
    .nullable()
    .default(null),
  reorganisatieWerkgeverAanvrager2: Yup.boolean()
    .nullable()
    .default(null),
  verwachtingOntslagAanvrager1: Yup.boolean()
    .nullable()
    .default(null),
  verwachtingOntslagAanvrager2: Yup.boolean()
    .nullable()
    .default(null),
  financieleProblemenWerkgeverAanvrager1: Yup.boolean()
    .nullable()
    .default(null),
  financieleProblemenWerkgeverAanvrager2: Yup.boolean()
    .nullable()
    .default(null),
  strafrechtelijkVerledenAanvrager1: Yup.boolean()
    .nullable()
    .default(null),
  strafrechtelijkVerledenAanvrager2: Yup.boolean()
    .nullable()
    .default(null),
  toelichtingGelezenAkkoordMetSlotverklaring: Yup.boolean()
    .nullable()
    .default(null)
});

export type AanvullendeVragenType = Yup.InferType<typeof aanvullendeVragenSchema>;

export const aflossingSchema = Yup.object({
  bedoeldVoorAflossing: Yup.boolean().default(false),
  bedoeldVoorAflossingSpecificatie: afTeLossenLeningdelenModalSchema,
  verpandAanGeldverstrekker: Yup.boolean().default(false)
});
export type AflossingType = Yup.InferType<typeof aflossingSchema>;

export const aovSchema = Yup.object({
  toggleBerekenen: Yup.boolean().default(true),
  productId: Yup.string().nullable(),
  partijCode: Yup.string(),
  productCode: Yup.string(),
  soortProduct: yupEnum(AovSoortProductOptions).default(AovSoortProductOptions.Product),
  product: productSchema.shape({
    einddatum: nullableLocalDateSchema.required("Waarde is verplicht"),
    ingangsdatum: nullableLocalDateSchema.required("Waarde is verplicht")
  }),
  verzekerden: verzekerdenSchema,
  dekking: dekkingSchema,
  premiegegevens: premiegegevensSchema,
  verpanding: aflossingSchema,
  aanvullendeVragen: aanvullendeVragenSchema
});
export type AovType = Yup.InferType<typeof aovSchema>;

export const aovsSchema = Yup.object({
  producten: Yup.array(aovSchema).default([]),
  aanvrager1: aovAanvragerSchema.nullable(),
  aanvrager2: aovAanvragerSchema.nullable().default(null),
  ingangsdatumVoorstel: nullableLocalDateSchema,
  hypotheekbedrag: Yup.number().nullable(),
  marktwaardeBedrag: Yup.number().nullable(),
  aflosProductNew: Yup.array()
    .of(aflosproductSchema)
    .default([])
});

export type AovsType = Yup.InferType<typeof aovsSchema>;
export type AovsProps = Yup.InferType<typeof aovsSchema> &
  WithSaveData<AovsType> & { situatie: SituatieSoort; tarieven?: Algemeen; beroepen?: Beroep[] };
export type AovsSchemaContextType = {
  values: AovsType;
};
