import React, { ReactElement } from "react";
import { connect, FormikContextType } from "formik";
import { LijfrenteUitgangspuntBerekeningOptions } from "../../.generated/forms/formstypes";

import { LabeledRadioInput, LabeledCurrencyInput } from "adviesbox-shared";
import { UitkerendeLijfrentesType } from "../infra/uitkerende-lijfrente-types";

const kapitaalOptions = [
  { label: "Beschikbare koopsom", value: LijfrenteUitgangspuntBerekeningOptions.BeschikbareKoopsom },
  { label: "Lijfrenteuitkering", value: LijfrenteUitgangspuntBerekeningOptions.Lijfrenteuitkering }
];

type KapitaalProps = { selected: number };

const Kapitaal = ({
  selected,
  formik: { setFieldValue, values }
}: KapitaalProps & {
  formik: FormikContextType<UitkerendeLijfrentesType>;
}): ReactElement => {
  const { uitgangspuntBerekening } = values.producten[selected].kapitaal;

  return (
    <>
      <LabeledRadioInput
        caption="Uitgangspunt berekening"
        name={`producten[${selected}].kapitaal.uitgangspuntBerekening`}
        options={kapitaalOptions}
      />

      <LabeledCurrencyInput
        caption="Beschikbare koopsom"
        name={`producten[${selected}].kapitaal.beschikbareKoopsom`}
        readonly={uitgangspuntBerekening !== LijfrenteUitgangspuntBerekeningOptions.BeschikbareKoopsom}
      />
    </>
  );
};

export default connect<KapitaalProps, UitkerendeLijfrentesType>(Kapitaal);
