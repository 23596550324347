import { SharedProductType, ProductSelectieType } from "../producten-overzicht-types";
import { partijOnafhankelijk, partijAnders } from "../product-constanten";
import { EenVanDeKenmerken } from "../product-kenmerken-types";

export const mapProductSelectieToProduct = (
  productSelectie: ProductSelectieType,
  productFactory: (productSelectie: ProductSelectieType, kenmerken?: EenVanDeKenmerken | null) => SharedProductType,
  legePartijnaam?: boolean,
  kenmerken?: EenVanDeKenmerken | null
): SharedProductType => {
  const maatschappijProduct = productFactory(productSelectie, kenmerken);
  maatschappijProduct.productCode = productSelectie.codes.productCode;
  maatschappijProduct.partijCode = productSelectie.codes.maatschappijCode;
  maatschappijProduct.product.productNaam =
    productSelectie.productNaam || (productSelectie.codes.productVorm as string); // getVormInTekst(verzekeringSelectie.codes.productVorm);
  maatschappijProduct.product.partijNaam =
    legePartijnaam && productSelectie.codes.maatschappijCode === partijOnafhankelijk
      ? ""
      : productSelectie.maatschappijNaam || "Onafhankelijk";
  maatschappijProduct.product.partijCodeSelectie =
    productSelectie.codes.maatschappijCode === partijOnafhankelijk
      ? partijAnders
      : productSelectie.codes.maatschappijCode;
  return maatschappijProduct;
};
