import React, { ReactElement, useState } from "react";
import { Column } from "react-table-6";
import { getOrvTextResources } from "../infra/orv-resources";
import {
  orvVergelijkerResultaatSchema,
  OrvVergelijkenModalType,
  OrvVergelijkerResultaatType,
  OrvVergelijkerResultaatStatus
} from "../infra/orv-schema";
import {
  FilterType,
  createSpanWithId,
  DataGrid,
  CardWrapper,
  SortType,
  Icon,
  Card,
  bedragFormat
} from "adviesbox-shared";
import { FormikContextType, connect } from "formik";
import classes from "./orv-vergelijker-modal-data-grid.module.scss";

const createIcon = (status: OrvVergelijkerResultaatStatus, index: number): ReactElement => {
  let icon: ReactElement | null = null;
  switch (status) {
    case OrvVergelijkerResultaatStatus.Groen:
      icon = <Icon name="vink" alt="Geldig" />;
      break;
    case OrvVergelijkerResultaatStatus.Rood:
      icon = <Icon name="kruis" alt="Ongeldig" />;
      break;
  }

  return (
    <div id={`orv-vergelijker-resultaat-${index}`} className="d-flex align-items-center h-100">
      {icon}
    </div>
  );
};

const orvVergelijkenColumns: Column[] = [
  {
    Header: "Voorw.",
    accessor: "status",
    width: 50,
    id: "status",
    Cell: (c): ReactElement => createIcon(c.original.status, c.index)
  },
  {
    Header: "Maatschappij",
    accessor: "maatschappijOmschrijving",
    id: "maatschappijOmschrijving",
    Cell: (c): ReactElement =>
      createSpanWithId(c.index, 1, c.original.maatschappijOmschrijving, c.original.maatschappijOmschrijving)
  },
  {
    Header: "Product",
    accessor: "productnaam",
    id: "productnaam",
    Cell: (c): ReactElement => createSpanWithId(c.index, 2, c.original.productnaam, c.original.productnaam)
  },
  {
    Header: "Premieduur",
    accessor: "premieduurInJaren",
    id: "premieduurInJaren",
    Cell: (c): ReactElement =>
      createSpanWithId(c.index, 3, c.original.premieduurInJaren ? `${c.original.premieduurInJaren} Jaar` : null)
  },
  {
    Header: "Premieverloop",
    accessor: "premieverloop",
    id: "premieverloop",
    Cell: (c): ReactElement => createSpanWithId(c.index, 4, c.original.premieverloop)
  },
  {
    Header: "Totale premie",
    accessor: "totalePremieBedrag",
    id: "totalePremieBedrag",
    Cell: (c): ReactElement =>
      createSpanWithId(
        c.index,
        5,
        c.original.totalePremieBedrag ? bedragFormat(c.original.totalePremieBedrag, 2, false) : null
      )
  },
  {
    Header: "Eerste premie",
    accessor: "eerstePremieBedrag",
    id: "eerstePremieBedrag",
    Cell: (c): ReactElement =>
      createSpanWithId(
        c.index,
        6,
        c.original.eerstePremieBedrag ? bedragFormat(c.original.eerstePremieBedrag, 2, false) : null
      )
  }
];

const OrvVergelijkerDataGrid = ({
  formik: { setFieldValue, values }
}: {
  formik: FormikContextType<OrvVergelijkenModalType>;
}): ReactElement => {
  const selectedRow = useState(0);
  const [, setSelectedRow] = selectedRow;

  const onDataGridChange = (data?: OrvVergelijkerResultaatType[]): void => {
    if (!data || !values.selectedResultaat || !data.length) return;
    setFieldValue("sortedOrvVergelijkerResultaat", data);
    const indexOfSelected = data.indexOf(values.selectedResultaat);
    if (indexOfSelected === -1) {
      setFieldValue("selectedResultaat", data[0]);
      setSelectedRow(0);
      return;
    }
    setSelectedRow(indexOfSelected);
  };
  return (
    <>
      <CardWrapper className="master-detail-card flex-grow-1" maxRowCount={4}>
        <Card title="" className="w-xl-100 w-lg-100 w-md-50 w-50">
          <DataGrid
            className={classes.bordered_result}
            masterDetail
            resizable={false}
            defaultPageSize={values.orvVergelijkerResultaat.length}
            columns={orvVergelijkenColumns}
            showPagination={false}
            sortable={true}
            filterable={true}
            filterConfig={{
              maatschappijOmschrijving: FilterType.Text,
              premieverloop: FilterType.Text
            }}
            sortConfig={{
              eerstePremieBedrag: SortType.Ascending
            }}
            initialSort="status"
            name="orvVergelijkerResultaat"
            rowSelected={selectedRow}
            filteredCallback={onDataGridChange}
            sortedCallback={onDataGridChange}
            validationSchema={orvVergelijkerResultaatSchema}
            rowCaption="orvVergelijkerResultaat"
            getTrProps={(_: any, rowInfo: any) => {
              return {
                onClick: () => {
                  setFieldValue("selectedResultaat", rowInfo.original);
                }
              };
            }}
          />
        </Card>
      </CardWrapper>
      <div className={"d-flex align-items-center justify-content-start my-3"}>
        <div className={"d-flex align-items-center"}>
          <span className={"ml-3"}>{getOrvTextResources("GeenRechtenOntleend")}</span>
        </div>
      </div>
    </>
  );
};

export default connect<{}, OrvVergelijkenModalType>(OrvVergelijkerDataGrid);
