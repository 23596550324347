import { createGetTextResources } from "../../shared/utils/text-resources-algemeen";

export const getAanleidingTextResources = createGetTextResources({
  ToolTipAankoopNieuwbouw: `Bij een nieuwbouw woning gaan we standaard uit van energielabel A++, waardoor bij een toetsinkomen van minimaal € 33.000 een extra bedrag van € 9.000 geleend mag worden. Je kunt in het scherm 'Analyse - Woning' aangeven wat het werkelijke energielabel is.`,
  ToolTipEchtScheiding: `Bij echtscheiding kun je de inventarisatie doen op basis van de situatie vóór echtscheiding. Maar de analyse en het advies doe je altijd op basis van de situatie na echtscheiding. Adviseer je beide partijen? Maak dan twee aparte klanten aan. 
  
De te betalen vergoeding aan de ex-partner kun je invullen in het veld 'Uitkoop partner', bij het scherm 'Voorstel - Financieringsbehoefte'. Als hier sprake van is, dan vind je de hoogte van het bedrag in de akte van verdeling.
  `,
  echtscheidingWarning: `Let op! In dit advies zijn twee aanvragers opgenomen. Wilt u advies geven voor de situatie na echtscheiding, zorg er dan voor dat alleen de aanvrager(s) van de toekomstige situatie in het advies zijn opgenomen.`
});
