/* istanbul ignore file */
import {
  createSpanWithId,
  DataGrid,
  hasValue,
  LabeledCurrencyInput,
  LabeledPercentageInput,
  LabeledRadioInput,
  LabeledSelectInput,
  LabeledTextInput,
  LabeledResult
} from "adviesbox-shared";
import { LabelValuePairs } from "adviesbox-shared/utils/types";
import { getIn, useFormikContext } from "formik";
import React, { ReactElement, useMemo } from "react";
import { Column } from "react-table-6";
import {
  FinancieringOptions,
  DuurPensioenuitkeringOptions,
  DuurOverzichtOptions,
  MotiveringOnderwerpsoort
} from "../../.generated/forms/formstypes";
import { UitgangspuntenAdvies } from "../../.generated/instellingen-forms/instellingen-formstypes";
import { maandenNaarJaarMaand } from "../../shared/generic-parts/jaar-maand/helpers";
import { getNaam } from "../../shared/utils/helpers";
import { getMotiveringTextResources } from "../infra/motivering-resources";
import { MotiveringState, NamePrefixRisicoUitgangspunten } from "../infra/motivering-schema";
import { ControleOpGewenstInkomen } from "./controle-op-gewenst-inkomen/controle-op-gewenst-inkomen";
import { ControleOpHypotheeknorm } from "./controle-op-hypotheeknorm/controle-op-hypotheeknorm";
import classes from "./uitgangspunten.module.scss";

type UitgangspuntenProps = {
  hasSecondAanvrager: boolean;
  type: MotiveringOnderwerpsoort;
  instellingen: UitgangspuntenAdvies;
  namePrefix: NamePrefixRisicoUitgangspunten;
  financiering: FinancieringOptions | null;
};

export type UitgangspuntenProperties = {
  name: string;
  isOverlijden: boolean;
  isArbeidsOngeschiktheid: boolean;
  isPensioen: boolean;
  isWerkloosheid: boolean;
};

const gewenteDuurBasisOptions: Record<DuurOverzichtOptions, string> = {
  VasteDuurInJaren: "Vaste duur in jaren",
  Levenslang: "Levenslang",
  TotLeeftijd: "Tot leeftijd"
};

const pensioenUitkeringOptions = (): LabelValuePairs => {
  return [
    {
      label: "Levenslang",
      value: DuurPensioenuitkeringOptions.Levenslang
    },
    { label: "Tot leeftijd", value: DuurPensioenuitkeringOptions.TotLeeftijd }
  ];
};

const determineUitangspuntenProperties = (type: MotiveringOnderwerpsoort): UitgangspuntenProperties | null => {
  let properties: UitgangspuntenProperties | null;

  switch (type) {
    case MotiveringOnderwerpsoort.AfdekkenWW:
      properties = {
        name: "uitgangspuntenWerkloosheid",
        isOverlijden: false,
        isArbeidsOngeschiktheid: false,
        isPensioen: false,
        isWerkloosheid: true
      };
      break;
    case MotiveringOnderwerpsoort.AfdekkenOverlijdensrisico:
      properties = {
        name: "uitgangspuntenOverlijden",
        isOverlijden: true,
        isArbeidsOngeschiktheid: false,
        isPensioen: false,
        isWerkloosheid: false
      };
      break;
    case MotiveringOnderwerpsoort.AfdekkenAOWW:
      properties = {
        name: "uitgangspuntenArbeidsongeschiktheid",
        isOverlijden: false,
        isArbeidsOngeschiktheid: true,
        isPensioen: false,
        isWerkloosheid: false
      };

      break;
    case MotiveringOnderwerpsoort.Pensioenvoorziening:
      properties = {
        name: "uitgangspuntenPensioen",
        isOverlijden: false,
        isArbeidsOngeschiktheid: false,
        isPensioen: true,
        isWerkloosheid: false
      };
      break;
    default:
      properties = null;
  }

  return properties;
};

export const Uitgangspunten = ({
  type,
  hasSecondAanvrager,
  namePrefix,
  financiering
}: UitgangspuntenProps): ReactElement => {
  const formik = useFormikContext<MotiveringState>();
  const values = formik.values;
  const properties: UitgangspuntenProperties | null = determineUitangspuntenProperties(type);
  const controleHypotheeknorm = getIn(values, `${properties?.name}.controleHypotheeknorm`);
  const aanvrager1UitDashboard = values[namePrefix]?.scenarioVanKlantIds.find(id => id === values.aanvrager1.klantId);
  const aanvrager2UitDashboard = values[namePrefix]?.scenarioVanKlantIds.find(id => id === values.aanvrager2?.klantId);

  const voorstelkeuzeOpties = useMemo(
    () =>
      values[namePrefix]?.voorstellen?.map(voorstel => ({
        label: voorstel.omschrijving || `Voorstel #${voorstel.volgnummer}`,
        value: voorstel.voorstelId || ""
      })),
    [values, namePrefix]
  );

  const ArbeidsOngeschiktheidColumns: Column[] = [
    {
      Header: "Scenario",
      id: "scenario",
      accessor: "scenario",
      width: 100,
      Cell: (c): ReactElement => createSpanWithId(c.index, 0, c.original.scenario)
    },
    {
      Header: "AO percentage",
      id: "aoPercentage",
      accessor: "aoPercentage",
      width: 100,
      Cell: (c): ReactElement => createSpanWithId(c.index, 1, `${c.original.aoPercentage ?? 0}%`)
    },
    {
      Header: "Duurzaam AO",
      id: "duurzaamAo",
      width: 100,
      Cell: (c): ReactElement => createSpanWithId(c.index, 2, c.original.duurzaamAo ? "Ja" : "Nee")
    },
    {
      Header: "Benutting verdiencapaciteit",
      id: "benuttingVerdiencapaciteit",
      accessor: "benuttingVerdiencapaciteit",
      width: 150,
      Cell: (c): ReactElement => createSpanWithId(c.index, 3, `${c.original.benuttingVerdiencapaciteit ?? 0}%`)
    }
  ];

  if (!properties) {
    return (
      <LabeledTextInput
        name={"title"}
        labelColSize={6}
        readonly={true}
        caption={"Data type onbekend voor uitgangspunten"}
      />
    );
  }

  return (
    <>
      {properties.isWerkloosheid && (
        <>
          <div className={classes.left}>
            <div>
              {voorstelkeuzeOpties && (
                <>
                  <h2>Voorstelkeuze</h2>
                  <LabeledRadioInput
                    caption="Berekeningen uitvoeren op basis van"
                    name={`${namePrefix}.motiveringVoorVoorstelId`}
                    options={voorstelkeuzeOpties}
                    tooltip={getMotiveringTextResources("VoorstelKeuzeTooltip")}
                  />
                </>
              )}
            </div>
            {financiering !== FinancieringOptions.GeenFinanciering &&
              (!!controleHypotheeknorm?.controleerMaximaleHypotheek || !!controleHypotheeknorm?.controleMaandlast) && (
                <div>
                  <ControleOpHypotheeknorm
                    type={type}
                    properties={properties}
                    controleHypotheeknorm={controleHypotheeknorm}
                  />
                </div>
              )}
          </div>
          <div className={classes.right}>
            <ControleOpGewenstInkomen type={type} properties={properties} />
          </div>
        </>
      )}
      {(properties.isOverlijden || properties.isPensioen || properties.isArbeidsOngeschiktheid) && (
        <>
          <div>
            <div className={classes.left}>
              <div>
                {voorstelkeuzeOpties && (
                  <>
                    <h2>Voorstelkeuze</h2>
                    <LabeledRadioInput
                      caption="Berekeningen uitvoeren op basis van"
                      name={`${namePrefix}.motiveringVoorVoorstelId`}
                      options={voorstelkeuzeOpties}
                      tooltip={getMotiveringTextResources("VoorstelKeuzeTooltip")}
                    />
                  </>
                )}
              </div>
              <>
                {financiering !== FinancieringOptions.GeenFinanciering &&
                  (!!controleHypotheeknorm?.controleerMaximaleHypotheek ||
                    !!controleHypotheeknorm?.controleMaandlast) && (
                    <div className="pt-2">
                      <ControleOpHypotheeknorm
                        type={type}
                        properties={properties}
                        controleHypotheeknorm={controleHypotheeknorm}
                      />
                    </div>
                  )}

                <div className="pt-2">
                  <ControleOpGewenstInkomen type={type} properties={properties} />
                </div>
              </>
            </div>
            {properties.isPensioen && values.uitgangspuntenPensioen && (
              <div className={classes.right}>
                {aanvrager1UitDashboard && (
                  <>
                    <h2>{`Beschikbare jaarruimte ${getNaam(values.aanvrager1, "Aanvrager 1")}`}</h2>
                    {!!values.uitgangspuntenPensioen?.beschikbareJaarruimteAanvrager1
                      ?.gewensteDuurPensioenUitkering && (
                      <LabeledSelectInput
                        options={pensioenUitkeringOptions()}
                        readonly={true}
                        caption="Gewenste duur pensioenuitkering"
                        name={`uitgangspuntenPensioen.beschikbareJaarruimteAanvrager1.gewensteDuurPensioenUitkering`}
                      />
                    )}
                    {!!values.uitgangspuntenPensioen?.beschikbareJaarruimteAanvrager1
                      ?.pensioenuitkeringTotLeeftijdInMaanden && (
                      <LabeledResult
                        caption="Leeftijd"
                        fieldSize="no-size"
                        name={`uitgangspuntenPensioen.beschikbareJaarruimteAanvrager1.pensioenuitkeringTotLeeftijdInMaanden`}
                        result={() =>
                          `${Math.ceil(
                            values.uitgangspuntenPensioen?.beschikbareJaarruimteAanvrager1
                              ?.pensioenuitkeringTotLeeftijdInMaanden ?? 1
                          ) / 12} Jaar`
                        }
                      />
                    )}
                    <LabeledCurrencyInput
                      decimalen={0}
                      caption={"Beschikbare jaarruimte"}
                      readonly={true}
                      fieldSize="no-size"
                      name={"uitgangspuntenPensioen.beschikbareJaarruimteAanvrager1.beschikbareJaarruimteBedrag"}
                    />
                  </>
                )}
                {aanvrager2UitDashboard ? (
                  <>
                    <h2>{`Beschikbare jaarruimte ${getNaam(values.aanvrager2, "Aanvrager 2")}`}</h2>
                    {!!values.uitgangspuntenPensioen?.beschikbareJaarruimteAanvrager2
                      ?.gewensteDuurPensioenUitkering && (
                      <LabeledSelectInput
                        options={pensioenUitkeringOptions()}
                        readonly={true}
                        caption="Gewenste duur pensioenuitkering"
                        name={`uitgangspuntenPensioen.beschikbareJaarruimteAanvrager2.gewensteDuurPensioenUitkering`}
                      />
                    )}
                    {!!values.uitgangspuntenPensioen?.beschikbareJaarruimteAanvrager2
                      ?.pensioenuitkeringTotLeeftijdInMaanden && (
                      <LabeledResult
                        caption="Leeftijd"
                        fieldSize="no-size"
                        name={`uitgangspuntenPensioen.beschikbareJaarruimteAanvrager2.pensioenuitkeringTotLeeftijdInMaanden`}
                        result={() =>
                          `${Math.ceil(
                            values.uitgangspuntenPensioen?.beschikbareJaarruimteAanvrager2
                              ?.pensioenuitkeringTotLeeftijdInMaanden ?? 1
                          ) / 12} Jaar`
                        }
                      />
                    )}
                    <LabeledCurrencyInput
                      decimalen={0}
                      caption={"Beschikbare jaarruimte"}
                      readonly={true}
                      fieldSize="no-size"
                      name={"uitgangspuntenPensioen.beschikbareJaarruimteAanvrager2.beschikbareJaarruimteBedrag"}
                    />
                  </>
                ) : null}
              </div>
            )}
            {properties.isOverlijden && values.uitgangspuntenOverlijden && (
              <div className={classes.right}>
                <h2>ORV</h2>
                <div>
                  {aanvrager1UitDashboard && (
                    <>
                      <span className={classes.padding_left}>{getNaam(values.aanvrager1, "Aanvrager 1")}</span>
                      <LabeledPercentageInput
                        decimalen={0}
                        caption={"Blijft % werken na overlijden"}
                        name={"uitgangspuntenOverlijden.werkenNaOverlijdenPartnerAanvrager1Percentage"}
                        readonly={true}
                      />
                    </>
                  )}

                  {aanvrager2UitDashboard && (
                    <>
                      <span className={classes.padding_left}>{getNaam(values.aanvrager2, "Aanvrager 2")}</span>
                      <LabeledPercentageInput
                        data-testid={"werkenNaOverlijdenAanvrager2"}
                        decimalen={0}
                        caption={"Blijft % werken na overlijden"}
                        name={"uitgangspuntenOverlijden.werkenNaOverlijdenPartnerAanvrager2Percentage"}
                        readonly={true}
                      />
                    </>
                  )}
                </div>
                {hasValue(values.uitgangspuntenOverlijden?.duurOverzicht) && (
                  <LabeledResult
                    data-testid={"ovezichtInJaren"}
                    caption={"Gewenste duur overzicht"}
                    name={"uitgangspuntenOverlijden.duurOverzicht"}
                    fieldSize="no-size"
                    result={() =>
                      `${
                        gewenteDuurBasisOptions[values.uitgangspuntenOverlijden?.duurOverzicht as DuurOverzichtOptions]
                      }`
                    }
                  />
                )}
                {hasValue(values.uitgangspuntenOverlijden?.overzichtDuurInJaren) && (
                  <LabeledResult
                    data-testid={"ovezichtInJaren"}
                    caption={"Aantal jaar"}
                    name={"uitgangspuntenOverlijden.overzichtDuurInJaren"}
                    fieldSize="no-size"
                    result={() => `${values.uitgangspuntenOverlijden?.overzichtDuurInJaren ?? 0} Jaar`}
                  />
                )}
                {hasValue(values.uitgangspuntenOverlijden?.overzichtTotLeeftijdInMaanden) && (
                  <LabeledResult
                    data-testid={"ovezichtInMaanden"}
                    caption={"Leeftijd"}
                    name={"uitgangspuntenOverlijden.overzichtTotLeeftijdInMaanden"}
                    fieldSize="no-size"
                    result={() =>
                      `${maandenNaarJaarMaand(values.uitgangspuntenOverlijden?.overzichtTotLeeftijdInMaanden ?? 0)}`
                    }
                  />
                )}
              </div>
            )}

            {properties.isArbeidsOngeschiktheid && (
              <div className={classes.right}>
                <h2>Scenario doorrekening</h2>
                <LabeledTextInput
                  caption={"Toetsjaar tekort"}
                  tooltip={getMotiveringTextResources("ToetsjaarTekortTooltip")}
                  readonly={true}
                  name={"toestjaar"}
                  appendChildren={<div>{values.uitgangspuntenArbeidsongeschiktheid?.toetsjaar ?? ""}</div>}
                />
                {values.arbeidsongeschiktheidDatagrid && (
                  <div className={classes.datagrid}>
                    <DataGrid
                      name="arbeidsongeschiktheidDatagrid"
                      columns={ArbeidsOngeschiktheidColumns}
                      showButtonDelete={false}
                      editable={false}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production") Uitgangspunten.displayName = "Uitgangspunten";
