import * as Yup from "yup";
import { klantnaamSchema } from "../../shared/generic-parts/klantnaam/schema";
import { WithSaveData } from "../../shared/utils/save-data";
import {
  TaxatieGebruikOptions,
  TaxatieOpdrachtgeverOptions,
  TaxatieDoelOptions,
  TaxatieContactPersoonOptions,
  TaxatieInhoudOptions,
  TaxatieTenBehoevenVanOptions,
  TaxatieBenodigdeKeuringOptions,
  TaxatieGestapeldeBouwOptions,
  TaxatieJaNeeOnbekendOptions,
  GeslachtOpties,
  TaxatieAlgemeenReadOnly,
  TaxatieToekomstigGebruikOptions,
  TaxatieVerbouwingTypeOptions,
  TaxatieSoortAanvraagOptions
} from "../../.generated/forms/formstypes";
import { yupEnum, yupNullableEnum } from "../../shared/utils/yup-enum";
import { getTaxatieResources } from "./taxatie-resources";
import adresSchema from "../../shared/generic-parts/adres/schema";
import { getGeneralTextResources } from "../../shared/utils/text-resources-algemeen";
import { TaxatiebureauPartij } from "../../.generated/instellingen-forms/instellingen-formstypes";
import { nullableLocalDateSchema } from "adviesbox-shared";
import { PlatformFoutenState } from "adviesbox-shared/utils/types";

const phoneNumberAllowEmptyRegex = /^(((((\+|00)31|0)[\s-]{0,1}([0-9][\s-]{0,1}){9}|((\+|00)(?!31)[0-9]{2}([0-9][\s-]{0,1}){1,13})))|)$/;
const emailAllowEmptyRegex = /^(([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)|)$/i;

export enum WaarschuwingType {
  Geldverstrekker = "Geldverstrekker",
  Taxateur = "Taxateur"
}

export const TaxatieVerbouwingSpecificatieSchema = Yup.object({
  verbouwingId: Yup.string()
    .nullable()
    .default(null),
  volgnummer: Yup.number(),
  werkzaamheid: Yup.string()
    .nullable()
    .default(null),
  bedrag: Yup.number()
    .nullable()
    .default(null),
  eigenBeheer: Yup.boolean().default(false),
  verbouwingType: yupNullableEnum(TaxatieVerbouwingTypeOptions).default(null)
});
export const TaxatieVerbouwingSpecificatieModalSchema = Yup.object({
  data: Yup.array(TaxatieVerbouwingSpecificatieSchema).default([])
});

export const GeldverstrekkerNwwiResultaat = Yup.object({
  bedrijfsnaam: Yup.string()
    .nullable()
    .default(null),
  titel: Yup.string()
    .nullable()
    .default(null),
  contractId: Yup.number()
    .nullable()
    .default(null),
  contractType: Yup.string()
    .nullable()
    .default(null),
  plaats: Yup.string()
    .nullable()
    .default(null)
});

export const GeldverstrekkerNwwiModalSchema = Yup.object({
  resultaat: Yup.array(GeldverstrekkerNwwiResultaat).default([]),
  resultaatIsLeeg: Yup.boolean()
    .nullable()
    .default(false),
  selectedResultaat: GeldverstrekkerNwwiResultaat.nullable().default(null),
  agentnummer: Yup.string()
    .nullable()
    .default(null),
  doelTaxatie: yupNullableEnum(TaxatieDoelOptions).default(null),
  postcode: Yup.string()
    .nullable()
    .default("")
});

export const TaxateurNwwiResultaat = Yup.object({
  taxatiebureauId: Yup.string()
    .nullable()
    .default(null),
  bedrijfsnaam: Yup.string()
    .nullable()
    .default(null),
  straat: Yup.string()
    .nullable()
    .default(null),
  huisnummer: Yup.number()
    .nullable()
    .default(null),
  toevoeging: Yup.string()
    .nullable()
    .default(null),
  postcode: Yup.string()
    .nullable()
    .default(null),
  plaats: Yup.string()
    .nullable()
    .default(null),
  telefoonnummer: Yup.string()
    .nullable()
    .default(null),
  emailadres: Yup.string()
    .nullable()
    .default(null),
  branche: Yup.string()
    .nullable()
    .default(null),
  afstand: Yup.string()
    .nullable()
    .default(null)
});

export const TaxateurNwwiModalSchema = Yup.object({
  resultaat: Yup.array(TaxateurNwwiResultaat).default([]),
  resultaatIsLeeg: Yup.boolean()
    .nullable()
    .default(false),
  selectedResultaat: TaxateurNwwiResultaat.nullable().default(null),
  contractId: Yup.number()
    .nullable()
    .default(null),
  huisnummer: Yup.number()
    .nullable()
    .default(null),
  postcode: Yup.string()
    .nullable()
    .default("")
});

export const TaxatiebureauSchema = Yup.object({
  maatschappijCode: Yup.string()
    .nullable()
    .default(null),
  soortAanvraag: yupNullableEnum(TaxatieSoortAanvraagOptions)
    .default(null)
    .required(getTaxatieResources("SoortAanvraagLeeg")),
  // soortAanvraagCode: Yup.number()
  //   .nullable()
  //   .default(null),
  andersTeWeten: Yup.string()
    .nullable()
    .default(null),
  agentnummer: Yup.string()
    .nullable()
    .default(null),
  productCode: Yup.number()
    .nullable()
    .default(null),
  taxatieProductNaam: Yup.string()
    .nullable()
    .default(null)
});
export const TaxatiePersoonSchema = Yup.object({
  achternaam: Yup.string()
    .nullable()
    .default(null),
  voornamen: Yup.string()
    .nullable()
    .default(null),
  voorvoegsel: Yup.string()
    .nullable()
    .default(null),
  voorletters: Yup.string()
    .nullable()
    .default(null),
  roepnaam: Yup.string()
    .nullable()
    .default(null),
  geslacht: yupEnum(GeslachtOpties)
    .default(GeslachtOpties.Man)
    .meta({ testdata: GeslachtOpties }),
  geboortedatum: nullableLocalDateSchema,
  geboortelandCode: Yup.string().default("NL"),
  adres: adresSchema,
  telefoonnummerPrive: Yup.string()
    .nullable()
    .default(null)
    .matches(phoneNumberAllowEmptyRegex, getTaxatieResources("GeldigTelefoon")),
  telefoonnummerWerk: Yup.string()
    .nullable()
    .default(null)
    .matches(phoneNumberAllowEmptyRegex, getTaxatieResources("GeldigTelefoon")),
  telefoonnummerMobiel: Yup.string()
    .nullable()
    .default(null)
    .matches(phoneNumberAllowEmptyRegex, getTaxatieResources("GeldigTelefoon")),
  emailAdres: Yup.string()
    .nullable()
    .default(null)
    .matches(emailAllowEmptyRegex, getTaxatieResources("GeldigEmail")),
  iban: Yup.string()
    .nullable()
    .default(null)
});
export const OpdrachtgeverSchema = TaxatiePersoonSchema.shape({});
export const PartnerSchema = TaxatiePersoonSchema.shape({});

export const TaxatieObjectSchema = Yup.object({
  adresPand: adresSchema,
  aankoopprijsBedrag: Yup.number()
    .nullable()
    .default(null),
  nieuwbouw: Yup.boolean()
    .nullable()
    .default(null),
  bouwplan: Yup.string()
    .nullable()
    .default(null),
  bouwnummer: Yup.string()
    .nullable()
    .default(null),
  gebruikMomenteel: yupNullableEnum(TaxatieGebruikOptions).default(null),
  gebruikToekomst: yupNullableEnum(TaxatieToekomstigGebruikOptions).default(null),
  gebruikToekomstAnders: Yup.string()
    .nullable()
    .default(null),
  huurovereenkomst: Yup.boolean()
    .nullable()
    .default(null),
  opdrachtgever: yupNullableEnum(TaxatieOpdrachtgeverOptions).default(null)
});

export const TaxatieAlgemeenSchema = Yup.object({
  doelTaxatie: yupNullableEnum(TaxatieDoelOptions).default(null),
  geldverstrekker: Yup.string()
    .nullable()
    .default(null),
  geldverstrekkerCode: Yup.string()
    .nullable()
    .default(null),
  nhg: Yup.boolean()
    .nullable()
    .default(true),
  referentieGeldverstrekker: Yup.string()
    .nullable()
    .default(null),
  herbouwwaarde: Yup.boolean()
    .nullable()
    .default(true),
  nwwiAanvraag: Yup.boolean()
    .nullable()
    .default(null),
  validatieVerplicht: Yup.boolean()
    .nullable()
    .default(null),
  energiePrestatieAdvies: Yup.boolean()
    .nullable()
    .default(null),
  bouwtechnischeKeuring: Yup.boolean()
    .nullable()
    .default(null),
  aanvraagdatum: nullableLocalDateSchema,
  gereedDatum: nullableLocalDateSchema,
  passeerdatum: nullableLocalDateSchema,
  voortaxatieGewenst: Yup.boolean()
    .nullable()
    .default(false),
  faxVerstuurd: Yup.boolean()
    .nullable()
    .default(false),
  recenteVerkoop: Yup.boolean()
    .nullable()
    .default(null),
  bemiddelingsKostenBedrag: Yup.number()
    .nullable()
    .default(0),
  verbouwing: Yup.boolean()
    .nullable()
    .default(null),
  voorVerkrijgenLening: Yup.boolean()
    .nullable()
    .default(null),
  beinvloedingWaarde: Yup.boolean()
    .nullable()
    .default(false),
  toelichtingBeinvloedingWaarde: Yup.string()
    .nullable()
    .default(null),
  verbouwingSpecificatiesTotaal: Yup.number()
    .nullable()
    .default(0),
  verbouwingSpecificaties: Yup.array(TaxatieVerbouwingSpecificatieSchema).default([]),
  taxateurNwwiKeuze: TaxateurNwwiModalSchema.nullable().default(null),
  geldverstrekkerNwwiKeuze: GeldverstrekkerNwwiModalSchema.nullable().default(null),
  taxateurWaarschuwingTonen: Yup.bool().default(false),
  geldverstrekkerWaarschuwingTonen: Yup.bool().default(false),
  verbouwingTonen: Yup.bool().default(false),
  voorVerkrijgenLeningTonen: Yup.bool().default(false),
  betreftAankoop: Yup.boolean()
    .nullable()
    .default(false)
});

export const ContactpersoonSchema = Yup.object({
  typeContactpersoon: yupNullableEnum(TaxatieContactPersoonOptions).default(null),
  naam: Yup.string()
    .nullable()
    .default(null),
  naamMakelaar: Yup.string()
    .nullable()
    .default(null),
  telefoonPrive: Yup.string()
    .nullable()
    .default(null)
    .matches(phoneNumberAllowEmptyRegex, getTaxatieResources("GeldigTelefoon")),
  telefoonWerk: Yup.string()
    .nullable()
    .default(null)
    .matches(phoneNumberAllowEmptyRegex, getTaxatieResources("GeldigTelefoon")),
  telefoonMobiel: Yup.string()
    .nullable()
    .default(null)
    .matches(phoneNumberAllowEmptyRegex, getTaxatieResources("GeldigTelefoon")),
  woonplaats: Yup.string()
    .nullable()
    .default(null),
  postcode: Yup.string()
    .matches(/^$|^[1-9][0-9]{3}[\s]?[A-Za-z]{2}$/i, getGeneralTextResources("ErrorPostcode"))
    .default(""),
  geslacht: yupEnum(GeslachtOpties)
    .default(GeslachtOpties.Man)
    .meta({ testdata: GeslachtOpties }),
  email: Yup.string()
    .default(null)
    .matches(emailAllowEmptyRegex, "Ongeldig e-mailadres.")
    .nullable()
});

export const AanvullendeInformatieSchema = Yup.object({
  taxateur: Yup.string()
    .nullable()
    .default(null),
  opmerkingen: Yup.string()
    .nullable()
    .default(null),
  spoed: Yup.boolean()
    .nullable()
    .default(null),
  naamEigenaar: Yup.string()
    .nullable()
    .default(null),
  telefoonnummer: Yup.string()
    .nullable()
    .default(null)
    .matches(phoneNumberAllowEmptyRegex, getTaxatieResources("GeldigTelefoon")),
  taxateurnummer: Yup.string()
    .nullable()
    .default(null),
  geldverstrekker: Yup.number() //contractID
    .nullable()
    .default(null),
  opdrachtnummer: Yup.string()
    .nullable()
    .default(null),
  externReferentienummer: Yup.string()
    .nullable()
    .default(null),
  betalingViaNotaris: Yup.boolean()
    .nullable()
    .default(true),
  tussenpersoonNaam: Yup.string()
    .nullable()
    .default(null),
  tussenpersoonNummer: Yup.string()
    .nullable()
    .default(null)
});

export const BouwkundigeKeuringSchema = Yup.object({
  bouwjaar: Yup.number()
    .nullable()
    .default(null),
  inhoudwoning: yupNullableEnum(TaxatieInhoudOptions).default(TaxatieInhoudOptions.Tot800),
  keuringTenBehoevenVan: yupNullableEnum(TaxatieTenBehoevenVanOptions).default(TaxatieTenBehoevenVanOptions.Aankoop),
  keuringTenBehoevenVanAnders: Yup.string()
    .nullable()
    .default(""),
  benodigdeKeuring: yupNullableEnum(TaxatieBenodigdeKeuringOptions).default(
    TaxatieBenodigdeKeuringOptions.PlusKeuringMetNhgKeuring
  ),
  monument: yupNullableEnum(TaxatieJaNeeOnbekendOptions).default(TaxatieJaNeeOnbekendOptions.Ja),
  gestapeldeBouw: yupNullableEnum(TaxatieGestapeldeBouwOptions).default(TaxatieGestapeldeBouwOptions.Appartement),
  vve: yupNullableEnum(TaxatieJaNeeOnbekendOptions).default(TaxatieJaNeeOnbekendOptions.Ja),
  inhoud: Yup.number()
    .nullable()
    .default(null),
  soortWoning: Yup.number()
    .nullable()
    .default(null)
});

export const labelValueSchema = Yup.object({
  label: Yup.string().default(""),
  value: Yup.string().default(""),
  productCode: Yup.number()
    .nullable()
    .default(null)
});

export type soortAanvraagLabelValuePairs = Yup.InferType<typeof labelValueSchema>;

export const taxatieSchermSchema = Yup.object({
  taxatiebureau: TaxatiebureauSchema.nullable(),
  opdrachtgever: OpdrachtgeverSchema,
  taxatieObject: TaxatieObjectSchema.nullable(),
  partner: PartnerSchema.nullable().default(null),
  bouwkundigeKeuring: BouwkundigeKeuringSchema.nullable().default(null),
  taxatieAlgemeen: TaxatieAlgemeenSchema.nullable(),
  contactpersoon: ContactpersoonSchema.nullable().default(null),
  aanvullendeInformatie: AanvullendeInformatieSchema.nullable(),
  aanvrager1: klantnaamSchema,
  aanvrager2: klantnaamSchema.nullable().default(null),
  aanvragenInvalidText: Yup.array(Yup.string()).default([]),
  dataOutDated: Yup.bool().default(false),
  taxatieKenmerken: Yup.object()
    .nullable()
    .default(null),
  isLoading: Yup.bool().default(false),
  TaxatieSoortAanvraagOptionsOpties: Yup.array(labelValueSchema).default([]),
  gewenstehypotheekVoorstelId: Yup.string()
    .nullable()
    .default(null),
  taxatieAlgemeenReadOnly: Yup.object<TaxatieAlgemeenReadOnly>()
    .nullable()
    .default(null)
});

export type TaxatieSchermType = Yup.InferType<typeof taxatieSchermSchema> & PlatformFoutenState;
export type TaxatiebureauType = Yup.InferType<typeof TaxatiebureauSchema>;
export type TaxatieObjectType = Yup.InferType<typeof TaxatieObjectSchema>;
export type TaxatieAlgemeenType = Yup.InferType<typeof TaxatieAlgemeenSchema>;
export type ContactpersoonType = Yup.InferType<typeof ContactpersoonSchema>;
export type OpdrachtgeverTaxatieType = Yup.InferType<typeof OpdrachtgeverSchema>;
export type PartnerTaxatieType = Yup.InferType<typeof PartnerSchema>;
export type TaxatieVerbouwingSpecificatieType = Yup.InferType<typeof TaxatieVerbouwingSpecificatieSchema>;
export type TaxatieVerbouwingSpecificatieModalType = Yup.InferType<typeof TaxatieVerbouwingSpecificatieModalSchema>;
export type TaxateurNwwiModalType = Yup.InferType<typeof TaxateurNwwiModalSchema>;
export type GeldverstrekkerNwwiModalType = Yup.InferType<typeof GeldverstrekkerNwwiModalSchema>;
export type TaxateurNwwiResultaatType = Yup.InferType<typeof TaxateurNwwiResultaat>;
export type GeldverstrekkerNwwiResultaatType = Yup.InferType<typeof GeldverstrekkerNwwiResultaat>;
export type AanvullendeInformatieType = Yup.InferType<typeof AanvullendeInformatieSchema>;
export type BouwkundigeKeuringType = Yup.InferType<typeof BouwkundigeKeuringSchema>;
export type TaxatieSchermProps = Yup.InferType<typeof taxatieSchermSchema> &
  WithSaveData<TaxatieSchermType> & { taxatiebureauOpties: TaxatiebureauPartij[] };

export const taxatieBaseKenmerken = {
  TaxateurTonen: false,
  DoelTaxatieTonen: false,
  GeldverstrekkerReferentieTonen: false,
  GeldverstrekkerKeuzeTonen: false,
  HerbouwwaardeTonen: false,
  DatumAanvraagTonen: false,
  VoortaxatieTonen: false,
  NwwiAanvraagTonen: false,
  ValidatieVerplichtTonen: false,
  EnergieAdviesTonen: false,
  BouwtechnischeKeuringTonen: false,
  RecenteVerkoopTonen: false,
  BemiddelingskostenTonen: false,
  VerbouwingTonen: false,
  VoorVerkrijgenLeningTonen: false,
  BeïnvloedingWaardeTonen: false,
  ToelichtingWaardeTonen: false,
  DatumGereedTonen: false,
  FaxVerstuurdTonen: false,
  PasseerdatumTonen: false,
  OpdrachtgeverGeboortedatumTonen: false,
  OpdrachtgeverAdresTonen: false,
  OpdrachtgeverTelefoonWerkTonen: false,
  OpdrachtgeverTelefoonPriveTonen: false,
  OpdrachtgeverTelefoonMobielTonen: false,
  OpdrachtgeverEmailadresTonen: false,
  KaartPartnerTonen: false,
  PartnerTelefoonPriveTonen: false,
  PartnerTelefoonMobielTonen: false,
  PartnerEmailadresTonen: false,
  KaartContactpersoonTonen: false,
  ContactpersoonTypeTonen: false,
  MakelaarNaamTonen: false,
  ContactpersoonGeslachtTonen: false,
  ContactpersoonAdresTonen: false,
  ContactpersoonPlaatsTonen: false,
  ContactpersoonTelefoonWerkTonen: false,
  ContactpersoonTelefoonMobielTonen: false,
  ContactpersoonTelefoonPriveTonen: false,
  AankoopprijsTonen: false,
  NieuwbouwTonen: false,
  BouwplanTonen: false,
  BouwnummerTonen: false,
  GebruikTonen: false,
  GebruikToekomstTonen: false,
  HuurovereenkomstTonen: false,
  OpdrachtgeverRolTonen: false,
  KaartBouwkundigeKeuringTonen: false,
  InhoudInMetersTonen: false,
  InhoudBenaderingTonen: false,
  SoortWoningTonen: false,
  KeuringTbvTonen: false,
  BenodigdeKeuringTonen: false,
  WoningMonumentTonen: false,
  GestapeldeBouwTonen: false,
  VveTonen: false,
  SpoedTonen: false,
  OpdrachtnummerTonen: false,
  ExternReferentienummerTonen: false,
  EigenaarNaamTonen: false,
  EigenaarTelefoonTonen: false,
  TaxateurnummerTonen: false,
  BetalingViaNotarisTonen: false,
  KaartTaxatieTonen: false,
  KaartObjectTonen: false,
  KaartAanvullendeInformatieTonen: false,
  BouwjaarTonen: false,
  NhgTonen: false,
  SoortAanvraagTonen: false,
  VerbouwingspecificatieTonen: false,
  BetreftAankoopTonen: false
};

export type TaxatieKenmerkenType = typeof taxatieBaseKenmerken;
