import { LocalDate } from "@js-joda/core";
import * as Yup from "yup";
import {
  BetalingsTermijnType,
  PremieverloopOptions,
  SoortOrvProductOptions,
  OrvUitkeringssoortOptions
} from "../../.generated/forms/formstypes";
import { OrganisatieEx } from "../../.generated/licenties/licentiestypes";
import {
  PeriodiekBedragInputTermijn,
  PeriodiekeOnttrekkingInputTermijn
} from "../../.generated/vermogen/vermogentypes";
import { getProductenOverzichtTextResources } from "../../producten-overzicht/infra/producten-overzicht-resources";
import {
  aflosproductSchema,
  dekkingSchema,
  informatieVoorVerzendingAanvraagSchema,
  premieSpecificatieModalSchema,
  productSchema,
  verpandingSchema,
  verzekerdenSchema,
  verzekeringNemersSchema
} from "../../producten-overzicht/infra/producten-overzicht-schema";
import { SituatieSoort } from "../../producten-overzicht/infra/producten-overzicht-types";
import { mapJaarMaandInputDl2Ui } from "../../shared/generic-parts/jaar-maand/map-dl-2-ui";
import { jaarMaandInputSchema, JaarMaandInputType } from "../../shared/generic-parts/jaar-maand/schema";
import { klantnaamMetKinderenSchema, klantnaamSchema } from "../../shared/generic-parts/klantnaam/schema";
import { AanvragerKeuze } from "../../shared/types";
import { addYears, getDifferenceYearsMonths } from "../../shared/utils/dates";
import { hasValue } from "../../shared/utils/helpers";
import { WithSaveData } from "../../shared/utils/save-data";
import { yupEnum, yupNullableEnum } from "../../shared/utils/yup-enum";
import { getOrvTextResources } from "./orv-resources";
import { localDateSchema, nullableLocalDateSchema } from "adviesbox-shared";

export enum OrvVergelijkerResultaatStatus {
  Groen = "Groen",
  Rood = "Rood"
}

export const indicatieveUitkerendeFaseModalSchema = Yup.object({});
export const aanvullingInkomenBijOverlijdenModalSchema = Yup.object({});
export const afTeLossenLeningdelenModalSchema = Yup.object({});
export const premieDepotModalSchema = Yup.object({});
export const aanvangExtraPremieStortingenModalSchema = Yup.object({});

export const orvVerpandingSchema = verpandingSchema.shape({
  indicatieveUitkerendeFaseSpecificatie: Yup.object({})
    .default(null)
    .nullable()
});

export const orvPremieGegevensSchema = Yup.object({
  verkortePremieduur: Yup.boolean()
    .nullable()
    .default(null),
  looptijd: jaarMaandInputSchema,
  betalingstermijn: Yup.mixed<
    | BetalingsTermijnType
    | BetalingsTermijnType
    | PeriodiekBedragInputTermijn
    | BetalingsTermijnType
    | PeriodiekeOnttrekkingInputTermijn
  >()
    .oneOf([...Object.values(BetalingsTermijnType), null], getProductenOverzichtTextResources("ErrorOnbekendeWaarde"))
    .default(BetalingsTermijnType.Maand),
  risicoPremieLaag: Yup.number()
    .typeError("")
    .nullable()
    .default(null),
  premiespecificatie: premieSpecificatieModalSchema,
  einddatumPremieBetaling: localDateSchema.default(addYears(LocalDate.of(2019, 1, 1), 30)),
  premieAftrekbaar: Yup.boolean()
    .default(true)
    .nullable()
});

export const orvDekkingSchema = dekkingSchema.shape({
  verzekerdKapitaalAanvrager1: Yup.number()
    .nullable()
    .default(0)
    .test({
      test: function(value: number) {
        const context = this.options.context as OrvSchemaContextType;
        const extractedNumber = this.path.match(/\d{1,2}/);
        const selectedProduct =
          extractedNumber && context.values ? context.values.producten[Number(extractedNumber[0])] : null;
        const situatie = context.values && context?.situatie ? context.situatie : null;
        const aanvrager1 = !hasValue(value) && situatie === "voorstel";

        if (
          aanvrager1 &&
          (selectedProduct?.verzekerden.verzekerden === AanvragerKeuze.Aanvrager1 ||
            selectedProduct?.verzekerden.verzekerden === AanvragerKeuze.Beiden)
        ) {
          return this.createError({
            message: getOrvTextResources("dekkingVerzekerdKapitaalIsLaag")
          });
        }
        return true;
      }
    }),
  annuiteitspercentage1: Yup.number()
    .nullable()
    .default(null)
    .test({
      test: function(value: number) {
        const context = this.options.context as OrvSchemaContextType;
        const extractedNumber = this.path.match(/\d{1,2}/);
        const selectedProduct =
          extractedNumber && context.values ? context.values.producten[Number(extractedNumber[0])] : null;
        const aanvrager1 =
          !hasValue(value) && selectedProduct?.product.uitkeringssoort === OrvUitkeringssoortOptions.AnnuïtairDalend;

        if (
          aanvrager1 &&
          (selectedProduct?.verzekerden.verzekerden === AanvragerKeuze.Aanvrager1 ||
            selectedProduct?.verzekerden.verzekerden === AanvragerKeuze.Beiden)
        ) {
          return this.createError({
            message: getOrvTextResources("annuiteitsPercentageLeeg")
          });
        }
        return true;
      }
    }),
  verzekerdKapitaalAanvrager2: Yup.number()
    .nullable()
    .default(null)
    .test({
      test: function(value: number) {
        const context = this.options.context as OrvSchemaContextType;
        const situatie = context.values && context?.situatie ? context.situatie : null;
        const extractedNumber = this.path.match(/\d{1,2}/);
        const selectedProduct =
          extractedNumber && context.values ? context.values.producten[Number(extractedNumber[0])] : null;
        const aanvrager2 = !hasValue(value) && situatie === "voorstel";

        if (aanvrager2 && selectedProduct?.verzekerden.verzekerden === AanvragerKeuze.Beiden) {
          return this.createError({
            message: getOrvTextResources("dekkingVerzekerdKapitaalIsLaag")
          });
        }
        return true;
      }
    }),
  annuiteitspercentage2: Yup.number()
    .nullable()
    .default(null)
    .test({
      test: function(value: number) {
        const context = this.options.context as OrvSchemaContextType;
        const extractedNumber = this.path.match(/\d{1,2}/);
        const selectedProduct =
          extractedNumber && context.values ? context.values.producten[Number(extractedNumber[0])] : null;
        const aanvrager2 =
          !hasValue(value) && selectedProduct?.product.uitkeringssoort === OrvUitkeringssoortOptions.AnnuïtairDalend;

        if (aanvrager2 && selectedProduct?.verzekerden.verzekerden === AanvragerKeuze.Beiden) {
          return this.createError({
            message: getOrvTextResources("annuiteitsPercentageLeeg")
          });
        }
        return true;
      }
    })
});

export const orvVergelijkerResultaatSchema = Yup.object({
  maatschappijCode: Yup.string().nullable(),
  maatschappijOmschrijving: Yup.string().nullable(),
  productcode: Yup.number(),
  productnaam: Yup.string().nullable(),
  premieduurInJaren: Yup.number(),
  premieverloop: yupNullableEnum(PremieverloopOptions).default(null),
  totalePremieBedrag: Yup.number().nullable(),
  eerstePremieBedrag: Yup.number().nullable(),
  reservewaarde: Yup.boolean()
    .nullable()
    .default(false)
});

export const modalVerzekerdenSchema = Yup.object({
  aanvrager1: klantnaamSchema.nullable(),
  aanvrager2: klantnaamSchema.nullable(),
  verzekerden: yupEnum(AanvragerKeuze).default(AanvragerKeuze.Aanvrager1),
  verzekerde1Roker: Yup.boolean().default(false),
  verzekerde2Roker: Yup.boolean()
    .nullable()
    .default(null)
});

export const modalVerzekeringSchema = Yup.object({
  verzekeringsvorm: yupEnum(OrvUitkeringssoortOptions).default(OrvUitkeringssoortOptions.Gelijkblijvend),
  aanvangsdatum: localDateSchema.default(LocalDate.now()),
  duurInJaren: Yup.number()
    .min(1, getOrvTextResources("ErrorDuurInJaren"))
    .max(30, getOrvTextResources("ErrorDuurInJaren"))
    .required(getOrvTextResources("ErrorDuurInJaren"))
    .default(30),
  verzekerdKapitaalBedrag: Yup.number()
    .min(1, getOrvTextResources("ErrorMinMaxVerzekerdKapitaalBedrag"))
    .max(99999999, getOrvTextResources("ErrorMinMaxVerzekerdKapitaalBedrag"))
    .nullable()
    .required(getOrvTextResources("ErrorVerzekerdKapitaalBedrag"))
    .default(null),
  annuiteitsPercentage: Yup.number()
    .nullable()
    .min(0)
    .max(20, getOrvTextResources("ErrorMaxAnnuiteitsPercentage"))
    .default(6),
  verpand: Yup.boolean().default(false)
});

export const orvVergelijkenModalSchema = Yup.object({
  verzekerden: Yup.object<Yup.InferType<typeof modalVerzekerdenSchema>>().when("modalOpen", {
    is: true,
    then: modalVerzekerdenSchema
  }),
  verzekering: Yup.object<Yup.InferType<typeof modalVerzekeringSchema>>().when("modalOpen", {
    is: true,
    then: modalVerzekeringSchema
  }),
  orvVergelijkerResultaat: Yup.array(orvVergelijkerResultaatSchema).default([]),
  sortedOrvVergelijkerResultaat: Yup.array(orvVergelijkerResultaatSchema).default([]),
  dataOutdated: Yup.boolean().default(false),
  selectedResultaat: orvVergelijkerResultaatSchema.nullable().default(null),
  organisatieData: Yup.object<OrganisatieEx>()
    .nullable()
    .default(null),
  modalOpen: Yup.boolean().default(false)
});

export const orvProductSchema = productSchema.shape({
  ingangsdatum: localDateSchema.default(LocalDate.of(2019, 1, 1)),
  uitkeringssoort: yupEnum(OrvUitkeringssoortOptions).default(OrvUitkeringssoortOptions.LineairDalend),
  soortProduct: yupEnum(SoortOrvProductOptions).default(SoortOrvProductOptions.Orv),
  productId: Yup.string().nullable()
});

export const orvSchema = Yup.object({
  partijCode: Yup.string(),
  productCode: Yup.string(),
  premieverloop: yupNullableEnum(PremieverloopOptions).default(null),
  product: orvProductSchema,
  verzekeringnemers: verzekeringNemersSchema,
  verzekerden: verzekerdenSchema,
  dekking: orvDekkingSchema.when("product", (value?: OrvProductType) => {
    return value?.soortProduct === SoortOrvProductOptions.AnwHiaatVerzekering
      ? orvDekkingSchema.shape({
          verzekerdKapitaalAanvrager1: Yup.number().nullable(),
          verzekerdKapitaalAanvrager2: Yup.number().nullable()
        })
      : orvDekkingSchema;
  }),
  premieGegevens: orvPremieGegevensSchema,
  verpanding: orvVerpandingSchema,
  informatieVoorVerzendingAanvraag: informatieVoorVerzendingAanvraagSchema,
  incorrecteProductkenmerken: Yup.boolean().default(false)
});

export const orvsSchemaBase = Yup.object({
  producten: Yup.array(orvSchema),
  aanvrager1: klantnaamMetKinderenSchema.nullable(),
  aanvrager2: klantnaamMetKinderenSchema.nullable(),
  ingangsdatumVoorstel: nullableLocalDateSchema,
  orvVergelijkenModal: orvVergelijkenModalSchema,
  aflosProductNew: Yup.array()
    .of(aflosproductSchema)
    .default([])
});

export const huidigOrvProductSchema = orvSchema.shape({
  product: orvProductSchema
    .shape({
      productNummer: Yup.string().nullable(),
      einddatum: localDateSchema,
      looptijd: jaarMaandInputSchema
        .nullable()
        .default(mapJaarMaandInputDl2Ui(30, 0))
        .test({
          test: function(value: JaarMaandInputType) {
            const context = this.options.context as OrvSchemaContextType;
            const extractedNumber = this.path.match(/\d{1,2}/);
            const selectedProduct =
              extractedNumber && context.values ? context.values.producten[Number(extractedNumber[0])] : null;
            const productIngangsdatum = selectedProduct ? selectedProduct.product.looptijd : false;

            if (
              productIngangsdatum &&
              selectedProduct &&
              context.values.aanvrager1?.aowdatum &&
              selectedProduct.product.soortProduct === SoortOrvProductOptions.AnwHiaatVerzekering
            ) {
              const duurTotAow =
                selectedProduct.verzekerden.verzekerden === AanvragerKeuze.Aanvrager2 &&
                context.values.aanvrager2?.aowdatum
                  ? getDifferenceYearsMonths(selectedProduct.product.ingangsdatum, context.values.aanvrager2.aowdatum)
                  : getDifferenceYearsMonths(selectedProduct.product.ingangsdatum, context.values.aanvrager1.aowdatum);

              if (
                (productIngangsdatum.jaren || 0) > duurTotAow.year ||
                ((productIngangsdatum.jaren || 0) === duurTotAow.year &&
                  (productIngangsdatum.maanden || 0) > duurTotAow.month)
              ) {
                return this.createError({
                  message: getOrvTextResources("ErrorOrvProductLooptijd"),
                  path: `${this.path}.jaren`
                });
              }
            }

            return true;
          }
        })
    })
    .default({
      ...orvProductSchema.default(),
      productNummer: "",
      einddatum: addYears(LocalDate.of(2019, 1, 1), 30),
      looptijd: mapJaarMaandInputDl2Ui(30, 0)
    }),
  premieGegevens: orvPremieGegevensSchema.shape({
    einddatumPremieBetaling: nullableLocalDateSchema.default(addYears(LocalDate.of(2019, 1, 1), 30)),
    looptijd: jaarMaandInputSchema
      .nullable()
      .default(mapJaarMaandInputDl2Ui(30, 0))
      .test({
        test: function(value: JaarMaandInputType) {
          const context = this.options.context as OrvSchemaContextType;
          const extractedNumber = this.path.match(/\d{1,2}/);
          const selectedProduct =
            extractedNumber && context.values ? context.values.producten[Number(extractedNumber[0])] : null;
          const productIngangsdatum = context.values ? selectedProduct?.product.looptijd : false;

          if (productIngangsdatum) {
            const productJaren = productIngangsdatum.jaren;
            const productMaanden = productIngangsdatum.maanden;
            const jarenFout = (value.jaren || 0) > (productJaren || 0);
            const maandenFout =
              (value.maanden || 0) > (productMaanden || 0) && (value.jaren || 0) >= (productJaren || 0);

            if (jarenFout) {
              return this.createError({
                path: `${this.path}.jaren`,
                message: getOrvTextResources("ErrorOrvPremieGegevensLooptijd")
              });
            } else if (maandenFout) {
              return this.createError({
                path: `${this.path}.maanden`,
                message: getOrvTextResources("ErrorOrvPremieGegevensLooptijd")
              });
            }
          }
          return true;
        }
      })
  }),
  informatieVoorVerzendingAanvraag: informatieVoorVerzendingAanvraagSchema.nullable().default(null)
});

export const orvSchemaHuidig = orvsSchemaBase.shape({
  producten: Yup.array(huidigOrvProductSchema),
  orvVergelijkenModal: Yup.object().notRequired()
});

export const voorstelOrvProductSchema = orvSchema.shape({
  product: orvProductSchema
    .shape({
      productNummer: Yup.string().nullable(),
      einddatum: localDateSchema,
      looptijd: jaarMaandInputSchema
    })
    .default({
      ...orvProductSchema.default(),
      productNummer: "",
      uwBemiddeling: true,
      einddatum: addYears(LocalDate.of(2019, 1, 1), 30),
      looptijd: mapJaarMaandInputDl2Ui(30, 0)
    }),
  premieGegevens: orvPremieGegevensSchema.shape({
    einddatumPremieBetaling: nullableLocalDateSchema.default(addYears(LocalDate.of(2019, 1, 1), 30)),
    looptijd: jaarMaandInputSchema.nullable().default(mapJaarMaandInputDl2Ui(30, 0))
  }),
  hdnAanvraag: Yup.object({
    hintHdnAanvraagNietAanwezig: Yup.string(),
    hdnAanvraagNietAanwezigTonen: Yup.boolean()
  }).default({
    hintHdnAanvraagNietAanwezig: "",
    hdnAanvraagNietAanwezigTonen: false
  }),
  informatieVoorVerzendingAanvraag: informatieVoorVerzendingAanvraagSchema.nullable().default(null),
  verpanding: orvVerpandingSchema
});

export const orvSchemaVoorstel = orvsSchemaBase.shape({
  producten: Yup.array(voorstelOrvProductSchema)
});

export type OrvState = Yup.InferType<typeof orvSchema>;
export type OrvVergelijkenModalType = Yup.InferType<typeof orvVergelijkenModalSchema>;
export type OrvVergelijkerResultaatType = Yup.InferType<typeof orvVergelijkerResultaatSchema>;
export type ModalVerzekerdenType = Yup.InferType<typeof modalVerzekerdenSchema>;
export type ModalVerzekeringType = Yup.InferType<typeof modalVerzekeringSchema>;
export type OrvsState = Yup.InferType<typeof orvsSchemaBase>;
export type OrvProps = OrvsState &
  WithSaveData<OrvsState> & {
    situatie: SituatieSoort;
  };

export type OrvProductType = Yup.InferType<typeof orvProductSchema>;
export type OrvType = Yup.InferType<typeof orvSchema>;
export type OrvHuidigType = Yup.InferType<typeof orvSchemaHuidig>;
export type OrvVoorstelType = Yup.InferType<typeof orvSchemaVoorstel>;
export type VoorstelOrvProductType = Yup.InferType<typeof voorstelOrvProductSchema>;
export type HuidigOrvProductType = Yup.InferType<typeof huidigOrvProductSchema>;
export type OrvPremieGegevenType = Yup.InferType<typeof orvPremieGegevensSchema>;
export type OrvVerpandingType = Yup.InferType<typeof orvVerpandingSchema>;
export type OrvDekkingType = Yup.InferType<typeof orvDekkingSchema>;
export type OrvSchemaContextType = {
  values: OrvsState;
  situatie: SituatieSoort;
};

export interface OrvVergelijkerModalDataType extends OrvVergelijkerResultaatType {
  status: OrvVergelijkerResultaatStatus;
}
