import { ErrorPage, PageLoading, SettingsType, withErrorBoundary } from "adviesbox-shared";
import React, { ReactElement, useCallback } from "react";
import { mapDlTargetToVermogenUiField, mapVermogensDlToUi } from "./infra/map-vermogen-dl-2-ui";

import { InvalidAdviesboxResultErrorPage } from "../shared/components/fetch-error-page/fetch-error-page";
import { RouteParams } from "../shared/paramrouting/paramrouting-context";
import { SituatieSoort } from "../producten-overzicht/infra/producten-overzicht-types";
import Vermogen from "./vermogen";
import { mapVermogensUiToDl } from "./infra/map-vermogen-ui-2-dl";
import { useAdviesboxData } from "../shared/hooks/use-adviesbox-data";

type VermogenAjaxProps = { situatie: SituatieSoort };

const VermogenAjax = ({ situatie }: VermogenAjaxProps): ReactElement => {
  const createUrl = useCallback(
    (s: SettingsType, p: RouteParams): string =>
      situatie === "huidig"
        ? `${s.klantdossiersFormsOrigin}/Adviesdossiers/${p.adviesdossier}/Vermogen`
        : `${s.klantdossiersFormsOrigin}/Voorstellen/${p.voorstel}/Vermogen`,
    [situatie]
  );

  const createDataId = useCallback((p: RouteParams) => (situatie === "huidig" ? p.adviesdossier : p.voorstel), [
    situatie
  ]);

  const { loading, error, data, platformData, saveData } = useAdviesboxData(
    createUrl,
    createDataId,
    mapVermogensDlToUi,
    mapVermogensUiToDl,
    mapDlTargetToVermogenUiField
  );

  if (loading) {
    return <PageLoading />;
  }

  if (error) {
    return <ErrorPage error={error} data={platformData} />;
  }

  if (!data) {
    return <InvalidAdviesboxResultErrorPage />;
  }

  return <Vermogen saveData={saveData} situatie={situatie} {...data} />;
};

VermogenAjax.displayName = "VermogenAjax";

export default withErrorBoundary(VermogenAjax);
