import "adviesbox-shared";
import { LabeledNumberInput, LabeledRadioInput, LabeledText } from "adviesbox-shared";
import { useFormikContext } from "formik";
import React, { ReactElement, useMemo } from "react";
import { MotiveringOnderwerpsoort } from "../../.generated/forms/formstypes";
import { getMotiveringTextResources } from "../infra/motivering-resources";
import { MotiveringState, NamePrefixDubbeleWoonlasten } from "../infra/motivering-schema";
import classes from "./uitgangspunten.module.scss";

type DubbeleWoonlastenProps = {
  namePrefix: NamePrefixDubbeleWoonlasten;
  motiveringType?: MotiveringOnderwerpsoort;
};
export const DubbeleWoonlasten = (_props: DubbeleWoonlastenProps): ReactElement => {
  const { namePrefix, motiveringType } = _props;
  const formik = useFormikContext<MotiveringState>();
  const values = formik.values;

  const voorstelkeuzeOpties = useMemo(
    () =>
      values[namePrefix]?.voorstellen?.map(voorstel => ({
        label: voorstel.omschrijving || `Voorstel #${voorstel.volgnummer}`,
        value: voorstel.voorstelId || ""
      })),
    [values, namePrefix]
  );

  return (
    <>
      <div className={`${classes.left} pb-3`}>
        {motiveringType === MotiveringOnderwerpsoort.DubbeleWoonlasten && (
          <>
            <LabeledText caption="Uw maandlasten tijdens een overbrugging" value="" />
            <LabeledNumberInput
              caption="Geschat aantal maanden overbrugging"
              name={`uitgangspuntenDubbeleWoonlasten.geschatteDuurOverbruggingInMaanden`}
            />
          </>
        )}
        {voorstelkeuzeOpties && (
          <>
            <h2>Voorstelkeuze</h2>
            <LabeledRadioInput
              caption="Berekeningen uitvoeren op basis van"
              name={`${namePrefix}.motiveringVoorVoorstelId`}
              options={voorstelkeuzeOpties}
              tooltip={getMotiveringTextResources("VoorstelKeuzeTooltip")}
            />
          </>
        )}
      </div>
    </>
  );
};
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production")
  DubbeleWoonlasten.displayName = "DubbeleWoonlasten";
