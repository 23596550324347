import { HypotheekvormBasis, HypotheekvormenBasisOutput } from "../../.generated/producten/productentypes";
import { useContext, useEffect } from "react";
import { SituatieSoort } from "../../producten-overzicht/infra/producten-overzicht-types";
import { partijOnafhankelijk } from "../../producten-overzicht/infra/product-constanten";
import useAbortableFetch from "use-abortable-fetch";
import { useRequestInit, ForceRerenderContext } from "adviesbox-shared";

export type AflosProductKeuzeLijstType = { [index: string]: HypotheekvormBasis };

export type UseAflossingProductDataResult = {
  error: null | Error;
  aflosProductKeuzeLijst: AflosProductKeuzeLijstType | null;
  loading?: boolean;
};

export function useProductenData(
  situatie: SituatieSoort,
  maatschappijCode: string,
  labelCode: string,
  isAankoop?: boolean
): UseAflossingProductDataResult {
  const loadingDone = useContext(ForceRerenderContext);

  const getHypotheekVormenUrl = (
    productenOriginUrl: string,
    situatie: SituatieSoort,
    maatschappijCode: string,
    labelCode: string
  ): string => {
    return situatie === "huidig" || maatschappijCode === partijOnafhankelijk
      ? `${productenOriginUrl}/MaatschappijCodes/XX/Hypotheekvormen`
      : `${productenOriginUrl}/MaatschappijCodes/${maatschappijCode}/HypotheeklabelCodes/${labelCode}/Hypotheekvormen${
          situatie !== "voorstel" ? "" : `?inclGeenAankoop=${!isAankoop}`
        }`;
  };

  const {
    requestInit,
    settings: { productenOrigin }
  } = useRequestInit();
  const { error, data, loading } = useAbortableFetch<HypotheekvormenBasisOutput>(
    getHypotheekVormenUrl(productenOrigin, situatie, maatschappijCode, labelCode),
    requestInit
  );

  useEffect((): void => {
    if (!loading && !!data && loadingDone) {
      loadingDone();
    }
  }, [loading, data, loadingDone]);

  const listWithLoadingEntry = null;

  if (typeof data === "string") {
    return { error: new Error("Fout bij het laden van producten data"), aflosProductKeuzeLijst: {} };
  }

  return {
    error,
    aflosProductKeuzeLijst: !data || !data.hypotheekvormen ? listWithLoadingEntry : data.hypotheekvormen,
    loading
  };
}
