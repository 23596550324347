import * as Yup from "yup";
import { localDateSchema } from "adviesbox-shared";

export const leningdeelSchema = Yup.object({
  leningdeelId: Yup.string().default(""),
  doorlopend: Yup.boolean().default(false),
  productnaam: Yup.string()
    .nullable()
    .default(null),
  aanvangsdatum: localDateSchema,
  looptijd: Yup.number(),
  maatschappijCode: Yup.string()
    .nullable()
    .default(null),
  productcode: Yup.number()
});

export const vermogenLeningdeelSchema = leningdeelSchema.shape({
  doelkapitaal: Yup.number().nullable(),
  doelkapitaalPercentage: Yup.number().nullable()
});

export type LeningdeelType = Yup.InferType<typeof leningdeelSchema>;
export type VermogenLeningdeelType = Yup.InferType<typeof vermogenLeningdeelSchema>;
