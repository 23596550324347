import { VerzekeraarPartij } from "../../.generated/instellingen-forms/instellingen-formstypes";
import { AlleMogelijkeProductBasisTypes, SituatieSoort } from "./producten-overzicht-types";
import { partijOnafhankelijkFilter } from "./producten-helper";
import { partijInstellingenFilter } from "../../shared/utils/instellingen-filters";

export const getSituatieFilter = (
  situatie: SituatieSoort,
  product: AlleMogelijkeProductBasisTypes,
  verzekeraarsPartijen: VerzekeraarPartij[] | null | undefined
): boolean => {
  if (situatie === "huidig") {
    return partijOnafhankelijkFilter(product);
  }
  return partijInstellingenFilter(verzekeraarsPartijen || [], product.maatschappijCode || "");
};
