import React, { ReactElement } from "react";
import Woning from "./woning";
import { mapWoningDlNaarUi, mapWoningUiNaarDl, mapWoningDlNameToUiName } from "./infra/map-woning";
import { useAdviesboxData } from "../shared/hooks/use-adviesbox-data";
import { InvalidAdviesboxResultErrorPage } from "../shared/components/fetch-error-page/fetch-error-page";
import { ErrorBoundary, ErrorPage, PageLoading, SettingsType } from "adviesbox-shared";
import { SoortFoto } from "./infra/woning-schema";
import { GetAdresEncoded, GetMapsImageUrl } from "../shared/components/mapsimage/MapImage";

type RouteParams = {
  vestiging: string;
  adviesdossier: string;
};

const createUrl = (s: SettingsType, p: RouteParams): string =>
  `${s.klantdossiersFormsOrigin}/Adviesdossiers/${p.adviesdossier}/Woning`;

const WoningAjax = (): ReactElement | null => {
  const { loading, error, data, platformData, saveData } = useAdviesboxData(
    createUrl,
    p => p.adviesdossier,
    mapWoningDlNaarUi,
    mapWoningUiNaarDl,
    mapWoningDlNameToUiName
  );

  if (loading) {
    return <PageLoading />;
  }

  if (error) {
    return <ErrorPage error={error} data={platformData} />;
  }

  if (!data) {
    return <InvalidAdviesboxResultErrorPage />;
  }
  if (
    data.aanTeKopenWoning?.adres.postcode.length > 0 &&
    data.aanTeKopenWoning?.adres.huisnummer !== null &&
    data.aanTeKopenWoning?.fotoGebruikRapportage &&
    data.aanTeKopenWoning?.soortFoto === SoortFoto.GoogleMaps
  ) {
    const locationStr = GetAdresEncoded(data.aanTeKopenWoning.adres);
    const mapsUrl = GetMapsImageUrl(locationStr);
    data.aanTeKopenWoning.locatieFoto = mapsUrl;
  }
  return (
    <ErrorBoundary>
      <Woning
        financiering={data.financiering}
        hypotheekOpWoning={data.hypotheekOpWoning}
        teVerkopenWoningen={data.teVerkopenWoningen}
        aanTeKopenWoning={data.aanTeKopenWoning}
        huidigeWoningen={data.huidigeWoningen}
        saveData={saveData}
      />
    </ErrorBoundary>
  );
};

WoningAjax.displayName = "WoningAjax";

export default WoningAjax;
