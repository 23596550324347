/* istanbul ignore file */

import {
  MotiveringOnderwerpsoort,
  StandaardTeksten,
  StandaardTekstenOutput
} from "../../.generated/instellingen-forms/instellingen-formstypes";
import { useContext, useEffect } from "react";

import useAbortableFetch from "use-abortable-fetch";
import { useRequestInit, ForceRerenderContext } from "adviesbox-shared";

export type UseInstellingenDataResult<T> = {
  loading: boolean;
  error: null | Error;
  data: T;
};

export enum StandaardteksenOnderwerpen {
  "algemeen" = "algemeen",
  "verduurzaming" = "verduurzaming",
  "verantwoorde-woonlasten" = "verantwoorde-woonlasten",
  "eigen-geld" = "eigen-geld",
  "dubbele-woonlasten" = "dubbele-woonlasten",
  "fiscaal" = "fiscaal",
  "hypotheekvorm" = "hypotheekvorm",
  "rentevastperiode" = "rentevastperiode",
  "geldverstrekker" = "geldverstrekker",
  "pensioen" = "pensioen",
  "overlijden" = "overlijden",
  "arbeidsongeschiktheid" = "arbeidsongeschiktheid",
  "werkloosheid" = "werkloosheid",
  "opbouw-doelvermogen" = "opbouw-doelvermogen",
  "risicoprofiel" = "risicoprofiel"
}

export function mapOnderwerpToOnderwerpSoort(onderwerp: StandaardteksenOnderwerpen): MotiveringOnderwerpsoort {
  switch (onderwerp) {
    case StandaardteksenOnderwerpen.algemeen:
      return MotiveringOnderwerpsoort.Algemeen;
    case StandaardteksenOnderwerpen.arbeidsongeschiktheid:
      return MotiveringOnderwerpsoort.AfdekkenAOWW;
    case StandaardteksenOnderwerpen["dubbele-woonlasten"]:
      return MotiveringOnderwerpsoort.DubbeleWoonlasten;
    case StandaardteksenOnderwerpen["eigen-geld"]:
      return MotiveringOnderwerpsoort.InbrengEigenGeld;
    case StandaardteksenOnderwerpen.fiscaal:
      return MotiveringOnderwerpsoort.FiscaleAftrek;
    case StandaardteksenOnderwerpen.geldverstrekker:
      return MotiveringOnderwerpsoort.Geldverstrekker;
    case StandaardteksenOnderwerpen.hypotheekvorm:
      return MotiveringOnderwerpsoort.HypotheekAflossen;
    case StandaardteksenOnderwerpen["opbouw-doelvermogen"]:
      return MotiveringOnderwerpsoort.WijzeOpbouwDoelvermogen;
    case StandaardteksenOnderwerpen.overlijden:
      return MotiveringOnderwerpsoort.AfdekkenOverlijdensrisico;
    case StandaardteksenOnderwerpen.pensioen:
      return MotiveringOnderwerpsoort.Pensioenvoorziening;
    case StandaardteksenOnderwerpen.rentevastperiode:
      return MotiveringOnderwerpsoort.Rentevastperiode;
    case StandaardteksenOnderwerpen.risicoprofiel:
      return MotiveringOnderwerpsoort.Risicoprofiel;
    case StandaardteksenOnderwerpen["verantwoorde-woonlasten"]:
      return MotiveringOnderwerpsoort.VerantwoordeWoonlasten;
    case StandaardteksenOnderwerpen.verduurzaming:
      return MotiveringOnderwerpsoort.Energie;
    case StandaardteksenOnderwerpen.werkloosheid:
      return MotiveringOnderwerpsoort.AfdekkenWW;
    default:
      return MotiveringOnderwerpsoort.Geen;
  }
}

export function useInstellingenStandaardTeksten(
  onderwerp: StandaardteksenOnderwerpen
): UseInstellingenDataResult<StandaardTeksten | null> & {
  settings?: any;
  user?: any;
  params?: any;
  url?: string;
} {
  const { settings, user, params, requestInit } = useRequestInit();
  const url = `${settings.instellingenFormsOrigin}/StandaardTeksten?onderwerpsoort=${mapOnderwerpToOnderwerpSoort(
    onderwerp
  )}`;
  const loadingDone = useContext(ForceRerenderContext);

  const { loading, error, data } = useAbortableFetch<StandaardTekstenOutput>(url, requestInit);

  useEffect((): void => {
    if (!loading && !!data && loadingDone) {
      loadingDone();
    }
  }, [loading, data, loadingDone]);

  if (typeof data === "string") {
    return { error: new Error("Fout bij het laden van instellingen rapportage data"), data: null, loading: false };
  }

  return { loading, error, data: data ? data.standaardTeksten : null, user, params, settings, url };
}
