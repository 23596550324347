import { ErrorPage, PageLoading, SettingsType, withErrorBoundary } from "adviesbox-shared";
import React, { ReactElement } from "react";
import { useParams } from "react-router-dom";
import { MotiveringOnderwerpsoort } from "../.generated/forms/formstypes";
import { InvalidAdviesboxResultErrorPage } from "../shared/components/fetch-error-page/fetch-error-page";
import { useAdviesboxData } from "../shared/hooks/use-adviesbox-data";
import {
  mapOnderwerpToOnderwerpSoort,
  StandaardteksenOnderwerpen
} from "../shared/hooks/use-instellingen-teksten-data";
import { useInstellingenUitgangspuntenAdviesData } from "../shared/hooks/use-instellingen-uitgangspunten-data";
import { mapDlTargetToMotiveringAlgemeenUiField } from "./infra/map-motivering-dl-target-to-ui-field";
import { mapMotiveringAlgemeenDlToUi } from "./infra/map-motivering-dl-to-ui";
import { mapMotiveringUiToDl } from "./infra/map-motivering-ui-to-dl";
import { Motivering } from "./motivering";
import { MotiveringAanleidingAjax } from "./motivering-aanleiding-ajax";

type RouteParams = {
  vestiging: string;
  adviesdossier: string;
};

const MotiveringAlgemeenAjaxComponent = (): ReactElement => {
  const params = useParams<{ onderwerp: StandaardteksenOnderwerpen }>();
  const motiveringType = mapOnderwerpToOnderwerpSoort(params.onderwerp as StandaardteksenOnderwerpen);

  const createUrl = (s: SettingsType, p: RouteParams): string =>
    `${s.klantdossiersFormsOrigin}/Adviesdossiers/${p.adviesdossier}/Motivering/${motiveringType}`;

  const { loading, error, data, platformData, saveData } = useAdviesboxData(
    createUrl,
    p => p.adviesdossier,
    mapMotiveringAlgemeenDlToUi,
    mapMotiveringUiToDl,
    mapDlTargetToMotiveringAlgemeenUiField
  );

  const {
    data: instellingenUitgangspuntenAdviesData,
    error: instellingenUitgangspuntenAdviesError,
    loading: instellingenUitgangspuntenAdviesLoading
  } = useInstellingenUitgangspuntenAdviesData();

  if (loading || instellingenUitgangspuntenAdviesLoading) {
    return <PageLoading />;
  }

  if (error) {
    return <ErrorPage error={error} data={platformData} />;
  }

  if (instellingenUitgangspuntenAdviesError) {
    return (
      <ErrorPage
        error={instellingenUitgangspuntenAdviesError}
        data={JSON.stringify(instellingenUitgangspuntenAdviesData)}
      />
    );
  }

  if (!data || !instellingenUitgangspuntenAdviesData) {
    return <InvalidAdviesboxResultErrorPage />;
  }

  if (
    motiveringType === MotiveringOnderwerpsoort.Pensioenvoorziening ||
    motiveringType === MotiveringOnderwerpsoort.AfdekkenWW ||
    motiveringType === MotiveringOnderwerpsoort.AfdekkenAOWW ||
    motiveringType === MotiveringOnderwerpsoort.AfdekkenOverlijdensrisico
  )
    return (
      <MotiveringAanleidingAjax
        saveData={saveData}
        motiveringType={motiveringType}
        data={data}
        instellingenUitgangspuntenAdviesData={instellingenUitgangspuntenAdviesData}
      />
    );
  else
    return (
      <Motivering
        saveData={saveData}
        motiveringType={motiveringType}
        data={data}
        instellingenUitgangspuntenAdviesData={instellingenUitgangspuntenAdviesData}
      />
    );
};

export const MotiveringAjax = withErrorBoundary(MotiveringAlgemeenAjaxComponent);
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production") MotiveringAjax.displayName = "MotiveringAjax";
