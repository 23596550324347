import { FormikHelpers, getIn } from "formik";
import { KindType } from "../infra/personalia-schema";


type BindSaveKinderenType<T> = (values: T) => (result: KindType[]) => void;

export const bindSaveKinderenFunction = <T>(
  setFieldValue: FormikHelpers<T>["setFieldValue"],
  aanvragerNummer: 1 | 2
): BindSaveKinderenType<T> => values => (result): void => {
  const otherAanvrager = aanvragerNummer === 1 ? 2 : 1;

  const aanvragerFieldName = `aanvrager${aanvragerNummer}BurgerlijkeStatus.kinderen`;
  const otherAanvragerFieldName = `aanvrager${otherAanvrager}BurgerlijkeStatus.kinderen`;

  const kinderen = [...result]
    .filter(kind => kind.internalAanvragerNummer !== otherAanvrager)
    .map(kind => ({
      ...kind,
      internalAanvragerNummer: aanvragerNummer
    }));

  const otherKinderen = result.filter(kind => kind.internalAanvragerNummer === otherAanvrager);
  const updateCurrentOtherKinderen: KindType[] = [...getIn(values, otherAanvragerFieldName)]
    .filter(k => !k.gezamenlijkKind)
    .concat(otherKinderen);

  setFieldValue(aanvragerFieldName, kinderen);
  setFieldValue(otherAanvragerFieldName, updateCurrentOtherKinderen);
};
