/* istanbul ignore file */
import { LocalDate, Period } from "@js-joda/core";
import {
  AowData,
  BedragScenario,
  FiscaleAftrekposten,
  FiscaleBijtelposten,
  InkomenFiscus as InkomenEnFiscusDlEntry,
  InkomenFiscusAanvrager,
  LoonverklaringOpties,
  InkomenFiscusOutput as InkomenEnFiscusDlOutput,
  InkomstenBox3,
  Loondienst,
  InkomenPeriode,
  SoortDienstBetrekkingType,
  Onderneming,
  RechtsVormType,
  InkomenverklaringOpties,
  SoortOndernemingType,
  OverigeInkomstenBox1
} from "../../.generated/forms/formstypes";
import { mapAdresDlNaarUi } from "../../shared/generic-parts/adres/map-dl-2-ui";
import { mapBerekenDate } from "../../shared/generic-parts/bereken-date/map";
import { BerekenDateType } from "../../shared/generic-parts/bereken-date/schema";
import { scenarioCardInputType } from "../../shared/types";
import { createMapEnum } from "../../shared/utils/create-map-enum";
import { createMapToUi } from "../../shared/utils/create-map-to-ui";
import { hasValue, prefixWithZero } from "../../shared/utils/helpers";
import { sortVolgnummer } from "../../shared/utils/sort-volgnummer";
import {
  aowSchema,
  BrutoSalarisPeriode,
  fiscaleAftrekpostenModalSchema,
  fiscaleBijtelpostenModalSchema,
  fiscaleVerdelingSchema,
  fiscaliteitSchema,
  inkomenEnFiscusSchema,
  InkomenEnFiscusState,
  inkomenSchema,
  inkomensverledenSchema,
  inkomenUitArbeidModalSchema,
  InkomenVerklaring,
  inkomstenAanmerkelijkBelangModalSchema,
  inkomstenUitBeleggingenModalSchema,
  inkomstenVerhuurOnroerendGoedModalSchema,
  ondernemingModalSchema,
  overigeInkomstenModalSchema,
  overigeWerkzaamhedenModalSchema,
  RechtsVorm,
  scenarioInstellingenSchema,
  sociaalSchema,
  SoortDienstverband,
  SoortInkomenverklaring,
  SoortOnderneming
} from "./inkomen-en-fiscus-schema";
import { mapStringToLocalDate, isSameDate } from "adviesbox-shared";

const mapSoortDienstBetrekkingTypeEnum = createMapEnum(SoortDienstBetrekkingType).to({
  Geen: SoortDienstverband.Geen,
  FlexibeleArbeidsrelatieMetPerspectiefverklaring: SoortDienstverband.FlexibeleArbeidsrelatieMetPerspectiefverklaring,
  FlexibeleArbeidsrelatieZonderPerspectiefverklaring:
    SoortDienstverband.FlexibeleArbeidsrelatieZonderPerspectiefverklaring,
  LoondienstVast: SoortDienstverband.LoondienstVast,
  LoondienstTijdelijkMetIntentie: SoortDienstverband.LoondienstTijdelijkMetIntentie,
  LoondienstTijdelijkZonderIntentie: SoortDienstverband.LoondienstTijdelijkZonderIntentie
});

const mapInkomenPeriodeEnum = createMapEnum(InkomenPeriode).to({
  Geen: BrutoSalarisPeriode.Geen,
  // Per2Maanden: BrutoSalarisPeriode.Geen,
  // PerKwartaal: BrutoSalarisPeriode.Geen,
  // PerHalfJaar: BrutoSalarisPeriode.Geen,
  PerWeek: BrutoSalarisPeriode.PerWeek,
  Per4Weken: BrutoSalarisPeriode.Per4Weken,
  PerMaand: BrutoSalarisPeriode.PerMaand,
  PerJaar: BrutoSalarisPeriode.PerJaar
});

const mapLoonverklaringOptiesEnum = createMapEnum(LoonverklaringOpties).to({
  Arbeidsmarktscan: InkomenVerklaring.ArbeidScan,
  Geen: null,
  Perspectiefverklaring: null,
  UwvDocument: InkomenVerklaring.UWVdocument,
  Werkgeververklaring: InkomenVerklaring.Werkgeversverklaring
});

/* eslint-disable @typescript-eslint/camelcase */
const mapOndernemingEnum = createMapEnum(SoortOndernemingType).to({
  Geen: SoortOnderneming.ZelfstandigeMetPersoneel, // Default aangepast
  ZelfstandigeMetPersoneel: SoortOnderneming.ZelfstandigeMetPersoneel,
  ZelfstandigeZonderPersoneel_ZZP_: SoortOnderneming.ZelfstandigeZonderPersoneel
});
/* eslint-enable @typescript-eslint/camelcase */

/* eslint-disable @typescript-eslint/camelcase */
const mapRechtsVormTypeEnum = createMapEnum(RechtsVormType).to({
  Geen: RechtsVorm.Geen,
  BeslotenVennootschap_Bv_: RechtsVorm.BeslotenVennootschap,
  CommanditaireVennootschap_Cv_: RechtsVorm.CommanditaireVennootschap,
  Eenmanszaak: RechtsVorm.Eenmanszaak,
  Maatschap: RechtsVorm.Maatschap,
  NaamlozeVennootschap_Nv_: RechtsVorm.NaamlozeVennootschap,
  Stichting: RechtsVorm.Stichting,
  VennootschapOnderFirma_Vof_: RechtsVorm.VennootschapOnderFirma
});
/* eslint-enable @typescript-eslint/camelcase */

const mapInkomenverklaringOptiesEnum = createMapEnum(InkomenverklaringOpties).to({
  Geen: SoortInkomenverklaring.Geen,
  Accountantsverklaring: SoortInkomenverklaring.Accountantsverklaring,
  IbStukken: SoortInkomenverklaring.IbStukken
});

type ExtraAanvragerInfo = {
  geboortedatum: LocalDate | null;
  aowdatum: LocalDate | null;
  hasAanvrager2: boolean;
  soortLoonverklaring: LoonverklaringOpties | null;
};
const createExtraInfo = (
  aanvrager: InkomenFiscusAanvrager | null | undefined,
  hasAanvrager2: boolean
): ExtraAanvragerInfo => {
  if (!aanvrager)
    return {
      aowdatum: null,
      geboortedatum: null,
      hasAanvrager2: hasAanvrager2,
      soortLoonverklaring: null
    };

  return {
    aowdatum: aanvrager.aowData
      ? aanvrager.aowData.aowDatumOvernemen
        ? mapStringToLocalDate(aanvrager.aowData.berekendeAowDatum)
        : mapStringToLocalDate(aanvrager.aowData.aowDatum)
      : null,
    geboortedatum: mapStringToLocalDate(aanvrager.geboortedatum),
    hasAanvrager2: hasAanvrager2,
    soortLoonverklaring: hasValue(aanvrager.soortLoonverklaring) ? aanvrager.soortLoonverklaring : null
  };
};
const createExtraInfoAanvrager1 = (data: InkomenEnFiscusDlEntry): ExtraAanvragerInfo =>
  createExtraInfo(data.aanvrager1, hasValue(data.aanvrager2));
const createExtraInfoAanvrager2 = (data: InkomenEnFiscusDlEntry): ExtraAanvragerInfo =>
  createExtraInfo(data.aanvrager2, hasValue(data.aanvrager2));

const mapLoondienstToInkomensverleden = createMapToUi(inkomensverledenSchema).from<Loondienst>({
  jaar: v => v.inkomenFlexibelJaar,
  resultaat1: v => v.inkomenFlexibelJaarBedrag,
  resultaat2: v => v.inkomenFlexibelJaarMin1Bedrag,
  resultaat3: v => v.inkomenFlexibelJaarMin2Bedrag,
  gemiddeld: v => {
    const inkomenFlexibelJaarBedrag = v.inkomenFlexibelJaarBedrag ? v.inkomenFlexibelJaarBedrag : null;

    const gemiddelde =
      !inkomenFlexibelJaarBedrag && !v.inkomenFlexibelJaarMin1Bedrag && !v.inkomenFlexibelJaarMin2Bedrag
        ? null
        : ((inkomenFlexibelJaarBedrag || 0) +
            (v.inkomenFlexibelJaarMin1Bedrag || 0) +
            (v.inkomenFlexibelJaarMin2Bedrag || 0)) /
          3;

    return hasValue(gemiddelde) && hasValue(inkomenFlexibelJaarBedrag) && inkomenFlexibelJaarBedrag < gemiddelde
      ? inkomenFlexibelJaarBedrag
      : gemiddelde;
  }
});

const mapOndernemingToInkomensverleden = createMapToUi(inkomensverledenSchema).from<Onderneming>({
  jaar: v => v.boekjaar,
  resultaat1: v => (v.inkomenScenario ? v.inkomenScenario.waarde : null),
  resultaat2: v => v.inkomenJaarMin1Bedrag,
  resultaat3: v => v.inkomenJaarMin2Bedrag,
  gemiddeld: v => {
    const scenario = v.inkomenScenario ? v.inkomenScenario.waarde : null;

    const gemiddelde =
      !scenario && !v.inkomenJaarMin1Bedrag && !v.inkomenJaarMin2Bedrag
        ? null
        : ((scenario || 0) + (v.inkomenJaarMin1Bedrag || 0) + (v.inkomenJaarMin2Bedrag || 0)) / 3;

    return hasValue(gemiddelde) && hasValue(scenario) && scenario < gemiddelde ? scenario : gemiddelde;
  }
});

const getBrutoSalarisPerJaar = (gekozenPeriode: BrutoSalarisPeriode | null, bedrag: number | null): number | null => {
  if (!bedrag) return null;

  switch (gekozenPeriode) {
    case BrutoSalarisPeriode.PerWeek:
      return bedrag * 52;
    case BrutoSalarisPeriode.Per4Weken:
      return bedrag * 13;
    case BrutoSalarisPeriode.PerMaand:
      return bedrag * 12;
    case BrutoSalarisPeriode.PerJaar:
      return bedrag;
    default:
      return null;
  }
};

const mapRenteInkomensScenario = (
  inkomenScenario: BedragScenario | null,
  berekenDate: BerekenDateType
): scenarioCardInputType[] => {
  const result: scenarioCardInputType[] = [];
  const ingangsDatum = LocalDate.ofYearDay(LocalDate.now().year(), 1);
  const meetellenTot = berekenDate.berekenen
    ? berekenDate.berekendeDatum || LocalDate.now().plusYears(30)
    : berekenDate.datum || LocalDate.now().plusYears(30);
  const scenarioYears = Period.between(ingangsDatum, meetellenTot).years();

  // Vullen van het scenario a.d.h.v. indexeringen (vanaf maand + percentage)
  for (let index = 0; index < scenarioYears; index++) {
    result.push({
      percentage: null,
      bedrag:
        inkomenScenario && inkomenScenario.indexeringen && inkomenScenario.indexeringen.length
          ? inkomenScenario.indexeringen
              .filter(f => f.ingangsmaand != null && Math.ceil(f.ingangsmaand / 12) <= index + 1)
              .sort(function(a, b) {
                return (b.ingangsmaand || 0) - (a.ingangsmaand || 0);
              })
              .map(m => m.bedrag)[0]
          : inkomenScenario?.waarde ?? null
    });
  }
  return result;
};

const mapLoondienst = createMapToUi(inkomenUitArbeidModalSchema)
  .with<ExtraAanvragerInfo>()
  .from<Loondienst>({
    periodeBedragLastChanged: () => false,
    loondienstId: v => v.loondienstId,
    soortDienstverband: v => mapSoortDienstBetrekkingTypeEnum(v.soortDienstBetrekking),
    faseUitzendcontract: v => (v.faseUitzendcontract ? v.faseUitzendcontract.toString() : null),
    aantalUrenPerWeek: v => v.aantalContracturenPerWeek,
    datumInDienst: v => mapStringToLocalDate(v.dienstverbandAanvangsdatum),
    einddatumContract: v => mapStringToLocalDate(v.contractEinddatum),
    inkomenMeetellenTot: (v, x) =>
      mapBerekenDate(
        v.dienstverbandEinddatum,
        isSameDate(v.dienstverbandEinddatum, x.aowdatum?.toJSON() ?? null),
        x.aowdatum?.toJSON()
      ),
    beroep: v => prefixWithZero(v.beroeptypeCode, 4),
    beroepsfunctie: v => v.beroepOmschrijving,
    naam: v => v.naamWerkgever,
    adres: v => mapAdresDlNaarUi(v.adresWerkgever),
    werkgeverIsFamilie: v => v.werkgeverIsFamilie,
    buitenlandsInkomen: v => v.buitenlandsInkomen,
    brutoSalaris: v => v.inkomenPeriodeBedrag,
    brutoSalarisPer: v => mapInkomenPeriodeEnum(v.inkomenPeriodeCode),
    brutoSalarisPerJaar: v =>
      getBrutoSalarisPerJaar(
        mapInkomenPeriodeEnum(v.inkomenPeriodeCode),
        hasValue(v.inkomenPeriodeBedrag) ? v.inkomenPeriodeBedrag : null
      ),
    vakantieToeslagBedrag: v => v.vakantiegeldBedrag,
    vakantieToeslagPercentage: v => v.vakantiegeldPercentage,
    dertiendeMaandToggle: v => hasValue(v.maand13Bedrag) && v.maand13Bedrag > 0,
    dertiendeMaand: v => v.maand13Bedrag,
    vasteEindejaarsuitkering: v => v.gratificatieBedrag,
    onregelmatigheidstoeslag: v => v.onregelmatigheidstoeslagBedrag,
    inkomstentoeslag: v => v.inkomstenToeslagBedrag,
    vergoedingBeslaglegging: v => v.vebBedrag,
    structureelOverwerk: v => v.overwerkBedrag,
    ingangsdatumStructureelOverwerk: v => mapStringToLocalDate(v.overwerkAanvangsdatum),
    einddatumStructureelOverwerk: v => mapStringToLocalDate(v.overwerkEinddatum),
    provisie: v => v.provisieBedrag,
    vastFlexibelBudget: v => v.vastFlexibelBudgetBedrag,
    ingangsdatumProvisie: v => mapStringToLocalDate(v.provisieAanvangsdatum),
    einddatumProvisie: v => mapStringToLocalDate(v.provisieEinddatum),
    totaalBruto: v => (v.inkomenScenario ? v.inkomenScenario.waarde : null),
    inkomensverleden: v => mapLoondienstToInkomensverleden(v),
    toetsinkomenPerspectiefVerklaring: v =>
      v.soortDienstBetrekking === SoortDienstBetrekkingType.FlexibeleArbeidsrelatieMetPerspectiefverklaring
        ? v.toetsinkomenPerspectiefverklaring
        : null,
    jaar1: v => v.percentageLoondoorbetalingAoJaar1,
    jaar2: v => v.percentageLoondoorbetalingAoJaar2,
    scenario: (v, x) =>
      mapRenteInkomensScenario(
        v.inkomenScenario,
        mapBerekenDate(
          v.dienstverbandEinddatum,
          isSameDate(v.dienstverbandEinddatum, x.aowdatum?.toJSON() ?? null),
          x.aowdatum?.toJSON()
        )
      ),
    geboortedatum: (_, x) => x.geboortedatum
  });

const mapScenarioInstellingen = createMapToUi(scenarioInstellingenSchema)
  .with<ExtraAanvragerInfo>()
  .from<Onderneming | InkomstenBox3>({
    geboortedatum: (_, x) => x.geboortedatum,
    eindeBijArbeidsongeschiktheid: v => v.eindeBijAo,
    regelingArbeidsongeschiktheid: v => v.resultaatVdc,
    eindeWerkloosheid: v => v.eindeBijWw,
    overgangBijOverlijden: v => v.overgangOpPartner,
    percentage: v => v.overgangOpPartnerPercentage,
    einddatum: (v, x) => mapStringToLocalDate(v.overgangOpPartnerEinddatum) || x.aowdatum,
    leeftijd: v => v.overgangOpPartnerEindleeftijd,
    hasAanvrager2: (_, x) => x.hasAanvrager2
  });

const mapOndernemingToOverigeWerkzaamheden = createMapToUi(overigeWerkzaamhedenModalSchema)
  .with<ExtraAanvragerInfo>()
  .from<Onderneming>({
    inkomenMeetellenTot: (v, x) =>
      mapBerekenDate(
        v.eindeOndernemingDatum,
        isSameDate(v.eindeOndernemingDatum, x.aowdatum?.toJSON() ?? null),
        x.aowdatum?.toJSON()
      ),
    scenarioInstellingen: (v, x) => mapScenarioInstellingen(x)(v),
    inkomensverleden: v => mapOndernemingToInkomensverleden(v),
    scenario: (v, x) =>
      mapRenteInkomensScenario(
        v.inkomenScenario,
        mapBerekenDate(
          v.eindeOndernemingDatum,
          isSameDate(v.eindeOndernemingDatum, x.aowdatum?.toJSON() ?? null),
          x.aowdatum?.toJSON()
        )
      ),
    omschrijving: x => x.soortOverigeInkomsten
  });

const mapOndernemingToInkomstenAanmerkelijkBelang = createMapToUi(inkomstenAanmerkelijkBelangModalSchema)
  .with<ExtraAanvragerInfo>()
  .from<Onderneming>({
    inkomenMeetellenTot: (v, x) =>
      mapBerekenDate(
        v.eindeOndernemingDatum,
        isSameDate(v.eindeOndernemingDatum, x.aowdatum?.toJSON() ?? null),
        x.aowdatum?.toJSON()
      ),
    scenarioInstellingen: (v, x) => mapScenarioInstellingen(x)(v),
    inkomensverleden: v => mapOndernemingToInkomensverleden(v),
    scenario: (v, x) =>
      mapRenteInkomensScenario(
        v.inkomenScenario,
        mapBerekenDate(
          v.eindeOndernemingDatum,
          isSameDate(v.eindeOndernemingDatum, x.aowdatum?.toJSON() ?? null),
          x.aowdatum?.toJSON()
        )
      )
  });

const mapInkomstenBox3ToInkomstenUitBeleggingen = createMapToUi(inkomstenUitBeleggingenModalSchema)
  .with<ExtraAanvragerInfo>()
  .from<InkomstenBox3>({
    eindDatum: v => mapStringToLocalDate(v.einddatum),
    inkomstenUitBeleggingen: v => v.bedrag,
    scenarioInstellingen: (v, x) => mapScenarioInstellingen(x)(v)
  });

const mapInkomstenBox3ToInkomstenVerhuurOnroerendGoed = createMapToUi(inkomstenVerhuurOnroerendGoedModalSchema)
  .with<ExtraAanvragerInfo>()
  .from<InkomstenBox3>({
    eindDatum: v => mapStringToLocalDate(v.einddatum),
    inkomstenVerhuurOnroerendGoed: v => v.bedrag,
    scenarioInstellingen: (v, x) => mapScenarioInstellingen(x)(v)
  });
const bedragOrZero = <T extends { bedrag?: number | null }>(o: T | null | undefined): number => {
  if (!o) return 0;
  return o.bedrag || 0;
};

const mapOverigeInkomstenBox1ToBerekenDate = (
  o: OverigeInkomstenBox1 | null | undefined,
  aowData: AowData | null | undefined
): BerekenDateType => {
  const aowDatum = aowData ? (aowData.aowDatumOvernemen ? aowData.berekendeAowDatum : aowData.aowDatum) : null;
  if (!o) return mapBerekenDate(null, true, aowDatum);

  return mapBerekenDate(o.einddatum, o.einddatumOvernemen || false, aowDatum);
};

const isOverigeInkomstenEnabled = (v: InkomenFiscusAanvrager): boolean =>
  hasValue(v.nabestaandenpensioenNaAow) ||
  hasValue(v.nabestaandenpensioenVooAow) ||
  hasValue(v.teOntvangenAlimentatieExPartner) ||
  hasValue(v.anwUitkering) ||
  hasValue(v.ivaUitkering) ||
  hasValue(v.overigeInkomsten1) ||
  hasValue(v.overigeInkomsten2) ||
  hasValue(v.rwwUitkering) ||
  hasValue(v.wachtgeld) ||
  hasValue(v.wajongUitkering) ||
  hasValue(v.waoUitkeringBlijvend) ||
  hasValue(v.waoUitkeringTijdelijk) ||
  hasValue(v.wazUitkering) ||
  hasValue(v.wgaUitkering) ||
  hasValue(v.wwUitkering);

const mapAanvragerToOverigeInkomsten = createMapToUi(overigeInkomstenModalSchema).from<InkomenFiscusAanvrager>({
  totaal: v =>
    bedragOrZero(v.teOntvangenAlimentatieExPartner) +
      bedragOrZero(v.anwUitkering) +
      bedragOrZero(v.ivaUitkering) +
      bedragOrZero(v.overigeInkomsten1) +
      bedragOrZero(v.overigeInkomsten2) +
      bedragOrZero(v.rwwUitkering) +
      bedragOrZero(v.wachtgeld) +
      bedragOrZero(v.wajongUitkering) +
      bedragOrZero(v.waoUitkeringBlijvend) +
      bedragOrZero(v.waoUitkeringTijdelijk) +
      bedragOrZero(v.wazUitkering) +
      bedragOrZero(v.wgaUitkering) +
      bedragOrZero(v.wwUitkering) +
      (mapStringToLocalDate(v.aowData?.berekendeAowDatum)?.isAfter(LocalDate.now())
        ? v.nabestaandenpensioenVooAow || 0
        : v.nabestaandenpensioenNaAow || 0) || null,

  calculatedDate: v => mapStringToLocalDate(v.aowData && v.aowData.aowDatum ? v.aowData.aowDatum : null),

  nabestaandenpensioenNaAowBedrag: v => v.nabestaandenpensioenNaAow,
  nabestaandenpensioenVoorAowBedrag: v => v.nabestaandenpensioenVooAow,

  alimentatieExBedrag: v => (v.teOntvangenAlimentatieExPartner ? v.teOntvangenAlimentatieExPartner.bedrag : null),
  alimentatieExTotDatum: v => mapOverigeInkomstenBox1ToBerekenDate(v.teOntvangenAlimentatieExPartner, v.aowData),

  anwUitkeringBedrag: v => (v.anwUitkering ? v.anwUitkering.bedrag : null),
  anwUitkeringTotDatum: v => mapOverigeInkomstenBox1ToBerekenDate(v.anwUitkering, v.aowData),

  ivaUitkeringBedrag: v => (v.ivaUitkering ? v.ivaUitkering.bedrag : null),
  ivaUitkeringTotDatum: v => mapOverigeInkomstenBox1ToBerekenDate(v.ivaUitkering, v.aowData),

  overigeInkomsten1Bedrag: v => (v.overigeInkomsten1 ? v.overigeInkomsten1.bedrag : null),
  overigeInkomsten1Omschrijving: v => (v.overigeInkomsten1 ? v.overigeInkomsten1.omschrijving : null),
  overigeInkomsten1TotDatum: v => mapOverigeInkomstenBox1ToBerekenDate(v.overigeInkomsten1, v.aowData),

  overigeInkomsten2Bedrag: v => (v.overigeInkomsten2 ? v.overigeInkomsten2.bedrag : null),
  overigeInkomsten2Omschrijving: v => (v.overigeInkomsten2 ? v.overigeInkomsten2.omschrijving : null),
  overigeInkomsten2TotDatum: v => mapOverigeInkomstenBox1ToBerekenDate(v.overigeInkomsten2, v.aowData),

  rwwUitkeringBedrag: v => (v.rwwUitkering ? v.rwwUitkering.bedrag : null),
  rwwUitkeringTotDatum: v => mapOverigeInkomstenBox1ToBerekenDate(v.rwwUitkering, v.aowData),

  wachtgeldBedrag: v => (v.wachtgeld ? v.wachtgeld.bedrag : null),
  wachtgeldTotDatum: v => mapOverigeInkomstenBox1ToBerekenDate(v.wachtgeld, v.aowData),

  wajongUitkeringBedrag: v => (v.wajongUitkering ? v.wajongUitkering.bedrag : null),
  wajongUitkeringTotDatum: v => mapOverigeInkomstenBox1ToBerekenDate(v.wajongUitkering, v.aowData),

  waoUitkeringBlijvendBedrag: v => (v.waoUitkeringBlijvend ? v.waoUitkeringBlijvend.bedrag : null),
  waoUitkeringBlijvendTotDatum: v => mapOverigeInkomstenBox1ToBerekenDate(v.waoUitkeringBlijvend, v.aowData),

  waoUitkeringTijdelijkBedrag: v => (v.waoUitkeringTijdelijk ? v.waoUitkeringTijdelijk.bedrag : null),
  waoUitkeringTijdelijkTotDatum: v => mapOverigeInkomstenBox1ToBerekenDate(v.waoUitkeringTijdelijk, v.aowData),

  wazUitkeringBedrag: v => (v.wazUitkering ? v.wazUitkering.bedrag : null),
  wazUitkeringTotDatum: v => mapOverigeInkomstenBox1ToBerekenDate(v.wazUitkering, v.aowData),

  wgaUitkeringBedrag: v => (v.wgaUitkering ? v.wgaUitkering.bedrag : null),
  wgaUitkeringTotDatum: v => mapOverigeInkomstenBox1ToBerekenDate(v.wgaUitkering, v.aowData),

  wwUitkeringBedrag: v => (v.wwUitkering ? v.wwUitkering.bedrag : null),
  wwUitkeringTotDatum: v => mapOverigeInkomstenBox1ToBerekenDate(v.wwUitkering, v.aowData)
});

const mapOnderneming = createMapToUi(ondernemingModalSchema)
  .with<ExtraAanvragerInfo>()
  .from<Onderneming>({
    volgnummer: v => v.volgnummer,
    soortOnderneming: v => mapOndernemingEnum(v.soortOndernemingHdn),
    rechtsvorm: v => mapRechtsVormTypeEnum(v.rechtsVorm),
    ingangsdatumOnderneming: v => mapStringToLocalDate(v.ondernemingAanvangsdatum),
    inkomenMeetellenTot: (v, x) =>
      mapBerekenDate(
        v.eindeOndernemingDatum,
        isSameDate(v.eindeOndernemingDatum, x.aowdatum?.toJSON() ?? null),
        x.aowdatum?.toJSON()
      ),
    kvkNummer: v => v.kvkNummer,
    sbiCode: v => v.sbiCode,
    rsinNummer: v => v.rsin,
    dga: v => v.dga,
    aandelenPercentage: v => v.aandelenPercentage,
    verklaringInkomen: v => mapInkomenverklaringOptiesEnum(v.soortInkomenverklaring),
    toetsinkomen: v => v.toetsinkomen,
    aantalOndernemingen: v => v.aantalOndernemingen,
    boi: v => v.boi,
    inkomensverleden: v => mapOndernemingToInkomensverleden(v),
    scenarioInstellingen: (v, x) => mapScenarioInstellingen(x)(v),
    scenario: (v, x) =>
      mapRenteInkomensScenario(
        v.inkomenScenario,
        mapBerekenDate(
          v.eindeOndernemingDatum,
          isSameDate(v.eindeOndernemingDatum, x.aowdatum?.toJSON() ?? null),
          x.aowdatum?.toJSON()
        )
      ),
    dossiernummer: v => v.dossiernummer,
    uitgifteDatum: v =>
      v.uitgiftedatumVerklaringInkomen ? mapStringToLocalDate(v.uitgiftedatumVerklaringInkomen) : null,
    rekenexpert: v => (v.rekenexperttypeCode ? prefixWithZero(v.rekenexperttypeCode) : null)
  });

const mapInkomen = createMapToUi(inkomenSchema)
  .with<ExtraAanvragerInfo>()
  .from<InkomenFiscusAanvrager>({
    inkomstenBox3IsEnabled: v => v.heeftInkomstenBox3,

    brutoSalarisUitDienstverbandIsEnabled: v => v.heeftLoondienst,
    brutoSalarisUitDienstverband: v =>
      v.loondiensten
        ? v.loondiensten
            .sort(sortVolgnummer)
            .reduce((total, ld) => total + (ld.inkomenScenario ? ld.inkomenScenario.waarde || 0 : 0), 0) || null
        : null,

    inkomenUitArbeid: (v, x) => {
      const inkomens = v.loondiensten ? v.loondiensten.sort(sortVolgnummer).map(l => mapLoondienst(x)(l)) : [];

      if (inkomens.length === 0) {
        inkomens.push(mapLoondienst(x)({} as Loondienst));
      }
      return inkomens;
    },

    verklaringInkomen: v => mapLoonverklaringOptiesEnum(v.soortLoonverklaring),
    toetsinkomenUwv: v =>
      hasValue(v.toetsinkomenUwv) &&
      v.toetsinkomenUwv !== 0 &&
      v.soortLoonverklaring === LoonverklaringOpties.UwvDocument
        ? v.toetsinkomenUwv
        : null,

    ondernemingIsEnabled: v => hasValue(v.onderneming),
    onderneming: (v, x) => mapOnderneming(x)(v.onderneming ? v.onderneming : ({} as Onderneming)),

    overigeWerkzaamhedenIsEnabled: v => !!v.overigeWerkzaamheden,
    overigeWerkzaamheden: (v, x) =>
      mapOndernemingToOverigeWerkzaamheden(x)(v.overigeWerkzaamheden || ({} as Onderneming)),

    overigeInkomstenBox1IsEnabled: v => isOverigeInkomstenEnabled(v),
    overigeInkomstenBox1: v => mapAanvragerToOverigeInkomsten(v),

    inkomstenAanmerkelijkBelangIsEnabled: v => !!v.aanmerkelijkBelang,
    inkomstenAanmerkelijkBelang: (v, x) =>
      v.aanmerkelijkBelang
        ? mapOndernemingToInkomstenAanmerkelijkBelang(x)(v.aanmerkelijkBelang)
        : mapOndernemingToInkomstenAanmerkelijkBelang(x)({} as Onderneming),

    inkomstenVerhuurOnroerendGoed: (v, x) =>
      v.inkomstenBox3Verhuur
        ? mapInkomstenBox3ToInkomstenVerhuurOnroerendGoed(x)(v.inkomstenBox3Verhuur)
        : mapInkomstenBox3ToInkomstenVerhuurOnroerendGoed(x)({} as InkomstenBox3),

    inkomstenUitBeleggingen: (v, x) =>
      v.inkomstenBox3Beleggingen
        ? mapInkomstenBox3ToInkomstenUitBeleggingen(x)(v.inkomstenBox3Beleggingen)
        : mapInkomstenBox3ToInkomstenUitBeleggingen(x)({} as InkomstenBox3),

    //todo: nog niet te mappen:
    arbeidsscanRapportnummer: v => v.rapportnummer,
    arbeidsscanScore: v => v.verdiencapaciteitScore,
    arbeidsscanVerdiencapaciteit: v =>
      hasValue(v.toetsinkomenUwv) &&
      v.toetsinkomenUwv !== 0 &&
      v.soortLoonverklaring === LoonverklaringOpties.Arbeidsmarktscan
        ? v.toetsinkomenUwv
        : null
  });

const mapAow = createMapToUi(aowSchema)
  .with<string | null | undefined>()
  .from<AowData>({
    originalBedrag: v => v.aowBedrag,
    bedrag: v => v.aowBedrag,
    ingangsdatum: v => {
      const validDate = v.aowDatum !== "0001-01-01";
      if (validDate) {
        return mapBerekenDate(v.aowDatum, v.aowDatumOvernemen, v.berekendeAowDatum);
      }

      return mapBerekenDate(v.berekendeAowDatum, true, v.berekendeAowDatum);
    },
    korting: v => v.duurkortingAowInJaren,
    geboorteDatum: (_, geboortedatum) => mapStringToLocalDate(geboortedatum)
  });

const mapFiscaleAftrekposten = createMapToUi(fiscaleAftrekpostenModalSchema).from<FiscaleAftrekposten>({
  eindDatum: v => mapStringToLocalDate(v.teBetalenPartnerAlimentatieEinddatum),
  teBetalenAlimentatie: v => v.teBetalenPartnerAlimentatieBedrag
});

const mapFiscaleBijtelposten = createMapToUi(fiscaleBijtelpostenModalSchema).from<FiscaleBijtelposten>({
  eigenBijdrage: v => v.bijdrageAutovdZaakBedrag,
  bijtellingBedraagt: v => v.autovdZaakBijtellingBedrag,
  bijtellingOpInkomen: v =>
    v.autovdZaakBijtellingBedrag
      ? v.bijdrageAutovdZaakBedrag
        ? v.autovdZaakBijtellingBedrag - v.bijdrageAutovdZaakBedrag
        : v.autovdZaakBijtellingBedrag
      : null
});

const mapFiscaliteit = createMapToUi(fiscaliteitSchema).from<InkomenFiscusAanvrager>({
  toggleFiscaleAftrekposten: v =>
    v.fiscaleAftrekposten && hasValue(v.fiscaleAftrekposten.teBetalenPartnerAlimentatieBedrag),
  toggleFiscaleBijtelposten: v => !!v.fiscaleBijtelposten,
  fiscaleAftrekposten: v => mapFiscaleAftrekposten(v.fiscaleAftrekposten),
  fiscaleBijtelposten: v => mapFiscaleBijtelposten(v.fiscaleBijtelposten),
  beleggingskorting: v => (v.heffingskortingen ? v.heffingskortingen.beleggingskortingBedrag : null),
  zelfstandigenaftrek: v => (v.onderneming ? v.onderneming.zelfstandigenaftrek : null),
  meewerkaftrek: v => (v.onderneming ? v.onderneming.meewerkaftrek : null),
  dotatieFiscaleOudedagsReserve: v => (v.onderneming ? v.onderneming.forDotatie : null),
  mkbWinstvrijstelling: v => (v.onderneming ? v.onderneming.mkbWinstvrijstelling : null)
});

const mapSociaal = createMapToUi(sociaalSchema).from<InkomenFiscusAanvrager>({
  feitelijkArbeidsverleden: v => v.arbeidsverledenInJaren,
  fictiefArbeidsverleden: v =>
    v.geboortedatum ? Math.max(0, 1998 - (mapStringToLocalDate(v.geboortedatum).year() + 18)) : null,
  voldoetJareneis: v => v.jarenEis,
  voldoetWekeneis: v => v.wekenEis
});

const mapFiscaleVerdeling = createMapToUi(fiscaleVerdelingSchema).from<InkomenEnFiscusDlEntry>({
  aanvrager1: v => (v.aanvrager1 ? v.aanvrager1.fiscaleVerdelingPercentage : null),
  aanvrager2: v => (v.aanvrager2 ? v.aanvrager2.fiscaleVerdelingPercentage : null),
  optimaleVerdeling: v => v.optimaleFiscaleVerdeling
});

const mapInkomenEnFiscus = createMapToUi(inkomenEnFiscusSchema).from<InkomenEnFiscusDlEntry>({
  aanvrager1KlantId: v => (v.aanvrager1 ? v.aanvrager1.klantId : null),
  aanvrager2KlantId: v => (v.aanvrager2 ? v.aanvrager2.klantId : null),
  hasAanvrager2: v => hasValue(v.aanvrager2),
  aanvrager1Inkomen: v => mapInkomen(createExtraInfoAanvrager1(v))(v.aanvrager1),
  aanvrager2Inkomen: v => mapInkomen(createExtraInfoAanvrager2(v))(v.aanvrager2),
  aanvrager1Aow: v => (v.aanvrager1 ? mapAow(v.aanvrager1.geboortedatum)(v.aanvrager1.aowData) : null),
  aanvrager2Aow: v => (v.aanvrager2 ? mapAow(v.aanvrager2.geboortedatum)(v.aanvrager2.aowData) : null),
  aanvrager1Fiscaliteit: v => mapFiscaliteit(v.aanvrager1),
  aanvrager2Fiscaliteit: v => mapFiscaliteit(v.aanvrager2),
  aanvrager1Sociaal: v => mapSociaal(v.aanvrager1),
  aanvrager2Sociaal: v => mapSociaal(v.aanvrager2),
  fiscaleVerdeling: v => mapFiscaleVerdeling(v)
});

export function mapInkomenEnFiscusDlToUi(
  inkomenEnFiscusId: string,
  data: InkomenEnFiscusDlOutput
): InkomenEnFiscusState | null {
  const inkomenEnFiscus = data && data.isValid && data.inkomenFiscusen ? data.inkomenFiscusen[inkomenEnFiscusId] : null;

  if (inkomenEnFiscus) {
    return mapInkomenEnFiscus(inkomenEnFiscus);
  }

  return null;
}
