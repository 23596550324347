import * as Yup from "yup";

export const scenarioVanSchema = Yup.object({
  aanvrager1Geselecteerd: Yup.boolean()
    .nullable()
    .default(true),
  aanvrager2Geselecteerd: Yup.boolean()
    .nullable()
    .default(true),

  beiden: Yup.boolean()
    .nullable()
    .default(null)
});
export type ScenarioVanType = Yup.InferType<typeof scenarioVanSchema>;
