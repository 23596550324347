import { useFormikContext } from "formik";
import React, { ReactElement, useEffect } from "react";
import { useRequestInit, LoadingSpinner, ErrorPage } from "adviesbox-shared";
import { InvalidAdviesboxResultErrorPage } from "../shared/components/fetch-error-page/fetch-error-page";
import { RapportageSamenstellenState } from "../shared/components/rapportage/infra/rapportage-samenstellen-types";
import { RapportageStructuur } from "../shared/components/rapportage/rapportage-structuur";
import { useAdviesboxData } from "../shared/hooks/use-adviesbox-data";
import { RouteParams } from "../shared/paramrouting/paramrouting-context";
import {
  mapRapportStructuurToUi,
  mapRapportStructuurToDl,
  mapDlTargetToRapportStructuur
} from "./infra/map-rapportage-samenstellen-dl-to-ui";
import { useParams } from "react-router-dom";

export const getDossierId = (p: RouteParams): string => p.adviesdossier;

export const RapportageStructuurAjax = (): ReactElement => {
  const { values, setFieldValue } = useFormikContext<RapportageSamenstellenState>();
  const { adviesdossier: adviesdossierId } = useParams() as { adviesdossier?: string };
  const voorstelId = values.rapportSamenstellenOpBasisVan;
  const structuurId = values.rapportstructuur;
  const { settings } = useRequestInit();

  // gebruik het adviesdossierId uit de params (url) indien 'rapportSamenstellenOpBasisVan' leeg is:
  const url = voorstelId
    ? `${settings.rapportageOrigin}/Voorstellen/${voorstelId}/RapportSamenstellen/Structuren${structuurId}`
    : `${settings.rapportageOrigin}/Adviesdossiers/${adviesdossierId}/RapportSamenstellen/Structuren${structuurId}`;

  const { data, error, loading } = useAdviesboxData(
    () => url,
    getDossierId,
    mapRapportStructuurToUi,
    mapRapportStructuurToDl,
    mapDlTargetToRapportStructuur
  );

  useEffect(() => {
    setFieldValue("selectedStructuur", data);
  }, [data, setFieldValue]);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <ErrorPage error={error} data={JSON.stringify(data)} />;
  }

  if (!data) {
    return <InvalidAdviesboxResultErrorPage />;
  }

  return (
    <RapportageStructuur
      naam={"rapport-structuur"}
      elementen={values.selectedStructuur?.elementen ?? []}
      parent={`selectedStructuur`}
      parentKey={0}
    />
  );
};
