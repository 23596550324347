import { LabelValuePairs } from "adviesbox-shared/utils/types";
import {
  TaxatieGebruikOptions,
  TaxatieToekomstigGebruikOptions,
  TaxatieOpdrachtgeverOptions
} from "../../.generated/forms/formstypes";

export const gebruikMomenteelOpties: LabelValuePairs = [
  {
    label: "Woning staat leeg",
    value: TaxatieGebruikOptions.Leegstaand
  },
  {
    label: "Woning wordt volledig bewoond door eigenaar",
    value: TaxatieGebruikOptions.VolledigBewoondDoorEigenaar
  },
  {
    label: "Woning wordt deels bewoond door eigenaar ander deel door anderen",
    value: TaxatieGebruikOptions.DeelsBewoonDoorEigenaarAndereDeelDoorAnderen
  },
  {
    label: "Woning wordt geheel bewoond door anderen",
    value: TaxatieGebruikOptions.GeheelBewoondDoorAnderen
  },
  {
    label: "Woning is als geheel verhuurd",
    value: TaxatieGebruikOptions.GeheelVerhuurd
  },
  {
    label: "Woning wordt deels bewoond door eigenaar ander deel staat leeg",
    value: TaxatieGebruikOptions.DeelsBewoondDeelsLeeg
  },
  {
    label: "Woning is deels verhuurd ander deel staat leeg",
    value: TaxatieGebruikOptions.DeelsVerhuurdDeelsLeeg
  },
  {
    label: "Onbekend",
    value: TaxatieGebruikOptions.Onbekend
  }
];

export const gebruikToekomstOpties: LabelValuePairs = [
  {
    label: "Ben eigenaar en blijf in woning wonen",
    value: TaxatieToekomstigGebruikOptions.BenEigenaarEnBlijfInWoning
  },
  {
    label: "Ben eigenaar en blijf woning verhuren",
    value: TaxatieToekomstigGebruikOptions.BenEigenaarEnBlijfWoningVerhuren
  },
  {
    label: "Ben eigenaar en wil woning verhuren",
    value: TaxatieToekomstigGebruikOptions.BenEigenaarEnWilWoningVerhuren
  },
  {
    label: "Woont in woning en wil woning kopen",
    value: TaxatieToekomstigGebruikOptions.WoontInWoningEnWilWoningKopen
  },
  {
    label: "Wil woning kopen om er zelf in te gaan wonen",
    value: TaxatieToekomstigGebruikOptions.WilWoningKopenVoorWonen
  },
  {
    label: "Wil woning kopen om woning te gaan verhuren",
    value: TaxatieToekomstigGebruikOptions.WilWoningKopenVoorVerhuren
  },
  {
    label: "Anders...",
    value: TaxatieToekomstigGebruikOptions.Anders
  }
];

export const opdrachtgeverOpties: LabelValuePairs = [
  {
    label: "Niet van toepassing",
    value: TaxatieOpdrachtgeverOptions.Nvt
  },
  {
    label: "De huidige eigenaar",
    value: TaxatieOpdrachtgeverOptions.DeHuidigeEigenaar
  },
  {
    label: "De huidige eigenaar en verhuurder en bewoner",
    value: TaxatieOpdrachtgeverOptions.DeHuidigeEigenaarEnVerhuurderEnBewoner
  },
  {
    label: "Bewoner en koper voor eigen bewoning",
    value: TaxatieOpdrachtgeverOptions.BewonerEnKoperVoorEigenBewoning
  },
  {
    label: "Koper voor eigen bewoning",
    value: TaxatieOpdrachtgeverOptions.KoperVoorEigenBewoning
  },
  {
    label: "Anders",
    value: TaxatieOpdrachtgeverOptions.Anders
  }
];
