import { LabeledSelectInput, LabeledTextInput } from "adviesbox-shared";
import { connect, useFormikContext } from "formik";
import React, { ReactElement } from "react";
import { Notarissen } from "../../.generated/instellingen-forms/instellingen-formstypes";
import { AdresInput } from "../../shared/components/adres/adres-input";
import { LabelValuePairs } from "../../shared/types";
import { getBankgarantieTextResources } from "../infra/bankgarantie-resources";
import { BankgarantieState } from "../infra/bankgarantie-schema";

type NotarisProps = {
  notarissen: Notarissen | null;
};

const Notaris = ({ notarissen }: NotarisProps): ReactElement => {
  const formik = useFormikContext<BankgarantieState>();
  const values = formik.values;
  const notarisOptions: LabelValuePairs = [{ label: "Anders", value: "Anders" }];

  notarissen?.partijen?.forEach(x => {
    if (x.notariskantoorId !== null) {
      notarisOptions.push({ label: x.kantoornaam, value: x.notariskantoorId });
    }
  });

  return (
    <>
      {notarisOptions.length > 1 && (
        <LabeledSelectInput
          caption="Notariskantoor"
          name="notaris.gekozenNotariskantoorId"
          options={notarisOptions}
          readonly={notarissen?.partijen?.length === 0}
          tooltip={getBankgarantieTextResources("tooltipNotarisKantoor")}
        />
      )}
      {values.notaris.gekozenNotariskantoorId === "Anders" && (
        <LabeledTextInput caption="Naam notariskantoor" name="notaris.kantoornaam" />
      )}
      {notarisOptions.length === 1 && values.notaris.gekozenNotariskantoorId !== "Anders" && (
        <LabeledTextInput caption="Notariskantoor" name="notaris.kantoornaam" />
      )}
      <AdresInput
        name="notaris.adres"
        readonly={notarisOptions.length === 1 ? false : values.notaris.gekozenNotariskantoorId !== "Anders"}
        metLand={false}
      />
      <LabeledTextInput
        caption="Telefoonnummer"
        name="notaris.telefoonnummer"
        readonly={notarisOptions.length === 1 ? false : values.notaris.gekozenNotariskantoorId !== "Anders"}
      />
    </>
  );
};

export default connect<NotarisProps, BankgarantieState>(Notaris);
