export enum RapportageElementSoort {
  Onderdeel = "Onderdeel",
  Hoofdstuk = "Hoofdstuk",
  Koptekst = "Koptekst",
  Tekstblok = "Tekstblok",
  Grafiek = "Grafiek",
  Tabel = "Tabel"
}

export type RapportageElementenType = {
  code: number;
  naam: string;
  geselecteerd: boolean;
  soortElement: RapportageElementSoort;
  volgnummer: number;
  elementen: RapportageElementenType[];
};

export const defaultElementen = [
  {
    code: 1,
    naam: "Voorblad",
    geselecteerd: false,
    soortElement: RapportageElementSoort.Onderdeel,
    volgnummer: 1,
    elementen: []
  },
  {
    code: 2,
    naam: "Inhoudsopgave",
    geselecteerd: true,
    soortElement: RapportageElementSoort.Onderdeel,
    volgnummer: 2,
    elementen: []
  },
  {
    code: 3,
    naam: "Hoofdstukken",
    geselecteerd: true,
    soortElement: RapportageElementSoort.Onderdeel,
    volgnummer: 3,
    elementen: [
      {
        code: 301,
        naam: "Inleiding",
        geselecteerd: true,
        soortElement: RapportageElementSoort.Hoofdstuk,
        volgnummer: 1,
        elementen: []
      },
      {
        code: 302,
        naam: "Uw huidige situatie",
        geselecteerd: false,
        soortElement: RapportageElementSoort.Hoofdstuk,
        volgnummer: 1,
        elementen: [
          {
            code: 30201,
            naam: "Belangrijke financiïele gebeurtenissen",
            geselecteerd: true,
            soortElement: RapportageElementSoort.Hoofdstuk,
            volgnummer: 1,
            elementen: []
          },
          {
            code: 30202,
            naam: "Uw gegevens",
            geselecteerd: true,
            soortElement: RapportageElementSoort.Hoofdstuk,
            volgnummer: 2,
            elementen: []
          },
          {
            code: 30203,
            naam: "Uw huidige lastenoverzicht",
            geselecteerd: true,
            soortElement: RapportageElementSoort.Hoofdstuk,
            volgnummer: 3,
            elementen: []
          },
          {
            code: 30204,
            naam: "Analyse van uw financiïele risico's",
            geselecteerd: true,
            soortElement: RapportageElementSoort.Hoofdstuk,
            volgnummer: 4,
            elementen: []
          },
          {
            code: 30205,
            naam: "Uw huidige netto besteedbaar inkomen",
            geselecteerd: true,
            soortElement: RapportageElementSoort.Hoofdstuk,
            volgnummer: 5,
            elementen: []
          },
          {
            code: 30206,
            naam: "Ontwikkeling van uw vermogen",
            geselecteerd: true,
            soortElement: RapportageElementSoort.Hoofdstuk,
            volgnummer: 6,
            elementen: []
          }
        ]
      },
      {
        code: 303,
        naam: "Uw wensen en doelstellingen",
        geselecteerd: true,
        soortElement: RapportageElementSoort.Hoofdstuk,
        volgnummer: 1,
        elementen: [
          {
            code: 30301,
            naam: "Aanleiding",
            geselecteerd: true,
            soortElement: RapportageElementSoort.Hoofdstuk,
            volgnummer: 1,
            elementen: []
          },
          {
            code: 30302,
            naam: "Dienstverlening",
            geselecteerd: true,
            soortElement: RapportageElementSoort.Hoofdstuk,
            volgnummer: 2,
            elementen: []
          }
        ]
      },
      {
        code: 304,
        naam: "Uw benodigde financiering",
        geselecteerd: false,
        soortElement: RapportageElementSoort.Hoofdstuk,
        volgnummer: 1,
        elementen: [
          {
            code: 30401,
            naam: "Financieringsopzet",
            geselecteerd: true,
            soortElement: RapportageElementSoort.Hoofdstuk,
            volgnummer: 1,
            elementen: []
          },
          {
            code: 30402,
            naam: "Overwaardespecificatie",
            geselecteerd: true,
            soortElement: RapportageElementSoort.Hoofdstuk,
            volgnummer: 2,
            elementen: []
          },
          {
            code: 30403,
            naam: "Restschuldspecificatie",
            geselecteerd: true,
            soortElement: RapportageElementSoort.Hoofdstuk,
            volgnummer: 3,
            elementen: []
          },
          {
            code: 30404,
            naam: "Verbouwingsspecificatie",
            geselecteerd: true,
            soortElement: RapportageElementSoort.Hoofdstuk,
            volgnummer: 4,
            elementen: []
          },
          {
            code: 30405,
            naam: "Fiscale aftrek van de financiering",
            geselecteerd: true,
            soortElement: RapportageElementSoort.Hoofdstuk,
            volgnummer: 5,
            elementen: []
          },
          {
            code: 30406,
            naam: "Oversluitenindicatie",
            geselecteerd: true,
            soortElement: RapportageElementSoort.Hoofdstuk,
            volgnummer: 6,
            elementen: []
          }
        ]
      },
      {
        code: 305,
        naam: "Ons advies",
        geselecteerd: true,
        soortElement: RapportageElementSoort.Hoofdstuk,
        volgnummer: 1,
        elementen: [
          {
            code: 30501,
            naam: "Belangrijke financiïele gebeurtenissen",
            geselecteerd: true,
            soortElement: RapportageElementSoort.Hoofdstuk,
            volgnummer: 1,
            elementen: []
          },
          {
            code: 30502,
            naam: "Adviessamenvatting",
            geselecteerd: true,
            soortElement: RapportageElementSoort.Hoofdstuk,
            volgnummer: 2,
            elementen: []
          },
          {
            code: 30503,
            naam: "Samenvatting van ons advies",
            geselecteerd: true,
            soortElement: RapportageElementSoort.Hoofdstuk,
            volgnummer: 3,
            elementen: []
          },
          {
            code: 30504,
            naam: "Uw financiïele risico's",
            geselecteerd: true,
            soortElement: RapportageElementSoort.Hoofdstuk,
            volgnummer: 4,
            elementen: []
          },
          {
            code: 30505,
            naam: "Overzicht geadviseerde producten",
            geselecteerd: true,
            soortElement: RapportageElementSoort.Hoofdstuk,
            volgnummer: 5,
            elementen: []
          },
          {
            code: 30506,
            naam: "Geadviseerde producten",
            geselecteerd: true,
            soortElement: RapportageElementSoort.Hoofdstuk,
            volgnummer: 6,
            elementen: []
          },
          {
            code: 30507,
            naam: "Overzicht van uw nieuwe lasten",
            geselecteerd: true,
            soortElement: RapportageElementSoort.Hoofdstuk,
            volgnummer: 7,
            elementen: []
          },
          {
            code: 30508,
            naam: "Uw nieuwe netto besteedbaar inkomen",
            geselecteerd: true,
            soortElement: RapportageElementSoort.Hoofdstuk,
            volgnummer: 8,
            elementen: []
          },
          {
            code: 30509,
            naam: "Ontwikkeling van uw vermogen",
            geselecteerd: true,
            soortElement: RapportageElementSoort.Hoofdstuk,
            volgnummer: 9,
            elementen: []
          },
          {
            code: 30510,
            naam: "Overzicht vergeleken voorstellen",
            geselecteerd: true,
            soortElement: RapportageElementSoort.Hoofdstuk,
            volgnummer: 10,
            elementen: []
          }
        ]
      },
      {
        code: 306,
        naam: "Toelichting bij ons advies",
        geselecteerd: true,
        soortElement: RapportageElementSoort.Hoofdstuk,
        volgnummer: 1,
        elementen: [
          {
            code: 30601,
            naam: "Algemeen",
            geselecteerd: true,
            soortElement: RapportageElementSoort.Hoofdstuk,
            volgnummer: 1,
            elementen: []
          },
          {
            code: 30602,
            naam: "Uw hypotheek",
            geselecteerd: true,
            soortElement: RapportageElementSoort.Hoofdstuk,
            volgnummer: 2,
            elementen: []
          },
          {
            code: 30603,
            naam: "Wat gebeurt er bij overlijden?",
            geselecteerd: true,
            soortElement: RapportageElementSoort.Hoofdstuk,
            volgnummer: 3,
            elementen: []
          },
          {
            code: 30604,
            naam: "Wat gebeurt er bij arbeidsongeschiktheid?",
            geselecteerd: true,
            soortElement: RapportageElementSoort.Hoofdstuk,
            volgnummer: 4,
            elementen: []
          },
          {
            code: 30605,
            naam: "Wat gebeurt er bij werkloosheid?",
            geselecteerd: true,
            soortElement: RapportageElementSoort.Hoofdstuk,
            volgnummer: 5,
            elementen: []
          },
          {
            code: 30606,
            naam: "Hoe ziet uw pensioen eruit?",
            geselecteerd: true,
            soortElement: RapportageElementSoort.Hoofdstuk,
            volgnummer: 6,
            elementen: []
          },
          {
            code: 30607,
            naam: "Uw vermogensopbouw",
            geselecteerd: true,
            soortElement: RapportageElementSoort.Hoofdstuk,
            volgnummer: 7,
            elementen: []
          },
          {
            code: 30608,
            naam: "Uw consumptieve kredieten",
            geselecteerd: true,
            soortElement: RapportageElementSoort.Hoofdstuk,
            volgnummer: 8,
            elementen: []
          }
        ]
      },
      {
        code: 307,
        naam: "Akkoordverklaring",
        geselecteerd: true,
        soortElement: RapportageElementSoort.Hoofdstuk,
        volgnummer: 1,
        elementen: []
      }
    ]
  },
  {
    code: 4,
    naam: "Bijlagen",
    geselecteerd: false,
    soortElement: RapportageElementSoort.Onderdeel,
    volgnummer: 4,
    elementen: [
      {
        code: 401,
        naam: "Specificatie van uw inkomen en uitgaven ",
        geselecteerd: true,
        soortElement: RapportageElementSoort.Hoofdstuk,
        volgnummer: 1,
        elementen: []
      },
      {
        code: 402,
        naam: "Specificatie van uw vermogen",
        geselecteerd: true,
        soortElement: RapportageElementSoort.Hoofdstuk,
        volgnummer: 2,
        elementen: []
      },
      {
        code: 403,
        naam: "Specificatie van uw belastbaar inkomen",
        geselecteerd: true,
        soortElement: RapportageElementSoort.Hoofdstuk,
        volgnummer: 3,
        elementen: []
      },
      {
        code: 404,
        naam: "Overzicht van de hypotheekvormen",
        geselecteerd: true,
        soortElement: RapportageElementSoort.Hoofdstuk,
        volgnummer: 4,
        elementen: []
      },
      {
        code: 405,
        naam: "Toelichting op pensioen",
        geselecteerd: true,
        soortElement: RapportageElementSoort.Hoofdstuk,
        volgnummer: 5,
        elementen: []
      },
      {
        code: 406,
        naam: "Toelichting op sociale voorzieningen",
        geselecteerd: true,
        soortElement: RapportageElementSoort.Hoofdstuk,
        volgnummer: 6,
        elementen: []
      },
      {
        code: 407,
        naam: "De uitgereikte documenten",
        geselecteerd: true,
        soortElement: RapportageElementSoort.Hoofdstuk,
        volgnummer: 7,
        elementen: []
      },
      {
        code: 408,
        naam: "Te ontvangen documenten",
        geselecteerd: true,
        soortElement: RapportageElementSoort.Hoofdstuk,
        volgnummer: 8,
        elementen: []
      },
      {
        code: 409,
        naam: "Uw klantprofiel",
        geselecteerd: true,
        soortElement: RapportageElementSoort.Hoofdstuk,
        volgnummer: 9,
        elementen: []
      },
      {
        code: 410,
        naam: "Wijzigingsverklaring",
        geselecteerd: true,
        soortElement: RapportageElementSoort.Hoofdstuk,
        volgnummer: 10,
        elementen: []
      },
      {
        code: 411,
        naam: "Disclaimer",
        geselecteerd: true,
        soortElement: RapportageElementSoort.Hoofdstuk,
        volgnummer: 11,
        elementen: []
      }
    ]
  }
];
