import React, { ReactElement } from "react";
import { Field } from "formik";
import classes from "./product-wijzigingen-meenemen-button.module.scss";
import { Icon } from "adviesbox-shared";

type ProductWijzigingenMeenemenButtonProps = {
  name: string;
};

export const ProductWijzigingenMeenemenButton = ({ name }: ProductWijzigingenMeenemenButtonProps): ReactElement => {
  return (
    <div>
      <Field
        name={name}
        className={classes.button_hidden}
        as={"input"}
        type={"checkbox"}
        id={`${name}-button`}
        data-testid={`${name}-button`}
      />
      <label htmlFor={`${name}-button`} className={`${classes.button_wrapper}`}>
        <label className={classes.button} htmlFor={`${name}-button`}>
          <Icon name="checkmark" className={classes.button_icon}></Icon>
        </label>
        <span className={`${classes.button_text}`}>Doorlopende product(en) overschrijven</span>
      </label>
    </div>
  );
};

/* istanbul ignore next */
if (process.env.NODE_ENV !== "production")
  ProductWijzigingenMeenemenButton.displayName = "ProductWijzigingenMeenemenButton";
