import { FormikHelpers } from "formik";

export function bindSaveFunction<T>(
  setFieldValue: FormikHelpers<T>["setFieldValue"]
): <T2 = any>(
  fieldName: string,
  callback?: (result: T2, setFieldValue: FormikHelpers<T>["setFieldValue"]) => void
) => (result: T2) => void {
  return function createSaveFunction<T2>(
    fieldName: string,
    callback?: (result: T2, setFieldValue: FormikHelpers<T>["setFieldValue"]) => void
  ): (result: T2) => void {
    return function(result: T2): void {
      setFieldValue(fieldName, result);
      if (callback) callback(result, setFieldValue);
    };
  };
}
