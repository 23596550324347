import { InkomenLoondienstInput, InkomenOutput } from "../../.generated/forms/formstypes";
import {
  createISWAsyncSideEffect,
  createISWSideEffect,
  initISWAsyncSideEffect,
  initISWSideEffect
} from "../../shared/components/isw-side-effects/create-isw-helpers";
import { createMapToDl } from "../../shared/utils/create-map-to-dl";
import { mapBrutoSalarisPeriode, mapInkomenUitArbeidOutput } from "../infra/determine-inkomen-en-fiscus-side-effects";
import {
  BrutoSalarisPeriode,
  InkomenUitArbeidApiControllerAction,
  InkomenUitArbeidModalType,
  inkomenUitArbeidModalValuesSchema,
  InkomenUitArbeidModalValuesType,
  SoortDienstverband
} from "../infra/inkomen-en-fiscus-schema";
import { determineGemiddeldInkomensVerleden } from "../infra/shared-components/determine-inkomensverleden-side-effects";

export const mapInkomenUitArbeidInput = createMapToDl(inkomenUitArbeidModalValuesSchema)
  .with<number>()
  .to<InkomenLoondienstInput>({
    gratificatieBedrag: (v, i) => v.modal[i].vasteEindejaarsuitkering,
    inkomstentoeslag: (v, i) => v.modal[i].inkomstentoeslag,
    maand13: (v, i) => v.modal[i].dertiendeMaandToggle,
    onregelmatigheidstoeslagBedrag: (v, i) => v.modal[i].onregelmatigheidstoeslag,
    overwerkBedrag: (v, i) => v.modal[i].structureelOverwerk,
    provisieBedrag: (v, i) => v.modal[i].provisie,
    vakantiegeldPercentage: (v, i) => v.modal[i].vakantieToeslagPercentage,
    vastFlexibelBudgetBedrag: (v, i) => v.modal[i].vastFlexibelBudget,
    vebBedrag: (v, i) => v.modal[i].vergoedingBeslaglegging
  });

type Context = {
  selected: number,
  setFieldValue: (fieldname: string, value: any) => void
};

export const totaalBrutoAsync = createISWAsyncSideEffect<InkomenUitArbeidModalValuesType, Context>(
  async ({ draft, settings, fetchData, context }) => {
    if (!draft.modal[context.selected].totaalBruto) {
      mapInkomenUitArbeidOutput(draft.modal[context.selected], {} as InkomenOutput);
      return;
    }

    const salarisPer = mapBrutoSalarisPeriode(draft.modal[context.selected].brutoSalarisPer);
    const json = await fetchData<InkomenOutput, InkomenLoondienstInput>({
      url: `${settings.klantdossiersFormsOrigin}/InkomenFiscus/${InkomenUitArbeidApiControllerAction.JaarNaarPeriode}/${draft.modal[context.selected].totaalBruto
        }/${salarisPer}`,
      body: mapInkomenUitArbeidInput(context.selected)
    });
    mapInkomenUitArbeidOutput(draft.modal[context.selected], json);
  }
);

export const brutoSalarisAsync = createISWAsyncSideEffect<InkomenUitArbeidModalValuesType, Context>(
  async ({ draft, settings, fetchData, context }) => {
    if (!draft.modal[context.selected].brutoSalaris) {
      mapInkomenUitArbeidOutput(draft.modal[context.selected], {} as InkomenOutput);
      return;
    }

    const salarisPer = mapBrutoSalarisPeriode(draft.modal[context.selected].brutoSalarisPer);
    const json = await fetchData<InkomenOutput, InkomenLoondienstInput>({
      url: `${settings.klantdossiersFormsOrigin}/InkomenFiscus/${InkomenUitArbeidApiControllerAction.PeriodeNaarJaar}/${draft.modal[context.selected].brutoSalaris
        }/${salarisPer}`,
      body: mapInkomenUitArbeidInput(context.selected)
    });
    mapInkomenUitArbeidOutput(draft.modal[context.selected], json);
  }
);

export const calcVakantieToeslagPc = createISWSideEffect<InkomenUitArbeidModalType, Context>((bag): void => {
  const { draft } = bag;
  if (!!draft.brutoSalarisPerJaar && !!draft.vakantieToeslagBedrag) {
    draft.vakantieToeslagPercentage = (draft.vakantieToeslagBedrag / draft.brutoSalarisPerJaar) * 100;
  }
});

export const calcBrutoSalarisPerPeriode = (brutoSalarisPerJaar: number | null, periode: BrutoSalarisPeriode): number | null => {
  if (brutoSalarisPerJaar === null) return null;
  switch (periode) {
    case BrutoSalarisPeriode.Per4Weken: return brutoSalarisPerJaar / 13;
    case BrutoSalarisPeriode.PerJaar: return brutoSalarisPerJaar;
    case BrutoSalarisPeriode.PerMaand: return brutoSalarisPerJaar / 12;
    case BrutoSalarisPeriode.PerWeek: return brutoSalarisPerJaar / 52;
  }
  return null;
}

export const jaarNaarPeriode = (draft: InkomenUitArbeidModalValuesType, context: Context): void => {
  const salarisPer = calcBrutoSalarisPerPeriode(draft.modal[context.selected].brutoSalarisPerJaar, draft.modal[context.selected].brutoSalarisPer);
  if (draft.modal[context.selected].brutoSalaris !== salarisPer) {
    context.setFieldValue(`modal[${context.selected}].brutoSalaris`, salarisPer);
  }
}

export const inkomenUitArbeidModalSideEffects = initISWSideEffect<InkomenUitArbeidModalValuesType, Context>(bag => {
  const { draft, has, subset, context } = bag;

  if (
    draft.modal[context.selected].brutoSalarisPer !== BrutoSalarisPeriode.Geen &&
    has.modal[context.selected].vakantieToeslagBedrag.changed &&
    draft.modal[context.selected].brutoSalarisPerJaar
  ) {
    calcVakantieToeslagPc(subset.modal[context.selected].create());
  }

  if (
    has.modal[context.selected].soortDienstverband.changed &&
    draft.modal[context.selected].soortDienstverband !==
    SoortDienstverband.FlexibeleArbeidsrelatieZonderPerspectiefverklaring
  ) {
    draft.modal[context.selected].faseUitzendcontract = null;
  }

  if (has.modal[context.selected].brutoSalarisPerJaar.changed) {
    jaarNaarPeriode(draft, context);
  }

  determineGemiddeldInkomensVerleden(bag.subset.modal[context.selected].inkomensverleden.create());
});

export const inkomenUitArbeidModalAsyncSideEffects = initISWAsyncSideEffect<InkomenUitArbeidModalValuesType, Context>(
  ({ has, curr, prev, context, runAsync }) => {
    if (curr.modal.length !== prev.modal.length) return;
    const { selected } = context;
    const inkomen = has.modal[selected];

    if (
      curr.modal[selected].brutoSalarisPer !== BrutoSalarisPeriode.Geen &&
      (inkomen.vergoedingBeslaglegging.changed ||
        inkomen.provisie.changed ||
        inkomen.onregelmatigheidstoeslag.changed ||
        inkomen.vakantieToeslagBedrag.changed ||
        inkomen.vakantieToeslagPercentage.changed ||
        inkomen.dertiendeMaandToggle.changed ||
        inkomen.vasteEindejaarsuitkering.changed ||
        inkomen.structureelOverwerk.changed ||
        inkomen.inkomstentoeslag.changed ||
        inkomen.totaalBruto.changed ||
        inkomen.brutoSalaris.changed ||
        inkomen.vastFlexibelBudget.changed ||
        inkomen.brutoSalarisPer.changed)
    ) {
      if (inkomen.totaalBruto.changed) {
        runAsync(totaalBrutoAsync(context));
      } else {
        runAsync(brutoSalarisAsync(context));
      }
    }

  }
);
