import { FiscaleVoortzettingOptions, AflossingsVormType } from "../../.generated/forms/formstypes";
import { premieGegevensSchema } from "../../producten-overzicht/infra/producten-overzicht-schema";
import { PremieGegevensType, SituatieSoort } from "../../producten-overzicht/infra/producten-overzicht-types";
import { AanvragerKeuze } from "../../shared/types";
import { hasValue } from "../../shared/utils/helpers";
import { hypotheekFiscaleRegelingSchema, kapitaalopbouwSchema } from "../infra/hypotheek-schema";
import {
  HypotheekProductSelectieStateType,
  HypotheekProductSelectieVoorstelStateType,
  HypotheekSelectieSamenstellingType,
  HypotheekType,
  HypotheekVormType,
  HypothekenState,
  KapitaalopbouwType
} from "../infra/hypotheek-types";
import { mapProductSelectieToHypotheekProduct } from "../infra/map-hypotheek-selectie";
import { mapSamenstellingLeningdelen } from "./hypotheek-selectie-wijzig-samenstelling";

function nearMatchesToKeyValueArray(nearMatchHypotheekvorm: any): Record<number, number> {
  const nearMatches: Array<number> = [];
  nearMatchHypotheekvorm &&
    nearMatchHypotheekvorm.forEach(
      (v: string) => typeof v !== "undefined" && (nearMatches[parseInt(v.split("_")[0])] = parseInt(v.split("_")[1]))
    );
  return nearMatches;
}

export function mapGenereerLeningdelen(
  leningdeel: HypotheekType,
  nieuweHypotheekVorm: HypotheekVormType,
  maatschappijCode: string,
  labelOmschrijving: string,
  labelCode: number
): HypotheekType {
  const ceiledLeningdeelHoofdsom = {
    bedrag: leningdeel.leningdeelgegevens.leningdeelHoofdsom.bedrag
      ? Math.round(leningdeel.leningdeelgegevens.leningdeelHoofdsom.bedrag)
      : null,
    berekenen: leningdeel.leningdeelgegevens.leningdeelHoofdsom.berekenen,
    berekendBedrag: leningdeel.leningdeelgegevens.leningdeelHoofdsom.berekendBedrag
      ? Math.round(leningdeel.leningdeelgegevens.leningdeelHoofdsom.berekendBedrag)
      : null
  };
  return {
    ...leningdeel,
    leningdeelgegevens: {
      ...leningdeel.leningdeelgegevens,
      leningdeelHoofdsom: ceiledLeningdeelHoofdsom
    },
    verzekerde: {
      ...leningdeel.verzekerde,
      verzekerde: leningdeel.schuldenaars.schuldenaar as AanvragerKeuze
    },
    verzekeringnemers: {
      ...leningdeel.verzekeringnemers,
      verzekeringnemer: leningdeel.schuldenaars.schuldenaar as AanvragerKeuze
    },
    premieGegevens:
      nieuweHypotheekVorm.aflossingsvorm === AflossingsVormType.Spaarrekening
        ? ({
            ...premieGegevensSchema.default(),
            looptijd: leningdeel.product.looptijd
          } as PremieGegevensType)
        : null,
    kapitaalopbouw:
      nieuweHypotheekVorm.aflossingsvorm === AflossingsVormType.Spaarrekening
        ? ({
            ...leningdeel.kapitaalopbouw,
            doelkapitaal1Bedrag: ceiledLeningdeelHoofdsom.bedrag,
            doelkapitaal1Percentage: leningdeel.leningdeelgegevens.rentePercentage?.berekendBedrag
          } as KapitaalopbouwType)
        : null,
    fiscalegegevens: {
      ...leningdeel.fiscalegegevens,
      deelBox1Bedrag: Math.round(leningdeel.fiscalegegevens.deelBox1Bedrag),
      deelBox3Bedrag: Math.round(leningdeel.fiscalegegevens.deelBox3Bedrag),
      renteaftrekSpecificatie: {
        renteAftrekken: leningdeel.fiscalegegevens.renteaftrekSpecificatie.renteAftrekken.map(ra => ({
          ...ra,
          bedrag: ra.bedrag ? Math.round(ra.bedrag) : null
        }))
      }
    },
    hypotheekVorm: nieuweHypotheekVorm,
    labelCode: labelCode,
    labelNaam: labelOmschrijving,
    partijCode: maatschappijCode,
    productCode: nieuweHypotheekVorm.code.toString(),
    nearMatchHypotheekvormen: null
  };
}

type SaveHypotheekSelectieProps = {
  gekozenProduct: HypotheekProductSelectieStateType | HypotheekProductSelectieVoorstelStateType;
  situatie: SituatieSoort;
  hypotheek: HypothekenState;
  onSaveProduct?: (product: HypotheekType | Array<HypotheekType>) => void;
  selectedRow?: number;
  hypotheekSamenstellingWijzigingState?: HypotheekSelectieSamenstellingType[];
  updateLeningdeel?: boolean;
  productUpdateOnly?: boolean;
  omzetten?: boolean;
  geldverstrekkerUpdateOnly?: boolean;
};

export const saveHypotheekSelectie = ({
  gekozenProduct,
  hypotheekSamenstellingWijzigingState,
  hypotheek,
  situatie,
  productUpdateOnly,
  omzetten,
  onSaveProduct,
  selectedRow,
  updateLeningdeel,
  geldverstrekkerUpdateOnly
}: SaveHypotheekSelectieProps): void => {
  const {
    maatschappijCode,
    labelCode,
    maatschappijOmschrijving,
    labelOmschrijving
  } = gekozenProduct.maatschappijProductCode;

  const { code, aflosvorm, productOmschrijving, renteBoxMaatschappijCode, renteboxCode } = gekozenProduct.productCode;
  const partijCode = maatschappijCode;
  const filteredProducten = hypotheek.producten.filter(
    c =>
      c.product.doorlopend ||
      c.hypotheekVorm.isStartersLening ||
      (c.labelCode === labelCode && c.partijCode === partijCode) ||
      c.hypotheekVorm.isRestschuldLening ||
      situatie === "huidig"
  );

  const selectieKeuzeProduct = (): void => {
    /* istanbul ignore next */

    if (
      !hasValue(maatschappijCode) ||
      !hasValue(labelCode) ||
      (!geldverstrekkerUpdateOnly && !hasValue(code)) ||
      !hasValue(aflosvorm)
    ) {
      throw new Error(`incompleet product ${maatschappijCode}, ${labelCode}, ${code}, ${aflosvorm}`);
    }

    const leningdeel = mapProductSelectieToHypotheekProduct(
      maatschappijCode,
      maatschappijOmschrijving,
      labelCode,
      code ?? "",
      (aflosvorm as unknown) as AflossingsVormType,
      productOmschrijving,
      { ...hypotheek, producten: filteredProducten },
      situatie,
      labelOmschrijving,
      renteBoxMaatschappijCode,
      renteboxCode
    );

    onSaveProduct &&
      onSaveProduct(
        filteredProducten
          .map(c => {
            const basePatch: Partial<typeof c> = {};
            const productPatch: Partial<typeof c["product"]> = {};

            if (c.hypotheekVorm.isRestschuldLening) {
              basePatch.labelCode = leningdeel.labelCode;
              basePatch.partijCode = leningdeel.partijCode;
              productPatch.partijNaam = leningdeel.product.partijNaam;
            }

            return {
              ...c,
              ...basePatch,
              product: {
                ...c.product,
                ...productPatch
              }
            };
          })
          .concat([leningdeel])
      );
  };

  const selectieSamenstelling = (): void => {
    const nearMatches = nearMatchesToKeyValueArray(gekozenProduct.nearMatchHypotheekvorm);

    const nieuweLeningdelen = hypotheek.producten.map(
      (leningdeel: HypotheekType): HypotheekType => {
        const nearMatchHypotheekvormen =
          hypotheekSamenstellingWijzigingState &&
          hypotheekSamenstellingWijzigingState.find(
            (c: HypotheekSelectieSamenstellingType) => c.volgnummer === leningdeel.volgnummer
          )?.nearMatchHypotheekvormen;

        const matchHypotheekvormen =
          hypotheekSamenstellingWijzigingState &&
          hypotheekSamenstellingWijzigingState.find(
            (c: HypotheekSelectieSamenstellingType) => c.volgnummer === leningdeel.volgnummer
          )?.matchHypotheekvormen;

        const nieuweHypotheekVormen = nearMatchHypotheekvormen || matchHypotheekvormen || [];

        /* istanbul ignore next */
        if (
          nearMatches &&
          typeof nearMatches[leningdeel.volgnummer] !== "undefined" &&
          /* istanbul ignore next */ nieuweHypotheekVormen
        ) {
          const nieuwLeningdeel = mapProductSelectieToHypotheekProduct(
            maatschappijCode,
            maatschappijOmschrijving,
            labelCode,
            nieuweHypotheekVormen[nearMatches[leningdeel.volgnummer]].code.toString(),
            nieuweHypotheekVormen[nearMatches[leningdeel.volgnummer]].aflossingsvorm as AflossingsVormType,
            nieuweHypotheekVormen[nearMatches[leningdeel.volgnummer]].omschrijving,
            hypotheek,
            situatie,
            labelOmschrijving,
            renteBoxMaatschappijCode,
            nieuweHypotheekVormen[nearMatches[leningdeel.volgnummer]].code
          );

          return mapSamenstellingLeningdelen(leningdeel, nieuwLeningdeel);
        }

        const nieuweHypotheekVorm =
          hypotheekSamenstellingWijzigingState &&
          hypotheekSamenstellingWijzigingState.find(
            (c: HypotheekSelectieSamenstellingType) => c.volgnummer === leningdeel.volgnummer
          )?.hypotheekVorm;

        /* istanbul ignore else */
        if (nieuweHypotheekVorm) {
          const nieuwLeningdeel = mapProductSelectieToHypotheekProduct(
            maatschappijCode,
            maatschappijOmschrijving,
            labelCode,
            nieuweHypotheekVorm.code.toString(),
            nieuweHypotheekVorm.aflossingsvorm as AflossingsVormType,
            nieuweHypotheekVorm.omschrijving,
            hypotheek,
            situatie,
            labelOmschrijving,
            renteBoxMaatschappijCode,
            renteboxCode
          );

          return mapSamenstellingLeningdelen(leningdeel, nieuwLeningdeel);
        }

        return leningdeel;
      }
    );

    onSaveProduct && onSaveProduct(nieuweLeningdelen);
  };

  const selectieGenereer = (): void => {
    const nearMatches = nearMatchesToKeyValueArray(gekozenProduct.nearMatchHypotheekvorm);

    const nieuweLeningdelen = (gekozenProduct.leningdelen
      ? (gekozenProduct.leningdelen as HypotheekType[])
      : /* istanbul ignore next */ []
    ).map((leningdeel: HypotheekType, leningdeelIndex: number) => {
      if (leningdeel.fiscaleRegeling && !leningdeel.fiscaleRegeling.fiscaleVoortzetting) {
        leningdeel.fiscaleRegeling.fiscaleVoortzetting = FiscaleVoortzettingOptions.Geen;
      }

      return mapGenereerLeningdelen(
        leningdeel,
        nearMatches &&
          nearMatches[leningdeelIndex] !== undefined &&
          /* istanbul ignore next */ leningdeel.nearMatchHypotheekvormen
          ? leningdeel.nearMatchHypotheekvormen[nearMatches[leningdeelIndex]]
          : leningdeel.hypotheekVorm,
        maatschappijCode,
        labelOmschrijving,
        labelCode
      );
    });

    const behoudenLeningdelen = hypotheek.producten.filter(
      c => c.product.doorlopend || c.hypotheekVorm.isStartersLening
    );

    onSaveProduct && onSaveProduct(behoudenLeningdelen.concat(nieuweLeningdelen));
  };

  const selectieUpdateLeningdeelProduct = (selectedRow: number, aflossingsvorm: AflossingsVormType): void => {
    const productCode = `${code ?? /* istanbul ignore next */ 0}`;
    /* istanbul ignore next */
    const ingangsdatum = productUpdateOnly
      ? hypotheek.aanvangsdatum
      : hypotheek.producten[selectedRow].product.ingangsdatum;
    const eindDatum = hypotheek.producten[selectedRow].product.einddatum;
    /* istanbul ignore next */
    const looptijdLocalDateUntil =
      productUpdateOnly && ingangsdatum && eindDatum ? ingangsdatum.until(eindDatum) : null;
    /* istanbul ignore next */
    const looptijd =
      productUpdateOnly && looptijdLocalDateUntil
        ? { jaren: looptijdLocalDateUntil.years(), maanden: looptijdLocalDateUntil.months() }
        : hypotheek.producten[selectedRow].product.looptijd;

    const leningdeel: HypotheekType = {
      ...hypotheek.producten[selectedRow],
      leningdeelgegevens: {
        ...hypotheek.producten[selectedRow].leningdeelgegevens,
        rentePercentage: {
          berekenen: true,
          bedrag:
            hypotheek.producten[selectedRow].leningdeelgegevens.rentePercentage?.bedrag ??
            /*istanbul ignore next*/ null,
          berekendBedrag:
            hypotheek.producten[selectedRow].leningdeelgegevens.rentePercentage?.berekendBedrag ??
            /* istanbul ignore next */ null
        }
      },
      // Wanneer we een bestaand leningdeel wijzigen, willen we bij bankspaar producten deze gegevens behouden of toevoegen.
      fiscaleRegeling:
        aflossingsvorm === AflossingsVormType.Spaarrekening
          ? hypotheek.producten[selectedRow].fiscaleRegeling
            ? hypotheek.producten[selectedRow].fiscaleRegeling
            : {
                ...hypotheekFiscaleRegelingSchema.default(),
                oorspronkelijkeIngangsdatum: hypotheek.producten[selectedRow].product?.ingangsdatum || null,
                ingangsdatumBox1: hypotheek.producten[selectedRow].fiscalegegevens.begindatumRenteaftrek || null
              }
          : null,
      // Wanneer we een bestaand leningdeel wijzigen, willen we bij bankspaar producten deze gegevens behouden of toevoegen.
      premieGegevens:
        aflossingsvorm === AflossingsVormType.Spaarrekening
          ? hypotheek.producten[selectedRow].premieGegevens
            ? hypotheek.producten[selectedRow].premieGegevens
            : { ...premieGegevensSchema.default(), looptijd: looptijd }
          : null,
      // Wanneer we een bestaand leningdeel wijzigen, willen we bij bankspaar producten deze gegevens behouden of toevoegen.
      kapitaalopbouw:
        aflossingsvorm === AflossingsVormType.Spaarrekening
          ? hypotheek.producten[selectedRow].kapitaalopbouw
            ? hypotheek.producten[selectedRow].kapitaalopbouw
            : {
                ...kapitaalopbouwSchema.default(),
                doelkapitaal1Bedrag: hypotheek.producten[selectedRow].leningdeelgegevens.leningdeelHoofdsom.bedrag,
                doelkapitaal1Overnemen: true,
                doelkapitaal1Percentage:
                  hypotheek.producten[selectedRow].leningdeelgegevens.rentePercentage?.bedrag || 0
              }
          : null,
      productCode: `${productCode}`,
      labelNaam: labelOmschrijving,
      labelCode: labelCode,
      renteBoxMaatschappijCode: renteBoxMaatschappijCode ?? /* istanbul ignore next */ null,
      hypotheekVorm: {
        ...hypotheek.producten[selectedRow].hypotheekVorm,
        code: parseInt(productCode),
        omschrijving: productOmschrijving,
        aflossingsvorm: (aflosvorm as unknown) as AflossingsVormType,
        isRestschuldLening: false,
        isStartersLening: false
      },
      partijCode: maatschappijCode,
      product: {
        ...hypotheek.producten[selectedRow].product,
        ingangsdatum: ingangsdatum,
        renteboxCode: renteboxCode,
        looptijd,
        partijCodeSelectie: maatschappijCode,
        partijNaam: maatschappijOmschrijving
      }
    };
    onSaveProduct && onSaveProduct(leningdeel);
  };

  switch (gekozenProduct.leningdeelKeuze) {
    case "keuzeproduct":
      if (
        updateLeningdeel &&
        hasValue(selectedRow) &&
        (hypotheek.producten[selectedRow]?.partijCode === maatschappijCode || productUpdateOnly || omzetten)
      ) {
        selectieUpdateLeningdeelProduct(selectedRow, aflosvorm);
      } else {
        selectieKeuzeProduct();
      }
      break;
    case "genereer":
      selectieGenereer();
      break;
    case "samenstelling":
      selectieSamenstelling();
      break;
  }
};
