import { LeningdeelReadOnly, VermogenLeningdeelReadOnly } from "../../../.generated/forms/formstypes";
import { LeningdeelType, VermogenLeningdeelType } from "./schema";
import { mapLocalDateToString } from "adviesbox-shared";

export function mapLeningdelenUi2Dl(values: LeningdeelType[]): LeningdeelReadOnly[] {
  return values.map(mapLeningdeelUi2DL);
}
export function mapVermogenLeningdelenUi2Dl(values: VermogenLeningdeelType[]): VermogenLeningdeelReadOnly[] {
  return values.map(mapVermogenLeningdeelUi2DL);
}

export function mapLeningdeelUi2DL(values: LeningdeelType): LeningdeelReadOnly {
  return {
    leningdeelId: values.leningdeelId,
    doorlopend: values.doorlopend,
    productnaam: values.productnaam,
    aanvangsdatum: mapLocalDateToString(values.aanvangsdatum),
    looptijdInMaanden: values.looptijd,
    maatschappijCode: values.maatschappijCode,
    productcode: values.productcode,
    leningdeelBedrag: null,
    volgnummer: 0
  };
}

export function mapVermogenLeningdeelUi2DL(values: VermogenLeningdeelType): VermogenLeningdeelReadOnly {
  return {
    ...mapLeningdeelUi2DL(values),
    doelkapitaalBedrag: values.doelkapitaal,
    doelkapitaalPercentage: values.doelkapitaalPercentage
  };
}
