import React, { ReactElement } from "react";
import { Formik, FormikProps } from "formik";
import Modal from "../../shared/components/modal/Modal";
import { LabeledCurrencyInput, LabeledBevestigingInput } from "adviesbox-shared";
import { Koopsom as KoopsomState } from "../infra/financieringsbehoefte-types";
import { koopsomSchema } from "../infra/financieringsbehoefte-schema";

export type KoopsomData = {
  data: KoopsomState;
};

type KoopsomProps = {
  onSave?: (data: KoopsomState) => void;
  closeModal?: () => void;
};

const Koopsom = ({ data, onSave, closeModal }: KoopsomData & KoopsomProps): ReactElement => {
  const body = (
    <>
      <LabeledCurrencyInput caption="Koopsom woning incl. grond" name="koopsomBedrag" />
      <LabeledCurrencyInput caption="Waarvan grondprijs" name="kostenGrondBedrag" />
      <LabeledBevestigingInput caption="Grond reeds in bezit" name="grondReedsInBezit" />
    </>
  );

  return (
    <Formik
      initialValues={{ ...data }}
      validationSchema={koopsomSchema}
      onSubmit={(values): void => {
        onSave && onSave(values);
        closeModal && closeModal();
      }}
    >
      {({ submitForm }: FormikProps<KoopsomState>): ReactElement => (
        <>
          <Modal title="Koopsom v.o.n." body={body} onSubmitClick={submitForm} onCancelClick={closeModal} />
        </>
      )}
    </Formik>
  );
};

export default Koopsom;
