import { Afbeelding, Icon } from "adviesbox-shared";
import React, { ReactElement, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import AdviseurNaam from "../../auth/AdviseurNaam";
import { GeslachtOpties } from "../../.generated/forms/formstypes";
import { jaarMaandInputNaarJaarMaand } from "../../shared/generic-parts/jaar-maand/helpers";
import { JaarMaandInputType } from "../../shared/generic-parts/jaar-maand/schema";
import { bedragFormat } from "../../shared/utils/currency";
import { getDifferenceYearsMonths, getFormattedDate } from "../../shared/utils/dates";
import {
  InkomenType,
  MaximaleHypotheekState,
  MaximaleHypothekenType,
  PersoonsgegevensType,
  ToetsrenteType,
  UitgangspuntenType
} from "../infra/maximale-hypotheek-schema";
import classes from "../maximale-hypotheek.module.scss";
import {
  getCurrentDateFormatted,
  numberWithDots,
  printPDF
} from "./maximale-hypotheek-pdf-helpers/maximale-hypotheek-pdf-helpers";

type MaximaleHypotheekPDFProps = {
  values: MaximaleHypotheekState;
  sortedData: MaximaleHypothekenType;
  initial: MaximaleHypothekenType;
};

export const MaximaleHypotheekPDF = ({ values, sortedData, initial }: MaximaleHypotheekPDFProps): ReactElement => {
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    if (sortedData.length > 0) {
      setData(sortedData);
      return;
    }

    setData(initial);
  }, [sortedData, initial]);

  const maximaleHypotheken = [...data];
  const amountOfLinesFirstPage = 40;
  const amountOfLinesPerPage = 55;
  const amountOflinesTotal = maximaleHypotheken.length;
  let amountOfpages = Math.ceil((amountOflinesTotal - amountOfLinesFirstPage) / amountOfLinesPerPage + 1);

  const bedrijfslogo = new Image();
  bedrijfslogo.src = `data:image/png;base64,${values?.orgData?.logo}`;

  const renderHeader = (persoonsgegevens: PersoonsgegevensType, inkomen: InkomenType): ReactElement => {
    let aowLeeftijdAanvrager: JaarMaandInputType | null = null;
    let aowLeeftijdPartner: JaarMaandInputType | null = null;
    if (persoonsgegevens.naamAanvrager && persoonsgegevens.naamAanvrager.aowdatum) {
      const aowAanvrager = getDifferenceYearsMonths(
        persoonsgegevens.naamAanvrager.geboortedatum,
        persoonsgegevens.naamAanvrager.aowdatum
      );
      aowLeeftijdAanvrager = { maanden: aowAanvrager.month, jaren: aowAanvrager.year };
    }
    if (persoonsgegevens.naamPartner && persoonsgegevens.naamPartner.aowdatum) {
      const aowPartner = getDifferenceYearsMonths(
        persoonsgegevens.naamPartner.geboortedatum,
        persoonsgegevens.naamPartner.aowdatum
      );
      aowLeeftijdPartner = { maanden: aowPartner.month, jaren: aowPartner.year };
    }

    return (
      <>
        <div className={classes.pdf_header_container}>
          <h1 className={classes.pdf_header}>Maximale Hypotheek</h1>
          <div className={classes.pdf_header}>
            <Afbeelding name="orgData.logo" alt="bedrijfslogo" location={bedrijfslogo.src} style={{ maxHeight: 60 }} />
          </div>
        </div>
        <table className={classes.pdf_table}>
          <thead>
            <tr>
              <th className={classes.pdf_table_header}></th>
              <th className={`${classes.pdf_table_header_center}`}>
                {persoonsgegevens.naamAanvrager?.geslacht === GeslachtOpties.Man ? "Dhr." : "Mevr."}{" "}
                {persoonsgegevens.naamAanvrager?.voorletters} {persoonsgegevens.naamAanvrager?.voorvoegsel}{" "}
                {persoonsgegevens.naamAanvrager?.achternaam}
              </th>

              {!!persoonsgegevens.naamPartner?.achternaam && (
                <th className={classes.pdf_table_header}>
                  {persoonsgegevens.naamPartner?.geslacht === GeslachtOpties.Man ? "Dhr." : "Mevr."}{" "}
                  {persoonsgegevens.naamPartner?.voorletters} {persoonsgegevens.naamPartner?.voorvoegsel}{" "}
                  {persoonsgegevens.naamPartner?.achternaam}
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={classes.pdf_table_data_info}>Geboortedatum</td>
              <td className={`${classes.pdf_table_data}`} style={{ backgroundColor: "#f0f0f0" }}>
                {persoonsgegevens.naamAanvrager
                  ? getFormattedDate(persoonsgegevens.naamAanvrager.geboortedatum)
                  : /* istanbul ignore next */ ""}
              </td>
              {!!persoonsgegevens.naamPartner?.achternaam && (
                <td className={`${classes.pdf_table_data}`} style={{ backgroundColor: "#f0f0f0" }}>
                  {getFormattedDate(persoonsgegevens.naamPartner.geboortedatum)}
                </td>
              )}
            </tr>
            <tr>
              <td className={classes.pdf_table_data_info}>Toetsinkomen</td>
              <td className={`${classes.pdf_table_data}`}>
                {inkomen.toetsinkomenAanvrager.berekenen
                  ? inkomen.toetsinkomenAanvrager?.berekendBedrag
                    ? bedragFormat(inkomen.toetsinkomenAanvrager.berekendBedrag)
                    : /* istanbul ignore next */ 0
                  : inkomen.toetsinkomenAanvrager?.bedrag
                  ? bedragFormat(inkomen.toetsinkomenAanvrager.bedrag)
                  : /* istanbul ignore next */ 0}
              </td>
              {!!persoonsgegevens.naamPartner?.achternaam && (
                <td className={`${classes.pdf_table_data}`}>
                  {inkomen.toetsinkomenPartner.berekenen
                    ? inkomen.toetsinkomenPartner?.berekendBedrag
                      ? bedragFormat(inkomen.toetsinkomenPartner.berekendBedrag)
                      : /* istanbul ignore next */ bedragFormat(0)
                    : inkomen.toetsinkomenPartner?.bedrag
                    ? bedragFormat(inkomen.toetsinkomenPartner.bedrag)
                    : /* istanbul ignore next */ ""}
                </td>
              )}
            </tr>
            <tr>
              <td className={classes.pdf_table_data_info}>Pensioeninkomen</td>
              <td className={`${classes.pdf_table_data}`} style={{ backgroundColor: "#f0f0f0" }}>
                {inkomen.pensioeninkomenAanvrager.berekenen
                  ? inkomen.pensioeninkomenAanvrager?.berekendBedrag
                    ? bedragFormat(inkomen.pensioeninkomenAanvrager.berekendBedrag)
                    : /* istanbul ignore next */ bedragFormat(0)
                  : inkomen.pensioeninkomenAanvrager?.bedrag
                  ? bedragFormat(inkomen.pensioeninkomenAanvrager.bedrag)
                  : /* istanbul ignore next */ bedragFormat(0)}
              </td>
              {!!persoonsgegevens.naamPartner?.achternaam && (
                <td className={`${classes.pdf_table_data}`} style={{ backgroundColor: "#f0f0f0" }}>
                  {inkomen.pensioeninkomenPartner.berekenen
                    ? inkomen.pensioeninkomenPartner?.berekendBedrag
                      ? bedragFormat(inkomen.pensioeninkomenPartner.berekendBedrag)
                      : /* istanbul ignore next */ bedragFormat(0)
                    : inkomen.pensioeninkomenPartner?.bedrag
                    ? bedragFormat(inkomen.pensioeninkomenPartner.bedrag)
                    : /* istanbul ignore next */ ""}
                </td>
              )}
            </tr>
            <tr>
              <td className={classes.pdf_table_data_info}>AOW leeftijd</td>
              <td className={`${classes.pdf_table_data}`}>{jaarMaandInputNaarJaarMaand(aowLeeftijdAanvrager)}</td>
              {!!persoonsgegevens.naamPartner?.achternaam && (
                <td className={`${classes.pdf_table_data}`}>{jaarMaandInputNaarJaarMaand(aowLeeftijdPartner)}</td>
              )}
            </tr>
          </tbody>
        </table>
      </>
    );
  };

  const renderFooter = (): ReactElement => {
    return (
      <>
        <div style={{ flexGrow: 1 }} />
        <div className={classes.pdf_footer}>
          <div className={classes.pdf_footer_header}>
            <span>
              Deze bedragen zijn slechts een indicatie van wat werkelijk mogelijk is. Controle van de acceptatienormen
              van de gekozen maatschappij dient te geschieden op het tabblad &apos;acceptatie&apos;. Definitieve
              acceptatie geschiedt altijd door de gekozen maatschappij zelf.
            </span>
          </div>
          <div className={classes.pdf_footer_sub}>
            <span>
              Overzicht verzorgd door{" "}
              <span>
                <AdviseurNaam />
              </span>{" "}
              van {values?.orgData?.bedrijfsnaam} op {getCurrentDateFormatted()}.
            </span>
            <span className={classes.pdf_footer_sub_rights}>
              Aan dit overzicht kunnen geen rechten worden ontleend.
            </span>
          </div>
        </div>
      </>
    );
  };

  const renderUitgangspunten = (
    uitgangspunten: UitgangspuntenType,
    toetsrente: ToetsrenteType,
    onlyUitgangsPunten: boolean
  ): ReactElement => {
    return (
      <>
        <table className={onlyUitgangsPunten ? /* istanbul ignore next*/ classes.pdf_padding : ""}>
          <thead>
            <tr>
              <td className={classes.pdf_table_uitgangspunten_header_left}>Uitgangspunten berekening</td>
              <td className={classes.pdf_table_uitgangspunten_header_right}></td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={classes.pdf_table_data_info}>Marktwaarde</td>
              <td className={`${classes.pdf_table_data}`} style={{ backgroundColor: "#f0f0f0" }}>
                €{" "}
                {uitgangspunten.marktwaarde ? numberWithDots(uitgangspunten.marktwaarde) /* istanbul ignore next*/ : 0}
              </td>
            </tr>
            <tr>
              <td className={classes.pdf_table_data_info}>Gewenste hypotheek</td>
              <td className={`${classes.pdf_table_data}`}>
                €{" "}
                {uitgangspunten.gewensteHypotheek
                  ? numberWithDots(uitgangspunten.gewensteHypotheek) /* istanbul ignore next*/
                  : 0}
              </td>
            </tr>
            <tr>
              <td className={classes.pdf_table_data_info}>Andere financiering</td>
              <td className={`${classes.pdf_table_data}`} style={{ backgroundColor: "#f0f0f0" }}>
                €{" "}
                {uitgangspunten.andereFinanciering
                  ? numberWithDots(uitgangspunten.andereFinanciering) /* istanbul ignore next*/
                  : 0}
              </td>
            </tr>
            <tr>
              <td className={classes.pdf_table_data_info}>Totale lening</td>
              <td className={`${classes.pdf_table_data}`}>
                €{" "}
                {uitgangspunten.totaleLening
                  ? numberWithDots(uitgangspunten.totaleLening) /* istanbul ignore next*/
                  : 0}
              </td>
            </tr>
            <tr>
              <td className={classes.pdf_table_data_info}>Looptijd hypotheek</td>
              <td className={`${classes.pdf_table_data}`} style={{ backgroundColor: "#f0f0f0" }}>
                {uitgangspunten.looptijd} jaar
              </td>
            </tr>
            <tr>
              <td className={classes.pdf_table_data_info}>Gespecificeerde rentevastperiode</td>
              <td className={`${classes.pdf_table_data}`}>{uitgangspunten.gewensteRentevastperiode} jaar</td>
            </tr>
            <tr>
              <td className={classes.pdf_table_data_info}>Vrij vermogen</td>
              <td className={`${classes.pdf_table_data}`} style={{ backgroundColor: "#f0f0f0" }}>
                € {toetsrente.vrijVermogen ? numberWithDots(toetsrente.vrijVermogen) /* istanbul ignore next*/ : 0}
              </td>
            </tr>
            <tr>
              <td className={classes.pdf_table_data_info}>Maandlast kredieten</td>
              <td className={`${classes.pdf_table_data}`}>
                €{" "}
                {toetsrente.kredietToetslast.berekenen
                  ? toetsrente.kredietToetslast.berekendBedrag
                    ? numberWithDots(toetsrente.kredietToetslast.berekendBedrag)
                    : /* istanbul ignore next*/ 0
                  : toetsrente.kredietToetslast.bedrag
                  ? numberWithDots(toetsrente.kredietToetslast.bedrag)
                  : /* istanbul ignore next*/ 0}
              </td>
            </tr>
          </tbody>
        </table>
      </>
    );
  };

  const renderPage = (
    firstRender: boolean,
    index: number,
    lastPage: boolean,
    renderOnlyUitgangspunten: boolean,
    hypotheken?: undefined | MaximaleHypothekenType,
    persoonsgegevens?: PersoonsgegevensType
  ): ReactElement => {
    return (
      <div
        className={classes.pdf_hide}
        id={`print-container-${index}`}
        key={`print-container-${index}-key`}
        style={{ maxWidth: "30.3cm", minWidth: "30.3cm", display: "block" }}
      >
        <div className={classes.pdf_page} style={{ backgroundColor: "white" }}>
          {firstRender && !renderOnlyUitgangspunten && renderHeader(values?.persoonsgegevens, values?.inkomen)}
          {!renderOnlyUitgangspunten && hypotheken && (
            <table className={`${classes.pdf_table} ${classes.pdf_table_dataTable}`}>
              <thead>
                <tr>
                  <th className={`${classes.pdf_table_data_header} ${classes.pdf_table_data_header_first}`}>
                    Maatschappij
                  </th>
                  <th className={`${classes.pdf_table_data_header} ${classes.pdf_table_data_header_second}`}>
                    Toetsinkomen aanvrager 1
                  </th>
                  {!!persoonsgegevens?.naamPartner?.achternaam && (
                    <th className={`${classes.pdf_table_data_header} ${classes.pdf_table_data_header_third}`}>
                      Toetsinkomen aanvrager 2
                    </th>
                  )}
                  <th className={`${classes.pdf_table_data_header} ${classes.pdf_table_data_header_fourth}`}>NHG %</th>
                  <th className={`${classes.pdf_table_data_header} ${classes.pdf_table_data_header_fifth}`}>
                    NHG toetsing
                  </th>
                  <th className={`${classes.pdf_table_data_header} ${classes.pdf_table_data_header_sixth}`}>
                    NHG onderpand
                  </th>
                </tr>
              </thead>
              <tbody>
                {hypotheken.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td>{data.productnaam}</td>

                      <td>
                        {data.inkomstenVerklaring1
                          ? `€ ${data.inkomstenVerklaring1.split("\u20AC")[1]} o.b.v. ${
                              data.inkomstenVerklaring1.split(",")[0]
                            }`
                          : ""}
                      </td>
                      {!!persoonsgegevens?.naamPartner?.achternaam && (
                        <td>
                          {data.inkomstenVerklaring2
                            ? `€ ${data.inkomstenVerklaring2.split("\u20AC")[1]} o.b.v. ${
                                data.inkomstenVerklaring2.split(",")[0]
                              }`
                            : ""}
                        </td>
                      )}
                      <td className={classes.pdf_table_right_align}>
                        {data.toetsrente ? data.toetsrente.toString().replace(".", ",") : ""}%
                      </td>
                      <td className={classes.pdf_table_right_align}>
                        € {data.maximaleHypotheek ? numberWithDots(data.maximaleHypotheek) : 0}
                      </td>
                      <td className={classes.pdf_table_right_align}>
                        € {data.maximaleHypotheekOnderpand ? numberWithDots(data.maximaleHypotheekOnderpand) : 0}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
          {(lastPage || renderOnlyUitgangspunten) &&
            renderUitgangspunten(values?.uitgangspunten, values?.toetsrente, renderOnlyUitgangspunten)}
          {renderFooter()}
        </div>
      </div>
    );
  };

  const renderPages = (persoonsgegevens?: PersoonsgegevensType): ReactElement => {
    let lastPage = false;
    let uitgangsPuntenFitsOnLastPage = false;
    const counter = amountOfpages;
    const pages: ReactElement[] = [];
    if (maximaleHypotheken.length === 0) return <></>;
    for (let i = 1; i <= counter; i++) {
      const startFrom = 0;
      const until = (): number => {
        if (i === 1) {
          return amountOfLinesFirstPage;
        }

        return amountOfLinesPerPage;
      };

      const sliced = maximaleHypotheken.splice(startFrom, until());
      const amountOfLinesOnpage = sliced.length;
      const EnoughSpaceForUitgangspunten = (): boolean => {
        const check = amountOfLinesOnpage + 13;
        if (i === 1 && check >= amountOfLinesFirstPage) return false;
        if (i !== 1 && check >= amountOfLinesPerPage) return false;
        return true;
      };

      if (amountOfpages === i) {
        lastPage = true;
        if (EnoughSpaceForUitgangspunten()) uitgangsPuntenFitsOnLastPage = true;
      }

      if (i > amountOfpages) break;

      const page = renderPage(i === 1, i, uitgangsPuntenFitsOnLastPage, false, sliced, persoonsgegevens);
      pages.push(page);

      if (lastPage && !uitgangsPuntenFitsOnLastPage) {
        amountOfpages = amountOfpages + 1;
        const uitgangspuntenIndex = i + 1;
        const uitgangsPunten = renderPage(
          uitgangspuntenIndex === 1,
          uitgangspuntenIndex,
          uitgangsPuntenFitsOnLastPage,
          true,
          sliced,
          persoonsgegevens
        );
        pages.push(uitgangsPunten);
      }
    }

    return <>{pages.map(page => page)}</>;
  };

  return (
    <>
      {renderPages(values.persoonsgegevens)}
      <Button onClick={async () => await printPDF(amountOfpages, "download")}>
        <Icon name="download" alt="download" multiColor={true} />
      </Button>
    </>
  );
};
