import { produceWithPatches } from "immer";


import { partijOnafhankelijk } from "../infra/product-constanten";
import { createDraftSideEffect } from "../../shared/utils/create-draft-side-effects";
import { prefixWithZero } from "../../shared/utils/helpers";
import { ProductSelectieStateType, SelectieType } from "../infra/producten-overzicht-types";

const productSelectieSideEffects = createDraftSideEffect<ProductSelectieStateType, SelectieType[]>(
  (has, curr, _prev, productSelecties): void => {
    if (!productSelecties || productSelecties.length <= 0) return;

    // Selecteer het eerste product nadat de maatschappij wijzigt
    if (has.maatschappijCode.changed) {
      const maatschappij = productSelecties.find((c): boolean => c.value === curr.maatschappijCode);
      if (maatschappij !== undefined) {
        maatschappij.value === partijOnafhankelijk
          ? (curr.productCode = "03")
          : (curr.productCode = prefixWithZero(maatschappij.producten[0].value) || "");
      }
    }

    if (has.productCode.changed) {
      const maatschappij = productSelecties.find((c): boolean => c.value === curr.maatschappijCode);
      const productCode = parseInt(curr.productCode);

      if (maatschappij !== undefined) {
        const product = maatschappij.producten.find(c => c.value === productCode);

        if (product !== undefined) {
          curr.productVorm = product.productvorm;
        }
      }
    }
  }
);

export const determineProductSelectieSideEffects = (
  curr: ProductSelectieStateType,
  prev: ProductSelectieStateType,
  productSelecties: SelectieType[]
): ProductSelectieStateType | null => {
  const [next, changes] = produceWithPatches(curr, (draft): void =>
    productSelectieSideEffects(draft, prev, productSelecties)
  );
  /* istanbul ignore next */
  /* eslint-disable-next-line no-console */
  process.env.NODE_ENV === "development" && changes.length > 0 && console.log("sideEffects", changes);
  if (changes.length > 0) return next;
  return null;
};
