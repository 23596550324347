import * as yup from 'yup';

export const dossierAdviseurWijzigenSchema = yup.object().shape({
    huidigeAdviseur: yup.string(),
    huidigeAdviseurId: yup.string(),
    doelAdviseur: yup.string(),
    doelAdviseurId: yup.string().required("doel adviseurId is verplicht"),    
});

export type DossierWijzigAdviseurType = yup.InferType<typeof dossierAdviseurWijzigenSchema>;
