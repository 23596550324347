import React, { ReactElement, useState, useEffect } from "react";
import { connect, FormikContextType } from "formik";
import { LabeledSelectInput, LabeledRadioInput, LabeledTextInput, convertUtcToTimeZone } from "adviesbox-shared";
import { useInstellingenBeheerPartijenData } from "../../shared/hooks/use-instellingen-beheer-partijen-data";

import { getFormattedDate } from "../../shared/utils/dates";
import LabeledHdnKeuzelijst from "../../shared/components/hdn-keuzelijst/LabeledHdnKeuzelijst";
import { AdresInput } from "../../shared/components/adres/adres-input";
import { AanvraagLeningState } from "../infra/aanvraag-lening-schema";
import { getAanvraagLeningTextResources } from "../infra/aanvraag-lening-resources";

const Notaris = ({
  formik: {
    setFieldValue,
    values: { lening, notaris }
  }
}: {
  formik: FormikContextType<AanvraagLeningState>;
}): ReactElement => {
  const [muteerbaar, setMuteerbaar] = useState(true);
  const { data } = useInstellingenBeheerPartijenData("Notarissen");

  const notariskantoorOptions: any[] = [];
  notariskantoorOptions.push({ label: "Anders", value: "" });
  data?.notarissen?.partijen?.forEach(notaris => {
    notariskantoorOptions.push({ label: notaris.kantoornaam, value: notaris.notariskantoorId });
  });

  useEffect(() => {
    notaris?.notariskantoorId === "" ? setMuteerbaar(true) : setMuteerbaar(false);
  }, [notaris, notaris.notariskantoorId]);

  const handleNotarisSelectie = (event: { target: { value: string } }): void => {
    if (event.target.value === "") {
      setFieldValue("notaris.notariskantoorId", "");
      setFieldValue("notaris.kantoornaam", "");
      setFieldValue("notaris.adres", {
        postcode: "",
        huisnummer: null,
        toevoeging: "",
        straat: "",
        plaats: ""
      });
      setFieldValue("notaris.telefoonnummer", "");
      setFieldValue("notaris.geslacht", "Man");
      setFieldValue("notaris.persoonsgegevens.voorletters", "");
      setFieldValue("notaris.persoonsgegevens.voorvoegsel", "");
      setFieldValue("notaris.persoonsgegevens.achternaam", "");
    } else {
      const geselecteerdeNotaris = data.notarissen?.partijen?.find(e => e.notariskantoorId === event.target.value);
      setFieldValue("notaris.notariskantoorId", geselecteerdeNotaris?.notariskantoorId);
      setFieldValue("notaris.kantoornaam", geselecteerdeNotaris?.kantoornaam);
      setFieldValue("notaris.adres", geselecteerdeNotaris?.adres);
      setFieldValue("notaris.telefoonnummer", geselecteerdeNotaris?.telefoonnummer);
      setFieldValue("notaris.geslacht", geselecteerdeNotaris?.contactpersoon?.geslacht);
      setFieldValue("notaris.persoonsgegevens.voorletters", geselecteerdeNotaris?.contactpersoon?.voorletters);
      setFieldValue("notaris.persoonsgegevens.voorvoegsel", geselecteerdeNotaris?.contactpersoon?.voorvoegsel);
      setFieldValue("notaris.persoonsgegevens.achternaam", geselecteerdeNotaris?.contactpersoon?.achternaam);
    }
  };

  const convertedString = convertUtcToTimeZone(lening?.geselecteerdVoorstel?.passeerdatum)?.toLocalDate();
  const showPasseerDatum = convertedString ? getFormattedDate(convertedString) : "";

  return (
    <>
      <LabeledSelectInput
        caption="Notariskantoor"
        name="notaris.notariskantoorId"
        options={notariskantoorOptions}
        tooltip={getAanvraagLeningTextResources("notarisNotariskantoor")}
        onChange={handleNotarisSelectie}
        readonly={notariskantoorOptions.length === 1}
      />

      <LabeledHdnKeuzelijst
        caption="Rol notaris"
        name="notaris.typeNotaris"
        berichtSoortType="AX"
        keuzelijst="NotarisType"
      />

      <LabeledTextInput readonly={!muteerbaar} caption="Naam notariskantoor" name="notaris.kantoornaam" />

      <AdresInput readonly={!muteerbaar} name={`notaris.adres`} metLand={false} />

      <LabeledTextInput readonly={!muteerbaar} caption="Telefoonnummer" name="notaris.telefoonnummer" />

      <h2 className="ml-3">Contactpersoongegevens</h2>

      {muteerbaar ? (
        <LabeledRadioInput
          caption="Geslacht"
          name="notaris.geslacht"
          options={[
            { label: "Man", value: "Man" },
            { label: "Vrouw", value: "Vrouw" }
          ]}
        />
      ) : (
        <div className="form-group form-row">
          <div className={`col-6`}>
            <span>Geslacht</span>
          </div>
          <div className={`col-6`} id="notaris.geslacht">
            <span id={`notaris.geslacht-${notaris.geslacht}`} data-testid={`notaris.geslacht-${notaris.geslacht}`}>
              {notaris.geslacht}
            </span>
          </div>
        </div>
      )}

      <LabeledTextInput readonly={!muteerbaar} caption="Voorletters" name="notaris.persoonsgegevens.voorletters" />

      <LabeledTextInput readonly={!muteerbaar} caption="Tussenvoegsel" name="notaris.persoonsgegevens.voorvoegsel" />

      <LabeledTextInput readonly={!muteerbaar} caption="Achternaam" name="notaris.persoonsgegevens.achternaam" />

      <LabeledTextInput readonly={true} caption="Passeerdatum" name="notaris.persoonsgegevens.passeerdatum" placeholder={showPasseerDatum}/>
    </>
  );
};

export default connect<{}, AanvraagLeningState>(Notaris);
