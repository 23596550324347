import React, { ReactElement } from "react";
import Modal from "../../shared/components/modal/Modal";
import { FormikProps, getIn } from "formik";

import { getFinancieringsbehoefteTextResources } from "../infra/financieringsbehoefte-resources";
import { totaleRestschuldSideEffects } from "./totale-restschuld-modal-side-effects";
import { ISWSideEffects } from "../../shared/components/isw-side-effects/isw-side-effects";
import { LabeledCurrencyInput, BerekenCurrencyInput, afronden, LabeledResult } from "adviesbox-shared";
import { bedragFormat } from "../../shared/utils/currency";
import AdviesBoxFormik from "../../shared/utils/adviesbox-formik";
import { TotaleRestschuldModal as TotaleRestschuldModalState } from "../infra/financieringsbehoefte-types";
import { totaleRestschuldModalSchema } from "../infra/financieringsbehoefte-schema";

export type TotaleRestschuldModalData = {
  data: TotaleRestschuldModalState;
  eigenGeldInbrengOpgebouwdeWaardeTotaal: number;
};

type TotaleRestschuldModalProps = {
  onSave?: (data: TotaleRestschuldModalState) => void;
  closeModal?: () => void;
};

const TotaleRestschuldModal = ({
  data,
  eigenGeldInbrengOpgebouwdeWaardeTotaal,
  onSave,
  closeModal
}: TotaleRestschuldModalData & TotaleRestschuldModalProps): ReactElement => {
  const body = (values: TotaleRestschuldModalState, errors: any): ReactElement => {
    const totaleRestschuld = (values.restschuldHuidigeWoning.bedrag || 0) + (values.extraKostenHuidigeHypotheek || 0);

    const meeTeFinancierenRestschuld =
      (afronden(totaleRestschuld, 0) || 0) -
      (afronden(values.inbrengEigenGeldVoorVerlagenRestschuld, 0) || 0) -
      (afronden(values.inbrengOpgebouwdeWaardeRestschuld.bedrag, 0) || 0);

    const restschuldError = getIn(errors, "meeTeFinancierenRestschuld");

    return (
      <>
        <BerekenCurrencyInput caption="Restschuld huidige woning" name="data.restschuldHuidigeWoning" />

        <LabeledCurrencyInput
          caption="Extra kosten huidige hypotheek"
          name="data.extraKostenHuidigeHypotheek"
          tooltip={getFinancieringsbehoefteTextResources("TooltipExtraKosten")}
        />

        <LabeledResult
          caption="Totaal restschuld"
          name="data.totaleRestschuld"
          alignRight={true}
          result={(): string => bedragFormat(totaleRestschuld)}
          tooltip={getFinancieringsbehoefteTextResources("TooltipTotaleRestschuld")}
          fontWeight="bold"
        />

        <LabeledCurrencyInput
          caption="Inbreng eigen geld voor verlagen restschuld"
          name="data.inbrengEigenGeldVoorVerlagenRestschuld"
          tooltip={getFinancieringsbehoefteTextResources("TooltipVerlagenRestschuld")}
          width="195px"
        />

        <BerekenCurrencyInput
          caption="Inbreng opgebouwde waarde"
          name="data.inbrengOpgebouwdeWaardeRestschuld"
          tooltip={getFinancieringsbehoefteTextResources("TooltipOpgebouwdeWaarde")}
        />

        <LabeledResult
          caption="Mee te financieren restschuld"
          name="data.meeTeFinancierenRestschuld"
          alignRight={true}
          result={(): string => bedragFormat(meeTeFinancierenRestschuld)}
          tooltip={getFinancieringsbehoefteTextResources("TooltipMeeFinancierenRestschuld")}
        />

        {restschuldError && <div className="foutmelding">{restschuldError}</div>}
      </>
    );
  };
  return (
    <AdviesBoxFormik<TotaleRestschuldModalData>
      initialValues={{
        data: { ...data },
        eigenGeldInbrengOpgebouwdeWaardeTotaal
      }}
      validationSchema={totaleRestschuldModalSchema}
      onSave={(state): void => onSave && onSave(state.data)}
      closeModal={closeModal}
      render={({ values, submitForm, errors }: FormikProps<TotaleRestschuldModalData>): ReactElement => (
        <>
          <ISWSideEffects<TotaleRestschuldModalData> sync={totaleRestschuldSideEffects} />

          <Modal
            title="Totale restschuld"
            body={body(values.data, errors)}
            onSubmitClick={submitForm}
            onCancelClick={closeModal}
          />
        </>
      )}
    />
  );
};

export default TotaleRestschuldModal;
