import React, { ReactElement } from "react";
import { FormikProps } from "formik";
import { AdviesBoxFormik, AdviesBoxColumn } from "adviesbox-shared";
import { DataGrid } from "adviesbox-shared";
import {
  TaxatieVerbouwingSpecificatieModalType,
  TaxatieVerbouwingSpecificatieModalSchema
} from "../../infra/taxatie-scherm-schema";
import Modal from "../../../shared/components/modal/Modal";

type TaxatieVerbouwingModalProps = {
  onSave?: (data: TaxatieVerbouwingSpecificatieModalType) => void;
  closeModal?: () => void;
};

const taxatieVerbouwingColumns: AdviesBoxColumn[] = [
  {
    Header: "Werkzaamheden",
    accessor: "werkzaamheid",
    Cell: "TextInput",
    config: { readonly: true }
  },
  {
    Header: "Bedrag",
    accessor: "bedrag",
    Cell: "CurrencyInput",
    width: 100,
    config: { readonly: true },
    style: { display: "flex", justifyContent: "flex-end" },
    headerStyle: { display: "flex", justifyContent: "flex-end" }
  }
];

const TaxatieVerbouwing = ({
  data,
  closeModal
}: TaxatieVerbouwingSpecificatieModalType & TaxatieVerbouwingModalProps): ReactElement => {
  const body = (values: TaxatieVerbouwingSpecificatieModalType): ReactElement => (
    <>
      <div>{values && <DataGrid name="data" columns={taxatieVerbouwingColumns} />}</div>
      <div>
        <span>De verbouwingsspecificatie is overgenomen van de financieringsbehoefte.</span>
      </div>
    </>
  );

  return (
    <AdviesBoxFormik<TaxatieVerbouwingSpecificatieModalType>
      initialValues={{ data }}
      validationSchema={TaxatieVerbouwingSpecificatieModalSchema}
      closeModal={closeModal}
      render={({ values }: FormikProps<TaxatieVerbouwingSpecificatieModalType>): ReactElement => {
        return (
          <>
            <Modal
              noEditableData
              title="Verbouwing"
              body={body(values)}
              onCancelClick={closeModal}
              saveButtonTekst="Sluiten"
            />
          </>
        );
      }}
    />
  );
};

export default TaxatieVerbouwing;
