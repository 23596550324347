import {
  BerekenCurrencyInput,
  BerekenPercentageInput,
  Icon,
  LabeledBevestigingInput,
  LabeledCurrencyInput,
  LabeledDateInput,
  LabeledNumberInput,
  LabeledRadioInput,
  ModalButton,
  LabeledResult,
  TooltipWrap
} from "adviesbox-shared";
import { connect, FormikContextType, FormikHelpers } from "formik";
import React, { ReactElement, useCallback } from "react";
import {
  Garantiesoort,
  DoorlopendLeningdeelMeenemenOptions,
  RentevariantOptions,
  AflossingsVormType
} from "../../.generated/forms/formstypes";
import { RentevariantMetRenteperiodesOutput } from "../../.generated/hypotheekrentes/hypotheekrentestypes";
import { HypotheeklabelDetailsOutput } from "../../.generated/producten/productentypes";
import { LeninggegevensKenmerken } from "../../producten-overzicht/infra/product-kenmerken-types";
import { SituatieSoort } from "../../producten-overzicht/infra/producten-overzicht-types";
import RentevariantPresentation from "../../shared/components/rentevariant/rentevariant-presentation";
import { LabelValuePairs } from "../../shared/types";
import { bindSaveFunction } from "../../shared/utils/bind-save-function";
import { bedragFormat, optellen } from "../../shared/utils/currency";
import { enum2options } from "../../shared/utils/enum-to-options";
import ExtraAflossingenModal from "../extra-aflossingen-modal/extra-aflossingen-modal";
import HypotheekOptiesIngModal from "../hypotheek-opties-ing/hypotheek-opties-ing-modal";
import Hypotheekopties from "../hypotheek-opties/hypotheekopties";
import { getHypotheekTextResources } from "../infra/hypotheek-resources";
import { HypotheekLeningdeelRentevariant, HypotheekType, HypothekenState } from "../infra/hypotheek-types";
import RenteScenarioModal from "../rente-scenario-modal/rente-scenario-modal";
import RentedalingObvMarktwaardePercentageModal from "../rentedaling-obv-marktwaarde-percentage-modal/rentedaling-obv-marktwaarde-percentage-modal";
import classes from "./leningdeelgegevens.module.scss";

type LeningdeelgegevensProps = {
  selected: number;
  situatie: SituatieSoort;
  kenmerken: LeninggegevensKenmerken;
  hypotheekDetailData: HypotheeklabelDetailsOutput | null;
  renteVarianten: RentevariantMetRenteperiodesOutput | null;
};

export const onSaveHypotheekOptiesIngModal = (
  data: HypothekenState,
  setValues: FormikHelpers<HypothekenState>["setValues"]
): void => {
  const updatedProducten: HypotheekType[] = data.producten.map((v, i) => {
    if (
      (!v.product.doorlopend && v.hypotheekOptiesIngPriceToolLeningdeel?.teBetalenRenteData?.percentage) ||
      (v.product.doorlopend &&
        v.hypotheekOptiesIngPriceToolLeningdeel?.meenemen === DoorlopendLeningdeelMeenemenOptions.Rentemiddeling &&
        v.hypotheekOptiesIngPriceToolLeningdeel?.teBetalenRenteData?.percentage)
    ) {
      const update: HypotheekType = {
        ...v,
        leningdeelgegevens: {
          ...v.leningdeelgegevens,
          rentePercentage: {
            bedrag: v.hypotheekOptiesIngPriceToolLeningdeel.teBetalenRenteData.percentage,
            berekenen: false,
            berekendBedrag: v.hypotheekOptiesIngPriceToolLeningdeel.teBetalenRenteData.percentage
          }
        }
      };

      return update;
    }

    return v;
  });

  setValues({
    ...data,
    producten: updatedProducten
  });
};

const Leningdeelgegevens = ({
  selected,
  situatie,
  kenmerken,
  hypotheekDetailData,
  renteVarianten,
  formik: {
    setFieldValue,
    setValues,
    values: { producten, hypotheekOptie, nhg },
    values
  }
}: LeningdeelgegevensProps & {
  formik: FormikContextType<HypothekenState>;
}): ReactElement => {
  const createSaveFunction = useCallback(bindSaveFunction(setFieldValue), [setFieldValue]);
  const selectedProduct = producten[selected];
  const voorstelProduct = producten.find(
    x => x.product.doorlopend === false && x.hypotheekVorm.isStartersLening === false
  );
  const isDoorlopend = situatie === "voorstel" && selectedProduct.product.doorlopend;
  const isHuidigOfDoorlopend = situatie === "huidig" || isDoorlopend;
  const leningdeelbedragLabel = isHuidigOfDoorlopend ? "Restant hoofdsom" : "Leningdeelbedrag";
  const isLineair = selectedProduct.hypotheekVorm.aflossingsvorm === AflossingsVormType.Lineair;
  const isAnnuitair = selectedProduct.hypotheekVorm.aflossingsvorm === AflossingsVormType.Annuïteit;
  const heeftHypotheekOpties = hypotheekOptie.hypotheekOpties.length > 0;
  const isNhgHuidig = producten[selected].leningdeelgegevens.garantie
    ? producten[selected].leningdeelgegevens.garantie === Garantiesoort.Nhg
    : false;

  // Opties vullen
  const renteVariantOptions: LabelValuePairs = selectedProduct.hypotheekVorm.isStartersLening
    ? [{ label: HypotheekLeningdeelRentevariant.Rentevast, value: HypotheekLeningdeelRentevariant.Rentevast }]
    : enum2options(HypotheekLeningdeelRentevariant);

  /* istanbul ignore next */
  const getWarningText = (): string => {
    if (selectedProduct.partijCode === "IN") {
      return getHypotheekTextResources("IngRenteWaarschuwing");
    } else if (selectedProduct.partijCode === "TB") {
      return getHypotheekTextResources("TridiosRenteWaarschuwing");
    } else {
      return getHypotheekTextResources("ASNRenteWaarschuwing");
    }
  };

  return (
    <>
      {situatie === "huidig" && (
        <>
          <LabeledCurrencyInput
            caption={
              selectedProduct.hypotheekVorm.aflossingsvorm === AflossingsVormType.Krediet
                ? "Hoofdsom/ Limiet/ Garantie"
                : "Oorspronkelijke hoofdsom"
            }
            verplicht
            name={`producten[${selected}].leningdeelgegevens.oorspronkelijkeHoofdsom`}
          />
          <LabeledRadioInput
            caption="Garantie"
            name={`producten[${selected}].leningdeelgegevens.garantie`}
            options={[
              { value: Garantiesoort.Geen, label: "Geen" },
              { value: Garantiesoort.Gemeente, label: "Gemeentegarantie" },
              { value: Garantiesoort.Nhg, label: "NHG" }
            ]}
          />
        </>
      )}

      {situatie === "huidig" ? (
        <BerekenCurrencyInput
          caption={leningdeelbedragLabel}
          name={`producten[${selected}].leningdeelgegevens.leningdeelHoofdsom`}
        />
      ) : (
        <LabeledCurrencyInput
          caption={leningdeelbedragLabel}
          name={`producten[${selected}].leningdeelgegevens.leningdeelHoofdsom.bedrag`}
          readonly={
            isDoorlopend ||
            selectedProduct.hypotheekVorm.isStartersLening ||
            selectedProduct.hypotheekVorm.isRestschuldLening
          }
        />
      )}

      {isHuidigOfDoorlopend && (
        <LabeledDateInput
          caption="Datum opgave"
          name={`producten[${selected}].leningdeelgegevens.datumOpgave`}
          readonly={
            isDoorlopend ||
            !!(
              selectedProduct.leningdeelgegevens.leningdeelHoofdsom &&
              selectedProduct.leningdeelgegevens.leningdeelHoofdsom.berekenen
            )
          }
        />
      )}

      {isHuidigOfDoorlopend || selectedProduct.hypotheekVorm.isStartersLening ? (
        <>
          <LabeledRadioInput
            caption="Rentevariant"
            name={`producten[${selected}].leningdeelgegevens.renteVariant`}
            options={renteVariantOptions}
          />

          {producten[selected].leningdeelgegevens.renteVariant === RentevariantOptions.Rentevast && (
            <LabeledNumberInput
              caption="Rentevastperiode"
              name={`producten[${selected}].leningdeelgegevens.rentevastPeriodeJaar`}
              disabled={selectedProduct.hypotheekVorm.isStartersLening}
              appendChildren={<div className="p-1">Jaar</div>}
            />
          )}
        </>
      ) : (
        <RentevariantPresentation
          name={`producten[${selected}].leningdeelgegevens`}
          selected={selected}
          rentevarianten={renteVarianten}
          appendChildren={
            selectedProduct.marktwaardeWaarschuwingTonen && (
              <TooltipWrap
                name="hoofdsom-leeg"
                warningText={selectedProduct.leningdeelgegevens.renteVariantenWaarschuwingText}
                placement="bottom"
                iconType="waarschuwing"
                tooltipClasses="px-1 mt-1"
              />
            )
          }
          onChangeCustom={
            /* istanbul ignore next */ (
              jaren: number,
              percentage: number,
              renteVariant: RentevariantOptions | RentevariantOptions | RentevariantOptions,
              rentebedenktijdMaanden
            ): void => {
              setFieldValue(`producten[${selected}].leningdeelgegevens.rentevastPeriodeJaar`, +jaren);
              setFieldValue(`producten[${selected}].leningdeelgegevens.renteVariant`, renteVariant.toString());
              setFieldValue(
                `producten[${selected}].leningdeelgegevens.renteBedenktijdJaar`,
                rentebedenktijdMaanden / 12
              );
              setFieldValue(`producten[${selected}].leningdeelgegevens.rentePercentage.berekendBedrag`, percentage);
            }
          }
        />
      )}

      {isHuidigOfDoorlopend ? (
        <>
          {producten[selected].leningdeelgegevens.renteVariant === RentevariantOptions.Rentevast && (
            <LabeledNumberInput
              caption="Rentebedenktijd"
              name={`producten[${selected}].leningdeelgegevens.renteBedenktijdJaar`}
              appendChildren={<div className="p-1">Jaar</div>}
            />
          )}
          <LabeledDateInput
            caption="Einddatum"
            name={`producten[${selected}].leningdeelgegevens.einddatum`}
            readonly={situatie === "voorstel" && !isDoorlopend}
          />
        </>
      ) : (
        <>
          {voorstelProduct && voorstelProduct.partijCode === "IN" && (
            <LabeledResult
              caption="Hypotheekopties"
              name="hypotheekOptiesIngPriceTool.ingModalBtn"
              appendChildren={
                <ModalButton
                  parent="hypotheekOptiesIngPriceTool"
                  content={<Icon name="specificatie" alt="HypotheekoptiesIng" />}
                >
                  <HypotheekOptiesIngModal
                    data={values}
                    selected={selected}
                    onSave={(data: HypothekenState) => {
                      onSaveHypotheekOptiesIngModal(data, setValues);
                    }}
                  />
                </ModalButton>
              }
              result={(): string => {
                return `${Object.values(values.hypotheekOptiesIngPriceTool || {}).filter(c => !!c).length ||
                  0} geselecteerd`;
              }}
            />
          )}

          {heeftHypotheekOpties && voorstelProduct && voorstelProduct.partijCode !== "IN" && (
            <Hypotheekopties selected={selected} />
          )}
        </>
      )}
      <LabeledBevestigingInput
        caption={kenmerken.heeftScenarioKorting ? "Automatische rentedaling" : "Rentedaling op verzoek"}
        name={`producten[${selected}].leningdeelgegevens.automatischeRentedaling`}
        readonly={isNhgHuidig || nhg || false}
        appendChildren={
          <div className={classes.modal_btn}>
            <ModalButton
              parent={`producten[${selected}].leningdeelgegevens.automatischeRentedalingModal`}
              content={<Icon name="specificatie" alt="Rentedaling obv marktwaarde percentage" />}
              size="lg"
              render={selectedProduct.leningdeelgegevens.automatischeRentedaling}
              disabled={
                !selectedProduct.leningdeelgegevens.automatischeRentedaling &&
                !selectedProduct.leningdeelgegevens.automatischeRentedalingModal.rentedalingPercentages.length
              }
            >
              {
                <RentedalingObvMarktwaardePercentageModal
                  initialdata={{
                    ...selectedProduct.leningdeelgegevens.automatischeRentedalingModal,
                    marktwaardeRenteBedrag:
                      selectedProduct.leningdeelgegevens.automatischeRentedalingModal.marktwaardeRenteBedrag ??
                      values.panden.find(p => p.pandId === selectedProduct.hypotheekProductDetails?.hypotheekOpWoning)
                        ?.marktwaardeRenteBedrag ??
                      null
                  }}
                  onSave={createSaveFunction(`producten[${selected}].leningdeelgegevens.automatischeRentedalingModal`)}
                  kenmerken={kenmerken}
                  hypotheekDetailData={hypotheekDetailData}
                  situatie={situatie}
                />
              }
            </ModalButton>
          </div>
        }
      />

      <BerekenPercentageInput
        caption="Rentepercentage"
        name={`producten[${selected}].leningdeelgegevens.rentePercentage`}
        hideBerekenenButton={situatie === "huidig"}
        decimalen={3}
        verplicht
        appendChildren={
          <>
            <ModalButton
              parent={`producten[${selected}].leningdeelgegevens.renteScenarioModal`}
              aria-label="Rentescenario button"
              size="lg"
              content={<Icon name="specificatie" alt="Scenario" />}
              render={!producten[selected].leningdeelgegevens.automatischeRentedaling}
            >
              <RenteScenarioModal
                data={producten[selected].leningdeelgegevens.renteScenarioModal}
                onSave={createSaveFunction(`producten[${selected}].leningdeelgegevens.renteScenarioModal`)}
              />
            </ModalButton>
            <div className={classes.modal_btn}>
              {(selectedProduct.partijCode === "TB" ||
                (selectedProduct.partijCode === "IN" && !selectedProduct.leningdeelgegevens.rentePercentage)) && (
                <TooltipWrap
                  name="rente-percentage"
                  warningText={getWarningText()}
                  placement="bottom"
                  iconType="waarschuwing"
                  tooltipClasses="px-1 mt-1"
                />
              )}
            </div>
          </>
        }
      />

      {situatie === "huidig" && !selectedProduct.hypotheekVorm.isStartersLening && (isAnnuitair || isLineair) && (
        <LabeledCurrencyInput
          caption={isAnnuitair ? "Annuïteit" : "Lineaire aflossing"}
          name={`producten[${selected}].leningdeelgegevens.periodiekeAflossing`}
          readonly={isLineair}
          appendChildren={<div className="p-1">Per maand</div>}
          decimalen={2}
        />
      )}

      <LabeledResult
        caption="Extra aflossingen"
        name={`producten[${selected}].leningdeelgegevens.extraAflossing`}
        alignRight={true}
        appendChildren={
          <ModalButton
            parent={`producten[${selected}].leningdeelgegevens.extraAflossing`}
            content={<Icon name="specificatie" alt="Extra aflossingen" />}
          >
            <ExtraAflossingenModal
              benodigdheden={{
                leningdeelBedrag: selectedProduct.leningdeelgegevens.leningdeelHoofdsom.bedrag,
                ingangsdatum: selectedProduct.product.ingangsdatum,
                einddatum: selectedProduct.product.einddatum
              }}
              data={producten[selected].leningdeelgegevens.extraAflossing}
              onSave={createSaveFunction(`producten[${selected}].leningdeelgegevens.extraAflossing`)}
            />
          </ModalButton>
        }
        result={(): string =>
          bedragFormat(
            optellen(
              selectedProduct.leningdeelgegevens.extraAflossing.extraAflossingen.map((x): number | null => x.bedrag)
            )
          )
        }
      />
    </>
  );
};

export default connect<LeningdeelgegevensProps, HypothekenState>(Leningdeelgegevens);
