import {
  FileUpload,
  Icon,
  LabeledBevestigingInput,
  LabeledDateInput,
  LabeledRadioInput,
  LabeledText,
  ModalButton,
  useRequestInit
} from "adviesbox-shared";
import classNames from "classnames";
import { connect, FormikContextType } from "formik";
import React, { ReactElement } from "react";
import { Button } from "react-bootstrap";
import { AdresInput } from "../../shared/components/adres/adres-input";
import LabeledHdnKeuzelijst from "../../shared/components/hdn-keuzelijst/LabeledHdnKeuzelijst";
import OnderpandModalContent from "../../shared/components/onderpand/OnderpandModal";
import { AdresType, Direction } from "../../shared/types";
import { bedragFormat } from "../../shared/utils/currency";
import { getWoningTextResources } from "../infra/woning-resources";
import { SoortFoto, WoningState } from "../infra/woning-schema";
import { uploadFotoVanWoningApi, getBlobUrlApi } from "../woning-api/woning-api";
import classes from "../woning-scherm.module.scss";

export const createDuurzaamLink = (adres: AdresType): string => {
  if (adres.huisnummer && adres.postcode) {
    return `https://www.duurzaamheidsprofiel.nl/?postal_code=${adres.postcode}&house_number=${adres.huisnummer}`;
  }
  if (adres.huisnummer && !adres.postcode) {
    return `https://www.duurzaamheidsprofiel.nl/?house_number=${adres.huisnummer}`;
  }
  if (!adres.huisnummer && adres.postcode) {
    return `https://www.duurzaamheidsprofiel.nl/?postal_code=${adres.postcode}`;
  }
  return `https://www.duurzaamheidsprofiel.nl`;
};

type AanTeKopenWoningProps = {};
const AanTeKopenWoning = ({
  formik
}: AanTeKopenWoningProps & {
  formik: FormikContextType<WoningState>;
}): ReactElement => {
  const { settings, params, user } = useRequestInit<{ vestiging: string; adviesdossier: string }>();
  const { adresGelijkAanWoonadres, adres } = formik.values.aanTeKopenWoning;

  const onderpandTekst = formik.values.aanTeKopenWoning.onderpand.bouwjaar
    ? `Bouwjaar:  ${formik.values.aanTeKopenWoning.onderpand.bouwjaar}` +
      (formik.values.aanTeKopenWoning.onderpand.serviceKosten &&
      formik.values.aanTeKopenWoning.onderpand.serviceKosten > 0
        ? `, Servicekosten:  ${bedragFormat(formik.values.aanTeKopenWoning.onderpand.serviceKosten, 2)}`
        : "")
    : "Geen gegevens ingevuld";

  const opnemenFotoOpties = [
    { value: SoortFoto.Eigen, label: "Foto zelf uploaden" },
    { value: SoortFoto.GoogleMaps, label: "Foto van Google maps" }
  ];

  const aanTeKopenWoning = formik.values.aanTeKopenWoning;

  return (
    <div>
      <LabeledHdnKeuzelijst
        caption="Object soort"
        name="aanTeKopenWoning.onderpand.objectSoort"
        keuzelijst="ObjectSoortType"
        berichtSoortType="AX"
        appendLabelChildren={
          <span style={{ marginLeft: "5px" }}>
            <sub>
              <a
                href={"https://support.adviesbox.nl/portal/nl/kb/articles/object-soort"}
                className="link"
                target="_blank"
                rel="noopener noreferrer"
              >
                Toelichting
              </a>
            </sub>
          </span>
        }
      />

      <LabeledText
        id="onderpand-label"
        caption="Onderpand"
        fieldSize="no-size"
        value={onderpandTekst}
        appendChildren={
          <ModalButton
            parent="aanTeKopenWoning.onderpand"
            aria-label={getWoningTextResources("OnderPandSpecificatieKnop")}
            content={<Icon name="specificatie" alt="Onderpand specificatie" />}
          >
            <OnderpandModalContent
              data={aanTeKopenWoning.onderpand}
              onSave={(result): void => {
                const values: WoningState = {
                  ...formik.values,
                  aanTeKopenWoning: {
                    ...aanTeKopenWoning,
                    onderpand: { ...result, gebruikPand: formik.values.aanTeKopenWoning.onderpand.gebruikPand }
                  }
                };
                formik.setValues(values);
              }}
            />
          </ModalButton>
        }
      />

      <LabeledHdnKeuzelijst
        caption="Huidig energielabel"
        name="aanTeKopenWoning.energieLabel"
        keuzelijst="EnergieKlasseType"
        berichtSoortType="AX"
        tooltip={getWoningTextResources("TooltipEnergieLabel")}
      />

      <div className="form-group form-row  mt-2">
        <div className="col-md-6 col-12">
          <a
            href={createDuurzaamLink(adres)}
            className="link"
            target="_blank"
            rel="noopener noreferrer"
            id={`duurzaamheidsprofiel-link`}
          >
            <span className="ml-3">Duurzaamheidsprofiel</span> <i className="icon externallink" />
          </a>
        </div>
      </div>

      <LabeledDateInput caption="Datum energieklasse" name="aanTeKopenWoning.energieklasseDatum" />

      <LabeledBevestigingInput caption="Adres gelijk aan woonadres" name="aanTeKopenWoning.adresGelijkAanWoonadres" />

      <AdresInput readonly={adresGelijkAanWoonadres} name={"aanTeKopenWoning.adres"} />

      <LabeledBevestigingInput caption="Foto opnemen in rapportage" name="aanTeKopenWoning.fotoGebruikRapportage" />

      {aanTeKopenWoning.fotoGebruikRapportage && (
        <>
          <LabeledRadioInput
            caption="Op te nemen foto"
            options={opnemenFotoOpties}
            name="aanTeKopenWoning.soortFoto"
            layout={Direction.Vertical}
          />

          {/* Alleen indien we google maps gebruiken en er een postcode + huisnr +straat aanwezig is (om aantal calls te beperken) 
              na invullen van postcode + huisnr dient postcode-aanvulling uitgevoerd te worden, wanneer deze terug komt volledig adres 
              wordt onderstaande uitgevoerd: we willen zo min mogelijk calls naar Google, ivm kosten:
          */}

          {aanTeKopenWoning.soortFoto === SoortFoto.GoogleMaps && aanTeKopenWoning.locatieFoto?.includes("google") && (
            <img
              data-testid="google-maps-image"
              className={classNames("maps-image", classes.img)}
              src={aanTeKopenWoning.locatieFoto}
              alt="Aan te kopen woning"
            />
          )}

          {aanTeKopenWoning.soortFoto === SoortFoto.Eigen && aanTeKopenWoning.locatieFoto?.includes("blob") && (
            <div>
              <img
                data-testid="blob-image"
                className={classNames("maps-image", classes.img)}
                src={aanTeKopenWoning.locatieFoto}
                alt="Aan te kopen woning"
              />
              <Button
                data-testid="btn-verwijder-woning-foto"
                id="btn-verwijder-woning-foto"
                variant="outline-secondary"
                onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
                  event.stopPropagation();
                  formik.setFieldValue("aanTeKopenWoning.locatieFoto", null);
                }}
              >
                <Icon name="prullenbak" alt="Verwijderen" />
              </Button>
            </div>
          )}

          {aanTeKopenWoning.soortFoto === SoortFoto.Eigen && !aanTeKopenWoning.locatieFoto?.includes("blob") && (
            <div className={classes.img}>
              <FileUpload
                name="aanTeKopenWoning.locatieFoto"
                variant="Afbeeldingen"
                updateRef={async (f: File[]) => {
                  const file = f[0];
                  const formData = new FormData();
                  formData.append("fotoWoningBestand", file ?? new Blob());
                  const success = await uploadFotoVanWoningApi(
                    settings,
                    user,
                    params.vestiging,
                    params.adviesdossier,
                    formData,
                    formik
                  );
                  if (!!success) {
                    const locatieFoto = await getBlobUrlApi(settings, user, params.vestiging, params.adviesdossier);
                    formik.setFieldValue("aanTeKopenWoning.locatieFoto", locatieFoto);
                  }
                }}
                multipleFiles={false}
                hideFormats
                allowExtensions={[".png", ".jpg", ".jpeg"]}
                buttonText="Kies een bestand"
                title={
                  <>
                    <div className={classes.bold_margin}>Sleep uw foto in het vak</div>
                    <div className={classes.italic_margin}>Ondersteunde formaten: JPG, PNG</div>
                  </>
                }
              />
            </div>
          )}

          {/* Ophalen wordt nu via image url gedaan, heb dit laten staan voor als er toch teruggedraaid wordt als adres wel opgeslagen kan worden*/}
          {/* {aanTeKopenWoning.soortFoto === SoortFoto.GoogleMaps &&
            aanTeKopenWoning.adres.postcode &&
            aanTeKopenWoning.adres.huisnummer &&
            aanTeKopenWoning.adres.straat && (
              <MapsImage address={aanTeKopenWoning.adres} />
            )} */}
        </>
      )}
    </div>
  );
};

export default connect<AanTeKopenWoningProps, WoningState>(AanTeKopenWoning);
