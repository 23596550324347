import { createISWSideEffect, initISWSideEffect } from "../../../shared/components/isw-side-effects/create-isw-helpers";
import { DashboardState } from "../../infra/dashboard-types";
import { updateDataOutdated } from "../../infra/scenario-side-effects";
import {
  syncScenarioMinimaalUitgangspuntSideEffects,
  syncScenarioVanSideEffects
} from "../../infra/scenario-van-side-effects";
import { BeschikbareJaarruimteType } from "./scenario-pensioen-modal-schema";
import { syncControleNormSideEffects } from "../../components/controle-norm/infra/controle-norm-sideeffects";

export const syncScenarioPensioenModalSideEffects = createISWSideEffect<BeschikbareJaarruimteType | null>(
  ({ has, draft }): void => {
    if (!draft) return;

    if (
      has.winstOndernemingsaftrek.bedrag.changed ||
      has.inkomenHuidigeDienstbetrekking.bedrag.changed ||
      has.inkomenVroegereDienstbetrekking.bedrag.changed ||
      has.buitenlandseInkomsten.changed ||
      has.reisaftrekWoonWerkverkeer.changed ||
      has.resultaatOverigeWerkzaamheden.bedrag.changed ||
      has.periodiekeUitkering.changed ||
      has.pensioenaangroeiBedrag.changed ||
      has.toevoegingOudedagreserve.changed ||
      has.benutteJaarruimteBedrag.changed
    ) {
      //TODO: check the use of benutteJaarruimteOvernemen and implement
      const premiegrondslagBedrag =
        (draft.winstOndernemingsaftrek.bedrag || 0) +
        (draft.inkomenHuidigeDienstbetrekking.bedrag || 0) +
        (draft.inkomenVroegereDienstbetrekking.bedrag || 0) +
        (draft.buitenlandseInkomsten || 0) +
        (draft.reisaftrekWoonWerkverkeer || 0) +
        (draft.resultaatOverigeWerkzaamheden.bedrag || 0) +
        (draft.periodiekeUitkering || 0);

      draft.premiegrondslagBedrag = negativeValuesToZero(Math.min(premiegrondslagBedrag, draft.maxPremieGrondslag));

      draft.subtotaalFranchise1Bedrag = negativeValuesToZero(draft.premiegrondslagBedrag - draft.franchiseBedrag);
      draft.subtotaalFranchise2Bedrag = negativeValuesToZero(
        draft.subtotaalFranchise1Bedrag * draft.premiegrondslagPercentage
      );
      draft.subtotaalPensioenaangroeiBedrag = negativeValuesToZero(
        (draft.pensioenaangroeiBedrag || 0) * draft.pensioenaangroeiPercentage
      );

      draft.jaarruimteBedrag = negativeValuesToZero(draft.subtotaalFranchise2Bedrag);
      draft.beschikbareJaarruimteBedrag = negativeValuesToZero(
        draft.jaarruimteBedrag - (draft.benutteJaarruimteBedrag.bedrag || 0)
      );
    }
  }
);

function negativeValuesToZero(value: number): number {
  return Math.max(0, value);
}

export const initScenarioPensioenModalSideEffects = initISWSideEffect<DashboardState>(({ has, draft, subset }) => {
  syncControleNormSideEffects(subset.pensioen.controleNorm.create());
  syncScenarioPensioenModalSideEffects(subset.pensioen.beschikbareJaarruimteAanvrager.create());
  syncScenarioPensioenModalSideEffects(subset.pensioen.beschikbareJaarruimtePartner.create());
  syncScenarioVanSideEffects(subset.pensioen.scenarioVan.create());

  subset.pensioenUitgangspunten !== null &&
    syncScenarioMinimaalUitgangspuntSideEffects(subset.pensioen.controleNorm.create());

  if (has.pensioen.changed) {
    updateDataOutdated(draft, draft.pensioen.scenarioVan, has.pensioen.scenarioVan);
  }
})();
