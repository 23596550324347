import React, { ReactElement, useEffect } from "react";
import produce from "immer";
import { LocalDate } from "@js-joda/core";
import { LabeledRadioInput } from "adviesbox-shared";

import { JaarMaandNoNull } from "../../shared/types";

import { connect, FormikContextType } from "formik";
import { getFormattedDateMetLangeMaand } from "../../shared/utils/dates";
import { VergoedingenOverzichtState } from "../infra/vergoedingen-overzicht-schema";

type HypotheekNormPresentationProps = {
  toetsinglijst: JaarMaandNoNull[];
  name: string;
  caption: string;
  tooltip?: string;
};

const HypotheekNormPresentation = ({
  formik: { resetForm, values },
  toetsinglijst,
  name,
  caption,
  tooltip
}: HypotheekNormPresentationProps & { formik: FormikContextType<VergoedingenOverzichtState> }): ReactElement => {
  const reversedToetsingLijst = [...toetsinglijst].reverse();

  useEffect(() => {
    if (values.vergoedingenOverzicht.hypotheekNorm === null) {
      const next = produce(values, v => {
        v.vergoedingenOverzicht.hypotheekNormJaarMaand = `${reversedToetsingLijst[0].jaar},${reversedToetsingLijst[0].maand}`;
        v.vergoedingenOverzicht.hypotheekNorm = {
          jaar: reversedToetsingLijst[0].jaar,
          maand: reversedToetsingLijst[0].maand
        };
      });
      resetForm({ values: next });
    }
  }, [reversedToetsingLijst, resetForm, values]);

  const datum = LocalDate.of(reversedToetsingLijst[0].jaar, reversedToetsingLijst[0].maand, 1);
  const normDatum = getFormattedDateMetLangeMaand(datum);

  const hypotheeknormOptions = [
    {
      label: `Voor ${normDatum}`,
      value: `${reversedToetsingLijst[1].jaar},${reversedToetsingLijst[1].maand}`
    },
    {
      label: `Vanaf ${normDatum}`,
      value: `${reversedToetsingLijst[0].jaar},${reversedToetsingLijst[0].maand}`
    }
  ];
  return <LabeledRadioInput caption={caption} name={name} options={hypotheeknormOptions} tooltip={tooltip} />;
};

export default connect<HypotheekNormPresentationProps, VergoedingenOverzichtState>(HypotheekNormPresentation);
