/* istanbul ignore file */
import * as Yup from "yup";
import { yupEnum } from "../../shared/utils/yup-enum";
import { StatusOptions } from "../../.generated/externekoppelingen/externekoppelingentypes";

export enum DataType {
  HDNKeuzelijst = "HDNKeuzelijst",
  EntityObject = "EntityObject",
  Date = "Date",
  Integer = "Integer",
  Decimal = "Decimal",
  Boolean = "Boolean",
  Alphanumeric = "Alphanumeric",
  Telefoon = "Telefoon",
  Email = "Email",
  Postcode = "Postcode",
  Iban = "Iban",
  Enum = "Enum"
}

export enum FoutType {
  Required = "Required",
  InvalidValue = "InvalidValue",
  MaxLengthExceeded = "MaxLengthExceeded",
  TypeMismatch = "TypeMismatch"
}

export const validationError = Yup.object({
  jsonPath: Yup.string(),
  dataType: yupEnum(DataType),
  maxLength: Yup.string(),
  platformDataType: Yup.string(),
  foutType: yupEnum(FoutType),
  omschrijving: Yup.string().nullable(),
  mogelijkeWaarden: Yup.array(Yup.string())
});

const checkFoutType = (error: validationErrorType, value: any): string | null => {
  let errorMessage: string | null = null;
  switch (error.foutType) {
    case FoutType.MaxLengthExceeded:
      if (error.maxLength && value.length > parseInt(error.maxLength)) {
        errorMessage = error.omschrijving ?? "Maximale lengte overschreden";
      }
      break;
    case FoutType.Required:
      if (!value) {
        errorMessage = error.omschrijving ?? "Veld is verplicht";
      }
      break;
    case FoutType.TypeMismatch:
    case FoutType.InvalidValue:
      break;
  }
  return errorMessage;
};

const checkFoutCustom = (error: validationErrorType, value: any): string | null => {
  let errorMessage: string | null = null;

  const phoneNumberAllowEmptyRegex = /^$|^\+?-?[\d\s-]{6,19}$/;
  const emailAllowEmptyRegex = /^(([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)|)$/i;
  const postcodeRegex = /^$|^[1-9][0-9]{3}[\s]?[A-Za-z]{2}$/i;

  switch (error.dataType) {
    case DataType.Email:
      if (!emailAllowEmptyRegex.test(value)) {
        errorMessage = "Ongeldig e-mailadres";
      }
      break;
    case DataType.Telefoon:
      if (!phoneNumberAllowEmptyRegex.test(value)) {
        errorMessage = "Ongeldig telefoonnummer";
      }
      break;
    case DataType.Postcode:
      if (!postcodeRegex.test(value)) {
        errorMessage = "Ongeldige postcode";
      }
      break;
    case DataType.Date:
      const dateObj = new Date(value);
      if (isNaN(dateObj.getTime())) {
        errorMessage = "Ongeldige datum";
      }
      break;
    case DataType.Enum:
      if (error.mogelijkeWaarden.findIndex(x => x === value) < 0) {
        errorMessage = "Ongeldige keuze";
      }
      break;
    case DataType.Integer:
      if (isNaN(value)) {
        errorMessage = "Ongeldig getal";
      }
  }
  return errorMessage;
};

export const labelValuePair = Yup.object({
  jsonPath: Yup.string(),
  label: Yup.string(),
  value: Yup.string()
    .nullable()
    .default(null)
    .test({
      test: function(value: any) {
        const error: validationErrorType =
          this.parent.validationErrors?.length > 0 ? this.parent.validationErrors[0] : null;
        if (!error) {
          return true;
        }

        const foutTypeError = checkFoutType(error, value);
        if (foutTypeError) return this.createError({ message: foutTypeError });

        const foutCustomError = checkFoutCustom(error, value);
        if (foutCustomError) return this.createError({ message: foutCustomError });

        return true;
      }
    }),
  validationErrors: Yup.array(validationError).default([])
});

export const fieldMap = Yup.object({
  prefix: Yup.string(),
  parent: Yup.string(),
  labelValues: Yup.array(labelValuePair)
});

export const importSchema = Yup.object({
  koppelingKey: Yup.string(),
  importTaakId: Yup.string(),
  fieldMaps: Yup.array(fieldMap),
  status: yupEnum(StatusOptions)
});

export type fieldMap = Yup.InferType<typeof fieldMap>;
export type labelValuePairType = Yup.InferType<typeof labelValuePair>;
export type importState = Yup.InferType<typeof importSchema>;
export type importProps = importState;
export type validationErrorType = Yup.InferType<typeof validationError>;
