import {
  LabeledAfbeelding,
  LabeledSelectInput,
  LabeledText,
  LabeledTextInput,
  SettingsContext
} from "adviesbox-shared";
import { connect, FormikContextType, useFormikContext } from "formik";
import React, { ReactElement, useContext } from "react";
import { TaxatiebureauPartij } from "../../.generated/instellingen-forms/instellingen-formstypes";
import { LabelValuePairs } from "../../shared/types";
import { OnafhankelijkCode } from "../infra/taxatie-bureaus-helper";
import {
  taxatieSchermSchema,
  TaxatieSchermType
} from "../infra/taxatie-scherm-schema";
import { TaxatieSoortAanvraagOptions } from "../../.generated/forms/formstypes";

type taxatieBureauProps = { taxatiebureauOpties: TaxatiebureauPartij[] };
const Taxatiebureau = ({
  taxatiebureauOpties,
  formik: {
    setFieldValue,
    setValues,
    values: { taxatiebureau, TaxatieSoortAanvraagOptionsOpties }
  }
}: taxatieBureauProps & {
  formik: FormikContextType<TaxatieSchermType>;
}): ReactElement => {
  const formik = useFormikContext<TaxatieSchermType>();
  const { productenOrigin, OcpApimSubscriptionKey } = useContext(SettingsContext);
  const maatschappijCode = taxatiebureau?.maatschappijCode;
  const soortAanvraagDropdownTonen = (!!maatschappijCode);
  const andersFieldTonen =
    soortAanvraagDropdownTonen && taxatiebureau?.soortAanvraag === TaxatieSoortAanvraagOptions.Anders;

  const soortAanvraagOpties: LabelValuePairs = TaxatieSoortAanvraagOptionsOpties.map(v => ({
    label: v.label,
    value: v.value
  }));

  const taxatiebureausDropdownOptions: LabelValuePairs = [];

  taxatiebureauOpties
    .forEach(bureau => {
      taxatiebureausDropdownOptions.push({ label: bureau.partijnaam ??  /* istanbul ignore next */ "", value: bureau.code ?? /* istanbul ignore next */ "" });
    });
  taxatiebureausDropdownOptions.sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));

  const handleTaxatieBureauSelectie = (event: { target: { value: string | null } }): void => {
    if (event.target.value === "") {
      const def: TaxatieSchermType = taxatieSchermSchema.default();
      setValues({
        ...formik.values,
        taxatiebureau: null,
        opdrachtgever: def.opdrachtgever,
        taxatieObject: null,
        partner: null,
        bouwkundigeKeuring: null,
        taxatieAlgemeen: null,
        contactpersoon: null,
        aanvullendeInformatie: null
      });
    } else {
      const geselecteerdeBureau = taxatiebureauOpties.find(e => e.code === event.target.value);
      setFieldValue("isLoading", true);
      setFieldValue("taxatiebureau.maatschappijCode", geselecteerdeBureau?.code);
      setFieldValue("taxatiebureau.agentnummer", geselecteerdeBureau?.agentnummer);
    }
  };

  const handleSoortAanvraagSelectie = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    if (event.target.value === "") {
      setFieldValue("taxatiebureau.soortAanvraag", null);
      setFieldValue("taxatiebureau.taxatieProductNaam", null);
      setFieldValue("taxatiebureau.productCode", null);
    } else {
      setFieldValue("taxatiebureau.soortAanvraag", event.target.value);
      const index = event.target.selectedIndex;
      const innerText = event.target.options[index].innerText;
      setFieldValue("taxatiebureau.taxatieProductNaam", innerText);
    }
  };

  return (
    <>
      {taxatiebureausDropdownOptions.length > 1 ? (
        <LabeledSelectInput
          caption="Taxatiebureau/-instituut"
          name="taxatiebureau.maatschappijCode"
          options={taxatiebureausDropdownOptions}
          onChange={handleTaxatieBureauSelectie}
          emptyValue="Selecteer een optie"
        />
      ) : (
        <LabeledText
          caption="Taxatiebureau/-instituut"
          fieldSize="no-size"
          value={taxatiebureauOpties?.find(v => v.code === taxatiebureau?.maatschappijCode)?.partijnaam ?? "Geen"}
        />
      )}
      {taxatiebureau &&
        taxatiebureau?.maatschappijCode !== OnafhankelijkCode &&
        taxatiebureau?.maatschappijCode !== null && (
          <div className="mb-2 mt-2">
            <LabeledAfbeelding
              caption="Logo"
              name={`taxatiebureau.maatschappijCode.logo`}
              location={`${productenOrigin}/Logos/Logo/${taxatiebureau?.maatschappijCode}?subscription-key=${OcpApimSubscriptionKey}`}
              style={{ maxHeight: 50 }}
            />
          </div>
        )}

        <LabeledSelectInput
          caption="Soort aanvraag"
          name={`taxatiebureau.soortAanvraag`}
          onChange={handleSoortAanvraagSelectie}
          options={soortAanvraagOpties}
          readonly={soortAanvraagOpties.length === 1}
          emptyValue="Selecteer een optie"
        />

      {andersFieldTonen && <LabeledTextInput caption="Anders, te weten" name="taxatiebureau.andersTeWeten" />}
    </>
  );
};

export default connect<taxatieBureauProps, TaxatieSchermType>(Taxatiebureau);
