import React, { ReactElement } from "react";
import { connect, FormikContextType } from "formik";

import HypotheekNorm from "../hypotheek-norm/hypotheek-norm";
import { FinancieringOptions } from "../../.generated/forms/formstypes";
import { getVergoedingenOverzichtTextResources } from "../infra/vergoedingen-overzicht-resources";

import {
  LabeledCurrencyInput,
  ResetButton,
  BerekenCurrencyButton,
  LabeledDateInput,
  LabeledNumberInput,
  LabeledResult
} from "adviesbox-shared";
import { bedragFormat, optellen } from "../../shared/utils/currency";
import { VergoedingenOverzichtState } from "../infra/vergoedingen-overzicht-schema";

const VergoedingenOverzichtCard = ({
  formik
}: {
  formik: FormikContextType<VergoedingenOverzichtState>;
}): ReactElement | null => {
  const {
    advieskostenOvernemen,
    bemiddelingsvergoedingGeldleningOvernemen,
    overigeBemiddelingsvergoedingOvernemen
  } = formik.values.vergoedingenOverzicht;

  const vergoedingenOverzicht = formik.values.vergoedingenOverzicht;

  const financieringGeselecteerd = formik.values.financiering !== FinancieringOptions.GeenFinanciering;
  const advieskostenPerUur = vergoedingenOverzicht.vergoedingenOverzichtInstellingenTarieven.advieskostenPerUur;
  const advieskostenUurTarief = financieringGeselecteerd
    ? vergoedingenOverzicht.vergoedingenOverzichtInstellingenTarieven.hypotheekAdvieskostenUurTarief
    : vergoedingenOverzicht.vergoedingenOverzichtInstellingenTarieven.advieskostenUurTarief;
  const advieskostenDefault = vergoedingenOverzicht.vergoedingenOverzichtInstellingenTarieven.advieskostenDefaultBedrag;
  const geldleningPerUur = vergoedingenOverzicht.vergoedingenOverzichtInstellingenTarieven.geldleningPerUur;
  const geldleningUurTarief =
    vergoedingenOverzicht.vergoedingenOverzichtInstellingenTarieven.bemiddelingsvergoedingGeldleningUurTarief;
  const geldleningDefault =
    vergoedingenOverzicht.vergoedingenOverzichtInstellingenTarieven.bemiddelingsvergoedingGeldleningDefaultBedrag;
  const overigeVergoedingPerUur =
    vergoedingenOverzicht.vergoedingenOverzichtInstellingenTarieven.overigeVergoedingPerUur;
  const overigeVergoedingDefault =
    vergoedingenOverzicht.vergoedingenOverzichtInstellingenTarieven.overigeBemiddelingsvergoedingDefaultBedrag;
  const overigeVergoedingUurTarief =
    vergoedingenOverzicht.vergoedingenOverzichtInstellingenTarieven.overigeBemiddelingsvergoedingUurTarief;
  const dossiervergoedingDefault =
    vergoedingenOverzicht.vergoedingenOverzichtInstellingenTarieven.dossiervergoedingDefaultBedrag;

  return (
    <>
      <LabeledDateInput caption="Verwachte passeerdatum" name="vergoedingenOverzicht.ingangsdatum" verplicht />

      <HypotheekNorm
        name="vergoedingenOverzicht.hypotheekNormJaarMaand"
        caption="NHG- en hypotheeknorm"
        tooltip={getVergoedingenOverzichtTextResources("TooltipHypotheekNorm")}
      />
      {advieskostenPerUur && (
        <LabeledNumberInput
          caption="(geschatte) uren aan advies"
          name="vergoedingenOverzicht.advieskostenAantalUur"
          appendChildren={<span className="d-flex align-items-center">uur à € {advieskostenUurTarief}</span>}
        />
      )}

      <LabeledCurrencyInput
        caption="Advieskosten"
        name="vergoedingenOverzicht.advieskostenBedrag"
        disabled={advieskostenOvernemen && advieskostenPerUur}
        tooltip={getVergoedingenOverzichtTextResources("TooltipAdvieskosten")}
        appendChildren={
          <>
            {advieskostenPerUur ? (
              <BerekenCurrencyButton
                name="vergoedingenOverzicht.advieskostenOvernemen"
                berekenen={advieskostenOvernemen}
              />
            ) : (
              <ResetButton
                name="vergoedingenOverzicht.advieskostenBedrag"
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                defaultValue={advieskostenDefault!}
              />
            )}
          </>
        }
      />

      {financieringGeselecteerd && (
        <>
          {geldleningPerUur && (
            <LabeledNumberInput
              caption="(geschatte) uren bemiddeling geldlening"
              name="vergoedingenOverzicht.bemiddelingsvergoedingGeldleningAantalUur"
              appendChildren={<span className="d-flex align-items-center">uur à € {geldleningUurTarief}</span>}
            />
          )}

          <LabeledCurrencyInput
            caption="Bemiddelingsvergoeding geldlening"
            name="vergoedingenOverzicht.bemiddelingsvergoedingGeldleningBedrag"
            disabled={(bemiddelingsvergoedingGeldleningOvernemen ?? false) && geldleningPerUur}
            tooltip={getVergoedingenOverzichtTextResources("TooltipBemiddelingsvergoedingGeldlening")}
            appendChildren={
              <>
                {geldleningPerUur ? (
                  <BerekenCurrencyButton
                    name="vergoedingenOverzicht.bemiddelingsvergoedingGeldleningOvernemen"
                    berekenen={bemiddelingsvergoedingGeldleningOvernemen ?? false}
                  />
                ) : (
                  <ResetButton
                    name="vergoedingenOverzicht.bemiddelingsvergoedingGeldleningBedrag"
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    defaultValue={geldleningDefault!}
                  />
                )}
              </>
            }
          />
        </>
      )}
      {overigeVergoedingPerUur && (
        <LabeledNumberInput
          caption="(geschatte) uren overige bemiddeling"
          name="vergoedingenOverzicht.overigeBemiddelingsvergoedingAantalUur"
          appendChildren={<span className="d-flex align-items-center">uur à € {overigeVergoedingUurTarief}</span>}
        />
      )}

      <LabeledCurrencyInput
        caption="Overige bemiddelingsvergoeding"
        name="vergoedingenOverzicht.overigeBemiddelingsvergoedingBedrag"
        disabled={overigeBemiddelingsvergoedingOvernemen && overigeVergoedingPerUur}
        tooltip={getVergoedingenOverzichtTextResources("TooltipOverigeBemiddelingsvergoeding")}
        appendChildren={
          <>
            {overigeVergoedingPerUur ? (
              <BerekenCurrencyButton
                name="vergoedingenOverzicht.overigeBemiddelingsvergoedingOvernemen"
                berekenen={overigeBemiddelingsvergoedingOvernemen}
              />
            ) : (
              <ResetButton
                name="vergoedingenOverzicht.overigeBemiddelingsvergoedingBedrag"
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                defaultValue={overigeVergoedingDefault!}
              />
            )}
          </>
        }
      />

      {financieringGeselecteerd && (
        <LabeledCurrencyInput
          caption="Dossiervergoeding geldlening"
          name="vergoedingenOverzicht.dossiervergoedingBedrag"
          tooltip={getVergoedingenOverzichtTextResources("TooltipDossierVergoedingBedrag")}
          appendChildren={
            <ResetButton
              name="vergoedingenOverzicht.dossiervergoedingBedrag"
              defaultValue={dossiervergoedingDefault || 0}
            />
          }
        />
      )}
      <LabeledResult
        caption="Totaal"
        name="vergoedingenOverzicht.totaal"
        alignRight={true}
        result={(): string =>
          bedragFormat(
            optellen([
              formik.values.vergoedingenOverzicht.advieskostenBedrag,
              formik.values.vergoedingenOverzicht.bemiddelingsvergoedingGeldleningBedrag,
              formik.values.vergoedingenOverzicht.overigeBemiddelingsvergoedingBedrag,
              formik.values.vergoedingenOverzicht.dossiervergoedingBedrag
            ])
          )
        }
      />
    </>
  );
};

export default connect<{}, VergoedingenOverzichtState>(VergoedingenOverzichtCard);
