import { createGetTextResources } from "../../shared/utils/text-resources-algemeen";
import { LocalDate } from "@js-joda/core";

export const getInkomenEnFiscusTextResources = createGetTextResources({
  TooltipFiscaliteitBeleggingsKorting: `De heffingskorting op groene beleggingen is 0,7% van de vrijstelling in box 3.`,

  TooltipInkomenEnFiscusFiscaleVerdeling: `Bij de keuze 'Optimale verdeling' wordt hiernaast de optimale verdeling in de huidige situatie getoond. In de berekeningen en de rapportage wordt per jaar de optimale fiscale verdeling bepaald.`,

  TooltipInkomenUitArbeidInkomensverleden: `Het toetsinkomen is het gemiddelde jaarinkomen van de afgelopen drie kalenderjaren met als maximum het laatste jaar. Het laatste jaarinkomen wordt gebruikt als toekomstig inkomen in de (inkomens)scenario's. Je kunt het toekomstig inkomen aanpassen in het specificatiescherm 'Scenario bruto salaris uit dienstverband'.`,
  TooltipInkomenUitArbeidAantalUrenPerWeek: `Het aantal uren per week is hoger dan 40 uur. Adviesbox neemt het ingevulde inkomen mee in alle berekeningen. Mogelijk heeft de hoogte van het aantal uren impact op sociale uitkeringen, zijn er fiscale gevolgen of is er sprake van een afwijkende toetsing door partijen. Hier wordt geen rekening mee gehouden.`,
  TooltipInkomenUitArbeidEinddatumContract: `Het inkomen blijft standaard doorlopen in het scenario langleven. Je kunt dit wijzigen via het specificatiescherm bij 'Bruto salaris uit dienstverband'.`,
  TooltipInkomenUitArbeidInkomstentoeslag: `Vul hier de inkomsten in uit toeslagen die structureel mee kunnen tellen, bijvoorbeeld diplomatoeslag, gevarentoeslag en oefeningtoeslag.`,

  TooltipOndernemingIngangsdatum: `Dit is de ingangsdatum bij KvK.`,
  TooltipOndernemingDga: `We gaan ervan uit dat de DGA geen recht op sociale verzekeringen heeft.`,
  TooltipOndernemingBoi: `BOI staat voor Bepaling Ondernemers Inkomen. Het BOI-nummer is het nummer dat gekoppeld is aan de inkomensverklaring ondernemer (IVO).`,

  TooltipOverigeInkomstenInkomenMeetellenTot: `Het inkomen wordt standaard meegeteld tot de ingangsdatum van de AOW. Door op het pennetje te klikken kun je dit aanpassen.`,
  TooltipOverigeInkomstenVrijVeld: `De overige inkomsten worden niet als inkomen meegenomen in de maximale hypotheekberekening. Wil je dit toch? Dan kun je dit aanpassen op het scherm 'Huidige situatie - Overig'. Voer een bijtelpost met fiscale bijtelling op en geef aan dat het onderdeel van 'Inkomen uit arbeid' is.`,

  TooltipSociaalWekeneis: `Om aan de wekeneis te voldoen moet de aanvrager in de 26 van de afgelopen 36 weken hebben gewerkt. In geval van werkloosheid krijgt de aanvrager een basisuitkering van drie maanden. Het maakt niet uit hoeveel uur per week gewerkt is in die 26 weken.`,
  TooltipSociaalJareneis: `Om aan de jareneis te voldoen moet de aanvrager in de laatste vijf jaar minimaal vier kalenderjaren hebben gewerkt. Daarnaast moet de aanvrager in elk van deze vier jaren minimaal 52 dagen hebben gewerkt. Ook geldt dat vanaf 1 januari 2013 er over minimaal 208 uur loon moet zijn ontvangen.`,
  TooltipSociaalFeitelijkArbeidsverleden: `Het feitelijk arbeidsverleden is het aantal jaar vanaf 1998 tot en met 2012 waarin minimaal 52 dagen per jaar is gewerkt. En het aantal jaar vanaf 2013 waarin minimaal 208 uur sv-loon is ontvangen. Het jaar waarin de WW-uitkering begint, telt niet mee. In de scenario's gaan we voor het kalenderjaar ${LocalDate.now().year()} uit van een extra jaar opbouw van arbeidsverleden (volgens de Wet Werk en zekerheid).`,

  TooltipToetsinkomenUwv: `Vul hier het toetsinkomen in dat is berekend op basis van het sv-loon uit het verzekeringsbericht van het UWV.`,

  WarningInkomenUitArbeidBuitenlandsInkomen: `Let op: buitenlands inkomen wordt gebruikt voor de acceptatie en de aanvraag richting de maatschappij. Dit heeft geen invloed op de (fiscale) berekeningen en overzichten.`,
  WarningTitleInkomenUitArbeidVerwijderen: `Inkomen uit arbeid verwijderen?`,
  WarningTekstInkomenUitArbeidVerwijderen: `Weet je zeker dat je de inkomens uit arbeid wilt verwijderen?`,
  WarningConfirmButtonkomenUitArbeidVerwijderen: `Inkomens verwijderen`,

  ErrorInkomensverledenJaar: `Vul een boekjaar tussen ${LocalDate.now().year() -
    5} en ${LocalDate.now().year()} in.`,
  ErrorDateCheckToekomstMax: `Vul een geldige datum in.`,
  ErrorDateLeeg: `Vul een datum in.`,
  ErrorDateCheckToekomstMin: `Vul een datum in de toekomst in.`,
  ErrorDatumInDienst: `Vul een geldige datum in.`,
  ErrorEinddatumContract: `Vul een geldige einddatum in of controleer de datum in dienst.`,
  ErrorOvergangBijOverlijdenPercentage: `Waarde moet tussen 0 en 100% liggen.`,
  ErrorEinddatumRequired: `Vul de einddatum in.`,
  ErrorEinddatumOnderneming: `Vul een geldige ingangsdatum in. `,
  ErrorEinddatumVoorBegindatumOverwerk: `Einddatum structureel overwerk moet na begindatum liggen.`,
  ErrorEinddatumVoorBegindatumProvisie: `Einddatum provisie moet na begindatum liggen.`,
  ErrorAowKortingsjaren: `Vul een geldig aantal kortingsjaren van maximaal 50 jaar in.`,
  ErrorAowIngangsdatum: `Vul een ingangsdatum in waarbij de AOW-leeftijd tussen de 65 en 80 jaar is.`,
  ErrorFeitelijkArbeidsverleden: `De ingevoerde waarde mag niet hoger zijn dan het verschil tussen het jaartal van het huidige fiscale jaar en 1998.`,
  ErrorBeroepsfunctieVerplicht: `Vul een beroepsfunctie in.`,
  ErrorKvkNummer: `Vul een geldig KvK nummer in.`
});
