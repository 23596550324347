import { LocalDate } from "@js-joda/core";
import { FormikContextType } from "formik";
import * as Yup from "yup";
import { InferType } from "yup";
import { ValidationResultModel } from "../../.generated/forms/formstypes";
import { Algemeen } from "../../.generated/tarieven/tarieventypes";
import { adresSchema } from "../../shared/generic-parts/adres/schema";
import { birthdate2age } from "../../shared/utils/birthdate-to-age";
import { addMonths } from "../../shared/utils/dates";
import { hasValue } from "../../shared/utils/helpers";
import { WithSaveData } from "../../shared/utils/save-data";
import validateIBAN from "../../shared/utils/validateIBAN";
import { yupEnum, yupNullableEnum } from "../../shared/utils/yup-enum";
import { getPersonaliaTextResources } from "./personalia-resources";
import { localDateSchema, nullableLocalDateSchema } from "adviesbox-shared";

export enum AdresSyncHeightIndex {
  All = 0,
  Adres,
  TelefoonnummerPrive,
  TelefoonnummerWerk,
  TelefoonnummerMobiel
}

export enum BurgerlijkeStatusSyncHeightIndex {
  All = 0,
  Iban,
  BSN
}

export enum Geslacht {
  Geen = "Geen",
  Man = "Man",
  Vrouw = "Vrouw"
}

export enum SoortLegitimatiebewijs {
  EuropeseIdentiteitskaart = "EI",
  Paspoort = "PP",
  Rijbewijs = "RB",
  Visum = "VI",
  Geen = "Geen"
}
export enum SoortVerblijfsvergunning {
  Geen = "",
  Onbekend = "01",
  OnbepaaldeTijd = "02",
  BepaaldeTijd = "03",
  BepaaldeTijdAsielDocumentIII = "04",
  OnbepaaldeTijdAsielIV = "05",
  BepaaldeTijdNietTijdelijkVerblijfsdoel = "06"
}

export enum SchoolType {
  GeenSchool = "Geen school",
  BasisSchool = "Basisschool",
  Mbo = "MBO",
  Hbo = "HBO",
  Wo = "WO",
  NietErkendeOpleiding = "niet erkende opleiding",
  OnderbouwHAVOVWO = "Onderbouw HAVO/VWO",
  BovenbouwHAVOVWO = "Bovenbouw HAVO/VWO",
  OnderbouwVMBO = "Onderbouw VMBO",
  BovenbouwVMBO = "Bovenbouw VMBO"
}

export enum AantalKeer {
  x1 = "1x",
  x2 = "2x"
}

export type OpslagWarningModalProps = {
  clickedRoute: string;
  showModal: boolean;
  warnings: ValidationResultModel[][];
  setWarnings: React.Dispatch<React.SetStateAction<ValidationResultModel[][]>>;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  saveData: (values: PersonaliaState) => Promise<any>;
  resetForm: (nextValues?: Partial<FormikContextType<PersonaliaState>>) => void;
  setRouteToClickedPath: React.Dispatch<React.SetStateAction<boolean>>;
};

export const phoneNumberAllowEmptyRegex = /^$|^\+?-?[\d\s-]{6,19}$/;
const emailAllowEmptyRegex = /^(([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)|)$/i;
const hdnNumberValueRegex = /^([0-9]{2})$/;
//const hdnOptionalNumberValueRegex = /^([0-9]{2}|)$/;
//const hdnLetterValueRegex = /^([A-Z]{2})$/;

export const medeAanvragerOptiesSchema = Yup.object({
  medeAanvrager: Yup.boolean().default(false),
  afwijkendAdres: Yup.boolean().default(false)
});
export type MedeAanvragerOptiesType = InferType<typeof medeAanvragerOptiesSchema>;

export const kindSchema = Yup.object({
  kindId: Yup.string()
    .nullable()
    .default(null),
  internalAanvragerNummer: Yup.number()
    .min(0)
    .max(2)
    .default(0),
  gezamenlijkKind: Yup.boolean()
    .nullable()
    .default(true),
  naam: Yup.string()
    .nullable()
    .default(null),
  geboortedatum: localDateSchema
    .required(getPersonaliaTextResources("schemaGeboortedatumKindVerplicht"))
    .typeError(getPersonaliaTextResources("schemaOngeldigeDatum"))
    .test({
      message: getPersonaliaTextResources("schemaGeboortedatumKindToekomst"),
      test: (value: LocalDate | null) => hasValue(value) && value <= addMonths(LocalDate.now(), 9)
    }),
  inwonend: Yup.boolean().default(true),
  schooltype: yupNullableEnum(SchoolType)
    .default(SchoolType.GeenSchool)
    .meta({ testdata: SchoolType }),
  kinderbijslagPerKwartaal: Yup.number()
    .nullable()
    .default(null),
  aantalKeer: yupNullableEnum(AantalKeer)
    .default(AantalKeer.x1)
    .meta({ testdata: AantalKeer }),
  coOuderschap: Yup.boolean().default(false),
  kinderbijslagPerJaar: Yup.number()
    .nullable()
    .default(null)
});
export type KindType = InferType<typeof kindSchema>;

export const uitsluitingSchema = Yup.object({
  uitsluitingId: Yup.string()
    .nullable()
    .default(null),
  maatschappijCode: Yup.string().default(""),
  maatschappijOmschrijving: Yup.string().default(""),
  uitsluitingsdatum: nullableLocalDateSchema,
  reden: Yup.string().default("")
});
export type Uitsluiting = InferType<typeof uitsluitingSchema>;

export const weigeringSchema = Yup.object({
  weigeringId: Yup.string()
    .nullable()
    .default(null),
  maatschappijCode: Yup.string().default(""),
  maatschappijOmschrijving: Yup.string().default(""),
  weigeringDatum: nullableLocalDateSchema,
  reden: Yup.string().default("")
});
export type Weigering = InferType<typeof weigeringSchema>;

export const aanvrager1Schema = Yup.object({
  hasTweedeAanvrager: Yup.boolean().default(false),
  klantId: Yup.string()
    .nullable()
    .default(null),
  achternaam: Yup.string()
    .default("")
    .required(getPersonaliaTextResources("schemaAchternaamVerplicht")),
  tussenvoegselAchternaam: Yup.string().default(""),
  titel: Yup.string()
    .nullable()
    .default(null),
  voornamen: Yup.string().default(""),
  voorletters: Yup.string().default(""),
  roepnaam: Yup.string().default(""),
  geboortenaam: Yup.string().default(""),
  tussenvoegselGeboortenaam: Yup.string().default("")
});
export type Aanvrager1Type = InferType<typeof aanvrager1Schema>;

export const aanvrager2Schema = Yup.object({
  hasTweedeAanvrager: Yup.boolean().default(false),
  klantId: Yup.string()
    .nullable()
    .default(null),
  achternaam: Yup.string()
    .default("")
    .test({
      message: getPersonaliaTextResources("schemaAchternaamVerplicht"),
      test: function(value: string) {
        return this.parent?.hasTweedeAanvrager ? value !== "" : true;
      }
    }),
  tussenvoegselAchternaam: Yup.string().default(""),
  titel: Yup.string()
    .nullable()
    .default(null),
  voornamen: Yup.string().default(""),
  voorletters: Yup.string().default(""),
  roepnaam: Yup.string().default(""),
  geboortenaam: Yup.string().default(""),
  tussenvoegselGeboortenaam: Yup.string().default("")
});
export type Aanvrager2Type = InferType<typeof aanvrager2Schema>;

export const aanvragerExtraSchema = Yup.object({
  geboortedatum: localDateSchema
    .typeError(getPersonaliaTextResources("schemaOngeldigeDatum"))
    .required(getPersonaliaTextResources("schemaGeboortedatumVerplicht"))
    .test({
      message: getPersonaliaTextResources("schemaGeboortedatum1900"),
      test: (value: LocalDate | null) => !hasValue(value) || value.year() >= 1900
    })
    .test({
      message: getPersonaliaTextResources("schemaGeboortedatumToekomst"),
      test: (value: LocalDate | null) => !hasValue(value) || value < LocalDate.now()
    })
    .test({
      message: "De aanvrager moet minimaal 18 jaar oud zijn.",
      test: (value: LocalDate | null) => !hasValue(value) || birthdate2age(value) >= 18
    })
    .test({
      message: getPersonaliaTextResources("schemaGeboortedatumVerplicht"),
      test: (value: LocalDate | null) => hasValue(value)
    }),
  geslacht: yupEnum(Geslacht)
    .default(Geslacht.Man)
    .meta({ testdata: Geslacht }),
  roker: Yup.boolean().default(false)
});
export type AanvragerExtraType = InferType<typeof aanvragerExtraSchema>;

export const aanvrager2ExtraSchema = Yup.object({
  hasTweedeAanvrager: Yup.boolean().default(false),
  geboortedatum: localDateSchema
    .typeError(getPersonaliaTextResources("schemaOngeldigeDatum"))
    .required(getPersonaliaTextResources("schemaGeboortedatumVerplicht"))
    .test({
      message: getPersonaliaTextResources("schemaGeboortedatum1900"),
      test: (value: LocalDate | null) => !hasValue(value) || value.year() >= 1900
    })
    .test({
      message: getPersonaliaTextResources("schemaGeboortedatumToekomst"),
      test: (value: LocalDate | null) => !hasValue(value) || value < LocalDate.now()
    })
    .test({
      message: "De aanvrager moet minimaal 18 jaar oud zijn.",
      test: (value: LocalDate | null) => !hasValue(value) || birthdate2age(value) >= 18
    })
    .test({
      message: getPersonaliaTextResources("schemaGeboortedatumVerplicht"),
      test: (value: LocalDate | null) => hasValue(value)
    }),
  geslacht: yupEnum(Geslacht)
    .default(Geslacht.Vrouw)
    .meta({ testdata: Geslacht }),
  roker: Yup.boolean().default(false)
});

export type Aanvrager2ExtraType = InferType<typeof aanvrager2ExtraSchema>;

export const aanvragerAdresSchema = Yup.object({
  adres: adresSchema,
  geboortelandCode: Yup.string().default("NL"),
  nationaliteitCode: Yup.string().default("NL"),
  telefoonnummerPrive: Yup.string()
    .default("")
    .matches(phoneNumberAllowEmptyRegex, "Ongeldig telefoonnummer."),
  telefoonnummerWerk: Yup.string()
    .default("")
    .matches(phoneNumberAllowEmptyRegex, "Ongeldig telefoonnummer."),
  telefoonnummerMobiel: Yup.string()
    .default("")
    .matches(phoneNumberAllowEmptyRegex, "Ongeldig telefoonnummer."),
  emailAdres: Yup.string()
    .default("")
    .matches(emailAllowEmptyRegex, "Ongeldig e-mailadres."),
  fiscaleWoonstaatCode: Yup.string().default("NL")
});
export type AanvragerAdresType = InferType<typeof aanvragerAdresSchema>;

export const legitimatiebewijsSchema = Yup.object({
  soortLegitimatiebewijs: Yup.string()
    .nullable()
    .default(null),
  nummerLegitimatiebewijs: Yup.string()
    .nullable()
    .default(null)
    .when("soortLegitimatiebewijs", {
      is: (value): boolean => {
        return value !== undefined && value !== null;
      },
      then: Yup.string().required(getPersonaliaTextResources("schemaLegitimatiebewijsNummer")),
      otherwise: Yup.string().nullable()
    }),
  plaatsnaamAfgifte: Yup.string().default(""),
  landAfgifte: Yup.string().default("NL"),
  datumAfgifte: nullableLocalDateSchema,
  geldigTot: nullableLocalDateSchema.test({
    message: getPersonaliaTextResources("schemaLegitimatiebewijsGeldigTot"),
    test: function(value: LocalDate | null) {
      if (hasValue(value)) {
        const datumAfgifte: LocalDate | null = this.parent.datumAfgifte;
        if (hasValue(datumAfgifte)) {
          return value > datumAfgifte;
        }
      }
      return true;
    }
  })
});
export type Legitimatiebewijs = InferType<typeof legitimatiebewijsSchema>;

export const verblijfsvergunningSchema = Yup.object({
  soortVerblijfsvergunning: yupNullableEnum(SoortVerblijfsvergunning)
    .default(null)
    .meta({ testdata: SoortVerblijfsvergunning }),
  nummerVerblijfsvergunning: Yup.string()
    .nullable()
    .default(null),
  geldigTot: nullableLocalDateSchema
});
export type Verblijfsvergunning = InferType<typeof verblijfsvergunningSchema>;

export const aanvragerBurgerlijkeStatusSchema = Yup.object({
  rekeningId: Yup.string()
    .nullable()
    .default(null),
  iban: Yup.string()
    .default("")
    .test({
      message: "Ongeldig IBAN nummer.",
      test: function(value: string): boolean {
        return !!value ? validateIBAN(value) : true;
      }
    }),
  gezamelijkeRekening: Yup.boolean().default(false),
  burgerservicenummer: Yup.string().nullable(),
  burgerlijkeStaat: Yup.string()
    .default("03")
    .matches(hdnNumberValueRegex),
  geboorteplaats: Yup.string().default(""),
  kinderen: Yup.array(kindSchema)
    .max(100)
    .default([]),
  legitimatiebewijs: legitimatiebewijsSchema,
  verblijfsvergunning: verblijfsvergunningSchema
});
export type AanvragerBurgerlijkeStatusType = InferType<typeof aanvragerBurgerlijkeStatusSchema>;

export const aanvragerLevensgeschiedenisSchema = Yup.object({
  hoogstGenotenOpleiding: Yup.string()
    .default("08")
    .matches(hdnNumberValueRegex),
  ooitGescheiden: Yup.boolean().default(false),
  datumEchtscheiding: nullableLocalDateSchema,
  scheiding: Yup.string()
    .default("01")
    .matches(hdnNumberValueRegex),
  hoofdelijkAansprakelijk: Yup.boolean().default(false),
  uitsluitingenVerzekeringen: Yup.array(uitsluitingSchema)
    .min(9)
    .max(9)
    .default([
      uitsluitingSchema.default(),
      uitsluitingSchema.default(),
      uitsluitingSchema.default(),
      uitsluitingSchema.default(),
      uitsluitingSchema.default(),
      uitsluitingSchema.default(),
      uitsluitingSchema.default(),
      uitsluitingSchema.default(),
      uitsluitingSchema.default()
    ]),
  weigeringenVerzekeringen: Yup.array(weigeringSchema)
    .min(9)
    .max(9)
    .default([
      weigeringSchema.default(),
      weigeringSchema.default(),
      weigeringSchema.default(),
      weigeringSchema.default(),
      weigeringSchema.default(),
      weigeringSchema.default(),
      weigeringSchema.default(),
      weigeringSchema.default(),
      weigeringSchema.default()
    ])
});
export type AanvragerLevensgeschiedenisType = InferType<typeof aanvragerLevensgeschiedenisSchema>;

export const uitsluitingenModalSchema = Yup.object({
  uitsluitingen: Yup.array(uitsluitingSchema).default([])
});

export const weigeringenModalSchema = Yup.object({
  weigeringen: Yup.array(weigeringSchema).default([])
});

export const initWaarschuwingenSchema = Yup.object({
  aanvrager1roker: Yup.boolean().default(false),
  aanvrager2roker: Yup.boolean().default(false),
  aanvrager1GeboorteDatum: localDateSchema,
  aanvrager2GeboorteDatum: localDateSchema,
  aanvrager1Geslacht: yupEnum(Geslacht).default(Geslacht.Man),
  aanvrager2Geslacht: yupEnum(Geslacht).default(Geslacht.Man),
  medeAanvrager: Yup.boolean().default(false)
});

export const personaliaSchema = Yup.object({
  initWaarschuwingen: initWaarschuwingenSchema,
  medeAanvragerOpties: medeAanvragerOptiesSchema,
  aanvrager1: aanvrager1Schema,
  aanvrager2: aanvrager2Schema.when("medeAanvragerOpties", (values: MedeAanvragerOptiesType) => {
    return values && !values.medeAanvrager
      ? aanvrager2Schema.shape({
          achternaam: Yup.string()
        })
      : aanvrager2Schema;
  }),
  aanvrager1Extra: aanvragerExtraSchema,
  aanvrager2Extra: aanvrager2ExtraSchema
    .nullable()
    .when("medeAanvragerOpties", (values: MedeAanvragerOptiesType) => {
      return values && !values.medeAanvrager
        ? aanvrager2ExtraSchema.shape({
            geboortedatum: nullableLocalDateSchema
          })
        : aanvrager2ExtraSchema;
    })
    .default(aanvrager2Schema.default()),
  aanvrager1Adres: aanvragerAdresSchema,
  aanvrager2Adres: aanvragerAdresSchema,
  aanvrager1BurgerlijkeStatus: aanvragerBurgerlijkeStatusSchema,
  aanvrager2BurgerlijkeStatus: aanvragerBurgerlijkeStatusSchema,
  aanvrager1Levensgeschiedenis: aanvragerLevensgeschiedenisSchema,
  aanvrager2Levensgeschiedenis: aanvragerLevensgeschiedenisSchema,
  preventSave: Yup.boolean().default(false),
  platformApiFouten: Yup.array(
    Yup.object({
      error: Yup.string(),
      field: Yup.string(),
      label: Yup.string()
    })
  ).nullable(),
  herberekeningskeuze: Yup.string()
    .nullable()
    .default("1")
});

export type PersonaliaState = InferType<typeof personaliaSchema>;
export type PersonaliaProps = InferType<typeof personaliaSchema> &
  WithSaveData<PersonaliaState> & {
    tarieven: Algemeen;
  };
