import * as Yup from "yup";
import { ValidationError } from "yup";
import {
  BetalingsTermijnType,
  SoortDepotOptions,
  SoortVermogenProductOptions
} from "../../.generated/forms/formstypes";
import {
  fiscaleRegelingSchema,
  kapitaalopbouwSchema,
  productSchema,
  verpandingSchema,
  verzekeringNemersSchema
} from "../../producten-overzicht/infra/producten-overzicht-schema";
import { jaarMaandInMaanden } from "../../shared/generic-parts/jaar-maand/map-ui-2-dl";
import { jaarMaandInputSchema, JaarMaandInputType } from "../../shared/generic-parts/jaar-maand/schema";
import { klantnaamSchema } from "../../shared/generic-parts/klantnaam/schema";
import { kredietSchema } from "../../shared/generic-parts/krediet/schema";
import { vermogenLeningdeelSchema } from "../../shared/generic-parts/leningdeel/schema";
import { bedragFormat } from "../../shared/utils/currency";
import { getVermogenTextResources } from "./vermogen-resources";
import { VermogenType } from "./vermogen-types";
import { nullableLocalDateSchema } from "adviesbox-shared";

export const vermogenFiscaleRegelingSchema = fiscaleRegelingSchema.shape({
  lijfrenteclausule: Yup.boolean()
    .nullable()
    .default(null)
});

export const waardeopbouwMaandSchema = Yup.object({
  maand: Yup.number().default(0),
  eindwaardeBedrag: Yup.number().default(0)
});

export const waardeopbouwJaarSchema = Yup.object({
  jaar: Yup.number().default(0),
  beginwaardeBedrag: Yup.number().default(0),
  stortingenBedrag: Yup.number().default(0),
  onttrekkingenBedrag: Yup.number().default(0),
  eindwaardeBedrag: Yup.number().default(0)
});

export const depotSchema = Yup.object({
  soortDepot: Yup.mixed<SoortDepotOptions>().default(SoortDepotOptions.OpbouwDepot),
  bestedingsdoel: Yup.string()
    .nullable()
    .default(null),
  aankoopkostenPercentage: Yup.number()
    .nullable()
    .default(null),
  verkoopkostenPercentage: Yup.number()
    .nullable()
    .default(null),
  beheerkostenPercentage: Yup.number()
    .nullable()
    .default(null),
  waardeopbouwBedrag: Yup.number()
    .nullable()
    .default(null),
  waardeopbouwNa5Jaar: Yup.number()
    .nullable()
    .default(null),
  waardeopbouwMaanden: Yup.array(waardeopbouwMaandSchema).default([]),
  waardeopbouwJaren: Yup.array(waardeopbouwJaarSchema).default([]),
  reedsOpgebouwdBedrag: Yup.number()
    .nullable()
    .default(null),
  reedsOpgebouwdDatum: nullableLocalDateSchema,
  afkoopwaardeBedrag: Yup.number()
    .nullable()
    .default(null),
  afkoopwaardeDatum: nullableLocalDateSchema
});

export const extraInlegJaarSchema = Yup.object({
  bedrag: Yup.number()
    .nullable()
    .default(null)
});

export const extraInlegJarenSchema = Yup.object({
  scenario: Yup.array(extraInlegJaarSchema).default([])
});

export const inleggegevensSchema = Yup.object({
  duur: jaarMaandInputSchema,
  aftrekbaar: Yup.number()
    .nullable()
    .default(null),
  duurHoogLaag: jaarMaandInputSchema.test({
    message: getVermogenTextResources("ErrorDuurHoogGroterDanLooptijd"),
    test: function(value: JaarMaandInputType): boolean | ValidationError {
      const duurHoogLaagInMaanden = jaarMaandInMaanden(value) ?? 0;
      const duurInMaanden = jaarMaandInMaanden(this.parent.duur) ?? 0;

      if (duurHoogLaagInMaanden > duurInMaanden) {
        return this.createError({ path: `${this.path}.jaren` });
      }

      return true;
    }
  }),
  stortingstermijn: Yup.mixed<BetalingsTermijnType>()
    .oneOf([...Object.values(BetalingsTermijnType), null])
    .nullable()
    .default(BetalingsTermijnType.Maand),
  inleg: Yup.number()
    .nullable()
    .default(null),
  inlegHoog: Yup.number()
    .nullable()
    .default(null),
  eersteInleg: Yup.number()
    .nullable()
    .default(null),
  extraInlegJaren: extraInlegJarenSchema
});

export const extraOpnameSchema = Yup.object({
  jaar: Yup.number().default(0),
  extraInlegBedrag: Yup.number()
    .nullable()
    .default(null),
  voorbeeldBedrag: Yup.number().default(0),
  maxOpnameBedrag: Yup.number()
    .nullable()
    .default(null),
  opnameBedrag: Yup.number()
    .nullable()
    .default(null)
    .test("maximaal-opname-bedrag", "", function(value: number): boolean | ValidationError {
      if (!value) {
        return true;
      }

      const { maxOpnameBedrag } = this.parent;

      if (typeof maxOpnameBedrag === "number" && value > maxOpnameBedrag) {
        return this.createError({
          message: `${getVermogenTextResources("ErrorOpnameMaximaal")} ${bedragFormat(this.parent.maxOpnameBedrag)}`
        });
      }

      return true;
    })
});

export const extraOpnamesSchema = Yup.object({
  scenario: Yup.array(extraOpnameSchema).default([])
});

export const onttrekkingenSchema = Yup.object({
  onttrekkingenAanwezig: Yup.boolean().default(false),
  begindatum: nullableLocalDateSchema,
  einddatum: nullableLocalDateSchema,
  duur: jaarMaandInputSchema,
  onttrekkingstermijn: Yup.mixed<BetalingsTermijnType>()
    .nullable()
    .default(null),
  onttrekkingBedrag: Yup.number()
    .nullable()
    .default(null),
  extraOpnames: extraOpnamesSchema
});

export const vermogenSchema = Yup.object({
  productId: Yup.string()
    .nullable()
    .default(null),
  productCode: Yup.string().default(""),
  partijCode: Yup.string().default(""),
  partijCodeSelectie: Yup.string()
    .nullable()
    .default(null),
  soortProduct: Yup.mixed<SoortVermogenProductOptions>()
    .oneOf(Object.values(SoortVermogenProductOptions))
    .default(SoortVermogenProductOptions.Spaarrekening),
  product: productSchema,
  fiscaleRegeling: vermogenFiscaleRegelingSchema,
  verzekeringnemers: verzekeringNemersSchema,
  depot: depotSchema,
  kapitaalopbouw: kapitaalopbouwSchema,
  inleggegevens: inleggegevensSchema,
  onttrekkingen: onttrekkingenSchema,
  verpanding: verpandingSchema,
  dataHasChanged: Yup.boolean().default(false)
})
  .test("onttrekkingen.duur", getVermogenTextResources("ErrorDuurOnttrekkingGroterDanLooptijd"), function(value) {
    // Tests voor vermogen product die de data uit de verschillende delen van het product nodig hebben
    const vermogen = value as VermogenType;

    if (vermogen.onttrekkingen.onttrekkingenAanwezig) {
      const duurInMaanden = jaarMaandInMaanden(vermogen.onttrekkingen.duur);

      if (duurInMaanden) {
        const looptijdInMaanden = jaarMaandInMaanden(vermogen.product.looptijd) ?? 0;

        if (duurInMaanden > looptijdInMaanden) {
          return this.createError({
            path: `${this.path}.onttrekkingen.duur.jaren`
          });
        }
      }
    }

    return true;
  })
  .test("onttrekkingen.begindatum", "", function(value) {
    // Tests voor vermogen product die de data uit de verschillende delen van het product nodig hebben
    const vermogen = value as VermogenType;

    if (vermogen.onttrekkingen.onttrekkingenAanwezig) {
      const productIngangsdatum = vermogen.product.ingangsdatum;
      const { begindatum } = vermogen.onttrekkingen;

      if (productIngangsdatum && begindatum) {
        if (begindatum.isBefore(productIngangsdatum)) {
          return this.createError({
            path: `${this.path}.onttrekkingen.begindatum`,
            message: getVermogenTextResources("ErrorBegindatumVoorAanvangdatum")
          });
        }

        if (productIngangsdatum.dayOfMonth() !== begindatum.dayOfMonth()) {
          return this.createError({
            path: `${this.path}.onttrekkingen.begindatum`,
            message: getVermogenTextResources("ErrorBegindatumGeheelAantalMaandenVanAanvangdatum")
          });
        }
      }
    }

    return true;
  })
  .test("onttrekkingen.einddatum", "", function(value) {
    // Tests voor vermogen product die de data uit de verschillende delen van het product nodig hebben
    const vermogen = value as VermogenType;

    if (vermogen.onttrekkingen.onttrekkingenAanwezig) {
      const productEinddatum = vermogen.product.einddatum;
      const { begindatum, einddatum } = vermogen.onttrekkingen;

      if (einddatum) {
        if (begindatum && einddatum.isBefore(begindatum)) {
          return this.createError({
            path: `${this.path}.onttrekkingen.einddatum`,
            message: getVermogenTextResources("ErrorEinddatumOnttrekkingVoorBegindatum")
          });
        }

        if (productEinddatum && productEinddatum.isBefore(einddatum)) {
          return this.createError({
            path: `${this.path}.onttrekkingen.einddatum`,
            message: getVermogenTextResources("ErrorEinddatumOnttrekkingenNaEinddatumProduct")
          });
        }
      }
    }

    return true;
  })
  .test("inleggegevens.duur.jaren", getVermogenTextResources("ErrorDuurInlegGroterDanLooptijd"), function(value) {
    // Tests voor vermogen product die de data uit de verschillende delen van het product nodig hebben
    const vermogen = value as VermogenType;

    const duurInMaanden = jaarMaandInMaanden(vermogen.inleggegevens.duur);
    if (duurInMaanden) {
      const looptijdInMaanden = jaarMaandInMaanden(vermogen.product.looptijd) ?? 0;
      if (duurInMaanden > looptijdInMaanden) {
        return this.createError({
          path: `${this.path}.inleggegevens.duur.jaren`
        });
      }
    }

    return true;
  });

export const vermogensSchema = Yup.object({
  producten: Yup.array(vermogenSchema).default([]),
  aanvrager1: klantnaamSchema.nullable(),
  aanvrager2: klantnaamSchema.nullable(),
  leningdelen: Yup.array(vermogenLeningdeelSchema).default([]),
  kredieten: Yup.array(kredietSchema).default([]),
  geldverstrekkerNaam: Yup.string()
    .nullable()
    .default(null),
  ingangsdatumVoorstel: nullableLocalDateSchema,
  platformApiFouten: Yup.array(
    Yup.object({
      error: Yup.string(),
      field: Yup.string(),
      label: Yup.string()
    })
  )
    .nullable()
    .default([])
});
