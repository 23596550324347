import { original } from "immer";
import { AanleidingenOutput } from "../../.generated/forms/formstypes";
import {
  createISWAsyncSideEffect,
  initISWAsyncSideEffect
} from "../../shared/components/isw-side-effects/create-isw-helpers";
import { UiError } from "../../shared/types";
import { submit } from "../../shared/utils/save-validation";
import { AanleidingState } from "./aanleiding-schema";
import { createAanleidingUrl, getDossierId } from "./aanleiding-url";
import { mapAanleidingDlNaarUi } from "./map-aanleiding-dl-to-ui";

export type AsyncContext = { 
  saveData: (values: any) => Promise<UiError[] | null>; 
  opslaanRechten: boolean | undefined; 
  modelFinancieringsKeuzeOpslaan: [boolean | null, React.Dispatch<React.SetStateAction<boolean | null>>];
 };

export const aanleidingSaveAsync = createISWAsyncSideEffect<AanleidingState, AsyncContext>(
  async ({ draft, context, formik, fetchData, settings, params }) => {
    // Add sync sideeffect changes to the submit / saveData function
    const values = original(draft);
    if (values) formik.values = values;
    // save screen using regular submit method (kinda nasty to use in sideeffects but ohwell)
    const submitResult = await submit(context.saveData, formik);

    // reload changes from platform using resetform instead of changing draft (kinda nasty to use, but ohwell)
    if (submitResult === "completed") {
      const id = getDossierId(params);
      const url = createAanleidingUrl(settings, params);
      const fetchResult = await fetchData<AanleidingenOutput>({ method: "GET", url });
      const mappedResult = mapAanleidingDlNaarUi()(id, fetchResult);
      if (mappedResult) {
        formik.resetForm({ values: mappedResult });
      }
    }

    draft.saving = false;
  }
);


export const aanleidingAsyncSideEffects = initISWAsyncSideEffect<AanleidingState, AsyncContext>(
  ({ has, curr, prev, runAsync, context }) => {
    if (context.opslaanRechten) 
    {
      const [financieringsKeuzeOpslaan, setFinancieringsKeuzeOpslaan] = context.modelFinancieringsKeuzeOpslaan;
      if (financieringsKeuzeOpslaan === true) {
        setFinancieringsKeuzeOpslaan(null);
        runAsync(aanleidingSaveAsync(context));
      }
      if (prev.voorstellen.filter(v => !v.deleted).length !== curr.voorstellen.filter(v => !v.deleted).length ||
      prev.voorstellen.some(
        (v, i) =>
          curr.voorstellen[i] &&
          curr.voorstellen[i].kopieVoorstelId &&
          v.kopieVoorstelId !== curr.voorstellen[i].kopieVoorstelId
      )) {
        runAsync(aanleidingSaveAsync(context));
      }
    }
  }
  
);
