import { FieldMap, UiName, mapBerekenInput } from "../../shared/types";

import {
  Lijfrente as UitkerendeLijfrenteDlEntry,
  LijfrenteOutput as UitkerendeLijfrenteDlOutput,
  LijfrenteProduct,
  LijfrenteKapitaal,
  LijfrenteUitkerendeFase,
  SoortLijfrenteUitkeringOptions
} from "../../.generated/forms/formstypes";

import { mapProductDl2Ui } from "../../producten-overzicht/infra/product-mapper-dl-2-ui";
import { indicatieveUitkerendeFaseSchema } from "../../producten-overzicht/infra/producten-overzicht-schema";

import {
  uitkerendeLijfrentesSchema,
  verzekeringnemersSchema,
  verzekerdenSchema,
  kapitaalSchema,
  uitkerendeLijfrenteSchema
} from "./uitkerende-lijfrente-schema";
import { UitkerendeLijfrentesType } from "./uitkerende-lijfrente-types";
import { KlantnaamType } from "../../shared/generic-parts/klantnaam/schema";
import { mapKlantenNaarAanvragerKeuze, mapKlantnaamDl2Ui } from "../../shared/generic-parts/klantnaam/map-dl-2-ui";
import { mapJaarMaandInputFromLooptijdDl2Ui } from "../../shared/generic-parts/jaar-maand/map-dl-2-ui";
import { prefixWithZero } from "../../shared/utils/helpers";
import { target2field } from "../../shared/utils/target-to-field";
import { createMapToUi } from "../../shared/utils/create-map-to-ui";
import { mapStringToLocalDate } from "adviesbox-shared";

type AanvragerContext = {
  aanvrager1: KlantnaamType | null;
  aanvrager2: KlantnaamType | null;
};

const mapVerzekeringnemersDl2Ui = createMapToUi(verzekeringnemersSchema)
  .with<AanvragerContext>()
  .from<LijfrenteProduct>({
    verzekeringnemers: (v, context) =>
      mapKlantenNaarAanvragerKeuze(v.verzekeringnemerKlantIds, context.aanvrager1, context.aanvrager2)
  });

const mapVerzekerdenDl2Ui = createMapToUi(verzekerdenSchema)
  .with<AanvragerContext>()
  .from<LijfrenteProduct>({
    verzekerden: (v, context) =>
      mapKlantenNaarAanvragerKeuze(v.verzekerdeKlantIds, context.aanvrager1, context.aanvrager2)
  });

const mapKapitaalDl2Ui = createMapToUi(kapitaalSchema).from<LijfrenteKapitaal>({
  beschikbareKoopsom: v => v.beschikbareKoopsomBedrag,
  uitgangspuntBerekening: v => v.uitgangspuntBerekening
});

const mapIndicatieveUitkerendeFaseDl2Ui = createMapToUi(indicatieveUitkerendeFaseSchema).from<LijfrenteUitkerendeFase>({
  belastingBox: v => v.belastingBox,
  soortLijfrenteUitkering: v => v.soortLijfrenteUitkering,
  duurUitkering: v =>
    v.duurUitkeringInMaanden && v.soortLijfrenteUitkering !== SoortLijfrenteUitkeringOptions.Levenslang
      ? mapJaarMaandInputFromLooptijdDl2Ui(v.duurUitkeringInMaanden)
      : { jaren: null, maanden: null },
  duurUitkeringHoog: v => mapJaarMaandInputFromLooptijdDl2Ui(v.duurUitkeringHoogInMaanden),
  hoogLaagVerhouding: v => v.hooglaagVerhouding,
  lijfrenteTarief: v => v.lijfrenteTariefPercentage,
  overgangOpTweedeVerzekerde: v => v.overgangOp2eVerzekerde,
  overgangOpTweedeVerzekerdePercentage: v => v.overgangOp2eVerzekerdePercentage,
  lijfrenteUitkering: v => mapBerekenInput(v.lijfrenteUitkeringBedrag, v.lijfrenteUitkeringOvernemen),
  termijn: v => v.termijn
});

const mapUitkerendeLijfrenteDl2Ui = createMapToUi(uitkerendeLijfrenteSchema)
  .with<AanvragerContext>()
  .from<LijfrenteProduct>({
    productId: v => v.productId,
    partijCode: v => v.polis.maatschappijCode ?? "",
    productCode: v => prefixWithZero(v.polis.productcode),
    soortProduct: v => v.soortProduct,
    product: v => {
      const result = mapProductDl2Ui(v.polis);
      result.doorlopend = v.doorlopend ?? false;
      result.wijzigingenInDoorlopendProductOvernemen = v.wijzigingenInDoorlopendeProductenOvernemen;
      result.looptijd = { jaren: null, maanden: null };
      return result;
    },
    verzekeringnemers: (v, context) => mapVerzekeringnemersDl2Ui(context)(v),
    verzekerden: (v, context) => mapVerzekerdenDl2Ui(context)(v),
    kapitaal: v => mapKapitaalDl2Ui(v.kapitaal),
    indicatieveUitkerendeFase: v => mapIndicatieveUitkerendeFaseDl2Ui(v.uitkerendeFase)
  });

function dl2ui(values: UitkerendeLijfrenteDlEntry): UitkerendeLijfrentesType {
  const aanvrager1 = mapKlantnaamDl2Ui(values.aanvrager1);
  const aanvrager2 = mapKlantnaamDl2Ui(values.aanvrager2);

  const uitkerendeLijfrentes: UitkerendeLijfrentesType = {
    producten: values.producten.map(product => mapUitkerendeLijfrenteDl2Ui({ aanvrager1, aanvrager2 })(product)) as any,
    aanvrager1,
    aanvrager2,
    ingangsdatumVoorstel: mapStringToLocalDate(values.ingangsdatumVoorstel)
  };

  return uitkerendeLijfrentesSchema.cast(uitkerendeLijfrentes);
}

export function mapUitkerendeLijfrentesDlToUi(
  uitkerendeLijfrenteId: string,
  data: UitkerendeLijfrenteDlOutput
): UitkerendeLijfrentesType | null {
  const uitkerendeLijfrente = data && data.lijfrentes ? data.lijfrentes[uitkerendeLijfrenteId] : null;

  if (!uitkerendeLijfrente) {
    return null;
  }

  return dl2ui(uitkerendeLijfrente);
}

export function mapDlTargetToUitkerendeLijfrenteUiField(target: string): UiName | null {
  const map: FieldMap<UitkerendeLijfrenteDlEntry> = {
    producten: {
      polis: {
        productnummer: {
          field: "producten[i].product.productNummer",
          label: "Productnummer"
        },
        productnaam: {
          field: "producten[i].product.productNaam",
          label: "Productnaam"
        },
        maatschappijOmschrijving: {
          field: "producten[i].product.partijNaam",
          label: "Partijnaam"
        }
      }
    }
  };

  switch (target) {
    case "DuurUitkeringInMaanden":
      return {
        field: "",
        label: "Duur uitkering"
      };
    default:
      const regExp = /Producten\[(\d+)\].(\w+).(\w+)/;
      const match = regExp.exec(target);

      if (match) {
        const index = +match[1];
        const kaart = match[2];
        const veld = match[3];

        switch (kaart) {
          case "Kapitaal":
            switch (veld) {
              case "BeschikbareKoopsomBedrag":
                return {
                  field: `producten[${index}].kapitaal.beschikbareKoopsom`,
                  label: `Lijfrente ${index + 1}: Beschikbare koopsom`
                };
            }
            break;
          case "Polis":
            switch (veld) {
              case "Ingangsdatum":
                return {
                  field: `producten[${index}].product.ingangsdatum`,
                  label: `Lijfrente ${index + 1}: Ingangsdatum`
                };
            }
            break;

          case "UitkerendeFase":
            switch (veld) {
              case "DuurUitkeringHoogInMaanden":
                return {
                  field: `producten[${index}].indicatieveUitkerendeFase.duurUitkeringHoog.jaren`,
                  label: `Lijfrente ${index + 1}: Duur hoog`
                };
              case "DuurUitkeringInMaanden":
                return {
                  field: `producten[${index}].indicatieveUitkerendeFase.duurUitkering.jaren`,
                  label: `Lijfrente ${index + 1}: Duur uitkering`
                };
              case "LijfrenteTariefPercentage":
                return {
                  field: `producten[${index}].indicatieveUitkerendeFase.lijfrenteTarief`,
                  label: `Lijfrente ${index + 1}: Gehanteerd lijfrentetarief`
                };
              case "LijfrenteUitkeringBedrag":
                return {
                  field: `producten[${index}].indicatieveUitkerendeFase.lijfrenteUitkering`,
                  label: `Lijfrente ${index + 1}: Lijfrente-uitkering`
                };
            }
            break;
        }
      }
  }

  return target2field(map, target);
}
