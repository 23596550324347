import { Afbeelding } from "adviesbox-shared";
import React, { ReactElement } from "react";
import { GeslachtOpties } from "../../../.generated/forms/formstypes";
import { KlantnaamType } from "../../../shared/generic-parts/klantnaam/schema";
import { getFormattedDate } from "../../../shared/utils/dates";
import { getNaam } from "../../../shared/utils/helpers";
import classes from "./hypotheek-vergelijker-pdf.module.scss";
type HypotheekVergelijkerPDFHeaderProps = {
  bedrijfslogo: string | null | undefined;
  displayAanvrager1: boolean;
  aanvrager1: KlantnaamType | null;
  displayAanvrager2: boolean;
  aanvrager2: KlantnaamType | null;
};

export const renderHypotheekVergelijkerPDFHeader = ({
  bedrijfslogo,
  displayAanvrager1,
  aanvrager1,
  displayAanvrager2,
  aanvrager2
}: HypotheekVergelijkerPDFHeaderProps): ReactElement => {
  const bedrijfsImg = new Image();
  if (bedrijfslogo) bedrijfsImg.src = `data:image/png;base64,${bedrijfslogo}`;

  return (
    <>
      <div className={classes.pdf_header_container}>
        <h1 className={classes.pdf_header}>Hypotheekvergelijker</h1>
        <div className={classes.pdf_header}>
          <Afbeelding name="orgData.logo" alt="bedrijfslogo" location={bedrijfsImg.src} style={{ maxHeight: 60 }} />
        </div>
      </div>
      <table className={classes.pdf_table}>
        <thead>
          <tr>
            <th className={classes.pdf_table_header}></th>
            {displayAanvrager1 && (
              <>
                <th className={`${classes.pdf_table_header_center}`}>
                  {aanvrager1?.geslacht === GeslachtOpties.Man ? "Dhr." : "Mevr."}&nbsp;
                  {getNaam(aanvrager1, "Aanvrager 1")}
                </th>
              </>
            )}
            {displayAanvrager2 && (
              <>
                <th className={classes.pdf_table_header}>
                  {aanvrager2?.geslacht === GeslachtOpties.Man ? "Dhr." : "Mevr."}&nbsp;
                  {getNaam(aanvrager2, "Aanvrager 2")}
                </th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className={classes.pdf_table_data_info}>Geboortedatum</td>
            {displayAanvrager1 && (
              <>
                <td className={`${classes.pdf_table_data}`} style={{ backgroundColor: "#f0f0f0" }}>
                  {aanvrager1?.geboortedatum ? getFormattedDate(aanvrager1.geboortedatum) : ""}
                </td>
              </>
            )}
            {displayAanvrager2 && (
              <>
                <td className={`${classes.pdf_table_data}`} style={{ backgroundColor: "#f0f0f0" }}>
                  {aanvrager2?.geboortedatum ? getFormattedDate(aanvrager2.geboortedatum) : ""}
                </td>
              </>
            )}
          </tr>
        </tbody>
      </table>
    </>
  );
};
