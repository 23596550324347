import * as Yup from "yup";
import { SoortProductOptions, FinancieringOptions, BetalingsTermijnType } from "../../.generated/forms/formstypes";
import { getVergoedingenOverzichtTextResources } from "./vergoedingen-overzicht-resources";
import { InferType } from "yup";
import { WithSaveData } from "../../shared/utils/save-data";
import { LocalDate } from "@js-joda/core";
import { addYears, hasValue, localDateSchema, nullableLocalDateSchema } from "adviesbox-shared";

export const hypotheekNormSchema = Yup.object({
  jaar: Yup.number(),
  maand: Yup.number()
});
export type HypotheekNormType = InferType<typeof hypotheekNormSchema>;

export const vergoedingenOverzichtInstellingenTarievenSchema = Yup.object({
  advieskostenPerUur: Yup.boolean().default(false),
  advieskostenUurTarief: Yup.number()
    .nullable()
    .default(null),
  hypotheekAdvieskostenUurTarief: Yup.number()
    .nullable()
    .default(null),
  advieskostenDefaultBedrag: Yup.number()
    .nullable()
    .default(null),
  geldleningPerUur: Yup.boolean().default(false),
  bemiddelingsvergoedingGeldleningUurTarief: Yup.number()
    .nullable()
    .default(null),
  bemiddelingsvergoedingGeldleningDefaultBedrag: Yup.number()
    .nullable()
    .default(null),
  overigeVergoedingPerUur: Yup.boolean().default(false),
  overigeBemiddelingsvergoedingDefaultBedrag: Yup.number()
    .nullable()
    .default(null),
  overigeBemiddelingsvergoedingUurTarief: Yup.number()
    .nullable()
    .default(null),
  dossiervergoedingDefaultBedrag: Yup.number()
    .nullable()
    .default(null)
});
export type VergoedingenOverzichtInstellingenTarievenType = InferType<
  typeof vergoedingenOverzichtInstellingenTarievenSchema
>;

export const vergoedingenOverzichtKaartSchema = Yup.object({
  vergoedingenOverzichtInstellingenTarieven: vergoedingenOverzichtInstellingenTarievenSchema,
  ingangsdatum: localDateSchema.required(getVergoedingenOverzichtTextResources("ErrorDatumVerplicht")).test({
    message: getVergoedingenOverzichtTextResources("ErrorDatumBereik"),
    test: function(value: LocalDate | null) {
      if (hasValue(value)) {
        return value <= addYears(LocalDate.now(), 5);
      }
      return true;
    }
  }),
  hypotheekNorm: hypotheekNormSchema.nullable().default(null),
  hypotheekNormJaarMaand: Yup.string().default(""),
  advieskostenAantalUur: Yup.number()
    .nullable()
    .default(null),
  advieskostenOvernemen: Yup.boolean().default(false),
  advieskostenBedrag: Yup.number()
    .nullable()
    .default(null),
  bemiddelingsvergoedingGeldleningBedrag: Yup.number()
    .nullable()
    .default(null),
  bemiddelingsvergoedingGeldleningOvernemen: Yup.boolean()
    .nullable()
    .default(null),
  bemiddelingsvergoedingGeldleningAantalUur: Yup.number()
    .nullable()
    .default(null),
  overigeBemiddelingsvergoedingBedrag: Yup.number()
    .nullable()
    .default(null),
  overigeBemiddelingsvergoedingOvernemen: Yup.boolean().default(false),
  overigeBemiddelingsvergoedingAantalUur: Yup.number()
    .nullable()
    .default(null),
  dossiervergoedingBedrag: Yup.number()
    .nullable()
    .default(null)
});
export type VergoedingenOverzichtType = InferType<typeof vergoedingenOverzichtKaartSchema>;

export const huidigProductKenmerkSchema = Yup.object({
  productnaam: Yup.string().default(""),
  uwBemiddeling: Yup.boolean().default(false),
  ingangsDatum: nullableLocalDateSchema.default(null),
  looptijd: Yup.number()
    .nullable()
    .default(null),
  hoofdsom: Yup.number()
    .nullable()
    .default(null),
  dekking1: Yup.number()
    .nullable()
    .default(null),
  dekking2: Yup.number()
    .nullable()
    .default(null),
  premie: Yup.number()
    .nullable()
    .default(null),
  rentePercentage: Yup.number()
    .nullable()
    .default(null),
  rvp: Yup.number()
    .nullable()
    .default(null),
  rbt: Yup.number()
    .nullable()
    .default(null),
  betalingstermijn: Yup.mixed<BetalingsTermijnType>()
    .oneOf([...Object.values(BetalingsTermijnType), null])
    .nullable()
    .default(null),
  opbouwBedrag: Yup.number()
    .nullable()
    .default(null),
  renteBedrag: Yup.number()
    .nullable()
    .default(null)
});
export type HuidigProductKenmerkType = InferType<typeof huidigProductKenmerkSchema>;

export const huidigProductSchema = Yup.object({
  productnaam: Yup.string().default(""),
  productId: Yup.string().default(""),
  maatschappijNaam: Yup.string().default(""),
  omschrijving: Yup.string().default(""),
  meenemen: Yup.boolean().default(false),
  premievrijMaken: Yup.boolean()
    .nullable()
    .default(null),
  premievrijMakenMogelijk: Yup.boolean().default(false),
  inFinanciering: Yup.boolean()
    .nullable()
    .default(null),
  uitFinanciering: Yup.boolean()
    .nullable()
    .default(null),
  productsoort: Yup.mixed<SoortProductOptions>()
    .oneOf(Object.values(SoortProductOptions))
    .default(SoortProductOptions.Onbekend),
  eindeLooptijdDatum: nullableLocalDateSchema.default(null)
});
export type HuidigProductType = InferType<typeof huidigProductSchema>;

const huidigProductenKaartSchema = Yup.array().of(huidigProductSchema);
const huidigProductenKenmerkenSchema = Yup.array().of(huidigProductKenmerkSchema);

export const vergoedingenOverzichtSchema = Yup.object({
  vergoedingenOverzicht: vergoedingenOverzichtKaartSchema,
  huidigProducten: huidigProductenKaartSchema,
  huidigProductKenmerken: huidigProductenKenmerkenSchema,
  financiering: Yup.mixed<FinancieringOptions>().oneOf(Object.values(FinancieringOptions))
});

export type VergoedingenOverzichtState = InferType<typeof vergoedingenOverzichtSchema>;
export type VergoedingenOverzichtProps = InferType<typeof vergoedingenOverzichtSchema> &
  WithSaveData<VergoedingenOverzichtState>;

export default vergoedingenOverzichtSchema;
