import { AdviesBoxFormik, LabeledSelectInput } from "adviesbox-shared";
import { LabelValuePairs } from "adviesbox-shared/utils/types";
import { FormikProps } from "formik";
import React, { ReactElement } from "react";
import Modal from "../../shared/components/modal/Modal";
import { aanvraagHypotheekSchema, aanvraagHypotheekType, AanvraagState } from "../infra/aanvraag-schema";
import { AanvraagType } from "../aanvraag-product/aanvraag-product";

export type AanvraagNaarHypotheekProps = {
  aanvraag: AanvraagState;
  closeModal?: () => void;
  onSave?: (data: aanvraagHypotheekType) => void;
};

const AanvraagNaarHypotheekModal = ({ aanvraag, closeModal, onSave }: AanvraagNaarHypotheekProps): ReactElement => {
  const methodeIndienenOptions: LabelValuePairs = [
    {
      label: `Rechtstreeks naar ${aanvraag.hypotheek?.partijnaam}`,
      value: `${AanvraagType.Serviceprovider}**${aanvraag.hypotheek?.partijnaam}****${aanvraag.hypotheek?.hdnNummer}`
    }
  ];

  aanvraag.serviceprovider.forEach(serviceprovider => {
    methodeIndienenOptions.push({
      label: `Via ${serviceprovider.naam}`,
      value: `${AanvraagType.Serviceprovider}**${serviceprovider.naam}**${serviceprovider.serviceproviderId}**${serviceprovider.hdnNummer}`
    });
  });

  aanvraag.softwareKoppelingen.forEach(softwareKoppeling => {
    methodeIndienenOptions.push({
      label: `Via ${softwareKoppeling.naam}`,
      value: `${AanvraagType.SoftwareKoppeling}**${softwareKoppeling.naam}****${softwareKoppeling.softwareKoppelingId}`
    });
  });

  // istanbul ignore next
  if(!aanvraag.hypotheek) /* istanbul ignore next */ return <></>;

  return (
    <AdviesBoxFormik<aanvraagHypotheekType>
      initialValues={aanvraag.hypotheek}
      validationSchema={aanvraagHypotheekSchema}
      closeModal={closeModal}
      onSave={onSave}
      data-testid="aanvraag-naar-hypotheek-modal"
      render={({ submitForm }: FormikProps<aanvraagHypotheekType>): ReactElement => {
        return (
          <>
            <Modal
              title="Aanvraag naar"
              saveButtonTekst={"Bevestigen"}
              onSubmitClick={submitForm}
              onCancelClick={closeModal}
              body={
                <>
                  <LabeledSelectInput
                    caption="Methode indienen"
                    name="methodeIndienen"
                    options={methodeIndienenOptions}
                  />
                </>
              }
            />
          </>
        );
      }}
    />
  );
};

AanvraagNaarHypotheekModal.displayName = "AanvraagNaarHypotheekModal";

export default AanvraagNaarHypotheekModal;
