import { Tarieven, TarievenOutput } from "../../.generated/instellingen-forms/instellingen-formstypes";
import { useContext, useEffect } from "react";

import useAbortableFetch from "use-abortable-fetch";
import { useRequestInit, ForceRerenderContext } from "adviesbox-shared";

export type UseInstellingenTarievenDataResult = {
  error: null | Error;
  data: Tarieven | null;
  loading: boolean;
};

export const useInstellingenTarievenData = (): UseInstellingenTarievenDataResult => {
  const {
    requestInit,
    settings: { instellingenFormsOrigin }
  } = useRequestInit();
  const loadingDone = useContext(ForceRerenderContext);

  const url = `${instellingenFormsOrigin}/Tarieven`;
  const { loading, error, data } = useAbortableFetch<TarievenOutput>(url, requestInit);

  useEffect((): void => {
    if (!loading && !!data && loadingDone) {
      loadingDone();
    }
  }, [loading, data, loadingDone]);

  if (typeof data === "string") {
    return { error: new Error("Fout bij het laden van instellingen-tarieven data"), data: null, loading: false };
  }

  return { error, data: data ? data.tarieven : null, loading };
};
