import { ErrorPage, PageLoading, SettingsType, withErrorBoundary } from "adviesbox-shared";
import React, { ReactElement } from "react";

import { InvalidAdviesboxResultErrorPage } from "../shared/components/fetch-error-page/fetch-error-page";
import { MaximaleHypotheekOrganisatieAjax } from "./maximale-hypotheek-organisatie-ajax";
import { RouteParams } from "../shared/paramrouting/paramrouting-context";
import { mapDlTargetToMaximaleHypotheekUiField } from "./infra/map-maximale-hypotheek-dl-target-to-ui-field";
import { mapMaximaleHypotheekDlToUi } from "./infra/map-maximale-hypotheek-dl-to-ui";
import { mapMaximaleHypotheekUiToDl } from "./infra/map-maximale-hypotheek-ui-to-dl";
import { useAdviesboxData } from "../shared/hooks/use-adviesbox-data";

const createUrl = (s: SettingsType, p: RouteParams): string =>
  `${s.klantdossiersFormsOrigin}/Voorstellen/${p.voorstel}/MaximaleHypotheek`;

const MaximaleHypotheekAjax = (): ReactElement => {
  const { loading, error, data, platformData, saveData } = useAdviesboxData(
    createUrl,
    p => p.voorstel,
    mapMaximaleHypotheekDlToUi,
    mapMaximaleHypotheekUiToDl,
    mapDlTargetToMaximaleHypotheekUiField
  );

  if (loading) {
    return <PageLoading />;
  }

  if (error) {
    return <ErrorPage error={error} data={platformData} />;
  }

  if (!data) {
    return <InvalidAdviesboxResultErrorPage />;
  }

  return <MaximaleHypotheekOrganisatieAjax saveData={saveData} data={data} />;
};

MaximaleHypotheekAjax.displayName = "MaximaleHypotheekAjax";

export default withErrorBoundary(MaximaleHypotheekAjax);
