import React, { ReactElement } from "react";
import { FormikProps } from "formik";

import { fiscaleAftrekpostenModalSchema, FiscaleAftrekpostenModalType } from "../infra/inkomen-en-fiscus-schema";
import { LabeledCurrencyInput, LabeledDateInput } from "adviesbox-shared";
import AdviesBoxFormik from "../../shared/utils/adviesbox-formik";
import Modal from "../../shared/components/modal/Modal";

export type FiscaleAftrekpostenModalData = {
  data: FiscaleAftrekpostenModalType;
};

type FiscaleAftrekpostenModalProps = {
  onSave?: (data: FiscaleAftrekpostenModalType) => void;
  closeModal?: () => void;
};

const FiscaleAftrekpostenModal = ({
  data,
  onSave,
  closeModal
}: FiscaleAftrekpostenModalData & FiscaleAftrekpostenModalProps): ReactElement => {
  const body = (
    <>
      <LabeledCurrencyInput caption="Te betalen alimentatie ex-partner" name="teBetalenAlimentatie" appendChildren={<small className={"pt-2 pl-2"}>Per jaar</small>} />
      <LabeledDateInput caption="Einddatum" name="eindDatum" />
    </>
  );

  return (
    <AdviesBoxFormik<FiscaleAftrekpostenModalType>
      initialValues={{ ...data }}
      validationSchema={fiscaleAftrekpostenModalSchema}
      onSave={onSave}
      closeModal={closeModal}
      render={({ values, submitForm }: FormikProps<FiscaleAftrekpostenModalType>): ReactElement => (
        <>
          <Modal title="Fiscale aftrekposten" body={body} onSubmitClick={submitForm} onCancelClick={closeModal} />
        </>
      )}
    />
  );
};

export default FiscaleAftrekpostenModal;
