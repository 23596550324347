import React, { ReactElement } from "react";
import { connect, FormikContextType } from "formik";
import { Financieringsoort } from "../../.generated/forms/formstypes";
import { LabeledText, LabeledCurrencyInput, LabeledDateInput, LabeledBevestigingInput } from "adviesbox-shared";
import { FinancieringsbehoefteState } from "../infra/financieringsbehoefte-types";

type ErfpachtProps = {
  readonly?: boolean;
};

const Erfpacht = ({
  readonly = false,
  formik
}: ErfpachtProps & {
  formik: FormikContextType<FinancieringsbehoefteState>;
}): ReactElement => {
  const erfpachtValue = formik.values.erfpacht.erfpacht;
  const eeuwigdurendErfpachtValue = formik.values.erfpacht.eeuwigdurendErfpacht;
  const soortFinanciering = formik.values.financiering.soortFinanciering;
  const isAankoopNieuwbouwOrAankoopBestaandebouw =
    soortFinanciering === Financieringsoort.AankoopNieuwbouw ||
    soortFinanciering === Financieringsoort.AankoopBestaandeBouw;

  return (
    <>
      {isAankoopNieuwbouwOrAankoopBestaandebouw ? (
        <>
          <LabeledBevestigingInput caption="Erfpacht" readonly={readonly} name="erfpacht.erfpacht" />
          {erfpachtValue && (
            <>
              <LabeledCurrencyInput caption="Jaarlijkse canon" name="erfpacht.jaarlijksCanon" readonly={readonly} />
              <LabeledBevestigingInput
                caption="Particuliere erfpacht"
                name="erfpacht.particulierErfpacht"
                readonly={readonly}
              />
              <LabeledBevestigingInput
                caption="Eeuwigdurende erfpacht"
                name="erfpacht.eeuwigdurendErfpacht"
                readonly={readonly}
              />
              {!eeuwigdurendErfpachtValue && (
                <LabeledDateInput caption="Einddatum" readonly={readonly} name="erfpacht.einddatum" />
              )}
            </>
          )}
        </>
      ) : (
        <>
          <LabeledCurrencyInput caption="Jaarlijkse canon" readonly={true} name="erfpacht.jaarlijksCanon" />

          <LabeledText
            value={formik.values.erfpacht.particulierErfpacht ? "Ja" : "Nee"}
            caption="Particuliere erfpacht"
            name="erfpacht.particulierErfpacht"
            fieldSize="no-size"
          />

          <LabeledText
            value={formik.values.erfpacht.eeuwigdurendErfpacht ? "Ja" : "Nee"}
            caption="Eeuwigdurende erfpacht"
            name="erfpacht.eeuwigdurendErfpacht"
            fieldSize="no-size"
          />

          {!eeuwigdurendErfpachtValue && (
            <LabeledDateInput readonly={true} caption="Einddatum" name="erfpacht.einddatum" />
          )}
        </>
      )}
    </>
  );
};

export default connect<ErfpachtProps, FinancieringsbehoefteState>(Erfpacht);
