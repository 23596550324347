import { default as ModalBody } from "../../../shared/components/modal/Modal";
import React, { ReactElement } from "react";
import { Modal } from "react-bootstrap";
import { VoorstelType } from "../../infra/aanleiding-schema";

type VoorstelDeleteModalProps = {
  showModal: boolean;
  onCloseModal: () => void;
  onSubmitModal: () => void;
  selectedVoorstel: VoorstelType | null;
};

export const VoorstelDeleteModal = ({
  showModal,
  onCloseModal,
  onSubmitModal,
  selectedVoorstel
}: VoorstelDeleteModalProps): ReactElement => {
  const voorstelNaam = selectedVoorstel
    ? selectedVoorstel.omschrijving || `Voorstel\xa0${selectedVoorstel.index + 1}#`
    : null;

  return (
    <Modal show={showModal}>
      {selectedVoorstel && (
        <div>
          <ModalBody
            title={`${voorstelNaam}\xa0verwijderen`}
            submitButtonVariant="danger"
            onCancelClick={onCloseModal}
            saveButtonTekst={`${voorstelNaam}\xa0verwijderen`}
            onSubmitClick={onSubmitModal}
            body={<div>Weet je zeker dat je voorstel&nbsp;{voorstelNaam}&nbsp;wilt verwijderen?</div>}
          />
        </div>
      )}
    </Modal>
  );
};
