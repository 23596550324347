/* eslint-disable @typescript-eslint/no-non-null-assertion */

import {
  VergoedingenoverzichtenOutput,
  SoortProductOptions,
  Vergoedingen,
  VergoedingenoverzichtProduct,
  Vergoedingenoverzicht,
  VergoedingenoverzichtProductKenmerken,
  FinancieringOptions
} from "../../.generated/forms/formstypes";
import { Tarieven, VastBedragBedragPerUurOptions } from "../../.generated/instellingen-forms/instellingen-formstypes";
import vergoedingenOverzichtSchema, {
  huidigProductSchema,
  huidigProductKenmerkSchema,
  vergoedingenOverzichtKaartSchema,
  VergoedingenOverzichtState,
  VergoedingenOverzichtType,
  HuidigProductType,
  vergoedingenOverzichtInstellingenTarievenSchema
} from "./vergoedingen-overzicht-schema";
import { UiName, FieldMap } from "../../shared/types";

import { createMapToUi } from "../../shared/utils/create-map-to-ui";
import { createMapToDl } from "../../shared/utils/create-map-to-dl";
import { target2field } from "../../shared/utils/target-to-field";
import { mapStringToLocalDate, mapLocalDateToString } from "adviesbox-shared";

const mapHuidigProductDl2Ui = createMapToUi(huidigProductSchema).from<VergoedingenoverzichtProduct>({
  productId: v => v.productId,
  productnaam: v => v.productnaam || "",
  maatschappijNaam: v => v.maatschappijNaam || "",
  omschrijving: v => v.omschrijving || "",
  meenemen: v => v.meenemen,
  premievrijMaken: v => (!v.premievrijMaken ? null : true),
  premievrijMakenMogelijk: v => v.premievrijMakenMogelijk,
  inFinanciering: v => v.inFinanciering,
  uitFinanciering: v => v.uitFinanciering,
  productsoort: v => v.productsoort,
  eindeLooptijdDatum: v => mapStringToLocalDate(v.eindeLooptijdDatum)
});

const mapHuidigProductKenmerkDl2Ui = createMapToUi(huidigProductKenmerkSchema).from<
  VergoedingenoverzichtProductKenmerken
>({
  productnaam: v => v.productnaam || "",
  uwBemiddeling: v => v.uwBemiddeling || false,
  ingangsDatum: v => mapStringToLocalDate(v.ingangsdatum),
  looptijd: v => v.looptijdInMaanden,
  hoofdsom: v => v.hoofdsomBedrag,
  dekking1: v => v.dekking1Bedrag,
  dekking2: v => v.dekking2Bedrag,
  premie: v => v.premieBedrag,
  rentePercentage: v => v.rentePercentage,
  rvp: v => v.rentevastAantalMaanden,
  rbt: v => v.rentebedenktijdInMaanden,
  betalingstermijn: v => v.betalingstermijn,
  opbouwBedrag: v => v.opbouwBedrag,
  renteBedrag: v => v.renteBedrag
});

export const mapVergoedingenOverzichtInstellingenTarievenToUi = createMapToUi(
  vergoedingenOverzichtInstellingenTarievenSchema
).from<{ tarieven: Tarieven; financiering: FinancieringOptions | null }>({
  advieskostenPerUur: ({ tarieven }) =>
    tarieven.advieskostenVaststellingWaarde === VastBedragBedragPerUurOptions.BedragPerUur,
  advieskostenUurTarief: ({ tarieven }) => tarieven.advieskostenBedragPerUur,
  hypotheekAdvieskostenUurTarief: ({ tarieven }) => tarieven.hypotheekAdvieskostenBedragPerUur,
  advieskostenDefaultBedrag: ({ tarieven, financiering }) =>
    financiering === FinancieringOptions.GeenFinanciering
      ? tarieven.advieskostenVastBedrag
      : tarieven.hypotheekAdvieskostenVastBedrag,
  geldleningPerUur: ({ tarieven }) =>
    tarieven.bemiddelingsvergoedingGeldleningVaststellingWaarde === VastBedragBedragPerUurOptions.BedragPerUur,
  bemiddelingsvergoedingGeldleningUurTarief: ({ tarieven }) => tarieven.bemiddelingsvergoedingGeldleningBedragPerUur,
  bemiddelingsvergoedingGeldleningDefaultBedrag: ({ tarieven }) => tarieven.bemiddelingsvergoedingGeldleningVastBedrag,
  overigeVergoedingPerUur: ({ tarieven }) =>
    tarieven.overigeBemiddelingsvergoedingVaststellingWaarde === VastBedragBedragPerUurOptions.BedragPerUur,
  overigeBemiddelingsvergoedingUurTarief: ({ tarieven }) => tarieven.overigeBemiddelingsvergoedingBedragPerUur,
  overigeBemiddelingsvergoedingDefaultBedrag: ({ tarieven }) => tarieven.overigeBemiddelingsvergoedingVastBedrag,
  dossiervergoedingDefaultBedrag: ({ tarieven }) => tarieven.dossiervergoedingVastBedrag
});

const mapVergoedingenOverzichtDl2Ui = createMapToUi(vergoedingenOverzichtKaartSchema)
  .with<{ tarieven: Tarieven; financiering: FinancieringOptions | null }>()
  .from<Vergoedingen>({
    vergoedingenOverzichtInstellingenTarieven: (_, c) => mapVergoedingenOverzichtInstellingenTarievenToUi(c),
    ingangsdatum: v => mapStringToLocalDate(v.ingangsdatum),
    hypotheekNorm: v =>
      v.hypotheekNorm && v.hypotheekNorm.jaar && v.hypotheekNorm.maand
        ? {
            jaar: v.hypotheekNorm.jaar,
            maand: v.hypotheekNorm.maand
          }
        : null,
    hypotheekNormJaarMaand: v => (v.hypotheekNorm ? `${v.hypotheekNorm?.jaar},${v.hypotheekNorm?.maand}` : ""),
    advieskostenAantalUur: v => v.advieskostenAantalUur,
    advieskostenOvernemen: v => v.advieskostenOvernemen || false,
    advieskostenBedrag: v => v.advieskostenBedrag,
    bemiddelingsvergoedingGeldleningBedrag: v => v.bemiddelingsvergoedingGeldleningBedrag,
    bemiddelingsvergoedingGeldleningOvernemen: v => v.bemiddelingsvergoedingGeldleningOvernemen,
    bemiddelingsvergoedingGeldleningAantalUur: v => v.bemiddelingsvergoedingGeldleningAantalUur,
    overigeBemiddelingsvergoedingBedrag: v => v.overigeBemiddelingsvergoedingBedrag,
    overigeBemiddelingsvergoedingOvernemen: v => v.overigeBemiddelingsvergoedingOvernemen || false,
    overigeBemiddelingsvergoedingAantalUur: v => v.overigeBemiddelingsvergoedingAantalUur,
    dossiervergoedingBedrag: v => v.dossiervergoedingBedrag
  });

export const mapVergoedingenDlNaarUi = (tarievenInstellingen: Tarieven | null) => (
  adviesdossierId: string,
  data: VergoedingenoverzichtenOutput
): VergoedingenOverzichtState | null => {
  const values = data && data.vergoedingenoverzichten ? data.vergoedingenoverzichten[adviesdossierId] : null;

  if (!values || !tarievenInstellingen) {
    return null;
  }

  const vergoedingen = values.vergoedingen || ({} as Vergoedingen);
  const financiering = values.financiering;
  const huidigeProducten = values.huidigeProducten || [];
  const huidigeProductenKenmerken = values.huidigeProductenKenmerken || [];

  const vergoedingOverzicht: VergoedingenOverzichtState = {
    vergoedingenOverzicht: mapVergoedingenOverzichtDl2Ui({ tarieven: tarievenInstellingen, financiering })(
      vergoedingen
    ),
    huidigProducten: huidigeProducten.map(x => mapHuidigProductDl2Ui(x)),
    huidigProductKenmerken: huidigeProductenKenmerken.map(x => mapHuidigProductKenmerkDl2Ui(x)),

    financiering: values.financiering!
  };

  return vergoedingenOverzichtSchema.cast(vergoedingOverzicht);
};

export function mapVergoedingenOverzichtUiNaarDl(vergoeding: VergoedingenOverzichtType): Vergoedingen {
  return {
    ingangsdatum: mapLocalDateToString(vergoeding.ingangsdatum),
    hypotheekNorm: vergoeding.hypotheekNorm || null,
    advieskostenBedrag: vergoeding.advieskostenBedrag,
    advieskostenOvernemen: vergoeding.advieskostenOvernemen,
    advieskostenAantalUur: vergoeding.advieskostenAantalUur,
    bemiddelingsvergoedingGeldleningBedrag: vergoeding.bemiddelingsvergoedingGeldleningBedrag,
    bemiddelingsvergoedingGeldleningOvernemen: vergoeding.bemiddelingsvergoedingGeldleningOvernemen,
    bemiddelingsvergoedingGeldleningAantalUur: vergoeding.bemiddelingsvergoedingGeldleningAantalUur,
    overigeBemiddelingsvergoedingBedrag: vergoeding.overigeBemiddelingsvergoedingBedrag,
    overigeBemiddelingsvergoedingOvernemen: vergoeding.overigeBemiddelingsvergoedingOvernemen,
    overigeBemiddelingsvergoedingAantalUur: vergoeding.overigeBemiddelingsvergoedingAantalUur,
    dossiervergoedingBedrag: vergoeding.dossiervergoedingBedrag,
    dossiervergoedingOvernemen: null // wordt niet gebruikt in het scherm
  };
}

export function mapHuidigeProductenUiNaarDl(producten: HuidigProductType[]): VergoedingenoverzichtProduct[] {
  return producten.map(
    (item): VergoedingenoverzichtProduct => {
      const product: VergoedingenoverzichtProduct = {
        productId: item.productId,
        productsoort: item.productsoort,
        meenemen: item.meenemen,
        premievrijMaken:
          item.productsoort === SoortProductOptions.Overlijdensrisicoverzekering ? null : item.premievrijMaken,
        inFinanciering:
          item.productsoort === SoortProductOptions.Overlijdensrisicoverzekering ? null : item.inFinanciering,
        uitFinanciering: item.productsoort === SoortProductOptions.Krediet ? item.uitFinanciering : null,
        productnaam: item.productnaam,
        maatschappijNaam: item.maatschappijNaam,
        omschrijving: item.omschrijving,
        premievrijMakenMogelijk: item.premievrijMakenMogelijk,
        eindeLooptijdDatum: mapLocalDateToString(item.eindeLooptijdDatum)
      };
      return product;
    }
  );
}

const mapHuidigProductKenmerkUi2Dl = createMapToDl(huidigProductKenmerkSchema).to<
  VergoedingenoverzichtProductKenmerken
>({
  productnaam: v => v.productnaam || "",
  uwBemiddeling: v => v.uwBemiddeling || false,
  ingangsdatum: v => (v.ingangsDatum ? mapLocalDateToString(v.ingangsDatum) : null),
  looptijdInMaanden: v => v.looptijd,
  hoofdsomBedrag: v => v.hoofdsom,
  dekking1Bedrag: v => v.dekking1,
  dekking2Bedrag: v => v.dekking2,
  premieBedrag: v => v.premie,
  rentePercentage: v => v.rentePercentage,
  rentevastAantalMaanden: v => v.rvp,
  rentebedenktijdInMaanden: v => v.rbt,
  betalingstermijn: v => v.betalingstermijn,
  opbouwBedrag: v => v.opbouwBedrag,
  renteBedrag: v => v.renteBedrag
});

export function mapVergoedingenUiNaarDl(vergoedingen: VergoedingenOverzichtState): Vergoedingenoverzicht {
  return {
    financiering: vergoedingen.financiering,
    vergoedingen: mapVergoedingenOverzichtUiNaarDl(vergoedingen.vergoedingenOverzicht),
    huidigeProducten: mapHuidigeProductenUiNaarDl(vergoedingen.huidigProducten),
    huidigeProductenKenmerken: vergoedingen.huidigProductKenmerken.map(x => mapHuidigProductKenmerkUi2Dl(x))
  };
}

export function mapVergoedingenDlNameToUiName(target: string): UiName | null {
  const map: FieldMap<Vergoedingen> = {};

  return target2field(map, target);
}
