import { FormikContextType } from "formik";
import React, { ReactElement } from "react";
import { Card } from "adviesbox-shared";
import { KlantprofielVraagOptions } from "../../.generated/forms/formstypes";

import {
  KlantprofielOptieType,
  QuestionSpec,
  QuestionSpecCombined,
  QuestionType,
  KlantprofielVragenType,
  AanvragersNaam,
  CardSpec,
  TitleType,
  QuestionCondition
} from "./klantprofiel-schema";
import { QuestionBedrag } from "./question-components/question-bedrag/question-bedrag";
import { QuestionRadio } from "./question-components/question-radio/question-radio";
import { QuestionCheckbox } from "./question-components/question-checkbox/question-checkbox";
import { QuestionJaar } from "./question-components/question-jaar/question-jaar";
import { QuestionMaand } from "./question-components/question-maand/question-maand";
import { QuestionToelichting } from "./question-components/question-toelichting/question-toelichting";
import { QuestionSlider } from "./question-components/question-slider/question-slider";
import { QuestionInstructionText } from "./question-components/question-instructiontext/question-instruction-text";
import { mergedSpecs } from "./merged-specs";
import { QuestionRadioMetJaar } from "./question-components/question-radio-met-jaar/question-radio-met-jaar";
import { QuestionRadioMetText } from "./question-components/question-radio-met-text/question-radio-met-text";
import { QuestionToggleCheckbox } from "./question-components/question-toggle-check-box/question-toggle-check-box";
import { QuestionRadioMetJaarMaanden } from "./question-components/question-radio-met-jaar-maanden/question-radio-met-jaar-maanden";
import { QuestionJaarMaanden } from "./question-components/question-jaar-maanden/question-jaar-maanden";
import { getNaam, toArray } from "../../shared/utils/helpers";

const _isAanvrager1Question = (code: KlantprofielVraagOptions): boolean => code.includes("Aanvrager1");
const _isAanvrager2Question = (code: KlantprofielVraagOptions): boolean => code.includes("Aanvrager2");

const vervangAanvragerNamen = (input: string, schema: AanvragersNaam): string =>
  input.replace("[AANVRAGER1]", schema.aanvragerNaam1).replace("[AANVRAGER2]", schema.aanvragerNaam2);

export const shouldQuestionBeDisplayed = (
  spec: QuestionSpec,
  allSpecs: QuestionSpec[],
  formik: KlantprofielOptieType
): boolean => {
  const conditions = toArray(spec.condition);
  if (conditions.length === 0) return true;
  return anyConditionTrue(conditions, allSpecs, formik);
};

const shouldShowVerplichtToelichting = (
  spec: QuestionSpec,
  allSpecs: QuestionSpec[],
  formik: KlantprofielOptieType
): boolean => {
  const conditions = toArray(spec.showVerplichtToelichting);
  if (conditions.length === 0) return false;
  return anyConditionTrue(conditions, allSpecs, formik);
};

function anyConditionTrue(
  conditions: QuestionCondition[],
  allSpecs: QuestionSpec[],
  formik: KlantprofielOptieType
): boolean {
  for (let i = 0; i < conditions.length; i++) {
    const condition = conditions[i];

    if (_isAanvrager2Question(condition.question) && !formik.aanvrager2) continue;

    const spec = allSpecs.find(q => q.question.toString() === condition.question.toString());
    if (!spec) {
      throw new Error(`no QuestionSpec for condition with question code ${condition.question}`);
    }

    if (spec.condition && !shouldQuestionBeDisplayed(spec, allSpecs, formik)) {
      continue;
    }

    const vraag = formik.vragen.find(v => v.code.toString() === condition.question.toString());
    if (!vraag) throw new Error(`no formik.vragen available for condition with question code ${condition.question}`);

    switch (spec.type) {
      case QuestionType.radio:
      case QuestionType.radioMetJaarMaanden:
      case QuestionType.radioMetText:
        if (condition.answer.toString() === vraag.gekozenAntwoord?.toString()) {
          return true;
        }
        break;

      case QuestionType.checkbox:
      case QuestionType.toggleCheckbox:
        if (conditions.some(c => vraag.antwoorden.some(a => a.waarde1 && c.answer.toString() === a.code.toString()))) {
          return true;
        }
        break;
    }
  }

  return false;
}

export const combineQuestionSpecsAndMappedData = (
  specs: QuestionSpec[],
  formik: FormikContextType<KlantprofielOptieType>
): QuestionSpecCombined[] =>
  specs
    // Filter aanvrager2 questions if we don't have aanvrager2 info
    .filter(q => !q.question.includes("Aanvrager2") || (q.question.includes("Aanvrager2") && formik.values.aanvrager2))
    .map((questionSpec, i) => {
      if (i === 0 && questionSpec.condition) {
        throw new Error(`first question (${questionSpec.question}) in specs is not allowed to have a condition`);
      }
      const unboundQuestion = questionSpec.question.toString() === "unbound";

      const platformSpecIndex = unboundQuestion
        ? -1
        : formik.values.vragen.findIndex(p => {
            return p.code && p.code.toString() === questionSpec.question.toString();
          });

      if (platformSpecIndex === -1 && !unboundQuestion) {
        throw new Error(`no platform question index defined for question ${questionSpec.question}`);
      }

      const schema: KlantprofielVragenType = unboundQuestion
        ? {
            code: "unbound" as KlantprofielVraagOptions,
            omschrijving: "unbound",
            antwoorden: [],
            gekozenAntwoord: null,
            toelichting: null
          }
        : formik.values.vragen[platformSpecIndex];

      const displayQuestion = (): boolean => shouldQuestionBeDisplayed(questionSpec, specs, formik.values);
      const displayToelichtingVerplicht = (): boolean =>
        shouldShowVerplichtToelichting(questionSpec, specs, formik.values);
      const isAanvrager1Question = (): boolean => _isAanvrager1Question(schema.code);
      const isAanvrager2Question = (): boolean => _isAanvrager2Question(schema.code);

      const aanvragers: AanvragersNaam = {
        aanvragerNaam1: getNaam(formik.values.aanvrager1, "Aanvrager1"),
        aanvragerNaam2: getNaam(formik.values.aanvrager2, "Aanvrager2")
      };

      let omschrijving: string | null;
      switch (questionSpec.title) {
        case TitleType.platform:
        case undefined:
          omschrijving = schema.omschrijving ? vervangAanvragerNamen(schema.omschrijving, aanvragers) : null;
          break;
        case TitleType.aanvrager:
          omschrijving =
            isAanvrager1Question() && formik.values.aanvrager2
              ? aanvragers.aanvragerNaam1
              : isAanvrager1Question() && !formik.values.aanvrager2
              ? ""
              : isAanvrager2Question()
              ? aanvragers.aanvragerNaam2
              : null;
          break;
        case TitleType.aanvrager1:
          omschrijving = aanvragers.aanvragerNaam1;
          break;
        case TitleType.aanvrager2:
          omschrijving = aanvragers.aanvragerNaam2;
          break;
        case TitleType.none:
          omschrijving = null;
          break;
        case TitleType.custom:
          omschrijving = questionSpec.customTitle ?? null;
          break;
        default:
          throw new Error(`unknown TitleType ${questionSpec.title}`);
      }

      const platformSpec = {
        ...aanvragers,
        ...schema,
        omschrijving
      };

      return {
        ...questionSpec,
        schema: platformSpec,
        index: platformSpecIndex,
        specsIndex: i,
        displayQuestion,
        displayToelichtingVerplicht,
        isAanvrager1Question,
        isAanvrager2Question
      };
    });

const renderQuestions = (specs: QuestionSpecCombined[]): ReactElement[] =>
  specs.map(c => {
    if (!c.displayQuestion()) return <React.Fragment key={c.question}></React.Fragment>;

    switch (c.type) {
      case QuestionType.radio:
        return <QuestionRadio data={c} key={c.question} />;
      case QuestionType.bedrag:
        return <QuestionBedrag data={c} key={c.question} />;
      case QuestionType.jaar:
        return <QuestionJaar data={c} key={c.question} />;
      case QuestionType.maand:
        return <QuestionMaand data={c} key={c.question} />;
      case QuestionType.jaarMaanden:
        return <QuestionJaarMaanden data={c} key={c.question} />;
      case QuestionType.radioMetJaarMaanden:
        return <QuestionRadioMetJaarMaanden data={c} key={c.question} />;
      case QuestionType.radioMetText:
        return <QuestionRadioMetText data={c} key={c.question} />;
      case QuestionType.radioMetJaar:
        return <QuestionRadioMetJaar data={c} key={c.question} />;
      case QuestionType.checkbox:
        return <QuestionCheckbox data={c} key={c.question} />;
      case QuestionType.toggleCheckbox:
        return <QuestionToggleCheckbox data={c} key={c.question} />;
      case QuestionType.slider:
        return <QuestionSlider data={c} key={c.question} />;
      case QuestionType.instructionText:
        return <QuestionInstructionText data={c} key={c.question} />;
      case QuestionType.toelichting:
        throw new Error("QuestionType.toelichting should not be used directly");
      default:
        throw new Error(`unknown QuestionType ${c.type}`);
    }
  });

export const renderSpec = (specs: CardSpec[], formik: FormikContextType<KlantprofielOptieType>): ReactElement[] =>
  specs.map((card, cardIndex) => {
    if (card.questions && card.sections) throw new Error("questions and sections not allowed on cards, pick one");

    if (card.questions) {
      const combinedQuestionSpecs = combineQuestionSpecsAndMappedData(card.questions, formik);
      if (combinedQuestionSpecs.length === 0) return <React.Fragment key={cardIndex}></React.Fragment>;
      const renderedQuestions = renderQuestions(combinedQuestionSpecs);
      const showToelichting = card.toelichting !== false; // default true
      const multipleQuestions =
        combinedQuestionSpecs.filter(
          q => q.displayQuestion() && q.type !== QuestionType.instructionText && q.type !== QuestionType.slider
        ).length > 1;

      return (
        <Card
          title={card.title}
          subtitle={card.subtitle}
          subtitleItalic={true}
          subtitleOnSeparateLine={true}
          data-testid={`card-${cardIndex}`}
          key={`key-card-${cardIndex}`}
        >
          <div className="mb-3 px-4">
            {renderedQuestions}
            {showToelichting && (
              <QuestionToelichting
                data={{ ...combinedQuestionSpecs[0], type: QuestionType.toelichting }}
                multipleQuestions={multipleQuestions}
              />
            )}
          </div>
        </Card>
      );
    } else if (card.sections) {
      const renderedSections = card.sections.map((section, sectionIndex) => {
        const combinedQuestionSpecs = combineQuestionSpecsAndMappedData(section.questions, formik);
        if (combinedQuestionSpecs.length === 0) return <React.Fragment key={sectionIndex}></React.Fragment>;
        const renderedQuestions = renderQuestions(combinedQuestionSpecs);
        const showToelichting = section.toelichting !== false; // default true
        const multipleQuestions =
          combinedQuestionSpecs.filter(
            q => q.displayQuestion() && q.type !== QuestionType.instructionText && q.type !== QuestionType.slider
          ).length > 1;

        return (
          <div className="p-4" key={`key-section-${sectionIndex}`}>
            {section.title === TitleType.custom && section.customTitle && <h2>{section.customTitle}</h2>}
            {section.title === TitleType.aanvrager1 && formik.values.aanvrager1 && (
              <h2>{getNaam(formik.values.aanvrager1, "Aanvrager 1")}</h2>
            )}
            {section.title === TitleType.aanvrager2 && formik.values.aanvrager2 && (
              <h2>{getNaam(formik.values.aanvrager2, "Aanvrager 2")}</h2>
            )}
            {section.subtitle && <span className="d-block font-italic px-1">{section.subtitle}</span>}

            <div className="mb-3">
              {renderedQuestions}
              {showToelichting && (
                <QuestionToelichting
                  data={{ ...combinedQuestionSpecs[0], type: QuestionType.toelichting }}
                  multipleQuestions={multipleQuestions}
                />
              )}
            </div>
          </div>
        );
      });
      return (
        <Card
          title={card.title}
          subtitle={card.subtitle}
          subtitleItalic={true}
          subtitleOnSeparateLine={true}
          data-testid={`card-${cardIndex}`}
          key={`key-card-${cardIndex}`}
        >
          <div>{renderedSections}</div>
        </Card>
      );
    } else {
      throw new Error("cards must contain questions or sections");
    }
  });

export const combineQuestionSpecsFor = (cardSpecs: CardSpec[]): QuestionSpec[] =>
  cardSpecs
    // Reduce cards & sections to questions
    .reduce((acc: QuestionSpec[], card) => {
      if (card.questions) return [...acc, ...card.questions];
      if (card.sections) {
        const questions = card.sections.reduce((acc: QuestionSpec[], section) => [...acc, ...section.questions], []);
        return [...acc, ...questions];
      }
      return [];
    }, []);

export const getSpecForKlantprofielVraagOptions = (code: KlantprofielVraagOptions): QuestionSpec => {
  const spec = combineQuestionSpecsFor(mergedSpecs).find(s => s.question === code);
  if (!spec) throw new Error(`No spec for question ${code}`);

  return spec;
};

export const getQuestionType = (code: KlantprofielVraagOptions): QuestionType =>
  getSpecForKlantprofielVraagOptions(code).type;

export const alleVragenMetAntwoordCheck = (vragen: KlantprofielVragenType[]): boolean => {
  return vragen.every(v => v.gekozenAntwoord || v.antwoorden.some(a => a.waarde1));
};

/* istanbul ignore else */
if (process.env.NODE_ENV !== "production") {
  const checkSpecDefinitionForIssues = (
    specToCheck: QuestionSpec,
    allSpecs: QuestionSpec[],
    recursiveCheck?: QuestionSpec[]
  ): void => {
    if (recursiveCheck && recursiveCheck.includes(specToCheck)) {
      throw new Error(
        `infinite condition loop detected on question ${specToCheck.question}: ${[...recursiveCheck, specToCheck]
          .map(s => s.question)
          .join(" => ")}`
      );
    }

    const conditions =
      specToCheck.condition instanceof Array
        ? specToCheck.condition
        : specToCheck.condition
        ? [specToCheck.condition]
        : [];
    if (conditions.length === 0) return;

    for (let i = 0; i < conditions.length; i++) {
      const condition = conditions[i];

      const spec = allSpecs.find(q => q.question.toString() === condition.question.toString());
      if (!spec)
        throw new Error(
          `Error in condition for question ${specToCheck.question}: condition with question ${condition.question} has no spec`
        );

      switch (spec.type) {
        case QuestionType.radio:
        case QuestionType.radioMetJaarMaanden:
        case QuestionType.radioMetText:
          break;
        case QuestionType.checkbox:
        case QuestionType.toggleCheckbox:
          break;
        default:
          throw new Error(
            `Error in condition for question ${specToCheck.question}: condition with question ${spec.question} has type ${spec.type} which is not supported in conditions`
          );
      }

      if (spec.condition) {
        checkSpecDefinitionForIssues(spec, allSpecs, recursiveCheck ? [...recursiveCheck, specToCheck] : [specToCheck]);
      }
    }
  };

  combineQuestionSpecsFor(mergedSpecs).forEach((spec, _, allSpecs) => checkSpecDefinitionForIssues(spec, allSpecs));
}
