import { createGetTextResources } from "../../shared/utils/text-resources-algemeen";
/* istanbul ignore file */

export const getZoekenTextResources = createGetTextResources({
  /*:TOOLTIP:*/
  MinderToegangsrechtenResultaten: "Er zijn meer dossiers onder deze klant waarvoor je geen rechten hebt.",
  LegeResultatenMetToegangsrechten: "Er zijn dossiers onder deze klant waarvoor je geen rechten hebt.",
  TimeoutWarning:
    "Het zoeken naar resultaten duurde te lang. Maak je zoektermen specifieker en probeer het opnieuw.",
  FoutBijImport: "Er ging iets fout bij het importeren. Controleer of het bestand een abox is en/of controleer de logs op fouten.",
  GeenAdviesdossierNaImport: "Er is na het importeren geen adviesdossier teruggekomen.",
  GeenFileGeselecteerdVoorImport: "Er is geen file geselecteerd voor import."
});
