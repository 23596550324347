import React, { ReactElement } from "react";
import Modal from "../../shared/components/modal/Modal";
import { FormikProps } from "formik";

import { getFinancieringsbehoefteTextResources } from "../infra/financieringsbehoefte-resources";
import { LabeledCurrencyInput, CurrencyInput, TextInput, BerekenCurrencyInput } from "adviesbox-shared";

import AdviesBoxFormik from "../../shared/utils/adviesbox-formik";
import { InbrengEigenGeldModal as InbrengEigenGeldModalState } from "../infra/financieringsbehoefte-types";
import { inbrengEigenGeldModalSchema } from "../infra/financieringsbehoefte-schema";
import classes from "./inbreng-eigen-geld-modal.module.scss";

export type InbrengEigenGeldModalData = {
  data: InbrengEigenGeldModalState;
};

type InbrengEigenGeldModalProps = {
  onSave?: (data: InbrengEigenGeldModalState) => void;
  closeModal?: () => void;
};

const InbrengEigenGeldModal = ({
  data,
  onSave,
  closeModal
}: InbrengEigenGeldModalData & InbrengEigenGeldModalProps): ReactElement => {
  const body = (values: InbrengEigenGeldModalState, touched: any): ReactElement => {
    return (
      <>
        <div>
          <LabeledCurrencyInput caption="Inbreng eigen geld" name="inbrengEigenGeld" allowNegative={true} />
        </div>
        <LabeledCurrencyInput
          caption="Inbreng voor verlagen restschuld"
          name="inbrengVoorVerlagenRestschuld"
          readonly={true}
          tooltip={getFinancieringsbehoefteTextResources("TooltipInbrengVoorVerlagenRestschuld")}
        />

        <BerekenCurrencyInput
          caption="Inbreng verwachte overwaarde"
          name="eigenGeldOverwaarde"
          tooltip={getFinancieringsbehoefteTextResources("TooltipEigengeldOverwaarde")}
        />

        <LabeledCurrencyInput caption="Extra aflossing" name="extraAflossing" />

        <div className="form-group form-row">
          <div className="col-7">
            <div className={classes.inbreng_eigen_geld}>
              <TextInput name="inbrengEigengeldOmschrijving1" placeholder="Omschrijving" />
            </div>
          </div>

          <div className="col-5">
            <CurrencyInput name="inbrengEigengeldBedrag1" />
          </div>
        </div>

        <div className="form-group form-row">
          <div className="col-7">
            <div className={classes.inbreng_eigen_geld}>
              <TextInput name="inbrengEigengeldOmschrijving2" placeholder="Omschrijving" />
            </div>
          </div>

          <div className="col-5">
            <CurrencyInput name="inbrengEigengeldBedrag2" />
          </div>
        </div>

        <h2>Inbreng polis / rekening</h2>

        <LabeledCurrencyInput caption="Inbreng opgebouwde waarde" name="inbrengOpgebouwdeWaarde" readonly={true} />

        <div className="form-group form-row">
          <div className="col-5">
            <div className={classes.inbreng_eigen_geld}>
              <TextInput name="inbrengPolisOmschrijving" placeholder="Omschrijving" />
            </div>
          </div>

          <div className="col-7">
            <CurrencyInput name="inbrengPolisBedrag" />
          </div>
        </div>

        <LabeledCurrencyInput
          caption="Waarvan verlagen restschuld"
          name="waarvanVerlagenRestschuld"
          readonly={true}
          tooltip={getFinancieringsbehoefteTextResources("TooltipInbrengWaarvanVerlagenRestschuld")}
        />
      </>
    );
  };
  return (
    <AdviesBoxFormik<InbrengEigenGeldModalState>
      initialValues={{ ...data }}
      validationSchema={inbrengEigenGeldModalSchema}
      onSave={onSave}
      closeModal={closeModal}
      render={({ values, submitForm, touched }: FormikProps<InbrengEigenGeldModalState>): ReactElement => (
        <>
          <Modal
            title="Inbreng eigen geld"
            body={body(values, touched)}
            onSubmitClick={submitForm}
            onCancelClick={closeModal}
          />
        </>
      )}
    />
  );
};

export default InbrengEigenGeldModal;
