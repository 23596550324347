import { InferType } from "yup";
import {
  uitkerendeLijfrenteSchema,
  verzekeringnemersSchema,
  verzekerdenSchema,
  kapitaalSchema,
  uitkerendeLijfrentesSchema,
  uitkerendeLijfrenteProductSchema
} from "./uitkerende-lijfrente-schema";

export type UitkerendeLijfrenteProductType = InferType<typeof uitkerendeLijfrenteProductSchema>;

export type UitkerendeLijfrentesType = InferType<typeof uitkerendeLijfrentesSchema>;

export type UitkerendeLijfrenteType = InferType<typeof uitkerendeLijfrenteSchema>;

export type VerzekeringnemersType = InferType<typeof verzekeringnemersSchema>;

export type VerzekerdenType = InferType<typeof verzekerdenSchema>;

export type KapitaalType = InferType<typeof kapitaalSchema>;

export const productCodeLijfrenteVerzekering = "10";
export const productCodeBancaireLijfrente = "11";
