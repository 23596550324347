import {
  ErrorPage,
  PageLoading,
  SettingsType,
  UseRequestInitOptions,
  useRequestInit,
  withErrorBoundary
} from "adviesbox-shared";
import { KlantprofielOnderwerpOptions, RisicoprofielOutput } from "../../../.generated/forms/formstypes";
import React, { ReactElement, useMemo } from "react";
import { mapDlTargetToKlantProfielUiField, mapKlantprofielDlToUi } from "../../infra/map-klantprofiel-scherm-dl-to-ui";

import Risicoprofiel from "./risicoprofiel";
import { alleVragenMetAntwoordCheck } from "../../infra/question-helpers";
import { mapKlantprofielUiToDl } from "../../infra/map-klantprofiel-scherm-ui-to-dl";
import { risicoprofielQuestionSpecs } from "./risicoprofiel-question-specs";
import useAbortableFetch from "use-abortable-fetch";
import { useAdviesboxData } from "../../../shared/hooks/use-adviesbox-data";

type RouteParams = {
  vestiging: string;
  adviesdossier: string;
};

const createUrl = (s: SettingsType, p: RouteParams): string =>
  `${s.klantdossiersFormsOrigin}/Adviesdossiers/${p.adviesdossier}/Klantprofiel/${KlantprofielOnderwerpOptions.VermogensopbouwRisicoprofiel}`;

const RisicoprofielAjax = (): ReactElement | null => {
  const { loading, error, data, platformData, saveData } = useAdviesboxData(
    createUrl,
    p => p.adviesdossier,
    mapKlantprofielDlToUi,
    mapKlantprofielUiToDl,
    mapDlTargetToKlantProfielUiField
  );

  const extraHeaders = useMemo((): UseRequestInitOptions | undefined => {
    if (data)
      return {
        method: "POST",
        extraHeaders: {
          "content-type": "application/json"
        },
        body: JSON.stringify({ klantprofiel: mapKlantprofielUiToDl(data) })
      };
    return undefined;
  }, [data]);

  const { settings, requestInit, params } = useRequestInit(extraHeaders);

  const url =
    data && !error && !loading && alleVragenMetAntwoordCheck(data.vragen)
      ? `${settings.klantdossiersFormsOrigin}/Adviesdossiers/${params.adviesdossier}/Klantprofiel/Risicoprofiel`
      : null;

  //TODO: refactor this fetch to a hook with mock + refactor associated tests
  const profielStatus = useAbortableFetch<RisicoprofielOutput>(url, requestInit);

  if (error) {
    return <ErrorPage error={error} data={platformData} />;
  }
  if (profielStatus.error) {
    return <ErrorPage error={profielStatus.error} data={profielStatus.data} />;
  }

  if (typeof profielStatus.data === "string") {
    return <ErrorPage error={new Error("invalid profielStatus data")} data={profielStatus.data} />;
  }

  if (
    loading ||
    !data ||
    (profielStatus.loading && alleVragenMetAntwoordCheck(data.vragen)) ||
    (!profielStatus.data && alleVragenMetAntwoordCheck(data.vragen))
  ) {
    return <PageLoading />;
  }

  const risicoprofielStatus = profielStatus.data?.resultaat?.risicoprofiel ?? null;

  return (
    <Risicoprofiel
      {...data}
      risicoprofielStatus={risicoprofielStatus}
      schermTitle="Risicoprofiel"
      specs={risicoprofielQuestionSpecs}
      saveData={saveData}
    />
  );
};

RisicoprofielAjax.displayName = "RisicoprofielAjax";

export default withErrorBoundary(RisicoprofielAjax);
