/* istanbul ignore file */
/* eslint-disable */
import React, { createContext, useContext, useRef, useCallback, ReactNode, useMemo } from 'react';

const logDebug = false;

type QueueItem<T> = {
  fn: () => Promise<T>;
  resolve: (value: T | PromiseLike<T>) => void;
  reject: (reason?: any) => void;
  lowPriority: boolean;
};

type SemaphoreContextType = {
  run: <T>(fn: () => Promise<T>, lowPriority?: boolean) => Promise<T>;
  getStatus: () => {
    active: number;
    activeHighPriority: number;
    activeLowPriority: number;
    queuedHighPriority: number;
    queuedLowPriority: number;
  };
};

const SemaphoreContext = createContext<SemaphoreContextType | null>(null);

export const SemaphoreProvider: React.FC<{ maxConcurrent?: number; children: ReactNode }> = ({ 
  maxConcurrent = 3, 
  children 
}) => {
  const highPriorityQueue = useRef<QueueItem<any>[]>([]);
  const lowPriorityQueue = useRef<QueueItem<any>[]>([]);
  const running = useRef<Set<Promise<any>>>(new Set());
  const activeCount = useRef(0);
  const activeHighPriorityCount = useRef(0);
  const activeLowPriorityCount = useRef(0);

  const runNext = useCallback(() => {
    logDebug && console.log(`[Semaphore] runNext called. Active: ${activeCount.current}, ActiveHigh: ${activeHighPriorityCount.current}, ActiveLow: ${activeLowPriorityCount.current}, MaxConcurrent: ${maxConcurrent}`);
    logDebug && console.log(`[Semaphore] Queue sizes - High: ${highPriorityQueue.current.length}, Low: ${lowPriorityQueue.current.length}`);

    const startTask = (item: QueueItem<any>) => {
      const { fn, resolve, reject, lowPriority } = item;
      logDebug && console.log(`[Semaphore] Starting new ${lowPriority ? 'low' : 'high'} priority task`);
      
      const promise = fn();
      running.current.add(promise);
      activeCount.current += 1;
      if (lowPriority) {
        activeLowPriorityCount.current += 1;
      } else {
        activeHighPriorityCount.current += 1;
      }

      promise
        .then(resolve)
        .catch(reject)
        .finally(() => {
          running.current.delete(promise);
          activeCount.current -= 1;
          if (lowPriority) {
            activeLowPriorityCount.current -= 1;
          } else {
            activeHighPriorityCount.current -= 1;
          }
          logDebug && console.log(`[Semaphore] Task completed. Active: ${activeCount.current}, ActiveHigh: ${activeHighPriorityCount.current}, ActiveLow: ${activeLowPriorityCount.current}`);
          runNext();
        });
    };

    // First, try to start high priority tasks
    while (running.current.size < maxConcurrent && highPriorityQueue.current.length > 0) {
      const nextItem = highPriorityQueue.current.shift();
      if (nextItem) startTask(nextItem);
    }

    // Only start a low priority task if there are no high priority tasks running or queued,
    // and no low priority task is currently running
    if (activeHighPriorityCount.current === 0 && 
        highPriorityQueue.current.length === 0 && 
        activeLowPriorityCount.current === 0 && 
        lowPriorityQueue.current.length > 0 &&
        running.current.size < maxConcurrent) {
      const nextItem = lowPriorityQueue.current.shift();
      if (nextItem) startTask(nextItem);
    }
  }, [maxConcurrent]);

  const run = useCallback(<T,>(fn: () => Promise<T>, lowPriority: boolean = false): Promise<T> => {
    return new Promise<T>((resolve, reject) => {
      const queueItem: QueueItem<T> = { fn, resolve, reject, lowPriority };
      if (lowPriority) {
        lowPriorityQueue.current.push(queueItem);
      } else {
        highPriorityQueue.current.push(queueItem);
      }
      logDebug && console.log(`[Semaphore] Task added to ${lowPriority ? 'low' : 'high'} priority queue. Queue sizes - High: ${highPriorityQueue.current.length}, Low: ${lowPriorityQueue.current.length}`);
      runNext();
    });
  }, [runNext]);

  const getStatus = useCallback(() => {
    return {
      active: activeCount.current,
      activeHighPriority: activeHighPriorityCount.current,
      activeLowPriority: activeLowPriorityCount.current,
      queuedHighPriority: highPriorityQueue.current.length,
      queuedLowPriority: lowPriorityQueue.current.length,
    };
  }, []);

  const contextValue = useMemo(() => ({ run, getStatus }), [run, getStatus]);

  return (
    <SemaphoreContext.Provider value={contextValue}>
      {children}
    </SemaphoreContext.Provider>
  );
};

export const useSemaphore = () => {
  const context = useContext(SemaphoreContext);
  if (!context) {
    throw new Error('useSemaphore must be used within a SemaphoreProvider');
  }
  return context;
};