import { createMapToDl } from "../../../shared/utils/create-map-to-dl";
import { nieuweKlantSchema, NieuwKlantRequestBody } from "../../infra/zoekscherm-schema";

export const mapNieuwKlantUiToDl = createMapToDl(nieuweKlantSchema).to<NieuwKlantRequestBody>({
  achternaamAanvrager1: v => v.aanvrager1.achternaam,
  tussenvoegselAanvrager1: v => v.aanvrager1.tussenVoegsel,
  roepnaamAanvrager1: v => v.aanvrager1.voornaam,
  geboortedatumAanvrager1: v => v.aanvrager1.geboorteDatum?.toJSON(),
  heeftAanvrager2: v => v.hasPartner,
  achternaamAanvrager2: v => (v.hasPartner && v.aanvrager2 ? v.aanvrager2.achternaam : null),
  geboortedatumAanvrager2: v => (v.hasPartner && v.aanvrager2 ? v.aanvrager2.geboorteDatum?.toJSON() : null),
  roepnaamAanvrager2: v => (v.hasPartner && v.aanvrager2 ? v.aanvrager2.voornaam : null),
  tussenvoegselAanvrager2: v => (v.hasPartner && v.aanvrager2 ? v.aanvrager2.tussenVoegsel : null)
});
