import { useContext, useEffect } from "react";

import { RentevariantMetRenteperiodesOutput } from "../../.generated/hypotheekrentes/hypotheekrentestypes";
import { SoortOnderpand } from "../../hypotheek/infra/hypotheek-types";
import useAbortableFetch from "use-abortable-fetch";
import { useRequestInit, ForceRerenderContext } from "adviesbox-shared";

export type UseHypotheekRentesResult = {
  error: null | Error;
  rentevarianten: RentevariantMetRenteperiodesOutput | null;
};

export function useHypotheekrentesData(
  geldverstrekkerCode: string,
  hypotheeklabelCode: number | null,
  soortOnderpand: SoortOnderpand,
  marktwaardePercentage: number,
  nhg: boolean,
  renteboxCode?: number | null
): UseHypotheekRentesResult {
  const loadingDone = useContext(ForceRerenderContext);

  const {
    requestInit,
    settings: { hypotheekrentesOrigin }
  } = useRequestInit();

  let url = "";
  if (renteboxCode !== null && hypotheeklabelCode !== null) {
    url = `${hypotheekrentesOrigin}/Geldverstrekkers/${geldverstrekkerCode
    }/Hypotheeklabels/${hypotheeklabelCode}/Hypotheekvormen/${renteboxCode}/Rentevarianten?soortOnderpand=${soortOnderpand}&marktwaardePercentage=${marktwaardePercentage}&nhg=${nhg}`;
  }
  const { error, data, loading } = useAbortableFetch<RentevariantMetRenteperiodesOutput>(url, requestInit);

  useEffect((): void => {
    if (!loading && !!data && loadingDone && renteboxCode) {
      loadingDone();
    }
  }, [loading, data, loadingDone, renteboxCode]);

  if (typeof data === "string") {
    return { error: new Error("Fout bij het laden van rentevarianten"), rentevarianten: null };
  }

  return {
    error,
    rentevarianten: data ?? null
  };
}

export default {
  useHypotheekrentesData
};
