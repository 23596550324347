import React, { ReactElement } from "react";
import { connect, FormikContextType } from "formik";

import { LeningGegevensKenmerken } from "../../producten-overzicht/infra/product-kenmerken-types";
import { SoortKredietProductOptions, Garantiesoort } from "../../.generated/forms/formstypes";
import {
  LabeledRadioInput,
  LabeledCurrencyInput,
  LabeledPercentageInput,
  BerekenCurrencyInput,
  LabeledDateInput,
  LabeledTextInput
} from "adviesbox-shared";
import LabeledHdnKeuzelijst from "../../shared/components/hdn-keuzelijst/LabeledHdnKeuzelijst";
import { KredietenType } from "../infra/kredieten-types";
import { getKredietenTextResources } from "../infra/kredieten-resources";

type Props = { selected: number; kenmerken: LeningGegevensKenmerken };

const LeningGegevens = ({
  selected,
  kenmerken,
  formik: {
    setFieldValue,
    values: { producten }
  }
}: Props & {
  formik: FormikContextType<KredietenType>;
}): ReactElement => {
  const {
    soortProduct
    // leningGegevens
  } = producten[selected];

  return (
    <>
      {kenmerken.berekenHoofdsomTonen ? (
        <BerekenCurrencyInput
          caption="Hoofdsom"
          name={`producten[${selected}].leningGegevens.hoofdsom`}
          tooltip={getKredietenTextResources("TooltipHoofdsom")}
          verplicht
        />
      ) : (
        <LabeledCurrencyInput
          caption={soortProduct === SoortKredietProductOptions.DoorlopendKrediet ? "Limiet" : "Hoofdsom"}
          name={`producten[${selected}].leningGegevens.hoofdsom.bedrag`}
          verplicht
        />
      )}

      {kenmerken.garantieTonen && (
        <LabeledRadioInput
          caption="Garantie"
          name={`producten[${selected}].leningGegevens.garantie`}
          options={[
            { label: "Geen", value: Garantiesoort.Geen },
            { label: "NHG", value: Garantiesoort.Nhg }
          ]}
        />
      )}

      {kenmerken.restantHoofdsomTonen && (
        <LabeledCurrencyInput
          caption="Restant hoofdsom"
          name={`producten[${selected}].leningGegevens.restantHoofdsom`}
        />
      )}

      <LabeledDateInput
        verplicht={true}
        tooltip="De opgavedatum moet liggen tussen de ingangsdatum van het krediet en de huidige datum plus 3 maanden."
        caption="Opgavedatum"
        name={`producten[${selected}].leningGegevens.opgaveDatum`}
      />

      {kenmerken.slottermijnTonen && (
        <LabeledCurrencyInput
          caption="Slottermijn"
          name={`producten[${selected}].leningGegevens.slottermijn`}
          tooltip={getKredietenTextResources("TooltipSlottermijn")}
        />
      )}

      {kenmerken.aflossingPercentageTonen && (
        <LabeledPercentageInput
          caption="Aflossingspercentage"
          name={`producten[${selected}].leningGegevens.aflossingPercentage`}
          decimalen={2}
        />
      )}

      <LabeledPercentageInput
        caption="Rentepercentage per jaar"
        name={`producten[${selected}].leningGegevens.rentePercentage`}
        decimalen={2}
      />

      {kenmerken.berekenMaandlastTonen ? (
        <BerekenCurrencyInput
          caption="Maandlast"
          name={`producten[${selected}].leningGegevens.maandlast`}
          decimalen={2}
        />
      ) : (
        <LabeledCurrencyInput
          caption="Maandlast"
          name={`producten[${selected}].leningGegevens.maandlast.bedrag`}
          decimalen={2}
        />
      )}

      <LabeledHdnKeuzelijst
        caption="Bestedingsdoel"
        name={`producten[${selected}].leningGegevens.bestedingsdoel`}
        berichtSoortType="KX"
        keuzelijst="BestedingsDoelType"
        readonly={!kenmerken.bestedingsdoelEnabled}
        emptyValue=""
      />

      {producten[selected].leningGegevens.bestedingsdoel === "99" && (
        <LabeledTextInput
          name={`producten[${selected}].leningGegevens.bestedingsdoelOmschrijving`}
          caption="Omschrijving"
        />
      )}
    </>
  );
};

export default connect<Props, KredietenType>(LeningGegevens);
