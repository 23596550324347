import classNames from "classnames";
import React, { ReactElement } from "react";
import { bedragFormat } from "../../shared/utils/currency";
import {
  FinancieringsBehoefteExtraPdfVelden,
  ToetsrenteType,
  UitgangspuntenType
} from "../infra/hypotheek-oversluiten-schema";
import classes from "./hypotheek-oversluiten-pdf.module.scss";

type HypotheekOversluitenIndicatiePDFTableProps = {
  uitgangspunten: UitgangspuntenType;
  toetsrente: ToetsrenteType;
  renderIndicatieTableOnly: boolean;
  extraPdfVelden: FinancieringsBehoefteExtraPdfVelden | null;
};

export const RenderHypotheekOversluitenIndicatieTable = ({
  renderIndicatieTableOnly,
  uitgangspunten,
  toetsrente,
  extraPdfVelden
}: HypotheekOversluitenIndicatiePDFTableProps): ReactElement => {
  const ttlHypotheekrente = uitgangspunten.huidigeSituatie?.reduce((sum, v) => (sum += v.hypotheekrente ?? 0), 0);
  const gemiddledHypotheekrente = ttlHypotheekrente ? ttlHypotheekrente / uitgangspunten.huidigeSituatie.length : 0;
  const indicatieHeaders = [
    "Over te sluiten hypotheek",
    "Waarde pand",
    "Bruto maandlasten",
    "Boeterente",
    "Gewenste rentevastperiode",
    "Notaris",
    "Taxatie",
    "NHG",
    "Advieskosten/Bemiddelingsvergoeding",
    "Dossiervergoeding",
    "Hypotheekrente (gemiddeld)"
  ];
  const indicatieContent = [
    bedragFormat(uitgangspunten?.gewensteHypotheek),
    bedragFormat(uitgangspunten?.marktwaarde),
    bedragFormat(uitgangspunten?.totaleBrutoRentelast, 2),
    bedragFormat(uitgangspunten?.boeterente),
    (toetsrente?.gewensteRentevastperiode ?? 0) + " Jaar",
    bedragFormat(extraPdfVelden?.arbeidskostenNotarisBedrag ?? 0),
    bedragFormat(extraPdfVelden?.taxatiekostenBedrag ?? 0),
    bedragFormat(extraPdfVelden?.nhgBedrag ?? 0),
    bedragFormat(extraPdfVelden?.adviesKosten ?? 0),
    bedragFormat(extraPdfVelden?.dossiervergoedingBedrag ?? 0),
    gemiddledHypotheekrente?.toFixed(2) + " %"
  ];

  return (
    <>
      <table className={classNames({ [classes.pdf_padding]: renderIndicatieTableOnly })}>
        <thead>
          <tr>
            <td className={classes.pdf_table_indicatie_header_left}>Uitgangspunten berekening</td>
            <td className={classes.pdf_table_indicatie_header_right}></td>
          </tr>
        </thead>
        <tbody>
          {indicatieHeaders.map((v, i) => (
            <tr key={"vt_row_" + i}>
              <td className={classes.pdf_table_data_info}>{v}</td>
              <td className={`${classes.pdf_table_data}`} style={i % 2 ? {} : { backgroundColor: "#f0f0f0" }}>
                {indicatieContent[i]}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};
