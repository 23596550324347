import { createGetTextResources } from "../../shared/utils/text-resources-algemeen";
/* istanbul ignore file */

export const getAanvraagTextResources = createGetTextResources({
  /*:TOOLTIP:*/
  meenemenError: "Kies een product dat je wilt meenemen naar Aanvragen.",
  loadingTxt: "Je wordt straks automatisch doorgestuurd naar Adviesbox Aanvragen.",
  successTxt: "De productaanvragen zijn succesvol doorgestuurd naar de aanvragen-app.",
  redirectedTxt: "Je wordt doorgestuurd naar Adviesbox Aanvragen.",
  generalErrorTxt:
    "Er is een fout opgetreden bij het controleren van de volledigheid van de aanvraag. Neem contact op met onze servicedesk.",
  generalErrorBemidelaarTxt: "Er is een fout opgetreden bij het laden van de resultaten.",
  geenProductenTxt: "Er zijn geen producten om over te nemen naar aanvragen",
  voegProductenToeTxt: "Gebruik het menu aan de linkerkant om producten toe te voegen",
  productaanvragen: "Product aanvragen",
  productOpnieuwAanvragen: "Product opnieuw aanvragen",
  nieuweVersieProductAanvragen: "Nieuwe versie product aanvragen"
});
