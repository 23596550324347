import { createMapToDl } from "../../shared/utils/create-map-to-dl";
import { Aanleiding as AanvraagDlEntry } from "../../.generated/forms/formstypes";
import { aanvraagSchema, AanvraagState, ProductTypeName } from "./aanvraag-schema";
import { NieuweAanvraagInput, AanvullendAntwoord } from "../../.generated/foundation/foundationtypes";

export const mapAanvraagUiToDl = createMapToDl(aanvraagSchema)
  // TODO: Remove any
  .to<any | AanvraagDlEntry>({
    // TODO: map keys
  });

type ProductCommon = {
  aanvraagNaarHdnNummer: string | null;
  aanvraagNaar: string | null;
  softwareKoppelingId: string | null;
};

interface ProductInfo {
  ontvangerNrHdn: string | null;
  ontvangerNaam: string | null;
  softwareKoppelingId: string | null;
}

/* istanbul ignore next */
const getProductInfoForProduct = <T extends ProductCommon>(product: T | undefined | null): ProductInfo => {
  return {
    ontvangerNrHdn: product?.aanvraagNaarHdnNummer ?? null,
    ontvangerNaam: product?.aanvraagNaar ?? null,
    softwareKoppelingId: product?.softwareKoppelingId ?? null
  };
};

/* istanbul ignore next */
const getProductInfoProductType = (productType: ProductTypeName | null, draft: AanvraagState): ProductInfo => {
  switch (productType) {
    case ProductTypeName.hypotheek:
      return getProductInfoForProduct(draft.hypotheek);
    case ProductTypeName.ORV:
      const orvProduct = draft.orvProduct?.find(v => v.productId === draft.geselecteerdProduct);
      return getProductInfoForProduct(orvProduct);
    case ProductTypeName.AOV:
      const aovProduct = draft.aovProduct?.find(v => v.productId === draft.geselecteerdProduct);
      return getProductInfoForProduct(aovProduct);
    case ProductTypeName.BG:
      return getProductInfoForProduct(draft.bankgarantieProduct);
    case ProductTypeName.taxatie:
      return getProductInfoForProduct(draft.taxatieProduct);
    default:
      return {} as ProductInfo;
  }
};

/* istanbul ignore next */
const getProductInfo = (draft: AanvraagState): ProductInfo => {
  const product = draft.productIdsEnTypes.find(v => v.productId === draft.geselecteerdProduct);
  if (!product) {
    return { ontvangerNrHdn: null, ontvangerNaam: null, softwareKoppelingId: null };
  }
  return getProductInfoProductType(product.productType, draft);
};

/* istanbul ignore next */
const isVoorstelAanvraagId = (draft: AanvraagState): boolean => {
  return draft.aanvraagOpBasisVan === draft.geselecteerdProduct;
};

/* istanbul ignore next */
export const mapNieuweAanvraagInput = (
  values: AanvraagState,
  adviesdossierId: string,
  nieuweVersie: boolean
): NieuweAanvraagInput => {
  const isVoorstelId = isVoorstelAanvraagId(values);
  const productInfo = getProductInfo(values);
  return {
    voorstelId: isVoorstelId ? values.aanvraagOpBasisVan : null,
    adviesdossierId: adviesdossierId,
    ontvangerNaam: productInfo.ontvangerNaam,
    ontvangerNrHdn: productInfo.ontvangerNrHdn,
    softwareKoppelingId: productInfo.softwareKoppelingId,
    dekkingId: isVoorstelId ? null : values.geselecteerdProduct,
    nieuweVersie: nieuweVersie,
    aanvullendeAntwoorden: [] as AanvullendAntwoord[],
    externeReferentie: values.externeReferentie
  };
};
