import * as Yup from "yup";
import { InferType } from "yup";
import { OrganisatieEx } from "../../.generated/licenties/licentiestypes";
import { klantnaamSchema } from "../../shared/generic-parts/klantnaam/schema";
import { nullableLocalDateSchema } from "adviesbox-shared";

export enum HypotheekOversluitenDataGridTemplates {
  akkoordOversluiten,
  maatschappijNaam,
  rentePercentage,
  brutoMaandlast,
  besparingMaand,
  oversluitkosten,
  besparingInRvp,
  terugverdienPeriodeInMaanden,
  terugverdienPeriodePercentage
}

export type FinancieringsBehoefteExtraPdfVelden = {
  adviesKosten: number | null;
  dossiervergoedingBedrag: number | null;
  taxatiekostenBedrag: number | null;
  nhgBedrag: number | null;
  arbeidskostenNotarisBedrag: number | null;
};
export type HypotheekOversluitenSortConfig = {
  reversed: boolean;
  field: HypotheekOversluitenDataGridTemplates | null;
};

export type HypotheekOversluitenSortFunc = (
  template: HypotheekOversluitenDataGridTemplates,
  advies: MappedBerekenHypotheekOversluitenResponse[],
  sorted: HypotheekOversluitenSortConfig,
  initialSort?: boolean
) => () => void;

export type HypotheekOversluitenSortFuncResponse = {
  sorted: HypotheekOversluitenSortConfig;
  advies: MappedBerekenHypotheekOversluitenResponse[];
};

export const templateHuidigeSituatieSchema = Yup.object({
  maatschappijnaam: Yup.string()
    .nullable()
    .default(null),
  hypotheekbedrag: Yup.number()
    .nullable()
    .default(null),
  hypotheekrente: Yup.number()
    .nullable()
    .default(null),
  brutoRentelasten: Yup.number()
    .nullable()
    .default(null),
  corrBrutoRentelasten: Yup.number()
    .nullable()
    .default(null),
  rentevastperiode: Yup.number()
    .nullable()
    .default(null),
  eindeRvp: nullableLocalDateSchema
});
export type HuidigeSituatieType = InferType<typeof templateHuidigeSituatieSchema>;

export const modalHuidigeSituatieSchema = Yup.array(templateHuidigeSituatieSchema).default([]);
export type ModalHuidigeSituatieType = InferType<typeof modalHuidigeSituatieSchema>;

export const uitgangspuntenSchema = Yup.object({
  marktwaarde: Yup.number()
    .nullable()
    .default(null),
  gewensteHypotheek: Yup.number()
    .nullable()
    .default(null),
  boeterente: Yup.number()
    .nullable()
    .default(null),
  totaleBrutoRentelast: Yup.number().default(0),
  huidigeSituatie: modalHuidigeSituatieSchema
});
export type UitgangspuntenType = InferType<typeof uitgangspuntenSchema>;

export const toetsrenteSchema = Yup.object({
  gewensteRentevastperiode: Yup.number()
    .nullable()
    .default(null)
});
export type ToetsrenteType = InferType<typeof toetsrenteSchema>;

export const berekenHypotheekOversluitenResponseSchema = Yup.object({
  geldverstrekkerCode: Yup.string().default(""),
  geldverstrekkerNaam: Yup.string().default(""),
  hypotheeklabelCode: Yup.number()
    .nullable()
    .default(null),
  hypotheeklabelNaam: Yup.string().default(""),
  rentepercentage: Yup.number()
    .nullable()
    .default(null),
  oversluitkostenBedrag: Yup.number()
    .nullable()
    .default(null),
  brutoRentelastPerMaand: Yup.number()
    .nullable()
    .default(null),
  besparingPerMaand: Yup.number()
    .nullable()
    .default(null),
  besparingRvp: Yup.number()
    .nullable()
    .default(null),
  terugverdienperiodeInMaanden: Yup.number()
    .nullable()
    .default(null),
  terugverdienpercentage: Yup.number()
    .nullable()
    .default(null),
  uitslag: Yup.string()
    .oneOf(["Rood", "Oranje", "Groen"])
    .default("Oranje")
});

export type BerekenHypotheekOversluitenResponse = InferType<typeof berekenHypotheekOversluitenResponseSchema>;

export const hypotheekOversluitenGridEntrySchema = Yup.object({
  akkoordOversluiten: Yup.string()
    .oneOf(["Rood", "Oranje", "Groen"])
    .default("Oranje"),
  maatschappijNaam: Yup.string().default(""),
  rentePercentage: Yup.number()
    .nullable()
    .default(null),
  brutoMaandlast: Yup.number()
    .nullable()
    .default(null),
  besparingMaand: Yup.number()
    .nullable()
    .default(null),
  oversluitkosten: Yup.number()
    .nullable()
    .default(null),
  besparingInRvp: Yup.number()
    .nullable()
    .default(null),
  terugverdienPeriodeInMaanden: Yup.number()
    .nullable()
    .default(null),
  terugverdienPeriodePercentage: Yup.number()
    .nullable()
    .default(null)
});
export type MappedBerekenHypotheekOversluitenResponse = InferType<typeof hypotheekOversluitenGridEntrySchema>;

export const hypotheekOversluitenSchema = Yup.object({
  uitgangspunten: uitgangspuntenSchema,
  toetsrente: toetsrenteSchema,
  dataHasChanged: Yup.boolean().default(false),
  berekendeHypotheken: Yup.array(hypotheekOversluitenGridEntrySchema).default([]),
  sortedBerekendeHypotheken: Yup.array(hypotheekOversluitenGridEntrySchema).default([]),
  aanvrager1: klantnaamSchema.nullable().default(null),
  aanvrager2: klantnaamSchema.nullable().default(null),
  organisatieData: Yup.object<OrganisatieEx>()
    .nullable()
    .default(null),
  extraPdfVelden: Yup.object<FinancieringsBehoefteExtraPdfVelden>()
    .nullable()
    .default(null)
});
export type HypotheekOversluitenState = InferType<typeof hypotheekOversluitenSchema>;
export type HypotheekOversluitenProps = InferType<typeof hypotheekOversluitenSchema>;

export interface BerekenHypotheekOversluitenInput {
  gewensteRentevastperiodeInJaren: number | null;
}
