import * as Yup from "yup";

import { klantnaamSchema } from "../../shared/generic-parts/klantnaam/schema";
import {
  LijfrenteUitgangspuntBerekeningOptions,
  SoortLijfrenteProductOptions
} from "../../.generated/forms/formstypes";

import {
  productSchemaYup,
  indicatieveUitkerendeFaseSchema
} from "../../producten-overzicht/infra/producten-overzicht-schema";

import { AanvragerKeuze } from "../../shared/types";
import { jaarMaandInputSchema, JaarMaandInputType } from "../../shared/generic-parts/jaar-maand/schema";
import { nullableLocalDateSchema } from "adviesbox-shared";

const productLooptijdDefault: JaarMaandInputType = { jaren: null, maanden: null };

const uitkerendeLijfrenteProductSchemaYup = productSchemaYup.shape({
  looptijd: jaarMaandInputSchema.default(productLooptijdDefault)
});

export const uitkerendeLijfrenteProductSchema = uitkerendeLijfrenteProductSchemaYup.default(
  uitkerendeLijfrenteProductSchemaYup.default()
);

export const verzekeringnemersSchema = Yup.object({
  verzekeringnemers: Yup.mixed<AanvragerKeuze>()
    .oneOf(Object.values(AanvragerKeuze))
    .default(AanvragerKeuze.Aanvrager1)
});

export const verzekerdenSchema = Yup.object({
  verzekerden: Yup.mixed<AanvragerKeuze>()
    .oneOf(Object.values(AanvragerKeuze))
    .default(AanvragerKeuze.Aanvrager1)
});

export const kapitaalSchema = Yup.object({
  uitgangspuntBerekening: Yup.mixed<LijfrenteUitgangspuntBerekeningOptions>()
    .oneOf([...Object.values(LijfrenteUitgangspuntBerekeningOptions), null])
    .nullable()
    .default(LijfrenteUitgangspuntBerekeningOptions.BeschikbareKoopsom),
  beschikbareKoopsom: Yup.number()
    .nullable()
    .default(null)
});

export const uitkerendeLijfrenteSchema = Yup.object({
  productId: Yup.string().nullable(),
  productCode: Yup.string(),
  partijCode: Yup.string(),
  soortProduct: Yup.mixed<SoortLijfrenteProductOptions>()
    .oneOf([...Object.values(SoortLijfrenteProductOptions), null])
    .nullable()
    .default(SoortLijfrenteProductOptions.BancaireLijfrente),
  product: uitkerendeLijfrenteProductSchema,
  verzekeringnemers: verzekeringnemersSchema,
  verzekerden: verzekerdenSchema,
  kapitaal: kapitaalSchema,
  indicatieveUitkerendeFase: indicatieveUitkerendeFaseSchema
});

export const uitkerendeLijfrentesSchema = Yup.object({
  producten: Yup.array(uitkerendeLijfrenteSchema).default([]),
  aanvrager1: klantnaamSchema.nullable(),
  aanvrager2: klantnaamSchema.nullable(),
  ingangsdatumVoorstel: nullableLocalDateSchema
});
