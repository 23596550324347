// TODO: istanbul weghalen als het de mapping verder wordt uitgewerkt
/* istanbul ignore file */
import { LocalDate } from "@js-joda/core";
import { mapJaarMaandInputFromLooptijdDl2Ui, mapStringToLocalDate } from "adviesbox-shared";
import * as Yup from "yup";
import {
  Aov as AovDlEntry,
  AovAanvrager,
  AovDekking,
  AovDekkingArbeidsongeschiktheid,
  AovDekkingBasis,
  AovDekkingRubriekA,
  Indexatiesoort,
  UitkeringAoSoort,
  AovDekkingRubriekB,
  AovDekkingWerkloosheid,
  AovOutput as AovDlOutput,
  BetalingsTermijnType,
  AovProduct,
  GeslachtOpties
} from "../../.generated/forms/formstypes";
import { mapSpecificatieAoUitkeringModalDl2Ui } from "../../producten-overzicht/infra/map-specificatie-ao-uitkering";
import { productSchema } from "../../producten-overzicht/infra/producten-overzicht-schema";
import { AoUitkeringswijze } from "../../producten-overzicht/infra/specificatie-ao-uitkering-schema";
import {
  mapAflosProductenDlToUi,
  mapAflosProductNew
} from "../../producten-overzicht/infra/verpanding/map-verpanding-dl-to-ui";
import { mapKlantenNaarAanvragerKeuze } from "../../shared/generic-parts/klantnaam/map-dl-2-ui";
import { mapBerekenInput } from "../../shared/types";
import { createMapEnum } from "../../shared/utils/create-map-enum";
import { createMapToUi } from "../../shared/utils/create-map-to-ui";
import { addMonths } from "../../shared/utils/dates";
import { prefixWithZero } from "../../shared/utils/helpers";
import {
  aanvullendeVragenSchema,
  aflossingSchema,
  aovAanvragerSchema,
  AovJaarlijkseIndexatieVan,
  AovScenarioCardInputType,
  aovSchema,
  aovsSchema,
  AovsType,
  dekkingGegevensAoSchema,
  dekkingGegevensEaSchema,
  dekkingGegevensVerzekerdeSchema,
  dekkingGegevensWwSchema,
  dekkingSchema,
  dekkingVerzekerdeRubriekASchema,
  dekkingVerzekerdeRubriekBSchema,
  PartijProduct,
  PremieBetalingstermijn,
  premiegegevensSchema,
  verzekerdBedragModalSchema
} from "./aov-schema";

export const mapPartijProduct = (partijCode: string, productCode: string): PartijProduct => {
  switch (true) {
    case partijCode === "CA" && productCode === "03":
      return PartijProduct.CardifHypotheekAflosVerzekering;
    case partijCode === "XX" && productCode === "01":
      return PartijProduct.OnafhankelijkWoonlasten;
    case partijCode === "XX" && productCode === "02":
      return PartijProduct.OnafhankelijkWgaGat;
    case partijCode === "XX" && productCode === "03":
      return PartijProduct.OnafhankelijkWiaExcedent;
    case partijCode === "XX" && productCode === "04":
      return PartijProduct.OnafhankelijkAo;
    case partijCode === "XX" && productCode === "05":
      return PartijProduct.OnafhankelijkHav;
    case partijCode === "XX" && productCode === "06":
      return PartijProduct.OnafhankelijkIgv;
    case partijCode === "NEE" && productCode === "02":
      return PartijProduct.CallasInkomensGarantie;
    case partijCode === "NEE" && (productCode === "03" || productCode === "01"):
      return PartijProduct.CallasWoonlasten;
    case partijCode === "NEE" && productCode === "04":
      return PartijProduct.CreditLifeWoonlastenOngevallen;
    case partijCode === "NEE" && productCode === "05":
      return PartijProduct.CreditLifeInkomensgarantieOngevallen;
    case partijCode === "NEE" && productCode === "06":
      return PartijProduct.CreditLifeArbeidsongeschiktheid;
    case partijCode === "TF" && productCode === "06":
      return PartijProduct.TafInkomensBeschermer;
    case partijCode === "TF" && productCode === "01":
      return PartijProduct.TafQuantumLeben;
    case partijCode === "GID" && productCode === "01":
      return PartijProduct.DazureWoonlasten;
    case partijCode === "GID" && productCode === "02":
      return PartijProduct.DazureInkomensbeschermer;
    case partijCode === "GID" && productCode === "03":
      return PartijProduct.DazureLastenbeschermer;
      case partijCode === "TF" && productCode === "05":
      return PartijProduct.TafCreditLife;
    case partijCode === "TF" && productCode === "08":
      return PartijProduct.TafMaandlastenbeschermerIPTIQ;
    case partijCode === "TF" && productCode === "10":
      return PartijProduct.TafMaandlastenbeschermerZelfstandige;
    case partijCode === "TF" && productCode === "07":
      return PartijProduct.TafInkomensbeschermerIPTIQ;
    case partijCode === "TF" && productCode === "09":
      return PartijProduct.TafBnpHypotheekbeschermer;
    default:
      return PartijProduct.Onbekend;
  }
};

const mapUitkeringsWijze: Record<UitkeringAoSoort, AoUitkeringswijze> = {
  Geen: AoUitkeringswijze.Volledig,
  Volledig: AoUitkeringswijze.Volledig,
  ProRata: AoUitkeringswijze.ProRata
};

const mapUitkeringsWijzeRubriekA: Record<UitkeringAoSoort, AoUitkeringswijze> = {
  Geen: AoUitkeringswijze.Volledig,
  Volledig: AoUitkeringswijze.Volledig,
  ProRata: AoUitkeringswijze.ProRata
};

const mapJaarlijkseIndexatieVan: Record<Indexatiesoort, AovJaarlijkseIndexatieVan> = {
  Geen: AovJaarlijkseIndexatieVan.Uitkering,
  Uitkering: AovJaarlijkseIndexatieVan.Uitkering,
  VerzekerdBedrag: AovJaarlijkseIndexatieVan.VerzekerdBedrag
};

const aanvragerContextSchema = Yup.object({
  aanvrager1: aovAanvragerSchema.nullable(),
  aanvrager2: aovAanvragerSchema.nullable().default(null)
});
type aanvragerContextType = Yup.InferType<typeof aanvragerContextSchema>;

type dekkingContextType = {
  brutoJaarinkomen: number;
  indicatiefJaarInkomen: number;
  indicatiefMaandInkomen: number;
};

type dekkingContextMetProductType = dekkingContextType & {
  aovProduct: AovProduct;
}

export const mapProduct = createMapToUi(productSchema).from<AovProduct>({
  doorlopend: v => v.doorlopend || false,
  productNummer: v => v.polis.productnummer || "",
  partijCodeSelectie: v => v.polis.maatschappijCodeHdn || "", // TODO: Veld moet nog in backend toegevoegd worden (Pertijcode bedoeld voor de dropdown)
  partijNaam: v => v.polis.maatschappijOmschrijving || "",
  productNaam: v => v.polis.productnaam || "",
  ingangsdatum: v => mapStringToLocalDate(v.polis.ingangsdatum) || LocalDate.of(2019, 1, 1),
  einddatum: v =>
    v.polis.ingangsdatum && v.polis.looptijdInMaanden
      ? addMonths(mapStringToLocalDate(v.polis.ingangsdatum), v.polis.looptijdInMaanden)
      : mapStringToLocalDate(v.polis.ingangsdatum),
  looptijd: v => mapJaarMaandInputFromLooptijdDl2Ui(v.polis.looptijdInMaanden),
  uwBemiddeling: v => v.polis.uwBemiddeling || false,
  omschrijving: () => "",
  meenemen: v => v.meenemen,
  wijzigingenInDoorlopendProductOvernemen: v => v.wijzigingenInDoorlopendeProductenOvernemen,
  renteboxCode: _ => null
});

const mapVerzekerdBedragModal = createMapToUi(verzekerdBedragModalSchema).from<AovProduct>({
  scenario: v => {
    const result: AovScenarioCardInputType[] = [];
    v.aanvraag?.verzekerdBedragScenario?.indexeringen
      ?.map(item => ({ ingangsmaand: item.ingangsmaand ?? 0, bedrag: item.bedrag }))
      .sort((a, b) => a.ingangsmaand - b.ingangsmaand)
      .forEach(item => {
        // alleen eerste maand per jaar meenemen
        if (item.ingangsmaand % 12 === 0) {
          result.push({ bedrag: item.bedrag, percentage: null, jaartal: 2020 + item.ingangsmaand / 12 });
        }
      });
    return result;
  }
});

const mapDekkingGegevensAo = createMapToUi(dekkingGegevensAoSchema)
  .with<dekkingContextMetProductType>()
  .from<AovDekkingArbeidsongeschiktheid>({
    beroep: v => v.beroep ?? "",
    beroepnaam: (v, context) => context.aovProduct.beroep,
    risicoklasse: (v, context) => context.aovProduct.risicoklasseberoep,
    verzekerdBedrag: v => v.verzekerdBedrag,
    verzekerdBedragGedeeltelijkAo: v => v.verzekerdBedragGedeeltelijkAo,
    looptijd: v => mapJaarMaandInputFromLooptijdDl2Ui(v.looptijdInMaanden),
    uitkeringsDuur: v => mapJaarMaandInputFromLooptijdDl2Ui(v.uitkeringsduurInMaandenAo),
    eigenRisico: v => v.eigenRisico,
    uitkeringsduurAo: v => v.uitkeringsduurInMaanden,
    uitkeringGedeeltelijkAo: v => (v.uitkeringGedeeltelijkAo ? mapUitkeringsWijze[v.uitkeringGedeeltelijkAo] : null),
    uitkeringGedeeltelijkeAoModal: v => mapSpecificatieAoUitkeringModalDl2Ui(v.aoUitkering),
    annuiteitsPercentage: v => v.annuiteitsPercentage
  });
const mapDekkingGegevensWw = createMapToUi(dekkingGegevensWwSchema).from<AovDekkingWerkloosheid>({
  verzekerdBedrag: v => v.verzekerdBedrag,
  looptijd: v => mapJaarMaandInputFromLooptijdDl2Ui(v.looptijdInMaanden),
  uitkeringsDuur: v => mapJaarMaandInputFromLooptijdDl2Ui(v.uitkeringsduurInMaanden),
  uitkeringsDuurInMaanden: v => (v.uitkeringsduurInMaanden ? v.uitkeringsduurInMaanden.toString() : null)
});
const mapDekkingGegevensAe = createMapToUi(dekkingGegevensEaSchema).from<AovDekkingBasis>({
  verzekerdBedrag: v => v.verzekerdBedrag,
  looptijd: v => mapJaarMaandInputFromLooptijdDl2Ui(v.looptijdInMaanden)
});

const mapDekkinggegevensRubriekA = createMapToUi(dekkingVerzekerdeRubriekASchema)
  .with<dekkingContextType>()
  .from<AovDekkingRubriekA>({
    beoordelingscriterium: v => v.beoordelingscriterium,
    eigenRisicoInMaanden: v => v.eigenRisicoInMaanden,
    jaarlijkseIndexatiePercentage: v => v.jaarlijkseIndexatiePercentage,
    jaarlijkseIndexatieVan: v =>
      v.soortJaarlijkseIndexering ? mapJaarlijkseIndexatieVan[v.soortJaarlijkseIndexering] : null,
    uitkeringGedeeltelijkAo: v =>
      v.uitkeringGedeeltelijkAo ? mapUitkeringsWijzeRubriekA[v.uitkeringGedeeltelijkAo] : null,
    uitkeringGedeeltelijkeAoModal: v => mapSpecificatieAoUitkeringModalDl2Ui(v.aoUitkering),
    verzekerdBedrag: v => v.verzekerdBedrag,
    percentageVanInkomen: (v, context) => ((v.verzekerdBedrag || 0) / context.brutoJaarinkomen) * 100
  });

const mapDekkinggegevensRubriekB = createMapToUi(dekkingVerzekerdeRubriekBSchema)
  .with<dekkingContextType>()
  .from<AovDekkingRubriekB>({
    beoordelingscriterium: v => v.beoordelingscriterium,
    eindLeeftijd: v => v.eindleeftijd,
    verzekerdBedrag: v => v.verzekerdBedrag,
    percentageVanInkomen: (v, context) => ((v.verzekerdBedrag || 0) / context.brutoJaarinkomen) * 100
  });

const mapDekkingGegevensVerzekerde = createMapToUi(dekkingGegevensVerzekerdeSchema)
  .with<dekkingContextMetProductType>()
  .from<AovDekking>({
    ao: (v, context) => (v.dekkingAo ? mapDekkingGegevensAo(context)(v.dekkingAo) : null),
    ww: v => (v.dekkingWw ? mapDekkingGegevensWw(v.dekkingWw) : null),
    ea: v => (v.dekkingEa ? mapDekkingGegevensAe(v.dekkingEa) : null),
    rubriekA: (v, context) => (v.rubriekA ? mapDekkinggegevensRubriekA(context)(v.rubriekA) : null),
    rubriekB: (v, context) => (v.rubriekB ? mapDekkinggegevensRubriekB(context)(v.rubriekB) : null),
    dekking: v => v.verzekerdeDekkingen,
    uitgebreid: v => v.uitgebreid,
    keuzeDekking: v => v.keuzeDekking,
    vastBedrag: v => v.vastBedrag,
    percentage: v => v.percentageBovenMaxDagloon,
    voornaamsteInkomstenbron: v => v.voornaamsteInkomstenbron,
    beoordelingscriterium: v => v.beoordelingscriterium
  });

const mapDekking = createMapToUi(dekkingSchema)
  .with<dekkingContextType>()
  .from<AovProduct>({
    marktwaarde: v => v.aanvraag?.marktwaardeBedrag || 0,
    hypotheekbedrag: v => v.aanvraag?.hypotheekbedrag || 0,
    verzekerdeKredietsom: (v, context) => (Math.max(2500,Math.min((v.aanvraag?.hypotheekbedrag || 0) * 0.1,75000))),
    brutoJaarinkomen: (_, context) => context.brutoJaarinkomen,
    jaarinkomenIndicatief: (_v, context) => context.indicatiefJaarInkomen,
    maandinkomen: (v, context) =>
      mapBerekenInput(
        v.aanvraag?.maandinkomen ?? context.indicatiefMaandInkomen,
        v.aanvraag?.maandinkomenOvernemen ?? true,
        Math.round(context.indicatiefMaandInkomen)
      ),
    indexatieTijdensUitkering: v => v.aanvraag?.indexatieTijdensUitkering,
    nettoMaandinkomen: v => v.aanvraag?.nettoMaandinkomen,
    maandinkomenGegarandeerd: v => v.aanvraag?.maandinkomenGegarandeerd,
    verzekerdBedragVerzekerdBedragModalValues: v => mapVerzekerdBedragModal(v),
    dekkingGegevensVerzekerde1: (v, context) => mapDekkingGegevensVerzekerde({ ...context, aovProduct: v })(v.dekkingVerzekerde1),
    dekkingGegevensVerzekerde2: (v, context) =>
      v.dekkingVerzekerde2 ? mapDekkingGegevensVerzekerde({ ...context, aovProduct: v })(v.dekkingVerzekerde2) : null
  });

const mapPremieBetalingsTermijn = createMapEnum(BetalingsTermijnType).to({
  Geen: null,
  Maand: PremieBetalingstermijn.Maand,
  TweeMaanden: PremieBetalingstermijn.TweeMaanden,
  Kwartaal: PremieBetalingstermijn.Kwartaal,
  HalfJaar: PremieBetalingstermijn.HalfJaar,
  Jaar: PremieBetalingstermijn.Jaar,
  Eenmalig: PremieBetalingstermijn.Eenmalig,
  Week: null,
  Dag: null
});

const mapPremiegegevens = createMapToUi(premiegegevensSchema).from<AovProduct>({
  koopsomAO: v => v.premie.koopsomAftrekbaarBedrag,
  koopsomBedrag: v => v.premie.koopsomBedrag,
  koopsomPeriodeInMaanden: v => v.premie.koopsomperiodeInMaanden,
  betalingMiddels: v => v.premie.betalingMiddels,
  betalingsTermijn: v => mapPremieBetalingsTermijn(v.premie.betalingstermijn),
  premie: v => v.premie.premieBedrag,
  premieAo: v => v.premie.premieBedragAo,
  premieTop: v => v.premie.premieBedragTop,
  premieAftrekbaarAO: v => v.premie.premieAftrekbaar,
  premieAftrekbaarBedrag: v => v.premie.premieAftrekbaarBedrag,
  collectief: v => v.premie.collectief,
  premieWw: v => v.premie.premieBedragWw,
  jaarlijksIndexatiePercentage: v => v.premie.jaarlijksIndexatiePercentage
});

const mapVerpanding = createMapToUi(aflossingSchema).from<AovProduct>({
  bedoeldVoorAflossing: v => v.verpanding?.bedoeldVoorAflossing ?? false,
  bedoeldVoorAflossingSpecificatie: v => mapAflosProductenDlToUi(v.verpanding?.aflosproducten),
  verpandAanGeldverstrekker: v => v.verpanding?.verpandAanGeldverstrekker ?? false
});

const mapAanvullendeVragen = createMapToUi(aanvullendeVragenSchema).from<AovProduct>({
  reorganisatieWerkgeverAanvrager1: v => v.aanvraag?.reorganisatieWerkgeverAanvrager1,
  reorganisatieWerkgeverAanvrager2: v => v.aanvraag?.reorganisatieWerkgeverAanvrager2,
  verwachtingOntslagAanvrager1: v => v.aanvraag?.verwachtingOntslagAanvrager1,
  verwachtingOntslagAanvrager2: v => v.aanvraag?.verwachtingOntslagAanvrager2,
  financieleProblemenWerkgeverAanvrager1: v => v.aanvraag?.financieleProblemenWerkgeverAanvrager1,
  financieleProblemenWerkgeverAanvrager2: v => v.aanvraag?.financieleProblemenWerkgeverAanvrager2,
  strafrechtelijkVerledenAanvrager1: v => v.aanvraag?.strafrechtelijkVerledenAanvrager1,
  strafrechtelijkVerledenAanvrager2: v => v.aanvraag?.strafrechtelijkVerledenAanvrager2,
  toelichtingGelezenAkkoordMetSlotverklaring: v => v.aanvraag?.toelichtingGelezenAkkoordMetSlotverklaring
});

const mapAov = createMapToUi(aovSchema)
  .with<aanvragerContextType>()
  .from<AovProduct>({
    toggleBerekenen: () => null,
    productId: v => v.productId,
    partijCode: v => v.polis.maatschappijCode ?? "",
    productCode: v => prefixWithZero(v.polis.productcode) ?? "",
    product: v => mapProduct(v),
    soortProduct: v => v.soortProduct,
    verzekerden: (v, context) => ({
      verzekerden: mapKlantenNaarAanvragerKeuze(v.verzekerdeKlantIds, context.aanvrager1, context.aanvrager2)
    }),
    dekking: (v, context) => {
      const result: dekkingContextType = { indicatiefJaarInkomen: 0, indicatiefMaandInkomen: 0, brutoJaarinkomen: 0 };
      if (context.aanvrager1 && v.verzekerdeKlantIds.includes(context.aanvrager1.klantId)) {
        result.indicatiefJaarInkomen += context.aanvrager1.indicatiefJaarInkomen ?? 0;
        result.indicatiefMaandInkomen += context.aanvrager1.indicatiefMaandInkomen ?? 0;
        result.brutoJaarinkomen += context.aanvrager1.brutoJaarinkomen ?? 0;
      }
      if (context.aanvrager2 && v.verzekerdeKlantIds.includes(context.aanvrager2.klantId)) {
        result.indicatiefJaarInkomen += context.aanvrager2.indicatiefJaarInkomen ?? 0;
        result.indicatiefMaandInkomen += context.aanvrager2.indicatiefMaandInkomen ?? 0;
        result.brutoJaarinkomen += context.aanvrager2.brutoJaarinkomen ?? 0;
      }

      return mapDekking(result)(v);
    },
    premiegegevens: v => mapPremiegegevens(v),
    verpanding: v => mapVerpanding(v),
    aanvullendeVragen: v => mapAanvullendeVragen(v)
  });

const mapAanvrager = createMapToUi(aovAanvragerSchema).from<AovAanvrager>({
  klantId: v => v.klantId,
  achternaam: v => v.achternaam,
  voorletters: v => v.voorletters,
  voornamen: v => v.voornamen,
  voorvoegsel: v => v.voorvoegsel,
  geboortedatum: v => mapStringToLocalDate(v.geboortedatum || "1900-01-01"),
  roker: v => v.roker,
  geslacht: v => v.geslacht as GeslachtOpties | null,
  brutoJaarinkomen: v => v.brutoJaarinkomen,
  indicatiefJaarInkomen: v => v.indicatiefJaarinkomenBedrag,
  indicatiefMaandInkomen: v => v.indicatiefMaandinkomenBedrag,
  aowdatum: v => mapStringToLocalDate(v.aowdatum)
});

const mapContext = createMapToUi(aanvragerContextSchema).from<AovDlEntry>({
  aanvrager1: v => mapAanvrager(v.aanvrager1),
  aanvrager2: v => (v.aanvrager2 ? mapAanvrager(v.aanvrager2) : null)
});

const dl2ui = createMapToUi(aovsSchema)
  .with<aanvragerContextType>()
  .from<AovDlEntry>({
    aanvrager1: (_, c) => c.aanvrager1,
    aanvrager2: (_, c) => c.aanvrager2,
    producten: (v, c) => v.producten.map(mapAov(c)),
    ingangsdatumVoorstel: v => mapStringToLocalDate(v.ingangsdatumVoorstel),
    hypotheekbedrag: v => v.gewensteHypotheekBedrag,
    marktwaardeBedrag: v => v.marktwaardeBedrag,
    aflosProductNew: v => mapAflosProductNew(v.leningdelen, [])
  });

export type AovDoToUiMapper = (aovId: string, data: AovDlOutput)=> AovsType | null;

export function mapAovDlToUi(aovId: string, data: AovDlOutput): AovsType | null {
  const aov = data && data.isValid && data.aovs ? data.aovs[aovId] : null;

  if (aov) {
    return dl2ui(mapContext(aov))(aov);
  }

  return null;
}
