import { TaxatieNwwiTaxatiebureausOutput } from "../../../.generated/forms/formstypes";
import {
  createISWAsyncSideEffect,
  initISWAsyncSideEffect
} from "../../../shared/components/isw-side-effects/create-isw-helpers";
import { hasValue } from "../../../shared/utils/helpers";
import { TaxateurNwwiModalType } from "../../infra/taxatie-scherm-schema";

type AsyncContext = { postcode: string | null; huisnummer: number | null; contractId: number | null };

export const asyncTaxatieSideEffects = createISWAsyncSideEffect<TaxateurNwwiModalType, AsyncContext>(
  async ({ draft, settings, params, context, fetchData }) => {
    const url = `${settings.klantdossiersFormsOrigin}/Taxatie/NWWI/Taxatiebureaus?postcode=${context.postcode}&huisnummer=${context.huisnummer}&contractId=${context.contractId}`;

    const resTaxatiebureaus = await fetchData<TaxatieNwwiTaxatiebureausOutput>({
      url,
      method: "GET",
      extraHeaders: { vestigingId: params.vestiging }
    });

    if (resTaxatiebureaus.isValid && resTaxatiebureaus.taxatiebureaus?.length) {
      draft.resultaat = resTaxatiebureaus.taxatiebureaus;
      if (!draft.selectedResultaat) draft.selectedResultaat = draft.resultaat[0];
    }

    if (resTaxatiebureaus.isValid && resTaxatiebureaus.taxatiebureaus?.length === 0) {
      draft.resultaatIsLeeg = true;
    }
  }
);

export const determineTaxatieAsyncSideEffects = initISWAsyncSideEffect<TaxateurNwwiModalType, AsyncContext>(
  ({ runAsync, context, curr }) => {
    if (!curr.resultaat?.length && hasValue(context.huisnummer) && context.postcode && hasValue(context.contractId))
      runAsync(asyncTaxatieSideEffects(context));
  }
);
