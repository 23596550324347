import React, { ReactElement } from "react";
import { Formik, FormikErrors, FormikProps, validateYupSchema, yupToFormErrors } from "formik";
import * as Yup from "yup";
import { UiError } from "../types";

type AdviesBoxFormikProps<Values> = {
  initialValues: Values;
  validationSchema:
    | Yup.ObjectSchema<any>
    | ((props: Omit<AdviesBoxFormikProps<Values>, "render">) => Yup.ObjectSchema<any>);
  createValidationContext?: (props: AdviesBoxFormikProps<Values>, values: Values) => any;
  onSave?: (values: Values) => void;
  closeModal?: () => void;
  render: (props: FormikProps<Values>) => React.ReactNode;
  validateOnMount?: boolean;
};

const AdviesBoxFormik = <Values extends {}>(
  props: AdviesBoxFormikProps<Values & { platformApiFouten?: UiError[] | null }>
): ReactElement => {
  const { onSave, closeModal, createValidationContext, render, ...rest } = props;
  let { validationSchema } = props;

  return (
    <Formik
      {...rest}
      validateOnChange={false}
      onSubmit={(values): void => {
        onSave && onSave(values);
        closeModal && closeModal();
      }}
      validate={async (values): Promise<FormikErrors<Values> | object> => {
        try {
          if (typeof validationSchema === "function") {
            validationSchema = validationSchema(rest);
          }
          const context = typeof createValidationContext === "function" ? createValidationContext(props, values) : {};

          await validateYupSchema(values, validationSchema, true, context);
        } catch (yupErrors) {
          const formikErrors = yupToFormErrors(yupErrors);

          return formikErrors;
        }
        return {};
      }}
    >
      {props => {
        if (!props.values) {
          throw new Error("AdviesBoxFormik without values");
        }
        const displayPlatformApiFouten = props.values?.platformApiFouten?.map(e => e.error).join(" ") ?? "";
        return (
          <>
            {render(props)}
            {displayPlatformApiFouten}
          </>
        );
      }}
    </Formik>
  );
};

export default AdviesBoxFormik;
