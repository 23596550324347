import React, { ReactElement } from "react";
import { FormikProps } from "formik";
import { getPensioenTextResources } from "../infra/pensioen-resources";
import AdviesBoxFormik from "../../shared/utils/adviesbox-formik";
import Modal from "../../shared/components/modal/Modal";
import {
  OuderdomspensioenModalType,
  OuderdomspensioenModalEntryType,
  ouderdomspensioenModalEntrySchema,
  ouderdomspensioenModalSchema
} from "../infra/pensioen-schema";
import { ISWSideEffects } from "../../shared/components/isw-side-effects/isw-side-effects";
import { determineOuderdomspensioenModalSideEffects } from "./determine-ouderdomspensioen-modal-side-effects";
import { Column } from "react-table-6";
import { Label, DataGrid } from "adviesbox-shared";

export type OuderdomspensioenModalData = {
  data: OuderdomspensioenModalType;
};

type OuderdomspensioenModalProps = {
  onSave?: (data: OuderdomspensioenModalType) => void;
  closeModal?: () => void;
};

const indexeringenColumns: Column[] = [
  {
    Header: (
      <Label
        caption={"Ingangsdatum"}
        tooltip={getPensioenTextResources("ouderdomspensioenModalTooltipIngangsdatum")}
        name="ingangsdatum"
      />
    ),
    accessor: "ingangsdatum",
    Cell: "DateInput"
  },
  {
    Header: "Bedrag",
    accessor: "bedrag",
    Cell: "CurrencyInput",
    width: 100
  },
  {
    Cell: "DeleteButton"
  }
];

const OuderdomspensioenModal = ({
  data,
  onSave,
  closeModal
}: OuderdomspensioenModalData & OuderdomspensioenModalProps): ReactElement => {
  const body = (
    <>
      <DataGrid
        columns={indexeringenColumns}
        name="indexeringen"
        rowCaption="ouderdomspensioen"
        getNewRowValues={(): OuderdomspensioenModalEntryType => ({
          ...ouderdomspensioenModalEntrySchema.default()
        })}
        className="editable"
      />
    </>
  );

  return (
    <AdviesBoxFormik<OuderdomspensioenModalType>
      initialValues={{ ...data }}
      validationSchema={ouderdomspensioenModalSchema}
      onSave={onSave}
      closeModal={closeModal}
      render={({ submitForm }: FormikProps<OuderdomspensioenModalType>): ReactElement => (
        <>
          <ISWSideEffects sync={determineOuderdomspensioenModalSideEffects()} />

          <Modal title="Ouderdomspensioen" body={body} onSubmitClick={submitForm} onCancelClick={closeModal} />
        </>
      )}
    />
  );
};

OuderdomspensioenModal.displayName = "OuderdomspensioenModal";

export default OuderdomspensioenModal;
