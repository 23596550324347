import { createGetTextResources } from "../../shared/utils/text-resources-algemeen";

export const getVergoedingenOverzichtTextResources = createGetTextResources({
  TooltipDossierVergoedingBedrag: `Voor 'Dossiervergoeding geldlening' kun je een standaardbedrag instellen of wijzigen in de beheerapplicatie 'Instellingen', bij het scherm 'Voorkeuren - Tarieven'.`,
  TooltipOverigeBemiddelingsvergoeding: `Voor 'Overige bemiddelingsvergoeding' kun je standaard een vast bedrag of een bedrag per uur instellen. Ga hiervoor naar de beheerapplicatie 'Instellingen'. Vervolgens kun je bij het scherm 'Voorkeuren - Tarieven' het bedrag instellen of wijzigen.`,
  TooltipHypotheekNorm: `Maak hier de keuze welke NHG- en hypotheeknormen je mee wilt nemen in het advies. De keuze heeft geen invloed op de bruto- en netto berekeningen. Adviesbox Online maakt de berekeningen namelijk altijd op basis van de huidige fiscaliteiten.

Als je kiest voor de normen in een toekomstig jaar, dan worden de nieuwe normen toegepast op het huidige acceptatiebeleid van de geldverstrekkers. `,
  TooltipAdvieskosten: `Voor 'Advieskosten' kun je standaard een vast bedrag of een bedrag per uur instellen. Ga hiervoor naar de beheerapplicatie 'Instellingen'. Vervolgens kun je bij het scherm 'Voorkeuren - Tarieven' het bedrag instellen of wijzigen.`,
  TooltipBemiddelingsvergoedingGeldlening: `Voor 'Bemiddelingsvergoeding geldlening' kun je standaard een vast bedrag of een bedrag per uur instellen. Ga hiervoor naar de beheerapplicatie 'Instellingen'. Vervolgens kun je bij het scherm 'Voorkeuren - Tarieven' het bedrag instellen of wijzigen.`,
  ErrorDatumVerplicht: "Vul de verwachte ingangsdatum in.",
  ErrorDatumBereik: "De verwachte passeerdatum mag niet verder dan vijf jaar in de toekomst liggen.",
  EindelooptijdWarning:
    "Dit product is op 'Ingangsdatum/passeerdatum voorstel' al afgelopen. Zet het vinkje Meenemen van dit product 'Uit'."
});
