/* istanbul ignore file */

import { ErrorPage, PageLoading, SettingsType, withErrorBoundary } from "adviesbox-shared";
import React, { ReactElement, useCallback } from "react";
import { mapDlTargetToOverigePostenUiField, mapOverigePostenUiToDl } from "./infra/map-overige-posten-ui-2-dl";

import { InvalidAdviesboxResultErrorPage } from "../shared/components/fetch-error-page/fetch-error-page";
import OverigePosten from "./overige-posten";
import { RouteParams } from "../shared/paramrouting/paramrouting-context";
import { SituatieSoort } from "../producten-overzicht/infra/producten-overzicht-types";
import { mapOverigePostenDlToUi } from "./infra/map-overige-posten-dl-2-ui";
import { useAdviesboxData } from "../shared/hooks/use-adviesbox-data";

type OverigePostenAjaxProps = { situatie: SituatieSoort };

const OverigePostenAjax = ({ situatie }: OverigePostenAjaxProps): ReactElement => {
  const createUrl = useCallback(
    (s: SettingsType, p: RouteParams): string =>
      situatie === "huidig"
        ? `${s.klantdossiersFormsOrigin}/Adviesdossiers/${p.adviesdossier}/Overig`
        : `${s.klantdossiersFormsOrigin}/Voorstellen/${p.voorstel}/Overig`,
    [situatie]
  );

  const createDataId = useCallback((p: RouteParams) => (situatie === "huidig" ? p.adviesdossier : p.voorstel), [
    situatie
  ]);

  const { loading, error, data, platformData, saveData } = useAdviesboxData(
    createUrl,
    createDataId,
    mapOverigePostenDlToUi,
    mapOverigePostenUiToDl,
    mapDlTargetToOverigePostenUiField
  );

  if (loading) {
    return <PageLoading />;
  }

  if (error) {
    return <ErrorPage error={error} data={platformData} />;
  }

  if (!data) {
    return <InvalidAdviesboxResultErrorPage />;
  }

  return <OverigePosten situatie={situatie} saveData={saveData} {...data} />;
};

OverigePostenAjax.displayName = "OverigePostenAjax";

export default withErrorBoundary(OverigePostenAjax);
