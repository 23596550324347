import React, { ReactElement } from "react";

import VergoedingenOverzicht from "./vergoedingen-overzicht";
import {
  mapVergoedingenUiNaarDl,
  mapVergoedingenDlNaarUi,
  mapVergoedingenDlNameToUiName
} from "./infra/map-vergoedingen-overzicht";
import { ErrorBoundary, SettingsType } from "adviesbox-shared";
import { PageLoading } from "adviesbox-shared";
import { useAdviesboxData } from "../shared/hooks/use-adviesbox-data";
import { useInstellingenTarievenData } from "../shared/hooks/use-instellingen-tarieven-data";
import { ErrorPage } from "adviesbox-shared";
import { InvalidAdviesboxResultErrorPage } from "../shared/components/fetch-error-page/fetch-error-page";

type RouteParams = {
  vestiging: string;
  adviesdossier: string;
  voorstel: string;
};

const createUrl = (s: SettingsType, p: RouteParams): string =>
  `${s.klantdossiersFormsOrigin}/Voorstellen/${p.voorstel}/Vergoedingenoverzicht`;

const VergoedingenOverzichtAjax = (): ReactElement => {
  const {
    data: instellingenTarieven,
    error: errorInstellingenTarieven,
    loading: loadingInstellingenTarieven
  } = useInstellingenTarievenData();

  const { loading, error, data, platformData, saveData } = useAdviesboxData(
    createUrl,
    p => p.voorstel,
    mapVergoedingenDlNaarUi(instellingenTarieven),
    mapVergoedingenUiNaarDl,
    mapVergoedingenDlNameToUiName
  );

  if (loading || loadingInstellingenTarieven) {
    return <PageLoading />;
  }

  if (error) {
    return <ErrorPage error={error} data={platformData} />;
  }

  if (errorInstellingenTarieven) {
    return <ErrorPage error={errorInstellingenTarieven} data={JSON.stringify(instellingenTarieven)} />;
  }

  if (!data || !instellingenTarieven) {
    return <InvalidAdviesboxResultErrorPage />;
  }

  return (
    <ErrorBoundary>
      <VergoedingenOverzicht saveData={saveData} {...data} />
    </ErrorBoundary>
  );
};

VergoedingenOverzichtAjax.displayName = "VergoedingenOverzichtAjax";

export default VergoedingenOverzichtAjax;
