import { ChronoUnit, LocalDate } from "@js-joda/core";
import reactFastCompare from "react-fast-compare";
import { BetalingsTermijnType, PremieverloopOptions, SoortOrvProductOptions } from "../../.generated/forms/formstypes";
import { partijOnafhankelijk } from "../../producten-overzicht/infra/product-constanten";
import { OrvKenmerken } from "../../producten-overzicht/infra/product-kenmerken-types";
import { aanvullingInkomenBijOverlijdenModalSchema } from "../../producten-overzicht/infra/producten-overzicht-schema";
import { createISWSideEffect, initISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";
import { mapJaarMaandInputDl2Ui } from "../../shared/generic-parts/jaar-maand/map-dl-2-ui";
import { KlantnaamType } from "../../shared/generic-parts/klantnaam/schema";
import { AanvragerKeuze } from "../../shared/types";
import { addMonths, addYears, getDifferenceYearsMonths } from "../../shared/utils/dates";
import { mapMultipleOrvInput } from "./berekeningen/risico-premie-berekening-mapping";
import { OrvsState, OrvType } from "./orv-schema";

type orvSideEffectsContext = {
  selected: number;
  kenmerken: OrvKenmerken | null;
};

const verzekerdenSideEffects = createISWSideEffect<OrvType>((bag): void => {
  const { draft, has } = bag;
  if (has.verzekerden.verzekerden.changed) {
    draft.dekking.annuiteitspercentage1 = null;
    draft.dekking.verzekerdKapitaalAanvrager1 = null;
    draft.dekking.dekkingDaaltTotAanvrager1 = null;

    draft.dekking.annuiteitspercentage2 = null;
    draft.dekking.verzekerdKapitaalAanvrager2 = null;
    draft.dekking.dekkingDaaltTotAanvrager2 = null;

    draft.verpanding.inkomensaanvullingSpecificatie.duurUitkeringAanvrager1 = null;
    draft.verpanding.inkomensaanvullingSpecificatie.gehanteerdTariefAanvrager1 = null;
    draft.verpanding.inkomensaanvullingSpecificatie.gewensteUitkeringAanvrager1 = null;
    draft.verpanding.inkomensaanvullingSpecificatie.aanvullingInkomenBijOverlijdenAanvrager1 = false;
    draft.verpanding.inkomensaanvullingSpecificatie.gewensteUitkeringPerPeriodeAanvrager1 = BetalingsTermijnType.Maand;

    draft.verpanding.inkomensaanvullingSpecificatie.duurUitkeringAanvrager2 = null;
    draft.verpanding.inkomensaanvullingSpecificatie.gehanteerdTariefAanvrager2 = null;
    draft.verpanding.inkomensaanvullingSpecificatie.gewensteUitkeringAanvrager2 = null;
    draft.verpanding.inkomensaanvullingSpecificatie.aanvullingInkomenBijOverlijdenAanvrager2 = false;
    draft.verpanding.inkomensaanvullingSpecificatie.gewensteUitkeringPerPeriodeAanvrager2 = BetalingsTermijnType.Maand;
  }
});

const dekkingSideEffects = createISWSideEffect<OrvType, OrvKenmerken | null>((bag): void => {
  const { draft, context, has } = bag;
  if (context && !context.dekking.reservewaardeTonen && draft.dekking.reservewaarde !== null) {
    draft.dekking.reservewaarde = null;
  }

  if (has.dekking.verzekerdKapitaalAanvrager1.changed) {
    draft.verpanding.inkomensaanvullingSpecificatie.verzekerdKapitaalAanvrager1 =
      draft.dekking.verzekerdKapitaalAanvrager1;
  }
  if (has.dekking.verzekerdKapitaalAanvrager2.changed) {
    draft.verpanding.inkomensaanvullingSpecificatie.verzekerdKapitaalAanvrager2 =
      draft.dekking.verzekerdKapitaalAanvrager2;
  }

  if (has.verzekerden.verzekerden.changed && draft.verzekerden.verzekerden === AanvragerKeuze.Beiden && context) {
    if (!context.dekking.tweedeDekkingDaaltTotTonen) {
      draft.dekking.dekkingDaaltTotAanvrager2 = draft.dekking.dekkingDaaltTotAanvrager1;
    }
    if (!context.dekking.tweedeVerzekerdKapitaalTonen) {
      draft.dekking.verzekerdKapitaalAanvrager2 = draft.dekking.verzekerdKapitaalAanvrager1;
    }
    if (!context.dekking.tweedeAnnuiteitsperscentageTonen) {
      draft.dekking.annuiteitspercentage2 = draft.dekking.annuiteitspercentage1;
    }
  }
  if (!has.verzekerden.verzekerden.changed && draft.verzekerden.verzekerden === AanvragerKeuze.Beiden && context) {
    if (!context.dekking.tweedeDekkingDaaltTotTonen && has.dekking.dekkingDaaltTotAanvrager1.changed) {
      draft.dekking.dekkingDaaltTotAanvrager2 = draft.dekking.dekkingDaaltTotAanvrager1;
    }
    if (!context.dekking.tweedeVerzekerdKapitaalTonen && has.dekking.verzekerdKapitaalAanvrager1.changed) {
      draft.dekking.verzekerdKapitaalAanvrager2 = draft.dekking.verzekerdKapitaalAanvrager1;
    }
    if (!context.dekking.tweedeAnnuiteitsperscentageTonen && has.dekking.annuiteitspercentage1.changed) {
      draft.dekking.annuiteitspercentage2 = draft.dekking.annuiteitspercentage1;
    }
  }
});

const legenPremieSideEffects = createISWSideEffect<OrvType>((bag): void => {
  const { draft, prev } = bag;
  const dummyAanvrager1: KlantnaamType = {
    achternaam: "",
    klantId: "",
    voorletters: "",
    voornamen: "",
    voorvoegsel: "",
    geboortedatum: LocalDate.of(1900, 1, 1),
    roker: false,
    geslacht: null,
    aowdatum: null
  };

  const dummyAanvrager2: KlantnaamType = {
    achternaam: "",
    klantId: "",
    voorletters: "",
    voornamen: "",
    voorvoegsel: "",
    geboortedatum: LocalDate.of(1900, 1, 1),
    roker: null,
    geslacht: null,
    aowdatum: null
  };

  const currOrvInput = mapMultipleOrvInput(
    {
      ...draft,
      premieGegevens: {
        // ignore looptijd and betalingstermijn for comparission, since these can be changed from calculating premie.
        ...draft.premieGegevens,
        looptijd: { jaren: null, maanden: null },
        betalingstermijn: BetalingsTermijnType.Maand
      }
    },
    dummyAanvrager1,
    dummyAanvrager2
  );
  const prevOrvInput = mapMultipleOrvInput(
    {
      ...prev,
      premieGegevens: {
        // ignore looptijd and betalingstermijn for comparission, since these can be changed from calculating premie.
        ...prev.premieGegevens,
        looptijd: { jaren: null, maanden: null },
        betalingstermijn: BetalingsTermijnType.Maand
      }
    },
    dummyAanvrager1,
    dummyAanvrager2
  );
  if (!reactFastCompare(currOrvInput, prevOrvInput)) {
    draft.premieGegevens.risicoPremieLaag = null;
  }
});

const polisSideEffects = createISWSideEffect<OrvType>((bag): void => {
  const { draft, prev, has } = bag;
  const prevLooptijdJaren = prev.product.looptijd ? prev.product.looptijd.jaren : null;
  const prevLooptijdMaanden = prev.product.looptijd ? prev.product.looptijd.maanden : null;
  const prevPremieBetalingLooptijdJaren = prev.premieGegevens.looptijd ? prev.premieGegevens.looptijd.jaren : null;
  const prevPremieBetalingLooptijdMaanden = prev.premieGegevens.looptijd ? prev.premieGegevens.looptijd.maanden : null;

  const premieEinddatumBepalen = (): boolean => {
    // premiebetaling looptijd of ingangsdatum gewijzigd -> premiebetaling einddatum berekenen.
    if (
      draft.product.ingangsdatum &&
      draft.premieGegevens.looptijd &&
      (!prev ||
        has.product.ingangsdatum.changed ||
        draft.premieGegevens.looptijd.jaren !== prevPremieBetalingLooptijdJaren ||
        draft.premieGegevens.looptijd.maanden !== prevPremieBetalingLooptijdMaanden)
    ) {
      let jaren = draft.premieGegevens.looptijd.jaren || 0;
      let maanden = draft.premieGegevens.looptijd.maanden || 0;

      if (jaren * 12 + maanden > 999 * 12) {
        jaren = 0;
        maanden = 0;
        draft.premieGegevens.looptijd.jaren = 0;
        draft.premieGegevens.einddatumPremieBetaling = draft.product.ingangsdatum;
      }

      if (maanden > 11) return false;
      const newEinddatum = addMonths(addYears(draft.product.ingangsdatum, jaren), maanden);

      if (!newEinddatum.equals(draft.premieGegevens.einddatumPremieBetaling)) {
        draft.premieGegevens.einddatumPremieBetaling = newEinddatum;
      }
      return true;
    }
    return false;
  };

  const productEinddatumBepalen = (): boolean => {
    // looptijd of ingangsdatum gewijzigd -> einddatum berekenen.
    if (
      draft.product.ingangsdatum &&
      draft.product.looptijd &&
      (!prev ||
        has.product.ingangsdatum.changed ||
        draft.product.looptijd.jaren !== prevLooptijdJaren ||
        draft.product.looptijd.maanden !== prevLooptijdMaanden)
    ) {
      let jaren = draft.product.looptijd.jaren || 0;
      let maanden = draft.product.looptijd.maanden || 0;

      if (jaren * 12 + maanden > 999 * 12) {
        jaren = 0;
        maanden = 0;
        draft.product.looptijd.jaren = 0;
        draft.product.einddatum = draft.product.ingangsdatum;
      }
      if (maanden > 11) return false;
      const newEinddatum = addMonths(addYears(draft.product.ingangsdatum, jaren), maanden);
      if (!newEinddatum.equals(draft.product.einddatum)) {
        draft.product.einddatum = newEinddatum;
      }
      return true;
    }
    return false;
  };

  const productLooptijdBepalen = (): boolean => {
    // einddatum gewijzigd, looptijd opnieuw bepalen of aanmaken
    if (draft.product.ingangsdatum && draft.product.einddatum && (!prev || has.product.einddatum.changed)) {
      const diff = getDifferenceYearsMonths(draft.product.ingangsdatum, draft.product.einddatum);

      if (!draft.product.looptijd) {
        draft.product.looptijd = mapJaarMaandInputDl2Ui(diff.year, diff.month);
      } else {
        draft.product.looptijd.jaren = diff.year;
        draft.product.looptijd.maanden = diff.month;
      }

      if (!draft.premieGegevens.looptijd) {
        draft.premieGegevens.looptijd = mapJaarMaandInputDl2Ui(diff.year, diff.month);
      } else {
        draft.premieGegevens.looptijd.jaren = diff.year;
        draft.premieGegevens.looptijd.maanden = diff.month;
      }
      return true;
    }
    return false;
  };

  const dekkingEinddatumBepalen = (): boolean => {
    // dekking einddatum berekenen a.d.h.v. product ingangsdatum
    if (
      draft.product.ingangsdatum &&
      draft.product.looptijd &&
      (!prev ||
        !draft.dekking.einddatum ||
        has.product.ingangsdatum.changed ||
        draft.product.looptijd.jaren !== prevLooptijdJaren ||
        draft.product.looptijd.maanden !== prevLooptijdMaanden)
    ) {
      const jaren = draft.product.looptijd.jaren || 0;
      const maanden = draft.product.looptijd.maanden || 0;

      if (maanden > 11) return false;
      const newEinddatum = addMonths(addYears(draft.product.ingangsdatum, jaren), maanden);
      if (!newEinddatum.equals(draft.dekking.einddatum)) {
        draft.dekking.einddatum = newEinddatum;
      }

      if (draft.product.soortProduct === SoortOrvProductOptions.AnwHiaatVerzekering) {
        draft.dekking.einddatum = null;
      }
      return true;
    }
    return false;
  };

  premieEinddatumBepalen();
  productEinddatumBepalen();
  dekkingEinddatumBepalen();
  productLooptijdBepalen();
  productEinddatumBepalen();
  dekkingEinddatumBepalen();
  premieEinddatumBepalen();
  productEinddatumBepalen();
  dekkingEinddatumBepalen();
});

export const verpandingSideEffects = createISWSideEffect<OrvType>((bag): void => {
  const { draft, has } = bag;
  //Bij aanvinken ‘Verpand aan geldverstrekker’ wordt checkbox ‘Bedoeld voor aflossing van’ ook aangevinkt.
  //Indien gekozen is voor ‘Bedoeld voor aflossing van’, dan is checkbox ‘Inkomensaanvulling’ en ‘Indicatieve uitkerende fase’ uitgevinkt.
  if (has.verpanding.verpandAanGeldverstrekker.changed && draft.verpanding.verpandAanGeldverstrekker) {
    draft.verpanding.bedoeldVoorAflossing = true;
    draft.verpanding.inkomensaanvulling = false;
    draft.verpanding.indicatieveUitkerendeFase = false;
  }

  //Indien gekozen is voor ‘inkomensaanvulling’, dan is checkbox ‘Bedoeld voor aflossing van’ uitgevinkt en ook wordt ‘Verpand aan geldverstrekker’ uitgevinkt.
  if (has.verpanding.bedoeldVoorAflossing.changed && draft.verpanding.bedoeldVoorAflossing) {
    draft.verpanding.inkomensaanvulling = false;
    draft.verpanding.indicatieveUitkerendeFase = false;
  }

  //Aanvinken van de checkbox ‘Indicatieve uitkerende fase’ doet de checkboxen ‘Verpand aan geldverstrekker’ en ‘Bedoeld voor aflossing van’ uitvinken.
  if (has.verpanding.inkomensaanvulling.changed && draft.verpanding.inkomensaanvulling) {
    draft.verpanding.bedoeldVoorAflossing = false;
    draft.verpanding.verpandAanGeldverstrekker = false;
  }

  if (has.verpanding.indicatieveUitkerendeFase.changed && draft.verpanding.indicatieveUitkerendeFase) {
    draft.verpanding.bedoeldVoorAflossing = false;
    draft.verpanding.verpandAanGeldverstrekker = false;
  }

  if (has.verpanding.inkomensaanvulling.changed && !draft.verpanding.inkomensaanvulling) {
    draft.verpanding.inkomensaanvullingSpecificatie = { ...aanvullingInkomenBijOverlijdenModalSchema.default() };
  }
});

const premieSideEffects = createISWSideEffect<OrvType, OrvKenmerken | null>((bag): void => {
  const { draft, context, has } = bag;
  if (!draft.premieverloop && context?.premie.heeftPremieverloopGelijkblijvend) {
    draft.premieverloop = PremieverloopOptions.Gelijkblijvend;
  }

  if (!draft.premieverloop && context?.premie.heeftPremieverloopVariabel) {
    draft.premieverloop = PremieverloopOptions.Variabel;
  }

  if (has.premieverloop.changed) {
    draft.premieGegevens.risicoPremieLaag = null;
  }
});

const productSideEffects = createISWSideEffect<OrvType, OrvKenmerken | null>((bag): void => {
  const { draft, context, subset } = bag;
  polisSideEffects(bag);
  verzekerdenSideEffects(bag);
  dekkingSideEffects(subset.createWithContext(context));
  if (draft.partijCode !== partijOnafhankelijk) {
    legenPremieSideEffects(bag);
  }
  verpandingSideEffects(bag);
  premieSideEffects(bag);
});

export const eindleeftijdSideEffects = createISWSideEffect<OrvsState, orvSideEffectsContext>((bag): void => {
  const { draft, prev, has, context } = bag;
  const draftSelected = draft.producten[context.selected];
  const hasSelected = has.producten[context.selected];

  if (
    draftSelected.product.einddatum &&
    draftSelected.verzekerden.verzekerden === AanvragerKeuze.Aanvrager1 &&
    (!prev ||
      hasSelected.verzekerden.changed ||
      hasSelected.product.einddatum.changed ||
      hasSelected.product.looptijd.changed)
  ) {
    const newEindleeftijd =
      draft.aanvrager1?.geboortedatum?.until(draftSelected.product.einddatum, ChronoUnit.YEARS) ?? null;
    draftSelected.dekking.eindleeftijd = newEindleeftijd;

    if (draftSelected.product.soortProduct === SoortOrvProductOptions.AnwHiaatVerzekering) {
      const newEindleeftijd =
        draft.aanvrager1?.geboortedatum?.until(
          draft.aanvrager1.aowdatum || draft.aanvrager1.geboortedatum.plusYears(67),
          ChronoUnit.YEARS
        ) ?? null;
      draftSelected.dekking.eindleeftijd = newEindleeftijd;
      draftSelected.dekking.einddatum = null;
    }
    // stop because draft.producten[context.selected] will never be aanvrager2 anyway
    return;
  }

  if (
    draftSelected.product.einddatum &&
    draftSelected.verzekerden.verzekerden === AanvragerKeuze.Aanvrager2 &&
    (!prev ||
      hasSelected.verzekerden.changed ||
      hasSelected.product.einddatum.changed ||
      hasSelected.product.looptijd.changed)
  ) {
    const newEindleeftijd =
      draft.aanvrager2?.geboortedatum?.until(draftSelected.product.einddatum, ChronoUnit.YEARS) ?? null;
    draftSelected.dekking.eindleeftijd = newEindleeftijd;

    if (draftSelected.product.soortProduct === SoortOrvProductOptions.AnwHiaatVerzekering) {
      const newEindleeftijd =
        draft.aanvrager2?.geboortedatum?.until(
          draft.aanvrager2.aowdatum || draft.aanvrager2.geboortedatum.plusYears(67),
          ChronoUnit.YEARS
        ) ?? null;
      draftSelected.dekking.eindleeftijd = newEindleeftijd;
      draftSelected.dekking.einddatum = null;
    }
  }
});

const informatieVoorVerzendingAanvraagSideEffects = createISWSideEffect<OrvType, OrvKenmerken | null>(bag => {
  const { draft, context } = bag;

  if (
    context?.aanvraag.akkoordMetDigitaleCommunicatieTonen &&
    draft.informatieVoorVerzendingAanvraag.akkoordMetDigitaleCommunicatie === null
  ) {
    draft.informatieVoorVerzendingAanvraag.akkoordMetDigitaleCommunicatie = false;
  }
  if (
    context?.aanvraag.toelichtingGelezenAkkoordMetSlotverklaringTonen &&
    draft.informatieVoorVerzendingAanvraag.toelichtingGelezenAkkoordMetSlotverklaring === null
  ) {
    draft.informatieVoorVerzendingAanvraag.toelichtingGelezenAkkoordMetSlotverklaring = false;
  }
  if (
    context?.aanvraag.sprakeVanStrafrechtelijkVerledenTonen &&
    draft.informatieVoorVerzendingAanvraag.strafrechtelijkVerledenAanvrager1 === null
  ) {
    draft.informatieVoorVerzendingAanvraag.strafrechtelijkVerledenAanvrager1 = false;
  }
  if (
    context?.aanvraag.sprakeVanStrafrechtelijkVerledenTonen &&
    draft.informatieVoorVerzendingAanvraag.strafrechtelijkVerledenAanvrager2 === null
  ) {
    draft.informatieVoorVerzendingAanvraag.strafrechtelijkVerledenAanvrager2 = false;
  }
  if (
    context?.aanvraag.akkoordMetDigitalePolisTonen &&
    draft.informatieVoorVerzendingAanvraag.akkoordMetDigitalePolis === null
  ) {
    draft.informatieVoorVerzendingAanvraag.akkoordMetDigitalePolis = false;
  }
});

export const orvDetailDraftSideEffects = createISWSideEffect<OrvsState, orvSideEffectsContext>((bag): void => {
  const { draft, prev, subset, context } = bag;

  if (!draft.producten.length) {
    // nog geen product geselecteerd, niks doen
    return;
  }

  // leningdeel wordt verwijderd, we zijn klaar
  if (prev.producten.length > draft.producten.length) {
    return;
  }

  productSideEffects(subset.producten[context.selected].createWithContext(context.kenmerken));
  eindleeftijdSideEffects(bag);
  informatieVoorVerzendingAanvraagSideEffects(subset.producten[context.selected].createWithContext(context.kenmerken));
});

export const determineOrvDetailsSideEffects = initISWSideEffect<OrvsState, orvSideEffectsContext>(
  orvDetailDraftSideEffects
);
