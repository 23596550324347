import React, { ReactElement } from "react";
import { FormikProps } from "formik";

import { FormikSideEffects } from "use-formik-side-effects";
import { determineProductSelectieSideEffects } from "./determine-product-selectie-side-effects";
import { getProducten } from "../infra/product-selectie/product-selectie-helper";

import { LabeledRadioInput, LabeledSelectInput } from "adviesbox-shared";
import AdviesBoxFormik from "../../shared/utils/adviesbox-formik";
import Modal from "../../shared/components/modal/Modal";
import { ProductSelectieStateType, SelectieType, SituatieSoort } from "../infra/producten-overzicht-types";
import { productKeuzeModalSchema } from "../infra/producten-overzicht-schema";

type ProductSelectieModalData = {
  data: ProductSelectieStateType;
  labels: SelectieType[];
};

type ProductSelectieModalProps = {
  situatie: SituatieSoort;
  modalTitle: string;
  maatschappijTitel: string;
  productTitel: string;
  onSave: (verzekeringSelectie: ProductSelectieStateType) => void;
  closeModal?: () => void;
};

const ProductSelectieModal = ({
  data,
  onSave,
  labels: productvormen,
  closeModal,
  situatie,
  modalTitle,
  maatschappijTitel,
  productTitel
}: ProductSelectieModalProps & ProductSelectieModalData): ReactElement => {
  const body = ({ maatschappijCode }: ProductSelectieStateType): ReactElement => {
    return (
      <div>
        {situatie === "voorstel" && productvormen.length > 1 && (
          <>
            {productvormen.length <= 3 ? (
              <LabeledRadioInput caption={maatschappijTitel} name="maatschappijCode" options={productvormen} />
            ) : (
              <LabeledSelectInput
                caption={maatschappijTitel}
                name="maatschappijCode"
                options={productvormen}
                fieldSize="xl"
              />
            )}
          </>
        )}

        <>
          {getProducten(productvormen, maatschappijCode).length <= 3 ? (
            <LabeledRadioInput
              caption={productTitel}
              name="productCode"
              options={getProducten(productvormen, maatschappijCode)}
            />
          ) : (
            <LabeledSelectInput
              caption={productTitel}
              name="productCode"
              options={getProducten(productvormen, maatschappijCode)}
              fieldSize="xl"
            />
          )}
        </>
      </div>
    );
  };

  return (
    <AdviesBoxFormik<ProductSelectieStateType>
      initialValues={data}
      validationSchema={productKeuzeModalSchema}
      onSave={onSave}
      closeModal={closeModal}
      render={({ submitForm, values, isSubmitting }: FormikProps<ProductSelectieStateType>): ReactElement => {
        return (
          <>
            <FormikSideEffects<ProductSelectieStateType>
              determineSideEffects={(current, previous): ProductSelectieStateType | null =>
                determineProductSelectieSideEffects(current, previous, productvormen)
              }
            />
            <Modal
              title={modalTitle}
              body={body(values)}
              onSubmitClick={submitForm}
              onCancelClick={closeModal}
              isSubmitting={isSubmitting}
            />
          </>
        );
      }}
    />
  );
};
export default ProductSelectieModal;
