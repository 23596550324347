import { SettingsType } from "adviesbox-shared";
import { RapportSamenstellenStructuur } from "../../.generated/rapportage/rapportagetypes";

export const startRapportageSamenstellen = async (
  settings: SettingsType,
  requestInit: RequestInit,
  structuur: RapportSamenstellenStructuur,
  voorstelId: string,
  adviesdossierId: string
): Promise<void> => {
  const url = !!voorstelId
    ? `${settings.rapportageOrigin}/Voorstellen/${voorstelId}/RapportSamenstellen/Rapporten`
    : `${settings.rapportageOrigin}/Adviesdossiers/${adviesdossierId}/RapportSamenstellen/Rapporten`;

  const rsp = await fetch(url, {
    ...requestInit,
    method: "POST",
    headers: { ...requestInit.headers, "content-type": "application/json; charset=utf-8" },
    body: JSON.stringify(structuur)
  });

  if (!rsp.ok) {
    const err: any = new Error(rsp.statusText);
    err.status = rsp.status;
    throw err;
  }
};
