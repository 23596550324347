/* istanbul ignore file */
import {
  hasValue,
  Icon,
  LabeledCurrencyInput,
  LabeledSelectInput,
  LabeledTextInput,
  ModalButton,
  Card,
  useFeature,
  SubscribeNotifyButton,
  useRequestInit
} from "adviesbox-shared";
import { useFormikContext } from "formik";
import React, { ReactElement } from "react";
import AanvraagNaarProductModal from "../aanvraag-naar-product-modal/aanvraag-naar-product-modal";
import { AanvraagState, ProductType } from "../infra/aanvraag-schema";
import { AanvraagButton } from "../aanvraag-button/aanvraag-button";
import { getAanvraagTextResources } from "../infra/aanvraag-resources";
import { NieuweAanvraagInput } from "../../.generated/foundation/foundationtypes";
import { mapNieuweAanvraagInput } from "../infra/map-aanvraag-ui-to-dl";
import classes from "../aanvraag.module.scss";

export enum AanvraagProductType {
  Aov,
  Orv
}

export enum AanvraagType {
  Serviceprovider = "Serviceprovider",
  SoftwareKoppeling = "SoftwareKoppeling"
}

type AanvraagProductCardProps = {
  productType: AanvraagProductType;
  vestiging: string;
  adviesdossier: string;
  title: string;
  product: ProductType;
  name: string;
  index?: number | undefined;
  testId: string;
  isLoading: boolean;
};

export const AanvraagProduct = ({
  productType,
  title,
  product,
  index,
  name,
  testId,
  isLoading,
  vestiging,
  adviesdossier
}: AanvraagProductCardProps): ReactElement => {
  const featureNotificatie2 = useFeature("FeatureNotificaties2");
  const formik = useFormikContext<AanvraagState>();
  const { values, setFieldValue } = formik;
  const { settings, user } = useRequestInit<{ vestiging: string; adviesdossier: string }>();
  const aanwezigTekst =
    product.aanwezigInBemiddeling === true ? `Ja, ${product.voorstel}, Versie ${product.versie}` : "Nee";

  const requestInitNieuweAanvraag = (nieuweversie: boolean): RequestInit => {
    const nieuweAanvraag: NieuweAanvraagInput = mapNieuweAanvraagInput(values, adviesdossier, nieuweversie);
    return {
      headers: {
        authorization: `${user?.token_type} ${user?.access_token}`,
        "Ocp-Apim-Subscription-Key": settings.OcpApimSubscriptionKey,
        "Content-Type": "application/json",
        vestigingId: vestiging
      },
      method: "POST",
      body: JSON.stringify(nieuweAanvraag) // TODO
    };
  };

  return (
    <Card title={title} className="col-6">
      <LabeledTextInput caption="Partij" name={`${name}.partijnaam`} readonly />
      {
        <>
          <LabeledTextInput caption="Product" name={`${name}.productnaam`} readonly />
          <LabeledCurrencyInput caption="Premie" name={`${name}.premie`} readonly fieldSize="no-size" decimalen={2} />
        </>
      }
      <LabeledTextInput
        caption="Aanwezig in Aanvragen"
        name={`${name}.aanwezigInBemiddeling`}
        readonly
        appendChildren={<span>{aanwezigTekst}</span>}
      />

      {title === "Overlijdensrisicoverzekering" &&
        hasValue(index) &&
        values.orvProduct[index].verzekerde?.length !== 0 && (
          <LabeledTextInput
            caption={values.orvProduct[index].verzekerde?.length === 2 ? "Verzekerden" : "Verzekerde"}
            name={`${name}.verzekerde.naamVerzekerde`}
            readonly
            appendChildren={
              <div>
                {values.orvProduct[index].verzekerde?.map((verzekerde, index) => (
                  <div key={`naam-verzekerde-${index}`} id={`naam-verzekerde-${index}`}>
                    <span>{verzekerde.naamVerzekerde} </span>
                  </div>
                ))}
              </div>
            }
          />
        )}
      {product.aanwezigInBemiddeling && (
        <LabeledSelectInput
          name={`${name}.overschrijven`}
          caption="Nieuwe aanvraag of nieuwe versie"
          options={[
            {
              label: "Nieuwe aanvraag",
              value: "false"
            },
            {
              label: "Nieuwe versie",
              value: "true"
            }
          ]}
        />
      )}
      <LabeledTextInput
        caption="Aanvraag naar"
        name={`${name}.aanvraagNaar`}
        readonly
        appendChildren={
          <>
            <ModalButton parent={`${name}`} content={<Icon name="specificatie" alt="Aanvraag naar" />}>
              <AanvraagNaarProductModal
                product={product}
                onSave={/* istanbul ignore next */ (data: ProductType): void => setFieldValue(`${name}`, data)}
              />
            </ModalButton>
          </>
        }
      />

      {featureNotificatie2 && (
        <div className={classes.button_container}>
          <div className={classes.button_wrapper}>
            <SubscribeNotifyButton
              context={formik}
              data-testid={"hypotheekProduct-0"}
              buttonText={
                !product.aanvraagVerzonden
                  ? getAanvraagTextResources("productaanvragen")
                  : getAanvraagTextResources("productOpnieuwAanvragen")
              }
              requestInit={requestInitNieuweAanvraag(false)}
              url={`${settings.foundationOrigin}/HdnOnline/bus/NieuweAanvraag`}
            />
          </div>
        </div>
      )}

      {featureNotificatie2 && product.aanvraagVerzonden && (
        <div className={classes.button_container}>
          <div className={classes.button_wrapper}>
            <SubscribeNotifyButton
              context={formik}
              data-testid={"update-hypotheekProduct-0"}
              buttonText={getAanvraagTextResources("nieuweVersieProductAanvragen")}
              requestInit={requestInitNieuweAanvraag(true)}
              url={`${settings.foundationOrigin}/HdnOnline/bus/NieuweAanvraag`}
            />
          </div>
        </div>
      )}

      {!featureNotificatie2 && (
        <AanvraagButton
          formik={formik}
          testId={testId}
          buttonTekst={
            !product.aanvraagVerzonden
              ? getAanvraagTextResources("productaanvragen")
              : getAanvraagTextResources("productOpnieuwAanvragen")
          }
          nieuweVersieAanvraag={false}
          productId={product.productId ?? ""}
          isLoading={isLoading}
        />
      )}

      {!featureNotificatie2 && product.aanvraagVerzonden && (
        <AanvraagButton
          formik={formik}
          testId={`update-${testId}`}
          buttonTekst={getAanvraagTextResources("nieuweVersieProductAanvragen")}
          nieuweVersieAanvraag={true}
          productId={product.productId ?? ""}
          isLoading={isLoading}
        />
      )}
    </Card>
  );
};
