/* eslint-disable */
import React, { useMemo, useCallback, useEffect } from "react";
import { useRequestInit, LoadingSpinner } from "adviesbox-shared";
import { useFetchData } from "../../../shared/components/dossier/dossier-wijzig-adviseur-modal/usefetchdata";
import { useRefreshTrigger } from "../infra/use-refresh-trigger";

const logDebug = false;

export const Loading: React.FC = () => <div>Loading...</div>;

interface DataDisplayProps<T> {
  endpoint?: string;
  render: (data: T) => React.ReactNode;
  isVoorstel?: boolean;
  method?: string;
  loader?: () => React.ReactElement;
  autostart?: boolean;
  lowPriority?: boolean;
}


export const DataDisplay = <T,>({
  endpoint,
  render,
  isVoorstel,
  method = "GET",
  loader = () => <LoadingSpinner size="S" />,
  autostart = true,
  lowPriority = false
}: DataDisplayProps<T>): React.ReactElement => {
  const { settings, params, requestInit } = useRequestInit<{
    vestiging: string;
    adviesdossier: string;
    voorstel: string;
  }>();

  const { refreshTrigger } = useRefreshTrigger();

  const getFinalUrl = useMemo(() => {
    if (!endpoint) {
      logDebug && console.error("DataDisplay: Endpoint is undefined");
      return "";
    }

    if (endpoint.toLowerCase().startsWith("http")) {
      logDebug && console.log("DataDisplay: Using custom endpoint:", endpoint);
      return endpoint;
    }

    const baseUrl = `${settings.klantdossiersFormsOrigin}`;
    const url = isVoorstel
      ? `${baseUrl}/Voorstellen/${params.voorstel}/Dashboard/${endpoint}`
      : `${baseUrl}/Adviesdossiers/${params.adviesdossier}/Dashboard/${endpoint}`;

    logDebug && console.log("DataDisplay: Generated URL:", url);
    return url;
  }, [endpoint, isVoorstel, params.adviesdossier, params.voorstel, settings.klantdossiersFormsOrigin]);

  const getRequestInit = useCallback(() => {
    return {
      ...requestInit,
      method
    };
  }, [requestInit, method]);

  const { data, loading, error, refetch, dataUrl } = useFetchData<T>(
    getFinalUrl,
    getRequestInit,
    !!getFinalUrl, // Only enable if we have a valid URL
    lowPriority,
    autostart
  );

  useEffect(() => {
    if (refreshTrigger) {
      logDebug && console.log("DataDisplay: Refetching due to refreshTrigger change");
      refetch();
    }
  }, [refreshTrigger, refetch]);

  useEffect(() => {
    logDebug && console.log("DataDisplay: Data:", data);
    logDebug && console.log("DataDisplay: Error:", error);
    logDebug && console.log("DataDisplay: DataUrl:", dataUrl);
    logDebug && console.log("DataDisplay: Expected URL:", getFinalUrl);
  }, [data, error, dataUrl, getFinalUrl]);

  if (loading) return loader();
  
  if (error) {
    logDebug && console.error("Error in DataDisplay:", error);
    return <div>Er is een fout opgetreden bij het ophalen van de gegevens. Details: {error}</div>;
  }
  if (!data) {
    logDebug && console.warn("No data received in DataDisplay");
    return <></>;
  }

  return <>{render(data)}</>;
};