import { QuestionType, CardSpec, TitleType } from "../../infra/klantprofiel-schema";
import { KlantprofielVraagOptions } from "../../../.generated/forms/formstypes";
import { WelkeUitgavenBeperkenExtra } from "../welkeuitgavenbeperken-extra";

export const pensioenQuestionSpecs: CardSpec[] = [
  {
    sections: [
      {
        title: TitleType.custom,
        customTitle: "Kennisniveau van onderwerpen die te maken hebben met pensioen",
        subtitle: "Geef per onderwerp het kennisniveau aan door het bolletje te slepen.",
        questions: [
          {
            question: KlantprofielVraagOptions.KennisInkomenssituatiePensioen,
            type: QuestionType.slider
          },
          {
            question: KlantprofielVraagOptions.KennisPensioenWerkgever,
            type: QuestionType.slider
          },
          {
            question: KlantprofielVraagOptions.KennisPensioenAow,
            type: QuestionType.slider
          },
          {
            question: KlantprofielVraagOptions.KennisPensioenLijfrente,
            type: QuestionType.slider
          },
          {
            question: KlantprofielVraagOptions.KennisPensioenVermogen,
            type: QuestionType.slider
          }
        ]
      }
    ]
  },
  {
    title: "Welke maatregelen wenst u te treffen voor als u of uw partner met pensioen gaat.",
    questions: [
      {
        title: TitleType.aanvrager,
        question: KlantprofielVraagOptions.MaatregelenPensioenAanvrager1,
        type: QuestionType.checkbox
      },
      {
        title: TitleType.aanvrager,
        question: KlantprofielVraagOptions.MaatregelenPensioenAanvrager2,
        type: QuestionType.checkbox
      }
    ]
  },
  {
    questions: [
      {
        question: KlantprofielVraagOptions.MaatregelenGevolgenPensioenBeperken,
        type: QuestionType.radio
      },
      {
        question: KlantprofielVraagOptions.WaarvoorInkomenVermogenOpzijZetten,
        type: QuestionType.checkbox,
        condition: [
          {
            question: KlantprofielVraagOptions.MaatregelenGevolgenPensioenBeperken,
            answer: 160801
          }
        ]
      },
      {
        question: KlantprofielVraagOptions.UitgavenLagerPensioenInkomenBeperken,
        type: QuestionType.radio,
        condition: [
          {
            question: KlantprofielVraagOptions.MaatregelenGevolgenPensioenBeperken,
            answer: 160803
          }
        ]
      },
      {
        question: KlantprofielVraagOptions.WelkeUitgavenBeperkenPensioen,
        type: QuestionType.toggleCheckbox,
        extra: WelkeUitgavenBeperkenExtra,
        condition: [
          {
            question: KlantprofielVraagOptions.UitgavenLagerPensioenInkomenBeperken,
            answer: 160901
          }
        ]
      }
    ]
  }
];
