import { LocalDate } from "@js-joda/core";
import { jaarMaandInMaanden, mapStringToLocalDate } from "adviesbox-shared";
import {
  AovPolis,
  HypotheekHuidigeSituatieLeningdeel,
  EnergieKlasseType,
  HypotheeksamenstellingLeningdeel,
  HypotheekVoorstelLeningdeel,
  Financieringsoort,
  KapitaalverzekeringPolis,
  KredietPolis,
  LijfrentePolis,
  OverigAlgemeen,
  VermogenPolis
} from "../../.generated/forms/formstypes";
/* eslint-disable @typescript-eslint/no-unused-vars */
import { PremiedepotInput, PremiedepotInputUitgangspunt, ExtraPremiestortingInJaar } from "../../.generated/vermogen/vermogentypes";
import { mapJaarMaandInputFromLooptijdDl2Ui } from "../../shared/generic-parts/jaar-maand/map-dl-2-ui";
import { createMapToDl } from "../../shared/utils/create-map-to-dl";
import { addMonths } from "../../shared/utils/dates";
import { partijAnders, partijOnafhankelijk } from "./product-constanten";
import { premiedepotModalSchema } from "./producten-overzicht-schema";
import { ProductType } from "./producten-overzicht-types";

type HypotheekConfig = {
  energyKlasse: EnergieKlasseType | null;
  hypotheekVoorstel: Financieringsoort | null;
  meerwerkEBV: number | null;
};

type ProductTypes =
  | AovPolis
  | HypotheekHuidigeSituatieLeningdeel
  | HypotheeksamenstellingLeningdeel
  | HypotheekVoorstelLeningdeel
  | KapitaalverzekeringPolis
  | KredietPolis
  | LijfrentePolis
  | OverigAlgemeen
  | VermogenPolis;

  /* istanbul ignore next */
export const mapPremieDepotBerekening = createMapToDl(premiedepotModalSchema).to<PremiedepotInput>({
  uitgangspunt: v => PremiedepotInputUitgangspunt[v.uitgangspunt as keyof typeof PremiedepotInputUitgangspunt],
  premiedepotBedrag: v => v.bedrag,
  vergoedingPercentage: v => v.vergoeding,

  opnameHogePremies: v => (v.hogePremies && (v.hoogLaagDuur?.jaren !== 0 || v.hoogLaagDuur?.maanden !== 0)),
  hogeInlegBedrag: v => (v.hogePremies && (v.hoogLaagDuur?.jaren !== 0 || v.hoogLaagDuur?.maanden !== 0)) ? v.hoogInlegBedrag : null,

  inlegBedrag: v => (v.lagePremies && v.inlegBedrag)? v.inlegBedrag : null,
  opnameLagePremies: v => v.lagePremies,

  premiedepotDuurInMaanden: v => v.duur,
  premieDuurInMaanden: v => jaarMaandInMaanden(v.premieDuur),
  
  opnameAanvangsstorting: v => v.aanvangsstorting,
  aanvangsstortingBedrag: v => (v.aanvangsstorting && v.aanvangsstortingBedrag) ? v.aanvangsstortingBedrag : null,

  opnameExtraStortingen: v =>  v.extraStortingen && v.extraPremieStortingen && v.extraPremieStortingen?.length>0,
  extraPremiestortingen: v => v.extraStortingen && v.extraPremieStortingen && v.extraPremieStortingen.length > 0 ? (v.extraPremieStortingen?.filter(c=>!!c.bedrag).map<ExtraPremiestortingInJaar>((c, index)=> {
    return {bedrag:c.bedrag, jaar:1}
  }) || []) : null,

  duurHooglaagInMaanden: v => (v.hogePremies ? jaarMaandInMaanden(v.hoogLaagDuur) : null), 
});

const isKredietPolis = (input: any): input is KredietPolis => {
  return (
    typeof input.leningnummer === "undefined" &&
    (typeof input.productnummer === "string" || input.productnummer === null) &&
    typeof input.aanvangsdatum === "undefined" &&
    (typeof input.ingangsdatum === "string" || input.ingangsdatum === null)
  );
};

const isKapitaalverzekeringPolis = (input: any): input is KapitaalverzekeringPolis => {
  return (
    typeof input.leningnummer === "undefined" &&
    (typeof input.productnummer === "string" || input.productnummer === null) &&
    (typeof input.maatschappijCode === "string" || input.maatschappijCode === null) &&
    (typeof input.maatschappijOmschrijving === "string" || input.maatschappijOmschrijving === null) &&
    (typeof input.maatschappijCodeHdn === "string" || input.maatschappijCodeHdn === null) &&
    (typeof input.productnaam === "string" || input.productnaam === null) &&
    typeof input.productcode === "number" &&
    typeof input.aanvangsdatum === "undefined" &&
    (typeof input.ingangsdatum === "string" || input.ingangsdatum === null) &&
    (typeof input.looptijdInMaanden === "number" || input.looptijdInMaanden === null) &&
    typeof input.uwBemiddeling === "boolean"
  );
};

const getPartijNaam = (input: ProductTypes): string => {
  if (!("maatschappijOmschrijving" in input)) {
    return "";
  }
  return "hypotheekvorm" in input && input.hypotheekvorm.starterslening ? "SVn" : input.maatschappijOmschrijving || "";
};

// TODO: NX: Er moet 1 productmapper zijn. Orv is hier nog niet aan toegevoegd. (Task 44012)
// TODO: Code netter opzetten (typeguards gebruiken - zie mappers hypotheek)
export function mapProductDl2Ui(input: ProductTypes, energyKlasseHackConfig?: HypotheekConfig): ProductType {
  let productNummer = "";
  let ingangsdatum: LocalDate | null = null;

  if (isKapitaalverzekeringPolis(input)) {
    productNummer = input.productnummer ?? "";
    ingangsdatum = mapStringToLocalDate(input.ingangsdatum);
  } else if (isKredietPolis(input)) {
    productNummer = input.productnummer ?? "";
    ingangsdatum = mapStringToLocalDate(input.ingangsdatum);
  } else {
    productNummer = "leningnummer" in input ? input.leningnummer ?? "" : "";
    ingangsdatum = mapStringToLocalDate(input.aanvangsdatum);
  }

  const product: ProductType = {
    productNummer,
    ingangsdatum,
    einddatum: ingangsdatum && input.looptijdInMaanden ? addMonths(ingangsdatum, input.looptijdInMaanden) : null,
    doorlopend: "doorlopend" in input ? input.doorlopend || false : false,
    productNaam: "productnaam" in input ? input.productnaam : null,
    partijCodeSelectie: ((): string => {
      // gebruik de maatschappijCodeHdn indien aanwezig
      if ("maatschappijCodeHdn" in input && input.maatschappijCodeHdn) return input.maatschappijCodeHdn;

      // gebruik anders de maatschappijCode, bijvoorbeeld "IN" (met uitzondering van "XX")
      if ("maatschappijCode" in input && input.maatschappijCode && input.maatschappijCode !== partijOnafhankelijk)
        return input.maatschappijCode;

      // bij maatschappijCode "XX" geven we "ZZ" terug, omdat dit neerkomt op 'Anders', en er geen eerdere selectie gemaakt is.
      // voorbeeld: in de huidige situatie is na migratie, het importeren van hypotheek product met 'Anders' gekomen, er is geen maatschappij geselecteerd
      if ("maatschappijCode" in input && input.maatschappijCode && input.maatschappijCode === partijOnafhankelijk)
        return partijAnders;

      return "";
    })(),

    omschrijving: "omschrijving" in input ? input.omschrijving || "" : "",
    partijNaam: getPartijNaam(input),
    looptijd: mapJaarMaandInputFromLooptijdDl2Ui(input.looptijdInMaanden),
    uwBemiddeling: input.uwBemiddeling,
    meenemen: "meenemen" in input ? input.meenemen : false,
    wijzigingenInDoorlopendProductOvernemen:
      "wijzigingenInDoorlopendeProductenOvernemen" in input ? input.wijzigingenInDoorlopendeProductenOvernemen : null,
    renteboxCode: null
  };

  return product;
}
