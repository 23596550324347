import * as Yup from "yup";
import {
  FinancieringOptions,
  DuurPensioenuitkeringOptions,
  ControleGewenstInkomenOptions,
  ControleGewenstNbiOptions,
  ControleMaandlastOptions,
  DuurOverzichtOptions,
  MotiveringOnderwerpsoort
} from "../../.generated/forms/formstypes";
import { UitgangspuntenAdvies } from "../../.generated/instellingen-forms/instellingen-formstypes";
import { klantnaamSchema } from "../../shared/generic-parts/klantnaam/schema";
import { WithSaveData } from "../../shared/utils/save-data";
import { yupEnum, yupNullableEnum } from "../../shared/utils/yup-enum";

export enum MotiveringScenario {
  Standaard = "Standaard",
  Extra = "Extra"
}

export type NamePrefixRisicoUitgangspunten =
  | "uitgangspuntenWerkloosheid"
  | "uitgangspuntenPensioen"
  | "uitgangspuntenOverlijden"
  | "uitgangspuntenArbeidsongeschiktheid";

export type NamePrefixDubbeleWoonlasten = "uitgangspuntenHypotheek" | "uitgangspuntenVermogen";

export type uitgangspuntenCardType = {
  voorstelId: string | undefined;
  omschrijving: string | null | undefined;
  volgnummer: number | undefined;
  namePrefixInkomenAnalyseCard: NamePrefixRisicoUitgangspunten | undefined;
};

export const voorstelSchema = Yup.object({
  voorstelId: Yup.string().default(null),
  omschrijving: Yup.string()
    .default("")
    .nullable(),
  volgnummer: Yup.number().default(0)
});

export type VoorstelType = Yup.InferType<typeof voorstelSchema>;

export const wensSchema = Yup.object({
  wensenAanvrager1: Yup.array()
    .of(Yup.string())
    .default([]),
  wensenAanvrager2: Yup.array()
    .of(Yup.string())
    .default([]),
  wensenAlgemeen: Yup.array(Yup.string()).default([])
});

export type WensType = Yup.InferType<typeof wensSchema>;

export const adviesSchema = Yup.object({
  adviesAanvrager1: Yup.string()
    .nullable()
    .default(null),
  adviesAanvrager2: Yup.string()
    .nullable()
    .default(null)
});

export type AdviesType = Yup.InferType<typeof adviesSchema>;

export const afwijkendeKeuzeSchema = Yup.object({
  afwijkendeKeuzeAanvrager1: Yup.string()
    .nullable()
    .default(null),
  afwijkendeKeuzeAanvrager2: Yup.string()
    .nullable()
    .default(null)
});

export type AfwijkendeKeuzeType = Yup.InferType<typeof afwijkendeKeuzeSchema>;

export const motiveringControleHypotheeknormSchema = Yup.object({
  controleMaandlast: yupEnum(ControleMaandlastOptions),
  controleerMaximaleHypotheek: Yup.boolean()
});

export type MotiveringControleHypotheeknormType = Yup.InferType<typeof motiveringControleHypotheeknormSchema>;

export const motiveringControleGewenstInkomenSchema = Yup.object({
  controleGewenstInkomen: yupEnum(ControleGewenstInkomenOptions),
  brutoInkomenswensPercentage: Yup.number()
    .nullable()
    .default(null),
  controleGewenstNbi: yupNullableEnum(ControleGewenstNbiOptions),
  margeOndergrensPercentage: Yup.number()
    .nullable()
    .default(null),
  margeBovengrensPercentage: Yup.number()
    .nullable()
    .default(null),
  vastBedrag: Yup.number()
    .nullable()
    .default(null),
  huidigNbiPercentage: Yup.number()
    .nullable()
    .default(null),
  gewijzigdeJarenTonen: Yup.boolean(),
  benodigdKapitaalTonen: Yup.boolean()
});
export type ControleGewenstInkomenOptionsType = Yup.InferType<typeof motiveringControleGewenstInkomenSchema>;

export const uitgangspuntenOverlijdenSchema = Yup.object({
  werkenNaOverlijdenPartnerAanvrager1Percentage: Yup.number()
    .nullable()
    .default(null),
  werkenNaOverlijdenPartnerAanvrager2Percentage: Yup.number()
    .nullable()
    .default(null),
  duurOverzicht: yupNullableEnum(DuurOverzichtOptions),
  overzichtDuurInJaren: Yup.number()
    .nullable()
    .default(null),
  overzichtTotLeeftijdInMaanden: Yup.number()
    .nullable()
    .default(null),
  controleHypotheeknorm: motiveringControleHypotheeknormSchema.nullable().default(null),
  controleGewenstInkomen: motiveringControleGewenstInkomenSchema.nullable().default(null),
  motiveringVoorVoorstelId: Yup.string()
    .nullable()
    .default(null),
  voorstellen: Yup.array(voorstelSchema).default([]),
  scenarioVanKlantIds: Yup.array(Yup.string()).default([]),
  overzichtBeideTonen: Yup.bool()
    .nullable()
    .default(null)
});

export type UitgangspuntenOverlijdenType = Yup.InferType<typeof uitgangspuntenOverlijdenSchema>;

export const uitgangspuntenArbeidsongeschiktheidSchema = Yup.object({
  arbeidsongeschiktheidExtra1Percentage: Yup.number()
    .nullable()
    .default(null),
  arbeidsongeschiktheidExtra2Percentage: Yup.number()
    .nullable()
    .default(null),
  arbeidsongeschiktheidStandaardPercentage: Yup.number()
    .nullable()
    .default(null),
  benuttingVerdiencapaciteitExtra1Percentage: Yup.number()
    .nullable()
    .default(null),
  benuttingVerdiencapaciteitExtra2Percentage: Yup.number()
    .nullable()
    .default(null),
  benuttingVerdiencapaciteitStandaardPercentage: Yup.number()
    .nullable()
    .default(null),
  controleHypotheeknorm: motiveringControleHypotheeknormSchema.nullable().default(null),
  controleGewenstInkomen: motiveringControleGewenstInkomenSchema.nullable().default(null),
  duurzaamArbeidsongeschiktExtra1: Yup.boolean()
    .nullable()
    .default(null),
  duurzaamArbeidsongeschiktExtra2: Yup.boolean()
    .nullable()
    .default(null),
  duurzaamArbeidsongeschiktStandaard: Yup.boolean()
    .nullable()
    .default(null),
  scenarioDoorrekeningExtra1: Yup.boolean()
    .nullable()
    .default(null),
  scenarioDoorrekeningExtra2: Yup.boolean()
    .nullable()
    .default(null),
  toetsjaar: Yup.number()
    .nullable()
    .default(null),
  motiveringVoorVoorstelId: Yup.string()
    .nullable()
    .default(null),
  voorstellen: Yup.array(voorstelSchema).default([]),
  overzichtBeideTonen: Yup.bool()
    .nullable()
    .default(null),
  scenarioVanKlantIds: Yup.array(Yup.string()).default([])
});

export type UitgangspuntenArbeidsongeschiktheidType = Yup.InferType<typeof uitgangspuntenArbeidsongeschiktheidSchema>;

export const arbeidsongeschiktheidDatagridItemSchema = Yup.object({
  scenario: yupEnum(MotiveringScenario).default(MotiveringScenario.Standaard),
  aoPercentage: Yup.number()
    .nullable()
    .default(null),
  duurzaamAo: Yup.boolean()
    .nullable()
    .default(null),
  benuttingVerdiencapaciteit: Yup.number()
    .nullable()
    .default(null),
  motiveringVoorVoorstelId: Yup.string()
    .nullable()
    .default(null)
});

export type ArbeidsongeschiktheidDatagridItemType = Yup.InferType<typeof arbeidsongeschiktheidDatagridItemSchema>;

export const uitgangspuntenWerkloosheidSchema = Yup.object({
  controleHypotheeknorm: motiveringControleHypotheeknormSchema,
  controleGewenstInkomen: motiveringControleGewenstInkomenSchema,
  motiveringVoorVoorstelId: Yup.string()
    .nullable()
    .default(null),
  voorstellen: Yup.array(voorstelSchema).default([]),
  scenarioVanKlantIds: Yup.array(Yup.string()).default([]),
  overzichtBeideTonen: Yup.bool()
    .nullable()
    .default(null)
});

export type UitgangspuntenWerkloosheidType = Yup.InferType<typeof uitgangspuntenWerkloosheidSchema>;

export const uitgangspuntenHypotheekSchema = Yup.object({
  controleHypotheeknorm: motiveringControleHypotheeknormSchema,
  controleGewenstInkomen: motiveringControleGewenstInkomenSchema,
  motiveringVoorVoorstelId: Yup.string()
    .nullable()
    .default(null),
  voorstellen: Yup.array(voorstelSchema).default([])
});

export type uitgangspuntenHypotheekType = Yup.InferType<typeof uitgangspuntenHypotheekSchema>;

export const uitgangspuntenVermogenSchema = Yup.object({
  motiveringVoorVoorstelId: Yup.string()
    .nullable()
    .default(null),
  voorstellen: Yup.array(voorstelSchema).default([])
});

export type uitgangspuntenVermogenType = Yup.InferType<typeof uitgangspuntenVermogenSchema>;

export const beschikbareJaarruimteSchema = Yup.object({
  gewensteDuurPensioenUitkering: yupNullableEnum(DuurPensioenuitkeringOptions),
  pensioenuitkeringTotLeeftijdInMaanden: Yup.number()
    .nullable()
    .default(null),
  winstBedrag: Yup.number()
    .nullable()
    .default(null),
  winstBerekendBedrag: Yup.number(),
  winstOvernemen: Yup.boolean().default(false),
  tegenwoordigeDienstbetrekkingBedrag: Yup.number()
    .nullable()
    .default(null),
  tegenwoordigeDienstbetrekkingBerekendBedrag: Yup.number(),
  tegenwoordigeDienstbetrekkingOvernemen: Yup.boolean().default(false),
  vroegereDienstbetrekkingBedrag: Yup.number()
    .nullable()
    .default(null),
  vroegereDienstbetrekkingBerekendBedrag: Yup.number(),
  vroegereDienstbetrekkingOvernemen: Yup.boolean().default(false),
  buitenlandseInkomstenBedrag: Yup.number()
    .nullable()
    .default(null),
  reisaftrekBedrag: Yup.number(),
  overigeWerkzaamhedenBedrag: Yup.number()
    .nullable()
    .default(null),
  overigeWerkzaamhedenBerekendBedrag: Yup.number(),
  overigeWerkzaamhedenOvernemen: Yup.boolean().default(false),
  periodiekeUitkeringenBedrag: Yup.number()
    .nullable()
    .default(null),
  premiegrondslagBedrag: Yup.number(),
  franchiseBedrag: Yup.number(),
  subtotaalFranchise1Bedrag: Yup.number(),
  premiegrondslagPercentage: Yup.number(),
  subtotaalFranchise2Bedrag: Yup.number(),
  pensioenaangroeiBedrag: Yup.number()
    .nullable()
    .default(null),
  pensioenaangroeiPercentage: Yup.number()
    .nullable()
    .default(null),
  subtotaalPensioenaangroeiBedrag: Yup.number(),
  toevoegingOdrBedrag: Yup.number()
    .nullable()
    .default(null),
  subtotaalToevoegingOdrBedrag: Yup.number(),
  jaarruimteBedrag: Yup.number(),
  benutteJaarruimteBedrag: Yup.number(),
  benutteJaarruimteBerekendBedrag: Yup.number(),
  benutteJaarruimteOvernemen: Yup.boolean().default(false),
  beschikbareJaarruimteBedrag: Yup.number(),
  maxPremieGrondslag: Yup.number()
});

export type BeschikbareJaarruimteType = Yup.InferType<typeof beschikbareJaarruimteSchema>;

export const uitgangspuntenPensioenSchema = Yup.object({
  beschikbareJaarruimteAanvrager1: beschikbareJaarruimteSchema.nullable().default(null),
  beschikbareJaarruimteAanvrager2: beschikbareJaarruimteSchema.nullable().default(null),
  controleHypotheeknorm: motiveringControleHypotheeknormSchema.nullable().default(null),
  controleGewenstInkomen: motiveringControleGewenstInkomenSchema.nullable().default(null),
  motiveringVoorVoorstelId: Yup.string()
    .nullable()
    .default(null),
  voorstellen: Yup.array(voorstelSchema).default([]),
  scenarioVanKlantIds: Yup.array(Yup.string()).default([]),
  overzichtBeideTonen: Yup.bool()
    .nullable()
    .default(null)
});

export type UitgangspuntenPensioenType = Yup.InferType<typeof uitgangspuntenPensioenSchema>;

export const uitgangspuntenDubbeleWoonlastenSchema = Yup.object({
  geschatteDuurOverbruggingInMaanden: Yup.number()
    .default(3)
    .required()
});

export const motiveringSchema = Yup.object({
  aanvrager1: klantnaamSchema,
  aanvrager2: klantnaamSchema.nullable().default(null),
  wens: wensSchema,
  advies: adviesSchema,
  afwijkendeKeuze: afwijkendeKeuzeSchema,
  dubbeleWoonlasten: Yup.string()
    .nullable()
    .default(null),
  uitgangspuntenOverlijden: uitgangspuntenOverlijdenSchema.nullable().default(null),
  uitgangspuntenWerkloosheid: uitgangspuntenWerkloosheidSchema.nullable().default(null),
  uitgangspuntenHypotheek: uitgangspuntenHypotheekSchema.nullable().default(null),
  uitgangspuntenVermogen: uitgangspuntenVermogenSchema.nullable().default(null),
  uitgangspuntenPensioen: uitgangspuntenPensioenSchema.nullable().default(null),
  uitgangspuntenDubbeleWoonlasten: uitgangspuntenDubbeleWoonlastenSchema.nullable().default(null),
  arbeidsongeschiktheidDatagrid: Yup.array()
    .of(arbeidsongeschiktheidDatagridItemSchema)
    .default([]),
  uitgangspuntenArbeidsongeschiktheid: uitgangspuntenArbeidsongeschiktheidSchema.nullable().default(null),
  uitgangspunten: Yup.string()
    .nullable()
    .default(null),
  tabellenResourceUrl: Yup.string()
    .nullable()
    .default(null),

  financiering: yupNullableEnum(FinancieringOptions).default(null),
  dataHasChanged: Yup.bool().default(false)
});
export type MotiveringState = Yup.InferType<typeof motiveringSchema>;
export type MotiveringProps = {
  data: MotiveringState;
  instellingenUitgangspuntenAdviesData: UitgangspuntenAdvies;
} & WithSaveData<MotiveringState> & { motiveringType: MotiveringOnderwerpsoort };
