import classNames from "classnames";
import React, { ReactElement } from "react";
import { VoorwaardenSelectieType } from "../hypotheek-vergelijken-types";
import classes from "./hypotheek-vergelijker-pdf.module.scss";

type HypotheekVoorwaardenPDFTableProps = {
  voorwaardeSelectie?: VoorwaardenSelectieType;
  renderVoorwaardenOnly?: boolean;
};

export const verklaringInkomen = (voorwaardeSelectie?: VoorwaardenSelectieType): string[] => {
  if (!voorwaardeSelectie?.verklaringInkomen) return [];
  const res = [];
  if (voorwaardeSelectie.verklaringInkomen.werkgever) res.push("Werkgever");
  if (voorwaardeSelectie.verklaringInkomen.uwv) res.push("UWV");
  if (voorwaardeSelectie.verklaringInkomen.perspectief) res.push("Perspectief");
  if (voorwaardeSelectie.verklaringInkomen.accountantsverklaring) res.push("Accountantsverklaring");
  if (voorwaardeSelectie.verklaringInkomen.ibStukken) res.push("IB stukken");

  return res;
};

export const contractRente = (voorwaardeSelectie?: VoorwaardenSelectieType): string[] => {
  if (!voorwaardeSelectie?.contractRente) return [];
  const res = [];
  if (voorwaardeSelectie.contractRente.offerteRente) res.push("Offerterente");
  if (voorwaardeSelectie.contractRente.dagRente) res.push("Dagrente");
  if (voorwaardeSelectie.contractRente.dalRente) res.push("Dalrente");
  return res;
};
export const RenderHypotheekVoorwaardenTable = ({
  renderVoorwaardenOnly,
  voorwaardeSelectie
}: HypotheekVoorwaardenPDFTableProps): ReactElement => {
  const voorwaardenHeaders = [
    { txt: "Verklaring inkomen", display: !!verklaringInkomen(voorwaardeSelectie)?.length },
    { txt: "Contractrente", display: !!contractRente(voorwaardeSelectie)?.length },
    { txt: "Annuleringskosten offerte acceptabel", display: true },
    { txt: "Verplichte ORV boven marktwaarde %", display: true },
    { txt: "Verhuisregeling", display: true },
    { txt: "Minimale offertegeldigheidsduur", display: true },
    { txt: "Geldigheidsduur minimaal", display: voorwaardeSelectie?.offerte?.minimaleOfferteGeldigheidsDuur },
    { txt: "Inclusief verlengingsduur", display: voorwaardeSelectie?.offerte?.minimaleOfferteGeldigheidsDuur }
  ];

  const voorwaardenContent = [
    verklaringInkomen(voorwaardeSelectie)?.join(" , "),
    contractRente(voorwaardeSelectie)?.join(" , "),
    voorwaardeSelectie?.overige?.annuleringsKostenAcceptabel ? "Ja" : "Nee",
    voorwaardeSelectie?.overige?.verplichteOrv ? "Ja" : "Nee",
    voorwaardeSelectie?.overige?.verhuisRegeling ? "Ja" : "Nee",
    voorwaardeSelectie?.offerte?.minimaleOfferteGeldigheidsDuur ? "Ja" : "Nee",
    voorwaardeSelectie?.offerte?.geldigheidsduurMinimaal ?? 0,
    voorwaardeSelectie?.offerte?.inclusiefVerlengingsduur ? "Ja" : "Nee"
  ];

  let displayIndex = 0;
  return (
    <>
      <table className={classNames({ [classes.pdf_padding]: renderVoorwaardenOnly })}>
        <thead>
          <tr>
            <td className={classes.pdf_table_voorwaarden_header_left}>Voorwaarden</td>
            <td className={classes.pdf_table_voorwaarden_header_right}></td>
          </tr>
        </thead>
        <tbody>
          {voorwaardenHeaders.map((v, i) => (
            <tr key={"vt_row_" + i}>
              {v.display && (
                <>
                  <td className={classes.pdf_table_data_info}>{v.txt}</td>
                  <td
                    className={`${classes.pdf_table_data}`}
                    style={displayIndex++ % 2 ? {} : { backgroundColor: "#f0f0f0" }}
                  >
                    {voorwaardenContent[i]}
                  </td>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};
