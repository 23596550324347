import {
  Overig,
  OverigOutput,
  OverigProduct,
  OverigGegevens,
  SoortCorrectieLastOptions,
  SoortCorrectieFiscaalOptions
} from "../../.generated/forms/formstypes";

import { mapProductDl2Ui } from "../../producten-overzicht/infra/product-mapper-dl-2-ui";

import { KlantnaamType } from "../../shared/generic-parts/klantnaam/schema";
import { mapKlantnaamDl2Ui } from "../../shared/generic-parts/klantnaam/map-dl-2-ui";

import {
  heffingSchema,
  correctieSchema,
  overigePostenGegevensSchema,
  verrekenenSchema,
  overigePostSchema,
  overigePostenSchema
} from "./overige-posten-schema";
import { createMapToUi } from "../../shared/utils/create-map-to-ui";
import { bepaalEindleeftijd } from "./helpers";
import { OverigePostenType } from "./overige-posten-types";
import { mapStringToLocalDate } from "adviesbox-shared";

type AanvragerContext = {
  aanvrager1: KlantnaamType | null;
  aanvrager2: KlantnaamType | null;
};

const heffingDl2Ui = createMapToUi(heffingSchema).from<OverigProduct>({
  heffingBij: v => v.heffingBijKlantId
});

const correctieDl2Ui = createMapToUi(correctieSchema).from<OverigProduct>({
  correctieLast: v => v.correctieLast || SoortCorrectieLastOptions.Last,
  correctieFiscaal: v => v.correctieFiscaal || SoortCorrectieFiscaalOptions.Aftrekpost
});

const overigePostenGegevensDl2Ui = createMapToUi(overigePostenGegevensSchema).from<OverigGegevens>({
  bedrag: v => v.correctieScenario.waarde,
  bedragScenario: v => ({
    scenario: v.correctieScenario.indexeringen?.map(e => ({ bedrag: e.bedrag, percentage: null })) ?? []
  }),
  termijn: v => v.betalingstermijn,
  inkomenUitArbeid: v => v.inkomenUitArbeid,
  onderdeelDagloon: v => v.onderdeelDagloon
});

const verrekenenDl2Ui = createMapToUi(verrekenenSchema)
  .with<AanvragerContext>()
  .from<OverigProduct>({
    langleven: v => v.verrekenen.langleven,
    arbeidsongeschiktheid: v => v.verrekenen.arbeidsongeschiktheid,
    werkloosheid: v => v.verrekenen.werkloosheid,
    overlijden: v => v.verrekenen.overlijden,
    overgangOpPartnerPercentage: v => v.verrekenen.overgangOpPartnerPercentage,
    overgangOpPartnerEinddatum: v => mapStringToLocalDate(v.verrekenen.overgangOpPartnerEinddatum),
    eindleeftijd: (v, context) =>
      bepaalEindleeftijd(
        mapStringToLocalDate(v.verrekenen.overgangOpPartnerEinddatum),
        v.heffingBijKlantId,
        context.aanvrager1,
        context.aanvrager2
      )
  });

const overigePostDl2Ui = createMapToUi(overigePostSchema)
  .with<AanvragerContext>()
  .from<OverigProduct>({
    productId: v => v.productId,
    product: v => {
      const prod = mapProductDl2Ui(v.algemeen);
      prod.omschrijving = v.gegevens.correctieScenario.omschrijving || "";
      prod.wijzigingenInDoorlopendProductOvernemen = v.wijzigingenInDoorlopendeProductenOvernemen;

      return prod;
    },
    heffing: v => heffingDl2Ui(v),
    correctie: v => correctieDl2Ui(v),
    overigePostenGegevens: v => overigePostenGegevensDl2Ui(v.gegevens),
    verrekenen: (v, context) => verrekenenDl2Ui(context)(v)
  });

function dl2ui(values: Overig): OverigePostenType {
  const aanvrager1 = mapKlantnaamDl2Ui(values.aanvrager1);
  const aanvrager2 = mapKlantnaamDl2Ui(values.aanvrager2);

  const overigePosten: OverigePostenType = {
    producten: values.producten.map(product => overigePostDl2Ui({ aanvrager1, aanvrager2 })(product)),
    aanvrager1,
    aanvrager2,
    ingangsdatumVoorstel: mapStringToLocalDate(values.ingangsdatumVoorstel)
  };

  return overigePostenSchema.cast(overigePosten);
}

export function mapOverigePostenDlToUi(overigePostenId: string, data: OverigOutput): OverigePostenType | null {
  const overigePosten = data && data.overigen ? data.overigen[overigePostenId] : null;

  if (!overigePosten) {
    return null;
  }

  return dl2ui(overigePosten);
}
