import { DekkingProps, claimBeoordelingOptions } from "./dekking";
import React, { ReactElement } from "react";
import { UitkeringGedeeltelijkeAo } from "./uitkering-gedeeltelijke-ao";
import {
  LabeledRadioInput,
  LabeledCurrencyInput,
  LabeledNumberInput,
  PercentageInput,
  LabeledPercentageInput
} from "adviesbox-shared";
import { enum2options } from "../../shared/utils/enum-to-options";
import { AovJaarlijkseIndexatieVan } from "../infra/aov-schema";

export const DekkingArbeidsogeschiktheid = ({ selected, kenmerken, verzekerde }: DekkingProps): ReactElement => {
  return (
    <>
      <LabeledCurrencyInput
        caption="Inkomen"
        name={`producten[${selected}].dekking.brutoJaarinkomen`}
        readonly={true}
      />

      <h2 className="ml-3 mt-2">Rubriek A (1e jaar van AO)</h2>

      <LabeledCurrencyInput
        name={`producten[${selected}].dekking.dekkingGegevensVerzekerde${verzekerde}.rubriekA.verzekerdBedrag`}
        caption="Verzekerd bedrag/ % van inkomen"
        appendChildren={
          <div className="px-1 no-margin">
            <PercentageInput
              name={`producten[${selected}].dekking.dekkingGegevensVerzekerde${verzekerde}.rubriekA.percentageVanInkomen`}
              decimalen={2}
            />
          </div>
        }
      />

      <LabeledPercentageInput
        caption="Jaarlijkse indexatie"
        name={`producten[${selected}].dekking.dekkingGegevensVerzekerde${verzekerde}.rubriekA.jaarlijkseIndexatiePercentage`}
        decimalen={2}
      />

      <LabeledRadioInput
        caption="Jaarlijkse indexatie van"
        name={`producten[${selected}].dekking.dekkingGegevensVerzekerde${verzekerde}.rubriekA.jaarlijkseIndexatieVan`}
        options={enum2options(AovJaarlijkseIndexatieVan)}
      />

      <LabeledNumberInput
        caption="Eigen risico"
        name={`producten[${selected}].dekking.dekkingGegevensVerzekerde${verzekerde}.rubriekA.eigenRisicoInMaanden`}
        appendChildren={<div>Maanden</div>}
      />

      {kenmerken.claimBeoordelingAoTonen && (
        <LabeledRadioInput
          caption="Claimbeoordeling"
          name={`producten[${selected}].dekking.dekkingGegevensVerzekerde${verzekerde}.rubriekA.beoordelingscriterium`}
          options={claimBeoordelingOptions()}
        />
      )}

      {kenmerken.uitkeringGedeeltelijkAoTonen && (
        <UitkeringGedeeltelijkeAo
          kenmerken={kenmerken}
          selected={selected}
          verzekerde={verzekerde}
          subSchema={"rubriekA"}
        />
      )}

      <h2 className="ml-3 mt-2">Rubriek B (vervolgjaren)</h2>

      <LabeledCurrencyInput
        name={`producten[${selected}].dekking.dekkingGegevensVerzekerde${verzekerde}.rubriekB.verzekerdBedrag`}
        caption="Verzekerd bedrag/ % van inkomen"
        appendChildren={
          <div className="px-1 no-margin">
            <PercentageInput
              name={`producten[${selected}].dekking.dekkingGegevensVerzekerde${verzekerde}.rubriekB.percentageVanInkomen`}
              decimalen={2}
            />
          </div>
        }
      />

      {kenmerken.claimBeoordelingAoTonen && (
        <LabeledRadioInput
          caption="Claimbeoordeling"
          name={`producten[${selected}].dekking.dekkingGegevensVerzekerde${verzekerde}.rubriekB.beoordelingscriterium`}
          options={claimBeoordelingOptions()}
        />
      )}

      <LabeledNumberInput
        caption="Eindleeftijd uitkering"
        name={`producten[${selected}].dekking.dekkingGegevensVerzekerde${verzekerde}.rubriekB.eindLeeftijd`}
        appendChildren={<div>Jaar</div>}
        verplicht
      />
    </>
  );
};
