import { LocalDate } from "@js-joda/core";
import { ExtraAflossingenModalType } from "../infra/hypotheek-types";
import { createISWSideEffect, initISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";

const extraAflossingenSideEffects = createISWSideEffect<ExtraAflossingenModalType>(({ has, draft }): void => {
  if (draft.extraAflossingen && has.extraAflossingen.changed) {
    if (draft.extraAflossingen.length > 0) {
      //Zet de datum naar de eerste dag van de maand
      draft.extraAflossingen.forEach(
        (aflossing): LocalDate | null => (aflossing.datum = aflossing.datum?.withDayOfMonth(1) ?? null)
      );
    }
  }
});

export const determineExtraAflossingenModalSideEffects = initISWSideEffect(extraAflossingenSideEffects)();
