import React, { ReactElement } from "react";
import classes from "./productwrapper-component.module.scss";
import { Button, Accordion } from "react-bootstrap";
import { LabeledToggleInput } from "adviesbox-shared";

/**
 * Returns an element specific to the type of the value, ie. for a boolean value, its returns a label with a checkbox.
 */
const getOptionComponent = (name: string, value: any, key: number, prefix: string): ReactElement | null => {
  switch (typeof value) {
    case "boolean":
      return <LabeledToggleInput caption={name} name={`${prefix}.${name}`} key={"opt" + key} />;
    default:
      return null;
  }
};

/**
 * This component shows the available 'options' for the props it's been given, and then render its child component.
 * 'options' in this context means, for all booleans a label with a 'checkbox' (see getOptionComponent()).
 */
export function ProductWrapper<T extends { children?: ReactElement; kenmerken: any; prefix: string }>(
  opts: T
): ReactElement {
  const options = Object.keys(opts.kenmerken)
    .sort(new Intl.Collator("nl").compare)
    .map((keyName, index): ReactElement | null =>
      getOptionComponent(keyName, opts.kenmerken[keyName], index, opts.prefix)
    );

  return (
    <Accordion>
      <Accordion.Toggle as={Button} variant="primary" eventKey="0">
        ProductWrapper
      </Accordion.Toggle>

      <Accordion.Collapse eventKey="0">
        <div className={classes.prodwrapper}>
          {options}
          {opts.children}
        </div>
      </Accordion.Collapse>
    </Accordion>
  );
}
