import { FormikProps } from "formik";
import React, { ReactElement } from "react";
import Modal from "../../../shared/components/modal/Modal";
import AdviesBoxFormik from "../../../shared/utils/adviesbox-formik";
import { SimpleModal } from "adviesbox-shared";
import { FinancieringsbehoefteState } from "../../infra/financieringsbehoefte-types";
import { financieringsbehoefteSchema } from "../../infra/financieringsbehoefte-schema";
import { getFinancieringsbehoefteTextResources } from "../../infra/financieringsbehoefte-resources";

type NhgWijzigenModalProps = {
  data: FinancieringsbehoefteState;
  onSave?: (data: FinancieringsbehoefteState) => void;
  closeModal?: () => void;
  onCancel?: () => void;
};

export const NhgWijzigenModal = ({
  data,
  onSave,
  closeModal,
  onCancel
}: NhgWijzigenModalProps): ReactElement => {
  return (
    <SimpleModal closeModal={closeModal}>
        <AdviesBoxFormik<FinancieringsbehoefteState>
        initialValues={data}
        validationSchema={financieringsbehoefteSchema}
        onSave={onSave}
        closeModal={closeModal}
        render={({ submitForm }: FormikProps<FinancieringsbehoefteState>): ReactElement => (
            <Modal
            onSubmitClick={submitForm}
            onCancelClick={onCancel}
            noEditableData={true}
            saveButtonTekst="Wijzigen"
            submitButtonVariant="danger"
            title="Financiering"
            body={
              <div data-testid="nhg-wijzigen">
                {getFinancieringsbehoefteTextResources("WarningWijzigenNhg")}
              </div>
            }
            />
        )}
        />
    </SimpleModal>
  );
};
