import { Icon } from "adviesbox-shared";
import React, { ReactElement, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { renderHypotheekOversluitenPDFFooter } from "./hypotheek-oversluiten-pdf-footer";
import { renderHypotheekOversluitenPDFHeader } from "./hypotheek-oversluiten-pdf-header";
import { printPDF } from "./hypotheek-oversluiten-pdf-helpers/hypotheek-oversluiten-pdf-helpers";
import { RenderHypotheekOversluitenIndicatieTable } from "./hypotheek-oversluiten-indicatie-pdf-table";
import classes from "./hypotheek-oversluiten-pdf.module.scss";
import classNames from "classnames";
import { castUitslag2Icon } from "../data-grid/data-grid-helpers/data-grid-icons/data-grid-icons";
import { bedragFormat } from "../../shared/utils/currency";
import {
  HypotheekOversluitenState,
  MappedBerekenHypotheekOversluitenResponse
} from "../infra/hypotheek-oversluiten-schema";
import { RenderHypotheekOversluitenLeningdelenTable } from "./hypotheek-oversluiten-pdf-leningdelen-table";

type HypotheekOversluitenPDFProps = {
  values: HypotheekOversluitenState;
};

export const HypotheekOversluitenPDFGenerator = (props: HypotheekOversluitenPDFProps): ReactElement => {
  const {
    values: {
      organisatieData,
      uitgangspunten,
      toetsrente,
      berekendeHypotheken,
      sortedBerekendeHypotheken,
      extraPdfVelden
    }
  } = props;

  const [data, setData] = useState(berekendeHypotheken);
  useEffect(() => {
    if (sortedBerekendeHypotheken?.length > 0) {
      setData(sortedBerekendeHypotheken);
      return;
    }
    setData(berekendeHypotheken);
  }, [setData, sortedBerekendeHypotheken, berekendeHypotheken]);

  const hypotheekOversluiten = [...data];
  const amountOfLinesFirstPage = 50;
  const amountOfLinesPerPage = 55;
  const amountOflinesTotal = hypotheekOversluiten.length;
  let amountOfpages = Math.ceil((amountOflinesTotal - amountOfLinesFirstPage) / amountOfLinesPerPage + 1);

  const renderPage = (
    renderLeningdelenOnly: boolean,
    renderIndicatieTableOnly: boolean,
    slicedContent: MappedBerekenHypotheekOversluitenResponse[],
    index?: number,
    firstRender?: boolean,
    lastPageHasSpaceForLeningdelenTable?: boolean,
    lastPageHasSpaceForIndicatieTable?: boolean
  ): ReactElement => {
    const headers = [
      { header: "Advies", width: classes.pdf_table_data_header_centered, display: true },
      {
        header: "Maatschappij",
        width: classes.pdf_table_data_header_L,
        display: slicedContent.some(v => !!v.maatschappijNaam)
      },
      {
        header: "Rente",
        width: classes.pdf_table_data_header_XS,
        display: slicedContent.some(v => !!v.rentePercentage)
      },
      {
        header: "Bruto maandlast",
        width: classes.pdf_table_data_header_S,
        display: slicedContent.some(v => !!v.brutoMaandlast)
      },
      {
        header: "Besparing maand",
        width: classes.pdf_table_data_header_XM,
        display: slicedContent.some(v => !!v.besparingMaand)
      },
      {
        header: "Oversluit- kosten",
        width: classes.pdf_table_data_header_S,
        display: slicedContent.some(v => !!v.oversluitkosten)
      },
      {
        header: "Besparing in rvp",
        width: classes.pdf_table_data_header_S,
        display: slicedContent.some(v => !!v.besparingInRvp)
      },
      {
        header: "Terugverdien- periode",
        width: classes.pdf_table_data_header_M,
        display: slicedContent.some(v => !!v.terugverdienPeriodeInMaanden)
      },
      {
        header: "Terugverdien- percentage",
        width: classes.pdf_table_data_header_M,
        display: slicedContent.some(v => !!v.terugverdienPeriodePercentage)
      }
    ];

    return (
      <div
        className={classes.pdf_hide}
        id={`print-container-${index}`}
        key={`print-container-${index}-key`}
        style={{ maxWidth: "30.3cm", minWidth: "30.3cm", display: "block" }}
      >
        <div className={classes.pdf_page} style={{ backgroundColor: "white" }}>
          {firstRender &&
            renderHypotheekOversluitenPDFHeader({
              bedrijfslogo: organisatieData?.logo
            })}
          {!renderIndicatieTableOnly && slicedContent && (
            <table className={`${classes.pdf_table} ${classes.pdf_table_dataTable}`}>
              <thead>
                <tr>
                  {headers.map(
                    (v, i) =>
                      v.display && (
                        <th key={"header" + i} className={classNames(classes.pdf_table_data_header, v.width)}>
                          {v.header}
                        </th>
                      )
                  )}
                </tr>
              </thead>
              <tbody>
                {slicedContent.map((v, i) => (
                  <tr key={"body_row" + i}>
                    {headers[0].display && <td>{castUitslag2Icon(v.akkoordOversluiten)}</td>}
                    {headers[1].display && <td>{v.maatschappijNaam}</td>}
                    {headers[2].display && (
                      <td className={classes.pdf_table_right_align}>{v.rentePercentage?.toFixed(3) ?? 0 + "%"}</td>
                    )}
                    {headers[3].display && (
                      <td className={classes.pdf_table_right_align}>{bedragFormat(v.brutoMaandlast, 2)}</td>
                    )}
                    {headers[4].display && (
                      <td className={classes.pdf_table_right_align}>{bedragFormat(v.besparingMaand, 2)}</td>
                    )}
                    {headers[5].display && (
                      <td className={classes.pdf_table_right_align}>{bedragFormat(v.oversluitkosten, 2)}</td>
                    )}
                    {headers[6].display && (
                      <td className={classes.pdf_table_right_align}>{bedragFormat(v.besparingInRvp, 2)}</td>
                    )}
                    {headers[7].display && (
                      <td className={classes.pdf_table_right_align}>
                        {v.terugverdienPeriodeInMaanden ?? 0 + " Maanden"}
                      </td>
                    )}
                    {headers[8].display && (
                      <td className={classes.pdf_table_right_align}>
                        {v.terugverdienPeriodePercentage?.toFixed(3) ?? 0 + " %"}
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          {!!uitgangspunten?.huidigeSituatie.length &&
            (renderLeningdelenOnly || lastPageHasSpaceForLeningdelenTable) &&
            RenderHypotheekOversluitenLeningdelenTable({
              renderLeningdelenOnly,
              huidigeSituatie: uitgangspunten?.huidigeSituatie
            })}
          {(renderIndicatieTableOnly || lastPageHasSpaceForIndicatieTable) &&
            RenderHypotheekOversluitenIndicatieTable({
              renderIndicatieTableOnly,
              uitgangspunten,
              toetsrente,
              extraPdfVelden
            })}
          {renderHypotheekOversluitenPDFFooter({ organisatieNaam: organisatieData?.naam })}
        </div>
      </div>
    );
  };

  const renderPages = (): ReactElement => {
    let leningdelenTableFitsOnLastPage = false;
    let indicatieTableFitsOnLastPage = false;
    const counter = amountOfpages;
    const pages: ReactElement[] = [];
    if (hypotheekOversluiten?.length === 0) return <></>;
    for (let i = 1; i <= counter; i++) {
      const lastPage = amountOfpages === i;
      const until = (): number => {
        if (i === 1) {
          return amountOfLinesFirstPage;
        }

        return amountOfLinesPerPage;
      };

      const sliced = hypotheekOversluiten.splice(0, until());
      const amountOfLinesOnpage = sliced.length;
      type IndicatieHasSpaceFunctionReturnType = { hasSpace: boolean; space: number };
      const hasSpaceForLeningdelenTable = (): IndicatieHasSpaceFunctionReturnType => {
        const indicatieTableRows = 7;
        const space = amountOfLinesOnpage + indicatieTableRows;
        if (i === 1 && space >= amountOfLinesFirstPage) return { hasSpace: false, space };
        if (i !== 1 && space >= amountOfLinesPerPage) return { hasSpace: false, space };
        return { hasSpace: true, space };
      };

      const hasSpaceForIndicatieTable = (): boolean => {
        const leningdelenTableRows = uitgangspunten?.huidigeSituatie?.length + 2;
        const space = hasSpaceForLeningdelenTable().space + leningdelenTableRows;
        if (i === 1 && space >= amountOfLinesFirstPage) return false;
        if (i !== 1 && space >= amountOfLinesPerPage) return false;
        return true;
      };

      if (lastPage) {
        leningdelenTableFitsOnLastPage = hasSpaceForLeningdelenTable().hasSpace;
        indicatieTableFitsOnLastPage = hasSpaceForIndicatieTable();
      }

      if (i > amountOfpages) break;

      const page = renderPage(
        false,
        false,
        sliced,
        i,
        i === 1,
        leningdelenTableFitsOnLastPage,
        indicatieTableFitsOnLastPage
      );

      pages.push(page);

      if (lastPage && (!indicatieTableFitsOnLastPage || !leningdelenTableFitsOnLastPage)) {
        amountOfpages = amountOfpages + 1;
        const verzekeringIndex = i + 1;
        const verzekeringTable = renderPage(
          !leningdelenTableFitsOnLastPage,
          true,
          sliced,
          verzekeringIndex,
          false,
          false,
          true
        );
        pages.push(verzekeringTable);
      }
    }

    return <>{pages.map(page => page)}</>;
  };

  return (
    <>
      {renderPages()}
      <Button
        className="mt-1"
        onClick={/* istanbul ignore next */ async () => await printPDF(amountOfpages, "download")}
      >
        <Icon name="download" alt="download" multiColor={true} />
      </Button>
    </>
  );
};
