import {
  LabeledAfbeelding,
  LabeledSelectInput,
  LabeledText,
  SettingsContext
} from "adviesbox-shared";
import { connect, FormikContextType } from "formik";
import React, { ReactElement, useContext, useEffect } from "react";
import { BankgarantieinstellingPartij } from "../../.generated/instellingen-forms/instellingen-formstypes";
import { BankgarantieState, InstellingCodes } from "../infra/bankgarantie-schema";

type BankgarantieProductProps = { productInstellingOptions: BankgarantieinstellingPartij[] };

const Product = ({
  productInstellingOptions: instellingOptions,
  formik: {
    setFieldValue,
    values: { product }
  }
}: BankgarantieProductProps & {
  formik: FormikContextType<BankgarantieState>;
}): ReactElement => {
  const { productenOrigin, OcpApimSubscriptionKey } = useContext(SettingsContext);

  const productInstellingOptions: any[] = [];

  instellingOptions
    ?.filter(optie => optie.code !== InstellingCodes.Onafhankelijk)
    .forEach(optie => {
      if (optie.actief)
        productInstellingOptions.push({ label: optie.partijnaam, value: optie.code });
    });

  productInstellingOptions
    .sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1))
    .unshift({ label: InstellingCodes.Geen, value: InstellingCodes.Geen });

  const handleInstellingSelectie = (event: { target: { value: string | null } }): void => {
    if (event.target.value === InstellingCodes.Geen) {
      setFieldValue("product.instelling", InstellingCodes.Geen);
      setFieldValue("product.agentnummer", null);
    } else {
      const geselecteerdeOptie = instellingOptions?.find(e => e.code === event.target.value);
      setFieldValue("product.instelling", geselecteerdeOptie?.code);
      setFieldValue("product.agentnummer", geselecteerdeOptie?.agentnummer);
    }
  };

  // agentnummer wordt hier gevuld om mee te sturen bij de Aanvraag verzenden button
  useEffect(() => {
    if (!product.agentnummer && product.instelling !== InstellingCodes.Geen) {
      setFieldValue("product.agentnummer", instellingOptions?.find(v => v.code === product.instelling)?.agentnummer);
    }
  }, [instellingOptions, product.agentnummer, product.instelling, setFieldValue]);

  return (
    <>
      {productInstellingOptions.length > 1 ? (
        <LabeledSelectInput
          caption="Instellingen"
          name={`product.instelling`}
          options={productInstellingOptions}
          onChange={handleInstellingSelectie}
        />
      ) : (
        <LabeledText
          caption="Instellingen"
          fieldSize="no-size"
          value={instellingOptions?.find(v => v.code === product.instelling)?.partijnaam ?? "Geen"}
        />
      )}

      {product.instelling !== InstellingCodes.Geen && (
        <div>
          <LabeledAfbeelding
            caption="Product logo"
            name={`product.instelling`}
            location={`${productenOrigin}/Logos/Logo/${product.instelling}?subscription-key=${OcpApimSubscriptionKey}`}
          />
        </div>
      )}
    </>
  );
};

export default connect<BankgarantieProductProps, BankgarantieState>(Product);
