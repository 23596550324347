import React, { ReactElement } from "react";
import { FormikProps } from "formik";

import Modal from "../../shared/components/modal/Modal";
import { LabeledCurrencyInput, CurrencyInput, TextInput } from "adviesbox-shared";

import AdviesBoxFormik from "../../shared/utils/adviesbox-formik";
import { AndereFinancieringenModal as AndereFinancieringenModalState } from "../infra/financieringsbehoefte-types";
import { andereFinancieringenModalSchema } from "../infra/financieringsbehoefte-schema";
import { getFinancieringsbehoefteTextResources } from "../infra/financieringsbehoefte-resources";
import classes from "./andere-financieringen-modal.module.scss";

export type AndereFinancieringenModalData = {
  data: AndereFinancieringenModalState;
  startersLeningToegestaan: boolean;
};

type AndereFinancieringenModalProps = {
  onSave?: (data: AndereFinancieringenModalState) => void;
  closeModal?: () => void;
};

const AndereFinancieringenModal = ({
  data,
  startersLeningToegestaan,
  onSave,
  closeModal
}: AndereFinancieringenModalData & AndereFinancieringenModalProps): ReactElement => {
  const body = (
    <>
      {(data.doorlopendeHypotheken ? data.doorlopendeHypotheken : 0) > 0 && (
        <LabeledCurrencyInput caption="Doorlopende hypothe(e)k(en)" name="doorlopendeHypotheken" readonly={true} />
      )}
      <LabeledCurrencyInput
        caption="Kortlopende lening / krediet"
        name="kortlopendeLeningKrediet"
        tooltip={getFinancieringsbehoefteTextResources("TooltipKortlopendeLening")}
      />
      {startersLeningToegestaan && (
        <>
          <LabeledCurrencyInput caption="Starterslening" name="startersleningBedrag" />
          <div className="form-group form-row mb-3">
            <div className="col-12">
              <a
                href="https://www.svn.nl"
                className="link"
                target="_blank"
                id="StartersleningLink"
                rel="noopener noreferrer"
              >
                <span>Starterslening berekenen</span>
                <i className="icon externallink" />
              </a>
            </div>
          </div>
        </>
      )}
      <div className="form-group form-row">
        <div className="col-7">
          <div className={classes.andere_financieringen}>
            <TextInput name="overigeFinanciering1Omschrijving" placeholder="Omschrijving" />
          </div>
        </div>
        <div className="col-5">
          <CurrencyInput name="overigeFinanciering1Bedrag" />
        </div>
      </div>
      <div className="form-group form-row">
        <div className="col-7">
          <div className={classes.andere_financieringen}>
            <TextInput name="overigeFinanciering2Omschrijving" placeholder="Omschrijving" />
          </div>
        </div>
        <div className="col-5">
          <CurrencyInput name="overigeFinanciering2Bedrag" />
        </div>
      </div>
    </>
  );

  return (
    <AdviesBoxFormik<AndereFinancieringenModalState>
      initialValues={{ ...data }}
      validationSchema={andereFinancieringenModalSchema}
      onSave={onSave}
      closeModal={closeModal}
      render={({ submitForm }: FormikProps<AndereFinancieringenModalState>): ReactElement => (
        <>
          <Modal title="Andere financiering(en)" body={body} onSubmitClick={submitForm} onCancelClick={closeModal} />
        </>
      )}
    />
  );
};

export default AndereFinancieringenModal;
