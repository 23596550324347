import { EwsInput, EwsOutput, Financieringsoort, GebruikPandSoort } from "../../.generated/forms/formstypes";
import { FinancieringsbehoefteExtInput, FinancieringsbehoefteOutput } from "../../.generated/hypotheek/hypotheektypes";
import {
  EigenwoningforfaitInput,
  EigenwoningforfaitOutput,
  OverdrachtsbelastingPercentageInput,
  OverdrachtsbelastingPercentageOutput
} from "../../.generated/tarieven/tarieventypes";
import {
  createISWAsyncSideEffect,
  initISWAsyncSideEffect
} from "../../shared/components/isw-side-effects/create-isw-helpers";
import { ISWAsyncSideEffectBag } from "../../shared/components/isw-side-effects/isw-types";
import { koopsomSchema } from "./financieringsbehoefte-schema";
import { FinancieringsbehoefteState } from "./financieringsbehoefte-types";
import {
  mapBerekeningFinancieringsbehoefteExtOutput,
  mapEigenWoningForfaitInput,
  mapEigenWoningSchuldInput,
  mapEigenWoningSchuldOutput,
  mapFinancieringsbehoefteExtInput,
  mapOverdrachtsbelastingPercentageInput
} from "./map-financieringsbehoefte-berekenen";

export type AsyncContext = { 
  voorstelId: string;
  isAankoop?: boolean;
};

const eigenwoningforfaitBerekenen = async ({
  draft,
  settings,
  fetchData
}: ISWAsyncSideEffectBag<FinancieringsbehoefteState, AsyncContext>): Promise<void> => {
  const result = await fetchData<EigenwoningforfaitOutput, EigenwoningforfaitInput>({
    url: `${settings.accTarievenOrigin}/Berekeningen/Eigenwoningforfait`,
    body: mapEigenWoningForfaitInput(draft)
  });

  if (result.isValid) {
    draft.onderpand.eigenwoningforfait = result.eigenwoningforfait ?? 0;
  }
};

const financieringsbehoefteBerekenen = async ({
  draft,
  settings,
  fetchData,
  has,
  context
}: ISWAsyncSideEffectBag<FinancieringsbehoefteState, AsyncContext>): Promise<void> => {
  let overdrachtsbelastingPercentage = null;
  if (context.isAankoop) {
    const overdrachtsbelastingPercentageResultaat = await fetchData<
      OverdrachtsbelastingPercentageOutput,
      OverdrachtsbelastingPercentageInput
    >({
      url: `${settings.accTarievenOrigin}/Bepalingen/OverdrachtsbelastingPercentage`,
      body: mapOverdrachtsbelastingPercentageInput(draft)
    });
    overdrachtsbelastingPercentage = overdrachtsbelastingPercentageResultaat.overdrachtsbelastingPercentage;
  }

  const result = await fetchData<FinancieringsbehoefteOutput, FinancieringsbehoefteExtInput>({
    url: `${settings.hypotheekOrigin}/Financieringsbehoefte/Ext`,
    body: mapFinancieringsbehoefteExtInput({ overdrachtsbelastingPercentage })(draft)
  });

  if (result.isValid) {
    mapBerekeningFinancieringsbehoefteExtOutput(draft, result);
  }
};

const eigenWoningschuldBerekenen = async ({
  draft,
  settings,
  fetchData,
  context
}: ISWAsyncSideEffectBag<FinancieringsbehoefteState, AsyncContext>): Promise<void> => {
  const result = await fetchData<EwsOutput, EwsInput>({
    url: `${settings.klantdossiersFormsOrigin}/Voorstellen/${context.voorstelId}/Financieringsbehoefte/Eigenwoningschuld`,
    body: mapEigenWoningSchuldInput(draft)
  });

  if (result.isValid) {
    mapEigenWoningSchuldOutput(draft, result);
  }
};

export const eigenWoningForfaitAsyncBerekening = createISWAsyncSideEffect<FinancieringsbehoefteState, AsyncContext>(
  async bag => {
    await eigenwoningforfaitBerekenen(bag);
  }
);

export const financieringsBehoefteAsyncBerekening = createISWAsyncSideEffect<FinancieringsbehoefteState, AsyncContext>(
  async bag => {
    await financieringsbehoefteBerekenen(bag);
    if (bag.draft.onderpand.gebruik === GebruikPandSoort.PrimaireWoning) {
      await eigenWoningschuldBerekenen(bag);
    }
  }
);

export const financieringsBehoefteAsyncSideEffects = initISWAsyncSideEffect<FinancieringsbehoefteState, AsyncContext>(
  ({ has, curr, runAsync, context }) => {
    if (has.onderpand.wozWaarde.changed) {
      runAsync(eigenWoningForfaitAsyncBerekening(context));
    }

    const isAankoop =
      curr.financiering.soortFinanciering === Financieringsoort.AankoopBestaandeBouw ||
      curr.financiering.soortFinanciering === Financieringsoort.AankoopNieuwbouw;

    const contextMetIsAankoop = { ...context, isAankoop };

    // Zetten van nhgMogelijkBerekenen voor eerste keer op basis van marktwaarde en maximale koopsom
    if (has.onderpand.marktwaardeVoorVerbouwing.changed && !curr.financieringsopzet.nhgMogelijkIngesteld) {
      runAsync(financieringsBehoefteAsyncBerekening(contextMetIsAankoop));
    }

    if (
      isAankoop &&
      (curr.financieringsopzet.koopsom.koopsomBedrag === null || curr.financieringsopzet.koopsom.koopsomBedrag === 0)
    ) {
      return;
    }
    const isValid = koopsomSchema.isValidSync(curr.financieringsopzet.koopsom);

    if ((has.financiering.changed || has.financieringsopzet.changed || has.onderpand.changed) && isValid) {
      runAsync(financieringsBehoefteAsyncBerekening(contextMetIsAankoop));
    }
  }
);
