import { connect, FormikContextType, getIn } from "formik";
import React, { ReactElement, useState } from "react";
import { Button, ListGroup } from "react-bootstrap";
import { AanleidingState, VoorstelType } from "../infra/aanleiding-schema";
import { VoorstelDeleteModal } from "./voorstel-delete-modal/voorstel-delete-modal";
import VoorstelItem from "./voorstel-item";

export const resetAllIndex = (voorstellen: VoorstelType[]): VoorstelType[] => {
  let x = 0;
  return voorstellen.map(v => {
    if (v.index !== -1) return { ...v, index: x++ };
    else return v;
  });
};

const VoorstellenOverzicht = ({
  formik: { values, setFieldValue, errors }
}: {
  formik: FormikContextType<AanleidingState>;
}): ReactElement => {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [selectedElement, setSelectedElement] = useState<VoorstelType | null>(null);
  const errorMessage = getIn(errors, "voorstellen");

  const huidigeVoorstellen: VoorstelType[] = values.voorstellen
    .map((e, index): VoorstelType => ({ ...e, index }))
    .filter((c): boolean => !c.deleted);

  const onCloseDeleteModal = (): void => {
    setSelectedElement(null);
    setShowDeleteModal(false);
  };

  const deleteItem = (selectedItem: VoorstelType | null): (() => void) => (): void => {
    if (!selectedItem) {
      onCloseDeleteModal();
      return;
    }

    // Indien een voorstel geen Id heeft, is dit ook niet op de server aangemaakt
    // en mag dan ook simpelweg uit het lijstje verwijderd wortden.
    let voorstellen = [...values.voorstellen];
    if (selectedItem.id === null) {
      voorstellen.splice(selectedItem.index, 1);
      voorstellen = resetAllIndex(voorstellen);
      setFieldValue("voorstellen", voorstellen);
    } else {
      // Op de server bestaand voorstel, dus markeren als verwijderd.
      //en naar het eind van de lijst verplaatsen
      voorstellen.splice(selectedItem.index, 1);
      voorstellen = voorstellen.concat({ ...selectedItem, deleted: true, index: -1 });
      voorstellen = resetAllIndex(voorstellen);
      setFieldValue(`voorstellen`, voorstellen);
    }

    onCloseDeleteModal();
  };

  return (
    <div className="px-1">
      <VoorstelDeleteModal
        onSubmitModal={deleteItem(selectedElement)}
        onCloseModal={onCloseDeleteModal}
        showModal={showDeleteModal}
        selectedVoorstel={selectedElement}
      />
      <ListGroup>
        {huidigeVoorstellen.length === 0 && (
          <ListGroup.Item>Er zijn geen voorstellen aanwezig in het dossier.</ListGroup.Item>
        )}
        {huidigeVoorstellen.length > 0 &&
          huidigeVoorstellen.map(
            (elem): ReactElement => {
              return (
                <VoorstelItem
                  onDeleteClick={() => {
                    setSelectedElement(elem);
                    setShowDeleteModal(true);
                  }}
                  key={elem.index}
                  element={elem}
                  index={elem.index}
                />
              );
            }
          )}
      </ListGroup>
      {errorMessage && (
        <div className="foutmelding mt-2 w-100">Naam van een voorstel mag maximaal 25 karakters lang zijn.</div>
      )}
      {huidigeVoorstellen.length < 3 && (
        <div className="button-container">
          <Button
            id="btn-nieuw"
            data-testid="btn-nieuw"
            variant="primary"
            disabled={!!values.saving}
            onClick={(): void => {
              setFieldValue("saving", true);
              const copiedItem = {
                id: null,
                deleted: false,
                omschrijving: "",
                kopieVoorstelId: null,
                index: huidigeVoorstellen.length
              };
              let voorstellen = [...values.voorstellen];
              if (huidigeVoorstellen.length) voorstellen.splice(huidigeVoorstellen.length, 0, copiedItem);
              else voorstellen.push(copiedItem);
              voorstellen = resetAllIndex(voorstellen);
              setFieldValue("voorstellen", voorstellen);
            }}
          >
            + Nieuw voorstel
          </Button>
        </div>
      )}
    </div>
  );
};

export default connect<{}, AanleidingState>(VoorstellenOverzicht);
