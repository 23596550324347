
import { initISWSideEffect, createISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";
import { optellen } from "../../shared/utils/currency";
import { RenteverliesTijdensBouwModal } from "../infra/financieringsbehoefte-types";

const renteverliesTijdensBouwDraftSideEffects = createISWSideEffect<RenteverliesTijdensBouwModal>((bag): void => {
  const { has, draft } = bag;

  if (has.soortBerekening.changed || has.duurVanDeBouw.changed) {
    if (draft.soortBerekening === "Standaard") {
      draft.renteverliesMaandelijks = [];
    } else {
      draft.renteverliesMaandelijks = new Array(draft.duurVanDeBouw)
        .fill(null)
        .map((_, index) => ({ percentage: null, maand: index + 1 }));
    }
  }

  draft.renteverliesMaandelijksTotaal = optellen(draft.renteverliesMaandelijks
    .filter((x) => x.percentage && x.percentage > 0)
    .map((x): number | null => x.percentage));

  if (
    (has.hypotheekrente.changed || has.depotvergoeding.changed || has.duurVanDeBouw.changed) &&
    (!draft.hypotheekrente || !draft.depotvergoeding || !draft.duurVanDeBouw)
  ) {
    draft.renteverlies = null;
  }
});

export const determineRenteverliesTijdensBouwSideEffects = initISWSideEffect<RenteverliesTijdensBouwModal>(
  renteverliesTijdensBouwDraftSideEffects
)();
