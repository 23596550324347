import { LocalDate } from "@js-joda/core";
import { hasValue, jaarMaandInMaanden, mapLocalDateToString } from "adviesbox-shared";
import {
  Aov as AovDlEntry,
  AovAanvraag,
  AovAanvrager,
  AovDekking,
  AovDekkingArbeidsongeschiktheid,
  AovDekkingBasis,
  AovDekkingRubriekA,
  Indexatiesoort,
  UitkeringAoSoort,
  AovDekkingRubriekB,
  AovVerzekerdeDekkingenOptions,
  AovDekkingWerkloosheid,
  AovPolis,
  AovPremie,
  BetalingsTermijnType,
  AovProduct,
  BedragIndexering,
  BedragScenario,
  Verpanding
} from "../../.generated/forms/formstypes";
import { mapAanvragers } from "../../producten-overzicht/infra/map-aanvragers";
import { mapSpecificatieAoUitkeringModalUi2Dl } from "../../producten-overzicht/infra/map-specificatie-ao-uitkering";
import { partijOnafhankelijk } from "../../producten-overzicht/infra/product-constanten";
import { AovKenmerken } from "../../producten-overzicht/infra/product-kenmerken-types";
import { productSchema } from "../../producten-overzicht/infra/producten-overzicht-schema";
import { AoUitkeringswijze } from "../../producten-overzicht/infra/specificatie-ao-uitkering-schema";
import { mapAflosProductenUiToDl } from "../../producten-overzicht/infra/verpanding/map-verpanding-ui-to-dl";
import { KlantnaamType } from "../../shared/generic-parts/klantnaam/schema";
import { createMapEnum } from "../../shared/utils/create-map-enum";
import { createMapToDl } from "../../shared/utils/create-map-to-dl";
import {
  aflossingSchema,
  AovJaarlijkseIndexatieVan,
  AovScenarioCardInputType,
  aovSchema,
  aovsSchema,
  dekkingGegevensAoSchema,
  dekkingGegevensEaSchema,
  dekkingGegevensVerzekerdeSchema,
  dekkingGegevensWwSchema,
  dekkingVerzekerdeRubriekASchema,
  dekkingVerzekerdeRubriekBSchema,
  PremieBetalingstermijn,
  AovsType
} from "./aov-schema";

// TODO: Is bijna gelijk aan mapKlantnaamUi2Dl uit ../../shared/generic-parts/klantnaam/map-ui-2-dl => kijken of die functie gebruikt kan worden
export const checkAlleenDefinedKenmerken = (kenmerk: any | null): any => {
  const kenmerkIsDefined = hasValue(kenmerk);
  if (!kenmerkIsDefined) /* istanbul ignore next */ return true;
  return kenmerk;
};
export function mapKlantnaamUi2Dl(values: KlantnaamType | null): AovAanvrager | null {
  if (!values) {
    return null;
  }

  return {
    klantId: values.klantId,
    achternaam: values.achternaam,
    voorletters: values.voorletters,
    voorvoegsel: values.voorvoegsel,
    geboortedatum: mapLocalDateToString(values.geboortedatum),
    geslacht: null,
    voornamen: null,
    roker: values.roker,
    brutoJaarinkomen: 0, // TODO
    indicatiefJaarinkomenBedrag: null, // TODO: behoeft correcte implementatie, dit is een nswag update quickfix
    indicatiefMaandinkomenBedrag: null, // TODO: behoeft correcte implementatie, dit is een nswag update quickfix
    aowdatum: null // TODO: behoeft correcte implementatie, dit is een nswag update quickfix
  };
}

const mapPolisUi2Dl = createMapToDl(productSchema)
  .with<{ partijCode: string; productCode: string }>()
  .to<AovPolis>(
    // TODO: PartijCodeSelectie
    {
      productnummer: v => v.productNummer,
      maatschappijCodeHdn: (v, { partijCode }) => (partijCode === partijOnafhankelijk ? v.partijCodeSelectie : ""),
      maatschappijCode: (_, { partijCode }) => partijCode,
      maatschappijOmschrijving: v => v.partijNaam,
      productcode: (_, { productCode }) => Number(productCode),
      productnaam: v => v.productNaam,
      ingangsdatum: v => mapLocalDateToString(v.ingangsdatum) ?? /* istanbul ignore next */ "",
      looptijdInMaanden: v => jaarMaandInMaanden(v.looptijd) ?? /* istanbul ignore next */ 0,
      uwBemiddeling: v => v.uwBemiddeling
    }
  );
const mapUitkeringsWijze = createMapEnum(AoUitkeringswijze).to({
  Volledig: UitkeringAoSoort.Volledig,
  ProRata: UitkeringAoSoort.ProRata
});

const mapUitkeringsWijzeRubriekA = createMapEnum(AoUitkeringswijze).to({
  Volledig: UitkeringAoSoort.Volledig,
  ProRata: UitkeringAoSoort.ProRata
});

const mapJaarlijkseIndexatieVan: Record<AovJaarlijkseIndexatieVan, Indexatiesoort> = {
  Uitkering: Indexatiesoort.Uitkering,
  "Uitkering en verzekerd bedrag": Indexatiesoort.VerzekerdBedrag
};

const mapDekkingGegevensAo = createMapToDl(dekkingGegevensAoSchema)
  .with<{ productkenmerken: AovKenmerken | null }>()
  .to<AovDekkingArbeidsongeschiktheid>({
    aoUitkering: v =>
      v.uitkeringGedeeltelijkAo
        ? mapSpecificatieAoUitkeringModalUi2Dl(v.uitkeringGedeeltelijkAo)(v.uitkeringGedeeltelijkeAoModal)
        : null,
    eigenRisico: (v, x) =>
      x.productkenmerken === null || x.productkenmerken.dekking.eigenRisicoAoTonen ? v.eigenRisico : null,
    uitkeringsduurInMaanden: (v, x) =>
      x.productkenmerken === null || x.productkenmerken.dekking.aoUitkeringsduurTonen ? v.uitkeringsduurAo : null,
    looptijdInMaanden: (v, x) =>
      checkAlleenDefinedKenmerken(x.productkenmerken === null || x.productkenmerken.dekking.looptijdAoTonen)
        ? jaarMaandInMaanden(v.looptijd)
        : null,
    uitkeringGedeeltelijkAo: v => (v.uitkeringGedeeltelijkAo ? mapUitkeringsWijze(v.uitkeringGedeeltelijkAo) : null),
    uitkeringsduurInMaandenAo: v => jaarMaandInMaanden(v.uitkeringsDuur),
    verzekerdBedrag: v => v.verzekerdBedrag,
    verzekerdBedragGedeeltelijkAo: v => v.verzekerdBedragGedeeltelijkAo,
    annuiteitsPercentage: v => v.annuiteitsPercentage,
    beroep: v => v.beroep
  });
const mapDekkingGegevensWw = createMapToDl(dekkingGegevensWwSchema).to<AovDekkingWerkloosheid>({
  verzekerdBedrag: v => v.verzekerdBedrag,
  looptijdInMaanden: v =>
    typeof v.looptijd === "string" ? /* istanbul ignore next*/ parseInt(v.looptijd) : jaarMaandInMaanden(v.looptijd),
  uitkeringsduurInMaanden: v =>
    v.uitkeringsDuurInMaanden ? parseInt(v.uitkeringsDuurInMaanden) : jaarMaandInMaanden(v.uitkeringsDuur)
});
const mapDekkingGegevensAe = createMapToDl(dekkingGegevensEaSchema).to<AovDekkingBasis>({
  verzekerdBedrag: v => /* istanbul ignore next */ v.verzekerdBedrag,
  looptijdInMaanden: v => /* istanbul ignore next */ jaarMaandInMaanden(v.looptijd)
});

const mapDekkinggegevensRubriekA = createMapToDl(dekkingVerzekerdeRubriekASchema).to<AovDekkingRubriekA>({
  beoordelingscriterium: v => v.beoordelingscriterium,
  eigenRisicoInMaanden: v => v.eigenRisicoInMaanden,
  jaarlijkseIndexatiePercentage: v => v.jaarlijkseIndexatiePercentage,
  soortJaarlijkseIndexering: v =>
    v.jaarlijkseIndexatieVan ? mapJaarlijkseIndexatieVan[v.jaarlijkseIndexatieVan] : null,
  uitkeringGedeeltelijkAo: v =>
    v.uitkeringGedeeltelijkAo ? mapUitkeringsWijzeRubriekA(v.uitkeringGedeeltelijkAo) : null,
  aoUitkering: v => mapSpecificatieAoUitkeringModalUi2Dl(v.uitkeringGedeeltelijkAo)(v.uitkeringGedeeltelijkeAoModal),
  verzekerdBedrag: v => v.verzekerdBedrag
});

const mapDekkinggegevensRubriekB = createMapToDl(dekkingVerzekerdeRubriekBSchema).to<AovDekkingRubriekB>({
  beoordelingscriterium: v => v.beoordelingscriterium,
  eindleeftijd: v => v.eindLeeftijd,
  verzekerdBedrag: v => v.verzekerdBedrag
});

const mapDekkingUi2Dl = createMapToDl(dekkingGegevensVerzekerdeSchema)
  .with<{ productkenmerken: AovKenmerken | null }>()
  .to<AovDekking>({
    beoordelingscriterium: v => v.beoordelingscriterium,
    dekkingAo: (v, productkenmerk) =>
      v.ao &&
      v.dekking &&
      [
        AovVerzekerdeDekkingenOptions.Ao,
        AovVerzekerdeDekkingenOptions.AoEa,
        AovVerzekerdeDekkingenOptions.AoWw,
        AovVerzekerdeDekkingenOptions.AoWwEa
      ].includes(v.dekking)
        ? mapDekkingGegevensAo(productkenmerk)(v.ao)
        : null,
    dekkingEa: v =>
      v.ea &&
      v.dekking &&
      [
        AovVerzekerdeDekkingenOptions.Ea,
        AovVerzekerdeDekkingenOptions.AoWwEa,
        AovVerzekerdeDekkingenOptions.AoEa
      ].includes(v.dekking)
        ? mapDekkingGegevensAe(v.ea)
        : null,
    dekkingWw: v =>
      v.ww &&
      v.dekking &&
      [
        AovVerzekerdeDekkingenOptions.AoWw,
        AovVerzekerdeDekkingenOptions.AoWwEa,
        AovVerzekerdeDekkingenOptions.Ww
      ].includes(v.dekking)
        ? mapDekkingGegevensWw(v.ww)
        : null,
    keuzeDekking: v => v.keuzeDekking,
    percentageBovenMaxDagloon: v => v.percentage,
    rubriekA: v => (v.rubriekA ? mapDekkinggegevensRubriekA(v.rubriekA) : null),
    rubriekB: v => (v.rubriekB ? mapDekkinggegevensRubriekB(v.rubriekB) : null),
    uitgebreid: v => v.uitgebreid,
    vastBedrag: v => v.vastBedrag,
    verzekerdeDekkingen: v => (v.ao ? v.dekking : null),
    voornaamsteInkomstenbron: v => v.voornaamsteInkomstenbron
  });

const mapPremieBetalingsTermijn = createMapEnum(PremieBetalingstermijn).to({
  Maand: BetalingsTermijnType.Maand,
  TweeMaanden: BetalingsTermijnType.TweeMaanden,
  Kwartaal: BetalingsTermijnType.Kwartaal,
  HalfJaar: BetalingsTermijnType.HalfJaar,
  Jaar: BetalingsTermijnType.Jaar,
  Eenmalig: BetalingsTermijnType.Eenmalig
});

const mapPremieUi2Dl = createMapToDl(aovSchema).to<AovPremie>({
  betalingMiddels: v => v.premiegegevens.betalingMiddels,
  betalingstermijn: v => mapPremieBetalingsTermijn(v.premiegegevens.betalingsTermijn),
  collectief: v => v.premiegegevens.collectief,
  jaarlijksIndexatiePercentage: v => v.premiegegevens.jaarlijksIndexatiePercentage,
  koopsomAftrekbaarBedrag: v => v.premiegegevens.koopsomAO,
  koopsomBedrag: v => v.premiegegevens.koopsomBedrag,
  koopsomperiodeInMaanden: v => v.premiegegevens.koopsomPeriodeInMaanden,
  premieAftrekbaar: v => v.premiegegevens.premieAftrekbaarAO,
  premieAftrekbaarBedrag: v => v.premiegegevens.premieAftrekbaarBedrag,
  premieBedrag: v => v.premiegegevens.premie,
  premieBedragAo: v => v.premiegegevens.premieAo,
  premieBedragTop: v => v.premiegegevens.premieTop,
  premieBedragWw: v => v.premiegegevens.premieWw,
  premieBedragEa: () => null // TODO: behoeft correcte implementatie, dit is een nswag update quickfix
});

function mapBedragScenarioUi2Dl(scenarios: AovScenarioCardInputType[]): BedragScenario {
  const indexeringen: BedragIndexering[] = [];
  let valueTemp: number | null = null;
  let volgnummer = 1;
  scenarios.forEach(x => {
    /* istanbul ignore else */

    if (x.bedrag !== valueTemp && x.jaartal) {
      indexeringen.push({
        volgnummer: volgnummer,
        ingangsmaand: (x.jaartal - LocalDate.now().year()) * 12,
        bedrag: x.bedrag
      });
      valueTemp = x.bedrag;
      volgnummer++;
    }
  });

  return {
    waarde: scenarios.length > 0 ? scenarios[0].bedrag : /* istanbul ignore next */ null,
    omschrijving: "",
    indexeringen: indexeringen
  };
}

const mapAanvraagUi2Dl = createMapToDl(aovSchema).to<AovAanvraag>({
  financieleProblemenWerkgeverAanvrager1: v => v.aanvullendeVragen.financieleProblemenWerkgeverAanvrager1,
  financieleProblemenWerkgeverAanvrager2: v => v.aanvullendeVragen.financieleProblemenWerkgeverAanvrager2,
  indexatieTijdensUitkering: v => v.dekking.indexatieTijdensUitkering,
  maandinkomen: v => v.dekking.maandinkomen.bedrag ?? v.dekking.maandinkomen.berekendBedrag,
  nettoMaandinkomen: v => v.dekking.nettoMaandinkomen,
  maandinkomenGegarandeerd: v => v.dekking.maandinkomenGegarandeerd,
  maandinkomenOvernemen: v => v.dekking.maandinkomen.berekenen,
  reorganisatieWerkgeverAanvrager1: v => v.aanvullendeVragen.reorganisatieWerkgeverAanvrager1,
  reorganisatieWerkgeverAanvrager2: v => v.aanvullendeVragen.reorganisatieWerkgeverAanvrager2,
  strafrechtelijkVerledenAanvrager1: v => v.aanvullendeVragen.strafrechtelijkVerledenAanvrager1,
  strafrechtelijkVerledenAanvrager2: v => v.aanvullendeVragen.strafrechtelijkVerledenAanvrager2,
  toelichtingGelezenAkkoordMetSlotverklaring: v => v.aanvullendeVragen.toelichtingGelezenAkkoordMetSlotverklaring,
  topVerzekering: () => null,
  verwachtingEindeDienstverband: () => null,
  verwachtingOntslagAanvrager1: v => v.aanvullendeVragen.verwachtingOntslagAanvrager1,
  verwachtingOntslagAanvrager2: v => v.aanvullendeVragen.verwachtingOntslagAanvrager2,
  verwachtingWerkomgeving: () => null,
  verzekerdBedragScenario: v => mapBedragScenarioUi2Dl(v.dekking.verzekerdBedragVerzekerdBedragModalValues.scenario)
}); // TODO: mapping afmaken

const mapVerpanding = createMapToDl(aflossingSchema).to<Verpanding>({
  bedoeldVoorAflossing: v => v.bedoeldVoorAflossing,
  aflosproducten: v => mapAflosProductenUiToDl(v.bedoeldVoorAflossingSpecificatie.aflosproducten),
  verpandAanGeldverstrekker: v => v.verpandAanGeldverstrekker
});

export const mapProductUi2Dl = createMapToDl(aovSchema)
  .with<{ aanvrager1: KlantnaamType | null; aanvrager2: KlantnaamType | null; productkenmerken: AovKenmerken | null }>()
  .to<AovProduct>({
    aanvraag: v => mapAanvraagUi2Dl(v),
    dekkingVerzekerde1: (v, c) =>
      mapDekkingUi2Dl({ productkenmerken: c.productkenmerken })(v.dekking.dekkingGegevensVerzekerde1),
    dekkingVerzekerde2: (v, c) =>
      v.dekking.dekkingGegevensVerzekerde2
        ? mapDekkingUi2Dl({ productkenmerken: c.productkenmerken })(v.dekking.dekkingGegevensVerzekerde2)
        : null,
    polis: v => mapPolisUi2Dl({ partijCode: v.partijCode, productCode: v.productCode })(v.product),
    premie: v => mapPremieUi2Dl(v),
    productId: v => v.productId,
    soortProduct: v => v.soortProduct,
    verpanding: v => mapVerpanding(v.verpanding),
    verzekerdeKlantIds: (v, { aanvrager1, aanvrager2 }) =>
      mapAanvragers(v.verzekerden.verzekerden, aanvrager1, aanvrager2),
    wijzigingenInDoorlopendeProductenOvernemen: v => v.product.wijzigingenInDoorlopendProductOvernemen ?? true,
    doorlopend: v => v.product.doorlopend,
    beroep: v => (v.dekking.dekkingGegevensVerzekerde1.ao ? v.dekking.dekkingGegevensVerzekerde1.ao.beroepnaam : ""), // TODO: Verder uitwerken
    einddatumIsAowLeeftijd: () => false, // TODO: Verder uitwerken
    risicoklasseberoep: v =>
      v.dekking.dekkingGegevensVerzekerde1.ao ? v.dekking.dekkingGegevensVerzekerde1.ao.risicoklasse : "CLASS_2", // TODO: Verder uitwerken
    verzekerdeKredietsom: v => v.product.verzekerdeKredietsom,
    meenemen: _ => false // TODO: CONTROLEREN!!
  });

export type AovProductkenmerken = {
  getProductkenmerken: (partijCode: string, productCode: string) => AovKenmerken | null;
};

export const mapAovUiToDl = createMapToDl(aovsSchema)
  .with<AovProductkenmerken>()
  .to<AovDlEntry>({
    producten: (v, c) =>
      v.producten.map(
        (productUi): AovProduct => {
          const productkenmerken = c.getProductkenmerken(productUi.partijCode, productUi.productCode);
          return mapProductUi2Dl({
            aanvrager1: v.aanvrager1,
            aanvrager2: v.aanvrager2,
            productkenmerken: productkenmerken
          })(productUi);
        }
      ),
    aanvrager1: v => mapKlantnaamUi2Dl(v.aanvrager1),
    aanvrager2: v => mapKlantnaamUi2Dl(v.aanvrager2),
    ingangsdatumVoorstel: v => mapLocalDateToString(v.ingangsdatumVoorstel)
  });

export type AovUiToDlMapper = (source: AovsType, index?: number | undefined) => AovDlEntry;
