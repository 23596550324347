import {
  MedewerkerKlantdossierRechtenOrganisatie,
  MedewerkerKlantdossierRechtenVestigingen
} from "../../.generated/licenties/licentiestypes";

export const lezenEnSchrijvenRechten = (
  vestigingId: string,
  lezenEnSchrijvenRechtenVestigingen: MedewerkerKlantdossierRechtenVestigingen | null,
  lezenEnSchrijvenRechtenOrganisatie: MedewerkerKlantdossierRechtenOrganisatie | null,
  gebruikersVestigingIds?: string[],
  organisatieVestigingIds?: string[]
): string | null => {
  // In werk-item #71304 staan de uitgangspunten waar dit op gebaseerd is
  const isGebruikersvestiging = gebruikersVestigingIds?.includes(vestigingId);
  const isOrganisatievestiging = organisatieVestigingIds?.includes(vestigingId);

  // Rechten bepalen voor huidige vestingen toegewezen aan gebruiker
  if (isGebruikersvestiging) {
    return lezenEnSchrijvenRechtenVestigingen;
  }

  if (!isOrganisatievestiging) return "Geen";

  // Rechten bepalen voor overige vestigingen die bij huidige organisatie horen maar niet toegewezen aan gebruiker
  return lezenEnSchrijvenRechtenOrganisatie;
};
