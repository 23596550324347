import { Card, CardWrapper, PageLoading, FormFirstFocus, PlatformFoutenSamenvatting, TooltipWrap, insightsReactPlugin } from "adviesbox-shared";
import { Form, FormikProps } from "formik";
import React, { ReactElement, useContext, useRef } from "react";
import { Button } from "react-bootstrap";
import { AppDataContext } from "../navigation/appdata-context";
import { DevDebug } from "../shared/components/dev-debug/dev-debug";
import { ISWSideEffects } from "../shared/components/isw-side-effects/isw-side-effects";
import { SaveButton } from "../shared/components/save-button/save-button";
import { WithSaveData } from "../shared/utils/save-data";
import { withAdviesboxFormik } from "../shared/utils/with-adviesbox-formik";
import Aanvrager1AOW from "./aanvrager1-aow/aanvrager1-aow";
import Aanvrager1Fiscaliteit from "./aanvrager1-fiscaliteit/aanvrager1-fiscaliteit";
import Aanvrager1Inkomen from "./aanvrager1-inkomen/aanvrager1-inkomen";
import Aanvrager1Sociaal from "./aanvrager1-sociaal/aanvrager1-sociaal";
import Aanvrager2AOW from "./aanvrager2-aow/aanvrager2-aow";
import Aanvrager2Fiscaliteit from "./aanvrager2-fiscaliteit/aanvrager2-fiscaliteit";
import Aanvrager2Inkomen from "./aanvrager2-inkomen/aanvrager2-inkomen";
import Aanvrager2Sociaal from "./aanvrager2-sociaal/aanvrager2-sociaal";
import FiscaleVerdeling from "./fiscale-verdeling/fiscale-verdeling";
import {
  determineInkomenEnFiscusAsyncCondition,
  determineInkomenEnFiscusSideEffects
} from "./infra/determine-inkomen-en-fiscus-side-effects";
import { getInkomenEnFiscusTextResources } from "./infra/inkomen-en-fiscus-resources";
import { InkomenEnFiscusProps, inkomenEnFiscusSchema, InkomenEnFiscusState } from "./infra/inkomen-en-fiscus-schema";
import { withAITracking } from "@microsoft/applicationinsights-react-js";

const InkomenEnFiscus = (props: FormikProps<InkomenEnFiscusState> & InkomenEnFiscusProps): ReactElement => {
  const {
    values: { hasAanvrager2, aanvrager1Inkomen, aanvrager2Inkomen },
    isSubmitting
  } = props;

  const aanvrager1InkomenRef = [useRef(null), useRef(null), useRef(null)];
  const aanvrager2InkomenRef = [useRef(null), useRef(null), useRef(null)];

  const aanvrager1AowRef = [useRef(null), useRef(null), useRef(null)];
  const aanvrager2AowRef = [useRef(null), useRef(null), useRef(null)];

  const aanvrager1SociaalRef = [useRef(null), useRef(null)];
  const aanvrager2SociaalRef = [useRef(null), useRef(null)];

  const aanvrager1FiscaliteitRef = useRef(null);
  const aanvrager2FiscaliteitRef = useRef(null);

  const showAanvrager1Sociaal = aanvrager1Inkomen.brutoSalarisUitDienstverbandIsEnabled;
  const showAanvrager2Sociaal = aanvrager2Inkomen.brutoSalarisUitDienstverbandIsEnabled;
  const { setSErunning } = useContext(AppDataContext);

  return (
    <FormFirstFocus>
      <Form>
        {isSubmitting && <PageLoading />}
        <ISWSideEffects<InkomenEnFiscusState>
          sync={determineInkomenEnFiscusSideEffects}
          async={determineInkomenEnFiscusAsyncCondition}
          asyncStartStopCallback={setSErunning}
        />
        <CardWrapper className="px-3">
          <div className="text-container">
            <h2>Inkomen &amp; Fiscus</h2>
            <div className="save-btn-position">
              <div className="button-container">
                <SaveButton context={props} />
              </div>
            </div>
          </div>
        </CardWrapper>

        <PlatformFoutenSamenvatting />
        <div className="d-flex flex-wrap flex-row mb-5 pb-5">
          <CardWrapper flexType="flex-column" className="pl-3">
            <Card title="Inkomen aanvrager 1">
              <Aanvrager1Inkomen sourceRef={aanvrager1InkomenRef} targetRef={aanvrager2InkomenRef} />
            </Card>
            <Card title="AOW aanvrager 1">
              <Aanvrager1AOW sourceRef={aanvrager1AowRef} targetRef={aanvrager2AowRef} />
            </Card>
            <Card title="Fiscaliteit aanvrager 1">
              <Aanvrager1Fiscaliteit sourceRef={aanvrager1FiscaliteitRef} targetRef={aanvrager2FiscaliteitRef} />
            </Card>
            {showAanvrager1Sociaal && (
              <Card title="Sociaal aanvrager 1">
                <Aanvrager1Sociaal sourceRef={aanvrager1SociaalRef} targetRef={aanvrager2SociaalRef} />
              </Card>
            )}
            {hasAanvrager2 && (
              <Card
                title="Fiscale verdeling"
                titleAppend={
                 <TooltipWrap placement="right-start" iconType="questionmark" name="super" warningText={ getInkomenEnFiscusTextResources("TooltipInkomenEnFiscusFiscaleVerdeling")}>
                          <Button className="question-tip" id={`fiscale-verdeling-tooltip-button`} />
                </TooltipWrap>
                }
              >
                <FiscaleVerdeling />
              </Card>
            )}
          </CardWrapper>
          <CardWrapper flexType="flex-column" className="pr-3 p-left">
            {hasAanvrager2 && (
              <Card title="Inkomen aanvrager 2">
                <Aanvrager2Inkomen sourceRef={aanvrager2InkomenRef} targetRef={aanvrager1InkomenRef} />
              </Card>
            )}
            {hasAanvrager2 && (
              <Card title="AOW aanvrager 2">
                <Aanvrager2AOW sourceRef={aanvrager2AowRef} targetRef={aanvrager1AowRef} />
              </Card>
            )}
            {hasAanvrager2 && (
              <Card title="Fiscaliteit aanvrager 2">
                <Aanvrager2Fiscaliteit sourceRef={aanvrager2FiscaliteitRef} targetRef={aanvrager1FiscaliteitRef} />
              </Card>
            )}
            {showAanvrager2Sociaal && hasAanvrager2 && (
              <Card title="Sociaal aanvrager 2">
                <Aanvrager2Sociaal sourceRef={aanvrager2SociaalRef} targetRef={aanvrager1SociaalRef} />
              </Card>
            )}
          </CardWrapper>
        </div>

        <DevDebug />
      </Form>
    </FormFirstFocus>
  );
};

InkomenEnFiscus.displayName = "InkomenEnFiscus";

export default withAdviesboxFormik<InkomenEnFiscusProps & WithSaveData<InkomenEnFiscusState>, InkomenEnFiscusState>({
  mapPropsToValues: (e: InkomenEnFiscusProps): InkomenEnFiscusState => e,
  validationSchema: inkomenEnFiscusSchema
})(withAITracking(insightsReactPlugin, InkomenEnFiscus));
