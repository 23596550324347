import { KlantnaamType } from "../../shared/generic-parts/klantnaam/schema";
import { LocalDate, ChronoUnit } from "@js-joda/core";
import { BetalingsTermijnType } from "../../.generated/forms/formstypes";
import { assertNever } from "../../shared/utils/helpers";
import { LabelValuePair } from "../../shared/types";
import { overigePostSchema } from "./overige-posten-schema";
import { OverigePostType } from "./overige-posten-types";
import { SituatieSoort } from "../../producten-overzicht/infra/producten-overzicht-types";

export const bepaalAanvragerLabelValuePairs = (
  aanvrager1: KlantnaamType | null,
  aanvrager2: KlantnaamType | null
): LabelValuePair[] => {
  const aanvragers: LabelValuePair[] = [];
  if (aanvrager1) {
    aanvragers.push({
      label: "Aanvrager",
      value: aanvrager1.klantId
    });
  }
  if (aanvrager2?.klantId) {
    aanvragers.push({
      label: "Partner",
      value: aanvrager2.klantId
    });
  }
  return aanvragers;
};

export const bepaalAanvrager = (
  klantId: string,
  aanvrager1: KlantnaamType | null,
  aanvrager2: KlantnaamType | null
): KlantnaamType => {
  if (aanvrager1 && aanvrager1.klantId === klantId) {
    return aanvrager1;
  }
  if (aanvrager2 && aanvrager2.klantId === klantId) {
    return aanvrager2;
  }

  throw new Error(`Onbekende aanvrager met ID: ${klantId}`);
};

export const bepaalEindleeftijd = (
  overgangOpPartnerEinddatum: LocalDate | null,
  klantId: string,
  aanvrager1: KlantnaamType | null,
  aanvrager2: KlantnaamType | null
): number | null => {
  const heffingBijAanvrager = bepaalAanvrager(klantId, aanvrager1, aanvrager2);
  const { geboortedatum } = heffingBijAanvrager;

  return overgangOpPartnerEinddatum ? geboortedatum.until(overgangOpPartnerEinddatum, ChronoUnit.YEARS) : null;
};

export const getBetalingsTermijnLabel = (termijn: BetalingsTermijnType): string => {
  switch (termijn) {
    case BetalingsTermijnType.Geen:
      return "geen";
    case BetalingsTermijnType.Maand:
      return "per maand";
    case BetalingsTermijnType.TweeMaanden:
      return "per twee maanden";
    case BetalingsTermijnType.Kwartaal:
      return "per kwartaal";
    case BetalingsTermijnType.HalfJaar:
      return "per half jaar";
    case BetalingsTermijnType.Jaar:
      return "per jaar";
    case BetalingsTermijnType.Eenmalig:
      return "eenmalig";
    case BetalingsTermijnType.Week:
      return "per week";
    case BetalingsTermijnType.Dag:
      return "per dag";
    default:
      return assertNever(termijn);
  }
};

export const getNewRowValues = (
  aanvrager1: KlantnaamType | null,
  ingangsdatumVoorstel: LocalDate | null,
  situatie: SituatieSoort
): OverigePostType => {
  const newRow = overigePostSchema.default();
  const { heffing, product } = newRow;

  product.ingangsdatum =
    ingangsdatumVoorstel ??
    LocalDate.now()
      .plusMonths(1)
      .withDayOfMonth(1);
  product.looptijd.jaren = null;
  product.looptijd.maanden = null;
  product.uwBemiddeling = true;
  product.wijzigingenInDoorlopendProductOvernemen = null;

  newRow.product = product;
  if (aanvrager1) {
    heffing.heffingBij = aanvrager1.klantId;
  }
  return newRow;
};
