import React, { ReactElement } from "react";
import * as yup from "yup";
import { huidigProductKenmerkSchema, HuidigProductKenmerkType } from "../infra/vergoedingen-overzicht-schema";
import { DataGrid, createSpanWithId } from "adviesbox-shared";
import { bedragFormat } from "../../shared/utils/currency";
import { Column } from "react-table-6";
import { maandenNaarJaarMaand } from "../../shared/generic-parts/jaar-maand/helpers";
import { getFormattedDate } from "../../shared/utils/dates";
import AdviesBoxFormik from "../../shared/utils/adviesbox-formik";
import Modal from "../../shared/components/modal/Modal";

type HuidigeProductenModalProps = {
  closeModal?: () => void;
};

type HuidigeProductenModalType = { huidigeProducten: HuidigProductKenmerkType[] };

export type HuidigeProductenModalData = {
  data: HuidigProductKenmerkType[];
};

const huidigeProductenColumns: Column[] = [
  {
    Header: "Product",
    id: "productnaam",
    Cell: (c): ReactElement => createSpanWithId(c.index, 0, c.original.productnaam, c.original.productnaam)
  },
  {
    Header: "Uw bemiddeling",
    id: "uwBemiddeling",
    Cell: (c): ReactElement => createSpanWithId(c.index, 1, c.original.uwBemiddeling ? "Ja" : "Nee")
  },
  {
    Header: "Ingangsdatum",
    id: "ingangsDatum",
    Cell: (c): ReactElement => createSpanWithId(c.index, 2, getFormattedDate(c.original.ingangsDatum))
  },
  {
    Header: "Looptijd",
    id: "looptijd",
    Cell: (c): ReactElement => createSpanWithId(c.index, 3, maandenNaarJaarMaand(c.original.looptijd))
  },
  {
    Header: "Hoofdsom/opbouw",
    id: "hoofdsom",
    Cell: (c): ReactElement => createSpanWithId(c.index, 4, bedragFormat(c.original.hoofdsom))
  },
  {
    Header: "Dekking 1",
    id: "dekking1",
    Cell: (c): ReactElement =>
      createSpanWithId(c.index, 5, c.original.dekking1 ? bedragFormat(c.original.dekking1) : "")
  },
  {
    Header: "Dekking 2",
    id: "dekking2",
    Cell: (c): ReactElement =>
      createSpanWithId(c.index, 6, c.original.dekking2 ? bedragFormat(c.original.dekking2) : "")
  },
  {
    Header: "Premie/rente p.m.",
    id: "premie",
    Cell: (c): ReactElement =>
      createSpanWithId(
        c.index,
        7,
        `${c.original.premie ? bedragFormat(c.original.premie) : ""}${
          c.original.rentePercentage ? ` (${c.original.rentePercentage}%)` : ""
        }`
      )
  },
  {
    Header: "RVP",
    id: "rvp",
    Cell: (c): ReactElement =>
      createSpanWithId(
        c.index,
        8,
        `${c.original.rvp ? maandenNaarJaarMaand(c.original.rvp as number) : ""}${
          c.original.rbt ? ` (RBT ${c.original.rbt} mnd)` : ""
        }`
      )
  }
];

const HuidigeProductenModal = ({
  data,
  closeModal
}: HuidigeProductenModalData & HuidigeProductenModalProps): ReactElement => {
  const body = (): ReactElement => {
    return (
      <>
        <DataGrid columns={huidigeProductenColumns} name="huidigeProducten" className="readonly" />
      </>
    );
  };
  return (
    <AdviesBoxFormik<HuidigeProductenModalType>
      initialValues={{ huidigeProducten: data }}
      validationSchema={yup.object({
        huidigeProducten: yup.array(huidigProductKenmerkSchema)
      })}
      render={(): ReactElement => {
        return (
          <>
            <Modal title="Huidige producten" body={body()} noEditableData={true} onCancelClick={closeModal} />
          </>
        );
      }}
    />
  );
};

export default HuidigeProductenModal;
