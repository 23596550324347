import React, { ReactElement } from "react";
import { connect, FormikContextType } from "formik";
import { LabeledSelectInput, LabeledBevestigingInput } from "adviesbox-shared";
import { Financieringsoort } from "../../.generated/forms/formstypes";
import { Direction } from "../../shared/types";
import { getFinancieringsbehoefteTextResources } from "../infra/financieringsbehoefte-resources";
import LabeledHdnKeuzelijst from "../../shared/components/hdn-keuzelijst/LabeledHdnKeuzelijst";
import { FinancieringsbehoefteState } from "../infra/financieringsbehoefte-types";

type FinancieringProps = {
  readonly?: boolean;
};

const soortFinancieringValues = [
  {
    value: Financieringsoort.Oversluiten,
    label: "Oversluiten"
  },
  {
    value: Financieringsoort.Heropname,
    label: "Onderhandse verhoging"
  },
  {
    value: Financieringsoort._1eHypotheek,
    label: "1e hypotheek"
  },
  {
    value: Financieringsoort._2eHypotheek,
    label: "2e hypotheek"
  },
  {
    value: Financieringsoort._3eHypotheek,
    label: "3e hypotheek"
  },
  {
    value: Financieringsoort._4eHypotheek,
    label: "4e hypotheek"
  },
  {
    value: Financieringsoort.Omzetting,
    label: "Omzetting leningdeel"
  }
];

const bestaandeBouwEnNieuwbouw = [
  {
    value: Financieringsoort.AankoopBestaandeBouw,
    label: "Aankoop bestaande bouw"
  },
  {
    value: Financieringsoort.AankoopNieuwbouw,
    label: "Aankoop nieuwbouw"
  }
];

const Financiering = ({
  readonly = false,
  formik: {
    values: {
      financiering: { soortFinanciering, soortNieuwbouw }
    }
  }
}: FinancieringProps & {
  formik: FormikContextType<FinancieringsbehoefteState>;
}): ReactElement => {
  const isNieuwbouwOrBestaandebouw =
    soortFinanciering === Financieringsoort.AankoopNieuwbouw ||
    soortFinanciering === Financieringsoort.AankoopBestaandeBouw;
  const isBestaandeBouw = soortFinanciering === Financieringsoort.AankoopBestaandeBouw;
  const isNieuwbouw = soortFinanciering === Financieringsoort.AankoopNieuwbouw;
  const isZelfBouw = soortNieuwbouw === "02";
  return (
    <>
      {(isNieuwbouwOrBestaandebouw && (
        <LabeledSelectInput
          caption="Soort financiering"
          name="financiering.soortFinanciering"
          options={bestaandeBouwEnNieuwbouw}
          readonly
        />
      )) || (
        <LabeledSelectInput
          caption="Soort financiering"
          name="financiering.soortFinanciering"
          options={soortFinancieringValues}
          readonly={readonly}
        />
      )}

      {isBestaandeBouw && (
        <LabeledBevestigingInput
          layout={Direction.Vertical}
          caption="Kosten eigendomsoverdracht"
          name="financiering.kostenKoper"
          labels={{ true: "Kosten koper", false: "Vrij op naam" }}
          readonly={readonly}
        />
      )}
      {isNieuwbouw && (
        <>
          <LabeledHdnKeuzelijst
            caption="Soort nieuwbouw"
            name="financiering.soortNieuwbouw"
            keuzelijst="SoortNieuwbouwType"
            berichtSoortType="AX"
            tooltip={getFinancieringsbehoefteTextResources("TooltipSoortNieuwbouw")}
            readonly={readonly}
          />
          {isZelfBouw && (
            <LabeledHdnKeuzelijst
              caption="Soort zelfbouw"
              name="financiering.soortZelfbouw"
              keuzelijst="SoortZelfbouwType"
              berichtSoortType="AX"
              readonly={readonly}
            />
          )}
        </>
      )}
    </>
  );
};

export default connect<FinancieringProps, FinancieringsbehoefteState>(Financiering);
