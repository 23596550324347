import { LocalDate } from "@js-joda/core";
import { AovVerzekerdeDekkingenOptions } from "../.generated/forms/formstypes";
import { EenVanDeKenmerken } from "../producten-overzicht/infra/product-kenmerken-types";
import { ProductSelectieType, SituatieSoort } from "../producten-overzicht/infra/producten-overzicht-types";
import { aoIndexeringenSchema, AoUitkeringswijze } from "../producten-overzicht/infra/specificatie-ao-uitkering-schema";
import { aovSchema, AovsProps, AovsSchemaContextType, AovsType, AovType } from "./infra/aov-schema";
import {
  getDekkingGegevensVerzekerDefaults,
  getPremiegegevensDefaults,
  getProductDefaults,
  getProductSoortDefaults
} from "./infra/defaults";
import { mapPartijProduct } from "./infra/map-aov-dl-to-ui";

export const newAovObjectFactory = (
  productSelectie: ProductSelectieType,
  situatie: SituatieSoort,
  ingangsdatumVoorstel: LocalDate | null,
  values: AovsType,
  kenmerken: EenVanDeKenmerken | null | undefined
): AovType => {
  const nieuweAov = aovSchema.default();
  const partijProductCode = mapPartijProduct(productSelectie.codes.maatschappijCode, productSelectie.codes.productCode);
  nieuweAov.product = getProductDefaults({ ingangsdatumVoorstel, situatie });
  nieuweAov.dekking.dekkingGegevensVerzekerde1 = getDekkingGegevensVerzekerDefaults(partijProductCode, situatie);
  nieuweAov.premiegegevens = getPremiegegevensDefaults(partijProductCode);
  nieuweAov.verpanding.bedoeldVoorAflossingSpecificatie.aflosproducten = values.aflosProductNew;

  const wgaDefaults = [
    {
      ...aoIndexeringenSchema.default(),
      ondergrensPercentage: 0,
      bovengrensPercentage: 35,
      uitkeringPercentage: "0%"
    },
    {
      ...aoIndexeringenSchema.default(),
      ondergrensPercentage: 35,
      bovengrensPercentage: 80,
      uitkeringPercentage: "70%"
    },
    {
      ...aoIndexeringenSchema.default(),
      ondergrensPercentage: 80,
      bovengrensPercentage: 100,
      uitkeringPercentage: "0%"
    }
  ];

  if (
    productSelectie.codes.maatschappijCode === "XX" &&
    productSelectie.codes.productCode === "02" &&
    nieuweAov.dekking.dekkingGegevensVerzekerde1.ao
  ) {
    nieuweAov.dekking.dekkingGegevensVerzekerde1.ao.uitkeringGedeeltelijkeAoModal.indexeringen = wgaDefaults;
    nieuweAov.dekking.dekkingGegevensVerzekerde1.ao.uitkeringGedeeltelijkAo = AoUitkeringswijze.ProRata;
  }

  if (kenmerken && "validaties" in kenmerken && kenmerken.validaties.datum1evdMaand) {
    nieuweAov.product.ingangsdatum =
      (nieuweAov.product.ingangsdatum || values.ingangsdatumVoorstel)?.withDayOfMonth(1) ?? null;
    nieuweAov.product.einddatum =
      (nieuweAov.product.einddatum || values.ingangsdatumVoorstel?.plusYears(30))?.withDayOfMonth(1) ?? null;
  }

  if (
    kenmerken &&
    "dekking" in kenmerken &&
    "verzekerdeDekkingTonen" in kenmerken.dekking &&
    kenmerken.dekking.verzekerdeDekkingTonen
  ) {
    nieuweAov.dekking.dekkingGegevensVerzekerde1 = {
      ...nieuweAov.dekking.dekkingGegevensVerzekerde1,
      dekking: AovVerzekerdeDekkingenOptions.Ao
    };
  }

  if (productSelectie.codes.maatschappijCode === "CA" && productSelectie.codes.productCode === "03") {
    nieuweAov.verpanding.bedoeldVoorAflossing = true;
  }

  if (values.aanvrager1) {
    const inkomenRounded = values.aanvrager1.indicatiefMaandInkomen
      ? Math.round(values.aanvrager1.indicatiefMaandInkomen)
      : /* istanbul ignore next */ null;
    nieuweAov.dekking.jaarinkomenIndicatief = values.aanvrager1.indicatiefJaarInkomen
      ? Math.round(values.aanvrager1.indicatiefJaarInkomen)
      : null;
    nieuweAov.dekking.maandinkomen.berekendBedrag = inkomenRounded;
    nieuweAov.dekking.brutoJaarinkomen = values.aanvrager1.brutoJaarinkomen;
  }
  nieuweAov.soortProduct = getProductSoortDefaults(partijProductCode);
  nieuweAov.dekking.marktwaarde = values.marktwaardeBedrag;
  nieuweAov.dekking.hypotheekbedrag = values.hypotheekbedrag;

  if (productSelectie.codes.maatschappijCode === "GID" && productSelectie.codes.productCode === "03") {
    nieuweAov.dekking.verzekerdeKredietsom = Math.max(2500,Math.min((values.hypotheekbedrag || 0) * 0.1,75000));
  }
  return nieuweAov;
};

export const createFormValidationContext = (props: AovsProps, values: AovsType): AovsSchemaContextType => ({
  values
});
