import { LabeledBevestigingInput, LabeledCurrencyInput, LabeledSelectInput, LabeledTextInput } from "adviesbox-shared";
import { connect, FormikContextType, useFormikContext } from "formik";
import React, { ReactElement } from "react";
import { TaxatieToekomstigGebruikOptions } from "../../.generated/forms/formstypes";
import { AdresInput } from "../../shared/components/adres/adres-input";
import { TaxatieKenmerkenType, TaxatieSchermType } from "../infra/taxatie-scherm-schema";
import { gebruikMomenteelOpties, gebruikToekomstOpties, opdrachtgeverOpties } from "./object-taxatie-helper";

const ObjectTaxatie = ({
  formik: {
    values: { taxatieKenmerken, taxatieObject, taxatieAlgemeen }
  }
}: {
  formik: FormikContextType<TaxatieSchermType>;
}): ReactElement => {
  const context = useFormikContext<TaxatieSchermType>();
  const kenmerken = taxatieKenmerken as TaxatieKenmerkenType;
  const nieuwbouwTonen = kenmerken?.NieuwbouwTonen;
  const bouwplanTonen = kenmerken?.BouwplanTonen && taxatieObject?.nieuwbouw === true;
  const bouwnummerTonen = kenmerken?.BouwnummerTonen && taxatieObject?.nieuwbouw === true;
  const aankoopprijsTonen = kenmerken?.AankoopprijsTonen;
  const huurovereenkomstTonen = kenmerken?.HuurovereenkomstTonen;
  const opdrachtgeverTonen = kenmerken?.OpdrachtgeverRolTonen;
  const gebruikMomenteelTonen = kenmerken?.GebruikTonen;
  const gebruikToekomstTonen = kenmerken?.GebruikToekomstTonen;

  return (
    <>
      {gebruikMomenteelTonen && (
        <LabeledSelectInput
          caption="Gebruik op dit moment"
          name="taxatieObject.gebruikMomenteel"
          options={gebruikMomenteelOpties}
        />
      )}
      {gebruikToekomstTonen && (
        <LabeledSelectInput
          caption="Toekomstig gebruik"
          name="taxatieObject.gebruikToekomst"
          options={gebruikToekomstOpties}
        />
      )}
      {gebruikToekomstTonen &&
        context.values.taxatieObject?.gebruikToekomst === TaxatieToekomstigGebruikOptions.Anders && (
          <LabeledTextInput
            caption="Toelichting toekomstig gebruik 'Anders'"
            name="taxatieObject.gebruikToekomstAnders"
          />
        )}
      <>
        <AdresInput name="taxatieObject.adresPand" metLand={false} plaatsCaption="Woonplaats" />
        {(!taxatieObject?.adresPand?.postcode || !taxatieObject?.adresPand?.huisnummer) &&
          (taxatieAlgemeen?.taxateurWaarschuwingTonen || taxatieAlgemeen?.geldverstrekkerWaarschuwingTonen) && (
            <div className="row justify-content-end">
              <div className="foutmelding col-6">{"Vul het adres in."}</div>
            </div>
          )}
      </>

      {nieuwbouwTonen && (
        <LabeledBevestigingInput caption="Nieuwbouw (nog geen postcode bekend)" name="taxatieObject.nieuwbouw" />
      )}
      {bouwplanTonen && <LabeledTextInput caption="Bouwplan" name="taxatieObject.bouwplan" />}
      {bouwnummerTonen && <LabeledTextInput caption="Bouwnummer" name="taxatieObject.bouwnummer" />}

      {aankoopprijsTonen && <LabeledCurrencyInput caption="Aankoopprijs" name="taxatieObject.aankoopprijsBedrag" />}
    
      {huurovereenkomstTonen && (
        <LabeledBevestigingInput caption="Huurovereenkomst" name="taxatieObject.huurovereenkomst" />
      )}
      {opdrachtgeverTonen && (
        <LabeledSelectInput
          caption="Opdrachtgever is"
          name="taxatieObject.opdrachtgever"
          options={opdrachtgeverOpties}
        />
      )}
    </>
  );
};

export default connect<{}, TaxatieSchermType>(ObjectTaxatie);
