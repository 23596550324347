import React, { ReactElement, useContext } from "react";
import { FormikProps } from "formik";
import { LocalDate } from "@js-joda/core";

import { AanvragerKeuze } from "../../shared/types";
import { ISWSideEffects } from "../../shared/components/isw-side-effects/isw-side-effects";

import { IndicatieveUitkerendeFaseKenmerken } from "../infra/product-kenmerken-types";
import IndicatieveUitkerendeFase from "./indicatieve-uitkerende-fase";
import { determineIndicatieveUitkerendeFaseModalSideEffects } from "./determine-indicatieve-uitkerende-fase-modal-side-effects";
import { determineIndicatieveUitkerendeFaseModalAsyncSideEffects } from "./determine-indicatieve-uitkerende-fase-modal-async-side-effects";
import AdviesBoxFormik from "../../shared/utils/adviesbox-formik";
import Modal from "../../shared/components/modal/Modal";
import { IndicatieveUitkerendeFaseModalType } from "../infra/producten-overzicht-types";
import { indicatieveUitkerendeFaseModalSchema } from "../infra/producten-overzicht-schema";
import { AppDataContext } from "../../navigation/appdata-context";

export type IndicatieveUitkerendeFaseModalData = {
  data: IndicatieveUitkerendeFaseModalType;
  ingangsdatum: LocalDate | null;
  verzekerden: AanvragerKeuze;
  waardeopbouwBedrag: number | null;
  kenmerken: IndicatieveUitkerendeFaseKenmerken;
};

type IndicatieveUitkerendeFaseModalProps = {
  onSave?: (data: IndicatieveUitkerendeFaseModalType) => void;
  closeModal?: () => void;
};

const IndicatieveUitkerendeFaseModal = ({
  data,
  ingangsdatum,
  verzekerden,
  waardeopbouwBedrag,
  kenmerken,
  onSave,
  closeModal
}: IndicatieveUitkerendeFaseModalData & IndicatieveUitkerendeFaseModalProps): ReactElement => {
  const { setSErunning } = useContext(AppDataContext);

  return (
    <AdviesBoxFormik<IndicatieveUitkerendeFaseModalType>
      initialValues={{ ...data }}
      validationSchema={indicatieveUitkerendeFaseModalSchema}
      onSave={onSave}
      closeModal={closeModal}
      render={({ submitForm }: FormikProps<IndicatieveUitkerendeFaseModalType>): ReactElement => (
        <>
          <ISWSideEffects<IndicatieveUitkerendeFaseModalType>
            sync={determineIndicatieveUitkerendeFaseModalSideEffects()}
            async={determineIndicatieveUitkerendeFaseModalAsyncSideEffects({ waardeopbouwBedrag })}
            asyncStartStopCallback={setSErunning}
          />

          <Modal
            title="Indicatieve uitkerende fase"
            body={
              <IndicatieveUitkerendeFase
                baseName="values"
                ingangsdatum={ingangsdatum}
                verzekerden={verzekerden}
                kenmerken={kenmerken}
              />
            }
            onSubmitClick={submitForm}
            onCancelClick={closeModal}
          />
        </>
      )}
    />
  );
};

IndicatieveUitkerendeFaseModal.displayName = "IndicatieveUitkerendeFaseModal";

export default IndicatieveUitkerendeFaseModal;
