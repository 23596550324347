import React, { ReactElement } from "react";
import { connect, FormikContextType } from "formik";

import {
  LabeledRadioInput,
  LabeledCurrencyInput,
  LabeledNumberInput,
  LabeledPercentageInput,
  BerekenCurrencyInput
} from "adviesbox-shared";
import { enum2options } from "../../shared/utils/enum-to-options";
import { MaximaleHypotheekState, toetsrenteSchema, ToetsrenteBerekeningObv } from "../infra/maximale-hypotheek-schema";
import { getMaximaleHypotheekTextResources } from "../infra/maximale-hypotheek-resources";

const Toetsrente = ({
  formik: {
    setFieldValue,
    values: { toetsrente }
  }
}: {
  formik: FormikContextType<MaximaleHypotheekState>;
}): ReactElement => {
  const setDefaultGewensteRentevastperiode = (): void => {
    setFieldValue(
      "toetsrente.gewensteRentevastperiode",
      toetsrente.gewensteRentevastperiode || toetsrenteSchema.default().gewensteRentevastperiode
    );
  };

  return (
    <>
      <LabeledRadioInput
        caption="Berekening op basis van"
        name="toetsrente.berekeningOpBasisVan"
        options={enum2options(ToetsrenteBerekeningObv)}
      />
      {toetsrente.berekeningOpBasisVan === ToetsrenteBerekeningObv.SpecificatieToetsrente && (
        <LabeledPercentageInput caption="Toetsrente" name="toetsrente.toetsrente" decimalen={3} fieldSize="s" />
      )}

      {toetsrente.berekeningOpBasisVan === ToetsrenteBerekeningObv.GewensteRentevastperiode && (
        <LabeledNumberInput
          onBlur={setDefaultGewensteRentevastperiode}
          caption="Gewenste rentevastperiode"
          name="toetsrente.gewensteRentevastperiode"
          appendChildren={<span>jaar</span>}
        />
      )}

      <h2 className="ml-3">Vermogen en kredieten</h2>

      <LabeledCurrencyInput caption="Vrij vermogen" name="toetsrente.vrijVermogen" />

      <BerekenCurrencyInput
        caption="Krediet toetslast per maand"
        name="toetsrente.kredietToetslast"
        tooltip={getMaximaleHypotheekTextResources("tooltipToetsrenteKredietToetslast")}
      />
    </>
  );
};

export default connect<{}, MaximaleHypotheekState>(Toetsrente);
