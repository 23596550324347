import { createSpanWithId, DataGrid, LoadingSpinner } from "adviesbox-shared";
import { useFormikContext } from "formik";
import _ from "lodash";
import React, { ReactElement, SetStateAction, useEffect, useMemo } from "react";
import { Column } from "react-table-6";
import { SnelInzichtProduct } from "../../.generated/rapportage/rapportagetypes";
import { useProductenAanwezigData } from "../../shared/hooks/use-producten-aanwezig";
import { buildProductDisplayName } from "../infra/build-maandlast-display-name";
import { mapProductSoortToDisplayname } from "../infra/map-maandlast-overzicht-producten";
import { SnelInzichtModalState } from "../snel-inzicht-modal";

export const MaandlastOverzichtProducten = ({
  productenAanwezigCallback
}: {
  productenAanwezigCallback: React.Dispatch<SetStateAction<boolean>>;
}): ReactElement => {
  const snelInzichtDataGrid: Column[] = useMemo<Column[]>(
    () => [
      {
        Header: "Categorie",
        accessor: "productsoort",
        Cell: c => createSpanWithId(c.index, 0, mapProductSoortToDisplayname(c.original.productsoort))
      },
      {
        Header: "Product",
        accessor: "productnaam",
        Cell: c => createSpanWithId(c.index, 1, buildProductDisplayName(c.original))
      }
    ],
    []
  );
  const { values, setFieldValue } = useFormikContext<SnelInzichtModalState>();
  const { error, loading, productLijst } = useProductenAanwezigData(
    values.voorstelKeuze === "Huidig" ? null : values.voorstelKeuze,
    [values]
  );

  useEffect(() => {
    if (productLijst && Object.keys(productLijst).length) {
      let prods: SnelInzichtProduct[] | null = productLijst[Object.keys(productLijst)[0]].producten;
      if (prods) {
        prods = _.sortBy(prods, [
          x => {
            return mapProductSoortToDisplayname(x.productsoort);
          },
          "maatschappijNaam"
        ]);
      }
      setFieldValue("producten", prods);
      productenAanwezigCallback(productLijst[Object.keys(productLijst)[0]].producten?.length ? true : false);
    }
  }, [productLijst, setFieldValue, productenAanwezigCallback]);

  if (!loading && values.producten.length === 0)
    return (
      <div className="col-md-4">
        <p>Er zijn geen producten aanwezig.</p>
      </div>
    );

  return (
    <>
      {loading && (
        <div className="col-12 d-flex justify-content-center">
          <LoadingSpinner size="M" />
        </div>
      )}
      {!loading && values.producten && values.producten.length && (
        <DataGrid columns={snelInzichtDataGrid} name="producten" />
      )}
      {error && (
        <>
          Er is een fout opgetreden, de producten behorende bij het maandlastenoverzicht kunnen niet worden opgevraagd.
        </>
      )}
    </>
  );
};
