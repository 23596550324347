/* istanbul ignore file */

/**
 * DEZE FILE STAAT NIET ONDER TEST. WIJZIGINGEN -ALTIJD- CONTROLEREN!
 */

import {
  Adviesonderwerpen,
  AdviesonderwerpenOutput
} from "../../.generated/instellingen-forms/instellingen-formstypes";
import { useContext, useEffect } from "react";

import useAbortableFetch from "use-abortable-fetch";
import { useRequestInit, ForceRerenderContext } from "adviesbox-shared";

export type UseInstellingenAdviesonderwerpenDataResult<T> = {
  loading: boolean;
  error: null | Error;
  data: T | null;
};

export const useInstellingenAdviesonderwerpenData = (): UseInstellingenAdviesonderwerpenDataResult<Adviesonderwerpen> => {
  const {
    requestInit,
    settings: { instellingenFormsOrigin }
  } = useRequestInit();
  const loadingDone = useContext(ForceRerenderContext);

  const url = `${instellingenFormsOrigin}/Adviesonderwerpen`;
  const { loading, error, data } = useAbortableFetch<AdviesonderwerpenOutput>(url, requestInit);

  useEffect((): void => {
    if (!loading && !!data && loadingDone) {
      loadingDone();
    }
  }, [loading, data, loadingDone]);

  if (typeof data === "string") {
    return { error: new Error("Fout bij het laden van instellingen-tarieven data"), data: null, loading: false };
  }

  return { error, data: data ? data.adviesonderwerpen : null, loading };
};
