import {
  Aanleiding,
  AanleidingGesprekOptions,
  AanleidingenOutput,
  AanleidingVoorstel,
  FinancielePlanningOptions
} from "../../.generated/forms/formstypes";
import { Adviesonderwerpen } from "../../.generated/instellingen-forms/instellingen-formstypes";
import { createMapToUi } from "../../shared/utils/create-map-to-ui";
import { hasValue } from "../../shared/utils/helpers";
import {
  FinancieringOptions,
  aanleidingGesprekSchema,
  aanleidingSchema,
  AanleidingState,
  aanleidingTypeSchema,
  GebruikWoning,
  planningKeuzeSchema,
  voorstelSchema
} from "./aanleiding-schema";

const mapAanleiding = createMapToUi(aanleidingTypeSchema)
  .with<Adviesonderwerpen>()
  .from<Aanleiding>({
    financiering: (v, instellingen) =>
      instellingen.onderwerpHypotheekTonen ? v.financiering : FinancieringOptions.GeenFinanciering,
    gebruikWoning: v => (v.tweedeWoning ? GebruikWoning.TweedeWoning : GebruikWoning.Primair)
  });

const mapPlanningKeuze = createMapToUi(planningKeuzeSchema).from<Aanleiding>({
  overlijden: v => v.financielePlanningKeuzes?.map(e => e.keuze).includes(FinancielePlanningOptions.Overlijden),
  arbeidsongeschiktheid: v =>
    v.financielePlanningKeuzes?.map(e => e.keuze).includes(FinancielePlanningOptions.Arbeidsongeschiktheid),
  werkloosheid: v => v.financielePlanningKeuzes?.map(e => e.keuze).includes(FinancielePlanningOptions.Werkloosheid),
  pensioen: v => v.financielePlanningKeuzes?.map(e => e.keuze).includes(FinancielePlanningOptions.Pensioen),
  vermogensopbouw: v =>
    v.financielePlanningKeuzes?.map(e => e.keuze).includes(FinancielePlanningOptions.Vermogensopbouw)
});

const mapAanleidingGesprek = createMapToUi(aanleidingGesprekSchema).from<Aanleiding>({
  afkooperfpacht: v => v.aanleidingen?.map(e => e.aanleidingGesprek).includes(AanleidingGesprekOptions.AfkoopErfpacht),
  aflossingsVormen: v =>
    v.aanleidingen?.map(e => e.aanleidingGesprek).includes(AanleidingGesprekOptions.AflossingsvormAanpassen),
  echtscheiding: v => v.aanleidingen?.map(e => e.aanleidingGesprek).includes(AanleidingGesprekOptions.Echtscheiding),
  eindeRentevastperiode: v =>
    v.aanleidingen?.map(e => e.aanleidingGesprek).includes(AanleidingGesprekOptions.EindeRentevastperiode),
  extraAflossen: v => v.aanleidingen?.map(e => e.aanleidingGesprek).includes(AanleidingGesprekOptions.ExtraAflossen),
  financieringWoning: v =>
    v.financiering === FinancieringOptions.AankoopNieuwbouw ||
    v.financiering === FinancieringOptions.AankoopBestaandeBouw,
  maandlastenverlichting: v =>
    v.aanleidingen?.map(e => e.aanleidingGesprek).includes(AanleidingGesprekOptions.MaandlastenVerlichting),
  risicoscan: v => v.aanleidingen?.map(e => e.aanleidingGesprek).includes(AanleidingGesprekOptions.Risicoscan),
  verbouwing: v => v.aanleidingen?.map(e => e.aanleidingGesprek).includes(AanleidingGesprekOptions.Verbouwing),
  vermogensAdvies: v =>
    v.aanleidingen?.map(e => e.aanleidingGesprek).includes(AanleidingGesprekOptions.Vermogensadvies),
  controlegesprek: v =>
    v.aanleidingen?.map(e => e.aanleidingGesprek).includes(AanleidingGesprekOptions.Controlegesprek),
  anders: v => v.aanleidingen?.map(e => e.aanleidingGesprek).includes(AanleidingGesprekOptions.Overig),
  andersTekst: v => {
    const overige = v.aanleidingen?.find(e => e.aanleidingGesprek === AanleidingGesprekOptions.Overig);
    return (overige && overige.omschrijving) || "";
  }
});

const mapVoorstel = createMapToUi(voorstelSchema)
  .with<number>()
  .from<AanleidingVoorstel>({
    id: v => v.voorstelId,
    omschrijving: v => v.omschrijving,
    deleted: () => false,
    kopieVoorstelId: v => v.kopieVanVoorstelId,
    index: (_, i) => i
  });

const dl2ui = createMapToUi(aanleidingSchema)
  .with<Adviesonderwerpen>()
  .from<Aanleiding>({
    aanleiding: (v, instellingen) => mapAanleiding(instellingen)(v),
    planningKeuze: v => mapPlanningKeuze(v),
    aanleidingGesprek: v => mapAanleidingGesprek(v),
    voorstellen: v => v.voorstellen?.map((v, i) => mapVoorstel(i)(v)),
    hasTweedeAanvrager: v => hasValue(v.aanvrager2),
    kopieOptie: _ => "0",
    newKopieOptieNaam: _ => "",
    saving: _ => false
  });

let instellingenAdviesonderwerpenDataCache: Adviesonderwerpen | null = null; // Gaat niet goed? Vraag walter wat deze waanzin kun je gewoon niet alleen aan.
export const mapAanleidingDlNaarUi = (
  instellingenAdviesonderwerpenData: Adviesonderwerpen | null = instellingenAdviesonderwerpenDataCache
) => (adviesdossierId: string, data: AanleidingenOutput): AanleidingState | null => {
  const aanleiding = data.aanleidingen && data.aanleidingen[adviesdossierId];

  if (!aanleiding || !instellingenAdviesonderwerpenData) {
    return null;
  }

  instellingenAdviesonderwerpenDataCache = instellingenAdviesonderwerpenData;
  return dl2ui(instellingenAdviesonderwerpenData)(aanleiding);
};
