import { hasValue } from "adviesbox-shared";
import * as Yup from "yup";
import { ValidationError } from "yup";
import { DuurOverzichtOptions } from "../../../.generated/forms/formstypes";
import { jaarMaandInputSchema, JaarMaandInputType } from "../../../shared/generic-parts/jaar-maand/schema";
import { controleNormSchema } from "../../components/controle-norm/infra/controle-norm-schema";
import { scenarioVanSchema } from "../../components/scenario-van/scenario-van-schema";

export const orvSchema = Yup.object({
  aanvragerPercentage: Yup.number().default(0),
  partnerPercentage: Yup.number().default(0),
  leeftijd: jaarMaandInputSchema
    .default({ jaren: 0, maanden: null })
    .test("required-check", "", function(value: JaarMaandInputType): true | ValidationError {
      const valid =
        this.parent.gewensteDuurOverzicht !== DuurOverzichtOptions.TotLeeftijd ||
        (this.parent.gewensteDuurOverzicht === DuurOverzichtOptions.TotLeeftijd && hasValue(value.jaren));

      if (valid) {
        return true;
      } else {
        return this.createError({
          path: `${this.path}.jaren`,
          message: "Jaren is een verplicht veld indien 'gewenste duur uitkering: tot leeftijd'"
        });
      }
    }),
  gewensteDuurOverzicht: Yup.mixed<DuurOverzichtOptions>()
    .nullable()
    .default(DuurOverzichtOptions.TotLeeftijd),
  aantalJaar: Yup.number()
    .nullable()
    .test("required-check", "", function(value: JaarMaandInputType): true | ValidationError {
      const valid =
        this.parent.gewensteDuurOverzicht !== DuurOverzichtOptions.VasteDuurInJaren ||
        (this.parent.gewensteDuurOverzicht === DuurOverzichtOptions.VasteDuurInJaren && hasValue(value));

      if (valid) {
        return true;
      } else {
        return this.createError({
          path: `${this.path}`,
          message: "Aantal jaar is een verplicht veld indien 'gewenste duur uitkering: vaste duur in jaren'"
        });
      }
    })
});
export type OrvType = Yup.InferType<typeof orvSchema>;

export const scenarioOverlijdenModalSchema = Yup.object({
  scenarioVan: scenarioVanSchema,
  orv: orvSchema,
  controleNorm: controleNormSchema
});

export type ScenarioOverlijdenModalType = Yup.InferType<typeof scenarioOverlijdenModalSchema>;
export type ScenarioOverlijdenModalProps = {
  inVoorstel?: boolean;
  closeModal?: () => void;
  callbackOnClose?: () => void;
};
