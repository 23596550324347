import { LabeledTextInput } from "adviesbox-shared";
import { connect, FormikContextType } from "formik";
import React, { ReactElement } from "react";
import LabeledHdnKeuzelijst from "../../shared/components/hdn-keuzelijst/LabeledHdnKeuzelijst";
import { getPersonaliaTextResources } from "../infra/personalia-resources";
import { PersonaliaState } from "../infra/personalia-schema";

const Aanvrager2 = ({
  formik: {
    setFieldValue,
    values: { aanvrager2 }
  }
}: {
  formik: FormikContextType<PersonaliaState>;
}): ReactElement => (
  <div>
    <LabeledTextInput verplicht={true} caption="Achternaam" name="aanvrager2.achternaam" />
    <LabeledTextInput caption="Voorletters" name="aanvrager2.voorletters" fieldSize="m" />
    <LabeledTextInput caption="Tussenvoegsel" name="aanvrager2.tussenvoegselAchternaam" />
    <LabeledHdnKeuzelijst
      caption="Titel"
      name="aanvrager2.titel"
      emptyValue="Selecteer een optie"
      berichtSoortType="AX"
      keuzelijst="VoortitelType"
      fieldSize="xl"
    />
    <LabeledTextInput caption="Roepnaam" name="aanvrager2.roepnaam" />
    <LabeledTextInput
      caption="Voornamen"
      name="aanvrager2.voornamen"
      autoComplete="new-password"
      tooltip={getPersonaliaTextResources("tooltipVoornamen")}
    />
    <LabeledTextInput
      caption="Geboortenaam"
      name="aanvrager2.geboortenaam"
      autoComplete="new-password"
      tooltip={getPersonaliaTextResources("tooltipGeboortenaam")}
    />
    <LabeledTextInput caption="Tussenvoegsel geboortenaam" name="aanvrager2.tussenvoegselGeboortenaam" />
  </div>
);

export default connect<{}, PersonaliaState>(Aanvrager2);
