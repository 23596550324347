import { KapitaalverzekeringType } from "../../kapitaalverzekering/infra/kapitaalverzekering-types";
import { SoortLijfrenteUitkeringOptions } from "../../.generated/forms/formstypes";
import { createISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";
import { VermogenType } from "../../vermogen/infra/vermogen-types";
import {
  aanvullingInkomenBijOverlijdenModalSchema,
  indicatieveUitkerendeFaseSchema
} from "../infra/producten-overzicht-schema";
import { ProductDetailState } from "../infra/producten-overzicht-types";

export const verpandingSideEffects = createISWSideEffect<ProductDetailState | VermogenType | KapitaalverzekeringType>(
  ({ has, draft, prev }): void => {
    //Bij aanvinken ‘Verpand aan geldverstrekker’ wordt checkbox ‘Bedoeld voor aflossing van’ ook aangevinkt.
    //Indien gekozen is voor ‘Bedoeld voor aflossing van’, dan is checkbox ‘Inkomensaanvulling’ en ‘Indicatieve uitkerende fase’ uitgevinkt.

    if (
      draft.verpanding.verpandAanGeldverstrekker !== prev.verpanding.verpandAanGeldverstrekker &&
      draft.verpanding.verpandAanGeldverstrekker
    ) {
      draft.verpanding.bedoeldVoorAflossing = true;
      draft.verpanding.inkomensaanvulling = false;
      draft.verpanding.indicatieveUitkerendeFase = false;
      draft.verpanding.bedoeldVoorAflossingSpecificatie.aflosproducten = draft.verpanding.bedoeldVoorAflossingSpecificatie.aflosproducten.map(
        a => ({
          ...a,
          aflossen: true
        })
      );
    }

    //Indien gekozen is voor ‘inkomensaanvulling’, dan is checkbox ‘Bedoeld voor aflossing van’ uitgevinkt en ook wordt ‘Verpand aan geldverstrekker’ uitgevinkt.
    if (
      draft.verpanding.bedoeldVoorAflossing !== prev.verpanding.bedoeldVoorAflossing &&
      draft.verpanding.bedoeldVoorAflossing
    ) {
      draft.verpanding.inkomensaanvulling = false;
      draft.verpanding.indicatieveUitkerendeFase = false;
    }

    //Aanvinken van de checkbox ‘Indicatieve uitkerende fase’ doet de checkboxen ‘Verpand aan geldverstrekker’ en ‘Bedoeld voor aflossing van’ uitvinken.
    if (
      draft.verpanding.inkomensaanvulling !== prev.verpanding.inkomensaanvulling &&
      draft.verpanding.inkomensaanvulling
    ) {
      draft.verpanding.bedoeldVoorAflossing = false;
      draft.verpanding.verpandAanGeldverstrekker = false;
    }

    if (has.verpanding.indicatieveUitkerendeFase.changed) {
      if (draft.verpanding.indicatieveUitkerendeFase) {
        draft.verpanding.bedoeldVoorAflossing = false;
        draft.verpanding.verpandAanGeldverstrekker = false;

        draft.verpanding.indicatieveUitkerendeFaseSpecificatie.values = indicatieveUitkerendeFaseSchema.default();
        draft.verpanding.indicatieveUitkerendeFaseSpecificatie.values.soortLijfrenteUitkering =
          SoortLijfrenteUitkeringOptions.Levenslang;
      } else {
        draft.verpanding.indicatieveUitkerendeFaseSpecificatie.values = indicatieveUitkerendeFaseSchema.default();
      }
    }

    if (!draft.verpanding.inkomensaanvulling && prev.verpanding.inkomensaanvulling) {
      draft.verpanding.inkomensaanvullingSpecificatie = { ...aanvullingInkomenBijOverlijdenModalSchema.default() };
    }

    // Aanvinken van checkbox 'Bedoeld voor Aflossing" zet ook de flag 'bedoeldVoorAflossing' van kapitaalopbouw op true, i.v.m. validatie in dat schema
    if (draft.verpanding.bedoeldVoorAflossing) {
      draft.kapitaalopbouw.bedoeldVoorAflossing = true;
    } else {
      draft.kapitaalopbouw.bedoeldVoorAflossing = false;
    }
  }
);
