import React, { ReactElement } from "react";
import { Icon } from "adviesbox-shared";
import { useFormikContext } from "formik";
import { IndicatieOptions } from "../../../../../.generated/forms/formstypes";
import { AcceptatieState } from "../infra/acceptatie-schema";

export const AcceptatieCard = (): ReactElement => {
  const { values } = useFormikContext<AcceptatieState>();
  return (
    <>
      <div className="row">
        <div className="col-8 pl-4 mb-4">Indicatie</div>
        <div className="col-4">
          {values.acceptatieCard.acceptatieIndicatie === IndicatieOptions.Afgekeurd ? (
            <>
              <span className="text-danger">{values.acceptatieCard.acceptatieIndicatie}*&nbsp;&nbsp;</span>
              <Icon name="kruis" />
            </>
          ) : values.acceptatieCard.acceptatieIndicatie === IndicatieOptions.Goedgekeurd ? (
            <>
              <span className="text-success">{values.acceptatieCard.acceptatieIndicatie}*&nbsp;&nbsp;</span>
              <Icon name="vink" />
            </>
          ) : (
            <>
              <span className="text-warning">{values.acceptatieCard.acceptatieIndicatie}*&nbsp;&nbsp;</span>
            </>
          )}
        </div>
      </div>
      {values.acceptatieCard.toelichtingRegels && values.acceptatieCard.toelichtingRegels?.length > 0 && (
        <>
          <strong className="mt-6 ml-2">Toelichting</strong>
          <ul>
            {values.acceptatieCard.toelichtingRegels?.map((c, k) => (
              <li key={k}>{c.regel}</li>
            ))}
          </ul>
        </>
      )}
      {values.acceptatieCard.voetnoten && values.acceptatieCard.voetnoten?.length > 0 && (
        <>
          <div className="px-2">
            {values.acceptatieCard.voetnoten?.map((c, k) => (
              /* istanbul ignore next */
              <p className="small" key={k}>
                {c.voetnoot}
              </p>
            ))}
          </div>
        </>
      )}
    </>
  );
};

/* istanbul ignore else */ if (process.env.NODE_ENV !== "production") AcceptatieCard.displayName = "AcceptatieCard";
