import { useState, useCallback, useEffect } from 'react';
import Cookies from 'js-cookie';

interface CookieOptions {
  /**
   * Number of days until the cookie expires (must be positive)
   * @default 365
   */
  expiryDays?: number;
  /**
   * Cookie domain
   * @default current domain
   */
  domain?: string;
  /**
   * Cookie path
   * @default "/"
   */
  path?: string;
  /**
   * Whether the cookie should be secure
   * @default true for HTTPS, false for HTTP
   */
  secure?: boolean;
  /**
   * SameSite attribute
   * @default "strict"
   */
  sameSite?: 'strict' | 'lax' | 'none';
}

interface UseCookieSettingsOptions<T> {
  defaultValue: T;
  cookieOptions?: CookieOptions;
  transform?: {
    serialize: (value: T) => string;
    deserialize: (value: string) => T;
  };
}

type CookieSettingsHook<T> = (key: string, defaultValue: T) => [T, (value: T) => void];

export function getDefaultTransform<T>(defaultValue: T): { serialize: (value: T) => string; deserialize: (value: string) => T } {
  // Handle primitive types explicitly
  if (typeof defaultValue === 'string') {
    return {
      serialize: (value: T) => String(value),
      deserialize: (value: string) => value as unknown as T
    };
  }

  if (typeof defaultValue === 'number') {
    return {
      serialize: (value: T) => String(value),
      deserialize: (value: string) => {
        const num = Number(value);
        return (Number.isFinite(num) ? num : defaultValue) as unknown as T;
      }
    };
  }

  if (typeof defaultValue === 'boolean') {
    return {
      serialize: (value: T) => String(value),
      deserialize: (value: string) => (value === 'true') as unknown as T
    };
  }

  // Handle complex types with safe JSON parsing
  return {
    serialize: (value: T) => {
      try {
        return JSON.stringify(value);
      } catch {
        return JSON.stringify(defaultValue);
      }
    },
    deserialize: (value: string) => {
      try {
        return JSON.parse(value) as T;
      } catch {
        return defaultValue;
      }
    }
  };
}

function validateCookieOptions(options: CookieOptions): void {
  /* istanbul ignore next */
  if (options.expiryDays !== undefined && (options.expiryDays <= 0 || !Number.isFinite(options.expiryDays))) {
    throw new Error('Cookie expiryDays must be a positive number');
  }

  /* istanbul ignore next */
  if (options.sameSite === 'none' && !options.secure) {
    options.secure = true;
  }
}

function createCookieSettingsHook<T>(options: Partial<UseCookieSettingsOptions<T>> = {}): CookieSettingsHook<T> {
  return (key: string, defaultValue: T) => {
    /* istanbul ignore next */
    if (!key || typeof key !== 'string') {
      throw new Error('Cookie key must be a non-empty string');
    }

    const {
      cookieOptions = {},
      transform = getDefaultTransform(defaultValue)
    } = options;

    const {
      expiryDays = 365,
      domain,
      path = '/',
      secure = window.location.protocol === 'https:',
      sameSite = 'strict'
    } = cookieOptions;

    validateCookieOptions({ expiryDays, domain, path, secure, sameSite });

    const cookieConfig = {
      expires: expiryDays,
      domain,
      path,
      secure,
      sameSite
    };

    const [value, setValue] = useState<T>(() => {
      const cookieValue = Cookies.get(key);
      if (!cookieValue) {
        Cookies.set(key, transform.serialize(defaultValue), cookieConfig);
        return defaultValue;
      }
      return transform.deserialize(cookieValue);
    });

    const updateValue = useCallback(
      (newValue: T) => {
        setValue(newValue);
        Cookies.set(key, transform.serialize(newValue), cookieConfig);
        // Trigger storage event for cross-tab sync
        try {
          localStorage.setItem(key, transform.serialize(newValue));
        } catch (error) {
        }
      },
      [key, transform, cookieConfig]
    );

    useEffect(() => {
      const handleStorageChange = (event: StorageEvent): void => {
        /* istanbul ignore else */
        if (event.key === key) {
          if (event.newValue !== null) {
            try {
              const newValueDeserialized = transform.deserialize(event.newValue);
              setValue(newValueDeserialized);
            } catch (error) {
              setValue(defaultValue);
            }
          } else {
            setValue(defaultValue);
          }
        }
      };

      window.addEventListener('storage', handleStorageChange);
      return () => window.removeEventListener('storage', handleStorageChange);
    }, [key, defaultValue, transform]);

    return [value, updateValue];
  };
}

// Predefined hooks exports remain the same
export const useCookieBoolean = createCookieSettingsHook<boolean>({
  transform: {
    serialize: (value: boolean) => String(value),
    deserialize: (value: string) => value === 'true'
  }
});

export const useCookieNumber = createCookieSettingsHook<number>({
  transform: {
    serialize: (value: number) => String(value),
    deserialize: (value: string) => {
      const num = Number(value);
      return Number.isFinite(num) ? num : 0;
    }
  }
});

export const useCookieString = createCookieSettingsHook<string>({
  transform: {
    serialize: (value: string) => value,
    deserialize: (value: string) => value
  }
});

export const useCookieJSON = createCookieSettingsHook<object>({
  transform: {
    serialize: (value: object) => {
      try {
        return JSON.stringify(value);
      } catch (error) {
        /* istanbul ignore next */
        return '{}';
      }
    },
    deserialize: (value: string) => {
      try {
        return JSON.parse(value);
      } catch (error) {
        return {};
      }
    }
  }
});

export const useCookieSettings = <T,>(
  key: string,
  defaultValue: T,
  options: Partial<UseCookieSettingsOptions<T>> = {}
): [T, (value: T) => void] => {
  const hook = createCookieSettingsHook<T>({
    ...options,
    transform: options.transform || getDefaultTransform(defaultValue)
  });
  return hook(key, defaultValue);
};