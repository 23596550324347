/* istanbul ignore file */
import { UiName, FieldMap } from "../../shared/types";
import { DashboardOutput as DashboardDl } from "../../.generated/forms/formstypes";
import { target2field } from "../../shared/utils/target-to-field";

export function mapDashboardDlTargetToUiField(target: string): UiName | null {
  const map: FieldMap<DashboardDl> = {};

  return target2field(map, target);
}
