import React, { ReactElement, useMemo } from "react";
import { labelValuePairType, DataType, importState, FoutType } from "./infra/import-schema";
import {
  LabeledText,
  LabeledTextInput,
  LabeledDateInput,
  LabeledNumberInput,
  LabeledBevestigingInput,
  LabeledPostcodeInput,
  LabeledRadioInput
} from "adviesbox-shared";
import LabeledHdnKeuzelijst from "../shared/components/hdn-keuzelijst/LabeledHdnKeuzelijst";
import { FormikContextType } from "formik";
import { Vertaling } from "./infra/externekoppeling-import-api";
import classes from "./externekoppeling-import.module.scss";
import classNames from "classnames";

export const renderExterneKoppelingInportGegevens = (
  indexFieldMap: number,
  externeKoppelingGegevens: labelValuePairType[],
  formik: FormikContextType<importState>,
  vertalingen: Vertaling[]
): ReactElement => {
  const renderedComponents: ReactElement[] = [];
  const foutenAanwezig = externeKoppelingGegevens.some(
    externeKoppelingGegeven => externeKoppelingGegeven.validationErrors.length > 0
  );
  const col1 = foutenAanwezig ? "col-9" : "col-12";
  const classImportComponent = classNames(classes.import_component);

  externeKoppelingGegevens.forEach((externeKoppelingGegeven, index) => {
    const caption = externeKoppelingGegeven.label;
    const vertaling = vertalingen.find(value => value.key === caption);
    const label = vertaling ? vertaling.value : caption;
    let renderedComponent: ReactElement | null = null;

    if (externeKoppelingGegeven.validationErrors.length > 0) {
      const fout = externeKoppelingGegeven.validationErrors[0];
      const name = `fieldMaps[${indexFieldMap}].labelValues[${index}].value`;
      const waarde: any = useMemo(
        () =>
          fout.foutType === FoutType.TypeMismatch ||
          fout.dataType === DataType.HDNKeuzelijst ||
          fout.dataType === DataType.Enum ||
          fout.dataType === DataType.Postcode
            ? formik.values.fieldMaps[indexFieldMap].labelValues[index].value
            : "",
        [fout, index]
      );

      switch (fout.dataType) {
        case DataType.Date:
          renderedComponent = (
            <LabeledDateInput name={name} caption={label} key={`veld-${index}`} readonly={formik.isSubmitting} />
          );

          break;
        case DataType.Integer:
          renderedComponent = (
            <LabeledNumberInput name={name} caption={label} key={`veld-${index}`} readonly={formik.isSubmitting} />
          );
          break;
        case DataType.Boolean:
          renderedComponent = (
            <LabeledBevestigingInput name={name} caption={label} key={`veld-${index}`} readonly={formik.isSubmitting} />
          );
          break;
        case DataType.Postcode:
          renderedComponent = (
            <LabeledPostcodeInput name={name} caption={label} key={`veld-${index}`} readOnly={formik.isSubmitting} />
          );
          break;
        case DataType.Enum:
          if (fout.mogelijkeWaarden?.length > 0) {
            const options = fout.mogelijkeWaarden?.map(value => ({ label: value, value }));
            renderedComponent = (
              <LabeledRadioInput
                name={name}
                caption={label}
                options={options}
                key={`veld-${index}`}
                readOnly={formik.isSubmitting}
              />
            );
          }
          break;
        case DataType.HDNKeuzelijst:
          renderedComponent = (
            <LabeledHdnKeuzelijst
              name={name}
              caption={label}
              berichtSoortType={"AX"}
              keuzelijst={fout.platformDataType}
              key={`veld-${index}`}
              readonly={formik.isSubmitting}
            />
          );
          break;
        default:
          renderedComponent = (
            <LabeledTextInput name={name} caption={label} key={`veld-${index}`} readonly={formik.isSubmitting} />
          );
          break;
      }
      renderedComponent = (
        <div className="row" key={`row-${index}`}>
          <div className={`${col1} ${classImportComponent}`}>{renderedComponent}</div>
          {foutenAanwezig && <div className="col-3">{waarde}</div>}
        </div>
      );
    } else {
      if (externeKoppelingGegeven.value) {
        renderedComponent = (
          <div className="row" key={`row-${index}`}>
            <div className={col1}>
              <LabeledText caption={label} value={externeKoppelingGegeven.value} key={`veld-${index}`} />
            </div>
            {foutenAanwezig && <div className="col-3"></div>}
          </div>
        );
      }
    }
    if (renderedComponent) {
      renderedComponents.push(renderedComponent);
    }
  });
  return (
    <div className="container">
      <div className="row form-group" key={"header-row"}>
        <div className={col1}></div>
        {foutenAanwezig && <div className="col-3">Binnenkomende waarde</div>}
      </div>

      {renderedComponents.map(v => v)}
    </div>
  );
};
