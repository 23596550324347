import { AdviesBoxFormik, createSpanWithId, DataGrid } from "adviesbox-shared";
import React, { ReactElement } from "react";
import { Column } from "react-table-6";
import { SpecificatieExtraAflossingenState, specificatieExtraAflossingschema } from "../infra/acceptatie-schema";
import { bedragFormat } from "../../../../../shared/utils/currency";
import { percentageFormat } from "../../../../../shared/utils/percentage-format";
import Modal from "../../../../../shared/components/modal/Modal";

export type SpecificatieExtraAflossingProps = {
  data: SpecificatieExtraAflossingenState;
  closeModal?: () => void;
};

const ExtraAflossingColumns = (sumExtraAflossingen: number): Column[] => {
  return [
    {
      Header: "Periode (van t/m maand)",
      id: "periode",
      Cell: (c): ReactElement => createSpanWithId(c.index, 0, c.original.periode),
      Footer: <strong>Totaal</strong>
    },
    {
      Header: "Verplichte extra aflossing",
      id: "verplichteExtraAflossing",
      Footer: <span>{bedragFormat(sumExtraAflossingen)}</span>,
      Cell: (c): ReactElement => createSpanWithId(c.index, 1, bedragFormat(c.original.verplichteExtraAflossing, 0))
    },
    {
      Header: "Mogelijke extra aflossing",
      id: "mogelijkeExtraAflossing",
      Cell: (c): ReactElement => createSpanWithId(c.index, 2, bedragFormat(c.original.mogelijkeExtraAflossing, 0))
    },
    {
      Header: "Looptijd (mnd)",
      id: "looptijd",
      Cell: (c): ReactElement => createSpanWithId(c.index, 3, c.original.looptijd)
    },
    {
      Header: "Rente",
      id: "rente",
      Cell: (c): ReactElement => createSpanWithId(c.index, 4, percentageFormat(c.original.rente, 3))
    }
  ];
};

export const SpecificatieExtraAflossingModal = ({
  data: specsData,
  closeModal
}: SpecificatieExtraAflossingProps): ReactElement => {
  const body = (
    <>
      {specsData.specificaties.length > 0 && (
        <DataGrid
          columns={ExtraAflossingColumns(
            specsData.specificaties.map(c => c.verplichteExtraAflossing || 0).reduce((prev, cur) => prev + cur)
          )}
          name="specificaties"
          rowCaption="resultaten"
        />
      )}
      {specsData.specificaties.length === 0 && (
        <div className="d-flex justify-content-center">Er zijn geen extra aflossingen om weer te geven.</div>
      )}
    </>
  );

  return (
    <AdviesBoxFormik<SpecificatieExtraAflossingenState>
      initialValues={specsData}
      validationSchema={specificatieExtraAflossingschema}
      closeModal={closeModal}
      render={(): ReactElement => (
        <div className="modal-with-cards">
          <Modal title="Specificatie extra aflossing" body={body} noEditableData onCancelClick={closeModal} />
        </div>
      )}
    />
  );
};

/* istanbul ignore else */ if (process.env.NODE_ENV !== "production")
  SpecificatieExtraAflossingModal.displayName = "SpecificatieExtraAflossingModalComponent";
