import {
  getValueFromKenmerken,
  updateTaxatieSchermObject
} from "../update-taxatie-scherm-object/update-taxatie-scherm-object";
import {
  AanvullendeInformatieSchema,
  AanvullendeInformatieType,
  BouwkundigeKeuringSchema,
  BouwkundigeKeuringType,
  ContactpersoonSchema,
  ContactpersoonType,
  OpdrachtgeverSchema,
  OpdrachtgeverTaxatieType,
  PartnerSchema,
  PartnerTaxatieType,
  TaxatieAlgemeenSchema,
  TaxatieAlgemeenType,
  TaxatieKenmerkenType,
  TaxatieObjectSchema,
  TaxatieObjectType,
  TaxatieSchermType
} from "./taxatie-scherm-schema";
/* istanbul ignore file */

const opdrachgeverKenmerkenDictionary: Partial<Record<
  keyof OpdrachtgeverTaxatieType,
  (keyof TaxatieKenmerkenType)[]
>> = {
  geboortedatum: ["OpdrachtgeverGeboortedatumTonen"],
  adres: ["OpdrachtgeverAdresTonen"],
  telefoonnummerWerk: ["OpdrachtgeverTelefoonWerkTonen"],
  telefoonnummerPrive: ["OpdrachtgeverTelefoonPriveTonen"],
  telefoonnummerMobiel: ["OpdrachtgeverTelefoonMobielTonen"],
  emailAdres: ["OpdrachtgeverEmailadresTonen"]
};

const partnerKenmerkenDictionary: Partial<Record<keyof PartnerTaxatieType, (keyof TaxatieKenmerkenType)[]>> = {
  telefoonnummerPrive: ["PartnerTelefoonPriveTonen"],
  telefoonnummerMobiel: ["PartnerTelefoonMobielTonen"],
  emailAdres: ["PartnerEmailadresTonen"]
};

const taxatieObjectKenmerkenDictionary: Partial<Record<keyof TaxatieObjectType, (keyof TaxatieKenmerkenType)[]>> = {
  aankoopprijsBedrag: ["AankoopprijsTonen"],
  nieuwbouw: ["NieuwbouwTonen"],
  bouwplan: ["BouwplanTonen"],
  bouwnummer: ["BouwnummerTonen"],
  gebruikMomenteel: ["GebruikTonen"],
  huurovereenkomst: ["HuurovereenkomstTonen"],
  opdrachtgever: ["OpdrachtgeverRolTonen"]
};

const bouwkundigeKeuringKenmerkenDictionary: Partial<Record<
  keyof BouwkundigeKeuringType,
  (keyof TaxatieKenmerkenType)[]
>> = {
  inhoud: ["InhoudInMetersTonen"],
  inhoudwoning: ["InhoudBenaderingTonen"],
  soortWoning: ["SoortWoningTonen"],
  keuringTenBehoevenVan: ["KeuringTbvTonen"],
  benodigdeKeuring: ["BenodigdeKeuringTonen"],
  monument: ["WoningMonumentTonen"],
  gestapeldeBouw: ["GestapeldeBouwTonen"],
  vve: ["VveTonen"],
  keuringTenBehoevenVanAnders: ["KeuringTbvTonen"],
  bouwjaar: ["BouwjaarTonen"]
};

const taxatieAlgemeenKenmerkenDictionary: Partial<Record<keyof TaxatieAlgemeenType, (keyof TaxatieKenmerkenType)[]>> = {
  taxateurNwwiKeuze: ["TaxateurTonen"],
  doelTaxatie: ["DoelTaxatieTonen"],
  referentieGeldverstrekker: ["GeldverstrekkerReferentieTonen"],
  geldverstrekkerNwwiKeuze: ["GeldverstrekkerKeuzeTonen"],
  herbouwwaarde: ["HerbouwwaardeTonen"],
  aanvraagdatum: ["DatumAanvraagTonen"],
  voortaxatieGewenst: ["VoortaxatieTonen"],
  nwwiAanvraag: ["NwwiAanvraagTonen"],
  validatieVerplicht: ["ValidatieVerplichtTonen"],
  energiePrestatieAdvies: ["EnergieAdviesTonen"],
  bouwtechnischeKeuring: ["BouwtechnischeKeuringTonen"],
  recenteVerkoop: ["RecenteVerkoopTonen"],
  bemiddelingsKostenBedrag: ["BemiddelingskostenTonen"],
  verbouwing: ["VerbouwingTonen"],
  voorVerkrijgenLening: ["VoorVerkrijgenLeningTonen"],
  beinvloedingWaarde: ["BeïnvloedingWaardeTonen"],
  toelichtingBeinvloedingWaarde: ["ToelichtingWaardeTonen"],
  gereedDatum: ["DatumGereedTonen"],
  faxVerstuurd: ["FaxVerstuurdTonen"],
  passeerdatum: ["PasseerdatumTonen"],
  nhg: ["NhgTonen"]
};

const contactpersoonKenmerkenDictionary: Partial<Record<keyof ContactpersoonType, (keyof TaxatieKenmerkenType)[]>> = {
  typeContactpersoon: ["ContactpersoonTypeTonen"],
  naamMakelaar: ["MakelaarNaamTonen"],
  geslacht: ["ContactpersoonGeslachtTonen"],
  postcode: ["ContactpersoonAdresTonen"],
  woonplaats: ["ContactpersoonPlaatsTonen"],
  telefoonWerk: ["ContactpersoonTelefoonWerkTonen"],
  telefoonMobiel: ["ContactpersoonTelefoonMobielTonen"],
  telefoonPrive: ["ContactpersoonTelefoonPriveTonen"]
};

const aanvullendeInformatieKenmerkenDictionary: Partial<Record<
  keyof AanvullendeInformatieType,
  (keyof TaxatieKenmerkenType)[]
>> = {
  spoed: ["SpoedTonen"],
  opdrachtnummer: ["OpdrachtnummerTonen"],
  externReferentienummer: ["ExternReferentienummerTonen"],
  naamEigenaar: ["EigenaarNaamTonen"],
  telefoonnummer: ["EigenaarTelefoonTonen"],
  taxateurnummer: ["TaxateurnummerTonen"],
  betalingViaNotaris: ["BetalingViaNotarisTonen"],
  taxateur: ["TaxateurTonen"]
};

export const taxatieObjectFactoryByKenmerken = (currentTaxatie: TaxatieSchermType): TaxatieSchermType => {
  const kenmerken = currentTaxatie?.taxatieKenmerken;
  if (!kenmerken) return currentTaxatie;

  return {
    ...currentTaxatie,
    taxatieAlgemeen: getValueFromKenmerken(kenmerken, "KaartTaxatieTonen")
      ? updateTaxatieSchermObject(
          taxatieAlgemeenKenmerkenDictionary,
          kenmerken,
          TaxatieAlgemeenSchema,
          currentTaxatie.taxatieAlgemeen
        )
      : null,
    opdrachtgever: updateTaxatieSchermObject(
      opdrachgeverKenmerkenDictionary,
      kenmerken,
      OpdrachtgeverSchema,
      currentTaxatie.opdrachtgever
    ),
    partner: getValueFromKenmerken(kenmerken, "KaartPartnerTonen")
      ? updateTaxatieSchermObject(partnerKenmerkenDictionary, kenmerken, PartnerSchema, currentTaxatie.partner)
      : null,
    taxatieObject: getValueFromKenmerken(kenmerken, "KaartObjectTonen")
      ? updateTaxatieSchermObject(
          taxatieObjectKenmerkenDictionary,
          kenmerken,
          TaxatieObjectSchema,
          currentTaxatie.taxatieObject
        )
      : null,
    bouwkundigeKeuring: getValueFromKenmerken(kenmerken, "KaartBouwkundigeKeuringTonen")
      ? updateTaxatieSchermObject(
          bouwkundigeKeuringKenmerkenDictionary,
          kenmerken,
          BouwkundigeKeuringSchema,
          currentTaxatie.bouwkundigeKeuring
        )
      : null,
    contactpersoon: getValueFromKenmerken(kenmerken, "KaartContactpersoonTonen")
      ? updateTaxatieSchermObject(
          contactpersoonKenmerkenDictionary,
          kenmerken,
          ContactpersoonSchema,
          currentTaxatie.contactpersoon
        )
      : null,
    aanvullendeInformatie: getValueFromKenmerken(kenmerken, "KaartAanvullendeInformatieTonen")
      ? updateTaxatieSchermObject(
          aanvullendeInformatieKenmerkenDictionary,
          kenmerken,
          AanvullendeInformatieSchema,
          currentTaxatie.aanvullendeInformatie
        )
      : null
  } as TaxatieSchermType;
};
