import { SettingsType } from "adviesbox-shared";
import { User } from "oidc-client";
import { OrvVergelijkerInput, OrvVergelijkerOutput } from "../../.generated/forms/formstypes";
import { OrganisatieEx } from "../../.generated/licenties/licentiestypes";
import { hasJSONResponse } from "../../shared/utils/ajax";

export const orvVergelijkerModalApi = async (
  settings: SettingsType,
  user: User,
  vestigingId: string,
  adviesdossierId: string,
  body: OrvVergelijkerInput | null
): Promise<OrvVergelijkerOutput> => {
  const url = `${settings.klantdossiersFormsOrigin}/Adviesdossiers/${adviesdossierId}/OrvVergelijker`;
  const rsp = await fetch(url, {
    method: "POST",
    headers: {
      authorization: `${user.token_type} ${user.access_token}`,
      "Ocp-Apim-Subscription-Key": settings.OcpApimSubscriptionKey,
      "Content-Type": "application/json;charset=UTF-8",
      vestigingId
    },
    body: JSON.stringify(body)
  });

  if (!(rsp.ok && hasJSONResponse(rsp))) {
    throw Error(`Fout bij aanroep van web-api '${url}'. Resultaat bevat geen JSON.`);
  }

  const json = await rsp.json();

  if (!json.isValid) {
    throw Error(`Fout bij aanroep van web-api '${url}'. Input is niet valid.`);
  }
  return json;
};

export const organisatieDataApi = async (
  settings: SettingsType,
  user: User | null,
  usersOrganisatieId: string | null
): Promise<OrganisatieEx> => {
  const url = `${settings?.licentiesOrigin}/Organisaties/${usersOrganisatieId}`;

  if (!usersOrganisatieId) throw Error(`Fout bij aanroep van web-api '${url}'. User bevat geen Organisatie-id.`);
  const rsp = await fetch(url, {
    method: "GET",
    headers: {
      authorization: `${user?.token_type} ${user?.access_token}`,
      "Ocp-Apim-Subscription-Key": settings.OcpApimSubscriptionKey,
      "Content-Type": "application/json;charset=UTF-8"
    }
  });

  if (!(rsp.ok && hasJSONResponse(rsp))) {
    throw Error(`Fout bij aanroep van web-api '${url}'. Resultaat bevat geen JSON.`);
  }

  const json = await rsp.json();

  if (!json.isValid) {
    throw Error(`Fout bij aanroep van web-api '${url}'. Input is niet valid.`);
  }

  return json.organisaties[usersOrganisatieId];
};
