import {
  ControleGewenstNbiOptions,
  ControleMaandlastOptions,
  ControleGewenstInkomenOptions
} from "../../../../.generated/forms/formstypes";

export const controleNBIoptions = [
  {
    label: "Behouden van huidige NBI",
    value: ControleGewenstNbiOptions.BehoudenHuidigNbi
  },
  {
    label: "Acceptabele marge op huidige NBI",
    value: ControleGewenstNbiOptions.MargeHuidigNbi
  },
  {
    label: "Overhouden van vast bedrag NBI",
    value: ControleGewenstNbiOptions.VastBedrag
  },
  {
    label: "Percentage van huidig NBI",
    value: ControleGewenstNbiOptions.PercentageHuidigNbi
  }
];

export const controleMaandlastOptions = [
  {
    label: "Benodigd inkomen voor hypotheek",
    value: ControleMaandlastOptions.BenodigdInkomen
  },
  {
    label: "Werkelijke last toets",
    value: ControleMaandlastOptions.WerkelijkeLast
  }
];

export const controleGewenstInkomenOptions = [
  {
    label: "Netto besteedbaar inkomen",
    value: ControleGewenstInkomenOptions.Nbi
  },
  {
    label: "Bruto inkomenswens",
    value: ControleGewenstInkomenOptions.BrutoInkomenswens
  },
  {
    label: "Geen",
    value: ControleGewenstInkomenOptions.Geen
  }
];
