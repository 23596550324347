import { mapJaarMaandInputDl2Ui } from "../../shared/generic-parts/jaar-maand/map-dl-2-ui";
import { initISWSideEffect, createISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";
import { LocalDate } from "@js-joda/core";

import { getDifferenceYearsMonths } from "../../shared/utils/dates";
import { BoeterenteModalType } from "../infra/financieringsbehoefte-types";

const boeterenteDraftSideEffects = createISWSideEffect<BoeterenteModalType, number>((bag): void => {
  const { has, draft, context } = bag;
  if (
    draft.modal[context].resterendePeriodeWeergeven &&
    (has.modal[context].resterendePeriodeWeergeven.changed || has.modal[context].datumBoeterente.changed || has.modal[context].resterendePeriodeWeergeven.changed)
  ) {
    const differenceYearMonths = getDifferenceYearsMonths(
      draft.modal[context].datumBoeterente || LocalDate.now(),
      draft.modal[context].einddatumRentevastperiode || LocalDate.now(),
      true
    );

    draft.modal[context].resterendePeriode = mapJaarMaandInputDl2Ui(
      Math.max(differenceYearMonths.year, 0),
      Math.max(differenceYearMonths.month, 0)
    );
  }
});

export const boeterenteSideEffects = initISWSideEffect<BoeterenteModalType, number>(boeterenteDraftSideEffects);
