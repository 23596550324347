import { AuthContext, MenuWrapper, NameChangeType, SettingsContext, useRequestInit, convertAndFormatUtcToTimeZone } from "adviesbox-shared";
import { capitalize } from "lodash";
import React, { ReactElement, useContext, useEffect, useMemo } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import brandLogoIcon from "../assets/new-brand-logo.svg";
import brandCollapsedLogoIcon from "../assets/new-collapsed-brand-logo.svg";
import { useMenuInfoData } from "../shared/hooks/use-menu-info-data";
import OpenDossierLogContext from "../shared/open-dossier-log/open-dossier-log-context";
import { RouteParams } from "../shared/paramrouting/paramrouting-context";
import { getActivePageTitle } from "../shared/utils/menu-info";
import { AppDataContext } from "./appdata-context";
import { menuConfig } from "./menu-config";
import { updateNaamVoorstel } from "./update-naam-voorstel-api";
import { useFeature } from "adviesbox-shared";

type NavigationListProps = {
  collapsed: boolean;
  collapse(): void;
};

const NavigationList = (props: NavigationListProps & RouteComponentProps): ReactElement => {
  const { collapsed, collapse } = props;
  const { params } = useRequestInit<RouteParams>();
  const { reloadCount, setMenuInfo, setActivePage, setBeschikbareVoorstellen, setError } = useContext(AppDataContext);
  const { data: menuInfo, loading, error } = useMenuInfoData(reloadCount);
  const { seRunning, setLastMutationDate } = useContext(AppDataContext);
  const settings = useContext(SettingsContext);
  const { user } = useContext(AuthContext);
  const featureNewDashboard = useFeature("FeatureNewDashboard");

  const config = useMemo(() => {
    if (menuInfo !== null) {
      return menuConfig(menuInfo, params, featureNewDashboard);
    }
    return [];
  }, [menuInfo, params, featureNewDashboard]);

  useEffect(() => {
    if (menuInfo) {
      setMenuInfo(menuInfo);
      setBeschikbareVoorstellen(
        menuInfo.voorstellen?.map((c, index) => {
          return { naam: c.naam || `Voorstel ${++index}`, voorstelId: c.id, selected: true };
        }) || []
      );

      setActivePage(capitalize(getActivePageTitle(config, window.location.pathname)));

      props.history.listen((location): void => {
        setActivePage(capitalize(getActivePageTitle(config, location.pathname)));
      });

      if (menuInfo.laatsteMutatieDatum) {
        setLastMutationDate(convertAndFormatUtcToTimeZone(menuInfo.laatsteMutatieDatum));
      }
    }
    if (error) {
      setError(error);
    }
  }, [setMenuInfo, menuInfo, params, setActivePage, props.history, config, setBeschikbareVoorstellen, error, setError, setLastMutationDate]);

  const logContext = useContext(OpenDossierLogContext);
  if (menuInfo && menuInfo.klantdossierId && params.vestiging && params.adviesdossier) {
    logContext.setAdviesDossierId(params.adviesdossier, menuInfo.klantdossierId, params.vestiging);
  }

  return (
    <MenuWrapper
      brandLogoIcon={brandLogoIcon}
      brandCollapsedLogoIcon={brandCollapsedLogoIcon}
      collapse={collapse}
      collapsed={collapsed}
      hasMenuInfo={!!menuInfo}
      loading={loading}
      menuConfig={config}
      appName={"advies"}
      readonly={seRunning}
      onEditComplete={/* istanbul ignore next */ async (item: NameChangeType) => {
        if (user) {
          return await updateNaamVoorstel(params.vestiging, item.uniqueId, item.name, settings, user);
        }
        return false;
      }}
    />
  );
};

NavigationList.displayName = "NavigationList";

export default withRouter(NavigationList);
