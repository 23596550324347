import React, { ReactElement } from "react";
import { connect, FormikContextType } from "formik";
import { HdnAnders, WoningState } from "../infra/woning-schema";
import { GebruikPandSoort, Verkoopstatus } from "../../.generated/forms/formstypes";
import { Direction } from "../../shared/types";
import {
  LabeledCurrencyInput,
  LabeledRadioInput,
  BerekenCurrencyButton,
  LabeledSelectInput,
  LabeledDateInput,
  LabeledBevestigingInput,
  LabeledTextInput
} from "adviesbox-shared";
import LabeledHdnKeuzelijst from "../../shared/components/hdn-keuzelijst/LabeledHdnKeuzelijst";
import { getOnderpandTextResources } from "./te-verkopen-woning-resources";

export type VerkoopProps = {
  index: number;
};

const textForVerkoopstatusOptions = [
  { label: "Blijft in bezit", value: "BlijftInBezit" },
  { label: "Is verkocht", value: "IsVerkocht" },
  { label: "Wordt verkocht", value: "WordtVerkocht" }
];

const textForGebruik = [
  { value: GebruikPandSoort.PrimaireWoning, label: "Primair" },
  { value: GebruikPandSoort.TweedeWoning, label: "Tweede woning" },
  { value: GebruikPandSoort.Geen, label: "Geen" }
];

const TeVerkopenWoning = ({
  formik,
  index
}: VerkoopProps & {
  formik: FormikContextType<WoningState>;
}): ReactElement => {
  const verkoopstatus = formik.values.teVerkopenWoningen[index].verkoopstatus;
  const ondervoorwaarden = formik.values.teVerkopenWoningen[index].verkoopondervoorwaarden;
  
  const berekenen = formik.values.teVerkopenWoningen[index].berekenen;
  const toonIsVerkocht: boolean = verkoopstatus === Verkoopstatus.IsVerkocht;
  const toonBlijftInBezit: boolean = verkoopstatus === Verkoopstatus.BlijftInBezit;
  const toonConstructie: boolean = ondervoorwaarden || false;
  const toonConstructieOmschrijving: boolean = formik.values.teVerkopenWoningen[index].constructie === `${HdnAnders}`;

  return (
    <div>
      <LabeledSelectInput
        caption="Gebruik"
        name={`teVerkopenWoningen[${index}].gebruikPandVoorstel`}
        options={textForGebruik}
      />

      <LabeledCurrencyInput name={`teVerkopenWoningen[${index}].marktwaarde`} caption="Marktwaarde" readonly={true} />

      <LabeledRadioInput
        caption="Verkoopstatus"
        name={`teVerkopenWoningen[${index}].verkoopstatus`}
        options={textForVerkoopstatusOptions}
        layout={Direction.Vertical}
      />

      {toonIsVerkocht && (
        <LabeledDateInput caption="Verkoopstatus per" name={`teVerkopenWoningen[${index}].verkoopstatusDatum`} />
      )}

      {toonIsVerkocht && (
        <LabeledDateInput
          caption="Ontbindende voorwaarden tot"
          name={`teVerkopenWoningen[${index}].ontbindendeVoorwaardenDatum`}
        />
      )}

      {!toonBlijftInBezit && (
        <LabeledCurrencyInput
          caption={toonIsVerkocht ? "Verkoopprijs" : "Verkoopprijs (geschat)"}
          name={`teVerkopenWoningen[${index}].verkoopprijs`}
        />
      )}

      {!toonBlijftInBezit && (
        <>
          <LabeledCurrencyInput
            caption={toonIsVerkocht ? "Verkoopkosten" : "Verkoopkosten (geschat)"}
            name={`teVerkopenWoningen[${index}].verkoopkosten`}
            disabled={berekenen}
            appendChildren={
              <BerekenCurrencyButton name={`teVerkopenWoningen[${index}].berekenen`} berekenen={berekenen} />
            }
          />
        </>
      )}

      {!toonBlijftInBezit && (
        <LabeledBevestigingInput
          caption="Verkoop onder voorwaarden"
          name={`teVerkopenWoningen[${index}].verkoopondervoorwaarden`}
        />
      )}
      {!toonBlijftInBezit && toonConstructie && (
        <LabeledHdnKeuzelijst
          caption="Constructie"
          name={`teVerkopenWoningen[${index}].constructie`}
          keuzelijst="VerkoopOnderVoorwaardenConstructieType"
          berichtSoortType="AX"
        />
      )}
      {!toonBlijftInBezit && toonConstructie && toonConstructieOmschrijving && (
        <>
          <LabeledHdnKeuzelijst
            caption="Constructie anders"
            name={`teVerkopenWoningen[${index}].erfpachtConstructieAnders`}
            keuzelijst="ErfpachtOfKortingsConstructieAndersType"
            berichtSoortType="AX"
          />
          <LabeledTextInput
            caption={"Constructie omschrijving"}
            name={`teVerkopenWoningen[${index}].constructieOmschrijving`}
            textarea={3}
            tooltip={getOnderpandTextResources("constructieOmschrijving")}
          />
        </>
      )}
    </div>
  );
};

export default connect<VerkoopProps, WoningState>(TeVerkopenWoning);
