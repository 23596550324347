import { Card, LabeledCurrencyInput, LabeledNumberInput } from "adviesbox-shared";
import React, { ReactElement } from "react";

type ContractantenProps = {
  selected: number;
  periodiekeOpname?: boolean;
};

export const Opnames = ({ selected, periodiekeOpname }: ContractantenProps): ReactElement => {
  return (
    <Card title="Opnames">
      <LabeledCurrencyInput
        caption={"Bedrag"}
        name={`producten[${selected}].opnameBedrag`}
        disabled={periodiekeOpname === false}
      />
      {periodiekeOpname && (
        <>
          <LabeledNumberInput caption={"Aantal maanden"} name={`producten[${selected}].opnameMaanden`} />
        </>
      )}
    </Card>
  );
};
