import { ChronoUnit, LocalDate } from "@js-joda/core";
import { beschikbareOrvProducten, mapPeriodeInMaandenDl2Ui, mapStringToLocalDate } from "adviesbox-shared";
import { BeschikbareProductenType } from "adviesbox-shared/product-filters/filter-types";
import {
  LeningdeelReadOnly,
  Orv as OrvDlEntry,
  OrvAanvraag,
  OrvDekking,
  OrvOutput as OrvDlOutput,
  OrvPremie,
  BetalingsTermijnType,
  OrvProduct,
  PremieverloopOptions,
  SoortOrvProductOptions,
  OrvResultaatPremie,
  OrvUitkeringssoortOptions,
  OrvVergelijkerResultaat,
  OrvVerpanding
} from "../../.generated/forms/formstypes";
import { VerzekeraarPartij } from "../../.generated/instellingen-forms/instellingen-formstypes";
import { OrvBasis } from "../../.generated/producten/productentypes";
import {
  dekkingSchema,
  informatieVoorVerzendingAanvraagSchema,
  premieSpecificatieModalSchema,
  verpandingSchema
} from "../../producten-overzicht/infra/producten-overzicht-schema";
import {
  mapAflosProductenDlToUi,
  mapAflosProductNew
} from "../../producten-overzicht/infra/verpanding/map-verpanding-dl-to-ui";
import { ScenarioCardInput } from "../../shared/components/scenario-input/scenario-card";
import { mapJaarMaandInputFromLooptijdDl2Ui } from "../../shared/generic-parts/jaar-maand/map-dl-2-ui";
import {
  mapKlantenNaarAanvragerKeuze,
  mapKlantnaamDl2Ui,
  mapKlantnaamDl2UiMetKinderen
} from "../../shared/generic-parts/klantnaam/map-dl-2-ui";
import { KlantnaamType } from "../../shared/generic-parts/klantnaam/schema";
import { AanvragerKeuze, FieldMap, UiName } from "../../shared/types";
import { createMapToUi } from "../../shared/utils/create-map-to-ui";
import { addMonths, addYears } from "../../shared/utils/dates";
import { prefixWithZero } from "../../shared/utils/helpers";
import { partijFilter } from "../../shared/utils/partij-filter";
import { target2field } from "../../shared/utils/target-to-field";
import {
  orvPremieGegevensSchema,
  orvProductSchema,
  orvSchema,
  orvsSchemaBase,
  OrvsState,
  OrvType,
  orvVergelijkenModalSchema,
  OrvVergelijkenModalType,
  OrvVergelijkerModalDataType,
  OrvVergelijkerResultaatStatus,
  orvVerpandingSchema
} from "./orv-schema";

export const mapProductDl2Ui = createMapToUi(orvProductSchema).from<OrvProduct>({
  doorlopend: v => v.doorlopend || false,
  productNummer: v => v.polis.productnummer || "",
  partijCodeSelectie: v => v.polis.maatschappijCodeHdn || "", // TODO: Veld moet nog in backend toegevoegd worden (Pertijcode bedoeld voor de dropdown)
  partijNaam: v => v.polis.maatschappijOmschrijving || "",
  productNaam: v => v.polis.productnaam || "",
  ingangsdatum: v => mapStringToLocalDate(v.polis.ingangsdatum) || LocalDate.of(2019, 1, 1),
  einddatum: v =>
    v.polis.ingangsdatum && v.polis.looptijdInMaanden
      ? addMonths(mapStringToLocalDate(v.polis.ingangsdatum), v.polis.looptijdInMaanden)
      : mapStringToLocalDate(v.polis.ingangsdatum),
  looptijd: v => mapJaarMaandInputFromLooptijdDl2Ui(v.polis.looptijdInMaanden),
  uwBemiddeling: v => v.polis.uwBemiddeling || false,
  uitkeringssoort: v => v.uitkeringssoort,
  omschrijving: () => "",
  meenemen: v => v.meenemen,
  wijzigingenInDoorlopendProductOvernemen: v => v.wijzigingenInDoorlopendeProductenOvernemen,
  soortProduct: v => v.soortProduct,
  productId: v => v.productId,
  renteboxCode: v => null
});

export const mapOrvVergelijkerResultaat2DataGridResultaat = (
  data: OrvVergelijkerResultaat[],
  beschikbarePartijenInstellingen: VerzekeraarPartij[],
  filterList: BeschikbareProductenType[]
): OrvVergelijkerModalDataType[] => {
  return data
    .filter(
      c =>
        beschikbarePartijenInstellingen.find(k => k.code === c.maatschappijCode) &&
        partijFilter({ maatschappijCode: c.maatschappijCode, code: c.productcode } as OrvBasis, beschikbareOrvProducten)
    )
    .map(res => ({
      ...res,
      status: res.validationResults?.length ? OrvVergelijkerResultaatStatus.Rood : OrvVergelijkerResultaatStatus.Groen
    }))
    .sort((a, b) => {
      const iconA = a["status"];
      const iconB = b["status"];
      const eerstePremieA = a["eerstePremieBedrag"] || 0;
      const eerstePremieB = b["eerstePremieBedrag"] || 0;
      const orderByVal = iconA === "Groen" && iconB === "Groen" ? Math.sign(eerstePremieA - eerstePremieB) : -1;

      const iconVal = {
        Groen: 2,
        Rood: 0
      };

      return iconA === iconB ? orderByVal : iconVal[iconB] - iconVal[iconA];
    });
};

const berekenDekkingEinddatum = (v: OrvProduct): LocalDate | null => {
  const looptijd = mapJaarMaandInputFromLooptijdDl2Ui(v.polis.looptijdInMaanden);
  const jaren = looptijd.jaren || 0;
  const maanden = looptijd.maanden || 0;
  const newEinddatum = addMonths(
    addYears(mapStringToLocalDate(v.polis.ingangsdatum) || LocalDate.of(2019, 1, 1), jaren),
    maanden
  );
  return newEinddatum;
};

const berekenEindleeftijd = (
  aanvrager1: KlantnaamType | null,
  aanvrager2: KlantnaamType | null,
  product: OrvProduct
): number | null => {
  const eindDatumDekkingProduct = berekenDekkingEinddatum(product) || LocalDate.now();
  const vergelijkDatumAanvrager1 =
    product.soortProduct === SoortOrvProductOptions.AnwHiaatVerzekering && aanvrager1 && aanvrager1.aowdatum
      ? aanvrager1.aowdatum
      : eindDatumDekkingProduct;
  const vergelijkDatumAanvrager2 =
    product.soortProduct === SoortOrvProductOptions.AnwHiaatVerzekering && aanvrager2 && aanvrager2.aowdatum
      ? aanvrager2.aowdatum
      : eindDatumDekkingProduct;

  const newEindleeftijdAanvrager1 =
    aanvrager1?.geboortedatum?.until(vergelijkDatumAanvrager1, ChronoUnit.YEARS) ?? null;
  const newEindleeftijdAanvrager2 =
    aanvrager2?.geboortedatum?.until(vergelijkDatumAanvrager2, ChronoUnit.YEARS) ?? null;
  const verzekerden = mapKlantenNaarAanvragerKeuze(product.verzekerdeKlantIds, aanvrager1, aanvrager2);

  if (verzekerden === AanvragerKeuze.Aanvrager1) return newEindleeftijdAanvrager1;
  if (verzekerden === AanvragerKeuze.Aanvrager2) return newEindleeftijdAanvrager2;
  return null;
};

export const mapDekkingDl2Ui = createMapToUi(dekkingSchema)
  .with<{ aanvrager1: KlantnaamType | null; aanvrager2: KlantnaamType | null; product: OrvProduct }>()
  .from<OrvDekking>({
    annuiteitspercentage1: v => v.annuiteits1Percentage || null,
    annuiteitspercentage2: v => v.annuiteits2Percentage || null,
    dekkingDaaltTotAanvrager1: v => v.dekkingDaaltTot1Bedrag || null,
    dekkingDaaltTotAanvrager2: v => v.dekkingDaaltTot2Bedrag || null,
    reservewaarde: v => v.reservewaarde ?? null,
    verzekerdKapitaalAanvrager1: v => v.verzekerdKapitaal1Bedrag || null,
    verzekerdKapitaalAanvrager2: v => v.verzekerdKapitaal2Bedrag || null,
    verzekeringsmotief: v => v.verzekeringsmotief || null,
    doelVerzekering: v => v.doelVerzekering || null,
    basisdekking: v => v.basisdekking,
    einddatum: (v, { product }) => berekenDekkingEinddatum(product),
    verzekerdMaandbedrag: v => v.verzekerdMaandbedrag,
    eindleeftijd: (v, { aanvrager1, aanvrager2, product }) => berekenEindleeftijd(aanvrager1, aanvrager2, product),
    uitkeringBij: v => v.uitkeringBij,
    duurDalingInJaren1: v => v.duurDalingInJaren1,
    duurDalingInJaren2: v => v.duurDalingInJaren2
  });

export const mapRisicoPremieScenario = createMapToUi(premieSpecificatieModalSchema).from<OrvResultaatPremie[]>({
  termijnPremie: v => v.map(c => ({ bedrag: c.bedrag, percentage: null }))
});

export const mapPremieGegevensDl2Ui = createMapToUi(orvPremieGegevensSchema).from<OrvPremie>({
  premieAftrekbaar: v => v.premieAftrekbaar,
  verkortePremieduur: v => (typeof v.verkortePremieduur === "boolean" ? v.verkortePremieduur : null),
  looptijd: v => mapJaarMaandInputFromLooptijdDl2Ui(v.premieduurInMaanden),
  betalingstermijn: v => v.betalingsTermijn as BetalingsTermijnType,
  risicoPremieLaag: v => v.premieBedrag || null,
  premiespecificatie: v => ({
    termijnPremie:
      v.premiesPerJaar && v.premiesPerJaar.length
        ? v.premiesPerJaar.map(
          (x): ScenarioCardInput => {
            return { bedrag: x.premieTotaalBedrag, percentage: null };
          }
        )
        : []
  }),
  einddatumPremieBetaling: _ => addYears(LocalDate.of(2019, 1, 1), 30)
});

export const mapVerpandingDl2Ui = createMapToUi(orvVerpandingSchema)
  .with<{
    verzekerdKapitaalAanvrager1: number | null;
    verzekerdKapitaalAanvrager2: number | null;
    leningdelen: LeningdeelReadOnly[] | null;
  }>()
  .from<OrvVerpanding>({
    verpandAanGeldverstrekker: v => v.verpandAanGeldverstrekker || false,
    bedoeldVoorAflossing: v => v.bedoeldVoorAflossing || false,
    bedoeldVoorAflossingSpecificatie: (v, c) => ({
      aflosproducten: mapAflosProductenDlToUi(v.aflosproducten).aflosproducten.map(ap => ({
        ...ap,
        leningdeelBedrag: c.leningdelen?.find(ld => ld.leningdeelId === ap.productId)?.leningdeelBedrag || null
      }))
    }),
    indicatieveUitkerendeFaseSpecificatie: _ => null,
    inkomensaanvulling: v => v.inkomensaanvulling || false,
    inkomensaanvullingSpecificatie: (v, c) => ({
      ...verpandingSchema.default().inkomensaanvullingSpecificatie,
      aanvullingInkomenBijOverlijdenAanvrager1: v.inkomensaanvullingAanvrager1 ? true : false,
      gehanteerdTariefAanvrager1: v.inkomensaanvullingAanvrager1?.gehanteerdTariefPercentage ?? null,
      gewensteUitkeringAanvrager1: v.inkomensaanvullingAanvrager1?.gewensteUitkeringBedrag ?? null,
      gewensteUitkeringPerPeriodeAanvrager1:
        (v.inkomensaanvullingAanvrager1 && v.inkomensaanvullingAanvrager1.uitkeringstermijn) ||
        BetalingsTermijnType.Maand,
      aanvullingInkomenBijOverlijdenAanvrager2: v.inkomensaanvullingAanvrager2 ? true : false,
      gehanteerdTariefAanvrager2: v.inkomensaanvullingAanvrager2?.gehanteerdTariefPercentage ?? null,
      gewensteUitkeringAanvrager2: v.inkomensaanvullingAanvrager2?.gewensteUitkeringBedrag ?? null,
      gewensteUitkeringPerPeriodeAanvrager2:
        (v.inkomensaanvullingAanvrager2 && v.inkomensaanvullingAanvrager2.uitkeringstermijn) ||
        BetalingsTermijnType.Maand,
      verzekerdKapitaalAanvrager1: c.verzekerdKapitaalAanvrager1,
      verzekerdKapitaalAanvrager2: c.verzekerdKapitaalAanvrager2,
      duurUitkeringAanvrager1: mapPeriodeInMaandenDl2Ui(v.inkomensaanvullingAanvrager1?.duurInMaanden),
      duurUitkeringAanvrager2: mapPeriodeInMaandenDl2Ui(v.inkomensaanvullingAanvrager2?.duurInMaanden)
    }),
    indicatieveUitkerendeFase: _ => null
  });

export const mapInformatieVoorVerzendingAanvraagDl2Ui = createMapToUi(informatieVoorVerzendingAanvraagSchema).from<
  OrvAanvraag
>({
  akkoordMetDigitaleCommunicatie: v =>
    typeof v.akkoordMetDigitaleCommunicatie === "boolean" ? v.akkoordMetDigitaleCommunicatie : null,
  akkoordMetDigitalePolis: v => (typeof v.akkoordMetDigitalePolis === "boolean" ? v.akkoordMetDigitalePolis : null),
  strafrechtelijkVerledenAanvrager1: v =>
    typeof v.strafrechtelijkVerledenAanvrager1 === "boolean" ? v.strafrechtelijkVerledenAanvrager1 : null,
  strafrechtelijkVerledenAanvrager2: v =>
    typeof v.strafrechtelijkVerledenAanvrager2 === "boolean" ? v.strafrechtelijkVerledenAanvrager2 : null,
  toelichtingGelezenAkkoordMetSlotverklaring: v =>
    typeof v.toelichtingGelezenAkkoordMetSlotverklaring === "boolean"
      ? v.toelichtingGelezenAkkoordMetSlotverklaring
      : null
});

export const mapProductDetailDl2Ui = createMapToUi(orvSchema)
  .with<{
    aanvrager1: KlantnaamType | null;
    aanvrager2: KlantnaamType | null;
    leningdelen: LeningdeelReadOnly[] | null;
  }>()
  .from<OrvProduct>({
    partijCode: v => v.polis.maatschappijCode || "",
    productCode: v => prefixWithZero(v.polis.productcode) || "",
    product: v => mapProductDl2Ui(v),
    verzekeringnemers: (v, { aanvrager1, aanvrager2 }) => ({
      verzekeringnemers: mapKlantenNaarAanvragerKeuze(v.verzekeringnemerKlantIds, aanvrager1, aanvrager2)
    }),
    verzekerden: (v, { aanvrager1, aanvrager2 }) => ({
      verzekerden: mapKlantenNaarAanvragerKeuze(v.verzekerdeKlantIds, aanvrager1, aanvrager2),
      premiesplitsing: v.premiesplitsing ?? null
    }),
    dekking: (v, { aanvrager1, aanvrager2 }) => mapDekkingDl2Ui({ aanvrager1, aanvrager2, product: v })(v.dekking),
    premieGegevens: v => mapPremieGegevensDl2Ui(v.premie),
    verpanding: (v, c) =>
      mapVerpandingDl2Ui({
        verzekerdKapitaalAanvrager1: v.dekking.verzekerdKapitaal1Bedrag,
        verzekerdKapitaalAanvrager2: v.dekking.verzekerdKapitaal2Bedrag,
        leningdelen: c.leningdelen
      })(v.verpanding || null),
    informatieVoorVerzendingAanvraag: v => (v.aanvraag ? mapInformatieVoorVerzendingAanvraagDl2Ui(v.aanvraag) : null),
    premieverloop: v => v.premieverloop,
    incorrecteProductkenmerken: v => orvSchema.default().incorrecteProductkenmerken
  });

function mapOrvVergelijkenModal(values: OrvDlEntry): OrvVergelijkenModalType {
  const ingangsDatumDL = mapStringToLocalDate(values.ingangsdatumVoorstel) ?? LocalDate.now().plusDays(1);
  const firstDayofNextMonth = LocalDate.now()
    .withDayOfMonth(1)
    .plusMonths(1);
  const aanvangsDatum = ingangsDatumDL.isAfter(LocalDate.now()) ? ingangsDatumDL : firstDayofNextMonth;
  const orvModal = {
    verzekerden: {
      aanvrager1: values.aanvrager1 ? mapKlantnaamDl2Ui(values.aanvrager1) : null,
      aanvrager2: values.aanvrager2 ? mapKlantnaamDl2Ui(values.aanvrager2) : null,
      verzekerde1Roker: values.aanvrager1?.roker || false,
      verzekerde2Roker: values.aanvrager2?.roker ?? null,
      verzekerden: AanvragerKeuze.Aanvrager1
    },
    verzekering: {
      aanvangsdatum: aanvangsDatum,
      annuiteitsPercentage: 6,
      duurInJaren: 30,
      verpand: false,
      verzekerdKapitaalBedrag: null,
      verzekeringsvorm: OrvUitkeringssoortOptions.Gelijkblijvend
    }
  };
  return orvVergelijkenModalSchema.cast(orvModal);
}

function dl2ui(values: OrvDlEntry): OrvsState {
  const aanvrager1 = values.aanvrager1 ? mapKlantnaamDl2UiMetKinderen(values.aanvrager1) : null;
  const aanvrager2 = values.aanvrager2 ? mapKlantnaamDl2UiMetKinderen(values.aanvrager2) : null;

  const orvState: OrvsState = {
    producten: values.producten.map(
      (productDl): OrvType => {
        return mapProductDetailDl2Ui({ aanvrager1, aanvrager2, leningdelen: values.leningdelen })(productDl);
      }
    ),
    aanvrager1,
    aanvrager2,
    ingangsdatumVoorstel: mapStringToLocalDate(values.ingangsdatumVoorstel),
    orvVergelijkenModal: mapOrvVergelijkenModal(values),
    aflosProductNew: mapAflosProductNew(values.leningdelen, values.kredieten)
  };
  return orvsSchemaBase.cast(orvState);
}

export function mapOrvDlToUi(orvId: string, data: OrvDlOutput): OrvsState | null {
  const orv = data && data.orvs ? data.orvs[orvId] : null;
  if (!orv) {
    return null;
  }
  return dl2ui(orv);
}

export function mapDlTargetToOrvUiField(target: string): UiName | null {
  const map: FieldMap<OrvDlEntry> = {
    producten: {
      dekking: {
        verzekerdKapitaal2Bedrag: {
          field: "producten[i].dekking.verzekerdKapitaalAanvrager2",
          label: "Verzekerd kapitaal aanvrager 2"
        },
        einddatum: { field: "producten[i].dekking.einddatum", label: "Verzekerd kapitaal aanvrager 2" }
      },
      polis: {
        ingangsdatum: { field: "producten[i].product.ingangsdatum", label: "Ingangsdatum" }
      }
    }
  };
  return target2field(map, target);
}

export const getVerzekerdenOrv = (aanvragers: AanvragerKeuze): string => {
  switch (aanvragers) {
    case AanvragerKeuze.Aanvrager1:
      return "Aanvrager 1";
    case AanvragerKeuze.Aanvrager2:
      return "Aanvrager 2";
    case AanvragerKeuze.Beiden:
      return "Beiden";
  }
};

export const mapOrvVergelijkerResultaat2OrvType = (resultaat: OrvVergelijkenModalType | null): OrvType | null => {
  if (!resultaat) return null;

  const beideGekozen = resultaat.verzekerden.verzekerden === AanvragerKeuze.Beiden;
  const aanvrager1Gekozen = resultaat.verzekerden.verzekerden === AanvragerKeuze.Aanvrager1;
  const heeftAnnuïteitspercentage =
    resultaat.verzekering.verzekeringsvorm === OrvUitkeringssoortOptions.AnnuïtairDalend
      ? resultaat.verzekering.annuiteitsPercentage
      : null;
  return {
    partijCode: resultaat.selectedResultaat?.maatschappijCode ?? "",
    productCode: resultaat.selectedResultaat?.productcode?.toString() ?? "",
    premieverloop:
      PremieverloopOptions[resultaat.selectedResultaat?.premieverloop ?? PremieverloopOptions.Gelijkblijvend],
    product: {
      ...orvProductSchema.default(),
      ingangsdatum: resultaat.verzekering.aanvangsdatum,
      partijNaam: resultaat.selectedResultaat?.maatschappijOmschrijving ?? "Onafhankelijk",
      productNaam: resultaat.selectedResultaat?.productnaam ?? "",
      looptijd: {
        jaren: resultaat.verzekering.duurInJaren,
        maanden: 0
      },
      uitkeringssoort: resultaat.verzekering.verzekeringsvorm,
      einddatum: addYears(resultaat.verzekering.aanvangsdatum, resultaat.verzekering.duurInJaren)
    },
    verzekeringnemers: { verzekeringnemers: AanvragerKeuze.Aanvrager1 },
    verzekerden: {
      verzekerden: resultaat.verzekerden.verzekerden,
      premiesplitsing: false
    },
    dekking: {
      ...dekkingSchema.default(),
      verzekerdKapitaalAanvrager1:
        beideGekozen || aanvrager1Gekozen ? resultaat.verzekering.verzekerdKapitaalBedrag : null,
      annuiteitspercentage1: beideGekozen || aanvrager1Gekozen ? heeftAnnuïteitspercentage : null,
      verzekerdKapitaalAanvrager2: beideGekozen ? resultaat.verzekering.verzekerdKapitaalBedrag : null,
      annuiteitspercentage2: beideGekozen ? heeftAnnuïteitspercentage : null,
      reservewaarde: resultaat.selectedResultaat?.reservewaarde || false
    },
    verpanding: {
      ...orvVerpandingSchema.default(),
      verpandAanGeldverstrekker: resultaat.verzekering.verpand,
      bedoeldVoorAflossing: resultaat.verzekering.verpand
    },
    informatieVoorVerzendingAanvraag: informatieVoorVerzendingAanvraagSchema.default(),
    premieGegevens: {
      ...orvPremieGegevensSchema.default(),
      looptijd: {
        jaren: resultaat.selectedResultaat?.premieduurInJaren ?? null,
        maanden: 0
      },
      //'totalePremieBedrag' word niet gebruikt in het orv scherm.
      //risicoPremieHoog heb je niet bij ORV. Dus risicopremielaag is gebruikt.
      risicoPremieLaag: resultaat.selectedResultaat?.eerstePremieBedrag ?? 0
    },
    incorrecteProductkenmerken: orvSchema.default().incorrecteProductkenmerken
  };
};
