import React, { ReactElement } from "react";
import { RapportageElementenType } from "./infra/rapportage-structuur-types";

import classes from "./rapportage-structuur.module.scss";
import { RapportageStructuurElementen, RapportageStructuurSamenstellenProps } from "./rapportage-structuur-elementen";

export const RapportageStructuur = ({
  elementen,
  parent,
  parentKey
}: RapportageStructuurSamenstellenProps): ReactElement => {
  return (
    <div className={classes.structuur_table}>
      {elementen.map((element: RapportageElementenType, i: number) => {
        return (
          <RapportageStructuurElementen
            naam={element.naam}
            elementen={element.elementen}
            key={`${parent}.elementen[${i}]`}
            parentKey={parentKey}
            parent={`${parent}.elementen[${i}]`}
          />
        );
      })}
    </div>
  );
};
