import { FormikContextType, connect } from "formik";

import React, { ReactElement } from "react";
import BankgarantieModal from "../bankgarantie-modal/bankgarantie-modal";
import { LabeledCurrencyInput, Icon } from "adviesbox-shared";
import { ModalButton } from "adviesbox-shared";
import { FinancieringsbehoefteState } from "../infra/financieringsbehoefte-types";

const Bankgarantie = ({
  formik: { values, setFieldValue }
}: {
  formik: FormikContextType<FinancieringsbehoefteState>;
}): ReactElement => {
  return (
    <LabeledCurrencyInput
      caption="Bankgarantie"
      name="financieringsopzet.bankgarantie"
      readonly={true}
      appendChildren={
        <ModalButton
          parent="financieringsopzet.bankgarantieModal"
          aria-label="Bankgarantie button"
          content={<Icon name="specificatie" alt="Bankgarantie" />}
        >
          <BankgarantieModal
            data={values.financieringsopzet.bankgarantieModal}
            onSave={(result): void => {
              setFieldValue("financieringsopzet.bankgarantie", result.kostenBankgarantie);
              setFieldValue("financieringsopzet.bankgarantieModal", result);
            }}
          />
        </ModalButton>
      }
    />
  );
};

export default connect<{}, FinancieringsbehoefteState>(Bankgarantie);
