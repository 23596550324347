import React, { ReactElement } from "react";
import { QuestionSpecCombined, QuestionType } from "../../klantprofiel-schema";

type QuestionInstructionTextProps = {
  data: QuestionSpecCombined;
};
export const QuestionInstructionText = ({ data }: QuestionInstructionTextProps): ReactElement => {
  if (data.type !== QuestionType.instructionText) return <>{`invalid QuestionType: ${data.type}`}</>;

  return (
    <div className="mt-3 text-wrap" style={{ maxWidth: "450px" }}>
      {data.schema.omschrijving && <h2>{data.schema.omschrijving}</h2>}
    </div>
  );
};
