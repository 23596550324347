/*istanbul ignore file*/
import { LocalDate } from "@js-joda/core";
import { hasValue, localDateSchema, nullableLocalDateSchema } from "adviesbox-shared";
import * as Yup from "yup";
import { InferType } from "yup";
import { NotarisType as NotarisTypeForms, Financieringsoort } from "../../.generated/forms/formstypes";
import { BankgarantieinstellingPartij, Notarissen } from "../../.generated/instellingen-forms/instellingen-formstypes";
import adresSchema from "../../shared/generic-parts/adres/schema";
import { berekenDateSchema } from "../../shared/generic-parts/bereken-date/schema";
import { berekenInputSchema } from "../../shared/types";
import { WithSaveData } from "../../shared/utils/save-data";
import { yupEnum, yupNullableEnum } from "../../shared/utils/yup-enum";
import { getBankgarantieTextResources } from "./bankgarantie-resources";
import { PlatformFoutenState } from "adviesbox-shared/utils/types";

export enum InstellingCodes {
  Geen = "Geen",
  NWB = "NWB",
  CA = "CA",
  Onafhankelijk = "XX"
}

export enum Geslacht {
  Geen = "Geen",
  Man = "Man",
  Vrouw = "Vrouw"
}

export const aanvrager1Schema = Yup.object({
  klantId: Yup.string()
    .default("")
    .nullable(),
  voorletters: Yup.string()
    .default("")
    .nullable(),
  voorvoegsel: Yup.string()
    .default("")
    .nullable(),
  voornamen: Yup.string()
    .default("")
    .nullable(),
  achternaam: Yup.string()
    .default("")
    .nullable(),
  geslacht: yupEnum(Geslacht)
    .default(Geslacht.Man)
    .nullable(),
  geboortedatum: localDateSchema.nullable(),
  aowdatum: localDateSchema.nullable(),
  roker: Yup.bool()
    .default(false)
    .nullable()
});
export type Aanvrager1Type = InferType<typeof aanvrager1Schema>;

export const aanvrager2Schema = Yup.object({
  klantId: Yup.string()
    .default("")
    .nullable(),
  voorletters: Yup.string()
    .default("")
    .nullable(),
  voorvoegsel: Yup.string()
    .default("")
    .nullable(),
  voornamen: Yup.string()
    .default("")
    .nullable(),
  achternaam: Yup.string()
    .default("")
    .nullable(),
  geslacht: yupEnum(Geslacht)
    .default(Geslacht.Man)
    .nullable(),
  geboortedatum: localDateSchema.nullable(),
  aowdatum: localDateSchema.nullable(),
  roker: Yup.bool()
    .default(false)
    .nullable()
});
export type Aanvrager2Type = InferType<typeof aanvrager1Schema>;

export const productSchema = Yup.object({
  instelling: Yup.string().default(InstellingCodes.Geen),
  productkeuze: Yup.string().default(""),
  tussenpersoonnummer: Yup.string().default(""),
  aanvraagVia: Yup.string().default(""),
  agentnummer: Yup.string()
    .nullable()
    .default("")
});

export type ProductType = InferType<typeof productSchema>;

export const verkoperSchema = Yup.object({
  achternaam: Yup.string().default(""),
  voorletters: Yup.string().default(""),
  tussenvoegsel: Yup.string().default(""),
  woonplaats: Yup.string().default("")
});
export type VerkoperType = InferType<typeof verkoperSchema>;

export const verkopersSchema = Yup.object({
  bedrijfsnaam: Yup.string().default(""),
  verkoper1: verkoperSchema,
  verkoper2: verkoperSchema
});
export type VerkopersType = InferType<typeof verkopersSchema>;

export const onroerendeZaakSchema = Yup.object({
  isNieuwbouw: Yup.boolean().default(false),
  adres: adresSchema,
  bouwnummer: Yup.string()
    .nullable()
    .default(null)
});
export type OnroerendeZaakType = InferType<typeof onroerendeZaakSchema>;

export const pandSchema = Yup.object({
  aankoopprijs: Yup.number()
    .nullable()
    .default(null),
  hypotheekbedrag: Yup.number()
    .nullable()
    .default(null),
  gehanteerdeMarktwaarde: Yup.number()
    .nullable()
    .default(null),
  nhg: Yup.boolean().default(false),
  eigenMiddelen: Yup.number()
    .nullable()
    .default(null),
  overbrugging: Yup.number()
    .nullable()
    .default(null),
  geldverstrekker: Yup.string()
    .nullable()
    .default("")
});
export type PandType = InferType<typeof pandSchema>;

export const notarisSchema = Yup.object({
  gekozenNotariskantoorId: Yup.string().default("Anders"),
  kantoornaam: Yup.string().default(""),
  adres: adresSchema,
  telefoonnummer: Yup.string().default(""),
  typeNotaris: yupEnum(NotarisTypeForms).default(NotarisTypeForms.Geen)
});
export type NotarisType = InferType<typeof notarisSchema>;

const passeerdatumSchema = berekenDateSchema.shape({
  datum: nullableLocalDateSchema.default(null)
});

export const bankgarantieKaartSchema = Yup.object({
  garantiebedrag: Yup.number()
    .nullable()
    .default(null)
    .test({
      test: function(value: any) {
        const readOnlyInstelling = this.parent?.readOnlyInstelling;
        const notValid = readOnlyInstelling === InstellingCodes.CA && !value;
        if (notValid)
          return this.createError({
            message: getBankgarantieTextResources("garantiebedrag")
          });

        return true;
      }
    }),
  garantieKostenberekenen: berekenInputSchema,
  vergoedingKorting: Yup.string().default(""),
  verzekerdeBankgarantie: Yup.boolean().default(false),
  afgiftedatumBankgarantie: localDateSchema,
  passeerdatum: passeerdatumSchema,
  datumOndertekeningKoopakte: nullableLocalDateSchema,
  ingangsdatumGarantie: nullableLocalDateSchema,
  einddatumGarantie: nullableLocalDateSchema.test({
    message: getBankgarantieTextResources("einddatumGarantieIsEerderDanIngangsDatum"),
    test: function(value: LocalDate | null) {
      if (hasValue(value)) {
        const ingangsdatum: LocalDate | null = this.parent.ingangsdatumGarantie;
        if (hasValue(ingangsdatum)) {
          return value >= ingangsdatum;
        }
      }
      return true;
    }
  }),
  readOnlyInstelling: Yup.string().default(InstellingCodes.Geen)
});
export type bankgarantieKaartType = InferType<typeof bankgarantieKaartSchema>;

export const bankgarantieSchema = Yup.object({
  aanvrager1: aanvrager1Schema,
  aanvrager2: aanvrager2Schema.nullable(),
  product: productSchema,
  verkopers: verkopersSchema,
  onroerendeZaak: onroerendeZaakSchema,
  pand: pandSchema,
  notaris: notarisSchema,
  bankgarantieKaart: bankgarantieKaartSchema,
  soortFinanciering: yupNullableEnum(Financieringsoort).default(null),
  aanvraagVerzendenError: Yup.bool()
    .nullable()
    .default(false)
});
export type BankgarantieState = InferType<typeof bankgarantieSchema> & PlatformFoutenState;
export type BankgarantieProps = {
  data: BankgarantieState;
  notarissenData: Notarissen | null;
  productInstellingOptions: BankgarantieinstellingPartij[];
} & WithSaveData<BankgarantieState>;
