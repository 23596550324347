import { OnderpandModalContentState } from "./OnderpandModal";
import { createISWSideEffect, initISWSideEffect } from "../isw-side-effects/create-isw-helpers";
import { HdnAnders } from "../../generic-parts/onderpand/schema";
import { hasValue } from "adviesbox-shared";

const onderpandDraftSideEffects = createISWSideEffect<OnderpandModalContentState>(({ has, draft }): void => {
  if (has.taxatieAanwezig.changed && !draft.taxatieAanwezig) {
    if (draft.taxatieDatum) {
      draft.taxatieDatum = null;
    }
  }

  // opschonen constructie/constructie-omschrijving indien niet meer van toepassing.
  if (has.verkoopOnderVoorwaarden.changed || has.constructie.changed) {
    if (!draft.verkoopOnderVoorwaarden && hasValue(draft.constructie)) {
      draft.constructie = null;
      draft.constructieOmschrijving = null;
    }

    if (draft.constructie !== `${HdnAnders}` && !!draft.constructieOmschrijving) {
      draft.constructieOmschrijving = null;
    }
  }

  if (has.constructie.changed && draft.constructie !== "99") {
    draft.erfpachtConstructieAnders = null;
  }
});

export const determineOnderpandSideEffects = initISWSideEffect(onderpandDraftSideEffects)();
