import {
  Card,
  CardWrapper,
  ErrorPage,
  PageLoading,
  useAdviesboxDataRepository,
  useRequestInit
} from "adviesbox-shared";
import { connect, FormikContextType } from "formik";
import produce from "immer";
import React, { ReactElement, useContext } from "react";
import { SoortOrvProductOptions, OrvResultaat } from "../../.generated/forms/formstypes";
import Dekking from "../../producten-overzicht/dekking/dekking";
import InformatieVoorVerzendingAanvraag from "../../producten-overzicht/informatie-voor-verzending-aanvraag/informatie-voor-verzending-aanvraag";
import { isKenmerkError, KenmerkenError, OrvKenmerken } from "../../producten-overzicht/infra/product-kenmerken-types";
import { ProductkenmerkenContext } from "../../producten-overzicht/infra/producten-context";
import { SituatieSoort } from "../../producten-overzicht/infra/producten-overzicht-types";
import PremieGegevens from "../../producten-overzicht/premie-gegevens/premie-gegevens";
import Product from "../../producten-overzicht/product/product";
import Verpanding from "../../producten-overzicht/verpanding/verpanding";
import Verzekerden from "../../producten-overzicht/verzekerden/verzekerden";
import VerzekeringNemers from "../../producten-overzicht/verzekering-nemers/verzekering-nemers";
import { ISWSideEffects } from "../../shared/components/isw-side-effects/isw-side-effects";
import { useHdnData } from "../../shared/hooks/use-hdn-data";
import HdnAanvraag from "../hdn-aanvraag/hdn-aanvraag";
import { determineOrvDetailsSideEffects } from "../infra/determine-orv-side-effects";
import { mapDlTargetToOrvUiField, mapRisicoPremieScenario } from "../infra/map-orv-dl-2-ui";
import { mapProductUi2Dl } from "../infra/map-orv-ui-2-dl";
import { OrvsState } from "../infra/orv-schema";

type OrvDetailProps = {
  selected: number;
  situatie: SituatieSoort;
};

const OrvDetails = ({
  selected,
  situatie,
  formik: { values }
}: OrvDetailProps & { formik: FormikContextType<OrvsState> }): ReactElement => {
  const { producten, aanvrager1, aanvrager2, aflosProductNew } = values;
  const kenmerkenContext = useContext(ProductkenmerkenContext);
  const list = useHdnData("AX", "MaatschappijType");
  const { settings, params } = useRequestInit<{ vestiging: string; voorstel: string }>();
  const selectedProduct = producten && producten[selected];
  const partijNaam = list.hdnKeuzelijst[aflosProductNew.find(ld => ld.aflossen)?.maatschappijCode ?? ""];
  const kenmerken: OrvKenmerken | null | KenmerkenError = kenmerkenContext.getProductKenmerken("Orv", selectedProduct.partijCode, selectedProduct.productCode);
  const isAnwHiaatVerzekering = selectedProduct.product.soortProduct === SoortOrvProductOptions.AnwHiaatVerzekering;
  const url = `${settings.klantdossiersFormsOrigin}/Voorstellen/${params.voorstel}/Orv`;

  const { fetchData, loading } = useAdviesboxDataRepository<OrvResultaat, OrvsState>(url, {
    method: "POST",
    getDataId: () => "",
    mapDlToUi: (_, b) => {
      const mappedScenario = mapRisicoPremieScenario(b?.premies);

      const update = produce(values, v => {
        /* istanbul ignore else */
        if (
          kenmerken &&
          !isKenmerkError(kenmerken) &&
          (kenmerken.premie.heeftPremieverloopVariabel || kenmerken.premie.risicopremiespecificatieTonen)
        ) {
          v.producten[selected].premieGegevens.premiespecificatie = mappedScenario;
        }
        v.producten[selected].premieGegevens.risicoPremieLaag = b?.premies?.find(() => true)?.bedrag ?? null;

        const looptijdMaanden = b?.premies?.find(/* istanbul ignore next */ () => true)?.looptijdInMaanden;
        if (looptijdMaanden) {
          v.producten[selected].premieGegevens.looptijd = {
            jaren: Math.floor(looptijdMaanden / 12),
            maanden: looptijdMaanden % 12
          };
        }
      });

      return update;
    },
    mapUiToDl: () => ({
      orvProduct: mapProductUi2Dl({ aanvrager1, aanvrager2, dekking: selectedProduct.dekking })(selectedProduct)
    }),
    mapTargetToUiField: mapDlTargetToOrvUiField
  });

  if (!kenmerken) return <PageLoading />;

  if (kenmerken && isKenmerkError(kenmerken)) {
    if (kenmerken.reden === "...Geen kenmerken ontvangen") {
      selectedProduct.incorrecteProductkenmerken = true;
      return (
        <>
          <CardWrapper className="px-3" maxRowCount={12}>
            <Card title={"Probleem met dit product"}>
              <div>Er is iets fout gegaan bij het ophalen van de kenmerken. Dit kan betekenen:</div>
              <ul>
                <li>
                  <div>
                    Het product in het overzicht kan niet meer worden afgesloten, omdat het niet meer bestaat. In dit
                    geval zal er een ander product gekozen moeten worden.
                  </div>
                </li>
                <li>
                  <div>
                    Als je denkt dat dit een fout is, probeer het dan later nog een keer. Lukt het dan nog steeds niet,
                    dan kun je contact opnemen met de Servicedesk van Intersoftware.
                  </div>
                </li>
              </ul>
            </Card>
          </CardWrapper>
        </>
      );
    }
    return <ErrorPage error={new Error(`Fout bij het ophalen van producteigenschappen: ${kenmerken.reden}`)} />;
  }

  const verzendingAanvraagKenmerk =
    kenmerken.aanvraag.akkoordMetDigitaleCommunicatieTonen ||
    kenmerken.aanvraag.akkoordMetDigitalePolisTonen ||
    kenmerken.aanvraag.sprakeVanStrafrechtelijkVerledenTonen ||
    kenmerken.aanvraag.toelichtingGelezenAkkoordMetSlotverklaringTonen;

  return (
    <>
      <ISWSideEffects<OrvsState>
        runOnFirstRender={true}
        sync={determineOrvDetailsSideEffects({ selected: selected, kenmerken: kenmerken })}
      />
      <CardWrapper className="px-3" maxRowCount={12}>
        <Card
          title={
            situatie === "voorstel"
              ? `${selectedProduct.product.doorlopend ? "Doorlopend" : "Nieuw"} product`
              : "Huidig product"
          }
        >
          <Product
            selected={selected}
            situatie={situatie}
            kenmerken={kenmerken.product}
            productSoort="orv"
            isStarterslening={false}
          />
        </Card>
        {!isAnwHiaatVerzekering && (
          <Card title="Verzekeringnemers">
            <VerzekeringNemers selected={selected} kenmerken={kenmerken.verzekeringnemer} />
          </Card>
        )}
        <Card title="Verzekerden">
          <Verzekerden
            selected={selected}
            kenmerken={kenmerken.verzekerden}
            caption={isAnwHiaatVerzekering ? "Verzekerden" : null}
          />
        </Card>
        <Card title="Dekking">
          <Dekking selected={selected} kenmerken={kenmerken.dekking} situatie={situatie} />
        </Card>
        {kenmerken.premie.premiegegevensTonen && (
          <Card title="Premiegegevens">
            <PremieGegevens
              selected={selected}
              kenmerken={kenmerken.premie}
              validatieKenmerken={kenmerken.validaties}
              loading={loading}
              calculate={async (): Promise<number | null> => {
                await fetchData();
                return null;
              }}
            />
          </Card>
        )}
        {kenmerken.verpanding.verpandingTonen && (
          <Card title="Verpanding">
            <Verpanding
              selected={selected}
              list={list}
              waardeopbouwBedrag={null}
              verpandAanGeldverstrekkerTonen
              kenmerken={kenmerken.verpanding}
              showOnHavingItems={true}
              verpandAanPartij={partijNaam}
            />
          </Card>
        )}
        {verzendingAanvraagKenmerk && (
          <Card title="Informatie voor verzending aanvraag">
            <InformatieVoorVerzendingAanvraag selected={selected} kenmerken={kenmerken.aanvraag} />
          </Card>
        )}
        {kenmerken.hdnaanvraag.hDNAanvraagNietAanwezigTonen && (
          <Card title="Informatie">
            <HdnAanvraag kenmerken={kenmerken.hdnaanvraag} />
          </Card>
        )}
      </CardWrapper>
    </>
  );
};

export default connect<OrvDetailProps, OrvsState>(OrvDetails);
