import { ErrorPage, PageLoading, SettingsType, withErrorBoundary } from "adviesbox-shared";
import React, { ReactElement } from "react";

import InkomenEnFiscus from "./inkomen-en-fiscus";
import { InvalidAdviesboxResultErrorPage } from "../shared/components/fetch-error-page/fetch-error-page";
import { mapDlTargetToInkomenEnFiscusUiField } from "./infra/map-inkomen-en-fiscus-dl-target-to-ui-field";
import { mapInkomenEnFiscusDlToUi } from "./infra/map-inkomen-en-fiscus-dl-to-ui";
import { mapInkomenEnFiscusUiToDl } from "./infra/map-inkomen-en-fiscus-ui-to-dl";
import { useAdviesboxData } from "../shared/hooks/use-adviesbox-data";

type RouteParams = {
  vestiging: string;
  adviesdossier: string;
};

const createUrl = (s: SettingsType, p: RouteParams): string =>
  `${s.klantdossiersFormsOrigin}/Adviesdossiers/${p.adviesdossier}/InkomenFiscus`;

const InkomenEnFiscusAjax = (): ReactElement => {
  const { loading, error, data, platformData, saveData } = useAdviesboxData(
    createUrl,
    p => p.adviesdossier,
    mapInkomenEnFiscusDlToUi,
    mapInkomenEnFiscusUiToDl,
    mapDlTargetToInkomenEnFiscusUiField
  );

  if (loading) {
    return <PageLoading />;
  }

  if (error) {
    return <ErrorPage error={error} data={platformData} />;
  }

  if (!data) {
    return <InvalidAdviesboxResultErrorPage />;
  }

  return <InkomenEnFiscus saveData={saveData} {...data} />;
};

InkomenEnFiscusAjax.displayName = "InkomenEnFiscusAjax";

export default withErrorBoundary(InkomenEnFiscusAjax);
