import { KlantprofielVraagOptions } from "../../../.generated/forms/formstypes";
import { QuestionType, CardSpec, TitleType } from "../../infra/klantprofiel-schema";

export const risicoEnKennisQuestionSpecs: CardSpec[] = [
  {
    sections: [
      {
        title: TitleType.custom,
        customTitle: "Kennisniveau van algemene financiële onderwerpen",
        subtitle: "Geef per onderwerp het kennisniveau aan door het bolletje te slepen.",
        questions: [
          {
            question: KlantprofielVraagOptions.KennisFinancieelEnErvaring,
            type: QuestionType.slider
          },
          {
            question: KlantprofielVraagOptions.KennisFinancieleProducten,
            type: QuestionType.slider
          },
          {
            question: KlantprofielVraagOptions.KennisFiscaleSocialeWetgeving,
            type: QuestionType.slider
          },
          {
            question: KlantprofielVraagOptions.KennisFinancieelAdvies,
            type: QuestionType.slider
          }
        ]
      }
    ]
  },
  {
    questions: [
      {
        question: KlantprofielVraagOptions.WelkeMateFinancieleRisicos,
        type: QuestionType.radio
      }
    ]
  },
  {
    questions: [
      {
        question: KlantprofielVraagOptions.GeldVoorFinancieleTegenvallers,
        type: QuestionType.radio,
        showVerplichtToelichting: {
          question: KlantprofielVraagOptions.GeldVoorFinancieleTegenvallers,
          answer: 30602
        }
      },
      {
        question: KlantprofielVraagOptions.HoeveelGeldVoorFinancieleTegenvallers,
        type: QuestionType.bedrag,
        condition: [
          {
            question: KlantprofielVraagOptions.GeldVoorFinancieleTegenvallers,
            answer: 30601
          }
        ]
      },
      {
        title: TitleType.custom,
        customTitle: "Geef in de toelichting aan hoe u financiële tegenvallers denkt op te kunnen vangen.",
        question: "unbound", // this doesnt have any question-id
        type: QuestionType.instructionText,
        condition: [
          {
            question: KlantprofielVraagOptions.GeldVoorFinancieleTegenvallers,
            answer: 30602
          }
        ]
      }
    ]
  },
  {
    questions: [
      {
        question: KlantprofielVraagOptions.WatSpreektMeesteAanRisico,
        type: QuestionType.radio
      }
    ]
  }
];
