import { LabeledBevestigingInput, useRequestInit } from "adviesbox-shared";
import { FormikContextType, FormikProps } from "formik";
import React, { ReactElement } from "react";
import { Button, Card } from "react-bootstrap";
import { IngPriceOutput } from "../../.generated/forms/formstypes";
import Modal from "../../shared/components/modal/Modal";
import AdviesBoxFormik from "../../shared/utils/adviesbox-formik";
import { getHypotheekIngPricetoolTextResources } from "../infra/hypotheek-resources";
import { hypotheekOptiesIngPriceToolSchema } from "../infra/hypotheek-schema";
import { HypothekenState } from "../infra/hypotheek-types";
import { updateIngModal } from "./hypotheek-opties-ing-modal-helper";
import { mapIngPriceToolInput } from "./map-ing-price-tool";
import ingPriceToolOphalenRentes from "./ophalen-rentes-ing-price-tool";
import RenteOpbouw from "./renteopbouw-ing-modal";
import { useHdnData, UseHdnDataResult } from "../../shared/hooks/use-hdn-data";
import { ISWSideEffects } from "../../shared/components/isw-side-effects/isw-side-effects";
import { determineHypotheekOptiesIngSideEffects } from "./hypotheek-opties-ing-side-effects";
import { useRef } from "react";

export type HypotheekOptiesIngModalData = {};

type HypotheekoptiesIngModalProps = {
  data: HypothekenState;
  selected: number;
  onSave?: (data: HypothekenState) => void;
  closeModal?: () => void;
};

const HypotheekOptiesIngModal = ({
  data,
  onSave,
  closeModal,
  selected
}: HypotheekoptiesIngModalProps): ReactElement => {
  const { settings, params, user } = useRequestInit<{ vestiging: string; voorstel: string }>();
  const afloscodes: UseHdnDataResult = useHdnData("AX", "CodeDeelMijType");
  const hdnKeuzelijst = afloscodes.hdnKeuzelijst;
  const abortController = useRef(new AbortController());
  let ingHdnData: [string, string][] = [];
  if (Object.keys(hdnKeuzelijst).length > 1)
    ingHdnData = Object.entries(hdnKeuzelijst).filter(([k, _]) => k.includes("IN0")); //filter ING

  async function ophalenRente(
    data: HypothekenState,
    setFieldValue: FormikContextType<HypothekenState>["setFieldValue"]
  ): Promise<void> {
    const input = mapIngPriceToolInput({ ingHdnData })(data);
    const newController = new AbortController();
    abortController.current.abort();
    abortController.current = newController;

    const res: IngPriceOutput = await ingPriceToolOphalenRentes(
      settings,
      user,
      params.vestiging,
      params.voorstel,
      input,
      newController.signal
    );

    updateIngModal(res, data?.hypotheekOptiesIngPriceTool, setFieldValue);
  }

  const body = (
    data: HypothekenState,
    setFieldValue: FormikContextType<HypothekenState>["setFieldValue"]
  ): JSX.Element | undefined => (
    <>
      <LabeledBevestigingInput
        name="hypotheekOptiesIngPriceTool.dagrente"
        caption="Dagrente (tegen renteopslag)"
        tooltip={getHypotheekIngPricetoolTextResources("tooltipTekstDagrente")}
      />
      <LabeledBevestigingInput
        name="hypotheekOptiesIngPriceTool.actieveBetaalRekening"
        caption="ING Actieve betaalrekening"
        tooltip={getHypotheekIngPricetoolTextResources("tooltipTekstActieveBetaalrekening")}
      />

      <h2>Renteopbouw</h2>
      {data?.producten &&
        data?.producten.map((value, index) => {
          return (
            <div className="p-2" key={index}>
              <Card>
                <RenteOpbouw productIndex={index} key={index} />
              </Card>
            </div>
          );
        })}

      {data?.producten[0]?.hypotheekOptiesIngPriceToolLeningdeel?.errorMelding && (
        <div className="foutmelding justify-content-center d-flex flex-wrap p-2">
          {data?.producten[0].hypotheekOptiesIngPriceToolLeningdeel.errorMelding}
        </div>
      )}

      <div className="button-container">
        <Button
          id="btnOphalenRente"
          onClick={async (): Promise<void> => {
            await ophalenRente(data, setFieldValue);
          }}
          aria-label="Ophalen rente button"
        >
          Rente ophalen
        </Button>
      </div>
    </>
  );

  return (
    <>
      <AdviesBoxFormik<HypothekenState>
        initialValues={{ ...data }}
        validationSchema={hypotheekOptiesIngPriceToolSchema}
        onSave={onSave}
        closeModal={closeModal}
        render={({ submitForm, setFieldValue, values }: FormikProps<HypothekenState>): ReactElement => (
          <>
            <ISWSideEffects<HypothekenState> sync={determineHypotheekOptiesIngSideEffects({ selected })} />
            <Modal
              title="Hypotheekopties ING"
              body={body(values, setFieldValue)}
              onSubmitClick={submitForm}
              onCancelClick={closeModal}
            />
          </>
        )}
      />
    </>
  );
};

export default HypotheekOptiesIngModal;
