import { ErrorPage, PageLoading, withErrorBoundary } from "adviesbox-shared";
import React, { ReactElement, useMemo, useState } from "react";
import { GeldverstrekkersOutput } from "../../.generated/instellingen-forms/instellingen-formstypes";
import {
  HypotheeklabelMetHypotheekopties,
  HypotheeklabelMetHypotheekoptiesOutput,
  AflossingsVormType
} from "../../.generated/producten/productentypes";
import { partijOnafhankelijk } from "../../producten-overzicht/infra/product-constanten";
import { SituatieSoort } from "../../producten-overzicht/infra/producten-overzicht-types";
import { useHypotheeklabelsData } from "../../shared/hooks/use-hypotheeklabels-data";
import { useInstellingenBeheerPartijenData } from "../../shared/hooks/use-instellingen-beheer-partijen-data";
import { productenFilter } from "../../shared/utils/instellingen-filters";
import { leningdeelKeuzeModalSchema, leningdeelKeuzeVoorstelModalSchema } from "../infra/hypotheek-schema";
import {
  HypotheekProductSelectieStateType,
  HypotheekSelectieSamenstellingType,
  HypotheekType,
  HypothekenState
} from "../infra/hypotheek-types";
import {
  getUniqueMaatschappijenFromProducten,
  isAankoop,
  getHuidigeMaatschappijCode
} from "./hypotheek-selectie-helper";
import HypotheekSelectieModal from "./hypotheek-selectie-modal";
import { saveHypotheekSelectie } from "./hypotheek-selectie-opslaan";

export type VerzekeringSelectieProps = {
  situatie: SituatieSoort;
  hypotheek: HypothekenState;
  onSaveProduct?: (product: HypotheekType | Array<HypotheekType>) => void;
  closeModal?: () => void;
  newItem?: boolean;
  selectedRow?: number;
  updateLeningdeel?: boolean;
  productOnly?: boolean;
  omzetten?: boolean;
  selectedNieuwProduct?: number;
  geldverstrekkerOnly?: boolean;
};

const getSelectieStateExistingProduct = (leningdeel: HypotheekType): HypotheekProductSelectieStateType => {
  return {
    nearMatchHypotheekvorm: null,
    leningdeelKeuze: "keuzeproduct",
    maatschappijProductCode: {
      maatschappijCode: leningdeel.partijCode,
      maatschappijOmschrijving: "",
      maatschappijLabelCode: `${leningdeel.partijCode}_${leningdeel.labelCode}`,
      labelCode: leningdeel.labelCode || 1,
      labelOmschrijving: ""
    },
    productCode: {
      aflosvorm: (leningdeel.hypotheekVorm.aflossingsvorm as unknown) as AflossingsVormType,
      code: leningdeel.productCode,
      productOmschrijving: "",
      renteBoxMaatschappijCode: leningdeel.renteBoxMaatschappijCode,
      renteboxCode: null
    },
    leningdelen: [],
    hasProducten: true,
    soortFinanciering: null
  };
};

type HypotheekProductenType = { [key: string]: HypotheeklabelMetHypotheekopties };

const HypotheekSelectieAjax = ({
  situatie,
  hypotheek,
  onSaveProduct,
  closeModal,
  newItem,
  selectedRow,
  updateLeningdeel,
  productOnly = false,
  omzetten = false,
  selectedNieuwProduct,
  geldverstrekkerOnly = false
}: VerzekeringSelectieProps): ReactElement | null => {
  const [hypotheekSamenstellingWijzigingState, setHypotheekSamenstellingWijzigingState] = useState<
    HypotheekSelectieSamenstellingType[]
  >();

  const { error, data, loading } = useHypotheeklabelsData(
    !isAankoop(hypotheek.soortFinanciering),
    getHuidigeMaatschappijCode(hypotheek)
  );

  const {
    data: enabledGeldverstrekkersData,
    loading: geldverstrekkersLoading,
    error: geldverstrekkersError
  } = useInstellingenBeheerPartijenData("Geldverstrekkers");

  const beschikbarePartijen = useMemo(() => {
    const partijOnafhankelijkVerstrekker = enabledGeldverstrekkersData?.geldverstrekkers?.partijen?.find(
      p => p?.code === partijOnafhankelijk
    );

    return situatie === "voorstel"
      ? enabledGeldverstrekkersData
      : {
          ...enabledGeldverstrekkersData,
          geldverstrekkers: {
            ...enabledGeldverstrekkersData.geldverstrekkers,
            partijen: [partijOnafhankelijkVerstrekker]
          }
        };
  }, [enabledGeldverstrekkersData, situatie]);

  if (error) {
    return <ErrorPage error={new Error(`Invalid data: ${error.message}`)} data={data} />;
  }

  if (geldverstrekkersError) {
    return <ErrorPage error={geldverstrekkersError} data={enabledGeldverstrekkersData} />;
  }

  if (geldverstrekkersLoading || loading || !data) {
    return <PageLoading />;
  }

  let modalData;

  if (newItem) {
    if (situatie === "huidig") {
      modalData = leningdeelKeuzeModalSchema.default();
    } else {
      modalData = {
        ...leningdeelKeuzeVoorstelModalSchema.default(),
        soortFinanciering: hypotheek.soortFinanciering,
        leningdeelKeuze: "genereer" as "genereer" | "samenstelling" | "keuzeproduct"
      };
    }
  } else {
    // Controleer of lijst niet leeg is en selecteer het eerste product in de lijst
    const product = hypotheek.producten.find(prod => true);
    if (!product) throw new Error("Het product is niet gevonden!");
    modalData = getSelectieStateExistingProduct(product);
  }

  const filteredProductenData: HypotheekProductenType = filterProducten(
    data,
    beschikbarePartijen as GeldverstrekkersOutput
  );

  if (Object.keys(filteredProductenData).length === 0) {
    return <ErrorPage error={new Error("Geen geldverstrekkers om te tonen")} />;
  }

  const selectedProduct = hypotheek.producten[selectedNieuwProduct || 0];
  const selectedProductDetails =
    (typeof selectedNieuwProduct === "number" && selectedProduct) || (situatie === "huidig" && selectedProduct)
      ? {
          maatschappijCode: selectedProduct.product.partijCodeSelectie || selectedProduct.partijCode,
          maatschappijNaamKort: selectedProduct.product.partijNaam,
          code: selectedProduct.labelCode,
          productnaam: selectedProduct.product.productNaam
        }
      : null;

  modalData = setFirstMaatschappijProductCode(modalData, filteredProductenData, selectedProductDetails);

  return (
    <HypotheekSelectieModal
      situatie={situatie}
      isRestschuldHypotheek={
        !newItem && /* istanbul ignore next */ hypotheek.producten[selectedRow || 0]?.hypotheekVorm.isRestschuldLening
      }
      labels={{
        ...data,
        producten: filteredProductenData
      }}
      data={modalData}
      productOnly={productOnly}
      updateLeningdeel={updateLeningdeel}
      omzetten={omzetten}
      selectedRow={selectedRow}
      maatschappijNamen={getUniqueMaatschappijenFromProducten(data?.producten)}
      onSave={gekozenProduct =>
        saveHypotheekSelectie({
          hypotheek,
          hypotheekSamenstellingWijzigingState,
          situatie,
          productUpdateOnly: productOnly,
          omzetten: omzetten,
          onSaveProduct,
          selectedRow,
          updateLeningdeel,
          gekozenProduct,
          geldverstrekkerUpdateOnly: geldverstrekkerOnly
        })
      }
      closeModal={closeModal}
      setHypotheekSamenstellingWijzigingState={setHypotheekSamenstellingWijzigingState}
      geldverstrekkerOnly={geldverstrekkerOnly}
    />
  );
};
export default withErrorBoundary(HypotheekSelectieAjax);

function filterProducten(
  data: HypotheeklabelMetHypotheekoptiesOutput,
  enabledGeldverstrekkersData: GeldverstrekkersOutput
): HypotheekProductenType {
  const filteredProductenData: HypotheekProductenType = {};
  if (data && data.producten) {
    for (const key in data.producten) {
      if (
        productenFilter(
          enabledGeldverstrekkersData.geldverstrekkers?.partijen || /* istanbul ignore next */ [],
          data.producten[key].maatschappijCode || /* istanbul ignore next */ "",
          data.producten[key].code
        )
      ) {
        filteredProductenData[key] = data.producten[key];
      }
    }
  }
  return filteredProductenData;
}

export function setFirstMaatschappijProductCode(
  modalData: any,
  filteredProductenData: HypotheekProductenType,
  selectedProduct: {
    maatschappijCode: string | null;
    maatschappijNaamKort: string | null;
    code: number | null;
    productnaam: string | null;
  } | null
): any {
  // Kijk eerst of de maatschappij/ code voorkomen in de lijst
  let key = Object.keys(filteredProductenData).find(
    key =>
      filteredProductenData[key].maatschappijCode === selectedProduct?.maatschappijCode &&
      filteredProductenData[key].code === selectedProduct?.code
  );
  // Kijk vervolgens of de maatschappij voorkomt in de lijst (code komt niet voor)
  if (key === undefined) {
    key = Object.keys(filteredProductenData).find(
      key => filteredProductenData[key].maatschappijCode === selectedProduct?.maatschappijCode
    );
  }
  const first =
    key === undefined ? filteredProductenData[Object.keys(filteredProductenData)[0]] : filteredProductenData[key];
  modalData.maatschappijProductCode = {
    maatschappijCode: first?.maatschappijCode || "",
    maatschappijOmschrijving: first?.maatschappijNaamKort || "",
    maatschappijLabelCode: `${first?.maatschappijCode}_${first?.code || 1}`,
    labelCode: first?.code || 1,
    labelOmschrijving: first?.productnaam || ""
  };
  modalData.productCode.code = null;
  return modalData;
}
