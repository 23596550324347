import { LeningdeelReadOnly, VermogenLeningdeelReadOnly } from "../../../.generated/forms/formstypes";
import { LeningdeelType, VermogenLeningdeelType } from "./schema";
import { mapStringToLocalDate } from "adviesbox-shared";

export function mapLeningdelenDl2Ui(values: LeningdeelReadOnly[] | null): LeningdeelType[] {
  if (values) {
    return values.map(mapLeningdeelDl2Ui);
  }
  return [];
}
export function mapVemogenLeningdelenDl2Ui(values: VermogenLeningdeelReadOnly[] | null): VermogenLeningdeelType[] {
  if (values) {
    return values.map(mapVermogenLeningdeelDl2Ui);
  }
  return [];
}

export function mapLeningdeelDl2Ui(values: LeningdeelReadOnly): LeningdeelType {
  return {
    leningdeelId: values.leningdeelId,
    doorlopend: values.doorlopend,
    productnaam: values.productnaam,
    aanvangsdatum: mapStringToLocalDate(values.aanvangsdatum),
    looptijd: values.looptijdInMaanden,
    maatschappijCode: values.maatschappijCode,
    productcode: values.productcode
  };
}

export function mapVermogenLeningdeelDl2Ui(values: VermogenLeningdeelReadOnly): VermogenLeningdeelType {
  return {
    ...mapLeningdeelDl2Ui(values),
    doelkapitaal: values.doelkapitaalBedrag,
    doelkapitaalPercentage: values.doelkapitaalPercentage
  };
}
