import { SettingsType } from "adviesbox-shared";
import { FormikContextType } from "formik";
import { Draft } from "immer";
import { User } from "oidc-client";
import { RouteParams } from "../../paramrouting/paramrouting-context";
import { UiName } from "../../types";
import { HasChanged } from "./has-changed-helper";

export const forceReturnContext = Symbol("forceReturnContext");

export type ISWSideEffect<T> = (bag: ISWSideEffectBag<T>) => void;
export type ISWSideEffectWithContext<T, X> = (bag: ISWSideEffectBagWithContext<T, X>) => void;

export type CreateBagFunction<T, X> = {
  create: () => ISWSideEffectBagWithContext<T, X>;
  createWithContext: <N>(newcontext: N) => ISWSideEffectBagWithContext<T, N>;
};

type SubBagObj<T, X> = { [P in keyof T]: ISWSideEffectSubBag<T[P], X> };
export type ISWSideEffectSubBag<T, X> = Extract<T, object> extends never
  ? never
  : SubBagObj<T, X> & Omit<CreateBagFunction<T, X>, keyof T>;

type ISWSideEffectBag<T> = {
  has: HasChanged<T>;
  draft: Draft<T>;
  prev: Readonly<T>;
  init: Readonly<T>;
  subset: ISWSideEffectSubBag<T, any>;
};
export type ISWSideEffectBagWithContext<T, X> = {
  has: HasChanged<T>;
  draft: Draft<T>;
  prev: Readonly<T>;
  init: Readonly<T>;
  subset: ISWSideEffectSubBag<T, X>;
  context: X extends object ? Readonly<X> : X;
};

export type ISWAsyncCondition<T, X = undefined> = (bag: ISWAsyncConditionBag<T, X>) => void;
export type ISWAsyncSideEffect<T, X, P = RouteParams> = (bag: ISWAsyncSideEffectBag<T, X, P>) => Promise<void>;
export type ISWAsyncContext<T, X> = { id: number; asyncSideEffect: ISWAsyncSideEffect<T, X>; context: X };

export type ISWAsyncConditionBag<T, X> = {
  has: HasChanged<T>;
  curr: Readonly<T>;
  prev: Readonly<T>;
  init: Readonly<T>;
  context: X extends object ? Readonly<X> : X;
  runAsync: (asyncContext: ISWAsyncContext<T, X>) => void;
};
export type ISWAsyncSideEffectBag<T, X, P = RouteParams> = {
  has: HasChanged<T>;
  draft: Draft<T>;
  prev: Readonly<T>;
  init: Readonly<T>;
  signal: AbortSignal;
  fetchData: <R, B = undefined>(params: ISWFetchDataParams<T, B>) => Promise<R>;
  params: Readonly<P>;
  settings: SettingsType;
  user: User | null;
  context: X extends object ? Readonly<X> : X;
  formik: FormikContextType<T>;
};

export type ISWFetchDataParams<T, B> = {
  url: string;
  method?: "GET" | "POST" | "PUT";
  body?: string | B | ((data: T) => B);
  extraHeaders?: Record<string, string>;
  mapperDlNameToUiName?: (target: string) => UiName | null;
};
