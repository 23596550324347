import { mapJaarMaandInputFromLooptijdDl2Ui } from "adviesbox-shared";
import {
  Aanleiding as AanvraagDlEntry,
  AanleidingenOutput as AanvraagDlOutput,
  AanleidingVoorstel,
  AanvraagBankgarantie,
  AanvraagHypotheek,
  AanvraagTaxatie,
  AanvraagVerzekerde,
  AanvraagVerzekering,
  ServiceproviderReadOnly,
  SoftwareKoppelingReadOnly
} from "../../.generated/forms/formstypes";
import { createMapToUi } from "../../shared/utils/create-map-to-ui";
import {
  AanvraagErrorType,
  aanvraagHypotheekSchema,
  aanvraagOpBasisVanSchema,
  aanvraagSchema,
  AanvraagState,
  Overschrijven,
  productSchema,
  ProductTypeName,
  serviceProviderSchema,
  verzekerdeSchema,
  softwareKoppelingenSchema
} from "./aanvraag-schema";
import { errorSchema } from "../../shared/components/dossier/dossierbalk-fouten-dropdown/aanvullende-gegevens-schema";

export const mapErrors = createMapToUi(errorSchema)
  .with<{
    errorId: string | null;
    partijCode: string | null;
    bemiddelingId: string | null;
  }>()
  .from<AanvraagErrorType>({
    errorId: (_, { errorId }) => errorId,
    bemiddelingId: (_, { bemiddelingId }) => bemiddelingId,
    app: v => v?.app,
    locatie: v => v?.locatie,
    omschrijving: v => v?.message,
    partijCode: (_, { partijCode }) => partijCode,
    relativeUrl: v => v?.relativeUrl,
    parentOccurance: v => v?.parentOccurance ?? 0,
    soortPartijRef: v => v?.soortPartijRef,
    foutmeldingControleSchema: v => v?.foutmeldingControleSchema,
    platformVeldInfo: v => ({
      datatype: v?.veldInfo?.datatype ?? null,
      omschrijving: v?.veldInfo?.omschrijving ?? null,
      maxOccurs: v.veldInfo?.maxOccurs ?? null,
      naam: v?.veldInfo?.naam ?? null,
      verplicht: v?.veldInfo?.verplicht ?? false,
      xPath: v?.veldInfo?.xPath ?? null
    }),
    FEVeldInfo: _ => null,
    extraInfo: v => v?.extraInfo
  });

export const mapVoorstel = createMapToUi(aanvraagOpBasisVanSchema).from<AanleidingVoorstel>({
  id: v => v.voorstelId,
  omschrijving: v => v.omschrijving || "Voorstel #" + v.volgnummer,
  volgnummer: v => v.volgnummer
});

export const mapVerzekerde = createMapToUi(verzekerdeSchema).from<AanvraagVerzekerde>({
  persoonId: v => v.persoonId,
  volgnummer: v => v.volgnummer,
  naamVerzekerde: v => v.naamVerzekerde
});

export const mapServiceProvider = createMapToUi(serviceProviderSchema).from<ServiceproviderReadOnly>({
  serviceproviderId: v => v.serviceproviderId,
  naam: v => v.naam,
  hdnNummer: v => v.hdnNummer
});

export const mapSoftwareKoppeling = createMapToUi(softwareKoppelingenSchema).from<SoftwareKoppelingReadOnly>({
  naam: v => v.naam,
  softwareKoppelingId: v => v.softwareKoppelingId
});

export const mapOrvProduct = createMapToUi(productSchema).from<AanvraagVerzekering>({
  partijnaam: v => v.partijnaam,
  partijCode: v => v.partijcode,
  hdnOntvangerCode: v => v.hdnOntvangerCode,
  productnaam: v => v.productnaam,
  productId: v => v.productId,
  productcode: v => v.productcode,
  premie: v => v.premie,
  aanwezigInBemiddeling: v => v.aanwezigInBemiddeling,
  versie: v => v.versie,
  voorstel: v => v.voorstel.toString(),
  meenemenNaarBemiddeling: v => v.meenemenNaarBemiddeling,
  aanvraagVerzonden: v => v.aanvraagVerzonden,
  verzekerde: v => v.verzekerden?.map(verzekerden => mapVerzekerde(verzekerden)),
  overschrijven: () => Overschrijven.nieuweVersie,
  methodeIndienen: () => null,
  aanvraagNaarPartij: v => v.aanvraagNaarPartij,
  aanvraagNaarServiceproviderId: v => v.aanvraagNaarServiceproviderId,
  aanvraagNaarSoftwareKoppelingId: v => v.aanvraagNaarSoftwareKoppelingId,
  aanvraagNaar: v => v.partijnaam,
  aanvraagNaarHdnNummer: v => null,
  softwareKoppelingId: v => null,
  hdnNummer: v => v.hdnNummer,
  productType: () => ProductTypeName.ORV,
  bemiddelingProduct: _ => null,
  bankgarantieHoofdsom: () => null
});

export const mapAovProduct = createMapToUi(productSchema).from<AanvraagVerzekering>({
  partijnaam: v => v.partijnaam,
  partijCode: v => v.partijcode,
  hdnOntvangerCode: v => v.hdnOntvangerCode,
  productnaam: v => v.productnaam,
  productId: v => v.productId,
  productcode: v => v.productcode,
  premie: v => v.premie,
  aanwezigInBemiddeling: v => v.aanwezigInBemiddeling,
  versie: v => v.versie,
  voorstel: v => v.voorstel.toString(),
  meenemenNaarBemiddeling: v => v.meenemenNaarBemiddeling,
  aanvraagVerzonden: v => v.aanvraagVerzonden,
  verzekerde: v => {
    if (v.verzekerden) {
      return v.verzekerden.map(verzekerden => mapVerzekerde(verzekerden));
    }
  },
  overschrijven: () => Overschrijven.nieuweVersie,
  methodeIndienen: () => null,
  aanvraagNaarPartij: v => v.aanvraagNaarPartij,
  aanvraagNaarServiceproviderId: v => v.aanvraagNaarServiceproviderId,
  aanvraagNaarSoftwareKoppelingId: v => v.aanvraagNaarSoftwareKoppelingId,
  aanvraagNaar: v => v.partijnaam,
  aanvraagNaarHdnNummer: v => null,
  softwareKoppelingId: v => null,
  hdnNummer: v => v.hdnNummer,
  productType: () => ProductTypeName.AOV,
  bemiddelingProduct: _ => null,
  bankgarantieHoofdsom: () => null
});

export const mapBankgarantieProduct = createMapToUi(productSchema).from<AanvraagBankgarantie>({
  partijnaam: v => v.partijnaam,
  partijCode: v => v.partijcode,
  hdnOntvangerCode: v => v.hdnOntvangerCode,
  productnaam: v => v.productnaam,
  productId: v => v.productId,
  productcode: v => v.productcode,
  premie: () => null,
  aanwezigInBemiddeling: v => v.aanwezigInBemiddeling,
  versie: v => v.versie,
  voorstel: v => v.voorstel.toString(),
  meenemenNaarBemiddeling: v => v.meenemenNaarBemiddeling,
  aanvraagVerzonden: v => v.aanvraagVerzonden,
  verzekerde: () => null,
  overschrijven: () => Overschrijven.nieuweVersie,
  methodeIndienen: () => null,
  aanvraagNaarPartij: () => null,
  aanvraagNaarServiceproviderId: () => null,
  aanvraagNaarSoftwareKoppelingId: () => null,
  aanvraagNaar: v => v.partijnaam,
  aanvraagNaarHdnNummer: v => null,
  softwareKoppelingId: v => null,
  hdnNummer: v => v.hdnNummer,
  productType: () => ProductTypeName.BG,
  bemiddelingProduct: _ => null,
  bankgarantieHoofdsom: v => v.hoofdsom
});

export const mapTaxatieProduct = createMapToUi(productSchema).from<AanvraagTaxatie>({
  partijnaam: v => v.partijnaam,
  partijCode: v => v.partijcode,
  productnaam: v => v.productnaam,
  productId: v => v.productId,
  productcode: v => v.productcode,
  hdnOntvangerCode: v => v.hdnOntvangerCode,
  premie: () => null,
  aanwezigInBemiddeling: v => v.aanwezigInBemiddeling,
  versie: v => v.versie,
  voorstel: v => v.voorstel.toString(),
  meenemenNaarBemiddeling: v => v.meenemenNaarBemiddeling,
  aanvraagVerzonden: v => v.aanvraagVerzonden,
  verzekerde: () => null,
  overschrijven: () => Overschrijven.nieuweVersie,
  methodeIndienen: () => null,
  aanvraagNaarPartij: () => null,
  aanvraagNaarServiceproviderId: () => null,
  aanvraagNaarSoftwareKoppelingId: () => null,
  aanvraagNaar: v => v.partijnaam,
  aanvraagNaarHdnNummer: v => null,
  softwareKoppelingId: v => null,
  hdnNummer: v => v.hdnNummer,
  productType: () => ProductTypeName.taxatie,
  bemiddelingProduct: _ => null,
  bankgarantieHoofdsom: _ => null
});

export const mapHypotheek = createMapToUi(aanvraagHypotheekSchema).from<AanvraagHypotheek>({
  aanvraagNaarPartij: v => v.aanvraagNaarPartij,
  aanvraagNaarServiceproviderId: v => v.aanvraagNaarServiceproviderId,
  aanvraagNaarSoftwareKoppelingId: v => v.aanvraagNaarSoftwareKoppelingId,
  aanwezigInBemiddeling: v => v.aanwezigInBemiddeling,
  leningdelen: v => v.leningdelen,
  looptijdMaanden: v => mapJaarMaandInputFromLooptijdDl2Ui(v.looptijdMaanden),
  meenemenNaarBemiddeling: v => v.meenemenNaarBemiddeling,
  partijCode: v => v.partijcode,
  hdnOntvangerCode: v => v.hdnOntvangerCode,
  partijnaam: v => v.partijnaam,
  productId: v => v.productId,
  productcode: v => v.productcode,
  productnaam: v => v.productnaam,
  versie: v => v.versie,
  aanvraagVerzonden: v => v.aanvraagVerzonden,
  voorstel: v => v.voorstel.toString(),
  overschrijven: () => Overschrijven.nieuweVersie,
  methodeIndienen: () => null,
  aanvraagNaar: v => v.partijnaam,
  aanvraagNaarHdnNummer: v => null,
  softwareKoppelingId: v => null,
  hdnNummer: v => v.hdnNummer,
  productType: () => ProductTypeName.hypotheek,
  bemiddelingProduct: _ => null
});

// TODO: Wanneer correcte endpoint er is, mappers aanpassen
export const mapAanvraag = createMapToUi(aanvraagSchema).from<AanvraagDlEntry>({
  hypotheek: () => null,
  authorized: () => null,
  productenMetErrors: () => null,
  voorstelArray: v => {
    if (v.voorstellen) {
      return v.voorstellen.map(voorstel => mapVoorstel(voorstel));
    }
  },
  aanvraagOpBasisVan: () => null,
  fetchProducten: () => false,
  orvProduct: () => null,
  aovProduct: () => null,
  taxatieProduct: () => null,
  bankgarantieProduct: () => null,
  isLoading: () => null,
  isLoadingAanvraag: () => null,
  redirectToBemiddeling: () => null,
  productIdsEnTypes: () => [],
  serviceprovider: () => [],
  softwareKoppelingen: () => [],
  financiering: v => v.financiering,
  meenemenError: () => false,
  addOrUpdateCallError: () => false,
  adviseursBemiddelaarsCallError: () => false,
  addOrUpdateIsValid: () => true,
  maatschappijCodes: () => [],
  bemiddelingProductenResults: () => [],
  adviseurId: _ => null,
  bemiddelaar: _ => null,
  bemiddelaarId: _ => null,
  bemiddelaarEmailadres: _ => null,
  adviseur: _ => null,
  bemiddelaars: _ => null,
  foutenVoorstelId: _ => null,
  aanvraagStep: _ => null,
  showAanvraagBemiddelaarModal: _ => false,
  showAanvraagModal: _ => false,
  gekoppeldeBemiddelaar: _ => null,
  geselecteerdProduct: _ => null,
  nieuweVersieAanvraag: _ => false,
  externeReferentie: _ => null
});

export function mapAanvraagDlToUi(aanvraagId: string, data: AanvraagDlOutput): AanvraagState | null {
  const aanvraag = data && data.isValid && data.aanleidingen ? data.aanleidingen[aanvraagId] : null;

  if (aanvraag) {
    return mapAanvraag(aanvraag);
  }

  return null;
}
