/* istanbul ignore file */
import {
  Leningdeel,
  MaximaleHypotheek as MaximaleHypotheekDlEntry,
  MaximaleHypotheekAanvrager,
  ToetsrenteBerekeningOptions
} from "../../.generated/forms/formstypes";
import { jaarMaandNaarMaandenNumber } from "../../shared/generic-parts/jaar-maand/helpers";
import { mapKlantnaamUi2Dl } from "../../shared/generic-parts/klantnaam/map-ui-2-dl";
import { createMapToDl } from "../../shared/utils/create-map-to-dl";
import {
  mapLeningdeelVorm,
  maximaleHypotheekSchema,
  modalLeningdelenSchema,
  ToetsrenteBerekeningObv,
  ToetsrenteBerekeningObv as TypesToetsrenteBerekeningObv
} from "./maximale-hypotheek-schema";

const mapToetsrenteBerekenenObv: Record<ToetsrenteBerekeningOptions, TypesToetsrenteBerekeningObv> &
  Record<TypesToetsrenteBerekeningObv, ToetsrenteBerekeningOptions> = {
  SpecificatieToetsrente: TypesToetsrenteBerekeningObv.SpecificatieToetsrente,
  GewensteRentevastperiode: TypesToetsrenteBerekeningObv.GewensteRentevastperiode,
  "Gewenste rentevastperiode": ToetsrenteBerekeningOptions.GewensteRentevastperiode,
  "Specificatie toetsrente": ToetsrenteBerekeningOptions.SpecificatieToetsrente
};

const mapInkomenAanvrager = createMapToDl(maximaleHypotheekSchema).to<MaximaleHypotheekAanvrager>({
  aowLeeftijdInMaanden: v => jaarMaandNaarMaandenNumber(v.inkomen.aowleeftijdAanvrager),
  pensioeninkomenBedrag: v =>
    v.inkomen.pensioeninkomenAanvrager.berekenen ? null : v.inkomen.pensioeninkomenAanvrager.bedrag,
  pensioeninkomenOvernemen: v => v.inkomen.pensioeninkomenAanvrager.berekenen,
  toetsinkomenBedrag: v => (v.inkomen.toetsinkomenAanvrager.berekenen ? null : v.inkomen.toetsinkomenAanvrager.bedrag),
  toetsinkomenOvernemen: v => v.inkomen.toetsinkomenAanvrager.berekenen,
  aanvrager: v => mapKlantnaamUi2Dl(v.persoonsgegevens.naamAanvrager)
});

const mapInkomenPartner = createMapToDl(maximaleHypotheekSchema).to<MaximaleHypotheekAanvrager>({
  aowLeeftijdInMaanden: v => jaarMaandNaarMaandenNumber(v.inkomen.aowleeftijdPartner),
  pensioeninkomenBedrag: v =>
    v.inkomen.pensioeninkomenPartner.berekenen ? null : v.inkomen.pensioeninkomenPartner.bedrag,
  pensioeninkomenOvernemen: v => v.inkomen.pensioeninkomenPartner.berekenen,
  toetsinkomenBedrag: v => (v.inkomen.toetsinkomenPartner.berekenen ? null : v.inkomen.toetsinkomenPartner.bedrag),
  toetsinkomenOvernemen: v => v.inkomen.toetsinkomenPartner.berekenen,
  aanvrager: v => mapKlantnaamUi2Dl(v.persoonsgegevens.naamPartner)
});
const mapLeningdelenUitVerleden = createMapToDl(modalLeningdelenSchema).to<Leningdeel>({
  aflossingsvorm: v => mapLeningdeelVorm[v.hypotheekvorm],
  box3Bedrag: v => v.deelBox3,
  huidigLeningdeelId: v => null,
  leningdeelBedrag: v => v.hypotheekbedrag,
  leningdeelId: v => v.leningdeelId,
  opgebouwdeWaardeBedrag: v => v.opgebouwdeWaarde,
  restantLooptijdInMaanden: v => (v.looptijd ? (v.looptijd.jaren || 0) * 12 + (v.looptijd.maanden || 0) : null)
});

export const mapMaximaleHypotheekUiToDl = createMapToDl(maximaleHypotheekSchema).to<MaximaleHypotheekDlEntry>({
  aanvrager1: v => mapInkomenAanvrager(v),
  aanvrager2: v => mapInkomenPartner(v),
  gewensteRentevastperiodeInMaanden: v =>
    v.toetsrente.berekeningOpBasisVan === ToetsrenteBerekeningObv.GewensteRentevastperiode &&
    v.toetsrente.gewensteRentevastperiode
      ? v.toetsrente.gewensteRentevastperiode * 12
      : null,
  hypotheekLooptijdInMaanden: v => (v.uitgangspunten.looptijd ? v.uitgangspunten.looptijd * 12 : 0),
  kredietToetslastBedrag: v => (v.toetsrente.kredietToetslast.berekenen ? null : v.toetsrente.kredietToetslast.bedrag),
  kredietToetslastBerekendBedrag: v =>
    v.toetsrente.kredietToetslast.berekenen ? v.toetsrente.kredietToetslast.berekendBedrag : null,
  kredietToetslastOvernemen: v => v.toetsrente.kredietToetslast.berekenen,
  leningdelenUitVerleden: v =>
    v.leningdelenUitHetVerleden.meeTeNemenLeningdelen.map((ld): Leningdeel => mapLeningdelenUitVerleden(ld)),
  leningdelenUitVerledenMeenemen: v => v.leningdelenUitHetVerleden.leningdelenMeenemen,
  specificatieToetsrentePercentage: v =>
    v.toetsrente.berekeningOpBasisVan === ToetsrenteBerekeningObv.SpecificatieToetsrente && v.toetsrente.toetsrente
      ? v.toetsrente.toetsrente
      : null,
  toetsrenteBerekeningOpBasisVan: v =>
    mapToetsrenteBerekenenObv[v.toetsrente.berekeningOpBasisVan as ToetsrenteBerekeningObv],

  vrijVermogenBedrag: v => v.toetsrente.vrijVermogen,
  objectSoort: v => (v.uitgangspunten.objectSoort ? parseInt(v.uitgangspunten.objectSoort) : null),
  recreatieveBewoning: v => v.uitgangspunten.recreatieveBewoning
});
