import { Weigering } from "../infra/personalia-schema";
import { createISWSideEffect, initISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";

export const WeigeringenSideEffects = createISWSideEffect<{
  list: Weigering[];
}>(({ draft, prev }): void => {
  const { list: currentList } = draft;
  const { list: previousList } = prev;

  const foundweigeringenIndex = currentList.findIndex(
    (current, index): boolean =>
      current.maatschappijCode !== previousList[index].maatschappijCode && !!previousList[index].maatschappijCode
  );

  if (foundweigeringenIndex !== -1) {
    currentList[foundweigeringenIndex] = {
      weigeringId: null,
      maatschappijCode: currentList[foundweigeringenIndex].maatschappijCode,
      maatschappijOmschrijving: "",
      weigeringDatum: null,
      reden: ""
    };
  }
});

export const determineWeigeringenSideEffects = initISWSideEffect(WeigeringenSideEffects)();
