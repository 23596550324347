import {
  OuderdomspensioenIndexering,
  Pensioen as PensioenDlEntry,
  PensioenGegevens,
  UitkeringAoSoort,
  Pensioenregelingsoort as FormPensioenregelingsoort
} from "../../.generated/forms/formstypes";
import { mapSpecificatieAoUitkeringModalUi2Dl } from "../../producten-overzicht/infra/map-specificatie-ao-uitkering";
import { AoUitkeringswijze } from "../../producten-overzicht/infra/specificatie-ao-uitkering-schema";
import { createMapEnum } from "../../shared/utils/create-map-enum";
import { createMapToDl } from "../../shared/utils/create-map-to-dl";
import {
  OuderdomspensioenModalEntryType,
  pensioenenSchema,
  Pensioenregelingsoort as TypesPensioenregelingsoort,
  pensioenSchema
} from "./pensioen-schema";
import { mapLocalDateToString } from "adviesbox-shared";

function mapOuderdomspensioenIndexeringen(indexeringen: undefined): null;
function mapOuderdomspensioenIndexeringen(
  indexeringen: OuderdomspensioenModalEntryType[]
): OuderdomspensioenIndexering[] | null;
function mapOuderdomspensioenIndexeringen(
  indexeringen?: OuderdomspensioenModalEntryType[]
): OuderdomspensioenIndexering[] | null {
  if (!indexeringen) return null;
  if (indexeringen.length === 0) return null;
  return indexeringen.map(
    (x: OuderdomspensioenModalEntryType, index: number): OuderdomspensioenIndexering => ({
      bedrag: x.bedrag,
      ingangsdatum: mapLocalDateToString(x.ingangsdatum),
      volgnummer: index + 1
    })
  );
}

const mapSoortPensioenregeling: Record<TypesPensioenregelingsoort, FormPensioenregelingsoort> = {
  "": FormPensioenregelingsoort.Geen,
  "Beschikbare premieregeling": FormPensioenregelingsoort.BeschikbarePremie,
  Eindloonregeling: FormPensioenregelingsoort.Eindloon,
  Middelloonregeling: FormPensioenregelingsoort.Middelloon
};

const mapUitkeringsWijze = createMapEnum(AoUitkeringswijze).to({
  Volledig: UitkeringAoSoort.Volledig,
  ProRata: UitkeringAoSoort.ProRata
});

const mapPensioen = createMapToDl(pensioenSchema)
  .with<number>()
  .to<PensioenGegevens>({
    aangroeiBedrag: v =>
      v.werkgever.huidigeWerkgever && v.pensioengrondslag.pensioenaangroei.bedrag
        ? Math.floor(v.pensioengrondslag.pensioenaangroei.bedrag * 10000) / 10000
        : null,
    aangroeiOvernemen: v => (v.werkgever.huidigeWerkgever ? v.pensioengrondslag.pensioenaangroei.berekenen : null),
    anwCompensatie: v => v.pensioentoezeggingen.anwCompensatieIsEnabled,
    anwCompensatieBedrag: v => v.pensioentoezeggingen.anwCompensatie,
    aoUitkering: v =>
      v.pensioentoezeggingen.invaliditeitspensioenIsEnabled
        ? mapSpecificatieAoUitkeringModalUi2Dl(v.pensioentoezeggingen.invaliditeitspensioenWijze)(
            v.pensioentoezeggingen.invaliditeitspensioenModal
          )
        : null,
    beschikbaarBedrag: v => v.pensioenregeling.beschikbarePremie,
    bijdrageOuderdomsPensioenBedrag: v => v.werknemersbijdragen.bijdrageOuderdomspensioen,
    bijdragePrePensioenBedrag: v => v.werknemersbijdragen.bijdragePrePensioen,
    branche: v => v.pensioenuitvoerder.brancheSector,
    doelvermogenBedrag: v =>
      v.pensioenregeling.soortPensioenregeling === TypesPensioenregelingsoort.BeschikbarePremie &&
      v.werkgever.huidigeWerkgever
        ? v.pensioengrondslag.doelvermogen
        : null,
    franchiseBedrag: v =>
      v.pensioenregeling.soortPensioenregeling !== TypesPensioenregelingsoort.BeschikbarePremie &&
      v.pensioenregeling.soortPensioenregeling !== TypesPensioenregelingsoort.Geen
        ? v.pensioengrondslag.franchise.bedrag
        : null,
    franchiseOvernemen: v =>
      v.pensioenregeling.soortPensioenregeling !== TypesPensioenregelingsoort.BeschikbarePremie &&
      v.pensioenregeling.soortPensioenregeling !== TypesPensioenregelingsoort.Geen
        ? v.pensioengrondslag.franchise.berekenen
        : null,
    grondslagBedrag: v =>
      v.pensioenregeling.soortPensioenregeling !== TypesPensioenregelingsoort.BeschikbarePremie &&
      v.pensioenregeling.soortPensioenregeling !== TypesPensioenregelingsoort.Geen
        ? v.pensioengrondslag.pensioengrondslag.bedrag
        : null,
    grondslagOvernemen: v =>
      v.pensioenregeling.soortPensioenregeling !== TypesPensioenregelingsoort.BeschikbarePremie &&
      v.pensioenregeling.soortPensioenregeling !== TypesPensioenregelingsoort.Geen
        ? v.werkgever.huidigeWerkgever
          ? v.pensioengrondslag.pensioengrondslag.berekenen
          : null
        : null,
    invaliditeitspensioen: v => v.pensioentoezeggingen.invaliditeitspensioenIsEnabled,
    invaliditeitspensioenBedrag: v =>
      v.pensioentoezeggingen.invaliditeitspensioenIsEnabled ? v.pensioentoezeggingen.invaliditeitspensioenBedrag : null,
    invaliditeitspensioenPercentage: v =>
      v.pensioentoezeggingen.invaliditeitspensioenIsEnabled
        ? v.pensioentoezeggingen.invaliditeitspensioenPercentage
        : null,
    invaliditeitspensioenUitkeringswijze: v =>
      v.pensioentoezeggingen.invaliditeitspensioenIsEnabled
        ? mapUitkeringsWijze(v.pensioentoezeggingen.invaliditeitspensioenWijze)
        : null,
    klantId: v => v.selectedDeelnemer,
    loondienstId: v => (v.werkgever.huidigeWerkgever ? v.loondienstId : null),
    opbouwPercentage: v => {
      if (v.werkgever.huidigeWerkgever) {
        switch (v.pensioenregeling.soortPensioenregeling) {
          case TypesPensioenregelingsoort.BeschikbarePremie:
            return v.pensioengrondslag.rekenrenteOpbouwfase;
          case TypesPensioenregelingsoort.Eindloon:
            return v.pensioenregeling.eindloonregelingPercentage;
          case TypesPensioenregelingsoort.Middelloon:
            return v.pensioenregeling.middelloonregelingPercentage;
        }
      }
      return null;
    },
    opgebouwdBedrag: v => v.pensioengrondslag.huidigOpgebouwdBedrag,
    ouderdomspensioen: v => v.pensioentoezeggingen.ouderdomspensioenIsEnabled,
    ouderdomspensioenBedrag: v => v.pensioentoezeggingen.ouderdomspensioenBedrag,
    ouderdomspensioenDatum: v => mapLocalDateToString(v.pensioentoezeggingen.ouderdomspensioenIngangsdatum),
    ouderdomspensioenScenario: v =>
      v.pensioentoezeggingen.ouderdomspensioenIsEnabled
        ? { indexeringen: mapOuderdomspensioenIndexeringen(v.pensioentoezeggingen.ouderdomspensioenModal.indexeringen) }
        : null,
    overzichtDatum: v => mapLocalDateToString(v.pensioengrondslag.datumOverzicht),
    partnerpensioenNaAowOverlijdenNaAow: v => v.pensioentoezeggingen.nabestaandenpensioenNaAowOverlijdenNaAowIsEnabled,
    partnerpensioenNaAowOverlijdenNaAowBedrag: v =>
      v.pensioentoezeggingen.nabestaandenpensioenNaAowOverlijdenNaAowBedrag,
    partnerpensioenNaAowOverlijdenNaAowPercentage: v =>
      v.pensioentoezeggingen.nabestaandenpensioenNaAowOverlijdenNaAowPercentage,
    partnerpensioenNaAowOverlijdenVoorAow: v =>
      v.pensioentoezeggingen.nabestaandenpensioenNaAowOverlijdenVoorAowIsEnabled,
    partnerpensioenNaAowOverlijdenVoorAowBedrag: v =>
      v.pensioentoezeggingen.nabestaandenpensioenNaAowOverlijdenVoorAowBedrag,
    partnerpensioenNaAowOverlijdenVoorAowPercentage: v =>
      v.pensioentoezeggingen.nabestaandenpensioenNaAowOverlijdenVoorAowPercentage,
    partnerpensioenTotAowOverlijdenNaAow: v =>
      v.pensioentoezeggingen.nabestaandenpensioenTotAowOverlijdenNaAowIsEnabled,
    partnerpensioenTotAowOverlijdenNaAowBedrag: v =>
      v.pensioentoezeggingen.nabestaandenpensioenTotAowOverlijdenNaAowBedrag,
    partnerpensioenTotAowOverlijdenNaAowPercentage: v =>
      v.pensioentoezeggingen.nabestaandenpensioenTotAowOverlijdenNaAowPercentage,
    partnerpensioenTotAowOverlijdenVoorAow: v =>
      v.pensioentoezeggingen.nabestaandenpensioenTotAowOverlijdenVoorAowIsEnabled,
    partnerpensioenTotAowOverlijdenVoorAowBedrag: v =>
      v.pensioentoezeggingen.nabestaandenpensioenTotAowOverlijdenVoorAowBedrag,
    partnerpensioenTotAowOverlijdenVoorAowPercentage: v =>
      v.pensioentoezeggingen.nabestaandenpensioenTotAowOverlijdenVoorAowPercentage,
    pensioenBijHuidigeWerkgever: v => v.werkgever.huidigeWerkgever,
    pensioenId: v => v.id,
    pensioenUitvoerder: v => v.pensioenuitvoerder.naamPensioenuitvoerder,
    pensioengevendInkomenBedrag: v =>
      v.werkgever.huidigeWerkgever ? v.pensioengrondslag.pensioengevendSalaris.bedrag : null,
    pensioengevendInkomenOvernemen: v =>
      v.werkgever.huidigeWerkgever ? v.pensioengrondslag.pensioengevendSalaris.berekenen : null,
    prepensioen: v => v.pensioentoezeggingen.prePensioenIsEnabled,
    prepensioenBedrag: v => v.pensioentoezeggingen.prePensioenBedrag,
    prepensioenDatum: v => mapLocalDateToString(v.pensioentoezeggingen.prePensioenIngangsdatum),
    soortPensioenregeling: v => mapSoortPensioenregeling[v.pensioenregeling.soortPensioenregeling],
    uitkeringPercentage: v =>
      v.werkgever.huidigeWerkgever &&
      v.pensioenregeling.soortPensioenregeling === TypesPensioenregelingsoort.BeschikbarePremie
        ? v.pensioengrondslag.rekenrenteUitkeringfase
        : null,
    volgnummer: (_, i) => i + 1,
    wezenpensioen: v => v.pensioentoezeggingen.wezenpensioenIsEnabled,
    wezenpensioenBedrag: v => v.pensioentoezeggingen.wezenpensioenBedrag,
    wezenpensioenEindleeftijd: v => v.pensioentoezeggingen.wezenpensioenEindleeftijd,
    wezenpensioenPercentage: v => v.pensioentoezeggingen.wezenpensioenPercentage
  });

export const mapPensioenUiToDl = createMapToDl(pensioenenSchema).to<PensioenDlEntry>({
  pensioenen: v => v.pensioenen.map((p, i) => mapPensioen(i)(p)),
  aanvrager1: v => null, // TODO CONTROLEREN!!
  aanvrager2: v => null // TODO CONTROLEREN!!
});
