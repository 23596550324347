import React, { ReactElement } from "react";
import { connect, FormikContextType } from "formik";
import { InkomenEnFiscusState, SociaalSyncHeightIndex } from "../infra/inkomen-en-fiscus-schema";
import { getInkomenEnFiscusTextResources } from "../infra/inkomen-en-fiscus-resources";
import { LocalDate } from "@js-joda/core";
import { LabeledBevestigingInput, LabeledNumberInput, SyncMultipleHeightProps, SyncHeight } from "adviesbox-shared";

const Aanvrager1Sociaal = ({
  formik: {
    values: { aanvrager1Sociaal }
  },
  targetRef,
  sourceRef
}: SyncMultipleHeightProps & {
  formik: FormikContextType<InkomenEnFiscusState>;
}): ReactElement => {
  const disableJareneis = !aanvrager1Sociaal.voldoetWekeneis;
  return (
    <SyncHeight
      sourceRef={sourceRef && sourceRef[SociaalSyncHeightIndex.All]}
      targetRef={targetRef && targetRef[SociaalSyncHeightIndex.All]}
    >
      <LabeledBevestigingInput
        caption="Voldoet aan wekeneis"
        name="aanvrager1Sociaal.voldoetWekeneis"
        tooltip={getInkomenEnFiscusTextResources("TooltipSociaalWekeneis")}
      />
      {aanvrager1Sociaal.voldoetWekeneis && (
        <LabeledBevestigingInput
          caption="Voldoet aan jareneis"
          name="aanvrager1Sociaal.voldoetJareneis"
          tooltip={getInkomenEnFiscusTextResources("TooltipSociaalJareneis")}
          disabled={disableJareneis}
        />
      )}
      <SyncHeight
        sourceRef={sourceRef && sourceRef[SociaalSyncHeightIndex.Arbeidsverleden]}
        targetRef={targetRef && targetRef[SociaalSyncHeightIndex.Arbeidsverleden]}
      >
        <LabeledNumberInput
          caption={`Feitelijk arbeidsverleden tot ${LocalDate.now().year()}`}
          name="aanvrager1Sociaal.feitelijkArbeidsverleden"
          tooltip={getInkomenEnFiscusTextResources("TooltipSociaalFeitelijkArbeidsverleden")}
          appendChildren={<div className="p-1">Jaar</div>}
        />
      </SyncHeight>
      <LabeledNumberInput
        caption="Fictief arbeidsverleden"
        name="aanvrager1Sociaal.fictiefArbeidsverleden"
        readonly={true}
        appendChildren={<div className="px-1">Jaar</div>}
      />
    </SyncHeight>
  );
};

export default connect<SyncMultipleHeightProps, InkomenEnFiscusState>(Aanvrager1Sociaal);
