/* istanbul ignore file */
import { JaarMaandInputType } from "./schema";
import { jaarMaandInMaanden } from "./map-ui-2-dl";
import { SoortKredietProductOptions } from "../../../.generated/forms/formstypes";
import { ChronoUnit, LocalDate } from "@js-joda/core";

export const jaarMaandInputNaarJaarMaand = (input: JaarMaandInputType | null): string => {
  if (input) {
    const jaren = input.jaren;
    const maanden = input.maanden;

    if (jaren && maanden) {
      return `${jaren} jaar en ${maanden} maanden`;
    }
    if (jaren) {
      return `${jaren} jaar`;
    }
    if (maanden) {
      return `${maanden} maanden`;
    }
  }
  return "";
};

export const maandenNaarJaarMaand = (inputMaanden: number): string => {
  const jaren = Math.floor(inputMaanden / 12);
  const maanden = inputMaanden % 12;

  if (jaren && maanden) {
    return `${jaren} jaar en ${maanden} maanden`;
  }
  if (jaren) {
    return `${jaren} jaar`;
  }
  if (!jaren && maanden) {
    return `${maanden} maanden`;
  }
  return "";
};

export const jaarMaandNaarMaandenNumber = (inputString: string): number | null => {
  let arr: string[] | number[] = inputString.split(" ");
  let hasYears = false;

  if (arr.length > 1 && arr[1][0] === "j") hasYears = true;

  arr = arr.map(s => (s === "" ? NaN : Number(s))).filter(v => !isNaN(v));

  if (arr.length === 1) return hasYears ? arr[0] * 12 : arr[0];
  if (arr.length === 2) return arr[0] * 12 + arr[1];

  return null;
};

export const maandenNaarJrMnd = (inputMaanden: number): string => {
  const jaren = Math.floor(inputMaanden / 12);
  const maanden = inputMaanden % 12;

  if (jaren && maanden) {
    return `${jaren} jr ${maanden} mnd`;
  }
  if (jaren) {
    return `${jaren} jr`;
  }
  if (maanden) {
    return `${maanden} mnd`;
  }
  return "";
};

export function jaarMaandNaarMaanden(periode: JaarMaandInputType | null): string {
  const maanden = jaarMaandInMaanden(periode);
  if (maanden) {
    return `${maanden} mnd`;
  }

  return "";
}

export function werkJaarMaandInputBijInSideEffect(
  periode: JaarMaandInputType,
  maanden: number | null,
  soortProduct: SoortKredietProductOptions | null,
  ingangsdatum: LocalDate | null
): void {
  if (typeof maanden === "number") {
    if (maanden >= 0) {
      if (soortProduct === SoortKredietProductOptions.DoorlopendKrediet) {
        const vandaag = LocalDate.now();
        // in geval van een doorlopend krediet, is maanden de theoretisch aflosduur
        // de aflossingsStartdatum is vandaag, tenzij ingangsdatum groter is
        let aflossingsStartdatum = vandaag;
        if (ingangsdatum?.isAfter(aflossingsStartdatum)) aflossingsStartdatum = ingangsdatum;
        // de theoretische einddatum is today + de theoretische aflosduur
        let theoretischeEinddatum = aflossingsStartdatum.plusMonths(maanden);
        // aan het eind van de maand wil dat nog wel eens een probleem opleveren, bijvoorbeeld: 2022,5,31 + 40 maanden = 2025,9,30
        // de until komt dan 1 maand te kort
        if (theoretischeEinddatum.dayOfMonth() < aflossingsStartdatum.dayOfMonth()) {
          theoretischeEinddatum = theoretischeEinddatum.plusDays(1);
        }
        // de theoretische looptijd is het aantal maanden tussen ingangsdatum en theoretische einddatum
        maanden = (ingangsdatum ?? vandaag).until(theoretischeEinddatum, ChronoUnit.MONTHS);
      }
      periode.jaren = Math.floor(maanden / 12);
      periode.maanden = maanden % 12;
    } else {
      periode.jaren = 0;
      periode.maanden = 0;
    }
  } else {
    periode.jaren = null;
    periode.maanden = null;
  }
}
