import * as Yup from "yup";
import { InferType } from "yup";
import { IndicatieOptions } from "../../../../../.generated/forms/formstypes";
import { WithSaveData } from "../../../../../shared/utils/save-data";
import { yupEnum } from "../../../../../shared/utils/yup-enum";
import { nullableLocalDateSchema } from "adviesbox-shared";

export const hypotheekBijCardSchema = Yup.object({
  aanvrager1Naam: Yup.string().nullable(),
  aanvrager2Naam: Yup.string().nullable(),
  brutoInkomenAanvrager1: Yup.number().nullable(),
  brutoInkomenAanvrager2: Yup.number().nullable(),
  kredietToetslast: Yup.number().nullable(),
  marktwaarde: Yup.number().nullable(),
  erfpachtCanon: Yup.number().nullable(),
  totaalGevraagdeHypotheek: Yup.number().nullable(),
  indicatieMeerMinderLenen: Yup.number().nullable(),
  verplichteExtraAflossing: Yup.number().nullable(),
  maximaalObvInkomenLasten: Yup.number().nullable(),
  maximaalObvOnderpand: Yup.number().nullable(),
  maximaalObvNHGVoorwaarden: Yup.number().nullable(),
  maximaalObvInkomenLastenIndicatie: Yup.bool(),
  maximaalObvOnderpandIndicatie: Yup.bool(),
  maximaalObvNHGVoorwaardenIndicatie: Yup.bool(),
  opNHGBasis: Yup.bool(),
  hintTextAanvrager1: Yup.string().nullable(),
  hintTextAanvrager2: Yup.string().nullable(),
  geldverstrekkerNaam: Yup.string().nullable(),
  toonWaarschuwingMaxHypotheekIsMaxHypotheekBox3: Yup.bool().nullable()
});
export type HypotheekBijCardType = Yup.InferType<typeof hypotheekBijCardSchema>;

export const toelichtingRegelSchema = Yup.object({
  regel: Yup.string()
});

export const acceptatieVoetnootSchema = Yup.object({
  voetnoot: Yup.string()
});

export type AcceptatieVoetnootType = Yup.InferType<typeof acceptatieVoetnootSchema>;

export const acceptatieCardSchema = Yup.object({
  acceptatieIndicatie: yupEnum(IndicatieOptions)
    .default(IndicatieOptions.Goedgekeurd)
    .nullable(),
  toelichtingRegels: Yup.array(toelichtingRegelSchema)
    .default([
      {
        regel: "Uw gewenste hypotheek is hoger dan maximale hypotheek (xx.xxx)"
      },
      {
        regel: "Maximale hypotheek € 190.000 i.v.m. waarde pand"
      }
    ])
    .nullable(),
  voetnoten: Yup.array(acceptatieVoetnootSchema)
    .default([
      {
        voetnoot:
          "* Deze indictie controleert een gedeelte van het acceptatiebeleid van de geldverstrekker. De beoordeling en goedkeuring vindt altijd door de geldverstrekker zelf plaats. Aan deze indicatie kunnen geen rechten ontleend worden."
      },
      {
        voetnoot:
          "Deze toetsing is gebaseerd op de normen 2021. Bij overgang jaarnormen kan in de aparte berekening 'Sneltoets' met beide normen getoetst worden."
      }
    ])
    .nullable()
});

export type AcceptatieCardType = Yup.InferType<typeof acceptatieCardSchema>;

export const specificatieGevraagdeHypotheekSchema = Yup.object({
  doorlopendeHypotheken: Yup.number().nullable(),
  gewensteHypotheek: Yup.number().nullable(),
  totaalGevraagd: Yup.number().nullable()
});

export const periodeSchema = Yup.object({
  periode: Yup.string(),
  startDatum: nullableLocalDateSchema.default(null),
  eindDatum: nullableLocalDateSchema.default(null),
  inkomenAanvrager1: Yup.number().nullable(),
  inkomenAanvrager2: Yup.number().nullable(),
  onverpandVermogen: Yup.number().nullable(),
  toetsrente: Yup.number().nullable(),
  woonquoteBox1: Yup.number().nullable(),
  woonquoteBox3: Yup.number().nullable(),
  toegestaneMaandlast: Yup.number().nullable(),
  maandlastVoorstelBox1: Yup.number().nullable(),
  maandlastVoorstelBox3: Yup.number().nullable(),
  totaleMaandlastVoorstel: Yup.number().nullable(),
  beschikbareRuimte: Yup.number().nullable(),
  werkelijkeMaandlast: Yup.number().nullable()
});

export const specificatieIndicatieMeerMinderchema = Yup.object({
  toetsrente: Yup.number().nullable(),
  woonquote: Yup.number().nullable(),
  fictieveJaarlast: Yup.number().nullable(),
  toegestaneJaarlast: Yup.number().nullable(),
  constanteWaardeMeerMinderLenen: Yup.number().nullable(),
  uitwerkingen: Yup.array(periodeSchema),
  naamAanvrager: Yup.string(),
  naamPartner: Yup.string().nullable()
});

export const extraAflossingSchema = Yup.object({
  periode: Yup.string(),
  verplichteExtraAflossing: Yup.number().nullable(),
  mogelijkeExtraAflossing: Yup.number().nullable(),
  looptijd: Yup.number().nullable(),
  rente: Yup.number().nullable()
});
export type ExtraAflossingType = InferType<typeof extraAflossingSchema>;

export const specificatieExtraAflossingschema = Yup.object({
  specificaties: Yup.array(extraAflossingSchema)
});

export const acceptatieSchema = Yup.object({
  showPlaceholderCard: Yup.bool(),
  placeholderTitle: Yup.string(),
  placeholderMessage: Yup.string(),
  hypotheekBijCard: hypotheekBijCardSchema,
  acceptatieCard: acceptatieCardSchema,
  specificatieTotaalGevraagdeHypotheek: specificatieGevraagdeHypotheekSchema,
  specificatieIndicatieMeerMinder: specificatieIndicatieMeerMinderchema,
  specificatieExtraAflossing: specificatieExtraAflossingschema
});

export type AcceptatieState = Yup.InferType<typeof acceptatieSchema>;
export type AcceptatieProps = Yup.InferType<typeof acceptatieSchema> & WithSaveData<AcceptatieState>;

export type PeriodeUitwerkingType = Yup.InferType<typeof periodeSchema>;
export type SpecificatieHypotheekState = Yup.InferType<typeof specificatieGevraagdeHypotheekSchema>;
export type SpecificatieExtraAflossingenState = Yup.InferType<typeof specificatieExtraAflossingschema>;
export type SpecificatieIndicatieMeerMinderLenenState = Yup.InferType<typeof specificatieIndicatieMeerMinderchema>;
