import { hasValue } from "adviesbox-shared";
import reactFastCompare from "react-fast-compare";
import { Verkoopstatus } from "../../.generated/forms/formstypes";
import { createISWSideEffect, initISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";
import { GetAdresEncoded, GetMapsImageUrl } from "../../shared/components/mapsimage/MapImage";
import { SoortFoto, WoningState, VerkoopWoningType, HdnAnders } from "./woning-schema";

const teVerkopenWoningenDraftSideEffects = createISWSideEffect<VerkoopWoningType>(({ has, draft }): void => {
  if (has.verkoopstatus.changed) {
    if (draft.verkoopstatus === "BlijftInBezit") {
      draft.verkoopstatusDatum = null;
      draft.ontbindendeVoorwaardenDatum = null;
      draft.verkoopprijs = null;
      draft.verkoopkosten = null;
      draft.verkoopondervoorwaarden = null;
      draft.constructie = null;
      draft.constructieOmschrijving = null;
    }
  }
  if (has.verkoopstatus.changed && draft.verkoopstatus === Verkoopstatus.WordtVerkocht) {
    draft.verkoopstatusDatum = null;
    draft.ontbindendeVoorwaardenDatum = null;
  }

  if (draft.berekenen) {
    const verkoopkosten = typeof draft.verkoopprijs === "number" ? draft.verkoopprijs * 0.02 : null;
    if (verkoopkosten !== draft.verkoopkosten) {
      draft.verkoopkosten = verkoopkosten;
    }
  }
});

export const woningDraftSideEffects = createISWSideEffect<WoningState>(({ has, draft, subset, prev }): void => {
  if (draft.aanTeKopenWoning !== null && prev.aanTeKopenWoning !== null) {
    if (has.aanTeKopenWoning.adresGelijkAanWoonadres.changed) {
      if (draft.aanTeKopenWoning.adresGelijkAanWoonadres) {
        draft.aanTeKopenWoning.adres = { ...draft.aanTeKopenWoning.woonAdres };
      }
    }

    if (
      draft.aanTeKopenWoning.adres.postcode.length > 0 &&
      draft.aanTeKopenWoning.adres.huisnummer !== null &&
      draft.aanTeKopenWoning.fotoGebruikRapportage &&
      draft.aanTeKopenWoning.soortFoto === SoortFoto.GoogleMaps &&
      (!reactFastCompare(draft.aanTeKopenWoning.adres, prev.aanTeKopenWoning.adres) ||
        has.aanTeKopenWoning.fotoGebruikRapportage.changed ||
        has.aanTeKopenWoning.soortFoto.changed)
    ) {
      const locationStr = GetAdresEncoded(draft.aanTeKopenWoning.adres);
      const mapsUrl = GetMapsImageUrl(locationStr);

      draft.aanTeKopenWoning.locatieFoto = mapsUrl;
    }
    // indien foto voor rapportage gebruiken uit gezet wordt; locatie foto leegmaken.
    if (has.aanTeKopenWoning.fotoGebruikRapportage.changed) {
      draft.aanTeKopenWoning.locatieFoto = draft.aanTeKopenWoning.fotoGebruikRapportage
        ? draft.aanTeKopenWoning.locatieFoto
        : "";
      draft.aanTeKopenWoning.soortFoto = draft.aanTeKopenWoning.fotoGebruikRapportage
        ? draft.aanTeKopenWoning.soortFoto
        : SoortFoto.Geen;
    }

    // opschonen 'constructieOmschrijving' indien constructie anders dan '99'
    draft.teVerkopenWoningen.forEach((woning, index) => {
      const woningChanged = has.teVerkopenWoningen[index];
      if (
        !woningChanged.changed &&
        !woningChanged.constructie.changed &&
        !woningChanged.verkoopondervoorwaarden.changed
      )
        return;

      if (!woning.verkoopondervoorwaarden) {
        woning.constructie = null;
        woning.constructieOmschrijving = null;
      }

      if (
        woning.constructie == null ||
        (woning.constructie !== `${HdnAnders}` && hasValue(woning.constructieOmschrijving))
      ) {
        woning.constructieOmschrijving = null;
      }
    });
  }

  draft.teVerkopenWoningen.forEach((woning, index): void => {
    teVerkopenWoningenDraftSideEffects(subset.teVerkopenWoningen[index].create());
  });
});

export const determineWoningSideEffects = initISWSideEffect(woningDraftSideEffects)();
