import { ReactElement } from "react";

import { SoortLijfrenteUitkeringOptions, SoortLijfrenteProductOptions } from "../.generated/forms/formstypes";

import { AdviesBoxColumn, createSpanWithId } from "adviesbox-shared";
import { jaarMaandInputNaarJaarMaand } from "../shared/generic-parts/jaar-maand/helpers";
import { AanvragerKeuze } from "../shared/types";
import { SituatieSoort } from "../producten-overzicht/infra/producten-overzicht-types";

const getSoortLijfrente = (soortProduct: SoortLijfrenteProductOptions): string => {
  switch (soortProduct) {
    case SoortLijfrenteProductOptions.BancaireLijfrente:
      return "Bancaire lijfrente";
    case SoortLijfrenteProductOptions.LijfrenteVerzekering:
      return "Lijfrenteverzekering";
  }
};
const getVerzekerden = (aanvragers: AanvragerKeuze): string => {
  switch (aanvragers) {
    case AanvragerKeuze.Aanvrager1:
      return "Aanvrager 1";
    case AanvragerKeuze.Aanvrager2:
      return "Aanvrager 2";
    case AanvragerKeuze.Beiden:
      return "Beiden";
  }
};

export const bepaalUitkerendeLijfrenteKolommen = (situatie: SituatieSoort): AdviesBoxColumn[] => {
  return [
    {
      Header: "Lijfrente",
      Cell: (c): ReactElement =>
        createSpanWithId(
          c.index,
          0,
          situatie === "huidig" ? "Bestaand" : c.original.product.doorlopend ? "Doorlopend" : "Nieuw"
        )
    },
    {
      Header: "Partij",
      Cell: (c): ReactElement =>
        createSpanWithId(c.index, 1, c.original.product.partijNaam, c.original.product.partijNaam)
    },
    {
      Header: "Soort lijfrente",
      Cell: (c): ReactElement => {
        const soortProduct = getSoortLijfrente(c.original.soortProduct);
        return createSpanWithId(c.index, 2, soortProduct, soortProduct);
      }
    },
    {
      Header: "Verzekerde(n)",
      Cell: (c): ReactElement => createSpanWithId(c.index, 3, getVerzekerden(c.original.verzekerden.verzekerden))
    },
    {
      Header: "Koopsom",
      Cell: "CurrencyInput",
      accessor: "kapitaal.beschikbareKoopsom",
      config: { readonly: true }
    },
    {
      Header: "Uitkering",
      Cell: "CurrencyInput",
      accessor: "indicatieveUitkerendeFase.lijfrenteUitkering.bedrag",
      config: { readonly: true }
    },
    {
      Header: "Duur",
      Cell: (c): ReactElement => {
        let title = "Levenslang";
        if (
          c.original.indicatieveUitkerendeFase.soortLijfrenteUitkering !== SoortLijfrenteUitkeringOptions.Levenslang
        ) {
          title = jaarMaandInputNaarJaarMaand(c.original.indicatieveUitkerendeFase.duurUitkering);
        }
        return createSpanWithId(c.index, 6, title, title);
      }
    },
    {
      Cell: "DeleteButton"
    }
  ];
};
