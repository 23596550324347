import { PremiedepotOutput } from "../../.generated/vermogen/vermogentypes";
import {
  createISWAsyncSideEffect,
  initISWAsyncSideEffect
} from "../../shared/components/isw-side-effects/create-isw-helpers";
import { mapPremieDepotBerekening } from "../infra/product-mapper-dl-2-ui";
import { PremieDepotModalType } from "../infra/producten-overzicht-types";

export const premieDepotEffect = createISWAsyncSideEffect<PremieDepotModalType>(
  async ({ draft, settings, fetchData }) => {
    const result = await fetchData<PremiedepotOutput>({
      url: `${settings.vermogenOrigin}/premiedepot`,
      body: JSON.stringify(mapPremieDepotBerekening(draft))
    });

    if (!result.isValid) return;
    draft.bedrag = result.premiedepotBedrag;
    draft.duur = result.premiedepotDuurInMaanden;
  }
);

export const determinePremieDepotModalAsyncSideEffects = initISWAsyncSideEffect<PremieDepotModalType>(
  ({ runAsync, has }) => {
    if (has.changed) {
      runAsync(premieDepotEffect());
    }
  }
)();
