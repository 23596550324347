import React from "react";

import { Icon } from "adviesbox-shared";
import { MaximaleHypotheekUitslagTypes } from "../../../infra/maximale-hypotheek-schema";

export const castUitslag2Icon = (uitslag: string): React.ReactElement => {
  let icon;

  switch (uitslag) {
    case MaximaleHypotheekUitslagTypes.groen:
      icon = <Icon name="vink" alt="vink" />;
      break;
    case MaximaleHypotheekUitslagTypes.oranje:
      icon = <Icon name="uitroepteken" multiColor={true} alt="uitroepteken" />;
      break;
    case MaximaleHypotheekUitslagTypes.rood:
      icon = <Icon name="kruis" alt="kruis" />;
      break;
  }

  return <div className="w-100 h-100 d-flex justify-content-center align-items-center">{icon}</div>;
};
