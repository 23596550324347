import { Card, CardWrapper, ErrorPage, PageLoading } from "adviesbox-shared";
import { connect, FormikContextType } from "formik";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import { SoortLijfrenteProductOptions } from "../../.generated/forms/formstypes";
import { AppDataContext } from "../../navigation/appdata-context";
import IndicatieveUitkerendeFase from "../../producten-overzicht/indicatieve-uitkerende-fase-modal/indicatieve-uitkerende-fase";
import { partijOnafhankelijk } from "../../producten-overzicht/infra/product-constanten";
import { isKenmerkError } from "../../producten-overzicht/infra/product-kenmerken-types";
import { ProductkenmerkenContext } from "../../producten-overzicht/infra/producten-context";
import { SituatieSoort } from "../../producten-overzicht/infra/producten-overzicht-types";
import Product from "../../producten-overzicht/product/product";
import Verzekerden from "../../producten-overzicht/verzekerden/verzekerden";
import Verzekeringnemers from "../../producten-overzicht/verzekering-nemers/verzekering-nemers";
import { ISWSideEffects } from "../../shared/components/isw-side-effects/isw-side-effects";
import { determineUitkerendeLijfrenteDetailsAsyncSideEffects } from "../infra/determine-uitkerende-lijfrente-details-async-side-effects";
import { determineUitkerendeLijfrenteDetailsSideEffects } from "../infra/determine-uitkerende-lijfrente-details-side-effects";
import { UitkerendeLijfrentesType } from "../infra/uitkerende-lijfrente-types";
import Kapitaal from "../kapitaal/kapitaal";

type UitkerendeLijfrenteDetailsProps = { situatie: SituatieSoort; selected: number };

const UitkerendeLijfrenteDetails = (
  props: { formik: FormikContextType<UitkerendeLijfrentesType> } & UitkerendeLijfrenteDetailsProps
): ReactElement => {
  const { setSErunning } = useContext(AppDataContext);
  const {
    situatie,
    selected,
    formik: {
      values: { producten }
    }
  } = props;
  const selectedProduct = producten && producten[selected];

  const kenmerkenContext = useContext(ProductkenmerkenContext);
  const [kenmerken, setKenmerken] = useState<null | ReturnType<typeof kenmerkenContext.getProductKenmerken>>(null);
  const isLijfrenteVerzekering = selectedProduct.soortProduct === SoortLijfrenteProductOptions.LijfrenteVerzekering;
  const isBancaireLijfrente = selectedProduct.soortProduct === SoortLijfrenteProductOptions.BancaireLijfrente;

  useEffect(() => {
    setKenmerken(
      kenmerkenContext.getProductKenmerken("UitkerendeLijfrente", partijOnafhankelijk, selectedProduct.productCode)
    );
  }, [setKenmerken, kenmerkenContext, selectedProduct.productCode]);

  if (!kenmerken) return <PageLoading />;

  if (kenmerken && isKenmerkError(kenmerken)) {
    return <ErrorPage error={new Error(`Fout bij het ophalen van producteigenschappen: ${kenmerken.reden}`)} />;
  }

  return (
    <CardWrapper className="px-3" maxRowCount={7}>
      <ISWSideEffects<UitkerendeLijfrentesType>
        sync={determineUitkerendeLijfrenteDetailsSideEffects({ selected })}
        async={determineUitkerendeLijfrenteDetailsAsyncSideEffects({ selected })}
        asyncStartStopCallback={setSErunning}
      />

      <Card
        title={
          situatie === "voorstel"
            ? `${selectedProduct.product.doorlopend ? "Doorlopend" : "Nieuw"} product`
            : "Huidig product"
        }
      >
        <Product
          selected={selected}
          situatie={situatie}
          kenmerken={kenmerken.product}
          productSoort="uitkerende-lijfrente"
          isStarterslening={false}
        />
      </Card>

      {isLijfrenteVerzekering && (
        <Card title="Verzekeringnemer(s)">
          <Verzekeringnemers selected={selected} kenmerken={kenmerken.verzekeringnemer} />
        </Card>
      )}

      <Card title={isBancaireLijfrente ? "Contractant" : "Verzekerde(n)"}>
        <Verzekerden
          selected={selected}
          kenmerken={kenmerken.verzekerden}
          caption={isBancaireLijfrente ? "Contractant" : null}
        />
      </Card>
      <Card title="Kapitaal">
        <Kapitaal selected={selected} />
      </Card>
      <Card title="Indicatieve uitkerende fase">
        <IndicatieveUitkerendeFase
          baseName={`producten[${selected}].indicatieveUitkerendeFase`}
          ingangsdatum={selectedProduct.product.ingangsdatum}
          verzekerden={selectedProduct.verzekerden.verzekerden}
          kenmerken={kenmerken.indicatieveUitkerendeFaseKenmerken}
        />
      </Card>
    </CardWrapper>
  );
};

UitkerendeLijfrenteDetails.displayName = "UitkerendeLijfrenteDetails";

export default connect<UitkerendeLijfrenteDetailsProps, UitkerendeLijfrentesType>(UitkerendeLijfrenteDetails);
