import React, { createContext, useState, useCallback, useContext, useMemo } from "react";

type RefreshContextType = {
  refreshTrigger: number;
  triggerRefresh: () => void;
};

const RefreshContext = createContext<RefreshContextType>({
  refreshTrigger: 0,
  triggerRefresh: () => {}
});

/* eslint-disable react/prop-types */
export const RefreshProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  const triggerRefresh = useCallback((): void => {
    setRefreshTrigger(prev => prev + 1);
  }, []);

  const value = useMemo(() => ({ refreshTrigger, triggerRefresh }), [refreshTrigger, triggerRefresh]);

  return <RefreshContext.Provider value={value}>{children}</RefreshContext.Provider>;
};

export const useRefreshTrigger = (): RefreshContextType => useContext(RefreshContext);
