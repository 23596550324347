import { SettingsType } from "adviesbox-shared";
import { User } from "oidc-client";

export type Vertaling = {
  key: string;
  value: string;
};

export const getVertalingen = async (settings: SettingsType, user: User | null): Promise<Vertaling[]> => {
  if (user == null) return [];

  const url = `${settings.vertaalOrigin}/Vertaal/nl-NL/AdviesNaw`;
  const rsp = await fetch(url, {
    method: "GET",
    headers: {
      authorization: `${user?.token_type} ${user?.access_token}`,
      "Ocp-Apim-Subscription-Key": settings.OcpApimSubscriptionKey,
      "Content-Type": "application/json;charset=UTF-8"
    }
  });

  if (!rsp.ok) return [];

  const json = await rsp.json();
  const context = json.contexts[0];

  return [ ...context.vertalingen ];
};
