type CategorieKenmerk = { categorie: string; productkenmerken: {} };

export type ProductKenmerkenDlType = {
  productkenmerkCategorieen: CategorieKenmerk[]; //ProductkenmerkCategorie[]; //
  isValid: boolean;
  message: null;
  validationResults: any[];
};

// export interface ProductkenmerkCategorie {
//   categorie: "Product" | "Premie" | "Persoonkeuze" | ;
//   productkenmerken: ProductKenmerken | PremieKenmerken;
// }

export const productKenmerken = {
  maatschappijkeuzeEnabled: true,
  logoTonen: true,
  productoptiesTonen: true,
  productnummerTonen: true,
  ingangsdatumTonen: true,
  einddatumTonen: true,
  uwBemiddelingTonen: true,
  einddatumEnabled: true,
  looptijdTonen: true,
  looptijdJaarEnabled: true,
  looptijdMaandEnabled: true,
  productnaamTonen: true,
  productnaamEnabled: true
};
export type ProductKenmerken = typeof productKenmerken;

export const premieKenmerken = {
  aanvangstortingEnabled: true,
  aanvangstortingTonen: true,
  aftrekbarePremieTonen: true,
  berekenPremieKnopTonen: true,
  betalingstermijnEnabled: true,
  betalingstermijnTonen: true,
  componentKenmerk: true,
  duurHoogLaagConstructieTonen: true,
  duurHoogLaagConstructieEnabled: true,
  duurPremiebetalingEnabled: true,
  duurPremiebetalingTonen: true,
  einddatumPremiebetalingEnabled: true,
  einddatumPremiebetalingTonen: true,
  extraStortingTonen: true,
  garantiePremieTonen: true,
  heeftBetalingstermijnHalfJaar: true,
  heeftBetalingstermijnJaar: true,
  heeftBetalingstermijnKoopsom: true,
  heeftBetalingstermijnKwartaal: true,
  heeftBetalingstermijnMaand: true,
  heeftBetalingstermijnTweeMaanden: true,
  heeftPremieverloopGelijkblijvend: true,
  heeftPremieverloopVariabel: true,
  hintAanvangstortingTonen: true,
  hintRisicopremiespecificatieTonen: true,
  hoogLaagConstructieTonen: true,
  hoogLaagVerhoudingTonen: true,
  ingebrachteWaardeTonen: true,
  premiebetalingEnabled: true,
  premiedepotTonen: true,
  premieHoogEnabled: true,
  premiestijgendTonen: true,
  premieverloopTonen: true,
  premiegegevensTonen: true,
  risicopremieEnabled: true,
  risicopremiespecificatieHint: "",
  risicopremiespecificatieTonen: true,
  risicopremieTonen: true,
  soortDekking: true,
  spaarpremieEnabled: true,
  spaarpremieTonen: true,
  totalePremieEnabled: true,
  totalePremieTonen: true,
  verkortePremieduurTonen: true,
  hintPremieAftrekbaarTonen: true,
  hintPremieAftrekbaar: ""
};
export type PremieKenmerken = typeof premieKenmerken;

export const validatieKenmerken = {
  minLeeftijdBijAanvang: 18,
  maxLeeftijdBijAanvang: 60,
  maxLeeftijdBijEinde: 75,
  maxLooptijdInJaren: 40,
  minLooptijdInJaren: 5,
  maxMaandenInToekomst: 3,
  datum1evdMaand: false,
  datumInVerleden: true,
  minKapitaalBijOverlijden: 25000,
  maxKapitaalBijOverlijden: 2450000,
  minAnnuPercentage: 1,
  maxAnnuPercentage: 15,
  looptijdAlleenHeleJaren: true,
  minLooptijdInMaanden: 60,
  maxLooptijdInMaanden: 480,
  minLooptijdInMaandenBox1Polis: 0,
  minAanvangsstorting: 0,
  minPremie: 25,
  maxLooptijdPremieInMaanden: 0,
  maxLeeftijdBijEinde2Verzekerden: 0,
  maxLeeftijdBijEindePVAO: 0,
  tweeLevensMogelijk: true,
  aanvangsleeftijdInMaanden: 864,
  HogeInlegFgvToegestaan: false
};

export type ValidatieKenmerken = typeof validatieKenmerken;

export const dekkingKenmerken = {
  verzekerdKapitaalTonen: true,
  tweedeVerzekerdKapitaalTonen: true,
  annuiteitspercentageTonen: true,
  tweedeAnnuiteitsperscentageTonen: true,
  dekkingDaaltTotTonen: true,
  tweedeDekkingDaaltTotTonen: true,
  reservewaardeTonen: true,
  hintReservewaardeTonen: true,
  aanvullingTotWettelijkMaximumTonen: true,
  annuiteitspercentageEnabled: true,
  verzekeringsmotiefTonen: true,
  verzekeringsmotiefEnabled: true,
  verzekeringsmotiefDefault: null,
  doelVerzekeringTonen: true,
  doelVerzekeringEnabled: true,
  doelVerzekeringDefault: null,
  premieAoTonen: false,
  premieWwTonen: false,
  premieTopTonen: false,
  premieCollectiefTonen: false,
  premieAftrekbaarTonen: false,
  premieAftrekbaarEnabled: false,
  dekkingWwTonen: false,
  verzekerdBedragAoVerloopTonen: false,
  maandinkomenTonen: false,
  maandinkomenEnabled: false,
  gewenstGegarandeerdMaandinkomenTonen: false,
  heeftVerzekerdeDekkingWw: false,
  heeftVerzekerdeDekkingAo: false,
  heeftVerzekerdeDekkingEa: false,
  verzekerdeDekkingTonen: false,
  heeftBetalingstermijnCombinatie: false,
  koopsomPeriodeTonen: false,

  heeftEigenRisicoAo30Dagen: false,
  heeftEigenRisicoAo90Dagen: false,
  heeftEigenRisicoAo180Dagen: false,
  heeftEigenRisicoAo365Dagen: false,
  heeftEigenRisicoAo730Dagen: false,
  voornaamsteInkomstenbronTonen: false,
  verzekerdBedragAoEnabled: false,
  looptijdAoTonen: false,
  looptijdWwTonen: false,
  uitkeringsduurAoTonen: false,
  eigenRisicoAoTonen: false,
  aoUitkeringsduurTonen: false,
  heeftAoUitkeringsduur24Maanden: false,
  heeftAoUitkeringsduur60Maanden: false,
  heeftAoUitkeringsduur120Maanden: false,
  heeftAoUitkeringsduurTotEindeLooptijd: false,
  uitkeringGedeeltelijkAoTonen: false,
  hintUitkeringGedeeltelijkAoTonen: false,
  hintUitkeringGedeeltelijkAo: "Voorbeeld tekst",
  verzekerdBedragWwEnabled: false,
  wwUitkeringdduurTonen: false,
  looptijdWwEnabled: false,
  uitkeringsduurWwEnabled: false,
  claimBeoordelingAoTonen: false,
  heeftWwUitkeringdduur12Maanden: false,
  HeeftWwUitkeringdduur24Maanden: false,
  premieJaarlijkseIndexatieTonen: false,
  indexatieAoTonen: false,
  heeftSpecificatieAoUitkering: false,
  marktwaardeTonen: false,
  marktwaardeEnabled: false,
  hypotheekBedragTonen: true,
  hypotheekBedragEnabled: false,
  heeftUitkeringGedeeltelijkAoVolledig: false,
  heeftUitkeringGedeeltelijkAoNaarRato: false,
  verzekerdeKredietsomTonen: false,
  aanvullendeInformatie: ""
};

export type DekkingKenmerken = typeof dekkingKenmerken;

export const persoonkeuzeKenmerken = {
  contractantenSelectieTonen: false,
  verzekerdeSelectie: false,
  verzekeringnemerSelectie: false,
  premiesplitsingTonen: false,
  meerdereVerzekerdeOptiesMogelijk: false,
  meerdereVerzekeringnemerOptiesMogelijk: false,
  meerdereContractantenOptiesMogelijk: false,
  beroepTonen: false
};

export type PersoonkeuzeKenmerken = typeof persoonkeuzeKenmerken;

export const verpandingKenmerken = {
  verpandingTonen: true,
  pandhouderLijstTonen: true,
  lijfrenteUitkeringTonen: true,
  inkomensaanvullingTonen: true,
  belastingBoxTonen: true,
  soortLijfrenteUitkeringTonen: true,
  overgangOpTweedeVerzekerdeTonen: true,
  bedoeldAflossingTonen: true
};
export type VerpandingKenmerken = typeof verpandingKenmerken;

export const onttrekkingenKenmerken = {
  begindatumTonen: true,
  einddatumTonen: true,
  duurTonen: true,
  onttrekkingstermijnTonen: true,
  onttrekkingBedragTonen: true
};
export type OnttrekkingenKenmerken = typeof onttrekkingenKenmerken;

export const aanvraagKenmerken = {
  sprakeVanStrafrechtelijkVerledenTonen: false,
  akkoordMetDigitaleCommunicatieTonen: false,
  toelichtingGelezenAkkoordMetSlotverklaringTonen: false,
  hintToelichtingGelezenAkkoordMetSlotverklaring: "",
  akkoordMetDigitalePolisTonen: false,
  faillissementTonen: false,
  reorganisatieTonen: false,
  voorkennisOntslagTonen: false
};

export type AanvraagKenmerken = typeof aanvraagKenmerken;

export const verzekeringnemerKenmerken = {
  meerdereVerzekeringnemerOptiesMogelijk: false
};

export type VerzekeringnemerKenmerken = typeof verzekeringnemerKenmerken;

export const verzekerdenKenmerken = {
  meerdereVerzekerdeOptiesMogelijk: false,
  premiesplitsingTonen: false
};

export type VerzekerdenKenmerken = typeof verzekerdenKenmerken;

export const indicatieveUitkerendeFaseKenmerken = {
  belastingBoxTonen: false,
  soortLijfrenteUitkeringTonen: false,
  overgangOpTweedeVerzekerdeTonen: false
};

export type IndicatieveUitkerendeFaseKenmerken = typeof indicatieveUitkerendeFaseKenmerken;

export const contractantenKenmerken = {
  meerdereContractantenOptiesMogelijk: false
};

export type ContractantKenmerken = typeof contractantenKenmerken;

export const geldnemerKenmerken = {
  meerderGeldnemersOptiesMogelijk: false
};

export type GeldnemerKenmerken = typeof geldnemerKenmerken;

export const heffingen = {
  meerdereheffingBijOptiesMogelijk: false
};

export type HeffingKenmerken = typeof heffingen;

export const leninggegevensKenmerken = {
  heeftScenarioKorting: false,
  heeftPeriodiekeOpname: false,
  maximaleOpnameDuurMaanden: 240,
  minimaleOpnameDuurMaanden: 1
};

export type LeninggegevensKenmerken = typeof leninggegevensKenmerken;

export const dekkingHypothekenKenmerken = {
  overlijdensrisicodekkingTonen: false
};

export type DekkingHypothekenKenmerken = typeof dekkingHypothekenKenmerken;

export const kapitaalopbouwKenmerken = {
  heeftScenarioKorting: false,
  kapitaalopbouwTonen: false,
  doelkapitaalEnabled: false,
  rekenrendementEnabled: false,
  soortVerzekeringTonen: false,
  beleggersprofielTonen: false,
  soortBerekeningTonen: false
};

export type KapitaalopbouwKenmerken = typeof kapitaalopbouwKenmerken;

export const fiscaleRegelingKenmerken = {
  lijfrenteclausuleTonen: false,
  fiscaleRegelingTonen: false,
  kapitaalopbouwBoxKeuzeTonen: false,
  orvBoxKeuzeTonen: false,
  kapitaalopbouwOpKaartTonen: false,
  lijfrenteclausuleOpKaartTonen: false
};

export type FiscaleRegelingKenmerken = typeof fiscaleRegelingKenmerken;

export const gekoppeldProductKenmerken = {
  hintGekoppeldProduct: "",
  gekoppeldProductTonen: false
};

export const hdnaanvraagKenmerken = {
  hintHDNAanvraagNietAanwezig: "",
  hDNAanvraagNietAanwezigTonen: false
};

export type GekoppeldProductKenmerken = typeof gekoppeldProductKenmerken;
export type HdnaanvraagKenmerken = typeof hdnaanvraagKenmerken;
// export const premieHypothekenKenmerken = {
//   PremiegegevensTonen: false,
//   HoogLaagConstructieTonen: false,
//   AanvangstortingTonen: false,
//   PremiedepotTonen: false
// };

// export type PremieHypothekenKenmerken = typeof premieHypothekenKenmerken;

export const opmerkingKenmerken = {
  hintOpmerking: "",
  opmerkingTonen: false
};

export type OpmerkingKenmerken = typeof opmerkingKenmerken;

export const leningGegevensKenmerken = {
  berekenHoofdsomTonen: true,
  garantieTonen: true,
  restantHoofdsomTonen: true,
  slottermijnTonen: true,
  aflossingPercentageTonen: true,
  berekenMaandlastTonen: true,
  bestedingsdoelEnabled: true
};

export type LeningGegevensKenmerken = typeof leningGegevensKenmerken;

export const generiekeKapitaalopbouwKenmerken = {
  soortRekeningTonen: true,
  soortLijfrenteTonen: true,
  soortBerekeningTonen: true,
  soortBerekeningEigenInvoerTonen: true,
  beleggersprofielTonen: true,
  doelkapitaalBedragTonen: true,
  doelkapitaalBedragEnabled: true,
  doelrendementPercentageTonen: true,
  doelrendementPercentageEnabled: true,
  voorbeeldkapitaalBedragTonen: true,
  voorbeeldkapitaalBedragEnabled: true,
  voorbeeldrendementPercentageTonen: true,
  voorbeeldkapitaalPercentageEnabled: true,
  garantiekapitaalBedragTonen: true,
  garantierendementPercentageTonen: true
};

export type GeneriekeKapitaalopbouwKenmerkenKenmerken = typeof generiekeKapitaalopbouwKenmerken;

export const overzichtKenmerken = {
  product: { ...productKenmerken },
  premie: { ...premieKenmerken },
  dekking: { ...dekkingKenmerken },
  verzekerden: { ...verzekerdenKenmerken },
  verzekeringnemer: { ...verzekeringnemerKenmerken },
  verpanding: { ...verpandingKenmerken },
  validaties: { ...validatieKenmerken },
  aanvraag: { ...aanvraagKenmerken },
  kapitaalopbouw: { ...generiekeKapitaalopbouwKenmerken }
};

export type OverzichtKenmerken = typeof overzichtKenmerken;

export const orvKenmerken = {
  product: { ...productKenmerken },
  premie: { ...premieKenmerken },
  verzekerden: { ...verzekerdenKenmerken },
  verzekeringnemer: { ...verzekeringnemerKenmerken },
  dekking: { ...dekkingKenmerken },
  verpanding: { ...verpandingKenmerken },
  validaties: { ...validatieKenmerken },
  aanvraag: { ...aanvraagKenmerken },
  hdnaanvraag: { ...hdnaanvraagKenmerken }
};

export type OrvKenmerken = typeof orvKenmerken;

export const aovKenmerken = {
  aanvraag: { ...aanvraagKenmerken },
  product: { ...productKenmerken },
  premie: { ...premieKenmerken },
  persoonkeuze: { ...persoonkeuzeKenmerken },
  verpanding: { ...verpandingKenmerken },
  dekking: { ...dekkingKenmerken },
  validaties: { ...validatieKenmerken }
};

export type AovKenmerken = typeof aovKenmerken;

export const hypothekenKenmerken = {
  product: { ...productKenmerken },
  leninggegeven: { ...leninggegevensKenmerken },
  persoonkeuze: { ...persoonkeuzeKenmerken },
  dekking: { ...dekkingHypothekenKenmerken },
  kapitaalopbouw: { ...kapitaalopbouwKenmerken },
  fiscaleRegeling: { ...fiscaleRegelingKenmerken },
  premie: { ...premieKenmerken },
  gekoppeldProduct: { ...gekoppeldProductKenmerken },
  opmerking: { ...opmerkingKenmerken },
  validaties: { ...validatieKenmerken }
};

export type HypothekenKenmerken = typeof hypothekenKenmerken;

export const kredietKenmerken = {
  product: { ...productKenmerken },
  leningGegevens: { ...leningGegevensKenmerken }
};

export type KredietKenmerken = typeof kredietKenmerken;

export const uitkerendeLijfrenteKenmerken = {
  product: { ...productKenmerken },
  verzekeringnemer: { ...verzekeringnemerKenmerken },
  verzekerden: { ...verzekerdenKenmerken },
  indicatieveUitkerendeFaseKenmerken: { ...indicatieveUitkerendeFaseKenmerken }
};

export const depotKenmerken = {
  depotTonen: true,
  bestedingsdoelTonen: true,
  aankoopkostenPercentageTonen: true,
  verkoopkostenPercentageTonen: true,
  beheerkostenPercentageTonen: true,
  depotWaardeheaderTonen: true,
  waardeopbouwBedragTonen: true,
  waardeopbouwBedragEnabled: true,
  reedsOpgebouwdBedragTonen: true,
  reedsOpgebouwdDatumTonen: true,
  afkoopwaardeDatumTonen: true,
  afkoopwaardeBedragTonen: true,
  waardeopbouwNa5JaarTonen: true
};

export type DepotKenmerken = typeof depotKenmerken;

export type UitkerendeLijfrenteKenmerken = typeof uitkerendeLijfrenteKenmerken;

export const vermogenKenmerken = {
  product: { ...productKenmerken },
  fiscaleRegeling: { ...fiscaleRegelingKenmerken },
  verzekeringnemer: { ...verzekeringnemerKenmerken },
  depot: { ...depotKenmerken },
  kapitaalopbouw: { ...generiekeKapitaalopbouwKenmerken },
  premie: { ...premieKenmerken },
  verpanding: { ...verpandingKenmerken },
  onttrekkingen: { ...onttrekkingenKenmerken }
};

export type VermogenKenmerken = typeof vermogenKenmerken;

export const poliswaardeKenmerken = {
  waardeopbouwBedragTonen: true,
  waardeopbouwBedragEnabled: true,
  reedsOpgebouwdBedragTonen: true,
  reedsOpgebouwdDatumTonen: true,
  afkoopwaardeDatumTonen: true,
  afkoopwaardeBedragTonen: true
};

export type PoliswaardeKenmerken = typeof poliswaardeKenmerken;

export const kapitaalverzekeringKenmerken = {
  product: { ...productKenmerken },
  verzekeringnemer: { ...verzekeringnemerKenmerken },
  verzekerden: { ...verzekerdenKenmerken },
  kapitaalopbouw: { ...generiekeKapitaalopbouwKenmerken },
  premie: { ...premieKenmerken },
  poliswaarde: { ...poliswaardeKenmerken },
  verpanding: { ...verpandingKenmerken }
};

export type KapitaalverzekeringKenmerken = typeof kapitaalverzekeringKenmerken;

export type KenmerkenError = {
  reden: string;
};

export type EenVanDeKenmerken =
  | OrvKenmerken
  | AovKenmerken
  | HypothekenKenmerken
  | KredietKenmerken
  | UitkerendeLijfrenteKenmerken
  | VermogenKenmerken
  | KapitaalverzekeringKenmerken
  | KenmerkenError;

export type ProductSoort =
  | "Aov"
  | "Hypotheek"
  | "Kapitaalverzekering"
  | "Krediet"
  | "Orv"
  | "UitkerendeLijfrente"
  | "Vermogen";

export type ProductDict = {
  [key in ProductSoort]: {
    [key: string]: {
      [key: string]: EenVanDeKenmerken;
    };
  };
};
export const maakMinimaleProductDict: ProductDict = {
  Orv: {},
  Aov: {},
  Hypotheek: {},
  Krediet: {},
  UitkerendeLijfrente: {},
  Vermogen: {},
  Kapitaalverzekering: {}
};

export const isKenmerkError = (kenmerk: { [index: string]: any }): kenmerk is KenmerkenError =>
  typeof kenmerk.reden === "string";
