import React, { ReactElement, useState, useEffect } from "react";
import { FormFirstFocus, CardWrapper, Card, useRequestInit } from "adviesbox-shared";
import { Form } from "react-bootstrap";
import { FormikContextType, connect } from "formik";
import { importState } from "./infra/import-schema";
import { renderExterneKoppelingInportGegevens } from "./externekoppeling-import-helper";
import { DevDebug } from "../shared/components/dev-debug/dev-debug";
import { Vertaling, getVertalingen } from "./infra/externekoppeling-import-api";

const ExterneKoppelingImportDetails = ({ formik }: { formik: FormikContextType<importState> }): ReactElement => {
  const { settings, user } = useRequestInit();
  const [vertalingen, setVertalingen] = useState<Vertaling[]>([]);

  useEffect(() => {
    const fetchVertalingen = async (): Promise<void> => {
      const ver = await getVertalingen(settings, user);
      setVertalingen(ver);
    };

    /* eslint-disable-next-line @typescript-eslint/no-floating-promises */
    fetchVertalingen();
  }, [settings, user]);

  return (
    <FormFirstFocus>
      <Form>
        <div className="d-flex content_wrapper">
          <div className="content">
            {formik.values?.fieldMaps?.map(
                (fieldmap, indexFieldMap) =>
                  fieldmap.labelValues.length > 0 && (
                    <CardWrapper flexType="flex-row" className="px-3" key={`wrapper-${indexFieldMap}`} maxRowCount={3}>
                      <Card title={fieldmap.parent} key={`card-${indexFieldMap}`}>
                        <div className="content-body collapse show" key={`div-content-${indexFieldMap}`}>
                          {renderExterneKoppelingInportGegevens(
                            indexFieldMap,
                            fieldmap.labelValues,
                            formik,
                            vertalingen
                          )}
                        </div>
                      </Card>
                    </CardWrapper>
                  )
              )}
          </div>
        </div>
        <DevDebug />
      </Form>
    </FormFirstFocus>
  );
};

export default connect<{}, importState>(ExterneKoppelingImportDetails);
