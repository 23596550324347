import { AdviesBoxColumn, createSpanWithId, DataGrid } from "adviesbox-shared";
import { FormikProps } from "formik";
import React, { ReactElement, useContext } from "react";
import { AppDataContext } from "../../navigation/appdata-context";
import { ISWSideEffects } from "../../shared/components/isw-side-effects/isw-side-effects";
import Modal from "../../shared/components/modal/Modal";
import { KlantnaamType } from "../../shared/generic-parts/klantnaam/schema";
import AdviesBoxFormik from "../../shared/utils/adviesbox-formik";
import { bedragFormat } from "../../shared/utils/currency";
import { extraOpnameSchema, extraOpnamesSchema } from "../infra/vermogen-schema";
import { ExtraOpnamesSpecificatieModalType, VermogenType } from "../infra/vermogen-types";
import { extraOpnamesSpecificatieModalAsyncSideEffects } from "./extra-opnames-specificatie-modal-async-side-effects";

type ExtraOpnamesSpecificatieModalProps = {
  extraOpnames: ExtraOpnamesSpecificatieModalType;
  entries: number | null;
  product: VermogenType;
  aanvrager1: KlantnaamType | null;
  aanvrager2: KlantnaamType | null;
  onSave?: (data: ExtraOpnamesSpecificatieModalType) => void;
  closeModal?: () => void;
};

const columns: AdviesBoxColumn[] = [
  {
    Header: "Jaar",
    Cell: (c): ReactElement => createSpanWithId(c.index, 0, c.original.jaar),
    width: 50
  },
  {
    Header: "Extra inleg",
    Cell: (c): ReactElement => createSpanWithId(c.index, 1, bedragFormat(c.original.extraInlegBedrag))
  },
  {
    Header: "Opname",
    Cell: "CurrencyInput",
    accessor: "opnameBedrag"
  }
];

const ExtraOpnamesSpecificatieModal = ({
  extraOpnames,
  entries,
  product,
  aanvrager1,
  aanvrager2,
  closeModal,
  onSave
}: ExtraOpnamesSpecificatieModalProps): ReactElement => {
  const { setSErunning } = useContext(AppDataContext);

  const initialValues = React.useMemo<ExtraOpnamesSpecificatieModalType>(() => {
    const scenario = [...extraOpnames.scenario];

    if (typeof entries === "number") {
      if (scenario.length < entries) {
        for (let index = scenario.length; index < entries; index++) {
          scenario.push({ ...extraOpnameSchema.default(), jaar: scenario.length + 1 });
        }
      } else {
        // Truncate array
        scenario.length = entries;
      }
    }

    return {
      scenario
    };
  }, [extraOpnames, entries]);

  return (
    <AdviesBoxFormik<ExtraOpnamesSpecificatieModalType>
      initialValues={initialValues}
      validationSchema={extraOpnamesSchema}
      onSave={onSave}
      closeModal={closeModal}
      render={({ submitForm }: FormikProps<ExtraOpnamesSpecificatieModalType>): ReactElement => (
        <Modal
          title="Opname uit beleggingen (op jaarbasis)"
          body={
            <>
              <ISWSideEffects<ExtraOpnamesSpecificatieModalType>
                async={extraOpnamesSpecificatieModalAsyncSideEffects({
                  product,
                  aanvrager1,
                  aanvrager2
                })}
                asyncStartStopCallback={setSErunning}
              />

              <DataGrid
                editable
                columns={columns}
                name="scenario"
                defaultPageSize={Number.MAX_VALUE}
                showButtonDelete={false}
              />
            </>
          }
          onSubmitClick={submitForm}
          onCancelClick={closeModal}
        />
      )}
    />
  );
};

ExtraOpnamesSpecificatieModal.displayName = "ExtraOpnamesSpecificatieModal";

export default ExtraOpnamesSpecificatieModal;
