import { LocalDate, Period } from "@js-joda/core";
import { InkomenLoondienstInput, InkomenOutput, InkomenPeriode } from "../../.generated/forms/formstypes";
import {
  createISWAsyncSideEffect,
  createISWSideEffect,
  initISWAsyncSideEffect,
  initISWSideEffect
} from "../../shared/components/isw-side-effects/create-isw-helpers";
import { berekenDateSchema, BerekenDateType } from "../../shared/generic-parts/bereken-date/schema";
import { createMapEnum } from "../../shared/utils/create-map-enum";
import { createMapToDl } from "../../shared/utils/create-map-to-dl";
import { limitCurrent } from "../../shared/utils/helpers";
import {
  AOWType,
  BrutoSalarisPeriode,
  FiscaleVerdelingType,
  InkomenEnFiscusState,
  inkomenSchema,
  InkomenUitArbeidApiControllerAction,
  inkomenUitArbeidModalSchema,
  InkomenUitArbeidModalType,
  inkomstenAanmerkelijkBelangModalSchema,
  inkomstenUitBeleggingenModalSchema,
  inkomstenVerhuurOnroerendGoedModalSchema,
  ondernemingModalSchema,
  overigeInkomstenModalSchema,
  overigeWerkzaamhedenModalSchema
} from "./inkomen-en-fiscus-schema";
import { determineGemiddeldInkomensVerleden } from "./shared-components/determine-inkomensverleden-side-effects";
import { scenarioCardInputType } from "../../shared/types";

const getScenario = (
  enabled: boolean,
  berekenDateChanged: boolean,
  berekenDate: BerekenDateType,
  gemiddeldInkomenChanged: boolean,
  gemiddeldInkomen: number | null,
  scenario: scenarioCardInputType[]
): scenarioCardInputType[] => {
  if (!enabled) {
    return [];
  }

  const ingangsDatum = LocalDate.ofYearDay(LocalDate.now().year(), 1);
  const meetellenTot = berekenDate.berekenen
    ? berekenDate.berekendeDatum || LocalDate.now().plusYears(30)
    : berekenDate.datum || LocalDate.now().plusYears(30);
  const scenarioJaren = Period.between(ingangsDatum, meetellenTot).years();

  if (berekenDateChanged && !gemiddeldInkomenChanged && scenario.length) {
    if (scenarioJaren < scenario.length) {
      // Minder scenario jaren
      scenario = scenario.slice(0, scenarioJaren);
    } else if (scenarioJaren > scenario.length) {
      // Meer scenario jaren
      const lastKnownValue = scenario[scenario.length - 1].bedrag;
      const lastKnownPc = scenario[scenario.length - 1].percentage;
      const addYears = scenarioJaren - scenario.length;
      for (let i = 0; i < addYears; i++) scenario.push({ bedrag: lastKnownValue, percentage: lastKnownPc });
    }
  }

  // Scenario tabel opnieuw opbouwen
  if (gemiddeldInkomenChanged || (enabled && scenario.length === 0)) {
    scenario = [];
    for (let i = 0; i < scenarioJaren; i++) {
      scenario.push({
        bedrag: gemiddeldInkomen,
        percentage: 0
      });
    }
  }
  return scenario;
};

const determineAowSideEffects = createISWSideEffect<AOWType>(({ has, draft }): void => {
  if (has.korting.changed && draft.originalBedrag) {
    const berekendBedrag = draft.korting
      ? draft.originalBedrag * ((100 - 2 * draft.korting) / 100)
      : draft.originalBedrag;
    draft.bedrag = Math.max(berekendBedrag, 0);
  }
});

const determineFiscaliteitSideEffects = createISWSideEffect<InkomenEnFiscusState>(({ has, draft }): void => {
  if (
    has.aanvrager1Fiscaliteit.toggleFiscaleBijtelposten.changed &&
    !draft.aanvrager1Fiscaliteit.toggleFiscaleBijtelposten
  ) {
    draft.aanvrager1Fiscaliteit.fiscaleBijtelposten = {
      bijtellingBedraagt: null,
      bijtellingOpInkomen: null,
      eigenBijdrage: null
    };
  }

  if (
    has.aanvrager2Fiscaliteit.toggleFiscaleBijtelposten.changed &&
    !draft.aanvrager2Fiscaliteit.toggleFiscaleBijtelposten
  ) {
    draft.aanvrager2Fiscaliteit.fiscaleBijtelposten = {
      bijtellingBedraagt: null,
      bijtellingOpInkomen: null,
      eigenBijdrage: null
    };
  }

  if (
    has.aanvrager1Fiscaliteit.toggleFiscaleAftrekposten.changed &&
    !draft.aanvrager1Fiscaliteit.toggleFiscaleAftrekposten
  ) {
    draft.aanvrager1Fiscaliteit.fiscaleAftrekposten = {
      eindDatum: null,
      teBetalenAlimentatie: 0
    };
  }

  if (
    has.aanvrager2Fiscaliteit.toggleFiscaleAftrekposten.changed &&
    !draft.aanvrager2Fiscaliteit.toggleFiscaleAftrekposten
  ) {
    draft.aanvrager2Fiscaliteit.fiscaleAftrekposten = {
      eindDatum: null,
      teBetalenAlimentatie: 0
    };
  }
});

const determineFiscaleVerdelingSideEffects = createISWSideEffect<FiscaleVerdelingType>(({ has, draft }): void => {
  if (has.aanvrager1.changed || has.aanvrager2.changed) {
    const wasAanvrager1Changed = has.aanvrager1.changed;
    const wasAanvrager2Changed = has.aanvrager2.changed;

    draft.aanvrager1 = wasAanvrager2Changed ? 100 - limitCurrent(draft.aanvrager2) : limitCurrent(draft.aanvrager1);
    draft.aanvrager2 = wasAanvrager1Changed ? 100 - limitCurrent(draft.aanvrager1) : limitCurrent(draft.aanvrager2);
  }
});

const determineInkomenSideEffects = createISWSideEffect<InkomenEnFiscusState>(({ has, draft, subset }): void => {
  if (has.aanvrager1Inkomen.inkomenUitArbeid.changed) {
    if (draft.aanvrager1Inkomen.inkomenUitArbeid.length === 1) {
      draft.aanvrager1Inkomen.brutoSalarisUitDienstverband = draft.aanvrager1Inkomen.inkomenUitArbeid[0].totaalBruto;
    } else if (draft.aanvrager1Inkomen.inkomenUitArbeid.length > 1) {
      draft.aanvrager1Inkomen.brutoSalarisUitDienstverband = draft.aanvrager1Inkomen.inkomenUitArbeid.reduce(
        (p, c): number => p + (c.totaalBruto || 0),
        0
      );
    }
  }

  if (has.aanvrager2Inkomen.inkomenUitArbeid.changed) {
    if (draft.aanvrager2Inkomen.inkomenUitArbeid.length === 1) {
      draft.aanvrager2Inkomen.brutoSalarisUitDienstverband = draft.aanvrager2Inkomen.inkomenUitArbeid[0].totaalBruto;
    } else if (draft.aanvrager2Inkomen.inkomenUitArbeid.length > 1) {
      draft.aanvrager2Inkomen.brutoSalarisUitDienstverband = draft.aanvrager2Inkomen.inkomenUitArbeid.reduce(
        (p, c): number => p + (c.totaalBruto || 0),
        0
      );
    }
  }
});

const determineBrutoloonSideEffects = createISWSideEffect<InkomenEnFiscusState>(({ has, draft }): void => {
  if (has.aanvrager1Inkomen.verklaringInkomen.changed) {
    draft.aanvrager1Inkomen.toetsinkomenUwv = inkomenSchema.default().toetsinkomenUwv;
  }

  if (has.aanvrager2Inkomen.verklaringInkomen.changed) {
    draft.aanvrager2Inkomen.toetsinkomenUwv = inkomenSchema.default().toetsinkomenUwv;
  }

  if (
    has.aanvrager1Inkomen.brutoSalarisUitDienstverbandIsEnabled.changed &&
    (!draft.aanvrager1Inkomen.brutoSalarisUitDienstverbandIsEnabled ||
      (draft.aanvrager1Inkomen.brutoSalarisUitDienstverbandIsEnabled &&
        draft.aanvrager1Inkomen.inkomenUitArbeid.length === 0))
  ) {
    draft.aanvrager1Inkomen.inkomenUitArbeid = [
      {
        ...inkomenUitArbeidModalSchema.default(),
        geboortedatum: draft.aanvrager1Aow.geboorteDatum,
        inkomenMeetellenTot: {
          ...berekenDateSchema.default(),
          berekendeDatum: draft.aanvrager1Aow.ingangsdatum.berekendeDatum
        }
      }
    ];

    draft.aanvrager1Inkomen.brutoSalarisUitDienstverband = inkomenSchema.default().brutoSalarisUitDienstverband;
    draft.aanvrager1Inkomen.toetsinkomenUwv = inkomenSchema.default().toetsinkomenUwv;
    draft.aanvrager1Inkomen.verklaringInkomen = inkomenSchema.default().verklaringInkomen;
  }

  if (
    has.aanvrager2Inkomen.brutoSalarisUitDienstverbandIsEnabled.changed &&
    (!draft.aanvrager2Inkomen.brutoSalarisUitDienstverbandIsEnabled ||
      (draft.aanvrager2Inkomen.brutoSalarisUitDienstverbandIsEnabled &&
        draft.aanvrager2Inkomen.inkomenUitArbeid.length === 0))
  ) {
    draft.aanvrager2Inkomen.inkomenUitArbeid = [
      {
        ...inkomenUitArbeidModalSchema.default(),
        geboortedatum: draft.aanvrager2Aow.geboorteDatum,
        inkomenMeetellenTot: {
          ...berekenDateSchema.default(),
          berekendeDatum: draft.aanvrager2Aow.ingangsdatum.berekendeDatum
        }
      }
    ];

    draft.aanvrager2Inkomen.brutoSalarisUitDienstverband = inkomenSchema.default().brutoSalarisUitDienstverband;
    draft.aanvrager2Inkomen.toetsinkomenUwv = inkomenSchema.default().toetsinkomenUwv;
    draft.aanvrager2Inkomen.verklaringInkomen = inkomenSchema.default().verklaringInkomen;
  }
});

const determineOverigeInkomstenSideEffects = createISWSideEffect<InkomenEnFiscusState>(({ has, draft }): void => {
  if (
    has.aanvrager1Inkomen.overigeInkomstenBox1IsEnabled.changed &&
    !draft.aanvrager1Inkomen.overigeInkomstenBox1IsEnabled
  ) {
    draft.aanvrager1Inkomen.overigeInkomstenBox1 = overigeInkomstenModalSchema.default();
  }

  if (
    has.aanvrager2Inkomen.overigeInkomstenBox1IsEnabled.changed &&
    !draft.aanvrager2Inkomen.overigeInkomstenBox1IsEnabled
  ) {
    draft.aanvrager2Inkomen.overigeInkomstenBox1 = overigeInkomstenModalSchema.default();
  }
});

const determineOndernemingSideEffects = createISWSideEffect<InkomenEnFiscusState>(({ has, draft }): void => {
  if (has.aanvrager1Inkomen.ondernemingIsEnabled.changed && !draft.aanvrager1Inkomen.ondernemingIsEnabled) {
    const ingangsdatum = draft.aanvrager1Aow.ingangsdatum.berekenen
      ? draft.aanvrager1Aow.ingangsdatum.berekendeDatum
      : draft.aanvrager1Aow.ingangsdatum.datum;

    draft.aanvrager1Inkomen.onderneming = ondernemingModalSchema.default();
    draft.aanvrager1Inkomen.onderneming.inkomenMeetellenTot.berekendeDatum = ingangsdatum;
    draft.aanvrager1Inkomen.onderneming.scenarioInstellingen.einddatum = ingangsdatum;
    draft.aanvrager1Inkomen.onderneming.scenarioInstellingen.hasAanvrager2 = draft.hasAanvrager2;
    draft.aanvrager1Inkomen.onderneming.scenarioInstellingen.geboortedatum = draft.aanvrager1Aow.geboorteDatum;
  }

  if (has.aanvrager2Inkomen.ondernemingIsEnabled.changed && !draft.aanvrager2Inkomen.ondernemingIsEnabled) {
    const ingangsdatum = draft.aanvrager2Aow.ingangsdatum.berekenen
      ? draft.aanvrager2Aow.ingangsdatum.berekendeDatum
      : draft.aanvrager2Aow.ingangsdatum.datum;

    draft.aanvrager2Inkomen.onderneming = ondernemingModalSchema.default();
    draft.aanvrager2Inkomen.onderneming.inkomenMeetellenTot.berekendeDatum = ingangsdatum;
    draft.aanvrager2Inkomen.onderneming.scenarioInstellingen.einddatum = ingangsdatum;
    draft.aanvrager2Inkomen.onderneming.scenarioInstellingen.hasAanvrager2 = draft.hasAanvrager2;
    draft.aanvrager2Inkomen.onderneming.scenarioInstellingen.geboortedatum = draft.aanvrager2Aow.geboorteDatum;
  }

  if (
    has.aanvrager1Inkomen.onderneming.inkomenMeetellenTot.changed ||
    has.aanvrager1Inkomen.onderneming.inkomensverleden.resultaat1.changed
  ) {
    draft.aanvrager1Inkomen.onderneming.scenario = getScenario(
      draft.aanvrager1Inkomen.ondernemingIsEnabled,
      has.aanvrager1Inkomen.onderneming.inkomenMeetellenTot.changed,
      draft.aanvrager1Inkomen.onderneming.inkomenMeetellenTot,
      has.aanvrager1Inkomen.onderneming.inkomensverleden.resultaat1.changed,
      draft.aanvrager1Inkomen.onderneming.inkomensverleden.resultaat1,
      draft.aanvrager1Inkomen.onderneming.scenario
    );
  }
  if (
    has.aanvrager2Inkomen.onderneming.inkomenMeetellenTot.changed ||
    has.aanvrager2Inkomen.onderneming.inkomensverleden.resultaat1.changed
  ) {
    draft.aanvrager2Inkomen.onderneming.scenario = getScenario(
      draft.aanvrager2Inkomen.ondernemingIsEnabled,
      has.aanvrager2Inkomen.onderneming.inkomenMeetellenTot.changed,
      draft.aanvrager2Inkomen.onderneming.inkomenMeetellenTot,
      has.aanvrager2Inkomen.onderneming.inkomensverleden.resultaat1.changed,
      draft.aanvrager2Inkomen.onderneming.inkomensverleden.resultaat1,
      draft.aanvrager2Inkomen.onderneming.scenario
    );
  }
});

const determineInkomstenAanmerkelijkBelangSideEffects = createISWSideEffect<InkomenEnFiscusState>(
  ({ has, draft }): void => {
    if (
      has.aanvrager1Inkomen.inkomstenAanmerkelijkBelangIsEnabled.changed &&
      !draft.aanvrager1Inkomen.inkomstenAanmerkelijkBelangIsEnabled
    ) {
      const ingangsdatum = draft.aanvrager1Aow.ingangsdatum.berekenen
        ? draft.aanvrager1Aow.ingangsdatum.berekendeDatum
        : draft.aanvrager1Aow.ingangsdatum.datum;

      draft.aanvrager1Inkomen.inkomstenAanmerkelijkBelang = inkomstenAanmerkelijkBelangModalSchema.default();
      draft.aanvrager1Inkomen.inkomstenAanmerkelijkBelang.inkomenMeetellenTot.berekendeDatum = ingangsdatum;
      draft.aanvrager1Inkomen.inkomstenAanmerkelijkBelang.scenarioInstellingen.einddatum = ingangsdatum;
      draft.aanvrager1Inkomen.inkomstenAanmerkelijkBelang.scenarioInstellingen.hasAanvrager2 = draft.hasAanvrager2;
      draft.aanvrager1Inkomen.inkomstenAanmerkelijkBelang.scenarioInstellingen.geboortedatum =
        draft.aanvrager1Aow.geboorteDatum;
    }

    if (
      has.aanvrager2Inkomen.inkomstenAanmerkelijkBelangIsEnabled.changed &&
      !draft.aanvrager2Inkomen.inkomstenAanmerkelijkBelangIsEnabled
    ) {
      const ingangsdatum = draft.aanvrager2Aow.ingangsdatum.berekenen
        ? draft.aanvrager2Aow.ingangsdatum.berekendeDatum
        : draft.aanvrager2Aow.ingangsdatum.datum;

      draft.aanvrager2Inkomen.inkomstenAanmerkelijkBelang = inkomstenAanmerkelijkBelangModalSchema.default();
      draft.aanvrager2Inkomen.inkomstenAanmerkelijkBelang.inkomenMeetellenTot.berekendeDatum = ingangsdatum;
      draft.aanvrager2Inkomen.inkomstenAanmerkelijkBelang.scenarioInstellingen.einddatum = ingangsdatum;
      draft.aanvrager2Inkomen.inkomstenAanmerkelijkBelang.scenarioInstellingen.hasAanvrager2 = draft.hasAanvrager2;
      draft.aanvrager2Inkomen.inkomstenAanmerkelijkBelang.scenarioInstellingen.geboortedatum =
        draft.aanvrager2Aow.geboorteDatum;
    }

    if (
      has.aanvrager1Inkomen.inkomstenAanmerkelijkBelang.inkomenMeetellenTot.changed ||
      has.aanvrager1Inkomen.inkomstenAanmerkelijkBelang.inkomensverleden.resultaat1.changed
    ) {
      draft.aanvrager1Inkomen.inkomstenAanmerkelijkBelang.scenario = getScenario(
        draft.aanvrager1Inkomen.inkomstenAanmerkelijkBelangIsEnabled,
        has.aanvrager1Inkomen.inkomstenAanmerkelijkBelang.inkomenMeetellenTot.changed,
        draft.aanvrager1Inkomen.inkomstenAanmerkelijkBelang.inkomenMeetellenTot,
        has.aanvrager1Inkomen.inkomstenAanmerkelijkBelang.inkomensverleden.resultaat1.changed,
        draft.aanvrager1Inkomen.inkomstenAanmerkelijkBelang.inkomensverleden.resultaat1,
        draft.aanvrager1Inkomen.inkomstenAanmerkelijkBelang.scenario
      );
    }
    if (
      has.aanvrager2Inkomen.inkomstenAanmerkelijkBelang.inkomenMeetellenTot.changed ||
      has.aanvrager2Inkomen.inkomstenAanmerkelijkBelang.inkomensverleden.resultaat1.changed
    ) {
      draft.aanvrager2Inkomen.inkomstenAanmerkelijkBelang.scenario = getScenario(
        draft.aanvrager2Inkomen.inkomstenAanmerkelijkBelangIsEnabled,
        has.aanvrager2Inkomen.inkomstenAanmerkelijkBelang.inkomenMeetellenTot.changed,
        draft.aanvrager2Inkomen.inkomstenAanmerkelijkBelang.inkomenMeetellenTot,
        has.aanvrager2Inkomen.inkomstenAanmerkelijkBelang.inkomensverleden.resultaat1.changed,
        draft.aanvrager2Inkomen.inkomstenAanmerkelijkBelang.inkomensverleden.resultaat1,
        draft.aanvrager2Inkomen.inkomstenAanmerkelijkBelang.scenario
      );
    }
  }
);

const determineOverigeWerkzaamhedenSideEffects = createISWSideEffect<InkomenEnFiscusState>(({ has, draft }): void => {
  if (
    has.aanvrager1Inkomen.overigeWerkzaamhedenIsEnabled.changed &&
    !draft.aanvrager1Inkomen.overigeWerkzaamhedenIsEnabled
  ) {
    const ingangsdatum = draft.aanvrager1Aow.ingangsdatum.berekenen
      ? draft.aanvrager1Aow.ingangsdatum.berekendeDatum
      : draft.aanvrager1Aow.ingangsdatum.datum;

    draft.aanvrager1Inkomen.overigeWerkzaamheden = overigeWerkzaamhedenModalSchema.default();
    draft.aanvrager1Inkomen.overigeWerkzaamheden.inkomenMeetellenTot.berekendeDatum = ingangsdatum;
    draft.aanvrager1Inkomen.overigeWerkzaamheden.scenarioInstellingen.einddatum = ingangsdatum;
    draft.aanvrager1Inkomen.overigeWerkzaamheden.scenarioInstellingen.hasAanvrager2 = draft.hasAanvrager2;
    draft.aanvrager1Inkomen.overigeWerkzaamheden.scenarioInstellingen.geboortedatum = draft.aanvrager1Aow.geboorteDatum;
  }

  if (
    has.aanvrager2Inkomen.overigeWerkzaamhedenIsEnabled.changed &&
    !draft.aanvrager2Inkomen.overigeWerkzaamhedenIsEnabled
  ) {
    const ingangsdatum = draft.aanvrager2Aow.ingangsdatum.berekenen
      ? draft.aanvrager2Aow.ingangsdatum.berekendeDatum
      : draft.aanvrager2Aow.ingangsdatum.datum;

    draft.aanvrager2Inkomen.overigeWerkzaamheden = overigeWerkzaamhedenModalSchema.default();
    draft.aanvrager2Inkomen.overigeWerkzaamheden.inkomenMeetellenTot.berekendeDatum = ingangsdatum;
    draft.aanvrager2Inkomen.overigeWerkzaamheden.scenarioInstellingen.einddatum = ingangsdatum;
    draft.aanvrager2Inkomen.overigeWerkzaamheden.scenarioInstellingen.hasAanvrager2 = draft.hasAanvrager2;
    draft.aanvrager2Inkomen.overigeWerkzaamheden.scenarioInstellingen.geboortedatum = draft.aanvrager2Aow.geboorteDatum;
  }

  if (
    has.aanvrager1Inkomen.overigeWerkzaamheden.inkomenMeetellenTot.changed ||
    has.aanvrager1Inkomen.overigeWerkzaamheden.inkomensverleden.resultaat1.changed
  ) {
    draft.aanvrager1Inkomen.overigeWerkzaamheden.scenario = getScenario(
      draft.aanvrager1Inkomen.overigeWerkzaamhedenIsEnabled,
      has.aanvrager1Inkomen.overigeWerkzaamheden.inkomenMeetellenTot.changed,
      draft.aanvrager1Inkomen.overigeWerkzaamheden.inkomenMeetellenTot,
      has.aanvrager1Inkomen.overigeWerkzaamheden.inkomensverleden.resultaat1.changed,
      draft.aanvrager1Inkomen.overigeWerkzaamheden.inkomensverleden.resultaat1,
      draft.aanvrager1Inkomen.overigeWerkzaamheden.scenario
    );
  }
  if (
    has.aanvrager2Inkomen.overigeWerkzaamheden.inkomenMeetellenTot.changed ||
    has.aanvrager2Inkomen.overigeWerkzaamheden.inkomensverleden.resultaat1.changed
  ) {
    draft.aanvrager2Inkomen.overigeWerkzaamheden.scenario = getScenario(
      draft.aanvrager2Inkomen.overigeWerkzaamhedenIsEnabled,
      has.aanvrager2Inkomen.overigeWerkzaamheden.inkomenMeetellenTot.changed,
      draft.aanvrager2Inkomen.overigeWerkzaamheden.inkomenMeetellenTot,
      has.aanvrager2Inkomen.overigeWerkzaamheden.inkomensverleden.resultaat1.changed,
      draft.aanvrager2Inkomen.overigeWerkzaamheden.inkomensverleden.resultaat1,
      draft.aanvrager2Inkomen.overigeWerkzaamheden.scenario
    );
  }
});

const determineBox3SideEffects = createISWSideEffect<InkomenEnFiscusState>(({ has, draft }): void => {
  if (has.aanvrager1Inkomen.inkomstenBox3IsEnabled.changed && !draft.aanvrager1Inkomen.inkomstenBox3IsEnabled) {
    draft.aanvrager1Inkomen.inkomstenUitBeleggingen = inkomstenUitBeleggingenModalSchema.default();
    draft.aanvrager1Inkomen.inkomstenUitBeleggingen.scenarioInstellingen.hasAanvrager2 = draft.hasAanvrager2;

    draft.aanvrager1Inkomen.inkomstenVerhuurOnroerendGoed = inkomstenVerhuurOnroerendGoedModalSchema.default();
    draft.aanvrager1Inkomen.inkomstenVerhuurOnroerendGoed.scenarioInstellingen.hasAanvrager2 = draft.hasAanvrager2;
  }

  if (has.aanvrager2Inkomen.inkomstenBox3IsEnabled.changed && !draft.aanvrager2Inkomen.inkomstenBox3IsEnabled) {
    draft.aanvrager2Inkomen.inkomstenUitBeleggingen = inkomstenUitBeleggingenModalSchema.default();
    draft.aanvrager2Inkomen.inkomstenUitBeleggingen.scenarioInstellingen.hasAanvrager2 = draft.hasAanvrager2;

    draft.aanvrager2Inkomen.inkomstenVerhuurOnroerendGoed = inkomstenVerhuurOnroerendGoedModalSchema.default();
    draft.aanvrager2Inkomen.inkomstenVerhuurOnroerendGoed.scenarioInstellingen.hasAanvrager2 = draft.hasAanvrager2;
  }
});

const determineSociaalSideEffects = createISWSideEffect<InkomenEnFiscusState>(({ has, draft }): void => {
  if (has.aanvrager1Sociaal.voldoetWekeneis.changed && !draft.aanvrager1Sociaal.voldoetWekeneis) {
    draft.aanvrager1Sociaal.voldoetJareneis = false;
  }

  if (has.aanvrager2Sociaal.voldoetWekeneis.changed && !draft.aanvrager2Sociaal.voldoetWekeneis) {
    draft.aanvrager2Sociaal.voldoetJareneis = false;
  }
});

const inkomenEnFiscusSideEffects = createISWSideEffect<InkomenEnFiscusState>((bag): void => {
  determineAowSideEffects(bag.subset.aanvrager1Aow.create());
  determineAowSideEffects(bag.subset.aanvrager2Aow.create());
  determineFiscaleVerdelingSideEffects(bag.subset.fiscaleVerdeling.create());
  determineInkomenSideEffects(bag);
  determineFiscaliteitSideEffects(bag);
  determineBrutoloonSideEffects(bag);
  determineOndernemingSideEffects(bag);
  determineOverigeInkomstenSideEffects(bag);
  determineInkomstenAanmerkelijkBelangSideEffects(bag);
  determineOverigeWerkzaamhedenSideEffects(bag);
  determineBox3SideEffects(bag);
  determineSociaalSideEffects(bag);
  determineGemiddeldInkomensVerleden(bag.subset.aanvrager1Inkomen.overigeWerkzaamheden.inkomensverleden.create());
  determineGemiddeldInkomensVerleden(bag.subset.aanvrager2Inkomen.overigeWerkzaamheden.inkomensverleden.create());

  bag.draft.aanvrager1Inkomen.inkomenUitArbeid.forEach((_, index) => {
    if (
      bag.has.aanvrager1Inkomen.inkomenUitArbeid[index].inkomenMeetellenTot.changed ||
      bag.has.aanvrager1Inkomen.inkomenUitArbeid[index].brutoSalarisPerJaar.changed
    ) {
      bag.draft.aanvrager1Inkomen.inkomenUitArbeid[index].scenario = getScenario(
        bag.draft.aanvrager1Inkomen.brutoSalarisUitDienstverbandIsEnabled,
        bag.has.aanvrager1Inkomen.inkomenUitArbeid[index].inkomenMeetellenTot.changed,
        bag.draft.aanvrager1Inkomen.inkomenUitArbeid[index].inkomenMeetellenTot,
        bag.has.aanvrager1Inkomen.inkomenUitArbeid[index].brutoSalarisPerJaar.changed,
        bag.draft.aanvrager1Inkomen.inkomenUitArbeid[index].totaalBruto,
        bag.draft.aanvrager1Inkomen.inkomenUitArbeid[index].scenario
      );
    }
    if (
      bag.has.aanvrager2Inkomen.inkomenUitArbeid[index].inkomenMeetellenTot.changed ||
      bag.has.aanvrager2Inkomen.inkomenUitArbeid[index].brutoSalarisPerJaar.changed
    ) {
      bag.draft.aanvrager2Inkomen.inkomenUitArbeid[index].scenario = getScenario(
        bag.draft.aanvrager2Inkomen.brutoSalarisUitDienstverbandIsEnabled,
        bag.has.aanvrager2Inkomen.inkomenUitArbeid[index].inkomenMeetellenTot.changed,
        bag.draft.aanvrager2Inkomen.inkomenUitArbeid[index].inkomenMeetellenTot,
        bag.has.aanvrager2Inkomen.inkomenUitArbeid[index].brutoSalarisPerJaar.changed,
        bag.draft.aanvrager2Inkomen.inkomenUitArbeid[index].totaalBruto,
        bag.draft.aanvrager2Inkomen.inkomenUitArbeid[index].scenario
      );
    }
  });
});

export const determineInkomenEnFiscusSideEffects = initISWSideEffect(inkomenEnFiscusSideEffects)();

export const mapBrutoSalarisPeriode = createMapEnum(BrutoSalarisPeriode).to<InkomenPeriode>({
  Geen: InkomenPeriode.Geen,
  PerWeek: InkomenPeriode.PerWeek,
  Per4Weken: InkomenPeriode.Per4Weken,
  PerMaand: InkomenPeriode.PerMaand,
  PerJaar: InkomenPeriode.PerJaar
});

const mapInkomenLoondienstInput = createMapToDl(inkomenUitArbeidModalSchema).to<InkomenLoondienstInput>({
  gratificatieBedrag: v => v.vasteEindejaarsuitkering,
  inkomstentoeslag: v => v.inkomstentoeslag,
  maand13: v => v.dertiendeMaandToggle,
  onregelmatigheidstoeslagBedrag: v => v.onregelmatigheidstoeslag,
  overwerkBedrag: v => v.structureelOverwerk,
  provisieBedrag: v => v.provisie,
  vakantiegeldPercentage: v => v.vakantieToeslagPercentage,
  vebBedrag: v => v.vergoedingBeslaglegging,
  vastFlexibelBudgetBedrag: v => v.vastFlexibelBudget
});

export const mapInkomenLoondienstInputAanvrager1 = (values: InkomenEnFiscusState): InkomenLoondienstInput =>
  mapInkomenLoondienstInput(values.aanvrager1Inkomen.inkomenUitArbeid[0]);
export const mapInkomenLoondienstInputAanvrager2 = (values: InkomenEnFiscusState): InkomenLoondienstInput =>
  mapInkomenLoondienstInput(values.aanvrager2Inkomen.inkomenUitArbeid[0]);

export const mapInkomenUitArbeidOutput = (draft: InkomenUitArbeidModalType, json: InkomenOutput): void => {
  draft.brutoSalaris = json.resultaat?.brutoSalarisBedrag ?? null;
  draft.brutoSalarisPerJaar = json.resultaat?.brutoSalarisJaarBedrag ?? null;
  draft.dertiendeMaand = json.resultaat?.maand13Bedrag ?? null;
  draft.totaalBruto = json.resultaat?.totaalBrutoJaarsalarisBedrag ?? null;
  draft.vakantieToeslagBedrag = json.resultaat?.vakantietoeslagBedrag ?? null;
};

export const determineInkomenEnFiscusAsyncSideEffect = createISWAsyncSideEffect<
  InkomenEnFiscusState,
  "aanvrager1" | "aanvrager2"
>(async ({ draft, settings, fetchData, context }) => {
  const aanvragerInkomen = context === "aanvrager1" ? draft.aanvrager1Inkomen : draft.aanvrager2Inkomen;
  if (aanvragerInkomen.brutoSalarisUitDienstverband) {
    const salarisPer = mapBrutoSalarisPeriode(aanvragerInkomen.inkomenUitArbeid[0].brutoSalarisPer);
    const json = await fetchData<InkomenOutput, InkomenLoondienstInput>({
      url: `${settings.klantdossiersFormsOrigin}/InkomenFiscus/${InkomenUitArbeidApiControllerAction.JaarNaarPeriode}/${aanvragerInkomen.brutoSalarisUitDienstverband}/${salarisPer}`,
      body: context === "aanvrager1" ? mapInkomenLoondienstInputAanvrager1 : mapInkomenLoondienstInputAanvrager2
    });
    mapInkomenUitArbeidOutput(aanvragerInkomen.inkomenUitArbeid[0], json);
  } else {
    mapInkomenUitArbeidOutput(aanvragerInkomen.inkomenUitArbeid[0], {} as InkomenOutput);
    aanvragerInkomen.inkomenUitArbeid[0].totaalBruto = aanvragerInkomen.brutoSalarisUitDienstverband;
  }
});

export const determineInkomenEnFiscusAsyncCondition = initISWAsyncSideEffect<InkomenEnFiscusState>(
  ({ has, curr, runAsync }) => {
    if (
      has.aanvrager1Inkomen.brutoSalarisUitDienstverband.changed &&
      !has.aanvrager1Inkomen.inkomenUitArbeid.changed &&
      curr.aanvrager1Inkomen.brutoSalarisUitDienstverbandIsEnabled &&
      curr.aanvrager1Inkomen.inkomenUitArbeid.length === 1
    ) {
      runAsync(determineInkomenEnFiscusAsyncSideEffect("aanvrager1"));
    }

    if (
      has.aanvrager2Inkomen.brutoSalarisUitDienstverband.changed &&
      !has.aanvrager2Inkomen.inkomenUitArbeid.changed &&
      curr.aanvrager2Inkomen.brutoSalarisUitDienstverbandIsEnabled &&
      curr.aanvrager2Inkomen.inkomenUitArbeid.length === 1
    ) {
      runAsync(determineInkomenEnFiscusAsyncSideEffect("aanvrager2"));
    }
  }
)();
