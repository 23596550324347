import { useFormikContext } from "formik";
import React, { ReactElement } from "react";
import {
  AovEigenRisicoOptions,
  AovUitkeringsduurOptions,
  AoBeoordelingscriterium,
  AovVerzekerdeDekkingenOptions
} from "../../.generated/forms/formstypes";
import { Algemeen } from "../../.generated/tarieven/tarieventypes";
import { DekkingKenmerken } from "../../producten-overzicht/infra/product-kenmerken-types";
import { LabelValuePairs } from "../../shared/types";
import { AovsType, PartijProduct } from "../infra/aov-schema";
import { mapPartijProduct } from "../infra/map-aov-dl-to-ui";
import { DekkingArbeidsogeschiktheid } from "./dekking-arbeidsongeschiktheid";
import { DekkingCallasInkomensgarantie } from "./dekking-callas-inkomensgarantie";
import { DekkingCreditLife } from "./dekking-credit-life";
import { DekkingTafMlbz } from "./dekking-taf-mlbz";
import { DekkingDazure } from "./dekking-dazure";
import { DekkingDazureInkomensbeschermer } from "./dekking-dazure-inkomensbeschermer";
import { DekkingHypotheekAflosVerzekering } from "./dekking-hypotheek-aflos-verzekering";
import { DekkingWgaGat } from "./dekking-wga-gat";
import { DekkingWiaExcedent } from "./dekking-wia-excedent";
import { DekkingWoonlasten } from "./dekking-woonlasten";

export type DekkingProps = {
  selected: number;
  kenmerken: DekkingKenmerken;
  verzekerde: number;
  tarieven?: Algemeen;
};

export const claimBeoordelingOptions = (): LabelValuePairs => [
  { label: "Passende arbeid", value: AoBeoordelingscriterium.PassendeArbeid },
  { label: "Gangbare arbeid", value: AoBeoordelingscriterium.GangbareArbeid },
  { label: "Beroepsarbeid", value: AoBeoordelingscriterium.Beroeps }
];

export const eigenRisicoOptions = (kenmerken: DekkingKenmerken): LabelValuePairs => {
  const result: LabelValuePairs = [];
  kenmerken.heeftEigenRisicoAo30Dagen && result.push({ label: "30 dagen", value: AovEigenRisicoOptions.Dagen30 });
  kenmerken.heeftEigenRisicoAo90Dagen && result.push({ label: "90 dagen", value: AovEigenRisicoOptions.Dagen90 });
  kenmerken.heeftEigenRisicoAo180Dagen && result.push({ label: "180 dagen", value: AovEigenRisicoOptions.Dagen180 });
  kenmerken.heeftEigenRisicoAo365Dagen && result.push({ label: "365 dagen", value: AovEigenRisicoOptions.Dagen365 });
  kenmerken.heeftEigenRisicoAo730Dagen && result.push({ label: "730 dagen", value: AovEigenRisicoOptions.Dagen730 });
  return result;
};

export const uitkeringsduurOptions = (kenmerken: DekkingKenmerken): LabelValuePairs => {
  const result: LabelValuePairs = [];
  kenmerken.heeftAoUitkeringsduur24Maanden && result.push({ label: "2 jaar", value: AovUitkeringsduurOptions.Jaren2 });
  kenmerken.heeftAoUitkeringsduur60Maanden && result.push({ label: "5 jaar", value: AovUitkeringsduurOptions.Jaren5 });
  kenmerken.heeftAoUitkeringsduur120Maanden &&
    result.push({ label: "10 jaar", value: AovUitkeringsduurOptions.Jaren10 });
  kenmerken.heeftAoUitkeringsduurTotEindeLooptijd &&
    result.push({
      label: "Einde looptijd",
      value: AovUitkeringsduurOptions.EindeLooptijd
    });
  return result;
};

export const dekkingOptions = (
  kenmerken: DekkingKenmerken,
  wwMogelijk: boolean,
  partijProduct: PartijProduct
): LabelValuePairs => {
  const partijOnafhankelijk =
    partijProduct === PartijProduct.OnafhankelijkAo ||
    partijProduct === PartijProduct.OnafhankelijkWgaGat ||
    partijProduct === PartijProduct.OnafhankelijkWiaExcedent ||
    partijProduct === PartijProduct.OnafhankelijkWoonlasten ||
    partijProduct === PartijProduct.OnafhankelijkHav ||
    partijProduct === PartijProduct.OnafhankelijkIgv;

  const result: LabelValuePairs = [];
  kenmerken.heeftVerzekerdeDekkingAo && result.push({ label: "AO", value: AovVerzekerdeDekkingenOptions.Ao });
  kenmerken.heeftVerzekerdeDekkingAo &&
    wwMogelijk &&
    kenmerken.heeftVerzekerdeDekkingWw &&
    result.push({ label: "AO/WW", value: AovVerzekerdeDekkingenOptions.AoWw });
  kenmerken.heeftVerzekerdeDekkingAo &&
    kenmerken.heeftVerzekerdeDekkingEa &&
    result.push({ label: "AO/EA", value: AovVerzekerdeDekkingenOptions.AoEa });
  kenmerken.heeftVerzekerdeDekkingAo &&
    kenmerken.heeftVerzekerdeDekkingWw &&
    kenmerken.heeftVerzekerdeDekkingEa &&
    wwMogelijk &&
    result.push({ label: "AO/WW/EA", value: AovVerzekerdeDekkingenOptions.AoWwEa });
  kenmerken.heeftVerzekerdeDekkingEa && result.push({ label: "EA", value: AovVerzekerdeDekkingenOptions.Ea });
  kenmerken.heeftVerzekerdeDekkingWw &&
    wwMogelijk &&
    partijProduct !== PartijProduct.OnafhankelijkHav &&
    partijProduct !== PartijProduct.CallasWoonlasten &&
    partijOnafhankelijk &&
    result.push({ label: "WW", value: AovVerzekerdeDekkingenOptions.Ww });
  return result;
};

export const Dekking = ({ selected, kenmerken, verzekerde, tarieven }: DekkingProps): ReactElement => {
  const formik = useFormikContext<AovsType>();
  const partijCode = formik.values.producten[selected].partijCode;
  const productCode = formik.values.producten[selected].productCode;
  const partijProduct: PartijProduct = mapPartijProduct(partijCode, productCode);
  switch (partijProduct) {
    case PartijProduct.OnafhankelijkHav:
    case PartijProduct.CardifHypotheekAflosVerzekering:
    case PartijProduct.TafBnpHypotheekbeschermer:
    case PartijProduct.CreditLifeArbeidsongeschiktheid:
    case PartijProduct.DazureLastenbeschermer:
      return <DekkingHypotheekAflosVerzekering selected={selected} kenmerken={kenmerken} verzekerde={verzekerde} />;
    case PartijProduct.OnafhankelijkWoonlasten:
    case PartijProduct.CallasWoonlasten:
    case PartijProduct.CreditLifeWoonlastenOngevallen:
    case PartijProduct.CallasInkomensGarantie:
    case PartijProduct.CreditLifeInkomensgarantieOngevallen:
      return <DekkingWoonlasten selected={selected} kenmerken={kenmerken} verzekerde={verzekerde} />;
    case PartijProduct.OnafhankelijkWgaGat:
      return <DekkingWgaGat selected={selected} kenmerken={kenmerken} verzekerde={verzekerde} tarieven={tarieven} />;
    case PartijProduct.OnafhankelijkWiaExcedent:
      return <DekkingWiaExcedent selected={selected} kenmerken={kenmerken} verzekerde={verzekerde} />;
    case PartijProduct.OnafhankelijkAo:
      return <DekkingArbeidsogeschiktheid selected={selected} kenmerken={kenmerken} verzekerde={verzekerde} />;
    case PartijProduct.OnafhankelijkIgv:
    case PartijProduct.TafInkomensBeschermer:
    case PartijProduct.TafInkomensbeschermerIPTIQ:
      return <DekkingCallasInkomensgarantie selected={selected} kenmerken={kenmerken} verzekerde={verzekerde} />;
    case PartijProduct.DazureWoonlasten:
      return <DekkingDazure selected={selected} kenmerken={kenmerken} verzekerde={verzekerde} />;
    case PartijProduct.DazureInkomensbeschermer:
      return <DekkingDazureInkomensbeschermer selected={selected} kenmerken={kenmerken} verzekerde={verzekerde} />;
    case PartijProduct.TafCreditLife:
    case PartijProduct.TafQuantumLeben:
    case PartijProduct.TafMaandlastenbeschermerIPTIQ:
      return <DekkingCreditLife selected={selected} kenmerken={kenmerken} verzekerde={verzekerde} />;
    case PartijProduct.TafMaandlastenbeschermerZelfstandige:
      return <DekkingTafMlbz selected={selected} kenmerken={kenmerken} verzekerde={verzekerde} />;
    default:
      return <>Geen dekkingskaart beschikbaar voor deze partij, voor dit product</>;
  }
};
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production") Dekking.displayName = "Dekking";
