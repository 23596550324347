import { PageLoading, SettingsContext, useRequestInit, ForceRerenderContext } from "adviesbox-shared";
import React, { ReactElement, useContext, useEffect, useMemo } from "react";
import useAbortableFetch from "use-abortable-fetch";
import { assertNever, prefixWithZero } from "../../shared/utils/helpers";
import { partijOnafhankelijk } from "../infra/product-constanten";
import { EenVanDeKenmerken, ProductSoort as ProductKenmerkenType } from "../infra/product-kenmerken-types";
import { mapProductSelectieToProduct } from "../infra/product-selectie/map-product-selectie";
import { getProductParameters, mapProductSelectie } from "../infra/product-selectie/product-selectie-helper";
import {
  isAOVBasisOutput,
  isHypotheekvormenBasisOutput,
  isKredietvormBasisOutput,
  isLijfrenteBasisOutput,
  isOrvBasisOutput,
  isProductenBasisOutput,
  isVermogensvormenOutput
} from "../infra/producten-helper";
import {
  AlleMogelijkeProductBasisTypes,
  AlleMogelijkeProductOutputTypes,
  ProductSelectieStateType,
  ProductSelectieType,
  ProductSoort,
  SharedProductType,
  SituatieSoort
} from "../infra/producten-overzicht-types";
import { getKenmerken } from "./product-selectie-api";
import ProductSelectieModal from "./product-selectie-modal";
import { getProductSettings } from "./product-selectie-settings";

export type ProductSelectieAjaxProps = {
  situatie: SituatieSoort;
  productSoort: ProductSoort;
  legePartijnaam?: boolean;
  metProductKenmerken?: ProductKenmerkenType;
  productFilter?: (product: AlleMogelijkeProductBasisTypes) => boolean;
  productFactory: (productSelectie: ProductSelectieType, kenmerken?: EenVanDeKenmerken | null) => SharedProductType;
  onSaveProduct?: (product: SharedProductType) => void;
  closeModal?: () => void;
};

const zoekProductenInOutput = (
  data: AlleMogelijkeProductOutputTypes
): { [key: string]: AlleMogelijkeProductBasisTypes } | null => {
  if (isProductenBasisOutput(data)) {
    return data.producten;
  } else if (isOrvBasisOutput(data)) {
    return data.orvProducten;
  } else if (isKredietvormBasisOutput(data)) {
    return data.kredietvormen;
  } else if (isHypotheekvormenBasisOutput(data)) {
    return data.hypotheekvormen;
  } else if (isVermogensvormenOutput(data)) {
    return data.producten;
  } else if (isLijfrenteBasisOutput(data)) {
    return data.lijfrenteProducten;
  } else if (isAOVBasisOutput(data)) {
    return data.aovProducten;
  } else {
    assertNever(data);
  }
};

const defaultSelectedProduct = (soort: string): boolean => {
  switch (soort) {
    case "Spaarrekening":
      return true;
    default:
      return false;
  }
}

const ProductSelectieAjax = ({
  situatie,
  productSoort,
  legePartijnaam,
  productFilter,
  productFactory,
  onSaveProduct,
  closeModal,
  metProductKenmerken
}: ProductSelectieAjaxProps): ReactElement | null => {
  const { path, modalTitle, maatschappijTitel, productTitel } = getProductSettings(productSoort, situatie);
  const { productenOrigin, OcpApimSubscriptionKey } = useContext(SettingsContext);
  const { settings, user } = useRequestInit();
  const loadingDone = useContext(ForceRerenderContext);

  const requestInit = useMemo(
    (): RequestInit => ({
      headers: {
        "Ocp-Apim-Subscription-Key": `${OcpApimSubscriptionKey}`
      }
    }),
    [OcpApimSubscriptionKey]
  );

  const url = `${productenOrigin}/${path}`;
  const { error, data, loading } = useAbortableFetch<AlleMogelijkeProductOutputTypes>(url, requestInit);

  useEffect((): void => {
    if (!loading && !!data && loadingDone) {
      loadingDone();
    }
  }, [loading, data, loadingDone]);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (loading || !data) {
    return <PageLoading />;
  }

  if (typeof data === "string") {
    return <div>Error: Fout bij het laden van producten data</div>;
  }

  const producten = zoekProductenInOutput(data);
  const verzekeringSelecties = mapProductSelectie(producten, productFilter);
  if (!verzekeringSelecties) return <PageLoading />;
  const products = verzekeringSelecties[0]?.producten ?? [];
  const selectedProduct = products?.find((value) => defaultSelectedProduct(value.label)) ?? products[0];  

  return (
    <ProductSelectieModal
      labels={verzekeringSelecties}
      data={{
        // Selecteer het eerste product nadat de producten gesorteerd zijn op naam
        productCode: prefixWithZero(selectedProduct?.value) || "",
        productVorm: selectedProduct?.productvorm,
        maatschappijCode:
          situatie === "huidig"
            ? partijOnafhankelijk
            : verzekeringSelecties[0]?.value ?? partijOnafhankelijk
      }}
      onSave={async (gekozenProduct: ProductSelectieStateType): Promise<void> => {
        const prodSelectie = getProductParameters(
          verzekeringSelecties,
          gekozenProduct.maatschappijCode,
          gekozenProduct.productCode
        );
        let kenmerken = undefined;
        if (metProductKenmerken) {
          kenmerken = await getKenmerken(
            settings,
            user,
            metProductKenmerken,
            gekozenProduct.maatschappijCode,
            gekozenProduct.productCode
          );
        }
        const product = mapProductSelectieToProduct(prodSelectie, productFactory, legePartijnaam, kenmerken);

        // onSaveProduct wordt overschreven vanuit read-only-data-grid (met cloneElement)
        if (onSaveProduct) {
          onSaveProduct(product);
        }
      }}
      situatie={situatie}
      modalTitle={modalTitle}
      maatschappijTitel={maatschappijTitel}
      closeModal={closeModal}
      productTitel={productTitel}
    />
  );
};
export default ProductSelectieAjax;
