import { LabeledPercentageInput, LabeledRadioInput } from "adviesbox-shared";
import { useFormikContext } from "formik";
import React, { ReactElement } from "react";
import { DoorlopendLeningdeelMeenemenOptions } from "../../.generated/forms/formstypes";
import { getHypotheekIngPricetoolTextResources } from "../infra/hypotheek-resources";
import { HypothekenState } from "../infra/hypotheek-types";

type RenteOpbouwProps = {
  productIndex: number;
};

const RenteOpbouw = ({ productIndex }: RenteOpbouwProps): ReactElement => {
  const formik = useFormikContext<HypothekenState>();
  const values = formik.values.hypotheekOptiesIngPriceTool;
  const { producten, nhg } = formik.values;

  return (
    <>
      <div className={"form-row"}>
        <div className={`col-6`}>
          <h3 className="p-0 my-1">Leningdeel</h3>
        </div>
        <div className={`col-6`}>
          <h3 className="p-0 my-1">{producten[productIndex].hypotheekVorm.omschrijving}</h3>
        </div>
      </div>

      {producten[productIndex].product.doorlopend && (
        <LabeledRadioInput
          caption="Meenemen"
          name={`producten[${productIndex}].hypotheekOptiesIngPriceToolLeningdeel.meenemen`}
          options={[
            {
              label: "Rentemiddeling",
              value: DoorlopendLeningdeelMeenemenOptions.Rentemiddeling
            },
            {
              label: "1-op-1",
              value: DoorlopendLeningdeelMeenemenOptions.EenOpEen
            }
          ]}
          tooltip={getHypotheekIngPricetoolTextResources("tooltipTekstMeenemen")}
        />
      )}

      <LabeledPercentageInput
        caption="Standaard rente"
        name={`producten[${productIndex}].hypotheekOptiesIngPriceToolLeningdeel.standaardRenteData.percentage`}
        readonly={true}
        allowNegative={true}
        decimalen={2}
      />

      {!nhg && (
        <LabeledPercentageInput
          caption="LTV"
          name={`producten[${productIndex}].hypotheekOptiesIngPriceToolLeningdeel.ltvData.percentage`}
          readonly={true}
          allowNegative={true}
          decimalen={2}
        />
      )}

      {true /* TODO: Check toevoegen */ && (
        <LabeledPercentageInput
          caption="Verduurzaming"
          name={`producten[${productIndex}].hypotheekOptiesIngPriceToolLeningdeel.loyaliteitsbonusData.percentage`}
          readonly={true}
          allowNegative={true}
          decimalen={2}
        />
      )}

      {values?.dagrente && (
        <LabeledPercentageInput
          caption="Dagrente"
          name={`producten[${productIndex}].hypotheekOptiesIngPriceToolLeningdeel.dagrenteData.percentage`}
          readonly={true}
          allowNegative={true}
          decimalen={2}
        />
      )}
      {values?.actieveBetaalRekening && (
        <LabeledPercentageInput
          caption="Betaalrekening"
          name={`producten[${productIndex}].hypotheekOptiesIngPriceToolLeningdeel.actieveBetaalrekeningData.percentage`}
          readonly={true}
          allowNegative={true}
          decimalen={2}
        />
      )}
      {values?.loyaliteitsbonus && (
        <LabeledPercentageInput
          caption="Loyaliteit"
          name={`producten[${productIndex}].hypotheekOptiesIngPriceToolLeningdeel.loyaliteitsbonusData.percentage`}
          readonly={true}
          allowNegative={true}
          decimalen={2}
        />
      )}

      <LabeledPercentageInput
        caption="Te betalen rente"
        name={`producten[${productIndex}].hypotheekOptiesIngPriceToolLeningdeel.teBetalenRenteData.percentage`}
        readonly={true}
        allowNegative={true}
        decimalen={2}
        fontWeight="bold"
      />
      {producten[productIndex]?.hypotheekOptiesIngPriceToolLeningdeel?.optimalisatieMelding && (
        <div className="p-2 text-muted font-italic">
          {producten[productIndex]?.hypotheekOptiesIngPriceToolLeningdeel?.optimalisatieMelding}
        </div>
      )}
    </>
  );
};

export default RenteOpbouw;
