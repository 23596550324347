import { createGetTextResources } from "../../shared/utils/text-resources-algemeen";

export const getMaximaleHypotheekTextResources = createGetTextResources({
  leningdelenUitHetVerledenLeningdelenMeenemen: "true",
  errorToetsrente: "Vul een percentage in.",

  tooltipUitgangspuntenMarktwaardeTrue:
    "Voor dit type onderpand geldt een aangepast acceptatiebeleid. Ga de voorwaarden van de geldverstrekker na. Onderstaande toetsing is gebaseerd op een gangbaar onderpand (eengezinswoning, flat of appartement).",
  tooltipUitgangspuntenMarktwaardeNull:
    "Op het scherm 'Analyse - Woning' is het veld 'Aard pand' nog niet ingevuld. De berekening van de maximale hypotheek baseren we daarom op het meest voorkomende onderpand, namelijk eengezinswoning, flat of appartement.",
  tooltipToetsrenteKredietToetslast:
    "Deze krediet toetslast wordt standaard gevuld met de volgende maandelijkse toetslast:\n- Doorlopend krediet = kredietlimiet * 2% p.m.\n- Aflopend krediet = kredietsom * 2% p.m. of de werkelijke maandlast (als deze lager is)\n- Studielening = werkelijke maandlast * TRHK-bruteringsfactor\n- Werkgeverslening = kredietsom * 2% p.m. of de werkelijke maandlast (als deze lager is)\n- Private lease = 65% van de totale leasekosten * 2% p.m.\n- Restschuldlening krediet (met NHG) = Netto kredietmaandlast\n- Restschuldlening krediet (zonder NHG) = Kredietsom * 2% p.m.",
  tooltipInkomenToetsinkomen:
    "De toetsinkomen en pensioenleeftijd worden automatisch berekend op basis van een aantal gegevens in de schermen bij 'Inventarisatie'. Door op het potloodje te klikken, kun je deze gegevens handmatig wijzigen en gelden dan als basis voor de berekening van de maximale hypotheek.",
  validatieUitgangspuntenLooptijd: "Vul een looptijd tussen de 5 en 50 jaar in.",
  validatieUitgangspuntenLooptijdRentevastperiode:
    "Vul een looptijd in dat minimaal gelijk is aan de rentevastperiode.",
  validatieToetsrenteGewensteRentevastperiode:
    "Vul een rentevastperiode in dat maximaal de looptijd van de hypotheek is.",
  tooltipLeningdelenMeenemenNee:
    "Selecteer 'Ja' als je een of meerdere leningdelen uit het verleden wilt meenemen. Vervolgens voeg je in het specificatiescherm de leningdelen inclusief de kenmerken toe. Deze leningdelen worden meegenomen in de berekening van de maximale hypotheek.",
  tooltipLeningdelenMeenemenJa:
    "Selecteer 'Ja' als je een of meerdere leningdelen uit het verleden wilt meenemen. Vervolgens voeg je in het specificatiescherm de leningdelen inclusief de kenmerken toe. Hier voeg je ook een restschuldlening toe. Deze leningdelen worden meegenomen in de berekening van de maximale hypotheek.",
  gridInitialText: "Maximale hypotheek berekenen",
  gridFetchFout: "Helaas is er iets misgegaan tijdens het ophalen van de gegevens. Probeer het later nog een keer.",
  errorKvkNummer: `Vul een geldig KvK nummer in.`
});
