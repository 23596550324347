/* istanbul ignore file */
import { Draft, produceWithPatches } from "immer";
import isEqual from "react-fast-compare";
import reactFastCompare from "react-fast-compare";
import { SideEffects } from "use-formik-side-effects";

export type DraftSideEffectHandler<T, X = undefined> = Exclude<X, undefined> extends never
  ? (isChanged: DraftSideEffectHasChanged<T>, curr: Draft<T>, prev: Readonly<T>) => void
  : (isChanged: DraftSideEffectHasChanged<T>, curr: Draft<T>, prev: Readonly<T>, context: X) => void;

export type DraftSideEffects<T> = (curr: Draft<T>, prev: Readonly<T>) => void;
export type DraftSideEffectsWithContext<T, X> = (curr: Draft<T>, prev: Readonly<T>, extra: X) => void;

type DraftSideEffectPropChanged = { changed: boolean };
type DraftSideEffectHasChangedObj<T> = { [P in keyof T]: DraftSideEffectHasChanged<T[P]> };

export type DraftSideEffectHasChanged<T> = T extends object
  ? DraftSideEffectHasChangedObj<T> & Omit<DraftSideEffectPropChanged, keyof T>
  : DraftSideEffectPropChanged;

const pathPartToString = (index: string, i: number): string =>
  Number.isInteger(Number(index)) ? `[${index}]` : i === 0 ? `${index}` : `.${index}`;

const pathInObjForCompare = (obj: { [index: string]: any }, pathArr: string[], debug: string): any => {
  let i = 0;
  if (typeof obj !== "object") return `pathInObj: ${typeof obj} is not an object`;
  for (; i < pathArr.length - 1; i++) {
    obj = obj[pathArr[i]];
    if (obj === null || typeof obj !== "object") {
      return `pathInObj: missing property for path "${pathArr.map(pathPartToString).join("")}"`;
    }
  }

  return obj[pathArr[i]];
};

const compare = <T>(curr: Readonly<T>, prev: Readonly<T>, pathArr: string[]): boolean =>
  pathArr.length === 0
    ? !isEqual(curr, prev)
    : !isEqual(pathInObjForCompare(curr, pathArr, "curr"), pathInObjForCompare(prev, pathArr, "prev"));

function createDraftSideEffectHasChangedProxy<T>(
  pathArr: string[],
  curr: Readonly<T>,
  prev: Readonly<T>
): DraftSideEffectHasChanged<T> {
  return new Proxy(
    {},
    {
      get(target: { [index: string]: any }, name: string): DraftSideEffectHasChanged<T> | boolean {
        if (name === "changed" && typeof target["changed"] === "undefined") {
          return compare(curr, prev, pathArr);
        }
        return createDraftSideEffectHasChangedProxy([...pathArr, name], curr, prev);
      }
    }
  ) as DraftSideEffectHasChanged<T>;
}

export function createDraftSideEffect<T>(handler: DraftSideEffectHandler<T>): DraftSideEffects<T>;
export function createDraftSideEffect<T, X>(handler: DraftSideEffectHandler<T, X>): DraftSideEffectsWithContext<T, X>;
export function createDraftSideEffect<T, X>(
  handler: DraftSideEffectHandler<T> | DraftSideEffectHandler<T, X>
): DraftSideEffectsWithContext<T, X> {
  const f = (curr: Draft<T>, prev: Readonly<T>, context: X): void =>
    handler(createDraftSideEffectHasChangedProxy([], curr as Readonly<T>, prev), curr, prev, context);
  return f as any;
}

export function initDraftSideEffect<T>(sideEffects: DraftSideEffects<T>): SideEffects<T>;
export function initDraftSideEffect<T, X>(sideEffects: DraftSideEffectsWithContext<T, X>, context: X): SideEffects<T>;
export function initDraftSideEffect<T, X>(sideEffects: DraftSideEffectsWithContext<T, X>, context?: X): SideEffects<T> {
  return initDraftSideEffectWithOptions({ runWhenUnchanged: false }, sideEffects, context);
}

type initDraftSideEffectOptions = { runWhenUnchanged: boolean };
export function initDraftSideEffectWithOptions<T, X>(
  options: initDraftSideEffectOptions,
  sideEffects: DraftSideEffectsWithContext<T, X>,
  context?: X
): SideEffects<T> {
  return (curr: T, prev: T): T | null => {
    if (!options.runWhenUnchanged && curr === prev) return null;

    const [next, changes] = produceWithPatches(curr, draft => sideEffects(draft, prev, context as any));

    const hasChanged = changes.length > 0 && !reactFastCompare(curr, next);

    if (hasChanged) {
      /* istanbul ignore next */
      /* eslint-disable-next-line no-console */
      process.env.NODE_ENV === "development" && changes.length > 0 && console.log("sideEffects", changes);

      return next;
    }

    return null;
  };
}
