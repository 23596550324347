const mod97 = (input: string): number => {
  let checksum = Number(input.slice(0, 2));
  let fragment;

  for (let offset = 2; offset < input.length; offset += 7) {
    fragment = String(checksum) + input.substring(offset, offset + 7);
    checksum = parseInt(fragment, 10) % 97;
  }
  return checksum;
};

export type IBAN_COUNTRY_CODES =
  | "AD"
  | "AE"
  | "AT"
  | "AZ"
  | "BA"
  | "BE"
  | "BG"
  | "BH"
  | "BR"
  | "CH"
  | "CR"
  | "CY"
  | "CZ"
  | "DE"
  | "DK"
  | "DO"
  | "EE"
  | "ES"
  | "FI"
  | "FO"
  | "FR"
  | "GB"
  | "GI"
  | "GL"
  | "GR"
  | "GT"
  | "HR"
  | "HU"
  | "IE"
  | "IL"
  | "IS"
  | "IT"
  | "JO"
  | "KW"
  | "KZ"
  | "LB"
  | "LI"
  | "LT"
  | "LU"
  | "LV"
  | "MC"
  | "MD"
  | "ME"
  | "MK"
  | "MR"
  | "MT"
  | "MU"
  | "NL"
  | "NO"
  | "PK"
  | "PL"
  | "PS"
  | "PT"
  | "QA"
  | "RO"
  | "RS"
  | "SA"
  | "SE"
  | "SI"
  | "SK"
  | "SM"
  | "TN"
  | "TR";
export const IBAN_CODE_LENGTHS: { [key in IBAN_COUNTRY_CODES]: number } = {
  AD: 24,
  AE: 23,
  AT: 20,
  AZ: 28,
  BA: 20,
  BE: 16,
  BG: 22,
  BH: 22,
  BR: 29,
  CH: 21,
  CR: 21,
  CY: 28,
  CZ: 24,
  DE: 22,
  DK: 18,
  DO: 28,
  EE: 20,
  ES: 24,
  FI: 18,
  FO: 18,
  FR: 27,
  GB: 22,
  GI: 23,
  GL: 18,
  GR: 27,
  GT: 28,
  HR: 21,
  HU: 28,
  IE: 22,
  IL: 23,
  IS: 26,
  IT: 27,
  JO: 30,
  KW: 30,
  KZ: 20,
  LB: 28,
  LI: 21,
  LT: 20,
  LU: 20,
  LV: 21,
  MC: 27,
  MD: 24,
  ME: 22,
  MK: 19,
  MR: 27,
  MT: 31,
  MU: 30,
  NL: 18,
  NO: 15,
  PK: 24,
  PL: 28,
  PS: 29,
  PT: 25,
  QA: 29,
  RO: 24,
  RS: 22,
  SA: 24,
  SE: 24,
  SI: 19,
  SK: 24,
  SM: 27,
  TN: 24,
  TR: 26
};

const validateIBAN = (input: string | null | undefined): boolean => {
  if (!input) return false;
  const iban = String(input)
    .toUpperCase()
    .replace(/[^A-Z0-9]/g, ""); // keep only alphanumeric characters
  const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/); // match and capture (1) the country code, (2) the check digits, and (3) the rest
  // check syntax and length
  if (!code || iban.length !== IBAN_CODE_LENGTHS[code[1] as IBAN_COUNTRY_CODES]) {
    return false;
  }
  // rearrange country code and check digits, and convert chars to ints
  const digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, (letter): string => {
    return String(letter.charCodeAt(0) - 55);
  });

  // final check
  return mod97(digits) === 1;
};

export default validateIBAN;
