import React, { ReactElement } from "react";

import classnames from "classnames";
import AflossingProductKeuzelijst, { AflossingProductKeuzelijstProps } from "./AflossingProductKeuzelijst";
import { LabelProps, Label } from "adviesbox-shared";

type LabeledAflossingKeuzelijstProps = LabelProps & AflossingProductKeuzelijstProps;

const LabeledAflossingProductKeuzelijst = ({
  situatie,
  maatschappijCode,
  labelCode,
  caption,
  name,
  tooltip,
  readonly,
  visible,
  fieldSize,
  labelColSize = 7,
  isRestschuldHypotheek,
  isAankoop,
  asSelectElement
}: LabeledAflossingKeuzelijstProps): ReactElement => {
  const inputColSize = labelColSize < 12 ? 12 - labelColSize : 12;

  return (
    <div
      className={classnames("form-group form-row", {
        invisible: visible === false
      })}
    >
      <div className={`col-${labelColSize}`}>
        <Label caption={caption} name={name} tooltip={tooltip} />
      </div>
      <div className={`col-${inputColSize}`}>
        <AflossingProductKeuzelijst
          situatie={situatie}
          isAankoop={isAankoop}
          maatschappijCode={maatschappijCode}
          labelCode={labelCode}
          name={name}
          readonly={readonly}
          fieldSize={fieldSize}
          isRestschuldHypotheek={isRestschuldHypotheek}
          asSelectElement={asSelectElement}
        />
      </div>
    </div>
  );
};

export default LabeledAflossingProductKeuzelijst;
