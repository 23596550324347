import React, { ReactElement, ReactNode, useState } from "react";
import { SubmitResultTypes } from "../utils/save-validation";
import { SaveFormContext, SaveFormData } from "./save-form-context";

const setSaveFormProviderValue = (
    isSaving: boolean,
    saveResult: SubmitResultTypes | null,
    version: number,
    saveData: () => void,
    setIsSaving: (_v: boolean) => void,
    setSaveResult: (_v: SubmitResultTypes | null) => void
): SaveFormData => ({
    isSaving,
    saveResult,
    version,
    saveData,
    setIsSaving,
    setSaveResult
});

export const SaveFormProvider = ({ children }: { children: ReactNode }): ReactElement => {
    const [isSaving, setIsSaving] = useState(false);
    const [saveResult, setSaveResult] = useState<SubmitResultTypes | null>(null);
    const [version, setVersion] = useState(0)

    const internalSaveData = (): void => {
        setVersion(oldvalue => oldvalue + 1);
    }

    const val = setSaveFormProviderValue(
        isSaving,
        saveResult,
        version,
        () => internalSaveData(),
        setIsSaving,
        setSaveResult
    );
    return <SaveFormContext.Provider value={val} >{children}</SaveFormContext.Provider>;
}