import { dekkingOptions, DekkingProps, eigenRisicoOptions } from "./dekking";
import React, { ReactElement } from "react";
import { AovVerzekerdeDekkingenOptions } from "../../.generated/forms/formstypes";
import { useFormikContext } from "formik";
import { mapPartijProduct } from "../infra/map-aov-dl-to-ui";
import { AovsType } from "../infra/aov-schema";
import {
  LabeledCurrencyInput,
  LabeledBevestigingInput,
  BerekenCurrencyInput,
  LabeledJaarMaandInput,
  LabeledRadioInput
} from "adviesbox-shared";

export const DekkingCallasInkomensgarantie = ({ selected, kenmerken, verzekerde }: DekkingProps): ReactElement => {
  const { values } = useFormikContext<AovsType>();
  const partijProductCode = mapPartijProduct(
    values.producten[selected].partijCode,
    values.producten[selected].productCode
  );
  const dekkingGegevens = values.producten[selected].dekking.dekkingGegevensVerzekerde1;
  return (
    <>
      {kenmerken.verzekerdeDekkingTonen && (
        <LabeledRadioInput
          caption={"Dekking"}
          name={`producten[${selected}].dekking.dekkingGegevensVerzekerde${verzekerde}.dekking`}
          options={dekkingOptions(kenmerken, true, partijProductCode)}
        />
      )}

      {kenmerken.maandinkomenTonen && (
        <BerekenCurrencyInput
          caption="Maandinkomen"
          name={`producten[${selected}].dekking.maandinkomen`}
          readonly={!kenmerken.maandinkomenEnabled}
        />
      )}
      {kenmerken.gewenstGegarandeerdMaandinkomenTonen && (
        <LabeledCurrencyInput
          caption="Gewenst gegarandeerd maandinkomen"
          name={`producten[${selected}].dekking.maandinkomenGegarandeerd`}
        />
      )}
      {kenmerken.eigenRisicoAoTonen && (
        <LabeledRadioInput
          caption="Eigen risico"
          name={`producten[${selected}].dekking.dekkingGegevensVerzekerde${verzekerde}.ao.eigenRisico`}
          options={eigenRisicoOptions(kenmerken)}
        />
      )}
      {kenmerken.indexatieAoTonen && (
        <LabeledBevestigingInput
          caption={"Indexatie tijdens uitkering"}
          name={`producten[${selected}].dekking.indexatieTijdensUitkering`}
        />
      )}
      {kenmerken.heeftVerzekerdeDekkingWw &&
        (dekkingGegevens.dekking === AovVerzekerdeDekkingenOptions.Ww ||
          dekkingGegevens.dekking === AovVerzekerdeDekkingenOptions.AoWw ||
          dekkingGegevens.dekking === AovVerzekerdeDekkingenOptions.AoWwEa) && (
          <div>
            <h2 className="ml-3 mt-2">Werkloosheid (WW)</h2>
            {(
              <LabeledCurrencyInput
                caption="Verzekerd maandbedrag"
                name={`producten[${selected}].dekking.dekkingGegevensVerzekerde${verzekerde}.ww.verzekerdBedrag`}
                readonly={!kenmerken.verzekerdBedragWwEnabled}
              />
            )}
            {kenmerken.looptijdWwTonen && (
              <LabeledJaarMaandInput
                caption="Looptijd"
                name={`producten[${selected}].dekking.dekkingGegevensVerzekerde${verzekerde}.ww.looptijd`}
                readonly={!kenmerken.looptijdWwEnabled}
              />
            )}
            {kenmerken.wwUitkeringdduurTonen &&
              (kenmerken.heeftWwUitkeringdduur12Maanden || kenmerken.HeeftWwUitkeringdduur24Maanden ? (
                <LabeledRadioInput
                  caption="Uitkeringsduur"
                  name={`producten[${selected}].dekking.dekkingGegevensVerzekerde${verzekerde}.ww.uitkeringsDuurInMaanden`}
                  options={[
                    { label: "12 maanden", value: "12" },
                    { label: "24 maanden", value: "24" }
                  ]}
                />
              ) : (
                <LabeledJaarMaandInput
                  caption="Uitkeringsduur"
                  name={`producten[${selected}].dekking.dekkingGegevensVerzekerde${verzekerde}.ww.uitkeringsDuur`}
                  readonly={!kenmerken.uitkeringsduurWwEnabled}
                />
              ))}
          </div>
        )}
    </>
  );
};
