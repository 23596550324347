import React, { ReactElement, ReactNode, useMemo } from "react";
import { SelectInput } from "adviesbox-shared";
import { LabelValuePairs, Direction, MaatschappijLijstType } from "../../types";
import { RadioInputGroup } from "adviesbox-shared";

const { compare } = Intl.Collator();

type MaatschappijPresentationProps = {
  maatschappijen: { [index: string]: MaatschappijLijstType };
  name: string;
  appendChildren?: ReactNode;
  readonly?: boolean;
  fieldSize?: "xl" | "l" | "m" | "s";
  emptyValue?: string;
};

const MaatschappijKeuzelijstPresentation = ({
  maatschappijen: maatschappijenLijst,
  name,
  appendChildren,
  readonly,
  fieldSize,
  emptyValue
}: MaatschappijPresentationProps): ReactElement => {
  const options = useMemo(
    (): LabelValuePairs =>
      maatschappijenLijst
        ? Object.keys(maatschappijenLijst)
            .reduce(
              (arr, key): LabelValuePairs =>
                arr.concat({
                  label: maatschappijenLijst[key].maatschappijNaamLang,
                  value: maatschappijenLijst[key].maatschappijCode
                }),
              [] as LabelValuePairs
            )
            .sort((x, y): number => compare(x.label, y.label))
        : [],
    [maatschappijenLijst]
  );

  if (options.length === 2 || options.length === 3) {
    return <RadioInputGroup options={options} name={name} layout={Direction.Vertical} />;
  }
  return (
    <SelectInput
      name={name}
      options={options}
      readonly={readonly}
      appendChildren={appendChildren}
      fieldSize={fieldSize}
      emptyValue={emptyValue}
    />
  );
};

MaatschappijKeuzelijstPresentation.displayName = "MaatschappijKeuzelijstPresentation";

export default MaatschappijKeuzelijstPresentation;
