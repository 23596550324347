import { ErrorPage, PageLoading, SettingsType, useRequestInit, withErrorBoundary } from "adviesbox-shared";
import React, { ReactElement } from "react";
import useAbortableFetch from "use-abortable-fetch";
import { LeningOutput } from "../.generated/forms/formstypes";
import { InvalidAdviesboxResultErrorPage } from "../shared/components/fetch-error-page/fetch-error-page";
import { useAdviesboxData } from "../shared/hooks/use-adviesbox-data";
import { useInstellingenBeheerPartijenData } from "../shared/hooks/use-instellingen-beheer-partijen-data";
import { mapDlTargetToTaxatieUiField, mapTaxatieDlToUi } from "./infra/map-taxatie-scherm-dl-to-ui";
import { mapTaxatieUiToDl } from "./infra/map-taxatie-scherm-ui-to-dl";
import TaxatieKenmerkenAjax from "./taxatie-kenmerken-ajax";
import Taxatie from "./taxatie";
import { partijOnafhankelijk } from "../producten-overzicht/infra/product-constanten";

type RouteParams = {
  vestiging: string;
  adviesdossier: string;
};

const createUrl = (s: SettingsType, p: RouteParams): string =>
  `${s.klantdossiersFormsOrigin}/Adviesdossiers/${p.adviesdossier}/Taxatie`;

const TaxatieAjax = (): ReactElement => {
  const { params, settings, requestInit } = useRequestInit();
  const { loading, error, data, platformData, saveData } = useAdviesboxData(
    createUrl,
    p => p.adviesdossier,
    mapTaxatieDlToUi,
    mapTaxatieUiToDl,
    mapDlTargetToTaxatieUiField
  );

  const {
    data: instellingData,
    loading: instellingLoading,
    error: instellingError
  } = useInstellingenBeheerPartijenData("Taxatiebureaus");

  const leningUrl =
    params && params.adviesdossier
      ? `${settings.klantdossiersFormsOrigin}/Adviesdossiers/${params.adviesdossier}/Lening`
      : null;
  const { error: leningError, data: leningData, loading: leningLoading } = useAbortableFetch<LeningOutput>(
    leningUrl,
    requestInit
  );

  if (loading || instellingLoading || leningLoading) {
    return <PageLoading />;
  }

  if (error) {
    return <ErrorPage error={error} data={platformData} />;
  }

  if (instellingError) {
    return <ErrorPage error={instellingError} data={platformData} />;
  }

  if (leningError) {
    return <ErrorPage error={leningError} data={leningData} />;
  }

  if (
    !data ||
    !instellingData ||
    typeof leningData === "string" ||
    !leningData ||
    !leningData.leningen ||
    !instellingData?.taxatiebureaus?.partijen?.length
  ) {
    return <InvalidAdviesboxResultErrorPage />;
  }

  const leningen = leningData.leningen;
  const gewensteVoorstelId = Object.keys(leningen).map(key => {
    const gewenstevoorstelId = leningen[key].algemeen?.gewensteHypotheekVoorstelId;
    const eersteVoorstelId = leningen[key].voorstellen?.find(v => v)?.voorstelId ?? null;
    return gewenstevoorstelId ?? eersteVoorstelId;
  })[0];

  const filteredPartijen = instellingData.taxatiebureaus.partijen.filter(partij => (partij.code !== partijOnafhankelijk && partij.code !== "TVI"));

  if (data.taxatiebureau?.maatschappijCode)
    return (
      <TaxatieKenmerkenAjax
        saveData={saveData}
        {...data}
        taxatiebureauOpties={filteredPartijen}
        gewenstehypotheekVoorstelId={gewensteVoorstelId}
      />
    );
  else
    return (
      <Taxatie
        saveData={saveData}
        {...data}
        taxatiebureauOpties={filteredPartijen}
        gewenstehypotheekVoorstelId={gewensteVoorstelId}
      />
    );
};

TaxatieAjax.displayName = "TaxatieAjax";

export default withErrorBoundary(TaxatieAjax);
