import {
  initISWAsyncSideEffect,
  createISWAsyncSideEffect
} from "../../shared/components/isw-side-effects/create-isw-helpers";

import {
  RenteverliesTijdensBouwInput,
  RenteverliesTijdensBouwOutput,
  RenteverliesTijdensBouwMaandInput
} from "../../.generated/hypotheek/hypotheektypes";
import { createMapToDl } from "../../shared/utils/create-map-to-dl";
import { renteverliesTijdensBouwModalSchema } from "../infra/financieringsbehoefte-schema";
import { RenteverliesTijdensBouwModal } from "../infra/financieringsbehoefte-types";

export const mapRenteverliesTijdensBouwInput = createMapToDl(renteverliesTijdensBouwModalSchema).to<
  RenteverliesTijdensBouwInput
>({
  duur: v => v.duurVanDeBouw || 0,
  bouwdepotbedrag: v => v.bouwdepotbedrag || 0,
  hypotheekbedrag: v => v.hypotheekbedrag || 0,
  hypotheekrente: v => v.hypotheekrente || 0,
  rentevergoeding: v => v.depotvergoeding || 0,
  uitbetalingen: v =>
    v.renteverliesMaandelijks.map(
      (e): RenteverliesTijdensBouwMaandInput => ({
        maand: e.maand || 0,
        percentageUitbetaling: e.percentage || 0
      })
    )
});

export const renteverliesTijdensBouwAsyncBerekening = createISWAsyncSideEffect<RenteverliesTijdensBouwModal>(
  async ({ draft, settings, fetchData }) => {
    const result = await fetchData<RenteverliesTijdensBouwOutput, RenteverliesTijdensBouwInput>({
      url: `${settings.hypotheekOrigin}/RenteverliesTijdensBouw`,
      body: mapRenteverliesTijdensBouwInput
    });

    if (result.isValid) {
      draft.renteverlies = result.resultaat?.totaalRenteverlies || 0;
    }
  }
);

export const determineRenteverliesTijdensBouwAsyncSideEffects = initISWAsyncSideEffect<RenteverliesTijdensBouwModal>(
  ({ curr, runAsync, has }) => {
    if (
      typeof curr.hypotheekbedrag === "number" &&
      typeof curr.hypotheekrente === "number" &&
      typeof curr.depotvergoeding === "number" &&
      typeof curr.duurVanDeBouw === "number" &&
      (has.hypotheekbedrag.changed ||
        has.hypotheekrente.changed ||
        has.depotvergoeding.changed ||
        has.duurVanDeBouw.changed)
    ) {
      runAsync(renteverliesTijdensBouwAsyncBerekening());
    }
  }
)();
