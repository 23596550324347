import { TaxatieDoelOptions, TaxatieNwwiGeldverstrekkersOutput } from "../../../.generated/forms/formstypes";
import {
  createISWAsyncSideEffect,
  initISWAsyncSideEffect
} from "../../../shared/components/isw-side-effects/create-isw-helpers";
import { hasValue } from "../../../shared/utils/helpers";
import { mapGeldverstrekkerDl2Ui } from "../../infra/map-taxatie-scherm-dl-to-ui";
import { GeldverstrekkerNwwiModalType } from "../../infra/taxatie-scherm-schema";

type AsyncContext = {
  postcode: string | null;
  doelTaxatie: TaxatieDoelOptions | null;
  agentnummer: string | null;
};

export const asyncGeldverstrekkerSideEffects = createISWAsyncSideEffect<GeldverstrekkerNwwiModalType, AsyncContext>(
  async ({ draft, settings, params, context, fetchData }) => {
    const url = `${settings.klantdossiersFormsOrigin}/Taxatie/NWWI/Geldverstrekkers?agentnummer=${context.agentnummer}&taxatiedoel=${context.doelTaxatie}&postcode=${context.postcode}`;

    const res = await fetchData<TaxatieNwwiGeldverstrekkersOutput>({
      url,
      method: "GET",
      extraHeaders: { vestigingId: params.vestiging }
    });

    if (res.isValid && res.geldverstrekkers?.length) {
      draft.resultaat = res.geldverstrekkers.map(g => mapGeldverstrekkerDl2Ui(g));
      if (!draft.selectedResultaat) draft.selectedResultaat = draft.resultaat[0];
    }

    if (res.isValid && res.geldverstrekkers?.length === 0) {
      draft.resultaatIsLeeg = true;
    }
  }
);

export const determineGeldverstrekkerAsyncSideEffects = initISWAsyncSideEffect<
  GeldverstrekkerNwwiModalType,
  AsyncContext
>(({ runAsync, context, curr }) => {
  if (!curr.resultaat?.length && hasValue(context.doelTaxatie) && context.postcode && hasValue(context.agentnummer))
    runAsync(asyncGeldverstrekkerSideEffects(context));
});
