import { Icon, LabeledRadioInput, ModalButton } from "adviesbox-shared";
import { LabelValuePairs } from "adviesbox-shared/utils/types";
import { useFormikContext } from "formik";
import React, { ReactElement, useCallback } from "react";
import { DekkingKenmerken } from "../../producten-overzicht/infra/product-kenmerken-types";
import { AoUitkeringswijze } from "../../producten-overzicht/infra/specificatie-ao-uitkering-schema";
import SpecificatieAoUitkeringModal from "../../producten-overzicht/specificatie-ao-uitkering-modal/specificatie-ao-uitkering-modal";
import { bindSaveFunction } from "../../shared/utils/bind-save-function";
import { AovsType } from "../infra/aov-schema";
import { getDekkingGegevensVerzekerDefaults } from "../infra/defaults";
import { mapPartijProduct } from "../infra/map-aov-dl-to-ui";
import { DekkingProps } from "./dekking";

export type UitkeringGedeeltelijkeAoProps = DekkingProps & {
  subSchema?: "ao" | "rubriekA";
};

export const UitkeringGedeeltelijkeAo = ({
  selected,
  verzekerde,
  kenmerken,
  subSchema = "ao"
}: UitkeringGedeeltelijkeAoProps): ReactElement => {
  const { values, setFieldValue } = useFormikContext<AovsType>();
  const dekkingGegevensVerzekerdeDefault = getDekkingGegevensVerzekerDefaults(
    mapPartijProduct(values.producten[selected].partijCode, values.producten[selected].productCode)
  );

  const dekkingGegevens =
    verzekerde === 1
      ? subSchema === "ao"
        ? values.producten[selected].dekking.dekkingGegevensVerzekerde1.ao
        : values.producten[selected].dekking.dekkingGegevensVerzekerde1.rubriekA
      : subSchema === "ao"
      ? values.producten[selected].dekking.dekkingGegevensVerzekerde2?.ao ?? dekkingGegevensVerzekerdeDefault.ao
      : values.producten[selected].dekking.dekkingGegevensVerzekerde2?.rubriekA ??
        dekkingGegevensVerzekerdeDefault.rubriekA;

  const createSaveFunction = useCallback(bindSaveFunction(setFieldValue), [setFieldValue]);

  const gedeeltelijkeAoOptions = (kenmerken: DekkingKenmerken): LabelValuePairs => {
    const result: LabelValuePairs = [];
    kenmerken.heeftUitkeringGedeeltelijkAoVolledig &&
      result.push({ label: "Volledige uitkering", value: AoUitkeringswijze.Volledig });
    kenmerken.heeftUitkeringGedeeltelijkAoNaarRato &&
      result.push({ label: "Uitkering naar rato", value: AoUitkeringswijze.ProRata });
    return result;
  };
  return (
    <>
      {dekkingGegevens && kenmerken.uitkeringGedeeltelijkAoTonen && (
        <LabeledRadioInput
          caption={"Uitkering gedeeltelijke AO"}
          name={`producten[${selected}].dekking.dekkingGegevensVerzekerde${verzekerde}.${subSchema}.uitkeringGedeeltelijkAo`}
          tooltip={kenmerken.hintUitkeringGedeeltelijkAoTonen ? kenmerken.hintUitkeringGedeeltelijkAo : undefined}
          options={gedeeltelijkeAoOptions(kenmerken)}
          appendChildren={
            kenmerken.heeftSpecificatieAoUitkering ? (
              <div className="d-inline-block float-right mt-01 mr-23">
                <ModalButton
                  parent={`producten[${selected}].dekking.dekkingGegevensVerzekerde${verzekerde}.${subSchema}.uitkeringGedeeltelijkAo`}
                  content={<Icon name="specificatie" alt="Invaliditeitspensioen" />}
                >
                  <SpecificatieAoUitkeringModal
                    data={dekkingGegevens.uitkeringGedeeltelijkeAoModal}
                    aoUitkeringswijze={
                      dekkingGegevens?.uitkeringGedeeltelijkAo === AoUitkeringswijze.Volledig
                        ? AoUitkeringswijze.Volledig
                        : AoUitkeringswijze.ProRata
                    }
                    selectedAovProduct={values.producten[selected]}
                    onSave={createSaveFunction(
                      `producten[${selected}].dekking.dekkingGegevensVerzekerde${verzekerde}.${subSchema}.uitkeringGedeeltelijkeAoModal`
                    )}
                  />
                </ModalButton>
              </div>
            ) : (
              undefined
            )
          }
        />
      )}
    </>
  );
};
