import * as Yup from "yup";
import { WithSaveData } from "../../shared/utils/save-data";
import { klantnaamSchema } from "../../shared/generic-parts/klantnaam/schema";
import { yupEnum, yupNullableEnum } from "../../shared/utils/yup-enum";
import { KlantprofielVraagOptions, RisicoprofielOptions } from "../../.generated/forms/formstypes";
import { ReactElement } from "react";
import { hasValue, toArray } from "../../shared/utils/helpers";
import { getKlantprofielResources } from "./klantprofiel-resources";

export enum QuestionType {
  // Generic questions
  radio = "radio",
  checkbox = "checkbox",
  slider = "slider",
  jaar = "jaar",
  maand = "maand",
  jaarMaanden = "jaarMaanden",
  bedrag = "bedrag",
  toelichting = "toelichting",
  instructionText = "instructionText",
  // Custom questions
  radioMetJaarMaanden = "radioMetJaarMaanden",
  radioMetJaar = "radioMetJaar",
  radioMetText = "radioMetText",
  toggleCheckbox = "toggleCheckbox"
}
export enum TitleType {
  none = "none",
  platform = "platform",
  aanvrager = "aanvrager",
  aanvrager1 = "aanvrager1",
  aanvrager2 = "aanvrager2",
  custom = "custom"
}

export enum CheckboxType {
  default = "default",
  bedrag = "bedrag",
  text = "text",
  textEnbedrag = "textEnbedrag"
}
export type AanvragersNaam = {
  aanvragerNaam1: string;
  aanvragerNaam2: string;
};

export type QuestionCondition = {
  question: KlantprofielVraagOptions;
  answer: number;
};

export type CheckboxAnswersCondition = {
  type?: CheckboxType;
  index?: number;
};

export type CardSpec = {
  title?: string;
  subtitle?: string;
  questions?: QuestionSpec[];
  sections?: SectionSpec[];
  toelichting?: boolean;
  verplichtToelichting?: boolean;
};

export type SectionSpec = {
  title: TitleType.none | TitleType.custom | TitleType.aanvrager1 | TitleType.aanvrager2;
  customTitle?: string;
  subtitle?: string;
  questions: QuestionSpec[];
  toelichting?: boolean;
};

export type QuestionSpec = {
  question: KlantprofielVraagOptions | "unbound";
  type: QuestionType;
  title?: TitleType;
  customTitle?: string;
  extra?: () => ReactElement;
  condition?: QuestionCondition | QuestionCondition[];
  showVerplichtToelichting?: QuestionCondition | QuestionCondition[];
  checkboxAnswers?: CheckboxAnswersCondition[];
};

export type QuestionSpecCombined = QuestionSpec & {
  schema: KlantprofielVragenType & AanvragersNaam;
  index: number;
  specsIndex: number;
  displayQuestion: () => boolean;
  displayToelichtingVerplicht: () => boolean;
  isAanvrager1Question: () => boolean;
  isAanvrager2Question: () => boolean;
};

export const klantprofielAntwoordenSchema = Yup.object({
  code: Yup.string(),
  vraagCode: yupNullableEnum(KlantprofielVraagOptions).default(null),
  omschrijving: Yup.string()
    .nullable()
    .default(null),
  waarde1: Yup.mixed()
    .nullable()
    .default(null),
  waarde2: Yup.mixed()
    .nullable()
    .default(null)
    .test({
      test: function(value: any) {
        const context = this.options.context as KlantProfielSchemaContextType;
        const specs: QuestionSpec[] = context?.specs ? context.specs : [];
        const notValid =
          hasValue(value) &&
          specs &&
          !!specs.find(s => s.question === this.parent.vraagCode && s.type === QuestionType.jaarMaanden) &&
          (value < 0 || value >= 12);
        if (notValid)
          return this.createError({
            message: getKlantprofielResources("MaandenValue")
          });
        return true;
      }
    }),
  waarde3: Yup.mixed()
    .nullable()
    .default(null)
    .test({
      test: function(value: any) {
        const context = this.options.context as KlantProfielSchemaContextType;
        const specs: QuestionSpec[] = context?.specs ? context.specs : [];
        const notValid =
          hasValue(value) &&
          specs &&
          !!specs.find(s => s.question === this.parent.vraagCode && s.type === QuestionType.radioMetJaarMaanden) &&
          (value < 0 || value >= 12);
        if (notValid)
          return this.createError({
            message: getKlantprofielResources("MaandenValue")
          });
        return true;
      }
    })
});
export type KlantprofielAntwoordenType = Yup.InferType<typeof klantprofielAntwoordenSchema>;

export const klantprofielVragenSchema = Yup.object({
  code: yupEnum(KlantprofielVraagOptions),
  omschrijving: Yup.string()
    .nullable()
    .default(null),
  antwoorden: Yup.array(klantprofielAntwoordenSchema).default([]),
  gekozenAntwoord: Yup.string()
    .nullable()
    .default(null),
  toelichting: Yup.string()
    .nullable()
    .default(null)
    .test({
      message: getKlantprofielResources("ToelichtingVerplicht"),
      test: function(value: any) {
        const context = this.options.context as KlantProfielSchemaContextType;
        const specs: QuestionSpec[] = context?.specs ? context.specs : [];

        return (
          (hasValue(value) &&
            specs.some(s =>
              toArray(s.showVerplichtToelichting).some(
                v => v?.answer.toString() === this.parent.gekozenAntwoord && v?.question === this.parent.code
              )
            )) ||
          specs.every(s =>
            toArray(s.showVerplichtToelichting).every(v => v?.answer.toString() !== this.parent.gekozenAntwoord)
          )
        );
      }
    })
});
export type KlantprofielVragenType = Yup.InferType<typeof klantprofielVragenSchema>;

export const klantprofielOptieSchema = Yup.object({
  vragen: Yup.array(klantprofielVragenSchema),
  aanvrager1: klantnaamSchema,
  aanvrager2: klantnaamSchema.nullable().default(null),
  risicoprofielStatus: yupNullableEnum(RisicoprofielOptions).default(null)
});

export type KlantprofielOptieType = Yup.InferType<typeof klantprofielOptieSchema>;
export type KlantprofielOptieProps = Yup.InferType<typeof klantprofielOptieSchema> &
  WithSaveData<KlantprofielOptieType> & { specs: CardSpec[]; schermTitle: string };
export type KlantProfielSchemaContextType = {
  specs: QuestionSpec[];
};
