import React, { ReactElement } from "react";
import { connect, FormikContextType } from "formik";

import { getAanvragerOpties } from "../../producten-overzicht/infra/producten-helper";
import { LabeledRadioInput } from "adviesbox-shared";
import { hasValue } from "../../shared/utils/helpers";
import { HypothekenState } from "../infra/hypotheek-types";

type SchuldenaarsProps = {
  selected: number;
};

const Schuldenaars = ({
  selected,
  formik: {
    values: { aanvrager1, aanvrager2 }
  }
}: SchuldenaarsProps & {
  formik: FormikContextType<HypothekenState>;
}): ReactElement => {
  const schuldenaarsOpties = getAanvragerOpties(aanvrager1, aanvrager2, hasValue(aanvrager1) && hasValue(aanvrager2));

  return (
    <>
      <LabeledRadioInput
        caption={schuldenaarsOpties.length > 1 ? "Schuldenaar(s)" : "Schuldenaar"}
        name={`producten[${selected}].schuldenaars.schuldenaar`}
        options={schuldenaarsOpties}
      />
    </>
  );
};

export default connect<SchuldenaarsProps, HypothekenState>(Schuldenaars);
