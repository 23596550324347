/* istanbul ignore file */

import { Beroep } from "../../.generated/aov/aovtypes";
import useAbortableFetch from "use-abortable-fetch";
import { useRequestInit } from "adviesbox-shared";

export type BeroepenReturnType = {
  loading: boolean;
  error: Error | null;
  data: Beroep[] | null;
};

export function useBeroepData(productcode: number | null): BeroepenReturnType {
  const {
    requestInit,
    settings: { aovOrigin }
  } = useRequestInit();
  const url = `${aovOrigin}/Beroepen?product=${productcode}`;
  const { error, data, loading } = useAbortableFetch<Beroep[]>(url, requestInit);

  if (typeof data === "string") {
    return {
      error: new Error("Fout bij het laden van beroepen data"),
      data: null,
      loading: false
    };
  }

  return { loading, error, data: data || null };
}
