import React, { ReactElement } from "react";
import { connect, FormikContextType, getIn } from "formik";
import {
  LabeledBevestigingInput,
  LabeledCurrencyInput,
  LabeledDateInput,
  LabeledNumberInput,
  LabeledPercentageInput,
  LabeledRadioInput,
  LabeledSelectInput
} from "adviesbox-shared";

import {
  BasisdekkingOptions,
  OrvUitkeringssoortOptions,
  SoortKapitaalverzekeringProductOptions
} from "../../.generated/forms/formstypes";

import { LabelValuePairs } from "../../shared/types";
import { KapitaalverzekeringenType, OverlijdensrisicodekkingType } from "../infra/kapitaalverzekering-types";

const showBasisdekking = [BasisdekkingOptions.Geen, BasisdekkingOptions.PercentageOpgebouwdeWaarde, "110%", "90%"];

const basisdekkingLevensverzekeringOptions: LabelValuePairs = [
  { label: "Geen", value: BasisdekkingOptions.Geen },
  {
    label: "% Opgebouwde waarde",
    value: BasisdekkingOptions.PercentageOpgebouwdeWaarde
  },
  {
    label: "Som betaalde premies",
    value: BasisdekkingOptions.SomBetaaldePremies
  }
];

const basisdekkingUniversalLifePremieOptions: LabelValuePairs = [
  { label: "Geen", value: BasisdekkingOptions.Geen },
  {
    label: "110% opgebouwde waarde",
    value: "110%"
  },
  {
    label: "90% opgebouwde waarde",
    value: "90%"
  },
  {
    label: "Som betaalde premies",
    value: BasisdekkingOptions.SomBetaaldePremies
  }
];

const soortRisicodekkingOptions: LabelValuePairs = [
  {
    label: "Lineair dalend",
    value: OrvUitkeringssoortOptions.LineairDalend
  },
  {
    label: "Annuïtair dalend",
    value: OrvUitkeringssoortOptions.AnnuïtairDalend
  },
  {
    label: "Gelijkblijvend",
    value: OrvUitkeringssoortOptions.Gelijkblijvend
  }
];

type OverlijdensrisicodekkingProps = {
  selected: number;
  propName: string;
};

const Overlijdensrisicodekking = ({
  selected,
  propName,
  formik: { values }
}: OverlijdensrisicodekkingProps & {
  formik: FormikContextType<KapitaalverzekeringenType>;
}): ReactElement => {
  const { soortProduct } = values.producten[selected];
  const overlijdensrisicodekking = getIn(values, `producten[${selected}].${propName}`) as OverlijdensrisicodekkingType;

  return (
    <>
      {soortProduct === SoortKapitaalverzekeringProductOptions.Levensverzekering && (
        <LabeledRadioInput
          caption="Basisdekking"
          name={`producten[${selected}].${propName}.basisdekking`}
          options={basisdekkingLevensverzekeringOptions}
        />
      )}

      {soortProduct === SoortKapitaalverzekeringProductOptions.UniversalLifePremie && (
        <LabeledSelectInput
          caption="Basisdekking"
          name={`producten[${selected}].${propName}.basisdekking`}
          options={basisdekkingUniversalLifePremieOptions}
        />
      )}

      {showBasisdekking.includes(overlijdensrisicodekking.basisdekking) && (
        <LabeledCurrencyInput
          caption="Verzekerd kapitaal"
          name={`producten[${selected}].${propName}.verzekerdKapitaalBedrag`}
        />
      )}

      {showBasisdekking.includes(overlijdensrisicodekking.basisdekking) && (
        <LabeledRadioInput
          caption="Soort risicodekking"
          name={`producten[${selected}].${propName}.soortRisicodekking`}
          options={soortRisicodekkingOptions}
        />
      )}

      {overlijdensrisicodekking.basisdekking === BasisdekkingOptions.PercentageOpgebouwdeWaarde && (
        <LabeledPercentageInput
          caption="Percentage waarde"
          name={`producten[${selected}].${propName}.waardePercentage`}
          decimalen={2}
          allowAllValues={true}
        />
      )}

      {overlijdensrisicodekking.soortRisicodekking === OrvUitkeringssoortOptions.AnnuïtairDalend && (
        <LabeledPercentageInput
          caption="Annuïteitspercentage"
          name={`producten[${selected}].${propName}.annuiteitsPercentage`}
          decimalen={2}
        />
      )}

      {overlijdensrisicodekking.basisdekking === BasisdekkingOptions.Geen &&
        overlijdensrisicodekking.soortRisicodekking !== OrvUitkeringssoortOptions.Gelijkblijvend && (
          <LabeledCurrencyInput
            caption="Dekking daalt tot"
            name={`producten[${selected}].${propName}.dekkingDaaltTotBedrag`}
          />
        )}

      {overlijdensrisicodekking.basisdekking === BasisdekkingOptions.Geen &&
        overlijdensrisicodekking.soortRisicodekking !== OrvUitkeringssoortOptions.Gelijkblijvend && (
          <LabeledNumberInput
            caption="Duur daling in jaren"
            name={`producten[${selected}].${propName}.duurdalingInJaren`}
            appendChildren="Jaar"
          />
        )}

      {soortProduct === SoortKapitaalverzekeringProductOptions.Levensverzekering && !!values.aanvrager2 && (
        <>
          <LabeledCurrencyInput
            caption="Partnerrente per jaar"
            name={`producten[${selected}].${propName}.partnerrentePerJaarBedrag`}
          />

          {!!overlijdensrisicodekking.partnerrentePerJaarBedrag && (
            <LabeledBevestigingInput caption="Levenslang" name={`producten[${selected}].${propName}.levenslang`} />
          )}

          {!!overlijdensrisicodekking.partnerrentePerJaarBedrag && !overlijdensrisicodekking.levenslang && (
            <LabeledNumberInput
              caption="Maximale duur rente in jaren"
              name={`producten[${selected}].${propName}.maxDuurInJaren`}
              appendChildren="Jaar"
            />
          )}

          {!!overlijdensrisicodekking.partnerrentePerJaarBedrag && (
            <LabeledBevestigingInput
              caption="Direct ingaande rente"
              name={`producten[${selected}].${propName}.directIngaandeRente`}
            />
          )}

          {!!overlijdensrisicodekking.partnerrentePerJaarBedrag && !overlijdensrisicodekking.directIngaandeRente && (
            <LabeledDateInput
              caption="Uitsteldatum"
              name={`producten[${selected}].${propName}.uitsteldatum`}
            />
          )}
        </>
      )}
    </>
  );
};

export default connect<OverlijdensrisicodekkingProps, KapitaalverzekeringenType>(Overlijdensrisicodekking);
