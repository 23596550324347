import React, { ReactElement } from "react";
import { connect, FormikContextType } from "formik";

import { useHdnData } from "../../shared/hooks/use-hdn-data";
import { AanvraagLeningState } from "../infra/aanvraag-lening-schema";

const Dataleverancier = ({
  formik: {
    values: { dataleverancier }
  }
}: {
  formik: FormikContextType<AanvraagLeningState>;
}): ReactElement => {
  const { hdnKeuzelijst } = useHdnData("AX", "DataleverancierType");

  const leveranciers = dataleverancier.dataleveranciers.map((leverancier, index) => {
    return (
      <div key={`dataleveranciers-${index}`}>
        <span data-testid={`dataleverancier.dataleveranciers[${index}]`}>{hdnKeuzelijst[leverancier.hdnCode]}</span>
      </div>
    );
  });

  return <div className="px-1">{leveranciers}</div>;
};

export default connect<{}, AanvraagLeningState>(Dataleverancier);
