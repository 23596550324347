import {
  DataGrid,
  Label,
  LabeledBevestigingInput,
  LabeledPercentageInput,
  LabeledRadioInput,
  LabeledSelectInput
} from "adviesbox-shared";
import { FormikProps } from "formik";
import React, { ReactElement } from "react";
import { AovType } from "../../aov/infra/aov-schema";
import { GedeeltelijkeUitkeringAoOpties, RvcAfwijkingsoort } from "../../.generated/forms/formstypes";
import { ISWSideEffects } from "../../shared/components/isw-side-effects/isw-side-effects";
import Modal from "../../shared/components/modal/Modal";
import AdviesBoxFormik from "../../shared/utils/adviesbox-formik";
import { enum2options } from "../../shared/utils/enum-to-options";
import {
  aoIndexeringenSchema,
  AoIndexeringenType,
  AoUitkeringswijze,
  specificatieAoUitkeringModalSchema,
  SpecificatieAoUitkeringModalType
} from "../infra/specificatie-ao-uitkering-schema";
import { determineSpecificatieAoUitkeringModalSideEffects } from "./determine-specificatie-ao-uitkering-modal-side-effects";
import { indexeringenColumns, staffelColumns } from "./specificatie-ao-uitkering-kolommen";
import { getSpecificatieAoUitkeringTextResources } from "./specificatie-ao-uitkering-resources";

export type SpecificatieAoUitkeringModalData = {
  data: SpecificatieAoUitkeringModalType;
  aoUitkeringswijze: AoUitkeringswijze;
  selectedAovProduct?: AovType;
};

type SpecificatieAoUitkeringModalProps = {
  onSave?: (data: SpecificatieAoUitkeringModalType) => void;
  closeModal?: () => void;
};

const getStaffelError = (indexeringen: AoIndexeringenType[]): string => {
  if (!indexeringen.length) return "";

  for (let i = 0; i < indexeringen.length; i++) {
    /* istanbul ignore next */
    if (indexeringen[i] && indexeringen[i].error) {
      return getSpecificatieAoUitkeringTextResources("invaliditeitsModalStaffelError")
        .replace("i+1", `${i + 1}`)
        .replace("i+2", `${i + 2}`);
      // return `Regel ${i + 1} en regel ${i + 2} van de staffel sluiten niet op elkaar aan`;
    }
  }
  return "";
};

const SpecificatieAoUitkeringModal = ({
  data,
  aoUitkeringswijze,
  selectedAovProduct: selectedProduct,
  onSave,
  closeModal
}: SpecificatieAoUitkeringModalData & SpecificatieAoUitkeringModalProps): ReactElement => {
  return (
    <AdviesBoxFormik<SpecificatieAoUitkeringModalType>
      initialValues={{ ...data }}
      validationSchema={specificatieAoUitkeringModalSchema}
      onSave={onSave}
      closeModal={closeModal}
      render={({
        submitForm,
        values: { afwijkendToggle, afwijkendRestverdien, gedeeltelijkeUitkeringObv, indexeringen },
        touched: { indexeringen: touchedIndexeringen }
      }: FormikProps<SpecificatieAoUitkeringModalType>): ReactElement => {
        const staffelError = getStaffelError(indexeringen);

        return (
          <>
            <ISWSideEffects
              sync={determineSpecificatieAoUitkeringModalSideEffects({
                aoUitkeringswijze,
                touchedIndexeringen
              })}
            />

            <Modal
              title="Specificatie AO uitkering"
              onSubmitClick={submitForm}
              onCancelClick={closeModal}
              body={
                <>
                  <div className="pl-1">
                    <Label caption="Uitkeringsgrenzen" fontWeight="bold" />
                  </div>

                  {aoUitkeringswijze === AoUitkeringswijze.ProRata ? (
                    <LabeledPercentageInput
                      caption="Minimaal percentage arbeidsongeschiktheid"
                      name="proRataMinPercentage"
                      decimalen={2}
                      readonly={selectedProduct?.partijCode === "XX" && selectedProduct?.productCode === "02"}
                    />
                  ) : (
                    <LabeledPercentageInput
                      caption="Minimaal percentage arbeidsongeschiktheid"
                      name="volledigMinPercentage"
                      decimalen={2}
                      readonly={selectedProduct?.partijCode === "XX" && selectedProduct?.productCode === "02"}
                    />
                  )}
                  {aoUitkeringswijze === AoUitkeringswijze.ProRata ? (
                    <LabeledSelectInput
                      caption="Gedeeltelijke uitkering o.b.v."
                      name="gedeeltelijkeUitkeringObv"
                      options={[
                        { label: "Werkelijk percentage AO", value: GedeeltelijkeUitkeringAoOpties.WerkelijkAoPercentage },
                        { label: "Staffel", value: GedeeltelijkeUitkeringAoOpties.Staffel }
                      ]}
                    />
                  ) : (
                    <></>
                  )}
                  {aoUitkeringswijze === AoUitkeringswijze.ProRata ? (
                    <LabeledPercentageInput
                      caption="Volledige uitkering vanaf"
                      name="proRataTotPercentage"
                      decimalen={2}
                      readonly={selectedProduct?.partijCode === "XX" && selectedProduct?.productCode === "02"}
                    />
                  ) : (
                    <LabeledPercentageInput
                      caption="Volledige uitkering vanaf percentage arbeidsongeschiktheid"
                      name="volledigVanafPercentage"
                      decimalen={2}
                      readonly
                    />
                  )}

                  {gedeeltelijkeUitkeringObv === GedeeltelijkeUitkeringAoOpties.Staffel &&
                  aoUitkeringswijze === AoUitkeringswijze.ProRata ? (
                    <DataGrid
                      name="indexeringen"
                      columns={staffelColumns}
                      rowCaption="staffel"
                      getNewRowValues={(): AoIndexeringenType => ({
                        ...aoIndexeringenSchema.default()
                      })}
                      className="editable"
                    />
                  ) : (
                    <DataGrid editable name="indexeringen" columns={indexeringenColumns} />
                  )}
                  <LabeledBevestigingInput
                    caption="Afwijkend bij restverdiencapaciteit"
                    name="afwijkendToggle"
                    tooltip={getSpecificatieAoUitkeringTextResources("afwijkendToggle")}
                  />

                  {afwijkendToggle ? (
                    <>
                      <LabeledRadioInput
                        caption="Restverdiencapaciteit"
                        name="afwijkendRestverdien"
                        options={enum2options(RvcAfwijkingsoort)}
                      />

                      <LabeledPercentageInput
                        caption={`Percentage restverdiencapaciteit ${
                          afwijkendRestverdien === RvcAfwijkingsoort.Vanaf ? "vanaf" : "tot"
                        }`}
                        name="afwijkendRestverdienPercentage"
                        decimalen={2}
                      />

                      <LabeledPercentageInput
                        caption="Uitkeringspercentage verzekerd bedrag"
                        name="afwijkendUitkeringspercentage"
                        decimalen={2}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                  <div id="staffelFoutmelding" className="foutmelding">
                    {staffelError}
                  </div>
                </>
              }
            />
          </>
        );
      }}
    />
  );
};

SpecificatieAoUitkeringModal.displayName = "AoUitkeringswijzeModal";

export default SpecificatieAoUitkeringModal;
