import {
  DoorlopendLeningdeelMeenemenOptions,
  AflossingsVormType,
  IngPriceInput,
  Meeneemtype,
  RenteAfspraakType
} from "../../.generated/forms/formstypes";
import { createMapEnum } from "../../shared/utils/create-map-enum";
import { createMapToDl } from "../../shared/utils/create-map-to-dl";
import { hypothekenBaseSchema } from "../infra/hypotheek-schema";
import { LeningdeelgegevensType } from "../infra/hypotheek-types";
import { mapLocalDateToString } from "adviesbox-shared";

export const getAfloscodeHdn = (aflossingsvorm: AflossingsVormType, ingHdnData: [string, string][]): string | null => {
  let afloscodeHdn = null;
  if (aflossingsvorm === AflossingsVormType.Aflosvrij) {
    const res = ingHdnData.find(([_, v]) => v === "Aflossingsvrije Hypotheek") ?? [];
    afloscodeHdn = res[0] ?? "IN001";
  }
  if (aflossingsvorm === AflossingsVormType.Annuïteit) {
    const res = ingHdnData.find(([_, v]) => v === "Annuiteitenhypotheek") ?? [];
    afloscodeHdn = res[0] ?? "IN002";
  }
  if (aflossingsvorm === AflossingsVormType.Lineair) {
    const res = ingHdnData.find(([_, v]) => v === "Lineaire Hypotheek") ?? [];
    afloscodeHdn = res[0] ?? "IN003";
  }
  if (aflossingsvorm === AflossingsVormType.Spaarrekening) {
    const res = ingHdnData.find(([_, v]) => v === "Bankspaarhypotheek") ?? [];
    afloscodeHdn = res[0] ?? "IN016";
  }

  return afloscodeHdn;
};

export const getRenteVariant = (leningdeelgegevens: LeningdeelgegevensType): RenteAfspraakType => {
  if (leningdeelgegevens.renteVariant === "Variabel") return RenteAfspraakType.ContinuVariabel;
  return RenteAfspraakType.Rentevast;
};

export const mapIngPriceToolInput = createMapToDl(hypothekenBaseSchema)
  .with<{ ingHdnData: [string, string][] }>()
  .to<IngPriceInput>({
    actieveBetaalrekening: v => v.hypotheekOptiesIngPriceTool?.actieveBetaalRekening ?? false,
    dagrente: v => v.hypotheekOptiesIngPriceTool?.dagrente ?? false,
    hypotheekBedrag: v => v.producten.reduce((x, y) => x + (y.leningdeelgegevens?.leningdeelHoofdsom?.bedrag ?? 0), 0),
    loyaliteitsbonus: v => v.hypotheekOptiesIngPriceTool?.loyaliteitsbonus ?? false,
    marktwaarde: v => v.panden.reduce((x, y) => x + (y.marktwaardeRenteBedrag ?? 0), 0),
    nhg: v => v.nhg ?? false,
    overbruggingsBedrag: v => v.overbruggingBedrag,
    perLeningdelen: (v, { ingHdnData }) =>
      v.producten.map(prod => {
        const meenemen = prod.hypotheekOptiesIngPriceToolLeningdeel?.meenemen ?? null;
        const mapIngMeenemenOptie = createMapEnum(DoorlopendLeningdeelMeenemenOptions).to({
          Rentemiddeling: Meeneemtype.RenteBemiddeling,
          EenOpEen: Meeneemtype.EenOpEen
        });

        return {
          ingangsdatum: mapLocalDateToString(prod.product.ingangsdatum),
          volgnummer: prod.volgnummer,
          huidigeRente:
            !prod.product.doorlopend || prod.leningdeelgegevens.rentePercentage?.bedrag == null
              ? null
              : (prod.leningdeelgegevens.rentePercentage?.bedrag ?? 0) / 100,
          leningdeelBedrag: prod.leningdeelgegevens.leningdeelHoofdsom.bedrag ?? 0,
          loyaliteitsbedrag: prod.hypotheekOptiesIngPriceToolLeningdeel?.loyaliteitsbonusBedrag || null,
          meenemen: meenemen && prod.product.doorlopend ? mapIngMeenemenOptie(meenemen) : null,
          rentevastInMaanden: Number(prod.leningdeelgegevens.rentevastPeriodeJaar) * 12 || 0,
          afloscodeHdn: getAfloscodeHdn(prod.hypotheekVorm.aflossingsvorm, ingHdnData),
          renteAfspraak: getRenteVariant(prod.leningdeelgegevens)
        };
      })
  });
