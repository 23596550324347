import { Klantprofiel, KlantprofielVraag, KlantprofielAntwoord } from "../../.generated/forms/formstypes";
import { mapKlantnaamUi2Dl } from "../../shared/generic-parts/klantnaam/map-ui-2-dl";
import { createMapToDl } from "../../shared/utils/create-map-to-dl";
import {
  klantprofielOptieSchema,
  klantprofielVragenSchema,
  klantprofielAntwoordenSchema,
  QuestionType
} from "./klantprofiel-schema";
import {
  shouldQuestionBeDisplayed,
  getSpecForKlantprofielVraagOptions,
  combineQuestionSpecsFor,
  getQuestionType
} from "./question-helpers";
import { mergedSpecs } from "./merged-specs";
import { mapJaarMaandInputDl2Ui, jaarMaandInMaanden } from "adviesbox-shared";

export const mapKlantprofielAntwoorden = createMapToDl(klantprofielAntwoordenSchema).to<KlantprofielAntwoord>({
  code: v => Number(v.code),
  omschrijving: v => v.omschrijving,
  waarde1: v => v.waarde1,
  waarde2: v => v.waarde2,
  waarde3: v => v.waarde3
});

export const mapKlantprofielVraag = createMapToDl(klantprofielVragenSchema).to<KlantprofielVraag>({
  omschrijving: v => v.omschrijving,
  code: v => v.code,
  toelichting: v => v.toelichting,
  antwoorden: v =>
    v.antwoorden.map(a => {
      if (v.gekozenAntwoord) {
        if (a.code === v.gekozenAntwoord && getQuestionType(v.code) !== QuestionType.radioMetJaarMaanden) {
          return mapKlantprofielAntwoorden({
            ...a,
            waarde1: true
          });
        }
        if (a.code === v.gekozenAntwoord && getQuestionType(v.code) === QuestionType.radioMetJaarMaanden) {
          return mapKlantprofielAntwoorden({
            ...a,
            waarde1: true,
            waarde2: jaarMaandInMaanden(mapJaarMaandInputDl2Ui(a.waarde2, a.waarde3)),
            waarde3: null
          });
        }
        return mapKlantprofielAntwoorden({
          ...a,
          waarde1: null,
          waarde2: null,
          waarde3: null
        });
      }
      if (getQuestionType(v.code) === QuestionType.jaarMaanden) {
        return mapKlantprofielAntwoorden({
          ...a,
          waarde1: jaarMaandInMaanden(mapJaarMaandInputDl2Ui(a.waarde1, a.waarde2)),
          waarde2: null,
          waarde3: null
        });
      }
      return mapKlantprofielAntwoorden(a);
    })
});

export const mapKlantprofielUiToDl = createMapToDl(klantprofielOptieSchema).to<Klantprofiel>({
  aanvrager1: v => mapKlantnaamUi2Dl(v.aanvrager1),
  aanvrager2: v => mapKlantnaamUi2Dl(v.aanvrager2),
  vragen: v =>
    v.vragen
      .filter(vraag =>
        shouldQuestionBeDisplayed(
          getSpecForKlantprofielVraagOptions(vraag.code),
          combineQuestionSpecsFor(mergedSpecs),
          v
        )
      )
      .map(mapKlantprofielVraag),
  heeftKlantprofielAbc: v => false // TODO CONTROLEREN
});
