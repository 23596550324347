import { Adres, AdresReadOnly } from "../../../.generated/forms/formstypes";
import { AdresType } from "../../types";
import adresSchema from "./schema";

export function mapAdresDlNaarUi(valuesDl?: Adres | AdresReadOnly | null): AdresType {
  if (!valuesDl) valuesDl = {} as Adres | AdresReadOnly;

  let postcode = valuesDl.postcode || "";
  if (valuesDl.landCode === "NL") {
    postcode = postcode.toUpperCase().replace(/\s/g, "");
    const letters = postcode.slice(4, 6);
    const numbers = postcode.slice(0, 4);

    if (numbers.charAt(0) !== "") {
      postcode = `${numbers} ${letters}`;
    }
  }

  const result: AdresType = {
    straat: valuesDl.straat || "",
    huisnummer: typeof valuesDl.huisnummer === "number" ? valuesDl.huisnummer : null,
    toevoeging: valuesDl.toevoeging || "",
    locatie: valuesDl.locatie || "",
    plaats: valuesDl.plaats || "",
    landCode: valuesDl.landCode || "NL",
    postcode: postcode
  };

  return adresSchema.cast(result);
}
