import { createGetTextResources } from "../../shared/utils/text-resources-algemeen";

export const getOrvTextResources = createGetTextResources({
  ErrorOrvProductLooptijd: "Vul een einddatum overlijdensrisicoverzekering in die voor de aow-datum ligt.",
  ErrorOrvPremieGegevensLooptijd:
    "Vul een geldige duur in, zodat de einddatum van de premiebetaling binnen de looptijd van de verzekering valt. ",
  ErrorDuurInJaren: "Vul een getal in tussen de 1 en 30.",
  ErrorMinMaxVerzekerdKapitaalBedrag: "Vul het verzekerd kapitaal in.",
  ErrorMaxAnnuiteitsPercentage: "Vul een percentage tussen de 0 en 20% in.",
  DuurInJaren: "Vul een getal in tussen de 1 en 30.",
  GeenRechtenOntleend:
    "Aan dit overzicht kunnen geen rechten worden ontleend. Dit overzicht is onder voorbehoud van goedkeuring door de maatschappij.",
  VergelijkerStarten: "Gebruik de knop 'Vergelijker starten' om de ORV premies te berekenen.",
  GeenORV: "Er zijn nog geen ORV premies berekend.",
  ErrorDatumVerleden: "De datum mag niet in het verleden liggen",
  ErrorVerzekerdKapitaalBedrag: "Vul het verzekerd kapitaal in.",
  dekkingVerzekerdKapitaalIsLaag: "Verzekerd kapitaal bedrag invullen.",
  annuiteitsPercentageLeeg: "Annuiteitspercentage invullen.",
  IngangsdatumIsNietEersteDag: "De ingangsdatum moet op de eerste dag van de maand vallen."
});
