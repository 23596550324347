import {
  BerekenCurrencyButton,
  createSpanWithId,
  DataGrid,
  LabeledJaarMaandInput,
  LabeledCurrencyInput,
  LabeledDateInput,
  LabeledPercentageInput,
  LabeledRadioInput,
  LabeledTextInput
} from "adviesbox-shared";
import { FieldArray, FormikProps } from "formik";
import React, { ReactElement, useContext, useState } from "react";
import { Column } from "react-table-6";
import { AppDataContext } from "../../navigation/appdata-context";
import { BoeterenteBerekeningswijzeOpties } from "../../.generated/forms/formstypes";
import { ISWSideEffects } from "../../shared/components/isw-side-effects/isw-side-effects";
import Modal from "../../shared/components/modal/Modal";
import AdviesBoxFormik from "../../shared/utils/adviesbox-formik";
import { bedragFormat } from "../../shared/utils/currency";
import { boeterenteModalSchema, boeterenteSchema } from "../infra/financieringsbehoefte-schema";
import { Boeterente, BoeterenteModalType } from "../infra/financieringsbehoefte-types";
import { boeterenteAsyncSideEffects } from "./boeterente-modal-async-side-effects";
import { boeterenteSideEffects } from "./boeterente-modal-side-effects";

export type BoeterenteModalData = {
  data: Boeterente[];
};

type BoeterenteModalProps = {
  onSave?: (data: BoeterenteModalType) => void;
  closeModal?: () => void;
};

const boeterenteColumns: Column[] = [
  {
    Header: "Maatschappij",
    id: "maatschappij",
    Cell: (c): ReactElement => createSpanWithId(c.index, 0, c.original.maatschappij, c.original.maatschappij)
  },
  {
    Header: "Hypotheekvorm",
    id: "aflosvorm",
    Cell: (c): ReactElement => createSpanWithId(c.index, 1, c.original.aflosvorm)
  },
  {
    Header: "Bedrag",
    id: "restantHoofdsom",
    Cell: (c): ReactElement => createSpanWithId(c.index, 2, bedragFormat(c.original.restantHoofdsom))
  },
  {
    Header: "Boeterente",
    id: "boeterente",
    Cell: (c): ReactElement => createSpanWithId(c.index, 3, bedragFormat(c.original.boeterente))
  }
];

const BoeterenteModal = ({ data, onSave, closeModal }: BoeterenteModalData & BoeterenteModalProps): ReactElement => {
  const selectedState = useState(0);
  const [selected] = selectedState;
  const { setSErunning } = useContext(AppDataContext);

  const body = (values: Boeterente[]): ReactElement => {
    const contanteWaarde = values[selected].berekenwijzeBoeterente === BoeterenteBerekeningswijzeOpties.Contant;

    return (
      <>      
        <FieldArray
          name="modal"
          validateOnChange={false}
          render={(): React.ReactNode => (
            <>
              <DataGrid
                masterDetail
                columns={boeterenteColumns}
                name="modal"
                rowSelected={selectedState}
                validationSchema={boeterenteSchema}
                showButtonAddRow={false}
              />

              <div className="pt-4">
                <LabeledRadioInput
                  caption="Berekenwijze boeterente"
                  name={`modal[${selected}].berekenwijzeBoeterente`}
                  options={[
                    {
                      label: "Contante waarde",
                      value: BoeterenteBerekeningswijzeOpties.Contant
                    },
                    {
                      label: "Eigen invoer",
                      value: BoeterenteBerekeningswijzeOpties.EigenInvoer
                    }
                  ]}
                />
              </div>
              <LabeledTextInput caption="Aflosvorm" name={`modal[${selected}].aflosvorm`} readonly />
              <LabeledCurrencyInput
                caption="Oorspronkelijke hoofdsom"
                name={`modal[${selected}].oorspronkelijkeHoofdsom`}
                readonly={true}
              />
              <LabeledCurrencyInput
                caption="Restant hoofdsom"
                name={`modal[${selected}].restantHoofdsom`}
                readonly={true}
              />

              <LabeledJaarMaandInput caption="Restant looptijd" name={`modal[${selected}].restantLooptijd`} readonly />

              <LabeledCurrencyInput
                caption="Opgebouwde waarde"
                name={`modal[${selected}].opgewbouwdewaardeBedrag`}
                readonly={true}
              />

              {contanteWaarde && (
                <LabeledDateInput
                  caption="Einddatum rentevastperiode"
                  name={`modal[${selected}].einddatumRentevastperiode`}
                  readonly={true}
                />
              )}

              <LabeledDateInput
                readonly={contanteWaarde}
                caption="Datum boeterente"
                name={`modal[${selected}].datumBoeterente`}
              />

              {contanteWaarde && (
                <>
                  <LabeledJaarMaandInput
                    caption="Resterende periode"
                    name={`modal[${selected}].resterendePeriode`}
                    readonly={values[selected].resterendePeriodeWeergeven}
                    appendChildren={
                      <div className="no-margin">
                        <BerekenCurrencyButton
                          name={`modal[${selected}].resterendePeriodeWeergeven`}
                          berekenen={values[selected].resterendePeriodeWeergeven}
                        />
                      </div>
                    }
                  />

                  <LabeledPercentageInput
                    caption="Boetevrij percentage"
                    name={`modal[${selected}].boetevrijePercentage`}
                    decimalen={2}
                  />
                  <LabeledPercentageInput
                    caption="Huidige contractrente"
                    name={`modal[${selected}].huidigeContractrente`}
                    decimalen={2}
                  />
                  <LabeledPercentageInput
                    caption="Nieuw rentepercentage"
                    name={`modal[${selected}].nieuwRentepercentage`}
                    decimalen={2}
                  />
                </>
              )}
              <LabeledCurrencyInput
                caption="Boeterente"
                name={`modal[${selected}].boeterente`}
                readonly={contanteWaarde}
              />
            </>
          )}
        />
      </>
    );
  };

  return (
    <AdviesBoxFormik<BoeterenteModalType>
      initialValues={{ modal: data }}
      validationSchema={boeterenteModalSchema}
      onSave={onSave}
      closeModal={closeModal}
      render={({ ...props }: FormikProps<BoeterenteModalType>): ReactElement => (
        <>
          <ISWSideEffects<BoeterenteModalType>
            sync={boeterenteSideEffects(selected)}
            async={boeterenteAsyncSideEffects({ selected })}
            asyncStartStopCallback={setSErunning}
          />
          <Modal
            title="Boeterente"
            body={body(props.values.modal)}
            onSubmitClick={props.submitForm}
            onCancelClick={closeModal}
          />
        </>
      )}
    />
  );
};

export default BoeterenteModal;
