import React, { ReactElement } from "react";
import { connect, FormikContextType} from "formik";

import { GekoppeldProductKenmerken } from "../../producten-overzicht/infra/product-kenmerken-types";
import { HypothekenState } from "../infra/hypotheek-types";

type GekoppeldProductProps = {
  kenmerken: GekoppeldProductKenmerken;
};
const GekoppeldProduct = ({
  kenmerken,
  formik: {
    setFieldValue,
    values: { producten }
  }
}: GekoppeldProductProps & {
  formik: FormikContextType<HypothekenState>;
}): ReactElement => {
  return (
    <div className="px-03">
      <p>{kenmerken.hintGekoppeldProduct}</p>
    </div>
  );
};

export default connect<GekoppeldProductProps, HypothekenState>(GekoppeldProduct);
