import React from "react";
import { Table } from "react-bootstrap";
import { bedragFormatNull, monthDivision } from "../infra/scenario-utils";
import { InkomensanalyseResultaat } from "../../../../.generated/rapportage/rapportagetypes";

export interface BenodigdInkomenTableProps {
  benodigdInkomenBedrag: number | null;
  tekortBenodigInkomenBedrag: number | null;
  tekortNettoInkomenBedrag: number | null;
}

export const BenodigdInkomenTable: React.FC<BenodigdInkomenTableProps> = ({
  benodigdInkomenBedrag,
  tekortBenodigInkomenBedrag
}: Partial<InkomensanalyseResultaat>) => (
  <Table striped bordered hover responsive className="scenario__table mb-4">
    <colgroup>
      <col style={{ width: "70%" }} />
      <col style={{ width: "30%", textAlign: "right" }} />
    </colgroup>
    <tbody>
      <tr>
        <td>Minimaal benodigd inkomen voor uw hypotheek*</td>
        <td>{bedragFormatNull(benodigdInkomenBedrag, monthDivision)}</td>
      </tr>
      <tr className={"table-danger font-weight-bold"}>
        <td>Maandelijks tekort aan inkomsten voor uw hypotheek</td>
        <td>{bedragFormatNull(tekortBenodigInkomenBedrag, monthDivision)}</td>
      </tr>
      {/* <tr className={"table-danger font-weight-bold"}>
        <td>Netto maandelijks tekort voor uw hypotheek</td>
        <td>{bedragFormatNull(tekortNettoInkomenBedrag, monthDivision)}</td>
      </tr> */}
    </tbody>
  </Table>
);
