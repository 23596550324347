import * as React from "react";

import { LabeledCurrencyInput, LabeledTextInput } from "adviesbox-shared";
import { NettoVerkoopPandOpbrengst } from "../../infra/financieringsbehoefte-types";

type OpbrengstItemProps = {
  item: NettoVerkoopPandOpbrengst;
  index: number;
};

const OpbrengstItemComponent = ({ item, index }: OpbrengstItemProps): React.ReactElement => (
  <>
    <h2>{item.pandNaam}</h2>
    <LabeledCurrencyInput caption="Verkoopprijs" name={`nettoPandOpbrengst[${index}].verkoopPrijs`} readonly={true} />
    <LabeledCurrencyInput caption="Verkoopkosten" name={`nettoPandOpbrengst[${index}].verkoopKosten`} readonly={true} />
    <LabeledCurrencyInput caption="Subtotaal" name={`nettoPandOpbrengst[${index}].subtotaal`} readonly={true} />
    <LabeledTextInput
      caption="Percentage eigendom"
      name={`nettoPandOpbrengst[${index}].percentageEigendom`}
      readonly={true}
    />
    <LabeledCurrencyInput
      caption="Netto opbrengst"
      name={`nettoPandOpbrengst[${index}].nettoOpbrengst`}
      readonly={true}
    />
  </>
);

export default OpbrengstItemComponent;
