import { FormikContextType, connect } from "formik";

import React, { ReactElement } from "react";
import OverigeFinancieringskostenModal from "../overige-financieringskosten-modal/overige-financieringskosten-modal";
import { Icon } from "adviesbox-shared";
import { bedragFormat, optellen } from "../../shared/utils/currency";
import { ModalButton, LabeledResult } from "adviesbox-shared";
import { FinancieringsbehoefteState } from "../infra/financieringsbehoefte-types";

const OverigeFinancieringskosten = ({
  formik: { values, setFieldValue }
}: {
  formik: FormikContextType<FinancieringsbehoefteState>;
}): ReactElement => {
  return (
    <LabeledResult
      caption="Overige financieringskosten"
      name="overigeFinancieringskosten"
      alignRight={true}
      result={(): string =>
        bedragFormat(
          optellen([
            values.financieringsopzet.overigeFinancieringskostenModal.afsluitkostenSVnStarterslening,
            values.financieringsopzet.overigeFinancieringskostenModal.bereidstellingskosten
          ])
        )
      }
      appendChildren={
        <ModalButton
          parent="financieringsopzet.overigeFinancieringskostenModal"
          aria-label="Overige financieringskosten button"
          content={<Icon name="specificatie" alt="Overige financieringskosten" />}
        >
          <OverigeFinancieringskostenModal
            data={values.financieringsopzet.overigeFinancieringskostenModal}
            onSave={(result): void => {
              setFieldValue(
                "financieringsopzet.overigeFinancieringskosten",
                optellen([result.afsluitkostenSVnStarterslening, result.bereidstellingskosten])
              );
              setFieldValue("financieringsopzet.overigeFinancieringskostenModal", result);
            }}
          />
        </ModalButton>
      }
    />
  );
};

export default connect<{}, FinancieringsbehoefteState>(OverigeFinancieringskosten);
