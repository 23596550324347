import { LocalDate } from "@js-joda/core";

import { UiName, FieldMap } from "../../shared/types";
import { target2field } from "../../shared/utils/target-to-field";

import {
  Overig,
  OverigProduct,
  OverigAlgemeen,
  OverigGegevens,
  OverigVerrekenen,
  BedragScenario
} from "../../.generated/forms/formstypes";

import { OverigePostenType } from "./overige-posten-types";
import { mapKlantnaamUi2Dl } from "../../shared/generic-parts/klantnaam/map-ui-2-dl";
import { jaarMaandInMaanden } from "../../shared/generic-parts/jaar-maand/map-ui-2-dl";
import { createMapToDl } from "../../shared/utils/create-map-to-dl";
import { overigePostSchema, verrekenenSchema, overigePostProductSchema } from "./overige-posten-schema";
import { mapLocalDateToString } from "adviesbox-shared";

const correctieScenarioUi2Dl = createMapToDl(overigePostSchema).to<BedragScenario>({
  waarde: v => v.overigePostenGegevens.bedrag,
  indexeringen: v =>
    v.overigePostenGegevens.bedragScenario.scenario.map((scenario, index) => ({
      bedrag: scenario.bedrag,
      volgnummer: index + 1,
      ingangsmaand: null
    })),
  omschrijving: v => v.product.omschrijving
});

const gegevensUi2Dl = createMapToDl(overigePostSchema).to<OverigGegevens>({
  correctieScenario: v => correctieScenarioUi2Dl(v),
  betalingstermijn: v => v.overigePostenGegevens.termijn,
  inkomenUitArbeid: v => v.overigePostenGegevens.inkomenUitArbeid,
  onderdeelDagloon: v => v.overigePostenGegevens.onderdeelDagloon
});

const verrekenenUi2Dl = createMapToDl(verrekenenSchema).to<OverigVerrekenen>({
  langleven: v => v.langleven,
  arbeidsongeschiktheid: v => v.arbeidsongeschiktheid,
  werkloosheid: v => v.werkloosheid,
  overlijden: v => v.overlijden,
  overgangOpPartnerPercentage: v => v.overgangOpPartnerPercentage,
  overgangOpPartnerEinddatum: v => mapLocalDateToString(v.overgangOpPartnerEinddatum)
});

const algemeenUi2Dl = createMapToDl(overigePostProductSchema).to<OverigAlgemeen>({
  productnaam: v => v.productNaam,
  omschrijving: v => v.omschrijving,
  aanvangsdatum: v => mapLocalDateToString(v.ingangsdatum as LocalDate),
  looptijdInMaanden: v => jaarMaandInMaanden(v.looptijd) ?? 0,
  uwBemiddeling: v => v.uwBemiddeling,
  doorlopend: v => v.doorlopend
});

const overigePostUi2Dl = createMapToDl(overigePostSchema).to<OverigProduct>({
  productId: v => v.productId,
  algemeen: v => algemeenUi2Dl(v.product),
  correctieLast: v => v.correctie.correctieLast,
  correctieFiscaal: v => v.correctie.correctieFiscaal,
  heffingBijKlantId: v => v.heffing.heffingBij,
  gegevens: v => gegevensUi2Dl(v),
  verrekenen: v => verrekenenUi2Dl(v.verrekenen),
  wijzigingenInDoorlopendeProductenOvernemen: v =>
    v.product.wijzigingenInDoorlopendProductOvernemen ?? /*istanbul ignore next */ true,
  meenemen: v => false // TODO CONTROLEREN!!
});

export function mapOverigePostenUiToDl(overigePosten: OverigePostenType): Overig {
  return {
    producten: overigePosten.producten.map(overigePostUi2Dl),
    aanvrager1: mapKlantnaamUi2Dl(overigePosten.aanvrager1),
    aanvrager2: mapKlantnaamUi2Dl(overigePosten.aanvrager2),
    ingangsdatumVoorstel: mapLocalDateToString(overigePosten.ingangsdatumVoorstel)
  };
}

export function mapDlTargetToOverigePostenUiField(target: string): UiName | null {
  const map: FieldMap<Overig> = {
    producten: {
      algemeen: {
        productnaam: { field: "producten[i].product.productNaam", label: "Productnaam" },
        omschrijving: { field: "producten[i].product.omschrijving", label: "Omschrijving" },
        looptijdInMaanden: { field: "producten[i].product.einddatum", label: "Einddatum" }
      },
      gegevens: {
        correctieScenario: {
          omschrijving: { field: "", label: "Scenario omschrijving" }
        }
      }
    }
  };

  const regExp = /Producten\[(\d+)\].(\w+).(\w+)/;
  const match = regExp.exec(target);

  if (match) {
    const index = +match[1];
    const kaart = match[2];
    const veld = match[3];

    switch (kaart) {
      case "Verrekenen":
        switch (veld) {
          case "OvergangOpPartnerPercentage":
            return {
              field: `producten[${index}].verrekenen.overgangOpPartnerPercentage`,
              label: `Overige financiële post ${index + 1}: Overgang naar partner percentage`
            };
          case "OvergangOpPartnerEinddatum":
            return {
              field: `producten[${index}].verrekenen.overgangOpPartnerEinddatum`,
              label: `Overige financiële post ${index + 1}: Overgang naar partner einddatum`
            };
        }
    }
  }

  return target2field(map, target);
}
