import * as Yup from "yup";
import {
  AdviesdossierStatus,
  AdviesdossierStatusOptions,
  DossierbalkInfo,
  DossierbalkOutput
} from "../../../.generated/forms/formstypes";
import { createMapToUi } from "../../utils/create-map-to-ui";
import { createMapToDl } from "../../utils/create-map-to-dl";
import { yupNullableEnum } from "../../utils/yup-enum";

export const omschrijvingSchema = Yup.object({
  externAdviesnummer: Yup.string().default(""),
  korteOmschrijving: Yup.string().default(""),
  langeOmschrijving: Yup.string().default("")
});
export type OmschrijvingType = Yup.InferType<typeof omschrijvingSchema>;

const mapDossierDlEntry2Ui = createMapToUi(omschrijvingSchema).from<DossierbalkInfo>({
  externAdviesnummer: v => v.externeIdentificatie,
  korteOmschrijving: v => v.korteOmschrijving,
  langeOmschrijving: v => v.uitgebreideOmschrijving
});
export const mapDossierDl2Ui = (adviesdossierId: string, data: DossierbalkOutput): OmschrijvingType =>
  mapDossierDlEntry2Ui(data && data.isValid && data.dossierbalkInfo ? data.dossierbalkInfo[adviesdossierId] : null);

export const mapDossierUi2Dl = createMapToDl(omschrijvingSchema).to<DossierbalkInfo>({
  externeIdentificatie: v => v.externAdviesnummer,
  korteOmschrijving: v => v.korteOmschrijving,
  uitgebreideOmschrijving: v => v.langeOmschrijving,
  aanvrager1: v => null, // TODO CONTROLEREn
  aanvrager2: v => null // TODO CONTROLEREn
});

export const dossierStatusSchema = Yup.object({
  status: yupNullableEnum(AdviesdossierStatusOptions).default(null)
});
export type DossierStatusType = Yup.InferType<typeof dossierStatusSchema>;

const mapDossierStatusDlEntry2Ui = createMapToUi(dossierStatusSchema).from<AdviesdossierStatus>({
  status: v => v.status
});
export const mapDossierStatusDl2Ui = (adviesdossier: string | null, data: any): DossierStatusType =>
  mapDossierStatusDlEntry2Ui(data && adviesdossier && data.isValid && data.status ? data.status[adviesdossier] : null);

export const mapDossierStatusUi2Dl = createMapToDl(dossierStatusSchema).to<AdviesdossierStatus>({
  status: v => v.status ?? AdviesdossierStatusOptions.InBehandeling
});
