import { Column } from "react-table-6";
import {
  HypotheekOversluitenDataGridTemplates,
  HypotheekOversluitenState
} from "../../infra/hypotheek-oversluiten-schema";
import { getHypotheekOversluitenColumnTemplates } from "./data-grid-template/data-grid-template";

export const hypotheekOversluitenColumns = (values: HypotheekOversluitenState): Column[] => {
  const columnTemplates = getHypotheekOversluitenColumnTemplates;
  const columns: { [key: number]: Column | null } = {
    [HypotheekOversluitenDataGridTemplates.akkoordOversluiten]: null,
    [HypotheekOversluitenDataGridTemplates.maatschappijNaam]: null,
    [HypotheekOversluitenDataGridTemplates.rentePercentage]: null,
    [HypotheekOversluitenDataGridTemplates.brutoMaandlast]: null,
    [HypotheekOversluitenDataGridTemplates.besparingMaand]: null,
    [HypotheekOversluitenDataGridTemplates.oversluitkosten]: null,
    [HypotheekOversluitenDataGridTemplates.besparingInRvp]: null,
    [HypotheekOversluitenDataGridTemplates.terugverdienPeriodeInMaanden]: null,
    [HypotheekOversluitenDataGridTemplates.terugverdienPeriodePercentage]: null
  };

  values.berekendeHypotheken.forEach((a): void => {
    if (!columns[HypotheekOversluitenDataGridTemplates.akkoordOversluiten] && !!a.akkoordOversluiten) {
      columns[HypotheekOversluitenDataGridTemplates.akkoordOversluiten] =
        columnTemplates[HypotheekOversluitenDataGridTemplates.akkoordOversluiten];
    }

    if (!columns[HypotheekOversluitenDataGridTemplates.maatschappijNaam] && !!a.maatschappijNaam) {
      columns[HypotheekOversluitenDataGridTemplates.maatschappijNaam] =
        columnTemplates[HypotheekOversluitenDataGridTemplates.maatschappijNaam];
    }

    if (!columns[HypotheekOversluitenDataGridTemplates.rentePercentage] && !!a.rentePercentage) {
      columns[HypotheekOversluitenDataGridTemplates.rentePercentage] =
        columnTemplates[HypotheekOversluitenDataGridTemplates.rentePercentage];
    }

    if (!columns[HypotheekOversluitenDataGridTemplates.brutoMaandlast] && !!a.brutoMaandlast) {
      columns[HypotheekOversluitenDataGridTemplates.brutoMaandlast] = {
        ...columnTemplates[HypotheekOversluitenDataGridTemplates.brutoMaandlast],
        width: 97,
        className: "text-right"
      }
    }

    if (!columns[HypotheekOversluitenDataGridTemplates.besparingMaand] && !!a.besparingMaand) {
      columns[HypotheekOversluitenDataGridTemplates.besparingMaand] = {
        ...columnTemplates[HypotheekOversluitenDataGridTemplates.besparingMaand],
        width: 95,
        className: "text-right"
      }
    }

    if (!columns[HypotheekOversluitenDataGridTemplates.oversluitkosten] && !!a.oversluitkosten) {
      columns[HypotheekOversluitenDataGridTemplates.oversluitkosten] = {
        ...columnTemplates[HypotheekOversluitenDataGridTemplates.oversluitkosten],
        width: 95,
        className: "text-right"
      }
    }

    if (!columns[HypotheekOversluitenDataGridTemplates.besparingInRvp] && !!a.besparingInRvp) {
      columns[HypotheekOversluitenDataGridTemplates.besparingInRvp] = {
        ...columnTemplates[HypotheekOversluitenDataGridTemplates.besparingInRvp],
        width: 96,
        className: "text-right"
      }
    }

    if (
      !columns[HypotheekOversluitenDataGridTemplates.terugverdienPeriodeInMaanden] &&
      !!a.terugverdienPeriodeInMaanden
    ) {
      columns[HypotheekOversluitenDataGridTemplates.terugverdienPeriodeInMaanden] = {
        ...columnTemplates[HypotheekOversluitenDataGridTemplates.terugverdienPeriodeInMaanden],
        width: 95,
        className: "text-right"
      }

    }
    if (
      !columns[HypotheekOversluitenDataGridTemplates.terugverdienPeriodePercentage] &&
      !!a.terugverdienPeriodePercentage
    ) {
      columns[HypotheekOversluitenDataGridTemplates.terugverdienPeriodePercentage] =
        columnTemplates[HypotheekOversluitenDataGridTemplates.terugverdienPeriodePercentage];
    }
  });

  return Object.values(columns).filter((c): boolean => !!c) as Column[];
};
