import {
  LabeledBevestigingInput,
  LabeledCurrencyInput,
  LabeledDateInput,
  LabeledNumberInput,
  LabeledTextInput,
  TextInput
} from "adviesbox-shared";
import { FormikProps } from "formik";
import React, { ReactElement } from "react";
import { Button, Modal } from "react-bootstrap";
import { HdnAnders, onderpandSchema, OnderpandType } from "../../generic-parts/onderpand/schema";
import AdviesBoxFormik from "../../utils/adviesbox-formik";
import { DevDebug } from "../dev-debug/dev-debug";
import LabeledHdnKeuzelijst from "../hdn-keuzelijst/LabeledHdnKeuzelijst";
import { ISWSideEffects } from "../isw-side-effects/isw-side-effects";
import { getOnderpandTextResources } from "./onderpand-resources";
import { determineOnderpandSideEffects } from "./onderpand-side-effects";

type OnderpandModelContentProps = {
  data: OnderpandType;
  onSave(data: OnderpandType): void;
  closeModal?: () => void;
};

export type OnderpandModalContentState = OnderpandType;

const OnderpandModalContent = ({ data, closeModal, onSave }: OnderpandModelContentProps): ReactElement => {
  return (
    <AdviesBoxFormik<OnderpandModalContentState>
      initialValues={{
        ...data
      }}
      validationSchema={onderpandSchema}
      onSave={onSave}
      closeModal={closeModal}
      render={({ values, submitForm }: FormikProps<OnderpandModalContentState>): ReactElement => (
        <>
          <ISWSideEffects<OnderpandModalContentState> sync={determineOnderpandSideEffects} />

          <Modal.Header closeButton>
            <Modal.Title>Onderpand</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <LabeledNumberInput noThousandsSeparator name="bouwjaar" caption="Bouwjaar" fieldSize="m" />
            <LabeledDateInput name="opleverDatum" caption="Opleverdatum" />
            <LabeledTextInput name="bouwPlan" caption="Bouwplan" />
            <LabeledTextInput name="bouwNummer" caption="Bouwnummer" fieldSize="m" />

            <LabeledTextInput name="kadastraleGemeente" caption="Kadastrale gemeente" />

            <LabeledTextInput
              name="kadastraleSectie"
              caption="Kadastrale sectie / nummer"
              type="text"
              fieldSize="s"
              appendChildren={<TextInput name="kadastraleNummer" type="number" fieldSize="s" />}
            />

            <LabeledBevestigingInput name="gedeeltelijkePerceel" caption="Gedeeltelijk perceel" />
            <LabeledBevestigingInput name="appartementsRecht" caption="Appartementsrecht" />
            <LabeledCurrencyInput name="serviceKosten" caption="Servicekosten" fieldSize="m" decimalen={2} />
            <LabeledBevestigingInput name="recreatieveBewoning" caption="Recreatieve bewoning" />
            <LabeledBevestigingInput name="heeftGarage" caption="Heeft garage" />
            <LabeledHdnKeuzelijst
              caption="Bijzonder objectsoort"
              name="bijzonderObjectSoort"
              keuzelijst="BijzObjectSoortType"
              berichtSoortType="AX"
              emptyValue={"Selecteer een optie"}
            />
            <LabeledHdnKeuzelijst
              caption="Bouwaard"
              name="bouwaard"
              keuzelijst="BouwaardType"
              berichtSoortType="AX"
              emptyValue={"Selecteer een optie"}
            />
            <LabeledHdnKeuzelijst
              caption="Staat van onderhoud"
              name="staatVanOnderhoud"
              keuzelijst="OnderhoudsToestandType"
              berichtSoortType="AX"
              emptyValue={"Selecteer een optie"}
            />
            <LabeledBevestigingInput name="verkoopOnderVoorwaarden" caption="Verkoop onder voorwaarden" />
            {values.verkoopOnderVoorwaarden && (
              <LabeledHdnKeuzelijst
                caption="Constructie"
                name="constructie"
                keuzelijst="VerkoopOnderVoorwaardenConstructieType"
                berichtSoortType="AX"
              />
            )}
            {values.verkoopOnderVoorwaarden && values.constructie === `${HdnAnders}` && (
              <>
                <LabeledHdnKeuzelijst
                  caption="Constructie anders"
                  name="erfpachtConstructieAnders"
                  keuzelijst="ErfpachtOfKortingsConstructieAndersType"
                  berichtSoortType="AX"
                />
                <LabeledTextInput
                  caption={"Constructie omschrijving"}
                  name={`constructieOmschrijving`}
                  textarea={3}
                  tooltip={getOnderpandTextResources("constructieOmschrijving")}
                />
              </>
            )}
            <LabeledBevestigingInput
              name="koopAannemingsOvMetVormAfbouwGarantie"
              caption="Koop/aannemingsovereenkomst met vorm van afbouwgarantie?"
            />
            <LabeledBevestigingInput name="taxatieAanwezig" caption="Taxatie aanwezig" />
            {values.taxatieAanwezig && (
              <LabeledDateInput verplicht={values.taxatieAanwezig} name="taxatieDatum" caption="Taxatiedatum" />
            )}
            <LabeledDateInput name="ontbindendeVoorwaardenTot" caption="Ontbindende voorwaarden tot" />
            <DevDebug />
          </Modal.Body>
          <Modal.Footer>
            <Button data-testid="btnannuleren" id="btnannuleren" variant="link" onClick={closeModal}>
              Annuleren
            </Button>
            <Button
              data-testid="btnopslaan"
              id="btnopslaan"
              variant="primary"
              onClick={submitForm}
              //disabled={!isValid} Regel commented omdat er geen foutmelding werd getoond wanneer "taxatie aanwezig" op ja gezet wordt en meteen op "Bevestigen" gedrukt wordt.
            >
              Bevestigen
            </Button>
          </Modal.Footer>
        </>
      )}
    />
  );
};

export default OnderpandModalContent;
