import { OuderdomspensioenModalType, ouderdomspensioenModalEntrySchema } from "../infra/pensioen-schema";
import { createISWSideEffect, initISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";

const ouderdomspensioenModalSideEffects = createISWSideEffect<OuderdomspensioenModalType>(({ draft }) => {
  if (!draft.indexeringen || draft.indexeringen.length === 0) {
    draft.indexeringen = [ouderdomspensioenModalEntrySchema.default()];
  }
});

export const determineOuderdomspensioenModalSideEffects = initISWSideEffect(ouderdomspensioenModalSideEffects);
