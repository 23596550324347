import {
  Bankgarantie as BankgarantieDlEntry,
  BankgarantieAlgemeen,
  ProductkeuzeOptions,
  BankgarantieGarantie,
  BankgarantieNotaris,
  BankgarantieOnroerendeZaak,
  BankgarantiePand,
  BankgarantieVerkopers,
  Bankgarantie,
  GeslachtOpties
} from "../../.generated/forms/formstypes";
import { mapAdresUiNaarDl } from "../../shared/generic-parts/adres/map-ui-2-dl";
import { createMapToDl } from "../../shared/utils/create-map-to-dl";
import {
  bankgarantieKaartSchema,
  bankgarantieSchema,
  InstellingCodes,
  notarisSchema,
  onroerendeZaakSchema,
  pandSchema,
  productSchema,
  verkopersSchema
} from "./bankgarantie-schema";
import { mapLocalDateToString } from "adviesbox-shared";

const mapAlgemeen = createMapToDl(productSchema).to<BankgarantieAlgemeen>({
  aanvraagViaArrangementId: v => (v.instelling === InstellingCodes.Geen ? null : v.aanvraagVia),
  agentnummer: () => null,
  arrangementsnaam: v => (v.instelling === InstellingCodes.Geen ? null : v.productkeuze),
  instellingMaatschappijCode: v => (v.instelling === InstellingCodes.Geen ? null : v.instelling),
  tussenpersoonnummer: v => (v.instelling === InstellingCodes.Geen ? null : v.tussenpersoonnummer),
  productkeuze: v => (v.instelling === InstellingCodes.CA ? ProductkeuzeOptions.GS1218 : null),
  arrangementId: v=> null,
  offertenummer:v =>null,
  productId: v=> null,
  uwKenmerk: v=> null
});

const mapGarantie = createMapToDl(bankgarantieKaartSchema)
  .with<string>()
  .to<BankgarantieGarantie>({
    afgiftedatum: v => mapLocalDateToString(v.afgiftedatumBankgarantie),
    bankgarantiekostenBedrag: (v, i) =>
      i === InstellingCodes.CA
        ? v.garantieKostenberekenen.berekenen
          ? v.garantieKostenberekenen.berekendBedrag
          : v.garantieKostenberekenen.bedrag
        : null,
    passeerdatum: v => mapLocalDateToString(v.passeerdatum.datum),
    passeerdatumOvernemen: v => v.passeerdatum.berekenen,
    passeerdatumStandaard: v => mapLocalDateToString(v.passeerdatum.berekendeDatum),
    vergoedingCode: v => v.vergoedingKorting,
    verzekerdeBankgarantie: v => v.verzekerdeBankgarantie,
    ondertekeningKoopakteDatum: (v, i) =>
      i === InstellingCodes.CA ? mapLocalDateToString(v.datumOndertekeningKoopakte) : null,
    einddatum: (v, i) => (i === InstellingCodes.CA ? mapLocalDateToString(v.einddatumGarantie) : null),
    ingangsdatum: (v, i) => (i === InstellingCodes.CA ? mapLocalDateToString(v.ingangsdatumGarantie) : null),
    premieBedrag: (v, i) => (i === InstellingCodes.CA ? v.garantiebedrag : null),
    bankgarantiekostenEigenInvoer: v =>
      v.garantieKostenberekenen.berekenen ? !v.garantieKostenberekenen.berekenen : true
  });

export const mapNotaris = createMapToDl(notarisSchema).to<BankgarantieNotaris>({
  adres: v => v.adres,
  gekozenNotariskantoorId: v => (v.gekozenNotariskantoorId === "Anders" ? null : v.gekozenNotariskantoorId),
  kantoornaam: v => v.kantoornaam,
  typeNotaris: v => v.typeNotaris,
  telefoonNummer: v => v.telefoonnummer
});

const mapOnroerendeZaak = createMapToDl(onroerendeZaakSchema).to<BankgarantieOnroerendeZaak>({
  adres: v => mapAdresUiNaarDl(v.adres),
  bouwnummer: v => null,
  omschrijving: v => null,
  projectnaam: v => null,
  nieuwWoonadres: v => null //TODO CONTROLEREN!!
});

const mapPand = createMapToDl(pandSchema).to<BankgarantiePand>({
  aankoopprijsBedrag: v => v.aankoopprijs,
  marktwaardeBedrag: v => v.gehanteerdeMarktwaarde,
  hypotheekbedrag: v => v.hypotheekbedrag,
  nhg: v => v.nhg,
  geldverstrekkerNaam: v => null, // Niet meer van toepassing
  garantieOvernemen: v => null, // Niet meer van toepassing
  garantiebedrag: v => null, // Niet meer van toepassing,
  eigenMiddelenBedrag: v => null,
  overbruggingBedrag: v => null,
});

const mapVerkopers = createMapToDl(verkopersSchema).to<BankgarantieVerkopers>({
  bedrijfsnaam: v => v.bedrijfsnaam,
  verkoper1: v => {
    return {
      achternaam: v.verkoper1.achternaam,
      voorvoegsel: v.verkoper1.tussenvoegsel,
      voorletters: v.verkoper1.voorletters,
      woonplaats: v.verkoper1.woonplaats
    };
  },
  verkoper2: v => {
    return {
      achternaam: v.verkoper2.achternaam,
      voorvoegsel: v.verkoper2.tussenvoegsel,
      voorletters: v.verkoper2.voorletters,
      woonplaats: v.verkoper2.woonplaats
    };
  }
});

export const mapBankgarantieUiToDl = createMapToDl(bankgarantieSchema).to<BankgarantieDlEntry>({
  algemeen: v => mapAlgemeen(v.product),
  garantie: v =>
    v.product.instelling === InstellingCodes.Geen ? null : mapGarantie(v.product.instelling)(v.bankgarantieKaart),
  notaris: v => (v.product.instelling === InstellingCodes.Geen ? null : mapNotaris(v.notaris)),
  onroerendeZaak: v => (v.product.instelling === InstellingCodes.Geen ? null : mapOnroerendeZaak(v.onroerendeZaak)),
  pand: v => (v.product.instelling === InstellingCodes.Geen ? null : mapPand(v.pand)),
  verkopers: v => (v.product.instelling === InstellingCodes.Geen ? null : mapVerkopers(v.verkopers)),
  aanvrager1: _ => null, //TODO CONTROLEREN!!
  aanvrager2: _ => null, //TODO CONTROLEREN!!
  soortFinanciering: _ => null //TODO CONTROLEREN!!
});


export const mapBankgarantieAanvraagToDl = createMapToDl(bankgarantieSchema).to<Bankgarantie>({
  aanvrager1: v => ({
    geslacht: v.aanvrager1.geslacht as GeslachtOpties | null,
    voorletters: v.aanvrager1.voorletters,
    achternaam: v.aanvrager1.achternaam,
    geboortedatum: mapLocalDateToString(v.aanvrager1.geboortedatum),
    roker: v.aanvrager1.roker,
    voornamen: v.aanvrager1.voornamen,
    voorvoegsel: v.aanvrager1.voorvoegsel,
    aowdatum: null,
    klantId: null as any,
  }),
  aanvrager2: v => ({
    geslacht: v.aanvrager2?.geslacht as GeslachtOpties | null,
    voorletters: v.aanvrager2?.voorletters,
    achternaam: v.aanvrager2?.achternaam,
    geboortedatum: v.aanvrager2?.geboortedatum ? mapLocalDateToString(v.aanvrager2?.geboortedatum) : null,
    roker: v.aanvrager2?.roker,
    voornamen: v.aanvrager2?.voornamen,
    voorvoegsel: v.aanvrager2?.voorvoegsel,
    aowdatum: null,
    klantId: null as any,
  }),
  algemeen: v => ({
    aanvraagViaArrangementId: v.product.aanvraagVia,
    agentnummer: null,
    arrangementsnaam: v.product.productkeuze,
    instellingMaatschappijCode: v.product.instelling,
    productkeuze: v.product.productkeuze as ProductkeuzeOptions,
    tussenpersoonnummer: v.product.tussenpersoonnummer,
    arrangementId: null,
    offertenummer: null,
    productId: null,
    uwKenmerk: null,
    
  }),
  garantie: v => ({
    afgiftedatum: mapLocalDateToString(v.bankgarantieKaart.afgiftedatumBankgarantie),
    ondertekeningKoopakteDatum: mapLocalDateToString(v.bankgarantieKaart.datumOndertekeningKoopakte),
    passeerdatum: mapLocalDateToString(v.bankgarantieKaart.passeerdatum.datum),
    passeerdatumOvernemen: v.bankgarantieKaart.passeerdatum.berekenen,
    vergoedingCode: v.bankgarantieKaart.vergoedingKorting,
    verzekerdeBankgarantie: v.bankgarantieKaart.verzekerdeBankgarantie,
    bankgarantiekostenBedrag: v.bankgarantieKaart.garantieKostenberekenen.berekenen ? v.bankgarantieKaart.garantieKostenberekenen.berekendBedrag : v.bankgarantieKaart.garantieKostenberekenen.bedrag,
    bankgarantiekostenEigenInvoer: v.bankgarantieKaart.garantieKostenberekenen ? !v.bankgarantieKaart.garantieKostenberekenen.berekenen : true,
    premieBedrag: v.bankgarantieKaart.garantiebedrag,
    ingangsdatum: mapLocalDateToString(v.bankgarantieKaart.ingangsdatumGarantie),
    einddatum: mapLocalDateToString(v.bankgarantieKaart.einddatumGarantie),
    passeerdatumStandaard: null
  }),
  notaris: v => ({
    id: v.notaris.gekozenNotariskantoorId,
    naamKantoor: v.notaris.kantoornaam,
    adres: {
      straat: v.notaris.adres.straat,
      plaats: v.notaris.adres.plaats,
      postcode: v.notaris.adres.postcode,
      huisnummer: v.notaris.adres.huisnummer,
      landCode: v.notaris.adres.landCode,
      locatie: v.notaris.adres.locatie,
      toevoeging: v.notaris.adres.toevoeging,
    },
    gekozenNotariskantoorId: v.notaris.gekozenNotariskantoorId,
    kantoornaam: v.notaris.kantoornaam,
    typeNotaris: v.notaris.typeNotaris,
    telefoonNummer: v.notaris.telefoonnummer,
  }),
  onroerendeZaak: v => ({
    adres: {
      huisnummer: v.onroerendeZaak.adres.huisnummer,
      landCode: v.onroerendeZaak.adres.landCode,
      locatie: v.onroerendeZaak.adres.locatie,
      plaats: v.onroerendeZaak.adres.plaats,
      postcode: v.onroerendeZaak.adres.postcode,
      straat: v.onroerendeZaak.adres.straat,
      toevoeging: v.onroerendeZaak.adres.toevoeging,
    },
    bouwnummer: v.onroerendeZaak.bouwnummer,
    nieuwWoonadres: v.onroerendeZaak.adres,
    omschrijving: null,
    projectnaam: null
  }),
  pand: v => mapPand(v.pand),
  soortFinanciering: v => v.soortFinanciering,
  verkopers: v => ({
    verkoper1:
    {
      voorletters: v.verkopers.verkoper1.voorletters ?? /* istanbul ignore next */ null,
      achternaam: v.verkopers.verkoper1.achternaam ?? /* istanbul ignore next */null,
      voorvoegsel: v.verkopers.verkoper1.tussenvoegsel ?? /* istanbul ignore next */ null,
      woonplaats: v.verkopers.verkoper1.woonplaats ?? /* istanbul ignore next */ null
    },
    verkoper2:
    {
      voorletters: v.verkopers.verkoper2 ? v.verkopers.verkoper2.voorletters ?? /* istanbul ignore next */ null : /* istanbul ignore next */ null,
      achternaam: v.verkopers.verkoper2 ? v.verkopers.verkoper2.achternaam ?? /* istanbul ignore next */null : /* istanbul ignore next */ null,
      voorvoegsel: v.verkopers.verkoper2 ? v.verkopers.verkoper2.tussenvoegsel ?? /* istanbul ignore next */null : /* istanbul ignore next */ null,
      woonplaats: v.verkopers.verkoper2 ? v.verkopers.verkoper2.woonplaats ?? /* istanbul ignore next */ null : /* istanbul ignore next */ null
    },
    bedrijfsnaam: v.verkopers.bedrijfsnaam ?? /* istanbul ignore next */ null,
    bedrijfVerkopers: [
      {
        bedrijfsnaam: v.verkopers.bedrijfsnaam,
        plaats: null
      }
    ],
    anderVerkoperOmschrijving: null
  })
});

