import { Adres, AdresReadOnly } from "../../../.generated/forms/formstypes";
import { AdresType } from "../../types";

export function mapAdresUiNaarDl(value: AdresType): Adres | AdresReadOnly {
  let postcode = value.postcode;

  if (value.landCode === "NL") {
    postcode = value.postcode.toUpperCase().replace(/\s/g, "");
    const letters = postcode.slice(4, 6);
    const numbers = postcode.slice(0, 4);

    if (numbers.charAt(0) !== "") {
      postcode = `${numbers} ${letters}`;
    }
  }

  const result: Adres | AdresReadOnly = {
    straat: value.straat || "",
    huisnummer: typeof value.huisnummer === "number" ? value.huisnummer : null,
    toevoeging: value.toevoeging || "",
    postcode: postcode || "",
    locatie: value.locatie || "",
    plaats: value.plaats || "",
    landCode: value.landCode
  };
  return result;
}
