import { withErrorBoundary } from "adviesbox-shared";
import React, { ReactElement } from "react";
import { SimpleErrorPage } from "adviesbox-shared";

export const InvalidAdviesboxResultErrorPage = (): ReactElement => {
  //TODO: adviesbox insights toevoegen
  return (
    <SimpleErrorPage
      title="Er is geen data ontvangen"
      message="We hebben de data niet kunnen ophalen. Probeer de pagina opnieuw te laden of ga terug naar het startscherm."
    />
  );
};

InvalidAdviesboxResultErrorPage.displayName = "InvalidAdviesboxResultErrorPage";

export default withErrorBoundary(InvalidAdviesboxResultErrorPage);
