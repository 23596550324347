import { BetalingsTermijnType } from "../../.generated/forms/formstypes";
import { AanvullingInkomenBijOverlijdenModalType } from "../infra/producten-overzicht-types";
import { initISWSideEffect, createISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";

const aanvullingInkomenDraftSideEffects = createISWSideEffect<AanvullingInkomenBijOverlijdenModalType>((bag): void => {
  const { has, draft } = bag;
  if (has.aanvullingInkomenBijOverlijdenAanvrager1.changed) {
    draft.gewensteUitkeringPerPeriodeAanvrager1 = BetalingsTermijnType.Maand;
  }

  if (has.aanvullingInkomenBijOverlijdenAanvrager2.changed) {
    draft.gewensteUitkeringPerPeriodeAanvrager2 = BetalingsTermijnType.Maand;
  }
});

export const determineAanvullingInkomenSideEffects = initISWSideEffect(aanvullingInkomenDraftSideEffects)();
