import { hasJSONResponse, SettingsType } from "adviesbox-shared";
import { User } from "oidc-client";
import { mapKenmerken } from "../infra/product-kenmerken-mapping";
import { EenVanDeKenmerken, ProductSoort } from "../infra/product-kenmerken-types";

export const getKenmerken = async (
  settings: SettingsType,
  user: User | null,
  productSoort: ProductSoort,
  maatschappijCode: string | null,
  productCode: string | null
): Promise<EenVanDeKenmerken | null> => {
  const url = `${settings?.productenOrigin}/MaatschappijCodes/${maatschappijCode}/${productSoort}/${productCode}/Productkenmerken`;

  const rsp = await fetch(url, {
    method: "GET",
    headers: {
      authorization: `${user?.token_type} ${user?.access_token}`,
      "Ocp-Apim-Subscription-Key": settings.OcpApimSubscriptionKey,
      "Content-Type": "application/json;charset=UTF-8"
    }
  });

  if (!(rsp.ok && hasJSONResponse(rsp))) {
    throw Error(`Fout bij aanroep van web-api '${url}'. Resultaat bevat geen JSON.`);
  }

  const json = await rsp.json();

  if (!json.isValid) {
    throw Error(`Fout bij aanroep van web-api '${url}'. Input is niet valid.`);
  }

  return mapKenmerken(productSoort, json);
};
