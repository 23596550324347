import {
  LabeledJaarMaandInput,
  LabeledNumberInput,
  LabeledPercentageInput,
  LabeledSelectInput
} from "adviesbox-shared";
import { useFormikContext } from "formik";
import React, { ReactElement } from "react";
import { DuurOverzichtOptions, ControleGewenstInkomenOptions } from "../../../.generated/forms/formstypes";
import { getNaam } from "../../../shared/utils/helpers";
import { DashboardState } from "../../infra/dashboard-types";

const gewensteDuurTot = {
  label: "Tot leeftijd",
  value: DuurOverzichtOptions.TotLeeftijd
};

const gewenteDuurBasisOptions = [
  {
    label: "Vaste duur in jaren",
    value: DuurOverzichtOptions.VasteDuurInJaren
  },
  {
    label: "Levenslang",
    value: DuurOverzichtOptions.Levenslang
  }
];

export const Orv = (): ReactElement => {
  const { values } = useFormikContext<DashboardState>();

  const gewensteDuurTotLeeftijdTonen =
    values.overlijden.controleNorm.controleGewenstInkomen === ControleGewenstInkomenOptions.Nbi;

  let gewensteDuurOptions = gewenteDuurBasisOptions;
  if (gewensteDuurTotLeeftijdTonen) gewensteDuurOptions = [gewensteDuurTot].concat(gewenteDuurBasisOptions);

  const aanvrager1Tonen =
    (values.aanvrager2 !== null && values.overlijden.scenarioVan.aanvrager1Geselecteerd) || !values.aanvrager2;

  return (
    <>
      {aanvrager1Tonen && (
        <>
          <span>{getNaam(values.aanvrager1, "Aanvrager")}</span>
          <LabeledPercentageInput
            name="overlijden.orv.aanvragerPercentage"
            caption="Blijft % werken na overlijden"
            decimalen={0}
          />
        </>
      )}

      {values.overlijden.scenarioVan.aanvrager2Geselecteerd && values.aanvrager2 && (
        <>
          <span>{getNaam(values.aanvrager2, "Partner")}</span>
          <LabeledPercentageInput
            name="overlijden.orv.partnerPercentage"
            caption="Blijft % werken na overlijden"
            decimalen={0}
          />
        </>
      )}

      {values.overlijden.controleNorm.controleGewenstInkomen === ControleGewenstInkomenOptions.Nbi && (
        <>
          <LabeledSelectInput
            name="overlijden.orv.gewensteDuurOverzicht"
            caption="Gewenste duur overzicht"
            options={gewensteDuurOptions}
          />
          {values.overlijden.orv.gewensteDuurOverzicht === DuurOverzichtOptions.TotLeeftijd && (
            <LabeledJaarMaandInput name="overlijden.orv.leeftijd" caption="Leeftijd" />
          )}

          {values.overlijden.orv.gewensteDuurOverzicht === DuurOverzichtOptions.VasteDuurInJaren && (
            <LabeledNumberInput name="overlijden.orv.aantalJaar" caption="Aantal jaar" appendChildren={"Jaar"} />
          )}
        </>
      )}
    </>
  );
};
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production") Orv.displayName = "Orv";
