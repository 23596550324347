import { createISWSideEffect, initISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";
import { AdvieskostenBemiddelingsvergoedingModal } from "../infra/financieringsbehoefte-types";

const advieskostenBemiddelingsvergoedingDraftSideEffects = createISWSideEffect<AdvieskostenBemiddelingsvergoedingModal>(
  (bag): void => {
    const { has, draft } = bag;

    if (has.gespreidBetalen.changed && draft.gespreidBetalen === false) {
      draft.advieskostenGespreid = null;
      draft.bemiddelingsvergoedingGeldleningGespreid = null;
      draft.overigeBemiddelingsvergoedingGespreid = null;
      draft.gespreidBetalenOver = null;
    }
  }
);

export const advieskostenBemiddelingsvergoedingSideEffects = initISWSideEffect(
  advieskostenBemiddelingsvergoedingDraftSideEffects
)();
