// TODO: Opsplitsen in  ui-to-dl en dl-to-ui
import {
  OverlijdensRisicoInput,
  BetalingstermijnOpties,
  OverlijdensRisicoAlgemeenInput,
  OverlijdensRisicoVerzekerdeInput,
  OverlijdensRisicoVerzekeringInput,
  MultipleOrvInput,
  OverlijdensRisicoOutput,
  PremieverloopOpties,
  UitkeringOpties,
  GeslachtOpties
} from "../../../.generated/orv/orvtypes";
import { BetalingsTermijnType } from "../../../.generated/forms/formstypes";
import { KlantnaamType } from "../../../shared/generic-parts/klantnaam/schema";
import { jaarMaandInMaanden } from "../../../shared/generic-parts/jaar-maand/map-ui-2-dl";
import { OrvType } from "../orv-schema";
import { mapLocalDateToString } from "adviesbox-shared";

function mapBetalingstermijnOpties(betalingstermijn: BetalingsTermijnType | null): BetalingstermijnOpties {
  switch (betalingstermijn) {
    case BetalingsTermijnType.Maand:
      return BetalingstermijnOpties.PerMaand;
    case BetalingsTermijnType.Kwartaal:
      return BetalingstermijnOpties.PerKwartaal;
    case BetalingsTermijnType.HalfJaar:
      return BetalingstermijnOpties.PerHalfJaar;
    case BetalingsTermijnType.Jaar:
      return BetalingstermijnOpties.PerJaar;
  }

  return BetalingstermijnOpties.PerMaand;
}

function mapInvoerNaarAlgemeen(product: OrvType): OverlijdensRisicoAlgemeenInput {
  const algemeen: OverlijdensRisicoAlgemeenInput = {
    ingangsDatum: mapLocalDateToString(product.product.ingangsdatum),
    looptijdInMaanden: jaarMaandInMaanden(product.product.looptijd) || 0,
    verpand: product.verpanding.verpandAanGeldverstrekker,
    premieVerloop: PremieverloopOpties.Gelijkblijvend, // TODO, wat moet default zijn?
    premieSplitsing: product.verzekerden.premiesplitsing,
    kindDekking: null,
    premiereserve: null
  };

  return algemeen;
}

function mapInvoerNaarVerzekerden(
  product: OrvType,
  aanvrager1: KlantnaamType | null,
  aanvrager2: KlantnaamType | null
): OverlijdensRisicoVerzekerdeInput[] {
  const verzekerden: OverlijdensRisicoVerzekerdeInput[] = [
    {
      id: 0,
      stopDatumRoken: null,
      rookwaarPerDag: null,
      pva: null,
      inkomen: null,
      eindkapitaal: null,
      verzekerdBedrag: product.dekking.verzekerdKapitaalAanvrager1 || 0,
      uitkering: UitkeringOpties.AnnuitairDalend, // TODO controleren!!
      roker: null,
      geslacht: GeslachtOpties.Man, // TODO controleren!!
      geboorteDatum: "",
      annuiteitsPercentage: product.dekking.annuiteitspercentage1 || product.dekking.annuiteitspercentage2
    }
  ];

  return verzekerden;
}

function mapInvoerNaarPremie(product: OrvType): OverlijdensRisicoVerzekeringInput {
  return {
    ingangsDatum: mapLocalDateToString(product.product.ingangsdatum),
    betalingsTermijn: mapBetalingstermijnOpties(product.premieGegevens.betalingstermijn as BetalingsTermijnType),
    looptijdInMaanden: jaarMaandInMaanden(product.premieGegevens.looptijd) || 0
  };
}

export function mapMultipleOrvInput(
  product: OrvType,
  aanvrager1: KlantnaamType | null,
  aanvrager2: KlantnaamType | null
): MultipleOrvInput {
  const algemeen = mapInvoerNaarAlgemeen(product);
  const verzekerden = mapInvoerNaarVerzekerden(product, aanvrager1, aanvrager2);
  const premie = mapInvoerNaarPremie(product);

  const data: OverlijdensRisicoInput = {
    id: "", // TODO: Controleren!!
    algemeen,
    verzekerden,
    premie
  };

  const producten: number[] = [0];

  return { data, producten };
}

export function mapOverlijdensRisicoOutput(json: OverlijdensRisicoOutput): number | null {
  if (json.isValid && json.resultaten && json.resultaten.length) {
    const resultaat = json.resultaten[0];

    if (resultaat.isValid && resultaat.premies && resultaat.premies.length) {
      const premie = resultaat.premies[0];
      return premie.bedrag || null;
    }
  }

  return null;
}
