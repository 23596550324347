import { DraftSideEffects } from "../../shared/types";
import { ProductDetailState, ProductenState } from "./producten-overzicht-types";

import { verpandingSideEffects } from "../verpanding/determine-verpanding-side-effects";
import { mapJaarMaandInputDl2Ui } from "../../shared/generic-parts/jaar-maand/map-dl-2-ui";
import { LocalDate } from "@js-joda/core";
import { RenteAftrek } from "../../hypotheek/infra/hypotheek-types";
import { fiscaleGegevensSideEffects } from "./determine-fiscale-gegevens-side-effects";
import { createISWSideEffect, initISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";
import { getDifferenceYearsMonths, addMonths, addYears } from "../../shared/utils/dates";
import { GeslachtOpties } from "../../.generated/forms/formstypes";

export function getRenteaftrekAanvangstdatum(renteaftrekken: RenteAftrek[] | null): LocalDate | null {
  if (renteaftrekken && renteaftrekken.length > 0) {
    const renteAftrek = renteaftrekken
      .map(function(e) {
        return e.aanvangsdatum;
      })
      .sort();
    return renteAftrek.length > 0 ? renteAftrek[0] : null;
  }
  return null;
}

export function getRenteaftrekEinddatum(renteaftrekken: RenteAftrek[] | null): LocalDate | null {
  if (renteaftrekken && renteaftrekken.length > 0) {
    const renteAftrek = renteaftrekken
      .map(function(e) {
        return e.einddatum;
      })
      .sort();
    return renteAftrek.length > 0 ? renteAftrek[0] : null;
  }
  return null;
}

const productenOverzichtSideEffects = createISWSideEffect<ProductDetailState>(({ draft, prev }): void => {
  // einddatum gewijzigd, looptijd opnieuw bepalen of aanmaken
  if (
    draft.product.ingangsdatum &&
    draft.product.einddatum &&
    (!prev || !draft.product.einddatum.equals(prev.product.einddatum))
  ) {
    const diff = getDifferenceYearsMonths(draft.product.ingangsdatum, draft.product.einddatum);

    if (!draft.product.looptijd) {
      draft.product.looptijd = mapJaarMaandInputDl2Ui(diff.year, diff.month);
    } else {
      draft.product.looptijd.jaren = diff.year;
      draft.product.looptijd.maanden = diff.month;
    }
  }

  // looptijd of ingangsdatum gewijzigd -> einddatum berekenen.
  if (
    draft.product.ingangsdatum &&
    draft.product.looptijd &&
    (!prev ||
      !draft.product.ingangsdatum.equals(prev.product.ingangsdatum) ||
      draft.product.looptijd.jaren !== (prev.product.looptijd ? prev.product.looptijd.jaren : null) ||
      draft.product.looptijd.maanden !== (prev.product.looptijd ? prev.product.looptijd.maanden : null))
  ) {
    const jaren = draft.product.looptijd.jaren || 0;
    const maanden = draft.product.looptijd.maanden || 0;

    if (maanden > 11) return;
    const newEinddatum = addMonths(addYears(draft.product.ingangsdatum, jaren), maanden);
    if (!newEinddatum.equals(draft.product.einddatum)) {
      draft.product.einddatum = newEinddatum;
    }
  }

  // premiebetaling einddatum gewijzigd, premiebetaling looptijd opnieuw bepalen of aanmaken
  if (
    draft.product.ingangsdatum &&
    draft.premieGegevens.einddatumPremieBetaling &&
    (!prev || draft.premieGegevens.einddatumPremieBetaling !== prev.premieGegevens.einddatumPremieBetaling)
  ) {
    const diff = getDifferenceYearsMonths(draft.product.ingangsdatum, draft.premieGegevens.einddatumPremieBetaling);

    if (!draft.premieGegevens.looptijd) {
      draft.premieGegevens.looptijd = mapJaarMaandInputDl2Ui(diff.year, diff.month);
    } else {
      draft.premieGegevens.looptijd.jaren = diff.year;
      draft.premieGegevens.looptijd.maanden = diff.month;
    }
  }

  // premiebetaling looptijd of ingangsdatum gewijzigd -> premiebetaling einddatum berekenen.
  if (
    draft.product.ingangsdatum &&
    draft.premieGegevens.looptijd &&
    (!prev ||
      !draft.product.ingangsdatum.equals(prev.product.ingangsdatum) ||
      draft.premieGegevens.looptijd.jaren !==
        (prev.premieGegevens.looptijd ? prev.premieGegevens.looptijd.jaren : null) ||
      draft.premieGegevens.looptijd.maanden !==
        (prev.premieGegevens.looptijd ? prev.premieGegevens.looptijd.maanden : null))
  ) {
    const jaren = draft.premieGegevens.looptijd.jaren || 0;
    const maanden = draft.premieGegevens.looptijd.maanden || 0;

    if (maanden > 11) return;
    const newEinddatum = addMonths(addYears(draft.product.ingangsdatum, jaren), maanden);
    if (!newEinddatum.equals(draft.premieGegevens.einddatumPremieBetaling)) {
      draft.premieGegevens.einddatumPremieBetaling = newEinddatum;
    }
  }

  // hoog/laag einddatum gewijzigd, hoog/laag looptijd opnieuw bepalen of aanmaken
  if (
    draft.product.ingangsdatum &&
    draft.premieGegevens.hoogLaagEinddatum &&
    (!prev || !draft.premieGegevens.hoogLaagEinddatum.equals(prev.premieGegevens.hoogLaagEinddatum))
  ) {
    const diff = getDifferenceYearsMonths(draft.product.ingangsdatum, draft.premieGegevens.hoogLaagEinddatum);

    if (!draft.premieGegevens.hoogLaagLooptijd) {
      draft.premieGegevens.hoogLaagLooptijd = mapJaarMaandInputDl2Ui(diff.year, diff.month);
    } else {
      draft.premieGegevens.hoogLaagLooptijd.jaren = diff.year;
      draft.premieGegevens.hoogLaagLooptijd.maanden = diff.month;
    }
  }

  // hoog/laag looptijd of ingangsdatum gewijzigd -> hoog/laag einddatum berekenen.
  if (
    draft.product.ingangsdatum &&
    draft.premieGegevens.hoogLaagLooptijd &&
    (!prev ||
      !draft.product.ingangsdatum.equals(prev.product.ingangsdatum) ||
      draft.premieGegevens.hoogLaagLooptijd.jaren !==
        (prev.premieGegevens.hoogLaagLooptijd ? prev.premieGegevens.hoogLaagLooptijd.jaren : null) ||
      draft.premieGegevens.hoogLaagLooptijd.maanden !==
        (prev.premieGegevens.hoogLaagLooptijd ? prev.premieGegevens.hoogLaagLooptijd.maanden : null))
  ) {
    const jaren = draft.premieGegevens.hoogLaagLooptijd.jaren || 0;
    const maanden = draft.premieGegevens.hoogLaagLooptijd.maanden || 0;

    if (maanden > 11) return;
    const newEinddatum = addMonths(addYears(draft.product.ingangsdatum, jaren), maanden);
    if (!newEinddatum.equals(draft.premieGegevens.hoogLaagEinddatum)) {
      draft.premieGegevens.hoogLaagEinddatum = newEinddatum;
    }
  }
});

const meerdereAanvragersSideEffects: DraftSideEffects<ProductenState> = (curr, prev): void => {
  if (curr.meerdereAanvragersCheck !== prev.meerdereAanvragersCheck && curr.aanvrager1 && curr.aanvrager2) {
    curr.aanvrager1 = {
      klantId: "123",
      achternaam: "Duck",
      voorletters: "D",
      voornamen: "",
      voorvoegsel: "",
      geboortedatum: LocalDate.of(2000, 1, 1),
      roker: false,
      geslacht: GeslachtOpties.Man,
      aowdatum: null
    };
    curr.aanvrager2 = null;
    return;
  }

  if (curr.meerdereAanvragersCheck !== prev.meerdereAanvragersCheck && curr.aanvrager1 && !curr.aanvrager2) {
    curr.aanvrager1 = {
      klantId: "123",
      achternaam: "Duck",
      voorletters: "D",
      voornamen: "",
      voorvoegsel: "",
      geboortedatum: LocalDate.of(2000, 1, 1),
      roker: false,
      geslacht: GeslachtOpties.Man,
      aowdatum: null
    };
    curr.aanvrager2 = {
      klantId: "321",
      achternaam: "Duck",
      voorletters: "K",
      voornamen: "",
      voorvoegsel: "",
      geboortedatum: LocalDate.of(2002, 2, 28),
      roker: false,
      geslacht: GeslachtOpties.Vrouw,
      aowdatum: null
    };

    return;
  }
};

const productenOverzichtDraftSideEffects = createISWSideEffect<ProductenState>(({ draft, prev, subset }): void => {
  if (prev.producten.length > draft.producten.length) {
    return;
  }

  for (let i = 0; i < draft.producten.length; i++) {
    if (prev.producten[i]) {
      const selected = subset.producten[i].create();

      productenOverzichtSideEffects(selected);
      verpandingSideEffects(selected);
      fiscaleGegevensSideEffects(
        selected.subset.fiscalegegevens.createWithContext({
          leningBedrag: 10000,
          leningBedragGewijzigd: false,
          eigenwoningSchuld: 50000,
          ingangsdatum: selected.draft.product.ingangsdatum
        })
      );
    }
  }

  meerdereAanvragersSideEffects(draft, prev);
});

export const determineProductenOverzichtSideEffects = initISWSideEffect<ProductenState>(
  productenOverzichtDraftSideEffects
)();
