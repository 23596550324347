import { Leningdeel, MaximaleHypotheekUitkomst, UitslagOptions } from "../../../../.generated/forms/formstypes";
import { GeldverstrekkerPartij } from "../../../../.generated/instellingen-forms/instellingen-formstypes";
import { jaarMaandNaarMaandenNumber } from "../../../../shared/generic-parts/jaar-maand/helpers";
import { productenFilter } from "../../../../shared/utils/instellingen-filters";
import {
  BerekenMaximaleHypotheekInput,
  mapLeningdeelVorm,
  MappedBerekenMaximaleHypotheekResponse,
  mapToetsrenteBerekenenObv,
  MaximaleHypotheekState,
  ToetsrenteBerekeningObv as TypesToetsrenteBerekeningObv
} from "../../../infra/maximale-hypotheek-schema";

export const mapFormikValues = (values: MaximaleHypotheekState): BerekenMaximaleHypotheekInput => {
  const { inkomen, toetsrente, uitgangspunten, leningdelenUitHetVerleden } = values;
  return {
    aanvrager1: {
      pensioeninkomenOvernemen: inkomen.pensioeninkomenAanvrager.berekenen === false ? false : true,
      toetsinkomenOvernemen: inkomen.toetsinkomenAanvrager.berekenen === false ? false : true,
      aowLeeftijdInMaanden: jaarMaandNaarMaandenNumber(inkomen.aowleeftijdAanvrager),
      pensioeninkomenBedrag:
        inkomen.pensioeninkomenAanvrager.berekenen === false ? inkomen.pensioeninkomenAanvrager.bedrag : null,
      toetsinkomenBedrag:
        inkomen.toetsinkomenAanvrager.berekenen === false ? inkomen.toetsinkomenAanvrager.bedrag : null
    },
    aanvrager2: {
      pensioeninkomenOvernemen: inkomen.pensioeninkomenPartner.berekenen === false ? false : true,
      toetsinkomenOvernemen: inkomen.toetsinkomenPartner.berekenen === false ? false : true,
      aowLeeftijdInMaanden: jaarMaandNaarMaandenNumber(inkomen.aowleeftijdPartner),
      pensioeninkomenBedrag:
        inkomen.pensioeninkomenPartner.berekenen === false ? inkomen.pensioeninkomenPartner.bedrag : null,
      toetsinkomenBedrag: inkomen.toetsinkomenPartner.berekenen === false ? inkomen.toetsinkomenPartner.bedrag : null
    },
    gewensteRentevastperiodeInMaanden:
      toetsrente.berekeningOpBasisVan === TypesToetsrenteBerekeningObv.GewensteRentevastperiode &&
      toetsrente.gewensteRentevastperiode
        ? toetsrente.gewensteRentevastperiode * 12
        : null,
    kredietToetslastOvernemen: toetsrente.kredietToetslast.berekenen === true ? true : false,
    hypotheekLooptijdInMaanden: uitgangspunten.looptijd ? uitgangspunten.looptijd * 12 : null,
    kredietToetslastBedrag: toetsrente.kredietToetslast.berekenen ? null : toetsrente.kredietToetslast.bedrag,
    leningdelenUitVerleden: leningdelenUitHetVerleden.meeTeNemenLeningdelen.map(
      (ld): Leningdeel => ({
        box3Bedrag: ld.deelBox3,
        aflossingsvorm: mapLeningdeelVorm[ld.hypotheekvorm],
        leningdeelBedrag: ld.hypotheekbedrag,
        restantLooptijdInMaanden: ld.looptijd ? (ld.looptijd.jaren || 0) * 12 + (ld.looptijd.maanden || 0) : null,
        opgebouwdeWaardeBedrag: ld.opgebouwdeWaarde,
        huidigLeningdeelId: null,
        leningdeelId: ld.leningdeelId
      })
    ),
    leningdelenUitVerledenMeenemen: !!leningdelenUitHetVerleden.leningdelenMeenemen,
    specificatieToetsrentePercentage:
      toetsrente.berekeningOpBasisVan === TypesToetsrenteBerekeningObv.SpecificatieToetsrente && toetsrente.toetsrente
        ? toetsrente.toetsrente
        : null,
    toetsrenteBerekeningOpBasisVan: toetsrente.berekeningOpBasisVan
      ? mapToetsrenteBerekenenObv[toetsrente.berekeningOpBasisVan as TypesToetsrenteBerekeningObv]
      : null,
    vrijVermogenBedrag: toetsrente.vrijVermogen
  };
};

//deze?
export const mapMaximaleHypotheekResult = (
  apiRes: MaximaleHypotheekUitkomst[] | null,
  beschikbareGeldverstrekkersInstellingen: GeldverstrekkerPartij[],
  looptijd?: number
): MappedBerekenMaximaleHypotheekResponse[] | null => {
  if (apiRes === null) return null;
  const mappedApiRes = apiRes
    .filter(k => productenFilter(beschikbareGeldverstrekkersInstellingen, k.maatschappijCode ?? "", k.productCode))
    .map(
      (r: MaximaleHypotheekUitkomst): MappedBerekenMaximaleHypotheekResponse => ({
        uitslag: r.uitslag,
        looptijd: looptijd || 0,
        toetsrente: r.toetsrente ?? 0,
        productnaam: r.productnaam ?? "",
        maximaleHypotheek: r.maximaleHypotheek ?? 0,
        maximaleHypotheekOnderpand: r.maximaleHypotheekOnderpand ?? 0,
        inkomstenVerklaring1: r.toetsinkomenVerklaringAanvrager1
          ? `${
              r.toetsinkomenVerklaringAanvrager1.inkomensverklaring
                ? `${r.toetsinkomenVerklaringAanvrager1.inkomensverklaring}, `
                : ""
            }Toetsinkomen \u20AC${(r.toetsinkomenVerklaringAanvrager1.toetsInkomen ?? 0)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`
          : null,
        inkomstenVerklaring2: r.toetsinkomenVerklaringAanvrager2
          ? `${
              r.toetsinkomenVerklaringAanvrager2.inkomensverklaring
                ? `${r.toetsinkomenVerklaringAanvrager2.inkomensverklaring}, `
                : ""
            }Toetsinkomen \u20AC${(r.toetsinkomenVerklaringAanvrager2.toetsInkomen ?? 0)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`
          : null
      })
    )
    .sort((a, b) => {
      if (!!UitslagOptions[a.uitslag as UitslagOptions] && !!UitslagOptions[b.uitslag as UitslagOptions])
        return UitslagOptions[a.uitslag as UitslagOptions] > UitslagOptions[b.uitslag as UitslagOptions] ? 1 : -1;

      return 0;
    })
    .sort((a, b) => {
      if (a.uitslag === b.uitslag) {
        return a.maximaleHypotheek < b.maximaleHypotheek ? 1 : -1;
      }
      return 0;
    });

  return mappedApiRes;
};
