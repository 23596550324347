/* istanbul ignore file */
import { AdviesBoxFormik, LabeledText, LabeledSelectInput, useRequestInit, LoadingSpinner } from "adviesbox-shared";
import { FormikProps } from "formik";
import React, { ReactElement, useContext, useState } from "react";
import { AppDataContext } from "../navigation/appdata-context";
import Modal from "../shared/components/modal/Modal";
import {
  RapportageSamenstellenState,
  RapportageStructuurType
} from "../shared/components/rapportage/infra/rapportage-samenstellen-types";
import { defaultElementen } from "../shared/components/rapportage/infra/rapportage-structuur-types";
import { rapportageStructuurSchema } from "../shared/components/rapportage/rapportage-samenstellen-schema";
import { mapRapportageSamenstellenUiToDl } from "./infra/map-rapportage-samenstellen-ui-to-dl";
import { startRapportageSamenstellen } from "./infra/rapportage-genereren-api";
import { RapportageStructuurAjax } from "./rapportage-structuur-ajax";

type RapportSamenstellenModalProps = {
  data: RapportageSamenstellenState;
} & { closeModal?: () => void };

export const getNewRapportageStructuurFactory = (nextVolgnummer: number): RapportageStructuurType => {
  const newRapportageStructuur = rapportageStructuurSchema.default();
  newRapportageStructuur.naam = "Volledig Financieel Plan";
  newRapportageStructuur.elementen = [...defaultElementen];
  newRapportageStructuur.volgnummer = nextVolgnummer;

  return newRapportageStructuur;
};

export const RapportSamenstellenModal = ({ data, closeModal }: RapportSamenstellenModalProps): ReactElement => {
  const { menuInfo } = useContext(AppDataContext);
  const { settings, requestInit, params } = useRequestInit<{ vestiging: string; adviesdossier: string }>();
  const [generationStarted, showGenerationStarted] = useState(false);
  const [loading, setLoading] = useState(false);
  const body = (values: RapportageSamenstellenState): ReactElement => {
    const rapportStructuur = values.rapportageData ? values.rapportageData[0] : null;

    return (
      <>
        {menuInfo.voorstellen && menuInfo.voorstellen.length > 0 ? (
          <LabeledSelectInput
            caption={"Rapport samenstellen op basis van"}
            name={"rapportSamenstellenOpBasisVan"}
            options={
              rapportStructuur
                ? [{ label: "Huidige situatie", value: "" }].concat(
                  rapportStructuur.voorstellen.map(c => ({
                    label: c.omschrijving || `Voorstel #${c.volgnummer}`,
                    value: c.voorstelId
                  }))
                )
                : []
            }
          />
        ) : (
          <LabeledText caption={"Rapport samenstellen op basis van"} value={"Geen voorstellen beschikbaar"} />
        )}

        <LabeledSelectInput
          caption={"Rapportstructuur"}
          name={"rapportstructuur"}
          options={
            rapportStructuur
              ? rapportStructuur.structuurKeuzes.map((c, i) => {
                return { label: c.naam, value: c.structuurId };
              })
              : []
          }
        />
        {values.rapportstructuur && (
          <>
            <h2>Structuur</h2>
            <RapportageStructuurAjax />
          </>
        )}
      </>
    );
  };

  return (
    <AdviesBoxFormik
      initialValues={data}
      render={({ values }: FormikProps<RapportageSamenstellenState>) => {
        return (
          <>
            {!generationStarted && (
              <Modal
                title="Rapport samenstellen"
                body={loading ? <LoadingSpinner /> : body(values)}
                onSubmitClick={async () => {
                  setLoading(true);
                  const input = mapRapportageSamenstellenUiToDl(values);
                  await startRapportageSamenstellen(
                    settings,
                    requestInit,
                    input,
                    values.rapportSamenstellenOpBasisVan,
                    params.adviesdossier
                  );

                  setLoading(false);
                  showGenerationStarted(true);
                }}
                onCancelClick={closeModal}
                isSubmitting={loading}
                saveButtonTekst={"Rapport samenstellen en downloaden"}
              />
            )}

            {generationStarted && (
              <Modal
                title="Genereren van het document is gestart"
                body={
                  <p>
                    Het document dat je opgevraagd hebt moet nog gegenereerd worden. Het genereren van dit document is
                    gestart en zodra het klaar is om te bekijken krijg je een notificatie.
                  </p>
                }
                onSubmitClick={async () => {
                  closeModal && closeModal();
                }}
                saveButtonTekst={"Sluiten"}
              />
            )}
          </>
        );
      }}
      validationSchema={rapportageStructuurSchema}
    />
  );
};
