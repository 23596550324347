import { initISWSideEffect } from "../../../shared/components/isw-side-effects/create-isw-helpers";
import { syncControleNormSideEffects } from "../../components/controle-norm/infra/controle-norm-sideeffects";
import { DashboardState } from "../../infra/dashboard-types";
import { updateDataOutdated } from "../../infra/scenario-side-effects";
import {
  syncScenarioMinimaalUitgangspuntSideEffects,
  syncScenarioVanSideEffects
} from "../../infra/scenario-van-side-effects";

export const syncScenarioAoModalSideEffects = initISWSideEffect<DashboardState>(({ has, draft, subset }) => {
  syncControleNormSideEffects(subset.arbeidsongeschiktheid.controleNorm.create());
  if (has.arbeidsongeschiktheid.changed) {
    updateDataOutdated(draft, draft.arbeidsongeschiktheid.scenarioVan, has.arbeidsongeschiktheid.scenarioVan);
  }

  syncScenarioVanSideEffects(subset.arbeidsongeschiktheid.scenarioVan.create());
  subset.arbeidsongeschiktheidUitgangspunten !== null &&
    syncScenarioMinimaalUitgangspuntSideEffects(subset.arbeidsongeschiktheid.controleNorm.create());
})();
