import html2canvas from "html2canvas";
import jsPDF from "jspdf";
/* istanbul ignore file */

export const numberWithDots = (number: number): string => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

export const getCurrentDateFormatted = (): string => {
  const now = new Date();
  return `${now.getDate()}-${now.getMonth() + 1}-${now.getFullYear()}`;
};

export const printPDF = async (amountOfpages: number, pdfName: string): Promise<void> => {
  const pdf = new jsPDF("p", "mm", [303, 406]);
  for (let i = 1; i <= amountOfpages; i++) {
    const element = document.getElementById(`print-container-${i}`);

    if (element) {
      const canvas = await html2canvas(element, {
        scale: 1.4,
        scrollX: window.pageXOffset * -1,
        scrollY: window.pageYOffset * -1
      });
      const imgData = canvas.toDataURL("image/png", 1.0);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      pdf.addImage(imgData, "png", 0, 0, pdfWidth, pdfHeight, "", "MEDIUM");
      pdf.addPage();
    }
  }

  pdf.deletePage(amountOfpages + 1);
  pdf.save(`${pdfName}.pdf`);
};
