import React, { ReactElement } from "react";
import { FormikProps } from "formik";
import { Column } from "react-table-6";
import { Financieringsoort } from "../../.generated/forms/formstypes";
import { ISWSideEffects } from "../../shared/components/isw-side-effects/isw-side-effects";
import { determineVerbouwingVerbeteringSideEffects } from "./verbouwing-verbetering-modal-side-effects";
import { CurrencyInput, LabeledCurrencyInput } from "adviesbox-shared";
import AdviesBoxFormik from "../../shared/utils/adviesbox-formik";
import Modal from "../../shared/components/modal/Modal";
import { VerbouwingVerbeteringModal as VerbouwingVerbeteringModalState } from "../infra/financieringsbehoefte-types";
import { getFinancieringsbehoefteTextResources } from "../infra/financieringsbehoefte-resources";
import {
  bepaalAdviesTitel,
  verbouwingVerbeteringModalSchema,
  VerbouwingVerbeteringModalSchemaContextType
} from "../infra/financieringsbehoefte-schema";
import { DataGrid } from "adviesbox-shared";
import classes from "./verbouwing-verbetering-modal.module.scss";
import LabeledHdnKeuzelijst from "../../shared/components/hdn-keuzelijst/LabeledHdnKeuzelijst";

export type VerbouwingVerbeteringModalData = {
  soortFinanciering: Financieringsoort;
  data: VerbouwingVerbeteringModalState;
};

type VerbouwingVerbeteringModalProps = {
  onSave?: (data: VerbouwingVerbeteringModalState) => void;
  closeModal?: () => void;
};

const verbouwingVerbeteringSpecificatiesColumns: Column[] = [
  {
    Header: "Specificatie werkzaamheden",
    Footer: "Totaal kosten gespecificeerde werkzaamheden",
    accessor: "werkzaamheden",
    Cell: "TextInput"
  },
  {
    Header: "Bedrag",
    Footer: <CurrencyInput name="totaal" readOnly fieldSize="grid-cell" />,
    accessor: "werkzaamhedenBedrag",
    Cell: "CurrencyInput",
    width: 100
  }
];

const VerbouwingVerbeteringModal = ({
  soortFinanciering,
  data,
  onSave,
  closeModal
}: VerbouwingVerbeteringModalData & VerbouwingVerbeteringModalProps): ReactElement => {
  const body = (values: VerbouwingVerbeteringModalState): ReactElement => (
    <>
      <div>
        <LabeledCurrencyInput
          caption={
            soortFinanciering === Financieringsoort.AankoopNieuwbouw
              ? "Totaal meerwerk"
              : "Totaal verbouwing/verbetering"
          }
          name="totaalVerbouwingVerbetering"
        />
        <LabeledCurrencyInput
          caption={
            <>
              Waarvan&nbsp;<b>niet</b>&nbsp;waardevermeerderend
            </>
          }
          name="waarvanNietWaardevermeerderend"
        />
        <LabeledCurrencyInput
          caption="Waarvan energiebespaarbudget"
          name="waarvanEnergiebesparendeBudget"
          tooltip={getFinancieringsbehoefteTextResources("TooltipWaavanEnergieBesparendeBudget")}
        />
        <div className="form-row">
          <div className={"col-12"}>
            <a
              href={
                "https://www.nhg.nl/rekenhulp?utm_source=adviesbox&utm_medium=website&utm_campaign=nhg-groen&utm_term=websiteadviesbox&utm_content=nhgrekenhulp"
              }
              className={`link ${classes.nhg}`}
              target="_blank"
              rel="noopener noreferrer"
              id={`nhg-berekenen`}
            >
              <span className="ml-3">Bereken NHG Energiebespaarbudget</span>
              <i className="icon externallink" />
            </a>
          </div>
        </div>
        <LabeledCurrencyInput
          caption="Waarvan energiebesparende voorzieningen"
          name="waarvanEnergiebesparendeVoorzieningen"
          tooltip={getFinancieringsbehoefteTextResources("TooltipWaavanEnergieBesparendeVoorzieningen")}
        />
        <LabeledCurrencyInput caption="Waarvan achterstallig onderhoud" name="waarvanAchterstalligOnderhoud" />

        <LabeledHdnKeuzelijst
          caption="Toekomstig energielabel"
          name="energieklasseToekomstig"
          keuzelijst="EnergieKlasseType"
          berichtSoortType="AX"
        />

        {values.verbouwingVerbeteringSpecificaties && (
          <DataGrid
            editable
            columns={verbouwingVerbeteringSpecificatiesColumns}
            name="verbouwingVerbeteringSpecificaties"
          />
        )}
      </div>

      {(values.totaalVerbouwingVerbetering || 0) !== (values.totaal || 0) && (
        <div className="input-rood">
          {getFinancieringsbehoefteTextResources("ErrorTotaalBedragSpecificatieOngelijkVerbouwingVerbetering")
            .toString()
            .replace("{titel}", bepaalAdviesTitel({ soortFinanciering }))}
        </div>
      )}
    </>
  );

  return (
    <AdviesBoxFormik<VerbouwingVerbeteringModalState>
      initialValues={{ ...data }}
      validationSchema={verbouwingVerbeteringModalSchema}
      createValidationContext={(): VerbouwingVerbeteringModalSchemaContextType => ({
        soortFinanciering
      })}
      onSave={onSave}
      closeModal={closeModal}
      render={({ submitForm, values }: FormikProps<VerbouwingVerbeteringModalState>): ReactElement => {
        return (
          <>
            <ISWSideEffects<VerbouwingVerbeteringModalState> sync={determineVerbouwingVerbeteringSideEffects} />
            <Modal
              title={soortFinanciering === Financieringsoort.AankoopNieuwbouw ? "Meerwerk" : "Verbouwing Verbetering"}
              body={body(values)}
              onSubmitClick={submitForm}
              onCancelClick={closeModal}
            />
          </>
        );
      }}
    />
  );
};

export default VerbouwingVerbeteringModal;
