import { IngPriceOutput } from "../../.generated/forms/formstypes";
import { HypothekenState, HypotheekOptiesIngPriceToolType } from "../infra/hypotheek-types";
import { FormikHelpers } from "formik";

export const updateIngModal = (
  res: IngPriceOutput,
  hypotheekOptiesIngPriceTool: HypotheekOptiesIngPriceToolType | null,
  setFieldValue: FormikHelpers<HypothekenState>["setFieldValue"]
): void => {
  if (res.isValid) {
    if (res.hypotheek?.leningdelenResult && res.hypotheek?.leningdelenResult?.length) {
      setFieldValue(`producten[0].hypotheekOptiesIngPriceToolLeningdeel.errorMelding`, null); // resetting the error message

      const leningdelen = res.hypotheek.leningdelenResult;
      leningdelen?.forEach((value, index) => {
        let teBetalenRenteData = 0;

        if (value?.dagrente && hypotheekOptiesIngPriceTool?.dagrente) {
          const v = parseFloat(value.dagrente?.replace(",", ".") ?? "0");
          setFieldValue(`producten[${index}].hypotheekOptiesIngPriceToolLeningdeel.dagrenteData.percentage`, v);
          teBetalenRenteData += v;
        } else {
          setFieldValue(`producten[${index}].hypotheekOptiesIngPriceToolLeningdeel.dagrenteData.percentage`, null);
        }
        if (value?.loyaliteit) {
          const v = parseFloat(value.loyaliteit?.replace(",", ".") ?? "0");
          setFieldValue(`producten[${index}].hypotheekOptiesIngPriceToolLeningdeel.loyaliteitsbonusData.percentage`, v);
          teBetalenRenteData += v;
        } else {
          setFieldValue(
            `producten[${index}].hypotheekOptiesIngPriceToolLeningdeel.loyaliteitsbonusData.percentage`,
            null
          );
        }

        if (value?.betaalrekening && hypotheekOptiesIngPriceTool?.actieveBetaalRekening) {
          const v = parseFloat(value.betaalrekening.replace(",", "."));
          setFieldValue(
            `producten[${index}].hypotheekOptiesIngPriceToolLeningdeel.actieveBetaalrekeningData.percentage`,
            v
          );
          teBetalenRenteData += v;
        } else {
          setFieldValue(
            `producten[${index}].hypotheekOptiesIngPriceToolLeningdeel.actieveBetaalrekeningData.percentage`,
            null
          );
        }

        const ltv = parseFloat(value.ltv?.replace(",", ".") ?? "0");
        setFieldValue(`producten[${index}].hypotheekOptiesIngPriceToolLeningdeel.ltvData.percentage`, ltv);
        teBetalenRenteData += ltv;

        const standaardRente = parseFloat(value.standaardRente?.replace(",", ".") ?? "0");
        setFieldValue(
          `producten[${index}].hypotheekOptiesIngPriceToolLeningdeel.standaardRenteData.percentage`,
          standaardRente
        );
        teBetalenRenteData += standaardRente;

        setFieldValue(
          `producten[${index}].hypotheekOptiesIngPriceToolLeningdeel.teBetalenRenteData.percentage`,
          teBetalenRenteData
        );
        setFieldValue(`producten[${index}].hypotheekOptiesIngPriceToolLeningdeel.optimalisatieMelding`, value?.tooltip);
      });

      if (res.hypotheek.errorMessages && res.hypotheek.errorMessages.length) {
        const errors = res.hypotheek.errorMessages.map(v => v.message + "\n");
        setFieldValue(`producten[0].hypotheekOptiesIngPriceToolLeningdeel.errorMelding`, errors); // only saved in producten[0]
      }
    }
  }
  if (!res.isValid && res.message) {
    setFieldValue(`producten[0].hypotheekOptiesIngPriceToolLeningdeel.errorMelding`, res.message);
  }
};
