import React, { ReactElement, InputHTMLAttributes } from "react";
import HdnKeuzelijst, { HdnKeuzelijstProps } from "./HdnKeuzelijst";
import classnames from "classnames";
import { LabelProps, Label } from "adviesbox-shared";

type LabeledHdnKeuzelijstProps = LabelProps & HdnKeuzelijstProps & { appendLabelChildren?: ReactElement | null };

const LabeledHdnKeuzelijst = ({
  berichtSoortType,
  caption,
  keuzelijst,
  name,
  tooltip,
  appendChildren,
  readonly,
  visible,
  fieldSize,
  emptyValue,
  filter,
  labelColSize = 7,
  onChange,
  hdnData,
  appendLabelChildren,
  ontvangerCode,
  useHdnData
}: LabeledHdnKeuzelijstProps & InputHTMLAttributes<HTMLSelectElement>): ReactElement => {
  const inputColSize = labelColSize < 12 ? 12 - labelColSize : 12;

  return (
    <div
      className={classnames("form-group form-row", {
        invisible: visible === false
      })}
    >
      <div className={`col-${labelColSize}`}>
        <Label caption={caption} name={name} tooltip={tooltip} appendChildren={appendLabelChildren} />
      </div>
      <div className={`col-${inputColSize}`}>
        <HdnKeuzelijst
          berichtSoortType={berichtSoortType}
          keuzelijst={keuzelijst}
          name={name}
          hdnData={hdnData}
          filter={filter}
          appendChildren={appendChildren}
          readonly={readonly}
          fieldSize={fieldSize}
          emptyValue={emptyValue}
          onChange={onChange}
          ontvangerCode={ontvangerCode}
          useHdnData={useHdnData}
        />
      </div>
    </div>
  );
};

export default LabeledHdnKeuzelijst;
