import React, { createContext, ReactElement, ReactNode, useState } from "react";

export enum NewDashboardType {
  Geen = "Geen",
  HypotheekAcceptatie = "Hypotheekacceptatie",
  SnelInzicht = "SnelInzicht",
  Overlijden = "Overlijden",	
  Arbeidsongeschiktheid = "Arbeidsongeschiktheid",
  Werkloosheid = "Werkloosheid",
  Pensioen = "Pensioen"
}

type DashboardDataProviderProps = {
  children: ReactNode;
};

export type DashboardDataContextType = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  type: NewDashboardType;
  setType: React.Dispatch<React.SetStateAction<NewDashboardType>>;
};

export const DashboardDataContextValues = {
  open: false,
  setOpen: () => { },
  type: NewDashboardType.Geen,
  setType: () => { }
}

export const DashboardDataContext = createContext(DashboardDataContextValues as DashboardDataContextType);

export const DashboardDataProvider = ({ children }: DashboardDataProviderProps): ReactElement => {
  const [open, setOpen] = useState<boolean>(false);
  const [type, setType] = useState<NewDashboardType>(NewDashboardType.Geen);
  return (
    <DashboardDataContext.Provider
      value={{
        open,
        setOpen,
        type,
        setType
      }}
    >
      {children}
    </DashboardDataContext.Provider>
  );
};
