import React, { ReactElement } from "react";
import { connect, FormikContextType } from "formik";
import { InkomenEnFiscusState, AOWSyncHeightIndex } from "../infra/inkomen-en-fiscus-schema";
import { LabeledCurrencyInput, LabeledNumberInput, LabeledBerekenDate, SyncMultipleHeightProps, SyncHeight } from "adviesbox-shared";

const Aanvrager1AOW = ({
  targetRef,
  sourceRef
}: SyncMultipleHeightProps & {
  formik: FormikContextType<InkomenEnFiscusState>;
}): ReactElement => {
  return (
    <SyncHeight
      sourceRef={sourceRef && sourceRef[AOWSyncHeightIndex.All]}
      targetRef={targetRef && targetRef[AOWSyncHeightIndex.All]}
    >
      <LabeledCurrencyInput caption="AOW bedrag" name="aanvrager1Aow.bedrag" readonly={true} />
      <SyncHeight
        sourceRef={sourceRef && sourceRef[AOWSyncHeightIndex.Korting]}
        targetRef={targetRef && targetRef[AOWSyncHeightIndex.Korting]}
      >
        <LabeledNumberInput
          caption="Korting AOW"
          name="aanvrager1Aow.korting"
          appendChildren={<div className="p-1">Jaar</div>}
        />
      </SyncHeight>
      <SyncHeight
        sourceRef={sourceRef && sourceRef[AOWSyncHeightIndex.Ingangsdatum]}
        targetRef={targetRef && targetRef[AOWSyncHeightIndex.Ingangsdatum]}
      >
        <LabeledBerekenDate caption="Ingangsdatum AOW" name="aanvrager1Aow.ingangsdatum" />
      </SyncHeight>
    </SyncHeight>
  );
};

export default connect<SyncMultipleHeightProps, InkomenEnFiscusState>(Aanvrager1AOW);
