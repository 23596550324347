/* istanbul ignore file */
/**
 * DEZE FILE STAAT NIET ONDER TEST. WIJZIGINGEN -ALTIJD- CONTROLEREN!
 */

import { useContext, useEffect } from "react";

import { BerekeningenOutput } from "../../.generated/instellingen-forms/instellingen-formstypes";
import useAbortableFetch from "use-abortable-fetch";
import { useRequestInit, ForceRerenderContext } from "adviesbox-shared";

export type UseInstellingenDataResult<T> = {
  loading: boolean;
  error: Error | null;
  data: T | null;
};

export function useInstellingenBerekeningenData(): UseInstellingenDataResult<BerekeningenOutput> {
  const {
    requestInit,
    settings: { instellingenFormsOrigin }
  } = useRequestInit();
  const loadingDone = useContext(ForceRerenderContext);

  const url = `${instellingenFormsOrigin}/Berekeningen`;
  const { loading, error, data } = useAbortableFetch<any>(url, requestInit);

  useEffect((): void => {
    if (!loading && !!data && loadingDone) {
      loadingDone();
    }
  }, [loading, data, loadingDone]);

  if (typeof data === "string") {
    return { error: new Error("Fout bij het laden van instellingen-berekeningen data"), data: null, loading: false };
  }

  return { error, data: data ? data : null, loading };
}
