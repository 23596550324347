import { Card } from "adviesbox-shared";
import { LabelValuePairs } from "adviesbox-shared/utils/types";
import { connect, FormikContextType } from "formik";
import React, { ReactElement } from "react";
import { FiscaleVoortzettingOptions } from "../../.generated/forms/formstypes";
import FiscaleRegeling from "../../producten-overzicht/fiscale-regeling/fiscale-regeling";
import { HypothekenKenmerken } from "../../producten-overzicht/infra/product-kenmerken-types";
import { HypothekenState } from "../infra/hypotheek-types";
import PremieGegevens from "./../../producten-overzicht/premie-gegevens/premie-gegevens";
import Contractanten from "./contractanten";
import KapitaalopbouwSpaar from "./kapitaalopbouw-spaar";

type SpaarProductProps = {
  selected: number;
  kenmerken: HypothekenKenmerken;
  berekenState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
};

const SpaarProduct = (
  props: SpaarProductProps & {
    formik: FormikContextType<HypothekenState>;
  }
): ReactElement => {
  const {
    kenmerken,
    selected,
    berekenState,
    formik: { values }
  } = props;
  const productKeuzes = values.fiscaleVoortzettingKeuzes || [];
  const [bereken, setBereken] = berekenState;

  const opties: LabelValuePairs = [
    {
      label: "Geen",
      value: FiscaleVoortzettingOptions.Geen
    },
    ...productKeuzes.map((keuze, index) => ({
      label: keuze.omschrijving || "",
      value: keuze.productId || `${index}`
    })),
    {
      label: "Eigen invoer",
      value: FiscaleVoortzettingOptions.EigenInvoer
    }
  ];

  return (
    <>
      <Card title="Contractanten rekening">
        <Contractanten selected={selected} kenmerken={kenmerken.persoonkeuze} />

        {kenmerken.kapitaalopbouw.kapitaalopbouwTonen && (
          <div className="py-3">
            <h3 className="px-1">Kapitaalopbouw</h3>
            <KapitaalopbouwSpaar selected={selected} kenmerken={kenmerken.kapitaalopbouw} />
          </div>
        )}
        {kenmerken.fiscaleRegeling.fiscaleRegelingTonen && (
          <div>
            <h3 className="px-1">Fiscale regeling</h3>
            <FiscaleRegeling
              fiscaleVoortzettingKeuzes={opties}
              selected={selected}
              kenmerken={kenmerken.fiscaleRegeling}
            />
          </div>
        )}
      </Card>
      {!!values.producten[selected].premieGegevens && kenmerken.premie.premiegegevensTonen && (
        <Card title="Inleggegevens rekening">
          <PremieGegevens
            loading={bereken}
            selected={selected}
            kenmerken={kenmerken.premie}
            validatieKenmerken={kenmerken.validaties}
            isSpaarProduct={true}
            calculate={async () => {
              setBereken(true);
              return null;
            }}
            showPremieBerekenenBtn={true}
          />
        </Card>
      )}
    </>
  );
};

SpaarProduct.displayName = "SpaarProduct";

export default connect<SpaarProductProps, HypothekenState>(SpaarProduct);
