import React, { ReactElement, useCallback } from "react";
import { connect, FormikContextType } from "formik";
import { InkomenEnFiscusState } from "../infra/inkomen-en-fiscus-schema";
import { getInkomenEnFiscusTextResources } from "../infra/inkomen-en-fiscus-resources";
import FiscaleBijtelpostenModal from "../fiscale-bijtelposten-modal/fiscale-bijtelposten-modal";
import FiscaleAftrekpostenModal from "../fiscale-aftrekposten-modal/fiscale-aftrekposten-modal";
import { bindSaveFunction } from "../../shared/utils/bind-save-function";
import { CurrencyInput, LabeledCurrencyInput, LabeledToggleInput, Icon, LabeledBevestigingInput, ModalButton, SyncHeightProps, SyncHeight } from "adviesbox-shared";

const Aanvrager2Fiscaliteit = ({
  formik: {
    setFieldValue,
    values: { aanvrager2Fiscaliteit, aanvrager2Inkomen }
  },
  targetRef: syncHeightRef,
  sourceRef: assignRef
}: SyncHeightProps & {
  formik: FormikContextType<InkomenEnFiscusState>;
}): ReactElement => {
  const createSaveFunction = useCallback(bindSaveFunction(setFieldValue), [setFieldValue]);
  const hasOnderneming = aanvrager2Inkomen.ondernemingIsEnabled;
  return (
    <SyncHeight targetRef={syncHeightRef} sourceRef={assignRef}>
      <LabeledToggleInput
        caption="Fiscale aftrekposten"
        name="aanvrager2Fiscaliteit.toggleFiscaleAftrekposten"
        appendChildren={
          <CurrencyInput
            name="aanvrager2Fiscaliteit.fiscaleAftrekposten.teBetalenAlimentatie"
            readonly={true}
            placeholder="-"
            appendChildren={
              <ModalButton
                parent="aanvrager2Fiscaliteit.fiscaleAftrekposten"
                content={<Icon name="specificatie" alt="Fiscale aftrekposten" />}
              >
                <FiscaleAftrekpostenModal
                  data={aanvrager2Fiscaliteit.fiscaleAftrekposten}
                  onSave={createSaveFunction("aanvrager2Fiscaliteit.fiscaleAftrekposten")}
                />
              </ModalButton>
            }
          />
        }
      />

      <LabeledToggleInput
        caption="Fiscale bijtelposten"
        name="aanvrager2Fiscaliteit.toggleFiscaleBijtelposten"
        appendChildren={
          <CurrencyInput
            name="aanvrager2Fiscaliteit.fiscaleBijtelposten.bijtellingOpInkomen"
            readonly={true}
            placeholder="-"
            appendChildren={
              <ModalButton
                parent="aanvrager2Fiscaliteit.fiscaleBijtelposten"
                content={<Icon name="specificatie" alt="Fiscale bijtelposten" />}
              >
                <FiscaleBijtelpostenModal
                  data={aanvrager2Fiscaliteit.fiscaleBijtelposten}
                  onSave={createSaveFunction("aanvrager2Fiscaliteit.fiscaleBijtelposten")}
                />
              </ModalButton>
            }
          />
        }
      />
      <LabeledCurrencyInput
        allowNull={false}
        caption="Beleggingskorting"
        name="aanvrager2Fiscaliteit.beleggingskorting"
        tooltip={getInkomenEnFiscusTextResources("TooltipFiscaliteitBeleggingsKorting")}
      />

      {hasOnderneming && (
        <>
          <h3 className="pt-3">Ondernemersfaciliteiten</h3>
          <LabeledBevestigingInput caption="Zelfstandigenaftrek" name="aanvrager2Fiscaliteit.zelfstandigenaftrek" />
          <LabeledBevestigingInput caption="Meewerkaftrek" name="aanvrager2Fiscaliteit.meewerkaftrek" />
          <LabeledBevestigingInput caption="Mkb winstvrijstelling" name="aanvrager2Fiscaliteit.mkbWinstvrijstelling" />
        </>
      )}
    </SyncHeight>
  );
};

export default connect<SyncHeightProps, InkomenEnFiscusState>(Aanvrager2Fiscaliteit);
