import React, { ReactElement } from "react";
import { connect, FormikContextType } from "formik";

import { LabeledDateInput } from "adviesbox-shared";
import { AanvraagLeningState } from "../infra/aanvraag-lening-schema";

const Erfpacht = ({
  formik: {
    values: { erfpacht }
  }
}: {
  formik: FormikContextType<AanvraagLeningState>;
}): ReactElement => {
  return (
    <>
      <LabeledDateInput caption="Ingangsdatum" name="erfpacht.ingangsdatum" />
    </>
  );
};

export default connect<{}, AanvraagLeningState>(Erfpacht);
