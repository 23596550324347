import { createISWSideEffect, initISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";
import { AdresType } from "../../shared/types";
import { Geslacht, PersonaliaState } from "./personalia-schema";

export const personaliaSideEffects = createISWSideEffect<PersonaliaState>(({ has, draft, init }): void => {
  const useAanvrager1Adres = (): void => {
    draft.aanvrager2Adres.adres = {
      huisnummer: draft.aanvrager1Adres.adres.huisnummer,
      landCode: draft.aanvrager1Adres.adres.landCode,
      locatie: draft.aanvrager1Adres.adres.locatie,
      plaats: draft.aanvrager1Adres.adres.plaats,
      postcode: draft.aanvrager1Adres.adres.postcode,
      straat: draft.aanvrager1Adres.adres.straat,
      toevoeging: draft.aanvrager1Adres.adres.toevoeging
    };
  };

  if (has.medeAanvragerOpties.medeAanvrager.changed && draft.medeAanvragerOpties.medeAanvrager) {
    useAanvrager1Adres();
    draft.aanvrager2.hasTweedeAanvrager = true;

    if (draft.aanvrager2Extra) {
      draft.aanvrager2Extra.hasTweedeAanvrager = true;
      if (draft.aanvrager1Extra.geslacht === Geslacht.Man) {
        draft.aanvrager2Extra.geslacht = Geslacht.Vrouw;
      } else {
        draft.aanvrager2Extra.geslacht = Geslacht.Man;
      }
    }
  }

  if (has.medeAanvragerOpties.medeAanvrager.changed && !draft.medeAanvragerOpties.medeAanvrager) {
    if (draft.aanvrager2Extra) {
      draft.aanvrager2Extra.hasTweedeAanvrager = false;
    }
    draft.aanvrager2.hasTweedeAanvrager = false;
  }

  if (has.medeAanvragerOpties.afwijkendAdres.changed && draft.medeAanvragerOpties.medeAanvrager) {
    if (draft.medeAanvragerOpties.afwijkendAdres) {
      draft.aanvrager2Adres.adres = {
        huisnummer: null,
        landCode: "NL",
        locatie: "",
        plaats: "",
        postcode: "",
        straat: "",
        toevoeging: ""
      };
    } else {
      useAanvrager1Adres();
    }
  }

  if (has.aanvrager1Adres.adres.changed && !draft.medeAanvragerOpties.afwijkendAdres) {
    useAanvrager1Adres();
  }

  if (
    (has.aanvrager1.achternaam.changed &&
      (!draft.aanvrager1.geboortenaam || draft.aanvrager1.achternaam.startsWith(draft.aanvrager1.geboortenaam, 0))) ||
    (!has.aanvrager1.geboortenaam.changed && draft.aanvrager1.geboortenaam.startsWith(draft.aanvrager1.achternaam, 0))
  ) {
    draft.aanvrager1.geboortenaam = draft.aanvrager1.achternaam;
  }
  if (
    (has.aanvrager2.achternaam.changed &&
      (!draft.aanvrager2.geboortenaam || draft.aanvrager2.achternaam.startsWith(draft.aanvrager2.geboortenaam, 0))) ||
    (!has.aanvrager2.geboortenaam.changed && draft.aanvrager2.geboortenaam.startsWith(draft.aanvrager2.achternaam, 0))
  ) {
    draft.aanvrager2.geboortenaam = draft.aanvrager2.achternaam;
  }

  if (
    (has.aanvrager1.tussenvoegselAchternaam.changed &&
      (!draft.aanvrager1.tussenvoegselGeboortenaam ||
        draft.aanvrager1.tussenvoegselAchternaam.startsWith(draft.aanvrager1.tussenvoegselGeboortenaam, 0))) ||
    (!has.aanvrager1.tussenvoegselGeboortenaam.changed &&
      draft.aanvrager1.tussenvoegselGeboortenaam.startsWith(draft.aanvrager1.tussenvoegselAchternaam, 0))
  ) {
    draft.aanvrager1.tussenvoegselGeboortenaam = draft.aanvrager1.tussenvoegselAchternaam;
  }

  if (
    (has.aanvrager2.tussenvoegselAchternaam.changed &&
      (!draft.aanvrager2.tussenvoegselGeboortenaam ||
        draft.aanvrager2.tussenvoegselAchternaam.startsWith(draft.aanvrager2.tussenvoegselGeboortenaam, 0))) ||
    (!has.aanvrager2.tussenvoegselGeboortenaam.changed &&
      draft.aanvrager2.tussenvoegselGeboortenaam.startsWith(draft.aanvrager2.tussenvoegselAchternaam, 0))
  ) {
    draft.aanvrager2.tussenvoegselGeboortenaam = draft.aanvrager2.tussenvoegselAchternaam;
  }
  if (has.medeAanvragerOpties.medeAanvrager.changed && draft.medeAanvragerOpties.medeAanvrager) {
    draft.aanvrager2BurgerlijkeStatus.burgerlijkeStaat = draft.aanvrager1BurgerlijkeStatus.burgerlijkeStaat;
  }
});

const syncAutofillEffect = createISWSideEffect<AdresType>(bag => {
  const { draft } = bag;

  const splitRe = /([\S][^\d]+)\s([0-9]{1,5})\s*([a-zA-Z]*)/;
  const splitStraatInput = draft.straat.match(splitRe);

  if (splitStraatInput?.length) {
    if (splitStraatInput.length >= 2) {
      draft.huisnummer = parseInt(splitStraatInput[2]);
    }
    if (splitStraatInput.length >= 1) {
      draft.straat = splitStraatInput[1];
    }
  }

  const splitToevoegingInput = draft.toevoeging.match(splitRe);
  if (splitToevoegingInput && splitToevoegingInput[3]) {
    draft.toevoeging = splitToevoegingInput[3];
  } else if (splitToevoegingInput && splitToevoegingInput?.length >= 2) {
    draft.toevoeging = "";
  }
});

export const initPersonaliaSideEffects = initISWSideEffect<PersonaliaState>(({ has, subset, init, draft }) => {
  personaliaSideEffects(subset.create());

  if (
    has.medeAanvragerOpties.medeAanvrager.changed &&
    draft.initWaarschuwingen.medeAanvrager !== draft.medeAanvragerOpties.medeAanvrager &&
    draft.initWaarschuwingen.medeAanvrager
  ) {
    draft.preventSave = true;
  }

  if (
    has.aanvrager1Extra.changed &&
    (init.aanvrager1Extra.geboortedatum !== draft.initWaarschuwingen.aanvrager1GeboorteDatum ||
      init.aanvrager1Extra.geslacht !== draft.initWaarschuwingen.aanvrager1Geslacht ||
      init.aanvrager1Extra.roker !== draft.initWaarschuwingen.aanvrager1roker)
  ) {
    draft.preventSave = true;
  }

  if (
    has.aanvrager2Extra.changed &&
    !!draft.initWaarschuwingen.medeAanvrager &&
    (draft.initWaarschuwingen.aanvrager2roker !== draft.aanvrager2Extra?.roker ||
      draft.initWaarschuwingen.aanvrager2GeboorteDatum !== draft.aanvrager2Extra?.geboortedatum ||
      draft.initWaarschuwingen.aanvrager2Geslacht !== draft.aanvrager2Extra?.geslacht)
  ) {
    draft.preventSave = true;
  }

  if (
    draft.initWaarschuwingen.medeAanvrager === draft.medeAanvragerOpties.medeAanvrager &&
    draft.initWaarschuwingen.aanvrager1roker === draft.aanvrager1Extra.roker &&
    draft.initWaarschuwingen.aanvrager1Geslacht === draft.aanvrager1Extra.geslacht &&
    draft.initWaarschuwingen.aanvrager1GeboorteDatum?.toString() === draft.aanvrager1Extra?.geboortedatum?.toString() &&
    (!draft.initWaarschuwingen.medeAanvrager ||
      (draft.initWaarschuwingen.aanvrager2roker === draft.aanvrager2Extra?.roker &&
        draft.initWaarschuwingen.aanvrager2Geslacht === draft.aanvrager2Extra?.geslacht &&
        draft.initWaarschuwingen.aanvrager2GeboorteDatum?.toString() ===
          draft.aanvrager2Extra?.geboortedatum?.toString()))
  ) {
    draft.preventSave = false;
    draft.herberekeningskeuze = "1";
  }

  if (has.aanvrager1Adres.adres.straat.changed || has.aanvrager1Adres.adres.toevoeging.changed) {
    syncAutofillEffect(subset.aanvrager1Adres.adres.create());
  }

  if (
    has.medeAanvragerOpties.medeAanvrager &&
    !has.medeAanvragerOpties.afwijkendAdres.changed &&
    (has.aanvrager2Adres.adres.straat.changed || has.aanvrager2Adres.adres.toevoeging.changed)
  ) {
    syncAutofillEffect(subset.aanvrager2Adres.adres.create());
  }
})();
