import { ReactElement } from "react";
import { SituatieSoort } from "../producten-overzicht/infra/producten-overzicht-types";
import { AdviesBoxColumn, createSpanWithId } from "adviesbox-shared";
import { getAanvragersNaam } from "../shared/components/aanvrager/aanvrager-helper";

export const bepaalKredietenKolommen = (situatie: SituatieSoort): AdviesBoxColumn[] => [
  {
    Header: "Krediet",
    Cell: (c): ReactElement =>
      createSpanWithId(
        c.index,
        0,
        situatie === "huidig" ? "Bestaand" : c.original.product.doorlopend ? "Doorlopend" : "Nieuw"
      )
  },
  {
    Header: "Partij",
    Cell: (c): ReactElement =>
      createSpanWithId(c.index, 1, c.original.product.partijNaam, c.original.product.partijNaam)
  },
  {
    Header: "Soort krediet",
    Cell: (c): ReactElement =>
      createSpanWithId(c.index, 2, c.original.product.productNaam, c.original.product.productNaam)
  },
  {
    Header: "Geldnemer(s)",
    Cell: (c): ReactElement =>
      createSpanWithId(
        c.index,
        3,
        getAanvragersNaam(c.original.geldnemers.geldnemers),
        getAanvragersNaam(c.original.geldnemers.geldnemers)
      )
  },
  {
    Header: "Einddatum",
    Cell: "DateInput",
    accessor: "product.einddatum",
    config: { readonly: true }
  },
  {
    Header: "Box 1",
    Cell: "CurrencyInput",
    accessor: "fiscaleGegevens.deelBox1Bedrag",
    config: { readonly: true }
  },
  {
    Header: "Box 3",
    Cell: "CurrencyInput",
    accessor: "fiscaleGegevens.deelBox3Bedrag",
    config: { readonly: true }
  },
  {
    Header: "Rente",
    Cell: "PercentageInput",
    accessor: "leningGegevens.rentePercentage",
    config: { readonly: true, decimalen: 2 }
  },
  {
    Header: "Maandlast",
    Cell: "CurrencyInput",
    accessor: "leningGegevens.maandlast.bedrag",
    config: { readonly: true, decimalen: 2 }
  },
  {
    Cell: "DeleteButton"
  }
];
