import { CheckBox, LabeledCurrencyInput, TextInput } from "adviesbox-shared";
import classNames from "classnames";
import { FormikContextType, getIn, useFormikContext } from "formik";
import React, { ReactElement } from "react";
import { getKlantprofielResources } from "../../klantprofiel-resources";
import {
  CheckboxType,
  KlantprofielAntwoordenType,
  KlantprofielOptieType,
  QuestionSpecCombined,
  QuestionType
} from "../../klantprofiel-schema";

type QuestionToggleCheckboxProps = {
  data: QuestionSpecCombined;
};

const renderAntwoord = (
  data: QuestionSpecCombined,
  antwoord: KlantprofielAntwoordenType,
  index: number,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
): ReactElement => {
  const isTextEnBedrag =
    antwoord.omschrijving?.includes("<vrije invoer> <bedrag>") ||
    data.checkboxAnswers?.find(v => v.index === index)?.type === CheckboxType.textEnbedrag;
  const omschrijving =
    antwoord.omschrijving
      ?.replace("<vrije invoer>", "")
      .replace("<bedrag>", "")
      .trim() ?? "";

  if (isTextEnBedrag) {
    return (
      <CheckBox
        caption={omschrijving}
        key={`key-${index}`}
        name={`vragen[${data.index}].antwoorden[${index}].waarde1`}
        onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
          if (e.target.checked) {
            return setFieldValue(`vragen[${data.index}].antwoorden[${index}].waarde1`, true);
          } else {
            return setFieldValue(`vragen[${data.index}].antwoorden[${index}].waarde1`, null);
          }
        }}
        appendChildren={
          <>
            <TextInput
              name={`vragen[${data.index}].antwoorden[${index}].waarde2`}
              className="ml-2"
              type="text"
              fieldSize="xl"
              />
          </>
        }
      />
    );
  }

  return (
    <CheckBox
      caption={omschrijving}
      key={`key-${index}`}
      name={`vragen[${data.index}].antwoorden[${index}].waarde1`}
      onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
        if (e.target.checked) {
          setFieldValue(`vragen[${data.index}].antwoorden[${index}].waarde1`, true);
        } else {
          //waarde1 en waarde2 naar null setten als hier geen sprake van is
          setFieldValue(`vragen[${data.index}].antwoorden[${index}].waarde1`, null);
          setFieldValue(`vragen[${data.index}].antwoorden[${index}].waarde2`, null);
        }
      }}
    />
  );
};

const renderAntwoordBedragen = (
  data: QuestionSpecCombined,
  antwoord: KlantprofielAntwoordenType,
  index: number,
  values: FormikContextType<KlantprofielOptieType>["values"],
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
): ReactElement => {
  const isTextEnBedrag =
    data.checkboxAnswers?.find(v => v.index === index)?.type === CheckboxType.textEnbedrag ||
    antwoord.omschrijving?.includes("<vrije invoer> <bedrag>");
  const omschrijving =
    antwoord.omschrijving
      ?.replace(", namelijk:", "")
      .replace("<vrije invoer>", "")
      .replace("<bedrag>", "")
      .trim() ?? "";

  if (isTextEnBedrag && getIn(values, `vragen[${data.index}].antwoorden[${index}].waarde1`) === true) {
    return (
      <div className="mt-2" key={`Input-key-${index}`}>
        <LabeledCurrencyInput
          caption={omschrijving}
          data-testid={`labeled-currency-${data.schema.code}-${data.index}`}
          name={`vragen[${data.index}].antwoorden[${index}].waarde3`}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
            if (!e.target.value) {
              return setFieldValue(`vragen[${data.index}].antwoorden[${index}].waarde3`, null);
            }
          }}
        />
      </div>
    );
  }
  if (!isTextEnBedrag && getIn(values, `vragen[${data.index}].antwoorden[${index}].waarde1`) === true) {
    return (
      <div className="mt-2" key={`Input-key-${index}`}>
        <LabeledCurrencyInput
          caption={omschrijving}
          data-testid={`labeled-currency-${data.schema.code}-${data.index}`}
          name={`vragen[${data.index}].antwoorden[${index}].waarde2`}
        />
      </div>
    );
  }

  return <React.Fragment key={`Input-key-${index}`}></React.Fragment>;
};

export const QuestionToggleCheckbox = ({ data }: QuestionToggleCheckboxProps): ReactElement => {
  const { setFieldValue } = useFormikContext();
  const formik = useFormikContext<KlantprofielOptieType>();
  if (data.type !== QuestionType.toggleCheckbox) return <>{`invalid QuestionType: ${data.type}`}</>;
  if (!data.schema.antwoorden?.length) return <>{`missing answers for QuestionType.toggleCheckbox`}</>;
  const showToggelInputTitle = formik.values.vragen[data.index].antwoorden.some(a => a.waarde1);
  const renderedExtra = showToggelInputTitle && data.extra ? data.extra() : null;
  return (
    <>
      <div className={classNames({ "mt-3": data.schema.omschrijving })}>
        {data.schema.omschrijving && <h2>{data.schema.omschrijving}</h2>}
        <span className="d-block font-italic px-1">{getKlantprofielResources("MeerdereAntwoorden")}</span>
        <div className="mx-2 mt-3 mb-3">
          {data.schema.antwoorden.map((antwoord, index) => renderAntwoord(data, antwoord, index, setFieldValue))}
          {renderedExtra}
          {data.schema.antwoorden.map((antwoord, index) =>
            renderAntwoordBedragen(data, antwoord, index, formik.values, setFieldValue)
          )}
        </div>
      </div>
    </>
  );
};
/* istanbul ignore next */
if (process.env.NODE_ENV !== "production") QuestionToggleCheckbox.displayName = "QuestionToggleCheckbox";
