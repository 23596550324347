import { connect, FormikContextType } from "formik";
import React, { ReactElement } from "react";
import LabeledHdnKeuzelijst from "../../shared/components/hdn-keuzelijst/LabeledHdnKeuzelijst";
import { AanvraagLeningState } from "../infra/aanvraag-lening-schema";
import { getNaam } from "../../shared/utils/helpers";

const InventarisatieStudielening = ({
  formik: { values }
}: {
  formik: FormikContextType<AanvraagLeningState>;
}): ReactElement => {
  return (
    <>
      <LabeledHdnKeuzelijst
        caption={getNaam(values?.aanvrager1, "Aanvrager1")}
        name="inventarisatieStudielening.aanvrager1HdnCode"
        berichtSoortType="AX"
        keuzelijst="StudieleningInventarisatieType"
      />

      {values?.aanvrager2 && (
        <LabeledHdnKeuzelijst
          caption={getNaam(values?.aanvrager2, "Aanvrager2")}
          name="inventarisatieStudielening.aanvrager2HdnCode"
          berichtSoortType="AX"
          keuzelijst="StudieleningInventarisatieType"
        />
      )}
    </>
  );
};

export default connect<{}, AanvraagLeningState>(InventarisatieStudielening);
