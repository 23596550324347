import {
  productCodeLevensverzekering,
  productCodeUniversalLifePremie
} from "../../../kapitaalverzekering/infra/kapitaalverzekering-schema";
import { partijOnafhankelijk } from "../product-constanten";
import { KapitaalverzekeringKenmerken } from "../product-kenmerken-types";

// TODO MdB: Verwijderen als Kapitaalverzekering kenmerken beschikbaar zijn.
export const getKapitaalverzekeringKenmerken = (
  maatschappijCode: string,
  productCode: string
): KapitaalverzekeringKenmerken => ({
  product: {
    productnaamTonen: maatschappijCode === partijOnafhankelijk && productCode !== productCodeUniversalLifePremie,
    productnaamEnabled: true,
    ingangsdatumTonen: true,
    einddatumTonen: true,
    einddatumEnabled: true,
    looptijdTonen: true,
    looptijdJaarEnabled: true,
    looptijdMaandEnabled: true,
    uwBemiddelingTonen: true,
    maatschappijkeuzeEnabled: true,
    logoTonen: maatschappijCode !== partijOnafhankelijk,
    productoptiesTonen: false,
    productnummerTonen: true
  },
  verzekeringnemer: { meerdereVerzekeringnemerOptiesMogelijk: true },
  verzekerden: { meerdereVerzekerdeOptiesMogelijk: true, premiesplitsingTonen: true },
  kapitaalopbouw: {
    soortRekeningTonen: false,
    soortLijfrenteTonen: maatschappijCode === partijOnafhankelijk && productCode !== productCodeUniversalLifePremie,
    soortBerekeningTonen: true,
    soortBerekeningEigenInvoerTonen:
      maatschappijCode === partijOnafhankelijk && productCode !== productCodeUniversalLifePremie,
    beleggersprofielTonen: false,
    doelkapitaalBedragTonen: true,
    doelkapitaalBedragEnabled: true,
    doelrendementPercentageTonen: true,
    doelrendementPercentageEnabled: true,
    voorbeeldkapitaalBedragTonen: true,
    voorbeeldkapitaalBedragEnabled:
      maatschappijCode === partijOnafhankelijk && productCode !== productCodeUniversalLifePremie,
    voorbeeldrendementPercentageTonen: true,
    voorbeeldkapitaalPercentageEnabled:
      maatschappijCode === partijOnafhankelijk && productCode !== productCodeUniversalLifePremie,
    garantiekapitaalBedragTonen:
      maatschappijCode === partijOnafhankelijk && productCode !== productCodeUniversalLifePremie,
    garantierendementPercentageTonen:
      maatschappijCode === partijOnafhankelijk && productCode !== productCodeUniversalLifePremie
  },
  premie: {
    aanvangstortingEnabled: true,
    aanvangstortingTonen: true,
    aftrekbarePremieTonen: true,
    berekenPremieKnopTonen: true,
    betalingstermijnEnabled: true,
    betalingstermijnTonen: true,
    componentKenmerk: true,
    duurHoogLaagConstructieTonen: true,
    duurHoogLaagConstructieEnabled: true,
    duurPremiebetalingEnabled: true,
    duurPremiebetalingTonen: true,
    einddatumPremiebetalingEnabled: true,
    einddatumPremiebetalingTonen: true,
    extraStortingTonen: true,
    garantiePremieTonen: true,
    heeftBetalingstermijnHalfJaar: true,
    heeftBetalingstermijnJaar: true,
    heeftBetalingstermijnKoopsom: true,
    heeftBetalingstermijnKwartaal: true,
    heeftBetalingstermijnMaand: true,
    heeftBetalingstermijnTweeMaanden: true,
    heeftPremieverloopGelijkblijvend: true,
    heeftPremieverloopVariabel: true,
    hintAanvangstortingTonen: true,
    hintRisicopremiespecificatieTonen: true,
    hoogLaagConstructieTonen: true,
    hoogLaagVerhoudingTonen: true,
    ingebrachteWaardeTonen: true,
    premiebetalingEnabled: true,
    premiedepotTonen: true,
    premieHoogEnabled: true,
    premiestijgendTonen: true,
    premieverloopTonen: false,
    premiegegevensTonen: true,
    risicopremieEnabled: true,
    risicopremiespecificatieHint: "",
    risicopremiespecificatieTonen: true,
    risicopremieTonen: maatschappijCode === partijOnafhankelijk && productCode !== productCodeUniversalLifePremie,
    soortDekking: true,
    spaarpremieEnabled: maatschappijCode === partijOnafhankelijk && productCode !== productCodeUniversalLifePremie,
    spaarpremieTonen: maatschappijCode === partijOnafhankelijk && productCode !== productCodeUniversalLifePremie,
    totalePremieEnabled: false,
    totalePremieTonen: true,
    verkortePremieduurTonen: false,
    hintPremieAftrekbaar: "",
    hintPremieAftrekbaarTonen: false
  },
  poliswaarde: {
    waardeopbouwBedragTonen: true,
    waardeopbouwBedragEnabled: false,
    reedsOpgebouwdBedragTonen: true,
    reedsOpgebouwdDatumTonen: true,
    afkoopwaardeDatumTonen: maatschappijCode === partijOnafhankelijk && productCode === productCodeLevensverzekering,
    afkoopwaardeBedragTonen: maatschappijCode === partijOnafhankelijk && productCode === productCodeLevensverzekering
  },
  verpanding: {
    bedoeldAflossingTonen: true,
    verpandingTonen: true,
    pandhouderLijstTonen: true,
    lijfrenteUitkeringTonen: true,
    inkomensaanvullingTonen: false,
    belastingBoxTonen: false,
    soortLijfrenteUitkeringTonen: true,
    overgangOpTweedeVerzekerdeTonen: true
  }
});
