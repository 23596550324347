import { jaarMaandInMaanden } from "../../shared/generic-parts/jaar-maand/map-ui-2-dl";
import { ChronoUnit } from "@js-joda/core";
import { werkJaarMaandInputBijInSideEffect } from "../../shared/generic-parts/jaar-maand/helpers";
import { createISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";
import { ProductType } from "../infra/producten-overzicht-types";

export const productDraftSideEffects = createISWSideEffect<ProductType>(({ has, draft }): void => {
  if (!draft) return;
  if (has.einddatum.changed) {
    if (draft.einddatum && draft.ingangsdatum) {
      // Ingangsdatum + Einddatum aanpassen -> Looptijd aanpassen
      const maanden = draft.ingangsdatum.until(draft.einddatum, ChronoUnit.MONTHS);
      werkJaarMaandInputBijInSideEffect(draft.looptijd, maanden, null, null);
      if (maanden <= 0) {
        draft.einddatum = draft.ingangsdatum;
      }
    } else if (draft.ingangsdatum && !draft.einddatum) {
      // Einddatum leegmaken -> Looptijd leeg
      werkJaarMaandInputBijInSideEffect(draft.looptijd, null, null, null);
    }
  }

  if (has.ingangsdatum.changed) {
    if (draft.ingangsdatum) {
      const maanden = jaarMaandInMaanden(draft.looptijd);
      if (typeof maanden === "number" && maanden <= 999 * 12) {
        // Ingangsdatum aanpassen met looptijd -> Einddatum aanpassen
        const einddatum = draft.ingangsdatum.plusMonths(maanden);

        if (!draft.einddatum || !einddatum.isEqual(draft.einddatum)) {
          draft.einddatum = einddatum;
        }
      }
    } else {
      // Ingangsdatum leegmaken = einddatum leegmaken
      draft.einddatum = null;
    }
  }

  if (has.looptijd.changed && draft.ingangsdatum) {
    const maanden = jaarMaandInMaanden(draft.looptijd);
    if (typeof maanden === "number") {
      // Ingangsdatum + Looptijd aanpassen => Einddatum aanpassen
      // Aantal maanden mag niet groter zijn dan 999 jaar = 999*12 anders krijg je een datumfout
      // Is aantal maanden wel groter dan wordt einddatum op null gezet
      let berekendeEinddatum = draft.ingangsdatum;
      if (maanden <= 999 * 12) {
        berekendeEinddatum = draft.ingangsdatum.plusMonths(maanden);
        if (!draft.einddatum || !berekendeEinddatum.isEqual(draft.einddatum)) {
          draft.einddatum = berekendeEinddatum;
        }
      } else {
        draft.einddatum = null;
      }
    }
  }

  if (has.looptijd.changed && typeof draft.looptijd.jaren !== "number" && typeof draft.looptijd.maanden !== "number") {
    // Looptijd leegmaken -> Einddatum leeg
    draft.einddatum = null;
  }
});
