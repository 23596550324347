import React, { ReactElement } from "react";
import { connect, FormikContextType } from "formik";

import { SoortDepotOptions, KapitaalopbouwOptions } from "../../.generated/forms/formstypes";

import { DepotKenmerken } from "../../producten-overzicht/infra/product-kenmerken-types";
import { SituatieSoort } from "../../producten-overzicht/infra/producten-overzicht-types";
import WaardeopbouwJarenModal from "./waardeopbouw-jaren-modal";
import {
  LabeledRadioInput,
  LabeledCurrencyInput,
  LabeledTextInput,
  Icon,
  LabeledDateInput,
  LabeledPercentageInput
} from "adviesbox-shared";
import { ModalButton } from "adviesbox-shared";
import { VermogensType } from "../infra/vermogen-types";

const depotOptions = [
  { label: "Opbouw depot", value: SoortDepotOptions.OpbouwDepot },
  { label: "Opname depot", value: SoortDepotOptions.OpnameDepot },
  { label: "Vrij depot", value: SoortDepotOptions.VrijDepot }
];

type DepotProps = { situatie: SituatieSoort; selected: number; kenmerken: DepotKenmerken };

const Depot = ({
  situatie,
  selected,
  kenmerken,
  formik: {
    values: { producten }
  }
}: DepotProps & {
  formik: FormikContextType<VermogensType>;
}): ReactElement => {
  const {
    depot: { waardeopbouwJaren },
    fiscaleRegeling: { kapitaalopbouw }
  } = producten[selected];

  return (
    <>
      {kenmerken.depotTonen && kapitaalopbouw !== KapitaalopbouwOptions.Box1 && (
        <LabeledRadioInput caption="Depot" name={`producten[${selected}].depot.soortDepot`} options={depotOptions} />
      )}

      {kenmerken.bestedingsdoelTonen && (
        <LabeledTextInput caption="Bestedingsdoel" name={`producten[${selected}].depot.bestedingsdoel`} />
      )}

      {kenmerken.aankoopkostenPercentageTonen && (
        <LabeledPercentageInput
          caption="Aankoopkosten"
          name={`producten[${selected}].depot.aankoopkostenPercentage`}
          decimalen={2}
        />
      )}

      {kenmerken.verkoopkostenPercentageTonen && (
        <LabeledPercentageInput
          caption="Verkoopkosten"
          name={`producten[${selected}].depot.verkoopkostenPercentage`}
          decimalen={2}
        />
      )}

      {kenmerken.beheerkostenPercentageTonen && (
        <LabeledPercentageInput
          caption="Beheerkosten"
          name={`producten[${selected}].depot.beheerkostenPercentage`}
          decimalen={2}
        />
      )}

      {kenmerken.depotWaardeheaderTonen && <h2 className="ml-3">Depotwaarde</h2>}

      {kenmerken.waardeopbouwBedragTonen && (
        <LabeledCurrencyInput
          caption="Waardeopbouw"
          name={`producten[${selected}].depot.waardeopbouwBedrag`}
          readonly={!kenmerken.waardeopbouwBedragEnabled}
          appendChildren={
            <ModalButton
              parent={`producten[${selected}].depot.waardeopbouwJaren`}
              content={<Icon name="specificatie" alt="Waardeopbouw specificatie" />}
              disabled={waardeopbouwJaren.length === 0}
            >
              <WaardeopbouwJarenModal name={`producten[${selected}].depot.waardeopbouwJaren`} />
            </ModalButton>
          }
        />
      )}

      {kenmerken.waardeopbouwNa5JaarTonen && (
        <LabeledCurrencyInput
          caption="Waardeopbouw na 5 jaar"
          name={`producten[${selected}].depot.waardeopbouwNa5Jaar`}
          readonly={true}
          appendChildren={
            <>
              <ModalButton
                parent={`producten[${selected}].depot.waardeopbouwJaren`}
                content={<Icon name="specificatie" alt="Waardeopbouw specificatie" />}
                disabled={waardeopbouwJaren.length === 0}
              >
                <WaardeopbouwJarenModal name={`producten[${selected}].depot.waardeopbouwJaren`} />
              </ModalButton>
            </>
          }
        />
      )}

      {kenmerken.reedsOpgebouwdBedragTonen && (
        <LabeledCurrencyInput
          caption="Reeds opgebouwd"
          name={`producten[${selected}].depot.reedsOpgebouwdBedrag`}
          readonly={situatie === "voorstel"}
        />
      )}

      {kenmerken.reedsOpgebouwdDatumTonen && (
        <LabeledDateInput
          caption="Per datum"
          name={`producten[${selected}].depot.reedsOpgebouwdDatum`}
          readonly={situatie === "voorstel"}
        />
      )}

      {kenmerken.afkoopwaardeBedragTonen && (
        <LabeledCurrencyInput
          caption="Afkoopwaarde"
          name={`producten[${selected}].depot.afkoopwaardeBedrag`}
          readonly={situatie === "voorstel"}
        />
      )}

      {kenmerken.afkoopwaardeDatumTonen && (
        <LabeledDateInput
          caption="Per datum"
          name={`producten[${selected}].depot.afkoopwaardeDatum`}
          readonly={situatie === "voorstel"}
        />
      )}
    </>
  );
};

export default connect<DepotProps, VermogensType>(Depot);
