import React, { ReactElement } from "react";
import { Modal, Button } from "react-bootstrap";
import { FormikProps } from "formik";
import { determineWeigeringenSideEffects } from "./determine-weigeringen-side-effects";

import { getPersonaliaTextResources } from "../infra/personalia-resources";
import { TextInput, DateInput } from "adviesbox-shared";
import AdviesBoxFormik from "../../shared/utils/adviesbox-formik";
import HdnKeuzelijst from "../../shared/components/hdn-keuzelijst/HdnKeuzelijst";
import { Debug } from "../../shared/components/formik/Debug";
import { Weigering, weigeringenModalSchema } from "../infra/personalia-schema";
import { ISWSideEffects } from "../../shared/components/isw-side-effects/isw-side-effects";

type WeigeringenModalType = {
  list: Weigering[];
};

export type WeigeringenModalData = {
  data: Weigering[];
};

type WeigeringenModalProps = {
  onSave?: (data: Weigering[]) => void;
  closeModal?: () => void;
};

const WeigeringenModal = ({ data, onSave, closeModal }: WeigeringenModalData & WeigeringenModalProps): ReactElement => {
  return (
    <AdviesBoxFormik<WeigeringenModalType>
      initialValues={{ list: data }}
      validationSchema={weigeringenModalSchema}
      onSave={(values): void => onSave && onSave(values.list)}
      closeModal={closeModal}
      render={({ submitForm, values: { list } }: FormikProps<{ list: Weigering[] }>): ReactElement => {
        const shouldShow = (index: number): boolean => {
          return list[index] ? !!list[index].maatschappijCode : false;
        };
        return (
          <>
            <ISWSideEffects<{ list: Weigering[] }> sync={determineWeigeringenSideEffects} />
            <Modal.Header closeButton>
              <Modal.Title>Weigeringen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="px-1 pb-4">{getPersonaliaTextResources("tekstWeigeringenModal")}</div>
              <table className="mx-1 table-verzekeringen">
                <tbody>
                  <tr>
                    <th>Naam</th>
                    <th>Datum</th>
                    <th>Reden</th>
                  </tr>
                  {[...Array(9)].map(
                    (_, index): ReactElement => (
                      <tr key={index + 1}>
                        <td>
                          <HdnKeuzelijst
                            name={`list.[${index}].maatschappijCode`}
                            berichtSoortType="AX"
                            keuzelijst="MaatschappijType"
                            fieldSize="l"
                            emptyValue="Geen"
                          />
                        </td>
                        <td>
                          <DateInput name={`list.[${index}].uitsluitingsdatum`} visible={shouldShow(index)} />
                        </td>
                        <td>
                          <TextInput name={`list.[${index}].reden`} visible={shouldShow(index)} />
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
              <Debug />
            </Modal.Body>
            <Modal.Footer>
              <Button data-testid="btnannuleren" variant="link" onClick={closeModal} id="btnannuleren">
                Annuleren
              </Button>
              <Button data-testid="btnopslaan" variant="primary" onClick={submitForm} id="btnopslaan">
                Bevestigen
              </Button>
            </Modal.Footer>
          </>
        );
      }}
    />
  );
};

export default WeigeringenModal;
