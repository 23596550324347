import { OverbruggingskredietInput, OverbruggingskredietOutput } from "../../.generated/forms/formstypes";

import {
  createISWAsyncSideEffect,
  initISWAsyncSideEffect
} from "../../shared/components/isw-side-effects/create-isw-helpers";
import { OverbruggingskredietModalData } from "./overbruggingskrediet-modal";
import { createMapToDl } from "../../shared/utils/create-map-to-dl";
import { overbruggingskredietModalSchema } from "../infra/financieringsbehoefte-schema";
import { mapLocalDateToString } from "adviesbox-shared";

export const mapOverbruggingskredietInput = createMapToDl(overbruggingskredietModalSchema).to<
  OverbruggingskredietInput
>({
  afsluitkostenBedrag: v => v.afsluitkosten,
  box1Bedrag: v => v.fiscalegegevensDeelBox1Bedrag,
  overwaardeMeenemen: v => v.overwaardeMeenemen,
  einddatum: v => mapLocalDateToString(v.einddatum),
  rentePercentage: v => v.renteOverbruggingskrediet,
  aanvangsdatum: v => mapLocalDateToString(v.ingangsdatum),
  meenemenInOverbruggingBedrag: v => v.meenemenInOverbruggingskrediet,
  hypotheekNorm: v => v.hypotheekNorm,
  overbruggingBedrag: () => null,
  einddatumMeenemen: () => null
});

export const mapOverbruggingskredietOutput = (
  draft: OverbruggingskredietModalData,
  result: OverbruggingskredietOutput
): void => {
  if (result.resultaat) {
    draft.data.brutoRentelastPerMaand = result.resultaat?.rentelastMaandelijksBrutoBedrag || null;
    draft.data.nettoRentelastPerMaand = result.resultaat?.rentelastMaandelijksNettoBedrag || null;
    draft.data.brutoTotaleRentelast = result.resultaat?.rentelastTotaalBrutoBedrag || null;
    draft.data.nettoTotaleRentelast = result.resultaat?.rentelastTotaalNettoBedrag || null;
  }
};

export const overbruggingskredietAsyncBerekening = createISWAsyncSideEffect<OverbruggingskredietModalData>(
  async ({ draft, settings, fetchData, params }) => {
    const result = await fetchData<OverbruggingskredietOutput, OverbruggingskredietInput>({
      url: `${settings.klantdossiersFormsOrigin}/Voorstellen/${params.voorstel}/financieringsbehoefte/Overbruggingskrediet`,
      body: mapOverbruggingskredietInput(draft.data)
    });
    if (result.isValid) {
      mapOverbruggingskredietOutput(draft, result);
    }
  }
);

export const overbruggingskredietModalAsyncSideEffects = initISWAsyncSideEffect<OverbruggingskredietModalData>(
  ({ curr, runAsync }) => {
    if (!!curr.data.einddatum && !!curr.data.renteOverbruggingskrediet && !!curr.data.meenemenInOverbruggingskrediet) {
      runAsync(overbruggingskredietAsyncBerekening());
    }
  }
)();
