import { Formik, FormikProps } from "formik";
import { InferType } from "prop-types";
import React, { ReactElement, ReactNode, useState } from "react";
import { Column } from "react-table-6";
import { AflossingsVormType } from "../../.generated/forms/formstypes";
import { bedragFormat } from "../../shared/utils/currency";
import { enum2options } from "../../shared/utils/enum-to-options";
import Modal from "../../shared/components/modal/Modal";
import {
  LeningdelenUitHetVerledenType,
  ModalLeningdelenType,
  ModalAflossingsVormType,
  modalLeningdelenSchema,
  leningdelenUitHetVerledenSchema
} from "../infra/maximale-hypotheek-schema";
import { LabeledJaarMaandInput, LabeledCurrencyInput, LabeledSelectInput, DataGrid, createSpanWithId, cellCreator } from "adviesbox-shared";

export type ModalLeningdelenData = {
  data: LeningdelenUitHetVerledenType;
};

type ModalLeningdelenProps = {
  onSave?: (data: ModalLeningdelenType) => void;
  closeModal?: () => void;
};

type LeningdelenProps = {
  setValues: (data: LeningdelenUitHetVerledenType) => void;
  values: LeningdelenUitHetVerledenType;
};

const AflossingsVormTypeMapper: Record<AflossingsVormType, ModalAflossingsVormType> = {
  Geen: ModalAflossingsVormType.Geen,
  Aflosvrij: ModalAflossingsVormType.Aflosvrij,
  Annuïteit: ModalAflossingsVormType.Annuïteit,
  Spaarrekening: ModalAflossingsVormType.Spaarrekening,
  Belegging: ModalAflossingsVormType.Belegging,
  Hybride: ModalAflossingsVormType.Hybride,
  Krediet: ModalAflossingsVormType.Krediet,
  Levensverzekering: ModalAflossingsVormType.Levensverzekering,
  Lineair: ModalAflossingsVormType.Lineair,
  AnnuïteitUitgesteld: ModalAflossingsVormType.AnnuïteitUitgesteld,
  AnnuïteitBlok: ModalAflossingsVormType.AnnuïteitBlok,
  Spaar: ModalAflossingsVormType.Spaar,
  UnitLinked: ModalAflossingsVormType.UnitLinked,
  KredietNoPay: ModalAflossingsVormType.KredietNoPay
};

const ModalLeningdelen = ({ data, onSave, closeModal }: ModalLeningdelenData & ModalLeningdelenProps): ReactElement => {
  const selectedState = useState(0);
  const [selected] = selectedState;
  const addLeningdeel = (): InferType<typeof modalLeningdelenSchema> => modalLeningdelenSchema.default();

  const leningdelenColumns: Column[] = [
    {
      Header: "Hypotheekbedrag",
      id: "hypotheekbedrag",
      Cell: (c): ReactElement => createSpanWithId(c.index, 0, bedragFormat(c.original.hypotheekbedrag))
    },
    {
      Header: "Hypotheekvorm",
      id: "hypotheekvorm",
      Cell: (c): ReactElement => createSpanWithId(c.index, 1, c.original.hypotheekvorm)
    },
    {
      Header: "Opgeb. waarde",
      id: "opgebouwdeWaarde",
      Cell: (c): ReactElement => createSpanWithId(c.index, 2, bedragFormat(c.original.opgebouwdeWaarde))
    },
    {
      id: "looptijd",
      Header: "Rest. looptijd",
      Cell: (c): ReactElement =>
        createSpanWithId(
          c.index,
          3,
          c.original.looptijd && (c.original.looptijd.jaren || c.original.looptijd.maanden)
            ? `${c.original.looptijd.jaren ? `${c.original.looptijd.jaren} jr` : ""} ${c.original.looptijd.maanden ? `${c.original.looptijd.maanden} mnd` : ""
            }`
            : ""
        )
    },
    {
      Header: "Box 3",
      id: "deelBox3",
      Cell: (c): ReactElement => createSpanWithId(c.index, 4, c.original.deelBox3)
    },
    {
      Cell: (c): ReactNode => {
        const cellContents = cellCreator(c.original.editable ? "DeleteButton" : "", c.column, `meeTeNemenLeningdelen`, selectedState[1]);
        if (typeof cellContents === "function") return cellContents(c);
        return cellContents;
      }
    }
  ];

  const Leningdelen = ({ values }: LeningdelenProps): ReactElement => {
    return (
      <>
        <DataGrid
          masterDetail
          columns={leningdelenColumns}
          name="meeTeNemenLeningdelen"
          rowSelected={selectedState}
          validationSchema={leningdelenUitHetVerledenSchema}
          rowCaption="leningdeel"
          getNewRowValues={addLeningdeel}
        />

        {values.meeTeNemenLeningdelen.length > 0 ? (
          <div>
            <h3>Leningdeel</h3>
            <LabeledSelectInput
              caption="Hypotheekvorm"
              readonly={!values.meeTeNemenLeningdelen[selected]?.editable}
              name={`meeTeNemenLeningdelen[${selected}].hypotheekvorm`}
              options={enum2options(AflossingsVormTypeMapper)}
            />
            {[
              String(AflossingsVormType.Belegging),
              String(AflossingsVormType.Spaarrekening),
              String(AflossingsVormType.Spaar),
              String(AflossingsVormType.Hybride),
              String(AflossingsVormType.Levensverzekering)
            ].some((val): boolean => val === values.meeTeNemenLeningdelen[selected].hypotheekvorm) && (
                <LabeledCurrencyInput
                  disabled={!values.meeTeNemenLeningdelen[selected]?.editable}
                  caption="Opgebouwde waarde"
                  name={`meeTeNemenLeningdelen[${selected}].opgebouwdeWaarde`}
                />
              )}

            <LabeledCurrencyInput
              caption="Hypotheekbedrag"
              disabled={!values.meeTeNemenLeningdelen[selected]?.editable}
              name={`meeTeNemenLeningdelen[${selected}].hypotheekbedrag`}
            />
            <LabeledJaarMaandInput
              caption="Looptijd"
              disabled={!values.meeTeNemenLeningdelen[selected]?.editable}
              name={`meeTeNemenLeningdelen[${selected}].looptijd`}
            />
            <LabeledCurrencyInput
              caption="Deel box3"
              disabled={!values.meeTeNemenLeningdelen[selected]?.editable}
              name={`meeTeNemenLeningdelen[${selected}].deelBox3`}
            />
          </div>
        ) : null}
      </>
    );
  };

  return (
    <Formik
      initialValues={{ ...data }}
      validationSchema={leningdelenUitHetVerledenSchema}
      onSubmit={(values): void => {
        onSave && onSave(values.meeTeNemenLeningdelen);
        closeModal && closeModal();
      }}
    >
      {({ values, setValues, submitForm }: FormikProps<LeningdelenUitHetVerledenType>): ReactElement => (
        <>
          <Modal
            title={ModalLeningdelen.displayName}
            onSubmitClick={submitForm}
            onCancelClick={closeModal}
            body={<Leningdelen setValues={setValues} values={values} />}
          />
        </>
      )}
    </Formik>
  );
};

ModalLeningdelen.displayName = "Leningdelen uit het verleden";
export default ModalLeningdelen;
