import { HypotheekvormBasis, HypotheekvormenBasisOutput } from "../../.generated/producten/productentypes";
import useAbortableFetch from "use-abortable-fetch";
import { useRequestInit } from "adviesbox-shared";

export type UseHypotheekvormenResult = {
  loading: boolean,
  error: null | Error;
  hypotheekvormen: { [index: string]: HypotheekvormBasis } | null;
};

export function useHypotheekVormData(geldverstrekkerCode: string): UseHypotheekvormenResult {
  const {
    requestInit,
    settings: { productenOrigin }
  } = useRequestInit();

  const url = geldverstrekkerCode
    ? `${productenOrigin}/MaatschappijCodes/${geldverstrekkerCode}/Hypotheekvormen`
    : null;
  const { error, data, loading } = useAbortableFetch<HypotheekvormenBasisOutput>(url, requestInit);

  if (typeof data === "string") {
    return { hypotheekvormen: null, error: new Error("Fout bij het laden van hypotheekvorm"), loading: false };
  }

  return {
    loading, 
    error,
    hypotheekvormen: !data || !data.hypotheekvormen ? null : data.hypotheekvormen
  };
}

export default {
  useHypotheekVormData
};
