import React, { ReactElement, useMemo } from "react";
import { FormikProps, Form } from "formik";

import Lening from "./lening/lening";
import Maatwerkoplossing from "./maatwerkoplossing/maatwerkoplossing";
import Notaris from "./notaris/notaris";
import Financiering from "./financiering/financiering";
import Erfpacht from "./erfpacht/erfpacht";
import Dataleverancier from "./dataleverancier/dataleverancier";
import AanvullendeGegevens from "./aanvullende-gegevens/aanvullende-gegevens";
import { ISWSideEffects } from "../shared/components/isw-side-effects/isw-side-effects";
import { DevDebug } from "../shared/components/dev-debug/dev-debug";
import { PageLoading, Card, CardWrapper, FormFirstFocus, PlatformFoutenSamenvatting, insightsReactPlugin } from "adviesbox-shared";
import { WithSaveData } from "../shared/utils/save-data";
import { withAdviesboxFormik } from "../shared/utils/with-adviesbox-formik";
import { SaveButton } from "../shared/components/save-button/save-button";
import { AanvraagLeningState, AanvraagLeningProps, aanvraagLeningSchema } from "./infra/aanvraag-lening-schema";
import { determineAanvraagLeningSideEffects } from "./infra/determine-aanvraag-lening-side-effects";
import InventarisatieStudielening from "./inventarisatie-studielening/inventarisatie-studielening";
import { withAITracking } from "@microsoft/applicationinsights-react-js";

export type MaatwerkProp = {
  maatwerkLijst: {
    [index: string]: string;
  };
};

const AanvraagLening = (
  props: FormikProps<AanvraagLeningState> & WithSaveData<AanvraagLeningState> & MaatwerkProp
): ReactElement => {
  const { isSubmitting, values, maatwerkLijst } = props;

  const toonErfpacht = values.lening.geselecteerdVoorstel ? values.lening.geselecteerdVoorstel.erfpacht : true;
  const heeftNieuwLeningdeel = values.lening.geselecteerdVoorstel.heeftNieuwLeningdeel;
  const isMeeneemHypotheek = values.lening.geselecteerdVoorstel.isMeeneemHypotheek;

  const maatschappijCode = values.lening.geselecteerdVoorstel?.maatschappijCode
    ? values.lening.geselecteerdVoorstel.maatschappijCode
    : "00";

  const mogelijkeMaatwerkoplossingen: string[] = useMemo(
    () => Object.keys(maatwerkLijst).filter(key => key.startsWith(maatschappijCode)),
    [maatwerkLijst, maatschappijCode]
  );

  const toonMaatwerkOplossingen = mogelijkeMaatwerkoplossingen.length > 0;

  return (
    <FormFirstFocus>
      <Form>
        {isSubmitting && <PageLoading />}

        <ISWSideEffects<AanvraagLeningState> sync={determineAanvraagLeningSideEffects} />

        <CardWrapper className="px-3">
          <div className="text-container">
            <h2>Lening</h2>
            <div className="save-btn-position">
              <div className="button-container">
                <SaveButton context={props} />
              </div>
            </div>
          </div>
        </CardWrapper>

        <PlatformFoutenSamenvatting />

        <CardWrapper className="px-3" maxRowCount={9}>
          <Card title="Lening">
            <Lening />
          </Card>
          {toonMaatwerkOplossingen && (
            <Card title="Maatwerkoplossing">
              <Maatwerkoplossing />
            </Card>
          )}
          <Card title="Notaris">
            <Notaris />
          </Card>
          <Card title="Financiering">
            <Financiering />
          </Card>

          {(heeftNieuwLeningdeel || isMeeneemHypotheek) && (
            <Card title="Inventarisatie studielening">
              <InventarisatieStudielening />
            </Card>
          )}

          {toonErfpacht && (
            <Card title="Erfpacht">
              <Erfpacht />
            </Card>
          )}
          {values.dataleverancier.dataleveranciers.length > 0 && (
            <Card title="Dataleverancier">
              <Dataleverancier />
            </Card>
          )}

          <Card title="Aanvullende gegevens">
            <AanvullendeGegevens />
          </Card>
        </CardWrapper>

        <DevDebug />
      </Form>
    </FormFirstFocus>
  );
};

AanvraagLening.displayName = "AanvraagLening";

export default withAdviesboxFormik<
  AanvraagLeningProps & WithSaveData<AanvraagLeningState> & MaatwerkProp,
  AanvraagLeningState
>({
  // Transform outer props into form values
  mapPropsToValues: (e: AanvraagLeningProps): AanvraagLeningState => e,
  validationSchema: aanvraagLeningSchema
})(withAITracking(insightsReactPlugin, AanvraagLening));
