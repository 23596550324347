import {
  ControleGewenstInkomenOptions,
  ControleGewenstNbiOptions,
  ControleMaandlastOptions
} from "../../../../.generated/forms/formstypes";
import { createISWSideEffect } from "../../../../shared/components/isw-side-effects/create-isw-helpers";
import { ControleNormType } from "./controle-norm-schema";

export const syncControleNormSideEffects = createISWSideEffect<ControleNormType>(({ has, draft }): void => {
  if (has.controleGewenstInkomen.changed) {
    if (draft.controleGewenstInkomen === ControleGewenstInkomenOptions.Nbi) {
      draft.gewijzigdeJaren = true;
      draft.benodigdKapitaal = false;
    } else if (
      draft.controleGewenstInkomen === ControleGewenstInkomenOptions.Geen ||
      draft.controleGewenstInkomen === ControleGewenstInkomenOptions.BrutoInkomenswens
    ) {
      draft.gewijzigdeJaren = null;
      draft.benodigdKapitaal = null;
    }
  }

  if (has.controleGewenstInkomen.changed || has.gewenstNettoBesteedbaarInkomen.changed) {
    if (
      draft.controleGewenstInkomen !== ControleGewenstInkomenOptions.Nbi ||
      draft.gewenstNettoBesteedbaarInkomen !== ControleGewenstNbiOptions.MargeHuidigNbi
    ) {
      draft.margeBovengrensPercentage = null;
      draft.margeOndergrensPercentage = null;
    }

    if (
      draft.controleGewenstInkomen !== ControleGewenstInkomenOptions.Nbi ||
      draft.gewenstNettoBesteedbaarInkomen !== ControleGewenstNbiOptions.VastBedrag
    ) {
      draft.gewenstVastBedrag = null;
    }

    if (
      draft.controleGewenstInkomen !== ControleGewenstInkomenOptions.Nbi ||
      draft.gewenstNettoBesteedbaarInkomen !== ControleGewenstNbiOptions.PercentageHuidigNbi
    ) {
      draft.gewenstPercentageHuidigNbi = null;
    }

    if (draft.controleGewenstInkomen !== ControleGewenstInkomenOptions.BrutoInkomenswens) {
      draft.brutoInkomenswens = null;
    }
  }

  if (draft.controleGewenstInkomen === ControleGewenstInkomenOptions.Nbi &&
    (has.gewijzigdeJaren.changed || has.benodigdKapitaal.changed) && !draft.gewijzigdeJaren && !draft.benodigdKapitaal) {
    draft.gewijzigdeJaren = true;
    draft.benodigdKapitaal = false;
  }

  if (has.controleMaandlast.changed) {
    if (draft.controleMaandlast && !draft.controleMaandLastKeuze) {
      draft.controleMaandLastKeuze = ControleMaandlastOptions.BenodigdInkomen;
    } else if (!draft.controleMaandlast) {
      draft.controleMaandLastKeuze = null;
    }
  }
});
