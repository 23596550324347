import { Afbeelding } from "adviesbox-shared";
import React, { ReactElement } from "react";
import classes from "./hypotheek-oversluiten-pdf.module.scss";

type HypotheekOversluitenPDFHeaderProps = {
  bedrijfslogo: string | null | undefined;
  displayAanvrager1?: boolean;
  aanvrager1?: any | null;
  displayAanvrager2?: boolean;
  aanvrager2?: any | null;
};

export const renderHypotheekOversluitenPDFHeader = ({
  bedrijfslogo
}: HypotheekOversluitenPDFHeaderProps): ReactElement => {
  const bedrijfsImg = new Image();
  if (bedrijfslogo) bedrijfsImg.src = `data:image/png;base64,${bedrijfslogo}`;

  return (
    <>
      <div className={classes.pdf_header_container}>
        <h1 className={classes.pdf_header}>Hypotheek oversluiten</h1>
        <div className={classes.pdf_header}>
          <Afbeelding name="orgData.logo" alt="bedrijfslogo" location={bedrijfsImg.src} style={{ maxHeight: 60 }} />
        </div>
      </div>
      {/* aanvrager1 & 2 info should be displayed here when the issue is resolved */}
    </>
  );
};
