import { createSpanWithId } from "adviesbox-shared";
import { ReactElement } from "react";
import { Column } from "react-table-6";
import { SituatieSoort } from "../producten-overzicht/infra/producten-overzicht-types";
import { jaarMaandInputNaarJaarMaand } from "../shared/generic-parts/jaar-maand/helpers";
import { bedragFormat } from "../shared/utils/currency";
import { getVerzekerdenOrv } from "./infra/map-orv-dl-2-ui";

export const getOrvColumns = (situatie: SituatieSoort): Column[] => [
  {
    Header: "Verzekering",
    id: "Verzekering",
    Cell: (c): ReactElement => {
      const val = situatie === "huidig" ? "Bestaand" : c.original.product.doorlopend ? "Doorlopend" : "Nieuw";
      return createSpanWithId(c.index, 0, val, val);
    }
  },
  {
    Header: "Partij",
    id: "Partij",
    Cell: (c): ReactElement =>
      createSpanWithId(
        c.index,
        1,
        c.original.product.partijNaam,
        c.original.product.partijNaam,
        c.original.product.partijNaam
      )
  },
  {
    Header: "Soort verzekering",
    id: "Soortverzekering",
    Cell: (c): ReactElement =>
      createSpanWithId(
        c.index,
        2,
        c.original.product.productNaam,
        c.original.product.productNaam,
        c.original.product.productNaam
      )
  },
  {
    Header: "Verzekerde(n)",
    id: "Verzekerden",
    Cell: (c): ReactElement => {
      const val = getVerzekerdenOrv(c.original.verzekerden.verzekerden);
      return createSpanWithId(c.index, 3, val, val);
    }
  },
  {
    Header: "Looptijd",
    id: "Looptijd",
    Cell: (c): ReactElement => {
      const val = jaarMaandInputNaarJaarMaand(c.original.product.looptijd);
      return createSpanWithId(c.index, 4, val, val);
    }
  },
  {
    Header: "ORD1",
    id: "ORD1",
    Cell: (c): ReactElement => {
      const val = bedragFormat(c.original.dekking.verzekerdKapitaalAanvrager1);
      return createSpanWithId(c.index, 5, val, val);
    }
  },
  {
    Header: "ORD2",
    id: "ORD2",
    Cell: (c): ReactElement => {
      const val = bedragFormat(c.original.dekking.verzekerdKapitaalAanvrager2);
      return createSpanWithId(c.index, 6, val, val);
    }
  },
  {
    Header: "Premie",
    id: "Premie",
    Cell: (c): ReactElement => {
      const val = bedragFormat(c.original.premieGegevens.risicoPremieLaag);
      return createSpanWithId(c.index, 7, val, val);
    }
  },
  {
    Header: "Premieduur",
    id: "Premieduur",
    Cell: (c): ReactElement => {
      const val =
        c.original.premieGegevens && c.original.premieGegevens.looptijd
          ? jaarMaandInputNaarJaarMaand(c.original.premieGegevens.looptijd)
          : "";

      return createSpanWithId(c.index, 8, val, val);
    }
  },
  {
    Header: "Verpand",
    id: "Verpand",
    Cell: (c): ReactElement => {
      const val = c.original.verpanding.verpandAanGeldverstrekker ? "Ja" : "Nee";
      return createSpanWithId(c.index, 9, val, val);
    }
  },
  {
    Cell: "DeleteButton"
  }
];
