
import React, { ReactElement } from "react";
import { Modal, Button } from "react-bootstrap";
import { connect, FormikContextType } from "formik";
import OpbrengstItemComponent from "./opbrengstitem-component";
import { DevDebug } from "../../../shared/components/dev-debug/dev-debug";
import { FinancieringsbehoefteState } from "../../infra/financieringsbehoefte-types";

type NettoVerkoopopbrengstProps = {
  closeModal?: () => void;
};

const NettoVerkoopopbrengstComponent = ({
  closeModal,
  formik
}: NettoVerkoopopbrengstProps & {
  formik: FormikContextType<FinancieringsbehoefteState>;
}): ReactElement => {
  const nettoOpbrengstPanden = formik.values.nettoPandOpbrengst;
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Netto verkoopopbrengst</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {nettoOpbrengstPanden.map(
          (item): ReactElement => {
            const index = nettoOpbrengstPanden.indexOf(item);
            return <OpbrengstItemComponent item={item} index={index} key={index} />;
          }
        )}

        <DevDebug />
      </Modal.Body>

      <Modal.Footer>
        <Button id="btnsluiten" data-testid="btnsluiten" variant="primary" onClick={closeModal}>
          Sluiten
        </Button>
      </Modal.Footer>
    </>
  );
};

export default connect<{}, FinancieringsbehoefteState>(NettoVerkoopopbrengstComponent);
