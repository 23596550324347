import { ErrorPage, PageLoading, SettingsType, withErrorBoundary } from "adviesbox-shared";
import React, { ReactElement, useCallback } from "react";
import { SituatieSoort } from "../producten-overzicht/infra/producten-overzicht-types";
import { InvalidAdviesboxResultErrorPage } from "../shared/components/fetch-error-page/fetch-error-page";
import { useAdviesboxData } from "../shared/hooks/use-adviesbox-data";
import { RouteParams } from "../shared/paramrouting/paramrouting-context";
import { mapDlTargetToOrvUiField, mapOrvDlToUi } from "./infra/map-orv-dl-2-ui";
import { mapOrvUiToDl } from "./infra/map-orv-ui-2-dl";
import Orv from "./orv";

const OrvAjax = ({ situatie }: { situatie: SituatieSoort }): ReactElement => {
  const createUrl = useCallback(
    (s: SettingsType, p: RouteParams): string =>
      situatie === "huidig"
        ? `${s.klantdossiersFormsOrigin}/adviesdossiers/${p.adviesdossier}/Orv`
        : `${s.klantdossiersFormsOrigin}/voorstellen/${p.voorstel}/Orv`,
    [situatie]
  );

  const createDataId = useCallback((p: RouteParams) => (situatie === "huidig" ? p.adviesdossier : p.voorstel), [
    situatie
  ]);

  const { loading, error, data, platformData, saveData } = useAdviesboxData(
    createUrl,
    createDataId,
    mapOrvDlToUi,
    mapOrvUiToDl(situatie),
    mapDlTargetToOrvUiField
  );

  if (loading) {
    return <PageLoading />;
  }

  if (error) {
    return <ErrorPage error={error} data={platformData} />;
  }

  if (!data) {
    return <InvalidAdviesboxResultErrorPage />;
  }

  return <Orv situatie={situatie} saveData={saveData} {...data} />;
};

OrvAjax.displayName = "OrvAjax";

export default withErrorBoundary(OrvAjax);
