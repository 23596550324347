import { LabeledBevestigingInput } from "adviesbox-shared";
import { useFormikContext } from "formik";
import React, { ReactElement } from "react";
import { AanvraagKenmerken } from "../../producten-overzicht/infra/product-kenmerken-types";
import { AanvragerKeuze } from "../../shared/types";
import { AovsType } from "../infra/aov-schema";

type InformatieVoorAanvraagProps = { kenmerken: AanvraagKenmerken; selected: number };

export const InformatieVoorAanvraag = ({ kenmerken, selected }: InformatieVoorAanvraagProps): ReactElement => {
  const { values } = useFormikContext<AovsType>();
  const selectedProduct = values.producten[selected];
  return (
    <>
      {kenmerken.voorkennisOntslagTonen && (
        <LabeledBevestigingInput
          caption={"Heeft verzekeringnemer (voor)kennis van ontslag of van enige dreiging van ontslag?"}
          name={
            selectedProduct.verzekerden.verzekerden === AanvragerKeuze.Aanvrager1
              ? `producten[${selected}].aanvullendeVragen.verwachtingOntslagAanvrager1`
              : `producten[${selected}].aanvullendeVragen.verwachtingOntslagAanvrager2`
          }
          labelColSize={9}
        />
      )}
      {kenmerken.faillissementTonen && (
        <LabeledBevestigingInput
          caption={`Heeft de werkgever van verzekeringnemer plannen bekend gemaakt over en/of is de werkgever van verzekeringnemer bezig met werktijdverkorting, een uitstel van betaling of een faillissement?`}
          name={
            selectedProduct.verzekerden.verzekerden === AanvragerKeuze.Aanvrager1
              ? `producten[${selected}].aanvullendeVragen.financieleProblemenWerkgeverAanvrager1`
              : `producten[${selected}].aanvullendeVragen.financieleProblemenWerkgeverAanvrager2`
          }
          labelColSize={9}
        />
      )}
      {kenmerken.reorganisatieTonen && (
        <LabeledBevestigingInput
          caption={`Heeft de werkgever van verzekeringnemer plannen bekend gemaakt over en/of is werkgever van verzekeringnemer bezig met en reorganisatie?`}
          name={
            selectedProduct.verzekerden.verzekerden === AanvragerKeuze.Aanvrager1
              ? `producten[${selected}].aanvullendeVragen.reorganisatieWerkgeverAanvrager1`
              : `producten[${selected}].aanvullendeVragen.reorganisatieWerkgeverAanvrager2`
          }
          labelColSize={9}
        />
      )}
      {kenmerken.sprakeVanStrafrechtelijkVerledenTonen && (
        <LabeledBevestigingInput
          caption={`Bent u, of een andere belanghebbende bij deze verzekering in de laatste 8 jaar in aanraking geweest met politie of justitie voor het (mede)plegen van een strafbaar feit of bent u op dit moment betrokken bij een justitieel onderzoek?`}
          name={
            selectedProduct.verzekerden.verzekerden === AanvragerKeuze.Aanvrager1
              ? `producten[${selected}].aanvullendeVragen.strafrechtelijkVerledenAanvrager1`
              : `producten[${selected}].aanvullendeVragen.strafrechtelijkVerledenAanvrager2`
          }
          labelColSize={9}
        />
      )}
      {kenmerken.toelichtingGelezenAkkoordMetSlotverklaringTonen && (
        <LabeledBevestigingInput
          caption={"Toelichting gelezen en slotverklaring akkoord?"}
          name={`producten[${selected}].aanvullendeVragen.toelichtingGelezenAkkoordMetSlotverklaring`}
          labelColSize={9}
        />
      )}
    </>
  );
};
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production")
  InformatieVoorAanvraag.displayName = "InformatieVoorAanvraag";
