import { ValidatiePopup } from "adviesbox-shared";
import { connect, FormikContextType } from "formik";
import { History, LocationState } from "history";
import React, { Dispatch, ReactElement, RefObject, SetStateAction, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { UiError } from "../../types";
import { submit, SubmitResultTypes } from "../../utils/save-validation";

type SaveNavigationProps = {
  history: History<unknown>;
  saveData: (values: any) => Promise<UiError[] | null>;
  berekenSideEffectRef?: RefObject<{ asyncResult: Promise<unknown> }>;
  showValidatiePopup: boolean;
  setValidatiePopup: React.Dispatch<React.SetStateAction<boolean>>;
  setSaveResultText: React.Dispatch<React.SetStateAction<SubmitResultTypes>>;
  triggerPreSaveConfirmation?: boolean;
  preSaveConfirmation?: (path?: string) => void;
  callBack?: () => void;
  setPreviousPath?: Dispatch<SetStateAction<string | null>>;
  isCanceled?: boolean;
};

const SaveNavigation = ({
  formik,
  history,
  saveData,
  berekenSideEffectRef,
  showValidatiePopup,
  triggerPreSaveConfirmation,
  setValidatiePopup,
  setSaveResultText,
  callBack,
  preSaveConfirmation,
  setPreviousPath,
  isCanceled
}: SaveNavigationProps & { formik: FormikContextType<any> }): ReactElement => {
  const continueRef = useRef<(() => void) | null>(null);

  const location = useLocation();

  useEffect((): (() => void) => {
    const initialPush = history.push;

    history.push = async (path: any, state?: LocationState): Promise<void> => {
      if (setPreviousPath) setPreviousPath(path.pathname);
      if (triggerPreSaveConfirmation && preSaveConfirmation) {
        await preSaveConfirmation(path.pathname);
        return;
      }
      
      const validationResult = !isCanceled ? await submit(saveData, formik, berekenSideEffectRef, callBack) : "completed";
      if (validationResult === "default" || validationResult === "completed") {
        initialPush(path, state);
      } else {
        continueRef.current = (): void => initialPush(path, state);
        setValidatiePopup(true);
        setSaveResultText(validationResult);
      }
    };

    return (): void => {
      history.push = initialPush;
    };
  }, [
    saveData,
    formik,
    history.push,
    location,
    berekenSideEffectRef,
    setValidatiePopup,
    showValidatiePopup,
    setSaveResultText,
    history,
    callBack,
    triggerPreSaveConfirmation,
    preSaveConfirmation,
    setPreviousPath,
    isCanceled,
  ]);

  return (
    <>
      <ValidatiePopup
        infotekst="navigatieInfo"
        show={showValidatiePopup}
        onHide={(): void => setValidatiePopup(false)}
        onSubmit={(): void => {
          continueRef.current && continueRef.current();
        }}
        navigatiepopup
      />
    </>
  );
};

SaveNavigation.displayName = "SaveNavigation";

export default connect<SaveNavigationProps>(SaveNavigation);
