import {
  OpnameBerekeningOutput,
  VermogenBerekeningInput,
  SoortBerekeningOptions
} from "../../.generated/forms/formstypes";
import {
  createISWAsyncSideEffect,
  initISWAsyncSideEffect
} from "../../shared/components/isw-side-effects/create-isw-helpers";
import { KlantnaamType } from "../../shared/generic-parts/klantnaam/schema";
import { mapExtraOpnamesUi2Dl, mapVermogenUiToDl } from "../infra/map-vermogen-ui-2-dl";
import { ExtraOpnamesSpecificatieModalType, VermogenType } from "../infra/vermogen-types";

type Context = {
  product: VermogenType;
  aanvrager1: KlantnaamType | null;
  aanvrager2: KlantnaamType | null;
};

export const vermogenAsyncOnttrekkingBerekenen = createISWAsyncSideEffect<ExtraOpnamesSpecificatieModalType, Context>(
  async ({ draft, settings, fetchData, context: { product, aanvrager1, aanvrager2 } }) => {
    if (product.kapitaalopbouw.soortBerekening !== SoortBerekeningOptions.Voorbeeldkapitaal) {
      return;
    }

    const vermogenProduct = mapVermogenUiToDl({ aanvrager1, aanvrager2 })(product);
    if (vermogenProduct.onttrekkingen) {
      vermogenProduct.onttrekkingen.extraOpnameJaren = draft.scenario
        .filter(opname => !!opname.opnameBedrag)
        .map(mapExtraOpnamesUi2Dl);

      const result = await fetchData<OpnameBerekeningOutput, VermogenBerekeningInput>({
        url: `${settings.klantdossiersFormsOrigin}/Vermogen/Opname`,
        body: { vermogenProduct },
        method: "POST"
      });

      if (result.isValid && result.resultaat) {
        result.resultaat.forEach(row => {
          const extraOpname = draft.scenario.find(e => e.jaar === row.jaar);
          if (extraOpname) {
            extraOpname.maxOpnameBedrag = row.maxOpnameBedrag;
            extraOpname.voorbeeldBedrag = row.voorbeeldBedrag;
          }
        });
      }
    }
  }
);

export const extraOpnamesSpecificatieModalAsyncSideEffects = initISWAsyncSideEffect<
  ExtraOpnamesSpecificatieModalType,
  Context
>(({ runAsync, context }) => {
  runAsync(vermogenAsyncOnttrekkingBerekenen(context));
});
