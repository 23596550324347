import * as Yup from "yup";
import { LocalDate } from "@js-joda/core";
import { getIn } from "formik";

import { SoortKredietProductOptions, Garantiesoort } from "../../.generated/forms/formstypes";

import { berekenInputSchema, AanvragerKeuze } from "../../shared/types";
import { klantnaamSchema } from "../../shared/generic-parts/klantnaam/schema";
import { jaarMaandInputSchema, JaarMaandInputType } from "../../shared/generic-parts/jaar-maand/schema";

import { productSchemaYup } from "../../producten-overzicht/infra/producten-overzicht-schema";
import { getKredietenTextResources } from "./kredieten-resources";
import { KredietenSchemaContextType, KredietenType, productCodePrivateLease } from "./kredieten-types";
import { nullableLocalDateSchema } from "adviesbox-shared";

const productLooptijdDefault: JaarMaandInputType = { jaren: null, maanden: null };

const kredietProductSchemaYup = productSchemaYup.shape({
  ingangsdatum: nullableLocalDateSchema.required(getKredietenTextResources("ErrorGeenIngangsDatum")).default(
    LocalDate.now()
      .plusMonths(1)
      .withDayOfMonth(1)
  ),
  looptijd: jaarMaandInputSchema.default(productLooptijdDefault).test({
    test: function(value: JaarMaandInputType) {
      const context = this.options.context as KredietenSchemaContextType;
      let row = context.values.producten.find(p => p.product === this.parent);
      if (!row) {
        // Het product object is al gekloond door Formik, dan via het path
        const rowPath = this.path.replace(".product.looptijd", "");
        row = getIn(context.values, rowPath);
      }

      if (row?.soortProduct === SoortKredietProductOptions.Studielening) {
        const jarenFout = value.jaren !== 15 && value.jaren !== 35;
        const maandenFout = !!value.maanden;

        if (jarenFout) {
          return this.createError({
            path: `${this.path}.jaren`,
            message: getKredietenTextResources("ErrorStudieleningLooptijd")
          });
        } else if (maandenFout) {
          return this.createError({
            path: `${this.path}.maanden`,
            message: getKredietenTextResources("ErrorStudieleningLooptijd")
          });
        }
      }
      return true;
    }
  })
});

export const kredietProductSchema = kredietProductSchemaYup;

export const geldnemersSchema = Yup.object({
  geldnemers: Yup.mixed<AanvragerKeuze>()
    .oneOf(Object.values(AanvragerKeuze))
    .default(AanvragerKeuze.Aanvrager1)
});

export const leningGegevensSchema = Yup.object({
  hoofdsom: berekenInputSchema.test({
    message: "",
    test: function(val: Yup.InferType<typeof berekenInputSchema>) {
      const context = this.options.context ? (this.options.context as { values: KredietenType }) : null;
      const extractedNumber = this.path.match(/\d{1,2}/);
      const selectedProduct = extractedNumber && context ? context.values.producten[Number(extractedNumber[0])] : null;

      if (
        selectedProduct &&
        typeof selectedProduct.leningGegevens.hoofdsom.bedrag !== "number" &&
        selectedProduct.productCode === productCodePrivateLease
      ) {
        return this.createError({
          message: "Hoofdsom ontbreekt"
        });
      }

      if (selectedProduct && typeof selectedProduct.leningGegevens.hoofdsom.bedrag !== "number") {
        return this.createError({
          message: "Hoofdsom ontbreekt",
          path: `${this.path}.bedrag`
        });
      }

      return true;
    }
  }),
  garantie: Yup.mixed<Garantiesoort>()
    .oneOf([...Object.values(Garantiesoort), null])
    .nullable()
    .default(null),
  restantHoofdsom: Yup.number()
    .nullable()
    .default(null),
  opgaveDatum: nullableLocalDateSchema.required(getKredietenTextResources("ErrorGeenOpgaveDatum")).default(
    LocalDate.now()
      .plusMonths(1)
      .withDayOfMonth(1)
  ),
  slottermijn: Yup.number()
    .nullable()
    .default(null),
  aflossingPercentage: Yup.number()
    .nullable()
    .default(null),
  rentePercentage: Yup.number()
    .nullable()
    .default(null),
  maandlast: berekenInputSchema,
  bestedingsdoel: Yup.string().default(""),
  bestedingsdoelOmschrijving: Yup.string()
    .nullable()
    .default(null)
});

export const fiscaleGegevensSchema = Yup.object({
  deelBox1Bedrag: Yup.number()
    .nullable()
    .default(null),
  deelBox1Percentage: Yup.number()
    .max(100)
    .default(0),
  deelBox3Bedrag: Yup.number().default(0),
  deelBox3Percentage: Yup.number()
    .max(100)
    .default(100),
  einddatumRenteaftrek: nullableLocalDateSchema.test({
    test: function(value: LocalDate | null) {
      const context = this.options.context as KredietenSchemaContextType;

      if (value) {
        let row = context.values.producten.find(p => p.fiscaleGegevens === this.parent);
        if (!row) {
          // Het fiscaleGegevens object is al gekloond door Formik, dan via het path
          const rowPath = this.path.replace(".fiscaleGegevens.einddatumRenteaftrek", "");
          row = getIn(context.values, rowPath);
        }

        const ingangsdatum = row?.product.ingangsdatum;
        if (ingangsdatum) {
          if (row?.soortProduct === SoortKredietProductOptions.Restschuldlening) {
            if (ingangsdatum.plusYears(15).isBefore(value)) {
              return this.createError({
                message: getKredietenTextResources("ErrorEinddatumRenteaftrekRestschuldlening")
              });
            }
          } else {
            if (ingangsdatum.plusYears(30).isBefore(value)) {
              return this.createError({
                message: getKredietenTextResources("ErrorEinddatumRenteaftrekOverige")
              });
            }
          }
        }
      }

      return true;
    }
  })
});

export const kredietSchema = Yup.object({
  productId: Yup.string().nullable(),
  productCode: Yup.string(),
  partijCode: Yup.string(),
  soortProduct: Yup.mixed<SoortKredietProductOptions>()
    .oneOf(Object.values(SoortKredietProductOptions))
    .default(SoortKredietProductOptions.AflopendKrediet),
  product: kredietProductSchema,
  geldnemers: geldnemersSchema,
  leningGegevens: leningGegevensSchema,
  fiscaleGegevens: fiscaleGegevensSchema
});

export const kredietenSchema = Yup.object({
  producten: Yup.array(kredietSchema).default([]),
  aanvrager1: klantnaamSchema.nullable(),
  aanvrager2: klantnaamSchema.nullable(),
  ingangsdatumVoorstel: nullableLocalDateSchema
});
