import { jaarMaandInputSchema } from "adviesbox-shared";
import { LabelValuePairs } from "adviesbox-shared/utils/types";
import * as Yup from "yup";
import { AddBemiddelingproductOutputModel, BaseProduct } from "../../.generated/bemiddeling/bemiddelingtypes";
import { FinancieringOptions, AflossingsVormType } from "../../.generated/forms/formstypes";
import { yupEnum, yupNullableEnum } from "../../shared/utils/yup-enum";
/* istanbul ignore file */

export type HeeftBemiddelaarType = {
  heeftBemiddelaar: boolean;
  bemiddelaars: LabelValuePairs;
};

export type AddOrUpdateCallDekking = {
  dekkingId: string | null;
  teVervangenBemiddelingProductId: string | null;
  serviceProviderId: string | null;
};

export type ShowAanvraagModal = {
  show: boolean;
};

export enum productSoortEnum {
  orvProduct = "orvProduct",
  aovProduct = "aovProduct",
  bankgarantieProduct = "bankgarantieProduct",
  taxatieProduct = "taxatieProduct"
}

export enum Overschrijven {
  nieuweAanvraag = "false",
  nieuweVersie = "true"
}

export enum ProductTypeName {
  ORV = "Overlijdensrisicoverzekering",
  AOV = "Arbeidsongeschiktheidsverzekering",
  BG = "Bankgarantie",
  hypotheek = "Hypotheek",
  taxatie = "Taxatie"
}

export enum aanvraagNaar {
  aanvraagNaarPartijIsClicked = "aanvraagNaarPartijIsClicked",
  aanvraagNaarServiceproviderIdIsClicked = "aanvraagNaarServiceproviderIdIsClicked"
}

export interface BemiddelingProductObject extends BaseProduct {
  [key: string]: any;
}

export enum ErrorDataType {
  HDNKeuzelijst = "HDNKeuzelijst",
  entityObject = "entityObject",
  date = "date",
  integer = "integer",
  decimal = "decimal",
  boolean = "boolean",
  alphanumeric = "alphanumeric",
  telefoon = "telefoon",
  email = "email",
  postcode = "postcode",
  IBAN = "IBAN"
}

export const aanvullendeGegevensSchema = Yup.object({
  dataType: yupNullableEnum(ErrorDataType).default(null),
  veldNaam: Yup.string().default(""),
  maxDigits: Yup.number()
    .nullable()
    .default(0),
  maxAllowedDecimalpoints: Yup.number()
    .nullable()
    .default(0),
  waarde: Yup.mixed()
    .nullable()
    .default(null)
});

export const leningdeelSchema = Yup.object({
  aflossingsvorm: yupEnum(AflossingsVormType).default(AflossingsVormType.Geen),
  productcode: Yup.number().default(0),
  productnaam: Yup.string()
    .default("")
    .nullable(),
  hoofdsom: Yup.number()
    .default(0)
    .nullable()
});

export type leningdeelType = Yup.InferType<typeof leningdeelSchema>;

export const aanvraagVoorstelSchema = Yup.object({
  voorstelNaam: Yup.string().default(""),
  voorstelId: Yup.string().default(""),
  geselecteerd: Yup.bool().default(false)
});

export type aanvraagVoorstelType = Yup.InferType<typeof aanvraagVoorstelSchema>;

export const aanvraagHypotheekSchema = Yup.object({
  aanvraagNaarPartij: Yup.bool().default(false),
  aanvraagNaarServiceproviderId: Yup.string()
    .default("")
    .nullable(),
  aanvraagNaarSoftwareKoppelingId: Yup.string()
    .default("")
    .nullable(),
  aanwezigInBemiddeling: Yup.bool().default(false),
  leningdelen: Yup.array(leningdeelSchema).default([]),
  looptijdMaanden: jaarMaandInputSchema.default({ jaren: 30, maanden: 0 }),
  meenemenNaarBemiddeling: Yup.bool()
    .default(false)
    .nullable(),
  partijCode: Yup.string().default(""),
  hdnOntvangerCode: Yup.string().default(""),
  partijnaam: Yup.string().default(""),
  productId: Yup.string().default(""),
  productcode: Yup.number().default(0),
  productnaam: Yup.string().default(""),
  versie: Yup.number()
    .default(0)
    .nullable(),
  aanvraagVerzonden: Yup.bool()
    .default(false)
    .nullable(),
  voorstel: Yup.string()
    .default("0")
    .nullable(),
  overschrijven: yupEnum(Overschrijven).default(Overschrijven.nieuweVersie),
  methodeIndienen: Yup.string()
    .default("")
    .nullable(),
  aanvraagNaar: Yup.string()
    .default("")
    .nullable(),
  aanvraagNaarHdnNummer: Yup.string()
    .default(null)
    .nullable(),
  softwareKoppelingId: Yup.string()
    .default(null)
    .nullable(),
  hdnNummer: Yup.number()
    .default(null)
    .nullable(),
  productType: yupNullableEnum(ProductTypeName)
    .default(null)
    .nullable(),
  bemiddelingProduct: Yup.object<BemiddelingProductObject>()
    .nullable()
    .default(null)
});

export type aanvraagHypotheekType = Yup.InferType<typeof aanvraagHypotheekSchema>;

export const verzekerdeSchema = Yup.object({
  persoonId: Yup.string()
    .default("")
    .nullable(),
  volgnummer: Yup.number()
    .default(0)
    .nullable(),
  naamVerzekerde: Yup.string()
    .default("")
    .nullable()
});

export type verzekerdeType = Yup.InferType<typeof verzekerdeSchema>;

export const productSchema = Yup.object({
  partijnaam: Yup.string()
    .default("")
    .nullable(),
  partijCode: Yup.string()
    .default("")
    .nullable(),
  hdnOntvangerCode: Yup.string()
    .default("")
    .nullable(),
  productnaam: Yup.string()
    .default("")
    .nullable(),
  productId: Yup.string()
    .default("")
    .nullable(),
  productcode: Yup.number()
    .default(0)
    .nullable(),
  premie: Yup.number()
    .default(0)
    .nullable(),
  aanwezigInBemiddeling: Yup.bool()
    .default(false)
    .nullable(),
  versie: Yup.number()
    .default(0)
    .nullable(),
  voorstel: Yup.string()
    .default("0")
    .nullable(),
  meenemenNaarBemiddeling: Yup.bool()
    .default(false)
    .nullable(),
  aanvraagVerzonden: Yup.bool()
    .default(false)
    .nullable(),
  verzekerde: Yup.array(verzekerdeSchema)
    .nullable()
    .default([]),
  overschrijven: yupEnum(Overschrijven)
    .default(Overschrijven.nieuweVersie)
    .nullable(),
  methodeIndienen: Yup.string()
    .default("")
    .nullable(),
  aanvraagNaarPartij: Yup.bool().default(false),
  aanvraagNaarServiceproviderId: Yup.string()
    .default("")
    .nullable(),
  aanvraagNaarSoftwareKoppelingId: Yup.string()
    .default("")
    .nullable(),
  aanvraagNaar: Yup.string()
    .default("")
    .nullable(),
  aanvraagNaarHdnNummer: Yup.string()
    .default(null)
    .nullable(),
  softwareKoppelingId: Yup.string()
    .default(null)
    .nullable(),
  hdnNummer: Yup.number()
    .default(null)
    .nullable(),
  productType: yupNullableEnum(ProductTypeName)
    .default(null)
    .nullable(),
  bemiddelingProduct: Yup.object<BemiddelingProductObject>()
    .nullable()
    .default(null),
  bankgarantieHoofdsom: Yup.number()
    .nullable()
    .default(0)
});

export type ProductType = Yup.InferType<typeof productSchema>;

export const serviceProviderSchema = Yup.object({
  serviceproviderId: Yup.string().default(""),
  naam: Yup.string()
    .default("")
    .nullable(),
  hdnNummer: Yup.string()
    .default("")
    .nullable()
});

export type serviceProvidersType = Yup.InferType<typeof serviceProviderSchema>;

export const softwareKoppelingenSchema = Yup.object({
  softwareKoppelingId: Yup.string()
    .default("")
    .nullable(),
  naam: Yup.string()
    .default("")
    .nullable()
});

export type softwareKoppelingenType = Yup.InferType<typeof softwareKoppelingenSchema>;

export const maatschappijCodeSchema = Yup.object({
  maatschappijCode: Yup.string()
    .default("")
    .nullable(),
  productId: Yup.string()
    .default("")
    .nullable(),
  productType: yupNullableEnum(ProductTypeName)
    .default(null)
    .nullable()
});

export type MaatschappijCode = Yup.InferType<typeof maatschappijCodeSchema>;

export const aanvraagOpBasisVanSchema = Yup.object({
  id: Yup.string().default(""),
  omschrijving: Yup.string().default(""),
  volgnummer: Yup.number()
    .nullable()
    .default(null)
});
export type AanvraagOpBasisVanType = Yup.InferType<typeof aanvraagOpBasisVanSchema>;

export const productIdsSchema = Yup.object({
  productId: Yup.string().default(""),
  productType: yupNullableEnum(ProductTypeName)
    .default(null)
    .nullable(),
  volgnummer: Yup.number()
    .nullable()
    .default(0)
});
export type ProductIdsType = Yup.InferType<typeof productIdsSchema>;
export interface AanvraagErrorType {
  [key: string]: any;
}

export const aanvraagErrorsSchema = Yup.object({
  aanvraagErrors: Yup.array(Yup.object<AanvraagErrorType>().nullable()).default([]),
  productId: Yup.string()
    .nullable()
    .default(null),
  bemiddelingId: Yup.string()
    .nullable()
    .default(null),
  productNaamMetVolgnummer: Yup.string()
    .nullable()
    .default(null)
});
export type AanvraagErrorsType = Yup.InferType<typeof aanvraagErrorsSchema>;

export const bemiddelaarSchema = Yup.object({
  bemiddelaarId: Yup.string().nullable(),
  emailadres: Yup.string().nullable(),
  naam: Yup.string().nullable()
});

export enum AanvraagStep {
  ProductOvernemen = 1,
  BemiddelaarSelecteren,
  AanvraagTonen
}

export const aanvraagSchema = Yup.object({
  hypotheek: aanvraagHypotheekSchema.nullable().default(null),
  voorstelArray: Yup.array(aanvraagOpBasisVanSchema)
    .nullable()
    .default(null),
  aanvraagOpBasisVan: Yup.string().default(""), //voorstelId
  fetchProducten: Yup.bool().default(false),
  aanvraagStep: yupNullableEnum(AanvraagStep).default(null),
  showAanvraagModal: Yup.bool().default(false),
  showAanvraagBemiddelaarModal: Yup.bool().default(false),
  bemiddelaars: Yup.array(bemiddelaarSchema)
    .nullable()
    .default(null),
  adviseur: bemiddelaarSchema.nullable().default(null),
  foutenVoorstelId: Yup.string()
    .nullable()
    .default(null),
  gekoppeldeBemiddelaar: bemiddelaarSchema.nullable().default(null),
  isLoading: Yup.bool()
    .default(false)
    .nullable(),
  isLoadingAanvraag: Yup.bool()
    .default(false)
    .nullable(),
  redirectToBemiddeling: Yup.bool()
    .default(false)
    .nullable(),
  productenMetErrors: Yup.array(aanvraagErrorsSchema).default([]), //errors
  authorized: Yup.bool()
    .nullable()
    .default(null),
  adviseurId: Yup.string()
    .nullable()
    .default(null),
  bemiddelaar: bemiddelaarSchema.nullable().default(null),
  bemiddelaarId: Yup.string()
    .nullable()
    .default(null),
  bemiddelaarEmailadres: Yup.string()
    .nullable()
    .default(null),
  productIdsEnTypes: Yup.array(productIdsSchema).default([]),
  orvProduct: Yup.array(productSchema).default([]),
  aovProduct: Yup.array(productSchema).default([]),
  taxatieProduct: productSchema.nullable().default(null),
  bankgarantieProduct: productSchema.nullable().default(null),
  serviceprovider: Yup.array(serviceProviderSchema).default([]),
  softwareKoppelingen: Yup.array(softwareKoppelingenSchema).default([]),
  financiering: yupEnum(FinancieringOptions),
  meenemenError: Yup.bool()
    .nullable()
    .default(false),
  addOrUpdateCallError: Yup.bool()
    .nullable()
    .default(false),
  adviseursBemiddelaarsCallError: Yup.bool()
    .nullable()
    .default(false),
  addOrUpdateIsValid: Yup.bool()
    .nullable()
    .default(true),
  bemiddelingProductenResults: Yup.array(Yup.object<AddBemiddelingproductOutputModel>()).default([]), // result of the addOrUpdateCall
  maatschappijCodes: Yup.array(maatschappijCodeSchema).default([]),
  geselecteerdProduct: Yup.string()
    .nullable()
    .default(null),
  nieuweVersieAanvraag: Yup.bool().default(false),
  externeReferentie: Yup.string()
    .nullable()
    .default(null)
});

export type AanvraagState = Yup.InferType<typeof aanvraagSchema>;
export type AanvraagProps = Yup.InferType<typeof aanvraagSchema>;
