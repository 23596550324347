import * as Yup from "yup";
import {
  FiscaalRegimeOptions,
  FiscaleTyperingOptions,
  SoortBerekeningOptionsKv,
  BasisdekkingOptions,
  Premieverloopsoort,
  SoortKapitaalverzekeringProductOptions,
  PremieverloopOptions,
  OrvUitkeringssoortOptions,
  FiscalePlaatsingOrvOptions,
  KapitaalopbouwOptions,
  FiscaleVoortzettingOptions
} from "../../.generated/forms/formstypes";
import {
  kapitaalopbouwSchema,
  premieGegevensSchema,
  productSchema,
  verpandingSchema,
  verzekerdenSchema,
  verzekeringNemersSchema
} from "../../producten-overzicht/infra/producten-overzicht-schema";
import { klantnaamSchema } from "../../shared/generic-parts/klantnaam/schema";
import { kredietSchema } from "../../shared/generic-parts/krediet/schema";
import { leningdeelSchema } from "../../shared/generic-parts/leningdeel/schema";
import { AanvragerKeuze } from "../../shared/types";
import { yupEnum } from "../../shared/utils/yup-enum";
import { getKapitaalverzekeringTextResources } from "./kapitaalverzekering-resources";
import { KapitaalverzekeringType } from "./kapitaalverzekering-types";
import { nullableLocalDateSchema } from "adviesbox-shared";

export type ExtendedBasisdekkingOptions = BasisdekkingOptions | "90%" | "110%";

export const productCodeLevensverzekering = "01";
export const productCodeUniversalLifePremie = "02";

// TODO: Velden mappen als we de popup maken
export const premiedepotModalSchema = Yup.object({});

// TODO: Velden mappen als we de popup maken
export const extraInlegModalSchema = Yup.object({});

export const fiscaleRegelingSchema = Yup.object({
  orv: Yup.mixed<FiscalePlaatsingOrvOptions | null>()
    .oneOf([...Object.values(FiscalePlaatsingOrvOptions), null])
    .nullable()
    .default(FiscalePlaatsingOrvOptions.Box3),
  lijfrenteclausule: Yup.boolean()
    .nullable()
    .default(false),
  kapitaalopbouw: Yup.mixed<KapitaalopbouwOptions | null>()
    .oneOf([...Object.values(KapitaalopbouwOptions), null])
    .nullable()
    .default(KapitaalopbouwOptions.Box3),
  productId: Yup.string()
    .nullable()
    .default(null),
  fiscaleVoortzetting: Yup.mixed<FiscaleVoortzettingOptions | null>()
    .oneOf([...Object.values(FiscaleVoortzettingOptions), null])
    .nullable()
    .default(FiscaleVoortzettingOptions.Geen),
  externeMaatschappijCode: Yup.string()
    .nullable()
    .default(null),
  externeMaatschappijOmschrijving: Yup.string()
    .nullable()
    .default(null),
  lijfrenteclausuleOrigineel: Yup.boolean()
    .nullable()
    .default(null),
  fiscaleTypering: Yup.mixed<FiscaleTyperingOptions | null>()
    .oneOf([...Object.values(FiscaleTyperingOptions), null])
    .nullable()
    .default(null),
  garantieverzekering: Yup.boolean()
    .nullable()
    .default(null),
  fiscaalRegime: Yup.mixed<FiscaalRegimeOptions | null>()
    .oneOf([...Object.values(FiscaalRegimeOptions), null])
    .nullable()
    .default(null),
  oorspronkelijkeIngangsdatum: nullableLocalDateSchema,
  ingangsdatumBox1: nullableLocalDateSchema,
  einddatum: nullableLocalDateSchema,
  doelkapitaalBedrag: Yup.number()
    .nullable()
    .default(null),
  laagstePremieooitBedrag: Yup.number()
    .nullable()
    .default(null),
  hoogstePremieOoitBedrag: Yup.number()
    .nullable()
    .default(null),
  huidigeJaarPremieBedrag: Yup.number()
    .nullable()
    .default(null),
  premieLopendJaarBedrag: Yup.number()
    .nullable()
    .default(null),
  eerdereUitkeringenBedrag: Yup.number()
    .nullable()
    .default(null),
  ingebrachteWaardeBedrag: Yup.number()
    .nullable()
    .default(null),
  polisnummer: Yup.string()
    .nullable()
    .default(""),
  originelePolisnummer: Yup.string()
    .nullable()
    .default("")
});

export const overlijdensrisicodekkingSchema = Yup.object({
  basisdekking: Yup.mixed<ExtendedBasisdekkingOptions>()
    .oneOf([...Object.values(BasisdekkingOptions), "90%", "110%"])
    .default(BasisdekkingOptions.Geen),
  verzekerdKapitaalBedrag: Yup.number()
    .nullable()
    .default(null),
  soortRisicodekking: Yup.mixed<OrvUitkeringssoortOptions>()
    .oneOf([...Object.values(OrvUitkeringssoortOptions), null])
    .nullable()
    .default(Premieverloopsoort.Gelijkblijvend),
  annuiteitsPercentage: Yup.number()
    .nullable()
    .default(null),
  dekkingDaaltTotBedrag: Yup.number()
    .nullable()
    .default(null),
  duurdalingInJaren: Yup.number()
    .nullable()
    .default(null),
  waardePercentage: Yup.number()
    .nullable()
    .default(null),
  partnerrentePerJaarBedrag: Yup.number()
    .nullable()
    .default(null),
  levenslang: Yup.bool().default(false),
  maxDuurInJaren: Yup.number()
    .nullable()
    .default(null),
  directIngaandeRente: Yup.bool().default(false),
  uitsteldatum: nullableLocalDateSchema
});

const waardeopbouwJarenSchema = Yup.object({
  jaar: Yup.number().default(0),
  beginwaardeBedrag: Yup.number().default(0),
  stortingenBedrag: Yup.number().default(0),
  onttrekkingenBedrag: Yup.number().default(0),
  eindwaardeBedrag: Yup.number().default(0)
});

const waardeopbouwMaandenSchema = Yup.object({
  maand: Yup.number().default(0),
  eindwaardeBedrag: Yup.number().default(0)
});

export const poliswaardeModalSchema = Yup.object({
  waardeopbouwJaren: Yup.array(waardeopbouwJarenSchema).default([]),
  waardeopbouwMaanden: Yup.array(waardeopbouwMaandenSchema).default([]),
  beginJaar: Yup.number()
    .nullable()
    .default(null)
});

export const poliswaardeSchema = Yup.object({
  reedsOpgebouwdBedrag: Yup.number()
    .nullable()
    .default(null),
  reedsOpgebouwdDatum: nullableLocalDateSchema,
  afkoopwaardeBedrag: Yup.number()
    .nullable()
    .default(null),
  afkoopwaardeDatum: nullableLocalDateSchema,
  waardeopbouwBedrag: Yup.number()
    .nullable()
    .default(null),
  poliswaardeModal: poliswaardeModalSchema.default(poliswaardeModalSchema.default())
});

export const kapitaalVerzekeringKapitaalOpbouwSchema = kapitaalopbouwSchema.shape({
  soortBerekening: Yup.mixed<SoortBerekeningOptionsKv>()
    .oneOf([...Object.values(SoortBerekeningOptionsKv), null])
    .nullable()
    .default(SoortBerekeningOptionsKv.Premie)
});

export const kapitaalverzekeringSchema = Yup.object({
  productId: Yup.string()
    .nullable()
    .default(null),
  productCode: Yup.string().default(""),
  partijCode: Yup.string().default(""),
  soortProduct: Yup.mixed<SoortKapitaalverzekeringProductOptions>()
    .oneOf(Object.values(SoortKapitaalverzekeringProductOptions))
    .default(SoortKapitaalverzekeringProductOptions.Levensverzekering),
  meenemen: Yup.boolean().default(false),
  product: productSchema,
  verzekeringnemers: verzekeringNemersSchema,
  verzekerden: verzekerdenSchema,
  verzekerde1Overlijdensrisicodekking: overlijdensrisicodekkingSchema,
  verzekerde2Overlijdensrisicodekking: overlijdensrisicodekkingSchema,
  kapitaalopbouw: kapitaalVerzekeringKapitaalOpbouwSchema,
  premieGegevens: premieGegevensSchema.when(
    "fiscaleRegeling",
    (
      values: Yup.InferType<typeof fiscaleRegelingSchema>,
      schema: Yup.ObjectSchema<Yup.InferType<typeof premieGegevensSchema>>
    ) => {
      if (
        values &&
        values.lijfrenteclausule &&
        (values.orv === FiscalePlaatsingOrvOptions.Box1 || values.kapitaalopbouw === KapitaalopbouwOptions.Box1)
      ) {
        return schema;
      }

      return schema.shape({
        spaarPremieAftrekbaar: Yup.number()
          .default(null)
          .nullable()
      });
    }
  ),
  poliswaarde: poliswaardeSchema,
  fiscaleRegeling: fiscaleRegelingSchema,
  verpanding: verpandingSchema,
  premieverloop: yupEnum(PremieverloopOptions).default(PremieverloopOptions.Gelijkblijvend)
})
  .test("verzekerde1Overlijdensrisicodekking.uitsteldatum", "", function(value): boolean | Yup.ValidationError {
    const kapitaalverzekering = value as KapitaalverzekeringType;

    if (kapitaalverzekering.verzekerden.verzekerden !== AanvragerKeuze.Aanvrager2) {
      const overlijdensrisicodekking = kapitaalverzekering.verzekerde1Overlijdensrisicodekking;
      if (overlijdensrisicodekking.partnerrentePerJaarBedrag && !overlijdensrisicodekking.directIngaandeRente) {
        if (!overlijdensrisicodekking.uitsteldatum) {
          return this.createError({
            path: `${this.path}.verzekerde1Overlijdensrisicodekking.uitsteldatum`,
            message: getKapitaalverzekeringTextResources("ErrorUitsteldatumInvullen")
          });
        }

        if (
          kapitaalverzekering.product.ingangsdatum &&
          overlijdensrisicodekking.uitsteldatum.isBefore(kapitaalverzekering.product.ingangsdatum)
        ) {
          return this.createError({
            path: `${this.path}.verzekerde1Overlijdensrisicodekking.uitsteldatum`,
            message: getKapitaalverzekeringTextResources("ErrorUitsteldatumInDeToekomst")
          });
        }
      }
    }

    return true;
  })
  .test("verzekerde2Overlijdensrisicodekking.uitsteldatum", "", function(value): boolean | Yup.ValidationError {
    const kapitaalverzekering = value as KapitaalverzekeringType;

    if (kapitaalverzekering.verzekerden.verzekerden !== AanvragerKeuze.Aanvrager1) {
      const overlijdensrisicodekking = kapitaalverzekering.verzekerde2Overlijdensrisicodekking;
      if (overlijdensrisicodekking.partnerrentePerJaarBedrag && !overlijdensrisicodekking.directIngaandeRente) {
        if (!overlijdensrisicodekking.uitsteldatum) {
          return this.createError({
            path: `${this.path}.verzekerde2Overlijdensrisicodekking.uitsteldatum`,
            message: getKapitaalverzekeringTextResources("ErrorUitsteldatumInvullen")
          });
        }

        if (
          kapitaalverzekering.product.ingangsdatum &&
          overlijdensrisicodekking.uitsteldatum.isBefore(kapitaalverzekering.product.ingangsdatum)
        ) {
          return this.createError({
            path: `${this.path}.verzekerde2Overlijdensrisicodekking.uitsteldatum`,
            message: getKapitaalverzekeringTextResources("ErrorUitsteldatumInDeToekomst")
          });
        }
      }
    }

    return true;
  });

export const kapitaalverzekeringenSchema = Yup.object({
  producten: Yup.array(kapitaalverzekeringSchema).default([]),
  aanvrager1: klantnaamSchema.nullable(),
  aanvrager2: klantnaamSchema.nullable().default(null),
  leningdelen: Yup.array(leningdeelSchema).default([]),
  kredieten: Yup.array(kredietSchema).default([]),
  geldverstrekkerNaam: Yup.string()
    .nullable()
    .default(null),
  ingangsdatumVoorstel: nullableLocalDateSchema
});
