import * as Yup from "yup";
import { InferType } from "yup";

export const overzichtSubscriptionInputSchema = Yup.object({
  url: Yup.string().required(),
  body: Yup.string().nullable(),
  rapportageNaam: Yup.string().nullable()
});

export type overzichtSubscriptionInput = InferType<typeof overzichtSubscriptionInputSchema>;

export const voorstelKeuzeSchema = Yup.object({
  voorstelId: Yup.string(),
  naam: Yup.string().default(""),
  selected: Yup.bool().default(false)
});
