import { LocalDate } from "@js-joda/core";
import { createISWSideEffect, initISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";
import { SetFieldTouchedType } from "../../shared/types";
import { Legitimatiebewijs, legitimatiebewijsSchema, SoortLegitimatiebewijs } from "../infra/personalia-schema";

export const legitimatieSideEffects = createISWSideEffect<Legitimatiebewijs, { setFieldTouched: SetFieldTouchedType }>(
  ({ prev, has, draft, context }): void => {
    if (has.soortLegitimatiebewijs.changed && prev.soortLegitimatiebewijs !== null) {
      context.setFieldTouched("nummerLegitimatiebewijs", false);

      const reset = legitimatiebewijsSchema.default();
      if (draft.soortLegitimatiebewijs) reset.soortLegitimatiebewijs = draft.soortLegitimatiebewijs;
      draft = reset;
      return;
    }

    if (has.datumAfgifte.changed && draft.datumAfgifte) {
      switch (draft.soortLegitimatiebewijs) {
        case SoortLegitimatiebewijs.Rijbewijs:
        case SoortLegitimatiebewijs.EuropeseIdentiteitskaart:
        case SoortLegitimatiebewijs.Paspoort:
          draft.geldigTot = draft.datumAfgifte.plusYears(10);
          break;
        default:
          draft.geldigTot = LocalDate.now();
          break;
      }
    }
  }
);

export const determineLegitimatieSideEffects = initISWSideEffect(legitimatieSideEffects);
