import { LabeledNumberInput, NumberInput } from "adviesbox-shared";
import classNames from "classnames";
import { useFormikContext } from "formik";
import React, { ReactElement } from "react";
import { QuestionSpecCombined, QuestionType } from "../../klantprofiel-schema";

type QuestionJaarMaandenProps = {
  data: QuestionSpecCombined;
};

export const QuestionJaarMaanden = ({ data }: QuestionJaarMaandenProps): ReactElement => {
  const { setFieldValue } = useFormikContext();

  if (data.type !== QuestionType.jaarMaanden) {
    return <>{`invalid QuestionType: ${data.type}`}</>;
  }

  return (
    <>
      <div className={classNames({ "mt-3": data.schema.omschrijving })}>
        {data.schema.omschrijving && <h2 className="mb-3">{data.schema.omschrijving}</h2>}
        <LabeledNumberInput
          caption="Aantal jaar en maanden"
          name={`vragen[${data.index}].antwoorden[0].waarde1`}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
            if (!e.target.value) {
              return setFieldValue(`vragen[${data.index}].antwoorden[0].waarde1`, null);
            }
          }}
          data-testid={`labeled-jaar-${data.schema.code}-${data.index}`}
          key={`key-${data.index}`}
          fieldSize="s"
          appendChildren={
            <>
              <label className="px-1 pt-1 no-margin">Jaar</label>
              <NumberInput
                name={`vragen[${data.index}].antwoorden[0].waarde2`}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  if (!e.target.value) {
                    return setFieldValue(`vragen[${data.index}].antwoorden[0].waarde2`, null);
                  }
                }}
                data-testid={`labeled-maanden-${data.schema.code}-${data.index}`}
                fieldSize="s"
                suppressError={true}
              />
              <label className="px-1 pt-1 ml-1">Maanden</label>
            </>
          }
        />
      </div>
    </>
  );
};
