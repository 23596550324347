import React, { ReactElement } from "react";
import { connect, FormikContextType } from "formik";


import { SelectedProps } from "../../shared/types";
import { LabeledTextInput } from "adviesbox-shared";
import { PensioenenState } from "../infra/pensioen-schema";

const Pensioenuitvoerder = ({
  selected,
  formik: {
    values: { pensioenen }
  }
}: SelectedProps & {
  formik: FormikContextType<PensioenenState>;
}): ReactElement => {
  if (selected >= pensioenen.length) return <></>;

  return (
    <>
      <LabeledTextInput
        caption="Naam pensioenuitvoerder"
        name={`pensioenen[${selected}].pensioenuitvoerder.naamPensioenuitvoerder`}
      />

      <LabeledTextInput caption="Branche / sector" name={`pensioenen[${selected}].pensioenuitvoerder.brancheSector`} />
    </>
  );
};

export default connect<SelectedProps, PensioenenState>(Pensioenuitvoerder);
