import { ReactElement } from "react";
import { createSpanWithId } from "adviesbox-shared";
import { Column } from "react-table-6";
import moment from "moment";

export const laatsteDossierColumns: Column[] = [
  {
    Header: "Laatst geopend",
    accessor: "laatstGeopend",
    id: "openedOn",
    Cell: (c): ReactElement => {
      const val = moment(c.original.laatstGeopend.toString()).format("DD-MM-YYYY HH:mm");
      return createSpanWithId(c.index, 0, val, val, "laatste-dossiers");
    }
  },
  {
    Header: "Klant",
    accessor: "klant",
    id: "Klant",
    minWidth: 180,
    Cell: (c): ReactElement => createSpanWithId(c.index, 1, c.original.klant, c.original.klant, "laatste-dossiers")
  },
  {
    Header: "Aanleiding",
    accessor: "aanleiding",
    id: "Aanleiding",
    Cell: (c): ReactElement =>
      createSpanWithId(c.index, 2, c.original.aanleiding, c.original.aanleiding, "laatste-dossiers")
  },
  {
    Header: "Korte omschrijving",
    accessor: "omschrijving",
    id: "Omschrijving",
    Cell: (c): ReactElement =>
      createSpanWithId(c.index, 3, c.original.omschrijving, c.original.omschrijving, "laatste-dossiers")
  },
  {
    Header: "Status",
    accessor: "status",
    id: "status",
    Cell: (c): ReactElement => createSpanWithId(c.index, 4, c.original.status, c.original.status, "laatste-dossiers")
  }
];
