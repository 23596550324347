import React, { ReactElement } from "react";
import { connect, FormikContextType } from "formik";
import { LabeledRadioInput } from "adviesbox-shared";

import { PersoonkeuzeKenmerken } from "../../producten-overzicht/infra/product-kenmerken-types";
import { getAanvragerOpties } from "../../producten-overzicht/infra/producten-helper";
import { HypothekenState } from "../infra/hypotheek-types";

type ContractantenProps = {
  selected: number;
  caption?: string | null;
  kenmerken: PersoonkeuzeKenmerken;
};

const Contractanten = ({
  kenmerken,
  selected,
  caption,
  formik: {
    setFieldValue,
    values: { producten, aanvrager1, aanvrager2 }
  }
}: {
  formik: FormikContextType<HypothekenState>;
} & ContractantenProps): ReactElement => {
  const verzekerdenOpties = getAanvragerOpties(aanvrager1, aanvrager2, kenmerken.meerdereContractantenOptiesMogelijk);

  return (
    <>
      <LabeledRadioInput
        caption={caption ?? (verzekerdenOpties.length > 1 ? "Contractant(en)" : "Contractant")}
        name={`producten[${selected}].verzekerde.verzekerde`}
        options={verzekerdenOpties}
        onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
          setFieldValue(`producten[${selected}].verzekerde.verzekerde`, e.target.value);
          setFieldValue(`producten[${selected}].verzekeringnemers.verzekeringnemer`, e.target.value);
        }}
      />
    </>
  );
};

Contractanten.displayName = "Contractanten";

export default connect<ContractantenProps, HypothekenState>(Contractanten);
