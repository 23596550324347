import React, { ReactElement } from "react";
import { FormikProps } from "formik";
import { overigeWerkzaamhedenModalSchema, OverigeWerkzaamhedenModalType } from "../infra/inkomen-en-fiscus-schema";

import Inkomensverleden, { WinstSoort } from "../infra/shared-components/inkomensverleden";

import ScenarioInstellingen from "../infra/shared-components/scenario-instellingen";
import AdviesBoxFormik from "../../shared/utils/adviesbox-formik";
import Modal from "../../shared/components/modal/Modal";
import { LabeledBerekenDate, LabeledTextInput } from "adviesbox-shared";
import { ISWSideEffects } from "../../shared/components/isw-side-effects/isw-side-effects";
import { overigeWerkzaamhedenModalSideEffects } from "./overige-werkzaamheden-side-effects";

export type OverigeWerkzaamhedenModalData = {
  data: OverigeWerkzaamhedenModalType;
};

type OverigeWerkzaamhedenModalProps = {
  onSave?: (data: OverigeWerkzaamhedenModalType) => void;
  closeModal?: () => void;
};

const OverigeWerkzaamhedenModal = ({
  data,
  onSave,
  closeModal
}: OverigeWerkzaamhedenModalData & OverigeWerkzaamhedenModalProps): ReactElement => {
  return (
    <AdviesBoxFormik<OverigeWerkzaamhedenModalType>
      initialValues={{ ...data }}
      validationSchema={overigeWerkzaamhedenModalSchema}
      onSave={onSave}
      closeModal={closeModal}
      render={({ submitForm }: FormikProps<OverigeWerkzaamhedenModalType>): ReactElement => {
        const body = (
          <>
            <ISWSideEffects<OverigeWerkzaamhedenModalType> sync={overigeWerkzaamhedenModalSideEffects()} />
            <div className="input-to-right-with-button">
              <LabeledBerekenDate caption="Inkomen meetellen tot" name="inkomenMeetellenTot" />
            </div>
            <LabeledTextInput caption="Omschrijving" name="omschrijving" />
            <Inkomensverleden soort={WinstSoort.OverigeWerkzaamheden} name="inkomensverleden" />
            <ScenarioInstellingen name="scenarioInstellingen" />
          </>
        );
        return (
          <>
            <Modal
              title="Inkomsten uit overige werkzaamheden"
              body={body}
              onSubmitClick={submitForm}
              onCancelClick={closeModal}
            />
          </>
        );
      }}
    />
  );
};

export default OverigeWerkzaamhedenModal;
