import { IngPriceInput, IngPriceOutput } from "../../.generated/forms/formstypes";

import { SettingsType } from "adviesbox-shared";
import { User } from "oidc-client";

async function ingPriceToolOphalenRentes(
  settings: SettingsType,
  user: User | null,
  vestigingId: string,
  voorstelId: string,
  input: IngPriceInput,
  signal: AbortSignal
): Promise<IngPriceOutput> {
  const url = `${settings.klantdossiersFormsOrigin}/PostPricetoolResult?voorstelId=${voorstelId}`;
  if (!user) throw Error("Er is onvoldoende data om verder te gaan");
  const rsp = await fetch(url, {
    method: "POST",
    headers: {
      authorization: `${user.token_type} ${user.access_token}`,
      "Ocp-Apim-Subscription-Key": `${settings.OcpApimSubscriptionKey}`,
      "Content-Type": "application/json;charset=UTF-8",
      vestigingId
    },
    body: JSON.stringify(input),
    signal
  });

  if (!rsp.ok) {
    throw Error(`Fout bij aanroep van web-api '${url}'. Resultaat is niet OK.`);
  }

  const json = await rsp.json();

  if (!json.isValid) throw Error(`Fout bij aanroep van web-api '${url}'. Input is niet valid.`);

  return json;
}

export default ingPriceToolOphalenRentes;
