import React, { ReactElement } from "react";
import { FormikProps } from "formik";
import Modal from "../../shared/components/modal/Modal";

import { LabeledCurrencyInput, LabeledDateInput } from "adviesbox-shared";
import AdviesBoxFormik from "../../shared/utils/adviesbox-formik";
import { AfkoopErfpachtModal as AfkoopErfpachtModalState } from "../infra/financieringsbehoefte-types";
import { afkoopErfpachtModelSchema } from "../infra/financieringsbehoefte-schema";

export type AfkoopErfpachtModalData = {
  data: AfkoopErfpachtModalState;
};

type AfkoopErfpachtModalProps = {
  onSave?: (data: AfkoopErfpachtModalState) => void;
  closeModal?: () => void;
};

const AfkoopErfpachtModal = ({
  data,
  onSave,
  closeModal
}: AfkoopErfpachtModalData & AfkoopErfpachtModalProps): ReactElement => {
  const body = (
    <>
      <LabeledCurrencyInput caption="Bedrag afkoop" name="afkoopBedrag" />

      <LabeledDateInput caption="Einddatum afkoop" name="afkoopEinddatum" />
    </>
  );

  return (
    <AdviesBoxFormik<AfkoopErfpachtModalState>
      initialValues={{ ...data }}
      validationSchema={afkoopErfpachtModelSchema}
      onSave={onSave}
      closeModal={closeModal}
      render={({ submitForm }: FormikProps<AfkoopErfpachtModalState>): ReactElement => (
        <>
          <Modal title="Afkoop erfpacht" body={body} onSubmitClick={submitForm} onCancelClick={closeModal} />
        </>
      )}
    />
  );
};

export default AfkoopErfpachtModal;
