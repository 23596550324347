import React, { ReactElement } from "react";
import { connect, FormikContextType } from "formik";

import { SelectedProps } from "../../shared/types";
import { LabeledCurrencyInput, LabeledPercentageInput, ResetButton } from "adviesbox-shared";
import { WoonsituatieListState } from "../infra/woonsituatie-schema";

const Huurwoning = ({
  selected,
  formik: {
    values: { woonsituaties }
  }
}: SelectedProps & {
  formik: FormikContextType<WoonsituatieListState>;
}): ReactElement => {
  const selectedWoonsituatie = woonsituaties ? woonsituaties[selected] : null;
  if (!selectedWoonsituatie) return <></>;

  return (
    <>
      <LabeledCurrencyInput caption="Huur per maand" name={`woonsituaties[${selected}].huurwoning.huurPerMaand`} />

      <LabeledPercentageInput
        caption="Stijging"
        name={`woonsituaties[${selected}].huurwoning.stijging`}
        decimalen={2}
        appendChildren={
          <ResetButton
            name={`woonsituaties[${selected}].huurwoning.stijging`}
            defaultValue={selectedWoonsituatie.huurwoning.stijgingPercentageDefault || 0}
          />
        }
      />
    </>
  );
};

export default connect<SelectedProps, WoonsituatieListState>(Huurwoning);
