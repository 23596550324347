import * as Yup from "yup";
import { InferType } from "yup";
import { Adviesonderwerpen } from "../../.generated/instellingen-forms/instellingen-formstypes";
import { WithSaveData } from "../../shared/utils/save-data";
import { yupEnum } from "../../shared/utils/yup-enum";

export enum FinancieringOptions {
  AankoopNieuwbouw = "AankoopNieuwbouw",
  AankoopBestaandeBouw = "AankoopBestaandeBouw",
  HuidigeHypotheekWijzigen = "HuidigeHypotheekWijzigen",
  GeenFinanciering = "GeenFinanciering"
}

export enum GebruikWoning {
  Primair = "Primair",
  TweedeWoning = "TweedeWoning"
}

export const voorstelSchema = Yup.object().shape({
  id: Yup.string()
    .nullable()
    .default(null),
  omschrijving: Yup.string()
    .default("")
    .max(25),
  kopieVoorstelId: Yup.string()
    .nullable()
    .default(null),
  deleted: Yup.boolean().default(false),
  index: Yup.number().default(0)
});
export type VoorstelType = InferType<typeof voorstelSchema>;

export type VoorstellenType = VoorstelType[];

export const planningKeuzeSchema = Yup.object().shape({
  overlijden: Yup.boolean().default(false),
  arbeidsongeschiktheid: Yup.boolean().default(false),
  werkloosheid: Yup.boolean().default(false),
  pensioen: Yup.boolean().default(false),
  vermogensopbouw: Yup.boolean().default(false)
});
export type PlanningKeuzeType = InferType<typeof planningKeuzeSchema>;

export const aanleidingGesprekSchema = Yup.object().shape({
  verbouwing: Yup.boolean().default(false),
  afkooperfpacht: Yup.boolean().default(false),
  financieringWoning: Yup.boolean().default(false),
  extraAflossen: Yup.boolean().default(false),
  echtscheiding: Yup.boolean().default(false),
  eindeRentevastperiode: Yup.boolean().default(false),
  aflossingsVormen: Yup.boolean().default(false),
  maandlastenverlichting: Yup.boolean().default(false),
  risicoscan: Yup.boolean().default(false),
  vermogensAdvies: Yup.boolean().default(false),
  controlegesprek: Yup.boolean().default(false),
  anders: Yup.boolean().default(false),
  andersTekst: Yup.string().default("")
});
export type AanleidingGesprekKeuzeType = InferType<typeof aanleidingGesprekSchema>;

export const aanleidingTypeSchema = Yup.object().shape({
  financiering: yupEnum(FinancieringOptions).default(FinancieringOptions.AankoopBestaandeBouw),
  gebruikWoning: yupEnum(GebruikWoning).default(GebruikWoning.Primair)
});
export type AanleidingType = InferType<typeof aanleidingTypeSchema>;

export const aanleidingSchema = Yup.object().shape({
  aanleiding: Yup.object().shape({
    financiering: Yup.mixed()
      .required()
      .oneOf(
        ["AankoopNieuwbouw", "AankoopBestaandeBouw", "HuidigeHypotheekWijzigen", "GeenFinanciering"],
        `Onbekende waarde voor financiering.`
      )
      .test(
        "gebruikWoning",
        // eslint-disable-next-line no-template-curly-in-string
        "Tweede woning mag niet aangevinkt zijn bij '${value}'.",
        function(this: Yup.TestContext, input: FinancieringOptions): boolean {
          if (input === FinancieringOptions.AankoopNieuwbouw || input === FinancieringOptions.AankoopBestaandeBouw) {
            return true;
          }
          const aanleiding: AanleidingType = this.parent;
          return aanleiding.gebruikWoning !== "TweedeWoning";
        }
      )
      .default("AankoopBestaandeBouw"),
    gebruikWoning: Yup.mixed()
      .required()
      .oneOf(["Primair", "TweedeWoning"])
      .default("Primair")
  }),
  planningKeuze: planningKeuzeSchema,
  aanleidingGesprek: aanleidingGesprekSchema,
  voorstellen: Yup.array(voorstelSchema).default([]),
  hasTweedeAanvrager: Yup.boolean().default(false),
  newKopieOptieNaam: Yup.string()
    .nullable()
    .default(null),
  kopieOptie: Yup.string()
    .nullable()
    .default("0"),
  saving: Yup.bool().nullable() /* used to disable the 'nieuw voorstel toevoegen'
   button; while the GET (after PUT) aint completed / is canceled when the use clicks the button (immediately) again. 
   Fixes #71994. Otherwise it might occur that a voorstel is added, but it's voorstel(analyseid) doesnt come back to the screen, 
   causing an extra item to be created in the next PUT (and 4 items is not allowed). */
});
export type AanleidingState = InferType<typeof aanleidingSchema>;

export type AanleidingProps = {
  data: AanleidingState;
  adviesonderwerpenInstellingenData: Adviesonderwerpen;
} & WithSaveData<AanleidingState>;
