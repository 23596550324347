import { initISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";

import { determineIndicatieveUitkerendeFaseSideEffects } from "../../producten-overzicht/indicatieve-uitkerende-fase-modal/determine-indicatieve-uitkerende-fase-side-effects";

import { UitkerendeLijfrentesType } from "./uitkerende-lijfrente-types";
import { LijfrenteUitgangspuntBerekeningOptions } from "../../.generated/forms/formstypes";

type Context = {
  selected: number;
};

export const determineUitkerendeLijfrenteDetailsSideEffects = initISWSideEffect<UitkerendeLijfrentesType, Context>(
  bag => {
    const selected = bag.subset.producten[bag.context.selected].create();
    const { subset } = selected;

    determineIndicatieveUitkerendeFaseSideEffects(subset.indicatieveUitkerendeFase.create());

    if (bag.has.producten[bag.context.selected].kapitaal.uitgangspuntBerekening.changed) {
      bag.draft.producten[bag.context.selected].indicatieveUitkerendeFase.lijfrenteUitkering.berekenen =
        bag.draft.producten[bag.context.selected].kapitaal.uitgangspuntBerekening ===
        LijfrenteUitgangspuntBerekeningOptions.BeschikbareKoopsom;
    }
  }
);
