import {
  Icon,
  LabeledBevestigingInput,
  LabeledCurrencyInput,
  LabeledDateInput,
  LabeledSelectInput,
  LabeledText,
  LabeledTextInput,
  ModalButton
} from "adviesbox-shared";
import { LabelValuePairs } from "adviesbox-shared/utils/types";
import { connect, FormikContextType } from "formik";
import React, { ReactElement } from "react";
import { TaxatieDoelOptions } from "../../.generated/forms/formstypes";
import { deTaxatheekCode, FitrexCode, MaatwerkWoondienstenCode, NWWICode } from "../infra/taxatie-bureaus-helper";
import { getTaxatieResources } from "../infra/taxatie-resources";
import {
  GeldverstrekkerNwwiModalType,
  TaxateurNwwiModalType,
  TaxatieKenmerkenType,
  TaxatieSchermType,
  WaarschuwingType
} from "../infra/taxatie-scherm-schema";
import { taxatieAlgemeenDoelOpties } from "./algemeen-taxatie-helper";
import GeldverstrekkerModal from "./geldverstrekker-modal/geldverstrekker-modal";
import { MissendegegevensModal } from "./missende-gegevens-modal";
import TaxateurModal from "./taxateur-modal/taxateur-modal";
import TaxatieVerbouwingSpecificatieModal from "./taxatie-verbouwing-modal/taxatie-verbouwing-modal";

export const onSaveTaxateurNWWI = (
  setFieldValue: FormikContextType<TaxatieSchermType>["setFieldValue"],
  data: TaxateurNwwiModalType
): void => {
  setFieldValue("taxatieAlgemeen.taxateurNwwiKeuze", data);
};

export const onSaveGeldverstrekkerNWWI = (
  setFieldValue: FormikContextType<TaxatieSchermType>["setFieldValue"],
  data: GeldverstrekkerNwwiModalType
): void => {
  setFieldValue("taxatieAlgemeen.geldverstrekkerNwwiKeuze", data);
};

export const onSubmitTaxateurWaarschuwingModal = (
  setFieldValue: FormikContextType<TaxatieSchermType>["setFieldValue"]
): void => {
  setFieldValue(`taxatieAlgemeen.taxateurWaarschuwingTonen`, true);
};

export const onSubmitGeldverstrekkerWaarschuwingModal = (
  setFieldValue: FormikContextType<TaxatieSchermType>["setFieldValue"]
): void => {
  setFieldValue(`taxatieAlgemeen.geldverstrekkerWaarschuwingTonen`, true);
};
export const doelTaxatieFilter = (
  taxatieAlgemeenDoelOpties: LabelValuePairs,
  maatschappijCode: string | null | undefined
): LabelValuePairs => {
  const allowedNWWIDoelTaxatie = [
    TaxatieDoelOptions.VerkrijgenFinanciering,
    TaxatieDoelOptions.VerkrijgenFinancieringMetNhg,
    TaxatieDoelOptions.VerkrijgenInzichtWaardeAankoopbeslissing,
    TaxatieDoelOptions.VerkrijgenInzichtWaardeVerkoopbeslissing,
    TaxatieDoelOptions.VerkrijgenInzichtTenBehoeveVanExecutorialeVerkoop,
    TaxatieDoelOptions.Anders
  ];

  const allowedFitrexDoelTaxatie = [
    TaxatieDoelOptions.VerkrijgenFinanciering,
    TaxatieDoelOptions.BepalenPrijsBijVerkoop,
    TaxatieDoelOptions.BepalenPrijsBijAankoop,
    TaxatieDoelOptions.BepalenPrijsBijTerugkoop,
    TaxatieDoelOptions.GedwongenVerkoop,
    TaxatieDoelOptions.Anders
  ];

  const allowedMaatwerkWoondienstenDoelTaxatie = [
    TaxatieDoelOptions.AanvraagHypothecaireGeldlening,
    TaxatieDoelOptions.AankoopBeslissing,
    TaxatieDoelOptions.AanvraagOverbruggingsfinanciering,
    TaxatieDoelOptions.BijzonderBeheer,
    TaxatieDoelOptions.BoedelSplitsing,
    TaxatieDoelOptions.BrabantseVerkoopgarantie,
    TaxatieDoelOptions.Hertaxatie,
    TaxatieDoelOptions.MinnelijkeWaardering,
    TaxatieDoelOptions.OntpandingVerzekeringspolis,
    TaxatieDoelOptions.Uitwinning,
    TaxatieDoelOptions.VerkoopBeslissing
  ];

  const allowedDeTaxatheekDoelTaxatie = [
    TaxatieDoelOptions.WoningFinancieringMetValidatie,
    TaxatieDoelOptions.WoningFinancieringNwwi
  ];

  if (maatschappijCode === NWWICode)
    return taxatieAlgemeenDoelOpties.filter(v =>
      allowedNWWIDoelTaxatie.includes(TaxatieDoelOptions[v.value as TaxatieDoelOptions])
    );

  if (maatschappijCode === MaatwerkWoondienstenCode)
    return taxatieAlgemeenDoelOpties.filter(v =>
      allowedMaatwerkWoondienstenDoelTaxatie.includes(TaxatieDoelOptions[v.value as TaxatieDoelOptions])
    );

  if (maatschappijCode === FitrexCode)
    return taxatieAlgemeenDoelOpties.filter(v =>
      allowedFitrexDoelTaxatie.includes(TaxatieDoelOptions[v.value as TaxatieDoelOptions])
    );

  if (maatschappijCode === deTaxatheekCode)
    return taxatieAlgemeenDoelOpties.filter(v =>
      allowedDeTaxatheekDoelTaxatie.includes(TaxatieDoelOptions[v.value as TaxatieDoelOptions])
    );

  return taxatieAlgemeenDoelOpties;
};

const AlgemeenTaxatie = ({
  formik: {
    setFieldValue,
    values: { taxatieKenmerken, taxatieAlgemeen, taxatiebureau, taxatieObject, aanvullendeInformatie }
  }
}: {
  formik: FormikContextType<TaxatieSchermType>;
}): ReactElement => {
  const { maatschappijCode, agentnummer } = taxatiebureau ?? {};
  const kenmerken = taxatieKenmerken as TaxatieKenmerkenType;
  const nhgTonen = kenmerken?.NhgTonen;
  const nwwiAanvraagTonen = kenmerken?.NwwiAanvraagTonen;
  const validatieVerplichtTonen = kenmerken?.ValidatieVerplichtTonen;
  const energiePrestatieAdviesTonen = kenmerken?.EnergieAdviesTonen;
  const bouwtechnischeKeuringTonen = kenmerken?.BouwtechnischeKeuringTonen;
  const faxVerstuurdTonen = kenmerken?.FaxVerstuurdTonen;
  const voortaxatieGewenstTonen = kenmerken?.VoortaxatieTonen;
  const herbouwwaardeTonen = kenmerken?.HerbouwwaardeTonen;
  const datumAanvraagTonen = kenmerken?.DatumAanvraagTonen;
  const datumGereedTonen = kenmerken?.DatumGereedTonen;
  const passeerDatumTonen = kenmerken?.PasseerdatumTonen;
  const recenteVerkoopTonen = kenmerken?.RecenteVerkoopTonen;
  const bemiddelingsKostenTonen = kenmerken?.BemiddelingskostenTonen;
  const voorVerkrijgenLeningTonen = kenmerken?.VoorVerkrijgenLeningTonen;
  const beinvloedingWaardeTonen = kenmerken?.BeïnvloedingWaardeTonen;
  const toelichtingBeinvloedingWaardeTonen = kenmerken?.ToelichtingWaardeTonen;
  const taxateurTonen = kenmerken?.TaxateurTonen;
  const doelTaxatieTonen = kenmerken?.DoelTaxatieTonen;
  const referentieGeldverstrekkerTonen = kenmerken?.GeldverstrekkerReferentieTonen;
  const adresPand = taxatieObject?.adresPand ?? null;
  const doelTaxatie = taxatieAlgemeen?.doelTaxatie ?? null;
  const taxateurWaarschuwingTonen = taxatieAlgemeen?.taxateurWaarschuwingTonen ?? null;
  const geldverstrekkerWaarschuwingTonen = taxatieAlgemeen?.geldverstrekkerWaarschuwingTonen ?? null;
  const taxateurNwwiKeuze = taxatieAlgemeen?.taxateurNwwiKeuze ?? null;
  const verbouwingSpecificatiesTotaal = taxatieAlgemeen?.verbouwingSpecificatiesTotaal ?? null;
  const verbouwingSpecificaties = taxatieAlgemeen?.verbouwingSpecificaties ?? [];
  const verbouwingSpecificatiesModalKnopTonen = verbouwingSpecificatiesTotaal && kenmerken.VerbouwingspecificatieTonen;
  const geldverstrekkerContractId = aanvullendeInformatie?.geldverstrekker ?? null;
  const geldverstrekkerNwwiKeuze = taxatieAlgemeen?.geldverstrekkerNwwiKeuze ?? null;
  const geenGeldVerstrekkerId = !geldverstrekkerNwwiKeuze?.selectedResultaat && !taxatieAlgemeen?.geldverstrekker;
  const taxateurWaarschuwingModalTonen =
    taxateurTonen &&
    (geenGeldVerstrekkerId || !geldverstrekkerContractId || !adresPand?.postcode || !adresPand?.huisnummer);
  const geldverstrekkerWaarschuwingModalTonen =
    (doelTaxatieTonen && !doelTaxatie) || !adresPand?.postcode || !adresPand?.huisnummer;
  const betrefAankoopTonen = kenmerken.BetreftAankoopTonen;

  const geldverstrekkerComponent = (
    <LabeledText
      caption="Geldverstrekker"
      name="taxatieAlgemeen.geldverstrekker"
      fieldSize="no-size"
      value={taxatieAlgemeen?.geldverstrekker ?? "Geen gegevens ingevuld"}
      appendChildren={
        <>
          {!geldverstrekkerWaarschuwingModalTonen &&
            agentnummer &&
            maatschappijCode === NWWICode &&
            kenmerken?.GeldverstrekkerKeuzeTonen && (
              <ModalButton
                parent="taxatieAlgemeen.geldverstrekkerNwwiKeuze"
                aria-label="Geldverstrekker button"
                content={<Icon name="specificatie" alt="Geldverstrekker" />}
                size="lg"
              >
                <GeldverstrekkerModal
                  data={{
                    ...geldverstrekkerNwwiKeuze,
                    resultaat: [],
                    selectedResultaat: null,
                    postcode: adresPand && adresPand?.postcode,
                    agentnummer,
                    doelTaxatie,
                    resultaatIsLeeg: false
                  }}
                  onSave={
                    /* istanbul ignore next */
                    data => {
                      onSaveGeldverstrekkerNWWI(setFieldValue, data);
                    }
                  }
                />
              </ModalButton>
            )}
          {(geldverstrekkerWaarschuwingModalTonen || !agentnummer) &&
            maatschappijCode === NWWICode &&
            kenmerken?.GeldverstrekkerKeuzeTonen && (
              <ModalButton
                parent="taxatieAlgemeen.geldverstrekkerNwwiKeuze"
                aria-label="Geldverstrekker button"
                content={<Icon name="specificatie" alt="Geldverstrekker" />}
                size="sm"
              >
                <MissendegegevensModal
                  modalType={WaarschuwingType.Geldverstrekker}
                  extraText={!agentnummer ? getTaxatieResources("NietGeldigeAgentnummer") : ``}
                  gemarkeerdeVeldenTekstTonen={geldverstrekkerWaarschuwingModalTonen}
                  onClickSubmit={
                    /* istanbul ignore next */
                    () => onSubmitGeldverstrekkerWaarschuwingModal(setFieldValue)
                  }
                />
              </ModalButton>
            )}
        </>
      }
    />
  );

  return (
    <>
      <>
        {maatschappijCode === NWWICode && geldverstrekkerComponent}
        {maatschappijCode === NWWICode &&
          (!geldverstrekkerNwwiKeuze?.selectedResultaat || !geldverstrekkerContractId) &&
          taxateurWaarschuwingTonen && (
            <div className="row justify-content-end">
              <div className="foutmelding col-6">{getTaxatieResources("KiesGeldverstrekker")}</div>
            </div>
          )}
      </>
      {taxateurTonen && (
        <div className="">
          <LabeledText
            caption="Taxateur"
            name="aanvullendeInformatie.taxateur"
            verplicht={true}
            fieldSize="no-size"
            value={aanvullendeInformatie?.taxateur ?? "Geen gegevens ingevuld"}
            appendChildren={
              <>
                {!taxateurWaarschuwingModalTonen && (
                  <ModalButton
                    parent="taxatieAlgemeen.taxateurNwwiKeuze"
                    aria-label="Taxateur button"
                    content={<Icon name="specificatie" alt="Taxateur" />}
                    size="lg"
                  >
                    <TaxateurModal
                      data={{
                        ...taxateurNwwiKeuze,
                        resultaat: [],
                        selectedResultaat: null,
                        contractId: geldverstrekkerContractId,
                        huisnummer: adresPand && adresPand?.huisnummer,
                        postcode: adresPand && adresPand?.postcode,
                        resultaatIsLeeg: false
                      }}
                      onSave={
                        /* istanbul ignore next */
                        data => {
                          onSaveTaxateurNWWI(setFieldValue, data);
                        }
                      }
                    />
                  </ModalButton>
                )}
                {taxateurWaarschuwingModalTonen && (
                  <ModalButton
                    parent="taxatieAlgemeen.taxateurNwwiKeuze"
                    aria-label="Taxateur button"
                    content={<Icon name="specificatie" alt="Taxateur" />}
                    size="sm"
                  >
                    <MissendegegevensModal
                      modalType={WaarschuwingType.Taxateur}
                      onClickSubmit={
                        /* istanbul ignore next */
                        () => onSubmitTaxateurWaarschuwingModal(setFieldValue)
                      }
                    />
                  </ModalButton>
                )}
              </>
            }
          />
        </div>
      )}

      <>
        {doelTaxatieTonen && (
          <LabeledSelectInput
            caption={getTaxatieResources("Doeltaxatie")}
            name="taxatieAlgemeen.doelTaxatie"
            options={doelTaxatieFilter(taxatieAlgemeenDoelOpties, maatschappijCode)}
          />
        )}
        {doelTaxatieTonen && !doelTaxatie && geldverstrekkerWaarschuwingTonen && (
          //only with NWWI
          <div className="row justify-content-end">
            <div className="foutmelding col-6">{getTaxatieResources("KiesDoelTaxatie")}</div>
          </div>
        )}
      </>

      <>{maatschappijCode !== NWWICode && maatschappijCode !== FitrexCode && geldverstrekkerComponent}</>

      {referentieGeldverstrekkerTonen && (
        <LabeledTextInput name="taxatieAlgemeen.referentieGeldverstrekker" caption="Referentie geldverstrekker" />
      )}
      {nhgTonen && <LabeledBevestigingInput name="taxatieAlgemeen.nhg" caption="NHG" />}
      {herbouwwaardeTonen && <LabeledBevestigingInput name="taxatieAlgemeen.herbouwwaarde" caption="Herbouwwaarde" />}

      {maatschappijCode === NWWICode && <LabeledBevestigingInput name="taxatieAlgemeen.verbouwing" caption="Verbouwing" />}
      
      {maatschappijCode !== NWWICode && <LabeledCurrencyInput
        caption="Verbouwing"
        name="taxatieAlgemeen.verbouwingSpecificatiesTotaal"
        readonly={true}
        fieldSize="no-size"
        appendChildren={
          !!verbouwingSpecificatiesModalKnopTonen && (
            <ModalButton
              parent="taxatieAlgemeen.verbouwingSpecificaties"
              aria-label="Verbouwing button"
              content={<Icon name="specificatie" alt="Verbouwing" />}
            >
              <TaxatieVerbouwingSpecificatieModal data={verbouwingSpecificaties} />
            </ModalButton>
          )
        }
      />}
      {datumAanvraagTonen && <LabeledDateInput name="taxatieAlgemeen.aanvraagdatum" caption="Datum aanvraag" />}
      {datumGereedTonen && <LabeledDateInput name="taxatieAlgemeen.gereedDatum" caption="Datum gereed" />}
      {betrefAankoopTonen && <LabeledBevestigingInput name="taxatieAlgemeen.betreftAankoop" caption="Aankoop" />}
      {passeerDatumTonen && <LabeledDateInput name="taxatieAlgemeen.passeerdatum" caption="Passeerdatum" />}
      {voortaxatieGewenstTonen && (
        <LabeledBevestigingInput name="taxatieAlgemeen.voortaxatieGewenst" caption="Voortaxatie gewenst" />
      )}
      {faxVerstuurdTonen && <LabeledBevestigingInput name="taxatieAlgemeen.faxVerstuurd" caption="Fax verstuurd" />}
      {recenteVerkoopTonen && (
        <LabeledBevestigingInput name="taxatieAlgemeen.recenteVerkoop" caption="Recente verkoop" />
      )}
      {bemiddelingsKostenTonen && (
        <LabeledCurrencyInput name="taxatieAlgemeen.bemiddelingsKostenBedrag" caption="Bemiddelingskosten" />
      )}
      {beinvloedingWaardeTonen && (
        <LabeledBevestigingInput name="taxatieAlgemeen.beinvloedingWaarde" caption="Beïnvloeding waarde" />
      )}
      {toelichtingBeinvloedingWaardeTonen && taxatieAlgemeen?.beinvloedingWaarde && (
        <LabeledTextInput
          name="taxatieAlgemeen.toelichtingBeinvloedingWaarde"
          caption="Toelichting beïnvloeding waarde"
          data-testid={`algemeen-taxatie-toelichting`}
        />
      )}
      {voorVerkrijgenLeningTonen && (
        <LabeledBevestigingInput name="taxatieAlgemeen.voorVerkrijgenLening" caption="Voor verkrijgen lening" />
      )}
      {nwwiAanvraagTonen && <LabeledBevestigingInput name="taxatieAlgemeen.nwwiAanvraag" caption="NWWI aanvraag" />}
      {validatieVerplichtTonen && (
        <LabeledBevestigingInput name="taxatieAlgemeen.validatieVerplicht" caption="Validatie verplicht" />
      )}
      {energiePrestatieAdviesTonen && (
        <LabeledBevestigingInput name="taxatieAlgemeen.energiePrestatieAdvies" caption="Energie prestatie advies" />
      )}
      {bouwtechnischeKeuringTonen && (
        <LabeledBevestigingInput name="taxatieAlgemeen.bouwtechnischeKeuring" caption="Bouwtechnische keuring" />
      )}
    </>
  );
};

export default connect<{}, TaxatieSchermType>(AlgemeenTaxatie);
