import { createGetTextResources } from "../../shared/utils/text-resources-algemeen";

export const getFinancieringsbehoefteTextResources = createGetTextResources({
  TooltipArbeidskostenNotaris: `Geeft het gespecificeerde bedrag van de arbeidskosten die door de notaris worden gemaakt bij passeren van de hypotheek en/of het in eigendom verkrijgen van het onderpand`,
  TooltipAdvieskosten: `Voor 'Advieskosten' kun je standaard een vast bedrag of een bedrag per uur instellen. Ga hiervoor naar de beheerapplicatie 'Instellingen'. Vervolgens kun je bij het scherm 'Voorkeuren - Tarieven' het bedrag instellen of wijzigen.`,
  TooltipBemiddelingsvergoeding: `Voor 'Bemiddelingsvergoeding geldlening' kun je standaard een vast bedrag of een bedrag per uur instellen. Ga hiervoor naar de beheerapplicatie 'Instellingen'. Vervolgens kun je bij het scherm 'Voorkeuren - Tarieven' het bedrag instellen of wijzigen.`,
  TooltipOverigeBemiddeling: `Voor 'Overige bemiddelingsvergoeding' kun je standaard een vast bedrag of een bedrag per uur instellen. Ga hiervoor naar de beheerapplicatie 'Instellingen'. Vervolgens kun je bij het scherm 'Voorkeuren - Tarieven' het bedrag instellen of wijzigen.`,
  TooltipDossiervergoeding: `Voor 'Dossiervergoeding geldlening' kun je een standaardbedrag instellen of wijzigen in de beheerapplicatie 'Instellingen', bij het scherm 'Voorkeuren - Tarieven'.`,
  TooltipAfsluitprovisie:
    "Het bedrag 'Afsluitprovisie SVn Starterslening' wordt berekend als voor de subsidieregeling Starterslening is gekozen in het specificatiescherm 'Andere financiering(en)'.",
  TooltipExtraKosten: `Dit zijn de achtergestelde betalingen van de huidige hypotheek en de extra kosten die ermee gemoeid zijn. `,
  TooltipTotaleRestschuld: ` De totale restschuld is inclusief alle kosten.`,
  TooltipVerlagenRestschuld: `Dit bedrag aan eigen geld wordt gebruikt om de restschuld te verlagen.`,
  TooltipOpgebouwdeWaarde: `Deze opgebouwde waarde uit polis(sen) en rekening(en) wordt gebruikt om de restschuld te verlagen.`,
  TooltipMeeFinancierenRestschuld: `Dit bedrag wordt gebruikt voor het leningdeel 'Restschuld' in het scherm ‘Voorstel - Hypotheek’.`,
  TooltipSoortNieuwbouw: "CPO staat voor Collectief Particulier Opdrachtgeverschap.",
  TooltipHypotheekAkte: "Let op: de kosten voor de hypotheekakte is inclusief de kadastrale legeskosten.",
  TooltipTaxatie: `Vul hier de taxatiekosten in voor taxatie van een woning inclusief BTW (volgens het tarief van de Nederlandse Vereniging voor Makelaars en Taxateurs (NVM)). Maar exclusief de kosten: kadastrale recherche en verschotten (reiskosten).`,
  TooltipAdvieskostenBemiddeling: "Je kunt in het scherm 'Voorstel - Uitgangspunten' de advieskosten aanpassen.",
  TooltipBoeterente:
    "Als je de boeterente zelf invult, dan wordt de eenmalige aftrek als gevolg van de boeterente bepaald o.b.v. de af te lossen leningdelen in box 1.",
  TooltipAftelossenOverigeLeningen:
    "De af te lossen overige lening(en) kun je toevoegen in het scherm 'Krediet' bij 'Inventarisatie - Huidige situatie'. ",
  TooltipAftelossenKredieten: "Je kunt in het scherm 'Voorstel - Uitgangspunten' de advieskosten aanpassen.",
  TooltipAftelossenBestaandeHypotheken:
    "Dit bedrag is gebaseerd op het bedrag en de einddatum dat je hebt ingevuld in het veld 'Restant hoofdsom' in het scherm 'Huidige situatie - Hypotheek'. Daarbij is rekening gehouden met de reguliere aflossingen tot de datum van passeren. ",
  TooltipInbrengVoorVerlagenRestschuld: `In het specificatiescherm 'Totale restschuld' kun je de samenstelling van de restschuld invullen. In dit specificatiescherm wordt dan automatisch het bedrag bij 'Inbreng opgebouwde waarde' ingevuld.`,
  TooltipEigengeldOverwaarde: `In de beheerapplicatie 'Instellingen' kun je in het scherm 'Voorkeuren - Berekeningen' aangeven dat je de overwaarde standaard in mindering wilt brengen op de gewenste hypotheek.`,
  TooltipInbrengWaarvanVerlagenRestschuld: `Deze opgebouwde waarde uit polis(sen) en rekening(en) wordt gebruikt om de restschuld te verlagen. In het specificatiescherm 'Totale restschuld' kun je de samenstelling van de restschuld invullen. In dit specificatiescherm wordt dan automatisch het bedrag bij 'Inbreng opgebouwde waarde' ingevuld.`,
  TooltipOverwaardeHuidigeWoningen:
    "Zie voor een specificatie het onderdeel 'Overwaarde huidige woning(en)' op het scherm 'Financieringsbehoefte'.",
  TooltipMeenemenInOverbruggingskrediet: "Verhoog dit veld met de mee te nemen spaarwaarde als er opgebouwde spaarwaarde wordt ingebracht in het overbruggingskrediet.",
  TooltipIngangsdatumOverbruggingskrediet:
    "De ingangsdatum van het overbruggingskrediet is gelijk aan de (vermoedelijke) passeerdatum van de hypotheek.",
  TooltipNettoRentelastPerMaand: `Dit is de gemiddelde netto rentelast over de looptijd van de overbrugging.  Hierbij is uitgegaan van een rente die fiscaal aftrekbaar is en verrekend wordt in de hoogste belastingschijf.`,
  TooltipNettoRentelastTotaal: `Dit is de totale netto rentelast over de gehele looptijd van de overbrugging. Hierbij is uitgegaan van een rente die fiscaal aftrekbaar is en verrekend wordt in de hoogste belastingschijf.`,
  TooltipLastenOverbruggingskrediet: `Dit zijn de huidige lasten en de rentelast van de overbrugging, die in de hypotheek zijn meegefinancierd. Je kunt deze lasten invullen bij het specificatiescherm 'Overbruggingskrediet'. `,
  TooltipAftrekbaar: `Hier kun je aangeven of de kosten aftrekbaar zijn voor de inkomstenbelasting in het jaar van betaling. Het gaat om kosten die betrekking hebben op de aankoop, de verbetering of het onderhoud van de primaire woning.`,
  TooltipGrondkosten: `De grondkosten worden uit het hypotheekbedrag gehaald. Indien (een gedeelte van) de grondkosten uit eigen middelen worden gefinancierd, dan dient dit op de grondkosten in mindering te worden gebracht.`,
  TooltipWaavanEnergieBesparendeBudget:
    "Vul hier het bedrag in dat is gereserveerd voor energiebepalende aanpassingen waarover de klant nog geen beslissing heeft genomen.",
  TooltipWaavanEnergieBesparendeVoorzieningen:
    "Vul hier het exacte bedrag in wat is gereserveerd voor energiebesparende aanpassingen.",
  TooltipVermogenOverwaarde:
    "De overwaarde is gebaseerd op het verschil tussen de waarde van de woning (marktwaarde) en het restant van de huidige hypotheek.",
  TooltipVrijeVermogen: `Het veld Vrij vermogen wordt standaard gevuld met de som van de waarden van de huidige kapitaalverzekeringen en de huidige beleggingen. De waarde kan overschreven worden met een zelf in te voeren bedrag.`,
  TooltipFinancieringsKostenAankoopBestaandeBouwAankoopNieuwbouw:
    "De financieringskosten zijn aftrekbaar over de investering inclusief financieringskosten minus de eigenwonigreserve, gedeeld door de gewenste lening:",
  TooltipFinancieringsKostenOmzetting:
    "De financieringskosten zijn aftrekbaar over de investering gedeeld door de gewenste lening zonder financieringskosten:",
  ToolTipNhgNietMogelijk: "NHG niet mogelijk",
  TooltipKortlopendeLening:
    "Vul hier het bedrag in dat gefinancierd wordt met een kortlopende lening of krediet bij een kredietverstrekker. De lening of het krediet kun je vervolgens toevoegen in het scherm 'Krediet' bij 'Inventarisatie - Huidige situatie'.",
  TooltipNhgNietMogelijk: "NHG is niet mogelijk. ",
  TooltipStartersleningVanToepassing:
    "Bij een SVn Starterslening is de haalbaarheid afhankelijk  van de gemeentelijke voorwaarden.",
  ErrorMaxAdvieskosten:
    "Zorg er voor dat de gespreide betaling van de advieskosten gelijk of lager is dan de advieskosten.",
  ErrorMaxBemiddelingsvergoeding:
    "Zorg er voor dat de gespreide betaling van de bemiddelingskosten gelijk of lager is dan de bemiddelingsvergoeding.",
  ErrorGespreidBetalenVerplicht: "Vul een duur in voor de gespreide betaling.",
  ErrorGespreidBetalenMax: "Vul een duur in tussen 1 en 24 maanden.",
  ErrorTotaalBedragSpecificatieOngelijkVerbouwingVerbetering:
    "Zorg er voor dat het totaalbedrag van de specificatie(s) gelijk is aan het totaalbedrag aan {titel}.",
  ErrorEindDatumNietNaPasseerDatum: "Vul een datum in de toekomst (na de passeerdatum) in.",
  ErrorWaardevermeerderendNietGroterDanVerbouwingVerbetering:
    "Zorg er voor dat het bedrag 'waarvan niet waardevermeerderend' gelijk of lager is dan het totaalbedrag aan {titel}.",
  ErrorEnergiebesparendeVoorzieningenNietGroterDanVerbouwingVerbetering:
    "Zorg er voor dat het bedrag 'waarvan energiebesparende voorzieningen' gelijk of lager is dan het totaalbedrag aan {titel}.",
  ErrorEnergiebespaarBudgetNietGroterDanVerbouwingVerbetering:
    "Zorg er voor dat het bedrag 'waarvan energiebespaarbudget' gelijk of lager is dan het dan het totaalbedrag aan {titel}.",
  ErrorAchterstalligOnderhoudNietGroterDanVerbouwingVerbetering:
    "Zorg er voor dat het bedrag 'waarvan energiebespaarbudget' gelijk of lager is dan het totaalbedrag aan {titel}.",
  ErrorOnbekendeFinanciering: "Vul een geldige waarde in.",
  ErrorInbrengOpgebouwdeWaardeRestschuld:
    "Zorg er voor dat de inbreng opgebouwde waarde van de restschuld gelijk of lager is dan de totale inbreng uit opgebouwde waarde. ",
  ErrorMeeTeFinancierenRestschuld:
    "Zorg er voor dat de inbreng eigen geld voor het verlagen van de restschuld gelijk of lager is dan de totale restschuld.",
  ErrorKoopsomBedrag: "'Zorg er dat 'Koopsom woning' gelijk of hoger is dan 'Waarvan grondprijs'. ",
  ErrorRenteverliesMaandelijksTotaal: "Zorg er voor dat het totale percentage 100% is.",
  ErrorRenteOverbruggingskrediet: "Vul een geldig rentepercentage in.",
  ErrorEindDatum: "Zorg er voor dat de einddatum minimaal 1 maand na de ingangsdatum van het overbruggingskrediet ligt.",
  ErrorAfkoopEinddatum: "Vul een datum in de toekomst in.",
  ErrorDuurVanDeBouwMin: "Vul een duur tussen de 1 en 99 maanden in.",
  ErrorDuurVanDeBouwMax: "Vul een duur tussen de 1 en 99 maanden in.",
  ErrorOnbekendeBerekenwijze: "Vul een geldige waarde in.",
  ErrorHypotecaireInschrijvingLagerdanGewensteHypotheek:
    "Zorg er voor dat de inschrijving gelijk of hoger is dan de totale hypotheek.",
  ErrorDatumBoeterente:
    "Zorg er voor dat de datum van de boeterente minimaal 1 maand voor de einddatum van de rentevastperiode ligt.",
  ErrorResterendePeriode: "De resterende periode dient minimaal 1 maand te zijn.",
  WarningBevoorSchottingsPercentage: "Let op! Niet-beëindigde leningdelen in de huidige situatie worden meegenomen bij de berekening van het bevoorschottingspercentage.",
  WarningOmzettingLeningdeel: "Let op! Voor een 'omzetting' moet minimaal één leningdeel doorlopen dat omgezet kan worden.",
  WarningWijzigenNhg: "Door deze wijziging moet het rentepercentage van de nieuwe leningdelen opnieuw gekozen worden. Ga hiervoor naar de pagina 'Hypotheek' in het voorstel.",
  WarningMaximaleKostenNhg: "- Maximale kosten verkrijgen in eigendom conform NHG bedragen {bedrag}",
  WarningMaximaleLeningNhg: "- Maximale lening o.b.v. de waarde van de woning bedraagt {bedrag}"
});
