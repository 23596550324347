import {
  UitkeringInput,
  UitkeringOutput,
  UitkeringInputUitkeringstermijn,
  KoopsomOutput,
  KoopsomInput,
  KoopsomInputUitkeringstermijn
} from "../../.generated/vermogen/vermogentypes";
import {
  LijfrenteUitgangspuntBerekeningOptions,
  SoortLijfrenteUitkeringOptions
} from "../../.generated/forms/formstypes";

import {
  initISWAsyncSideEffect,
  createISWAsyncSideEffect
} from "../../shared/components/isw-side-effects/create-isw-helpers";

import { berekenInputToDraftSideEffectResult } from "../../shared/types";

import { uitkerendeLijfrentesSchema } from "./uitkerende-lijfrente-schema";
import { UitkerendeLijfrentesType } from "./uitkerende-lijfrente-types";
import { createMapToDl } from "../../shared/utils/create-map-to-dl";
import { jaarMaandInMaanden } from "../../shared/generic-parts/jaar-maand/map-ui-2-dl";

type Context = {
  selected: number;
};

export const mapLijfrenteDetailsToKoopsomInput = createMapToDl(uitkerendeLijfrentesSchema)
  .with<Context>()
  .to<KoopsomInput>({
    uitkeringLijfrente: (v, context) =>
      v.producten[context.selected].indicatieveUitkerendeFase.lijfrenteUitkering.bedrag,
    uitkeringstermijn: ({ producten }, { selected }) =>
      producten[selected].indicatieveUitkerendeFase.termijn as KoopsomInputUitkeringstermijn | null,
    lijfrentetarief: ({ producten }, { selected }) =>
      producten[selected].indicatieveUitkerendeFase.lijfrenteTarief ?? 0,
    uitkeringsduurInMaanden: ({ producten }, { selected }) =>
      jaarMaandInMaanden(producten[selected].indicatieveUitkerendeFase.duurUitkering),
    uitkeringsduurHoogInMaanden: ({ producten }, { selected }) =>
      jaarMaandInMaanden(producten[selected].indicatieveUitkerendeFase.duurUitkeringHoog),
    verhoudingHoogLaag: ({ producten }, { selected }) =>
      producten[selected].indicatieveUitkerendeFase.hoogLaagVerhouding
  });

export const lijfrenteDetailsAsyncKoopsomBerekenen = createISWAsyncSideEffect<UitkerendeLijfrentesType, Context>(
  async ({ draft, settings, fetchData, context, has }) => {
    const { kapitaal } = draft.producten[context.selected];

    if (kapitaal.uitgangspuntBerekening !== LijfrenteUitgangspuntBerekeningOptions.Lijfrenteuitkering) return;

    if (draft.producten[context.selected].indicatieveUitkerendeFase.lijfrenteUitkering.bedrag) {
      const result = await fetchData<KoopsomOutput, KoopsomInput>({
        url: `${settings.vermogenOrigin}/Lijfrente/Koopsom`,
        body: mapLijfrenteDetailsToKoopsomInput(context)
      });

      if (result.isValid && result.resultaat) {
        const {
          resultaat: { koopsom }
        } = result;

        kapitaal.beschikbareKoopsom = koopsom;
      }
    } else {
      kapitaal.beschikbareKoopsom = null;
    }
  }
);

export const mapLijfrenteDetailsToUitkeringInput = createMapToDl(uitkerendeLijfrentesSchema)
  .with<Context>()
  .to<UitkeringInput>({
    koopsom: (v, context) => v.producten[context.selected].kapitaal.beschikbareKoopsom,
    uitkeringstermijn: ({ producten }, { selected }) =>
      producten[selected].indicatieveUitkerendeFase.termijn as UitkeringInputUitkeringstermijn | null,
    lijfrentetarief: ({ producten }, { selected }) =>
      producten[selected].indicatieveUitkerendeFase.lijfrenteTarief ?? 0,
    uitkeringsduurInMaanden: ({ producten }, { selected }) =>
      producten[selected].indicatieveUitkerendeFase.soortLijfrenteUitkering !==
      SoortLijfrenteUitkeringOptions.Levenslang
        ? jaarMaandInMaanden(producten[selected].indicatieveUitkerendeFase.duurUitkering)
        : null,
    uitkeringsduurHoogInMaanden: ({ producten }, { selected }) =>
      jaarMaandInMaanden(producten[selected].indicatieveUitkerendeFase.duurUitkeringHoog),
    verhoudingHoogLaag: ({ producten }, { selected }) =>
      producten[selected].indicatieveUitkerendeFase.hoogLaagVerhouding
  });

export const lijfrenteDetailsAsyncUitkeringBerekenen = createISWAsyncSideEffect<UitkerendeLijfrentesType, Context>(
  async ({ draft, settings, fetchData, context }) => {
    const {
      indicatieveUitkerendeFase: { lijfrenteUitkering },
      kapitaal: { uitgangspuntBerekening }
    } = draft.producten[context.selected];

    if (uitgangspuntBerekening !== LijfrenteUitgangspuntBerekeningOptions.BeschikbareKoopsom) return;

    if (draft.producten[context.selected].kapitaal.beschikbareKoopsom) {
      const result = await fetchData<UitkeringOutput, UitkeringInput>({
        url: `${settings.vermogenOrigin}/Lijfrente/Uitkering`,
        body: mapLijfrenteDetailsToUitkeringInput(context)
      });

      if (result.isValid && result.resultaat) {
        const {
          resultaat: { uitkeringLijfrente }
        } = result;

        berekenInputToDraftSideEffectResult(lijfrenteUitkering, uitkeringLijfrente);
      }
    } else {
      berekenInputToDraftSideEffectResult(lijfrenteUitkering, 0);
    }
  }
);

export const determineUitkerendeLijfrenteDetailsAsyncSideEffects = initISWAsyncSideEffect<
  UitkerendeLijfrentesType,
  Context
>(({ runAsync, context, has }) => {
  const { kapitaal: hasKapitaal, indicatieveUitkerendeFase: hasIndicatieveUitkerendeFase } = has.producten[
    context.selected
  ];

  const rekenen =
    hasKapitaal.changed ||
    hasIndicatieveUitkerendeFase.termijn.changed ||
    hasIndicatieveUitkerendeFase.lijfrenteTarief.changed ||
    hasIndicatieveUitkerendeFase.lijfrenteUitkering.changed ||
    hasIndicatieveUitkerendeFase.duurUitkering.changed ||
    hasIndicatieveUitkerendeFase.duurUitkeringHoog.changed ||
    hasIndicatieveUitkerendeFase.hoogLaagVerhouding.changed;

  if (rekenen) {
    // Altijd async uitvoeren bij wijziging, daar bepalen of er een handeling moet plaatsvinden
    runAsync(lijfrenteDetailsAsyncKoopsomBerekenen(context));
    runAsync(lijfrenteDetailsAsyncUitkeringBerekenen(context));
  }
});
