/* istanbul ignore file */

import { HypotheeklabelMetHypotheekoptiesOutput } from "../../.generated/producten/productentypes";
import useAbortableFetch from "use-abortable-fetch";
import { useRequestInit } from "adviesbox-shared";
import { useMemo } from "react";

export type HypotheeklabelsReturnType = {
  loading: boolean;
  error: Error | null;
  data: HypotheeklabelMetHypotheekoptiesOutput | null;
};

export function useHypotheeklabelsData(geenAankoop: boolean, maatschappijCode: string | null): HypotheeklabelsReturnType {
  const {
    requestInit,
    settings: { productenOrigin }
  } = useRequestInit();

  const url = useMemo(() => {
    let value = `${productenOrigin}/Hypotheeklabels/MetHypotheekopties?inclGeenAankoop=${geenAankoop}`;
    if (maatschappijCode !== null) value = `${value}&maatschappijCode=${maatschappijCode}`;
    return value;
  }, [productenOrigin, geenAankoop, maatschappijCode]);

  const { error, data, loading } = useAbortableFetch<HypotheeklabelMetHypotheekoptiesOutput>(url, requestInit);

  if (typeof data === "string") {
    return {
      error: new Error("Fout bij het laden van hypotheeklabels met hypotheekopties data"),
      data: null,
      loading: false
    };
  }

  return { loading, error, data: data || null };
}
