import { AdviesBoxColumn, createSpanWithId } from "adviesbox-shared";
import { ReactElement } from "react";
import { SituatieSoort } from "../producten-overzicht/infra/producten-overzicht-types";
import { jaarMaandInputNaarJaarMaand } from "../shared/generic-parts/jaar-maand/helpers";
import { AanvragerKeuze } from "../shared/types";

const getContactanten = (aanvragers: AanvragerKeuze): string => {
  switch (aanvragers) {
    case AanvragerKeuze.Aanvrager1:
      return "Aanvrager 1";
    case AanvragerKeuze.Aanvrager2:
      return "Aanvrager 2";
    case AanvragerKeuze.Beiden:
      return "Beiden";
  }
};

export const bepaalVermogensKolommen = (situatie: SituatieSoort): AdviesBoxColumn[] => [
  {
    Header: "Vermogen",
    Cell: (c): ReactElement =>
      createSpanWithId(
        c.index,
        0,
        situatie === "huidig" ? "Bestaand" : c.original.product.doorlopend ? "Doorlopend" : "Nieuw"
      )
  },
  {
    Header: "Instelling",
    Cell: (c): ReactElement =>
      createSpanWithId(c.index, 1, c.original.product.partijNaam, c.original.product.partijNaam)
  },
  {
    Header: "Productnaam",
    Cell: (c): ReactElement =>
      createSpanWithId(c.index, 2, c.original.product.productNaam, c.original.product.productNaam)
  },
  {
    Header: "Contractant(en)",
    Cell: (c): ReactElement =>
      createSpanWithId(c.index, 3, getContactanten(c.original.verzekeringnemers.verzekeringnemers))
  },
  {
    Header: "Looptijd",
    Cell: (c): ReactElement => {
      const title = jaarMaandInputNaarJaarMaand(c.original.product.looptijd) || "99 jaar";
      return createSpanWithId(c.index, 4, title, title);
    }
  },
  {
    Header: "Doelkapitaal",
    Cell: "CurrencyInput",
    accessor: "kapitaalopbouw.doelkapitaalBedrag",
    config: { readonly: true }
  },
  {
    Header: "Inleg",
    Cell: "CurrencyInput",
    accessor: "inleggegevens.inleg",
    config: { readonly: true, decimalen: 2 }
  },
  {
    Header: "Duur inleg",
    Cell: (c): ReactElement => {
      const title = jaarMaandInputNaarJaarMaand(c.original.inleggegevens.duur) || "99 jaar";
      return createSpanWithId(c.index, 7, title, title);
    }
  },
  {
    Header: "Verpand",
    Cell: (c): ReactElement =>
      createSpanWithId(c.index, 8, c.original.verpanding.verpandAanGeldverstrekker ? "Ja" : "Nee")
  },
  {
    Cell: "DeleteButton"
  }
];
