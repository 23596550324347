import { logErrorToApplicationInsights } from "adviesbox-shared";
import reactFastCompare from "react-fast-compare";
import { assertNever, keysToLowerCaseFirst } from "../../shared/utils/helpers";
import {
  aanvraagKenmerken,
  AanvraagKenmerken,
  AovKenmerken,
  dekkingHypothekenKenmerken,
  DekkingHypothekenKenmerken,
  dekkingKenmerken,
  DekkingKenmerken,
  depotKenmerken,
  DepotKenmerken,
  EenVanDeKenmerken,
  fiscaleRegelingKenmerken,
  FiscaleRegelingKenmerken,
  gekoppeldProductKenmerken,
  GekoppeldProductKenmerken,
  hdnaanvraagKenmerken,
  generiekeKapitaalopbouwKenmerken,
  GeneriekeKapitaalopbouwKenmerkenKenmerken,
  HypothekenKenmerken,
  indicatieveUitkerendeFaseKenmerken,
  IndicatieveUitkerendeFaseKenmerken,
  kapitaalopbouwKenmerken,
  KapitaalopbouwKenmerken,
  KapitaalverzekeringKenmerken,
  KredietKenmerken,
  leninggegevensKenmerken,
  leningGegevensKenmerken,
  LeninggegevensKenmerken,
  LeningGegevensKenmerken,
  onttrekkingenKenmerken,
  OnttrekkingenKenmerken,
  OrvKenmerken,
  persoonkeuzeKenmerken,
  PersoonkeuzeKenmerken,
  PoliswaardeKenmerken,
  poliswaardeKenmerken,
  premieKenmerken,
  PremieKenmerken,
  productKenmerken,
  ProductKenmerken,
  ProductKenmerkenDlType,
  ProductSoort,
  UitkerendeLijfrenteKenmerken,
  validatieKenmerken,
  ValidatieKenmerken,
  VermogenKenmerken,
  verpandingKenmerken,
  VerpandingKenmerken,
  verzekerdenKenmerken,
  VerzekerdenKenmerken,
  verzekeringnemerKenmerken,
  VerzekeringnemerKenmerken,
  HdnaanvraagKenmerken,
  OpmerkingKenmerken,
  opmerkingKenmerken
} from "./product-kenmerken-types";

const getKenmerken = <T>(
  productKenmerkenData: ProductKenmerkenDlType,
  categorie: string,
  baseObj: T,
  stripUnknown = false
): T => {
  const prodKenmerken: any = productKenmerkenData.productkenmerkCategorieen.find((c): any => c.categorie === categorie);
  if (!prodKenmerken) {
    const err = new Error(`Kan categorie voor kenmerken niet vinden: ${categorie}`);

    /* istanbul ignore next */
    if (process.env.NODE_ENV === "production") logErrorToApplicationInsights(err);

    return { ...baseObj };
  }
  const mapped = keysToLowerCaseFirst(prodKenmerken.productkenmerken);

  if (stripUnknown) {
    if (
      !reactFastCompare(
        Object.keys(baseObj).sort(),
        Object.keys(mapped)
          .filter((mapval: string): string | undefined =>
            Object.keys(baseObj).find((c: string): boolean => c === mapval)
          )
          .sort()
      )
    ) {
      const err = new Error(`Er is een mismatch ontstaan in de kenmerken mapping, van categorie: ${categorie}`);
      /* istanbul ignore next */
      if (process.env.NODE_ENV === "production") logErrorToApplicationInsights(err);
    }

    const splitObj = { ...baseObj };
    const baseObjKeys = Object.keys(baseObj);

    Object.keys(mapped)
      .filter((mapval: string): string | undefined => baseObjKeys.find((c: string): boolean => c === mapval))
      .forEach((c: string): any => {
        (splitObj as { [index: string]: any })[c] = (mapped as { [index: string]: any })[c];
      });

    return splitObj;
  } else {
    return { ...baseObj, ...mapped };
  }
};

export function mapKenmerken(productSoort: ProductSoort, data: ProductKenmerkenDlType): EenVanDeKenmerken | null {
  if (!data.isValid) {
    /* istanbul ignore next */
    const err = new Error(`Ongeldige productkenmerken response uit platform gedetecteerd.`);
    if (process.env.NODE_ENV === "production") logErrorToApplicationInsights(err);
    return { reden: data.message || "onbekende fout" };
  }

  switch (productSoort) {
    case "Hypotheek":
      const hypotheekKenmerken: HypothekenKenmerken = {
        product: getKenmerken<ProductKenmerken>(data, "Product", productKenmerken),
        leninggegeven: getKenmerken<LeninggegevensKenmerken>(data, "Leninggegevens", leninggegevensKenmerken),
        persoonkeuze: getKenmerken<PersoonkeuzeKenmerken>(data, "Persoonkeuze", persoonkeuzeKenmerken),
        dekking: getKenmerken<DekkingHypothekenKenmerken>(data, "Dekking", dekkingHypothekenKenmerken),
        kapitaalopbouw: getKenmerken<KapitaalopbouwKenmerken>(data, "Kapitaalopbouw", kapitaalopbouwKenmerken),
        fiscaleRegeling: getKenmerken<FiscaleRegelingKenmerken>(data, "FiscaleRegeling", fiscaleRegelingKenmerken),
        premie: getKenmerken<PremieKenmerken>(data, "Premie", premieKenmerken),
        gekoppeldProduct: getKenmerken<GekoppeldProductKenmerken>(data, "GekoppeldProduct", gekoppeldProductKenmerken),
        opmerking: getKenmerken<OpmerkingKenmerken>(data, "Opmerking", opmerkingKenmerken),
        validaties: getKenmerken<ValidatieKenmerken>(data, "Validaties", validatieKenmerken)
      };
      return hypotheekKenmerken;

    case "Orv":
      const orvKenmerken: OrvKenmerken = {
        product: getKenmerken<ProductKenmerken>(data, "Product", productKenmerken),
        premie: getKenmerken<PremieKenmerken>(data, "Premie", premieKenmerken),
        verzekerden: getKenmerken<VerzekerdenKenmerken>(data, "Persoonkeuze", verzekerdenKenmerken, true),
        dekking: getKenmerken<DekkingKenmerken>(data, "Dekking", dekkingKenmerken),
        verzekeringnemer: getKenmerken<VerzekeringnemerKenmerken>(
          data,
          "Persoonkeuze",
          verzekeringnemerKenmerken,
          true
        ),
        verpanding: getKenmerken<VerpandingKenmerken>(data, "Verpanding", verpandingKenmerken),
        validaties: getKenmerken<ValidatieKenmerken>(data, "Validaties", validatieKenmerken),
        aanvraag: getKenmerken<AanvraagKenmerken>(data, "Aanvraag", aanvraagKenmerken),
        hdnaanvraag: getKenmerken<HdnaanvraagKenmerken>(data, "HdnAanvraag", hdnaanvraagKenmerken)
      };
      return orvKenmerken;

    case "Aov":
      // todo: nagaan welke onderdelen nodig zijn, en aanvullen...
      const aovKenmerken: AovKenmerken = {
        aanvraag: getKenmerken<AanvraagKenmerken>(data, "Aanvraag", aanvraagKenmerken),
        product: getKenmerken<ProductKenmerken>(data, "Product", productKenmerken),
        premie: getKenmerken<PremieKenmerken>(data, "Premie", premieKenmerken),
        persoonkeuze: getKenmerken<PersoonkeuzeKenmerken>(data, "Persoonkeuze", persoonkeuzeKenmerken),
        verpanding: getKenmerken<VerpandingKenmerken>(data, "Verpanding", verpandingKenmerken),
        dekking: getKenmerken<DekkingKenmerken>(data, "Dekking", dekkingKenmerken),
        validaties: getKenmerken<ValidatieKenmerken>(data, "Validaties", validatieKenmerken)
      };
      return aovKenmerken;

    case "Krediet":
      const kredietKenmerken: KredietKenmerken = {
        product: getKenmerken<ProductKenmerken>(data, "Product", productKenmerken),
        leningGegevens: getKenmerken<LeningGegevensKenmerken>(data, "LeningGegevens", leningGegevensKenmerken)
      };
      return kredietKenmerken;

    case "UitkerendeLijfrente":
      const uitkerendeLijfrenteKenmerken: UitkerendeLijfrenteKenmerken = {
        product: getKenmerken<ProductKenmerken>(data, "Product", productKenmerken),
        verzekeringnemer: getKenmerken<VerzekeringnemerKenmerken>(
          data,
          "Persoonkeuze",
          verzekeringnemerKenmerken,
          true
        ),
        verzekerden: getKenmerken<VerzekerdenKenmerken>(data, "Persoonkeuze", verzekerdenKenmerken, true),
        indicatieveUitkerendeFaseKenmerken: getKenmerken<IndicatieveUitkerendeFaseKenmerken>(
          data,
          "IndicatieveUitkerendeFaseKenmerken",
          indicatieveUitkerendeFaseKenmerken
        )
      };
      return uitkerendeLijfrenteKenmerken;

    case "Vermogen":
      const vermogenKenmerken: VermogenKenmerken = {
        product: getKenmerken<ProductKenmerken>(data, "Product", productKenmerken),
        verzekeringnemer: getKenmerken<VerzekeringnemerKenmerken>(
          data,
          "Persoonkeuze",
          verzekeringnemerKenmerken,
          true
        ),
        depot: getKenmerken<DepotKenmerken>(data, "Depot", depotKenmerken),
        kapitaalopbouw: getKenmerken<GeneriekeKapitaalopbouwKenmerkenKenmerken>(
          data,
          "GeneriekeKapitaalopbouw",
          generiekeKapitaalopbouwKenmerken
        ),
        premie: getKenmerken<PremieKenmerken>(data, "Premie", premieKenmerken),
        verpanding: getKenmerken<VerpandingKenmerken>(data, "Verpanding", verpandingKenmerken),
        onttrekkingen: getKenmerken<OnttrekkingenKenmerken>(data, "Onttrekkingen", onttrekkingenKenmerken),
        fiscaleRegeling: getKenmerken<FiscaleRegelingKenmerken>(data, "FiscaleRegeling", fiscaleRegelingKenmerken)
      };
      return vermogenKenmerken;

    case "Kapitaalverzekering":
      const kapitaalverzekeringKenmerken: KapitaalverzekeringKenmerken = {
        product: getKenmerken<ProductKenmerken>(data, "Product", productKenmerken),
        verzekeringnemer: getKenmerken<VerzekeringnemerKenmerken>(
          data,
          "Persoonkeuze",
          verzekeringnemerKenmerken,
          true
        ),
        verzekerden: getKenmerken<VerzekerdenKenmerken>(data, "Persoonkeuze", verzekerdenKenmerken, true),
        kapitaalopbouw: getKenmerken<GeneriekeKapitaalopbouwKenmerkenKenmerken>(
          data,
          "GeneriekeKapitaalopbouw",
          generiekeKapitaalopbouwKenmerken
        ),
        premie: getKenmerken<PremieKenmerken>(data, "Premie", premieKenmerken),
        poliswaarde: getKenmerken<PoliswaardeKenmerken>(data, "Poliswaarde", poliswaardeKenmerken),
        verpanding: getKenmerken<VerpandingKenmerken>(data, "Verpanding", verpandingKenmerken)
      };
      return kapitaalverzekeringKenmerken;

    default:
      return assertNever(productSoort);
  }
}
