/* istanbul ignore file */
import { useRequestInit, ForceRerenderContext } from "adviesbox-shared";
import { DependencyList, useContext, useEffect } from "react";
import useAbortableFetch from "use-abortable-fetch";
import {
  SnelInzichtProductoverzicht,
  SnelInzichtProductoverzichtenOutput
} from "../../.generated/rapportage/rapportagetypes";

export type UseProductenAanwezigDataResult = {
  error: Error | null;
  productLijst: { [key: string]: SnelInzichtProductoverzicht } | null;
  loading?: boolean;
};

export function useProductenAanwezigData(
  voorstelId: string | null,
  deps?: DependencyList
): UseProductenAanwezigDataResult {
  const loadingDone = useContext(ForceRerenderContext);

  const {
    params,
    requestInit,
    settings: { rapportageOrigin }
  } = useRequestInit();

  let url = "";

  if (!voorstelId) url = `${rapportageOrigin}/Adviesdossiers/${params.adviesdossier}/SnelInzicht/Productoverzicht`;
  if (voorstelId) url = `${rapportageOrigin}/Voorstellen/${voorstelId}/SnelInzicht/Productoverzicht`;

  const { error, data, loading } = useAbortableFetch<SnelInzichtProductoverzichtenOutput>(url, requestInit);

  useEffect((): void => {
    if (!loading && !!data && loadingDone) {
      loadingDone();
    }
  }, [loading, data, loadingDone]);

  if (typeof data === "string") {
    return { error: new Error("Fout bij het laden van producten data"), productLijst: {} };
  }

  return {
    error,
    productLijst: !data || !data.productoverzicht ? null : data.productoverzicht,
    loading
  };
}
