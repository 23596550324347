import React, { ReactElement } from "react";
import { connect, FormikContextType } from "formik";

import { SelectedProps } from "../../shared/types";
import { LabeledCurrencyInput } from "adviesbox-shared";
import { PensioenenState } from "../infra/pensioen-schema";

const Werknemersbijdragen = ({
  selected,
  formik: {
    values: { pensioenen }
  }
}: SelectedProps & {
  formik: FormikContextType<PensioenenState>;
}): ReactElement => {
  if (selected >= pensioenen.length) return <></>;

  return (
    <>
      <LabeledCurrencyInput
        caption="Bijdrage pré-pensioen"
        name={`pensioenen[${selected}].werknemersbijdragen.bijdragePrePensioen`}
      />

      <LabeledCurrencyInput
        caption="Bijdrage ouderdomspensioen"
        name={`pensioenen[${selected}].werknemersbijdragen.bijdrageOuderdomspensioen`}
      />
    </>
  );
};

export default connect<SelectedProps, PensioenenState>(Werknemersbijdragen);
