import { QuestionType, CardSpec } from "../../infra/klantprofiel-schema";
import { KlantprofielVraagOptions } from "../../../.generated/forms/formstypes";

export const rentevastperiodeQuestionSpecs: CardSpec[] = [
  {
    questions: [
      {
        question: KlantprofielVraagOptions.BereidRisicoRentestijging,
        type: QuestionType.radio
      }
    ]
  },
  {
    questions: [
      {
        question: KlantprofielVraagOptions.HoelangVastzettenHypotheekrente,
        type: QuestionType.jaar
      }
    ]
  },
  {
    questions: [
      {
        question: KlantprofielVraagOptions.VoornaamsteRedenVastzettenHypotheekrente,
        type: QuestionType.checkbox
      }
    ]
  },
  {
    questions: [
      {
        question: KlantprofielVraagOptions.HoeStijgingMaandlastenOpvangen,
        type: QuestionType.checkbox
      }
    ]
  }
];
