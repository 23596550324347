import { LocalDate, ChronoUnit } from "@js-joda/core";
import { SituatieSoort } from "../../producten-overzicht/infra/producten-overzicht-types";
import { getHypotheekTextResources } from "./hypotheek-resources";
import { HypotheekSchemaContextType } from "./hypotheek-schema";
import { HypothekenState } from "./hypotheek-types";
import { hasValue } from "adviesbox-shared";

export const checkTotalen = (values: HypothekenState, situatie: SituatieSoort): HypotheekSchemaContextType => {
  const result: HypotheekSchemaContextType = {
    oorspronkelijkeHoofdsomTotaalError: false,
    leningdeelHoofdsomTotaalError: false,
    situatie: situatie,
    geboorteDatumAanvrager1: values.aanvrager1?.geboortedatum || null,
    geboorteDatumAanvrager2: values.aanvrager2?.geboortedatum || null,
    productKenmerken: null
  };
  values.panden.forEach(pand => {
    const producten =
      situatie === "huidig"
        ? values.producten.filter(
            z => z.hypotheekProductDetails != null && z.hypotheekProductDetails.hypotheekOpWoning === pand.pandId
          )
        : values.producten;
    if (
      pand.totaleHypotheekBedrag &&
      producten
        .filter(e => !e.product.doorlopend)
        .map(e => e.leningdeelgegevens.leningdeelHoofdsom.bedrag || 0)
        .reduce((a, b) => a + b) > pand.totaleHypotheekBedrag
    ) {
      result.leningdeelHoofdsomTotaalError = true;
    }
    if (
      pand.totaleHypotheekBedrag &&
      producten.map(e => e.leningdeelgegevens.oorspronkelijkeHoofdsom || 0).reduce((a, b) => a + b) >
        pand.totaleHypotheekBedrag
    ) {
      result.oorspronkelijkeHoofdsomTotaalError = true;
    }
  });

  return result;
};

export const getLeeftijdValidationText = (
  ingangsdatumLeningdeel: LocalDate,
  geboorteDatumAanvrager1: LocalDate | null,
  geboorteDatumAanvrager2: LocalDate | null,
  minimaleLeeftijdInJaren: number
): string | null => {
  if (
    geboorteDatumAanvrager2 != null &&
    ((hasValue(geboorteDatumAanvrager1) &&
      geboorteDatumAanvrager1.until(ingangsdatumLeningdeel, ChronoUnit.YEARS) < minimaleLeeftijdInJaren) ||
      (hasValue(geboorteDatumAanvrager2) &&
        geboorteDatumAanvrager2.until(ingangsdatumLeningdeel, ChronoUnit.YEARS) < minimaleLeeftijdInJaren))
  )
    return getHypotheekTextResources("ErrorOpnameAowBeide").replace("{minimaleLeeftijdInJaren}", minimaleLeeftijdInJaren.toString());

  if (
    hasValue(geboorteDatumAanvrager1) && geboorteDatumAanvrager1.until(ingangsdatumLeningdeel, ChronoUnit.YEARS) < minimaleLeeftijdInJaren
  ) {
    return getHypotheekTextResources("ErrorOpnameAowAanvrager").replace("{minimaleLeeftijdInJaren}", minimaleLeeftijdInJaren.toString());
  }

  return null;
};
