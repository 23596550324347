import {
  ErfpachtOpWoning,
  EwrOfEws,
  HuidigeWoonsituatie,
  BewonersOpties,
  Woonsituatiesoort as FormsHuidigeWoonsituatie,
  HuidigPand,
  Huurwoning,
  HypotheekOpWoning,
  WoningInEigendom,
  GebruikPandSoort as FormsGebruikPand,
  Woonsituatie as WoonsituatieDlEntry,
  WoonsituatieRenteaftrek
} from "../../.generated/forms/formstypes";
import { mapAdresUiNaarDl } from "../../shared/generic-parts/adres/map-ui-2-dl";
import { createMapEnum } from "../../shared/utils/create-map-enum";
import { createMapToDl } from "../../shared/utils/create-map-to-dl";
import { hasValue } from "../../shared/utils/helpers";
import {
  Bewoners as TypesHuidigeBewoners,
  eigenwoningreserveSchema,
  eigenwoningschuldModalEntrySchema,
  erfpachtSchema,
  GebruikPand as TypesGebruikPand,
  HdnAnders,
  huidigPandSchema,
  huurwoningSchema,
  hypotheekSchema,
  persoonsgegevensSchema,
  SoortWoonsituatie as TypesHuidigeWoonsituatie,
  woonsituatieListSchema,
  woonsituatieSchema
} from "./woonsituatie-schema";
import { mapLocalDateToString } from "adviesbox-shared";

export const mapHuidigeWoonsituatieSoortWoonsituatie = createMapEnum(TypesHuidigeWoonsituatie).to({
  Eigendom: FormsHuidigeWoonsituatie.Eigendom,
  Huur: FormsHuidigeWoonsituatie.Huur,
  Inwonend: FormsHuidigeWoonsituatie.Inwonend
});

export const mapHuidigeWoonsituatieBewoners = createMapEnum(TypesHuidigeBewoners).to({
  Aanvrager1: BewonersOpties.Aanvrager1,
  Aanvrager2: BewonersOpties.Aanvrager2,
  Beiden: BewonersOpties.Beiden
});

export const mapWoningInEigendomGebruikPand = createMapEnum(TypesGebruikPand).to({
  PrimaireWoning: FormsGebruikPand.PrimaireWoning,
  TweedeWoning: FormsGebruikPand.TweedeWoning
});

export const mapRenteaftrekModal = createMapToDl(eigenwoningschuldModalEntrySchema)
  .with<number>()
  .to<WoonsituatieRenteaftrek>({
    bedrag: v => v.bedrag,
    aanvangsdatum: v => mapLocalDateToString(v.begindatum),
    einddatum: v => mapLocalDateToString(v.einddatum),
    volgnummer: (_, i) => i + 1,
    renteaftrekId: v => v.renteaftrekId
  });

const mapEwrOfEws = createMapToDl(eigenwoningreserveSchema).to<EwrOfEws>({
  bestaandeEwrBedrag: v => v.ewrVerleden,
  ewsOvergangsrechtBedrag: v => v.ewsOvergangsrecht,
  ewsOvergangsrechtRenteaftrekken: v =>
    v.ewsOvergangsrecht ? v.ewsOvergangsrechtModal.entries.map((val, i) => mapRenteaftrekModal(i)(val)) : null,
  ewsVerplichteAflossingBedrag: v => v.ewsAflossing,
  ewsVerplichteAflossingRenteaftrekken: v =>
    v.ewsAflossing ? v.ewsAflossingModal.entries.map((val, i) => mapRenteaftrekModal(i)(val)) : null
});

const mapHuurwoning = createMapToDl(huurwoningSchema).to<Huurwoning>({
  huurPerMaandBedrag: v => v.huurPerMaand,
  stijgingPercentage: v => v.stijging
});

const mapErfpacht = createMapToDl(erfpachtSchema).to<ErfpachtOpWoning>({
  erfpachtBedrag: v => v.canon,
  erfpachtEeuwigdurend: v => v.eeuwigdurendErfpacht,
  erfpachtEinddatum: v => (v.eeuwigdurendErfpacht ? null : mapLocalDateToString(v.einddatum)),
  erfpachtParticulier: v => v.particulierErfpacht
});

const mapHuidigPand = createMapToDl(huidigPandSchema).to<HuidigPand>({
  adres: v => (!v.adresIsWoonadres ? mapAdresUiNaarDl(v.adres) : null),
  typePand: () => null, // TODO: is obsolete geworden met HDN 24. Dient nog verwijderd te worden (als in backend verwijderd)
  energieklasse: v => (v.energielabel ? parseInt(v.energielabel) : null),
  energieklasseDatum: v => (v.energieklasseDatum ? mapLocalDateToString(v.energieklasseDatum) : null),
  gelijkAanWoonadres: v => v.adresIsWoonadres,
  afbouwgarantie: v => v.onderpand.koopAannemingsOvMetVormAfbouwGarantie,
  appartement: v => v.onderpand.appartementsRecht,
  bouwaard: v => (v.onderpand.bouwaard ? parseInt(v.onderpand.bouwaard) : null),
  bouwjaar: v => v.onderpand.bouwjaar,
  bouwnummer: v => v.onderpand.bouwNummer,
  bouwplan: v => v.onderpand.bouwPlan,
  gedeeld: v => v.onderpand.gedeeltelijkePerceel,
  kadasterGemeente: v => v.onderpand.kadastraleGemeente,
  kadasterNummer: v => v.onderpand.kadastraleNummer,
  kadasterSectie: v => v.onderpand.kadastraleSectie,
  ontbindendeVoorwaardenDatum: v => mapLocalDateToString(v.onderpand.ontbindendeVoorwaardenTot),
  opleverdatum: v => mapLocalDateToString(v.onderpand.opleverDatum),
  servicekosten: v => v.onderpand.serviceKosten,
  staatOnderhoud: v => (v.onderpand.staatVanOnderhoud ? parseInt(v.onderpand.staatVanOnderhoud) : null),
  taxatieAanwezig: v => v.onderpand.taxatieAanwezig,
  taxatiedatum: v => (v.onderpand.taxatieAanwezig ? mapLocalDateToString(v.onderpand.taxatieDatum) : null),
  verkoopOnderVoorwaarden: v => v.onderpand.verkoopOnderVoorwaarden,
  verkoopOnderVoorwaardenConstructie: v =>
    v.onderpand.verkoopOnderVoorwaarden && v.onderpand.constructie ? parseInt(v.onderpand.constructie) : null,
  verkoopOnderVoorwaardenConstructieOmschrijving: v =>
    v.onderpand.verkoopOnderVoorwaarden && v.onderpand.constructie === `${HdnAnders}`
      ? v.onderpand.constructieOmschrijving
      : null,
  soortConstructieAnders: v =>
    v.onderpand.verkoopOnderVoorwaarden && v.onderpand.erfpachtConstructieAnders
      ? parseInt(v.onderpand.erfpachtConstructieAnders)
      : null,
  objectSoort: v => (v.onderpand.objectSoort ? parseInt(v.onderpand.objectSoort) : null),
  bijzonderObjectSoort: v => (v.onderpand.bijzonderObjectSoort ? parseInt(v.onderpand.bijzonderObjectSoort) : null),
  heeftGarage: v => v.onderpand.heeftGarage,
  recreatieveBewoning: v => v.onderpand.recreatieveBewoning
});

const mapHypotheek = createMapToDl(hypotheekSchema).to<HypotheekOpWoning>({
  inschrijvingBedrag: v => v.inschrijving.bedrag,
  inschrijvingOvernemen: v => v.inschrijving.berekenen,
  oorspronkelijkHypotheekbedrag: v => v.oorspronkelijk
});

const mapWoningInEigendom = createMapToDl(woonsituatieSchema)
  .with(persoonsgegevensSchema)
  .to<WoningInEigendom>({
    erfpachtOpWoning: v => (v.erfpacht.hasErfpacht ? mapErfpacht(v.erfpacht) : null),
    huidigPand: v => mapHuidigPand(v.huidigPand),
    hypotheekOpWoning: v => (v.hypotheek.hasHypotheek ? mapHypotheek(v.hypotheek) : null),
    eigenWoningForfaitBedrag: v => v.woningInEigendom.eigenwoningforfait.bedrag,
    eigenWoningForfaitOvernemen: v => v.woningInEigendom.eigenwoningforfait.berekenen,
    eigendom1Percentage: v => v.woningInEigendom.percentageAanvrager,
    eigendom2Percentage: (v, p) => (p.naamPartner?.klantId ? v.woningInEigendom.percentagePartner : null),
    gebruikPand: v => mapWoningInEigendomGebruikPand(v.woningInEigendom.gebruikWoning),
    marktwaardeBedrag: v => v.woningInEigendom.marktwaarde,
    wozWaarde: v => v.woningInEigendom.wozWaarde,
    heeftHypotheekOpWoning: v => v.hypotheek.hasHypotheek
  });

const mapWoonsituatie = createMapToDl(woonsituatieListSchema)
  .with<number>()
  .to<HuidigeWoonsituatie>({
    bewoners: (v, i) => mapHuidigeWoonsituatieBewoners(v.woonsituaties[i].huidigeWoonsituatie.bewoners),
    ewrOfEws: (v, i) =>
      v.woonsituaties[i].eigenwoningreserve.eigenwoningreserveToggle
        ? mapEwrOfEws(v.woonsituaties[i].eigenwoningreserve)
        : null,
    huurwoning: (v, i) =>
      v.woonsituaties[i].huidigeWoonsituatie.woonsituatie === TypesHuidigeWoonsituatie.Huur
        ? mapHuurwoning(v.woonsituaties[i].huurwoning)
        : null,
    soortWoonsituatie: (v, i) =>
      mapHuidigeWoonsituatieSoortWoonsituatie(v.woonsituaties[i].huidigeWoonsituatie.woonsituatie),
    volgnummer: (_, i) => i + 1,
    woningInEigendom: (v, i) =>
      v.woonsituaties[i].huidigeWoonsituatie.woonsituatie === TypesHuidigeWoonsituatie.Eigendom
        ? mapWoningInEigendom(v.persoonsgegevens)(v.woonsituaties[i])
        : null,
    woonsituatieId: (v, i) => v.woonsituaties[i].woonsituatieId
  });

export const mapWoonsituatieUiToDl = createMapToDl(woonsituatieListSchema).to<WoonsituatieDlEntry>({
  huidigeWoonsituaties: v => v.woonsituaties.map((_, i: number): HuidigeWoonsituatie => mapWoonsituatie(i)(v)),
  adviesMetMedeaanvrager: v => hasValue(v.persoonsgegevens.naamPartner?.klantId),
  aanvrager1: v => null, // todo!!
  aanvrager2: v => null, // todo!!,
  adresVan1eKlant: v => null, // todo!!
  adresVan2eKlant: v => null // todo!!, // EVEN GOED OPLETTEN....
});
