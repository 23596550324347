import { jaarMaandInMaanden } from "adviesbox-shared";
import { Draft } from "immer";
import {
  SoortBerekeningOptionsKv,
  SoortKapitaalverzekeringsrekeningOptions,
  BasisdekkingOptions,
  OrvUitkeringssoortOptions
} from "../../.generated/forms/formstypes";
import { productDraftSideEffects } from "../../producten-overzicht/product/product-side-effects";
import { verpandingSideEffects } from "../../producten-overzicht/verpanding/determine-verpanding-side-effects";
import { createISWSideEffect, initISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";
import { HasChanged } from "../../shared/components/isw-side-effects/has-changed-helper";
import { assertNever } from "../../shared/utils/helpers";
import { poliswaardeModalSchema } from "./kapitaalverzekering-schema";
import {
  KapitaalverzekeringenType,
  KapitaalverzekeringType,
  OverlijdensrisicodekkingType
} from "./kapitaalverzekering-types";

export type KapitaalverzekeringDetailContext = {
  selected: number;
};

export const overlijdensrisicodekkingSideEffects = createISWSideEffect<OverlijdensrisicodekkingType | null>(
  ({ has, draft, prev }): void => {
    if (draft) {
      if (has.basisdekking.changed) {
        const basisdekkingOptions = [
          BasisdekkingOptions.Geen,
          BasisdekkingOptions.PercentageOpgebouwdeWaarde,
          "110%",
          "90%"
        ];
        if (!basisdekkingOptions.includes(draft.basisdekking)) {
          draft.verzekerdKapitaalBedrag = null;
          draft.soortRisicodekking = null;
        } else {
          draft.soortRisicodekking = OrvUitkeringssoortOptions.Gelijkblijvend;
        }
      }

      if (has.basisdekking.changed && draft.basisdekking !== BasisdekkingOptions.PercentageOpgebouwdeWaarde) {
        draft.waardePercentage = null;
      }

      if (has.soortRisicodekking.changed) {
        if (
          draft.soortRisicodekking === null ||
          draft.soortRisicodekking === OrvUitkeringssoortOptions.Gelijkblijvend
        ) {
          draft.annuiteitsPercentage = null;
          draft.dekkingDaaltTotBedrag = null;
          draft.duurdalingInJaren = null;
        } else {
          draft.dekkingDaaltTotBedrag = 0;
          draft.duurdalingInJaren = 30;
        }
      }

      if (has.levenslang.changed && draft.levenslang) {
        draft.maxDuurInJaren = null;
      }

      if (
        has.partnerrentePerJaarBedrag.changed &&
        !draft.partnerrentePerJaarBedrag !== !prev?.partnerrentePerJaarBedrag
      ) {
        draft.levenslang = !draft.partnerrentePerJaarBedrag;
        draft.directIngaandeRente = !draft.partnerrentePerJaarBedrag;
      }

      if (has.levenslang.changed && draft.levenslang) {
        draft.maxDuurInJaren = null;
      }

      if (has.directIngaandeRente.changed && draft.directIngaandeRente) {
        draft.uitsteldatum = null;
      }
    }
  }
);

export const premieGegevensSideEffects = createISWSideEffect<KapitaalverzekeringType>(({ has, draft }): void => {
  if (
    has.kapitaalopbouw.doelrendementPercentage.changed &&
    draft.kapitaalopbouw.soortRekening !== SoortKapitaalverzekeringsrekeningOptions.Spaar
  ) {
    draft.kapitaalopbouw.voorbeeldrendementPercentage = draft.kapitaalopbouw.doelrendementPercentage;
  }
  if (draft.premieGegevens) {
    // Update totale premie when spaar premie or risico premie changes
    if (has.premieGegevens.spaarPremieLaag.changed || has.premieGegevens.risicoPremieLaag.changed) {
      draft.premieGegevens.totalePremieLaag = 
        (draft.premieGegevens.spaarPremieLaag || 0) + (draft.premieGegevens.risicoPremieLaag || 0);
    }
    if (has.premieGegevens.spaarPremieHoog.changed || has.premieGegevens.risicoPremieHoog.changed) {
      draft.premieGegevens.totalePremieHoog = 
        (draft.premieGegevens.spaarPremieHoog || 0) + (draft.premieGegevens.risicoPremieHoog || 0);
    }

    if (has.premieGegevens.hoogLaagLooptijd.changed) {
      const hoogLaagLooptijdInMaanden = jaarMaandInMaanden(draft.premieGegevens.hoogLaagLooptijd);

      if (hoogLaagLooptijdInMaanden) {
        // hoogLaagLooptijd wijzigen => hoogLaagEinddatum bijwerken
        draft.premieGegevens.hoogLaagEinddatum =
          draft.product.ingangsdatum?.plusMonths(hoogLaagLooptijdInMaanden) ?? null;
      } else {
        // hoogLaagLooptijd leeg => Alle *hoog* properties legen
        draft.premieGegevens.hoogLaagVerhouding = null;
        draft.premieGegevens.hoogLaagEinddatum = null;
        draft.premieGegevens.spaarPremieHoog = null;
        draft.premieGegevens.risicoPremieHoog = null;
        draft.premieGegevens.totalePremieHoog = null;
      }
    }

    if (has.product.looptijd.changed) {
      // Product looptijd wijzigen => looptijd bijwerken
      draft.premieGegevens.looptijd = draft.product.looptijd;
    }
  }
});

export const maakBerekendeWaardesLeeg = (
  draft: Draft<KapitaalverzekeringType>,
  has: HasChanged<KapitaalverzekeringType>
): void => {
  if (!has.poliswaarde.waardeopbouwBedrag.changed) {
    draft.poliswaarde.waardeopbouwBedrag = null;
    draft.poliswaarde.poliswaardeModal = poliswaardeModalSchema.default();
    draft.poliswaarde.poliswaardeModal.beginJaar = draft.product.ingangsdatum?.year() ?? null;
  }

  const soortBerekening = draft.kapitaalopbouw.soortBerekening as SoortBerekeningOptionsKv | null;
  switch (soortBerekening) {
    case SoortBerekeningOptionsKv.Premie:
      if (!has.premieGegevens.spaarPremieLaag.changed) {
        draft.premieGegevens.spaarPremieLaag = null;
      }
      if (!has.premieGegevens.spaarPremieHoog.changed) {
        draft.premieGegevens.spaarPremieHoog = null;
      }
      if (!has.premieGegevens.totalePremieLaag.changed) {
        draft.premieGegevens.totalePremieLaag = null;
      }
      if (!has.premieGegevens.totalePremieHoog.changed) {
        draft.premieGegevens.totalePremieHoog = null;
      }
      break;
    case SoortBerekeningOptionsKv.Voorbeeldkapitaal:
      if (!has.kapitaalopbouw.voorbeeldkapitaalBedrag.changed) {
        draft.kapitaalopbouw.voorbeeldkapitaalBedrag = null;
      }
      if (!has.kapitaalopbouw.garantiekapitaalBedrag.changed) {
        draft.kapitaalopbouw.garantiekapitaalBedrag = null;
      }
      break;
    case SoortBerekeningOptionsKv.Voorbeeldrendement:
      if (!has.kapitaalopbouw.voorbeeldrendementPercentage.changed) {
        draft.kapitaalopbouw.voorbeeldrendementPercentage = null;
      }
      if (!has.kapitaalopbouw.garantierendementPercentage.changed) {
        draft.kapitaalopbouw.garantierendementPercentage = null;
      }
      break;
    case SoortBerekeningOptionsKv.EigenInvoer:
      break;
    case null:
      break;
    default:
      assertNever(soortBerekening);
  }

  if (has.kapitaalopbouw.soortRekening.changed) {
    const soortRekening = draft.kapitaalopbouw.soortRekening as SoortKapitaalverzekeringsrekeningOptions;
    switch (soortRekening) {
      case SoortKapitaalverzekeringsrekeningOptions.Spaar:
        draft.kapitaalopbouw.voorbeeldkapitaalBedrag = null;
        draft.kapitaalopbouw.voorbeeldrendementPercentage = null;
        break;
      case SoortKapitaalverzekeringsrekeningOptions.Belegging:
        draft.kapitaalopbouw.garantiekapitaalBedrag = null;
        draft.kapitaalopbouw.garantierendementPercentage = null;
        break;
      case SoortKapitaalverzekeringsrekeningOptions.Hybride:
        break;
      default:
        assertNever(soortRekening);
    }
  }
};

export const poliswaardesSideEffects = createISWSideEffect<KapitaalverzekeringType>(({ has, draft }): void => {
  if (has.product.ingangsdatum.changed) {
    draft.poliswaarde.poliswaardeModal.beginJaar = draft.product.ingangsdatum?.year() ?? null;
  }
  if (   
    has.premieGegevens.betalingstermijn.changed ||
    has.premieGegevens.looptijd.changed ||
    has.premieGegevens.hoogLaagLooptijd.changed ||
    has.premieGegevens.hoogLaagVerhouding.changed ||
    has.premieGegevens.risicoPremieHoog.changed ||
    has.poliswaarde.reedsOpgebouwdBedrag.changed ||
    has.poliswaarde.reedsOpgebouwdDatum.changed ||
    has.poliswaarde.afkoopwaardeBedrag.changed ||
    has.poliswaarde.afkoopwaardeDatum.changed ||
    has.product.looptijd.changed
  ) {
    maakBerekendeWaardesLeeg(draft, has);
  }
});

export const premieDepotModalSideEffects = createISWSideEffect<KapitaalverzekeringType>(({ has, draft }): void => {
  if (has.premieGegevens.totalePremieHoog.changed && draft.premieGegevens.premiedepot) {
    draft.premieGegevens.premiedepot.hoogInlegBedrag = draft.premieGegevens.totalePremieHoog;
  }
  if (has.premieGegevens.totalePremieLaag.changed && draft.premieGegevens.premiedepot) {
    draft.premieGegevens.premiedepot.inlegBedrag = draft.premieGegevens.totalePremieLaag;
  }
  if (has.premieGegevens.aanvangExtraPremieStortingen.changed && draft.premieGegevens.premiedepot) {
    draft.premieGegevens.premiedepot.aanvangsstortingBedrag = draft.premieGegevens.aanvangExtraPremieStortingenBedrag;
  }
  if (has.premieGegevens.looptijd.changed && draft.premieGegevens.premiedepot) {
    draft.premieGegevens.premiedepot.premieDuur = draft.premieGegevens.looptijd;
  }
  if (has.premieGegevens.hoogLaagLooptijd.changed && draft.premieGegevens.premiedepot) {
    draft.premieGegevens.premiedepot.hoogLaagDuur = draft.premieGegevens.hoogLaagLooptijd;
  }
  if (has.premieGegevens.aanvangExtraPremieStortingen.scenario.changed && draft.premieGegevens.premiedepot) {
    draft.premieGegevens.premiedepot.extraPremieStortingen = draft.premieGegevens.aanvangExtraPremieStortingen.scenario;
  }
});

export const determineKapitaalverzekeringDetailsSideEffects = initISWSideEffect<KapitaalverzekeringenType, number>(
  ({ context, subset, draft, prev }) => {
    if (draft.producten.length < prev.producten.length) return;
    const selected = subset.producten[context].create();
    const { subset: selectedSubset, has, draft: draftSelected } = selected;

    productDraftSideEffects(selectedSubset.product.create());
    overlijdensrisicodekkingSideEffects(selectedSubset.verzekerde1Overlijdensrisicodekking.create());
    overlijdensrisicodekkingSideEffects(selectedSubset.verzekerde2Overlijdensrisicodekking.create());
    premieGegevensSideEffects(selected);
    poliswaardesSideEffects(selected);
    verpandingSideEffects(selected);
    premieDepotModalSideEffects(selected);

    if (has.kapitaalopbouw.doelkapitaalBedrag.changed || has.kapitaalopbouw.doelrendementPercentage.changed) {
      maakBerekendeWaardesLeeg(draftSelected, has);
    }
  }
);
