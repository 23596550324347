import React, { ReactElement } from "react";
import "adviesbox-shared";
import { useFormikContext } from "formik";
import { MotiveringState } from "../infra/motivering-schema";

type WensProps = {
  aanvrager: number;
};

export const Wens = ({ aanvrager }: WensProps): ReactElement => {
  const formik = useFormikContext<MotiveringState>();
  const wens = formik.values.wens;

  let wensen = wens.wensenAlgemeen || [];
  if (aanvrager === 1 && wens.wensenAanvrager1?.length > 0) wensen = wensen.concat(wens.wensenAanvrager1);
  if (aanvrager === 2 && wens.wensenAanvrager2?.length > 0) wensen = wensen.concat(wens.wensenAanvrager2);

  return (
    <div data-testid="wens">
      {wensen.length > 0 ? (
        wensen.map(
          (elem, index, row): ReactElement => {
            return (index + 1 === row.length ? <span key={index}>{elem}</span> : <span key={index}>{elem}, </span>);
          }
        )
      ) : (
        <span>Geen wensen</span>
      )}
    </div>
  );
};

/* istanbul ignore else */ if (process.env.NODE_ENV !== "production") Wens.displayName = "Wens";
