import { ErrorPage, PageLoading, SettingsType, withErrorBoundary } from "adviesbox-shared";
import React, { ReactElement, useCallback } from "react";
import { HypothekenKenmerken, KenmerkenError } from "../producten-overzicht/infra/product-kenmerken-types";
import { SituatieSoort } from "../producten-overzicht/infra/producten-overzicht-types";
import { InvalidAdviesboxResultErrorPage } from "../shared/components/fetch-error-page/fetch-error-page";
import { useAdviesboxData } from "../shared/hooks/use-adviesbox-data";
import { useMap } from "../shared/hooks/use-map";
import { useNavigateBack } from "../shared/hooks/use-navigate-back";
import { RouteParams } from "../shared/paramrouting/paramrouting-context";
import Hypotheek from "./hypotheek";
import { mapDlTargetToHypotheekUiField, mapHypotheekDlToUi } from "./infra/map-hypotheek-dl-2-ui";
import { mapHypotheekHuidigUiToDl, mapHypotheekVoorstelUiToDl } from "./infra/map-hypotheek-ui-2-dl";

const HypotheekAjax = ({ situatie }: { situatie: SituatieSoort }): ReactElement => {
  // In deze map worden alle productkenmerken verzameld voor de aanwezige producten. Deze verzamelen we bij het openen van het hypotheekscherm,
  // door ze op te halen in 'determine-hypotheek-async-side-effects.ts' (side-effects triggeren op 'runOnFirstRender').
  const productKenmerkenMap = useMap<string, HypothekenKenmerken | KenmerkenError>();
  const { 0: productKenmerken } = productKenmerkenMap;

  const createUrl = useCallback(
    (s: SettingsType, p: RouteParams): string => {
      return situatie === "huidig"
        ? `${s.klantdossiersFormsOrigin}/Adviesdossiers/${p.adviesdossier}/Hypotheek`
        : `${s.klantdossiersFormsOrigin}/Voorstellen/${p.voorstel}/Hypotheek`;
    },
    [situatie]
  );

  const goBack = useNavigateBack();

  const createDataId = useCallback((p: RouteParams) => (situatie === "huidig" ? p.adviesdossier : p.voorstel), [
    situatie
  ]);

  const { loading, error, data, platformData, saveData } = useAdviesboxData(
    createUrl,
    createDataId,
    mapHypotheekDlToUi,
    situatie === "huidig"
      ? mapHypotheekHuidigUiToDl(new Map(productKenmerken.entries()))
      : mapHypotheekVoorstelUiToDl(new Map(productKenmerken.entries())),
    mapDlTargetToHypotheekUiField
  );

  if (loading) {
    return <PageLoading />;
  }

  if (error) {
    return <ErrorPage error={error} data={platformData} />;
  }

  if (!data) {
    return <InvalidAdviesboxResultErrorPage />;
  }

  if (situatie === "voorstel" && data.panden.length === 0) {
    return goBack();
  }

  return <Hypotheek situatie={situatie} saveData={saveData} productKenmerkenMap={productKenmerkenMap} {...data} />;
};

HypotheekAjax.displayName = "HypotheekAjax";

export default withErrorBoundary(HypotheekAjax);
