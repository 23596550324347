import React, { ReactElement } from "react";
import { connect, FormikContextType } from "formik";
import { LabeledRadioInput } from "adviesbox-shared";

import { SoortCorrectieLastOptions, SoortCorrectieFiscaalOptions } from "../../.generated/forms/formstypes";
import { LabelValuePair } from "../../shared/types";
import { assertNever } from "../../shared/utils/helpers";
import { OverigePostenType } from "../infra/overige-posten-types";

const correctieLastOptions: LabelValuePair[] = Object.values(SoortCorrectieLastOptions).map(
  (value): LabelValuePair => ({
    label: value,
    value
  })
);

const alleCorrectieFiscaalOptions: LabelValuePair[] = Object.values(SoortCorrectieFiscaalOptions).map(
  (value): LabelValuePair => ({
    label: value,
    value
  })
);

type Props = {
  selected: number;
};

export const bepaalCorrectieFiscaalOptions = (correctieLast: SoortCorrectieLastOptions): LabelValuePair[] => {
  switch (correctieLast) {
    case SoortCorrectieLastOptions.Last:
      return alleCorrectieFiscaalOptions.filter(o => o.value !== SoortCorrectieFiscaalOptions.Bijtelling);
    case SoortCorrectieLastOptions.Bijdrage:
      return alleCorrectieFiscaalOptions.filter(o => o.value !== SoortCorrectieFiscaalOptions.Aftrekpost);
    case SoortCorrectieLastOptions.Neutraal:
      return alleCorrectieFiscaalOptions.filter(o => o.value !== SoortCorrectieFiscaalOptions.Neutraal);
    default:
      return assertNever(correctieLast);
  }
};

const Correctie = ({
  selected,
  formik: {
    values: { producten }
  }
}: Props & {
  formik: FormikContextType<OverigePostenType>;
}): ReactElement => {
  const { correctieLast } = producten[selected].correctie;

  const correctieFiscaalOptions = React.useMemo((): LabelValuePair[] => bepaalCorrectieFiscaalOptions(correctieLast), [
    correctieLast
  ]);

  return (
    <>
      <LabeledRadioInput
        caption="Correctie last"
        name={`producten[${selected}].correctie.correctieLast`}
        options={correctieLastOptions}
      />

      <LabeledRadioInput
        caption="Correctie fiscaal"
        name={`producten[${selected}].correctie.correctieFiscaal`}
        options={correctieFiscaalOptions}
      />
    </>
  );
};

export default connect<Props, OverigePostenType>(Correctie);
