import React, { ReactElement } from "react";
import { CheckBox } from "adviesbox-shared";
import { Adviesonderwerpen } from "../../.generated/instellingen-forms/instellingen-formstypes";

type FinancielePlanningProps = {
  instellingen: Adviesonderwerpen;
};

const FinancielePlanning = ({ instellingen }: FinancielePlanningProps): ReactElement => {
  return (
    <div className="px-1">
      {instellingen.onderwerpOverlijdenTonen && <CheckBox caption="Overlijden" name="planningKeuze.overlijden" />}
      {instellingen.onderwerpArbeidsongeschiktheidTonen && (
        <CheckBox caption="Arbeidsongeschiktheid" name="planningKeuze.arbeidsongeschiktheid" />
      )}
      {instellingen.onderwerpWerkloosheidTonen && <CheckBox caption="Werkloosheid" name="planningKeuze.werkloosheid" />}
      {instellingen.onderwerpPensioenTonen && <CheckBox caption="Pensioen" name="planningKeuze.pensioen" />}
      {instellingen.onderwerpVermogensopbouwTonen && (
        <CheckBox caption="Vermogensopbouw" name="planningKeuze.vermogensopbouw" />
      )}
    </div>
  );
};

export default FinancielePlanning;
