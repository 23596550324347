import { AuthContext, ErrorPage, withErrorBoundary } from "adviesbox-shared";
import React, { ReactElement, useContext } from "react";
import { RouteComponentProps } from "react-router";
import { Title } from "../shared/components/title/title";
import { RouteParams } from "../shared/paramrouting/paramrouting-context";
import ParamRouteProvider from "../shared/paramrouting/paramrouting-provider";
import { zoekschermSchema } from "./infra/zoekscherm-schema";
import Zoekscherm from "./zoekscherm";

const ZoekschermAjax = ({ match }: RouteComponentProps<RouteParams>): ReactElement => {
  const { user } = useContext(AuthContext);

  if (!user) return <ErrorPage error={new Error("Missing AuthContext")} />;

  const zoekschermData = { ...zoekschermSchema.default(), recentGeopendError: false };
  return (
    <ParamRouteProvider route={match.params}>
      <Title altTitle={"Zoeken"} appName="Advies" />
      <Zoekscherm {...zoekschermData} />
    </ParamRouteProvider>
  );
};

ZoekschermAjax.displayName = "ZoekschermAjax";
export default withErrorBoundary(ZoekschermAjax);
