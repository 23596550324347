import { FormikContextType, connect } from "formik";
import React, { ReactElement, useState } from "react";
import { SettingsType, useRequestInit, validationResultsToUiError, setFormikUiErrors } from "adviesbox-shared";
import { TaxatieSchermType } from "../infra/taxatie-scherm-schema";
import { User } from "oidc-client";
import { mapTaxatieUiToDl } from "../infra/map-taxatie-scherm-ui-to-dl";
import { taxatieAanvragenApi, TaxatieAanvraagResponse } from "./taxatie-aanvragen-api";
import { getTaxatieResources } from "../infra/taxatie-resources";
import { SaveButton } from "../../shared/components/save-button/save-button";
import { WithSaveData } from "../../shared/utils/save-data";
import { UiError } from "adviesbox-shared/utils/types";
import { target2field } from "../../shared/utils/target-to-field";
import classes from "./taxatie-aanvragen.module.scss";
import { Button } from "react-bootstrap";

export const aanvraagVerzenden = async (
  formik: FormikContextType<TaxatieSchermType>,
  values: TaxatieSchermType,
  user: User | null,
  vestiging: string,
  adviesdossier: string,
  settings: SettingsType
): Promise<UiError[] | null> => {
  const resultaat: TaxatieAanvraagResponse = await taxatieAanvragenApi(
    settings,
    user,
    vestiging,
    adviesdossier,
    mapTaxatieUiToDl(values)
  );

  /* istanbul ignore next */
  const errorValidations = resultaat.validationResults?.filter(c => c.code !== "FX002" && c.code !== "FX001");
  /* istanbul ignore next */
  if (typeof resultaat != "string" && errorValidations.length) {
    const uiErrors: UiError[] = validationResultsToUiError(errorValidations, (target: string) =>
      target2field([], target)
    );
    setFormikUiErrors(uiErrors, formik);
    return uiErrors;
  }

  // fitrex
  const res = resultaat.validationResults?.find(c => c.code === "FX002");
  if (res && res.message?.startsWith("http")) {
    const url = res.message;
    /* istanbul ignore else */
    if (url) window.open(url, "_blank");
  }

  // nts
  if (resultaat.redirectUrl?.startsWith("http")) {
    const url = resultaat.redirectUrl;
    /* istanbul ignore else */
    if (url) window.open(url, "_blank");
  }
  return null;
};

const TaxatieAanvragen = ({
  formik,
  saveData,
  callbackHandler
}: {
  formik: FormikContextType<TaxatieSchermType>;
  saveData: (values: TaxatieSchermType, preventReload?: boolean | undefined) => Promise<UiError[] | null>;
  callbackHandler?: () => Promise<UiError[] | null | undefined>;
}): ReactElement => {
  const { values } = formik;
  const { settings, params, user } = useRequestInit<{ vestiging: string; adviesdossier: string }>();
  const taxatieBureauNWWI = values.taxatiebureau?.maatschappijCode === "NWW";
  const [aanvraagVerzonden, setAanvraagVerzonden] = useState(false);
  const [aanvragenBtnUsed, setAanvragenBtnUsed] = useState(false);

  // als met de save-button is opgeslagen wordt daarna de callback uitgevoerd, om de gebruiker van status info/spinner te voorzien:
  const [callBackVerwerken, setCallBackVerwerken] = useState(false);

   /* istanbul ignore next */
   let handleCallback =  async (): Promise< UiError[] | null | undefined> => {
    if (!aanvragenBtnUsed) return;
    setCallBackVerwerken(true);

    const resultaat = await aanvraagVerzenden(
      formik,
      values,
      user,
      params.vestiging,
      params.adviesdossier,
      settings
    );

    setAanvraagVerzonden(true);
    setCallBackVerwerken(false);
    return resultaat;
  }

   /* istanbul ignore next */
  if (callbackHandler) { handleCallback = callbackHandler; }

  return (
    <>
      {!!values.aanvragenInvalidText.length && (
        <div className="foutmelding w-100 d-flex flex-wrap">
          <ul className="pl-3">
            <>
              {!values.taxatiebureau?.soortAanvraag && <li>{getTaxatieResources("SoortAanvraagLeeg")}</li>}
              {values.aanvragenInvalidText.map(
                (v, index): ReactElement => (
                  <li key={index}>{v}</li>
                )
              )}
            </>
          </ul>
        </div>
      )}
      {taxatieBureauNWWI && (
        <div className="px-2">
          <p>Let op, om de aanvraag definitief te maken moet je de aanvraag in het NWWI portaal bevestigen.</p>
        </div>
      )}
      <div className="button-container mx-5 d-flex justify-content-center" id={`taxatie-aanvragen-button`}>
        { /* istanbul ignore next */ !callBackVerwerken && aanvraagVerzonden && (
          <p>
            {!values.platformApiFouten?.length && (
              <>
                Uw aanvraag is verzonden.{" "}
                <Button
                  variant={"secondary"}
                  onClick={
                     () => {
                      setAanvraagVerzonden(false);
                    }
                  }
                  type="button"
                  data-testid="reset-button"
                >
                  Reset
                </Button>
              </>
            )}
            {!!values.platformApiFouten?.length && (
              <span style={{ color: "red" }}>
                Er is een fout opgetreden bij het verzenden:
                <br />
                <Button onClick={/*istanbul ignore next */ () => window.scrollTo(0, 0)} variant={"secondary"} data-testid="bekijk-fouten-button">
                  Bekijk de fouten
                </Button>
                &nbsp;
                <Button
                  variant={"secondary"}
                  onClick={
                    /* istanbul ignore next */ () => {
                      setAanvraagVerzonden(false);
                    }
                  }
                  type="button"
                  data-testid="reset-button"
                >
                  Reset
                </Button>
              </span>
            )}
          </p>
        )}
        {/* istanbul ignore next */
        callBackVerwerken && (
          <div style={{ position: "relative" }}>
            <span id={"save-button"} className={classes.busy}>
              {<>Bezig met opslaan.</>}
            </span>
          </div>
        )}
        {!callBackVerwerken && !aanvraagVerzonden && (
          /* Van deze hidden div gebruiken we de onClick om te bepalen of de gebruiker op deze knop heeft geklikt 
           en dus niet op de SaveButton die onderin het scherm staat. Hiermee voorkomen we dat bij een normale submit/navigatie, de callback wordt uitgevoerd.
        */
          <div
            data-testid="save-btn-wrap"
            onClick={() => {
              setAanvragenBtnUsed(true);
            }}
          > 
            <SaveButton
              name={"save-aanvragen-button"}
              context={{ ...formik, saveData: saveData, dirty: true }}
              callBack={
               handleCallback
              }
              propagateDirty={false}
              showSaveRequest
            />
          </div>
        )}
      </div>

      {/* NWWI portaal link tonen indien taxatiebureau NWWI */}
      {taxatieBureauNWWI && (
        <div className="w-100 d-flex flex-wrap">
          <p className={"text-center w-100"}>
            <a href={"https://site.nwwi.nl/login/"} className="link" target="_blank" rel="noopener noreferrer">
              Naar het portaal van het NWWI
              <i className="icon externallink " style={{ position: "inherit" }} />
            </a>
          </p>
        </div>
      )}
    </>
  );
};

export default connect<WithSaveData<TaxatieSchermType> & { callbackHandler?: () => Promise<UiError[] | null | undefined>}, TaxatieSchermType>(TaxatieAanvragen);
