import { LabeledRadioInput, LabeledTextInput } from "adviesbox-shared";
import { connect, FormikContextType } from "formik";
import React, { ReactElement } from "react";
import { GeslachtOpties } from "../../.generated/forms/formstypes";
import { Direction } from "../../shared/types";
import { TaxatieKenmerkenType, TaxatieSchermType } from "../infra/taxatie-scherm-schema";

const Partner = ({
  formik: {
    values: { taxatieKenmerken }
  }
}: {
  formik: FormikContextType<TaxatieSchermType>;
}): ReactElement => {
  const kenmerken = taxatieKenmerken as TaxatieKenmerkenType;
  const telefoonnummerPrive = kenmerken?.PartnerTelefoonPriveTonen;
  const telefoonMobielTonen = kenmerken?.PartnerTelefoonMobielTonen;
  const emailAdresTonen = kenmerken?.PartnerEmailadresTonen;
  return (
    <>
      <LabeledTextInput name="partner.achternaam" caption="Achternaam" />
      <LabeledTextInput name="partner.voorletters" caption="Voorletters" />
      <LabeledTextInput name="partner.voorvoegsel" caption="Tussenvoegsel" />
      <LabeledRadioInput
        name="partner.geslacht"
        caption="Geslacht"
        layout={Direction.Vertical}
        options={[
          { label: "Man", value: GeslachtOpties.Man },
          { label: "Vrouw", value: GeslachtOpties.Vrouw }
        ]}
      />
      {telefoonnummerPrive && <LabeledTextInput name="partner.telefoonnummerPrive" caption="Telefoonnummer (privé)" />}
      {telefoonMobielTonen && (
        <LabeledTextInput name="partner.telefoonnummerMobiel" caption="Telefoonnummer (mobiel)" />
      )}
      {emailAdresTonen && (
        <LabeledTextInput autoComplete="new-password" name="partner.emailAdres" caption="E-mailadres" />
      )}
    </>
  );
};

export default connect<{}, TaxatieSchermType>(Partner);
