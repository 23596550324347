import { aflosproductSchema, afTeLossenLeningdelenModalSchema } from "../producten-overzicht-schema";
import { createMapToUi } from "../../../shared/utils/create-map-to-ui";
import {
  Aflosproduct,
  SoortAflosproductOptions,
  LeningdeelReadOnly,
  KredietReadOnly
} from "../../../.generated/forms/formstypes";
import { LocalDate } from "@js-joda/core";
import { AflosproductType, LeningdeelReadonlyType, KredietReadonlyType } from "../producten-overzicht-types";
import { mapStringToLocalDate } from "adviesbox-shared";

export const mapLeningDeel2AflosProduct = createMapToUi(aflosproductSchema)
  .with<number>()
  .from<LeningdeelReadOnly>({
    productId: v => v.leningdeelId,
    index: (_v, context) => context,
    omschrijving: v => v.productnaam ?? "",
    aflossen: (_, index) => index === 0,
    prioriteit: (_, index) => index,
    ingangsdatum: v => mapStringToLocalDate(v.aanvangsdatum) ?? LocalDate.of(LocalDate.now().year(), 1, 1),
    looptijd: v => v.looptijdInMaanden,
    leningdeelNummer: v => v.volgnummer,
    leningdeelBedrag: v => v.leningdeelBedrag,
    soortAflosproduct: () => SoortAflosproductOptions.Leningdeel,
    doorlopend: v => v.doorlopend,
    maatschappijCode: v => v.maatschappijCode,
    volgnummer: v => v.volgnummer
  });

export const mapKrediet2AflosProduct = createMapToUi(aflosproductSchema)
  .with<{ index: number; aflossingLeningdeel: boolean }>()
  .from<KredietReadOnly>({
    productId: v => v.kredietId,
    index: (_v, context) => context.index,
    omschrijving: v => v.productnaam ?? "",
    aflossen: (_, context) => !context.aflossingLeningdeel && context.index === 0,
    prioriteit: (_, context) => context.index,
    ingangsdatum: () => null,
    looptijd: () => null,
    leningdeelBedrag: _ => null,
    leningdeelNummer: _ => null,
    soortAflosproduct: () => SoortAflosproductOptions.Krediet,
    doorlopend: v => v.doorlopend,
    maatschappijCode: () => null,
    volgnummer: v => 0 //TODO: CONTROLEREN
  });

let doorlopendLeningdeelIndex = 0;
let leningdeelIndex = 0;
let doorlopendKredietIndex = 0;
let kredietIndex = 0;
export const mapAflosProductNew = (
  leningdelen: LeningdeelReadonlyType[] | null,
  kredieten: KredietReadonlyType[] | null
): AflosproductType[] => {
  const mappedLeningdelen =
    leningdelen?.map(values => {
      let index = 0;
      if (values.doorlopend) {
        doorlopendLeningdeelIndex = doorlopendLeningdeelIndex + 1;
        index = doorlopendLeningdeelIndex - 1;
      }

      if (!values.doorlopend) {
        leningdeelIndex = leningdeelIndex + 1;
        index = leningdeelIndex - 1;
      }

      return mapLeningDeel2AflosProduct(index)({
        ...values,
        volgnummer: index,
        leningdeelBedrag: values.leningdeelBedrag ?? null
      });
    }) ?? [];
  const mappedKredieten =
    kredieten?.map(values => {
      let index = 0;

      if (values.doorlopend) {
        doorlopendKredietIndex = doorlopendKredietIndex + 1;
        index = doorlopendKredietIndex - 1;
      }

      if (!values.doorlopend) {
        kredietIndex = kredietIndex + 1;
        index = kredietIndex - 1;
      }

      return mapKrediet2AflosProduct({ index, aflossingLeningdeel: !!mappedLeningdelen.length })(values);
    }) ?? [];

  const result = mappedLeningdelen?.concat(mappedKredieten).map((aflosproduct, index) => {
    aflosproduct.prioriteit = index;
    return aflosproduct;
  });

  return result ?? [];
};

export const mapAflosproductDlToUi = createMapToUi(aflosproductSchema)
  .with<number>()
  .from<Aflosproduct>({
    soortAflosproduct: v => v.soortAflosproduct,
    index: (_v, context) => context,
    aflossen: v => v.aflossen,
    productId: v => v.productId,
    ingangsdatum: v => mapStringToLocalDate(v.ingangsdatum) ?? LocalDate.of(LocalDate.now().year(), 1, 1),
    looptijd: v => v.looptijdInMaanden,
    omschrijving: v => v.omschrijving ?? "",
    prioriteit: v => v.prioriteit,
    leningdeelBedrag: _ => null,
    leningdeelNummer: v => v.volgnummer, // TODO: zie PBI #12345 
    doorlopend: v => v.doorlopend,
    maatschappijCode: v => null,
    volgnummer: v => v.volgnummer
  });

export const mapAflosProductenDlToUi = createMapToUi(afTeLossenLeningdelenModalSchema).from<Aflosproduct[]>({
  aflosproducten: v => {
    const aflosproducten = Array<AflosproductType>();
    v.filter(x => x.soortAflosproduct === SoortAflosproductOptions.Krediet).forEach((x, index) =>
      aflosproducten.push(mapAflosproductDlToUi(index)(x))
    );
    v.filter(x => x.soortAflosproduct === SoortAflosproductOptions.Leningdeel).forEach((x, index) =>
      aflosproducten.push(mapAflosproductDlToUi(index)(x))
    );
    aflosproducten?.forEach((value, index) => { value.prioriteit = index });
    return aflosproducten;
  }
});
