import { PageLoading } from "adviesbox-shared";
import React, { ReactElement } from "react";
import { ErrorPage } from "adviesbox-shared";
import { InvalidAdviesboxResultErrorPage } from "../shared/components/fetch-error-page/fetch-error-page";
import { useInstellingenBerekeningenData } from "../shared/hooks/use-instellingen-berekeningen-data";
import FinancieringsbehoefteTarievenAjax from "./financieringsbehoefte-tarieven-ajax";

const FinancieringsbehoefteBerekeningenAjax = (): ReactElement => {
  const {
    data: bekeningenInstellingenData,
    error: bekeningenInstellingenError,
    loading: bekeningenInstellingenLoading
  } = useInstellingenBerekeningenData();

  if (bekeningenInstellingenLoading) {
    return <PageLoading />;
  }

  if (bekeningenInstellingenError) {
    return <ErrorPage error={bekeningenInstellingenError} data={bekeningenInstellingenData} />;
  }

  if (!bekeningenInstellingenData) {
    return <InvalidAdviesboxResultErrorPage />;
  }

  return <FinancieringsbehoefteTarievenAjax bekeningenInstellingenData={bekeningenInstellingenData} />;
};

FinancieringsbehoefteBerekeningenAjax.displayName = "FinancieringsbehoefteBerekeningenAjax";

export default FinancieringsbehoefteBerekeningenAjax;
