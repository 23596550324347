import React, { ReactElement } from "react";
import { ListGroup } from "react-bootstrap";

import { HuidigProductComponent } from "./huidig-product-component";
import { HuidigProductType } from "../infra/vergoedingen-overzicht-schema";
import { LocalDate } from "@js-joda/core";

type HuidigeProducten = {
  producten: HuidigProductType[];
  ingangsdatum: LocalDate;
};

const HuidigeProductenCard = ({ producten, ingangsdatum }: HuidigeProducten): ReactElement => {
  const productListItems = producten.map(
    (item, index): ReactElement => {
      return <HuidigProductComponent item={item} index={index} key={index} ingangsdatum={ingangsdatum} />;
    }
  );
  return <ListGroup>{productListItems}</ListGroup>;
};

export default HuidigeProductenCard;
