import { GebruikPandSoort } from "../../.generated/forms/formstypes";
import { OverbruggingskredietModalData } from "./overbruggingskrediet-modal";
import { createISWSideEffect, initISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";

const overbruggingDraftSideEffects = createISWSideEffect<OverbruggingskredietModalData>((bag): void => {
  const { has, draft } = bag;

  if (has.data.meenemenInOverbruggingskrediet.changed) {
    if (draft.pandGebruik === GebruikPandSoort.PrimaireWoning) {
      draft.data.fiscalegegevensDeelBox1Bedrag = draft.data.meenemenInOverbruggingskrediet;
      draft.data.fiscalegegevensDeelBox1Percentage = 100;
      draft.data.fiscalegegevensDeelBox3Bedrag = null;
      draft.data.fiscalegegevensDeelBox3Percentage = null;
    } else {
      draft.data.fiscalegegevensDeelBox3Bedrag = draft.data.meenemenInOverbruggingskrediet;
      draft.data.fiscalegegevensDeelBox3Percentage = 100;
      draft.data.fiscalegegevensDeelBox1Bedrag = null;
      draft.data.fiscalegegevensDeelBox1Percentage = null;
    }
  } else {
    if (has.data.overwaardeMeenemen.changed && draft.data.overwaardeMeenemen) {
      if (draft.pandGebruik === GebruikPandSoort.PrimaireWoning) {
        draft.data.meenemenInOverbruggingskrediet = draft.data.overwaardeHuidigeWoningen;
        draft.data.fiscalegegevensDeelBox1Bedrag = draft.data.overwaardeHuidigeWoningen;
        draft.data.fiscalegegevensDeelBox1Percentage = 100;
        draft.data.fiscalegegevensDeelBox3Bedrag = null;
        draft.data.fiscalegegevensDeelBox3Percentage = null;
      } else {
        draft.data.meenemenInOverbruggingskrediet = draft.data.overwaardeHuidigeWoningen;
        draft.data.fiscalegegevensDeelBox3Bedrag = draft.data.overwaardeHuidigeWoningen;
        draft.data.fiscalegegevensDeelBox3Percentage = 100;
        draft.data.fiscalegegevensDeelBox1Bedrag = null;
        draft.data.fiscalegegevensDeelBox1Percentage = null;
      }
    }
  }
});

export const overbruggingSideEffects = initISWSideEffect(overbruggingDraftSideEffects)();
