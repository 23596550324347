import { LocalDate, convert, ChronoUnit } from "@js-joda/core";
import "moment/locale/nl";
import moment from "moment";
import { SituatieSoort } from "../../producten-overzicht/infra/producten-overzicht-types";

export const getFormattedDate = (input: LocalDate): string => {
  return moment(convert(input).toDate()).format("DD-MM-YYYY");
};

export const getFormattedDateMetLangeMaand = (input: LocalDate): string => {
  return moment(convert(input).toDate()).format("D MMMM Y");
};

export const getFormattedDateTime = (input: Date): string => {
  return moment(input).format("DD-MM-YYYY HH:mm");
};

export type YearMonth = {
  year: number;
  month: number;
};

const getDifference = (dateStart: LocalDate, dateEnd: LocalDate, calcWithFirstDayOfMonth?: boolean): number => {
  let date1 = dateStart;
  let date2 = dateEnd;

  if (calcWithFirstDayOfMonth) {
    date1 = date1.withDayOfMonth(1);
    date2 = date2.withDayOfMonth(1);
  }

  return date1.until(date2, ChronoUnit.MONTHS);
};

export const getDifferenceYearsMonths = (
  dateStart: LocalDate,
  dateEnd: LocalDate,
  calcWithFirstDayOfMonth?: boolean
): YearMonth => {
  const monthsTotal = getDifference(dateStart, dateEnd, calcWithFirstDayOfMonth);
  return { year: Math.floor(monthsTotal / 12), month: monthsTotal % 12 };
};

export function addYears(dt: LocalDate, numberOfYears: number): LocalDate {
  return dt.plusYears(numberOfYears);
}

export function addMonths(dt: LocalDate, numberOfMonths: number): LocalDate {
  return dt.plusMonths(numberOfMonths);
}

export const getIngangsdatum = (dt: LocalDate | null, situatie: SituatieSoort): LocalDate => {
  if (dt) return dt;
  dt = LocalDate.now().withDayOfMonth(1);
  if (situatie === "huidig") return dt;
  return dt.plusMonths(1);
}