import { ErrorPage, PageLoading, SettingsType, withErrorBoundary } from "adviesbox-shared";
import React, { ReactElement } from "react";
import { mapDlTargetToWoonsituatieUiField, mapWoonsituatieDlToUi } from "./infra/map-woonsituatie-dl-to-ui";

import { InvalidAdviesboxResultErrorPage } from "../shared/components/fetch-error-page/fetch-error-page";
import Woonsituatie from "./woonsituatie";
import { mapWoonsituatieUiToDl } from "./infra/map-woonsituatie-ui-to-dl";
import { useAdviesboxData } from "../shared/hooks/use-adviesbox-data";
import { useInstellingenBerekeningenData } from "../shared/hooks/use-instellingen-berekeningen-data";

type RouteParams = {
  vestiging: string;
  adviesdossier: string;
};

const createUrl = (s: SettingsType, p: RouteParams): string =>
  `${s.klantdossiersFormsOrigin}/Adviesdossiers/${p.adviesdossier}/Woonsituatie`;

const WoonsituatieAjax = (): ReactElement => {
  const {
    data: bekeningenInstellingenData,
    error: bekeningenInstellingenError,
    loading: bekeningenInstellingenLoading
  } = useInstellingenBerekeningenData();

  const { loading, error, data, platformData, saveData } = useAdviesboxData(
    createUrl,
    p => p.adviesdossier,
    mapWoonsituatieDlToUi(bekeningenInstellingenData?.berekeningen || null),
    mapWoonsituatieUiToDl,
    mapDlTargetToWoonsituatieUiField
  );

  if (loading || bekeningenInstellingenLoading) {
    return <PageLoading />;
  }

  if (error) {
    return <ErrorPage error={error} data={platformData} />;
  }

  if (bekeningenInstellingenError) {
    return <ErrorPage error={bekeningenInstellingenError} data={bekeningenInstellingenData} />;
  }

  if (!data || !bekeningenInstellingenData) {
    return <InvalidAdviesboxResultErrorPage />;
  }

  return <Woonsituatie saveData={saveData} {...data} />;
};

WoonsituatieAjax.displayName = "WoonsituatieAjax";

export default withErrorBoundary(WoonsituatieAjax);
