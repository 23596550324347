import { LabeledText, LabeledRadioInput, LabeledSelectInput, LabeledTextInput } from "adviesbox-shared";
import { useFormikContext } from "formik";
import React, { ReactElement } from "react";
import { SelectedProps } from "../../shared/types";
import { getPensioenTextResources } from "../infra/pensioen-resources";
import { PensioenAanvragerType, PensioenenState } from "../infra/pensioen-schema";
import { getDienstverbandOptions } from "./deelnemer-helpers";

export const Deelnemer = ({ selected }: SelectedProps): ReactElement => {
  const {
    setFieldValue,
    values: {
      deelnemers: [deelnemer1, deelnemer2],
      deelnemers,
      pensioenen
    }
  } = useFormikContext<PensioenenState>();

  if (selected >= pensioenen.length) return <></>;

  const currentSelectedDeelnemer = deelnemers.find(
    (x: PensioenAanvragerType): boolean => x.klantId === pensioenen[selected].selectedDeelnemer
  );

  const selectedDeelnemerDienstverbandOptions = currentSelectedDeelnemer
    ? getDienstverbandOptions(currentSelectedDeelnemer, pensioenen, selected)
    : [];

  const showLabel = selectedDeelnemerDienstverbandOptions && selectedDeelnemerDienstverbandOptions.length === 1;
  const showNoDiensverbanden =
    selectedDeelnemerDienstverbandOptions &&
    currentSelectedDeelnemer &&
    currentSelectedDeelnemer.loondiensten.length === 0;
  const showAllUsedDiensterverbanden =
    selectedDeelnemerDienstverbandOptions &&
    selectedDeelnemerDienstverbandOptions.length === 0 &&
    currentSelectedDeelnemer &&
    currentSelectedDeelnemer.loondiensten.length > 0;

  const setDienstverbandWhenOnlyOne = (e: React.MouseEvent<HTMLInputElement, MouseEvent>): void => {
    const clickedDeelnemer = deelnemers.find(
      (x: PensioenAanvragerType): boolean => x.klantId === e.currentTarget.value
    );

    if (currentSelectedDeelnemer && currentSelectedDeelnemer.klantId === clickedDeelnemer?.klantId) {
      return;
    }

    if (
      currentSelectedDeelnemer &&
      clickedDeelnemer &&
      currentSelectedDeelnemer.klantId !== clickedDeelnemer.klantId &&
      getDienstverbandOptions(clickedDeelnemer, pensioenen, selected).length > 0
    ) {
      setFieldValue(
        `pensioenen[${selected}].loondienstId`,
        getDienstverbandOptions(clickedDeelnemer, pensioenen, selected)[0].value
      );

      return;
    }

    setFieldValue(`pensioenen[${selected}].loondienstId`, null);
  };

  return (
    <>
      {deelnemers.length > 1 ? (
        <LabeledRadioInput
          numericalId={true}
          caption="Naam deelnemer"
          name={`pensioenen[${selected}].selectedDeelnemer`}
          options={[
            {
              label: deelnemer1.naam || "Aanvrager 1",
              value: deelnemer1.klantId || ""
            },
            {
              label: deelnemer2.naam || "Aanvrager 2",
              value: deelnemer2.klantId || ""
            }
          ]}
          onClick={(e: React.MouseEvent<HTMLInputElement, MouseEvent>): void => setDienstverbandWhenOnlyOne(e)}
        />
      ) : (
        <LabeledTextInput caption="Naam deelnemer" name={`deelnemers[0].naam`} readonly />
      )}
      {pensioenen[selected].werkgever.huidigeWerkgever && (
        <>
          {!showAllUsedDiensterverbanden && !showNoDiensverbanden && (
            <LabeledSelectInput
              caption="Dienstverband"
              name={`pensioenen[${selected}].loondienstId`}
              options={selectedDeelnemerDienstverbandOptions}
              readonly={showLabel}
            />
          )}
          {showNoDiensverbanden && (
            <LabeledText
              name={`pensioenen[${selected}].loondienstId`}
              caption="Dienstverband"
              value={"Voor deze deelnemer is er geen dienstverband ingevoerd."}
            ></LabeledText>
          )}
          {showAllUsedDiensterverbanden && (
            <LabeledText
              name={`pensioenen[${selected}].loondienstId`}
              caption="Dienstverband"
              value={getPensioenTextResources("deelnemerNoMoreDienstverbanden")}
            ></LabeledText>
          )}
        </>
      )}
    </>
  );
};
