import { initISWSideEffect } from "../../../shared/components/isw-side-effects/create-isw-helpers";
import { syncControleNormSideEffects } from "../../components/controle-norm/infra/controle-norm-sideeffects";
import { DashboardState } from "../../infra/dashboard-types";
import { updateDataOutdated } from "../../infra/scenario-side-effects";
import {
  syncScenarioMinimaalUitgangspuntSideEffects,
  syncScenarioVanSideEffects
} from "../../infra/scenario-van-side-effects";

export const syncScenarioWerkloosheidModalSideEffects = initISWSideEffect<DashboardState>(({ draft, has, subset }) => {
  syncControleNormSideEffects(subset.werkloosheid.controleNorm.create());
  syncScenarioVanSideEffects(subset.werkloosheid.scenarioVan.create());

  subset.werkloosheidUitgangspunten !== null &&
    syncScenarioMinimaalUitgangspuntSideEffects(subset.werkloosheid.controleNorm.create());

  if (has.werkloosheid.changed) {
    updateDataOutdated(draft, draft.werkloosheid.scenarioVan, has.werkloosheid.scenarioVan);
  }
})();
