import { useRequestInit, ValidatiePopup, Icon } from "adviesbox-shared";
import classNames from "classnames";
import { FormikContextType } from "formik";
import React, {
  Dispatch,
  MutableRefObject,
  ReactElement,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react";
import { Button } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router";
import { AppDataContext } from "../../navigation/appdata-context";
import OpenDossierLogContext from "../../shared/open-dossier-log/open-dossier-log-context";
import { SaveFormContext } from "../../shared/save-form/save-form-context";
import { WithSaveData } from "../../shared/utils/save-data";
import { isOkSubmitResult, SubmitResultTypes } from "../../shared/utils/save-validation";
import classes from "./ConfirmButton.module.scss";
import { confirmButtonTextResources } from "./confirm-button-resources";
import { formikIsValidAsync } from "../../shared/utils/formik-is-valid-async";
import { UiError } from "../../shared/types";
import { setFormikUiErrors } from "../../shared/utils/set-formik-ui-errors";

type confirmButtonProps = {
  callBack?: () => void;
  preSaveConfirmation?: (path?: string) => Promise<void> | void;
  triggerPreSaveConfirmation?: boolean;
  name?: string;
  initialSaveResult?: SubmitResultTypes; // Voor testen alleen
  setPreviousPath?: Dispatch<SetStateAction<string | null>>;
  context: FormikContextType<any> &
    WithSaveData<any> & {
      berekenSideEffectRef?: MutableRefObject<{ asyncResult: Promise<unknown> }>;
    };
  isCanceled?: boolean;
  alwaysShow?: boolean;
};

const submit = async (
  saveData: (values: any, preventValidation?: boolean) => Promise<UiError[] | null>,
  formik: FormikContextType<any>
): Promise<SubmitResultTypes> => {
  formik.setSubmitting(true);

  //Client validatie
  formik.setSubmitting(true);
  if (await formikIsValidAsync(formik)) {
    // Server validatie & Save
    try {
      formik.setFieldValue("platformApiFouten", null, false);

      const result = await saveData(formik.values, true);

      if (result) {
        setFormikUiErrors(result, formik);
        formik.setSubmitting(false);
        return "platformError";
      }
      return "completed";
    } catch (e) {
      // TODO: Foutafhandeling
      /* istanbul ignore next */
      /* eslint-disable-next-line no-console */
      process.env.NODE_ENV === "development" && console.error(e);
      formik.setSubmitting(false);
      return "Exception";
    }
  } else {
    formik.setSubmitting(false);
    return "clientError";
  }
};

const ConfirmButtonComponent = ({
  history,
  context,
  context: { saveData, dirty, isSubmitting },
  callBack,
  preSaveConfirmation,
  triggerPreSaveConfirmation,
  setPreviousPath,
  isCanceled,
  ...props
}: confirmButtonProps & RouteComponentProps): ReactElement | null => {
  const { setIsSaving, version, setSaveResult: propagateSaveResult } = useContext(SaveFormContext);
  const [lastSave, setLastSave] = useState(version);
  let buttonContent: ReactElement | null = null;
  const {
    menuInfo: { adviseurIds }
  } = useContext(AppDataContext);
  const { seRunning, setSaveContext } = useContext(AppDataContext);
  const {
    lezenEnSchrijvenRechtenVestigingen: lezenEnSchrijvenRechten,
    getAdviseurIds,
    adviseurIds: adviseurIdsHuidigeGebruiker,
    adviseurIdsOphalen
  } = useContext(OpenDossierLogContext);
  const { params } = useRequestInit<{ vestiging: string }>();
  const [showValidatiePopup, setShowValidatiePopup] = useState(false);
  const [saveResult, setSaveResult] = useState<SubmitResultTypes>(props.initialSaveResult || "default");
  const [prevStateValues, setPrevStateValues] = useState<any>(context.initialValues);

  const actualSaveData = useCallback(
    async (skipTriggerPreSaveConfirmation?: boolean): Promise<SubmitResultTypes | null> => {
      if (triggerPreSaveConfirmation && !skipTriggerPreSaveConfirmation) {
        preSaveConfirmation && (await preSaveConfirmation());
        return null;
      }

      const validationResult = await submit(saveData, context);

      setSaveResult(validationResult);
      setShowValidatiePopup(!isOkSubmitResult(validationResult));
      return validationResult;
    },
    [triggerPreSaveConfirmation, preSaveConfirmation, saveData, context, setSaveResult, setShowValidatiePopup]
  );

  useEffect(() => {
    if (lastSave === version) return;
    setLastSave(version);
    setIsSaving(true);
    propagateSaveResult(null);
    actualSaveData()
      .then(values => {
        propagateSaveResult(values);
      })
      .finally(() => {
        setIsSaving(false);
      })
      .catch(reason => {
        /* eslint-disable-line no-console */ /* istanbul ignore next */ console.error(reason);
      });
  }, [
    lastSave,
    version,
    setIsSaving,
    triggerPreSaveConfirmation,
    preSaveConfirmation,
    saveData,
    context,
    callBack,
    setSaveResult,
    setShowValidatiePopup,
    propagateSaveResult,
    actualSaveData
  ]);

  /* istanbul ignore next */
  if (adviseurIdsOphalen) {
    getAdviseurIds(params.vestiging);
  }

  /* istanbul ignore next */
  const huidigeGebruiker = adviseurIdsHuidigeGebruiker && adviseurIdsHuidigeGebruiker.map(e => e);
  const gebruikersEigenAangemaaktDossier = adviseurIds && huidigeGebruiker.includes(adviseurIds.toString());

  useEffect(() => {
    if (prevStateValues !== context.values) {
      setPrevStateValues(context.values);
      setSaveContext({
        saveDataFn: /* istanbul ignore next */ async preventReloadNavigation => {
          const result = await actualSaveData(preventReloadNavigation);
          return result;
        }
      });
    }
  }, [callBack, context, prevStateValues, saveData, setSaveContext, actualSaveData]);

  if (isSubmitting) {
    buttonContent = (
      <span id={props.name || "bevestigen-button"} className={classes.busy}>
        {confirmButtonTextResources("Busy")}
      </span>
    );
  } else if (dirty || props.alwaysShow) {
    const isPlatformError = saveResult === "platformError";
    buttonContent = (
      <Button
        disabled={seRunning}
        id={props.name || "bevestigen-button"}
        data-testid="bevestigen-button"
        variant={"primary"}
        onClick={async (): Promise<void> => {
          // trigger het opslaan
          setLastSave(version - 1);
        }}
      >
        <Icon name="save" className="mr-2"></Icon>
        {isPlatformError ? confirmButtonTextResources("TryAgain") : confirmButtonTextResources("Save")}
      </Button>
    );
  } else {
    buttonContent = (
      <span
        id={props.name || "bevestigen-button"}
        className={classNames({
          [classes.no_save]: saveResult === "default",
          [classes.saved]: saveResult === "completed",
          "pb-2": true
        })}
      >
        {confirmButtonTextResources("SaveSucces")}
      </span>
    );
  }
  return (
    <>
      {(!lezenEnSchrijvenRechten ||
        lezenEnSchrijvenRechten === "LezenEnSchrijven" ||
        gebruikersEigenAangemaaktDossier) && (
        <>
          {buttonContent}

          <ValidatiePopup
            infotekst={saveResult}
            show={showValidatiePopup}
            onHide={(): void => setShowValidatiePopup(false)}
          />
        </>
      )}
    </>
  );
};

ConfirmButtonComponent.displayName = "ConfirmButton";

export const ConfirmButton = withRouter(ConfirmButtonComponent);
