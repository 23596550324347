import React, { ReactElement, useContext, useEffect, useState } from "react";

import Modal from "../shared/components/modal/Modal";
import {
  LabeledRadioInput,
  Icon,
  CheckBox,
  LoadingSpinner,
  useRequestInit,
  AdviesBoxFormik,
  logEvent,
  getRapportageResource,
  useServiceBusSubscription
} from "adviesbox-shared";
import { Button } from "react-bootstrap";
import { AppDataContext } from "../navigation/appdata-context";
import * as Yup from "yup";
import { useFormikContext } from "formik";
import { UseDocumentenAanwezig } from "../shared/hooks/use-documenten-aanwezig";
import { getOverzichtSubscription } from "./infra/maandlastsnelinzicht-api";
import { overzichtSubscriptionInput, voorstelKeuzeSchema } from "./infra/rapportage-schema";
import { VergelijkendHypotheeklastenInput } from "../.generated/rapportage/rapportagetypes";

type RapportageDownloadenModalProps = {} & { closeModal?: () => void };

const rapportageDownloadenSchema = Yup.object({
  rapportageDownloadenVan: Yup.string(),
  vergelijkendeHypotheeklastenVan: Yup.array(voorstelKeuzeSchema).default([])
});

export type RapportageDownloadenState = Yup.InferType<typeof rapportageDownloadenSchema>;

const Body = (): ReactElement => {
  const { settings, params, user } = useRequestInit<{ vestiging: string; adviesdossier: string; voorstel: string }>();
  const { values, setFieldValue } = useFormikContext<RapportageDownloadenState>();
  const { voorstellen } = useContext(AppDataContext);
  const [sasToken, setSasToken] = useState<string | null>(null);
  const [subscription, setSubscription] = useState<string | null>(null);
  const [messageUrl, setMessageUrl] = useState<string | undefined>("");
  const [errorMsg, setErrorMsg] = useState<string | undefined>("");
  const [loadingDocument, setLoadingDocument] = useState<boolean | undefined>(false);
  const [overzichtSubscription, setOverzichtenSubscription] = useState<overzichtSubscriptionInput | undefined>(
    undefined
  );

  useEffect(() => {
    setFieldValue(
      "vergelijkendeHypotheeklastenVan",
      (
        voorstellen.map(x => {
          return { ...x, selected: false };
        }) || []
      ).concat([
        {
          naam: "Huidige situatie",
          selected: false,
          voorstelId: "Huidig"
        }
      ])
    );
  }, [voorstellen, setFieldValue]);

  // Ophalen van SasToken en Subscription als de OverzichtUrl is gevuld.
  useEffect(() => {
    if (overzichtSubscription?.url) {
      // Spinner aanzetten
      setLoadingDocument(true);
      // Ophalen SasToken en Subscription
      const overzichtSubscriptionResultFetch = async (): Promise<void> => {
        const result = await getOverzichtSubscription(overzichtSubscription, params, user, settings).then(x => {
          if (x) {
            setSasToken(x.sasToken);
            setSubscription(x.overzichtId);
            return true;
          }
        });
        if (!result) {
          setLoadingDocument(false);
        }
      };
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      overzichtSubscriptionResultFetch();
      // OverzichtSubscription url leeg maken, zodat proces nog een keer uitgevoerd kan worden.
      setOverzichtenSubscription({ ...overzichtSubscription, url: "" });
    }
  }, [overzichtSubscription, params, sasToken, settings, user]);

  // Als SasToken en Subscription opgehaald zijn subscriben op servicebus
  const { message: messageTo, error: errorSb } = useServiceBusSubscription(
    sasToken,
    subscription,
    "overzichtentopic",
    true,
    overzichtSubscription?.rapportageNaam,
    params.vestiging
  );

  // MessageUrl en error die uit de servicebus komen in de state zetten
  useEffect(() => {
    messageTo && setMessageUrl(messageTo);
    errorSb && setErrorMsg(errorSb);
  }, [messageTo, errorSb]);

  // Als messageUrl aanwezig is de file downloaden
  /* istanbul ignore next */
  useEffect(() => {
    if (messageUrl) {
      const fileFetch = async (): Promise<void> => {
        const file = await fetch(messageUrl);
        const blob = await file.blob();

        const a = window.document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = `${overzichtSubscription?.rapportageNaam}.pdf`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      };

      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      fileFetch().finally(() => {
        // Spinner uitzetten
        setLoadingDocument(false);
      });
      setMessageUrl(undefined);
      setErrorMsg(undefined);
    }
  }, [messageUrl, errorMsg, overzichtSubscription]);

  // Eerste voorstel selecteren
  useEffect(() => {
    if (!values.rapportageDownloadenVan && voorstellen.length > 0) {
      setFieldValue("rapportageDownloadenVan", voorstellen[0].voorstelId);
    }
  }, [setFieldValue, values, voorstellen]);

  // Ophalen van de gegevens voor het bepalen welk report getoond moet worden
  const { error, loading, documentenLijst } = UseDocumentenAanwezig(values?.rapportageDownloadenVan);

  // Nodig voor de controle of er minimaal 1 en maximaal 3 voorstellen/ huidig zijn geselecteerd
  const selectedHypotheeklastenVan =
    values?.vergelijkendeHypotheeklastenVan?.filter(x => x.selected === true).length || 0;

  const body = (
    <>
      <LabeledRadioInput
        name={"rapportageDownloadenVan"}
        caption={"Rapportage downloaden van"}
        options={voorstellen.map(c => ({
          label: c.naam,
          value: c.voorstelId
        }))}
        disabled={loading || loadingDocument}
      />
      <h2>Analyse</h2>
      {(loading || loadingDocument) && (
        <div className="col-12 d-flex justify-content-center">
          <LoadingSpinner size="M" />
        </div>
      )}
      {!loading && !loadingDocument && (
        <div className={"row"}>
          {!loading &&
            documentenLijst &&
            documentenLijst[Object.keys(documentenLijst)[0]].financieringsopzetBeschikbaar && (
              <>
                <div className={"col-6 pb-2"}>Financieringsopzet</div>

                <div
                  className={"ml-1 p-1 btn-link"}
                  data-testid={"btnFinancieringsopzet"}
                  onClick={async () => {
                    logEvent(getRapportageResource("FRL004"), {
                      rapportNaam: "financieringsopzet",
                      gebruiker: user?.profile.name
                    });

                    setOverzichtenSubscription({
                      url: `${settings.rapportageOrigin}/Voorstellen/${values.rapportageDownloadenVan}/Financieringsopzet/Overzichten`,
                      body: null,
                      rapportageNaam: "financieringsopzet"
                    });
                  }}
                >
                  <Icon name={"pdf"} />
                </div>
              </>
            )}
          {!loading &&
            documentenLijst &&
            documentenLijst[Object.keys(documentenLijst)[0]].specificatieVerbouwingBeschikbaar && (
              <>
                <div className={"col-6 pb-2"}>Specificatie verbouwing</div>
                <div
                  className={"ml-1 p-1 btn-link"}
                  data-testid={"btnSpecificatieVerbouwing"}
                  onClick={async () => {
                    const overzichtUrl = `${settings.rapportageOrigin}/Voorstellen/${values.rapportageDownloadenVan}/Verbouwingsspecificatie/Overzichten`;

                    logEvent(getRapportageResource("FRL004"), {
                      rapportNaam: "specificatie-verbouwing",
                      gebruiker: user?.profile.name,
                      requestUrl: overzichtUrl
                    });

                    setOverzichtenSubscription({
                      url: overzichtUrl,
                      body: null,
                      rapportageNaam: "specificatie-verbouwing"
                    });
                  }}
                >
                  <Icon name={"pdf"} />
                </div>
              </>
            )}
          {!loading && documentenLijst && documentenLijst[Object.keys(documentenLijst)[0]].hypotheeklastenBeschikbaar && (
            <>
              <div className={"col-6 pb-2"}>Hypotheeklasten</div>
              <div
                className={"ml-1 p-1 btn-link"}
                data-testid={"btnHypotheeklastenBeschikbaar"}
                onClick={async () => {
                  const overzichtUrl = `${settings.rapportageOrigin}/Voorstellen/${values.rapportageDownloadenVan}/Hypotheeklasten/Overzichten`;

                  logEvent(getRapportageResource("FRL004"), {
                    rapportNaam: "hypotheeklasten",
                    gebruiker: user?.profile.name,
                    requestUrl: overzichtUrl
                  });

                  setOverzichtenSubscription({
                    url: overzichtUrl,
                    body: null,
                    rapportageNaam: "hypotheeklasten"
                  });
                }}
              >
                <Icon name={"pdf"} />
              </div>
            </>
          )}
        </div>
      )}

      <h2>Advies</h2>
      <div className={"row"}>
        <div className={"col-6 pb-2"}>
          Vergelijkende hypotheeklasten van <small>(maximaal 3)</small>
        </div>

        <div className={"col-6"}>
          {values?.vergelijkendeHypotheeklastenVan?.map((element, index) => {
            return (
              <CheckBox
                key={element.voorstelId}
                caption={element.naam}
                name={`vergelijkendeHypotheeklastenVan[${index}].selected`}
                disabled={loading || loadingDocument}
              />
            );
          })}
        </div>
      </div>
      <div className={"text-center"}>
        {(loading || loadingDocument) && (
          <div className="col-12 d-flex justify-content-center">
            <LoadingSpinner size="M" />
          </div>
        )}
        {!loading && !loadingDocument && (
          <Button
            className={"mt-3"}
            variant={"light"}
            disabled={selectedHypotheeklastenVan < 1 || selectedHypotheeklastenVan > 3}
            onClick={
              /* istanbul ignore next */ async () => {
                const voorstellenLijst = values?.vergelijkendeHypotheeklastenVan
                  ?.filter(x => x.voorstelId !== "Huidig")
                  ?.map(x => {
                    return { voorstelId: x.selected ? x.voorstelId : "" };
                  });
                const vergelijkendHypotheeklastenInput: VergelijkendHypotheeklastenInput = {
                  huidigeSituatie:
                    values?.vergelijkendeHypotheeklastenVan?.find(x => x.voorstelId.toLowerCase() === "huidig")
                      ?.selected || false,
                  voorstellen: voorstellenLijst
                };

                const overzichtUrl = `${settings.rapportageOrigin}/Adviesdossiers/${params.adviesdossier}/VergelijkendHypotheeklasten/Overzichten`;

                logEvent(getRapportageResource("FRL004"), {
                  rapportNaam: "vergelijkend-hypotheeklasten",
                  gebruiker: user?.profile.name,
                  requestUrl: overzichtUrl
                });

                setOverzichtenSubscription({
                  url: overzichtUrl,
                  body: JSON.stringify(vergelijkendHypotheeklastenInput),
                  rapportageNaam: "vergelijkend-hypotheeklasten"
                });
              }
            }
          >
            Vergelijkend hypotheeklasten rapport genereren
          </Button>
        )}
      </div>
    </>
  );

  return (
    <>
      {body}
      {error && (
        <>
          Er is een fout opgetreden, de producten behorende bij het maandlastenoverzicht kunnen niet worden opgevraagd.
        </>
      )}
    </>
  );
};

export const RapportageDownloadenModal = ({ closeModal }: RapportageDownloadenModalProps): ReactElement => {
  return (
    <AdviesBoxFormik<RapportageDownloadenState>
      initialValues={{ ...rapportageDownloadenSchema.default() }}
      validationSchema={rapportageDownloadenSchema}
      closeModal={closeModal}
      render={(): ReactElement => (
        <>
          <Modal title="Rapportage downloaden" body={<Body />} noEditableData onCancelClick={closeModal} />
        </>
      )}
    />
  );
};
