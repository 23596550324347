import React, { ReactElement, useEffect, useState } from "react";
import { connect, FormikContextType } from "formik";
import { SituatieSoort } from "../../producten-overzicht/infra/producten-overzicht-types";
import { bedragFormat } from "../../shared/utils/currency";
import { PercentageInput } from "adviesbox-shared";
import { HypothekenState } from "../infra/hypotheek-types";
import { pandSchema } from "../infra/hypotheek-schema";
import "./hypotheek-totalen.scss";

type HypothekenTotalenProps = {
  situatie: SituatieSoort;
  selected?: number;
};

const HypotheekTotalen = ({
  situatie,
  selected = 0,
  formik: { values }
}: HypothekenTotalenProps & {
  formik: FormikContextType<HypothekenState>;
}): ReactElement => {
  const IsVoorstel = situatie === "voorstel";

  const [bedragen, setBedragen] = useState({
    totaalHyptheek: 0,
    totaalMartkWaarde: 0,
    totaalMarktWaardeLTV: 0
  });
  useEffect((): void => {
    const selectedProduct = situatie === "huidig" ? values.producten[selected] : null;
    const pandId = selectedProduct?.hypotheekProductDetails?.hypotheekOpWoning;        
    const pand =
      (IsVoorstel ? values.panden.find(() => true) : values.panden.find(x => x.pandId === pandId)) ||
      pandSchema.default();

    setBedragen({
      totaalHyptheek: pand.totaleHypotheekBedrag || 0,
      totaalMartkWaarde: pand.marktwaardeRenteBedrag || 0,
      totaalMarktWaardeLTV: pand.marktwaardeLtv.bedrag || 0
    });
  }, [selected, values.panden, values.producten, IsVoorstel, situatie]);

  return (
    <>
      <div className="hypotheek-totalen" data-testid="hypotheekTotalen.totalen">
        <div
          className="hypotheek-totalen__entry"
          id="hypotheekTotalen.totaleHypotheek"
          data-testid="hypotheekTotalen.totaleHypotheek"
          data-value={bedragen.totaalHyptheek}
        >
          <div className="hypotheek-totalen__label">
            Totale Hypotheek:
            <span className="hypotheek-totalen__price">{bedragFormat(bedragen.totaalHyptheek)}</span>
          </div>
        </div>
        <div
          className="hypotheek-totalen__entry"
          id="hypotheekTotalen.marktWaarde"
          data-testid="hypotheekTotalen.marktWaarde"
          data-value={bedragen.totaalMartkWaarde}
        >
          <div className="hypotheek-totalen__label">
            {situatie === "huidig" ? "Marktwaarde:" : "Marktwaarde rente:"}
            <span className="hypotheek-totalen__price">{bedragFormat(bedragen.totaalMartkWaarde)}</span>
            {IsVoorstel && (
              <>
                <span className="hypotheek-totalen__border"> / </span>
                <div className="hypotheek-totalen__percentage d-inline-block">
                  <PercentageInput name="panden[0].bevoorschottingspercentage" decimalen={2} readonly />
                </div>
              </>
            )}
          </div>
        </div>
        {IsVoorstel && (
          <div
            className="hypotheek-totalen__entry"
            id="panden[0].marktwaardeLtv"
            data-testid="panden[0].marktwaardeLtv"
            data-value={bedragen.totaalMarktWaardeLTV}
          >
            <div className="hypotheek-totalen__label">
              Marktwaarde LTV:
              <span className="hypotheek-totalen__price">{bedragFormat(bedragen.totaalMarktWaardeLTV)}</span>
            </div>
          </div>
        )}
        {IsVoorstel && values?.hypotheeklabel?.labelNaam && (
          <div
            className="hypotheek-totalen__entry"
            id="hypotheeklabel.labelNaam"
            data-testid="hypotheeklabel.labelNaam"
          >
            <div className="hypotheek-totalen__label">{values.hypotheeklabel.labelNaam}</div>
          </div>
        )}
      </div>
    </>
  );
};

export default connect<HypothekenTotalenProps, HypothekenState>(HypotheekTotalen);
