import { BerekenDateType } from "./schema";
import { mapStringToLocalDate } from "adviesbox-shared";

export function mapBerekenDate(
  datum: string,
  berekenen: boolean,
  berekendeDatum: string
): { [P in keyof BerekenDateType]: Exclude<BerekenDateType[P], null> };
export function mapBerekenDate(
  datum: string | null,
  berekenen: boolean,
  berekendeDatum: string | null
): BerekenDateType;
export function mapBerekenDate(
  datum?: string | null,
  berekenen?: boolean,
  berekendeDatum?: string | null
): BerekenDateType;

export function mapBerekenDate(
  datum?: string | null,
  berekenen?: boolean,
  berekendeDatum?: string | null
): BerekenDateType {
  return {
    datum: mapStringToLocalDate(datum),
    berekenen: berekenen || false,
    berekendeDatum: mapStringToLocalDate(berekendeDatum)
  };
}
