/* istanbul ignore file */
import { createContext } from "react";
import {
  AanvullendeGegevensType,
  aanvullendeGegevensSchema
} from "../components/dossier/dossierbalk-fouten-dropdown/aanvullende-gegevens-schema";
import { nieuweAanvraagType } from "./dossier-aanvraag-fouten-provider";
import { ProductTypeName } from "../../aanvraag/infra/aanvraag-schema";

export const dossierFoutenSetup: {
  setAanvullendeGegevens: (aanvullendeGegevens: AanvullendeGegevensType) => void;
  aanvullendeGegevens: AanvullendeGegevensType;
  setNieuweAanvraag: (nieuweAanvraag: nieuweAanvraagType | null) => void;
  nieuweAanvraag: nieuweAanvraagType | null;
} = {
  setAanvullendeGegevens: (_aanvullendeGegevens: AanvullendeGegevensType) => {},
  aanvullendeGegevens: aanvullendeGegevensSchema.default(),
  setNieuweAanvraag: (_nieuweAanvraag: nieuweAanvraagType | null) => null,
  nieuweAanvraag: {
    isloading: false,
    partijCode: "",
    productType: ProductTypeName.hypotheek,
    receivedId: "",
    closeModal: false
  }
};

export type DossierFoutenContextType = typeof dossierFoutenSetup;

const DossierAanvraagFoutenContext = createContext(dossierFoutenSetup);

export default DossierAanvraagFoutenContext;
