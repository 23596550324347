import {
  Card,
  CardWrapper,
  FormFirstFocus,
  LabeledSelectInput,
  PageLoading,
  useRequestInit,
  PlatformFoutenSamenvatting,
  NotificatiesContext,
  insightsReactPlugin
} from "adviesbox-shared";
import { Form, FormikProps, useFormikContext } from "formik";
import React, { ReactElement, useContext } from "react";
import { useParams } from "react-router-dom";
import { AppDataContext } from "../navigation/appdata-context";
import { Debug } from "../shared/components/formik/Debug";
import { ISWSideEffects } from "../shared/components/isw-side-effects/isw-side-effects";
import DossierAanvraagFoutenContext from "../shared/dossier-aanvraag-fouten/dossier-aanvraag-fouten-context";
import { RouteParams } from "../shared/paramrouting/paramrouting-context";
import { LabelValuePairs } from "../shared/types";
import { withAdviesboxFormik } from "../shared/utils/with-adviesbox-formik";
import { AanvraagHypotheek } from "./aanvraag-hypotheek/aanvraag-hypotheek";
import { AanvraagProduct, AanvraagProductType } from "./aanvraag-product/aanvraag-product";
import { aanvraagProductTitle } from "./infra/aanvraag-helper";
import {
  AanvraagProps,
  aanvraagSchema,
  AanvraagState,
  productSoortEnum,
  ProductTypeName
} from "./infra/aanvraag-schema";
import { asyncAanvraagSideEffects } from "./infra/determine-async-aanvraag-side-effects";
import { syncAanvraagSideEffects } from "./infra/determine-sync-aanvraag-side-effects";
import classNames from "classnames";
import classes from "./aanvraag.module.scss";
import { getAanvraagTextResources } from "./infra/aanvraag-resources";
import { withAITracking } from "@microsoft/applicationinsights-react-js";

export const createLabelValuePairs = (values: AanvraagState): LabelValuePairs | null => {
  if (values.voorstelArray) {
    return values.voorstelArray.map((voorstel, i) => {
      return {
        label: `${voorstel.omschrijving === "" ? `Voorstel#${i + 1}` : voorstel.omschrijving}`,
        value: voorstel.id
      };
    });
  }

  return null;
};

const AanvraagComponent = (props: FormikProps<AanvraagState> & AanvraagProps): ReactElement => {
  const { isSubmitting, values, setFieldValue } = props;
  const { settings, user } = useRequestInit<{ vestiging: string; adviesdossier: string }>();
  const { nieuweAanvraag, setNieuweAanvraag } = useContext(DossierAanvraagFoutenContext);
  const { reloadNavigation } = useContext(AppDataContext);
  const formik = useFormikContext<AanvraagState>();
  const voorstelOptions: LabelValuePairs | null = createLabelValuePairs(values);
  const paramContext = useParams<RouteParams>();
  const heeftProducten = !!values.orvProduct.length || !!values.aovProduct.length || !!values.hypotheek;

  const { setSErunning } = useContext(AppDataContext);

  const showPageLoading = isSubmitting || values.isLoadingAanvraag;

  const { subscribeCorrelationId } = useContext(NotificatiesContext);

  return (
    <FormFirstFocus>
      <Form>
        {showPageLoading && <PageLoading />}

        <ISWSideEffects<AanvraagState>
          sync={syncAanvraagSideEffects({ formik })}
          async={asyncAanvraagSideEffects({
            adviesdossierId: paramContext.adviesdossier,
            vestigingId: paramContext.vestiging,
            settings,
            user,
            setFieldValue,
            reloadNavigation,
            subscribeCorrelationId: subscribeCorrelationId,
            setNieuweAanvraag: setNieuweAanvraag
          })}
          runOnFirstRender
          asyncStartStopCallback={setSErunning}
        />

        <CardWrapper className="px-3">
          <div className="text-container">
            <h2>Aanvraag samenstellen</h2>
          </div>
        </CardWrapper>

        <PlatformFoutenSamenvatting />

        <CardWrapper maxRowCount={1} className={classNames("px-3", "col-12")}>
          {voorstelOptions && (
            <Card title="" className={classNames("col-6")}>
              <div>
                <LabeledSelectInput
                  key={"Aanvraag op basis van"}
                  caption="Aanvraag op basis van"
                  name="aanvraagOpBasisVan"
                  onChange={e => {
                    setFieldValue("aanvraagOpBasisVan", e.target.value);
                    setFieldValue("isLoadingAanvraag", true);
                    setFieldValue("geselecteerdProduct", null);
                  }}
                  options={voorstelOptions}
                  readonly={voorstelOptions.length === 1}
                  emptyValue={"-- selecteer een voorstel --"}
                />
              </div>
            </Card>
          )}
        </CardWrapper>

        <CardWrapper className="px-3">
          <>
            {!values.isLoadingAanvraag && !heeftProducten && (
              <Card title="">
                <div className={classes.header}>{getAanvraagTextResources("geenProductenTxt")}</div>
                <div className={classes.text}>{getAanvraagTextResources("voegProductenToeTxt")}</div>
              </Card>
            )}
            {voorstelOptions && heeftProducten && (
              <>
                {values.aanvraagOpBasisVan && (
                  <>
                    {values.hypotheek && (
                      <AanvraagHypotheek
                        title={`Hypotheek ${values.hypotheek.partijnaam}`}
                        isLoading={nieuweAanvraag?.isloading ?? false}
                        vestiging={paramContext.vestiging}
                        adviesdossier={paramContext.adviesdossier}
                      />
                    )}
                    {!!values.orvProduct.length &&
                      values.orvProduct.map((orvProduct, index) => (
                        <AanvraagProduct
                          productType={AanvraagProductType.Orv}
                          vestiging={paramContext.vestiging}
                          adviesdossier={paramContext.adviesdossier}
                          key={`orvProduct-${index}`}
                          testId={`orvProduct-${index}`}
                          title={aanvraagProductTitle(ProductTypeName.ORV, index, values.orvProduct.length)}
                          product={orvProduct}
                          index={index}
                          name={`${productSoortEnum.orvProduct}[${index}]`}
                          isLoading={nieuweAanvraag?.isloading ?? false}
                        />
                      ))}
                    {!!values.aovProduct.length &&
                      values.aovProduct.map((aovProduct, index) => (
                        <AanvraagProduct
                          productType={AanvraagProductType.Aov}
                          vestiging={paramContext.vestiging}
                          adviesdossier={paramContext.adviesdossier}
                          key={`aovProduct-${index}`}
                          testId={`aovProduct-${index}`}
                          title={aanvraagProductTitle(ProductTypeName.AOV, index, values.aovProduct.length)}
                          product={aovProduct}
                          index={index}
                          name={`${productSoortEnum.aovProduct}[${index}]`}
                          isLoading={nieuweAanvraag?.isloading ?? false}
                        />
                      ))}
                  </>
                )}
              </>
            )}
          </>
        </CardWrapper>
        <Debug />
      </Form>
    </FormFirstFocus>
  );
};

export const Aanvraag = withAdviesboxFormik<AanvraagProps, AanvraagState>({
  mapPropsToValues: (e: AanvraagProps): AanvraagState => e,
  validationSchema: aanvraagSchema
})(withAITracking(insightsReactPlugin, AanvraagComponent));
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production") Aanvraag.displayName = "Aanvraag";
