import { LabeledSelectInput } from "adviesbox-shared";
import { useFormikContext } from "formik";
import React, { ReactElement, useEffect } from "react";
import { HypotheeksamenstellingLeningdeel } from "../../.generated/forms/formstypes";
import { SituatieSoort } from "../../producten-overzicht/infra/producten-overzicht-types";
import { bedragFormat } from "../../shared/utils/currency";
import { HypotheekProductSelectieStateType, HypotheekSelectieSamenstellingType } from "../infra/hypotheek-types";
import { mapHypotheekSamenstellingProductDlToUi } from "../infra/map-hypotheek-dl-2-ui";
import { getOptions } from "./hypotheek-selectie-helper";
import { useHypotheekSamenstellingGenereerData } from "./use-hypotheek-selectie-genereer-samenstelling-data";

export type HypotheekSelectieGenereerSamenstellingProps = {
  situatie: SituatieSoort;
  setHypotheekSamenstellingLoadingState: (value: boolean) => void;
};

export function mapHypotheekSelectieGenereerSamenstellingToUi(
  leningdelenDl: HypotheeksamenstellingLeningdeel[]
): HypotheekSelectieSamenstellingType[] {
  return leningdelenDl.map(c => {
    return mapHypotheekSamenstellingProductDlToUi(c);
  });
}

const HypotheekSelectieGenereerSamenstelling = ({
  setHypotheekSamenstellingLoadingState
}: HypotheekSelectieGenereerSamenstellingProps): ReactElement => {
  const { setFieldValue, values, submitForm } = useFormikContext<HypotheekProductSelectieStateType>();
  const { maatschappijCode, labelCode } = values.maatschappijProductCode;
  const {
    data: hypotheekSamenstellingDataState,
    error: hypotheekSamenstellingDataErrorState,
    loading
  } = useHypotheekSamenstellingGenereerData(maatschappijCode, labelCode);

  useEffect(() => setHypotheekSamenstellingLoadingState(loading), [loading, setHypotheekSamenstellingLoadingState]);

  useEffect(() => {
    if (
      hypotheekSamenstellingDataState &&
      typeof hypotheekSamenstellingDataState !== "string" &&
      hypotheekSamenstellingDataState.resultaat?.leningdelen?.length
    ) {
      setFieldValue(
        "leningdelen",
        mapHypotheekSelectieGenereerSamenstellingToUi(hypotheekSamenstellingDataState.resultaat.leningdelen) ||
          /* istanbul ignore next */ []
      );
    }

    const hasNothingToMatch =
      (hypotheekSamenstellingDataState?.isValid === true && hypotheekSamenstellingDataState?.resultaat === null) ||
      hypotheekSamenstellingDataState?.resultaat?.leningdelen?.every(
        c => !c.nearMatchHypotheekvormen && !c.matchHypotheekvormen
      );

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    if (hasNothingToMatch) submitForm();
  }, [hypotheekSamenstellingDataState, loading, setFieldValue, submitForm]);

  const list = (values.leningdelen as any)
    ?.filter((c: HypotheekSelectieSamenstellingType) => c.nearMatchHypotheekvormen || c.matchHypotheekvormen)
    .map((v: HypotheekSelectieSamenstellingType, i: number): ReactElement | undefined => {
      return (
        <LabeledSelectInput
          caption={`${v.huidigeHypotheekvorm?.aflossingsvorm} (${bedragFormat(
            v.leningdeelgegevens.leningdeelHoofdsom.bedrag || null
          )}) omzetten naar:`}
          name={`nearMatchHypotheekvorm[${i}]`}
          key={i}
          options={getOptions(v)}
        />
      );
    });

  if (loading || !hypotheekSamenstellingDataState) {
    return <p className="px-1">Laden...</p>;
  } else if (hypotheekSamenstellingDataErrorState) {
    return <p className="px-1">Er is iets fout gegaan bij het ophalen van de data</p>;
  }

  return (
    <>
      <h3>Aflossingsvorm</h3>
      <p className="px-1">
        De onderstaande aflossingsvorm heeft bij de gekozen maatschappij meerdere producten met deze aflossingsvorm.
        Kies hieronder het gewenste product.
      </p>
      {list}
    </>
  );
};
export default HypotheekSelectieGenereerSamenstelling;
