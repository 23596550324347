/* istanbul ignore file */
//import { AuthContext } from "adviesbox-shared";
import { PageLoading, withErrorBoundary } from "adviesbox-shared";
import React, { ReactElement } from "react";
import { RouteComponentProps } from "react-router-dom";
import { UiError } from "../shared/types";
import { productenSchema } from "./infra/producten-overzicht-schema";
import ProductenOverzicht from "./producten-overzicht";

type RouteParams = {
  vestiging: string;
  producten: string;
};

const ProductenOverzichtAjax = ({ match }: RouteComponentProps<RouteParams>): ReactElement => {
  // const { user } = useContext(AuthContext);
  // const { vestiging: vestigingId, producten: dataId } = match.params;

  // const url = `/api/producten.json`;
  // const requestInit = useMemo(
  //   (): RequestInit => ({
  //     headers: {
  //       // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  //       authorization: `${user!.token_type} ${user!.access_token}`,
  //       vestigingId
  //     }
  //   }),
  //   [vestigingId, user]
  // );
  // const { loading, error, data } = useAbortableFetch<ProductenOverzichtDlOutput>(url, requestInit);

  // if (!user) {
  //   return <ErrorPage error={new Error("Missing AuthContext")} />;
  // }

  // if (error) {
  //   return <ErrorPage error={error} data={data} />;
  // }

  // if (typeof data === "string") {
  //   return <ErrorPage error={new Error("Invalid data")} data={data} />;
  // }

  // if (loading || !data) {
  //   return <PageLoading />;
  // }

  const mappedProps = productenSchema.default(); //gebruik in ORV!!:  mapProductenOverzichtDlToUi(dataId, data);
  if (!mappedProps) {
    return <PageLoading />;
  }

  return (
    <ProductenOverzicht
      saveData={async (): Promise<UiError[] | null> => {
        return null;
      }}
      {...mappedProps}
    />
  );
};

ProductenOverzichtAjax.displayName = "ProductenOverzichtAjax";

export default withErrorBoundary(ProductenOverzichtAjax);
