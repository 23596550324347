import {
  FiscaleVoortzettingOptions,
  RentevariantOptions,
  Financieringsoort,
  AflossingsVormType,
  IngPriceOutput,
  PeriodiekeAflossingInput,
  PeriodiekeAflossingOutput,
  RentepercentageInput,
  RentepercentageOutput,
  BetalingsTermijnType,
  RentepercentageResultaat,
  Hypotheekoptie
} from "../../.generated/forms/formstypes";
import {
  MarktwaardesOutput,
  RentevariantMetRenteperiodes,
  RentevariantMetRenteperiodesOutput,
  RentevariantMetRenteperiodesRenteafspraak
} from "../../.generated/hypotheekrentes/hypotheekrentestypes";
import {
  HypotheeklabelDetails,
  HypotheeklabelDetailsOutput,
  HypotheekvormBasis
} from "../../.generated/producten/productentypes";
import {
  InlegOutput,
  PeriodiekBedragInputTermijn,
  PeriodiekeOnttrekkingInputTermijn,
  VermogensrekeningInput,
  VermogensrekeningInputSoortOpbouwBerekening,
  WaardePerMaand
} from "../../.generated/vermogen/vermogentypes";
import { partijOnafhankelijk } from "../../producten-overzicht/infra/product-constanten";
import { mapKenmerken } from "../../producten-overzicht/infra/product-kenmerken-mapping";
import {
  HypothekenKenmerken,
  KenmerkenError,
  ProductKenmerkenDlType
} from "../../producten-overzicht/infra/product-kenmerken-types";
import { SituatieSoort } from "../../producten-overzicht/infra/producten-overzicht-types";
import {
  createISWAsyncSideEffect,
  initISWAsyncSideEffect
} from "../../shared/components/isw-side-effects/create-isw-helpers";
import { jaarMaandInMaanden } from "../../shared/generic-parts/jaar-maand/map-ui-2-dl";
import { Return } from "../../shared/hooks/use-map";
import { AanvragerKeuze } from "../../shared/types";
import { createMapToDl } from "../../shared/utils/create-map-to-dl";
import { afronden } from "../../shared/utils/currency";
import { partijcodeING, WaardeopbouwMaandType } from "../../vermogen/infra/vermogen-types";
import { mapIngPriceToolInput } from "../hypotheek-opties-ing/map-ing-price-tool";
import { hypothekenBaseSchema } from "./hypotheek-schema";
import { HypotheekType, HypothekenState, SoortOnderpand } from "./hypotheek-types";
import { mapDlTargetToHypotheekUiFieldPeriodiekeAflossing } from "./map-hypotheek-dl-2-ui";
import { getKenmerkCodeForProduct } from "./hypotheek-utils";
import { hasValue, mapLocalDateToString } from "adviesbox-shared";
import { getHypotheekTextResources } from "./hypotheek-resources";
import { ISWFetchDataParams } from "../../shared/components/isw-side-effects/isw-types";
import _ from "lodash";
import { defaultProductkenmerkenStarterslening } from "./default-productkenmerken-starterslening";

export type AsyncContext = {
  selected: number;
  berekenState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  situatie: SituatieSoort;
  voorstelId: string;
  ingHdnData: [string, string][];
  kenmerken: HypothekenKenmerken;
  kenmerkenMapActions: Return<string, HypothekenKenmerken | KenmerkenError>;
  hypotheekvormen: { [index: string]: HypotheekvormBasis } | null;
  hypotheekDetailData: HypotheeklabelDetailsOutput | null;
};

// const mapEnumAflossingsVormTypeToDl = createMapEnum(AflossingsVormType).to<PeriodiekeAflossingInput>({
//   Geen: AflossingsVormType.Geen,
//   Annuïteit: AflossingsVormType.Annuïteit,
//   Lineair: PeriodiekeAflossingInput.Lineair,
//   Levensverzekering: PeriodiekeAflossingInput.Levensverzekering,
//   Spaar: PeriodiekeAflossingInput.Spaar,
//   Aflosvrij: PeriodiekeAflossingInput.Aflosvrij,
//   Krediet: PeriodiekeAflossingInput.Krediet,
//   Belegging: PeriodiekeAflossingInput.Belegging,
//   Hybride: PeriodiekeAflossingInput.Hybride,
//   UnitLinked: PeriodiekeAflossingInput.UnitLinked,
//   Spaarrekening: PeriodiekeAflossingInput.Spaarrekening,
//   AnnuïteitUitgesteld: PeriodiekeAflossingInput.AnnuïteitUitgesteld,
//   AnnuïteitBlok: PeriodiekeAflossingInput.AnnuïteitBlok,
//   KredietNoPay: PeriodiekeAflossingInput.KredietNoPay
// });

const getSoortOnderpand = (prod: HypotheekType): SoortOnderpand => {
  return prod.soortOnderpand === Financieringsoort.AankoopBestaandeBouw
    ? SoortOnderpand.BestaandeBouw
    : prod.soortOnderpand === Financieringsoort.AankoopNieuwbouw
    ? SoortOnderpand.Nieuwbouw
    : SoortOnderpand.Geen;
};

const getRenteboxCode = (
  hypotheekvormen: { [index: string]: HypotheekvormBasis } | null,
  product: HypotheekType
): number | null => {
  const hypotheekvorm = hypotheekvormen
    ? Object.values(hypotheekvormen).find(
        vorm =>
          `${vorm.aflossingsvorm}` === `${product.hypotheekVorm.aflossingsvorm}` &&
          `${vorm.code}` === `${product.hypotheekVorm.code}`
      )
    : null;
  return hypotheekvorm?.renteboxCode ?? null;
};

const getRentevariantenMetRentePeriodes = async <T>(
  hypotheekRentesOrigin: string,
  producten: HypotheekType[],
  marktwaardePercentage: number,
  nhg: boolean,
  hypotheekvormen: { [index: string]: HypotheekvormBasis },
  fetchData: <R, B = undefined>(params: ISWFetchDataParams<T, B>) => Promise<R>
): Promise<{ [index: number]: RentevariantMetRenteperiodes[] }> => {
  const renteCalls: indexedFetchCall<RentevariantMetRenteperiodesOutput>[] = [];

  producten.forEach((product, index) => {
    const geldverstrekkerCode = product.partijCode;
    const hypotheeklabelCode = product.labelCode;
    const soortOnderpand = getSoortOnderpand(product);
    const renteboxCode = getRenteboxCode(hypotheekvormen, product);
    if (!renteboxCode) return;

    const url = `${hypotheekRentesOrigin}/Geldverstrekkers/${geldverstrekkerCode}/Hypotheeklabels/${hypotheeklabelCode}/Hypotheekvormen/${renteboxCode}/Rentevarianten?soortOnderpand=${soortOnderpand}&marktwaardePercentage=${marktwaardePercentage}&nhg=${nhg}`;

    renteCalls.push({
      call: fetchData({ url, method: "GET" }),
      index: index
    });
  });
  const fetchedRentevarianten = await Promise.all(
    renteCalls.map(async rv => {
      return { index: rv.index, result: await rv.call };
    })
  );
  const rentevarianten: { [index: number]: RentevariantMetRenteperiodes[] } = {};
  fetchedRentevarianten.forEach(rv => {
    if (rv.result.isValid && !!rv.result.rentevarianten) {
      rentevarianten[rv.index] = rv.result.rentevarianten;
    }
  });
  return rentevarianten;
};

const getHypotheekrentes = async <T>(
  klantdossiersFormsOrigin: string,
  producten: HypotheekType[],
  hypotheekOpties: Hypotheekoptie[],
  voorstelId: string,
  hypotheekvormen: { [index: string]: HypotheekvormBasis },
  fetchData: <R, B = undefined>(params: ISWFetchDataParams<T, B>) => Promise<R>
): Promise<{ [index: number]: RentepercentageResultaat }> => {
  const voorstelLeningdeelRenteCalls: indexedFetchCall<RentepercentageOutput>[] = [];

  producten.forEach((product, index) => {
    const renteboxCode = getRenteboxCode(hypotheekvormen, product);
    if (
      product.partijCode === partijOnafhankelijk ||
      product.productCode === partijcodeING ||
      !renteboxCode ||
      !product.leningdeelgegevens.rentePercentage?.berekenen
    ) {
      return;
    }
    const urlLeningdeelRente = klantdossiersFormsOrigin + "/Voorstellen/" + voorstelId + "/Hypotheek/Hypotheekrente";
    if (product.labelCode && product.hypotheekVorm.code && product.leningdeelgegevens.rentevastPeriodeJaar) {
      const input: RentepercentageInput = {
        aflossingsVorm: AflossingsVormType[product.hypotheekVorm.aflossingsvorm],
        hypotheeklabelCode: product.labelCode,
        hypotheekopties: hypotheekOpties,
        hypotheekvormCode: renteboxCode || 0,
        maatschappijCode: product.renteBoxMaatschappijCode || product.partijCode,
        personeelskortingPercentage: null,
        rentebedenktijdInMaanden: (product.leningdeelgegevens.renteBedenktijdJaar || 0) * 12,
        rentevariant: product.leningdeelgegevens.renteVariant,
        rentevastAantalMaanden: Number(product.leningdeelgegevens.rentevastPeriodeJaar) * 12
      };
      voorstelLeningdeelRenteCalls.push({
        call: fetchData({ url: urlLeningdeelRente, method: "POST", body: JSON.stringify(input) }),
        index: index
      });
    }
  });
  const fetchedVoorstelRentes = await Promise.all(
    voorstelLeningdeelRenteCalls.map(async rc => {
      return { index: rc.index, result: await rc.call };
    })
  );
  const result: { [index: number]: RentepercentageResultaat } = {};
  fetchedVoorstelRentes.forEach(vr => {
    if (vr.result.isValid && !!vr.result.resultaat) {
      result[vr.index] = vr.result.resultaat;
    }
  });
  return result;
};

export const hypotheekAsyncAutomatischeRentedaling = createISWAsyncSideEffect<HypothekenState, AsyncContext>(
  async ({ draft, settings, fetchData, context }) => {
    const selectedProduct = draft.producten[context.selected];
    const geldverstrekkerCode = selectedProduct.partijCode;
    const hypotheeklabelCode = selectedProduct.labelCode;
    const renteboxcode = getRenteboxCode(context.hypotheekvormen, selectedProduct);
    if (!renteboxcode) return;
    const result = await fetchData<MarktwaardesOutput>({
      method: "GET",
      url:
        `${settings.hypotheekrentesOrigin}/Geldverstrekkers/${geldverstrekkerCode}/Hypotheeklabels/${hypotheeklabelCode}/Hypotheekvormen/${renteboxcode}/Marktwaardes/Opslagen` +
        `?renteafspraak=${
          draft.producten[context.selected].leningdeelgegevens.renteVariant === RentevariantOptions.Rentevast ? 1 : 3
        }` +
        `&rentevastAantalMaanden=` +
        (Number(draft.producten[context.selected].leningdeelgegevens.rentevastPeriodeJaar) * 12 ?? 0) +
        `&rentebedenktijdInMaanden=` +
        (Number(draft.producten[context.selected].leningdeelgegevens.renteBedenktijdJaar) * 12 ?? 0) +
        `&soortOnderpand=${getSoortOnderpand(draft.producten[context.selected])}` +
        `&marktwaardePercentage=${draft.panden.find(p => p)?.bevoorschottingspercentage ?? 100}` +
        `&nhg=${!!draft.nhg}`
    });

    /* istanbul ignore else */
    if (result.isValid) {
      draft.producten[context.selected].leningdeelgegevens.automatischeRentedalingModal.rentedalingPercentages = [];

      result.marktwaardes
        ?.filter(c => !c.nhg)
        .forEach(c =>
          draft.producten[
            context.selected
          ].leningdeelgegevens.automatischeRentedalingModal.rentedalingPercentages?.push({
            marktwaardePercentageTotEnMet:
              c.marktwaardePercentageTotEnMet === 0 && c.marktwaardePercentageVanaf
                ? c.marktwaardePercentageVanaf
                : c.marktwaardePercentageTotEnMet
                ? c.marktwaardePercentageTotEnMet
                : 0,
            renteopslagPercentage: c.renteopslagPercentage ?? 0
          })
        );
    }
  }
);

type indexedFetchCall<T> = {
  call: Promise<T>;
  index: number;
};

export const mapPeriodiekeAflossingInput = createMapToDl(hypothekenBaseSchema)
  .with<number>()
  .to<PeriodiekeAflossingInput>({
    aflossingsVorm: (v, i) => v.producten[i].hypotheekVorm.aflossingsvorm,
    leningdeelBedrag: (v, i) => v.producten[i].leningdeelgegevens.leningdeelHoofdsom.bedrag,
    aanvangsdatum: (v, i) => mapLocalDateToString(v.producten[i].product.ingangsdatum) ?? "",
    looptijdInMaanden: (v, i) => {
      const maandenTotaal = jaarMaandInMaanden(v.producten[i].product.looptijd);
      return maandenTotaal ? maandenTotaal : null;
    },
    opgaveDatum: (v, i) =>
      mapLocalDateToString(v.producten[i].leningdeelgegevens.datumOpgave) ??
      mapLocalDateToString(v.producten[i].product.ingangsdatum),
    rentePercentage: (v, i) => v.producten[i].leningdeelgegevens.rentePercentage?.bedrag ?? 100
  });

export const hypotheekAsyncPeriodiekeAflossing = createISWAsyncSideEffect<HypothekenState, AsyncContext>(
  async ({ draft, settings, fetchData, context }) => {
    const result = await fetchData<PeriodiekeAflossingOutput, PeriodiekeAflossingInput>({
      url: `${settings.klantdossiersFormsOrigin}/Hypotheek/Periodieke Aflossing`,
      body: mapPeriodiekeAflossingInput(context.selected),
      mapperDlNameToUiName: mapDlTargetToHypotheekUiFieldPeriodiekeAflossing(context.selected)
    });

    /* istanbul ignore else */
    if (result.isValid) {
      draft.producten[context.selected].leningdeelgegevens.periodiekeAflossing =
        result.resultaat?.aflossingBedrag ?? /* istanbul ignore next */ null;
    }
  }
);

export const mapPremieBerekeningInput = createMapToDl(hypothekenBaseSchema)
  .with<number>()
  .to<VermogensrekeningInput>({
    soortOpbouwBerekening: () => VermogensrekeningInputSoortOpbouwBerekening.Spaar,
    doelkapitaal: (v, i) =>
      (v.producten[i].verzekerde.verzekerde === AanvragerKeuze.Aanvrager2
        ? afronden(v.producten[i].kapitaalopbouw?.doelkapitaal2Bedrag || null, 0) || null
        : afronden(v.producten[i].kapitaalopbouw?.doelkapitaal1Bedrag || null, 0)) || null,
    garantiekapitaal: (v, i) =>
      (v.producten[i].verzekerde.verzekerde === AanvragerKeuze.Aanvrager2
        ? afronden(v.producten[i].kapitaalopbouw?.doelkapitaal2Bedrag || null, 0) || null
        : afronden(v.producten[i].kapitaalopbouw?.doelkapitaal1Bedrag || null, 0)) || null,
    rendement: (v, i) =>
      (v.producten[i].verzekerde.verzekerde === AanvragerKeuze.Aanvrager2
        ? v.producten[i].kapitaalopbouw?.doelkapitaal2Percentage
        : v.producten[i].kapitaalopbouw?.doelkapitaal1Percentage) || 0,
    garantierendement: (v, i) =>
      (v.producten[i].verzekerde.verzekerde === AanvragerKeuze.Aanvrager2
        ? v.producten[i].kapitaalopbouw?.doelkapitaal2Percentage
        : v.producten[i].kapitaalopbouw?.doelkapitaal1Percentage) || 0,
    looptijdInMaanden: (v, i) => {
      const product = v.producten[i];
      /* istanbul ignore else */
      if (product && product.premieGegevens) {
        return jaarMaandInMaanden(product.premieGegevens.looptijd);
      }
      return 0;
    },
    eersteInleg: (v, i) => v.producten[i].premieGegevens?.aanvangExtraPremieStortingenBedrag || 0,
    hoogLaagLooptijdInMaanden: (v, i) => {
      const product = v.producten[i];
      /* istanbul ignore else */
      if (product && product.premieGegevens) {
        return jaarMaandInMaanden(product.premieGegevens.hoogLaagLooptijd);
      }
      return 0;
    },
    hoogLaagInlegHoog: (v, i) => v.producten[i].premieGegevens?.spaarPremieHoog || 0,
    garantiepercentageInleg: (v, i) => 100,
    inleg: (v, i) => {
      const product = v.producten[i];
      let looptijdInMaanden = null;
      /* istanbul ignore else */
      if (product && product.premieGegevens) {
        looptijdInMaanden = jaarMaandInMaanden(product.premieGegevens.looptijd);
      }
      return {
        looptijdInMaanden: looptijdInMaanden || 0,
        termijn: v.producten[i].premieGegevens?.betalingstermijn as PeriodiekBedragInputTermijn,
        bedrag: 0
      };
    },
    onttrekking: (v, i) => {
      const product = v.producten[i];
      let looptijdInMaanden = null;
      /* istanbul ignore else */
      if (product && product.premieGegevens) {
        looptijdInMaanden = jaarMaandInMaanden(product.premieGegevens.looptijd);
      }
      return {
        looptijdInMaanden: looptijdInMaanden || 0,
        termijn: v.producten[i].premieGegevens?.betalingstermijn as PeriodiekeOnttrekkingInputTermijn,
        bedrag: 0,
        aanvangsmaand: null // TODO
      };
    },
    extraInleggen: (v, i) => {
      return [
        //TODO
        {
          jaar: 1,
          bedrag: 0
        }
      ];
    },
    extraOnttrekkingen: (v, i) => {
      return [
        //TODO
        {
          jaar: 1,
          bedrag: 0
        }
      ];
    },
    aankoopkostenPercentage: () => null, //TODO
    bekeerskostenPercentage: () => null, //Todo
    verkoopkostenPercentage: () => null, //Todo
    ingangsdatum: (v, i) => mapLocalDateToString(v.producten[i].product.ingangsdatum),
    opgebouwdewaarde: (v, i) => v.producten[i].fiscaleRegeling?.ingebrachteWaardeBedrag || null,
    opgebouwdewaardedatum: (v, i) => mapLocalDateToString(v.producten[i].product.ingangsdatum)
  });

const berekenWaardeopbouwBedrag = (waardeopbouwPerMaand: WaardePerMaand[] | null): number | null => {
  if (waardeopbouwPerMaand && waardeopbouwPerMaand.length) {
    return waardeopbouwPerMaand[waardeopbouwPerMaand.length - 1].waarde;
  }
  return null;
};

const berekenWaardeopbouwMaanden = (waardeopbouwPerMaand: WaardePerMaand[] | null): WaardeopbouwMaandType[] => {
  return (
    waardeopbouwPerMaand?.map(waardeopbouw => ({
      maand: waardeopbouw.maand ?? 0,
      eindwaardeBedrag: waardeopbouw.waarde ?? 0
    })) ?? []
  );
};

export const hypotheekAsyncBerekenPremie = createISWAsyncSideEffect<HypothekenState, AsyncContext>(
  async ({ draft, settings, fetchData, context }) => {
    const requestUrl = `${settings.vermogenOrigin}/Vermogensrekening/Inleg`;
    const result = await fetchData<InlegOutput, VermogensrekeningInput>({
      url: requestUrl,
      body: mapPremieBerekeningInput(context.selected)
    });

    /* istanbul ignore next */
    if (result.isValid) {
      const premie = draft.producten[context.selected].premieGegevens;

      /* istanbul ignore next */
      if (premie) {
        if (premie.hoogLaagLooptijd && (premie.hoogLaagLooptijd.jaren || premie.hoogLaagLooptijd.maanden)) {
          premie.totalePremieLaag = result.resultaat?.inleg ?? null;
          premie.totalePremieHoog = result.resultaat?.inleg ?? null; // todo wachten op de juist berekening
          premie.spaarPremieLaag = null;
        } else {
          premie.spaarPremieLaag = result.resultaat?.inleg ?? null; // todo wachten op de juist berekening
          premie.totalePremieLaag = null;
          premie.totalePremieHoog = null;
        }
      }

      // De eerste maand krijgen we nog geen rendement uit /Inleg terug om die reden 'knippen' we maand 1 eraf, en hernummeren we de maanden -1.
      const waardeopbouw = result.resultaat?.waardeopbouwPerMaand
        ?.slice(1, result.resultaat?.waardeopbouwPerMaand.length)
        .map(opbouw => {
          return { ...opbouw, maand: opbouw.maand == null ? null : opbouw.maand - 1 };
        });

      const productwaarde = draft.producten[context.selected].productwaarde;
      if (productwaarde && waardeopbouw) {
        productwaarde.waardeopbouwBedrag = berekenWaardeopbouwBedrag(waardeopbouw);
        productwaarde.waardeopbouwMaanden = berekenWaardeopbouwMaanden(waardeopbouw);
      }
    }
  }
);

export const productKenmerkenOphalen = createISWAsyncSideEffect<HypothekenState, AsyncContext>(
  async ({ draft, settings, fetchData, context }) => {
    const [productKenmerken, productKenmerkenMapActions] = context.kenmerkenMapActions;
    const kenmerkenCalls: indexedFetchCall<ProductKenmerkenDlType>[] = [];
    // voor ieder product waarvoor geen kenmerken aanwezig zijn, fetch call klaarzetten.
    draft.producten.forEach((prod, index) => {
      // Als het een starterslening is dan default productkenmerken gebruiken anders ophalen
      if (prod.hypotheekVorm.isStartersLening) {
        const kenmerken = defaultProductkenmerkenStarterslening;
        productKenmerkenMapActions.set(`${prod.partijCode}-${prod.productCode.padStart(2, "0")}`, kenmerken);
      } else {
        if (productKenmerken.get(getKenmerkCodeForProduct(prod)) !== undefined) return;
        const url = `${settings.productenOrigin}/MaatschappijCodes/${
          prod.partijCode
        }/Hypotheekvormen/${prod.productCode.padStart(2, "0")}/Productkenmerken`;

        kenmerkenCalls.push({
          call: fetchData({ url, method: "GET" }),
          index
        });
      }
    });

    // Alle fetch calls uitvoeren
    const fetchedKenmerken = await Promise.all(kenmerkenCalls.map(kc => kc.call));
    fetchedKenmerken.forEach((fk, index) => {
      const currProduct = draft.producten[kenmerkenCalls[index].index];
      const kenmerken = mapKenmerken("Hypotheek", fk) as HypothekenKenmerken;

      if (!fk.isValid) {
        productKenmerkenMapActions.set(getKenmerkCodeForProduct(currProduct), {
          reden: "product niet beschikbaar"
        } as KenmerkenError);
        return;
      }

      // Map gedefinieerd in hypotheek-ajax.tsx updaten met toevoeging(en).
      kenmerken &&
        productKenmerkenMapActions.set(
          `${currProduct.partijCode}-${currProduct.productCode.padStart(2, "0")}`,
          kenmerken
        );
    });
  }
);

// In voorstel, bij niet-NHG. wanneer op het leningdeel 'automatischeRentedalingModal.rentedalingPercentages' nog niet is gezet
// proberen we de marktwaardes uit de fetch verkregen te gebruiken.
export const automatischeRentedalingOpslagenBepalen = createISWAsyncSideEffect<HypothekenState, AsyncContext>(
  async ({ draft, settings, fetchData, context }) => {
    const selectedProduct = draft.producten[context.selected];
    const geldverstrekkerCode = selectedProduct.partijCode;
    const hypotheeklabelCode = selectedProduct.labelCode;
    const renteboxcode = getRenteboxCode(context.hypotheekvormen, selectedProduct);
    const renteVariant = selectedProduct.leningdeelgegevens.renteVariant as RentevariantOptions;
    const rentevastJaren = Number(selectedProduct.leningdeelgegevens.rentevastPeriodeJaar);
    const rentebedenktijdInJaren = selectedProduct.leningdeelgegevens.renteBedenktijdJaar;
    const marktwaardePercentage = draft.panden.find(p => p)?.bevoorschottingspercentage ?? 100;

    const soortOnderpand = getSoortOnderpand(selectedProduct);

    if (!renteboxcode) return;

    // bij uitschakelen automatische rentedaling, opslagen opschonen
    if (
      !selectedProduct.leningdeelgegevens.automatischeRentedaling &&
      selectedProduct.leningdeelgegevens.automatischeRentedalingModal.rentedalingPercentages.length
    ) {
      selectedProduct.leningdeelgegevens.automatischeRentedalingModal.rentedalingPercentages = [];
      return;
    }

    /* istanbul ignore else*/
    if (
      !selectedProduct.leningdeelgegevens.automatischeRentedaling ||
      selectedProduct.partijCode === partijOnafhankelijk
    )
      return;

    const requestUrl =
      `${settings.hypotheekrentesOrigin}/Geldverstrekkers/${geldverstrekkerCode}/Hypotheeklabels/${hypotheeklabelCode}/Hypotheekvormen/${renteboxcode}/Marktwaardes/Opslagen` +
      `?renteafspraak=${renteVariant === RentevariantOptions.Rentevast ? 1 : 3}` +
      `&rentevastAantalMaanden=` +
      (Number(rentevastJaren) * 12 ?? /* istanbul ignore next */ 0) +
      `&rentebedenktijdInMaanden=` +
      (Number(rentebedenktijdInJaren) * 12 ?? /* istanbul ignore next */ 0) +
      `&soortOnderpand=${soortOnderpand}` +
      `&marktwaardePercentage=${marktwaardePercentage ?? /* istanbul ignore next */ 100}` +
      `&nhg=${!!draft.nhg}`;

    const { marktwaardes } = await fetchData<MarktwaardesOutput>({
      url: requestUrl,
      method: "GET"
    });

    const mwPercentages =
      marktwaardes?.map(c => {
        return {
          marktwaardePercentageTotEnMet:
            c.marktwaardePercentageTotEnMet === 0 && c.marktwaardePercentageVanaf
              ? c.marktwaardePercentageVanaf
              : c.marktwaardePercentageTotEnMet
              ? c.marktwaardePercentageTotEnMet
              : 0,
          renteopslagPercentage: c.renteopslagPercentage ?? /* istanbul ignore next */ 0
        };
      }) || [];

    selectedProduct.leningdeelgegevens.automatischeRentedalingModal.rentedalingPercentages = mwPercentages;
  }
);

function cleanFiscaleRegeling(productkenmerken: HypothekenKenmerken, product: HypotheekType): void {
  if (!productkenmerken.fiscaleRegeling.fiscaleRegelingTonen) {
    product.fiscaleRegeling = null;
  } else if (product.fiscaleRegeling) {
    if (!productkenmerken.fiscaleRegeling.kapitaalopbouwBoxKeuzeTonen) {
      product.fiscaleRegeling.kapitaalopbouw = null;
    }
    if (!productkenmerken.fiscaleRegeling.lijfrenteclausuleTonen) {
      product.fiscaleRegeling.lijfrenteclausuleOrigineel = null;
    }
  }
}

function cleanKapitaalopbouw(productkenmerken: HypothekenKenmerken, product: HypotheekType): void {
  if (!productkenmerken.kapitaalopbouw.kapitaalopbouwTonen) {
    product.kapitaalopbouw = null;
  } else if (product.kapitaalopbouw) {
    if (!productkenmerken.kapitaalopbouw.doelkapitaalEnabled) {
      product.kapitaalopbouw.doelkapitaal1Bedrag = null;
      product.kapitaalopbouw.doelkapitaal1Overnemen = null;
      product.kapitaalopbouw.doelkapitaal2Bedrag = null;
      product.kapitaalopbouw.doelkapitaal2Overnemen = null;
    }
    if (!productkenmerken.kapitaalopbouw.rekenrendementEnabled) {
      product.kapitaalopbouw.doelkapitaal1Percentage = null;
      product.kapitaalopbouw.doelkapitaal2Percentage = null;
    }
  }
}

function cleanPremiegegevens(productkenmerken: HypothekenKenmerken, product: HypotheekType): void {
  if (product.premieGegevens) {
    if (!productkenmerken.premie.aanvangstortingEnabled) {
      product.premieGegevens.aanvangExtraPremieStortingenBedrag = null;
    }
    if (!productkenmerken.premie.duurHoogLaagConstructieEnabled) {
      product.premieGegevens.hoogLaagEinddatum = null;
      product.premieGegevens.hoogLaagLooptijd = null;
      product.premieGegevens.hoogLaagVerhouding = null;
      product.premieGegevens.spaarPremieHoog = null;
      product.premieGegevens.risicoPremieHoog = null;
      product.premieGegevens.totalePremieHoog = null;
    }
    if (!productkenmerken.premie.extraStortingTonen) {
      product.premieGegevens.aanvangExtraPremieStortingen = { scenario: [] };
    }
    if (
      (!productkenmerken.premie.heeftBetalingstermijnHalfJaar &&
        product.premieGegevens.betalingstermijn === BetalingsTermijnType.HalfJaar) ||
      (!productkenmerken.premie.heeftBetalingstermijnJaar &&
        product.premieGegevens.betalingstermijn === BetalingsTermijnType.Jaar) ||
      (!productkenmerken.premie.heeftBetalingstermijnKoopsom &&
        product.premieGegevens.betalingstermijn === BetalingsTermijnType.Eenmalig) ||
      (!productkenmerken.premie.heeftBetalingstermijnKwartaal &&
        product.premieGegevens.betalingstermijn === BetalingsTermijnType.Kwartaal) ||
      (!productkenmerken.premie.heeftBetalingstermijnMaand &&
        product.premieGegevens.betalingstermijn === BetalingsTermijnType.Maand) ||
      (!productkenmerken.premie.heeftBetalingstermijnTweeMaanden &&
        product.premieGegevens.betalingstermijn === BetalingsTermijnType.TweeMaanden)
    ) {
      product.premieGegevens.betalingstermijn = BetalingsTermijnType.Geen;
    }
    if (!productkenmerken.premie.premieHoogEnabled) {
      product.premieGegevens.spaarPremieHoog = null;
      product.premieGegevens.risicoPremieHoog = null;
      product.premieGegevens.totalePremieHoog = null;
    }
    if (!productkenmerken.premie.risicopremieEnabled) {
      product.premieGegevens.risicoPremieHoog = null;
      product.premieGegevens.risicoPremieLaag = null;
    }
  }
}

export const cleanProductObvProductkenmerken = createISWAsyncSideEffect<HypothekenState, AsyncContext>(
  async ({ draft, context }) => {
    const productkenmerken = context.kenmerken;
    const product = draft.producten[context.selected];
    if (productkenmerken) {
      cleanFiscaleRegeling(productkenmerken, product);
      cleanKapitaalopbouw(productkenmerken, product);
      cleanPremiegegevens(productkenmerken, product);
    }
  }
);

const bepaalRenteperiodeMetRentepercentage = (
  product: HypotheekType,
  rentevarianten: RentevariantMetRenteperiodes[]
): void => {
  const renteAfspraak = product.leningdeelgegevens.renteVariant === RentevariantOptions.Variabel ? 3 : 1;
  const rvpPeriodes = rentevarianten.find(rv => rv.renteafspraak === renteAfspraak)?.renteperiodes;

  let result = rvpPeriodes?.find(
    rp => rp.rentevastAantalMaanden === Number(product.leningdeelgegevens.rentevastPeriodeJaar) * 12
  );
  if (!result) {
    result = rvpPeriodes?.find(
      rp => rp.rentevastAantalMaanden === Number(product.leningdeelgegevens.rentevastPeriodeJaar) * 12
    );
  }
  if (!result && rvpPeriodes && rvpPeriodes.length > 0) {
    result = rvpPeriodes[0];
  }
  const rvp = result?.rentevastAantalMaanden;
  const rentebedenktijdMaanden = result?.rentebedenktijdInMaanden;
  product.leningdeelgegevens.rentevastPeriodeJaar = !!rvp ? rvp / 12 : null;
  product.leningdeelgegevens.renteBedenktijdJaar = rentebedenktijdMaanden ? rentebedenktijdMaanden / 12 : null;
};

const bepaalWaarschuwing = (
  product: HypotheekType,
  rentevarianten: RentevariantMetRenteperiodes[],
  nhg: boolean | null
): void => {
  const warningMessage = getHypotheekTextResources(
    nhg ? "renteVastPeriodeWaarschuwing" : "renteVastPeriodeWaarschuwingExtended"
  );
  if (!!rentevarianten && rentevarianten.length > 0) {
    let waarschuwingTonen = rentevarianten.find(rv => rv.omschrijving === product.leningdeelgegevens.renteVariant)
      ?.marktwaardeWaarschuwingTonen;

    if (typeof waarschuwingTonen !== "boolean") {
      waarschuwingTonen = !!rentevarianten.find(() => true)?.marktwaardeWaarschuwingTonen;
    }

    if (product.marktwaardeWaarschuwingTonen !== waarschuwingTonen) {
      product.leningdeelgegevens.renteVariantenWaarschuwingText = warningMessage;
      product.marktwaardeWaarschuwingTonen = waarschuwingTonen;
    }
  } else {
    /* istanbul ignore else */
    if (
      product.leningdeelgegevens.renteVariantenWaarschuwingText !== warningMessage ||
      !product.marktwaardeWaarschuwingTonen
    ) {
      product.leningdeelgegevens.renteVariantenWaarschuwingText = warningMessage;
      product.marktwaardeWaarschuwingTonen = true;
    }
  }
};

const bepaalRentepercentage = (product: HypotheekType, hypotheekrente: RentepercentageResultaat | null): void => {
  const rentepercentage = product.leningdeelgegevens?.rentePercentage;
  if (hasValue(rentepercentage)) {
    if (hypotheekrente) {
      const berekendRentePc = hypotheekrente.rentePercentage ?? 0;
      rentepercentage.berekenen = rentepercentage.berekenen || true;
      rentepercentage.bedrag = berekendRentePc;
      rentepercentage.berekendBedrag = berekendRentePc;
    }
  }
};

const bepaalRentepercentageIng = async <T>(
  klantdossiersFormsOrigin: string,
  draft: HypothekenState,
  context: Readonly<AsyncContext>,
  fetchData: <R, B = undefined>(params: ISWFetchDataParams<T, B>) => Promise<R>
): Promise<void> => {
  const pricetoolCalls: indexedFetchCall<IngPriceOutput>[] = [];
  const { voorstelId, ingHdnData } = context;

  draft.producten.forEach((prod, index) => {
    /* istanbul ignore next reason: this check has already been done is only to filter out the existing mortgages */
    if (prod.partijCode !== partijcodeING) return;

    if (
      typeof prod.leningdeelgegevens.rentePercentage?.bedrag === "number" &&
      !prod.leningdeelgegevens.rentePercentage.berekenen
    )
      return;

    const url = `${klantdossiersFormsOrigin}/PostPricetoolResult?voorstelId=${voorstelId}`;

    pricetoolCalls.push({
      call: fetchData({ url, method: "POST", body: mapIngPriceToolInput({ ingHdnData })(draft) }),
      index
    });
  });

  const fetchedPriceToolRentes = await Promise.all(pricetoolCalls.map(pc => pc.call));
  fetchedPriceToolRentes.forEach((pricetoolResponse, index) => {
    if (!pricetoolResponse || !pricetoolResponse.hypotheek) return;

    const renteGegevens = pricetoolResponse.hypotheek?.leningdelenResult?.shift();
    const rente = renteGegevens
      ? parseFloat(renteGegevens.standaardRente?.replace(",", ".") ?? /* istanbul ignore next */ "0") +
        parseFloat(renteGegevens.ltv?.replace(",", ".") ?? /* istanbul ignore next */ "0") +
        parseFloat(renteGegevens.betaalrekening?.replace(",", ".") ?? /* istanbul ignore next */ "0") +
        parseFloat(renteGegevens.dagrente?.replace(",", ".") ?? /* istanbul ignore next */ "0") +
        parseFloat(renteGegevens.loyaliteit?.replace(",", ".") ?? /* istanbul ignore next */ "0")
      : null;

    const rentePercentage = draft.producten[pricetoolCalls[index].index].leningdeelgegevens?.rentePercentage;
    if (hasValue(rentePercentage)) {
      rentePercentage.bedrag = rente;
      rentePercentage.berekenen = true;
      rentePercentage.berekendBedrag = rente;
    }
  });
};

export const hypotheekAsyncOpties = async (draft: HypothekenState, context: Readonly<AsyncContext>): Promise<void> => {
  const selectedProduct = draft.producten[context.selected];

  const geldverstrekkerCode = selectedProduct.partijCode;
  const hypotheeklabelCode = selectedProduct.partijCode !== partijOnafhankelijk ? selectedProduct.labelCode : 0;

  const hypotheekOptie = draft.hypotheekOptie;
  const productenDl =
    context.hypotheekDetailData?.isValid &&
    (context.hypotheekDetailData?.producten as { [key: string]: HypotheeklabelDetails } | null);

  /* istanbul ignore else */
  if (productenDl && Object.keys(productenDl).length > 0) {
    const data = Object.keys(productenDl).map(c => productenDl[c])[0];
    const hypotheekOptiesDl = data.hypotheekopties;

    // hypotheekopties voor betreffende label in state (draft.hypotheekOptie) plaatsen
    hypotheekOptiesDl?.forEach(dlOptie => {
      if (
        !draft.hypotheekOptie.hypotheekOpties.some(
          c => c.maatschappijCode === geldverstrekkerCode && c.code === dlOptie.code
        )
      )
        draft.hypotheekOptie.hypotheekOpties.push({
          code: dlOptie.code || 0,
          geselecteerd: dlOptie.verplicht || dlOptie.default || false,
          omschrijving: dlOptie.omschrijving,
          rentekortingPercentage: dlOptie.rentekortingPercentage,
          toelichting: dlOptie.toelichting,
          hypotheekLabelCode: hypotheeklabelCode,
          maatschappijCode: geldverstrekkerCode,
          default: dlOptie.default,
          verplicht: dlOptie.verplicht
        });
    });

    // rente opslag/korting aan bestaande of nieuw toegevoegde hypotheekopties toevoegen
    hypotheekOptie?.hypotheekOpties.forEach(c => {
      c.rentekortingPercentage = hypotheekOptiesDl?.find(d => d.code === c.code)?.rentekortingPercentage || null;
    });

    // hypotheekopties die niet bij gekozen label horen verwijderen
    const hypotheekOptiesFiltered = draft.hypotheekOptie.hypotheekOpties.filter(
      c => c.maatschappijCode === geldverstrekkerCode && c.hypotheekLabelCode === hypotheeklabelCode
    );

    if (!_.isEqual(draft.hypotheekOptie.hypotheekOpties, hypotheekOptiesFiltered)) {
      draft.hypotheekOptie.hypotheekOpties = hypotheekOptiesFiltered;
    }
  }
};

export const hypotheekRenteBepalen = createISWAsyncSideEffect<HypothekenState, AsyncContext>(
  async ({ draft, settings, fetchData, context }) => {
    const product = draft.producten[context.selected];
    if (
      !product.hypotheekVorm.isStartersLening ||
      !product.hypotheekVorm.isRestschuldLening ||
      !product.product.doorlopend
    ) {
      await hypotheekAsyncOpties(draft, context);
    }
    if (!context.hypotheekvormen) {
      return;
    }
    const marktwaardePercentage = draft.panden.find(p => p)?.bevoorschottingspercentage ?? 100;

    const rentevariantenTotaal = await getRentevariantenMetRentePeriodes(
      settings.hypotheekrentesOrigin,
      draft.producten,
      marktwaardePercentage,
      !!draft.nhg,
      context.hypotheekvormen,
      fetchData
    );

    draft.producten.forEach((product, index) => {
      if (
        product.product.doorlopend ||
        product.hypotheekVorm.isRestschuldLening ||
        product.hypotheekVorm.isStartersLening
      )
        return;
      const rentevarianten = rentevariantenTotaal[index];
      if (!!rentevarianten && rentevarianten.length > 0) {
        bepaalRenteperiodeMetRentepercentage(product, rentevarianten);
      }
      bepaalWaarschuwing(product, rentevarianten, draft.nhg);
      const renteafspraak =
        product.leningdeelgegevens.renteVariant === RentevariantOptions.Variabel
          ? RentevariantMetRenteperiodesRenteafspraak._3
          : product.leningdeelgegevens.renteVariant === RentevariantOptions.Rentevast
          ? RentevariantMetRenteperiodesRenteafspraak._1
          : null;
      const renteboxSoort = rentevarianten?.find(x => x.renteafspraak === renteafspraak)?.code ?? null;
      if (renteboxSoort) product.renteBoxSoort = renteboxSoort;
    });

    const hypotheekrentes = await getHypotheekrentes(
      settings.klantdossiersFormsOrigin,
      draft.producten,
      draft.hypotheekOptie.hypotheekOpties,
      context.voorstelId,
      context.hypotheekvormen,
      fetchData
    );

    draft.producten.forEach(async (product, index) => {
      if (!product.leningdeelgegevens.rentePercentage?.berekenen) {
        return;
      }

      if (draft.producten.some(product => product.partijCode === partijcodeING)) {
        await bepaalRentepercentageIng(settings.klantdossiersFormsOrigin, draft, context, fetchData);
      } else {
        bepaalRentepercentage(product, hypotheekrentes[index]);
      }
    });
  }
);

export const hypotheekAsyncSideEffects = initISWAsyncSideEffect<HypothekenState, AsyncContext>(
  ({ has, curr, prev, runAsync, context }) => {
    const product = curr.producten[context.selected];
    const hasProduct = has.producten[context.selected];
    const [productKenmerken, productKenmerkenMapActions] = context.kenmerkenMapActions;
    const [bereken, setBereken] = context.berekenState;
    if (has.producten.changed || (curr.producten.length && context.kenmerkenMapActions[0].keys.length === 0)) {
      runAsync(productKenmerkenOphalen(context));
    }

    // Opschonen van kenmerken map als er geen producten meer zijn
    if (has.producten.changed && productKenmerken.size && !curr.producten.length) {
      productKenmerkenMapActions.reset();
    }

    if (has.producten.changed && prev.producten.length < curr.producten.length && curr.producten.length > 0) {
      let idx = -1;
      curr.producten.forEach(prd => {
        idx++;
        const hasPrd = has.producten[idx];
        if (hasPrd.partijCode.changed || hasPrd.productCode.changed) {
          const kenmerken =
            (productKenmerken.get(getKenmerkCodeForProduct(curr.producten[idx])) as HypothekenKenmerken) ?? null;
          runAsync(cleanProductObvProductkenmerken({ ...context, selected: idx, kenmerken: kenmerken }));
        }
      });
    }

    if (context.situatie === "huidig" && product) {
      const fieldChanged =
        hasProduct.hypotheekVorm.aflossingsvorm.changed ||
        hasProduct.leningdeelgegevens.leningdeelHoofdsom.bedrag.changed ||
        hasProduct.product.ingangsdatum.changed ||
        hasProduct.product.looptijd.changed ||
        hasProduct.leningdeelgegevens.datumOpgave.changed ||
        hasProduct.leningdeelgegevens.rentePercentage.changed ||
        hasProduct.leningdeelgegevens.periodiekeAflossing.changed ||
        hasProduct.isNieuw.changed;

      if (
        fieldChanged &&
        product.leningdeelgegevens.leningdeelHoofdsom.bedrag !== null &&
        product.product.ingangsdatum !== null &&
        jaarMaandInMaanden(product.product.looptijd) !== null
      ) {
        runAsync(hypotheekAsyncPeriodiekeAflossing(context));
      }
    }

    if (context.situatie === "voorstel" && product) {
      const fieldChangedPremie =
        hasProduct.verzekerde.verzekerde.changed ||
        hasProduct.kapitaalopbouw.doelkapitaal1Bedrag.changed ||
        hasProduct.kapitaalopbouw.doelkapitaal2Bedrag.changed ||
        hasProduct.kapitaalopbouw.doelkapitaal1Percentage.changed ||
        hasProduct.kapitaalopbouw.doelkapitaal2Percentage.changed ||
        hasProduct.premieGegevens.looptijd.changed ||
        hasProduct.premieGegevens.aanvangExtraPremieStortingenBedrag.changed ||
        hasProduct.premieGegevens.hoogLaagLooptijd.changed ||
        hasProduct.premieGegevens.betalingstermijn.changed ||
        hasProduct.fiscaleRegeling.fiscaleVoortzetting.changed ||
        hasProduct.leningdeelgegevens.rentevastPeriodeJaar.changed ||
        hasProduct.leningdeelgegevens.automatischeRentedaling.changed;

      if (
        bereken ||
        (fieldChangedPremie &&
          product.hypotheekVorm.aflossingsvorm === AflossingsVormType.Spaarrekening &&
          product.fiscaleRegeling?.fiscaleVoortzetting !== FiscaleVoortzettingOptions.VoortgezetProduct &&
          product.partijCode !== partijOnafhankelijk)
      ) {
        runAsync(hypotheekAsyncBerekenPremie(context));
        setBereken(false);
      }

      if (
        fieldChangedPremie &&
        product.leningdeelgegevens.automatischeRentedaling &&
        product.partijCode !== partijOnafhankelijk
      ) {
        runAsync(hypotheekAsyncAutomatischeRentedaling(context));
      }

      if (
        !curr.nhg &&
        has.producten.changed &&
        hasProduct.leningdeelgegevens.automatischeRentedaling.changed &&
        !product.product.doorlopend
      ) {
        runAsync(automatischeRentedalingOpslagenBepalen(context));
      }

      if (context.situatie === "voorstel" && (has.producten.changed || has.hypotheekOptie.changed)) {
        runAsync(hypotheekRenteBepalen(context));
      }
    }
  }
);
