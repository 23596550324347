import { connect } from "formik";
import React, { ReactElement } from "react";
import { Modal } from "react-bootstrap";
import { AanvullendeGegevensType } from "./aanvullende-gegevens-schema";
import classes from "./meer-gegevens-nodig-modal.module.scss";

type MeerGegevensNodigModalProps = {
  closeModal?: () => void;
};

export const MeerGegevensNodigModal = ({ closeModal }: MeerGegevensNodigModalProps): ReactElement => {
  return (
    <>
      <Modal.Header>
        <div className="d-flex w-100">
          <Modal.Title>Meer gegevens nodig</Modal.Title>
          <button
            type="button"
            data-testid="sluiten-meer-gegevens-nodig"
            className={"close ml-auto"}
            onClick={async () => {
              closeModal && closeModal();
            }}
            id={`close-modal`}
          >
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
        </div>
      </Modal.Header>
      <Modal.Body>
        <>
          <div>
            <div className={classes.text}>
              Op basis van de ingevulde antwoorden hebben we meer informatie nodig. Je kunt de aanvullende informatie
              toevoegen onder de knop &apos;fouten&apos; in de menubalk.
            </div>
          </div>
        </>
      </Modal.Body>
      <Modal.Footer className={classes.modal_footer}>
        <button
          type="button"
          className="btn btn-link"
          id="annuleer-meer-gegevens-nodig"
          data-testid="annuleer-meer-gegevens-nodig"
          onClick={async () => {
            closeModal && closeModal();
          }}
        >
          Sluiten
        </button>
      </Modal.Footer>
    </>
  );
};

MeerGegevensNodigModal.displayName = "MeerGegevensNodigModal";
export default connect<MeerGegevensNodigModalProps, AanvullendeGegevensType>(MeerGegevensNodigModal);
