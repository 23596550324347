import React, { ReactElement } from "react";
import { FormikProps, getIn, useFormikContext } from "formik";
import { RapportageSamenstellenState } from "./infra/rapportage-samenstellen-types";
import { AdviesBoxFormik, LabeledText, LabeledTextInput } from "adviesbox-shared";
import Modal from "../modal/Modal";
import { rapportageSamenstellenSchema } from "./rapportage-samenstellen-schema";

type TitelAanpassen = {
  onSave?: (data: any) => void;
  closeModal?: () => void;
  parent: string;
};

export const TitelAanpassenModal = ({ onSave, closeModal, parent }: TitelAanpassen): ReactElement => {
  const { values } = useFormikContext<RapportageSamenstellenState>();
  const body = (
    <>
      <LabeledText value={getIn(values, `${parent}.naam`)} caption={"Huidige titel"} />
      <LabeledTextInput name={`${parent}.naam`} caption={"Nieuwe titel"} />
    </>
  );

  return (
    <AdviesBoxFormik<RapportageSamenstellenState>
      initialValues={{ ...values }}
      validationSchema={rapportageSamenstellenSchema}
      onSave={onSave}
      closeModal={closeModal}
      render={({ submitForm }: FormikProps<RapportageSamenstellenState>): ReactElement => (
        <>
          <Modal title="Titel aanpassen" body={body} onSubmitClick={submitForm} onCancelClick={closeModal} />
        </>
      )}
    />
  );
};
