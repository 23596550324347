import React, { ReactElement } from "react";
import * as Yup from "yup";
import { FormikProps } from "formik";
import Modal from "../modal/Modal";
import { OmschrijvingType } from "./schema-and-mappers";
import AdviesBoxFormik from "../../utils/adviesbox-formik";
import { LabeledTextInput, FormFirstFocus } from "adviesbox-shared";

type OmschrijvingModalProps = {
  onSave?: (data: OmschrijvingType) => void;
  closeModal?: () => void;
};

const OmschrijvingModal = ({
  data,
  onSave,
  closeModal
}: { data: OmschrijvingType } & OmschrijvingModalProps): ReactElement => {
  const body = (
    <>
      <FormFirstFocus>
        <LabeledTextInput caption="Korte omschrijving" name="korteOmschrijving" />
        <LabeledTextInput caption="Lange omschrijving" name="langeOmschrijving" textarea={3} />
        <LabeledTextInput caption="Extern adviesnummer" name="externAdviesnummer" />
      </FormFirstFocus>
    </>
  );

  return (
    <AdviesBoxFormik<OmschrijvingType>
      initialValues={{ ...data }}
      validationSchema={Yup.object()}
      onSave={onSave}
      closeModal={closeModal}
      render={({ submitForm }: FormikProps<OmschrijvingType>): ReactElement => (
        <>
          <Modal title="Omschrijving Advies" body={body} onSubmitClick={submitForm} onCancelClick={closeModal} />
        </>
      )}
    />
  );
};

export default OmschrijvingModal;
