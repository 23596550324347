import { TaxatieDoelOptions, TaxatieSoortAanvraagOptions } from "../../.generated/forms/formstypes";
import { createMapEnum } from "../../shared/utils/create-map-enum";
import { TaxatieKenmerkenType } from "./taxatie-scherm-schema";
/* istanbul ignore file */

export const CalcasaCode = "CAL";
export const FitrexCode = "FX";
export const MaatwerkWoondienstenCode = "MW";
export const NationaleTaxatieCode = "NTS";
export const NWWICode = "NWW";
export const OnafhankelijkCode = "XX";
export const deTaxatheekCode = "TTH";
export const TaxatiepuntCode = "TXP";
export const TVICode = "TVI";

export const defaultValueDoelTaxatie = (maatschappijCode: string | null): TaxatieDoelOptions | null => {
  if (maatschappijCode === NWWICode) return TaxatieDoelOptions.VerkrijgenFinanciering;
  if (maatschappijCode === MaatwerkWoondienstenCode) return TaxatieDoelOptions.AanvraagHypothecaireGeldlening;
  if (maatschappijCode === FitrexCode) return TaxatieDoelOptions.InzichtWaardeTenBehoeveBeslissing;
  if (maatschappijCode === deTaxatheekCode) return TaxatieDoelOptions.WoningFinancieringMetValidatie;

  return null;
};

export const mapTaxatieSoortAanvraagOptionsUi2Dl = createMapEnum(TaxatieSoortAanvraagOptions).to({
  AanVerkoopBeslissing: TaxatieSoortAanvraagOptions.AanVerkoopBeslissing,
  Anders: TaxatieSoortAanvraagOptions.Anders,
  BouwkundigeKeuring: TaxatieSoortAanvraagOptions.BouwkundigeKeuring,
  Combinatie: TaxatieSoortAanvraagOptions.Combinatie,
  Oversluiting: TaxatieSoortAanvraagOptions.Oversluiting,
});

export const mapSubItemsTaxatieSoortAanvraagOptionsDl2Ui = createMapEnum(TaxatieSoortAanvraagOptions).to({
  AanVerkoopBeslissing: TaxatieSoortAanvraagOptions.AanVerkoopBeslissing,
  Anders: TaxatieSoortAanvraagOptions.Anders,
  BouwkundigeKeuring: TaxatieSoortAanvraagOptions.BouwkundigeKeuring,
  Combinatie: TaxatieSoortAanvraagOptions.Combinatie,
  Oversluiting: TaxatieSoortAanvraagOptions.Oversluiting
});

//this is a temp solution till the platform add their final fix to the kenmerken
export const editKenmerken = (
  maatschappijCode: string | null,
  soortAanvraag: TaxatieSoortAanvraagOptions | null,
  kenmerken: TaxatieKenmerkenType
): TaxatieKenmerkenType => {
  if (!soortAanvraag || !maatschappijCode) return kenmerken;
  if (maatschappijCode !== FitrexCode && maatschappijCode !== TaxatiepuntCode) return kenmerken;

  if (maatschappijCode === TaxatiepuntCode) {
    if (
      soortAanvraag === TaxatieSoortAanvraagOptions.Anders ||
      soortAanvraag === TaxatieSoortAanvraagOptions.Oversluiting
    )
      return {
        ...kenmerken,
        KaartContactpersoonTonen: false,
        KaartBouwkundigeKeuringTonen: false,
        AankoopprijsTonen: true,
        VveTonen: false,
        HerbouwwaardeTonen: true
      };

    if (soortAanvraag === TaxatieSoortAanvraagOptions.BouwkundigeKeuring)
      return {
        ...kenmerken,
        KaartContactpersoonTonen: false,
        KaartBouwkundigeKeuringTonen: true,
        AankoopprijsTonen: false,
        VveTonen: true,
        HerbouwwaardeTonen: true
      };

    if (soortAanvraag === TaxatieSoortAanvraagOptions.AanVerkoopBeslissing)
      return {
        ...kenmerken,
        KaartContactpersoonTonen: true,
        KaartBouwkundigeKeuringTonen: false,
        AankoopprijsTonen: true,
        VveTonen: false,
        HerbouwwaardeTonen: true
      };
  }

  return kenmerken;
};
