import { Label, TextInput } from "adviesbox-shared";
import React, { ReactElement } from "react";
import { QuestionSpecCombined, QuestionType } from "../../klantprofiel-schema";

type QuestionToelichtingProps = {
  data: QuestionSpecCombined;
  multipleQuestions: boolean;
};

export const QuestionToelichting = ({ data, multipleQuestions }: QuestionToelichtingProps): ReactElement => {
  if (data.type !== QuestionType.toelichting) {
    return <>{`invalid QuestionType: ${data.type}`}</>;
  }

  const caption = multipleQuestions ? "Toelichting op alle bovenstaande vragen" : "Toelichting";
  return (
    <>
      <div className="mt-3">
        <Label caption={caption} key="toelichting-label-key" verplicht={data.displayToelichtingVerplicht()} />
        <TextInput
          name={`vragen[${data.index}].toelichting`}
          data-testid={`toelichting-${data?.schema?.code}-${data?.index}`}
          textarea={5}
          fullWidth
          key="toelichting-input-key"
          />
      </div>
    </>
  );
};
