import { ErrorBoundary, ErrorPage, PageLoading, SettingsType, withErrorBoundary } from "adviesbox-shared";
import React, { ReactElement } from "react";

import { InvalidAdviesboxResultErrorPage } from "../shared/components/fetch-error-page/fetch-error-page";
import Personalia from "./personalia";
import { mapPersonaliaDlTargetToUiField } from "./infra/map-personalia-dl-target-to-ui-field";
import { mapPersonaliaDlToUi } from "./infra/map-personalia-dl-to-ui";
import { mapPersonaliaUiToDl } from "./infra/map-personalia-ui-to-dl";
import { useAdviesboxData } from "../shared/hooks/use-adviesbox-data";
import { useTarievenData } from "../shared/hooks/use-tarieven-data";

type RouteParams = {
  vestiging: string;
  adviesdossier: string;
};

const createUrl = (s: SettingsType, p: RouteParams): string =>
  `${s.klantdossiersFormsOrigin}/Adviesdossiers/${p.adviesdossier}/Personalia`;

const PersonaliaAjax = (): ReactElement => {
  const { loading: tarievenLoading, error: tarievenError, data: tarieven } = useTarievenData("Fiscaal");

  const { loading, error, data, platformData, saveData } = useAdviesboxData(
    createUrl,
    p => p.adviesdossier,
    mapPersonaliaDlToUi,
    mapPersonaliaUiToDl,
    mapPersonaliaDlTargetToUiField
  );

  if (loading || tarievenLoading) {
    return <PageLoading />;
  }

  if (error) {
    return <ErrorPage error={error} data={platformData} />;
  }

  if (tarievenError) {
    return <ErrorPage error={tarievenError} data={JSON.stringify(tarieven)} />;
  }

  if (!data || !tarieven) {
    return <InvalidAdviesboxResultErrorPage />;
  }

  return (
    <ErrorBoundary>
      <Personalia tarieven={tarieven} saveData={saveData} {...data} />
    </ErrorBoundary>
  );
};

PersonaliaAjax.displayName = "PersonaliaAjax";

export default withErrorBoundary(PersonaliaAjax);
