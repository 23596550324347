import * as Yup from "yup";

export const kredietSchema = Yup.object({
  kredietId: Yup.string().default(""),
  doorlopend: Yup.boolean().default(false),
  productnaam: Yup.string()
    .nullable()
    .default(null)
});

export type KredietType = Yup.InferType<typeof kredietSchema>;
