import { QuestionType, CardSpec } from "../../infra/klantprofiel-schema";
import { KlantprofielVraagOptions } from "../../../.generated/forms/formstypes";

export const verantwoordeWoonlastenQuestionSpecs: CardSpec[] = [
  {
    questions: [
      {
        question: KlantprofielVraagOptions.WensenMbtVerantwoordeWoonlasten,
        type: QuestionType.checkbox
      }
    ]
  },
  {
    questions: [
      {
        question: KlantprofielVraagOptions.HoeveelSparenPerMaand,
        type: QuestionType.bedrag
      }
    ]
  }
];
