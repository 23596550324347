import { logErrorToApplicationInsights, SettingsType } from "adviesbox-shared";
import { User } from "oidc-client";

export const updateNaamVoorstel = async (
  vestigingId: string,
  voorstel: string,
  nieuweNaam: string,
  settings: SettingsType,
  user: User
): Promise<boolean> => {
  const url = `${settings.klantdossiersFormsOrigin}/Voorstellen/${voorstel}/Naam`;

  const rsp = await fetch(url, {
    method: "PUT",
    headers: {
      authorization: `${user.token_type} ${user.access_token}`,
      "Ocp-Apim-Subscription-Key": settings.OcpApimSubscriptionKey,
      vestigingId,
      accept: "text/plain",
      "content-type": "application/json"
    },
    body: JSON.stringify({
      naam: nieuweNaam
    })
  });

  if (!rsp.ok) {
    logErrorToApplicationInsights(
      Error(`Fout bij aanroep van web - api '${url}'. Menu voorstel-naam kan niet gewijzigd worden.`)
    );
    return false;
  }

  return true;
};
