// istanbul ignore file
// dit is nooit getest en niet nodig voor het uiteindelijke product, dus de werking is as-is
import classnames from "classnames";
import React, { ReactElement } from "react";
import { useSwitchEnvironment } from "./use-switch-environment";
import classes from "./TopNavbar.module.scss";

export const EnvironmentSwitch = (): ReactElement => {
    const res = useSwitchEnvironment();
    return <div className="dropdown">
        <div
            className={classnames("nav-link btn btn-link", classes.switch_environment)}
            onClick={() => {
                const url = res?.url ?? "";
                window.open(url, "_blank");
            }}
            id="switch-environment"
            data-testid="switch-environment"
        >
            <span className={classnames(classes.switch_environment_icon)}>
                {process.env.NODE_ENV === "development" ? "T" : "D"}
            </span>
        </div>
    </div>;
}