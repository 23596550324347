import React, { ReactElement } from "react";
import ExterneKoppelingImport from "./externekoppeling-import";
import {
  SettingsType,
  PageLoading,
  ErrorPage,
  InvalidAdviesboxResultErrorPage,
  withErrorBoundary
} from "adviesbox-shared";
import { useAdviesboxData } from "../shared/hooks/use-adviesbox-data";
import { mapImportDlToUi } from "./infra/import-dl-to-ui";

export type RouteParams = {
  vestiging: string;
  koppelingKey: string;
  importTaakId: string;
};

const url = (s: SettingsType, p: RouteParams): string => {
  return `${s.externeKoppelingenOrigin}/importTaak/${p.koppelingKey}/${p.importTaakId}`;
};

const ExterneKoppelingImportAjax = (): ReactElement | null => {
  // Ophalen van de gegevens
  const { loading, error, data, platformData } = useAdviesboxData(
    url,
    p => p.importTaakId,
    mapImportDlToUi,
    /* istanbul ignore next */ () => null,
    /* istanbul ignore next */ () => null
  );

  if (loading) {
    return <PageLoading />;
  }

  if (error) {
    return <ErrorPage error={error} data={platformData} />;
  }

  if (!data) {
    return <InvalidAdviesboxResultErrorPage />;
  }

  // Tonen van de gegevens
  return <ExterneKoppelingImport {...data} />;
};

ExterneKoppelingImportAjax.displayName = "ExterneKoppelingImportAjax";

export default withErrorBoundary(ExterneKoppelingImportAjax);
