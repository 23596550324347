import React from "react";
import { createGetTextResources } from "../../shared/utils/text-resources-algemeen";

export const getProductenOverzichtTextResources = createGetTextResources({
  dekkingDuurDalingInJaren: "",
  productUwBemiddeling: "Selecteer 'Ja' als je nazorg op dit product levert.",
  dekkingReservewaarde:
    "De klant kan ervoor kiezen bij opzeggen van de polis de reservewaarde te laten uitkeren. De premie voor deze verzekering is hoger dan voor een vergelijkbare verzekering zonder deze optie.",
  premieGegevensEinddatumPremieBetaling: "De lage premie/inleg begint na de periode van de hoge premie/inleg.",
  premieGegevensRisicoPremieLaag: "Nader te bepalen",
  premieGegevensRisicoPremie: "Nader te bepalen",
  informatieVoorVerzendingAanvraagToelichtingGelezenEnSlotverklaring:
    "Hierbij verklaart de financieel adviseur in opdracht van de (mede)verzekeringnemer te handelen en bevoegd te zijn de elektronische aanvraag in te dienen. De financieel adviseur verklaart tevens de (mede)verzekernemer een offerte/aanvraag voor de verzekering te hebben verstrekt en de algemene voorwaarden te hand te hebben gesteld.",
  hoogLaagVerhoudingError: "De Hoog / Laag-verhouding is maximaal 1:10.",
  totalePremieHoogWarning: "Nader te bepalen",
  bedoeldVoorAflossingWarning:
    "Let op: De einddatum van dit product valt buiten de looptijd van een leningdeel ‘Bedoeld voor aflossing’. Controleer de einddatum.",
  afTeLossenLeningdelenWarning:
    "Let op: de einddatum van de dekking valt buiten de looptijd van het leningdeel. Controleer de einddatum.",
  indicatieveUitkerendeFaseWarning: "Vul de indicatieve uitkerende fase in via het specificatiescherm.",
  inkomensaanvullingWarning:
    "Vul de gewenste aanvulling van het inkomen bij overlijden in via het specificatiescherm.",
  dekkingDaaltTotHogerDanVerzekerdKapitaal: "Bedrag daling tot moet kleiner dan het verzekerd kapitaal zijn.",
  ErrorOngeldigGehanteerdTariefAanvrager: "Percentage moet tussen 0% en 20% liggen.",
  ErrorProductvorm: "Onbekende waarde voor financiering.",
  Box3NietMogelijk: "Deze aflossingsvorm kan alleen in box 1 geplaatst worden.",
  ErrorEinddatumMax:
    "Vul een geldige einddatum in.",
  WarningMeerdereRenteaftrekken: (
    <>
      Let op! Er zijn verschillende begin- en einddata renteaftrek gespecificeerd voor dit leningdeel. <br />
      Via HDN wordt slechts één periode renteaftrek voor het leningdeel meegegeven, namelijk de kortste vanaf
      ingangsdatum van het leningdeel. <br />
      Splits dus in meerdere leningdelen indien mogelijk zodat elk leningdeel één begin- / einddatum renteaftrek heeft.
    </>
  ),
  WarningBovenEigenWoningSchuld: "Box 1 bedrag is niet gelijk aan de eigenwoningschuld",
  ErrorRenteAftrekkenBedragGelijkaanBox1:
    "Het bedrag 'Deel box 1' moet gelijk zijn aan het totaal van alle renteaftrek-bedragen.",
  ErrorDuurUitkeringMoetGevuldZijn: "Duur uitkering moet moet gevuld zijn.",
  ErrorDuurUitkeringHoogMinderDanTotaal: "Duur hoge uitkering moet minder zijn dan totale uitkering.",
  ErrorHoogLaagVerhouding: "Vul een hoog/laag verhouding tussen 1 en 25 in.",
  VerzekerdKapitaalInvullen: "Verzekerd kapitaal invullen.",
  ErrorDuurPremiedepot: "Duur premiedepot mag maximaal duur verzekering zijn.",

  RequiredOorspronkelijkeIngangsdatum: "Vul de oorspronkelijke ingangsdatum in.",
  RequiredingangsdatumBox1: "Vul hier de ingangsdatum box 1 in.",
  LaagsteJaarpremieIsHoog: "De laagste premie ooit kan niet hoger zijn dan hoogste premie ooit.",
  HuidigeJaarpremieIsHoog: "De premie van het huidige jaar kan niet hoger zijn dan hoogste premie ooit.",
  LopendeJaarpremieIsHoog: "De premie van het lopende jaar kan niet hoger zijn dan hoogste premie ooit.",
  leningdeelAflossenError:
    "Wanneer er een leningdeel afgelost moet worden, dan is invoer van een doelkapitaal noodzakelijk.",
  ConsumptiefKredietTeHoog: "Het consumptief bedrag mag niet hoger zijn dan het bedrag gespecificeerd in box-3."
});
