import React, { ReactElement } from "react";
import { connect } from "formik";

import { LabeledText } from "adviesbox-shared";
import { getBankgarantieTextResources } from "../infra/bankgarantie-resources";
import { BankgarantieState } from "../infra/bankgarantie-schema";

type GarantieProps = {
  instelling:string
};

const Garantie = ({instelling}:GarantieProps): ReactElement => {
  return (
    <>
      {instelling === "CA"  && (
        <div className="mx-3">
          <p>Via de onderstaande knop komt u in het aanvraagscherm voor de garantiestelling van BNP Paribas Cardif.
            De benodigde gegevens van de aanvrager(s) worden zoveel mogelijk automatisch overgenomen vanuit Adviesbox.
          </p>

          <p>Verplichte gegevens in het aanvraagscherm zijn: </p>
          <span> - Kies een premie:</span>
            <ul>
              <li>- Met of zonder Vergoeding</li>
              <li>- Met of zonder aanvullende dekking over</li>
            </ul>
         
          
          <p>- Getekende koopovereenkomst: upload de <strong><u>getekende koopovereenkomst</u></strong></p>          

          <p>De koopovereenkomst gebruiken we om de gegevens te controleren en indien nodig te corrigeren. Zijn er afwijkingen ten opzichte van de koopovereenkomst?
          Vermeld deze dan bij &apos;opmerkingen&apos;</p>

          <p><u><a href="https://bnpparibascardif.nl/verzekeringen/garantiestelling/documenten" target="_blank" rel="noopener noreferrer">
            Klik hier voor de algemene voorwaarden, verzekeringskaart en overige informatie over onze garantiestelling.</a></u>
            </p>
        </div>
      )}

      {instelling === "NWB"  && (<>
            <LabeledText value={getBankgarantieTextResources("textDeGarantie")} caption="De garantie" />
            <LabeledText value={getBankgarantieTextResources("textVergoedingKorting")} caption="Vergoeding korting" />
            <LabeledText value={getBankgarantieTextResources("textDegarant")} caption="De garant" /> </>
      )}
   </>
  );
};

export default connect<GarantieProps, BankgarantieState>(Garantie);
