import React, { ReactElement } from "react";
import /*:COMPONENT:IMPORT:*/
"adviesbox-shared";
import MotiveringTextInput from "../../shared/components/motivering-input/MotiveringTextInput";

/*:IMPORT:*/

type AfwijkendeKeuzeProps = {
  aanvrager: number;
  title?: string;
};

export const AfwijkendeKeuze = ({ aanvrager, title }: AfwijkendeKeuzeProps): ReactElement => {
  /*:CODE:*/
  return (
    <>
      {
        <MotiveringTextInput
          name={`afwijkendeKeuze.afwijkendeKeuzeAanvrager${aanvrager}`}
          title={title}
          afwijkendeKeuze
        />
      }
    </>
  );
};
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production") AfwijkendeKeuze.displayName = "AfwijkendeKeuze";
