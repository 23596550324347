import { LocalDate } from "@js-joda/core";
import { mapJaarMaandInputFromLooptijdDl2Ui, mapStringToLocalDate } from "adviesbox-shared";
import {
  Kapitaalverzekering as KapitaalverzekeringDlEntry,
  KapitaalverzekeringBerekeningResultaat,
  KapitaalverzekeringKapitaalopbouw,
  SoortBerekeningOptionsKv,
  SoortKapitaalverzekeringsrekeningOptions,
  KapitaalverzekeringOutput as KapitaalverzekeringDlOutput,
  KapitaalverzekeringOverlijdensrisicodekking,
  KapitaalverzekeringPoliswaarde,
  KapitaalverzekeringPremiegegevens,
  BetalingsTermijnType,
  KapitaalverzekeringProduct,
  KapitaalverzekeringFiscaleRegeling,
  BasisdekkingOptions,
  SoortKapitaalverzekeringProductOptions
} from "../../.generated/forms/formstypes";
import { mapProductDl2Ui } from "../../producten-overzicht/infra/product-mapper-dl-2-ui";
import {
  aanvangExtraPremieStortingenModalSchema,
  premiedepotModalSchema,
  premieGegevensSchema,
  premieSpecificatieModalSchema,
  verzekerdenSchema,
  verzekeringNemersSchema
} from "../../producten-overzicht/infra/producten-overzicht-schema";
import { mapVerpandingDl2Ui } from "../../producten-overzicht/infra/verpanding-mapper-dl-2-ui";
import { mapKlantenNaarAanvragerKeuze, mapKlantnaamDl2Ui } from "../../shared/generic-parts/klantnaam/map-dl-2-ui";
import { KlantnaamType } from "../../shared/generic-parts/klantnaam/schema";
import { mapKredietenDl2Ui } from "../../shared/generic-parts/krediet/map-dl-2-ui";
import { mapLeningdelenDl2Ui } from "../../shared/generic-parts/leningdeel/map-dl-2-ui";
import { FieldMap, UiName } from "../../shared/types";
import { createMapToUi } from "../../shared/utils/create-map-to-ui";
import { prefixWithZero } from "../../shared/utils/helpers";
import { target2field } from "../../shared/utils/target-to-field";
import {
  fiscaleRegelingSchema,
  kapitaalverzekeringenSchema,
  kapitaalVerzekeringKapitaalOpbouwSchema,
  kapitaalverzekeringSchema,
  overlijdensrisicodekkingSchema,
  poliswaardeSchema,
  ExtendedBasisdekkingOptions
} from "./kapitaalverzekering-schema";
import {
  KapitaalverzekeringenType,
  KapitaalverzekeringType,
  OverlijdensrisicodekkingType
} from "./kapitaalverzekering-types";

export type MapBerekendePremieDl2UiProps = {
  voorbeeldbedragUitBerekening?: boolean;
  voorbeeldpercentageUitBerekening?: boolean;
};

export function mapBasisdekkingOptionsDl2Ui(
  basisdekkingOption: ExtendedBasisdekkingOptions,
  basisdekkingWaardePercentage: number | null,
  soortKapitaalverzekeringProductOptions: SoortKapitaalverzekeringProductOptions
): ExtendedBasisdekkingOptions {
  if (soortKapitaalverzekeringProductOptions !== SoortKapitaalverzekeringProductOptions.UniversalLifePremie) {
    return basisdekkingOption;
  }
  switch (basisdekkingOption) {
    case BasisdekkingOptions.PercentageOpgebouwdeWaarde:
      if (basisdekkingWaardePercentage === 110) return "110%";
      if (basisdekkingWaardePercentage === 90) return "90%";
      return BasisdekkingOptions.PercentageOpgebouwdeWaarde;
    default:
      return basisdekkingOption;
  }
}

export function mapBerekendePremieDl2Ui(
  product: KapitaalverzekeringType,
  results: KapitaalverzekeringBerekeningResultaat,
  props: MapBerekendePremieDl2UiProps
): KapitaalverzekeringType {
  const { poliswaarde, premieGegevens, kapitaalopbouw } = product;
  const { poliswaardeModal } = poliswaarde;
  const { premiedepot } = premieGegevens;
  const {
    waardeopbouwJaren,
    waardeopbouwMaanden,
    lagePremieBedrag,
    hogePremieBedrag,
    waardeopbouwBedrag,
    aanvangsstortingBedrag,
    rendementPercentage
  } = results;

  const berekeningSoort = {
    premie: kapitaalopbouw.soortBerekening === SoortBerekeningOptionsKv.Premie,
    kapitaal: kapitaalopbouw.soortBerekening === SoortBerekeningOptionsKv.Voorbeeldkapitaal,
    rendement: kapitaalopbouw.soortBerekening === SoortBerekeningOptionsKv.Voorbeeldrendement,
    eigenInvoer: kapitaalopbouw.soortBerekening === SoortBerekeningOptionsKv.EigenInvoer
  };

  const rekeningSoort = {
    spaar:
      kapitaalopbouw.soortRekening === SoortKapitaalverzekeringsrekeningOptions.Spaar ||
      kapitaalopbouw.soortRekening === null,
    belegging:
      kapitaalopbouw.soortRekening === SoortKapitaalverzekeringsrekeningOptions.Belegging ||
      kapitaalopbouw.soortRekening === null,
    hybride:
      kapitaalopbouw.soortRekening === SoortKapitaalverzekeringsrekeningOptions.Hybride ||
      kapitaalopbouw.soortRekening === null
  };

  const waardeOpbouwResults = berekeningSoort.eigenInvoer
    ? kapitaalopbouw.voorbeeldkapitaalBedrag ?? kapitaalopbouw.doelkapitaalBedrag
    : waardeopbouwBedrag;

  const lagePremieBeschikbaar =
    lagePremieBedrag ?? premieGegevens.totalePremieLaag ?? premieGegevens.risicoPremieLaag ?? false;

  const hoogPremieBeschikbaar =
    hogePremieBedrag ?? premieGegevens.totalePremieHoog ?? premieGegevens.risicoPremieHoog ?? false;

  const updatedSpaarPremieLaag =
    !!lagePremieBeschikbaar && berekeningSoort.premie
      ? lagePremieBedrag || premieGegevens.totalePremieLaag || 0
      : premieGegevens.spaarPremieLaag;

  const updatedSpaarPremieHoog =
    !!hoogPremieBeschikbaar && berekeningSoort.premie
      ? hogePremieBedrag || premieGegevens.totalePremieHoog || 0
      : premieGegevens.spaarPremieHoog;

  const updatedTotalePremieLaag = (updatedSpaarPremieLaag || 0) + (premieGegevens.risicoPremieLaag || 0);
  const updatedTotalePremieHoog = (updatedSpaarPremieHoog || 0) + (premieGegevens.risicoPremieHoog || 0);

  return {
    ...product,
    poliswaarde: {
      ...poliswaarde,
      waardeopbouwBedrag,
      poliswaardeModal: {
        ...poliswaardeModal,
        waardeopbouwJaren: waardeopbouwJaren?.filter(w => w.jaar) || [],
        waardeopbouwMaanden: waardeopbouwMaanden || []
      }
    },
    premieGegevens: {
      ...premieGegevens,
      spaarPremieLaag: updatedSpaarPremieLaag,
      spaarPremieHoog: updatedSpaarPremieHoog,
      totalePremieLaag: updatedTotalePremieLaag,
      totalePremieHoog: updatedTotalePremieHoog,
      premiedepot: premiedepot
        ? {
            ...premiedepot,
            aanvangsstorting: !!aanvangsstortingBedrag,
            bedrag: aanvangsstortingBedrag ?? null
          }
        : null
    },
    kapitaalopbouw: {
      ...kapitaalopbouw,
      garantierendementPercentage:
        berekeningSoort.rendement && (rekeningSoort.spaar || rekeningSoort.hybride)
          ? rendementPercentage
          : kapitaalopbouw.garantierendementPercentage,
      voorbeeldrendementPercentage:
        (berekeningSoort.rendement && (rekeningSoort.belegging || rekeningSoort.hybride)) ||
        props.voorbeeldpercentageUitBerekening
          ? rendementPercentage
          : kapitaalopbouw.voorbeeldrendementPercentage,
      voorbeeldkapitaalBedrag:
        (berekeningSoort.kapitaal && (rekeningSoort.belegging || rekeningSoort.hybride)) ||
        props.voorbeeldbedragUitBerekening
          ? waardeOpbouwResults
          : berekeningSoort.rendement
          ? results.waardeopbouwBedrag
          : product.kapitaalopbouw.voorbeeldkapitaalBedrag,
      garantiekapitaalBedrag:
        berekeningSoort.kapitaal && (rekeningSoort.spaar || rekeningSoort.hybride)
          ? waardeOpbouwResults
          : product.kapitaalopbouw.garantiekapitaalBedrag
    }
  };
}

export const mapVerzekeringnemersDl2Ui = createMapToUi(verzekeringNemersSchema)
  .with<{ aanvrager1: KlantnaamType | null; aanvrager2: KlantnaamType | null }>()
  .from<KapitaalverzekeringProduct>({
    verzekeringnemers: (v, { aanvrager1, aanvrager2 }) =>
      mapKlantenNaarAanvragerKeuze(v.verzekeringnemerKlantIds, aanvrager1, aanvrager2)
  });

export const mapVerzekerdenDl2Ui = createMapToUi(verzekerdenSchema)
  .with<{ aanvrager1: KlantnaamType | null; aanvrager2: KlantnaamType | null }>()
  .from<KapitaalverzekeringProduct>({
    verzekerden: (v, { aanvrager1, aanvrager2 }) =>
      mapKlantenNaarAanvragerKeuze(v.verzekerdeKlantIds, aanvrager1, aanvrager2),
    premiesplitsing: v => v.premiesplitsing
  });

export const mapKapitaalopbouwDl2Ui = createMapToUi(kapitaalVerzekeringKapitaalOpbouwSchema).from<
  KapitaalverzekeringKapitaalopbouw
>({
  soortRekening: v => v.soortRekening,
  soortBerekening: v => v.soortBerekening,
  beleggersprofiel: _ => null,
  doelkapitaalBedrag: v => v.doelkapitaalBedrag,
  doelrendementPercentage: v => v.doelrendementPercentage,
  voorbeeldkapitaalBedrag: v => v.voorbeeldkapitaalBedrag,
  voorbeeldrendementPercentage: v => v.voorbeeldrendementPercentage,
  garantiekapitaalBedrag: v => v.garantiekapitaalBedrag,
  garantierendementPercentage: v => v.garantierendementPercentage,
  bedoeldVoorAflossing: () => false
});

export const mapPremiedepotDl2Ui = createMapToUi(premiedepotModalSchema).from<KapitaalverzekeringPremiegegevens | null>(
  {
    uitgangspunt: v => v.premiedepot?.uitgangspunt,
    lagePremies: v => v.premiedepot?.opnameLagePremies,
    hogePremies: v => v.premiedepot?.opnameHogePremies,
    aanvangsstorting: v =>  v.premiedepot?.opnameAanvangsstorting,
    extraStortingen: v => v.premiedepot?.opnameExtraStortingen,
    vergoeding: v => v.premiedepot?.vergoeding,
    bedrag: v => v.premiedepot?.premiedepotBedrag,
    duur: v => v.premiedepot?.premiedepotDuurInMaanden,
    hoogInlegBedrag: v => v.hogeTotaalPremieBedrag,
    inlegBedrag: v => v.totaalPremieBedrag,
    aanvangsstortingBedrag: v => v.aanvangsstortingBedrag,
    extraPremieStortingen: v => v.extraPremiestortingJaren,
    hoogLaagDuur: v => mapJaarMaandInputFromLooptijdDl2Ui(v.duurHooglaagInMaanden),
    premieDuur: v => mapJaarMaandInputFromLooptijdDl2Ui(v.duurInMaanden)
  }
);

export const mapAanvangExtraPremieStortingenDl2Ui = createMapToUi(aanvangExtraPremieStortingenModalSchema).from<
  KapitaalverzekeringPremiegegevens
>({
  scenario: v => {
    if (!v.extraPremiestortingJaren || !v.extraPremiestortingJaren.length) {
      return [];
    }

    const emptyValues = new Array(mapJaarMaandInputFromLooptijdDl2Ui(v.duurInMaanden ?? 0).jaren);
    return Array.from(emptyValues, (_, i) => {
      const current = v.extraPremiestortingJaren ? v.extraPremiestortingJaren.find(x => x.jaar === i + 1) : null;
      if (current) {
        return { bedrag: current.bedrag };
      }
      return { bedrag: null };
    });
  }
});

export const mapPremiegegevensDl2Ui = createMapToUi(premieGegevensSchema)
  .with<{ ingangsdatum: LocalDate | null }>()
  .from<KapitaalverzekeringProduct>({
    betalingstermijn: ({ premiegegevens }) => premiegegevens?.betalingstermijn as BetalingsTermijnType,
    looptijd: ({ premiegegevens }) => mapJaarMaandInputFromLooptijdDl2Ui(premiegegevens?.duurInMaanden),
    einddatumPremieBetaling: ({ premiegegevens }, { ingangsdatum }) =>
      typeof premiegegevens?.duurInMaanden === "number" && ingangsdatum
        ? ingangsdatum.plusMonths(premiegegevens.duurInMaanden)
        : null,
    hoogLaagLooptijd: ({ premiegegevens }) => mapJaarMaandInputFromLooptijdDl2Ui(premiegegevens?.duurHooglaagInMaanden),
    hoogLaagEinddatum: ({ premiegegevens }, { ingangsdatum }) =>
      typeof premiegegevens?.duurHooglaagInMaanden === "number" && ingangsdatum
        ? ingangsdatum.plusMonths(premiegegevens.duurHooglaagInMaanden)
        : null,

    spaarPremieLaag: ({ premiegegevens }) => premiegegevens?.beleggingsinlegBedrag,
    spaarPremieHoog: ({ premiegegevens }) => premiegegevens?.hogeBeleggingsinlegBedrag,
    totalePremieLaag: ({ premiegegevens }) => premiegegevens?.totaalPremieBedrag,
    totalePremieHoog: ({ premiegegevens }) => premiegegevens?.hogeTotaalPremieBedrag,
    risicoPremieLaag: ({ premiegegevens }) => premiegegevens?.risicopremieBedrag,
    risicoPremieHoog: ({ premiegegevens }) => premiegegevens?.hogeRisicopremieBedrag,
    aanvangExtraPremieStortingenBedrag: ({ premiegegevens }) => premiegegevens?.aanvangsstortingBedrag,
    premiedepot: ({ premiegegevens }) => mapPremiedepotDl2Ui(premiegegevens),
    aanvangExtraPremieStortingen: ({ premiegegevens }) => mapAanvangExtraPremieStortingenDl2Ui(premiegegevens),
    premiespecificatie: _ => premieSpecificatieModalSchema.default(),
    hoogLaagVerhouding: ({ premiegegevens }) => premiegegevens?.hooglaagVerhouding,
    aanvangExtraPremieStortingenAftrekbaar: ({ premiegegevens }) => premiegegevens?.aanvangsstortingAftrekbaarBedrag,
    premiedepotBedrag:  ({ premiegegevens }) => premiegegevens?.premiedepot?.premiedepotBedrag,
    spaarPremieAftrekbaar: ({ premiegegevens }) => premiegegevens?.premieAftrekbaarBedrag,
    totalePremieAftrekbaar: _ => null,
    validatieEnabled: _ => false,
    verkortePremieduur: _ => null
  });

export const mapPoliswaardeDl2Ui = createMapToUi(poliswaardeSchema)
  .with<{ beginJaar: number | null }>()
  .from<KapitaalverzekeringPoliswaarde>({
    reedsOpgebouwdBedrag: v => v.reedsOpgebouwdBedrag ?? null,
    reedsOpgebouwdDatum: v => mapStringToLocalDate(v.reedsOpgebouwdDatum),
    afkoopwaardeBedrag: v => v.afkoopwaardeBedrag ?? null,
    afkoopwaardeDatum: v => mapStringToLocalDate(v.afkoopwaardeDatum),
    waardeopbouwBedrag: v => v.waardeopbouwBedrag ?? null,
    poliswaardeModal: (v, c) => ({
      waardeopbouwJaren: v.waardeopbouwJaren?.filter(w => w.jaar) ?? [],
      waardeopbouwMaanden: v.waardeopbouwMaanden ?? [],
      beginJaar: c.beginJaar
    })
  });

function mapOverlijdensrisicodekkingDl2Ui(
  overlijdensrisicodekking: KapitaalverzekeringOverlijdensrisicodekking | null,
  soortProduct: SoortKapitaalverzekeringProductOptions
): OverlijdensrisicodekkingType {
  if (!overlijdensrisicodekking) {
    return overlijdensrisicodekkingSchema.default();
  }

  return {
    basisdekking: mapBasisdekkingOptionsDl2Ui(
      overlijdensrisicodekking.basisdekking,
      overlijdensrisicodekking.waardePercentage,
      soortProduct
    ),
    verzekerdKapitaalBedrag: overlijdensrisicodekking.verzekerdKapitaalBedrag,
    soortRisicodekking: overlijdensrisicodekking.soortRisicodekking,
    annuiteitsPercentage: overlijdensrisicodekking.annuiteitsPercentage,
    dekkingDaaltTotBedrag: overlijdensrisicodekking.dekkingDaaltTotBedrag,
    duurdalingInJaren: overlijdensrisicodekking.duurdalingInJaren,
    waardePercentage: overlijdensrisicodekking.waardePercentage,
    partnerrentePerJaarBedrag: overlijdensrisicodekking.partnerrente?.partnerrentePerJaarBedrag ?? 0,
    levenslang: overlijdensrisicodekking.partnerrente?.levenslang ?? false,
    maxDuurInJaren: overlijdensrisicodekking.partnerrente?.maxDuurInJaren ?? null,
    directIngaandeRente: overlijdensrisicodekking.partnerrente?.directIngaandeRente ?? false,
    uitsteldatum: mapStringToLocalDate(overlijdensrisicodekking.partnerrente?.uitsteldatum)
  };
}

export const mapFiscaleRegelingDlToUi = createMapToUi(
  fiscaleRegelingSchema
).from<KapitaalverzekeringFiscaleRegeling | null>({
  orv: v => v.orv,
  lijfrenteclausule: v => v.lijfrenteclausule,
  kapitaalopbouw: v => v.kapitaalopbouw,
  productId: v => v.productId,
  fiscaleVoortzetting: v => v.fiscaleVoortzetting,
  externeMaatschappijCode: v => v.externeMaatschappijCode,
  externeMaatschappijOmschrijving: v => v.externeMaatschappijOmschrijving,
  lijfrenteclausuleOrigineel: v => v.lijfrenteclausuleOrigineel,
  fiscaleTypering: v => v.fiscaleTypering,
  garantieverzekering: v => v.garantieverzekering,
  fiscaalRegime: v => v.fiscaalRegime,
  oorspronkelijkeIngangsdatum: v => mapStringToLocalDate(v.oorspronkelijkeIngangsdatum),
  ingangsdatumBox1: v => mapStringToLocalDate(v.ingangsdatumBox1),
  einddatum: v => mapStringToLocalDate(v.einddatum),
  doelkapitaalBedrag: v => v.doelkapitaalBedrag,
  laagstePremieooitBedrag: v => v.laagstePremieooitBedrag,
  hoogstePremieOoitBedrag: v => v.hoogstePremieOoitBedrag,
  huidigeJaarPremieBedrag: v => v.huidigeJaarPremieBedrag,
  premieLopendJaarBedrag: v => v.premieLopendJaarBedrag,
  eerdereUitkeringenBedrag: v => v.eerdereUitkeringenBedrag,
  ingebrachteWaardeBedrag: v => v.ingebrachteWaardeBedrag,
  originelePolisnummer: v => v.originelePolisnummer,
  polisnummer: v => v.polisnummer
});

export const mapKapitaalverzekeringenTypeDl2Ui = createMapToUi(kapitaalverzekeringSchema)
  .with<{ aanvrager1: KlantnaamType | null; aanvrager2: KlantnaamType | null }>()
  .from<KapitaalverzekeringProduct>({
    productId: v => v.productId,
    partijCode: v => v.polis.maatschappijCode ?? "",
    productCode: v => prefixWithZero(v.polis.productcode),
    soortProduct: v => v.soortProduct,
    meenemen: v => v.meenemen,
    product: v => ({
      ...mapProductDl2Ui(v.polis),
      doorlopend: v.doorlopend ?? false,
      wijzigingenInDoorlopendProductOvernemen: v.wijzigingenInDoorlopendeProductenOvernemen
    }),
    verzekeringnemers: (v, { aanvrager1, aanvrager2 }) => mapVerzekeringnemersDl2Ui({ aanvrager1, aanvrager2 })(v),
    verzekerden: (v, { aanvrager1, aanvrager2 }) => mapVerzekerdenDl2Ui({ aanvrager1, aanvrager2 })(v),
    verzekerde1Overlijdensrisicodekking: v =>
      mapOverlijdensrisicodekkingDl2Ui(v.verzekerde1Overlijdensrisicodekking, v.soortProduct),
    verzekerde2Overlijdensrisicodekking: v =>
      mapOverlijdensrisicodekkingDl2Ui(v.verzekerde2Overlijdensrisicodekking, v.soortProduct),
    kapitaalopbouw: v => mapKapitaalopbouwDl2Ui(v.kapitaalopbouw),
    premieGegevens: v => mapPremiegegevensDl2Ui({ ingangsdatum: mapStringToLocalDate(v.polis.ingangsdatum) })(v),
    poliswaarde: v =>
      mapPoliswaardeDl2Ui({ beginJaar: mapStringToLocalDate(v.polis.ingangsdatum)?.year() ?? null })(v.poliswaarde),
    fiscaleRegeling: v => mapFiscaleRegelingDlToUi(v.fiscaleRegeling),
    verpanding: v => mapVerpandingDl2Ui(v.verpanding),
    premieverloop: v => null
  });

export const dl2ui = createMapToUi(kapitaalverzekeringenSchema)
  .with<{ aanvrager1: KlantnaamType | null; aanvrager2: KlantnaamType | null }>()
  .from<KapitaalverzekeringDlEntry>({
    aanvrager1: (v, { aanvrager1 }) => aanvrager1,
    aanvrager2: (v, { aanvrager2 }) => aanvrager2,
    geldverstrekkerNaam: v => v.geldverstrekkerNaam,
    ingangsdatumVoorstel: v => mapStringToLocalDate(v.ingangsdatumVoorstel),
    kredieten: v => mapKredietenDl2Ui(v.kredieten),
    leningdelen: v => mapLeningdelenDl2Ui(v.leningdelen),
    producten: (v, { aanvrager1, aanvrager2 }) =>
      v.producten.map(product => mapKapitaalverzekeringenTypeDl2Ui({ aanvrager1, aanvrager2 })(product))
  });

export function mapKapitaalverzekeringenDlToUi(
  kapitaalverzekeringId: string,
  data: KapitaalverzekeringDlOutput
): KapitaalverzekeringenType | null {
  const kapitaalverzekering =
    data && data.kapitaalverzekeringen ? data.kapitaalverzekeringen[kapitaalverzekeringId] : null;

  if (!kapitaalverzekering) {
    return null;
  }

  return dl2ui({
    aanvrager1: mapKlantnaamDl2Ui(kapitaalverzekering.aanvrager1),
    aanvrager2: mapKlantnaamDl2Ui(kapitaalverzekering.aanvrager2)
  })(kapitaalverzekering);
}

export function mapDlTargetToKapitaalverzekeringUiField(target: string): UiName | null {
  const map: FieldMap<KapitaalverzekeringDlEntry> = {
    producten: {
      poliswaarde: {
        afkoopwaardeBedrag: { field: "producten[i].poliswaarde.afkoopwaardeBedrag", label: "Poliswaarde Afkoopwaarde" },
        reedsOpgebouwdBedrag: {
          field: "producten[i].poliswaarde.reedsOpgebouwdBedrag",
          label: "Poliswaarde Reeds opgebouwd"
        },
        reedsOpgebouwdDatum: {
          field: "producten[i].poliswaarde.reedsOpgebouwdDatum",
          label: "Poliswaarde Reeds opgebouwd per datum"
        },
        afkoopwaardeDatum: {
          field: "producten[i].poliswaarde.afkoopwaardeDatum",
          label: "Poliswaarde Afkoopwaarde per datum"
        }
      },
      kapitaalopbouw: {
        doelkapitaalBedrag: {
          field: "producten[i].kapitaalopbouw.doelkapitaalBedrag",
          label: "Kapitaalopbouw doelkapitaal"
        },
        garantiekapitaalBedrag: {
          field: "producten[i].kapitaalopbouw.garantiekapitaalBedrag",
          label: "Kapitaalopbouw garantiekapitaal"
        },
        voorbeeldkapitaalBedrag: {
          field: "producten[i].kapitaalopbouw.voorbeeldkapitaalBedrag",
          label: "Kapitaalopbouw voorbeeldkapitaal"
        }
      }
    }
  };

  const regExp = /Producten\[(\d+)\]\.?(\w+)?\.(\w+)\.?(\w+)?/;
  const match = regExp.exec(target);

  if (match) {
    const index = +match[1];
    const kaart = match[2];
    const veld = match[3];
    const subveld = match[4];

    switch (kaart) {
      case "Verzekerde1Overlijdensrisicodekking":
      case "Verzekerde2Overlijdensrisicodekking":
        if (veld === "Partnerrente" && subveld === "MaxDuurInJaren") {
          return {
            field: `producten[${index}].verzekerde1Overlijdensrisicodekking.maxDuurInJaren`,
            label: `Kapitaalverzekering ${index + 1}: Overlijdensrisicodekking Maximale duur rente`
          };
        }
        break;
      case "Premiegegevens":
        switch (veld) {
          case "DuurHooglaagInMaanden":
            return {
              field: `producten[${index}].premieGegevens.hoogLaagLooptijd.jaren`,
              label: `Kapitaalverzekering ${index + 1}: Duur Hoog / Laag-constructie`
            };
        }
    }
  }

  return target2field(map, target);
}
