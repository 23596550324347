import { createContext } from "react";
import { Medewerker as M, MedewerkerGeslacht } from "../../.generated/licenties/licentiestypes";

export interface Medewerker extends M {
  medewerkerId: string;
  organisatieId: string;
}

export const defaultUserDetails = {
  userDetails: {
    voornaam: "",
    tussenvoegsels: "",
    achternaam: "",
    roepnaam: null,
    voorletters: "",
    telefoon: null,
    telefoonWerk: null,
    telefoonMobiel: null,
    geslacht: MedewerkerGeslacht.Man,
    email: "",
    functie: null,
    platformId: null,
    klantId: null,
    isActief: false,
    klantdossierRechtenVestigingen: null,
    klantdossierRechtenOrganisatie: null,
    medewerkerId: "",
    organisatieId: ""
  } as Medewerker,
  setUserDetails: (_userDetails: Medewerker): void => {}
};

const UserDetailsContext = createContext(defaultUserDetails);

export default UserDetailsContext;
