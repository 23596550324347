/* istanbul ignore file */
import { useEffect, useContext, useMemo } from "react";
import {
  WijzigGeldverstrekkerInputLeningdeel,
  HypotheeksamenstellingOutput
} from "../../.generated/forms/formstypes";
import { UseRequestInitOptions, useRequestInit, ForceRerenderContext } from "adviesbox-shared";
import useAbortableFetch from "use-abortable-fetch";

export function useHypotheekSamenstellingWijzigData(
  maatschappijCode: string,
  labelCode: number,
  leningdelen: WijzigGeldverstrekkerInputLeningdeel[]
): {
  data: HypotheeksamenstellingOutput | null;
  error: Error | null;
  loading: boolean;
} {
  const loadingDone = useContext(ForceRerenderContext);

  const jsonLeningdelen = JSON.stringify(leningdelen);

  const extraHeaders = useMemo((): UseRequestInitOptions => {
    return {
      method: "POST",
      extraHeaders: {
        "content-type": "application/json"
      },
      body: `{
        "geldverstrekkerCode": "${maatschappijCode}",
        "hypotheeklabelCode": ${labelCode},
        "leningdelen": ${jsonLeningdelen}
      }`
    };
  }, [maatschappijCode, labelCode, jsonLeningdelen]);

  const {
    requestInit,
    settings: { klantdossiersFormsOrigin }
  } = useRequestInit(extraHeaders);
  const requestUrl = `${klantdossiersFormsOrigin}/Hypotheek/WijzigGeldverstrekker`;

  const { error, data, loading } = useAbortableFetch<HypotheeksamenstellingOutput>(requestUrl, requestInit);

  useEffect((): void => {
    if (!loading && !!data && loadingDone) {
      loadingDone();
    }
  }, [loading, data, loadingDone]);

  if (typeof data === "string") {
    return { error: new Error("Invalid data"), data: null, loading: false };
  }

  return { data, error, loading };
}
