import React, { ReactElement, useState } from "react";
import { FormikProps } from "formik";

import { Column } from "react-table-6";
import { afronden, optellen } from "../../shared/utils/currency";
import AdviesBoxFormik from "../../shared/utils/adviesbox-formik";
import Modal from "../../shared/components/modal/Modal";
import { SpecificatieRenteaftrekModalType, RenteAftrek } from "../infra/hypotheek-types";
import { getHypotheekTextResources } from "../infra/hypotheek-resources";
import { specificatieRenteaftrekModalSchema } from "../../producten-overzicht/infra/producten-overzicht-schema";
import { determineSpecificatieRenteaftrekModalSideEffects } from "./determine-specificatie-renteaftrek-modal-side-effects";
import { LocalDate } from "@js-joda/core";
import { DataGrid } from "adviesbox-shared";
import { ISWSideEffects } from "../../shared/components/isw-side-effects/isw-side-effects";

export type SpecificatieRenteaftrekModalData = {
  data: SpecificatieRenteaftrekModalType;
};

export type SpecificatieRenteaftrekBenodigdheden = {
  leningdeelBedrag: number | null;
  box1Bedrag: number;
  ingangsdatum: LocalDate | null;
  einddatum: LocalDate | null;
};

type SpecificatieRenteaftrekModalProps = {
  benodigdheden: SpecificatieRenteaftrekBenodigdheden;
  onSave?: (data: SpecificatieRenteaftrekModalType) => void;
  closeModal?: () => void;
};

const renteAftrekkenColumns: Column[] = [
  {
    Header: "Bedrag",
    accessor: "bedrag",
    Cell: "CurrencyInput",
    width: 150
  },
  {
    Header: "Begindatum",
    accessor: "aanvangsdatum",
    Cell: "DateInput",
    width: 150
  },
  {
    Header: "Einddatum",
    accessor: "einddatum",
    Cell: "DateInput",
    width: 150
  },
  { Cell: "DeleteButton" }
];

const SpecificatieRenteaftrekModal = ({
  benodigdheden: { leningdeelBedrag, box1Bedrag, einddatum, ingangsdatum },
  data,
  onSave,
  closeModal
}: SpecificatieRenteaftrekModalData & SpecificatieRenteaftrekModalProps): ReactElement => {
  const [hasBlurred, setBlurred] = useState(true);
  const body = (values: SpecificatieRenteaftrekModalType, opgeteldeRenteaftrekBedrag: number): ReactElement => {
    return (
      <>
        <div
          onBlur={(): void => {
            !hasBlurred && setBlurred(true);
          }}
          onChange={(): void => {
            hasBlurred && setBlurred(false);
          }}
        >
          <div className="px-1 pb-4">{getHypotheekTextResources("RenteaftrekSpecificatieTekst")}</div>

          <DataGrid
            columns={renteAftrekkenColumns}
            name="renteAftrekken"
            showButtonDelete={values.renteAftrekken.length > 1}
            rowCaption="renteaftrek"
            getNewRowValues={(): RenteAftrek => ({
              aanvangsdatum: ingangsdatum,
              einddatum: einddatum,
              bedrag: Math.max(afronden(box1Bedrag - opgeteldeRenteaftrekBedrag, 6) || 0, 0)
            })}
            className="editable"
          />
          {hasBlurred && box1Bedrag && opgeteldeRenteaftrekBedrag !== box1Bedrag && (
            <div className="input-rood">{getHypotheekTextResources("ErrorRenteAftrekkenBedragGelijkaanBox1")}</div>
          )}
        </div>
      </>
    );
  };
  return (
    <AdviesBoxFormik<SpecificatieRenteaftrekModalType>
      initialValues={{ ...data }}
      validationSchema={specificatieRenteaftrekModalSchema}
      onSave={onSave}
      closeModal={closeModal}
      render={({ values, submitForm }: FormikProps<SpecificatieRenteaftrekModalType>): ReactElement => {
        const opgeteldeRenteaftrekBedrag = optellen(values.renteAftrekken.map((x): number | null => x.bedrag)) || 0;

        return (
          <>
            <ISWSideEffects sync={determineSpecificatieRenteaftrekModalSideEffects({productIngangsdatum: ingangsdatum, productEinddatum: einddatum})}  />

            <Modal
              title="Specificatie renteaftrek"
              body={body(values, opgeteldeRenteaftrekBedrag)}
              invalid={box1Bedrag ? opgeteldeRenteaftrekBedrag !== box1Bedrag : true}
              onSubmitClick={submitForm}
              onCancelClick={closeModal}
            />
          </>
        );
      }}
    />
  );
};

SpecificatieRenteaftrekModal.displayName = "SpecificatieRenteaftrekModal";

export default SpecificatieRenteaftrekModal;
