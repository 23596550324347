import { AdviesBoxFormik, Card, CardWrapper, ErrorPage, PageLoading, SettingsType, PlatformFoutenSamenvatting } from "adviesbox-shared";
import { FormikContextType, FormikHelpers, FormikProps, useFormikContext } from "formik";
import React, { ReactElement, useState } from "react";
import { Collapse } from "react-bootstrap";
import { InkomensAnalysePdf } from "../../inkomens-analyse/inkomens-analyse-pdf";
import { MotiveringOnderwerpsoort } from "../../.generated/forms/formstypes";
import { InvalidAdviesboxResultErrorPage } from "../../shared/components/fetch-error-page/fetch-error-page";
import { ISWSideEffects } from "../../shared/components/isw-side-effects/isw-side-effects";
import Modal from "../../shared/components/modal/Modal";
import { useAdviesboxData } from "../../shared/hooks/use-adviesbox-data";
import { useSaveBeforeLoad } from "../../shared/hooks/use-save-before-load";
import { RouteParams } from "../../shared/paramrouting/paramrouting-context";
import { UiError } from "../../shared/types";
import { getNaam } from "../../shared/utils/helpers";
import { setFormikUiErrors } from "../../shared/utils/set-formik-ui-errors";
import { ControleNorm } from "../components/controle-norm/controle-norm";
import { ScenarioVan } from "../components/scenario-van/scenario-van";
import { dashboardSchema } from "../infra/dashboard-schema";
import { DashboardState } from "../infra/dashboard-types";
import { mapDashboardOutput } from "../infra/map-dashboard-dl-2-ui";
import { mapDashboardDlTargetToUiField } from "../infra/map-dashboard-dl-target-to-ui-field";
import { mapDashboardUiToDl } from "../infra/map-dashboard-ui-2-dl";
import { syncScenarioOverlijdenModalSideEffects } from "./infra/determine-sync-scenario-overlijden-modal-side-effects";
import { ScenarioOverlijdenModalProps } from "./infra/scenario-overlijden-modal-schema";
import { Orv } from "./orv/orv";

export const ScenarioOverlijdenModal = (props: ScenarioOverlijdenModalProps): ReactElement => {
  /* istanbul ignore next*/
  const createUrl = (invoorstel: boolean) => (s: SettingsType, p: RouteParams): string =>
    `${s.klantdossiersFormsOrigin}/${invoorstel ? "Voorstellen" : "Adviesdossiers"}/${invoorstel ? p.voorstel : p.adviesdossier
    }/Dashboard/Overlijden?berekenTekort=false`;

  const { loading, error, data, platformData, saveData } = useAdviesboxData(
    createUrl(props.inVoorstel || false),
    p => p.adviesdossier,
    mapDashboardOutput,
    (values: DashboardState): any => {
      const mapped = mapDashboardUiToDl(values);

      return {
        ...mapped,
        pensioen: null,
        werkloosheid: null,
        arbeidsongeschiktheid: null
      };
    },
    mapDashboardDlTargetToUiField,
    true
  );

  const { loading: saveBeforeLoading } = useSaveBeforeLoad(true);

  const ModalBody = (): ReactElement => {
    const [showInstellingen, setInstellingenVisible] = useState(true);

    const formik = useFormikContext<DashboardState>();
    const { values } = formik;

    /* istanbul ignore next */
    const saveAndSetErrors = async (): Promise<void> => {
      const result = await saveData(values);
      if (result !== null && result instanceof Array) {
        setFormikUiErrors(result, formik as FormikContextType<DashboardState>);
      } else {
        setFormikUiErrors([], formik as FormikContextType<DashboardState>);
      }
    };

    return (
      <>
        <ISWSideEffects<DashboardState> sync={syncScenarioOverlijdenModalSideEffects} />
        <PlatformFoutenSamenvatting />

        {values.aanvrager2 && (
          <>
            <Collapse in={showInstellingen}>
              <div id="instellingen-orv">
                <CardWrapper>
                  <Card title="Controle op norm">
                    <ControleNorm namePrefix={"overlijden"} />
                  </Card>
                  {values.aanvrager2 && (
                    <Card title="Scenario van">
                      <ScenarioVan namePrefix="overlijden" />
                    </Card>
                  )}
                  <Card title="Orv">
                    <Orv />
                  </Card>
                </CardWrapper>
              </div>
            </Collapse>

            <CardWrapper className="px-3">
              <div className="text-container" style={{ textAlign: "right", width: "100%", minHeight: "70px" }}>
                <button
                  id="opslaan"
                  onClick={() => {
                    setInstellingenVisible(!showInstellingen);
                  }}
                  className="btn btn-light ml-1"
                  type="button"
                  aria-controls="instellingen-orv"
                  aria-expanded={showInstellingen}
                >
                  {showInstellingen ? <>Instellingen verbergen</> : <>Instellingen tonen</>}
                </button>
              </div>
            </CardWrapper>
          </>
        )}

        {!values.aanvrager2 && (
          <CardWrapper flexType={"flex-column"}>
            <Card className="full-width-card">
              <div data-testid="geen-instellingen-mogelijk">
                Er zijn geen instellingen mogelijk bij overlijdensscenario van een alleenstaande.
              </div>
            </Card>
          </CardWrapper>
        )}

        {values.aanvrager1 && values.overlijden.scenarioVan.aanvrager1Geselecteerd && (
          <CardWrapper flexType={"flex-column"} maxRowCount={1}>
            <Card
              title="Inkomensanalyse"
              fullscreenTonen
              subtitle={`Jouw inkomen bij overlijden van ${getNaam(values.aanvrager1, "Aanvrager")}`}
              subtitleOnSeparateLine
              subtitleSplit
              className="full-width-card"
              collapseExpandOption
            >
              <InkomensAnalysePdf
                motivering={MotiveringOnderwerpsoort.AfdekkenOverlijdensrisico}
                klantId={values.aanvrager1.klantId}
                onRefresh={saveAndSetErrors}
                dataOutdated={values.data1Outdated}
                setDataOutdated={/* istanbul ignore next */ value => formik.setFieldValue("data1Outdated", value)}
              />
            </Card>
          </CardWrapper>
        )}
        {values.aanvrager2 && values.overlijden.scenarioVan.aanvrager2Geselecteerd && (
          <CardWrapper flexType={"flex-column"} maxRowCount={1}>
            <Card
              title="Inkomensanalyse"
              fullscreenTonen
              subtitle={`Jouw inkomen bij overlijden van ${getNaam(values.aanvrager2, "Partner")}`}
              subtitleOnSeparateLine
              subtitleSplit
              className="full-width-card"
              collapseExpandOption
            >
              <InkomensAnalysePdf
                motivering={MotiveringOnderwerpsoort.AfdekkenOverlijdensrisico}
                klantId={values.aanvrager2.klantId}
                onRefresh={saveAndSetErrors}
                dataOutdated={values.data2Outdated}
                setDataOutdated={/* istanbul ignore next */ value => formik.setFieldValue("data2Outdated", value)}
              />
            </Card>
          </CardWrapper>
        )}
      </>
    );
  };

  if (saveBeforeLoading) {
    return <></>;
  }

  if (loading) {
    return <PageLoading />;
  }

  if (error) {
    return <ErrorPage error={error} data={platformData as any} />;
  }

  if (!data) {
    return <InvalidAdviesboxResultErrorPage />;
  }

  return (
    <AdviesBoxFormik<DashboardState>
      initialValues={data}
      validationSchema={dashboardSchema}
      onSaveAsync={async (
        values: DashboardState,
        formikHelpers: FormikHelpers<
          DashboardState & {
            platformApiFouten?: UiError[] | null | undefined;
          }
        >
      ): Promise<boolean> => {
        const result = await saveData(values);
        if (result !== null && result instanceof Array)
          /* istanbul ignore next */
          setFormikUiErrors(result, formikHelpers as FormikContextType<DashboardState>);

        props.callbackOnClose && props.callbackOnClose();

        return result === null ? true : false;
      }}
      closeModal={() => {
        /* istanbul ignore next */ props.closeModal && props.closeModal();
      }}
      render={({ submitForm }: FormikProps<DashboardState>): ReactElement => (
        <div className="modal-with-cards">
          <Modal
            title="Scenario overlijden"
            body={<ModalBody />}
            onSubmitClick={submitForm}
            onCancelClick={props.closeModal}
          />
        </div>
      )}
    />
  );
};
