import { LocalDate } from "@js-joda/core";
import { productDraftSideEffects } from "../../producten-overzicht/product/product-side-effects";
import { SoortKredietProductOptions } from "../../.generated/forms/formstypes";
import { KredietType, FiscaleGegevensType } from "./kredieten-types";
import { KredietenType } from "./kredieten-types";
import { initISWSideEffect, createISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";

const setDeelBoxBedrag = (fiscaleGegevens: FiscaleGegevensType, restantHoofdsom: number): void => {
  const percentageDeelBox1 = fiscaleGegevens.deelBox1Percentage;
  const percentageDeelBox3 = fiscaleGegevens.deelBox3Percentage;
  fiscaleGegevens.deelBox1Bedrag = Math.round((percentageDeelBox1 / 100) * restantHoofdsom);
  fiscaleGegevens.deelBox3Bedrag = Math.round((percentageDeelBox3 / 100) * restantHoofdsom);
};

const productenMetFiscaleGegevens = [
  SoortKredietProductOptions.AflopendKrediet,
  SoortKredietProductOptions.DoorlopendKrediet,
  SoortKredietProductOptions.Restschuldlening,
  SoortKredietProductOptions.Werkgeverslening
];

type FiscaleGegevensSideEffectsExtraParam = {
  soortProduct: SoortKredietProductOptions;
  restantHoofdsom: number;
  ingangsdatum: LocalDate | null;
  hasRestantHoofdsomChanged: boolean;
};

export const leningGegevensSideEffects = createISWSideEffect<KredietType>(({ has, draft }): void => {
  if (draft.soortProduct !== SoortKredietProductOptions.Privatelease) {
    const { leningGegevens } = draft;

    if (has.leningGegevens.hoofdsom.bedrag.changed) {
      // Bijwerken Hoofdsom => Restant hoofdsom
      leningGegevens.restantHoofdsom = leningGegevens.hoofdsom.bedrag;
    }
    if (
      has.leningGegevens.restantHoofdsom.changed &&
      (leningGegevens.restantHoofdsom ?? 0) > (leningGegevens.hoofdsom.bedrag ?? 0)
    ) {
      // Bijwerken Restant hoofdsom > Hoofdsom => Restant hoofdsom = Hoofdsom
      leningGegevens.restantHoofdsom = leningGegevens.hoofdsom.bedrag;
    }

    if (has.leningGegevens.restantHoofdsom.changed &&
      productenMetFiscaleGegevens.includes(draft.soortProduct)) {
      // Bijwerken Restant hoofdsom en box 1 & box 3 bedrag
      setDeelBoxBedrag(draft.fiscaleGegevens, leningGegevens.restantHoofdsom ?? 0);
    }
  }

  if (
    draft.soortProduct === SoortKredietProductOptions.Privatelease ||
    draft.soortProduct === SoortKredietProductOptions.Studielening
  ) {
    if (has.leningGegevens.hoofdsom.bedrag.changed) {
      // Bijwerken Hoofdsom => Restant hoofdsom
      draft.leningGegevens.restantHoofdsom = draft.leningGegevens.hoofdsom.bedrag;
      // draft.fiscaleGegevens.deelBox1Bedrag =0;
      draft.fiscaleGegevens.deelBox3Bedrag = draft.leningGegevens.hoofdsom.bedrag ?? 0;
    }
  }
});

const fiscaleGegevensSideEffects = createISWSideEffect<FiscaleGegevensType, FiscaleGegevensSideEffectsExtraParam>(
  ({ has, draft, context }): void => {
    if (context) {
      const { soortProduct, restantHoofdsom } = context;
      if (productenMetFiscaleGegevens.includes(soortProduct)) {
        if (
          (!has.deelBox1Bedrag.changed &&
          !has.deelBox3Bedrag.changed &&
          (!draft.deelBox1Bedrag && !draft.deelBox3Bedrag))
        ) {
          // Initieel zetten van box 1 & box 3 bedrag
          draft.deelBox1Bedrag = (restantHoofdsom * draft.deelBox1Percentage) / 100;
          draft.deelBox3Bedrag = restantHoofdsom - draft.deelBox1Bedrag;
        } 
        else if (
          !context.hasRestantHoofdsomChanged &&
          has.deelBox1Bedrag.changed
        ) {
          // Deel box 1 bedrag is veranderd
          let deelBox1Bedrag = draft.deelBox1Bedrag ?? 0;
          if (deelBox1Bedrag > restantHoofdsom) {
            deelBox1Bedrag = restantHoofdsom;
          }
          const deelBox1Percentage = restantHoofdsom ? (deelBox1Bedrag / restantHoofdsom) * 100 : 100;

          draft.deelBox1Bedrag = deelBox1Bedrag;
          draft.deelBox1Percentage = deelBox1Percentage;
          draft.deelBox3Bedrag = restantHoofdsom - deelBox1Bedrag;
          draft.deelBox3Percentage = 100 - deelBox1Percentage;
        } else if (
          has.deelBox1Percentage.changed
        ) {
          // Deel box 1 percentage is veranderd
          const deelBox1Percentage = draft.deelBox1Percentage ?? 0;
          const deelBox1Bedrag = (restantHoofdsom * deelBox1Percentage) / 100;

          draft.deelBox1Bedrag = deelBox1Bedrag;
          draft.deelBox1Percentage = deelBox1Percentage;
          draft.deelBox3Bedrag = restantHoofdsom - deelBox1Bedrag;
          draft.deelBox3Percentage = 100 - deelBox1Percentage;
        } else if (
          !context.hasRestantHoofdsomChanged &&
          has.deelBox3Bedrag.changed
        ) {
          // Deel box 3 bedrag is veranderd
          let deelBox3Bedrag = draft.deelBox3Bedrag ?? 0;
          if (deelBox3Bedrag > restantHoofdsom) {
            deelBox3Bedrag = restantHoofdsom;
          }
          const deelBox3Percentage = restantHoofdsom ? (deelBox3Bedrag / restantHoofdsom) * 100 : 100;

          draft.deelBox1Bedrag = restantHoofdsom - deelBox3Bedrag;
          draft.deelBox1Percentage = 100 - deelBox3Percentage;
          draft.deelBox3Bedrag = deelBox3Bedrag;
          draft.deelBox3Percentage = deelBox3Percentage;
        } else if (
          has.deelBox3Percentage.changed
        ) {
          // Deel box 3 percentage is veranderd
          const deelBox3Percentage = draft.deelBox3Percentage ?? 0;
          const deelBox3Bedrag = (restantHoofdsom * deelBox3Percentage) / 100;

          draft.deelBox1Bedrag = restantHoofdsom - deelBox3Bedrag;
          draft.deelBox1Percentage = 100 - deelBox3Percentage;
          draft.deelBox3Bedrag = deelBox3Bedrag;
          draft.deelBox3Percentage = deelBox3Percentage;
        }

        if (!draft.deelBox1Bedrag) {
          // Deel box 1 = leeg => Einddatum renteaftrek = leeg
          draft.einddatumRenteaftrek = null;
        } else if (context.ingangsdatum && draft.einddatumRenteaftrek?.isBefore(context.ingangsdatum)) {
          // Einddatum renteaftrek < Ingangsdatum => Einddatum renteaftrek = Ingangsdatum
          draft.einddatumRenteaftrek = context.ingangsdatum;
        }
      }
    }
  }
);

export const determineKredietDetailsSideEffects = initISWSideEffect<KredietenType, number>(bag => {
  const selected = bag.subset.producten[bag.context].create();
  const { draft, subset } = selected;

  productDraftSideEffects(subset.product.create());
  leningGegevensSideEffects(selected);

  fiscaleGegevensSideEffects(
    selected.subset.fiscaleGegevens.createWithContext({
      soortProduct: draft.soortProduct,
      restantHoofdsom: draft.leningGegevens.restantHoofdsom ?? 0,
      ingangsdatum: draft.product.ingangsdatum,
      hasRestantHoofdsomChanged: selected.has.leningGegevens.restantHoofdsom.changed
    })
  );
});
