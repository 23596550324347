import React, { ReactElement } from "react";
import classes from "../aanvraag.module.scss";
import { AanvraagState, AanvraagStep } from "../infra/aanvraag-schema";
import { FormikContextType } from "formik";
import { LoadingSpinner } from "adviesbox-shared";

export const AanvraagButton = ({
  formik: { values, setFieldValue },
  buttonTekst,
  nieuweVersieAanvraag,
  productId,
  isLoading,
  testId
}: {
  formik: FormikContextType<AanvraagState>;
  buttonTekst: string;
  nieuweVersieAanvraag: boolean;
  productId: string;
  isLoading: boolean;
  testId: string;
}): ReactElement => {
  return (
    <div className={classes.button_container}>
      <div
        className={classes.button_wrapper}
        onClick={() => {
          if (values.isLoadingAanvraag || isLoading) return;

          setFieldValue("geselecteerdProduct", productId);
          setFieldValue("aanvraagStep", AanvraagStep.ProductOvernemen);
          setFieldValue("nieuweVersieAanvraag", nieuweVersieAanvraag);
        }}
        data-testid="fetch-button-div"
        id="fetch-button-div"
      >
        {values.geselecteerdProduct === productId && 
        values.nieuweVersieAanvraag === nieuweVersieAanvraag && 
        (values.isLoadingAanvraag || isLoading) ? (
          <button data-testid={testId} type="button" id="fetch-button" className={`btn btn-primary`}>
            <LoadingSpinner size="S" />
          </button>
        ) : (
          <button
            data-testid={testId}
            type="button"
            id="fetch-button"
            className={`btn btn-primary`}
            disabled={
              (values.geselecteerdProduct !== productId || values.nieuweVersieAanvraag !== nieuweVersieAanvraag) && 
              (values.isLoadingAanvraag || isLoading)}
          >
            <span className={classes.display_text}>{buttonTekst}</span>
          </button>
        )}
      </div>
    </div>
  );
};

/* istanbul ignore next */
if (process.env.NODE_ENV !== "production") AanvraagButton.displayName = "AanvraagButton";
