import { Icon, ModalButton } from "adviesbox-shared";
import { LabelValuePair } from "adviesbox-shared/utils/types";
import { connect, Field, FormikContextType, FormikHelpers, getIn } from "formik";
import * as React from "react";
import { Button, InputGroup, ListGroup } from "react-bootstrap";
import { AanleidingState, VoorstelType } from "../infra/aanleiding-schema";
import { VoorstelkopierenModal } from "./voorstel-kopieren-modal/voorstel-kopieren-modal";
import { resetAllIndex } from "./voorstellen-overzicht";

type VoorstelItemProps = {
  index: number;
  element: VoorstelType;
  onDeleteClick: () => void;
};
export const onSaveVoorstelItem = (
  values: AanleidingState,
  setFieldValue: FormikHelpers<AanleidingState>["setFieldValue"],
  copiedItem: VoorstelType
): VoorstelType[] => {
  let voorstellen = [...values.voorstellen];
  const kopieOptie = values.kopieOptie;
  const hasNewKopieOptieNaam = values.newKopieOptieNaam ?? false;
  copiedItem.omschrijving = hasNewKopieOptieNaam ? hasNewKopieOptieNaam : copiedItem.omschrijving;
  voorstellen.splice(Number(kopieOptie), 1, copiedItem);
  voorstellen = resetAllIndex(voorstellen);

  setFieldValue("voorstellen", voorstellen);
  return voorstellen;
};

const VoorstelItem = ({
  element,
  index,
  formik: { values, setFieldValue, errors },
  onDeleteClick
}: VoorstelItemProps & {
  formik: FormikContextType<AanleidingState>;
}): React.ReactElement => {
  // om te bepalen of we de 'kopieer' knop mogen tonen moeten we weten
  const voorstellenLength = values.voorstellen.filter((c): boolean => !c.deleted).length;
  const copiedItem = { ...element };
  const error = getIn(errors, `voorstellen[${index}].omschrijving`);
  copiedItem.kopieVoorstelId = element.id || element.kopieVoorstelId;
  copiedItem.id = null;
  copiedItem.index = voorstellenLength;
  copiedItem.omschrijving = `Kopie ${element.omschrijving}`;

  const voorstellen = React.useMemo(() => {
    const list = values.voorstellen
      .filter(v => v.index !== element.index && !v.deleted)
      .map(
        (v): LabelValuePair => ({
          label: v.omschrijving || `Voorstel #${1 + v.index}`,
          value: v.index.toString()
        })
      );
    return list;
  }, [values.voorstellen, element]);

  return (
    <ListGroup.Item key={index}>
      <InputGroup>
        <Field
          id={`voorstel_${index}`}
          type="input"
          name={`voorstellen[${index}].omschrijving`}
          className={`form-control ${error ? "input-rood" : ""}`}
          placeholder="Naam voorstel (max. 25 karakters)"
          data-lpignore="true"
        />
        <InputGroup.Append>
          {voorstellenLength < 3 ? (
            <Button
              id={`btn-kopieer-primary-${index}`}
              data-testid={`btn-kopieer-primary-${index}`}
              variant="outline-secondary"
              onClick={(): void => {
                let voorstellen = [...values.voorstellen];
                voorstellen.splice(voorstellenLength, 0, copiedItem);
                voorstellen = resetAllIndex(voorstellen);
                setFieldValue("voorstellen", voorstellen);
              }}
            >
              <Icon name="copy" alt="Kopieer" />
            </Button>
          ) : (
            <ModalButton parent={`voorstellen[${index}]`} size="lg" content={<Icon name="copy" alt="Kopieer" />}>
              <VoorstelkopierenModal
                data={{ ...values, kopieOptie: !index ? "1" : "0", newKopieOptieNaam: copiedItem.omschrijving }} //een default value voor de radiobutton
                element={element}
                voorstellen={voorstellen}
                onSave={(values): void => {
                  onSaveVoorstelItem(values, setFieldValue, copiedItem);
                }}
              />
            </ModalButton>
          )}
          {/* Verwijder knop tonen -> maar niet bij eerste regel: */}
          {index > 0 && (
            <Button
              id={`btn-verwijder-${index}`}
              data-testid={`btn-verwijder-${index}`}
              variant="outline-secondary"
              onClick={onDeleteClick}
            >
              <Icon name="prullenbak" alt="Verwijderen" />
            </Button>
          )}
        </InputGroup.Append>
      </InputGroup>
    </ListGroup.Item>
  );
};

export default connect<VoorstelItemProps, AanleidingState>(VoorstelItem);
