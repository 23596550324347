import React, { ReactElement } from "react";
import { QuestionSpecCombined, QuestionType } from "../../klantprofiel-schema";
import { RadioInputGroup } from "adviesbox-shared";
import { Direction } from "../../../../shared/types";
import { getKlantprofielResources } from "../../klantprofiel-resources";
import classNames from "classnames";

type QuestionRadioProps = {
  data: QuestionSpecCombined;
};
export const QuestionRadio = ({ data }: QuestionRadioProps): ReactElement => {
  if (data.type !== QuestionType.radio) return <>{`invalid QuestionType: ${data.type}`}</>;

  const opties = data.schema.antwoorden.map(a => ({
    label: a.omschrijving ?? "",
    value: a.code.toString()
  }));

  return (
    <>
      <div className={classNames({ "mt-3": data.schema.omschrijving })}>
        {data.schema.omschrijving && <h2>{data.schema.omschrijving}</h2>}
        <span className="d-block font-italic px-1">{getKlantprofielResources("EenAntwoord")}</span>
        <RadioInputGroup
          options={opties}
          key={`key-${data.index}-${data.question}`}
          name={`vragen[${data.index}].gekozenAntwoord`}
          data-testid={`radio-group-${data.schema.code}`}
          layout={Direction.Vertical}
        />
      </div>
    </>
  );
};
