/* istanbul ignore file */
import { logErrorToApplicationInsights } from "adviesbox-shared";
import { AsyncSideEffect } from "use-formik-side-effects";
import { errorUi } from "../types";
export const returnErrorBerekening = (field: string, errorMessage: string): AsyncSideEffect<errorUi>[] => {
  const error = new Error(`Rekenfout: ${errorMessage}`);
  logErrorToApplicationInsights(error);

  return [
    {
      field: field,
      value: "Er is een fout opgetreden tijdens de berekening."
    }
  ];
};
