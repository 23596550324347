import { LocalDateTime } from "@js-joda/core";
import { BeschikbareHypotheekProductenType, ProductFilterType } from "adviesbox-shared/product-filters/filter-types";
import { AflosProductKeuzeLijstType } from "../hooks/use-producten-data";

export function filterAflosvormen(
  /** Dit zijn de mogelijke hypotheekvormen */
  aflosProductKeuzeLijst: AflosProductKeuzeLijstType | null,
  /** Code van de geselecteerde maatschappij */
  maatschappijCode: string,
  /** Code van de geselecteerde label */
  labelCode: string,
  /** De lijst producten waar tegen gefilterd moet worden */
  filterList: BeschikbareHypotheekProductenType[]
): AflosProductKeuzeLijstType {
  const filteredProductenKeuzelijst = {} as AflosProductKeuzeLijstType;

  for (const index in aflosProductKeuzeLijst) {
    filterList.some(
      productMaatschappij =>
        productMaatschappij.code === maatschappijCode &&
        productMaatschappij.hypotheeklabels.some(
          hypotheekLabel =>
            String(hypotheekLabel.code) === labelCode &&
            hypotheekLabel.hypotheekvormen.forEach(hypotheekvorm => {
              if (
                hypotheekvorm.code === aflosProductKeuzeLijst[index].code &&
                hypotheekvorm.actief &&
                isProductSupported(hypotheekvorm)
              ) {
                filteredProductenKeuzelijst[index] = aflosProductKeuzeLijst[index];
              }
            })
        )
    );
  }
  return filteredProductenKeuzelijst;
}

/** Checks in ProductFilterType if ondersteunenTot is set and if so it checks if the date is before now  */
export function isProductSupported(product: ProductFilterType): boolean {
  return (
    product.ondersteunenTot === undefined || LocalDateTime.parse(product.ondersteunenTot).isAfter(LocalDateTime.now())
  );
}
