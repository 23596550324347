import { QuestionType, CardSpec } from "../../infra/klantprofiel-schema";
import { KlantprofielVraagOptions } from "../../../.generated/forms/formstypes";

export const geldverstrekkerQuestionSpecs: CardSpec[] = [
  {
    questions: [
      {
        question: KlantprofielVraagOptions.VoorkeurGeldverstrekker,
        type: QuestionType.radioMetText
      }
    ]
  },
  {
    questions: [
      {
        question: KlantprofielVraagOptions.GeldverstrekkerVoorwaardenBelangrijk,
        type: QuestionType.checkbox
      }
    ]
  }
];
