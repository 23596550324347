import React, { ReactElement } from "react";
import { connect, FormikContextType } from "formik";
import { getNaam } from "../../shared/utils/helpers";
import { BerekenCurrencyInput, LabeledText } from "adviesbox-shared";
import { MaximaleHypotheekState } from "../infra/maximale-hypotheek-schema";
import { getMaximaleHypotheekTextResources } from "../infra/maximale-hypotheek-resources";
import { getDifferenceYearsMonths, YearMonth } from "../../shared/utils/dates";
import { jaarMaandInputNaarJaarMaand } from "../../shared/generic-parts/jaar-maand/helpers";

export const jaarMaandText = (dtm: YearMonth | null): string => {
  if (!dtm) return "";
  return jaarMaandInputNaarJaarMaand({ jaren: dtm.year, maanden: dtm.month });
};

const Inkomen = ({ formik: { values } }: { formik: FormikContextType<MaximaleHypotheekState> }): ReactElement => {
  const { persoonsgegevens } = values;
  const aanvrager1AOVLeeftijd =
    values.persoonsgegevens.naamAanvrager?.aowdatum && values.persoonsgegevens.naamAanvrager?.geboortedatum
      ? getDifferenceYearsMonths(
          values.persoonsgegevens.naamAanvrager?.geboortedatum,
          values.persoonsgegevens.naamAanvrager?.aowdatum
        )
      : null;
  const aanvrager2AOVLeeftijd =
    values.persoonsgegevens.naamPartner?.aowdatum && values.persoonsgegevens.naamPartner?.geboortedatum
      ? getDifferenceYearsMonths(
          values.persoonsgegevens.naamPartner?.geboortedatum,
          values.persoonsgegevens.naamPartner?.aowdatum
        )
      : null;

  return (
    <>
      <h2 className="ml-3">{getNaam(persoonsgegevens.naamAanvrager, "Aanvrager")}</h2>
      <BerekenCurrencyInput
        caption="Toetsinkomen"
        name="inkomen.toetsinkomenAanvrager"
        tooltip={getMaximaleHypotheekTextResources("tooltipInkomenToetsinkomen")}
      />
      <BerekenCurrencyInput caption="Pensioeninkomen" name="inkomen.pensioeninkomenAanvrager" />
      <LabeledText caption="AOW leeftijd" value={jaarMaandText(aanvrager1AOVLeeftijd)} fieldSize="no-size" />

      {!!persoonsgegevens.naamPartner?.klantId && (
        <>
          <h2 className="ml-3">{getNaam(persoonsgegevens.naamPartner, "Partner")}</h2>

          <BerekenCurrencyInput caption="Toetsinkomen" name="inkomen.toetsinkomenPartner" />

          <BerekenCurrencyInput caption="Pensioeninkomen" name="inkomen.pensioeninkomenPartner" />

          <LabeledText caption="AOW leeftijd" value={jaarMaandText(aanvrager2AOVLeeftijd)} fieldSize="no-size" />
        </>
      )}
    </>
  );
};

export default connect<{}, MaximaleHypotheekState>(Inkomen);
