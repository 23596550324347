import { Aanleiding as AanvraagDlEntry } from "../../.generated/forms/formstypes";
import { UiName, FieldMap } from "../../shared/types";
import { target2field } from "../../shared/utils/target-to-field";
/* istanbul ignore file */

export function mapDlTargetToAanvraagUiField(target: string): UiName | null {
  const map: FieldMap<AanvraagDlEntry> = {};

  return target2field(map, target);
}
