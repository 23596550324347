import React, { ReactElement } from "react";
import { Button } from "react-bootstrap";
import memoizeOne from "memoize-one";
import { Icon } from "adviesbox-shared";
import { FieldArray, FieldArrayRenderProps } from "formik";
import { InternalCellConfig } from "adviesbox-shared/data-grid/cell-components/cell-components";
import { CellInfo } from "react-table-6";
import { showEdit, HypotheekType } from "./infra/hypotheek-types";
import { partijOnafhankelijk } from "../producten-overzicht/infra/product-constanten";

const handleEditClick = (
  event: React.SyntheticEvent<HTMLElement>,
  index: number,
  setSelectedRow?: React.Dispatch<React.SetStateAction<number>>,
  setShowPopup?: React.Dispatch<React.SetStateAction<showEdit>>
): void => {
  event.stopPropagation();
  setSelectedRow && setSelectedRow(index);
  setShowPopup && setShowPopup({ visible: true, edit: true, productOnly: false, omzetten: false });
};

const handleEditKeyUp = (
  event: React.KeyboardEvent<HTMLElement>,
  index: number,
  setSelectedRow?: React.Dispatch<React.SetStateAction<number>>,
  setShowPopup?: React.Dispatch<React.SetStateAction<showEdit>>
): void => {
  if (event.key === "Enter") {
    handleEditClick(event, index, setSelectedRow, setShowPopup);
  }
};

const handleEditMouseUp = (
  event: React.MouseEvent<HTMLElement, MouseEvent>,
  index: number,
  setSelectedRow?: React.Dispatch<React.SetStateAction<number>>,
  setShowPopup?: React.Dispatch<React.SetStateAction<showEdit>>
): void => {
  if (event.button === 0) {
    handleEditClick(event, index, setSelectedRow, setShowPopup);
  }
};

const handleDeleteClick = (
  event: React.SyntheticEvent<HTMLElement>,
  index: number,
  arrayHelpers: FieldArrayRenderProps,
  rowSelected?: (index: number) => void
): void => {
  event.stopPropagation();
  rowSelected && rowSelected(index > 0 ? index - 1 : 0);
  arrayHelpers.remove(index);
  arrayHelpers.form.setFieldValue("platformApiFouten", null);
};

const handleDeleteKeyUp = (
  event: React.KeyboardEvent<HTMLElement>,
  index: number,
  arrayHelpers: FieldArrayRenderProps,
  rowSelected?: (index: number) => void
): void => {
  if (event.key === "Enter") {
    handleDeleteClick(event, index, arrayHelpers, rowSelected);
  }
};

const handleDeleteMouseUp = (
  event: React.MouseEvent<HTMLElement, MouseEvent>,
  index: number,
  arrayHelpers: FieldArrayRenderProps,
  rowSelected?: (index: number) => void
): void => {
  if (event.button === 0) {
    handleDeleteClick(event, index, arrayHelpers, rowSelected);
  }
};

export const EditButtonHypotheek = memoizeOne(
  (
    setShowPopup?: React.Dispatch<React.SetStateAction<showEdit>>,
    setSelectedRow?: React.Dispatch<React.SetStateAction<number>>
  ): ((cellInfo: CellInfo) => ReactElement) => {
    const CellEditButton = (c: CellInfo): ReactElement =>
      c.original.product.doorlopend ? (
        <></>
      ) : (
        <div className="d-flex align-items-center justify-content-center w-100 h-100">
          <Button
            data-testid={`btn-edit-${c.index}`}
            id={`btn-edit-${c.index}`}
            variant="outline-secondary"
            onKeyUp={event => handleEditKeyUp(event, c.index, setSelectedRow, setShowPopup)}
            onMouseUp={event => handleEditMouseUp(event, c.index, setSelectedRow, setShowPopup)}
            aria-label="bewerk knop"
          >
            <Icon name="pencil" alt="Wijzigen" />
          </Button>
        </div>
      );

    CellEditButton.displayName = "CellEditButton";

    return CellEditButton;
  }
);

export const DeleteButtonHypotheek = memoizeOne(
  ({
    arrayName,
    rowSelected,
    situatie
  }: InternalCellConfig & { situatie: "voorstel" | "huidig" }): ((cellInfo: CellInfo) => ReactElement) => {
    const CellDeleteButton = ({ index, original }: CellInfo): ReactElement =>
      (original.hypotheekVorm.omschrijving.includes("Restschuld") ||
        (original.partijCode === partijOnafhankelijk && (original as HypotheekType).hypotheekVorm.code === 12)) &&
      situatie === "voorstel" ? (
        <></>
      ) : (
        <FieldArray
          name={arrayName}
          render={(arrayHelpers: FieldArrayRenderProps): React.ReactNode => (
            <Button
              data-testid={`btn-verwijder-${index}`}
              id={`btn-verwijder-${index}`}
              variant="outline-secondary"
              onKeyUp={event => handleDeleteKeyUp(event, index, arrayHelpers, rowSelected)}
              onMouseUp={event => handleDeleteMouseUp(event, index, arrayHelpers, rowSelected)}
              aria-label="Verwijder knop"
            >
              <Icon name="prullenbak" alt="Verwijderen" />
            </Button>
          )}
        />
      );

    CellDeleteButton.displayName = "CellDeleteButton";
    return CellDeleteButton;
  }
);
