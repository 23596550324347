import { LocalDate } from "@js-joda/core";
import { hasValue } from "adviesbox-shared";
import { ErrorDataType } from "./aanvullende-gegevens-schema";

const phoneNumberAllowEmptyRegex = /^0{1,2}[0-9]{8,23}$/;
const emailAllowEmptyRegex = /^(([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)|)$/i;
const HDNsRegexVoorIBAN = /^[A-Z]{2}[0-9]{2}[A-Z0-9]{1,30}$/;
//Schema error text
export const returnErrorText = (parent: any): string | null => {
  const waardeLength = parent.waarde?.toString().length ?? 0;
  const waarde = parent.waarde?.toString();

  if (parent.dataType === ErrorDataType.integer) {
    if (waardeLength > parent.maxDigits) {
      return `Het maximaal aantal getallen is ${parent.maxDigits}.`;
    }
  }
  if (parent.dataType === ErrorDataType.decimal) {
    let waardeVoorDeKomma = "";
    let waardeNaDeKomma = "";
    if (hasValue(waarde) && waarde?.split(".")?.length === 2) {
      [waardeVoorDeKomma, waardeNaDeKomma] = waarde.split(".");
    }
    if (hasValue(waarde) && !waarde?.includes(".")) {
      waardeVoorDeKomma = waarde;
    }

    if (waardeVoorDeKomma.length > parent.maxDigits) {
      return `Het maximaal aantal getallen voor de komma is ${parent.maxDigits}.`;
    }
    if (waardeNaDeKomma.length > parent.maxAllowedDecimalpoints) {
      return `Het maximaal aantal getallen na de komma is ${parent.maxAllowedDecimalpoints}.`;
    }
  }
  if (parent.dataType === ErrorDataType.alphanumeric) {
    if (waardeLength > parent.maxDigits) {
      return `Het maximaal aantal letters is ${parent.maxDigits}.`;
    }
  }

  if (parent.dataType === ErrorDataType.date) {
    try {
      LocalDate.parse(waarde);
    } catch (e) {
      return "Vul een geldige datum in.";
    }
  }

  if (parent.dataType === ErrorDataType.telefoon) {
    if (waardeLength > parent.maxDigits) {
      return `Het maximaal aantal getallen is ${parent.maxDigits}.`;
    }
    if (!phoneNumberAllowEmptyRegex.test(waarde)) {
      return "Vul een geldig telefoonnummer in.";
    }
  }

  if (parent.dataType === ErrorDataType.IBAN) {
    if (waardeLength > parent.maxDigits) {
      return `Het maximaal aantal letters is ${parent.maxDigits}.`;
    }
    if (!HDNsRegexVoorIBAN.test(waarde)) {
      return "Vul een geldig IBAN-nummer in.";
    }
  }

  if (parent.dataType === ErrorDataType.email) {
    if (waardeLength > parent.maxDigits) {
      return `Het maximaal aantal getallen is ${parent.maxDigits}.`;
    }
    if (!emailAllowEmptyRegex.test(waarde)) {
      return "Vul een geldige e-mailadres in.";
    }
  }

  return null;
};
