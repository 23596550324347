import { GebruikWoning } from "../../shared/types";
import { AanleidingState, PlanningKeuzeType, AanleidingGesprekKeuzeType } from "./aanleiding-schema";
import { createISWSideEffect, initISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";
import { FinancieringOptions } from "../../.generated/forms/formstypes";

export type Context = {
  modelStateModal: [boolean, React.Dispatch<React.SetStateAction<boolean>>]; 
  modelStatePrevFinancieringsKeuze: [FinancieringOptions | null, React.Dispatch<React.SetStateAction<FinancieringOptions | null>>];
  modelFinancieringsKeuzeOpslaan: [boolean | null, React.Dispatch<React.SetStateAction<boolean | null>>];
};

const toonVerbouwing = (aanleidingFinanciering: FinancieringOptions): boolean =>
  aanleidingFinanciering === FinancieringOptions.AankoopBestaandeBouw ||
  aanleidingFinanciering === FinancieringOptions.AankoopNieuwbouw ||
  aanleidingFinanciering === FinancieringOptions.HuidigeHypotheekWijzigen;

const toonGebruikWoning = (aanleidingFinanciering: FinancieringOptions): boolean =>
  aanleidingFinanciering === FinancieringOptions.AankoopBestaandeBouw ||
  aanleidingFinanciering === FinancieringOptions.AankoopNieuwbouw;

const toonAfkoopErfpacht = (aanleidingFinanciering: FinancieringOptions): boolean =>
  aanleidingFinanciering === FinancieringOptions.HuidigeHypotheekWijzigen;

const toonFinancieringWoning = (aanleidingFinanciering: FinancieringOptions): boolean =>
  aanleidingFinanciering === FinancieringOptions.AankoopNieuwbouw ||
  aanleidingFinanciering === FinancieringOptions.AankoopBestaandeBouw;

const toonExtraAflossen = (aanleidingFinanciering: FinancieringOptions): boolean =>
  aanleidingFinanciering === FinancieringOptions.HuidigeHypotheekWijzigen;

const toonEchtscheiding = (aanleidingFinanciering: FinancieringOptions): boolean =>
  aanleidingFinanciering === FinancieringOptions.HuidigeHypotheekWijzigen;

const toonEindeRentevastperiode = (aanleidingFinanciering: FinancieringOptions): boolean =>
  aanleidingFinanciering === FinancieringOptions.HuidigeHypotheekWijzigen;

const toonAflossingsvorm = (aanleidingFinanciering: FinancieringOptions): boolean =>
  aanleidingFinanciering === FinancieringOptions.HuidigeHypotheekWijzigen;

const toonMaandlastenverlichting = (aanleidingFinanciering: FinancieringOptions): boolean =>
  aanleidingFinanciering === FinancieringOptions.HuidigeHypotheekWijzigen;

const toonRisicoscan = (planningKeuze: PlanningKeuzeType): boolean =>
  planningKeuze.overlijden ||
  planningKeuze.arbeidsongeschiktheid ||
  planningKeuze.werkloosheid ||
  planningKeuze.pensioen;

const toonVermogensadvies = (planningKeuze: PlanningKeuzeType): boolean =>
  planningKeuze.vermogensopbouw || planningKeuze.pensioen;

const toonControlegesprek = (aanleidingFinanciering: FinancieringOptions): boolean =>
  aanleidingFinanciering === FinancieringOptions.GeenFinanciering;

function AanleidingGesprekZetVinkjesUitWanneerNietGetoond(aanleiding: AanleidingState): AanleidingGesprekKeuzeType {
  const aanleidingGesprek = aanleiding.aanleidingGesprek;
  const financiering = aanleiding.aanleiding.financiering;

  if (!toonVerbouwing(financiering) && aanleidingGesprek.verbouwing) {
    aanleidingGesprek.verbouwing = false;
  }

  if (!toonAfkoopErfpacht(financiering) && aanleidingGesprek.afkooperfpacht) {
    aanleidingGesprek.afkooperfpacht = false;
  }

  if (!toonFinancieringWoning(financiering) && aanleidingGesprek.financieringWoning) {
    aanleidingGesprek.financieringWoning = false;
  }

  if (!toonExtraAflossen(financiering) && aanleidingGesprek.extraAflossen) {
    aanleidingGesprek.extraAflossen = false;
  }

  if (!toonEchtscheiding(financiering) && aanleidingGesprek.echtscheiding) {
    aanleidingGesprek.echtscheiding = false;
  }

  if (!toonEindeRentevastperiode(financiering) && aanleidingGesprek.eindeRentevastperiode) {
    aanleidingGesprek.eindeRentevastperiode = false;
  }

  if (!toonAflossingsvorm(financiering) && aanleidingGesprek.aflossingsVormen) {
    aanleidingGesprek.aflossingsVormen = false;
  }

  if (!toonMaandlastenverlichting(financiering) && aanleidingGesprek.maandlastenverlichting) {
    aanleidingGesprek.maandlastenverlichting = false;
  }

  if (!toonRisicoscan(aanleiding.planningKeuze) && aanleidingGesprek.risicoscan) {
    aanleidingGesprek.risicoscan = false;
  }

  if (!toonVermogensadvies(aanleiding.planningKeuze) && aanleidingGesprek.vermogensAdvies) {
    aanleidingGesprek.vermogensAdvies = false;
  }

  if (!toonControlegesprek(financiering) && aanleidingGesprek.controlegesprek) {
    aanleidingGesprek.controlegesprek = false;
  }

  return aanleidingGesprek;
}

const financieringskeuzeChanged = (draft: AanleidingState): void => {
  draft.planningKeuze.arbeidsongeschiktheid = true;
  draft.planningKeuze.overlijden = true;
  draft.planningKeuze.pensioen = true;
  draft.planningKeuze.vermogensopbouw = true;
  draft.planningKeuze.werkloosheid = true;

  const financieringWoning =
    draft.aanleiding.financiering === FinancieringOptions.AankoopNieuwbouw ||
    draft.aanleiding.financiering === FinancieringOptions.AankoopBestaandeBouw;
  if (draft.aanleidingGesprek.financieringWoning !== financieringWoning) {
    draft.aanleidingGesprek.financieringWoning = financieringWoning;
  }
}

export const aanleidingSideEffects = createISWSideEffect<AanleidingState, Context>(({ has, prev, draft, context }): void => {
  // Bij wijzigen van finacieringskeuze eerst modal openen met vraag of gebruiker de wijziging wilt opslaan
  const [prevFinancieringsKeuze, setPrevFinancieringsKeuze] = context.modelStatePrevFinancieringsKeuze;
  const [financieringsKeuzeOpslaan, setFinancieringsKeuzeOpslaan] = context.modelFinancieringsKeuzeOpslaan;
  const [, setModalState] = context.modelStateModal;
  if (has.aanleiding.financiering.changed) {
    // Als het op initiele waarde GeenFinanciering staat dan direct opslaan 
    if (prev.aanleiding.financiering === FinancieringOptions.GeenFinanciering) {
      setFinancieringsKeuzeOpslaan(true);
    } else {
      setPrevFinancieringsKeuze(prev.aanleiding.financiering);
      // Eerst modal openen met vraag of gebruiker de wijziging wilt opslaan
      setModalState(true);
    }
  }

  // Na annuleren van de wijziging de financieringskeuze weer terugzetten
  if (financieringsKeuzeOpslaan === false) {
    draft.aanleiding.financiering = prevFinancieringsKeuze;
    setFinancieringsKeuzeOpslaan(null);
    return;
  }

  if (has.aanleiding.financiering.changed || has.planningKeuze.changed || has.aanleidingGesprek.changed) {
    if (
      !toonGebruikWoning(draft.aanleiding.financiering) &&
      draft.aanleiding.gebruikWoning === GebruikWoning.TweedeWoning
    ) {
      draft.aanleiding.gebruikWoning = GebruikWoning.Primair;
    }
    /*    zet vinkjes uit bij aanleiding gesprek als deze niet getoond worden */
    draft.aanleidingGesprek = AanleidingGesprekZetVinkjesUitWanneerNietGetoond(draft);
  }

  if (has.aanleiding.financiering.changed) {
    financieringskeuzeChanged(draft)
  }

  if (has.aanleidingGesprek.andersTekst.changed) {
    draft.aanleidingGesprek.andersTekst === ""
      ? (draft.aanleidingGesprek.anders = false)
      : (draft.aanleidingGesprek.anders = true);
  }

  if (has.aanleidingGesprek.anders.changed) {
    draft.aanleidingGesprek.andersTekst = "";
  } 
});

export const determineAanleidingSideEffects = initISWSideEffect<AanleidingState, Context>(aanleidingSideEffects);
