import { Icon, ErrorPage, PageLoading, useRequestInit } from "adviesbox-shared";
import classnames from "classnames";
import React, { ReactElement, useEffect, useState } from "react";
import { CellInfo } from "react-table-6";
import classes from "./zoeken-delete.module.scss";
import { laatstVerzondenAanragenKlantdossier } from "./zoeken-delete-client-modal-api";
import { LaatstVerzondenAanvraagOutput } from "../../../.generated/hdndossier/hdndossiertypes";

export enum Dossierstatus {
  Geen = "Geen",
  InBehandeling = "InBehandeling",
  Actief = "Actief",
  NietActief = "NietActief"
}

export const ZoekenConfirmDeleteClientModal = ({
  deleteClient,
  cellInfo,
  closeModal
}: {
  deleteClient: (klantdossierId: string) => () => void;
  cellInfo: CellInfo;
  closeModal?: () => void;
}): ReactElement => {
  const { settings, user, params } = useRequestInit<{ vestiging: string }>();
  const { original } = cellInfo;
  const { klant } = original;

  const [laatstVerzondenAanvragen, setlaatstVerzondenAanvragen] = useState<LaatstVerzondenAanvraagOutput[] | null>(null);  
  const [loading, setLoading] = useState<boolean>(true);  
  const [error, setError] = useState<Error | null>(null);  
  const inBemiddeling = original.adviesDossiers?.some((v: any) => !!v.bemiddelingDossierStatus && v.bemiddelingDossierStatus !== Dossierstatus.Geen);

  useEffect(() => {
    setLoading(true);
    laatstVerzondenAanragenKlantdossier(settings, user, params.vestiging, original.adviesDossiers)
      .then((result) => {
        setlaatstVerzondenAanvragen(result);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [settings, user, params.vestiging, original.adviesDossiers]);  

  /* istanbul ignore next */
  if (error) {
    return <ErrorPage error={error} />;
  }

  /* istanbul ignore next */
  if (loading || !laatstVerzondenAanvragen) {
    return <PageLoading />;
  }

  const getModalMessage = (): ReactElement => {
    // Kijkt of alle adviesdossiers verwijderd mogen worden op basis van laatst verzonden aanvraag
    const aanvragenWithValue = laatstVerzondenAanvragen.flatMap((x) => x.laatstVerzondenAanvraag ? [x.laatstVerzondenAanvraag] : []);
    const allAdviesDossiersDeletable = aanvragenWithValue.every((x) => x.verwijderDrempelOverschreden === true); 
    const isDeletable = allAdviesDossiersDeletable || inBemiddeling;
    const dagenVerwijderDrempel = laatstVerzondenAanvragen[0]?.laatstVerzondenAanvraag?.aantalDagenVerwijderDrempel;

    const message = (): string => {
      if (!allAdviesDossiersDeletable) {
        return `Het is op dit moment niet mogelijk om dit klantdossier te verwijderen, omdat er voor ten minste één adviesdossier 
een HDN-bericht is verstuurd. Binnen ${dagenVerwijderDrempel} dagen na \
versturen kunnen hierop nog retourberichten binnenkomen.`;
        
      } else if (allAdviesDossiersDeletable && aanvragenWithValue.length) {
        return `Let op! Deze klantdossier bevat adviezen die zijn verzonden met een HDN-bericht. \
Wanneer u dit klantdossier verwijderd, kan een retourbericht (offerte, status) van de maatschappij niet meer aan de aanvrager worden gekoppeld. \
Alle onderliggende dossiers worden ook verwijderd. Wilt u doorgaan?`;
      }
      if (inBemiddeling) {
        return `Dit klantdossier bevat adviezen die momenteel zijn gekoppeld aan producten in aanvragen. Zolang het dossier nog niet is \
afgerond in de HDN-dossier applicatie, kan het dossier niet worden verwijderd.`;
      } 
      
      return `Alle onderliggende dossiers worden ook verwijderd.`;
    }
    
    return (
      <div data-testid={`confirm-delete-client-modal`}>
          <div className={"pb-2 d-flex px-4 pt-3"}>
            <h4>
              {isDeletable 
                ? "Weet u zeker dat u deze klant wilt verwijderen?"
                : "Dossier kan niet worden verwijderd"}
            </h4>
            <div
              data-testid="close-icon"
              onClick={closeModal}
              className={classnames("ml-auto font-weight-bold cursor-pointer", classes.button_container)}
            >
              <span id={"klant-verwijderen-annuleren-icon"} className={"p-1"}>
                <Icon name="close" alt="Sluiten" />
              </span>
            </div>
          </div>
          <p className="px-4 pb-2">  
            {isDeletable && (<>Weet u zeker dat u <strong>{klant}</strong> wilt verwijderen? </>)}
            {message()}
          </p>  
          <div className={classnames("d-flex align-items-center justify-content-end", classes.modal_footer)}>
            <button id={"klant-verwijderen-annuleren-button"} onClick={closeModal} className="btn btn-link mx-1">
              {isDeletable ? "Annuleren" : "Sluiten"}
            </button>
            {isDeletable && <button
              id={"klant-verwijderen-button"}
              onClick={deleteClient(original?.klantdossierId)}
              className="btn btn-delete mx-1 mr-4"
            >
              Klant en onderliggende dossiers verwijderen
            </button>}
          </div>
        </div>
    )
  }

  return (
    <>
      {getModalMessage()}
    </>
  );
};
