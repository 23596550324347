import { Icon } from "adviesbox-shared";
import React, { ReactElement } from "react";
import { Button } from "react-bootstrap";
import { bedragFormat } from "../../../shared/utils/currency";
import { OrvVergelijkenModalType, OrvVergelijkerResultaatType } from "../../infra/orv-schema";
import classes from "./orv-vergelijker-pdf.module.scss";
import { renderOrvPDFFooter } from "./orv-vergelijker-pdf-footer";
import { renderOrvPDFHeader } from "./orv-vergelijker-pdf-header";
import { printPDF } from "./orv-vergelijker-pdf-helpers/orv-vergelijker-pdf-helpers";
import { renderOrvPDFVerzekeringTable } from "./orv-vergelijker-pdf-verzekering-table";
type OrvVergelijkerPDFProps = {
  values: OrvVergelijkenModalType;
};

export const OrvVergelijkerPDFGenerator = (props: OrvVergelijkerPDFProps): ReactElement => {
  const {
    values: { verzekerden, sortedOrvVergelijkerResultaat, verzekering, organisatieData }
  } = props;

  const orvVergelijker = [...sortedOrvVergelijkerResultaat];
  const amountOfLinesFirstPage = 40;
  const amountOfLinesPerPage = 55;
  const amountOflinesTotal = orvVergelijker.length;
  let amountOfpages = Math.ceil((amountOflinesTotal - amountOfLinesFirstPage) / amountOfLinesPerPage + 1);

  const renderPage = (
    renderVerzekeringOnly: boolean,
    slicedContent: OrvVergelijkerResultaatType[],
    index?: number,
    firstRender?: boolean,
    lastPageHasSpace?: boolean
  ): ReactElement => {
    return (
      <div
        className={classes.pdf_hide}
        id={`print-container-${index}`}
        key={`print-container-${index}-key`}
        style={{ maxWidth: "30.3cm", minWidth: "30.3cm", display: "block" }}
      >
        <div className={classes.pdf_page} style={{ backgroundColor: "white" }}>
          {firstRender && renderOrvPDFHeader({ bedrijfslogo: organisatieData?.logo, verzekerden })}
          {!renderVerzekeringOnly && slicedContent && (
            <table className={`${classes.pdf_table} ${classes.pdf_table_dataTable}`}>
              <thead>
                <tr>
                  <th className={`${classes.pdf_table_data_header} ${classes.pdf_table_data_header_first}`}>
                    Maatschappij
                  </th>
                  <th className={`${classes.pdf_table_data_header} ${classes.pdf_table_data_header_second}`}>
                    Product
                  </th>
                  <th className={`${classes.pdf_table_data_header} ${classes.pdf_table_data_header_rest}`}>
                    Premieduur
                  </th>
                  <th className={`${classes.pdf_table_data_header} ${classes.pdf_table_data_header_rest}`}>
                    Premieverloop
                  </th>
                  <th className={`${classes.pdf_table_data_header} ${classes.pdf_table_data_header_align_right}`}>
                    Totale premie
                  </th>
                  <th className={`${classes.pdf_table_data_header} ${classes.pdf_table_data_header_align_right}`}>
                    Eerste premie
                  </th>
                </tr>
              </thead>
              <tbody>
                {slicedContent.map((v, i) => (
                  <tr key={"body_row" + i}>
                    <td>{v.maatschappijOmschrijving}</td>
                    <td>{v.productnaam}</td>
                    <td>{v.premieduurInJaren ? `${v.premieduurInJaren} Jaar` : ``}</td>
                    <td>{v.premieverloop}</td>
                    <td className={classes.pdf_table_right_align}>{bedragFormat(v.totalePremieBedrag, 2)}</td>
                    <td className={classes.pdf_table_right_align}>{bedragFormat(v.eerstePremieBedrag, 2)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          {(renderVerzekeringOnly || lastPageHasSpace) &&
            renderOrvPDFVerzekeringTable({ renderVerzekeringOnly, verzekering })}
          {renderOrvPDFFooter({ organisatieNaam: organisatieData?.naam })}
        </div>
      </div>
    );
  };

  const renderPages = (): ReactElement => {
    let verzekeringTableFitsOnLastPage = false;
    const counter = amountOfpages;
    const pages: ReactElement[] = [];
    if (orvVergelijker.length === 0) return <></>;
    for (let i = 1; i <= counter; i++) {
      const lastPage = amountOfpages === i;
      const until = (): number => {
        if (i === 1) {
          return amountOfLinesFirstPage;
        }

        return amountOfLinesPerPage;
      };

      const sliced = orvVergelijker.splice(0, until());
      const amountOfLinesOnpage = sliced.length;
      const hasSpaceForVerzekeringTable = (): boolean => {
        const verzekeringTableRows = 7;
        const check = amountOfLinesOnpage + verzekeringTableRows;
        if (i === 1 && check >= amountOfLinesFirstPage) return false;
        if (i !== 1 && check >= amountOfLinesPerPage) return false;
        return true;
      };

      if (lastPage) {
        verzekeringTableFitsOnLastPage = hasSpaceForVerzekeringTable();
      }

      const page = renderPage(false, sliced, i, i === 1, verzekeringTableFitsOnLastPage);
      pages.push(page);

      if (lastPage && !verzekeringTableFitsOnLastPage) {
        amountOfpages = amountOfpages + 1;
        const verzekeringIndex = i + 1;
        const verzekeringTable = renderPage(true, sliced, verzekeringIndex);
        pages.push(verzekeringTable);
      }
    }

    return <>{pages.map(page => page)}</>;
  };

  return (
    <>
      {renderPages()}
      <Button onClick={async () => await printPDF(amountOfpages, "download")} data-testid="download_button">
        <Icon name="download" alt="download" multiColor={true} />
      </Button>
    </>
  );
};
