import {
  LabeledBevestigingInput,
  LabeledCurrencyInput,
  LabeledDateInput,
  LabeledNumberInput,
  LabeledPercentageInput,
  LabeledRadioInput,
  LabeledSelectInput
} from "adviesbox-shared";
import { connect, FormikContextType } from "formik";
import React, { ReactElement } from "react";
import { OrvsState } from "../../orv/infra/orv-schema";
import {
  UitkeringBijOverlijdenSoort,
  DoelVerzekeringOptions,
  SoortOrvProductOptions,
  Indexatiesoort,
  Verzekeringsmotief
} from "../../.generated/forms/formstypes";
import { KlantnaamMetKinderenType } from "../../shared/generic-parts/klantnaam/schema";
import { AanvragerKeuze } from "../../shared/types";
import { getNaam } from "../../shared/utils/helpers";
import { DekkingKenmerken } from "../infra/product-kenmerken-types";
import { getProductenOverzichtTextResources } from "../infra/producten-overzicht-resources";
import { SituatieSoort } from "../infra/producten-overzicht-types";

type DekkingProps = {
  selected: number;
  kenmerken: DekkingKenmerken;
  situatie?: SituatieSoort | null;
};

const Dekking = ({
  selected,
  kenmerken,
  situatie,
  formik: {
    values: { producten, aanvrager1, aanvrager2 },
    setStatus
  }
}: DekkingProps & {
  formik: FormikContextType<OrvsState>;
}): ReactElement => {
  const aanvragerEen = aanvrager1 as KlantnaamMetKinderenType | null;
  const aanvragerTwee = aanvrager2 as KlantnaamMetKinderenType | null;
  const aanvragerEenNaam = getNaam(aanvragerEen, "Aanvrager");
  const aanvragerTweeNaam = getNaam(aanvragerTwee, "Partner");

  const selectedProduct = producten[selected];
  const isVerzekerdBedrag = selectedProduct.dekking.basisdekking === Indexatiesoort.VerzekerdBedrag;
  const soortProduct = selectedProduct.product.soortProduct;
  const isAnwHiaatVerzekering = soortProduct === SoortOrvProductOptions.AnwHiaatVerzekering;
  const UitkeringBijVertonen =
    selectedProduct.dekking.basisdekking === Indexatiesoort.VerzekerdBedrag &&
    ((selectedProduct.verzekerden.verzekerden === AanvragerKeuze.Aanvrager1 && aanvrager1?.heeftKinderen) ||
      (selectedProduct.verzekerden.verzekerden === AanvragerKeuze.Aanvrager2 && aanvrager2?.heeftKinderen));
  const Verzekeringsmotieven = [
    {
      value: Verzekeringsmotief.Hypotheek,
      label: "Hypotheek"
    },
    {
      value: Verzekeringsmotief.GerichteLijfrente,
      label: "Gerichte lijfrente"
    },
    {
      value: Verzekeringsmotief.NabestaandenVoorziening,
      label: "Nabestaandenvoorziening"
    },
    {
      value: Verzekeringsmotief.FinancieringOfKredietVerstrekking,
      label: "Financiering/Kredietverstrekking"
    },
    {
      value: Verzekeringsmotief.SuccessieAfdekking,
      label: "Successieafdekking"
    },
    {
      value: Verzekeringsmotief.CompagnonVerzekering,
      label: "Compagnonverzekering"
    },
    {
      value: Verzekeringsmotief.KeymanVerzekering,
      label: "Keymanverzekering"
    }
  ];

  const DoelVerzekering = [
    {
      value: DoelVerzekeringOptions.Hypotheek,
      label: "Hypotheek"
    },
    {
      value: DoelVerzekeringOptions.VerliesVanInkomen,
      label: "Verlies van inkomen"
    },
    {
      value: DoelVerzekeringOptions.Compagnon,
      label: "Compagnon"
    },
    {
      value: DoelVerzekeringOptions.KeymanKeywoman,
      label: "Keyman/keywoman"
    },
    {
      value: DoelVerzekeringOptions.AfdekkingErfbelasting,
      label: "Afdekking erfbelasting"
    },
    {
      value: DoelVerzekeringOptions.FiscaleClaimBijOverlijden,
      label: "Fiscale claim bij overlijden"
    },
    {
      value: DoelVerzekeringOptions.FinancieringKredietverstrekking,
      label: "Financiering kredietverstrekking"
    },
    {
      value: DoelVerzekeringOptions.LijfrenteAanvullendPensioenopbouw,
      label: "Lijfrente/aanvullende pensioenopbouw"
    }
  ];

  const DekkingOptions = [
    {
      value: Indexatiesoort.Uitkering,
      label: "Aanvulling tot wettelijk maximum"
    },
    {
      value: Indexatiesoort.VerzekerdBedrag,
      label: "Vast verzekerd bedrag"
    }
  ];

  return (
    <>
      {kenmerken.verzekeringsmotiefTonen && (
        <LabeledSelectInput
          caption="Verzekeringsmotief"
          name={`producten[${selected}].dekking.verzekeringsmotief`}
          readonly={!kenmerken.verzekeringsmotiefEnabled}
          options={Verzekeringsmotieven}
          defaultValue={Verzekeringsmotief.Hypotheek}
        />
      )}
      {kenmerken.doelVerzekeringTonen && (
        <LabeledSelectInput
          caption="Doel verzekering"
          name={`producten[${selected}].dekking.doelVerzekering`}
          readOnly={!kenmerken.doelVerzekeringEnabled}
          options={DoelVerzekering}
          defaultValue={DoelVerzekeringOptions.Hypotheek}
        />
      )}
      {kenmerken.reservewaardeTonen && (
        <LabeledBevestigingInput
          caption="Reservewaarde"
          name={`producten[${selected}].dekking.reservewaarde`}
          tooltip={
            kenmerken.hintReservewaardeTonen ? getProductenOverzichtTextResources("dekkingReservewaarde") : undefined
          }
        />
      )}
      {isAnwHiaatVerzekering && (
        <>
          <LabeledRadioInput
            caption="Dekking"
            name={`producten[${selected}].dekking.basisdekking`}
            options={DekkingOptions}
          />
          {isVerzekerdBedrag && (
            <LabeledCurrencyInput
              caption="Verzekerd maandbedrag"
              name={`producten[${selected}].dekking.verzekerdMaandbedrag`}
            />
          )}
          <LabeledDateInput
            caption="Einddatum"
            name={`producten[${selected}].dekking.einddatum`}
            readonly={!(isAnwHiaatVerzekering && isVerzekerdBedrag)}
          />
          <LabeledNumberInput
            caption="Eindleeftijd uitkering"
            name={`producten[${selected}].dekking.eindleeftijd`}
            readonly={!(isAnwHiaatVerzekering && isVerzekerdBedrag)}
            appendChildren={<span className="px-1">Jaar</span>}
          />
        </>
      )}

      {(selectedProduct.verzekerden.verzekerden === AanvragerKeuze.Aanvrager1 ||
        selectedProduct.verzekerden.verzekerden === AanvragerKeuze.Aanvrager2 ||
        selectedProduct.verzekerden.verzekerden === AanvragerKeuze.Beiden) &&
        (!kenmerken.tweedeVerzekerdKapitaalTonen ||
          !kenmerken.tweedeDekkingDaaltTotTonen ||
          !kenmerken.tweedeAnnuiteitsperscentageTonen) && (
          <>
            {!isAnwHiaatVerzekering && kenmerken.verzekerdKapitaalTonen && !kenmerken.tweedeVerzekerdKapitaalTonen && (
              <LabeledCurrencyInput
                caption="Verzekerd kapitaal"
                name={`producten[${selected}].dekking.verzekerdKapitaalAanvrager1`}
                verplicht={situatie === "huidig" ? false : true}
              />
            )}
            {kenmerken.annuiteitspercentageTonen && !kenmerken.tweedeAnnuiteitsperscentageTonen && (
              <LabeledPercentageInput
                caption="Annuiteitspercentage"
                name={`producten[${selected}].dekking.annuiteitspercentage1`}
                readonly={!kenmerken.annuiteitspercentageEnabled}
                verplicht={true}
                decimalen={2}
              />
            )}
            {kenmerken.dekkingDaaltTotTonen && !kenmerken.tweedeDekkingDaaltTotTonen && (
              <LabeledCurrencyInput
                caption="Dekking daalt tot"
                name={`producten[${selected}].dekking.dekkingDaaltTotAanvrager1`}
              />
            )}
            {kenmerken.dekkingDaaltTotTonen &&
              !kenmerken.tweedeDekkingDaaltTotTonen &&
              selectedProduct.partijCode === "XX" && (
                <LabeledNumberInput
                  caption="Duur daling in jaren"
                  name={`producten[${selected}].dekking.duurDalingInJaren1`}
                />
              )}
          </>
        )}

      {(selectedProduct.verzekerden.verzekerden === AanvragerKeuze.Aanvrager1 ||
        selectedProduct.verzekerden.verzekerden === AanvragerKeuze.Aanvrager2 ||
        selectedProduct.verzekerden.verzekerden === AanvragerKeuze.Beiden) &&
        (kenmerken.tweedeVerzekerdKapitaalTonen ||
          kenmerken.tweedeDekkingDaaltTotTonen ||
          kenmerken.tweedeAnnuiteitsperscentageTonen) && (
          <>
            <h2 className="ml-3 mt-2">
              {selectedProduct.verzekerden.verzekerden === AanvragerKeuze.Aanvrager2
                ? aanvragerTweeNaam
                : aanvragerEenNaam}
            </h2>
            {kenmerken.verzekerdKapitaalTonen && kenmerken.tweedeVerzekerdKapitaalTonen && (
              <LabeledCurrencyInput
                caption="Verzekerd kapitaal"
                name={`producten[${selected}].dekking.verzekerdKapitaalAanvrager1`}
                verplicht={situatie === "huidig" ? false : true}
              />
            )}
            {kenmerken.annuiteitspercentageTonen && kenmerken.tweedeAnnuiteitsperscentageTonen && (
              <LabeledPercentageInput
                caption="Annuiteitspercentage"
                name={`producten[${selected}].dekking.annuiteitspercentage1`}
                verplicht={true}
                readonly={!kenmerken.annuiteitspercentageEnabled}
                decimalen={2}
              />
            )}
            {kenmerken.dekkingDaaltTotTonen && kenmerken.tweedeDekkingDaaltTotTonen && (
              <LabeledCurrencyInput
                caption="Dekking daalt tot"
                name={`producten[${selected}].dekking.dekkingDaaltTotAanvrager1`}
              />
            )}
            {kenmerken.dekkingDaaltTotTonen &&
              kenmerken.tweedeDekkingDaaltTotTonen &&
              selectedProduct.partijCode === "XX" && (
                <LabeledNumberInput
                  caption="Duur daling in jaren"
                  name={`producten[${selected}].dekking.duurDalingInJaren1`}
                />
              )}
          </>
        )}
      {selectedProduct.verzekerden.verzekerden === AanvragerKeuze.Beiden &&
        (kenmerken.tweedeVerzekerdKapitaalTonen ||
          kenmerken.tweedeDekkingDaaltTotTonen ||
          kenmerken.tweedeAnnuiteitsperscentageTonen) && (
          <>
            {<h2 className="ml-3 mt-2">{aanvragerTweeNaam}</h2>}
            {kenmerken.verzekerdKapitaalTonen && kenmerken.tweedeVerzekerdKapitaalTonen && (
              <LabeledCurrencyInput
                caption="Verzekerd kapitaal"
                name={`producten[${selected}].dekking.verzekerdKapitaalAanvrager2`}
                verplicht={true}
              />
            )}
            {kenmerken.annuiteitspercentageTonen && kenmerken.tweedeAnnuiteitsperscentageTonen && (
              <LabeledPercentageInput
                caption="Annuiteitspercentage"
                name={`producten[${selected}].dekking.annuiteitspercentage2`}
                readonly={!kenmerken.annuiteitspercentageEnabled}
                decimalen={2}
                verplicht={true}
              />
            )}
            {kenmerken.dekkingDaaltTotTonen && kenmerken.tweedeDekkingDaaltTotTonen && (
              <LabeledCurrencyInput
                caption="Dekking daalt tot"
                name={`producten[${selected}].dekking.dekkingDaaltTotAanvrager2`}
              />
            )}
            {kenmerken.dekkingDaaltTotTonen && selectedProduct.partijCode === "XX" && (
              <LabeledNumberInput
                caption="Duur daling in jaren"
                name={`producten[${selected}].dekking.duurDalingInJaren2`}
              />
            )}
          </>
        )}
      {isAnwHiaatVerzekering && UitkeringBijVertonen && (
        <LabeledRadioInput
          caption="Uitkering bij"
          name={`producten[${selected}].dekking.uitkeringBij`}
          options={[
            {
              label: "Overlijden",
              value: UitkeringBijOverlijdenSoort.MaandNaOverlijden
            },
            {
              label: "Jongste kind 18 jaar",
              value: UitkeringBijOverlijdenSoort.JongsteKind18
            }
          ]}
        />
      )}
    </>
  );
};

export default connect<DekkingProps, OrvsState>(Dekking);
