import { LabeledBevestigingInput, LabeledTextInput } from "adviesbox-shared";
import { connect, FormikContextType } from "formik";
import React, { ReactElement } from "react";
import { TaxatieKenmerkenType, TaxatieSchermType } from "../infra/taxatie-scherm-schema";

const AanvullendeInformatie = ({
  formik: {
    values: { taxatieKenmerken }
  }
}: {
  formik: FormikContextType<TaxatieSchermType>;
}): ReactElement => {
  const kenmerken = taxatieKenmerken as TaxatieKenmerkenType;
  const opdrachtnummerTonen = kenmerken?.OpdrachtnummerTonen;
  const externReferentienummerTonen = kenmerken?.ExternReferentienummerTonen;
  const naamEigenaarTonen = kenmerken?.EigenaarNaamTonen;
  const telefoonnummerTonen = kenmerken?.EigenaarTelefoonTonen;
  const spoedTonen = kenmerken?.SpoedTonen;
  const taxateurNummerTonen = kenmerken?.TaxateurnummerTonen;
  const betalingViaNotarisTonen = kenmerken?.BetalingViaNotarisTonen;

  return (
    <>
      <LabeledTextInput
        name="aanvullendeInformatie.opmerkingen"
        caption="Opmerkingen"
        data-testid={`aanvullendeInformatie-opmerkingen`}
        textarea={3}
      />
      {opdrachtnummerTonen && (
        <LabeledTextInput name="aanvullendeInformatie.opdrachtnummer" readonly caption="Opdrachtnummer" />
      )}
      {externReferentienummerTonen && (
        <LabeledTextInput name="aanvullendeInformatie.externReferentienummer" caption="Extern referentienummer" />
      )}
      {spoedTonen && <LabeledBevestigingInput caption="Spoed" name="aanvullendeInformatie.spoed" />}
      {naamEigenaarTonen && <LabeledTextInput name="aanvullendeInformatie.naamEigenaar" caption="Eigenaar naam" />}
      {telefoonnummerTonen && <LabeledTextInput caption="Telefoonnummer" name="aanvullendeInformatie.telefoonnummer" />}
      {taxateurNummerTonen && (
        <LabeledTextInput name="aanvullendeInformatie.taxateurnummer" readonly caption="Taxateurnummer" />
      )}
      {betalingViaNotarisTonen && (
        <LabeledBevestigingInput caption="Betaling via notaris" name="aanvullendeInformatie.betalingViaNotaris" />
      )}
    </>
  );
};

export default connect<{}, TaxatieSchermType>(AanvullendeInformatie);
