/* eslint-disable react/prop-types */
import React, { Suspense } from "react";
import { DataDisplay, Loading } from "../data-display/data-display";
import {
  DashboardOutput,
  DashboardTekort,
  KlantnaamReadOnly,
  DashboardScenarioPensioen
} from "../../../.generated/forms/formstypes";
import { Tekort } from "../../components/tekort/tekort";
import { uitgangspuntenSchema, tekortSchema } from "../../infra/dashboard-schema";
import { klantnaamSchema } from "../../../shared/generic-parts/klantnaam/schema";
import { LoadingSpinner } from "adviesbox-shared";

interface PensioenResultaatProps {
  onClick: () => void;
  situatie: string;
}

const getAanvrager = (data: DashboardOutput, partner = false): KlantnaamReadOnly => {
  const dash = data.dashboards?.[Object.keys(data.dashboards)[0]];
  return (partner ? (dash?.aanvrager2 as any) : (dash?.aanvrager1 as any)) ?? klantnaamSchema.default();
};

const getPensioen = (data: DashboardOutput): DashboardScenarioPensioen | null => {
  const dash = data.dashboards?.[Object.keys(data.dashboards)[0]];
  return dash?.pensioen ?? null;
};

const getTekort = (data: DashboardOutput): DashboardTekort => {
  return getPensioen(data)?.tekort ?? tekortSchema.default();
};

export const PensioenResultaat: React.FC<PensioenResultaatProps> = ({ onClick, situatie }) => {
  return (
    <Suspense fallback={<Loading />}>
      <button
        type="button"
        className="new-dashboard__entry-btn"
        data-testid="button-testid"
        onClick={onClick}
      >
        <h2 className="mb-2">PENSIOEN</h2>
        <DataDisplay
          endpoint="Pensioen?berekenTekort=true"
          autostart={false}
          isVoorstel={situatie === "voorstel"}
          loader={() => <LoadingSpinner size="S" />}
          render={(data: DashboardOutput) => {
            if (data?.dashboards?.[Object.keys(data.dashboards)[0]].pensioen === null) return <></>;
            if (data?.dashboards?.[Object.keys(data.dashboards)[0]].pensioen !== null) {
              return (
                <div>
                  <Tekort
                    header="Pensioen"
                    tekort={getTekort(data)}
                    uitgangspunten={getPensioen(data)?.uitgangspunten ?? uitgangspuntenSchema.default()}
                    aanvrager={getAanvrager(data) as any}
                    partner={getAanvrager(data, true) as any}
                  />
                </div>
              );
            }
          }}
        />
      </button>
    </Suspense>
  );
};
