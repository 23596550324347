import {
  Krediet as KredietenDlEntry,
  KredietOutput as KredietenDlOutput,
  KredietProduct,
  KredietLeninggegevens
} from "../../.generated/forms/formstypes";
import { KlantnaamType } from "../../shared/generic-parts/klantnaam/schema";
import { mapKlantnaamDl2Ui } from "../../shared/generic-parts/klantnaam/map-dl-2-ui";
import { UiName, FieldMap, mapBerekenInput, AanvragerKeuze } from "../../shared/types";
import { mapProductDl2Ui } from "../../producten-overzicht/infra/product-mapper-dl-2-ui";
import { prefixWithZero } from "../../shared/utils/helpers";
import { target2field } from "../../shared/utils/target-to-field";
import { createMapToUi } from "../../shared/utils/create-map-to-ui";
import { KredietenType } from "./kredieten-types";
import {
  kredietenSchema,
  kredietSchema,
  geldnemersSchema,
  leningGegevensSchema,
  fiscaleGegevensSchema
} from "./kredieten-schema";
import { MaandlastKredietInput, PrivateLeaseInput } from "../../.generated/krediet/krediettypes";
import { mapStringToLocalDate } from "adviesbox-shared";

type AanvragerContext = {
  aanvrager1: KlantnaamType | null;
  aanvrager2: KlantnaamType | null;
};

const mapGeldnemersDl2Ui = createMapToUi(geldnemersSchema)
  .with<AanvragerContext>()
  .from<KredietProduct>({
    geldnemers: (v, context) => {
      let geldnemers = AanvragerKeuze.Aanvrager1;

      if (v.geldnemerKlantIds.length > 1) {
        geldnemers = AanvragerKeuze.Beiden;
      } else if (v.geldnemerKlantIds[0] === context.aanvrager1?.klantId) {
        geldnemers = AanvragerKeuze.Aanvrager1;
      } else if (v.geldnemerKlantIds[0] === context.aanvrager2?.klantId) {
        geldnemers = AanvragerKeuze.Aanvrager2;
      }

      return geldnemers;
    }
  });

const mapLeningGegevensDl2Ui = createMapToUi(leningGegevensSchema).from<KredietLeninggegevens>({
  hoofdsom: v => mapBerekenInput(v.hoofdsomBedrag, v.hoofdsomBedragOvernemen, v.berekendHoofdsomBedrag),
  garantie: v => v.garantie,
  restantHoofdsom: v => v.restantHoofdsomBedrag,
  opgaveDatum: v => mapStringToLocalDate(v.opgaveDatum),
  slottermijn: v => v.slottermijnBedrag,
  aflossingPercentage: v => v.aflossingPercentage,
  rentePercentage: v => v.rentePercentage,
  maandlast: v => mapBerekenInput(v.maandlastBedrag, v.maandlastOvernemen, v.maandlastBedrag),
  bestedingsdoel: v => prefixWithZero(v.bestedingsdoelCode) ?? "",
  bestedingsdoelOmschrijving: v => v.bestedingsdoelOmschrijving
});

const berekenDeelBox1Percentage = (leninggegevens: KredietLeninggegevens): number => {
  const restantHoofdsomBedrag = leninggegevens.restantHoofdsomBedrag ?? 0;
  return restantHoofdsomBedrag ? ((leninggegevens.deelBox1Bedrag ?? 0) / restantHoofdsomBedrag) * 100 : 100;
};

const mapFiscaleGegevensDl2Ui = createMapToUi(fiscaleGegevensSchema).from<KredietLeninggegevens>({
  deelBox1Bedrag: v => v.deelBox1Bedrag,
  deelBox1Percentage: v => berekenDeelBox1Percentage(v),
  deelBox3Bedrag: v => (v.restantHoofdsomBedrag ?? 0) - (v.deelBox1Bedrag ?? 0),
  deelBox3Percentage: v => 100 - berekenDeelBox1Percentage(v),
  einddatumRenteaftrek: v => mapStringToLocalDate(v.renteaftrekEinddatum)
});

const mapKredietDl2Ui = createMapToUi(kredietSchema)
  .with<AanvragerContext>()
  .from<KredietProduct>({
    productId: v => v.productId,
    partijCode: v => v.polis.maatschappijCode ?? "",
    productCode: v => prefixWithZero(v.polis.productcode),
    soortProduct: v => v.soortProduct,
    product: v => {
      const result = mapProductDl2Ui(v.polis);
      result.doorlopend = v.doorlopend ?? false;
      result.wijzigingenInDoorlopendProductOvernemen = v.wijzigingenInDoorlopendProductOvernemen;
      return result;
    },
    geldnemers: (v, context) => mapGeldnemersDl2Ui(context)(v),
    leningGegevens: v => mapLeningGegevensDl2Ui(v.leninggegevens),
    fiscaleGegevens: v => mapFiscaleGegevensDl2Ui(v.leninggegevens)
  });

function dl2ui(values: KredietenDlEntry): KredietenType {
  const aanvrager1 = mapKlantnaamDl2Ui(values.aanvrager1);
  const aanvrager2 = mapKlantnaamDl2Ui(values.aanvrager2);

  const kredieten: KredietenType = {
    producten: values.producten.map(p => mapKredietDl2Ui({ aanvrager1, aanvrager2 })(p)),
    aanvrager1,
    aanvrager2,
    ingangsdatumVoorstel: mapStringToLocalDate(values.ingangsdatumVoorstel)
  };

  return kredietenSchema.cast(kredieten);
}

export function mapKredietenDlToUi(kredietenId: string, data: KredietenDlOutput): KredietenType | null {
  const kredieten = data && data.kredieten ? data.kredieten[kredietenId] : null;

  if (!kredieten) {
    return null;
  }

  return dl2ui(kredieten);
}

export function mapDlTargetToKredietenUiField(target: string): UiName | null {
  const map: FieldMap<KredietenDlEntry> = {
    producten: {
      leninggegevens: {
        opgaveDatum: { field: "producten[i].leningGegevens.opgaveDatum", label: "Leninggegevens opgavedatum" }
      }
    }
  };

  const regExp = /Producten\[(\d+)\].(\w+).(\w+)/;
  const match = regExp.exec(target);

  if (match) {
    const index = +match[1];
    const kaart = match[2];
    const veld = match[3];

    switch (kaart) {
      case "Polis":
        switch (veld) {
          case "Ingangsdatum":
            return {
              field: `producten[${index}].product.ingangsdatum`,
              label: `Krediet ${index + 1}: Product`
            };
          case "LooptijdInMaanden":
            return {
              field: `producten[${index}].product.looptijd.jaren`,
              label: `Krediet ${index + 1}: Product`
            };
        }
        break;

      case "Leninggegevens":
        switch (veld) {
          case "RenteaftrekEinddatum":
            return {
              field: `producten[${index}].fiscaleGegevens.einddatumRenteaftrek`,
              label: `Krediet ${index + 1}: Fiscale gegevens`
            };
          case "BestedingsdoelCode":
            return {
              field: `producten[${index}].leningGegevens.bestedingsdoel`,
              label: `Krediet ${index + 1}: Bestedingsdoel`
            };
          case "HoofdsomBedrag":
            return {
              field: `producten[${index}].leningGegevens.hoofdsom`,
              label: `Krediet ${index + 1}: Hoofdsom`
            };
          case "HoofdsomBedragOvernemen":
            return {
              field: `producten[${index}].leningGegevens.hoofdsom`,
              label: `Krediet ${index + 1}: Hoofdsom`
            };
        }
        break;
    }
  }

  return target2field(map, target);
}

export const mapDlMaandlastTargetToKredietenUiField = (selected: number) => (target: string): UiName | null => {
  const map: FieldMap<MaandlastKredietInput> = {
    looptijdInMaanden: { field: `producten[${selected}].product.looptijd.jaren`, label: "Looptijd" }
  };

  return target2field(map, target);
};

export const mapDlPrivateLeaseTargetToKredietenUiField = (selected: number) => (target: string): UiName | null => {
  const map: FieldMap<PrivateLeaseInput> = {
    looptijdInMaanden: { field: `producten[${selected}].product.looptijd.jaren`, label: "Looptijd" }
  };

  return target2field(map, target);
};
