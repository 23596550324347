import { KredietKenmerken } from "../product-kenmerken-types";
import {
  productCodeDoorlopendKrediet,
  productCodePrivateLease,
  productCodeRestschuldlening,
  productCodeAflopendKrediet
} from "../../../kredieten/infra/kredieten-types";

// TODO MdB: Verwijderen als Krediet kenmerken beschikbaar zijn.
export const getKredietKenmerken = (productCode: string): KredietKenmerken => ({
  product: {
    productnaamTonen: true,
    productnaamEnabled: true,
    ingangsdatumTonen: true,
    einddatumTonen: true,
    einddatumEnabled: false,
    looptijdTonen: true,
    looptijdJaarEnabled: productCode !== productCodeDoorlopendKrediet,
    looptijdMaandEnabled: productCode !== productCodeDoorlopendKrediet,
    uwBemiddelingTonen: true,
    maatschappijkeuzeEnabled: true,
    logoTonen: false,
    productoptiesTonen: false,
    productnummerTonen: true
  },
  leningGegevens: {
    berekenHoofdsomTonen: productCode === productCodePrivateLease,
    garantieTonen: productCode === productCodeRestschuldlening,
    restantHoofdsomTonen: productCode !== productCodePrivateLease,
    slottermijnTonen: productCode === productCodeAflopendKrediet,
    aflossingPercentageTonen: productCode === productCodeDoorlopendKrediet,
    berekenMaandlastTonen: productCode !== productCodePrivateLease,
    bestedingsdoelEnabled: productCode !== productCodePrivateLease
  }
});
