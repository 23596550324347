import { LocalDate } from "@js-joda/core";

import { SoortAflosproductOptions } from "../../.generated/forms/formstypes";

import { KredietType } from "../../shared/generic-parts/krediet/schema";
import { LeningdeelType } from "../../shared/generic-parts/leningdeel/schema";
import { AflosproductType } from "../infra/producten-overzicht-types";

export const bepaalDefaultAflosproducten = (values: {
  kredieten: KredietType[];
  leningdelen: LeningdeelType[];
}): AflosproductType[] => {
  return values.leningdelen
    .map((leningdeel, index) => ({
      soortAflosproduct: SoortAflosproductOptions.Leningdeel,
      index: index,
      productId: leningdeel.leningdeelId,
      omschrijving: leningdeel.productnaam ?? "Leningdeel",
      aflossen: true,
      prioriteit: 0,
      ingangsdatum: leningdeel.aanvangsdatum as LocalDate | null,
      looptijd: leningdeel.looptijd as number | null,
      doorlopend: false,
      maatschappijCode: leningdeel.maatschappijCode,
      leningdeelBedrag: null,
      volgnummer: ++index
    }))
    .concat(
      values.kredieten.map((krediet, index) => ({
        soortAflosproduct: SoortAflosproductOptions.Krediet,
        index: index,
        productId: krediet.kredietId,
        omschrijving: krediet.productnaam ?? "Krediet",
        aflossen: false,
        prioriteit: 0,
        ingangsdatum: null as LocalDate | null,
        looptijd: null as number | null,
        doorlopend: false,
        maatschappijCode: null,
        leningdeelBedrag: null,
        volgnummer: ++index
      }))
    )
    .map((aflosproduct, index) => ({ ...aflosproduct, prioriteit: index, leningdeelNummer: ++index }));
};
