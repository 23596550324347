import { LabeledSelectInput, SettingsType, useRequestInit, FormFirstFocus, PlatformFoutenSamenvatting } from "adviesbox-shared";
import { LabelValuePairs, RouteParams } from "adviesbox-shared/utils/types";
import { FormikProps } from "formik";
import React, { ReactElement } from "react";
import { AdviesdossierStatusOptions } from "../../../../.generated/forms/formstypes";
import { useAdviesboxData } from "../../../hooks/use-adviesbox-data";
import AdviesBoxFormik from "../../../utils/adviesbox-formik";
import { InvalidAdviesboxResultErrorPage } from "../../fetch-error-page/fetch-error-page";
import Modal from "../../modal/Modal";
import {
  dossierStatusSchema,
  DossierStatusType,
  mapDossierStatusDl2Ui,
  mapDossierStatusUi2Dl
} from "../schema-and-mappers";
import { dossierStatusModalPOST } from "./dossier-status-modal-api";
import classes from "../dossier-balk.module.scss";

type DossierStatusModalProps = {
  onSave?: () => void;
  closeModal?: () => void;
};
export const createLabelValuePairs = (): LabelValuePairs => {
  return Object.keys(AdviesdossierStatusOptions)
    .filter(v => v !== AdviesdossierStatusOptions.Nieuw)
    .map(v => {
      return {
        label: v === AdviesdossierStatusOptions.InBehandeling ? "In behandeling" : v,
        value: v
      };
    });
};
export const defaultInBehandelingOptie = (status: AdviesdossierStatusOptions | null): AdviesdossierStatusOptions => {
  if (!status || status === AdviesdossierStatusOptions.Nieuw) return AdviesdossierStatusOptions.InBehandeling;
  return status;
};

const DossierStatusModal = ({ onSave, closeModal }: DossierStatusModalProps): ReactElement => {
  const { settings, params, user } = useRequestInit<{ vestiging: string; adviesdossier: string }>();
  const statusUrl = (s: SettingsType, p: RouteParams): string =>
    `${s.klantdossiersFormsOrigin}/Adviesdossiers/${p.adviesdossier}/Status`;

  const { data, loading, error } = useAdviesboxData(
    statusUrl,
    p => p.adviesdossier,
    mapDossierStatusDl2Ui,
    mapDossierStatusUi2Dl,
    /*istanbul ignore next */ () => null
  );

  if (loading) {
    return <div className="loader"></div>;
  }

  if (!data || error) {
    return <InvalidAdviesboxResultErrorPage />;
  }

  return (
    <AdviesBoxFormik<DossierStatusType>
      initialValues={{ ...data, status: defaultInBehandelingOptie(data.status) }}
      validationSchema={dossierStatusSchema}
      onSave={onSave}
      closeModal={closeModal}
      render={({ setFieldValue, values, submitForm }: FormikProps<DossierStatusType>): ReactElement => (
        <>
          <Modal
            title="Status dossier"
            body={
              <>
                <PlatformFoutenSamenvatting className={classes.platform_errors} />
                <FormFirstFocus>
                  <LabeledSelectInput
                    key="Dossier-Status"
                    caption="Status"
                    name="status"
                    onChange={e => {
                      setFieldValue("status", e.target.value);
                    }}
                    options={createLabelValuePairs()}
                    readonly={createLabelValuePairs().length === 1}
                  />
                </FormFirstFocus>
              </>
            }
            onSubmitClick={async () => {
              const res = await dossierStatusModalPOST(
                settings,
                user,
                values,
                params.vestiging,
                params.adviesdossier,
                setFieldValue
              );
              if (res) await submitForm();
            }}
            onCancelClick={closeModal}
            saveButtonTekst="Bevestigen"
          />
        </>
      )}
    />
  );
};

export default DossierStatusModal;
