import { Slider as coreSlider, withStyles } from "@material-ui/core";
import React, { ReactElement } from "react";
import { hasValue } from "../../../shared/utils/helpers";

type LabelValuePairs = {
  value: number;
  label?: string;
}[];

export type SliderProps = {
  name: string;
  caption: string;
  marks: LabelValuePairs;
  marksMetLabel?: boolean;
  value?: number;
  defaultValue?: number;
  min?: number;
  max?: number;
  onChange?: (v: number) => void;
};

export const LabeledSlider = ({
  name,
  caption,
  marks,
  onChange,
  value,
  defaultValue = 0,
  min = 0,
  max = 3,
  marksMetLabel = false,
  ...inputProps
}: SliderProps): ReactElement => {
  if (!hasValue(value)) value = defaultValue;
  if (value > max) value = max;
  if (value < min) value = min;
  const frameClasses = "d-flex flex-row";
  return (
    <div key={name} style={{ minWidth: 450 }} className={marksMetLabel ? frameClasses + "mt-4" : frameClasses}>
      <div className={"col-5 mt-2 px-1"}>
        <label htmlFor={name}>
          <span key={`${name}-caption`}>{caption}</span>
        </label>
      </div>
      <div className={"col-6 mt-2 mr-2"}>
        <Slider
          {...inputProps}
          value={value}
          defaultValue={defaultValue}
          id={name}
          data-testid={`${name}-test-id`}
          key={`${name}-slider`}
          min={min}
          max={max}
          marks={marks}
          aria-labelledby="discrete-slider"
          onChange={(_: any, value: any): void => {
            onChange && onChange(value);
          }}
        />
      </div>
    </div>
  );
};

/* istanbul ignore next */
if (process.env.NODE_ENV !== "production") LabeledSlider.displayName = "LabeledSlider";

const shadow = "0 2px 1px rgba(0,0,0,0.1)";
const Slider = withStyles({
  root: {
    color: "#213131",
    height: 5
  },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: "#2e3131",

    marginTop: -7.5,
    marginLeft: -10,
    "&:hover, &$active": {
      boxShadow: shadow,
      backgroundColor: "#737373",
      height: 30,
      width: 30,
      marginTop: -12,
      marginLeft: -15
    },
    "&:focus": {
      boxShadow: shadow
    }
  },
  active: {},

  markActive: {
    backgroundColor: "#fff",
    opacity: "unset"
  },
  track: {
    height: 5,
    borderRadius: 4
  },
  rail: {
    height: 5,
    borderRadius: 4,
    opacity: 0.5,
    backgroundColor: "#9ca4ab"
  },
  mark: {
    backgroundColor: "#fff",
    height: 5,
    width: 6,
    '&[data-index="0"], &[data-index="3"]': {
      height: 0,
      width: 0
    },
    borderRadius: 0
  },

  marked: {
    marginBottom: 0
  },
  markLabel: {
    fontSize: 10,
    color: "rgba(0, 0, 0, 0.8)",
    top: -15
  },
  markLabelActive: {
    color: "rgba(0, 0, 0, 0.8)"
  }
})(coreSlider);
