import { CheckBox } from "adviesbox-shared";
import { useFormikContext } from "formik";
import React, { ReactElement } from "react";
import { getNaam } from "../../../shared/utils/helpers";
import { DashboardState, NamePrefix } from "../../infra/dashboard-types";

type ScenarioVanProps = { beideTonen?: boolean; namePrefix: NamePrefix };

export const ScenarioVan = ({ beideTonen, namePrefix }: ScenarioVanProps): ReactElement => {
  const { values } = useFormikContext<DashboardState>();
  const naamAanvrager = getNaam(values.aanvrager1, "Aanvrager 1");
  const naamPartner = getNaam(values.aanvrager2, "Aanvrager 2");

  return (
    <>
      <CheckBox name={`${namePrefix}.scenarioVan.aanvrager1Geselecteerd`} caption={`${naamAanvrager}`} />
      <CheckBox name={`${namePrefix}.scenarioVan.aanvrager2Geselecteerd`} caption={`${naamPartner}`} />
      {beideTonen && <CheckBox name={`${namePrefix}.scenarioVan.beiden`} caption="Beiden" />}
    </>
  );
};
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production") ScenarioVan.displayName = "ScenarioVan";
