import { AuthContext, SettingsContext } from "adviesbox-shared";
import React, { ReactElement, ReactNode, useCallback, useContext, useRef, useState } from "react";
import {
  MedewerkerKlantdossierRechtenOrganisatie,
  MedewerkerKlantdossierRechtenVestigingen
} from "../../.generated/licenties/licentiestypes";
import { getAdviseurIds, logOpenDossier } from "./open-dossier-log-api";
import OpenDossierLogContext, { logger } from "./open-dossier-log-context";

const getOpenDossierLogContextValue = (
  setter: (
    adviesDossierId: string | null,
    vestigingId: string | null,
    klantDossierId: string | null
  ) => void | ((adviesDossierId: string | null, vestigingId: string | null, klantDossierId: string | null) => void),
  lezenEnSchrijvenRechtenVestigingen: MedewerkerKlantdossierRechtenVestigingen | null,
  lezenEnSchrijvenRechtenOrganisatie: MedewerkerKlantdossierRechtenOrganisatie | null,
  setLezenEnSchrijvenRechtenVestigingen: (id: MedewerkerKlantdossierRechtenVestigingen | null) => void,
  setLezenEnSchrijvenRechtenOrganisatie: (id: MedewerkerKlantdossierRechtenOrganisatie | null) => void,
  getAdviseurIds: (vestigingId: string | null) => void,
  adviseurIds: string[],
  adviseurIdsOphalen: boolean
): logger => ({
  setAdviesDossierId: setter,
  lezenEnSchrijvenRechtenVestigingen,
  lezenEnSchrijvenRechtenOrganisatie,
  setLezenEnSchrijvenRechtenVestigingen,
  setLezenEnSchrijvenRechtenOrganisatie,
  getAdviseurIds,
  adviseurIds,
  adviseurIdsOphalen
});

const OpenDossierLogProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const [
    lezenEnSchrijvenRechtenVestigingen,
    setLezenEnSchrijvenRechtenVestigingen
  ] = useState<MedewerkerKlantdossierRechtenVestigingen | null>(null);
  const [
    lezenEnSchrijvenRechtenOrganisatie,
    setLezenEnSchrijvenRechtenOrganisatie
  ] = useState<MedewerkerKlantdossierRechtenOrganisatie | null>(null);
  const prevAdviesDossierId = useRef<string | null>(null);
  const { user } = useContext(AuthContext);
  const settings = useContext(SettingsContext);
  const [adviseurIds, setAdviseurIds] = useState<string[]>([]);
  const [adviseurIdsOphalen, setadviseurIdsOphalen] = useState<boolean>(true);

  const logAdviesDossierId = useCallback(
    (adviesDossierId: string | null, klantDossierId: string | null, vestigingId: string | null) => {
      if (prevAdviesDossierId.current === adviesDossierId || !user || !klantDossierId || !vestigingId) return;
      prevAdviesDossierId.current = adviesDossierId;

      if (!adviesDossierId) return;
      /* eslint-disable-next-line @typescript-eslint/no-floating-promises */
      logOpenDossier(settings, adviesDossierId, klantDossierId, vestigingId, user);
    },
    [settings, user]
  );

  const getAdviseursIds = useCallback(
    async (vestigingId: string | null) => {
      if (!vestigingId || !user) return;
      else {
        /* eslint-disable-next-line @typescript-eslint/no-floating-promises */
        const res = await getAdviseurIds(settings, vestigingId, user);
        setAdviseurIds(res);
        setadviseurIdsOphalen(false);
      }
    },
    [settings, user]
  );

  const val = getOpenDossierLogContextValue(
    logAdviesDossierId,
    lezenEnSchrijvenRechtenVestigingen,
    lezenEnSchrijvenRechtenOrganisatie,
    setLezenEnSchrijvenRechtenVestigingen,
    setLezenEnSchrijvenRechtenOrganisatie,
    getAdviseursIds,
    adviseurIds,
    adviseurIdsOphalen
  );

  return <OpenDossierLogContext.Provider value={val}>{children}</OpenDossierLogContext.Provider>;
};

export default OpenDossierLogProvider;
