import { QuestionType, CardSpec, TitleType } from "../../infra/klantprofiel-schema";
import { KlantprofielVraagOptions } from "../../../.generated/forms/formstypes";

export const opbouwDoelvermogenQuestionSpecs: CardSpec[] = [
  {
    sections: [
      {
        title: TitleType.custom,
        customTitle: "Kennisniveau van onderwerpen die te maken hebben met het opbouwen van vermogen",
        subtitle: "Geef per onderwerp het kennisniveau aan door het bolletje te slepen.",
        questions: [
          {
            question: KlantprofielVraagOptions.KennisSparenBeleggenAlgemeen,
            type: QuestionType.slider
          },
          {
            question: KlantprofielVraagOptions.KennisOntwikkelingenFinancieleMarkt,
            type: QuestionType.slider
          },
          {
            question: KlantprofielVraagOptions.KennisBeleggingsfondsen,
            type: QuestionType.slider
          },
          {
            question: KlantprofielVraagOptions.KennisZelfHandelen,
            type: QuestionType.slider
          },
          {
            question: KlantprofielVraagOptions.KennisVermogensbeheer,
            type: QuestionType.slider
          },
          {
            question: KlantprofielVraagOptions.KennisSparenSpaarrekening,
            type: QuestionType.slider
          },
          {
            question: KlantprofielVraagOptions.KennisSparenVerzekering,
            type: QuestionType.slider
          }
        ]
      }
    ]
  },
  {
    questions: [
      {
        question: KlantprofielVraagOptions.BelangrijksteSpaarBeleggingsdoel,
        type: QuestionType.checkbox
      }
    ]
  },
  {
    questions: [
      {
        question: KlantprofielVraagOptions.BereidRisicoLagerDoelvermogen,
        type: QuestionType.radio
      }
    ]
  },
  {
    questions: [
      {
        question: KlantprofielVraagOptions.HoeDoelvermogenBereiken,
        type: QuestionType.checkbox
      }
    ]
  }
];
