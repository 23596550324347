/* istanbul ignore file */
import { createGetTextResources } from "../../shared/utils/text-resources-algemeen";

export const getKlantprofielResources = createGetTextResources({
  EenAntwoord: "Eén antwoord mogelijk",
  MeerdereAntwoorden: "Meerdere antwoorden mogelijk",
  AlleenCijfers: "Antwoord mag alleen cijfers bevatten",
  KennisNiveau: "Geef per onderwerp het kennisniveau aan door het bolletje te slepen.",
  KennisFinanciële: "Kennisniveau van algemene financiële onderwerpen.",
  EnkelBedrag: "Voer een bedrag in",
  ToelichtingVerplicht: "Vul een toelichting in.",
  AlleVragenBeantworden: "Het risicoprofiel wordt aangegeven wanneer alle vragen op het scherm zijn beantwoord.",
  MaandenValue: "Vul maanden tussen de 0 en 11 in."
});
