import { importState } from "./import-schema";
import { Operation } from "../../.generated/externekoppelingen/externekoppelingentypes";

export const mapImportUiToDl = (values: importState): Operation[] => {
  const patch: Operation[] = [];
  values.fieldMaps.forEach((fieldMap, index) => {
    fieldMap.labelValues.forEach((field, index) => {
      if (field.validationErrors.length > 0) {
        patch.push({
          value: field.value,
          path: field.jsonPath,
          op: "replace",
          from: null
        });
      }
    });
  });
  return patch;
};
