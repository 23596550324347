/* istanbul ignore file */
import React, { ReactElement } from "react";

export const WelkeUitgavenBeperkenExtra = (): ReactElement => (
  <>
    <h2>Hoeveel verwacht u de uitgaven te kunnen beperken?</h2>
    <span className="d-block font-italic px-1">{`Verwachte besparing in hele euro's`}</span>
  </>
);

if (process.env.NODE_ENV !== "production") WelkeUitgavenBeperkenExtra.displayName = "WelkeUitgavenBeperkenExtra";
