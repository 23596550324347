import {
  LabeledCurrencyInput,
  LabeledPercentageInput,
  LabeledRadioInput,
  LabeledSelectInput,
  TooltipWrap
} from "adviesbox-shared";
import { connect, FormikContextType } from "formik";
import React, { ReactElement } from "react";
import {
  SoortBerekeningOptions,
  SoortKapitaalverzekeringsrekeningOptions,
  BeleggersprofielOptions,
  SoortVermogenProductOptions,
  SoortBerekeningOptionsKv,
  SoortVermogensrekeningOptions
} from "../../.generated/forms/formstypes";
import { hasValue } from "../../shared/utils/helpers";
import { GeneriekeKapitaalopbouwKenmerkenKenmerken } from "../infra/product-kenmerken-types";
import { getProductTextResources } from "../infra/product-resources";
import { ProductenState } from "../infra/producten-overzicht-types";

const soortRekeningOptions = [
  { label: "Spaar", value: SoortKapitaalverzekeringsrekeningOptions.Spaar },
  { label: "Belegging", value: SoortKapitaalverzekeringsrekeningOptions.Belegging },
  { label: "Hybride", value: SoortKapitaalverzekeringsrekeningOptions.Hybride }
];

const soortKapitaalverzekeringOptions = [
  { label: "Spaar", value: SoortKapitaalverzekeringsrekeningOptions.Spaar },
  { label: "Belegging", value: SoortKapitaalverzekeringsrekeningOptions.Belegging },
  { label: "Hybride", value: SoortKapitaalverzekeringsrekeningOptions.Hybride }
];

const soortVermogenBerekeningOptions = [
  { label: "Inleg", value: SoortBerekeningOptions.Inleg },
  { label: "Eerste inleg", value: SoortBerekeningOptions.EersteInleg },
  { label: "Onttrekking", value: SoortBerekeningOptions.Onttrekking },
  { label: "Voorbeeldkapitaal", value: SoortBerekeningOptions.Voorbeeldkapitaal },
  { label: "Voorbeeldrendement", value: SoortBerekeningOptions.Voorbeeldrendement },
  { label: "Eigen invoer", value: SoortBerekeningOptions.EigenInvoer }
];

const soortKapitaalverzekeringBerekeningOptions = [
  { label: "Premie", value: SoortBerekeningOptionsKv.Premie },
  { label: "Voorbeeldkapitaal", value: SoortBerekeningOptions.Voorbeeldkapitaal },
  { label: "Voorbeeldrendement", value: SoortBerekeningOptions.Voorbeeldrendement },
  { label: "Eigen invoer", value: SoortBerekeningOptions.EigenInvoer }
];

const beleggersprofielOptions = [
  { label: "Zeer defensief", value: BeleggersprofielOptions.ZeerDefensief },
  { label: "Defensief", value: BeleggersprofielOptions.Defensief },
  { label: "Gematigd", value: BeleggersprofielOptions.Gematigd },
  { label: "Neutraal", value: BeleggersprofielOptions.Neutraal },
  { label: "Gematigd offensief", value: BeleggersprofielOptions.GematigdOffensief },
  { label: "Offensief", value: BeleggersprofielOptions.Offensief },
  { label: "Zeer offensief", value: BeleggersprofielOptions.ZeerOffensief }
];

type KapitaalopbouwProps = {
  selected: number;
  kenmerken: GeneriekeKapitaalopbouwKenmerkenKenmerken;
  gebruik: "vermogen" | "kapitaalverzekering";
};

const Kapitaalopbouw = ({
  selected,
  kenmerken,
  gebruik,
  formik: {
    values: { producten }
  }
}: KapitaalopbouwProps & {
  formik: FormikContextType<ProductenState>;
}): ReactElement => {
  const {
    soortProduct,
    kapitaalopbouw: { soortBerekening, soortRekening }
  } = producten[selected];

  const isBetaalrekening = soortProduct === SoortVermogenProductOptions.Betaalrekening;

  const soortRekeningConfig = {
    spaar:
      soortRekening === SoortVermogensrekeningOptions.Spaar ||
      soortRekening === SoortKapitaalverzekeringsrekeningOptions.Spaar,
    belegging:
      soortRekening === SoortVermogensrekeningOptions.Belegging ||
      soortRekening === SoortKapitaalverzekeringsrekeningOptions.Belegging,
    hybride:
      soortRekening === SoortVermogensrekeningOptions.Hybride ||
      soortRekening === SoortKapitaalverzekeringsrekeningOptions.Hybride
  };

  const soortBerekeningOptions = React.useMemo(() => {
    if (gebruik === "kapitaalverzekering") {
      if (kenmerken.soortBerekeningEigenInvoerTonen) {
        return soortKapitaalverzekeringBerekeningOptions;
      }

      return soortKapitaalverzekeringBerekeningOptions.filter(
        option => option.value !== SoortBerekeningOptions.EigenInvoer
      );
    } else {
      return soortVermogenBerekeningOptions.filter(
        option =>
          !(
            option.value === SoortBerekeningOptions.Onttrekking &&
            soortProduct === SoortVermogenProductOptions.Effectenlease
          )
      );
    }
  }, [gebruik, soortProduct, kenmerken.soortBerekeningEigenInvoerTonen]);

  let garantiekapitaalWaarschuwing = "";
  if (soortProduct === SoortVermogenProductOptions.Spaarrekening) {
    const huidigProduct = producten[selected];
    if (
      huidigProduct?.kapitaalopbouw !== null &&
      hasValue(huidigProduct.kapitaalopbouw.garantiekapitaalBedrag) &&
      hasValue(huidigProduct.kapitaalopbouw.doelkapitaalBedrag) &&
      huidigProduct.kapitaalopbouw.garantiekapitaalBedrag < huidigProduct.kapitaalopbouw.doelkapitaalBedrag
    ) {
      garantiekapitaalWaarschuwing = getProductTextResources("garantiekapitaalTeLaag");
    }
  }

  return (
    <>
      {kenmerken.soortRekeningTonen && (
        <LabeledRadioInput
          caption="Soort rekening"
          name={`producten[${selected}].kapitaalopbouw.soortRekening`}
          options={soortRekeningOptions}
          readOnly
        />
      )}
      {kenmerken.soortLijfrenteTonen && (
        <LabeledRadioInput
          caption="Soort verzekering"
          name={`producten[${selected}].kapitaalopbouw.soortRekening`}
          options={soortKapitaalverzekeringOptions}
        />
      )}
      {kenmerken.soortBerekeningTonen && (
        <LabeledSelectInput
          caption="Soort berekening"
          name={`producten[${selected}].kapitaalopbouw.soortBerekening`}
          options={soortBerekeningOptions}
        />
      )}

      {kenmerken.beleggersprofielTonen && (
        <LabeledSelectInput
          caption="Beleggersprofiel"
          name={`producten[${selected}].kapitaalopbouw.beleggersprofiel`}
          options={beleggersprofielOptions}
        />
      )}

      {kenmerken.doelkapitaalBedragTonen && (
        <LabeledCurrencyInput
          caption="Doelkapitaal"
          name={`producten[${selected}].kapitaalopbouw.doelkapitaalBedrag`}
          readonly={!kenmerken.doelkapitaalBedragEnabled}
          verplicht={producten[selected].verpanding.bedoeldVoorAflossing}
        />
      )}

      {kenmerken.doelrendementPercentageTonen && (
        <LabeledPercentageInput
          caption={isBetaalrekening ? "Rentepercentage" : "Rendement"}
          name={`producten[${selected}].kapitaalopbouw.doelrendementPercentage`}
          decimalen={2}
          readonly={!kenmerken.doelrendementPercentageEnabled}
        />
      )}

      {kenmerken.voorbeeldkapitaalBedragTonen && !soortRekeningConfig.spaar && (
        <LabeledCurrencyInput
          caption="Voorbeeldkapitaal"
          name={`producten[${selected}].kapitaalopbouw.voorbeeldkapitaalBedrag`}
          readonly={
            !kenmerken.voorbeeldkapitaalBedragEnabled || soortBerekening === SoortBerekeningOptions.Voorbeeldkapitaal
          }
        />
      )}

      {kenmerken.voorbeeldrendementPercentageTonen && !soortRekeningConfig.spaar && (
        <LabeledPercentageInput
          caption="Rendement"
          name={`producten[${selected}].kapitaalopbouw.voorbeeldrendementPercentage`}
          decimalen={2}
          readonly={
            !kenmerken.voorbeeldkapitaalPercentageEnabled ||
            soortBerekening === SoortBerekeningOptions.Voorbeeldrendement
          }
        />
      )}

      {kenmerken.garantiekapitaalBedragTonen && !soortRekeningConfig.belegging && (
        <LabeledCurrencyInput
          caption="Garantiekapitaal"
          name={`producten[${selected}].kapitaalopbouw.garantiekapitaalBedrag`}
          readonly={soortBerekening === SoortBerekeningOptions.Voorbeeldkapitaal}
          appendChildren={
            !!garantiekapitaalWaarschuwing && (
              <TooltipWrap
                name={`producten[${selected}].kapitaalopbouw.garantiekapitaalBedrag.waarschuwing`}
                warningText={garantiekapitaalWaarschuwing}
                placement="bottom"
                iconType="waarschuwing"
                tooltipClasses="px-1 mt-1"
              />
            )
          }
        />
      )}

      {kenmerken.garantierendementPercentageTonen && !soortRekeningConfig.belegging && (
        <LabeledPercentageInput
          caption={"Rendement"}
          name={`producten[${selected}].kapitaalopbouw.garantierendementPercentage`}
          decimalen={2}
          readonly={soortBerekening === SoortBerekeningOptions.Voorbeeldrendement}
        />
      )}
    </>
  );
};

export default connect<KapitaalopbouwProps, ProductenState>(Kapitaalopbouw);
