import { KredietReadOnly } from "../../../.generated/forms/formstypes";

import { KredietType } from "./schema";

export function mapKredietenDl2Ui(values: KredietReadOnly[] | null): KredietType[] {
  if (values) {
    return values.map(mapKredietDl2Ui);
  }

  return [];
}

export function mapKredietDl2Ui(values: KredietReadOnly): KredietType {
  return {
    kredietId: values.kredietId,
    doorlopend: values.doorlopend,
    productnaam: values.productnaam
  };
}
