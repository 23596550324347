import {
  MenuInfo,
  KlantprofielOnderwerpStatusOptions,
  MotiveringOnderwerpsoort,
  MenuInfoMotiveringOnderwerp,
  MotiveringOnderwerpStatusOptions,
  MenuInfoKlantprofielOnderwerp,
  MenuInfoVoorstel,
  KlantprofielOnderwerpOptions
} from "../.generated/forms/formstypes";
import { IconType, ItemType } from "adviesbox-shared";

enum Icon {
  menuerror = "menuerror",
  menuuntouched = "menuuntouched",
  menuincomplete = "menuincomplete",
  menucomplete = "menucomplete",
  waarschuwing = "waarschuwing"
}

export const getKlantProfielMenuIcon = (
  menuInfo: MenuInfo | null,
  selectedOnderwerp: KlantprofielOnderwerpOptions
): IconType => {
  if (
    menuInfo === null ||
    menuInfo?.klantprofiel === null ||
    menuInfo?.klantprofiel?.onderwerpen === null ||
    menuInfo?.klantprofiel?.onderwerpen === undefined
  ) {
    return Icon.waarschuwing;
  }
  const onderwerpen = menuInfo.klantprofiel.onderwerpen;
  const onderwerp: MenuInfoKlantprofielOnderwerp | undefined = onderwerpen.filter(
    onderwerp => onderwerp.onderwerp === selectedOnderwerp
  )[0];

  if (onderwerp === null || onderwerp === undefined) {
    return Icon.menuerror;
  }

  switch (onderwerp.status) {
    case KlantprofielOnderwerpStatusOptions.NietIngevuld:
      return Icon.menuuntouched;
    case KlantprofielOnderwerpStatusOptions.Onvolledig:
      return Icon.menuincomplete;
    case KlantprofielOnderwerpStatusOptions.Volledig:
      return Icon.menucomplete;
  }
};

export const getMotiveringMenuIcon = (
  menuInfo: MenuInfo | null,
  selectedOnderwerp: MotiveringOnderwerpsoort
): IconType => {
  if (
    menuInfo === null ||
    menuInfo?.motivering === null ||
    menuInfo?.motivering?.onderwerpen === null ||
    menuInfo?.motivering?.onderwerpen === undefined
  ) {
    return Icon.waarschuwing;
  }
  const onderwerpen = menuInfo.motivering.onderwerpen;
  const onderwerp: MenuInfoMotiveringOnderwerp | undefined = onderwerpen.filter(
    onderwerp => onderwerp.onderwerp === selectedOnderwerp
  )[0];

  if (onderwerp === null || onderwerp === undefined) {
    //Als waarschuwing getoond wordt dan komt de enum niet voor in menuInfo
    return Icon.menuerror;
  }

  switch (onderwerp.status) {
    case MotiveringOnderwerpStatusOptions.NietIngevuld:
      return Icon.menuuntouched;
    case MotiveringOnderwerpStatusOptions.Onvolledig:
      return Icon.menuincomplete;
    case MotiveringOnderwerpStatusOptions.Volledig:
      return Icon.menucomplete;
  }
};

export function mapVoorstelMenu(
  voorstellen: MenuInfoVoorstel[] | null,
  callback: (voorstel: MenuInfoVoorstel) => ItemType
): ItemType[] {
  if (voorstellen) return voorstellen?.map(callback);
  return [];
}
