import { QuestionType, CardSpec } from "../../infra/klantprofiel-schema";
import { KlantprofielVraagOptions } from "../../../.generated/forms/formstypes";

export const dubbeleWoonlastenQuestionSpecs: CardSpec[] = [
  {
    questions: [
      {
        question: KlantprofielVraagOptions.KrijgtTeMakenDubbeleWoonlasten,
        type: QuestionType.radio,
        showVerplichtToelichting: [
          {
            question: KlantprofielVraagOptions.KrijgtTeMakenDubbeleWoonlasten,
            answer: 80101
          },
          {
            question: KlantprofielVraagOptions.KrijgtTeMakenDubbeleWoonlasten,
            answer: 80102
          }
        ]
      },
      {
        question: KlantprofielVraagOptions.HoeLangDubbeleWoonlasten,
        type: QuestionType.jaarMaanden,
        condition: {
          question: KlantprofielVraagOptions.KrijgtTeMakenDubbeleWoonlasten,
          answer: 80101
        }
      },
      {
        question: KlantprofielVraagOptions.HoeDubbeleWoonlastenBetalen,
        type: QuestionType.checkbox,
        condition: {
          question: KlantprofielVraagOptions.KrijgtTeMakenDubbeleWoonlasten,
          answer: 80101
        }
      }
    ]
  }
];
