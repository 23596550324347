import React, { ReactElement, useState, useContext, useMemo, useCallback, useEffect } from "react";
import { withAdviesboxFormik } from "../shared/utils/with-adviesbox-formik";
import { importSchema, importProps, importState } from "./infra/import-schema";
import {
  MenuWrapper,
  useRequestInit,
  Footer,
  SettingsType,
  SubscribeNotifyButton,
  useFeature,
  NotificatiesContext
} from "adviesbox-shared";
import classnames from "classnames";
import brandLogoIcon from "../assets/new-brand-logo.svg";
import brandCollapsedLogoIcon from "../assets/new-collapsed-brand-logo.svg";
import ExternekoppelingImportDetails from "./externekoppeling-import-details";
import { RouteParams } from "./externekoppeling-import-ajax";
import { Button } from "react-bootstrap";
import { AppDataContext } from "../navigation/appdata-context";
import { User } from "oidc-client";
import { StatusOptions } from "../.generated/externekoppelingen/externekoppelingentypes";
import { FormikProps } from "formik";
import { useHistory } from "react-router-dom";
import { mapImportUiToDl } from "./infra/import-ui-to-dl";
import TopNavbar from "../shared/components/topnav-dossier/TopNavbar";
import { ImportOpslaanEnBevestigen, ImportBevestigen } from "./import-bevestigen";
import { mapImportDlNameToUiName } from "./infra/import-dl-to-ui";
import { ConfirmButton } from "./confirm-button/confirm-button";
/* istanbul ignore next */
const noop = async (): Promise<null> => null;

const bevestigUrl = (s: SettingsType, p: RouteParams): string => {
  return `${s.externeKoppelingenOrigin}/importTaak/${p.importTaakId}/confirm`;
};

const ExterneKoppelingImport = (props: FormikProps<importState> & importState): ReactElement => {
  const { values, isSubmitting, setSubmitting, validateForm, setFieldTouched, fieldMaps } = props;

  React.useEffect(() => {
    fieldMaps?.forEach((fieldMap, indexFieldMap) => {
      fieldMap.labelValues.forEach((_, indexLabelValue) => {
        setFieldTouched(`fieldMaps[${indexFieldMap}].labelValues[${indexLabelValue}].value`, true);
      });
    });
    /* eslint-disable-next-line @typescript-eslint/no-floating-promises */
    validateForm();
  }, [validateForm, fieldMaps, setFieldTouched]);

  const featureNotificatie2 = useFeature("FeatureNotificaties2");
  const [collapsed, setcollapsed] = useState(false);

  const { lastMutationDate } = useContext(AppDataContext);

  const history = useHistory();
  const { notificaties } = useContext(NotificatiesContext);

  const [correlationId, setCorrelationId] = useState<string | null>(null);
  const { settings, params, user } = useRequestInit<RouteParams>();

  const { subscribeCorrelationId } = useContext(NotificatiesContext);

  const url = useMemo(() => bevestigUrl(settings, params), [settings, params]);

  // Opslaan van de gegevens als er validatiefouten zijn (die gecorrigeerd zijn)
  /* istanbul ignore next */
  const saveData = useCallback(
    user
      ? ImportOpslaanEnBevestigen(
          settings.OcpApimSubscriptionKey,
          user,
          params.koppelingKey,
          params.vestiging,
          url,
          mapImportUiToDl,
          mapImportDlNameToUiName,
          subscribeCorrelationId,
          setCorrelationId
        )
      : noop,
    [user, params.koppelingKey, url, settings, user, mapImportUiToDl, mapImportDlNameToUiName]
  );
  // Importeren van de gegevens als er geen validatiefouten zijn (status = Ready)
  /* istanbul ignore next */
  const confirmData = useCallback(
    user
      ? ImportBevestigen(
          settings.OcpApimSubscriptionKey,
          user,
          params.koppelingKey,
          params.vestiging,
          url,
          mapImportDlNameToUiName,
          subscribeCorrelationId,
          setCorrelationId
        )
      : noop,
    [user, params.koppelingKey, url, settings, user, mapImportUiToDl, mapImportDlNameToUiName]
  );

  /* istanbul ignore next */
  useEffect(() => {
    if (!featureNotificatie2) {
      const notificatie = notificaties?.find(entry => entry.uniekID === correlationId);
      if (notificatie?.message) {
        setSubmitting(false);
        const message = JSON.parse(notificatie.message);
        if (message.validationResults.length > 0) {
          window.location.reload();
        }
        if (message?.name === "confirm-import-taak-result" && message?.adviesdossierId) {
          history.push(`/vestiging/${params.vestiging}/adviesdossier/${message.adviesdossierId}/personalia`);
        }
      }
    }
  }, [notificaties, correlationId, setSubmitting, history, params.vestiging, featureNotificatie2]);

  const ImportStatusRejectPUT = async (
    settings: SettingsType,
    user: User | null,
    koppelingKey: string,
    importTaakId: string
  ): Promise<void> => {
    const url = `${settings.externeKoppelingenOrigin}/importTaak/${importTaakId}/reject`;
    await fetch(url, {
      method: "PUT",
      headers: {
        authorization: `${user?.token_type} ${user?.access_token}`,
        "Ocp-Apim-Subscription-Key": settings.OcpApimSubscriptionKey,
        "Content-Type": "application/json;charset=UTF-8",
        koppelingKey,
        vestigingId: params.vestiging
      }
    });
  };

  const requestInitNoticicatie: RequestInit =
    values.status === StatusOptions.ValidationError
      ? {
          headers: {
            authorization: `${user?.token_type} ${user?.access_token}`,
            "Ocp-Apim-Subscription-Key": settings.OcpApimSubscriptionKey,
            "Content-Type": "application/json",
            koppelingKey: params.koppelingKey,
            vestigingId: params.vestiging
          },
          method: "PATCH",
          body: JSON.stringify(mapImportUiToDl(values))
        }
      : {
          headers: {
            authorization: `${user?.token_type} ${user?.access_token}`,
            "Ocp-Apim-Subscription-Key": settings.OcpApimSubscriptionKey,
            "Content-Type": "application/json",
            koppelingKey: params.koppelingKey,
            vestigingId: params.vestiging
          },
          method: "PUT"
        };

  return (
    <>
      <TopNavbar home={true} />
      <div className="drawer-wrapper">
        <div className={classnames("drawer-panel", { collapsed })}>
          <MenuWrapper
            brandLogoIcon={brandLogoIcon}
            brandCollapsedLogoIcon={brandCollapsedLogoIcon}
            collapse={(): void => setcollapsed(!collapsed)}
            collapsed={collapsed}
            hasMenuInfo={true}
            loading={false}
            menuConfig={[
              {
                name: "Advies",
                lists: [
                  {
                    name: "Personalia",
                    title: "Personalia",
                    icon: "inventarisatie",
                    link: `/vestiging/${params.vestiging}/import/${params.koppelingKey}/taak/${params.importTaakId}?categorie=Personalia` // TODO
                  }
                ]
              } // TODO: Dynamisch opzetten a.d.h.v. de JSON
            ]}
            appName={"advies"}
            readonly={false}
          />
        </div>
        <div className="drawer-content">
          <ExternekoppelingImportDetails />
        </div>
        <div className="text-container">
          <div className="save-btn-position">
            <div className="button-container">
              <Button
                className={"mr-2"}
                data-testid="afwijzen-button"
                disabled={
                  isSubmitting ||
                  (values.status !== StatusOptions.Ready && values.status !== StatusOptions.ValidationError)
                }
                onClick={() => {
                  // eslint-disable-next-line @typescript-eslint/no-floating-promises
                  ImportStatusRejectPUT(settings, user, values.koppelingKey, values.importTaakId);
                  history.push(`/vestiging/${params.vestiging}/zoeken`);
                }}
              >
                Afwijzen
              </Button>
              {!featureNotificatie2 && (
                <ConfirmButton
                  context={{ ...props, saveData: values.status === StatusOptions.Ready ? confirmData : saveData }}
                  alwaysShow={values.status === StatusOptions.Ready}
                />
              )}
              {featureNotificatie2 && (
                <SubscribeNotifyButton
                  context={props}
                  buttonText="Dossier importeren"
                  requestInit={requestInitNoticicatie}
                  url={url}
                  alwaysShow={values.status === StatusOptions.Ready}
                />
              )}
            </div>
          </div>
        </div>
        <Footer lastMutationDate={lastMutationDate}></Footer>
      </div>
    </>
  );
};

export default withAdviesboxFormik<importProps & importState, importState>({
  mapPropsToValues: (e: importProps): importState => e,
  validationSchema: importSchema
})(ExterneKoppelingImport);
