import React, { ReactElement } from "react";
import { ListGroup } from "react-bootstrap";
import { CheckBox, TooltipWrap } from "adviesbox-shared";
import { SoortProductOptions } from "../../.generated/forms/formstypes";
import { HuidigProductType } from "../infra/vergoedingen-overzicht-schema";
import { getVergoedingenOverzichtTextResources } from "../infra/vergoedingen-overzicht-resources";
import { LocalDate } from "@js-joda/core";

type HuidigProdProps = {
  item: HuidigProductType;
  index: number;
  ingangsdatum: LocalDate;
};

export const HuidigProductComponent = ({ item, index, ingangsdatum }: HuidigProdProps): ReactElement => {
  const inFinancieringBeschikbaar: boolean =
    item.productsoort === SoortProductOptions.Vermogensrekening ||
    item.productsoort === SoortProductOptions.Kapitaalverzekering;
  const uitFinancieringBeschikbaar: boolean = item.productsoort === SoortProductOptions.Krediet;

  return (
    <ListGroup.Item key={`productitem-${index}`} className="producten-table">
      <div className="row">
        <div className="col-6">
          {item.productsoort !== SoortProductOptions.Overig && (
            <span>
              {item.maatschappijNaam} {item.productnaam} ({item.productsoort.toLocaleLowerCase()})
            </span>
          )}
          {item.productsoort === SoortProductOptions.Overig && (
            <span>
              {item.omschrijving} {item.productnaam} ({item.productsoort.toLocaleLowerCase()})
            </span>
          )}
        </div>
        <div className="col-6">
          <CheckBox
            caption="Meenemen"
            name={`huidigProducten[${index}].meenemen`}
            appendChildren={
              item.eindeLooptijdDatum &&
              item.eindeLooptijdDatum <= ingangsdatum && (
                <TooltipWrap
                  name={`eindelooptijd-warning`}
                  warningText={getVergoedingenOverzichtTextResources("EindelooptijdWarning")}
                  iconType="waarschuwing"
                  wrapperId={`eindelooptijd-warning-${index}`}
                />
              )
            }
          />

          {item.premievrijMakenMogelijk && (
            <CheckBox
              caption="Premievrij maken"
              disabled={!item.premievrijMakenMogelijk}
              name={`huidigProducten[${index}].premievrijMaken`}
            />
          )}

          {!item.meenemen && uitFinancieringBeschikbaar && (
            <CheckBox
              caption="Uit financiering"
              disabled={!uitFinancieringBeschikbaar}
              name={`huidigProducten[${index}].uitFinanciering`}
            />
          )}

          {!item.meenemen && inFinancieringBeschikbaar && (
            <CheckBox
              caption="In financiering"
              disabled={!inFinancieringBeschikbaar}
              name={`huidigProducten[${index}].inFinanciering`}
            />
          )}
        </div>
      </div>
    </ListGroup.Item>
  );
};
