import { DataGrid, ErrorPage, Icon, SettingsContext, useAdviesboxDataRepository, FetchDataButton } from "adviesbox-shared";
import { useFormikContext } from "formik";
import React, { ReactElement, useContext } from "react";
import { useParams } from "react-router-dom";
import { Column } from "react-table-6";
import { MaximaleHypotheekUitkomstOutput } from "../../.generated/forms/formstypes";
import { useInstellingenBeheerPartijenData } from "../../shared/hooks/use-instellingen-beheer-partijen-data";
import { RouteParams } from "../../shared/paramrouting/paramrouting-context";
import { mapDlTargetToMaximaleHypotheekUiField } from "../infra/map-maximale-hypotheek-dl-target-to-ui-field";
import { getMaximaleHypotheekTextResources } from "../infra/maximale-hypotheek-resources";
import { MaximaleHypotheekDataGridTemplates, MaximaleHypotheekState } from "../infra/maximale-hypotheek-schema";
import { MaximaleHypotheekPDF } from "../maximale-hypotheek-pdf/maximale-hypotheek-pdf";
import { getMaximaleHypotheekColumnTemplates } from "./maximale-hypotheek-data-grid-helpers/maximale-hypotheek-data-grid-templates/maximale-hypotheek-data-grid-column-templates";
import {
  mapFormikValues,
  mapMaximaleHypotheekResult
} from "./maximale-hypotheek-data-grid-helpers/maximale-hypoyheek-data-grid-mapper/maximale-hypotheek-data-grid-mapper";

export type MaximaleHypotheekDataGridProps = {
  setBerekend: React.Dispatch<React.SetStateAction<boolean>>;
  berekend: boolean;
};

export const MaximaleHypotheekDataGrid = ({ berekend, setBerekend }: MaximaleHypotheekDataGridProps): ReactElement => {
  const { values, isValid, setFieldValue } = useFormikContext<MaximaleHypotheekState>();
  const settings = useContext(SettingsContext);
  const { voorstel } = useParams<RouteParams>();

  const {
    data: enabledGeldverstrekkersData,
    loading: geldverstrekkersLoading,
    error: geldverstrekkersError
  } = useInstellingenBeheerPartijenData("Geldverstrekkers");

  const urlMaximaleHypotheek = `${settings.klantdossiersFormsOrigin}/Voorstellen/${voorstel}/MaximaleHypotheek`;
  const { fetchData, loading } = useAdviesboxDataRepository<MaximaleHypotheekUitkomstOutput, MaximaleHypotheekState>(
    urlMaximaleHypotheek,
    {
      method: "POST",
      mapDlToUi: (_, res) => {
        const advies = mapMaximaleHypotheekResult(
          Object.values(res?.maximaleHypotheken ?? []),
          enabledGeldverstrekkersData.geldverstrekkers?.partijen || []
        );

        if (advies === null) return null;

        setBerekend(true);
        return {
          ...values,
          dataHasChanged: false,
          maximaleHypotheken: advies
        };
      },
      mapUiToDl: mapFormikValues,
      mapTargetToUiField: mapDlTargetToMaximaleHypotheekUiField
    }
  );

  const maxHypotheekColumns = (): Column[] => {
    if (!values.maximaleHypotheken || !values.maximaleHypotheken.length) return [];
    const columnTemplates = getMaximaleHypotheekColumnTemplates(!!values.uitgangspunten.nhg);
    const columns: { [key: number]: Column | null } = {
      [MaximaleHypotheekDataGridTemplates.uitslag]: null,
      [MaximaleHypotheekDataGridTemplates.productnaam]: null,
      [MaximaleHypotheekDataGridTemplates.toetsrente]: null,
      [MaximaleHypotheekDataGridTemplates.extraAflossing]: null,
      [MaximaleHypotheekDataGridTemplates.looptijd]: null,
      [MaximaleHypotheekDataGridTemplates.maximaleHypotheek]: null,
      [MaximaleHypotheekDataGridTemplates.maximaleHypotheekOnderpand]: null,
      [MaximaleHypotheekDataGridTemplates.inkomstenVerklaring1]: null,
      [MaximaleHypotheekDataGridTemplates.inkomstenVerklaring2]: null
    };

    values.maximaleHypotheken.forEach((a): void => {
      if (!columns[MaximaleHypotheekDataGridTemplates.uitslag] && !!a.uitslag)
        columns[MaximaleHypotheekDataGridTemplates.uitslag] =
          columnTemplates[MaximaleHypotheekDataGridTemplates.uitslag];
      if (!columns[MaximaleHypotheekDataGridTemplates.toetsrente] && !!a.toetsrente)
        columns[MaximaleHypotheekDataGridTemplates.toetsrente] =
          columnTemplates[MaximaleHypotheekDataGridTemplates.toetsrente];
      if (!columns[MaximaleHypotheekDataGridTemplates.looptijd] && !!a.looptijd)
        columns[MaximaleHypotheekDataGridTemplates.looptijd] =
          columnTemplates[MaximaleHypotheekDataGridTemplates.looptijd];
      if (!columns[MaximaleHypotheekDataGridTemplates.productnaam] && !!a.productnaam)
        columns[MaximaleHypotheekDataGridTemplates.productnaam] =
          columnTemplates[MaximaleHypotheekDataGridTemplates.productnaam];
      if (!columns[MaximaleHypotheekDataGridTemplates.maximaleHypotheek] && !!a.maximaleHypotheek)
        columns[MaximaleHypotheekDataGridTemplates.maximaleHypotheek] =
          columnTemplates[MaximaleHypotheekDataGridTemplates.maximaleHypotheek];
      if (!columns[MaximaleHypotheekDataGridTemplates.maximaleHypotheekOnderpand] && !!a.maximaleHypotheekOnderpand)
        columns[MaximaleHypotheekDataGridTemplates.maximaleHypotheekOnderpand] =
          columnTemplates[MaximaleHypotheekDataGridTemplates.maximaleHypotheekOnderpand];
      if (!columns[MaximaleHypotheekDataGridTemplates.inkomstenVerklaring1] && !!a.inkomstenVerklaring1)
        columns[MaximaleHypotheekDataGridTemplates.inkomstenVerklaring1] =
          columnTemplates[MaximaleHypotheekDataGridTemplates.inkomstenVerklaring1];
      if (!columns[MaximaleHypotheekDataGridTemplates.inkomstenVerklaring2] && !!a.inkomstenVerklaring2)
        columns[MaximaleHypotheekDataGridTemplates.inkomstenVerklaring2] =
          columnTemplates[MaximaleHypotheekDataGridTemplates.inkomstenVerklaring2];
    });

    return Object.values(columns).filter((c): boolean => !!c) as Column[];
  };

  /* istanbul ignore next */
  const onDataGridSortChange = (sortedData?: any): void => {
    if (!sortedData || !sortedData.length) return;
    const data: any = [];
    sortedData.forEach((obj: any) => {
      data.push(obj);
    });

    setFieldValue("sortedMaximaleHypotheken", data);
  };

  if (geldverstrekkersError) return <ErrorPage error={geldverstrekkersError} data={enabledGeldverstrekkersData} />;

  return (
    <div>
      {values.maximaleHypotheken && values.maximaleHypotheken.length > 0 && (
        <MaximaleHypotheekPDF
          sortedData={values.sortedMaximaleHypotheken}
          initial={values.maximaleHypotheken}
          values={values}
        />
      )}

      <div className="button-container">
        <FetchDataButton
          onClick={fetchData}
          dataOutDated={values.dataHasChanged || !values.maximaleHypotheken || values.maximaleHypotheken.length < 1}
          invalid={!isValid || geldverstrekkersLoading || geldverstrekkersError != null}
          initialText={getMaximaleHypotheekTextResources("gridInitialText")}
          hasResult={values.maximaleHypotheken && !!values.maximaleHypotheken.length}
          loading={loading}
        />
      </div>

      {values.maximaleHypotheken && values.maximaleHypotheken.length > 0 && (
        <div data-testid="data-table">
          <DataGrid
            name="maximaleHypotheken"
            sortable={true}
            filterable={false}
            loading={false}
            defaultPageSize={values.maximaleHypotheken.length}
            columns={maxHypotheekColumns()}
            sortedCallback={onDataGridSortChange}
            showPagination={false}
          />
          <div className={"d-flex align-items-center justify-content-start my-3"}>
            <div className={"d-flex align-items-center mx-2"}>
              <Icon name="vink" alt="vink" />
              <span className={"ml-2"}>Toetsing van maatschappij is passend.</span>
            </div>
            <div className={"d-flex align-items-center mx-2"}>
              <Icon name="uitroepteken" multiColor={true} alt="uitroepteken" />
              <span className={"ml-2"}>Toetsing is niet passend.</span>
            </div>
            <div className={"d-flex align-items-center mx-2"}>
              <Icon name="kruis" alt="kruis" />
              <span className={"ml-2"}>Geen van de toetsingen is passend.</span>
            </div>
          </div>
        </div>
      )}
      {berekend && !values.dataHasChanged && (!values.maximaleHypotheken || values.maximaleHypotheken.length < 1) && (
        <div className="d-flex align-items-center justify-content-center">
          <div>{getMaximaleHypotheekTextResources("gridFetchFout")}</div>
        </div>
      )}
    </div>
  );
};
