import { Icon, LabeledToggleInput, ModalButton, TooltipWrap } from "adviesbox-shared";
import { connect, FormikContextType } from "formik";
import React, { ReactElement, useCallback } from "react";
import { KapitaalverzekeringenType } from "../../kapitaalverzekering/infra/kapitaalverzekering-types";
import { OrvsState } from "../../orv/infra/orv-schema";
import { SoortLijfrenteUitkeringOptions } from "../../.generated/forms/formstypes";
import { jaarMaandNaarMaanden, maandenNaarJrMnd } from "../../shared/generic-parts/jaar-maand/helpers";
import { jaarMaandInMaanden } from "../../shared/generic-parts/jaar-maand/map-ui-2-dl";
import { UseHdnDataResult } from "../../shared/hooks/use-hdn-data";
import { bindSaveFunction } from "../../shared/utils/bind-save-function";
import { bedragFormat } from "../../shared/utils/currency";
import { VermogensType } from "../../vermogen/infra/vermogen-types";
import AanvullingInkomenBijOverlijdenModal from "../aanvulling-inkomen-bij-overlijden-modal/aanvulling-inkomen-bij-overlijden-modal";
import AfTeLossenLeningdelenModal, {
  valtEindatumBuitenLooptijd
} from "../af-te-lossen-leningdelen-modal/af-te-lossen-leningdelen-modal";
import IndicatieveUitkerendeFaseModal from "../indicatieve-uitkerende-fase-modal/indicatieve-uitkerende-fase-modal";
import { VerpandingKenmerken } from "../infra/product-kenmerken-types";
import { SituatieSoort } from "../../producten-overzicht/infra/producten-overzicht-types";
import { getProductenOverzichtTextResources } from "../infra/producten-overzicht-resources";
import { IndicatieveUitkerendeFaseType } from "../infra/producten-overzicht-schema";
import {
  AanvullingInkomenBijOverlijdenModalType,
  DekkingType,
  ProductenState
} from "../infra/producten-overzicht-types";
import classes from "./verpanding.module.scss";

export function indicatieveUitkerendeFaseLabel(
  indicatieveUitkerendeFaseSpecificatie: IndicatieveUitkerendeFaseType
): string {
  let lijfrenteUitkering = "";

  if (indicatieveUitkerendeFaseSpecificatie.lijfrenteUitkering.bedrag) {
    lijfrenteUitkering = bedragFormat(indicatieveUitkerendeFaseSpecificatie.lijfrenteUitkering.bedrag, 2);

    const duurUitkeringInMaanden = jaarMaandInMaanden(indicatieveUitkerendeFaseSpecificatie.duurUitkering);
    const duurUitkeringHoogInMaanden = jaarMaandInMaanden(indicatieveUitkerendeFaseSpecificatie.duurUitkeringHoog);

    if (duurUitkeringHoogInMaanden) {
      lijfrenteUitkering += " Hoog/laag";
    } else if (
      indicatieveUitkerendeFaseSpecificatie.soortLijfrenteUitkering === SoortLijfrenteUitkeringOptions.Levenslang
    ) {
      lijfrenteUitkering += " Levenslang";
    } else if (duurUitkeringInMaanden) {
      lijfrenteUitkering += " Gedurende " + maandenNaarJrMnd(duurUitkeringInMaanden);
    } else {
      lijfrenteUitkering += " Levenslang";
    }
  }

  return lijfrenteUitkering;
}

type VerpandingProps = {
  selected: number;
  situatie: SituatieSoort;
  waardeopbouwBedrag: number | null;
  verpandAanGeldverstrekkerTonen: boolean;
  kenmerken: VerpandingKenmerken;
  showOnHavingItems?: boolean;
  verpandAanPartij?: string | null;
  list?: UseHdnDataResult;
};

type VerpandingFormState = ProductenState | OrvsState | VermogensType | KapitaalverzekeringenType;

const Verpanding = ({
  selected,
  situatie,
  waardeopbouwBedrag,
  verpandAanGeldverstrekkerTonen,
  kenmerken,
  verpandAanPartij = null,
  showOnHavingItems = false,
  list,
  formik: {
    setFieldValue,
    values: { producten, aanvrager1, aanvrager2 },
    setFieldTouched
  }
}: VerpandingProps & {
  formik: FormikContextType<VerpandingFormState>;
}): ReactElement => {
  const createSaveFunction = useCallback(bindSaveFunction(setFieldValue), [setFieldValue]);
  const selectedProduct = producten && producten[selected];
  const { verpanding, product, verzekeringnemers } = selectedProduct;
  const { inkomensaanvullingSpecificatie } = verpanding;
  const { einddatum, partijNaam: geldverstrekker } = product;
  const verpandAanIsEnabledChecked = verpanding.verpandAanGeldverstrekker || false;
  const { aflosproducten } = verpanding.bedoeldVoorAflossingSpecificatie;
  let dekking: DekkingType | null = null;

  // De dekking prop bestaat alleen in ProductenState
  if ("dekking" in selectedProduct) {
    dekking = selectedProduct.dekking;
  }

  let bedoeldVoorAflossingOmschrijving = "";
  if (verpanding.bedoeldVoorAflossing && aflosproducten.length) {
    bedoeldVoorAflossingOmschrijving = aflosproducten
      .filter((p): boolean => p.aflossen || false)
      .map((p): string => {
        return `${p.doorlopend ? "Doorlopend " : ""}${p.soortAflosproduct} ${p.volgnummer} - ${p.omschrijving}`;
      })
      .join(", ");
  }

  const bedoeldVoorAflossingWarning = React.useMemo(() => {
    if (valtEindatumBuitenLooptijd(einddatum, verpanding.bedoeldVoorAflossingSpecificatie.aflosproducten)) {
      return getProductenOverzichtTextResources("bedoeldVoorAflossingWarning");
    }

    return "";
  }, [einddatum, verpanding.bedoeldVoorAflossingSpecificatie.aflosproducten]);

  let indicatieveUitkerendeFase = "";
  // Bij ORV is indicatieveUitkerendeFaseSpecificatie een leeg object
  if (
    verpanding.indicatieveUitkerendeFaseSpecificatie &&
    "values" in verpanding.indicatieveUitkerendeFaseSpecificatie
  ) {
    indicatieveUitkerendeFase = indicatieveUitkerendeFaseLabel(verpanding.indicatieveUitkerendeFaseSpecificatie.values);
  }

  return (
    <>
      {verpandAanGeldverstrekkerTonen && (
        <div
          onClick={_ => {
            setFieldTouched(`producten[${selected}].kapitaalopbouw.doelkapitaalBedrag`);
          }}
        >
          <LabeledToggleInput
            caption={
              situatie === "voorstel"
                ? `Verpand aan ${verpandAanPartij ?? geldverstrekker}`
                : "Verpand aan geldverstrekker"
            }
            name={`producten[${selected}].verpanding.verpandAanGeldverstrekker`}
          />
        </div>
      )}

      {kenmerken.bedoeldAflossingTonen && (
        <LabeledToggleInput
          caption="Bedoeld voor aflossing van"
          name={`producten[${selected}].verpanding.bedoeldVoorAflossing`}
          disabled={verpandAanIsEnabledChecked}
          appendChildren={
            <div className={classes.modal_button}>
              <span>{bedoeldVoorAflossingOmschrijving || "Geen"}</span>
              <div>
                {bedoeldVoorAflossingWarning && (
                  <TooltipWrap
                    name={`producten[${selected}].verpanding.bedoeldVoorAflossing`}
                    warningText={bedoeldVoorAflossingWarning}
                    placement="bottom"
                    iconType="waarschuwing"
                    tooltipClasses="verpanding-warning d-inline-flex"
                  />
                )}
                {(!showOnHavingItems || aflosproducten.length >= 1) && (
                  <ModalButton
                    parent={`producten[${selected}].verpanding.bedoeldVoorAflossingSpecificatie`}
                    className={classes.modal_button}
                    content={<Icon name="specificatie" alt="Af te lossen leningdelen." />}
                    disabled={aflosproducten.length === 0}
                  >
                    <AfTeLossenLeningdelenModal
                      data={verpanding.bedoeldVoorAflossingSpecificatie}
                      einddatum={product.einddatum}
                      list={list}
                      onSave={createSaveFunction(`producten[${selected}].verpanding.bedoeldVoorAflossingSpecificatie`)}
                    />
                  </ModalButton>
                )}
              </div>
            </div>
          }
        />
      )}

      {/* Bij ORV is indicatieveUitkerendeFaseSpecificatie een leeg object */}
      {kenmerken.lijfrenteUitkeringTonen &&
        verpanding.indicatieveUitkerendeFaseSpecificatie &&
        "values" in verpanding.indicatieveUitkerendeFaseSpecificatie && (
          <LabeledToggleInput
            caption="Indicatieve uitkerende fase"
            name={`producten[${selected}].verpanding.indicatieveUitkerendeFase`}
            appendChildren={
              <div className={classes.modal_button}>
                <span>{indicatieveUitkerendeFase}</span>
                <div>
                  {!!indicatieveUitkerendeFase || (
                    <TooltipWrap
                      name={`producten[${selected}].verpanding.indicatieveUitkerendeFase`}
                      warningText={getProductenOverzichtTextResources("indicatieveUitkerendeFaseWarning")}
                      placement="bottom"
                      iconType="waarschuwing"
                      tooltipClasses="verpanding-warning d-inline-flex"
                    />
                  )}
                  <ModalButton
                    size="lg"
                    parent={`producten[${selected}].verpanding.indicatieveUitkerendeFaseSpecificatie`}
                    className={classes.modal_button}
                    content={<Icon name="specificatie" alt="Indicatieve uitkerende fase" />}
                  >
                    <IndicatieveUitkerendeFaseModal
                      data={verpanding.indicatieveUitkerendeFaseSpecificatie}
                      ingangsdatum={einddatum}
                      verzekerden={verzekeringnemers.verzekeringnemers}
                      waardeopbouwBedrag={waardeopbouwBedrag}
                      kenmerken={kenmerken}
                      onSave={createSaveFunction(
                        `producten[${selected}].verpanding.indicatieveUitkerendeFaseSpecificatie`
                      )}
                    />
                  </ModalButton>
                </div>
              </div>
            }
          />
        )}

      {kenmerken.inkomensaanvullingTonen && dekking && aanvrager1 && aanvrager2 && (
        <LabeledToggleInput
          caption="Inkomensaanvulling"
          name={`producten[${selected}].verpanding.inkomensaanvulling`}
          appendChildren={
            <div className={classes.modal_button}>
              <span>{inkomensaanvullingSamenvatting(inkomensaanvullingSpecificatie)}</span>
              <div>
                {inkomensaanvullingWarning(inkomensaanvullingSpecificatie) && (
                  <TooltipWrap
                    name={`producten[${selected}].verpanding.inkomensaanvulling`}
                    warningText={inkomensaanvullingWarning(inkomensaanvullingSpecificatie)}
                    placement="bottom"
                    iconType="waarschuwing"
                    tooltipClasses="verpanding-warning d-inline-flex"
                  />
                )}
                <ModalButton
                  className={classes.modal_button}
                  parent={`producten[${selected}].verpanding.inkomensaanvulling`}
                  content={<Icon name="specificatie" alt="Aanvulling inkomen bij overlijden" />}
                >
                  <AanvullingInkomenBijOverlijdenModal
                    data={inkomensaanvullingSpecificatie}
                    verzekerdKapitaalAanvrager1={dekking.verzekerdKapitaalAanvrager1}
                    verzekerdKapitaalAanvrager2={dekking.verzekerdKapitaalAanvrager2}
                    onSave={createSaveFunction(`producten[${selected}].verpanding.inkomensaanvullingSpecificatie`)}
                  />
                </ModalButton>
              </div>
            </div>
          }
        />
      )}
    </>
  );
};

export function inkomensaanvullingSamenvatting(aanvulling: AanvullingInkomenBijOverlijdenModalType): string {
  const result: string[] = [];

  if (aanvulling.aanvullingInkomenBijOverlijdenAanvrager1) {
    result.push(
      `${bedragFormat(aanvulling.gewensteUitkeringAanvrager1, 2)} ${jaarMaandNaarMaanden(
        aanvulling.duurUitkeringAanvrager1
      )}`
    );
  }

  if (aanvulling.aanvullingInkomenBijOverlijdenAanvrager2) {
    result.push(
      `${bedragFormat(aanvulling.gewensteUitkeringAanvrager2, 2)} ${jaarMaandNaarMaanden(
        aanvulling.duurUitkeringAanvrager2
      )}`
    );
  }

  if (result.length) {
    return result.join("; ");
  }

  return "Niet ingevuld";
}

export function inkomensaanvullingWarning(aanvulling: AanvullingInkomenBijOverlijdenModalType): string {
  if (aanvulling.aanvullingInkomenBijOverlijdenAanvrager1 || aanvulling.aanvullingInkomenBijOverlijdenAanvrager2) {
    return "";
  }

  return getProductenOverzichtTextResources("inkomensaanvullingWarning");
}

export default connect<VerpandingProps, VerpandingFormState>(Verpanding);
