import React, { ReactElement } from "react";

import { SituatieSoort } from "../../producten-overzicht/infra/producten-overzicht-types";
import Product from "../../producten-overzicht/product/product";
import { ProductKenmerken } from "../../producten-overzicht/infra/product-kenmerken-types";

import Heffing from "../heffing/heffing";
import Correctie from "../correctie/correctie";
import OverigePostenGegevens from "../overige-posten-gegevens/overige-posten-gegevens";
import Verrekenen from "../verrekenen/verrekenen";

import { connect, FormikContextType } from "formik";
import { BetalingsTermijnType } from "../../.generated/forms/formstypes";
import { ISWSideEffects } from "../../shared/components/isw-side-effects/isw-side-effects";

import { OverigePostenType } from "../infra/overige-posten-types";
import { determineOverigePostDetailsSideEffects } from "../infra/determine-overige-posten-side-effects";

import { Card, CardWrapper } from "adviesbox-shared";

type Props = { selected: number; situatie: SituatieSoort };

const OverigePostDetails = ({
  selected,
  situatie,
  formik: {
    values: { producten, aanvrager1, aanvrager2 },
    touched
  }
}: Props & {
  formik: FormikContextType<OverigePostenType>;
}): ReactElement => {
  const selectedProduct = producten[selected];
  const { overigePostenGegevens } = selectedProduct;
  const productKenmerken: ProductKenmerken = {
    productnaamTonen: true,
    productnaamEnabled: true,
    ingangsdatumTonen: true,
    einddatumTonen: true,
    einddatumEnabled: true,
    looptijdTonen: true,
    looptijdJaarEnabled: true,
    looptijdMaandEnabled: true,
    uwBemiddelingTonen: true,
    maatschappijkeuzeEnabled: false,
    logoTonen: false,
    productoptiesTonen: false,
    productnummerTonen: false
  };

  if (overigePostenGegevens.termijn === BetalingsTermijnType.Eenmalig) {
    productKenmerken.einddatumTonen = false;
    productKenmerken.looptijdTonen = false;
  }

  return (
    <CardWrapper className="px-3" maxRowCount={7}>
      <ISWSideEffects<OverigePostenType>
        sync={determineOverigePostDetailsSideEffects({
          selected,
          aanvrager1,
          aanvrager2,
          touched: touched.producten ? touched.producten[selected] ?? null : null
        })}
      />

      <Card title="Algemeen">
        <Product
          selected={selected}
          situatie={situatie}
          kenmerken={productKenmerken}
          productSoort="overige"
          isStarterslening={false}
        />
      </Card>

      <Card title="Heffing bij">
        <Heffing selected={selected} />
      </Card>

      <Card title="Correctie">
        <Correctie selected={selected} />
      </Card>

      <Card title="Gegevens">
        <OverigePostenGegevens selected={selected} />
      </Card>

      <Card title="Verrekenen">
        <Verrekenen selected={selected} />
      </Card>
    </CardWrapper>
  );
};

export default connect<Props, OverigePostenType>(OverigePostDetails);
