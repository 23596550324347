import { FiscaleVoortzettingOptions } from "../../.generated/forms/formstypes";
import { createISWSideEffect, initISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";
import { addMonths, addYears } from "../../shared/utils/dates";
import { hasValue } from "../../shared/utils/helpers";
import { fiscaleGegevensSideEffects } from "../infra/determine-hypotheek-fiscale-gegevens-side-effects";
import { hypotheekFiscaleRegelingSchema, pandSchema } from "../infra/hypotheek-schema";
import { HypotheekVergelijkenModalType } from "./hypotheek-vergelijken-types";
import { SituatieSoort } from "../../producten-overzicht/infra/producten-overzicht-types";

export type Context = {
  selected: number,
  situatie: SituatieSoort
};

const HypotheekVergelijkenModalSideEffects = createISWSideEffect<HypotheekVergelijkenModalType, Context | null>(
  (bag): void => {
    const { draft, prev, has, subset, context } = bag;

    const rentevastPeriodeEinddatumBepalen = (selected: number): void => {
      // Berekenen einddatum a.d.h.v. ingangsdatum en rentevastperiode
      if (
        draft.hypotheekSamenstelling[selected].product.ingangsdatum &&
        (has.hypotheekSamenstelling[selected].leningdeelgegevens.rentevastPeriodeJaar.changed ||
          has.hypotheekSamenstelling[selected].product.ingangsdatum.changed)
      ) {
        const geselecteerdeHypotheeksamenstellingen = draft.hypotheekSamenstelling[selected];

        geselecteerdeHypotheeksamenstellingen.leningdeelgegevens.einddatum = geselecteerdeHypotheeksamenstellingen
          .product.ingangsdatum
          ? addYears(
              geselecteerdeHypotheeksamenstellingen.product.ingangsdatum,
              Number(geselecteerdeHypotheeksamenstellingen.leningdeelgegevens.rentevastPeriodeJaar) || 0
            )
          : null;
      }
    };

    const productEinddatumBepalen = (selected: number): boolean => {
      const prevProduct = prev.hypotheekSamenstelling[selected].product;
      const draftProduct = draft.hypotheekSamenstelling[selected].product;

      const prevLooptijdJaren = prevProduct.looptijd ? prevProduct.looptijd.jaren : null;
      const prevLooptijdMaanden = prevProduct.looptijd ? prevProduct.looptijd.maanden : null;

      if (
        draftProduct.ingangsdatum &&
        draftProduct.looptijd &&
        (!prev ||
          draftProduct.looptijd.jaren !== prevLooptijdJaren ||
          draftProduct.looptijd.maanden !== prevLooptijdMaanden)
      ) {
        const jaren = draftProduct.looptijd.jaren || 0;
        const maanden = draftProduct.looptijd.maanden || 0;

        if (maanden > 11) {
          return false;
        }
        const newEinddatum = addMonths(addYears(draftProduct.ingangsdatum, jaren), maanden);
        if (!newEinddatum.equals(draftProduct.einddatum)) {
          draftProduct.einddatum = newEinddatum;
        }
        return true;
      }
      return false;
    };

    const geselecteerdProductIdBepalen = (selected: number): void => {
      if (
        has.hypotheekSamenstelling[selected].fiscaleRegeling.productId.changed &&
        draft.hypotheekSamenstelling[selected].fiscaleRegeling &&
        draft.hypotheekSamenstelling[selected].fiscaleRegeling?.productId
      ) {
        const key = draft.hypotheekSamenstelling[selected].fiscaleRegeling?.productId as FiscaleVoortzettingOptions;
        draft.hypotheekSamenstelling[selected].fiscaleRegeling = {
          ...hypotheekFiscaleRegelingSchema.default(),
          fiscaleVoortzetting: key,
          productId:
            draft.hypotheekSamenstelling[selected].fiscaleRegeling?.productId ||
            hypotheekFiscaleRegelingSchema.default().productId
        };

        if (!FiscaleVoortzettingOptions[key]) {
          const selectedOption = draft.fiscaleVoortzettingKeuzes
            ? draft.fiscaleVoortzettingKeuzes.find(
                keuze => keuze.productId === draft.hypotheekSamenstelling[selected].fiscaleRegeling?.productId
              )
            : null;
          const fiscaleRegeling = draft.hypotheekSamenstelling[selected].fiscaleRegeling;
          if (selectedOption && selectedOption.productkenmerken && fiscaleRegeling) {
            const kenmerken = selectedOption.productkenmerken;

            fiscaleRegeling.productId = kenmerken.productId || "";
            fiscaleRegeling.fiscaleVoortzetting = FiscaleVoortzettingOptions.VoortgezetProduct;
            fiscaleRegeling.fiscaleTypering = kenmerken.fiscaleTypering;
            fiscaleRegeling.einddatum = kenmerken.einddatum;
            fiscaleRegeling.premieLopendJaarBedrag = kenmerken.premieLopendJaarBedrag;
            fiscaleRegeling.polisnummer = kenmerken.polisnummer;
            fiscaleRegeling.oorspronkelijkeIngangsdatum = kenmerken.oorspronkelijkeIngangsdatum;
            fiscaleRegeling.lijfrenteclausuleOrigineel = kenmerken.lijfrenteclausuleOrigineel;
            fiscaleRegeling.laagstePremieooitBedrag = kenmerken.laagstePremieooitBedrag;
            fiscaleRegeling.kapitaalopbouw = kenmerken.kapitaalopbouw;
            fiscaleRegeling.ingebrachteWaardeBedrag = kenmerken.ingebrachteWaardeBedrag;
            fiscaleRegeling.ingangsdatumBox1 = kenmerken.ingangsdatumBox1;
            fiscaleRegeling.huidigeJaarPremieBedrag = kenmerken.huidigeJaarPremieBedrag;
            fiscaleRegeling.hoogstePremieOoitBedrag = kenmerken.hoogstePremieOoitBedrag;
            fiscaleRegeling.garantieverzekering = kenmerken.garantieverzekering;
            fiscaleRegeling.fiscaalRegime = kenmerken.fiscaalRegime;
            fiscaleRegeling.externeMaatschappijOmschrijving = kenmerken.externeMaatschappijOmschrijving;
            fiscaleRegeling.externeMaatschappijCode = kenmerken.externeMaatschappijCode;
            fiscaleRegeling.eerdereUitkeringenBedrag = kenmerken.eerdereUitkeringenBedrag;
            fiscaleRegeling.doelkapitaalBedrag = kenmerken.doelkapitaalBedrag;
          }
        }
      }
    };

    if (
      context &&
      hasValue(context.selected) &&
      prev.hypotheekSamenstelling[context.selected] &&
      draft.hypotheekSamenstelling[context.selected]
    ) {
      const selectedProduct = draft.hypotheekSamenstelling[context.selected];
      const pandId =
        selectedProduct.hypotheekProductDetails && selectedProduct.hypotheekProductDetails.hypotheekOpWoning !== ""
          ? selectedProduct.hypotheekProductDetails.hypotheekOpWoning
          : draft.panden[0].pandId;
      const totaalBedrag = (draft.panden.find(x => x.pandId === pandId) || pandSchema.default()).totaleHypotheekBedrag;
      fiscaleGegevensSideEffects(
        subset.hypotheekSamenstelling[context.selected].createWithContext({
          eigenWoningSchuld: totaalBedrag,
          fiscaleVoortzettingKeuzes: draft.fiscaleVoortzettingKeuzes,
          situatie: context.situatie,
          panden: draft.panden
        })
      );

      productEinddatumBepalen(context.selected);
      rentevastPeriodeEinddatumBepalen(context.selected);
      geselecteerdProductIdBepalen(context.selected);
    }

    if (has.voorwaardeSelectie.offerte.minimaleOfferteGeldigheidsDuur.changed) {
      if (draft.voorwaardeSelectie.offerte && !draft.voorwaardeSelectie.offerte.minimaleOfferteGeldigheidsDuur) {
        draft.voorwaardeSelectie.offerte.inclusiefVerlengingsduur = false;
        draft.voorwaardeSelectie.offerte.geldigheidsduurMinimaal = null;
      }

      if (draft.voorwaardeSelectie.offerte && draft.voorwaardeSelectie.offerte.minimaleOfferteGeldigheidsDuur) {
        draft.voorwaardeSelectie.offerte.inclusiefVerlengingsduur = true;
      }
    }

    // bij wijzigingen in stap-1 of stap-2 (waar deze SE gebruikt wordt), resultaten api legen.
    if (has.hypotheekSamenstelling.changed || has.voorwaardeSelectie.changed) {
      draft.resultaten = [];
    }

    if (has.panden.changed) {
    }
  }
);

export const syncHypotheekVergelijkenModalSideEffects = initISWSideEffect<
  HypotheekVergelijkenModalType,
  Context | null
>(HypotheekVergelijkenModalSideEffects);
