import React, { ReactElement, useMemo } from "react";
import { FormikProps } from "formik";

import AdviesBoxFormik from "../../shared/utils/adviesbox-formik";

import Modal from "../../shared/components/modal/Modal";

import { verzekerdBedragModalSchema, VerzekerdBedragModalType } from "../infra/aov-schema";

import {
  ScenarioCard,
  ScenarioWrappers,
  ScenarioInputParams
} from "../../shared/components/scenario-input/scenario-card";
import { Label, CurrencyInput } from "adviesbox-shared";

type VerzekerdBedragModalProps = {
  aanvangsjaar?: number;
  data: VerzekerdBedragModalType;
  onSave?: (data: VerzekerdBedragModalType) => void;
  closeModal?: () => void;
};

const scenarioHeaderWrapper = (columnIndex: number): ReactElement => {
  return (
    <div className="d-flex flex-wrap scenario-container" key={`header-${columnIndex}`}>
      <div className="pr-2 col-6">Jaar</div>
      <div className="pr-1 col-6">Bedrag</div>
    </div>
  );
};

export const VerzekerdBedragModal = ({
  aanvangsjaar,
  data,
  onSave,
  closeModal
}: VerzekerdBedragModalProps): ReactElement => {
  const scenarioCardWrapper = useMemo(
    () => (itemIndex: number, _entries: number, _scenarioInputParams: ScenarioInputParams): ReactElement => {
      return (
        <div className="d-flex flex-wrap scenario-container" key={itemIndex}>
          <div className="pr-2 col-6">
            <Label
              caption={`${itemIndex + 1} (${data.scenario[itemIndex].jaartal})`}
              name={`scenario[${itemIndex}].bedrag`}
            />
          </div>
          <div className="pr-1 col-6">
            <CurrencyInput name={`scenario[${itemIndex}].bedrag`} fieldSize="no-size" readonly={true} />
          </div>
        </div>
      );
    },
    [data]
  );

  const scenarioWrappers: ScenarioWrappers = useMemo(
    () => ({
      scenarioCardWrapper: scenarioCardWrapper,
      scenarioHeaderWrapper: scenarioHeaderWrapper
    }),
    [scenarioCardWrapper]
  );

  return (
    <AdviesBoxFormik<VerzekerdBedragModalType>
      initialValues={{ ...data }}
      validationSchema={verzekerdBedragModalSchema}
      onSave={onSave}
      closeModal={closeModal}
      render={({ submitForm }: FormikProps<VerzekerdBedragModalType>): ReactElement => {
        const body = (
          <div className="scenario-read-only">
            <ScenarioCard name="scenario" scenarioWrappers={scenarioWrappers} captionStart={aanvangsjaar} />
          </div>
        );

        return (
          <Modal
            title="Verloop van verzekerd bedrag"
            body={body}
            onSubmitClick={submitForm}
            onCancelClick={closeModal}
          />
        );
      }}
    />
  );
};
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production")
  VerzekerdBedragModal.displayName = "VerzekerdBedragModal";
