import BoeterenteModal from "../boetrente-modal/boeterente-modal";
import React, { ReactElement } from "react";
import { FormikContextType, connect } from "formik";
import { LabeledCurrencyInput, Icon, optellen } from "adviesbox-shared";
import { ModalButton } from "adviesbox-shared";
import { FinancieringsbehoefteState } from "../infra/financieringsbehoefte-types";
import { getFinancieringsbehoefteTextResources } from "../infra/financieringsbehoefte-resources";

const Boeterente = ({
  formik: { values, setFieldValue }
}: {
  formik: FormikContextType<FinancieringsbehoefteState>;
}): ReactElement => {
  return (
    <LabeledCurrencyInput
      caption="Boeterente"
      name="financieringsopzet.boeterenteBedrag"
      tooltip={getFinancieringsbehoefteTextResources("TooltipBoeterente")}
      readonly={true}
      appendChildren={
        <ModalButton
          parent="financieringsopzet.boeterenteModal"
          aria-label="Boeterente button"
          content={<Icon name="specificatie" alt="Boeterente" />}
          size="lg"
        >
          <BoeterenteModal
            data={values.financieringsopzet.boeterenteModal}
            onSave={(result): void => {
              setFieldValue("financieringsopzet.boeterenteModal", result.modal);
              setFieldValue(
                "financieringsopzet.boeterenteBedrag",
                optellen(result.modal.map((x): number | null => x.boeterente))
              );
            }}
          />
        </ModalButton>
      }
    />
  );
};

export default connect<{}, FinancieringsbehoefteState>(Boeterente);
