import {
  HypotheekOversluiten,
  HypotheekOversluiten as HypotheekOversluitenDlEntry,
  HypotheekOversluitenHuidigeHypotheek,
  HypotheekOversluitenOutput as HypotheekOversluitenDlOutput
} from "../../.generated/forms/formstypes";
import { mapKlantnaamDl2Ui } from "../../shared/generic-parts/klantnaam/map-dl-2-ui";
import { mapKlantnaamUi2Dl } from "../../shared/generic-parts/klantnaam/map-ui-2-dl";
import { FieldMap, UiName } from "../../shared/types";
import { createMapToUi } from "../../shared/utils/create-map-to-ui";
import { target2field } from "../../shared/utils/target-to-field";
import {
  hypotheekOversluitenSchema,
  HypotheekOversluitenState,
  templateHuidigeSituatieSchema,
  toetsrenteSchema,
  uitgangspuntenSchema
} from "./hypotheek-oversluiten-schema";
import { mapStringToLocalDate } from "adviesbox-shared";

const mapHuidigeSituatie = createMapToUi(templateHuidigeSituatieSchema).from<HypotheekOversluitenHuidigeHypotheek>({
  brutoRentelasten: v => v.brutoRentelastenBedrag,
  corrBrutoRentelasten: v => v.gecorrigeerdeBrutoRentelastenBedrag,
  eindeRvp: v => mapStringToLocalDate(v.eindeRentevastperiode),
  hypotheekbedrag: v => v.leningdeelBedrag,
  hypotheekrente: v => v.rentepercentage,
  maatschappijnaam: v => v.maatschappij,
  rentevastperiode: v => v.rentevastperiodeInMaanden
});

const mapUitgangspunten = createMapToUi(uitgangspuntenSchema).from<HypotheekOversluiten>({
  boeterente: v => v.boeterenteBedrag,
  gewensteHypotheek: v => v.gewensteHypotheekBedrag,
  huidigeSituatie: v => v.huidigeSituatie?.map(huidigeSituatie => mapHuidigeSituatie(huidigeSituatie)),
  marktwaarde: v => v.marktwaardeBedrag,
  totaleBrutoRentelast: v => v.totaleBrutoRentelast
});

const mapToetsrente = createMapToUi(toetsrenteSchema).from<HypotheekOversluiten>({
  gewensteRentevastperiode: v => v.gewensteRentevastperiodeInJaren
});

const mapHypotheekOverluiten = createMapToUi(hypotheekOversluitenSchema).from<HypotheekOversluitenDlEntry>({
  berekendeHypotheken: () => [],
  dataHasChanged: () => false,
  toetsrente: v => mapToetsrente(v),
  uitgangspunten: v => mapUitgangspunten(v),
  organisatieData: () => null,
  sortedBerekendeHypotheken: () => [],
  extraPdfVelden: _ => null,
  aanvrager1: v => (v.aanvrager1 ? mapKlantnaamDl2Ui(v.aanvrager1) : null),
  aanvrager2: v => (v.aanvrager2 ? mapKlantnaamDl2Ui(v.aanvrager2) : null)
});

export function mapHypotheekOversluitenDlToUi(
  hypotheekOversluitenId: string,
  data: HypotheekOversluitenDlOutput
): HypotheekOversluitenState | null {
  const hypotheekOversluiten =
    data && data.hypotheekOversluiten ? data.hypotheekOversluiten[hypotheekOversluitenId] : null;

  if (hypotheekOversluiten) {
    return mapHypotheekOverluiten(hypotheekOversluiten);
  }
  return null;
}

export function mapHypotheekOversluitenUiToDl(
  hypotheekOversluiten: HypotheekOversluitenState
): HypotheekOversluitenDlEntry {
  return {
    gewensteRentevastperiodeInJaren: hypotheekOversluiten.toetsrente.gewensteRentevastperiode,
    marktwaardeBedrag: null,
    gewensteHypotheekBedrag: null,
    boeterenteBedrag: null,
    huidigeSituatie: null,
    advieskostenBedrag: null,
    aanvrager1: mapKlantnaamUi2Dl(hypotheekOversluiten.aanvrager1),
    aanvrager2: mapKlantnaamUi2Dl(hypotheekOversluiten.aanvrager2),
    bemiddelingsvergoedingBedrag: null,
    dossiervergoedingBedrag: null,
    nhgkostenBedrag: null,
    notariskostenBedrag: null,
    taxatiekostenBedrag: null,
    totaleBrutoRentelast: 0
  };
}

export function mapDlTargetToHypotheekOversluitenUiField(target: string): UiName | null {
  const map: FieldMap<HypotheekOversluitenDlEntry, UiName> = {};

  return target2field(map, target);
}
