import { RadioInputGroup, TextInput } from "adviesbox-shared";
import React, { ReactElement } from "react";
import { getKlantprofielResources } from "../../klantprofiel-resources";
import { QuestionSpecCombined, QuestionType } from "../../klantprofiel-schema";
import classNames from "classnames";

type QuestionRadioMetTextProps = {
  data: QuestionSpecCombined;
};
export const QuestionRadioMetText = ({ data }: QuestionRadioMetTextProps): ReactElement => {
  if (data.type !== QuestionType.radioMetText) return <>{`invalid QuestionType: ${data.type}`}</>;

  const opties = data.schema.antwoorden.map((a, i) => {
    return {
      appendChildren: a.omschrijving?.includes("<vrije invoer>") ? (
        <div className="d-flex flex-wrap ml-2 mt-1">
          <TextInput
            name={`vragen[${data.index}].antwoorden[${i}].waarde2`}
            data-testid={`radio-text-element-${data.schema.code}`}
            type="text"
            fieldSize="l"
          />
        </div>
      ) : (
        <></>
      ),
      label: a.omschrijving?.replace("<vrije invoer>", "").trim() ?? "",
      value: a.code.toString()
    };
  });
  const name = `vragen[${data.index}].gekozenAntwoord`;

  return (
    <>
      <div className={classNames({ "mt-3": data.schema.omschrijving })}>
        {data.schema.omschrijving && <h2>{data.schema.omschrijving}</h2>}
        <span className="d-block font-italic px-1">{getKlantprofielResources("EenAntwoord")}</span>
        <RadioInputGroup name={name} options={opties} key={`key-${data.index}`} />
      </div>
    </>
  );
};
