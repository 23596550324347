import { FormikContextType } from "formik";
import React, { ReactElement } from "react";
import { Modal } from "react-bootstrap";
import { getTaxatieResources } from "../infra/taxatie-resources";
import { TaxatieSchermType, WaarschuwingType } from "../infra/taxatie-scherm-schema";
import classes from "./missendeGegevensModal.module.scss";

export const MissendegegevensModal = ({
  modalType,
  gemarkeerdeVeldenTekstTonen = true,
  extraText,
  closeModal,
  onClickSubmit
}: {
  modalType: WaarschuwingType;
  gemarkeerdeVeldenTekstTonen?: boolean;
  extraText?: string;
  onClickSubmit: () => void;
  formik?: FormikContextType<TaxatieSchermType>;
  closeModal?: () => void;
}): ReactElement => {
  return (
    <>
      <Modal.Header>
        <div className="d-flex w-100">
          <Modal.Title>Ontbrekende gegevens</Modal.Title>
          <button type="button" className={"close ml-auto"} onClick={closeModal} id={`btnClose-ontbrekende-gegevens`}>
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="px-2">
          <div>{extraText}</div>
          {gemarkeerdeVeldenTekstTonen && <div>{getTaxatieResources("ontbrekenVelden")}</div>}
        </div>
      </Modal.Body>
      <Modal.Footer className={classes.modal_footer}>
        <button
          type="button"
          className="btn btn-primary"
          id="btnSluiten-ontbrekende-gegevens"
          onClick={() => {
            onClickSubmit();
            closeModal && closeModal();
          }}
        >
          Sluiten
        </button>
      </Modal.Footer>
    </>
  );
};

/* istanbul ignore else */ if (process.env.NODE_ENV !== "production")
  MissendegegevensModal.displayName = "MissendegegevensModal";
