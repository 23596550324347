import {
  Financieringsbehoefte,
  Financieringsbehoefte as FinancieringsbehoefteDlEntry,
  FinancieringsbehoefteFinancieringsopzet,
  Belastingbox,
  Financieringsoort,
  FinancieringsbehoeftenOutput as FinancieringsbehoefteDlOutput,
  FinancieringsbehoeftePand,
  GebruikPandSoort as PandGebruikPand,
  SpecificatieNettoVerkoopopbrengst,
  BoeterenteBerekeningswijzeOpties
} from "../../.generated/forms/formstypes";
import { Berekeningen, Tarieven } from "../../.generated/instellingen-forms/instellingen-formstypes";
import {
  mapJaarMaandInputDl2Ui,
  mapJaarMaandInputFromLooptijdDl2Ui
} from "../../shared/generic-parts/jaar-maand/map-dl-2-ui";
import { mapBerekenInput } from "../../shared/types";
import { createMapToUi } from "../../shared/utils/create-map-to-ui";
import { optellen } from "../../shared/utils/currency";
import { prefixWithZero } from "../../shared/utils/helpers";
import {
  advieskostenBemiddelingsvergoedingModalSchema,
  afkoopErfpachtModelSchema,
  andereFinancieringenModalSchema,
  bankgarantieModalSchema,
  eigenwoningschuldModalSchema,
  erfpachtSchema,
  financieringOnderpandSchema,
  financieringsbehoefteSchema,
  financieringSchema,
  financieringsopzetSchema,
  inbrengEigenGeldModalSchema,
  instellingenTarievenSchema,
  koopsomSchema,
  overbruggingskredietModalSchema,
  overigeFinancieringskostenModalSchema,
  overigeKostenInHypotheekModalSchema,
  overwaardeSchema,
  pandOpbrengstSchema,
  renteverliesTijdensBouwModalSchema,
  standaardStartersleningBedrag,
  totaleRestschuldModalSchema,
  verbouwingVerbeteringModalSchema,
  vermogenSchema
} from "./financieringsbehoefte-schema";
import {
  Boeterente,
  FinancieringsbehoefteState,
  NettoVerkoopPandOpbrengst,
  RenteverliesMaandelijks,
  VerbouwingVerbeteringSpecificatie
} from "./financieringsbehoefte-types";
import { getWozWaardeLink, mapNhgWarning } from "./map-financieringsbehoefte-helper";
import { convertUtcToTimeZone, mapStringToLocalDate, getDifferenceYearsMonths, JaarMaandInputType } from "adviesbox-shared";
import { LocalDate } from "@js-joda/core";

const calcAdvieskosten = (v: Financieringsbehoefte): number => {
  const advieskosten = v.advieskostenBedrag ? v.advieskostenBedrag - (v.advieskostenGespreidBedrag ?? 0) : 0;

  const bemiddelingsvergoeding = v.bemiddelingsvergoedingGeldleningBedrag
    ? v.bemiddelingsvergoedingGeldleningBedrag - (v.bemiddelingsvergoedingGeldleningGespreidBedrag ?? 0)
    : 0;

  const dossiervergoedingBedrag = v.dossiervergoedingBedrag ?? 0;

  const overige = v.overigeBemiddelingsvergoedingBedrag
    ? v.overigeBemiddelingsvergoedingBedrag - (v.overigeBemiddelingsvergoedingGespreidBedrag ?? 0)
    : 0;

  const totaal = advieskosten + bemiddelingsvergoeding + overige + dossiervergoedingBedrag;
  return totaal;
};

type NettoVerkoopType = {
  totaleVerkoopPrijs: number | null;
  nettoVerkoopOpbrengst: number | null;
};
const calcNettoVerkoop = (v: Financieringsbehoefte): NettoVerkoopType =>
  (v.specificatiesNettoVerkoopopbrengst ?? [])
    .map(item => ({
      verkoopPrijs: item.verkoopprijsBedrag,
      nettoOpbrengst:
        !!item.verkoopprijsBedrag || !!item.verkoopkostenBedrag
          ? (item.verkoopprijsBedrag ?? 0) - (item.verkoopkostenBedrag ?? 0)
          : null
    }))
    .reduce(
      (prev, curr): NettoVerkoopType => {
        return {
          totaleVerkoopPrijs:
            !!prev.totaleVerkoopPrijs || !!curr.verkoopPrijs
              ? (prev.totaleVerkoopPrijs ?? 0) + (curr.verkoopPrijs ?? 0)
              : null,
          nettoVerkoopOpbrengst:
            !!prev.nettoVerkoopOpbrengst || !!curr.nettoOpbrengst
              ? (prev.nettoVerkoopOpbrengst ?? 0) + (curr.nettoOpbrengst ?? 0)
              : null
        } as NettoVerkoopType;
      },
      {
        totaleVerkoopPrijs: null,
        nettoVerkoopOpbrengst: null
      } as NettoVerkoopType
    );

const calcRestantschuld = (v: Financieringsbehoefte): number | null =>
  !!v.restantSchuldBox1Bedrag || !!v.restantSchuldBox3Bedrag
    ? (v.restantSchuldBox1Bedrag || 0) + (v.restantSchuldBox3Bedrag || 0)
    : null;

const calcOverwaarde = (v: Financieringsbehoefte): number | null => {
  const nettoVerkoopOpbrengst = calcNettoVerkoop(v).nettoVerkoopOpbrengst;
  const restantSchuld = calcRestantschuld(v);
  if (!nettoVerkoopOpbrengst && !restantSchuld) return null;
  return (nettoVerkoopOpbrengst ?? 0) > (restantSchuld ?? 0) ? (nettoVerkoopOpbrengst ?? 0) - (restantSchuld ?? 0) : 0;
};

const calcWaardeInbrengOpgebouwdeWaarde = (v: FinancieringsbehoefteFinancieringsopzet): number => {
  const eigenGeldInbrengTotaalBedrag = (v.eigenGeldInbrengBedrag ?? 0) + (v.eigenGeldInbrengOverig1Bedrag ?? 0);
  const nettoRestSchuldBedrag =
    (v.restschuldTotaalBedrag ?? 0) + (v.extraKostenHuidigBedrag ?? 0) - (v.eigenGeldRestschuldBedrag ?? 0);

  const minResult = Math.min(eigenGeldInbrengTotaalBedrag, nettoRestSchuldBedrag);

  return Math.max(minResult, 0);
};

export function inbrengOpgebouwdeWaardeBerekendBedrag(
  eigenGeldInbrengBedrag: number,
  eigenGeldInbrengOverigBedrag: number,
  restSchuldTotaalBedrag: number,
  extraKostenHuidigBedrag: number,
  eigenGeldRestschuldBedrag: number
): number {
  const eigenGeldInbrengTotaalBedrag: number = eigenGeldInbrengBedrag + eigenGeldInbrengOverigBedrag;

  const nettoRestSchuldBedrag: number = restSchuldTotaalBedrag + extraKostenHuidigBedrag - eigenGeldRestschuldBedrag;
  const minResult = Math.min(eigenGeldInbrengTotaalBedrag, nettoRestSchuldBedrag);

  return Math.max(minResult, 0);
}

function mapNettoPandOpbrengstDlNaarUi(values: SpecificatieNettoVerkoopopbrengst): NettoVerkoopPandOpbrengst {
  return pandOpbrengstSchema.cast({
    nettoOpbrengst:
      ((values.verkoopprijsBedrag ? values.verkoopprijsBedrag : 0) -
        (values.verkoopkostenBedrag ? values.verkoopkostenBedrag : 0)) *
      ((values.eigendom1Percentage ? values.eigendom1Percentage / 100 : 0) +
        (values.eigendom2Percentage ? values.eigendom2Percentage / 100 : 0)),
    pandNaam: values.adres
      ? `${values.adres.straat} ${values.adres.huisnummer} ${values.adres.toevoeging}`
      : "[Adres onbekend]",
    percentageEigendom: values.eigendom2Percentage
      ? `( ${values.eigendom1Percentage}% + ${values.eigendom2Percentage}%) = ${(values.eigendom1Percentage ?? 0) +
          (values.eigendom2Percentage ?? 0)}%`
      : ` ${values.eigendom1Percentage} % `,
    verkoopKosten: values.verkoopkostenBedrag,
    verkoopPrijs: values.verkoopprijsBedrag,
    subtotaal:
      (values.verkoopprijsBedrag ? values.verkoopprijsBedrag : 0) -
      (values.verkoopkostenBedrag ? values.verkoopkostenBedrag : 0)
  });
}

function bepaalResterendePeriode(datumBoeterente:LocalDate|null, einddatumRentevastperiode:LocalDate|null):JaarMaandInputType{
  if (datumBoeterente===null || einddatumRentevastperiode===null) return {jaren:0, maanden:0};

  const differenceYearMonths = getDifferenceYearsMonths(
   datumBoeterente || LocalDate.now(),
   einddatumRentevastperiode || LocalDate.now(),
    true
  );

  return {jaren: Math.max(differenceYearMonths.year, 0), maanden: Math.max(differenceYearMonths.month, 0)};
}

function mapBoeterenteDl2Ui(values: FinancieringsbehoefteDlEntry): Boeterente[] {
  return values && values.teBeeindigenLeningdelen
    ? values.teBeeindigenLeningdelen.map(
        (value): Boeterente => ({
          leningdeelId: value.leningdeelId,
          boeterenteId: value.boeterenteId,
          maatschappij: value.maatschappijOmschrijving,
          berekenwijzeBoeterente: value.boeterenteBerekeningswijze || BoeterenteBerekeningswijzeOpties.Contant,
          aflosvorm: value.aflossingsvorm,
          oorspronkelijkeHoofdsom: value.oorspronkelijkeHoofdsomBedrag,
          restantHoofdsom: value.restantHoofdsomBedrag,
          restantLooptijd: mapJaarMaandInputFromLooptijdDl2Ui(value.restantLooptijdInMaanden),
          einddatumRentevastperiode: mapStringToLocalDate(value.rentevastperiodeEinddatum),
          datumBoeterente: mapStringToLocalDate(value.boeterenteDatum),
          resterendePeriodeWeergeven: !value.resterendePeriodeWeergeven,
          resterendePeriode: value.resterendePeriodeWeergeven 
            ? mapJaarMaandInputDl2Ui(value.resterendePeriodeJaren, value.resterendePeriodeMaanden) 
            : bepaalResterendePeriode(mapStringToLocalDate(value.boeterenteDatum), mapStringToLocalDate(value.rentevastperiodeEinddatum)),
          boetevrijePercentage: value.boetevrijPercentage,
          huidigeContractrente: value.huidigRentePercentage,
          nieuwRentepercentage: value.nieuwRentePercentage,
          opgewbouwdewaardeBedrag: value.opgebouwdeWaarde,
          boeterente: value.boeterentebedrag,
        })
      )
    : [];
}

export const mapFinancieringsopzetInstellingenTarievenToUi = createMapToUi(instellingenTarievenSchema).from<Tarieven>({
  hypothecaireInschrijvingVastBedrag: v => v.hypothecaireInschrijvingVastBedrag,
  hypothecaireInschrijvingPercentage: v => v.hypothecaireInschrijvingPercentage,
  hypothecaireInschrijvingVaststellingWaarde: v => v.hypothecaireInschrijvingVaststellingWaarde,
  hypotheekakteVastBedrag: v => v.hypotheekakteVastBedrag,
  hypotheekakteVaststellingWaarde: v => v.hypotheekakteVaststellingWaarde,
  leveringsakteVastBedrag: v => v.leveringsakteVastBedrag,
  leveringsakteVaststellingWaarde: v => v.leveringsakteVaststellingWaarde,
  taxatiekostenVastBedrag: v => v.taxatiekostenVastBedrag,
  taxatiekostenVaststellingWaarde: v => v.taxatiekostenVaststellingWaarde
});

const mapErfpacht = createMapToUi(erfpachtSchema)
  .with<string | null>()
  .from<FinancieringsbehoeftePand>({
    eeuwigdurendErfpacht: v => v.erfpachtEeuwigdurend,
    einddatum: v => mapStringToLocalDate(v.erfpachtEinddatum),
    erfpacht: v => v.erfpacht,
    jaarlijksCanon: v => v.erfpachtBedrag,
    particulierErfpacht: v => v.erfpachtParticulier,
    passeerdatum: (_, passeerdatum) => mapStringToLocalDate(passeerdatum)
  });

const mapFinanciering = createMapToUi(financieringSchema).from<FinancieringsbehoefteFinancieringsopzet>({
  doorlopendeLeningdelenBedrag: v => v.doorlopendeLeningdelenBedrag,
  kostenKoper: v => v.kostenKoper,
  soortFinanciering: v => v.soortFinanciering,
  soortNieuwbouw: v => prefixWithZero(v.soortNieuwbouw),
  soortZelfbouw: v => prefixWithZero(v.soortZelfbouw),
  heeftDoorlopendeLeningdelen: v => v.heeftDoorlopendeLeningdelen,
  analyseHeeftLeningdelen: v => v.analyseHeeftLeningdelen
});

const mapAdvieskostenBemiddelingsvergoedingModal = createMapToUi(advieskostenBemiddelingsvergoedingModalSchema).from<
  Financieringsbehoefte
>({
  advieskosten: v => v.advieskostenBedrag,
  advieskostenGespreid: v => v.advieskostenGespreidBedrag,
  bemiddelingsvergoedingGeldlening: v => v.bemiddelingsvergoedingGeldleningBedrag,
  bemiddelingsvergoedingGeldleningGespreid: v => v.bemiddelingsvergoedingGeldleningGespreidBedrag,
  dossiervergoedingGeldlening: v => v.dossiervergoedingBedrag,
  gespreidBetalen: v => v.gespreidBetalen,
  gespreidBetalenOver: v => v.gespreidBetalenDuurInMaanden,
  overigeBemiddelingsvergoeding: v => v.overigeBemiddelingsvergoedingBedrag,
  overigeBemiddelingsvergoedingGespreid: v => v.overigeBemiddelingsvergoedingGespreidBedrag
});

const mapAfkoopErfpachtModal = createMapToUi(afkoopErfpachtModelSchema).from<FinancieringsbehoefteFinancieringsopzet>({
  afkoopBedrag: v => v.erfpachtAfkoopBedrag,
  afkoopEinddatum: v => mapStringToLocalDate(v.erfpachtAfgekochtTotDatum)
});

const mapAndereFinancieringenModal = createMapToUi(andereFinancieringenModalSchema).from<
  FinancieringsbehoefteFinancieringsopzet
>({
  doorlopendeHypotheken: v => v.doorlopendeLeningdelenBedrag,
  kortlopendeLeningKrediet: v => v.inbrengKredietBedrag,
  overigeFinanciering1Bedrag: v => v.overigeFinanciering1Bedrag,
  overigeFinanciering1Omschrijving: v => v.overigeFinanciering1Omschrijving,
  overigeFinanciering2Bedrag: v => v.overigeFinanciering2Bedrag,
  overigeFinanciering2Omschrijving: v => v.overigeFinanciering2Omschrijving,
  startersleningBedrag: v => v.startersleningBedrag
});

const mapBankgarantieModal = createMapToUi(bankgarantieModalSchema).from<FinancieringsbehoefteFinancieringsopzet>({
  aanvragenBijGeldverstrekker: v => v.bankgarantieAanvragen,
  garantiebedrag: v => v.bankgarantieBedrag,
  kostenBankgarantie: v => v.kostenBankgarantieBedrag
});

const mapEigenwoningschuldModal = createMapToUi(eigenwoningschuldModalSchema).from<Financieringsbehoefte>({
  eigenwoningschuld: v =>
    mapBerekenInput(
      v.financieringsopzet.eigenwoningschuldOvernemen
        ? v.ewsResultaat?.ewsBedrag
        : v.financieringsopzet.eigenwoningschuldBedrag,
      v.financieringsopzet.eigenwoningschuldOvernemen,
      v.ewsResultaat?.ewsBedrag
    ),
  ewsAdviesKosten: v => v.ewsResultaat?.advieskostenAftrekbaarBedrag,
  financieringsKosten: v => v.ewsResultaat?.financieringskostenBedrag,
  financieringsKostenPercentage: v => v.ewsResultaat?.financieringskostenAftrekbaarPercentage,
  financieringskostenAftrekbaarOvernemen: v => v.financieringskostenAftrekbaarOvernemen,
  maximaalTeFinancierenBox1: v => v.ewsResultaat?.maximaalTeFinancierenBox1Bedrag,
  eigenWoningReserveVervreemdingSaldo: v => v.ewsResultaat?.ewrBedrag
});

const mapInbrengEigenGeldModal = createMapToUi(inbrengEigenGeldModalSchema)
  .with<{
    overwaarde: number | null;
    overwaardeTotaal: number | null;
    berekeingenInstellingen: Berekeningen;
    restantschuld: number;
    eigenGeldRestschuldBedrag: number | null;
    meenemenOverbruggingsKrediet: number | null;
  }>()
  .from<FinancieringsbehoefteFinancieringsopzet>({
    inbrengEigenGeld: v => v.inbrengEigenGeldBedrag,
    totaalOverwaarde: (v, c) => c.overwaardeTotaal,
    meenemenInOverbruggingskredietBedrag: (_, c) => c.meenemenOverbruggingsKrediet,
    eigenGeldOverwaarde: (v, c) => {
      const bedrag = c.berekeingenInstellingen?.algemeen.minderingOverwaardeGewensteHypotheek
        ? c.overwaarde
        : v.eigenGeldOverwaardeBedrag;

      return mapBerekenInput(
        bedrag,
        v.eigenGeldOverwaardeOvernemen ?? c.berekeingenInstellingen?.algemeen.minderingOverwaardeGewensteHypotheek,
        c.overwaardeTotaal
      );
    },
    extraAflossing: v => v.eigenGeldExtraAflossing,
    inbrengEigengeldBedrag1: v => v.eigenGeldBedrag1,
    inbrengEigengeldBedrag2: v => v.eigenGeldBedrag2,
    inbrengEigengeldOmschrijving1: v => v.eigenGeldOmschrijving1,
    inbrengEigengeldOmschrijving2: v => v.eigenGeldOmschrijving2,
    inbrengOpgebouwdeWaarde: v => v.eigenGeldInbrengBedrag,
    inbrengPolisBedrag: v => v.eigenGeldInbrengOverig1Bedrag,
    inbrengPolisOmschrijving: v => v.eigenGeldInbrengOverig1Omschrijving,
    inbrengVoorVerlagenRestschuld: (v, c) =>
      v.soortFinanciering &&
      [Financieringsoort.AankoopNieuwbouw, Financieringsoort.AankoopBestaandeBouw].includes(v.soortFinanciering)
        ? c.eigenGeldRestschuldBedrag
        : null,
    waarvanVerlagenRestschuld: v =>
      // check wordt gedaan omdat platform de velden niet reset
      v.soortFinanciering &&
      [Financieringsoort.AankoopNieuwbouw, Financieringsoort.AankoopBestaandeBouw].includes(v.soortFinanciering)
        ? calcWaardeInbrengOpgebouwdeWaarde(v)
        : null
  });

const mapKoopsom = createMapToUi(koopsomSchema).from<FinancieringsbehoefteFinancieringsopzet>({
  grondReedsInBezit: v => v.grondReedsInBezit,
  koopsomBedrag: v =>
    // check wordt gedaan omdat platform de velden niet reset
    v.soortFinanciering &&
    [Financieringsoort.AankoopNieuwbouw, Financieringsoort.AankoopBestaandeBouw].includes(v.soortFinanciering)
      ? v.koopsomBedrag
      : null,
  koopsomBedragNieuwbouw: v =>
    v.soortFinanciering && v.soortFinanciering === Financieringsoort.AankoopNieuwbouw
      ? v.grondReedsInBezit
        ? (v.koopsomBedrag ?? 0) - (v.kostenGrondBedrag ?? 0)
        : v.koopsomBedrag
      : null,
  kostenGrondBedrag: v =>
    // check wordt gedaan omdat platform de velden niet reset
    v.soortFinanciering &&
    [Financieringsoort.AankoopNieuwbouw, Financieringsoort.AankoopBestaandeBouw].includes(v.soortFinanciering)
      ? v.kostenGrondBedrag
      : null
});

const mapOverbruggingskredietModal = createMapToUi(overbruggingskredietModalSchema).from<Financieringsbehoefte>({
  bestaandeWoningInEigendomVerkocht: v => (calcNettoVerkoop(v).totaleVerkoopPrijs ?? 0) > 0,
  einddatum: v => mapStringToLocalDate(v.overbruggingskrediet?.einddatum),
  afsluitkosten: v => v.overbruggingskrediet?.afsluitkostenBedrag,
  brutoRentelastPerMaand: v => v.rentelastenOverbruggingskrediet?.rentelastMaandelijksBrutoBedrag,
  brutoTotaleRentelast: v => v.rentelastenOverbruggingskrediet?.rentelastTotaalBrutoBedrag,
  // TODO: fiscalegegevensDeelBox1Bedrag mapping controleren
  fiscalegegevensDeelBox1Bedrag: v => {
    if (v.pand.gebruikPand === PandGebruikPand.PrimaireWoning) {
      if (v.overbruggingskrediet?.overwaardeMeenemen === true || v.overbruggingskrediet?.overwaardeMeenemen === null) {
        return calcOverwaarde(v);
      }
      return v.overbruggingskrediet?.box1Bedrag;
    }
    return null;
  },
  fiscalegegevensDeelBox1Percentage: v => (v.pand.gebruikPand === PandGebruikPand.PrimaireWoning ? 100 : null),
  // TODO: fiscalegegevensDeelBox3Bedrag mapping controleren
  fiscalegegevensDeelBox3Bedrag: v => {
    if (!v.overbruggingskrediet) {
      if (v.pand.gebruikPand !== PandGebruikPand.PrimaireWoning) {
        return calcOverwaarde(v);
      }
      return null;
    }

    if (
      v.pand.gebruikPand !== PandGebruikPand.PrimaireWoning &&
      (v.overbruggingskrediet.overwaardeMeenemen === true || v.overbruggingskrediet.overwaardeMeenemen === null)
    ) {
      return calcOverwaarde(v);
    }

    if (v.overbruggingskrediet.box1Bedrag === null) {
      return v.overbruggingskrediet.meenemenInOverbruggingBedrag ?? null;
    }

    return null;
  },
  fiscalegegevensDeelBox3Percentage: v => (v.pand.gebruikPand !== PandGebruikPand.PrimaireWoning ? 100 : null),
  hypotheekNorm: v => ({
    jaar: v.hypotheekNorm?.jaar ?? 2019, // TODO: klopt dit hardcoded jaar
    maand: v.hypotheekNorm?.maand ?? 1
  }),
  ingangsdatum: v => mapStringToLocalDate(v.passeerdatum),
  meenemenInOverbruggingskrediet: v =>
    v.overbruggingskrediet ? v.overbruggingskrediet.meenemenInOverbruggingBedrag : null,
  nettoRentelastPerMaand: v => v.rentelastenOverbruggingskrediet?.rentelastMaandelijksNettoBedrag,
  nettoTotaleRentelast: v => v.rentelastenOverbruggingskrediet?.rentelastTotaalNettoBedrag,
  overwaardeHuidigeWoningen: v => calcOverwaarde(v),
  overwaardeMeenemen: v =>
    v.overbruggingskrediet &&
    v.overbruggingskrediet.overwaardeMeenemen &&
    Math.round(v.overbruggingskrediet.meenemenInOverbruggingBedrag ?? 0) === Math.round(calcOverwaarde(v) ?? 0),
  renteOverbruggingskrediet: v => (v.overbruggingskrediet ? v.overbruggingskrediet.rentePercentage : null),
  rentevastAantalMaanden: v => v.overbruggingskrediet?.rentevastAantalMaanden
});

const mapOverigeFinancieringskostenModal = createMapToUi(overigeFinancieringskostenModalSchema).from<
  Financieringsbehoefte
>({
  // TODO: afsluitkostenSVnStarterslening mapping controleren
  afsluitkostenSVnStarterslening: v =>
    v.financieringsopzet.startersleningBedrag || v.financieringsopzet.starterslening
      ? standaardStartersleningBedrag
      : v.financieringsopzet.startersleningAfsluitprovisieBedrag,
  bereidstellingskosten: v => v.financieringsopzet.bereidstellingsprovisieBedrag
});

const mapOverigeKostenInHypotheekModal = createMapToUi(overigeKostenInHypotheekModalSchema).from<
  FinancieringsbehoefteFinancieringsopzet
>({
  gewenstConsumptiefBedrag: v => v.gewenstConsumptiefBedrag,
  lastenOverbruggingskrediet: () => null, // TODO: Er is hier nog geen veld voor in platform
  overigeKostenSpecificaties: v => [
    {
      omschrijving: "Bouwkundige keuring",
      bedrag: v.bouwkundigeKeuringBedrag,
      fiscalePlaatsing: Belastingbox[v.bouwkundigeKeuringBox ?? "Box3"],
      aftrekbaar: v.bouwkundigeKeuringEenmaligAftrekbaar ? true : false
    },
    {
      omschrijving: v.overigeKosten1Omschrijving,
      bedrag: v.overigeKosten1Bedrag,
      fiscalePlaatsing: Belastingbox[v.overigeKosten1Box ?? "Box3"],
      aftrekbaar: v.overigeKosten1EenmaligAftrekbaar ? true : false
    },
    {
      omschrijving: v.overigeKosten2Omschrijving,
      bedrag: v.overigeKosten2Bedrag,
      fiscalePlaatsing: Belastingbox[v.overigeKosten2Box ?? "Box3"],
      aftrekbaar: v.overigeKosten2EenmaligAftrekbaar ? true : false
    },
    {
      omschrijving: v.overigeKosten3Omschrijving,
      bedrag: v.overigeKosten3Bedrag,
      fiscalePlaatsing: Belastingbox[v.overigeKosten3Box ?? "Box3"],
      aftrekbaar: v.overigeKosten3EenmaligAftrekbaar ? true : false
    }
  ],
  royementskostenBedrag: v => v.royementskostenBedrag,
  soortFinanciering: v => v.soortFinanciering
});

const mapRenteverliesTijdensBouwModal = createMapToUi(renteverliesTijdensBouwModalSchema).from<Financieringsbehoefte>({
  bouwdepotbedrag: v => (v.financieringsopzet.koopsomBedrag ?? 0) - (v.financieringsopzet.kostenGrondBedrag ?? 0),
  depotvergoeding: v => v.financieringsopzet.depotvergoedingPercentage,
  duurVanDeBouw: v => v.pand.duurBouwInMaanden,
  grondReedsInBezit: v => v.financieringsopzet.grondReedsInBezit,
  grondkosten: v => v.financieringsopzet.kostenGrondBedrag,
  hypotheekbedrag: v =>
    optellen([
      //financieringsopzet.gewensteHypotheek
      v.gewensteHypotheekBedrag,
      // [Gewenste lening] +

      v.financieringsopzet.doorlopendeLeningdelenBedrag,
      v.financieringsopzet.inbrengKredietBedrag,
      v.financieringsopzet.startersleningBedrag,
      v.financieringsopzet.overigeFinanciering1Bedrag,
      v.financieringsopzet.overigeFinanciering2Bedrag
      // *[Andere financieringen]
      // andereFinancieringenModal.doorlopendeHypotheken,
      // andereFinancieringenModal.kortlopendeLeningKrediet,
      // andereFinancieringenModal.startersleningBedrag,
      // andereFinancieringenModal.overigeFinanciering1Bedrag,
      // andereFinancieringenModal.overigeFinanciering2Bedrag
    ]),
  hypotheekrente: v => v.financieringsopzet.renteverliesHypotheekrentePercentage,
  renteverlies: () => null, // TODO: waar is dit voor en hoort hier een platform veld voor te bestaan?
  renteverliesMaandelijks: v =>
    v.uitbetalingen?.map(
      (r): RenteverliesMaandelijks => ({
        percentage: r.percentage,
        maand: r.maand
      })
    ) ?? [],
  renteverliesMaandelijksTotaal: v =>
    v.uitbetalingen
      ?.map((x): number | null | undefined => x.percentage)
      .reduce((x, y): number => (x ?? 0) + (y ?? 0), 0),
  soortBerekening: v => (v.uitbetalingen?.length ? "Geavanceerd" : "Standaard"),
  stichtingskosten: v =>
    v.uitbetalingen?.length
      ? v.financieringsopzet.stichtingskostenBedrag
      : (optellen([v.financieringsopzet.koopsomBedrag, v.financieringsopzet.meerwerkBedrag]) ?? 0) -
        (v.financieringsopzet.kostenGrondBedrag ?? 0)
});

const mapTotaleRestschuldModal = createMapToUi(totaleRestschuldModalSchema).from<
  FinancieringsbehoefteFinancieringsopzet
>({
  extraKostenHuidigeHypotheek: v => v.extraKostenHuidigBedrag,
  inbrengEigenGeldVoorVerlagenRestschuld: v => v.eigenGeldRestschuldBedrag,
  inbrengOpgebouwdeWaarde: v => v.eigenGeldInbrengBedrag,
  inbrengOpgebouwdeWaardeRestschuld: v =>
    mapBerekenInput(
      v.eigenGeldInbrengRestschuldBedrag,
      v.eigenGeldInbrengRestschuldOvernemen,
      inbrengOpgebouwdeWaardeBerekendBedrag(
        v.eigenGeldInbrengBedrag ?? 0,
        v.eigenGeldInbrengOverig1Bedrag ?? 0,
        v.restschuldTotaalBedrag ?? 0,
        v.extraKostenHuidigBedrag ?? 0,
        v.eigenGeldRestschuldBedrag ?? 0
      )
    ),
  meeTeFinancierenRestschuld: () => 0, // TODO: hardcoded 0, klopt dit?
  restschuldHuidigeWoning: v =>
    mapBerekenInput(
      v.restschuldTotaalBedrag === null ? null : v.restschuldTotaalBedrag - (v?.extraKostenHuidigBedrag ?? 0),
      v.restschuldOvernemen,
      v.restschuldTotaalBerekendBedrag === null
        ? null
        : v.restschuldTotaalBerekendBedrag - (v?.extraKostenHuidigBedrag ?? 0)
    ),
  totaleRestschuld: v => v.restschuldTotaalBedrag
});

const mapVerbouwingVerbeteringModal = createMapToUi(verbouwingVerbeteringModalSchema).from<Financieringsbehoefte>({
  totaal: v =>
    v.specificatiesVerbouwingVerbetering
      ?.map(item => item.werkzaamhedenBedrag)
      .reduce((total, val): number => (total ?? 0) + (val ?? 0), 0),
  totaalVerbouwingVerbetering: v =>
    v.financieringsopzet.soortFinanciering === Financieringsoort.AankoopNieuwbouw
      ? v.financieringsopzet.meerwerkBedrag
      : v.financieringsopzet.verbouwingBedrag,
  verbouwingVerbeteringSpecificaties: v => {
    const verbouwingVerbeteringArray =
      v.specificatiesVerbouwingVerbetering?.map(
        (p): VerbouwingVerbeteringSpecificatie => {
          return {
            verbouwingId: p.verbouwingId,
            werkzaamheden: p.werkzaamheden,
            werkzaamhedenBedrag: p.werkzaamhedenBedrag,
            volgnummer: p.volgnummer
          };
        }
      ) ?? [];

    let index;
    for (index = verbouwingVerbeteringArray.length; index < 10; index++) {
      verbouwingVerbeteringArray.push({
        verbouwingId: null,
        werkzaamheden: null,
        werkzaamhedenBedrag: null,
        volgnummer: index
      });
    }

    return verbouwingVerbeteringArray;
  },
  waarvanAchterstalligOnderhoud: v => v.financieringsopzet.achterstalligOnderhoudBedrag,
  waarvanEnergiebesparendeBudget: v => v.financieringsopzet.energiebespaarbudgetBedrag,
  waarvanEnergiebesparendeVoorzieningen: v => v.financieringsopzet.energiebesparendeMaatregelenBedrag,
  waarvanNietWaardevermeerderend: v => v.financieringsopzet.meerwerkWaardeloosBedrag,
  energieklasseToekomstig: v => prefixWithZero(v.financieringsopzet.energieklasseToekomstig)
});

const mapFinancieringsopzet = createMapToUi(financieringsopzetSchema)
  .with<{ berekeningenInstellingen: Berekeningen; tarievenInstellingen: Tarieven }>()
  .from<Financieringsbehoefte>({
    advieskosten: v => calcAdvieskosten(v),
    advieskostenBemiddelingsvergoedingModal: v => mapAdvieskostenBemiddelingsvergoedingModal(v),
    afTeLossenBestaandeHypotheken: v =>
      mapBerekenInput(
        v.financieringsopzet.hypotheekAflossingBedrag,
        v.financieringsopzet.hypotheekAflossingOvernemen,
        v.financieringsopzet.hypotheekAflossingBerekendBedrag
      ),
    afTeLossenoverigeLeningen: v => v.afTeLossenOverigeLeningenBedrag,
    afkoopErfpacht: v => v.financieringsopzet.erfpachtAfkoopBedrag,
    afkoopErfpachtModal: v => mapAfkoopErfpachtModal(v.financieringsopzet),
    andereFinancieringenModal: v => mapAndereFinancieringenModal(v.financieringsopzet),
    bankgarantie: v => v.financieringsopzet.kostenBankgarantieBedrag,
    bankgarantieModal: v => mapBankgarantieModal(v.financieringsopzet),
    bestaandeWoningInEigendomVerkocht: v => (calcNettoVerkoop(v).totaleVerkoopPrijs ?? 0) > 0,
    bevoorschottingspercentage: v => v.bevoorschottingspercentage,
    boeterenteBedrag: v => v.financieringsopzet.boeterenteBedrag,
    boeterenteModal: v => mapBoeterenteDl2Ui(v),
    bouwrentePeriodeNaAankoop: v =>
      v.financieringsopzet.soortFinanciering === Financieringsoort.AankoopNieuwbouw
        ? v.financieringsopzet.bouwrenteNaAankoopBedrag
        : null,
    bouwrentePeriodeVoorAankoop: v =>
      v.financieringsopzet.soortFinanciering === Financieringsoort.AankoopNieuwbouw
        ? v.financieringsopzet.bouwrenteVoorAankoopBedrag
        : null,
    eigenwoningschuld: v => v.financieringsopzet.eigenwoningschuldBedrag,
    eigenwoningschuldModal: v => mapEigenwoningschuldModal(v),
    gewensteHypotheek: v => v.gewensteHypotheekBedrag,
    gewensteHypotheekBerekenen: v => v.financieringsopzet.inbrengEigenGeld ?? true,
    hypothecaireInschrijving: v => mapBerekenInput(v.inschrijvingBedrag, v.inschrijvingOvernemen, v.inschrijvingBedrag),
    hypotheekakte: v =>
      mapBerekenInput(
        v.financieringsopzet.hypotheekakteBedrag,
        v.financieringsopzet.hypotheekakte,
        v.financieringsopzet.hypotheekakteBedrag
      ),
    inbrengEigenGeld: v => v.financieringsopzet.eigenGeldInbrengBedrag,
    inbrengEigenGeldModal: (v, c) => {
      const restantSchuld = v.financieringsopzet.eigenGeldRestschuldBedrag;

      return mapInbrengEigenGeldModal({
        overwaarde:
          (v.financieringsopzet.eigenGeldOverwaardeOvernemen === null ||
            v.financieringsopzet.eigenGeldOverwaardeOvernemen) &&
          c.berekeningenInstellingen.algemeen.minderingOverwaardeGewensteHypotheek
            ? calcOverwaarde(v)
            : v.financieringsopzet.eigenGeldOverwaardeBedrag,
        overwaardeTotaal: calcOverwaarde(v) ?? 0,
        berekeingenInstellingen: c.berekeningenInstellingen,
        restantschuld: restantSchuld && restantSchuld > 0 ? restantSchuld : 0,
        eigenGeldRestschuldBedrag: v.financieringsopzet.eigenGeldRestschuldBedrag,
        meenemenOverbruggingsKrediet: v.overbruggingskrediet?.meenemenInOverbruggingBedrag || null
      })(v.financieringsopzet);
    },
    koopsom: v => mapKoopsom(v.financieringsopzet),
    koopsomRoerendeZaken: v => v.financieringsopzet.roerendeZakenBedrag,
    kostenOmzettingBedrag: v => v.financieringsopzet.kostenOmzettingBedrag,
    leningdelenWordenAfgelost: v => (v.teBeeindigenLeningdelen && v.teBeeindigenLeningdelen.length > 0 ? true : false),
    leveringsakte: v =>
      mapBerekenInput(
        v.financieringsopzet.transportakteBedrag,
        v.financieringsopzet.transportakte ??
          v.financieringsopzet.soortFinanciering === Financieringsoort.AankoopBestaandeBouw
          ? true
          : false,
        v.financieringsopzet.transportakteBedrag
      ),
    makelaarscourtage: v =>
      mapBerekenInput(v.financieringsopzet.courtageBedrag, v.financieringsopzet.courtage || false),
    maxBevoorschottingspercentage: v => v.maxBevoorschottingspercentage,
    nationaleHypotheekGarantie: v => v.financieringsopzet.nhgBedrag,
    nationaleHypotheekGarantieBerekenen: v => v.financieringsopzet.nhgKostenOvernemen,
    nationaleHypotheekGarantieWarning: v => mapNhgWarning(v.nhgMogelijkResultaat, v.gewensteHypotheekBedrag ?? 0),
    nhgMogelijk: v => v.nhgMogelijk ?? true,
    nhgMogelijkIngesteld: v => v.nhgMogelijk !== null,
    overbruggingskredietModal: v => mapOverbruggingskredietModal(v),
    overdrachtsbelasting: v =>
      mapBerekenInput(
        v.financieringsopzet.overdrachtsbelastingBedrag,
        v.financieringsopzet.overdrachtsbelasting ??
          (v.financieringsopzet.soortFinanciering === Financieringsoort.Oversluiten ? false : true),
        v.financieringsopzet.overdrachtsbelastingBedrag
      ),
    overigeFinancieringskostenModal: v => mapOverigeFinancieringskostenModal(v),
    overigeKostenInHypotheekModal: v => mapOverigeKostenInHypotheekModal(v.financieringsopzet),
    renteverliesTijdensBouw: v => v.financieringsopzet.renteverliesTijdensBouwBedrag,
    renteverliesTijdensBouwModal: v => mapRenteverliesTijdensBouwModal(v),
    taxatie: v =>
      mapBerekenInput(
        v.financieringsopzet.taxatiekostenBedrag,
        v.financieringsopzet.taxatiekosten ||
          (v.financieringsopzet.soortFinanciering === Financieringsoort.AankoopNieuwbouw
            ? false
            : v.financieringsopzet.taxatiekosten),
        v.financieringsopzet.taxatiekostenBedrag
      ),
    totaleRestschuldModal: v => mapTotaleRestschuldModal(v.financieringsopzet),
    uitkoopPartner: v => v.financieringsopzet.vergoedingPartnerBedrag,
    verbouwingVerbeteringModal: v => mapVerbouwingVerbeteringModal(v),
    financieringsopzetInstellingenTarieven: (v, c) =>
      mapFinancieringsopzetInstellingenTarievenToUi(c.tarievenInstellingen),
    arbeidskostenNotaris: v => v.financieringsopzet.arbeidskostenNotarisBedrag,
    bestaandNhgBedrag: v => v.financieringsopzet.bestaandNhgBedrag,
    doorlopendZonderNhgBedrag: v => v.financieringsopzet.doorlopendZonderNhgBedrag
  });

const mapOnderpand = createMapToUi(financieringOnderpandSchema).from<Financieringsbehoefte>({
  eigendomsverhoudingAanvrager: v => v.pand.eigendom1Percentage,
  eigendomsverhoudingPartner: v => v.pand.eigendom2Percentage,
  eigenwoningforfait: v =>
    v.pand.gebruikPand === PandGebruikPand.PrimaireWoning ? v.pand.eigenWoningForfaitBedrag : 0,
  gebruik: v => v.pand.gebruikPand,
  heeftPartner: v => v.heeftPartner,
  marktwaardeNaVerbouwing: v => v.pand.marktwaardeNaVerbouwingBedrag,
  marktwaardeVoorVerbouwing: v => v.pand.taxatieBedrag,
  soortFinanciering: v => v.financieringsopzet.soortFinanciering,
  wozWaarde: v => v.pand.wozWaarde,
  wozWaardeloket: v => getWozWaardeLink(v.pand.adres?.postcode ?? null, v.pand.adres?.huisnummer ?? null),
  geboorteDatumAanvrager: v =>
    v.aanvrager1?.geboortedatum ? convertUtcToTimeZone(v.aanvrager1.geboortedatum)?.toLocalDate() : null,
  geboorteDatumPartner: v =>
    v.aanvrager2?.geboortedatum ? convertUtcToTimeZone(v.aanvrager2.geboortedatum)?.toLocalDate() : null
});

const mapOverwaarde = createMapToUi(overwaardeSchema)
  .with<NettoVerkoopType>()
  .from<Financieringsbehoefte>({
    nettoVerkoopOpbrengst: (_, n) => n.nettoVerkoopOpbrengst,
    restantSchuldBox1: v => v.restantSchuldBox1Bedrag,
    restantSchuldBox3: v => v.restantSchuldBox3Bedrag,
    vervreemdingsSaldo: (v, n) =>
      !!n.nettoVerkoopOpbrengst || !!v.restantSchuldBox1Bedrag
        ? (n.nettoVerkoopOpbrengst ?? 0) - (v.restantSchuldBox1Bedrag || 0) > 0
          ? (n.nettoVerkoopOpbrengst ?? 0) - (v.restantSchuldBox1Bedrag || 0)
          : 0
        : null,
    totaalOverwaarde: v => v.overwaardeVermogenBedrag
  });

const mapVermogen = createMapToUi(vermogenSchema).from<Financieringsbehoefte>({
  berekendeSomVermogen: _ => 0,
  overwaarde: v => v.overwaardeVermogenBedrag,
  overwaardeBerekeningTonen: v => (v.specificatiesNettoVerkoopopbrengst?.length ?? 0) > 0,
  totaalVermogen: _ => 0,
  vrijVermogen: v =>
    mapBerekenInput(v.vrijVermogenBedrag ?? 0, v.vrijVermogenOvernemen ?? true, v.vrijVermogenBerekendBedrag)
});

export const dl2ui = createMapToUi(financieringsbehoefteSchema)
  .with<{ berekeningenInstellingen: Berekeningen; tarievenInstellingen: Tarieven }>()
  .from<FinancieringsbehoefteDlEntry>({
    cascadingValueChange: () => ({ hasChanged: false, elementsChanged: [] }),
    erfpacht: v => mapErfpacht(v.passeerdatum)(v.pand),
    financiering: v => mapFinanciering(v.financieringsopzet),
    financieringsopzet: (v, c) => mapFinancieringsopzet(c)(v),
    nettoPandOpbrengst: v =>
      v.specificatiesNettoVerkoopopbrengst?.map(
        (opbrengst): NettoVerkoopPandOpbrengst => mapNettoPandOpbrengstDlNaarUi(opbrengst)
      ),
    omschrijvingVoorstel1: v => v.omschrijvingVoorstel1 || "Voorstel #1",
    onderpand: v => mapOnderpand(v),
    overwaarde: v => mapOverwaarde(calcNettoVerkoop(v))(v),
    vermogen: v => mapVermogen(v),
    volgnummerVoorstel: v => v.volgnummerVoorstel,
    totaalHuidigeLeningdelen: v => v.totaalHuidigeLeningdelen,
    hypotheekNorm: v =>
      v.hypotheekNorm
        ? {
            jaar: v.hypotheekNorm.jaar,
            maand: v.hypotheekNorm.maand
          }
        : null,
    doorlopendeLeningdelenBox1Bedrag: v => v.doorlopendeLeningdelenBox1Bedrag
  });

export const mapFinancieringsbehoefteDlToUi = (
  berekeningenInstellingenData: Berekeningen | null,
  instellingenTarieven: Tarieven | null
) => (voorstelId: string, data: FinancieringsbehoefteDlOutput): FinancieringsbehoefteState | null => {
  const financieringsbehoefte = data && data.financieringsbehoeften ? data.financieringsbehoeften[voorstelId] : null;
  if (!financieringsbehoefte || !instellingenTarieven || !berekeningenInstellingenData) {
    return null;
  }

  return dl2ui({ berekeningenInstellingen: berekeningenInstellingenData, tarievenInstellingen: instellingenTarieven })(
    financieringsbehoefte
  );
};
