import React, { ReactElement } from "react";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";

import { InkomstenAanmerkelijkBelangModalType } from "../infra/inkomen-en-fiscus-schema";

import { scenarioCardSchema } from "../../shared/types";
import { LocalDate } from "@js-joda/core";
import { birthdate2age } from "../../shared/utils/birthdate-to-age";
import { ScenarioCard } from "../../shared/components/scenario-input/scenario-card";
import Modal from "../../shared/components/modal/Modal";

export type InkomstenAanmerkelijkBelangScenarioData = {
  geboorteDatum: LocalDate | null | undefined;
  data: InkomstenAanmerkelijkBelangModalType;
};

type InkomstenAanmerkelijkBelangScenarioProps = {
  onSave?: (data: InkomstenAanmerkelijkBelangModalType) => void;
  closeModal?: () => void;
};

const InkomstenAanmerkelijkBelangScenario = ({
  geboorteDatum,
  data,
  onSave,
  closeModal
}: InkomstenAanmerkelijkBelangScenarioData & InkomstenAanmerkelijkBelangScenarioProps): ReactElement => {
  const age = geboorteDatum ? birthdate2age(geboorteDatum) : 18;
  const entries = data.scenario.length;

  return (
    <Formik
      initialValues={{ inkomstenAanmerkelijkBelang: data }}
      validationSchema={Yup.object({
        inkomstenAanmerkelijkBelang: Yup.object({
          scenario: scenarioCardSchema
        })
      })}
      onSubmit={(values): void => {
        onSave && onSave(values.inkomstenAanmerkelijkBelang);
        closeModal && closeModal();
      }}
    >
      {(formik: FormikProps<{ inkomstenAanmerkelijkBelang: InkomstenAanmerkelijkBelangModalType }>): ReactElement => {
        const {
          values: { inkomstenAanmerkelijkBelang }
        } = formik;

        const body = (
          <ScenarioCard
            name={`inkomstenAanmerkelijkBelang.scenario`}
            startBedrag={inkomstenAanmerkelijkBelang.inkomensverleden.gemiddeld || 0}
            captionStart={age}
            captionSuffix={" jr"}
            entries={entries}
          />
        );

        return (
          <>
            <Modal
              title="Scenario inkomsten aanmerkelijk belang"
              body={body}
              onSubmitClick={formik.submitForm}
              onCancelClick={closeModal}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default InkomstenAanmerkelijkBelangScenario;
