import * as Yup from "yup";
import { scenarioVanSchema } from "../../components/scenario-van/scenario-van-schema";
import { controleNormVeldSchema, controleNormSchema } from "../../components/controle-norm/infra/controle-norm-schema";

export const scenarioWerkloosheidModalSchema = controleNormVeldSchema.shape({
  scenarioVan: scenarioVanSchema,
  controleNorm: controleNormSchema
});

export type ScenarioWerkloosheidModalType = Yup.InferType<typeof scenarioWerkloosheidModalSchema>;

export type ScenarioWerkloosheidModalProps = {
  inVoorstel?: boolean;
  closeModal?: () => void;
  callbackOnClose?: () => void;
};
