import { mapJaarMaandInputFromLooptijdDl2Ui } from "adviesbox-shared";

import { KapitaalverzekeringVerpanding, VermogenVerpanding } from "../../.generated/forms/formstypes";

import { mapBerekenInput } from "../../shared/types";

import {
  aanvullingInkomenBijOverlijdenModalSchema,
  indicatieveUitkerendeFaseModalSchema
} from "./producten-overzicht-schema";
import { VerpandingType, IndicatieveUitkerendeFaseModalType } from "./producten-overzicht-types";
import { mapAflosProductenDlToUi } from "./verpanding/map-verpanding-dl-to-ui";

export function mapVerpandingDl2Ui(
  verpanding: KapitaalverzekeringVerpanding | VermogenVerpanding | null
): VerpandingType {
  const uitkerendeFase = verpanding?.uitkerendeFase;

  let indicatieveUitkerendeFase = false;
  let indicatieveUitkerendeFaseSpecificatie: IndicatieveUitkerendeFaseModalType = indicatieveUitkerendeFaseModalSchema.default();
  // De default van null is hier niet geldig
  indicatieveUitkerendeFaseSpecificatie.values.duurUitkering.jaren = 0;

  if (uitkerendeFase) {
    indicatieveUitkerendeFase = true;
    indicatieveUitkerendeFaseSpecificatie = {
      values: {
        belastingBox: uitkerendeFase.belastingBox ?? null,
        soortLijfrenteUitkering: uitkerendeFase.soortLijfrenteUitkering ?? null,
        duurUitkering: mapJaarMaandInputFromLooptijdDl2Ui(uitkerendeFase.duurUitkeringInMaanden),
        duurUitkeringHoog: mapJaarMaandInputFromLooptijdDl2Ui(uitkerendeFase.duurUitkeringHoogInMaanden),
        hoogLaagVerhouding: uitkerendeFase.hooglaagVerhouding ?? null,
        lijfrenteTarief: uitkerendeFase.lijfrenteTariefPercentage ?? null,
        overgangOpTweedeVerzekerde: uitkerendeFase.overgangOp2eVerzekerde ?? null,
        overgangOpTweedeVerzekerdePercentage: uitkerendeFase.overgangOp2eVerzekerdePercentage ?? null,
        lijfrenteUitkering: mapBerekenInput(
          uitkerendeFase.lijfrenteUitkeringBedrag,
          !uitkerendeFase.lijfrenteUitkeringBedrag
        ),
        termijn: uitkerendeFase.termijn ?? null
      }
    };
  }

  return {
    verpandAanGeldverstrekker: verpanding?.verpandAanGeldverstrekker ?? false,
    bedoeldVoorAflossing: verpanding?.bedoeldVoorAflossing ?? false,
    bedoeldVoorAflossingSpecificatie: mapAflosProductenDlToUi(verpanding?.aflosproducten),
    indicatieveUitkerendeFase,
    indicatieveUitkerendeFaseSpecificatie,
    inkomensaanvulling: false,
    inkomensaanvullingSpecificatie: aanvullingInkomenBijOverlijdenModalSchema.default()
  };
}
