export function buildProductDisplayName(original: any): string {
    if (original.maatschappijNaam) {
      let displayName = "";
      displayName += `${original.maatschappijNaam}`;
      if (original.omschrijving) {
        displayName += ` ${original.omschrijving}`;
      }
      return displayName;
    }
    if (original.omschrijving) {
      return `${original.omschrijving}`;
    }
    return 'Geen omschrijving'
}