import React, { ReactElement } from "react";
import { connect, FormikContextType } from "formik";

import { OpmerkingKenmerken } from "../../producten-overzicht/infra/product-kenmerken-types";
import { HypothekenState } from "../infra/hypotheek-types";

type OpmerkingProps = {
  kenmerken: OpmerkingKenmerken;
};
const Opmerking = ({
  kenmerken,
  formik: {
    setFieldValue,
    values: { producten }
  }
}: OpmerkingProps & {
  formik: FormikContextType<HypothekenState>;
}): ReactElement => {
  return (
    <div className="px-03">
      <p>{kenmerken.hintOpmerking}</p>
    </div>
  );
};

export default connect<OpmerkingProps, HypothekenState>(Opmerking);
