import { createGetTextResources } from "../../shared/utils/text-resources-algemeen";

export const getAanvraagLeningTextResources = createGetTextResources({
  leningBespreekzaak:
    "In dit veld geef je aan of voor het indienen van de aanvraag al contact is geweest en/of afspraken zijn gemaakt met ING over de aanvraag. De mid-office van ING wordt hier dan op geattendeerd, waarna het juiste proces wordt gestart.",
  leningNettoAdvieskosten: `Met 'Netto advieskosten' wordt het saldo van de bruto advieskosten minus het totaal aan verleende kortingen bedoeld.`,
  leningBetaalwijzeAdvieskosten: "Geeft aan op welke wijze de klant de advieskosten gaat betalen.",
  maatwerkoplossingMaatwerkoplossing: `Selecteer ‘Maatwerkoplossing’ als de aanvraag de GHF-normen overschrijft. Hieronder kun je motiveren waarom je kiest voor een maatwerkoplossing.`,
  notarisNotariskantoor:
    "Via de instellingen-app, scherm ‘Beheer partijen - Notarissen' kun je aangeven met welke notariskantoren je samenwerkt. Deze worden in de keuzelijst weergegeven.",
  financieringSoortOversluiting:
    "Door geldverstrekkers kan verzocht worden de HDN AX-aanvraag met een andere soort oversluiting (Regelingtype) in te dienen voor een correcte verwerking in hun systemen. Kies dan hier de juiste soort oversluiting.",
  maatwerkoplossingRedenValidatieText: "Selecteer a.u.b. een reden voor de maatwerkoplossing.",
  toelichtingBijOverigText: `Voer a.u.b. een toelichting in indien u kiest voor 'Overig'.`,
  maatwerkoplossingPopupText: `Wannneer u 'Maatwerkoplossing' uitzet worden de ingevoerde redenen verwijderd. Weet u zeker dat u Maatwekoplossing uit wilt zetten?`
});
