/* istanbul ignore file */
import React, { FC, useState, useCallback, useMemo, useEffect } from "react";
import LastenOverzichtTable from "../lastenoverzicht-table/lastenoverzicht-table";
import { Card, useRequestInit, Icon, CardWrapper } from "adviesbox-shared";
import { ProductOverzicht } from "../productoverzicht/product-overzicht";
import { Formik, FormikProps } from "formik";
import LastenOverzichtChart from "../lastenoverzicht-chart/lastenoverzicht-chart";
import LastenOverzichtCard from "../lastenoverzicht-card/lastenoverzicht-card";
import { Lasten, LastenOverzicht } from "../../../.generated/rapportage/rapportagetypes";
import "./snel-inzicht-drawer.scss";
import { useFetchData } from "../../../shared/components/dossier/dossier-wijzig-adviseur-modal/usefetchdata";
import { CardLoadingSpinner } from "../scenarios/card-loading-spinner";
import { useRefreshTrigger } from "../infra/use-refresh-trigger";
import classNames from "classnames";
import { IconButton } from "@material-ui/core";
import { useCookieBoolean } from "../../../useCookieSettings";

type KeuzeOpties = {
  MaandlastenoverzichtRadioButtons: string;
};

interface SnelInzichtDrawerProps {
  values?: KeuzeOpties;
}

export const SnelInzichtDrawer: FC<SnelInzichtDrawerProps> = ({ values }) => {
  const [lasten, setLasten] = useState<Lasten>();
  const [isCollapsed, setCollapsed] = useCookieBoolean('scenario-instellingen-productoverzicht-weergeven', false);
  const { refreshTrigger } = useRefreshTrigger();
  const { settings, params, requestInit } = useRequestInit<{
    vestiging: string;
    adviesdossier: string;
    voorstel: string;
  }>();

  const getLastenOverzichtUrl = useMemo(() => {
    const baseUrl = `${settings.rapportageOrigin}`;
    return params.voorstel
      ? `${baseUrl}/Voorstellen/${params.voorstel}/SnelInzicht/LastenOverzicht`
      : `${baseUrl}/Adviesdossiers/${params.adviesdossier}/SnelInzicht/LastenOverzicht`;
  }, [settings.rapportageOrigin, params.adviesdossier, params.voorstel]);

  const getRequestInit = useCallback(
    () => ({
      ...requestInit,
      method: "POST",
      url: getLastenOverzichtUrl
    }),
    [requestInit, getLastenOverzichtUrl]
  );

  const { data, loading, error, refetch } = useFetchData<LastenOverzicht>(getLastenOverzichtUrl, getRequestInit);
  useEffect(() => {
    if (refreshTrigger) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      refetch();
    }
  }, [refreshTrigger, refetch]);

  if (error) return <div>Error: {error}</div>;

  return (
    <Formik
      initialValues={
        values || {
          MaandlastenoverzichtRadioButtons: "tabel"
        }
      }
      onSubmit={/* istanbul ignore next */ () => {}}
    >
      {({ values }: FormikProps<any>) => (
        <div className="snel-inzicht">
          <CardWrapper maxRowCount={2} flexType="flex-row">
            <div className={classNames("snel-inzicht__column col-12", { "col-md-1": isCollapsed, "col-md-3": !isCollapsed })}>
              <Card
                title={!isCollapsed ? "Productoverzicht" : ""}
                titlePrepend={
                  <IconButton
                    title="Instellingen"
                    className="snel-inzicht__overzicht-button"
                    onClick={() => setCollapsed(!isCollapsed)}
                  >
                    <Icon name="hamburger" iconSize="l" />
                  </IconButton>
                }
              >
                <ProductOverzicht isCollapsed={isCollapsed} />
              </Card>
            </div>
            <div
              className={classNames("snel-inzicht__column snel-inzicht__no-padding col-12", {
                "col-md-11": isCollapsed,
                "col-md-9": !isCollapsed
              })}
            >
              <div>
                <div className="d-flex flex-wrap flex-row">
                  <Card
                    title="Maandlastenoverzicht"
                    radioOptions={[
                      { label: "Tabel", value: "tabel" },
                      { label: "Grafiek", value: "grafiek" }
                    ]}
                    className="col-12 mb-4"
                  >
                    {loading && <CardLoadingSpinner />}
                    {values.MaandlastenoverzichtRadioButtons === "tabel" && <LastenOverzichtTable data={data} />}
                    {values.MaandlastenoverzichtRadioButtons === "grafiek" && (
                      <LastenOverzichtChart
                        onHoverCallback={lasten => setLasten(lasten)}
                        onClickCallback={lasten => setLasten(lasten)}
                        data={data}
                      />
                    )}
                  </Card>
                  {values.MaandlastenoverzichtRadioButtons === "grafiek" && lasten && (
                    <Card title={`Jaar ${lasten.jaar}`} className="col-12">
                      <LastenOverzichtCard lasten={lasten} />
                    </Card>
                  )}
                </div>
              </div>
            </div>
          </CardWrapper>
        </div>
      )}
    </Formik>
  );
};
