import { HasChanged } from "../../shared/components/isw-side-effects/has-changed-helper";
import { ScenarioVanType } from "../components/scenario-van/scenario-van-schema";

export function updateDataOutdated(draft: any, draftScenarioVan: ScenarioVanType, has: HasChanged<ScenarioVanType>): void {
    if (draftScenarioVan.aanvrager1Geselecteerd) {
        draft.data1Outdated = draft.data1Outdated || (!has.aanvrager2Geselecteerd.changed && !has.beiden.changed);
    } else {
        draft.data1Outdated = false;
    }
    if (draftScenarioVan.aanvrager2Geselecteerd) {
        draft.data2Outdated = draft.data2Outdated || (!has.aanvrager1Geselecteerd.changed && !has.beiden.changed);
    } else {
        draft.data2Outdated = false;
    }
    if (draftScenarioVan.beiden) {
        draft.dataBeideOutdated = draft.dataBeideOutdated || (!has.aanvrager1Geselecteerd.changed && !has.aanvrager2Geselecteerd.changed);
    } else {
        draft.dataBeideOutdated = false;
    }
}