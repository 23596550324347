import { LocalDate } from "@js-joda/core";
import {
  AoUitkering,
  LoondienstReadOnly,
  OuderdomspensioenIndexering,
  OuderdomspensioenScenario,
  Pensioen,
  Pensioen as PensioenDlEntry,
  PensioenAanvrager,
  PensioenGegevens,
  UitkeringAoSoort,
  Pensioenregelingsoort as PlatformPensioenregelingsoort,
  PensioenOutput as PensioenDlOutput
} from "../../.generated/forms/formstypes";
import { mapSpecificatieAoUitkeringModalDl2Ui } from "../../producten-overzicht/infra/map-specificatie-ao-uitkering";
import { AoUitkeringswijze } from "../../producten-overzicht/infra/specificatie-ao-uitkering-schema";
import { FieldMap, mapBerekenInput, UiName } from "../../shared/types";
import { createMapToUi } from "../../shared/utils/create-map-to-ui";
import { target2field } from "../../shared/utils/target-to-field";
import {
  deelnemerSchema,
  loondienstenSchema,
  ouderdomspensioenModalEntrySchema,
  ouderdomspensioenModalSchema,
  pensioenenSchema,
  PensioenenState,
  Pensioenregelingsoort,
  pensioengrondslagSchema,
  pensioenregelingSchema,
  pensioenSchema,
  pensioentoezeggingenSchema,
  pensioenuitvoerderSchema,
  werkgeverSchema,
  werknemersbijdragenSchema
} from "./pensioen-schema";
import { mapStringToLocalDate } from "adviesbox-shared";

export const berekenJaarMaanden = (
  geboortedatum: LocalDate | null,
  ingangsdatum: LocalDate
): { jaren: number | null; maanden: number | null; ingangsDag: number | null } => {
  const geboorteJaar = geboortedatum ? geboortedatum.year() : null;
  const geboorteMaand = geboortedatum ? geboortedatum.monthValue() : null;

  const ingangsJaar = ingangsdatum.year();
  const ingangsMaand = ingangsdatum.monthValue();
  const ingangsDag = ingangsdatum.dayOfMonth();

  const totalMonthsBetween =
    geboorteJaar !== null && geboorteMaand !== null && ingangsJaar !== null && ingangsMaand !== null
      ? ingangsJaar * 12 - geboorteJaar * 12 + (ingangsMaand - geboorteMaand)
      : null;

  // Eerste index = jaren, tweede index = maanden, derde index = dag
  return {
    jaren: totalMonthsBetween ? Math.floor(totalMonthsBetween / 12) : null,
    maanden: totalMonthsBetween ? totalMonthsBetween - Math.floor(totalMonthsBetween / 12) * 12 : null,
    ingangsDag
  };
};

type aanvragerContext = {
  aanvrager: PensioenAanvrager | null;
};

const mapPlatformPensioenGegevensInvaliditeitspensioenUitkeringswijze: Record<UitkeringAoSoort, AoUitkeringswijze> = {
  Geen: AoUitkeringswijze.Volledig,
  Volledig: AoUitkeringswijze.Volledig,
  ProRata: AoUitkeringswijze.ProRata
};

const mapPlatformPensioenregelingsoort: Record<PlatformPensioenregelingsoort, Pensioenregelingsoort> = {
  Geen: Pensioenregelingsoort.Geen,
  Eindloon: Pensioenregelingsoort.Eindloon,
  Middelloon: Pensioenregelingsoort.Middelloon,
  BeschikbarePremie: Pensioenregelingsoort.BeschikbarePremie
};

const mapWerkgever = createMapToUi(werkgeverSchema).from<PensioenGegevens>({
  huidigeWerkgever: v => v.pensioenBijHuidigeWerkgever
});

const mapPensioenuitvoerder = createMapToUi(pensioenuitvoerderSchema).from<PensioenGegevens>({
  naamPensioenuitvoerder: v => v.pensioenUitvoerder,
  brancheSector: v => v.branche
});

const mapLoondienst = createMapToUi(loondienstenSchema).from<LoondienstReadOnly>({
  loondienstId: v => v.loondienstId,
  beroepsfunctie: v => v.beroepsfunctie,
  meetellenTotDatum: v => mapStringToLocalDate(v.meetellenTotDatum),
  totaalBrutoInkomenBedrag: v => v.totaalBrutoInkomenBedrag
});

const deelnemerdefaults = deelnemerSchema.default();

export const mapDeelnemer = createMapToUi(deelnemerSchema).from<PensioenAanvrager>({
  loondiensten: v => (v.loondiensten && v.loondiensten.length > 0 ? v.loondiensten.map(x => mapLoondienst(x)) : []),
  klantId: v => v.klantId,
  naam: v =>
    v.achternaam
      ? `${v.voorletters ? v.voorletters + "\xa0" : ""}${v.voorvoegsel ? v.voorvoegsel + "\xa0" : ""}${
          v.achternaam ? v.achternaam : ""
        }`
      : deelnemerdefaults.naam,
  geboortedatum: v => mapStringToLocalDate(v.geboortedatum),
  burgerlijkeStaat: v => v.burgerlijkeStaat
});

const grondslagdefaults = pensioenregelingSchema.default();

const mapPensioenregeling = createMapToUi(pensioenregelingSchema).from<PensioenGegevens>({
  soortPensioenregeling: v =>
    v.soortPensioenregeling ? mapPlatformPensioenregelingsoort[v.soortPensioenregeling] : Pensioenregelingsoort.Geen,
  beschikbarePremie: v => v.beschikbaarBedrag || grondslagdefaults.beschikbarePremie,
  middelloonregelingPercentage: v =>
    v.soortPensioenregeling &&
    mapPlatformPensioenregelingsoort[v.soortPensioenregeling] === Pensioenregelingsoort.Middelloon &&
    v.opbouwPercentage
      ? v.opbouwPercentage
      : grondslagdefaults.middelloonregelingPercentage,
  eindloonregelingPercentage: v =>
    v.soortPensioenregeling &&
    mapPlatformPensioenregelingsoort[v.soortPensioenregeling] === Pensioenregelingsoort.Eindloon &&
    v.opbouwPercentage
      ? v.opbouwPercentage
      : grondslagdefaults.eindloonregelingPercentage,
  eindloonregelingPercentageWettelijk: v => grondslagdefaults.eindloonregelingPercentageWettelijk,
  middelloonregelingPercentageWettelijk: v => grondslagdefaults.middelloonregelingPercentageWettelijk
});

const pensioengrondslagdefaults = pensioengrondslagSchema.default();
const selectedPensioenregeling: Pensioenregelingsoort | null = null;

const mapPensioengrondslag = createMapToUi(pensioengrondslagSchema).from<PensioenGegevens>({
  pensioengevendSalaris: v => mapBerekenInput(v.pensioengevendInkomenBedrag, v.pensioengevendInkomenOvernemen),
  franchise: v => mapBerekenInput(v.franchiseBedrag, v.franchiseOvernemen),
  pensioengrondslag: v => mapBerekenInput(v.grondslagBedrag, v.grondslagOvernemen),
  huidigOpgebouwdBedrag: v => v.opgebouwdBedrag,
  datumOverzicht: v => mapStringToLocalDate(v.overzichtDatum),
  rekenrenteOpbouwfase: v =>
    v.soortPensioenregeling &&
    mapPlatformPensioenregelingsoort[v.soortPensioenregeling] === Pensioenregelingsoort.BeschikbarePremie
      ? !v.opbouwPercentage
        ? null
        : v.opbouwPercentage
      : pensioengrondslagdefaults.rekenrenteOpbouwfase,
  rekenrenteUitkeringfase: v => v.uitkeringPercentage,
  doelvermogen: v => (v.pensioenBijHuidigeWerkgever ? v.doelvermogenBedrag : null),
  selectedPensioenregeling: v => selectedPensioenregeling,
  eindloonregelingPercentage: v =>
    v.soortPensioenregeling &&
    mapPlatformPensioenregelingsoort[v.soortPensioenregeling] === Pensioenregelingsoort.Eindloon &&
    v.opbouwPercentage
      ? v.opbouwPercentage
      : pensioengrondslagdefaults.eindloonregelingPercentage,
  middelloonregelingPercentage: v =>
    v.soortPensioenregeling &&
    mapPlatformPensioenregelingsoort[v.soortPensioenregeling] === Pensioenregelingsoort.Middelloon &&
    v.opbouwPercentage
      ? v.opbouwPercentage
      : pensioengrondslagdefaults.middelloonregelingPercentage,
  pensioenaangroei: v => mapBerekenInput(v.aangroeiBedrag, v.aangroeiOvernemen),
  huidigeWerkgever: v => v.pensioenBijHuidigeWerkgever
});

const mapWerknemersbijdragen = createMapToUi(werknemersbijdragenSchema).from<PensioenGegevens>({
  bijdragePrePensioen: v => v.bijdragePrePensioenBedrag,
  bijdrageOuderdomspensioen: v => v.bijdrageOuderdomsPensioenBedrag
});

const mapOuderdomspensioenIndexering = createMapToUi(ouderdomspensioenModalEntrySchema).from<
  OuderdomspensioenIndexering
>({
  bedrag: v => v.bedrag,
  ingangsdatum: v => mapStringToLocalDate(v.ingangsdatum),
  volgnummer: v => v.volgnummer
});

const mapOuderdomspensioenModal = createMapToUi(ouderdomspensioenModalSchema).from<OuderdomspensioenScenario>({
  indexeringen: v =>
    v.indexeringen && v.indexeringen.length > 0 ? v.indexeringen.map(x => mapOuderdomspensioenIndexering(x)) : []
});

const pensioentoezeggingdefaults = pensioentoezeggingenSchema.default();

const mapPensioentoezeggingen = createMapToUi(pensioentoezeggingenSchema)
  .with<aanvragerContext>()
  .from<PensioenGegevens>({
    ouderdomspensioenIsEnabled: v => !!v.ouderdomspensioen,
    ouderdomspensioenBedrag: v => v.ouderdomspensioenBedrag,
    ouderdomspensioenIngangsdatum: v => mapStringToLocalDate(v.ouderdomspensioenDatum),
    ouderdomspensioenLeeftijd: (v, context) => {
      const geboortedatum = context.aanvrager ? mapStringToLocalDate(context.aanvrager?.geboortedatum) : null;
      return v.ouderdomspensioenDatum
        ? berekenJaarMaanden(geboortedatum, mapStringToLocalDate(v.ouderdomspensioenDatum))
        : pensioentoezeggingdefaults.ouderdomspensioenLeeftijd;
    },
    ouderdomspensioenIngangsdag: (v, context) =>
      v.ouderdomspensioenDatum
        ? berekenJaarMaanden(
            context.aanvrager ? mapStringToLocalDate(context.aanvrager?.geboortedatum) : null,
            mapStringToLocalDate(v.ouderdomspensioenDatum)
          ).ingangsDag
        : pensioentoezeggingdefaults.ouderdomspensioenIngangsdag,
    prePensioenIsEnabled: v => !!v.prepensioen,
    prePensioenBedrag: v => v.prepensioenBedrag || pensioentoezeggingdefaults.prePensioenBedrag,
    prePensioenIngangsdatum: v =>
      mapStringToLocalDate(v.prepensioenDatum) || pensioentoezeggingdefaults.prePensioenIngangsdatum,
    prePensioenLeeftijd: (v, context) => {
      const geboortedatum = context.aanvrager ? mapStringToLocalDate(context.aanvrager?.geboortedatum) : null;
      return v.prepensioenDatum
        ? berekenJaarMaanden(geboortedatum, mapStringToLocalDate(v.prepensioenDatum))
        : pensioentoezeggingdefaults.prePensioenLeeftijd;
    },
    prePensioenIngangsdag: v => pensioentoezeggingdefaults.prePensioenIngangsdag,
    nabestaandenpensioenTotAowOverlijdenVoorAowIsEnabled: v => !!v.partnerpensioenTotAowOverlijdenVoorAow,
    nabestaandenpensioenTotAowOverlijdenVoorAowBedrag: v => v.partnerpensioenTotAowOverlijdenVoorAowBedrag,
    nabestaandenpensioenTotAowOverlijdenVoorAowPercentage: v => v.partnerpensioenTotAowOverlijdenVoorAowPercentage,
    nabestaandenpensioenNaAowOverlijdenVoorAowIsEnabled: v => v.partnerpensioenNaAowOverlijdenVoorAow,
    nabestaandenpensioenNaAowOverlijdenVoorAowBedrag: v => v.partnerpensioenNaAowOverlijdenVoorAowBedrag,
    nabestaandenpensioenNaAowOverlijdenVoorAowPercentage: v => v.partnerpensioenNaAowOverlijdenVoorAowPercentage,
    nabestaandenpensioenTotAowOverlijdenNaAowIsEnabled: v => !!v.partnerpensioenTotAowOverlijdenNaAow,
    nabestaandenpensioenTotAowOverlijdenNaAowBedrag: v => v.partnerpensioenTotAowOverlijdenNaAowBedrag,
    nabestaandenpensioenTotAowOverlijdenNaAowPercentage: v => v.partnerpensioenTotAowOverlijdenNaAowPercentage,
    nabestaandenpensioenNaAowOverlijdenNaAowIsEnabled: v => !!v.partnerpensioenNaAowOverlijdenNaAow,
    nabestaandenpensioenNaAowOverlijdenNaAowBedrag: v => v.partnerpensioenNaAowOverlijdenNaAowBedrag,
    nabestaandenpensioenNaAowOverlijdenNaAowPercentage: v => v.partnerpensioenNaAowOverlijdenNaAowPercentage,
    anwCompensatieIsEnabled: v => !!v.anwCompensatie,
    anwCompensatie: v => v.anwCompensatieBedrag,
    wezenpensioenIsEnabled: v => !!v.wezenpensioen,
    wezenpensioenBedrag: v => v.wezenpensioenBedrag,
    wezenpensioenPercentage: v => v.wezenpensioenPercentage,
    wezenpensioenEindleeftijd: v => v.wezenpensioenEindleeftijd,
    invaliditeitspensioenIsEnabled: v => !!v.invaliditeitspensioen,
    invaliditeitspensioenBedrag: v => v.invaliditeitspensioenBedrag,
    invaliditeitspensioenPercentage: v => v.invaliditeitspensioenPercentage,
    invaliditeitspensioenWijze: v =>
      v.invaliditeitspensioenUitkeringswijze
        ? mapPlatformPensioenGegevensInvaliditeitspensioenUitkeringswijze[v.invaliditeitspensioenUitkeringswijze]
        : pensioentoezeggingdefaults.invaliditeitspensioenWijze,
    ouderdomspensioenModal: v =>
      mapOuderdomspensioenModal(v.ouderdomspensioenScenario || ({} as OuderdomspensioenScenario)),
    invaliditeitspensioenModal: v => mapSpecificatieAoUitkeringModalDl2Ui(v.aoUitkering || ({} as AoUitkering)),
    geboortedatum: (v, context) => (context.aanvrager ? mapStringToLocalDate(context.aanvrager?.geboortedatum) : null),
    pensioengevendSalaris: (v, context) => {
      if (context.aanvrager !== null) {
        const loondienst = context.aanvrager.loondiensten
          ? context.aanvrager.loondiensten.find(
              (ld): boolean => !!v.loondienstId && ld.loondienstId === v.loondienstId
            ) || null
          : null;
        return loondienst ? loondienst.totaalBrutoInkomenBedrag || null : null;
      }

      return null;
    }
  });

const mapPensioen = createMapToUi(pensioenSchema)
  .with<PensioenAanvrager[]>()
  .from<PensioenGegevens>({
    id: v => v.pensioenId,
    loondienstId: v => v.loondienstId,
    selectedDeelnemer: v => v.klantId,
    pensioengrondslag: v => mapPensioengrondslag(v),
    pensioenregeling: v => mapPensioenregeling(v),
    pensioentoezeggingen: (v, aanvragers) =>
      mapPensioentoezeggingen({
        aanvrager: aanvragers.find((a): boolean => !!v.klantId && a.klantId === v.klantId) || null
      })(v),
    pensioenuitvoerder: v => mapPensioenuitvoerder(v),
    werkgever: v => mapWerkgever(v),
    werknemersbijdragen: v => mapWerknemersbijdragen(v)
  });

export const dl2ui = createMapToUi(pensioenenSchema)
  .with<PensioenAanvrager[]>()
  .from<Pensioen>({
    pensioenen: (v, aanvragers) =>
      v.pensioenen?.map(x => {
        return mapPensioen(aanvragers)(x);
      }),
    deelnemers: (v, aanvragers) =>
      aanvragers.length > 0
        ? aanvragers.map(x => {
            return mapDeelnemer(x);
          })
        : []
  });

export function mapPensioenenDlToUi(pensioenId: string, data: PensioenDlOutput): PensioenenState | null {
  const pensioen = data && data.pensioenen ? data.pensioenen[pensioenId] : null;

  if (!pensioen) {
    return null;
  }

  const aanvrager1 = pensioen.aanvrager1 || ({} as PensioenAanvrager);
  const aanvrager2 = pensioen.aanvrager2 || ({} as PensioenAanvrager);
  const aanvragers = [aanvrager1, aanvrager2].filter((x: PensioenAanvrager): boolean => {
    return Object.keys(x).length !== 0;
  });

  return dl2ui(aanvragers)(pensioen);
}

export function mapDlTargetToPensioenUiField(target: string): UiName | null {
  const map: FieldMap<PensioenDlEntry> = {
    pensioenen: {
      opbouwPercentage: {
        label: "Loonregeling percentage",
        field: "pensioenen[i].pensioenregeling.eindloonregelingPercentage"
      },
      uitkeringPercentage: {
        label: "Rekenrente uitkeringsfase",
        field: "pensioenen[i].pensioengrondslag.rekenrenteUitkeringfase"
      },
      partnerpensioenTotAowOverlijdenVoorAowBedrag: {
        label: "Nabestaandenpensioen tot AOW bij overlijden voor AOW: Bedrag",
        field: "pensioenen[i].pensioentoezeggingen.nabestaandenpensioenTotAowOverlijdenVoorAowBedrag"
      },
      partnerpensioenTotAowOverlijdenVoorAowPercentage: {
        label: "Nabestaandenpensioen tot AOW bij overlijden voor AOW: Percentage",
        field: "pensioenen[i].pensioentoezeggingen.nabestaandenpensioenTotAowOverlijdenVoorAowPercentage"
      },
      partnerpensioenNaAowOverlijdenVoorAowBedrag: {
        label: "Nabestaandenpensioen na AOW bij overlijden voor AOW: Bedrag",
        field: "pensioenen[i].pensioentoezeggingen.nabestaandenpensioenNaAowOverlijdenVoorAowBedrag"
      },
      partnerpensioenNaAowOverlijdenVoorAowPercentage: {
        label: "Nabestaandenpensioen na AOW bij overlijden voor AOW: Percentage",
        field: "pensioenen[i].pensioentoezeggingen.nabestaandenpensioenNaAowOverlijdenVoorAowPercentage"
      },
      partnerpensioenTotAowOverlijdenNaAowBedrag: {
        label: "Nabestaandenpensioen tot AOW bij overlijden na AOW: Bedrag",
        field: "pensioenen[i].pensioentoezeggingen.nabestaandenpensioenTotAowOverlijdenNaAowBedrag"
      },
      partnerpensioenTotAowOverlijdenNaAowPercentage: {
        label: "Nabestaandenpensioen tot AOW bij overlijden na AOW: Percentage",
        field: "pensioenen[i].pensioentoezeggingen.nabestaandenpensioenTotAowOverlijdenNaAowPercentage"
      },
      partnerpensioenNaAowOverlijdenNaAowBedrag: {
        label: "Nabestaandenpensioen na AOW bij overlijden na AOW: Bedrag",
        field: "pensioenen[i].pensioentoezeggingen.nabestaandenpensioenNaAowOverlijdenNaAowBedrag"
      },
      partnerpensioenNaAowOverlijdenNaAowPercentage: {
        label: "Nabestaandenpensioen na AOW bij overlijden na AOW: Percentage",
        field: "pensioenen[i].pensioentoezeggingen.nabestaandenpensioenNaAowOverlijdenNaAowPercentage"
      },
      wezenpensioenEindleeftijd: {
        label: "Eindleeftijd wezenpensioen",
        field: "pensioenen[i].pensioentoezeggingen.wezenpensioenEindleeftijd"
      },
      wezenpensioenBedrag: {
        label: "Wezenpensioen: Bedrag",
        field: "pensioenen[i].pensioentoezeggingen.wezenpensioenBedrag"
      },
      wezenpensioenPercentage: {
        label: "Wezenpensioen: Percentage",
        field: "pensioenen[i].pensioentoezeggingen.wezenpensioenPercentage"
      },
      invaliditeitspensioenBedrag: {
        label: "Invaliditeitspensioen: Bedrag",
        field: "pensioenen[i].pensioentoezeggingen.invaliditeitspensioenBedrag"
      },
      invaliditeitspensioenPercentage: {
        label: "Invaliditeitspensioen: Percentage",
        field: "pensioenen[i].pensioentoezeggingen.invaliditeitspensioenPercentage"
      },
      ouderdomspensioenDatum: {
        label: "Ouderdomspensioen: Datum",
        field: "pensioenen[i].pensioentoezeggingen.ouderdomspensioenIngangsdatum"
      },
      ouderdomspensioenBedrag: {
        label: "Ouderdomspensioen: Bedrag",
        field: "pensioenen[i].pensioentoezeggingen.ouderdomspensioenBedrag"
      }
    }
  };
  return target2field(map, target);
}
