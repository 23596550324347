import { ChronoUnit } from "@js-joda/core";
import {
  OpnameBerekeningOutput,
  VermogenBerekeningInput,
  SoortBerekeningOptions,
  SoortVermogenProductOptions,
  BetalingsTermijnType,
  GeslachtOpties as GeslachtOptiesForms
} from "../../.generated/forms/formstypes";
import {
  MultipleOpbouwInput,
  OpbouwOutput,
  OpbouwVerzekerdeInput,
  GeslachtOpties,
  SoortBerekeningOpties,
  BetalingsTermijnEnum
} from "../../.generated/opbouw/opbouwtypes";
import {
  EersteInlegOutput,
  InlegOutput,
  KapitaalOutput,
  OnttrekkingOutput,
  PeriodiekBedragInputTermijn,
  PeriodiekeOnttrekkingInputTermijn,
  RendementOutput,
  VermogensrekeningInput,
  VermogensrekeningInputSoortOpbouwBerekening,
  WaardePerMaand
} from "../../.generated/vermogen/vermogentypes";
import {
  createISWAsyncSideEffect,
  initISWAsyncSideEffect
} from "../../shared/components/isw-side-effects/create-isw-helpers";
import { ISWAsyncSideEffectBag } from "../../shared/components/isw-side-effects/isw-types";
import { jaarMaandInMaanden } from "../../shared/generic-parts/jaar-maand/map-ui-2-dl";
import { KlantnaamType } from "../../shared/generic-parts/klantnaam/schema";
import { AanvragerKeuze } from "../../shared/types";
import { createMapToDl } from "../../shared/utils/create-map-to-dl";
import { afronden } from "../../shared/utils/currency";
import { mapDlTargetBerekenenVermogensRekeningToVermogenUiField } from "./map-vermogen-dl-2-ui";
import { mapVermogenUiToDl } from "./map-vermogen-ui-2-dl";
import { extraOpnameSchema, vermogensSchema } from "./vermogen-schema";
import { partijCodeAEGON, VermogensType, WaardeopbouwJaarType, WaardeopbouwMaandType } from "./vermogen-types";
import { logErrorToApplicationInsights, mapLocalDateToString } from "adviesbox-shared";

type Context = {
  selected: number;
  looptijdVerlengen?: boolean;
};

export const vermogenAsyncOpnameBerekenen = async (
  bag: ISWAsyncSideEffectBag<VermogensType, Context>
): Promise<void> => {
  const { draft, settings, fetchData, context } = bag;
  const product = draft.producten[context.selected];

  if (
    !product.onttrekkingen.onttrekkingenAanwezig ||
    product.kapitaalopbouw.soortBerekening !== SoortBerekeningOptions.Voorbeeldkapitaal
  ) {
    return;
  }

  const vermogenProduct = mapVermogenUiToDl({ aanvrager1: draft.aanvrager1, aanvrager2: draft.aanvrager2 })(product);

  const result = await fetchData<OpnameBerekeningOutput, VermogenBerekeningInput>({
    url: `${settings.klantdossiersFormsOrigin}/Vermogen/Opname`,
    body: { vermogenProduct },
    method: "POST"
  });

  if (result.isValid && result.resultaat && result.resultaat.length) {
    const { resultaat } = result;
    const extraOpnames = product.onttrekkingen.extraOpnames.scenario;

    const extraOpnamesLength = extraOpnames.length;
    resultaat.forEach(row => {
      const extraOpname = extraOpnames.find(e => e.jaar === row.jaar);
      if (extraOpname) {
        extraOpname.maxOpnameBedrag = row.maxOpnameBedrag;
        extraOpname.voorbeeldBedrag = row.voorbeeldBedrag;
      } else {
        extraOpnames.push({
          ...extraOpnameSchema.default(),
          ...row
        });
      }
    });

    if (extraOpnamesLength !== extraOpnames.length) {
      extraOpnames.sort((a, b) => a.jaar - b.jaar);
    }
  }
};

// Note: Alle bedragen afronden op 0 decimalen omdat ze berekend kunnen zijn met decimalen maar de input structuur Integers gebruikt
export const mapVermogenDetailsToVermogensrekeningInput = createMapToDl(vermogensSchema)
  .with<Context>()
  .to<VermogensrekeningInput>({
    soortOpbouwBerekening: (v, context) =>
      (v.producten[context.selected].kapitaalopbouw.soortRekening ??
        VermogensrekeningInputSoortOpbouwBerekening.Spaar) as VermogensrekeningInputSoortOpbouwBerekening,
    // looptijdInMaanden + 1 maand extra doorrekenen omdat we in aanvang maand nog geen opbouw van rendement hebben uit uitvoer webservice -> in ABC gaan we hier wel vanuit > gelijktrekken.
    looptijdInMaanden: (v, context) =>
      (jaarMaandInMaanden(v.producten[context.selected].product.looptijd) ?? 1200) +
      (context.looptijdVerlengen ? 1 : 0),

    // Note: Hier wordt voorbeeldkapitaal bewust naar doelkapitaal gemapt.
    // Zie https://dev.azure.com/intersoftwarebv/Adviesbox%20Online/_workitems/edit/52313 voor de uitelg van Remon
    doelkapitaal: (v, context) => {
      if (v.producten[context.selected].soortProduct === SoortVermogenProductOptions.Effectenlease) {
        return v.producten[context.selected].kapitaalopbouw.soortBerekening === SoortBerekeningOptions.Inleg
          ? afronden(v.producten[context.selected].kapitaalopbouw.garantiekapitaalBedrag, 0)
          : afronden(v.producten[context.selected].kapitaalopbouw.voorbeeldkapitaalBedrag, 0);
      }
      return v.producten[context.selected].kapitaalopbouw.doelkapitaalBedrag;
    },
    rendement: (v, context) => {
      if (v.producten[context.selected].soortProduct === SoortVermogenProductOptions.Effectenlease) {
        return v.producten[context.selected].kapitaalopbouw.soortBerekening === SoortBerekeningOptions.Inleg
          ? v.producten[context.selected].kapitaalopbouw.garantierendementPercentage
          : v.producten[context.selected].kapitaalopbouw.voorbeeldrendementPercentage;
      }
      return v.producten[context.selected].kapitaalopbouw.doelrendementPercentage;
    },
    garantiekapitaal: (v, context) =>
      afronden(v.producten[context.selected].kapitaalopbouw.garantiekapitaalBedrag, 0) ?? 0,
    garantierendement: (v, context) => v.producten[context.selected].kapitaalopbouw.garantierendementPercentage ?? 0,
    garantiepercentageInleg: () => null,

    hoogLaagLooptijdInMaanden: (v, context) =>
      jaarMaandInMaanden(v.producten[context.selected].inleggegevens.duurHoogLaag),
    hoogLaagInlegHoog: (v, context) => afronden(v.producten[context.selected].inleggegevens.inlegHoog, 0),

    eersteInleg: (v, context) => afronden(v.producten[context.selected].inleggegevens.eersteInleg, 0),
    inleg: (v, context) => {
      const vermogen = v.producten[context.selected];
      return {
        looptijdInMaanden: jaarMaandInMaanden(vermogen.inleggegevens.duur) ?? 1200,
        termijn: vermogen.inleggegevens.stortingstermijn as PeriodiekBedragInputTermijn | null,
        bedrag: vermogen.inleggegevens.inleg
      };
    },
    extraInleggen: (v, context) => {
      const vermogen = v.producten[context.selected];

      return vermogen.inleggegevens.extraInlegJaren.scenario
        .map((inleg, index) => ({
          jaar: index + 1,
          bedrag: afronden(inleg.bedrag, 0)
        }))
        .filter(inleg => !!inleg.bedrag);
    },

    onttrekking: (v, context) => {
      const { onttrekkingen, product } = v.producten[context.selected];
      if (!onttrekkingen.onttrekkingenAanwezig) {
        return null;
      }

      let aanvangsmaand: number | null = null;
      if (product.ingangsdatum && onttrekkingen.begindatum) {
        aanvangsmaand = product.ingangsdatum.until(onttrekkingen.begindatum, ChronoUnit.MONTHS) + 1;
      }

      return {
        looptijdInMaanden: jaarMaandInMaanden(onttrekkingen.duur) ?? 0,
        termijn: (onttrekkingen.onttrekkingstermijn ??
          PeriodiekeOnttrekkingInputTermijn.Geen) as PeriodiekeOnttrekkingInputTermijn,
        bedrag: afronden(onttrekkingen.onttrekkingBedrag, 0),
        aanvangsmaand
      };
    },
    extraOnttrekkingen: (v, context) => {
      const { onttrekkingen } = v.producten[context.selected];
      if (!onttrekkingen.onttrekkingenAanwezig) {
        return [];
      }

      return onttrekkingen.extraOpnames.scenario
        .filter(opname => !!opname.opnameBedrag)
        .map(opname => ({ jaar: opname.jaar, bedrag: afronden(opname.opnameBedrag, 0) }));
    },
    aankoopkostenPercentage: (v, context) => v.producten[context.selected].depot.aankoopkostenPercentage,
    bekeerskostenPercentage: (v, context) => v.producten[context.selected].depot.beheerkostenPercentage,
    verkoopkostenPercentage: (v, context) => v.producten[context.selected].depot.verkoopkostenPercentage,
    ingangsdatum: (v, context) => mapLocalDateToString(v.producten[context.selected].product.ingangsdatum),
    opgebouwdewaarde: (v, context) => v.producten[context.selected].depot.reedsOpgebouwdBedrag,
    opgebouwdewaardedatum: (v, context) => mapLocalDateToString(v.producten[context.selected].depot.reedsOpgebouwdDatum)
  });

const berekenWaardeopbouwBedrag = (waardeopbouwPerMaand: WaardePerMaand[] | null): number | null => {
  if (waardeopbouwPerMaand && waardeopbouwPerMaand.length) {
    return waardeopbouwPerMaand[waardeopbouwPerMaand.length - 1].waarde;
  }
  return null;
};

const berekenWaardeopbouwMaanden = (waardeopbouwPerMaand: WaardePerMaand[] | null): WaardeopbouwMaandType[] => {
  return (
    waardeopbouwPerMaand?.map(waardeopbouw => ({
      maand: waardeopbouw.maand ?? 0,
      eindwaardeBedrag: waardeopbouw.waarde ?? 0
    })) ?? []
  );
};

const berekenWaardeopbouwJaren = (waardeopbouwPerMaand: WaardePerMaand[] | null): WaardeopbouwJaarType[] => {
  const waardeopbouwJaren =
    waardeopbouwPerMaand
      ?.filter(waardeopbouw => (waardeopbouw.maand ?? 0) % 12 === 0)
      .map(waardeopbouw => ({
        jaar: (waardeopbouw.maand ?? 0) / 12,
        beginwaardeBedrag: 0,
        stortingenBedrag: 0,
        onttrekkingenBedrag: 0,
        eindwaardeBedrag: waardeopbouw.waarde ?? 0
      })) ?? [];

  let eindwaardeBedrag = 0;
  waardeopbouwJaren.forEach(waardeopbouw => {
    waardeopbouw.beginwaardeBedrag = eindwaardeBedrag;
    eindwaardeBedrag = waardeopbouw.eindwaardeBedrag;
  });

  return waardeopbouwJaren;
};

export const vermogenAsyncInlegBerekenen = createISWAsyncSideEffect<VermogensType, Context>(async bag => {
  const { draft, settings, fetchData, context } = bag;
  const { depot, inleggegevens } = draft.producten[context.selected];

  inleggegevens.inleg = null;
  depot.waardeopbouwBedrag = null;
  depot.waardeopbouwJaren = [];
  if (
    draft.producten[context.selected].kapitaalopbouw.garantiekapitaalBedrag === null &&
    draft.producten[context.selected].soortProduct !== SoortVermogenProductOptions.Beleggingsdepot
  )
    draft.producten[context.selected].kapitaalopbouw.garantiekapitaalBedrag =
      draft.producten[context.selected].kapitaalopbouw.doelkapitaalBedrag;
  if (
    draft.producten[context.selected].kapitaalopbouw.garantierendementPercentage === null &&
    draft.producten[context.selected].soortProduct !== SoortVermogenProductOptions.Beleggingsdepot
  )
    draft.producten[context.selected].kapitaalopbouw.garantierendementPercentage =
      draft.producten[context.selected].kapitaalopbouw.doelrendementPercentage;

  const result = await fetchData<InlegOutput, VermogensrekeningInput>({
    url: `${settings.vermogenOrigin}/Vermogensrekening/Inleg`,
    body: mapVermogenDetailsToVermogensrekeningInput({ ...context }),
    mapperDlNameToUiName: mapDlTargetBerekenenVermogensRekeningToVermogenUiField(context.selected)
  });

  if (result.isValid && result.resultaat) {
    const {
      resultaat: { inleg, waardeopbouwPerMaand }
    } = result;

    inleggegevens.inleg = inleg;

    // De eerste maand krijgen we nog geen rendement uit /Inleg terug om die reden 'knippen' we maand 1 eraf, en hernummeren we de maanden -1.
    const waardeopbouw = waardeopbouwPerMaand?.slice(1, waardeopbouwPerMaand.length).map(opbouw => {
      return { ...opbouw, maand: opbouw.maand == null ? null : opbouw.maand - 1 };
    });

    if (!waardeopbouw?.length) {
      logErrorToApplicationInsights(new Error("Er is geen berekende waardeopbouw uit de inleg/vermogenswebservice"));
      return;
    }

    depot.waardeopbouwBedrag = berekenWaardeopbouwBedrag(waardeopbouw);
    depot.waardeopbouwMaanden = berekenWaardeopbouwMaanden(waardeopbouw);
    depot.waardeopbouwJaren = berekenWaardeopbouwJaren(waardeopbouw);

    await vermogenAsyncOpnameBerekenen(bag);
  }
});

export const vermogenAsyncEersteInlegBerekenen = createISWAsyncSideEffect<VermogensType, Context>(async bag => {
  const { draft, settings, fetchData, context } = bag;
  const { depot, inleggegevens } = draft.producten[context.selected];

  inleggegevens.eersteInleg = null;
  depot.waardeopbouwBedrag = null;
  depot.waardeopbouwJaren = [];

  const result = await fetchData<EersteInlegOutput, VermogensrekeningInput>({
    url: `${settings.vermogenOrigin}/Vermogensrekening/EersteInleg`,
    body: mapVermogenDetailsToVermogensrekeningInput(context),
    mapperDlNameToUiName: mapDlTargetBerekenenVermogensRekeningToVermogenUiField(context.selected)
  });

  if (result.isValid && result.resultaat) {
    const {
      resultaat: { eersteInleg, waardeopbouwPerMaand }
    } = result;

    inleggegevens.eersteInleg = eersteInleg;
    depot.waardeopbouwBedrag = berekenWaardeopbouwBedrag(waardeopbouwPerMaand);
    depot.waardeopbouwMaanden = berekenWaardeopbouwMaanden(waardeopbouwPerMaand);
    depot.waardeopbouwJaren = berekenWaardeopbouwJaren(waardeopbouwPerMaand);

    await vermogenAsyncOpnameBerekenen(bag);
  }
});

export const vermogenAsyncVoorbeeldrendementBerekenen = createISWAsyncSideEffect<VermogensType, Context>(async bag => {
  const { draft, settings, fetchData, context } = bag;
  const { depot, kapitaalopbouw } = draft.producten[context.selected];

  kapitaalopbouw.voorbeeldrendementPercentage = null;
  kapitaalopbouw.garantierendementPercentage = null;
  depot.waardeopbouwBedrag = null;
  depot.waardeopbouwJaren = [];

  const result = await fetchData<RendementOutput, VermogensrekeningInput>({
    url: `${settings.vermogenOrigin}/Vermogensrekening/Rendement`,
    body: mapVermogenDetailsToVermogensrekeningInput(context),
    mapperDlNameToUiName: mapDlTargetBerekenenVermogensRekeningToVermogenUiField(context.selected)
  });

  if (result.isValid && result.resultaat) {
    const {
      resultaat: { rendement, garantierendement, waardeopbouwPerMaand }
    } = result;

    kapitaalopbouw.voorbeeldrendementPercentage = rendement;
    kapitaalopbouw.garantierendementPercentage = garantierendement;
    depot.waardeopbouwBedrag = berekenWaardeopbouwBedrag(waardeopbouwPerMaand);
    depot.waardeopbouwMaanden = berekenWaardeopbouwMaanden(waardeopbouwPerMaand);
    depot.waardeopbouwJaren = berekenWaardeopbouwJaren(waardeopbouwPerMaand);

    await vermogenAsyncOpnameBerekenen(bag);
  }
});

export const vermogenAsyncVoorbeeldkapitaalBerekenen = createISWAsyncSideEffect<VermogensType, Context>(async bag => {
  const { draft, settings, fetchData, context } = bag;
  const { depot, kapitaalopbouw, product } = draft.producten[context.selected];

  kapitaalopbouw.garantiekapitaalBedrag = null;
  kapitaalopbouw.voorbeeldkapitaalBedrag = null;
  depot.waardeopbouwBedrag = null;
  depot.waardeopbouwJaren = [];
  depot.waardeopbouwNa5Jaar = null;

  const result = await fetchData<KapitaalOutput, VermogensrekeningInput>({
    url: `${settings.vermogenOrigin}/Vermogensrekening/Kapitaal`,
    body: mapVermogenDetailsToVermogensrekeningInput(context),
    mapperDlNameToUiName: mapDlTargetBerekenenVermogensRekeningToVermogenUiField(context.selected)
  });

  if (result.isValid && result.resultaat) {
    const {
      resultaat: { waardeopbouwPerMaand, garantiekapitaal }
    } = result;

    const waardeopbouwBedrag = berekenWaardeopbouwBedrag(waardeopbouwPerMaand);
    depot.waardeopbouwBedrag = waardeopbouwBedrag;
    depot.waardeopbouwMaanden = berekenWaardeopbouwMaanden(waardeopbouwPerMaand);
    depot.waardeopbouwJaren = berekenWaardeopbouwJaren(waardeopbouwPerMaand);

    let maand = 61;
    if (product.ingangsdatum && depot.reedsOpgebouwdDatum) {
      maand += product.ingangsdatum.until(depot.reedsOpgebouwdDatum, ChronoUnit.MONTHS);
    }
    depot.waardeopbouwNa5Jaar = depot.waardeopbouwMaanden.find(x => x.maand === maand)?.eindwaardeBedrag ?? null;

    if (kapitaalopbouw.soortBerekening !== SoortBerekeningOptions.EigenInvoer) {
      kapitaalopbouw.garantiekapitaalBedrag = garantiekapitaal;
    }

    await vermogenAsyncOpnameBerekenen(bag);
  }
});

export const vermogenAsyncOnttrekkingBerekenen = createISWAsyncSideEffect<VermogensType, Context>(async bag => {
  const { draft, settings, fetchData, context } = bag;
  const { depot, onttrekkingen } = draft.producten[context.selected];

  onttrekkingen.onttrekkingBedrag = null;
  depot.waardeopbouwBedrag = null;
  depot.waardeopbouwJaren = [];

  const result = await fetchData<OnttrekkingOutput, VermogensrekeningInput>({
    url: `${settings.vermogenOrigin}/Vermogensrekening/Onttrekking`,
    body: mapVermogenDetailsToVermogensrekeningInput(context),
    mapperDlNameToUiName: mapDlTargetBerekenenVermogensRekeningToVermogenUiField(context.selected)
  });

  if (result.isValid && result.resultaat) {
    const {
      resultaat: { onttrekking, waardeopbouwPerMaand }
    } = result;

    onttrekkingen.onttrekkingBedrag = onttrekking;
    depot.waardeopbouwBedrag = berekenWaardeopbouwBedrag(waardeopbouwPerMaand);
    depot.waardeopbouwMaanden = berekenWaardeopbouwMaanden(waardeopbouwPerMaand);
    depot.waardeopbouwJaren = berekenWaardeopbouwJaren(waardeopbouwPerMaand);

    await vermogenAsyncOpnameBerekenen(bag);
  }
});

export function mapToBetalingsTermijnType(value: BetalingsTermijnType | null): BetalingsTermijnEnum | null {
  switch (value) {
    case BetalingsTermijnType.Eenmalig:
      return BetalingsTermijnEnum.Eenmalig;
    case BetalingsTermijnType.HalfJaar:
      return BetalingsTermijnEnum.PerHalfJaar;
    case BetalingsTermijnType.Jaar:
      return BetalingsTermijnEnum.PerJaar;
    case BetalingsTermijnType.Kwartaal:
      return BetalingsTermijnEnum.PerKwartaal;
    case BetalingsTermijnType.Maand:
      return BetalingsTermijnEnum.PerMaand;
    case BetalingsTermijnType.TweeMaanden:
      return BetalingsTermijnEnum.PerTweeMaanden;
  }

  return null;
}

export function mapAanvragerToGeslachtOpties(geslacht: GeslachtOptiesForms | null): GeslachtOpties | null {
  switch (geslacht) {
    case GeslachtOpties.Man:
      return GeslachtOpties.Man;
    case GeslachtOpties.Vrouw:
      return GeslachtOpties.Vrouw;
  }

  return null;
}

function mapAanvragerToOpbouwVerzekerdeInput(aanvrager: KlantnaamType): OpbouwVerzekerdeInput {
  return {
    geboorteDatum: mapLocalDateToString(aanvrager.geboortedatum ?? /* istanbul ignore next */ null),
    geslacht: mapAanvragerToGeslachtOpties(aanvrager.geslacht),
    roker: aanvrager.roker,

    // TODO Waar halen we deze vandaan?
    id: null,
    eindkapitaal: null,
    verzekerdBedrag: null,
    uitkering: null,
    annuiteitsPercentage: null
  };
}

export const vermogenAsyncAegonBerekenen = createISWAsyncSideEffect<VermogensType, Context>(async bag => {
  const { context, draft, fetchData, settings } = bag;
  const { inleggegevens, kapitaalopbouw, product, productId, verpanding, verzekeringnemers } = draft.producten[
    context.selected
  ];

  const verzekerden: OpbouwVerzekerdeInput[] = [];

  if (verzekeringnemers.verzekeringnemers !== AanvragerKeuze.Aanvrager2 && draft.aanvrager1) {
    verzekerden.push(mapAanvragerToOpbouwVerzekerdeInput(draft.aanvrager1));
  }

  if (verzekeringnemers.verzekeringnemers !== AanvragerKeuze.Aanvrager1 && draft.aanvrager2) {
    verzekerden.push(mapAanvragerToOpbouwVerzekerdeInput(draft.aanvrager2));
  }

  const body: MultipleOpbouwInput = {
    data: {
      id: productId || "", // TODO waarom mag null niet?
      algemeen: {
        ingangsDatum: mapLocalDateToString(product.ingangsdatum),
        looptijdInMaanden: jaarMaandInMaanden(product.looptijd),
        verpand: verpanding.verpandAanGeldverstrekker,

        // TODO Waar halen we deze vandaan?
        premieSplitsing: null,
        kindDekking: null,
        premiereserve: null,
        soortBerekening: SoortBerekeningOpties.Doelkapitaal // TODO: behoeft correcte implementatie, dit is een nswag update quickfix
      },
      verzekerden,
      premie: {
        looptijdInMaanden: jaarMaandInMaanden(inleggegevens.duur),
        looptijdHoogLaagInMaanden: jaarMaandInMaanden(inleggegevens.duurHoogLaag),
        hoogLaagPercentage: inleggegevens.inlegHoog,
        eindDatum: mapLocalDateToString(
          product.ingangsdatum?.plusMonths(jaarMaandInMaanden(inleggegevens.duur) ?? 0) ??
            /* istanbul ignore next */ null
        ),
        betalingstermijn: mapToBetalingsTermijnType(inleggegevens.stortingstermijn),
        aanvangsstorting: inleggegevens.eersteInleg
      },
      doelkapitaal: {
        bedrag: kapitaalopbouw.doelkapitaalBedrag,
        percentage: kapitaalopbouw.doelrendementPercentage,
        percentageRekenrente: kapitaalopbouw.doelrendementPercentage
      },
      leningdeel: {
        // TODO Waar halen we deze vandaan?
        rentepercentage: null,
        bedrag: null,
        rentevastPeriode: null,
        rentebedenkPeriode: null
      }
    },
    producten: [
      2 // AegonSpaar
    ]
  };

  const result = await fetchData<OpbouwOutput, MultipleOpbouwInput>({
    url: `${settings.opbouwOrigin}/Opbouw/Multiple`,
    body
  });

  if (result.isValid && result.resultaten) {
    result.resultaten.forEach(
      ({ id, productcode, premie, aanvangsstorting, premieHoog, melding, messages, isValid }) => {
        if (isValid) {
          inleggegevens.inleg = premie;
          inleggegevens.eersteInleg = aanvangsstorting;
          inleggegevens.inlegHoog = premieHoog;
        }
      }
    );
  }
});

export const determineVermogenDetailsAsyncSideEffects = initISWAsyncSideEffect<VermogensType, Context>(
  ({ runAsync, context, has, curr }) => {
    if (has.producten[context.selected].dataHasChanged.changed) {
      const { kapitaalopbouw, soortProduct, partijCode } = curr.producten[context.selected];
      if (kapitaalopbouw.soortBerekening === SoortBerekeningOptions.Inleg) {
        runAsync(vermogenAsyncInlegBerekenen(context));
      } else if (kapitaalopbouw.soortBerekening === SoortBerekeningOptions.EersteInleg) {
        runAsync(vermogenAsyncEersteInlegBerekenen(context));
      } else if (kapitaalopbouw.soortBerekening === SoortBerekeningOptions.Voorbeeldrendement) {
        runAsync(vermogenAsyncVoorbeeldrendementBerekenen(context));
      } else if (
        kapitaalopbouw.soortBerekening === SoortBerekeningOptions.Voorbeeldkapitaal ||
        kapitaalopbouw.soortBerekening === SoortBerekeningOptions.EigenInvoer ||
        soortProduct === SoortVermogenProductOptions.Betaalrekening
      ) {
        runAsync(vermogenAsyncVoorbeeldkapitaalBerekenen(context));
      } else if (kapitaalopbouw.soortBerekening === SoortBerekeningOptions.Onttrekking) {
        runAsync(vermogenAsyncOnttrekkingBerekenen(context));
      } else if (partijCode === partijCodeAEGON) {
        runAsync(vermogenAsyncAegonBerekenen(context));
      }
    }
  }
);
