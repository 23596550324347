//TODO: 61798 tijdelijk voor webinar
/* istanbul ignore file */
import { ErrorPage, PageLoading, withErrorBoundary } from "adviesbox-shared";
import React, { ReactElement, useContext } from "react";
import { AppDataContext } from "../navigation/appdata-context";
import { InvalidAdviesboxResultErrorPage } from "../shared/components/fetch-error-page/fetch-error-page";
import { useSaveBeforeLoad } from "../shared/hooks/use-save-before-load";
import { mapRapportageSamenstellenDlToUi } from "./infra/map-rapportage-samenstellen-dl-to-ui";
import { useInstellingenRapportageStructurenData } from "./infra/use-instellingen-rapportage-structuren-data";
import { RapportSamenstellenModal } from "./rapport-samenstellen-modal";

const RapportageSamenstellenModalAjaxComponent = ({ closeModal }: { closeModal?: () => void }): ReactElement | null => {
  const {
    loading: rapportageLoading,
    data: rapportageData,
    error: rapportageError
  } = useInstellingenRapportageStructurenData();

  const { voorstellen } = useContext(AppDataContext);
  const { loading: saveLoading } = useSaveBeforeLoad(true);

  if (saveLoading) {
    return <></>;
  }

  if (rapportageLoading) {
    return <PageLoading />;
  }

  if (rapportageError) {
    return <ErrorPage error={rapportageError} data={JSON.stringify(rapportageError)} />;
  }

  if (!rapportageData) {
    return <InvalidAdviesboxResultErrorPage />;
  }

  const rapportageState = mapRapportageSamenstellenDlToUi(
    voorstellen.length ? voorstellen[0].voorstelId : "",
    rapportageData
  );

  return (
    <RapportSamenstellenModal
      data={
        rapportageState || {
          structuren: [],
          rapportstructuur: null,
          rapportSamenstellenOpBasisVan: ""
        }
      }
      closeModal={closeModal}
    />
  );
};

RapportageSamenstellenModalAjaxComponent.displayName = "RapportageSamenstellenModalAjax";

export const RapportageSamenstellenModalAjax = withErrorBoundary(RapportageSamenstellenModalAjaxComponent);
