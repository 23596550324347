import * as Yup from "yup";
import {
  AowData,
  BedragIndexering,
  BedragScenario,
  FiscaleAftrekposten,
  FiscaleBijtelposten,
  InkomenFiscus as InkomenEnFiscusDlEntry,
  InkomenFiscusAanvrager,
  LoonverklaringOpties,
  InkomstenBox3,
  OverigeInkomstenBox3Soort,
  Loondienst,
  InkomenPeriode,
  SoortDienstBetrekkingType,
  Onderneming,
  RechtsVormType,
  InkomenverklaringOpties,
  SoortOndernemingType,
  OverigeInkomstenBox1,
  BijtellingAutovdZaakOpties
} from "../../.generated/forms/formstypes";
import { mapAdresUiNaarDl } from "../../shared/generic-parts/adres/map-ui-2-dl";
import { berekenDateSchema } from "../../shared/generic-parts/bereken-date/schema";
import { scenarioCardInputType } from "../../shared/types";
import { createMapEnum } from "../../shared/utils/create-map-enum";
import { createMapToDl } from "../../shared/utils/create-map-to-dl";
import { hasValue } from "../../shared/utils/helpers";
import {
  aowSchema,
  BrutoSalarisPeriode,
  fiscaleAftrekpostenModalSchema,
  fiscaleBijtelpostenModalSchema,
  fiscaliteitSchema,
  inkomenEnFiscusSchema,
  inkomenSchema,
  inkomenUitArbeidModalSchema,
  InkomenVerklaring,
  inkomstenAanmerkelijkBelangModalSchema,
  inkomstenUitBeleggingenModalSchema,
  inkomstenVerhuurOnroerendGoedModalSchema,
  ondernemingModalSchema,
  overigeWerkzaamhedenModalSchema,
  RechtsVorm,
  sociaalSchema,
  SoortDienstverband,
  SoortInkomenverklaring,
  SoortOnderneming
} from "./inkomen-en-fiscus-schema";
import { mapLocalDateToString, nullableLocalDateSchema } from "adviesbox-shared";

const mapVerklaringInkomenEnum = createMapEnum(InkomenVerklaring).to({
  UWVdocument: LoonverklaringOpties.UwvDocument,
  Werkgeversverklaring: LoonverklaringOpties.Werkgeververklaring,
  ArbeidScan: LoonverklaringOpties.Arbeidsmarktscan
});

const mapBrutoSalarisPeriodeEnum = createMapEnum(BrutoSalarisPeriode).to({
  Geen: InkomenPeriode.Geen,
  Per4Weken: InkomenPeriode.Per4Weken,
  PerJaar: InkomenPeriode.PerJaar,
  PerMaand: InkomenPeriode.PerMaand,
  PerWeek: InkomenPeriode.PerWeek
});

const mapSoortDienstverbandEnum = createMapEnum(SoortDienstverband).to({
  Geen: SoortDienstBetrekkingType.Geen,
  FlexibeleArbeidsrelatieMetPerspectiefverklaring:
    SoortDienstBetrekkingType.FlexibeleArbeidsrelatieMetPerspectiefverklaring,
  FlexibeleArbeidsrelatieZonderPerspectiefverklaring:
    SoortDienstBetrekkingType.FlexibeleArbeidsrelatieZonderPerspectiefverklaring,
  LoondienstTijdelijkMetIntentie: SoortDienstBetrekkingType.LoondienstTijdelijkMetIntentie,
  LoondienstTijdelijkZonderIntentie: SoortDienstBetrekkingType.LoondienstTijdelijkZonderIntentie,
  LoondienstVast: SoortDienstBetrekkingType.LoondienstVast
});

const mapRechtsVormEnum = createMapEnum(RechtsVorm).to({
  Geen: RechtsVormType.Geen,
  BeslotenVennootschap: RechtsVormType.BeslotenVennootschap_Bv_,
  CommanditaireVennootschap: RechtsVormType.CommanditaireVennootschap_Cv_,
  Eenmanszaak: RechtsVormType.Eenmanszaak,
  Maatschap: RechtsVormType.Maatschap,
  NaamlozeVennootschap: RechtsVormType.NaamlozeVennootschap_Nv_,
  Stichting: RechtsVormType.Stichting,
  VennootschapOnderFirma: RechtsVormType.VennootschapOnderFirma_Vof_
});

const mapSoortInkomenverklaringEnum = createMapEnum(SoortInkomenverklaring).to({
  Geen: InkomenverklaringOpties.Geen,
  Accountantsverklaring: InkomenverklaringOpties.Accountantsverklaring,
  IbStukken: InkomenverklaringOpties.IbStukken
});

const mapSoortOndernemingEnum = createMapEnum(SoortOnderneming).to({
  Geen: SoortOndernemingType.Geen,
  ZelfstandigeMetPersoneel: SoortOndernemingType.ZelfstandigeMetPersoneel,
  ZelfstandigeZonderPersoneel: SoortOndernemingType.ZelfstandigeZonderPersoneel_ZZP_
});

const mapAow = createMapToDl(aowSchema).to<AowData>({
  aowDatumOvernemen: v => v.ingangsdatum.berekenen,
  aowDatum: v => mapLocalDateToString(v.ingangsdatum.berekenen ? v.ingangsdatum.berekendeDatum : v.ingangsdatum.datum),
  duurkortingAowInJaren: v => (hasValue(v.korting) ? v.korting : 0),
  aowBedrag: v => v.bedrag || 0,
  berekendeAowDatum: v => mapLocalDateToString(v.ingangsdatum.berekendeDatum)
});

function mapRenteScenarioUi2Dl(
  scenarios: scenarioCardInputType[],
  waarde: number | null,
  omschrijving: string | null
): BedragScenario {
  const indexeringen: BedragIndexering[] = [];
  let valueTemp: number | null = null;
  let index = 0;
  let volgnummer = 1;
  scenarios.forEach(x => {
    if (x.bedrag !== valueTemp) {
      indexeringen.push({
        volgnummer: volgnummer,
        ingangsmaand: index * 12 + 1,
        bedrag: x.bedrag
      });
      valueTemp = x.bedrag;
      volgnummer++;
    }
    index++;
  });

  return {
    waarde: waarde,
    omschrijving: omschrijving,
    indexeringen: indexeringen
  };
}

const mapInkomenUitArbeid = createMapToDl(inkomenUitArbeidModalSchema)
  .with<{ i: number; brutoSalarisUitDienstVerband: number | null }>()
  .to<Loondienst>({
    loondienstId: v => v.loondienstId,
    volgnummer: (_, c) => c.i + 1,
    aantalContracturenPerWeek: v => v.aantalUrenPerWeek,
    adresWerkgever: v => mapAdresUiNaarDl(v.adres),
    beroepOmschrijving: v => v.beroepsfunctie,
    beroeptypeCode: v => (hasValue(v.beroep) ? +v.beroep : null),
    buitenlandsInkomen: v => v.buitenlandsInkomen,
    contractEinddatum: v => mapLocalDateToString(v.einddatumContract),
    dienstverbandAanvangsdatum: v => mapLocalDateToString(v.datumInDienst),
    dienstverbandEinddatum: v => mapLocalDateToString(v.inkomenMeetellenTot.datum),
    inkomenPeriodeBedrag: v => v.brutoSalaris,
    inkomenPeriodeCode: v => mapBrutoSalarisPeriodeEnum(v.brutoSalarisPer),
    inkomstenToeslagBedrag: v => v.inkomstentoeslag,
    maand13Bedrag: v => v.dertiendeMaand,
    naamWerkgever: v => v.naam,
    onregelmatigheidstoeslagBedrag: v => v.onregelmatigheidstoeslag,
    overwerkAanvangsdatum: v => mapLocalDateToString(v.ingangsdatumStructureelOverwerk),
    overwerkBedrag: v => v.structureelOverwerk,
    overwerkEinddatum: v => mapLocalDateToString(v.einddatumStructureelOverwerk),
    percentageLoondoorbetalingAoJaar1: v => v.jaar1,
    percentageLoondoorbetalingAoJaar2: v => v.jaar2,
    provisieAanvangsdatum: v => mapLocalDateToString(v.ingangsdatumProvisie),
    provisieBedrag: v => v.provisie,
    provisieEinddatum: v => mapLocalDateToString(v.einddatumProvisie),
    soortDienstBetrekking: v => mapSoortDienstverbandEnum(v.soortDienstverband),
    vakantiegeldBedrag: v => v.vakantieToeslagBedrag,
    vakantiegeldPercentage: v => v.vakantieToeslagPercentage,
    vebBedrag: v => v.vergoedingBeslaglegging,
    vastFlexibelBudgetBedrag: v => v.vastFlexibelBudget,
    werkgeverIsFamilie: v => v.werkgeverIsFamilie,
    inkomenFlexibelBedrag: v => v.inkomensverleden.gemiddeld,
    inkomenFlexibelJaarBedrag: v => v.inkomensverleden.resultaat1,
    inkomenFlexibelJaarMin1Bedrag: v => v.inkomensverleden.resultaat2,
    inkomenFlexibelJaar: v => v.inkomensverleden.jaar,
    inkomenFlexibelJaarMin2Bedrag: v => v.inkomensverleden.resultaat3,
    inkomenScenario: v =>
      mapRenteScenarioUi2Dl(v.scenario, v.totaalBruto, "Inkomenscenario bruto salaris uit dienstverband"),
    gratificatieBedrag: v => v.vasteEindejaarsuitkering,
    faseUitzendcontract: v => (v.faseUitzendcontract ? parseInt(v.faseUitzendcontract) : null),
    toetsinkomenPerspectiefverklaring: v =>
      v.soortDienstverband === SoortDienstverband.FlexibeleArbeidsrelatieMetPerspectiefverklaring
        ? v.toetsinkomenPerspectiefVerklaring
        : null
  });

const mapOnderneming = createMapToDl(ondernemingModalSchema)
  .with(fiscaliteitSchema)
  .to<Onderneming>({
    volgnummer: v => v.volgnummer,
    aandelenPercentage: v => v.aandelenPercentage,
    aantalOndernemingen: v => v.aantalOndernemingen,
    boekjaar: v => v.inkomensverleden.jaar,
    boi: v => v.boi,
    dga: v => v.dga,
    eindeBijAo: v => v.scenarioInstellingen.eindeBijArbeidsongeschiktheid,
    eindeBijWw: v => v.scenarioInstellingen.eindeWerkloosheid,
    eindeOndernemingDatum: v => mapLocalDateToString(v.inkomenMeetellenTot.datum),
    forDotatie: (_, x) => x.dotatieFiscaleOudedagsReserve,
    inkomenJaarMin1Bedrag: v => v.inkomensverleden.resultaat2,
    inkomenJaarMin2Bedrag: v => v.inkomensverleden.resultaat3,
    inkomenScenario: v =>
      mapRenteScenarioUi2Dl(v.scenario, v.inkomensverleden.resultaat1, "Inkomenscenario onderneming"),
    kvkNummer: v => v.kvkNummer,
    kvkVestigingsnummer: () => null,
    meewerkaftrek: (_, x) => x.meewerkaftrek,
    mkbWinstvrijstelling: (_, x) => x.mkbWinstvrijstelling,
    ondernemingAanvangsdatum: v => mapLocalDateToString(v.ingangsdatumOnderneming),
    overgangOpPartner: v => v.scenarioInstellingen.overgangBijOverlijden,
    overgangOpPartnerEinddatum: v =>
      v.scenarioInstellingen.overgangBijOverlijden ? mapLocalDateToString(v.scenarioInstellingen.einddatum) : null,
    overgangOpPartnerEindleeftijd: v =>
      v.scenarioInstellingen.overgangBijOverlijden ? v.scenarioInstellingen.leeftijd : null,
    overgangOpPartnerPercentage: v =>
      v.scenarioInstellingen.overgangBijOverlijden ? v.scenarioInstellingen.percentage : null,
    rechtsVorm: v => mapRechtsVormEnum(v.rechtsvorm),
    resultaatVdc: v => v.scenarioInstellingen.regelingArbeidsongeschiktheid,
    rsin: v => v.rsinNummer,
    sbiCode: v => v.sbiCode,
    soortInkomenverklaring: v => mapSoortInkomenverklaringEnum(v.verklaringInkomen),
    soortOndernemingHdn: v => mapSoortOndernemingEnum(v.soortOnderneming),
    toetsinkomen: v => v.toetsinkomen,
    zelfstandigenaftrek: (_, x) => x.zelfstandigenaftrek,
    dossiernummer: v => v.dossiernummer,
    rekenexperttypeCode: v => (v.rekenexpert ? parseInt(v.rekenexpert) : null),
    uitgiftedatumVerklaringInkomen: v => (v.uitgifteDatum ? mapLocalDateToString(v.uitgifteDatum) : null),
    soortOverigeInkomsten: () => null
  });

const mapOverigeWerkzaamheden = createMapToDl(overigeWerkzaamhedenModalSchema).to<Onderneming>({
  eindeOndernemingDatum: v => mapLocalDateToString(v.inkomenMeetellenTot.datum),

  // scenarioInstellingen
  eindeBijAo: v => v.scenarioInstellingen.eindeBijArbeidsongeschiktheid,
  eindeBijWw: v => v.scenarioInstellingen.eindeWerkloosheid,
  resultaatVdc: v => v.scenarioInstellingen.regelingArbeidsongeschiktheid,
  overgangOpPartner: v => v.scenarioInstellingen.overgangBijOverlijden,
  overgangOpPartnerPercentage: v => v.scenarioInstellingen.percentage,
  overgangOpPartnerEinddatum: v =>
    v.scenarioInstellingen.overgangBijOverlijden ? mapLocalDateToString(v.scenarioInstellingen.einddatum) : null,
  overgangOpPartnerEindleeftijd: v => v.scenarioInstellingen.leeftijd,

  // inkomensverleden
  boekjaar: v => v.inkomensverleden.jaar,
  inkomenJaarMin1Bedrag: v => v.inkomensverleden.resultaat2,
  inkomenJaarMin2Bedrag: v => v.inkomensverleden.resultaat3,

  // scenario
  inkomenScenario: v =>
    mapRenteScenarioUi2Dl(v.scenario, v.inkomensverleden.resultaat1, "Inkomenscenario overige werkzaamheden"),

  aandelenPercentage: () => null,
  aantalOndernemingen: () => null,
  boi: () => null,
  dga: () => null,
  forDotatie: () => null,
  kvkNummer: () => null,
  kvkVestigingsnummer: () => null,
  meewerkaftrek: () => null,
  mkbWinstvrijstelling: () => null,
  ondernemingAanvangsdatum: () => null,
  rechtsVorm: () => null,
  rsin: () => null,
  sbiCode: () => null,
  soortInkomenverklaring: () => null,
  soortOndernemingHdn: () => null,
  toetsinkomen: () => null,
  volgnummer: () => 1,
  zelfstandigenaftrek: () => null,
  dossiernummer: v => null,
  rekenexperttypeCode: () => null,
  uitgiftedatumVerklaringInkomen: () => null,
  soortOverigeInkomsten: v => v.omschrijving
});

const mapAanmerkelijkBelang = createMapToDl(inkomstenAanmerkelijkBelangModalSchema).to<Onderneming>({
  eindeOndernemingDatum: v => mapLocalDateToString(v.inkomenMeetellenTot.datum),

  // scenarioInstellingen
  eindeBijAo: v => v.scenarioInstellingen.eindeBijArbeidsongeschiktheid,
  eindeBijWw: v => v.scenarioInstellingen.eindeWerkloosheid,
  resultaatVdc: v => v.scenarioInstellingen.regelingArbeidsongeschiktheid,
  overgangOpPartner: v => v.scenarioInstellingen.overgangBijOverlijden,
  overgangOpPartnerPercentage: v => v.scenarioInstellingen.percentage,
  overgangOpPartnerEinddatum: v =>
    v.scenarioInstellingen.overgangBijOverlijden ? mapLocalDateToString(v.scenarioInstellingen.einddatum) : null,
  overgangOpPartnerEindleeftijd: v => v.scenarioInstellingen.leeftijd,

  // inkomensverleden
  boekjaar: v => v.inkomensverleden.jaar,
  inkomenJaarMin1Bedrag: v => v.inkomensverleden.resultaat2,
  inkomenJaarMin2Bedrag: v => v.inkomensverleden.resultaat3,

  // scenario
  inkomenScenario: v =>
    mapRenteScenarioUi2Dl(v.scenario, v.inkomensverleden.resultaat1, "Inkomenscenario aanmerkelijk belang"),

  aandelenPercentage: () => null,
  aantalOndernemingen: () => null,
  boi: () => null,
  dga: () => null,
  forDotatie: () => null,
  kvkNummer: () => null,
  kvkVestigingsnummer: () => null,
  meewerkaftrek: () => null,
  mkbWinstvrijstelling: () => null,
  ondernemingAanvangsdatum: () => null,
  rechtsVorm: () => null,
  rsin: () => null,
  sbiCode: () => null,
  soortInkomenverklaring: () => null,
  soortOndernemingHdn: () => null,
  toetsinkomen: () => null,
  volgnummer: () => 1,
  zelfstandigenaftrek: () => null,
  dossiernummer: v => null,
  rekenexperttypeCode: () => null,
  uitgiftedatumVerklaringInkomen: () => null,
  soortOverigeInkomsten: () => null
});

const overigeInkomstenInfoSchema = Yup.object({
  bedrag: Yup.number(),
  einddatum: berekenDateSchema,
  omschrijving: Yup.string().nullable()
});

const mapToOverigeInkomstenBox1Type = createMapToDl(overigeInkomstenInfoSchema).to<OverigeInkomstenBox1>({
  bedrag: v => v.bedrag,
  einddatum: v =>
    v.einddatum.berekenen ? mapLocalDateToString(v.einddatum.berekendeDatum) : mapLocalDateToString(v.einddatum.datum),
  einddatumOvernemen: v => v.einddatum.berekenen,
  omschrijving: v => v.omschrijving
});

const mapInkomstenVerhuurOnroerendGoed = createMapToDl(inkomstenVerhuurOnroerendGoedModalSchema).to<InkomstenBox3>({
  volgnummer: () => 0,
  omschrijving: () => "Inkomsten uit verhuur onroerend goed",
  einddatum: v => mapLocalDateToString(v.eindDatum),
  eindeBijAo: v => v.scenarioInstellingen.eindeBijArbeidsongeschiktheid,
  eindeBijWw: v => v.scenarioInstellingen.eindeWerkloosheid,
  overgangOpPartner: v => v.scenarioInstellingen.overgangBijOverlijden,
  overgangOpPartnerEinddatum: v =>
    v.scenarioInstellingen.overgangBijOverlijden ? mapLocalDateToString(v.scenarioInstellingen.einddatum) : null,
  overgangOpPartnerEindleeftijd: v => v.scenarioInstellingen.leeftijd,
  overgangOpPartnerPercentage: v => v.scenarioInstellingen.percentage,
  resultaatVdc: v => v.scenarioInstellingen.regelingArbeidsongeschiktheid,
  bedrag: v => v.inkomstenVerhuurOnroerendGoed,
  fiscaalBedrag: () => null,
  looptijdInMaanden: () => null,
  soort: () => OverigeInkomstenBox3Soort.VerhuurInkomsten
});

const mapInkomstenUitBeleggingen = createMapToDl(inkomstenUitBeleggingenModalSchema).to<InkomstenBox3>({
  volgnummer: () => 1,
  omschrijving: () => "Inkomsten uit beleggingen",
  einddatum: v => mapLocalDateToString(v.eindDatum),
  eindeBijAo: v => v.scenarioInstellingen.eindeBijArbeidsongeschiktheid,
  eindeBijWw: v => v.scenarioInstellingen.eindeWerkloosheid,
  overgangOpPartner: v => v.scenarioInstellingen.overgangBijOverlijden,
  overgangOpPartnerEinddatum: v =>
    v.scenarioInstellingen.overgangBijOverlijden ? mapLocalDateToString(v.scenarioInstellingen.einddatum) : null,
  overgangOpPartnerEindleeftijd: v => v.scenarioInstellingen.leeftijd,
  overgangOpPartnerPercentage: v => v.scenarioInstellingen.percentage,
  resultaatVdc: v => v.scenarioInstellingen.regelingArbeidsongeschiktheid,
  bedrag: v => v.inkomstenUitBeleggingen,
  fiscaalBedrag: () => null,
  looptijdInMaanden: () => null,
  soort: () => OverigeInkomstenBox3Soort.BeleggingenInkomsten
});

const aanvragerInfoSchema = Yup.object({
  klantId: Yup.string(),
  geboorteDatum: nullableLocalDateSchema,
  optimaleVerdeling: Yup.boolean().nullable(),
  fiscaleVerdelingPercentage: Yup.number().nullable(),
  inkomen: inkomenSchema,
  aow: aowSchema,
  fiscaliteit: fiscaliteitSchema,
  sociaal: sociaalSchema,
  brutoSalarisUitDienstVerband: Yup.number().nullable()
});

const mapFiscaleAftrekposten = createMapToDl(fiscaleAftrekpostenModalSchema).to<FiscaleAftrekposten>({
  teBetalenPartnerAlimentatieBedrag: v => v.teBetalenAlimentatie as number,
  teBetalenPartnerAlimentatieEinddatum: v => mapLocalDateToString(v.eindDatum)
});

const mapFiscaleBijtelposten = createMapToDl(fiscaleBijtelpostenModalSchema).to<FiscaleBijtelposten>({
  autovdZaakCataloguswaardeBedrag: () => 0,
  bijtellingAutovdZaak: () => BijtellingAutovdZaakOpties._0Procent,
  bijdrageAutovdZaakBedrag: v => v.eigenBijdrage ?? 0,
  autovdZaakBijtellingBedrag: v => v.bijtellingBedraagt
});

const mapAanvrager = createMapToDl(aanvragerInfoSchema).to<InkomenFiscusAanvrager>({
  klantId: v => v.klantId,
  aowData: v => mapAow(v.aow),
  fiscaleAftrekposten: v =>
    v.fiscaliteit.toggleFiscaleAftrekposten ? mapFiscaleAftrekposten(v.fiscaliteit.fiscaleAftrekposten) : null,
  fiscaleBijtelposten: v =>
    v.fiscaliteit.toggleFiscaleBijtelposten ? mapFiscaleBijtelposten(v.fiscaliteit.fiscaleBijtelposten) : null,
  heffingskortingen: v => ({
    beleggingskortingBedrag: v.fiscaliteit.beleggingskorting
  }),
  fiscaleVerdelingPercentage: v => (v.optimaleVerdeling ? null : v.fiscaleVerdelingPercentage),
  wekenEis: v => v.sociaal.voldoetWekeneis,
  jarenEis: v => v.sociaal.voldoetJareneis,
  arbeidsverledenInJaren: v => v.sociaal.feitelijkArbeidsverleden,
  soortLoonverklaring: v => mapVerklaringInkomenEnum(v.inkomen.verklaringInkomen),
  toetsinkomenUwv: v =>
    v.inkomen.verklaringInkomen === InkomenVerklaring.UWVdocument
      ? v.inkomen.toetsinkomenUwv
      : v.inkomen.verklaringInkomen === InkomenVerklaring.ArbeidScan
      ? v.inkomen.arbeidsscanVerdiencapaciteit
      : null,
  loondiensten: v =>
    v.inkomen.brutoSalarisUitDienstverbandIsEnabled
      ? v.inkomen.inkomenUitArbeid.map((ia, i) =>
          mapInkomenUitArbeid({ i, brutoSalarisUitDienstVerband: v.brutoSalarisUitDienstVerband })(ia)
        )
      : [],

  onderneming: v => (v.inkomen.ondernemingIsEnabled ? mapOnderneming(v.fiscaliteit)(v.inkomen.onderneming) : null),

  overigeWerkzaamheden: v =>
    v.inkomen.overigeWerkzaamhedenIsEnabled ? mapOverigeWerkzaamheden(v.inkomen.overigeWerkzaamheden) : null,
  aanmerkelijkBelang: v =>
    v.inkomen.inkomstenAanmerkelijkBelangIsEnabled
      ? mapAanmerkelijkBelang(v.inkomen.inkomstenAanmerkelijkBelang)
      : null,
  teOntvangenAlimentatieExPartner: v =>
    v.inkomen.overigeInkomstenBox1.alimentatieExBedrag
      ? mapToOverigeInkomstenBox1Type({
          bedrag: v.inkomen.overigeInkomstenBox1.alimentatieExBedrag,
          einddatum: v.inkomen.overigeInkomstenBox1.alimentatieExTotDatum,
          omschrijving: "Alimentatie inkomsten"
        })
      : null,
  anwUitkering: v =>
    v.inkomen.overigeInkomstenBox1.anwUitkeringBedrag
      ? mapToOverigeInkomstenBox1Type({
          bedrag: v.inkomen.overigeInkomstenBox1.anwUitkeringBedrag,
          einddatum: v.inkomen.overigeInkomstenBox1.anwUitkeringTotDatum,
          omschrijving: "ANW uitkering"
        })
      : null,
  wgaUitkering: v =>
    v.inkomen.overigeInkomstenBox1.wgaUitkeringBedrag
      ? mapToOverigeInkomstenBox1Type({
          bedrag: v.inkomen.overigeInkomstenBox1.wgaUitkeringBedrag,
          einddatum: v.inkomen.overigeInkomstenBox1.wgaUitkeringTotDatum,
          omschrijving: "WGA uitkering"
        })
      : null,
  ivaUitkering: v =>
    v.inkomen.overigeInkomstenBox1.ivaUitkeringBedrag
      ? mapToOverigeInkomstenBox1Type({
          bedrag: v.inkomen.overigeInkomstenBox1.ivaUitkeringBedrag,
          einddatum: v.inkomen.overigeInkomstenBox1.ivaUitkeringTotDatum,
          omschrijving: "IVA uitkering"
        })
      : null,
  wazUitkering: v =>
    v.inkomen.overigeInkomstenBox1.wazUitkeringBedrag
      ? mapToOverigeInkomstenBox1Type({
          bedrag: v.inkomen.overigeInkomstenBox1.wazUitkeringBedrag,
          einddatum: v.inkomen.overigeInkomstenBox1.wazUitkeringTotDatum,
          omschrijving: "WAZ uitkering"
        })
      : null,
  waoUitkeringTijdelijk: v =>
    v.inkomen.overigeInkomstenBox1.waoUitkeringTijdelijkBedrag
      ? mapToOverigeInkomstenBox1Type({
          bedrag: v.inkomen.overigeInkomstenBox1.waoUitkeringTijdelijkBedrag,
          einddatum: v.inkomen.overigeInkomstenBox1.waoUitkeringTijdelijkTotDatum,
          omschrijving: "WAO tijdelijke uitkering"
        })
      : null,
  waoUitkeringBlijvend: v =>
    v.inkomen.overigeInkomstenBox1.waoUitkeringBlijvendBedrag
      ? mapToOverigeInkomstenBox1Type({
          bedrag: v.inkomen.overigeInkomstenBox1.waoUitkeringBlijvendBedrag,
          einddatum: v.inkomen.overigeInkomstenBox1.waoUitkeringBlijvendTotDatum,
          omschrijving: "WAO blijvende uitkering"
        })
      : null,
  wajongUitkering: v =>
    v.inkomen.overigeInkomstenBox1.wajongUitkeringBedrag
      ? mapToOverigeInkomstenBox1Type({
          bedrag: v.inkomen.overigeInkomstenBox1.wajongUitkeringBedrag,
          einddatum: v.inkomen.overigeInkomstenBox1.wajongUitkeringTotDatum,
          omschrijving: "Wajong uitkering"
        })
      : null,
  wwUitkering: v =>
    v.inkomen.overigeInkomstenBox1.wwUitkeringBedrag
      ? mapToOverigeInkomstenBox1Type({
          bedrag: v.inkomen.overigeInkomstenBox1.wwUitkeringBedrag,
          einddatum: v.inkomen.overigeInkomstenBox1.wwUitkeringTotDatum,
          omschrijving: "WW uitkering"
        })
      : null,
  rwwUitkering: v =>
    v.inkomen.overigeInkomstenBox1.rwwUitkeringBedrag
      ? mapToOverigeInkomstenBox1Type({
          bedrag: v.inkomen.overigeInkomstenBox1.rwwUitkeringBedrag,
          einddatum: v.inkomen.overigeInkomstenBox1.rwwUitkeringTotDatum,
          omschrijving: "RWW uitkering"
        })
      : null,
  wachtgeld: v =>
    v.inkomen.overigeInkomstenBox1.wachtgeldBedrag
      ? mapToOverigeInkomstenBox1Type({
          bedrag: v.inkomen.overigeInkomstenBox1.wachtgeldBedrag,
          einddatum: v.inkomen.overigeInkomstenBox1.wachtgeldTotDatum,
          omschrijving: "Wachtgeld"
        })
      : null,
  nabestaandenpensioenVooAow: v =>
    v.inkomen.overigeInkomstenBox1.nabestaandenpensioenVoorAowBedrag
      ? v.inkomen.overigeInkomstenBox1.nabestaandenpensioenVoorAowBedrag
      : null,
  nabestaandenpensioenNaAow: v =>
    v.inkomen.overigeInkomstenBox1.nabestaandenpensioenNaAowBedrag
      ? v.inkomen.overigeInkomstenBox1.nabestaandenpensioenNaAowBedrag
      : null,

  overigeInkomsten1: v =>
    v.inkomen.overigeInkomstenBox1.overigeInkomsten1Bedrag
      ? mapToOverigeInkomstenBox1Type({
          bedrag: v.inkomen.overigeInkomstenBox1.overigeInkomsten1Bedrag,
          einddatum: v.inkomen.overigeInkomstenBox1.overigeInkomsten1TotDatum,
          omschrijving: v.inkomen.overigeInkomstenBox1.overigeInkomsten1Omschrijving
        })
      : null,
  overigeInkomsten2: v =>
    v.inkomen.overigeInkomstenBox1.overigeInkomsten2Bedrag
      ? mapToOverigeInkomstenBox1Type({
          bedrag: v.inkomen.overigeInkomstenBox1.overigeInkomsten2Bedrag,
          einddatum: v.inkomen.overigeInkomstenBox1.overigeInkomsten2TotDatum,
          omschrijving: v.inkomen.overigeInkomstenBox1.overigeInkomsten2Omschrijving
        })
      : null,
  inkomstenBox3Verhuur: v =>
    v.inkomen.inkomstenBox3IsEnabled ? mapInkomstenVerhuurOnroerendGoed(v.inkomen.inkomstenVerhuurOnroerendGoed) : null,
  inkomstenBox3Beleggingen: v =>
    v.inkomen.inkomstenBox3IsEnabled ? mapInkomstenUitBeleggingen(v.inkomen.inkomstenUitBeleggingen) : null,
  heeftLoondienst: v => hasValue(v.inkomen.brutoSalarisUitDienstverband),
  heeftOverigeInkomstenBox1: v => hasValue(v.inkomen.overigeInkomstenBox1.totaal),
  heeftInkomstenBox3: v => v.inkomen.inkomstenBox3IsEnabled,
  rapportnummer: v =>
    v.inkomen.verklaringInkomen === InkomenVerklaring.ArbeidScan ? v.inkomen.arbeidsscanRapportnummer : null,
  verdiencapaciteitScore: v =>
    v.inkomen.verklaringInkomen === InkomenVerklaring.ArbeidScan ? v.inkomen.arbeidsscanScore : null,
  geboortedatum: _ => null //TODO: implement after nswag update
});

export const mapInkomenEnFiscusUiToDl = createMapToDl(inkomenEnFiscusSchema).to<InkomenEnFiscusDlEntry>({
  aanvrager1: v =>
    mapAanvrager({
      klantId: v.aanvrager1KlantId,
      aow: v.aanvrager1Aow,
      fiscaleVerdelingPercentage: v.fiscaleVerdeling.aanvrager1,
      fiscaliteit: v.aanvrager1Fiscaliteit,
      geboorteDatum: v.aanvrager1Aow.geboorteDatum,
      inkomen: v.aanvrager1Inkomen,
      optimaleVerdeling: v.fiscaleVerdeling.optimaleVerdeling,
      sociaal: v.aanvrager1Sociaal,
      brutoSalarisUitDienstVerband: v.aanvrager1Inkomen.brutoSalarisUitDienstverband
    }),
  aanvrager2: v =>
    v.hasAanvrager2
      ? mapAanvrager({
          klantId: v.aanvrager2KlantId,
          aow: v.aanvrager2Aow,
          fiscaleVerdelingPercentage: v.fiscaleVerdeling.aanvrager2,
          fiscaliteit: v.aanvrager2Fiscaliteit,
          geboorteDatum: v.aanvrager2Aow.geboorteDatum,
          inkomen: v.aanvrager2Inkomen,
          optimaleVerdeling: v.fiscaleVerdeling.optimaleVerdeling,
          sociaal: v.aanvrager2Sociaal,
          brutoSalarisUitDienstVerband: v.aanvrager2Inkomen.brutoSalarisUitDienstverband
        })
      : null,
  optimaleFiscaleVerdeling: v => v.fiscaleVerdeling.optimaleVerdeling
});
