import { NumberInput, RadioInputGroup } from "adviesbox-shared";
import React, { ReactElement } from "react";
import { getKlantprofielResources } from "../../klantprofiel-resources";
import { QuestionSpecCombined, QuestionType } from "../../klantprofiel-schema";
import classNames from "classnames";

type QuestionRadioMetJaarProps = {
  data: QuestionSpecCombined;
};
export const QuestionRadioMetJaar = ({ data }: QuestionRadioMetJaarProps): ReactElement => {
  if (data.type !== QuestionType.radioMetJaar) return <>{`invalid QuestionType: ${data.type}`}</>;

  const opties = data.schema.antwoorden.map((a, i) => {
    return {
      appendChildren: a.omschrijving?.includes("<aantal> jaar") ? (
        <div className="d-flex flex-wrap ml-2">
          <NumberInput
            name={`vragen[${data.index}].antwoorden[${i}].waarde2`}
            data-testid={`radio-jaar-element-${data.schema.code}`}
            fieldSize="s"
            suppressError={true}
          />
          <label className="px-1 pt-1 no-margin" htmlFor={`${a.code}-${i}.jaren`}>
            Jaar
          </label>
        </div>
      ) : (
        <></>
      ),
      label: a.omschrijving?.replace("<aantal> jaar", "") ?? "",
      value: a.code.toString()
    };
  });
  const name = `vragen[${data.index}].gekozenAntwoord`;

  return (
    <>
      <div className={classNames({ "mt-3": data.schema.omschrijving })}>
        {data.schema.omschrijving && <h2>{data.schema.omschrijving}</h2>}
        <span className="d-block font-italic px-1">{getKlantprofielResources("EenAntwoord")}</span>
        <RadioInputGroup name={name} options={opties} key={`key-${data.index}`} />
      </div>
    </>
  );
};
