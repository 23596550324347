import { CheckBox, CurrencyInput, NumberInput, TextInput } from "adviesbox-shared";
import classNames from "classnames";
import { useFormikContext } from "formik";
import React, { ReactElement } from "react";
import { getKlantprofielResources } from "../../klantprofiel-resources";
import {
  CheckboxType,
  KlantprofielAntwoordenType,
  QuestionSpecCombined,
  QuestionType
} from "../../klantprofiel-schema";

type QuestionRadioProps = {
  data: QuestionSpecCombined;
};

const renderAntwoord = (
  data: QuestionSpecCombined,
  antwoord: KlantprofielAntwoordenType,
  index: number,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
): ReactElement => {
  const isVrijInvoer =
    antwoord.omschrijving?.includes("<vrije invoer>") ||
    data.checkboxAnswers?.find(v => v.index === index)?.type === CheckboxType.text;
  const isBedragPerMaand = antwoord.omschrijving?.includes("<bedrag> per maand");
  const isBedragInvoer =
    !antwoord.omschrijving?.includes("<bedrag> per maand") &&
    (antwoord.omschrijving?.includes("<bedrag>") ||
      data.checkboxAnswers?.find(v => v.index === index)?.type === CheckboxType.bedrag);
  const isAantalMaanden = antwoord.omschrijving?.includes("<aantal> maanden");
  const isAantalJaar = antwoord.omschrijving?.includes("<aantal> jaar");

  const omschrijving =
    antwoord.omschrijving
      ?.replace("<bedrag> per maand", "")
      .replace("<aantal> maanden", "")
      .replace("<aantal> jaar", "")
      .replace("<bedrag>", "")
      .replace("<aantal>", "")
      .replace("<vrije invoer>", "")
      .replace("Anders, namelijk:", "")
      .trim() ?? "";

  if (isBedragInvoer) {
    return (
      <CheckBox
        caption={omschrijving}
        key={`key-${index}`}
        name={`vragen[${data.index}].antwoorden[${index}].waarde1`}
        onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
          if (e.target.checked) {
            return setFieldValue(`vragen[${data.index}].antwoorden[${index}].waarde1`, true);
          } else {
            return setFieldValue(`vragen[${data.index}].antwoorden[${index}].waarde1`, null);
          }
        }}
        appendChildren={
          <CurrencyInput
            name={`vragen[${data.index}].antwoorden[${index}].waarde2`}
            className="ml-2"
            allowNull={true}
            fieldSize="m"
            decimalen={2}
          />
        }
      />
    );
  }

  if (isBedragPerMaand) {
    return (
      <CheckBox
        caption={omschrijving}
        key={`key-${index}`}
        name={`vragen[${data.index}].antwoorden[${index}].waarde1`}
        onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
          if (e.target.checked) {
            return setFieldValue(`vragen[${data.index}].antwoorden[${index}].waarde1`, true);
          } else {
            return setFieldValue(`vragen[${data.index}].antwoorden[${index}].waarde1`, null);
          }
        }}
        appendChildren={
          <div className="d-flex flex-wrap ml-2">
            <CurrencyInput
              name={`vragen[${data.index}].antwoorden[${index}].waarde2`}
              className="ml-2"
              allowNull={true}
              fieldSize="m"
              decimalen={2}
            />
            <label className="px-1 pt-1 no-margin" htmlFor={`vragen[${data.index}].antwoorden[${index}].waarde`}>
              per maand
            </label>
          </div>
        }
      />
    );
  }

  if (isAantalMaanden) {
    return (
      <CheckBox
        caption={omschrijving}
        key={`key-${index}`}
        name={`vragen[${data.index}].antwoorden[${index}].waarde1`}
        onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
          if (e.target.checked) {
            return setFieldValue(`vragen[${data.index}].antwoorden[${index}].waarde1`, true);
          } else {
            return setFieldValue(`vragen[${data.index}].antwoorden[${index}].waarde1`, null);
          }
        }}
        appendChildren={
          <div className="d-flex flex-wrap ml-2">
            <NumberInput
              name={`vragen[${data.index}].antwoorden[${index}].waarde2`}
              className="ml-2"
              type="text"
              fieldSize="s"
            />
            <span className="d-block px-1">Maanden</span>
          </div>
        }
      />
    );
  }

  if (isAantalJaar) {
    return (
      <CheckBox
        caption={omschrijving}
        key={`key-${index}`}
        name={`vragen[${data.index}].antwoorden[${index}].waarde1`}
        onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
          if (e.target.checked) {
            return setFieldValue(`vragen[${data.index}].antwoorden[${index}].waarde1`, true);
          } else {
            return setFieldValue(`vragen[${data.index}].antwoorden[${index}].waarde1`, null);
          }
        }}
        appendChildren={
          <div className="d-flex flex-wrap ml-2">
            <NumberInput
              name={`vragen[${data.index}].antwoorden[${index}].waarde2`}
              className="ml-2"
              type="text"
              fieldSize="s"
            />
            <span className="d-block px-1">Jaar</span>
          </div>
        }
      />
    );
  }

  if (isVrijInvoer) {
    return (
      <CheckBox
        styleFix={true}
        caption={omschrijving}
        key={`key-${index}`}
        name={`vragen[${data.index}].antwoorden[${index}].waarde1`}
        onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
          if (e.target.checked) {
            return setFieldValue(`vragen[${data.index}].antwoorden[${index}].waarde1`, true);
          } else {
            return setFieldValue(`vragen[${data.index}].antwoorden[${index}].waarde1`, null);
          }
        }}
        appendChildren={
          <TextInput
            name={`vragen[${data.index}].antwoorden[${index}].waarde2`}
            className="ml-2"
            type="text"
            fieldSize="l"
          />
        }
      />
    );
  }

  return (
    <CheckBox
      caption={omschrijving}
      key={`key-${index}`}
      name={`vragen[${data.index}].antwoorden[${index}].waarde1`}
      onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
        if (e.target.checked) {
          return setFieldValue(`vragen[${data.index}].antwoorden[${index}].waarde1`, true);
        } else {
          return setFieldValue(`vragen[${data.index}].antwoorden[${index}].waarde1`, null);
        }
      }}
    />
  );
};

export const QuestionCheckbox = ({ data }: QuestionRadioProps): ReactElement => {
  const { setFieldValue } = useFormikContext();
  if (data.type !== QuestionType.checkbox) return <>{`invalid QuestionType: ${data.type}`}</>;
  if (!data.schema.antwoorden?.length) return <>{`missing answers for QuestionType.checkbox`}</>;

  return (
    <>
      <div className={classNames({ "mt-3": data.schema.omschrijving })}>
        {data.schema.omschrijving && <h2>{data.schema.omschrijving}</h2>}
        <span className="d-block font-italic px-1">{getKlantprofielResources("MeerdereAntwoorden")}</span>
        <div className="mx-2 mt-3 mb-3">
        {data.schema.antwoorden.map((antwoord, index) => renderAntwoord(data, antwoord, index, setFieldValue))}
        </div>
      </div>
    </>
  );
};
