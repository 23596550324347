/* istanbul ignore file */
import { getAppLocation, Icon, SettingsContext } from "adviesbox-shared";
import React, { ReactElement, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { ProductTypeName } from "../../../../aanvraag/infra/aanvraag-schema";
import { ErrorType, HdnValidatiemeldingFormApp, SoortHdnPartijOptions } from "./aanvullende-gegevens-schema";
import classes from "./DossierbalkFoutenDropdown.module.scss";
import DossierAanvraagFoutenContext from "../../../dossier-aanvraag-fouten/dossier-aanvraag-fouten-context";

export const getProductPath = (productType: ProductTypeName | null): string => {
  switch (productType) {
    case ProductTypeName.hypotheek:
      return "hypotheek";
    case ProductTypeName.ORV:
      return "orv";
    case ProductTypeName.AOV:
      return "aov";
    case ProductTypeName.taxatie:
      return "taxatie";
    case ProductTypeName.BG:
      return "bankgarantie";
    default:
      return "";
  }
};
type DossierbalkFoutEntryProps = {
  removeItem: Function;
  item: ErrorType;
  id: number;
  vestiging: string | null;
  adviesdossier: string | null;
  voorstel: string | null;
  reloadNavigation: () => void;
};

const DossierbalkFoutEntry = ({
  removeItem,
  item,
  id,
  vestiging,
  adviesdossier,
  voorstel,
  reloadNavigation
}: DossierbalkFoutEntryProps): ReactElement => {
  const [removed, setRemoved] = useState(false);
  const { nieuweAanvraag } = useContext(DossierAanvraagFoutenContext);

  const settings = useContext(SettingsContext);
  const productPath = getProductPath(nieuweAanvraag?.productType ?? null);
  const urls = item?.relativeUrl?.split(", ").map(url =>
    url
      .replace("{vestigingId}", vestiging ?? "")
      .replace("{adviesdossierId}", adviesdossier ?? "")
      .replace("{voorstelId}", voorstel ?? "")
      .replace("{producttype}", productPath)
  );

  const mapTarget = (formApp: HdnValidatiemeldingFormApp): "INS" | "ADV" | "POR" | "PRD" | "BER" | "LIC" | "DOC" => {
    switch (formApp) {
      case HdnValidatiemeldingFormApp.Advies:
        return "ADV";
      case HdnValidatiemeldingFormApp.Contract:
        return "LIC";
      case HdnValidatiemeldingFormApp.Instellingen:
        return "INS";
    }
  };

  const getVeldInformatie = (item: ErrorType): string => {  
    const veldInformatie = item.FEVeldInfo?.veldNaam || item.platformVeldInfo?.naam || "";

    if (item.soortPartijRef === SoortHdnPartijOptions.Aanvrager) {
      const partij = item.parentOccurance === 1 ? "aanvrager" : item.parentOccurance === 2 ? "partner" : "";
      return `${veldInformatie}${partij && ` ${partij}`}`;
    }

    return veldInformatie;
  }  

  return (
    <div
      onAnimationEnd={
        /* istanbul ignore next */ () => {
          removeItem(item.errorId);
          setRemoved(false);
        }
      }
      className={removed ? `${classes.entry} ${classes.remove}` : classes.entry}
      data-testid={`fout-entry-${id}`}
      id={`fout-entry-${id}`}
    >
      <div className={classes.entry_title_wrapper}>
        <div className={classes.entry_title}>
          <h4>{item.omschrijving}</h4>
        </div>
        <div onClick={() => setRemoved(true)} data-testid={`fout-entry-remove-${id}`} id={`fout-entry-remove-${id}`}>
          <Icon name="prullenbak" alt="Verwijderen" />
        </div>
      </div>
      {item.platformVeldInfo?.naam && (
        <div data-testid={`fout-entry-veldnaam-${id}`} id={`fout-entry-veldnaam-${id}`} className={classes.entry_body}>
          <span>{`${item.platformVeldInfo.naam.includes(",") ? "Veldnamen" : "Veldnaam"}: `}</span>
          <span>{getVeldInformatie(item)}</span>
        </div>
      )}
      {item.platformVeldInfo?.omschrijving && (
        <div
          data-testid={`fout-entry-veldomschrijving-${id}`}
          id={`fout-entry-veldomschrijving-${id}`}
          className={classes.entry_body}
        >
          <span>{"Omschrijving: "}</span>
          <span>{item.platformVeldInfo.omschrijving}</span>
        </div>
      )}
      {item.locatie && urls?.length && (
        <div className={classes.entry_body}>
          <span>{"Locatie: "}</span>
          <ul data-testid={`fout-entry-locatie-${id}`} id={`fout-entry-locatie-${id}`}>
            {urls.map((url, i) => {
              if (item.app !== 1) {
                // so we cant use react-router
                return (
                  <li key={i}>
                    {item.app ? (
                      <a
                        href={`${getAppLocation(
                          window.location.origin,
                          "ADV",
                          mapTarget(item.app),
                          settings.baseUrls
                        )}${item.relativeUrl
                          ?.replace("{vestigingId}", vestiging || "")
                          ?.replace("{adviesdossierId}", adviesdossier ?? "")
                          ?.replace("{voorstelId}", voorstel ?? "")
                          ?.replace("{producttype}", productPath)}`}
                        target="_self"
                        rel="noopener noreferrer"
                      >
                        {item.locatie}
                      </a>
                    ) : (
                      <>Link niet beschikbaar</>
                    )}
                  </li>
                );
              }

              return (
                <li key={i}>
                  <Link
                    key={`errorLink-${i}`}
                    className={classes.entry_body_link}
                    to={url}
                    onClick={() => reloadNavigation()}
                  >
                    {item.locatie
                      ?.split(", ")
                      [i]?.replace("{producttype}", nieuweAanvraag?.productType?.toString() ?? "")}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      )}
      {item.foutmeldingControleSchema && (
        <div className={classes.entry_body}>
          <span>{"Toelichting: "}</span>
          <ul>
            <li>{item.foutmeldingControleSchema}</li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default DossierbalkFoutEntry;
