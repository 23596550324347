import React, { ReactElement } from "react";
import { connect } from "formik";
import { LabeledRadioInput, LabeledDateInput, LabeledBevestigingInput, SyncHeight, SyncHeightProps } from "adviesbox-shared";
import { PersonaliaState } from "../infra/personalia-schema";
import { Direction } from "../../shared/types";

const Aanvrager1Extra = ({ targetRef: syncHeightRef, sourceRef: assignRef }: SyncHeightProps): ReactElement => {
  return (
    <>
      <SyncHeight targetRef={syncHeightRef} sourceRef={assignRef}>
        <LabeledDateInput caption="Geboortedatum" name="aanvrager1Extra.geboortedatum" verplicht />
      </SyncHeight>

      <LabeledRadioInput
        caption="Geslacht"
        layout={Direction.Horizontal}
        name="aanvrager1Extra.geslacht"
        options={[
          { label: "Man", value: "Man" },
          { label: "Vrouw", value: "Vrouw" }
        ]}
      />
      <LabeledBevestigingInput caption="Roker" name="aanvrager1Extra.roker" />
    </>
  );
};

export default connect<SyncHeightProps, PersonaliaState>(Aanvrager1Extra);
