import { LabeledBevestigingInput, LabeledCurrencyInput, LabeledRadioInput, LabeledSelectInput } from "adviesbox-shared";
import { connect, FormikContextType } from "formik";
import React, { ReactElement, useMemo } from "react";
import LabeledHdnKeuzelijst from "../../shared/components/hdn-keuzelijst/LabeledHdnKeuzelijst";
import { getAanvraagLeningTextResources } from "../infra/aanvraag-lening-resources";
import { AanvraagLeningState } from "../infra/aanvraag-lening-schema";

const Lening = ({
  formik: {
    values: { lening, voorstellen }
  }
}: {
  formik: FormikContextType<AanvraagLeningState>;
}): ReactElement => {
  const gewenstehyphotheekOptions = useMemo(
    () =>
      voorstellen.voorstellen.map(voorstel => ({
        label: voorstel.omschrijving ? voorstel.omschrijving : `Voorstel #${voorstel.volgnummer}`,
        value: voorstel.voorstelId
      })),
    [voorstellen.voorstellen]
  );

  const geselecteerdVoorstel = useMemo(
    () => voorstellen.voorstellen.find(voorstel => voorstel.voorstelId === lening.gewensteHypotheek),
    [lening.gewensteHypotheek, voorstellen.voorstellen]
  );
  const voorstelVanING = geselecteerdVoorstel?.maatschappijCode === "IN";

  const bespreekzaakToolTip = voorstelVanING ? { tooltip: getAanvraagLeningTextResources("leningBespreekzaak") } : null;

  return (
    <>
      <LabeledRadioInput
        caption="Gewenste hypotheek"
        name="lening.gewensteHypotheek"
        options={gewenstehyphotheekOptions}
      />

      <LabeledCurrencyInput caption="Inschrijvingsbedrag" name="lening.inschrijvingsbedrag" readonly={true} />

      <LabeledBevestigingInput caption="Bespreekzaak" name="lening.bespreekzaak" {...bespreekzaakToolTip} />
      <LabeledBevestigingInput caption="Verduurzaming besproken" name="lening.verduurzamingBesproken" />

      <LabeledHdnKeuzelijst
        caption="Soort advies"
        name="lening.soortAdvies"
        berichtSoortType="AX"
        keuzelijst="AdviesTypeType"
      />
      {voorstelVanING && (
        <>
          <LabeledCurrencyInput
            caption="Netto advieskosten"
            name="lening.nettoAdvieskosten"
            tooltip={getAanvraagLeningTextResources("leningNettoAdvieskosten")}
          />

          <LabeledSelectInput
            caption="Betaalwijze advieskosten"
            name="lening.betaalwijzeAdvieskosten"
            options={[
              { label: "Meefinancieren in de hypotheek", value: "01" },
              { label: "Voldoen uit eigen middelen", value: "02" }
            ]}
            emptyValue="-- selecteer een keuze --"
            tooltip={getAanvraagLeningTextResources("leningBetaalwijzeAdvieskosten")}
          />
        </>
      )}
    </>
  );
};

export default connect<{}, AanvraagLeningState>(Lening);
