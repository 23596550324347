import React, { ReactElement } from "react";
import { Formik } from "formik";
import * as Yup from "yup";

import { OndernemingModalType } from "../infra/inkomen-en-fiscus-schema";

import { scenarioCardSchema } from "../../shared/types";
import { LocalDate } from "@js-joda/core";
import { birthdate2age } from "../../shared/utils/birthdate-to-age";
import { ScenarioCard } from "../../shared/components/scenario-input/scenario-card";
import Modal from "../../shared/components/modal/Modal";

export type OndernemingScenarioData = {
  geboorteDatum: LocalDate | null | undefined;
  data: OndernemingModalType;
};

type OndernemingScenarioProps = {
  onSave?: (data: OndernemingModalType) => void;
  closeModal?: () => void;
};

const OndernemingScenario = ({
  geboorteDatum,
  data,
  onSave,
  closeModal
}: OndernemingScenarioData & OndernemingScenarioProps): ReactElement => {
  const age = geboorteDatum ? birthdate2age(geboorteDatum) : 18;
  const entries = data.scenario.length;

  return (
    <Formik
      initialValues={{ onderneming: data }}
      validationSchema={Yup.object({
        onderneming: Yup.object({
          scenario: scenarioCardSchema
        })
      })}
      onSubmit={(values): void => {
        onSave && onSave(values.onderneming);
        closeModal && closeModal();
      }}
    >
      {formik => {
        const {
          values: { onderneming }
        } = formik;

        const body = (
          <ScenarioCard
            name={`onderneming.scenario`}
            startBedrag={onderneming.inkomensverleden.gemiddeld || 0}
            captionStart={age}
            captionSuffix={" jr"}
            entries={entries}
          />
        );

        return (
          <>
            <Modal
              title="Scenario onderneming"
              body={body}
              onSubmitClick={formik.submitForm}
              onCancelClick={closeModal}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default OndernemingScenario;
