import { Icon } from "adviesbox-shared";
import React, { ReactElement, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { castUitslag2Icon } from "../../../maximale-hypotheek/maximale-hypotheek-data-grid/maximale-hypotheek-data-grid-helpers/maximale-hypotheek-data-grid-icons/maximale-hypotheek-data-grid-icons";
import { bedragFormat } from "../../../shared/utils/currency";
import { HypotheekResultaatType, HypotheekVergelijkenModalType } from "../hypotheek-vergelijken-types";
import { getRentedalingStars } from "../hypotheek-vergelijker-data-grid/hypotheek-vergelijker-resultaat-data-grid";
import { renderHypotheekVergelijkerPDFFooter } from "./hypotheek-vergelijker-pdf-footer";
import { renderHypotheekVergelijkerPDFHeader } from "./hypotheek-vergelijker-pdf-header";
import { printPDF } from "./hypotheek-vergelijker-pdf-helpers/hypotheek-vergelijker-pdf-helpers";
import { RenderHypotheekVoorstelTable } from "./hypotheek-vergelijker-pdf-voorstel-table";
import { RenderHypotheekVoorwaardenTable } from "./hypotheek-vergelijker-pdf-voorwarden-table";
import classes from "./hypotheek-vergelijker-pdf.module.scss";

type HypotheekVergelijkerPDFProps = {
  values: HypotheekVergelijkenModalType;
};
const amountOfLinesFirstPage = 45;
const amountOfLinesPerPage = 52;

export const hasSpaceForVoorstelTable = (
  index: number,
  hypotheekSamenstellingLength: number,
  amountOfLinesOnpage: number
): { hasSpace: boolean; space: number } => {
  const voorstelTableRows = hypotheekSamenstellingLength + 2;
  const space = amountOfLinesOnpage + voorstelTableRows;
  if (index === 1 && space >= amountOfLinesFirstPage) return { hasSpace: false, space };
  if (index !== 1 && space >= amountOfLinesPerPage) return { hasSpace: false, space };
  return { hasSpace: true, space };
};

export const hasSpaceForVoorwaardenTable = (
  index: number,
  hypotheekSamenstellingLength: number,
  amountOfLinesOnpage: number
): boolean => {
  const voorwaardenTableRows = 10;
  const space =
    hasSpaceForVoorstelTable(index, hypotheekSamenstellingLength, amountOfLinesOnpage).space + voorwaardenTableRows;
  if (index === 1 && space >= amountOfLinesFirstPage) return false;
  if (index !== 1 && space >= amountOfLinesPerPage) return false;
  return true;
};

export const HypotheekVergelijkerPDFGenerator = (props: HypotheekVergelijkerPDFProps): ReactElement => {
  const {
    values: {
      organisatieData,
      aanvrager1,
      aanvrager2,
      resultaten,
      sortedResultaten,
      hypotheekSamenstelling,
      voorwaardeSelectie
    }
  } = props;

  const [data, setData] = useState(resultaten);
  useEffect(() => {
    if (sortedResultaten.length > 0) {
      setData(sortedResultaten);
      return;
    }
    setData(resultaten);
  }, [setData, sortedResultaten, resultaten]);

  const displayAanvrager1 = !!aanvrager1?.achternaam;
  const displayAanvrager2 = !!aanvrager2?.achternaam;

  const hypotheekVergelijker = [...data];
  const amountOflinesTotal = hypotheekVergelijker.length;
  let amountOfpages = Math.ceil((amountOflinesTotal - amountOfLinesFirstPage) / amountOfLinesPerPage + 1);

  const renderPage = (
    renderVoorstellenOnly: boolean,
    renderVoorwaardenOnly: boolean,
    slicedContent: HypotheekResultaatType[],
    index?: number,
    firstRender?: boolean,
    lastPageHasSpaceForVoorstelTable?: boolean,
    lastPageHasSpaceForVoorwaardenTable?: boolean
  ): ReactElement => {
    return (
      <div
        className={classes.pdf_hide}
        id={`print-container-${index}`}
        key={`print-container-${index}-key`}
        style={{ maxWidth: "30.3cm", minWidth: "30.3cm", display: "block" }}
      >
        <div className={classes.pdf_page} style={{ backgroundColor: "white" }}>
          {firstRender &&
            renderHypotheekVergelijkerPDFHeader({
              bedrijfslogo: organisatieData?.logo,
              displayAanvrager1,
              aanvrager1,
              displayAanvrager2,
              aanvrager2
            })}
          {!renderVoorstellenOnly && !renderVoorwaardenOnly && slicedContent && (
            <table className={`${classes.pdf_table} ${classes.pdf_table_dataTable}`}>
              <thead>
                <tr>
                  <th className={`${classes.pdf_table_data_header} ${classes.pdf_table_data_header_first}`}>
                    Hypotheek mogelijk
                  </th>
                  <th className={`${classes.pdf_table_data_header} ${classes.pdf_table_data_header_S}`}>VWD</th>
                  <th className={`${classes.pdf_table_data_header} ${classes.pdf_table_data_header_XL}`}>
                    Maatschappij
                  </th>
                  <th className={`${classes.pdf_table_data_header} ${classes.pdf_table_data_header_XS}`}>Rente</th>
                  <th className={`${classes.pdf_table_data_header} ${classes.pdf_table_data_header_L_align_right}`}>
                    Bruto maandlast bij aanvang
                  </th>
                  <th className={`${classes.pdf_table_data_header} ${classes.pdf_table_data_header_L_align_right}`}>
                    Netto maand last bij aanvang
                  </th>
                  <th className={`${classes.pdf_table_data_header} ${classes.pdf_table_data_header_L_align_right}`}>
                    Totale netto last over looptijd
                  </th>
                  <th className={`${classes.pdf_table_data_header} ${classes.pdf_table_data_header_last}`}>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {slicedContent.map((v, i) => (
                  <tr key={"body_row" + i}>
                    <td>{castUitslag2Icon(v.UitslagMaximaleHypotheek)}</td>
                    <td>{castUitslag2Icon(v.UitslagVoorwaarden)}</td>
                    <td>{v.Productnaam}</td>
                    <td className={classes.pdf_table_right_align}>{v.WerkelijkRentePercentage?.toFixed(2) + " %"}</td>
                    <td className={classes.pdf_table_right_align}>{bedragFormat(v.BrutoMaandlastAanvangBedrag, 2)}</td>
                    <td className={classes.pdf_table_right_align}>{bedragFormat(v.NettoMaandlastAanvangBedrag, 2)}</td>
                    <td className={classes.pdf_table_right_align}>{bedragFormat(v.NettoLastTotaalBedrag, 2)}</td>
                    <td className={classes.pdf_table_right_align}>
                      <b>{getRentedalingStars(v.AutomatischeRentedalingDirect, v.AutomatischeRentedalingNaRvp)}</b>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          {(renderVoorstellenOnly || lastPageHasSpaceForVoorstelTable) &&
            RenderHypotheekVoorstelTable({ renderVoorstellenOnly, hypotheekSamenstelling })}
          {(renderVoorwaardenOnly || lastPageHasSpaceForVoorwaardenTable) &&
            RenderHypotheekVoorwaardenTable({ renderVoorwaardenOnly, voorwaardeSelectie })}
          {renderHypotheekVergelijkerPDFFooter({ organisatieNaam: organisatieData?.naam })}
        </div>
      </div>
    );
  };

  const renderPages = (): ReactElement => {
    let voorstelTableFitsOnLastPage = false;
    let voorwardenTableFitsOnLastPage = false;
    const counter = amountOfpages;
    const pages: ReactElement[] = [];
    if (hypotheekVergelijker.length === 0) return <></>;
    for (let i = 1; i <= counter; i++) {
      const lastPage = amountOfpages === i;
      const until = (): number => {
        if (i === 1) {
          return amountOfLinesFirstPage;
        }

        return amountOfLinesPerPage;
      };

      const sliced = hypotheekVergelijker.splice(0, until());
      const amountOfLinesOnpage = sliced.length;

      if (lastPage) {
        voorstelTableFitsOnLastPage = hasSpaceForVoorstelTable(i, hypotheekSamenstelling?.length, amountOfLinesOnpage)
          .hasSpace;
        voorwardenTableFitsOnLastPage = hasSpaceForVoorwaardenTable(
          i,
          hypotheekSamenstelling?.length,
          amountOfLinesOnpage
        );
      }

      if (i > amountOfpages) break;

      const page = renderPage(
        false,
        false,
        sliced,
        i,
        i === 1,
        voorstelTableFitsOnLastPage,
        voorwardenTableFitsOnLastPage
      );
      pages.push(page);

      if (lastPage && !(voorstelTableFitsOnLastPage && voorwardenTableFitsOnLastPage)) {
        amountOfpages = amountOfpages + 1;
        const verzekeringIndex = i + 1;
        const verzekeringTable = renderPage(
          !voorstelTableFitsOnLastPage,
          true,
          sliced,
          verzekeringIndex,
          false,
          false,
          true
        );
        pages.push(verzekeringTable);
      }
    }

    return <>{pages.map(page => page)}</>;
  };

  return (
    <>
      {renderPages()}

      <Button
        name="genereerHypotheekRapport"
        variant={"link"}
        onClick={/* istanbul ignore next */ async () => await printPDF(amountOfpages, "download")}
      >
        <Icon name="pdf" alt="Genereer rapport" />
        <span className="ml-2">Genereer rapport</span>
      </Button>
    </>
  );
};
