import React, { ReactElement } from "react";
import { connect, FormikContextType } from "formik";
import { CurrencyInput, LabeledCurrencyInput, Label, LabeledNumberInput, TooltipWrap } from "adviesbox-shared";
import { MaximaleHypotheekState } from "../infra/maximale-hypotheek-schema";
import { getMaximaleHypotheekTextResources } from "../infra/maximale-hypotheek-resources";

const Uitgangspunten = ({
  formik: {
    values: { uitgangspunten }
  }
}: {
  formik: FormikContextType<MaximaleHypotheekState>;
}): ReactElement => {
  let typePand: boolean | null;

  switch (uitgangspunten.objectSoort) {
    case "00": // Geen
    case "01": // Vrijstaande woning
    case "02": // 2-onder-1-kapwoning
    case "03": // Geschakelde 2-onder-1-kapwoning
    case "04": // Geschakelde woning
    case "05": // Halfvrijstaande woning
    case "06": // Tussenwoning
    case "07": // Hoekwoning
    case "08": // Eindwoning
    case "09": // Galerijflat
    case "10": // Portiekflat
    case "11": // Corridorflat
    case "12": // Maisonnette
    case "13": // Benedenwoning
    case "14": // Bovenwoning
    case "15": // Portiekwoning
    case "16": // Woonwagen / stacaravan
    case "17": // Woonwagenstandplaats / stacaravanstandplaats
      typePand = false;
      break;
    case "18": // Woonboot
    case "19": // Ligplaats
    case "20": // Waterwoning
    case "21": // Woon-/winkelpand
    case "22": // Bouwkavel
    case "23": // Garage
    case "24": // Parkeerplaats (dienstbaar aan wonen)
    case "25": // Berging
      typePand = true;
      break;
    default:
      typePand = null;
      break;
  }

  if (uitgangspunten.recreatieveBewoning) {
    typePand = true;
  }

  const marktwaardeTooltip = (): ReactElement | null => {
    if (typePand === true) {
      return (
        <TooltipWrap
          name="uitgangspunten.marktwaarde-warning-met-selectie"
          warningText={getMaximaleHypotheekTextResources("tooltipUitgangspuntenMarktwaardeTrue")}
          placement="auto-end"
          iconType="waarschuwing"
          tooltipClasses="m-1 d-inline-flex"
        />
      );
    }

    if (typePand === null) {
      return (
        <TooltipWrap
          name="uitgangspunten.marktwaarde-warning-zonder-selectie"
          warningText={getMaximaleHypotheekTextResources("tooltipUitgangspuntenMarktwaardeNull")}
          placement="auto-end"
          iconType="waarschuwing"
          tooltipClasses="m-1 d-inline-flex"
        />
      );
    }
    return null;
  };

  return (
    <>
      <div className="form-group form-row">
        <div className={`col-7`}>
          <Label caption="NHG" name="uitgangspunten.nhg" />
        </div>
        <div className={`col-5`}>
          <span id="uitgangspunten.nhg">{uitgangspunten.nhg ? "Ja" : "Nee"}</span>
        </div>
      </div>
      <LabeledNumberInput caption="Looptijd" name="uitgangspunten.looptijd" appendChildren={<span>jaar</span>} />

      <div className="form-group form-row">
        <div className={`col-7`}>
          <Label caption="Marktwaarde" key="uitgangspunten.marktwaarde" appendChildren={marktwaardeTooltip()} />
        </div>
        <div className={`col-5`}>
          <CurrencyInput name="uitgangspunten.marktwaarde" readonly={true} />
        </div>
      </div>

      <LabeledCurrencyInput caption="Gewenste hypotheek" name="uitgangspunten.gewensteHypotheek" readonly={true} />

      <LabeledCurrencyInput caption="Andere financieringen" name="uitgangspunten.andereFinanciering" readonly={true} />

      <LabeledCurrencyInput caption="Totale lening" name="uitgangspunten.totaleLening" readonly={true} />
    </>
  );
};

export default connect<{}, MaximaleHypotheekState>(Uitgangspunten);
