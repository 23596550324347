import React, { ReactElement, useContext, useMemo, useEffect } from "react";
import { connect, FormikContextType, getIn } from "formik";
import LabeledScenarioInput from "./labeled-scenario-input";
import { ISWSideEffects } from "../isw-side-effects/isw-side-effects";
import { initISWSideEffect, createISWSideEffect } from "../isw-side-effects/create-isw-helpers";
import { hasChanged } from "../isw-side-effects/has-changed-helper";
import { ErrorBoundaryContext } from "adviesbox-shared";

export type ScenarioCardInput = {
  bedrag: number | null;
  percentage: number | null;
};

export type InputListParams = {
  entries: number;
  columnCount: number;
};

export type ScenarioInputParams = {
  name: string;
  captionStart: number;
  captionPrefix: string;
  captionSuffix: string;
  disabled: boolean;
  disableFirstField: boolean;
  decimals?: 0 | 2 | undefined;
  readonly?: boolean;
};

export type ScenarioWrappers = {
  scenarioHeaderWrapper?: (columnIndex: number) => ReactElement;
  scenarioCardWrapper: (itemIndex: number, entries: number, scenarieInputParams: ScenarioInputParams) => ReactElement;
};

type ScenarioCardProps = {
  name: string;
  nameVervolgjarenAutomatischInvullen?: string;
  vervolgjarenAutomatischVullen?: boolean;
  startBedrag?: number | null;
  entries?: number;
  captionStart?: number;
  captionPrefix?: string;
  captionSuffix?: string;
  disabled?: boolean;
  readonly?: boolean;
  scenarioWrappers?: ScenarioWrappers;
  percentageOnly?: boolean;
  vanafVolgendJaar?: boolean;
  decimals?: 0 | 2 | undefined;
};

export type ScenarioCardInState = {
  [index: string]: ScenarioCardInput[];
};

const createInputList = (
  { columnCount, entries }: InputListParams,
  scenarieInputParams: ScenarioInputParams,
  { scenarioHeaderWrapper, scenarioCardWrapper }: ScenarioWrappers
): ReactElement[] => {
  if (entries < 1) {
    return [];
  }
  const columns: ReactElement[] = new Array(columnCount);
  const itemsPerColumn: number[] = new Array(columnCount);

  for (let i = 0; i < columnCount; i++) {
    itemsPerColumn[i] = Math.floor(entries / columnCount);
    if (i + 1 <= entries % columnCount) itemsPerColumn[i] += 1;
  }
  let itemIndex = 0;
  for (let i = 0; i < itemsPerColumn.length; i++) {
    const items: ReactElement[] = [];
    scenarioHeaderWrapper && items.push(scenarioHeaderWrapper(i));
    for (let j = 0; j < itemsPerColumn[i]; j++) {
      items.push(scenarioCardWrapper(itemIndex, entries, scenarieInputParams));
      itemIndex++;
    }
    columns[i] = (
      <div key={i} className="d-flex flex-wrap flex-column pr-1 order">
        {items}
      </div>
    );
  }

  return columns;
};

const scenarioCardSideEffects = createISWSideEffect<ScenarioCardInState, Context>(bag => {
  if (!bag.context.vervolgjarenAutomatischVullen) return;

  const draft: ScenarioCardInput[] = getIn(bag.draft, bag.context.name);
  const prev: ScenarioCardInput[] = getIn(bag.prev, bag.context.name);
  const has = hasChanged(draft, prev);
  if (typeof bag.context.startBedrag !== "undefined") {
    draft[0].bedrag = bag.context.startBedrag;
  }

  for (let i = 0; i < draft.length - 1; i++) {
    const prevBedrag = i > 0 ? draft[i - 1].bedrag : null;
    const thisBedrag = draft[i].bedrag;
    const thisPercentage = draft[i].percentage;

    if (i < draft.length - 1 && has[i].percentage.changed) {
      draft[i + 1].percentage = draft[i].percentage;
      if (thisBedrag && thisPercentage) {
        draft[i + 1].bedrag = thisBedrag * (thisPercentage / 100 + 1);
      }
    }

    if ((has[i].bedrag.changed || has[i].percentage.changed) && thisBedrag) {
      if (thisPercentage) {
        draft[i + 1].bedrag = thisBedrag * (thisPercentage / 100 + 1);
      } else {
        if (bag.context.vervolgjarenAutomatischVullen) draft[i + 1].bedrag = thisBedrag;
      }

      if (prevBedrag) {
        const newPercentage = Math.round((thisBedrag / prevBedrag - 1) * 10000) / 100;
        if (newPercentage || draft[i - 1].percentage !== null) {
          draft[i - 1].percentage = newPercentage;
        }
      }
    }
  }
});

type Context = {
  name: string;
  startBedrag?: number | null;
  vervolgjarenAutomatischVullen?: boolean;
};
export const determineScenarioCardSideEffects = initISWSideEffect<ScenarioCardInState, Context>(
  scenarioCardSideEffects
);

const ScenarioCardComponent = ({
  name,
  nameVervolgjarenAutomatischInvullen,
  vervolgjarenAutomatischVullen,
  startBedrag,
  disabled,
  entries,
  captionPrefix = "",
  captionStart = 1,
  captionSuffix = "",
  formik: { values, setFieldValue },
  readonly,
  scenarioWrappers,
  percentageOnly = false,
  decimals = 0
}: ScenarioCardProps & { formik: FormikContextType<{}> }): ReactElement => {
  const appHasError = !!useContext(ErrorBoundaryContext).error;
  const scenario: ScenarioCardInput[] = getIn(values, name);
  const disableFirstField = !(startBedrag === null || startBedrag === undefined);
  const automatischVullen: boolean =
    vervolgjarenAutomatischVullen ??
    (nameVervolgjarenAutomatischInvullen ? getIn(values, nameVervolgjarenAutomatischInvullen) : true);

  useEffect(() => {
    if (entries) {
      if (scenario.length < entries) {
        const updateScenario = [...scenario];
        const addEntries = entries - scenario.length;
        const scenarioEntry: ScenarioCardInput = {
          bedrag: null,
          percentage: null
        };
        for (let i = 0; i < addEntries; i++) {
          updateScenario.push({ ...scenarioEntry });
        }
        setFieldValue(name, updateScenario);
      }
    }
  }, [entries, name, scenario, setFieldValue]);

  // Default (zoals momenteel op Inkomen & Fiscus scherm gebruikt wordt (Bruto salaris uit dienstverband))
  const defaultScenarioCardWrapper = (
    itemIndex: number,
    entries: number,
    scenarieInputParams: ScenarioInputParams
  ): ReactElement => {
    return (
      <LabeledScenarioInput
        disabled={scenarieInputParams.disabled}
        readonly={scenarieInputParams.readonly}
        disableCurrency={itemIndex === 0 && scenarieInputParams.disableFirstField}
        key={itemIndex}
        caption={`${scenarieInputParams.captionPrefix}${(itemIndex + scenarieInputParams.captionStart)
          .toString()
          .padStart(2, "0")}${scenarieInputParams.captionSuffix}`}
        currencyName={`${name}[${itemIndex}].bedrag`}
        percentageName={`${name}[${itemIndex}].percentage`}
        decimalen={scenarieInputParams.decimals}
        hidePercentage={!percentageOnly && itemIndex + 1 === entries}
        hideCurrency={percentageOnly}
      />
    );
  };

  const scenarioCardWrappers = { scenarioCardWrapper: defaultScenarioCardWrapper };

  const items = useMemo(
    (): ReactElement[] =>
      createInputList(
        {
          columnCount:
            (entries && entries <= 10) || scenario.length <= 10
              ? 1
              : (entries && entries <= 20) || scenario.length <= 20
              ? 2
              : 3,
          entries: entries || scenario.length
        },
        {
          name: name,
          captionStart: captionStart,
          captionPrefix: captionPrefix,
          captionSuffix: captionSuffix,
          disabled: appHasError || (disabled !== undefined && disabled),
          disableFirstField: disableFirstField,
          readonly: readonly,
          decimals: decimals
        },
        scenarioWrappers || scenarioCardWrappers
      ),
    [
      entries,
      scenario.length,
      name,
      captionStart,
      captionPrefix,
      captionSuffix,
      appHasError,
      disabled,
      disableFirstField,
      readonly,
      scenarioWrappers,
      scenarioCardWrappers,
      decimals
    ]
  );

  return (
    <>
      <ISWSideEffects<ScenarioCardInState>
        sync={determineScenarioCardSideEffects({
          name,
          startBedrag,
          vervolgjarenAutomatischVullen: automatischVullen
        })}
      />
      <div className="d-flex flex-wrap flex-row pt-2 scenario-order">{items}</div>
    </>
  );
};

export const ScenarioCard = connect<ScenarioCardProps>(ScenarioCardComponent);
