import {
  AovKenmerken,
  EenVanDeKenmerken,
  HypothekenKenmerken,
  KapitaalverzekeringKenmerken,
  KenmerkenError,
  KredietKenmerken,
  OrvKenmerken,
  ProductDict,
  ProductSoort,
  UitkerendeLijfrenteKenmerken,
  VermogenKenmerken,
  maakMinimaleProductDict
} from "./product-kenmerken-types";
import React, { ReactElement, ReactNode, useContext, useState } from "react";

import { ProductkenmerkenContext } from "./producten-context";
import { SettingsContext } from "adviesbox-shared";
import { assertNever } from "../../shared/utils/helpers";
import { getKapitaalverzekeringKenmerken } from "./tijdelijke-kenmerken/kapitaalverzekering-kenmerken";
import { getKredietKenmerken } from "./tijdelijke-kenmerken/krediet-kenmerken";
import { getUitkerendeLijfrenteKenmerken } from "./tijdelijke-kenmerken/uitkerende-lijfrente-kenmerken";
import { getVermogenKenmerken } from "./tijdelijke-kenmerken/vermogen-kenmerken";
import { hasJSONResponse } from "../../shared/utils/ajax";
import { mapKenmerken } from "./product-kenmerken-mapping";

export type ContextType = {
  getProductKenmerken(
    soort: "Kapitaalverzekering",
    maatschappijCode: string,
    productCode: string,
    fromCache?: boolean | undefined
  ): KapitaalverzekeringKenmerken | null | KenmerkenError;
  getProductKenmerken(
    soort: "Vermogen",
    maatschappijCode: string,
    productCode: string,
    fromCache?: boolean | undefined
  ): VermogenKenmerken | null | KenmerkenError;
  getProductKenmerken(
    soort: "UitkerendeLijfrente",
    maatschappijCode: string,
    productCode: string,
    fromCache?: boolean | undefined
  ): UitkerendeLijfrenteKenmerken | null | KenmerkenError;
  getProductKenmerken(
    soort: "Krediet",
    maatschappijCode: string,
    productCode: string,
    fromCache?: boolean | undefined
  ): KredietKenmerken | null | KenmerkenError;
  getProductKenmerken(
    soort: "Orv",
    maatschappijCode: string,
    productCode: string,
    fromCache?: boolean | undefined
  ): OrvKenmerken | null | KenmerkenError;
  getProductKenmerken(
    soort: "Aov",
    maatschappijCode: string,
    productCode: string,
    fromCache?: boolean | undefined
  ): AovKenmerken | null | KenmerkenError;
  getProductKenmerken(
    soort: "Hypotheek",
    maatschappijCode: string,
    productCode: string,
    fromCache?: boolean | undefined
  ): HypothekenKenmerken | null | KenmerkenError;
  getProductKenmerken(soort: ProductSoort, maatschappijCode: string, productCode: string, fromCache?: boolean | undefined): any;
  resetProductKenmerken(): void;
};

type kenmerkSoortType =
  | "Aov"
  | "Hypotheekvormen"
  | "Kapitaalverzekering"
  | "Kredietvormen"
  | "Orv"
  | "UitkerendeLijfrente"
  | "Vermogen";

export function getKenmerkSoort(soort: ProductSoort): kenmerkSoortType {
  switch (soort) {
    case "Aov":
      return "Aov";
    case "Orv":
      return "Orv";
    case "Hypotheek":
      return "Hypotheekvormen";
    case "Krediet":
      // TODO: Juiste kenmerk soort bepalen als API er is
      return "Kredietvormen";
    case "UitkerendeLijfrente":
      // TODO: Juiste kenmerk soort bepalen als API er is
      return "UitkerendeLijfrente";
    case "Vermogen":
      // TODO: Juiste kenmerk soort bepalen als API er is
      return "Vermogen";
    case "Kapitaalverzekering":
      // TODO: Juiste kenmerk soort bepalen als API er is
      return "Kapitaalverzekering";
    default:
      return assertNever(soort);
  }
}

export const updateProductDictMetKenmerken = (
  productDict: ProductDict,
  soort: ProductSoort,
  maatschappijCode: string,
  productCode: string,
  kenmerken: EenVanDeKenmerken,
  setProductDict: React.Dispatch<React.SetStateAction<ProductDict>>
): void => {
  const updatedProducten = { ...productDict };

  // indien de maatschappij code nog niet in de dictionary staat deze toevoegen met product-kenmerken.
  if (!updatedProducten[soort][maatschappijCode]) {
    updatedProducten[soort][maatschappijCode] = { [productCode]: kenmerken };
  } else if (updatedProducten[soort][maatschappijCode]) {
    // maatschappij bestaat, stel product in.
    updatedProducten[soort][maatschappijCode][productCode] = kenmerken;
  }

  setProductDict(updatedProducten);
};

const ProductenProvider = ({ values, children }: { values?: ProductDict; children: ReactNode }): ReactElement => {
  const [kenmerkenLoaded, setKenmerkenLoaded] = useState<number>(Date.now());
  const [productDict, setProductDict] = useState<ProductDict>(values ?? maakMinimaleProductDict);
  const { productenOrigin, OcpApimSubscriptionKey } = useContext(SettingsContext);

  async function updateProductDict(soort: ProductSoort, maatschappijCode: string, productCode: string): Promise<void> {
    // We vertalen ons ProductSoort naar de string die platform verwacht in de url:
    const soortKenmerken: kenmerkSoortType = getKenmerkSoort(soort);

    // TODO MdB: Verwijderen als Krediet kenmerken beschikbaar zijn.
    /* istanbul ignore next */
    if (soort === "Krediet") {
      const kenmerken: KredietKenmerken = getKredietKenmerken(productCode);
      updateProductDictMetKenmerken(productDict, soort, maatschappijCode, productCode, kenmerken, setProductDict);

      return;
    }

    // TODO MdB: Verwijderen als UitkerendeLijfrente kenmerken beschikbaar zijn.
    /* istanbul ignore next */
    if (soort === "UitkerendeLijfrente") {
      const kenmerken: UitkerendeLijfrenteKenmerken = getUitkerendeLijfrenteKenmerken(productCode);
      updateProductDictMetKenmerken(productDict, soort, maatschappijCode, productCode, kenmerken, setProductDict);

      return;
    }

    // TODO MdB: Verwijderen als Vermogen kenmerken beschikbaar zijn.
    /* istanbul ignore next */
    if (soort === "Vermogen") {
      const kenmerken: VermogenKenmerken = getVermogenKenmerken(maatschappijCode, productCode);
      updateProductDictMetKenmerken(productDict, soort, maatschappijCode, productCode, kenmerken, setProductDict);

      return;
    }

    // TODO MdB: Verwijderen als Vermogen kenmerken beschikbaar zijn.
    /* istanbul ignore next */
    if (soort === "Kapitaalverzekering") {
      const kenmerken: KapitaalverzekeringKenmerken = getKapitaalverzekeringKenmerken(maatschappijCode, productCode);
      updateProductDictMetKenmerken(productDict, soort, maatschappijCode, productCode, kenmerken, setProductDict);

      return;
    }

    const fetchResult = await fetch(
      `${productenOrigin}/MaatschappijCodes/${maatschappijCode}/${soortKenmerken}/${productCode}/Productkenmerken`,
      {
        headers: { "Ocp-Apim-Subscription-Key": OcpApimSubscriptionKey }
      }
    );

    if (fetchResult && fetchResult.ok && hasJSONResponse(fetchResult)) {
      const data = await fetchResult.json();
      const kenmerken = mapKenmerken(soort, data);

      // TODO:
      /* istanbul ignore next */
      if (kenmerken) {
        updateProductDictMetKenmerken(productDict, soort, maatschappijCode, productCode, kenmerken, setProductDict);
      } else {
        updateProductDictMetKenmerken(
          productDict,
          soort,
          maatschappijCode,
          productCode,
          {
            reden: "..fout die terugkomt uit WS"
          },
          setProductDict
        );
      }
    } else {
      updateProductDictMetKenmerken(
        productDict,
        soort,
        maatschappijCode,
        productCode,
        {
          reden: "...Geen kenmerken ontvangen"
        },
        setProductDict
      );
    }
  }

  function resetProductKenmerken(): void {
    setProductDict(maakMinimaleProductDict);
  }

  function getProductKenmerken(
    soort: "Hypotheek",
    maatschappijCode: string,
    productCode: string,
    fromCache?: boolean | undefined
  ): HypothekenKenmerken | null | KenmerkenError;
  function getProductKenmerken(
    soort: "Orv",
    maatschappijCode: string,
    productCode: string,
    fromCache?: boolean | undefined
  ): OrvKenmerken | null | KenmerkenError;
  function getProductKenmerken(
    soort: "Aov",
    maatschappijCode: string,
    productCode: string,
    fromCache?: boolean | undefined
  ): AovKenmerken | null | KenmerkenError;
  function getProductKenmerken(
    soort: "Krediet",
    maatschappijCode: string,
    productCode: string,
    fromCache?: boolean | undefined
  ): KredietKenmerken | null | KenmerkenError;
  function getProductKenmerken(
    soort: "UitkerendeLijfrente",
    maatschappijCode: string,
    productCode: string,
    fromCache?: boolean | undefined
  ): UitkerendeLijfrenteKenmerken | null | KenmerkenError;
  function getProductKenmerken(
    soort: "Vermogen",
    maatschappijCode: string,
    productCode: string,
    fromCache?: boolean | undefined
  ): VermogenKenmerken | null | KenmerkenError;
  function getProductKenmerken(
    soort: "Kapitaalverzekering",
    maatschappijCode: string,
    productCode: string,
    fromCache?: boolean | undefined
  ): KapitaalverzekeringKenmerken | null | KenmerkenError;
  function getProductKenmerken(soort: ProductSoort, maatschappijCode: string, productCode: string, fromCache?: boolean | undefined): any {
    // invalidate kenmerken dict after 12 hours.
    const hours = Math.floor(Math.abs(Date.now() - kenmerkenLoaded) / 36e5);
    // TODO!!
    /* istanbul ignore next */
    if (hours > 12) {
      setProductDict(maakMinimaleProductDict);
      setKenmerkenLoaded(Date.now());
    }

    const kenmerk =
      productDict[soort] && productDict[soort][maatschappijCode] && productDict[soort][maatschappijCode][productCode]
        ? productDict[soort][maatschappijCode][productCode]
        : null;

    if (!kenmerk && !fromCache) {
      /* eslint-disable-next-line @typescript-eslint/no-floating-promises */
      updateProductDict(soort, maatschappijCode, productCode);
      return null;
    }

    return kenmerk;
  }

  return (
    <ProductkenmerkenContext.Provider
      value={{ getProductKenmerken: getProductKenmerken, resetProductKenmerken: resetProductKenmerken }}
    >
      {children}
    </ProductkenmerkenContext.Provider>
  );
};

export default ProductenProvider;
