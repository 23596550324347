import { SettingsType, hasJSONResponse } from "adviesbox-shared";
import { User } from "oidc-client";
import { NieuweAanvraagInput, AanvullendAntwoord } from "../../../../.generated/foundation/foundationtypes";
import { AanvullendeGegevensType } from "./aanvullende-gegevens-schema";

const mapAanvullendeAntwoorden = (aanvullendeGegevens: AanvullendeGegevensType): AanvullendAntwoord[] => {
  let aanvullendeAntwoorden = aanvullendeGegevens.aanvullendeAntwoorden;
  const aanvullendeGegevensAntwoorden = aanvullendeGegevens.modalFoutenArray
    .filter(x => x.FEVeldInfo?.waarde !== null && x.FEVeldInfo?.waarde !== undefined)
    .map(x => {
      return {
        xPath: x.platformVeldInfo.xPath,
        parentOccurance: x.parentOccurance,
        waarde: x.FEVeldInfo?.waarde
      };
    });
  // Filter eerder ingevulde aanvullende antwoorden uit de lijst als deze opnieuw worden ingevuld vanwege een ongeldige waarde
  // istanbul-ignore-next
  aanvullendeAntwoorden = aanvullendeAntwoorden.filter(
    x => !aanvullendeGegevensAntwoorden?.find(y => y.xPath === x.xPath && y.parentOccurance === x.parentOccurance)
  );
  return [...aanvullendeAntwoorden, ...aanvullendeGegevensAntwoorden];
};

const mapNieuweAanvraagInput = (aanvullendeGegevens: AanvullendeGegevensType): NieuweAanvraagInput => {
  return {
    voorstelId: aanvullendeGegevens.analyseId,
    adviesdossierId: aanvullendeGegevens.adviesdossierId,
    aanvullendeAntwoorden: mapAanvullendeAntwoorden(aanvullendeGegevens),
    dekkingId: aanvullendeGegevens.dekkingId,
    ontvangerNaam: aanvullendeGegevens.ontvangerNaam,
    ontvangerNrHdn: aanvullendeGegevens.ontvangerNrHdn,
    softwareKoppelingId: aanvullendeGegevens.softwareKoppelingId,
    externeReferentie: aanvullendeGegevens.externeReferentie,
    nieuweVersie: aanvullendeGegevens.nieuweVersie
  } as NieuweAanvraagInput;
};

export const nieuweAanvraagPost = async (
  settings: SettingsType,
  user: User | null,
  vestigingId: string,
  aanvullendeGegevens: AanvullendeGegevensType
): Promise<string> => {
  const url = `${settings.foundationOrigin}/HdnOnline/bus/NieuweAanvraag`;

  const body = mapNieuweAanvraagInput(aanvullendeGegevens);
  const rsp = await fetch(url, {
    method: "POST",
    headers: {
      authorization: `${user?.token_type} ${user?.access_token}`,
      "Ocp-Apim-Subscription-Key": settings.OcpApimSubscriptionKey,
      "Content-Type": "application/json;charset=UTF-8",
      vestigingId
    },
    body: JSON.stringify(body)
  });

  if (!(rsp.ok && hasJSONResponse(rsp))) {
    throw Error(`Fout bij aanroep van web-api '${url}'. Resultaat bevat geen JSON.`);
  }
  const jsonResponse = await rsp.json();

  if (!jsonResponse.isValid) throw Error(`Fout bij aanroep van web-api '${url}'. Input is niet valid.`);

  return jsonResponse.id;
};

export const requestInitNieuweAanvraag = (settings: SettingsType,
  user: User | null,
  vestigingId: string,
  aanvullendeGegevens: AanvullendeGegevensType): RequestInit => {
  const nieuweAanvraag: NieuweAanvraagInput = mapNieuweAanvraagInput(aanvullendeGegevens);
  return {
    headers: {
      authorization: `${user?.token_type} ${user?.access_token}`,
      "Ocp-Apim-Subscription-Key": settings.OcpApimSubscriptionKey,
      "Content-Type": "application/json",
      vestigingId: vestigingId
    },
    method: "POST",
    body: JSON.stringify(nieuweAanvraag) // TODO
  };
};
