/**
 * DEZE FILE STAAT NIET ONDER TEST. WIJZIGINGEN -ALTIJD- CONTROLEREN!
 */

import {
  BankgarantieinstellingenOutput,
  GeldverstrekkersOutput,
  NotarissenOutput,
  TaxatiebureausOutput,
  VermogensbeheerdersOutput,
  VerzekeraarsOutput,
  SoftwareKoppelingenOutput
} from "../../.generated/instellingen-forms/instellingen-formstypes";

import useAbortableFetch from "use-abortable-fetch";
import { useRequestInit } from "adviesbox-shared";

export type Soort =
  | "Notarissen"
  | "Taxatiebureaus"
  | "Bankgarantieinstellingen"
  | "Vermogensbeheerders"
  | "Geldverstrekkers"
  | "Verzekeraars"
  | "SoftwareKoppelingen";
export type UseInstellingenDataResult<T> = {
  loading: boolean;
  error: null | Error;
  data: T;
};

export function useInstellingenBeheerPartijenData(
  soort: "Bankgarantieinstellingen"
): UseInstellingenDataResult<BankgarantieinstellingenOutput>;
export function useInstellingenBeheerPartijenData(
  soort: "Taxatiebureaus"
): UseInstellingenDataResult<TaxatiebureausOutput>;
export function useInstellingenBeheerPartijenData(soort: "Notarissen"): UseInstellingenDataResult<NotarissenOutput>;
export function useInstellingenBeheerPartijenData(
  soort: "Vermogensbeheerders"
): UseInstellingenDataResult<VermogensbeheerdersOutput>;
export function useInstellingenBeheerPartijenData(soort: "Verzekeraars"): UseInstellingenDataResult<VerzekeraarsOutput>;
export function useInstellingenBeheerPartijenData(
  soort: "Geldverstrekkers"
): UseInstellingenDataResult<GeldverstrekkersOutput>;
export function useInstellingenBeheerPartijenData(
  soort: "SoftwareKoppelingen"
): UseInstellingenDataResult<SoftwareKoppelingenOutput>;

export function useInstellingenBeheerPartijenData(soort: Soort): unknown;
export function useInstellingenBeheerPartijenData(soort: Soort): UseInstellingenDataResult<NotarissenOutput> | unknown {
  const {
    requestInit,
    settings: { instellingenFormsOrigin }
  } = useRequestInit();
  const url = `${instellingenFormsOrigin}/BeheerPartijen/${soort}`;
  const { loading, error, data } = useAbortableFetch<any>(url, requestInit);

  const listWithLoadingEntry = { "-1": "Laden..." };
  return { loading, error, data: data ?? listWithLoadingEntry };
}
