import { useContext, useEffect } from "react";

import { MaatschappijLijstType } from "../types";
import useAbortableFetch from "use-abortable-fetch";
import { useRequestInit, ForceRerenderContext } from "adviesbox-shared";

export type Soort = "Geldverstrekkers" | "Verzekeringsmaatschappijen" | "Vermogensbeheerders";
export type UseMaatschappijDataResult = {
  error: null | Error;
  maatschappijen: { [index: string]: MaatschappijLijstType };
};

export function useMaatschappijData(soort: Soort): UseMaatschappijDataResult {
  const loadingDone = useContext(ForceRerenderContext);

  const {
    requestInit,
    settings: { productenOrigin }
  } = useRequestInit();
  const url = `${productenOrigin}/${soort}`;
  const { error, data, loading } = useAbortableFetch<any>(url, requestInit);

  useEffect((): void => {
    if (!loading && !!data && loadingDone) {
      loadingDone();
    }
  }, [loading, data, loadingDone]);

  if (typeof data === "string") {
    return { error: new Error("Fout bij het laden van Maatschappij data"), maatschappijen: {} };
  }

  return { error, maatschappijen: !data || !data.maatschappijen ? null : data.maatschappijen };
}
