import React, { ReactElement } from "react";
import { connect, FormikContextType } from "formik";

import { LabeledCurrencyInput, LabeledPercentageInput, LabeledSelectInput } from "adviesbox-shared";
import { enum2options } from "../../shared/utils/enum-to-options";
import { PensioenenState, Pensioenregelingsoort } from "../infra/pensioen-schema";

type PensioenregelingProps = {
  selected: number;
};

const Pensioenregeling = ({
  selected,
  formik: {
    values: { pensioenen }
  }
}: PensioenregelingProps & {
  formik: FormikContextType<PensioenenState>;
}): ReactElement => {
  if (selected >= pensioenen.length) return <></>;

  return (
    <div key={selected}>
      <LabeledSelectInput
        caption="Soort pensioenregeling"
        name={`pensioenen[${selected}].pensioenregeling.soortPensioenregeling`}
        options={enum2options(Pensioenregelingsoort)}
      />

      {Pensioenregelingsoort.Eindloon === pensioenen[selected].pensioenregeling.soortPensioenregeling &&
        pensioenen[selected].werkgever.huidigeWerkgever && (
          <LabeledPercentageInput
            caption={"Eindloonregeling opbouwpercentage"}
            name={`pensioenen[${selected}].pensioenregeling.eindloonregelingPercentage`}
            decimalen={3}
          />
        )}

      {Pensioenregelingsoort.Middelloon === pensioenen[selected].pensioenregeling.soortPensioenregeling &&
        pensioenen[selected].werkgever.huidigeWerkgever && (
          <LabeledPercentageInput
            caption={"Middelloonregeling opbouwpercentage"}
            name={`pensioenen[${selected}].pensioenregeling.middelloonregelingPercentage`}
            decimalen={3}
          />
        )}

      {pensioenen[selected].pensioenregeling.soortPensioenregeling === Pensioenregelingsoort.BeschikbarePremie && (
        <LabeledCurrencyInput
          caption="Beschikbare premie"
          name={`pensioenen[${selected}].pensioenregeling.beschikbarePremie`}
        />
      )}
    </div>
  );
};

export default connect<PensioenregelingProps, PensioenenState>(Pensioenregeling);
