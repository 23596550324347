import React, { ReactElement } from "react";
import { connect, FormikContextType } from "formik";
import { KapitaalopbouwOptions, FiscaleVoortzettingOptions } from "../../.generated/forms/formstypes";
import { LabelValuePairs } from "../../shared/types";
import { LabeledRadioInput, LabeledBevestigingInput, TooltipWrap } from "adviesbox-shared";
import FiscaleVoortzetting from "../../producten-overzicht/fiscale-regeling/fiscale-regeling";
import { FiscaleRegelingKenmerken } from "../../producten-overzicht/infra/product-kenmerken-types";
import { VermogensType } from "../infra/vermogen-types";
import { getVermogenTextResources } from "../infra/vermogen-resources";

const KapitaalopbouwOptionsPair: LabelValuePairs = [
  {
    label: "Box 1",
    value: KapitaalopbouwOptions.Box1
  },
  {
    label: "Box 3",
    value: KapitaalopbouwOptions.Box3
  },
  {
    label: "Box 3 tijdelijk vrijgesteld",
    value: KapitaalopbouwOptions.Box3TijdelijkVrijgesteld
  }
];

const FiscaleVoorzettingsOptions: LabelValuePairs = [
  {
    label: "Geen",
    value: FiscaleVoortzettingOptions.Geen
  },
  {
    label: "Eigen invoer",
    value: FiscaleVoortzettingOptions.EigenInvoer
  }
];

type FiscaleRegelingProps = {
  selected: number;
  kenmerken: FiscaleRegelingKenmerken;
};

const FiscaleRegeling = ({
  selected,
  kenmerken,
  formik: {
    values: { producten }
  }
}: FiscaleRegelingProps & {
  formik: FormikContextType<VermogensType>;
}): ReactElement => {
  const { fiscaleRegeling } = producten[selected];

  return (
    <>
      {kenmerken.kapitaalopbouwOpKaartTonen && (
        <LabeledRadioInput
          caption="Kapitaalopbouw"
          name={`producten[${selected}].fiscaleRegeling.kapitaalopbouw`}
          options={KapitaalopbouwOptionsPair}
          appendChildren={
            fiscaleRegeling.kapitaalopbouw === KapitaalopbouwOptions.Box1 ? (
              <TooltipWrap
              name={`producten[${selected}].fiscaleRegeling.kapitaalopbouw`}
              warningText={getVermogenTextResources("TooltipKapitaalopbouwBox1")}
              placement="bottom"
              iconType="waarschuwing"
            />
            ) : null
          }
        />
      )}

      {kenmerken.lijfrenteclausuleOpKaartTonen && (
        <LabeledBevestigingInput
          caption="Lijfrenteclausule"
          name={`producten[${selected}].fiscaleRegeling.lijfrenteclausule`}
        />
      )}

      <FiscaleVoortzetting
        selected={selected}
        kenmerken={kenmerken}
        fiscaleVoortzettingKeuzes={FiscaleVoorzettingsOptions}
      />
    </>
  );
};

export default connect<FiscaleRegelingProps, VermogensType>(FiscaleRegeling);
