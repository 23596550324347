import React, { ReactElement } from "react";
import { QuestionSpecCombined, QuestionType, KlantprofielOptieType } from "../../klantprofiel-schema";
import { LabeledSlider } from "../../material-ui-slider/labeledSlider";
import { useFormikContext } from "formik";
import produce from "immer";

type QuestionSliderProps = {
  data: QuestionSpecCombined;
};

const metLabelKennisMarks = [
  {
    value: 0,
    label: "Geen kennis"
  },
  {
    value: 1
  },
  {
    value: 2
  },
  {
    value: 3,
    label: "Veel kennis"
  }
];

const geenLabelkennisMarks = [
  {
    value: 0
  },
  {
    value: 1
  },
  {
    value: 2
  },
  {
    value: 3
  }
];

export const QuestionSlider = ({ data }: QuestionSliderProps): ReactElement => {
  const formik = useFormikContext<KlantprofielOptieType>();
  if (data.type !== QuestionType.slider) return <>{`invalid QuestionType: ${data.type}`}</>;
  const omschrijving = data.schema.omschrijving;
  const renderedExtra = data.extra ? data.extra() : null;
  const displayMarks = data.specsIndex === 0 || renderedExtra;
  const value = formik.values.vragen[data.index].antwoorden.findIndex(a => a.waarde1 === true);

  return (
    <>
      {renderedExtra}
      <div className="mt-3">
        <LabeledSlider
          name={`vragen[${data.index}]._sliderValue_unused`}
          value={value}
          caption={omschrijving ?? ""}
          marks={displayMarks ? metLabelKennisMarks : geenLabelkennisMarks}
          onChange={value => {
            const next = produce(formik.values, draft => {
              for (let i = 0; i <= 3; i++) {
                draft.vragen[data.index].antwoorden[i].waarde1 = null;
              }
              draft.vragen[data.index].antwoorden[value || 0].waarde1 = true;
            });
            formik.setValues(next);
          }}
        />
      </div>
    </>
  );
};
