import { User } from "oidc-client";
import { SettingsType } from "adviesbox-shared";

export const cleanCache = async (user: User, params: Record<string, string | undefined>, settings: SettingsType): Promise<void> => {
    try {
        if ((!params.adviesdossier || !params.voorstel) && !params.vestiging) {
            throw new Error("Missing required parameters");
        }

        const response = await fetch(
            `${settings.rapportageOrigin}/Adviesdossiers/${params.adviesdossier || "null"}/Voorstellen/${params.voorstel ||
            "null"}/Motivering/Clean`,
            {
                method: "GET",
                headers: {
                    vestigingId: params.vestiging || "",
                    "Content-Type": "application/json",
                    authorization: `${user?.token_type} ${user?.access_token}`,
                    "Ocp-Apim-Subscription-Key": settings.OcpApimSubscriptionKey
                }
            }
        );

        if (!response.ok) {
            throw new Error("Failed to clean cache");
        }

        const responseForms = await fetch(
            `${settings.klantdossiersFormsOrigin}/Adviesdossiers/${params.adviesdossier ||
            "null"}/Voorstellen/${params.voorstel || "null"}/Dashboard/Clean`,
            {
                method: "GET",
                headers: {
                    vestigingId: params.vestiging || "",
                    "Content-Type": "application/json",
                    authorization: `${user?.token_type} ${user?.access_token}`,
                    "Ocp-Apim-Subscription-Key": settings.OcpApimSubscriptionKey
                }
            }
        );

        if (!responseForms.ok) {
            throw new Error("Failed to clean dashboard cache");
        }

        /* eslint-disable-next-line no-console */
        console.log("Cache cleaned successfully");
    } catch (error) {
        /* eslint-disable-next-line no-console */
        console.error("Error cleaning cache:", error);
        // Handle error here (e.g., show an error message to the user)
    }
};
