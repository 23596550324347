import { SettingsType } from "adviesbox-shared";
import { FormikContextType } from "formik";
import { User } from "oidc-client";
import { DossierStatusType } from "../schema-and-mappers";

//POST is used to save and not PUT
export const dossierStatusModalPOST = async (
  settings: SettingsType,
  user: User | null,
  status: DossierStatusType,
  vestigingId: string,
  adviesdossierId: string,
  setFieldValue: FormikContextType<DossierStatusType>["setFieldValue"]
): Promise<boolean> => {
  const url = `${settings.klantdossiersFormsOrigin}/Adviesdossiers/${adviesdossierId}/Status`;
  const rsp = await fetch(url, {
    method: "POST",
    headers: {
      authorization: `${user?.token_type} ${user?.access_token}`,
      "Ocp-Apim-Subscription-Key": settings.OcpApimSubscriptionKey,
      "Content-Type": "application/json;charset=UTF-8",
      vestigingId
    },
    body: JSON.stringify({
      status: status.status
    })
  });

  if (!rsp.ok) {
    setFieldValue("platformApiFouten", [
      {
        field: "",
        label: `Fout: ${rsp.status}`,
        error: "Fout bij aanroep van web-api"
      }
    ]);
    throw Error(`Fout bij aanroep van web-api '${url}'.`);
  }

  return !!rsp.ok;
};
