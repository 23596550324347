import { AanvullingInkomenBijOverlijdenModalType } from "../infra/producten-overzicht-types";
import {
  initISWAsyncSideEffect,
  createISWAsyncSideEffect
} from "../../shared/components/isw-side-effects/create-isw-helpers";
import {
  InkomensaanvullingDuurInMaandenInput,
  BetalingsTermijnType,
  InkomensaanvullingDuurInMaandenOutput
} from "../../.generated/forms/formstypes";
import { hasValue, mapPeriodeInMaandenDl2Ui } from "adviesbox-shared";

export const mapAanvullingInkomenBijOverlijdenInput = (
  currentValues: AanvullingInkomenBijOverlijdenModalType,
  berekeningVoorAanvrager: boolean
): InkomensaanvullingDuurInMaandenInput => {
  return {
    betalingsTermijn:
      BetalingsTermijnType[
        currentValues[
          berekeningVoorAanvrager ? "gewensteUitkeringPerPeriodeAanvrager1" : "gewensteUitkeringPerPeriodeAanvrager2"
        ]
      ],
    gehanteerdTariefPercentage:
      currentValues[berekeningVoorAanvrager ? "gehanteerdTariefAanvrager1" : "gehanteerdTariefAanvrager2"],
    gewensteUitkeringBedrag:
      currentValues[berekeningVoorAanvrager ? "gewensteUitkeringAanvrager1" : "gewensteUitkeringAanvrager2"],
    verzekerdBedrag:
      currentValues[berekeningVoorAanvrager ? "verzekerdKapitaalAanvrager1" : "verzekerdKapitaalAanvrager2"] || 0
  };
};

export const mapAanvullingInkomenBijOverlijdenOutput = (
  currentValues: AanvullingInkomenBijOverlijdenModalType,
  json: InkomensaanvullingDuurInMaandenOutput,
  berekeningVoorAanvrager: boolean
): void => {
  const result = mapPeriodeInMaandenDl2Ui(json.duurInMaanden);
  currentValues[berekeningVoorAanvrager ? "duurUitkeringAanvrager1" : "duurUitkeringAanvrager2"] = result;
};

export const aanvullingInkomenOverlijdenModalEffect = createISWAsyncSideEffect<
  AanvullingInkomenBijOverlijdenModalType,
  { berekeningVoorAanvrager: boolean }
>(async ({ draft, settings, fetchData, context }) => {
  const result = await fetchData<InkomensaanvullingDuurInMaandenOutput, InkomensaanvullingDuurInMaandenInput>({
    url: `${settings.klantdossiersFormsOrigin}/Orv/Inkomensaanvulling/DuurInMaanden`,
    body: mapAanvullingInkomenBijOverlijdenInput(draft, context.berekeningVoorAanvrager)
  });

  if (result.isValid) {
    mapAanvullingInkomenBijOverlijdenOutput(draft, result, context.berekeningVoorAanvrager);
  }
});

export const aanvullingInkomenOverlijdenModalAsyncSideEffects = initISWAsyncSideEffect<
  AanvullingInkomenBijOverlijdenModalType
>(({ curr, runAsync, has }) => {
  if (
    hasValue(curr.gehanteerdTariefAanvrager1) &&
    hasValue(curr.gewensteUitkeringPerPeriodeAanvrager1) &&
    hasValue(curr.gewensteUitkeringAanvrager1) &&
    (has.gehanteerdTariefAanvrager1.changed ||
      has.gewensteUitkeringPerPeriodeAanvrager1.changed ||
      has.gehanteerdTariefAanvrager1)
  ) {
    runAsync(aanvullingInkomenOverlijdenModalEffect({ berekeningVoorAanvrager: true }));
  }

  if (
    hasValue(curr.gehanteerdTariefAanvrager2) &&
    hasValue(curr.gewensteUitkeringPerPeriodeAanvrager2) &&
    hasValue(curr.gehanteerdTariefAanvrager2) &&
    (has.gehanteerdTariefAanvrager2.changed ||
      has.gewensteUitkeringPerPeriodeAanvrager2.changed ||
      has.gehanteerdTariefAanvrager2)
  ) {
    runAsync(aanvullingInkomenOverlijdenModalEffect({ berekeningVoorAanvrager: false }));
  }
})();
