import { QuestionType, CardSpec, TitleType } from "../../infra/klantprofiel-schema";
import { KlantprofielVraagOptions } from "../../../.generated/forms/formstypes";
import { HypotheekAlgemeenExtraText } from "./hypotheek-algemeen-extra";

export const hypotheekalgemeenQuestionSpecs: CardSpec[] = [
  {
    questions: [
      {
        question: KlantprofielVraagOptions.WensenDoelstellingenHypotheek,
        type: QuestionType.checkbox
      }
    ]
  },
  {
    sections: [
      {
        title: TitleType.custom,
        customTitle: "Kennisniveau van hypotheek gerelateerde onderwerpen",
        subtitle: "Geef per onderwerp het kennisniveau aan door het bolletje te slepen.",
        questions: [
          {
            extra: HypotheekAlgemeenExtraText("Hypotheken"),
            question: KlantprofielVraagOptions.KennisHypothekenAlgemeen,
            type: QuestionType.slider
          },
          {
            question: KlantprofielVraagOptions.KennisHypothekenHypotheekvormen,
            type: QuestionType.slider
          },
          {
            question: KlantprofielVraagOptions.KennisHypothekenNhg,
            type: QuestionType.slider
          },
          {
            question: KlantprofielVraagOptions.KennisHypothekenFiscaliteit,
            type: QuestionType.slider
          },
          {
            extra: HypotheekAlgemeenExtraText("Risico's"),
            question: KlantprofielVraagOptions.KennisRisicosRestschuld,
            type: QuestionType.slider
          },
          {
            question: KlantprofielVraagOptions.KennisRentewijzigingen,
            type: QuestionType.slider
          },
          {
            question: KlantprofielVraagOptions.KennisInkomstendaling,
            type: QuestionType.slider
          },
          {
            question: KlantprofielVraagOptions.KennisDubbeleWoonlasten,
            type: QuestionType.slider
          }
        ]
      }
    ]
  }
];
