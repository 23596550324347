import classNames from "classnames";
import React, { ReactElement } from "react";
import { jaarMaandInputNaarJaarMaand } from "../../../shared/generic-parts/jaar-maand/helpers";
import { bedragFormat } from "../../../shared/utils/currency";
import classes from "./hypotheek-vergelijker-pdf.module.scss";

type HypotheekVoorstelPDFTableProps = {
  hypotheekSamenstelling: any;
  renderVoorstellenOnly: boolean;
};

export const RenderHypotheekVoorstelTable = ({
  renderVoorstellenOnly,
  hypotheekSamenstelling
}: HypotheekVoorstelPDFTableProps): ReactElement => {
  return (
    <>
      <table
        className={classNames(classes.pdf_table, classes.pdf_table_dataTable, {
          [classes.pdf_padding]: renderVoorstellenOnly
        })}
      >
        <thead>
          <tr>
            <th className={`${classes.pdf_table_data_header} ${classes.pdf_table_data_header_L}`}>Hypotheekvorm</th>
            <th className={`${classes.pdf_table_data_header} ${classes.pdf_table_data_header_L_align_right}`}>
              Bedrag
            </th>
            <th className={`${classes.pdf_table_data_header} ${classes.pdf_table_data_header_L_align_right}`}>
              Rentevast
            </th>
            <th className={`${classes.pdf_table_data_header} ${classes.pdf_table_data_header_L_align_right}`}>
              Looptijd
            </th>
          </tr>
        </thead>
        <tbody>
          {hypotheekSamenstelling?.map((v: any, i: number) => (
            <tr key={"body_row" + i}>
              <td>{v.hypotheekVorm?.aflossingsvorm}</td>
              <td className={classes.pdf_table_right_align}>
                {bedragFormat(v.leningdeelgegevens?.leningdeelHoofdsom?.bedrag, 2)}
              </td>
              <td className={classes.pdf_table_right_align}>{`${v.leningdeelgegevens?.rentevastPeriodeJaar ??
                0} Jaar`}</td>
              <td className={classes.pdf_table_right_align}>
                {jaarMaandInputNaarJaarMaand({
                  jaren: v.product?.looptijd?.jaren,
                  maanden: v.product?.looptijd?.maanden
                })}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};
