import React, { ReactElement } from "react";
import { connect, FormikContextType } from "formik";

import { SelectedProps } from "../../shared/types";
import { LabeledCurrencyInput, LabeledBevestigingInput, BerekenCurrencyInput } from "adviesbox-shared";
import { WoonsituatieListState } from "../infra/woonsituatie-schema";
import { getWoonsituatieTextResources } from "../infra/woonsituatie-resources";

const Hypotheek = ({
  selected,
  formik: {
    values: { woonsituaties }
  }
}: SelectedProps & {
  formik: FormikContextType<WoonsituatieListState>;
}): ReactElement => {
  const selectedWoonsituatie = woonsituaties ? woonsituaties[selected] : null;
  if (!selectedWoonsituatie) return <></>;

  return (
    <>
      <LabeledBevestigingInput
        caption="Hypotheek op de woning"
        name={`woonsituaties[${selected}].hypotheek.hasHypotheek`}
      />
      {woonsituaties[selected].hypotheek.hasHypotheek && (
        <>
          <LabeledCurrencyInput
            caption="Oorspronkelijke hypotheek"
            name={`woonsituaties[${selected}].hypotheek.oorspronkelijk`}
            tooltip={getWoonsituatieTextResources("hypotheekOorspronkelijk")}
          />

          <BerekenCurrencyInput caption="Inschrijving" name={`woonsituaties[${selected}].hypotheek.inschrijving`} />
        </>
      )}
    </>
  );
};

export default connect<SelectedProps, WoonsituatieListState>(Hypotheek);
