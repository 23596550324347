import React, { ReactElement } from "react";
import { connect, useFormikContext } from "formik";

import { AdresInput } from "../../shared/components/adres/adres-input";
import { BankgarantieState, InstellingCodes } from "../infra/bankgarantie-schema";
import { LabeledText } from "adviesbox-shared";
import { Financieringsoort } from "../../.generated/forms/formstypes";

const OnroerendeZaak = (): ReactElement => {
  const { values } = useFormikContext<BankgarantieState>();

  const instelling = values.product.instelling;
  const soortFinanciering = values.soortFinanciering;
  const straatCaption = soortFinanciering === Financieringsoort.AankoopNieuwbouw ? "Straat of project" : undefined;
  return (
    <>
      {instelling === InstellingCodes.CA && (
        <>
          <LabeledText
            caption="Type bouw"
            fieldSize="no-size"
            value={values.onroerendeZaak.isNieuwbouw ? "Nieuwbouw" : "Bestaande bouw"}
          />
        </>
      )}
      {soortFinanciering === Financieringsoort.AankoopNieuwbouw && (
        <>
          <LabeledText
            caption="Bouw- of kavelnummer"
            fieldSize="no-size"
            value={values.onroerendeZaak.bouwnummer ?? ""}
          />
        </>
      )}
      <AdresInput
        name="onroerendeZaak.adres"
        metLand={instelling === InstellingCodes.CA}
        readonly={true}
        straatCaption={straatCaption}
        metHuisEnToevoeging={soortFinanciering !== Financieringsoort.AankoopNieuwbouw}
      />
    </>
  );
};

export default connect<{}, BankgarantieState>(OnroerendeZaak);
