import { WebViewerInstance } from "@pdftron/webviewer";
import { LoadingSpinner, Pdf, useRequestInit, useServiceBusSubscription } from "adviesbox-shared";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { getOverzichtSubscription } from "../infra/maandlastsnelinzicht-api";

export const MaandlastenOverzichtPdf = ({
  voorstelId,
  productenAanwezig,
  pdfButtonState
}: {
  voorstelId: string | null;
  productenAanwezig: boolean;
  pdfButtonState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}): ReactElement => {
  const [sasToken, setSasToken] = useState<string | null>(null);
  const [subscription, setSubscription] = useState<string | null>(null);
  const { settings, params, user } = useRequestInit<{ vestiging: string; adviesdossier: string; voorstel: string }>();
  const viewer = useRef<HTMLDivElement | null>(null);
  const [messageUrl, setMessageUrl] = useState<string | undefined>("");
  const [errorMsg, setErrorMsg] = useState<string | undefined>("");
  const [showPdfButton, setShowPdfButton] = pdfButtonState;

  let url = "";
  if (!voorstelId) url = `${settings.rapportageOrigin}/Adviesdossiers/${params.adviesdossier}/SnelInzicht/Overzichten`;
  if (voorstelId) url = `${settings.rapportageOrigin}/Voorstellen/${voorstelId}/SnelInzicht/Overzichten`;

  const { message: messageTo, error } = useServiceBusSubscription(
    sasToken,
    subscription,
    "overzichtentopic",
    true,
    "maandlast-overzicht",
    params.vestiging
  );

  useEffect(() => {
    messageTo && setMessageUrl(messageTo);
    error && setErrorMsg(error);
  }, [messageTo, error]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    const getOverzicht = async (): Promise<void> => {
      const snelOverzichtResult = await getOverzichtSubscription(
        { url, body: null, rapportageNaam: "maandlast-overzicht" },
        params,
        user,
        settings
      );

      if (snelOverzichtResult !== null) {
        setSasToken(snelOverzichtResult.sasToken);
        setSubscription(snelOverzichtResult.overzichtId);
      }
    };

    !showPdfButton && getOverzicht();
  }, [url, voorstelId, params, settings, user, showPdfButton, setShowPdfButton, setMessageUrl]);

  return (
    <>
      {showPdfButton ? (
        <div className="col-12 d-flex justify-content-center">
          <Button
            id="btn-nieuw"
            data-testid="btn-nieuw"
            variant="primary"
            disabled={!productenAanwezig}
            onClick={
              /* istanbul ignore next */ (): void => {
                setMessageUrl(undefined);
                setShowPdfButton(false);
                setSasToken(null);
                setSubscription(null);
                setErrorMsg("");
              }
            }
          >
            Maandlastenoverzicht ophalen
          </Button>
        </div>
      ) : (
        <></>
      )}
      <div
        key={`snelinzicht`}
        id="snelinzicht"
        ref={viewer}
        style={{
          visibility: !showPdfButton && messageUrl ? "inherit" : "hidden",
          height: !showPdfButton && messageUrl ? "18cm" : "0cm"
        }}
      ></div>
      <Pdf
        key={`pdf-snelinzicht}`}
        disableTools={true}
        url={messageUrl ? messageUrl : undefined}
        viewer={viewer.current}
        exec={
          /* istanbul ignore next */
          (instance: WebViewerInstance | undefined) => {
            if (instance) {
              instance.docViewer.addEventListener("documentLoaded", () => {
                instance.docViewer.setCurrentPage(2);
              });
            }
          }
        }
      />
      {!errorMsg && !showPdfButton && !messageUrl && (
        <div className="col-12 d-flex justify-content-center">
          <LoadingSpinner size="M" />
        </div>
      )}
      {errorMsg && (
        <>
          Er is een fout opgetreden bij het ophalen van de PDF.
          <br />
          {errorMsg}
        </>
      )}
    </>
  );
};
