import { jaarMaandInMaanden } from "../../shared/generic-parts/jaar-maand/map-ui-2-dl";
import { werkJaarMaandInputBijInSideEffect } from "../../shared/generic-parts/jaar-maand/helpers";

import {
  MaandlastKredietInput,
  MaandlastKredietOutput,
  MaandlastKredietInputSoortKrediet,
  PrivateLeaseInput,
  PrivateLeaseOutput
} from "../../.generated/krediet/krediettypes";
import { KredietenType } from "./kredieten-types";
import { SoortKredietProductOptions } from "../../.generated/forms/formstypes";
import {
  createISWAsyncSideEffect,
  initISWAsyncSideEffect
} from "../../shared/components/isw-side-effects/create-isw-helpers";
import { kredietenSchema } from "./kredieten-schema";
import { createMapEnum } from "../../shared/utils/create-map-enum";
import { createMapToDl } from "../../shared/utils/create-map-to-dl";
import { afronden } from "../../shared/utils/currency";
import {
  mapDlMaandlastTargetToKredietenUiField,
  mapDlPrivateLeaseTargetToKredietenUiField
} from "./map-kredieten-dl-2-ui";

const mapSoortKredietProductOptions = createMapEnum(SoortKredietProductOptions).to({
  AflopendKrediet: MaandlastKredietInputSoortKrediet.AflopendKrediet,
  DoorlopendKrediet: MaandlastKredietInputSoortKrediet.DoorlopendKrediet,
  Studielening: MaandlastKredietInputSoortKrediet.Studielening,
  Restschuldlening: MaandlastKredietInputSoortKrediet.Restschuldlening,
  Werkgeverslening: MaandlastKredietInputSoortKrediet.AflopendKrediet,
  Privatelease: MaandlastKredietInputSoortKrediet.PrivateLease
});

export const mapKredietenTypeToPrivateLeaseInput = createMapToDl(kredietenSchema)
  .with<Context>()
  .to<PrivateLeaseInput>({
    looptijdInMaanden: (v, context) => jaarMaandInMaanden(v.producten[context.selected].product.looptijd),
    maandlast: (v, context) => v.producten[context.selected].leningGegevens.maandlast.bedrag
  });

export const kredietDetailsAsyncPrivateLease = createISWAsyncSideEffect<KredietenType, Context>(
  async ({ draft, settings, fetchData, context }) => {
    const result = await fetchData<PrivateLeaseOutput, PrivateLeaseInput>({
      url: `${settings.kredietOrigin}/PrivateLease`,
      body: mapKredietenTypeToPrivateLeaseInput(context),
      mapperDlNameToUiName: mapDlPrivateLeaseTargetToKredietenUiField(context.selected)
    });

    if (result.isValid && result.resultaat?.hoofdsom) {
      const { hoofdsom } = draft.producten[context.selected].leningGegevens;

      hoofdsom.berekendBedrag = result.resultaat.hoofdsom;
      if (hoofdsom.berekenen !== false) hoofdsom.bedrag = result.resultaat.hoofdsom;
    }
  }
);

export const mapKredietenTypeToMaandlastKredietInput = createMapToDl(kredietenSchema)
  .with<Context>()
  .to<MaandlastKredietInput>({
    looptijdInMaanden: (v, context) =>
      v.producten[context.selected].soortProduct === SoortKredietProductOptions.DoorlopendKrediet
        ? null
        : jaarMaandInMaanden(v.producten[context.selected].product.looptijd),
    aflossingPercentage: (v, context) => v.producten[context.selected].leningGegevens.aflossingPercentage,
    hoofdsom: (v, context) => v.producten[context.selected].leningGegevens.hoofdsom.bedrag,
    rentePercentage: (v, context) => v.producten[context.selected].leningGegevens.rentePercentage,
    restantHoofdsom: (v, context) => v.producten[context.selected].leningGegevens.restantHoofdsom,
    slottermijn: (v, context) => v.producten[context.selected].leningGegevens.slottermijn,
    soortKrediet: (v, context) => mapSoortKredietProductOptions(v.producten[context.selected].soortProduct)
  });

export const kredietDetailsAsyncMaandlast = createISWAsyncSideEffect<KredietenType, Context>(
  async ({ draft, settings, fetchData, context }) => {
    const result = await fetchData<MaandlastKredietOutput, MaandlastKredietInput>({
      url: `${settings.kredietOrigin}/MaandlastKrediet`,
      body: mapKredietenTypeToMaandlastKredietInput(context),
      mapperDlNameToUiName: mapDlMaandlastTargetToKredietenUiField(context.selected)
    });
    if (result.isValid) {
      if (result.resultaat?.brutoMaandlast) {
        const { maandlast } = draft.producten[context.selected].leningGegevens;
        const newBrutoMaandlast = afronden(result.resultaat.brutoMaandlast);

        maandlast.berekendBedrag = newBrutoMaandlast;
        if (maandlast.berekenen !== false) maandlast.bedrag = newBrutoMaandlast;

        if (draft.producten[context.selected].soortProduct === SoortKredietProductOptions.DoorlopendKrediet) {
          werkJaarMaandInputBijInSideEffect(
            draft.producten[context.selected].product.looptijd,
            result.resultaat.looptijdInMaanden,
            draft.producten[context.selected].soortProduct,
            draft.producten[context.selected].product.ingangsdatum
          );
        }
      }
    }
  }
);

type Context = {
  selected: number;
};
export const kredietDetailsAsyncSideEffects = initISWAsyncSideEffect<KredietenType, Context>(
  ({ has, curr, runAsync, context }) => {
    const hasProduct = has.producten[context.selected];
    const product = curr.producten[context.selected];

    if (product.soortProduct === SoortKredietProductOptions.Privatelease) {
      const fieldChanged =
        hasProduct.soortProduct.changed ||
        hasProduct.leningGegevens.maandlast.changed ||
        hasProduct.product.looptijd.changed;

      if (fieldChanged && jaarMaandInMaanden(product.product.looptijd) && product.leningGegevens.maandlast.bedrag) {
        runAsync(kredietDetailsAsyncPrivateLease(context));
      }
    } else {
      let fieldChanged =
        hasProduct.soortProduct.changed ||
        hasProduct.product.looptijd.changed ||
        hasProduct.leningGegevens.aflossingPercentage.changed ||
        hasProduct.leningGegevens.hoofdsom.bedrag.changed ||
        hasProduct.leningGegevens.rentePercentage.changed ||
        hasProduct.leningGegevens.maandlast.berekenen.changed ||
        hasProduct.leningGegevens.restantHoofdsom.changed ||
        hasProduct.leningGegevens.slottermijn.changed;

      // bij een doorlopend krediet is de ingangsdatum van een product ook van belang
      if (!fieldChanged && product.soortProduct === SoortKredietProductOptions.DoorlopendKrediet) {
        fieldChanged = hasProduct.product.ingangsdatum.changed;
      }

      if (
        fieldChanged &&
        (jaarMaandInMaanden(product.product.looptijd) ||
          product.soortProduct === SoortKredietProductOptions.DoorlopendKrediet) &&
        product.leningGegevens.hoofdsom.bedrag &&
        product.leningGegevens.rentePercentage
      ) {
        runAsync(kredietDetailsAsyncMaandlast(context));
      }
    }
  }
);
