import { hasValue } from "adviesbox-shared";
import * as Yup from "yup";

export const getValueFromKenmerken = (object: any, key: any): boolean | null => {
  return object && key && hasValue(object[key]) ? object[key] : null;
};

export const keyIsFoundInObject = <T>(key: any, object: T): key is keyof T => key && key in object;

export const updateTaxatieSchermObject = (
  schemaToKenmerkenMapper: Partial<Record<string, string[]>>,
  kenmerken: object,
  schema: Yup.ObjectSchema<any>,
  taxatieSchermObject: Record<string, any> | null
): object => {
  const newObject = schema.default();
  const currentObject = taxatieSchermObject ?? newObject;
  Object.keys(currentObject).forEach(schemaKey => {
    const kenmerkenKeys = schemaToKenmerkenMapper[schemaKey] ?? [];
    const mappingDoesNotExist = !keyIsFoundInObject(schemaKey, schemaToKenmerkenMapper);
    const kenmerkExistsAndIsTrue =
      kenmerkenKeys.every(kenmerkenKey => keyIsFoundInObject(kenmerkenKey, kenmerken)) &&
      kenmerkenKeys.some(kenmerkenKey => !!getValueFromKenmerken(kenmerken, kenmerkenKey));
    if (mappingDoesNotExist || kenmerkExistsAndIsTrue) {
      newObject[schemaKey] = currentObject[schemaKey]; // Keep current value
    } else {
      if (Yup.reach(schema, schemaKey).isValidSync(null)) newObject[schemaKey] = null;
    }
  });
  return newObject;
};
