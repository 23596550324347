import { hasValue, nullableLocalDateSchema } from "adviesbox-shared";
import * as Yup from "yup";
import { ValidationError } from "yup";
import {
  FiscaleVoortzettingOptions,
  EnergieKlasseType,
  Financieringsoort,
  AflossingsVormType
} from "../../.generated/forms/formstypes";
import { UitslagOptions } from "../../.generated/hypotheek-vergelijker/hypotheek-vergelijkertypes";
import { OrganisatieEx } from "../../.generated/licenties/licentiestypes";
import { getProductenOverzichtTextResources } from "../../producten-overzicht/infra/producten-overzicht-resources";
import { klantnaamSchema } from "../../shared/generic-parts/klantnaam/schema";
import { yupEnum, yupNullableEnum } from "../../shared/utils/yup-enum";
import {
  fiscaleVoortzettingKeuzeSchema,
  hypotheekFiscaleRegelingSchema,
  hypotheekSchema,
  hypotheekVormSchema,
  leningdeelgegevensSchema,
  pandSchema,
  voorwaardenSelectieSchema
} from "../infra/hypotheek-schema";

//todo: lowercase keynames (in api fetch)
export const hypotheekResultaatSchema = Yup.object({
  ProductCode: Yup.number(),
  Productnaam: Yup.string(),
  hypotheekvormCode: Yup.number(),
  MaatschappijCode: Yup.string(),
  leningdelen: Yup.array(hypotheekVormSchema.shape({ renteboxCode: Yup.number() })).nullable(),
  MaatschappijNaam: Yup.string(),
  UitslagMaximaleHypotheek: yupEnum(UitslagOptions).default(UitslagOptions.Wit),
  UitslagVoorwaarden: yupEnum(UitslagOptions).default(UitslagOptions.Wit),
  ToetsrentePercentage: Yup.number(),
  WerkelijkRentePercentage: Yup.number(),
  MaximaleHypotheekBedrag: Yup.number(),
  MaximaleHypotheekOnderpandBedrag: Yup.number(),
  aflossingsVorm: yupEnum(AflossingsVormType).default(AflossingsVormType.Annuïteit),
  hypotheekomschrijving: Yup.string(),
  MaximaleHypotheekNhgBedrag: Yup.number(),
  IndicatieMeerLenenBedrag: Yup.number(),
  VerplichteAflossingBedrag: Yup.number(),
  BrutoMaandlastAanvangBedrag: Yup.number(),
  NettoMaandlastAanvangBedrag: Yup.number(),
  NettoLastTotaalBedrag: Yup.number(),
  AutomatischeRentedalingNaRvp: Yup.bool(),
  AutomatischeRentedalingDirect: Yup.bool(),
  VoorwaardenToelichtingregels: Yup.object({
    Toelichting: Yup.string()
  })
});

export const hypotheekVergelijkenSchema = Yup.object({
  aanvangsDatum: nullableLocalDateSchema,
  hypotheekSamenstelling: Yup.array(
    hypotheekSchema.shape({
      leningdeelgegevens: leningdeelgegevensSchema.shape({
        datumOpgave: nullableLocalDateSchema
      }),
      // in Stap1 staan we toe om geen oorspronkelijkeIngangsdatum en ingangsdatumBox1 leeg te laten
      fiscaleRegeling: hypotheekFiscaleRegelingSchema
        .shape({
          oorspronkelijkeIngangsdatum: nullableLocalDateSchema.test(
            "required-check",
            getProductenOverzichtTextResources("RequiredOorspronkelijkeIngangsdatum"),
            function(this: Yup.TestContext): ValidationError | boolean {
              /* istanbul ignore else */
              if (
                this.parent.fiscaleVoortzetting === FiscaleVoortzettingOptions.Geen ||
                this.parent.fiscaleVoortzetting === FiscaleVoortzettingOptions.EigenInvoer
              ) {
                return true;
              }
              return hasValue(this.parent.oorspronkelijkeIngangsdatum);
            }
          ),
          ingangsdatumBox1: nullableLocalDateSchema.test(
            "required-check",
            getProductenOverzichtTextResources("RequiredingangsdatumBox1"),
            function(): ValidationError | boolean {
              /* istanbul ignore else */
              if (
                this.parent.fiscaleVoortzetting === FiscaleVoortzettingOptions.Geen ||
                this.parent.fiscaleVoortzetting === FiscaleVoortzettingOptions.EigenInvoer
              ) {
                return true;
              }
              return hasValue(this.parent.ingangsdatumBox1);
            }
          )
        })
        .nullable()
    })
  ).default([hypotheekSchema.default()]),
  soortFinanciering: Yup.mixed<Financieringsoort>()
    .oneOf(Object.values(Financieringsoort), getProductenOverzichtTextResources("ErrorOnbekendeWaarde"))
    .default(Financieringsoort.Geen),
  panden: Yup.array(pandSchema),
  energieKlasse: yupNullableEnum(EnergieKlasseType).default(null),
  meerwerkEBV: Yup.number().nullable(),
  voorwaardeSelectie: voorwaardenSelectieSchema,
  modalOpen: Yup.bool().default(false),
  resultaten: Yup.array(hypotheekResultaatSchema).default([]),
  sortedResultaten: Yup.array(hypotheekResultaatSchema).default([]),
  verwachteTotaal: Yup.string().default("?"),
  selectedResultaat: hypotheekResultaatSchema.nullable().default(null),
  fiscaleVoortzettingKeuzes: Yup.array(fiscaleVoortzettingKeuzeSchema).nullable(),
  aanvrager1: klantnaamSchema.nullable(),
  aanvrager2: klantnaamSchema.nullable(),
  organisatieData: Yup.object<OrganisatieEx>()
    .nullable()
    .default(null),
  nhg: Yup.boolean().nullable(),
  eigenwoningschuldBedrag: Yup.number().nullable(),
  gewensteHypotheekBedrag: Yup.number().nullable()
});
