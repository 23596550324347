import { SpecificatieRenteaftrekModalType } from "../infra/hypotheek-types";
import { createISWSideEffect, initISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";
import { LocalDate } from "@js-joda/core";

type Context = { productIngangsdatum: LocalDate | null, productEinddatum: LocalDate | null }

const renteAftrekSideEffects = createISWSideEffect<SpecificatieRenteaftrekModalType, Context>(({ has, draft, context }): void => {
  //Zet einddatum gelijk aan aanvangsdatum, op het moment dat deze voor aanvangsdatum komt, en zorg dat deze binnen looptijd van product blijft
  if (draft.renteAftrekken && has.renteAftrekken.changed) {
    draft.renteAftrekken.map((x, i) => {
      if (x.einddatum && x.aanvangsdatum) {
        if (x.einddatum < x.aanvangsdatum) {
          x.einddatum = x.aanvangsdatum;
        }
      }

      if (context.productIngangsdatum && context.productEinddatum) {
        if (x.aanvangsdatum && x.aanvangsdatum < context.productIngangsdatum) {
          x.aanvangsdatum = context.productIngangsdatum;
        }

        if (x.einddatum && x.einddatum > context.productEinddatum) {
          x.einddatum = context.productEinddatum;
        }
      }

      return x;
    });
  }
});

export const determineSpecificatieRenteaftrekModalSideEffects = initISWSideEffect<SpecificatieRenteaftrekModalType, Context>(renteAftrekSideEffects);
