import React, { ReactElement } from "react";

export const HoeveelEigenGeldInbrengenExtra = (): ReactElement => (
  <>
    <h2>Hoeveel wilt u per onderdeel inbrengen?</h2>
    <span className="d-block font-italic px-1">Geef per onderdeel een bedrag aan</span>
  </>
);
/* istanbul ignore next */
if (process.env.NODE_ENV !== "production")
  HoeveelEigenGeldInbrengenExtra.displayName = "HoeveelEigenGeldInbrengenExtra";
