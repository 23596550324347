import React, { ReactElement } from "react";
import { connect, FormikContextType } from "formik";

import { LabeledRadioInput, LabeledBevestigingInput } from "adviesbox-shared";
import { KapitaalverzekeringenType } from "../infra/kapitaalverzekering-types";
import { FiscaleVoortzettingOptions, KapitaalopbouwOptions } from "../../.generated/forms/formstypes";
import FiscaleRegelingInput from "../../producten-overzicht/fiscale-regeling/fiscale-regeling";

const FiscaleRegeling = ({
  selected
}: {
  formik: FormikContextType<KapitaalverzekeringenType>;
  selected: number;
}): ReactElement => {
  const fiscaleVoortzettingKeuzes = [
    { label: "Geen", value: FiscaleVoortzettingOptions.Geen },
    { label: "Eigen invoer", value: FiscaleVoortzettingOptions.EigenInvoer }
  ];
  return (
    <>
      <LabeledRadioInput
        caption="Kapitaalopbouw"
        name={`producten[${selected}].fiscaleRegeling.kapitaalopbouw`}
        options={[
          { label: "Box 1", value: KapitaalopbouwOptions.Box1 },
          { label: "Box 3", value: KapitaalopbouwOptions.Box3 },
          {
            label: "Box 3 tijdelijk vrijgesteld",
            value: KapitaalopbouwOptions.Box3TijdelijkVrijgesteld
          }
        ]}
      />

      <LabeledRadioInput
        caption="Orv"
        name={`producten[${selected}].fiscaleRegeling.orv`}
        options={[
          { label: "Box 1", value: KapitaalopbouwOptions.Box1 },
          { label: "Box 3", value: KapitaalopbouwOptions.Box3 }
        ]}
      />

      <LabeledBevestigingInput
        caption="Lijfrenteclausule"
        name={`producten[${selected}].fiscaleRegeling.lijfrenteclausule`}
      />

      <FiscaleRegelingInput
        selected={selected}
        kenmerken={{
          fiscaleRegelingTonen: false,
          kapitaalopbouwBoxKeuzeTonen: false,
          kapitaalopbouwOpKaartTonen: false,
          lijfrenteclausuleOpKaartTonen: false,
          lijfrenteclausuleTonen: true,
          orvBoxKeuzeTonen: false
        }}
        fiscaleVoortzettingKeuzes={fiscaleVoortzettingKeuzes}
      />
    </>
  );
};

export default connect<{ selected: number }, KapitaalverzekeringenType>(FiscaleRegeling);
