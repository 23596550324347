import { FiscaleBijtelpostenModalType } from "../infra/inkomen-en-fiscus-schema";
import { createISWSideEffect, initISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";

const inkomenEnFiscusSideEffects = createISWSideEffect<FiscaleBijtelpostenModalType>(({ has, draft }): void => {

  if (!has.bijtellingBedraagt.changed && !has.eigenBijdrage.changed) return;
  let result: number | null = null;
  if (draft.bijtellingBedraagt !== null) {
    result = draft.bijtellingBedraagt;
    if (draft.eigenBijdrage) {
      result = result - draft.eigenBijdrage;
    }
    if (result < 0) result = 0;
  }
  draft.bijtellingOpInkomen = result;
});

export const determineFiscaleBijtelpostenModalSideEffects = initISWSideEffect(inkomenEnFiscusSideEffects)();
