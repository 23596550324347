import { VermogenKenmerken } from "../product-kenmerken-types";
import {
  productCodeBetaalrekening,
  productCodeEffectenlease,
  productCodeBeleggingsdepot,
  productCodeSpaarrekening,
  partijCodeAEGON,
  partijcodeING,
  partijcodeBND
} from "../../../vermogen/infra/vermogen-types";
import { partijOnafhankelijk } from "../product-constanten";

// TODO MdB: Verwijderen als Vermogen kenmerken beschikbaar zijn.
export const getVermogenKenmerken = (maatschappijCode: string, productCode: string): VermogenKenmerken => ({
  product: {
    productnaamTonen: true,
    productnaamEnabled: true,
    ingangsdatumTonen: true,
    einddatumTonen: productCode !== productCodeBetaalrekening,
    einddatumEnabled: true,
    looptijdTonen: productCode !== productCodeBetaalrekening,
    looptijdJaarEnabled: true,
    looptijdMaandEnabled: true,
    uwBemiddelingTonen: productCode !== productCodeBetaalrekening,
    maatschappijkeuzeEnabled: true,
    logoTonen: maatschappijCode !== partijOnafhankelijk,
    productoptiesTonen: false,
    productnummerTonen: true
  },
  verzekeringnemer: {
    meerdereVerzekeringnemerOptiesMogelijk: true
  },
  depot: {
    depotTonen:
      maatschappijCode === partijOnafhankelijk &&
      productCode !== productCodeBetaalrekening &&
      productCode !== productCodeEffectenlease,
    bestedingsdoelTonen: maatschappijCode === partijOnafhankelijk && productCode !== productCodeBetaalrekening,
    aankoopkostenPercentageTonen:
      maatschappijCode === partijOnafhankelijk &&
      (productCode === productCodeBeleggingsdepot || productCode === productCodeEffectenlease),
    verkoopkostenPercentageTonen:
      maatschappijCode === partijOnafhankelijk &&
      (productCode === productCodeBeleggingsdepot || productCode === productCodeEffectenlease),
    beheerkostenPercentageTonen:
      maatschappijCode === partijOnafhankelijk &&
      (productCode === productCodeBeleggingsdepot || productCode === productCodeEffectenlease),
    depotWaardeheaderTonen: maatschappijCode === partijOnafhankelijk && productCode !== productCodeBetaalrekening,
    waardeopbouwBedragTonen: productCode !== productCodeBetaalrekening,
    waardeopbouwBedragEnabled: maatschappijCode === partijOnafhankelijk && productCode === productCodeBetaalrekening,
    reedsOpgebouwdBedragTonen: maatschappijCode === partijOnafhankelijk,
    reedsOpgebouwdDatumTonen: maatschappijCode === partijOnafhankelijk,
    afkoopwaardeDatumTonen: maatschappijCode === partijOnafhankelijk && productCode === productCodeEffectenlease,
    afkoopwaardeBedragTonen: maatschappijCode === partijOnafhankelijk && productCode === productCodeEffectenlease,
    waardeopbouwNa5JaarTonen: maatschappijCode === partijOnafhankelijk && productCode === productCodeBetaalrekening
  },
  kapitaalopbouw: {
    soortRekeningTonen: maatschappijCode === partijOnafhankelijk && productCode === productCodeBeleggingsdepot,
    soortLijfrenteTonen: false,
    soortBerekeningTonen:
      (maatschappijCode === partijOnafhankelijk && productCode !== productCodeBetaalrekening) ||
      maatschappijCode === partijcodeBND,
    soortBerekeningEigenInvoerTonen: true,
    beleggersprofielTonen:
      (maatschappijCode === partijOnafhankelijk && productCode === productCodeBeleggingsdepot) ||
      maatschappijCode === partijcodeBND,
    doelkapitaalBedragTonen:
      (maatschappijCode === partijOnafhankelijk ||
        maatschappijCode === partijCodeAEGON ||
        maatschappijCode === partijcodeING ||
        maatschappijCode === partijcodeBND) &&
      productCode !== productCodeBetaalrekening &&
      productCode !== productCodeEffectenlease,
    doelkapitaalBedragEnabled:
      (maatschappijCode === partijOnafhankelijk && productCode !== productCodeBetaalrekening) ||
      maatschappijCode === partijcodeBND,
    doelrendementPercentageTonen:
      (maatschappijCode === partijOnafhankelijk ||
        maatschappijCode === partijCodeAEGON ||
        maatschappijCode === partijcodeBND) &&
      (productCode === productCodeSpaarrekening ||
        productCode === productCodeBeleggingsdepot ||
        productCode === productCodeBetaalrekening),
    doelrendementPercentageEnabled:
      maatschappijCode === partijOnafhankelijk && productCode !== productCodeEffectenlease,
    voorbeeldkapitaalBedragTonen:
      (maatschappijCode === partijOnafhankelijk || maatschappijCode === partijcodeBND) &&
      (productCode === productCodeBeleggingsdepot || productCode === productCodeEffectenlease),
    voorbeeldkapitaalBedragEnabled: maatschappijCode !== partijcodeBND,
    voorbeeldrendementPercentageTonen:
      maatschappijCode === partijOnafhankelijk &&
      (productCode === productCodeBeleggingsdepot || productCode === productCodeEffectenlease),
    voorbeeldkapitaalPercentageEnabled: true,
    garantiekapitaalBedragTonen:
      maatschappijCode === partijOnafhankelijk &&
      (productCode === productCodeSpaarrekening || productCode === productCodeBeleggingsdepot),
    garantierendementPercentageTonen:
      maatschappijCode === partijOnafhankelijk &&
      (productCode === productCodeSpaarrekening || productCode === productCodeBeleggingsdepot)
  },
  premie: {
    aanvangstortingEnabled: maatschappijCode === partijOnafhankelijk && productCode === productCodeBetaalrekening,
    aanvangstortingTonen: true,
    aftrekbarePremieTonen: true,
    berekenPremieKnopTonen: true,
    betalingstermijnEnabled: maatschappijCode === partijOnafhankelijk && productCode !== productCodeBetaalrekening,
    betalingstermijnTonen: true,
    componentKenmerk: true,
    duurHoogLaagConstructieEnabled: true,
    duurHoogLaagConstructieTonen: true,
    duurPremiebetalingEnabled: true,
    duurPremiebetalingTonen: maatschappijCode === partijOnafhankelijk && productCode !== productCodeBetaalrekening,
    einddatumPremiebetalingEnabled: false,
    einddatumPremiebetalingTonen: maatschappijCode === partijOnafhankelijk && productCode !== productCodeBetaalrekening,
    extraStortingTonen: true,
    garantiePremieTonen: true,
    heeftBetalingstermijnHalfJaar: true,
    heeftBetalingstermijnJaar: true,
    heeftBetalingstermijnKoopsom: true,
    heeftBetalingstermijnKwartaal: true,
    heeftBetalingstermijnMaand: true,
    heeftBetalingstermijnTweeMaanden: true,
    heeftPremieverloopGelijkblijvend: true,
    heeftPremieverloopVariabel: true,
    hintAanvangstortingTonen: true,
    hintRisicopremiespecificatieTonen: true,
    hoogLaagConstructieTonen: maatschappijCode === partijOnafhankelijk && productCode !== productCodeBetaalrekening,
    hoogLaagVerhoudingTonen: true,
    ingebrachteWaardeTonen: true,
    premiebetalingEnabled: true,
    premiedepotTonen: true,
    premieHoogEnabled: true,
    premiestijgendTonen: true,
    premieverloopTonen: true,
    premiegegevensTonen: true,
    risicopremieEnabled: true,
    risicopremiespecificatieHint: "",
    risicopremiespecificatieTonen: true,
    risicopremieTonen: true,
    soortDekking: true,
    spaarpremieEnabled: true,
    spaarpremieTonen: true,
    totalePremieEnabled: true,
    totalePremieTonen: true,
    verkortePremieduurTonen: true,
    hintPremieAftrekbaar: "",
    hintPremieAftrekbaarTonen: true
  },
  verpanding: {
    bedoeldAflossingTonen: true,
    verpandingTonen:
      (maatschappijCode === partijOnafhankelijk ||
        maatschappijCode === partijCodeAEGON ||
        maatschappijCode === partijcodeING) &&
      (productCode === productCodeBeleggingsdepot || productCode === productCodeSpaarrekening),
    pandhouderLijstTonen: true,
    lijfrenteUitkeringTonen: maatschappijCode !== partijCodeAEGON && maatschappijCode !== partijcodeING,
    inkomensaanvullingTonen: false,
    belastingBoxTonen: false,
    soortLijfrenteUitkeringTonen: true,
    overgangOpTweedeVerzekerdeTonen: true
  },
  onttrekkingen: {
    begindatumTonen:
      maatschappijCode === partijOnafhankelijk &&
      (productCode === productCodeBeleggingsdepot || productCode === productCodeSpaarrekening),
    einddatumTonen:
      maatschappijCode === partijOnafhankelijk &&
      (productCode === productCodeBeleggingsdepot || productCode === productCodeSpaarrekening),
    duurTonen:
      maatschappijCode === partijOnafhankelijk &&
      (productCode === productCodeBeleggingsdepot || productCode === productCodeSpaarrekening),
    onttrekkingstermijnTonen:
      maatschappijCode === partijOnafhankelijk &&
      (productCode === productCodeBeleggingsdepot || productCode === productCodeSpaarrekening),
    onttrekkingBedragTonen:
      maatschappijCode === partijOnafhankelijk &&
      (productCode === productCodeBeleggingsdepot || productCode === productCodeSpaarrekening)
  },
  fiscaleRegeling: {
    lijfrenteclausuleTonen: true,
    fiscaleRegelingTonen: true,
    kapitaalopbouwBoxKeuzeTonen: true,
    orvBoxKeuzeTonen: true,
    kapitaalopbouwOpKaartTonen: maatschappijCode === partijOnafhankelijk,
    lijfrenteclausuleOpKaartTonen: maatschappijCode === partijOnafhankelijk
  }
});
