import React, { ReactElement } from "react";
import { connect, FormikContextType } from "formik";

import { LabeledCurrencyInput, LabeledBevestigingInput } from "adviesbox-shared";
import { BankgarantieState } from "../infra/bankgarantie-schema";

const Pand = ({
  formik: {
    setFieldValue,
    values: { pand }
  }
}: {
  formik: FormikContextType<BankgarantieState>;
}): ReactElement => {
  return (
    <>
      <LabeledCurrencyInput caption="Aankoopprijs" name="pand.aankoopprijs" readonly={true} />

      <LabeledCurrencyInput caption="Hypotheekbedrag" name="pand.hypotheekbedrag" readonly={true} />

      <LabeledCurrencyInput caption="Gehanteerde marktwaarde" name="pand.gehanteerdeMarktwaarde" readonly={true} />

      <LabeledBevestigingInput caption="Nationale hypotheek garantie" readonly={true} name="pand.nhg" />

      <LabeledCurrencyInput caption="Eigen middelen" name="pand.eigenMiddelen" readonly={true} />

      <LabeledCurrencyInput caption="Overbrugging" name="pand.overbrugging" readonly={true} />
    </>
  );
};

export default connect<{}, BankgarantieState>(Pand);
