import { toekomstPerspectiefQuestionSpecs } from "../algemeen/toekomstperspectief/toekomstperspectief-question-specs";
import { doelstellingQuestionSpecs } from "../algemeen/doelstelling/doelstelling-question-specs";
import { risicoEnKennisQuestionSpecs } from "../algemeen/risico-en-kennis/risico-en-kennis-question-specs";
import { arbeidsongeschiktheidQuestionSpecs } from "../risicos/arbeidsongeschiktheid/arbeidsongeschiktheid-question-specs";
import { rentevastperiodeQuestionSpecs } from "../hypotheek/rentevastperiode/rentevastperiode-question-specs";
import { fiscaalQuestionSpecs } from "../hypotheek/fiscaal/fiscaal-question-specs";
import { verduurzamingQuestionSpecs } from "../hypotheek/verduurzaming/verduurzaming-question-specs";
import { verantwoordeWoonlastenQuestionSpecs } from "../hypotheek/verantwoorde-woonlasten/verantwoorde-woonlasten-question-specs";
import { eigenGeldQuestionSpecs } from "../hypotheek/eigen-geld/eigen-geld-question-specs";
import { opbouwDoelvermogenQuestionSpecs } from "../vermogensopbouw/opbouw-doelvermogen/opbouw-doelvermogen-question-specs";
import { hypotheekvormQuestionSpecs } from "../hypotheek/hypotheekvorm/hypotheekvorm-question-specs";
import { geldverstrekkerQuestionSpecs } from "../hypotheek/geldverstrekker/geldverstrekker-question-specs";
import { risicoOverlijdenQuestionSpecs } from "../risicos/overlijden/overlijden-question-specs";
import { werkloosheidQuestionSpecs } from "../risicos/werkloosheid/werkloosheid-question-specs";
import { hypotheekalgemeenQuestionSpecs } from "../hypotheek/hypotheek-algemeen/hypotheek-algemeen-question-specs";
import { dubbeleWoonlastenQuestionSpecs } from "../hypotheek/dubbele-woonlasten/dubbele-woonlasten-question-specs";
import { risicoprofielQuestionSpecs } from "../vermogensopbouw/risicoprofiel/risicoprofiel-question-specs";
import { pensioenQuestionSpecs } from "../risicos/pensioen/pensioen-question-specs";

export const mergedSpecs = [
  ...toekomstPerspectiefQuestionSpecs,
  ...doelstellingQuestionSpecs,
  ...risicoEnKennisQuestionSpecs,
  ...arbeidsongeschiktheidQuestionSpecs,
  ...rentevastperiodeQuestionSpecs,
  ...fiscaalQuestionSpecs,
  ...verduurzamingQuestionSpecs,
  ...verantwoordeWoonlastenQuestionSpecs,
  ...arbeidsongeschiktheidQuestionSpecs,
  ...eigenGeldQuestionSpecs,
  ...opbouwDoelvermogenQuestionSpecs,
  ...hypotheekvormQuestionSpecs,
  ...geldverstrekkerQuestionSpecs,
  ...risicoOverlijdenQuestionSpecs,
  ...werkloosheidQuestionSpecs,
  ...hypotheekalgemeenQuestionSpecs,
  ...dubbeleWoonlastenQuestionSpecs,
  ...risicoprofielQuestionSpecs,
  ...pensioenQuestionSpecs
];
