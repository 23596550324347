import { MaximaleHypotheekState, ToetsrenteBerekeningObv } from "./maximale-hypotheek-schema";
import { TariefEntry } from "../../pensioen/infra/pensioen-schema";

import {
  initISWSideEffect,
  createISWSideEffect,
  createISWAsyncSideEffect,
  initISWAsyncSideEffect
} from "../../shared/components/isw-side-effects/create-isw-helpers";

const uitgangspuntenSideEffects = createISWSideEffect<MaximaleHypotheekState>(({ has, draft }): void => {
  if (has.uitgangspunten.looptijd.changed) {
    draft.toetsrente.looptijd = draft.uitgangspunten.looptijd;
    draft.dataHasChanged = true;
  }
});

const toetsrenteSideEffects = createISWSideEffect<MaximaleHypotheekState>(({ has, draft }): void => {
  if (
    has.toetsrente.berekeningOpBasisVan.changed &&
    draft.toetsrente.berekeningOpBasisVan === ToetsrenteBerekeningObv.SpecificatieToetsrente
  ) {
    draft.toetsrente.gewensteRentevastperiode = null;
    draft.dataHasChanged = true;
  }
  if (
    has.toetsrente.berekeningOpBasisVan.changed &&
    draft.toetsrente.berekeningOpBasisVan === ToetsrenteBerekeningObv.GewensteRentevastperiode
  ) {
    draft.toetsrente.gewensteRentevastperiode = 10;
    draft.dataHasChanged = true;
  }

  if (has.toetsrente.gewensteRentevastperiode.changed) {
    draft.uitgangspunten.gewensteRentevastperiode = draft.toetsrente.gewensteRentevastperiode;
    draft.dataHasChanged = true;
  }

  if (
    has.toetsrente.toetsrente.changed ||
    has.toetsrente.vrijVermogen.changed ||
    has.toetsrente.kredietToetslast.bedrag.changed ||
    has.toetsrente.kredietToetslast.berekenen.changed
  ) {
    draft.dataHasChanged = true;
  }
});

const inkomenSideEffects = createISWSideEffect<MaximaleHypotheekState>(({ has, draft }): void => {
  if (
    has.inkomen.pensioeninkomenAanvrager.bedrag.changed ||
    has.inkomen.pensioeninkomenAanvrager.berekenen.changed ||
    has.inkomen.pensioeninkomenPartner.bedrag.changed ||
    has.inkomen.pensioeninkomenPartner.berekenen.changed ||
    has.inkomen.toetsinkomenAanvrager.bedrag.changed ||
    has.inkomen.toetsinkomenAanvrager.berekenen.changed ||
    has.inkomen.toetsinkomenPartner.bedrag.changed ||
    has.inkomen.toetsinkomenPartner.berekenen.changed
  ) {
    draft.dataHasChanged = true;
  }
});

const leningdelenSideEffects = createISWSideEffect<MaximaleHypotheekState>(({ has, draft }): void => {
  if (
    has.leningdelenUitHetVerleden.leningdelenMeenemen.changed ||
    has.leningdelenUitHetVerleden.meeTeNemenLeningdelen.changed
  ) {
    if (
      draft.leningdelenUitHetVerleden.leningdelenMeenemen === true &&
      has.leningdelenUitHetVerleden.meeTeNemenLeningdelen.changed === false
    ) {
      const leningdeelIdsMeeTeNemenLeningdelen = new Set(
        draft.leningdelenUitHetVerleden.meeTeNemenLeningdelen
          .filter(x => x.leningdeelId !== null)
          .map(d => d.leningdeelId)
      );
      const mergedArray = [
        ...draft.leningdelenUitHetVerleden.meeTeNemenLeningdelen,
        ...draft.leningdelenUitHetVerleden.readOnlyLeningDelen.filter(
          d => !leningdeelIdsMeeTeNemenLeningdelen.has(d.leningdeelId)
        )
      ];
      draft.leningdelenUitHetVerleden.meeTeNemenLeningdelen = [];
      draft.leningdelenUitHetVerleden.meeTeNemenLeningdelen = mergedArray;
    }

    if (draft.leningdelenUitHetVerleden.leningdelenMeenemen !== true) {
      draft.leningdelenUitHetVerleden.meeTeNemenLeningdelen = [];
    }

    draft.dataHasChanged = true;
  }
});

const maximaleHypotheekSideEffects = createISWSideEffect<MaximaleHypotheekState>((bag): void => {
  uitgangspuntenSideEffects(bag);
  toetsrenteSideEffects(bag);
  inkomenSideEffects(bag);
  leningdelenSideEffects(bag);
});

export const determineMaximaleHypotheekSideEffects = initISWSideEffect(maximaleHypotheekSideEffects)();

export const getStaatsrente = createISWAsyncSideEffect<MaximaleHypotheekState>(
  async ({ draft, settings, fetchData }) => {
    const tariefJson = await fetchData<TariefEntry[]>({
      url: `${settings.accTarievenOrigin}/api/Toetsing`,
      method: "GET"
    });

    const latestTarief = tariefJson.reduce((p, c) => (p.jaar > c.jaar ? p : c));
    const staatsrenteJson = await fetchData<{ percentageStaatsrente?: number }>({
      url: `${settings.accTarievenOrigin}/api/Toetsing/${latestTarief.jaar}/${latestTarief.maand}`,
      method: "GET"
    });

    draft.toetsrente.toetsrente = staatsrenteJson.percentageStaatsrente ?? null;
  }
);

export const determineMaximaleHypotheekAsyncSideEffects = initISWAsyncSideEffect<MaximaleHypotheekState>(
  ({ has, curr, runAsync }) => {
    if (
      has.toetsrente.berekeningOpBasisVan.changed &&
      curr.toetsrente.berekeningOpBasisVan === ToetsrenteBerekeningObv.GewensteRentevastperiode
    ) {
      runAsync(getStaatsrente());
    }
  }
)();
