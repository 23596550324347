import { hasJSONResponse, logErrorToApplicationInsights, SettingsType } from "adviesbox-shared";
import { User } from "oidc-client";
import {
  KapitaalverzekeringBerekeningInput,
  KapitaalverzekeringBerekeningResultaat
} from "../../.generated/forms/formstypes";

export const kapitaalverzekeringPremieBerekenen = async (
  settings: SettingsType,
  user: User,
  vestigingId: string,
  adviesdossierId: string,
  body: KapitaalverzekeringBerekeningInput,
  signal: AbortSignal
): Promise<KapitaalverzekeringBerekeningResultaat | null> => {
  const url = `${settings.klantdossiersFormsOrigin}/Adviesdossiers/${adviesdossierId}/Kapitaalverzekering`;
  const rsp = await fetch(url, {
    method: "POST",
    headers: {
      authorization: `${user.token_type} ${user.access_token}`,
      "Ocp-Apim-Subscription-Key": settings.OcpApimSubscriptionKey,
      "Content-Type": "application/json;charset=UTF-8",
      vestigingId
    },
    body: JSON.stringify(body),
    signal
  }).catch((e: Error) => {
    logErrorToApplicationInsights(Error(`Fout bij aanroep van web-api '${url}'. ${e.message}`));
    return null;
  });

  if (rsp === null) return rsp;

  if (!(rsp.ok && hasJSONResponse(rsp))) {
    logErrorToApplicationInsights(Error(`Fout bij aanroep van web-api '${url}'. Resultaat bevat geen JSON.`));
    return null;
  }

  const json = await rsp.json();

  if (!json.isValid) {
    logErrorToApplicationInsights(Error(`Fout bij aanroep van web-api '${url}'. Input is niet valid.`));
    return null;
  }
  return json.resultaat;
};
