import React from "react";
import { createGetTextResources } from "../../shared/utils/text-resources-algemeen";

export const getPersonaliaTextResources = createGetTextResources({
  personaliaKindAantalKeerTooltip: (
    <>
      Maken de ouders hoge kosten voor een kind, dan kunnen ze soms dubbele kinderbijslag krijgen. Bijvoorbeeld als het
      kind vanwege ziekte, een handicap of een bepaalde opleiding niet thuis kan wonen. Hier zijn wel voorwaarden aan
      verbonden, de ouders mogen bijvoorbeeld niet te veel dagen bij het kind verblijven.
      <br />
      <br />
      Woont een kind tussen de 3 en 18 jaar thuis? Heeft het intensieve zorg nodig? En heeft het een positief advies
      gekregen van het Centrum indicatiestelling zorg (CIZ)? Dan heeft de ouder ook recht op dubbele kinderbijslag.
    </>
  ),
  personaliaKindGeboorteDatumTooltip: "Bij een zwangerschap vul je hier de geschatte geboortedatum in.",

  schemaGeboortedatumKindVerplicht: "Vul de geboortedatum van het kind in.",
  schemaOngeldigeDatum: "Ongeldige datum.",
  schemaGeboortedatumKindToekomst: "Vul een geldige geboortedatum in.",
  schemaAchternaamVerplicht: "Achternaam is verplicht.",
  schemaAanvragerMinimumLeeftijd: "Aanvrager moet minimaal 18 jaar oud zijn.",
  schemaGeboortedatum1900: "Geboortedatum voor 1900 is ongeldig.",
  schemaGeboortedatumToekomst: "Vul een geldige geboortedatum in.",
  schemaGeboortedatumVerplicht: "Geboortedatum is verplicht.",
  schemaLegitimatiebewijsNummer: "Vul het nummer van het legitimatiebewijs in.",
  schemaLegitimatiebewijsGeldigTot:
    "Controleer de geldigheidsdatum en de afgiftedatum van het legitimatiebewijs.",

  tooltipVoornamen: "Vul hier alle voornamen in zoals het op het legitimatiebewijs van de aanvrager staat.",
  tooltipGeboortenaam:
    "De geboortenaam is de achternaam die de aanvrager bij zijn geboorte kreeg.",

  tekstUitsluitingenModal:
    "Heeft de aanvrager ooit een ziektekosten-, arbeidsongeschiktheids-, of levensverzekering afgesloten waarbij een hogere premie en/of uitsluiting van bepaalde risico's op van toepassing waren?",
  tekstWeigeringenModal:
    "Heeft een verzekeraar de aanvrager ooit een ziektekosten-, arbeidsongeschiktheids-, of levensverzekering opgezegd, vernietigd of geweigerd?"
});
