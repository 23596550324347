import React, { ReactElement, useMemo } from "react";
import { FormikProps } from "formik";
import { useRequestInit } from "adviesbox-shared";
import {
  LabeledSelectInput,
  FormFirstFocus,
  PlatformFoutenSamenvatting
} from "adviesbox-shared";
import { LabelValuePairs } from "adviesbox-shared/utils/types";
import Modal from "../../modal/Modal";
import AdviesBoxFormik from "../../../utils/adviesbox-formik";
import { InvalidAdviesboxResultErrorPage } from "../../fetch-error-page/fetch-error-page";
import {
  DossierWijzigAdviseurType,
  dossierAdviseurWijzigenSchema
} from "./dossier-wijzig-adviseur-types";
import { MedewerkerOutput, Medewerker } from "../../../../.generated/licenties/licentiestypes";
import classes from "../dossier-balk.module.scss";
import { wijzigAdviseurPOST } from "./dossier-wijzig-adviseur-modal-api";
import { getNaamMedewerker } from "./dossier-wijzig-adviseur-helper";
import _ from "lodash";
import { AdviesEigenaarOutput } from "../../../../.generated/forms/formstypes";
import { useFetchData} from "./usefetchdata";

type DossierWijzigAdviseursModalProps = {
  onSave?: () => void;
  closeModal?: () => void;
};

const createLabelValuePairs = (medewerkers: { [key: string]: Medewerker }): LabelValuePairs => {
  const sortedMedewerkersArray = _.sortBy(Object.entries(medewerkers), ([, medewerker]) => medewerker.achternaam);

  return sortedMedewerkersArray.map(([key, medewerker]) => ({
    label: getNaamMedewerker(medewerker, "medewerker naam onbekend"),
    value: key
  }));
};

const WijzigAdviseurModal = ({ onSave, closeModal }: DossierWijzigAdviseursModalProps): ReactElement => {
  const requestInitBase = useRequestInit<{ vestiging: string; adviesdossier: string }>();
  const { settings, params, user, requestInit } = requestInitBase;

  const getUrlHuidigeEigenaar = useMemo(() => {
    return `${settings.klantdossiersFormsOrigin}/Adviesdossier/Eigenaar/${params.adviesdossier}`;
  }, [settings.klantdossiersFormsOrigin, params.adviesdossier]);

  const getUrlMedewerkers = useMemo(() => {
    return `${settings.licentiesOrigin}/Vestigingen/${params.vestiging}/Medewerkers`;
  }, [params.vestiging, settings.licentiesOrigin]);

  const { data: huidigeEigenaarData, error: errorsHuidigeEigenaar, loading: loadingHuidigeEigenaar } = useFetchData<AdviesEigenaarOutput>(getUrlHuidigeEigenaar, () => requestInit);
  const { data: medewerkersData, error: errorsMedewerkers, loading: loadingMedewerkers } = useFetchData<MedewerkerOutput>(getUrlMedewerkers, () => requestInit);

  const doelAdviseurMedewerkers = useMemo(() => createLabelValuePairs(medewerkersData?.medewerkers || {}), [medewerkersData]);

  if (loadingHuidigeEigenaar || loadingMedewerkers) {
    return <div data-testid="loader" className="loader"></div>;
  }

  if (errorsHuidigeEigenaar!=null || errorsMedewerkers!=null) {
    return <InvalidAdviesboxResultErrorPage />;
  }

  return (
    <AdviesBoxFormik<DossierWijzigAdviseurType>
      initialValues={{ ...dossierAdviseurWijzigenSchema.default(), huidigeAdviseurId: huidigeEigenaarData?.adviseurId || "", doelAdviseurId: doelAdviseurMedewerkers.find(c=>true)?.value }}
      validationSchema={dossierAdviseurWijzigenSchema}
      onSave={onSave}
      closeModal={closeModal}
      render={({ setFieldValue, values, submitForm }: FormikProps<DossierWijzigAdviseurType>): ReactElement =>

        <Modal
          title="Wijzig adviseur"
          body={<>
            <PlatformFoutenSamenvatting className={classes.platform_errors} />
            <FormFirstFocus>
              <LabeledSelectInput
                key="Dossier-HuidigAdviseur"
                caption="Huidige adviseur"
                name="huidigadviseurId"
                options={[
                  { label: huidigeEigenaarData?.naam || 'eigenaar onbekend', value: huidigeEigenaarData?.adviseurId|| "" }
                ]}
                readOnly
              />
              <LabeledSelectInput
                key="Dossier-NieuwAdviseur"
                caption="Nieuwe adviseur"
                name="doelAdviseurId"
                options={doelAdviseurMedewerkers}
              />
            </FormFirstFocus>
          </>}
          onSubmitClick={async () => {
            await wijzigAdviseurPOST(
              settings,
              user,
              values,
              params.vestiging,
              params.adviesdossier,
              setFieldValue
            );

            closeModal && closeModal();
          }}
          onCancelClick={closeModal}
          saveButtonTekst="Bevestigen"
        />
      } 
  />);
}

export default WijzigAdviseurModal;
