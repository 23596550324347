import { Card, CardWrapper, ErrorPage, PageLoading } from "adviesbox-shared";
import { connect, FormikContextType } from "formik";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import { AppDataContext } from "../../navigation/appdata-context";
import {
  SoortDepotOptions,
  KapitaalopbouwOptions,
  SoortVermogenProductOptions
} from "../../.generated/forms/formstypes";
import { partijOnafhankelijk } from "../../producten-overzicht/infra/product-constanten";
import { isKenmerkError } from "../../producten-overzicht/infra/product-kenmerken-types";
import { ProductkenmerkenContext } from "../../producten-overzicht/infra/producten-context";
import { SituatieSoort } from "../../producten-overzicht/infra/producten-overzicht-types";
import Kapitaalopbouw from "../../producten-overzicht/kapitaalopbouw/kapitaalopbouw";
import Product from "../../producten-overzicht/product/product";
import Verpanding from "../../producten-overzicht/verpanding/verpanding";
import Verzekeringnemers from "../../producten-overzicht/verzekering-nemers/verzekering-nemers";
import { ISWSideEffects } from "../../shared/components/isw-side-effects/isw-side-effects";
import Depot from "../depot/depot";
import FiscaleRegeling from "../fiscale-regeling/fiscale-regeling";
import { determineVermogenDetailsAsyncSideEffects } from "../infra/determine-vermogen-details-async-side-effects";
import { determineVermogenDetailsSideEffects } from "../infra/determine-vermogen-details-side-effects";
import { VermogensType } from "../infra/vermogen-types";
import Inleggegevens from "../inleggegevens/inleggegevens";
import Onttrekkingen from "../onttrekkingen/onttrekkingen";

type VermogenDetailsProps = { situatie: SituatieSoort; selected: number };

const VermogenDetails = (props: { formik: FormikContextType<VermogensType> } & VermogenDetailsProps): ReactElement => {
  const { setSErunning } = useContext(AppDataContext);
  const { situatie, selected, formik } = props;
  const { values } = formik;
  const { producten } = values;
  const selectedProduct = producten && producten[selected];
  const { waardeopbouwBedrag, soortDepot } = selectedProduct.depot;
  const { kapitaalopbouw } = selectedProduct.fiscaleRegeling;
  const verpandAanGeldverstrekkerTonen = soortDepot !== SoortDepotOptions.VrijDepot;

  const kenmerkenContext = useContext(ProductkenmerkenContext);
  const [kenmerken, setKenmerken] = useState<null | ReturnType<typeof kenmerkenContext.getProductKenmerken>>(null);

  useEffect(() => {
    setKenmerken(
      kenmerkenContext.getProductKenmerken("Vermogen", selectedProduct.partijCode, selectedProduct.productCode)
    );
  }, [setKenmerken, kenmerkenContext, selectedProduct.productCode, selectedProduct.partijCode]);

  if (!kenmerken) return <PageLoading />;

  if (kenmerken && isKenmerkError(kenmerken)) {
    return <ErrorPage error={new Error(`Fout bij het ophalen van producteigenschappen: ${kenmerken.reden}`)} />;
  }

  return (
    <CardWrapper className="px-3" maxRowCount={7}>
      <ISWSideEffects<VermogensType>
        sync={determineVermogenDetailsSideEffects(selected)}
        async={determineVermogenDetailsAsyncSideEffects({ selected })}
        asyncStartStopCallback={setSErunning}
      />

      <Card
        title={
          situatie === "voorstel"
            ? `${selectedProduct.product.doorlopend ? "Doorlopend" : "Nieuw"} product`
            : "Huidig product"
        }
      >
        <Product
          selected={selected}
          situatie={situatie}
          kenmerken={kenmerken.product}
          productSoort="vermogen"
          isStarterslening={false}
        />
      </Card>

      {selectedProduct.soortProduct !== SoortVermogenProductOptions.Betaalrekening && (
        <Card title="Fiscale regeling">
          <FiscaleRegeling selected={selected} kenmerken={kenmerken.fiscaleRegeling} />
        </Card>
      )}

      <Card title="Contractant">
        <Verzekeringnemers selected={selected} kenmerken={kenmerken.verzekeringnemer} caption="Contractant" />
      </Card>

      <Card
        title={
          selectedProduct.soortProduct === SoortVermogenProductOptions.Betaalrekening
            ? "Productwaarde"
            : selectedProduct.partijCode === partijOnafhankelijk
            ? "Depot"
            : "Poliswaarde"
        }
      >
        <Depot situatie={situatie} selected={selected} kenmerken={kenmerken.depot} />
      </Card>

      <Card title="Kapitaalopbouw">
        <Kapitaalopbouw gebruik="vermogen" selected={selected} kenmerken={kenmerken.kapitaalopbouw} />
      </Card>

      <Card title="Inleggegevens">
        <Inleggegevens selected={selected} kenmerken={kenmerken.premie} berekenKnopText={"Berekenen"} />
      </Card>

      {selectedProduct.soortProduct !== SoortVermogenProductOptions.Effectenlease &&
        (kapitaalopbouw === KapitaalopbouwOptions.Box3 ||
          selectedProduct.soortProduct === SoortVermogenProductOptions.Betaalrekening) && (
          <Card title="Onttrekking(en)">
            <Onttrekkingen selected={selected} kenmerken={kenmerken.onttrekkingen} />
          </Card>
        )}

      {kenmerken.verpanding.verpandingTonen && (
        <Card title="Verpanding">
          <Verpanding
            selected={selected}
            situatie={situatie}
            waardeopbouwBedrag={waardeopbouwBedrag}
            verpandAanGeldverstrekkerTonen={verpandAanGeldverstrekkerTonen}
            kenmerken={kenmerken.verpanding}
            verpandAanPartij={values.geldverstrekkerNaam}
          />
        </Card>
      )}
    </CardWrapper>
  );
};

VermogenDetails.displayName = "VermogenDetails";

export default connect<VermogenDetailsProps, VermogensType>(VermogenDetails);
