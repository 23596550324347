import { QuestionType, CardSpec, TitleType } from "../../infra/klantprofiel-schema";
import { KlantprofielVraagOptions } from "../../../.generated/forms/formstypes";

export const verduurzamingQuestionSpecs: CardSpec[] = [
  {
    sections: [
      {
        title: TitleType.custom,
        customTitle: "Kennisniveau van verduurzaming gerelateerde onderwerpen",
        subtitle: "Geef per onderwerp het kennisniveau aan door het bolletje te slepen.",
        questions: [
          {
            question: KlantprofielVraagOptions.KennisHypothekenVerduurzaming,
            type: QuestionType.slider
          },
          {
            question: KlantprofielVraagOptions.KennisHypothekenEnergiebesparendeMaatregelen,
            type: QuestionType.slider
          },
          {
            question: KlantprofielVraagOptions.KennisHypothekenBesparingen,
            type: QuestionType.slider
          },
          {
            question: KlantprofielVraagOptions.KennisHypothekenFinancieringsmogelijkheden,
            type: QuestionType.slider
          }
        ]
      }
    ]
  },
  {
    questions: [
      {
        question: KlantprofielVraagOptions.InteresseVerduurzamenWoning,
        type: QuestionType.radio
      },
      {
        question: KlantprofielVraagOptions.VoornaamsteRedenVerduurzamenWoning,
        type: QuestionType.radio,
        condition: {
          question: KlantprofielVraagOptions.InteresseVerduurzamenWoning,
          answer: 50501
        }
      },
      {
        question: KlantprofielVraagOptions.FinancierenVerduurzamenWoning,
        type: QuestionType.checkbox,
        condition: {
          question: KlantprofielVraagOptions.InteresseVerduurzamenWoning,
          answer: 50501
        }
      },
      {
        title: TitleType.custom,
        customTitle: "Geef in de toelichting aan waarom u geen interesse heeft in de verduurzaming van de woning.",
        question: "unbound",
        type: QuestionType.instructionText,
        condition: [
          {
            question: KlantprofielVraagOptions.InteresseVerduurzamenWoning,
            answer: 50502
          }
        ]
      }
    ]
  }
];
