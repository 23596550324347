import { EwsResultaat } from "../../.generated/forms/formstypes";
import { EigenwoningschuldModalCompleet, EigenwoningschuldModalType } from "./financieringsbehoefte-types";

const isEigenwoningschuldModalCompleet = (value: any): value is EigenwoningschuldModalCompleet => {
  return typeof value.aftrekbareAdvieskosten === "number";
};

export function mapDraftEwsResultaatDlToUi(
  draft: EigenwoningschuldModalType | EigenwoningschuldModalCompleet,
  resultaat: EwsResultaat
): void {
  draft.financieringsKosten = resultaat.financieringskostenBedrag || 0;
  draft.maximaalTeFinancierenBox1 = resultaat.maximaalTeFinancierenBox1Bedrag || 0;
  if (draft.financieringskostenAftrekbaarOvernemen) {
    draft.financieringsKostenPercentage = resultaat.financieringskostenAftrekbaarPercentage || 0;
  }

  if (isEigenwoningschuldModalCompleet(draft)) {
    draft.aftrekbareAdvieskosten = resultaat.advieskostenAftrekbaarBedrag || 0;
  }
}
