import {
  BerekenCurrencyInput,
  LabeledCurrencyInput,
  LabeledPercentageInput,
  LabeledRadioInput
} from "adviesbox-shared";
import { connect, FormikContextType } from "formik";
import React, { ReactElement } from "react";
import { SelectedProps } from "../../shared/types";
import { enum2options } from "../../shared/utils/enum-to-options";
import { getNaam } from "../../shared/utils/helpers";
import { Bewoners, GebruikPand, woningInEigendomSchema, WoonsituatieListState } from "../infra/woonsituatie-schema";

const WoningInEigendom = ({
  selected,
  formik: {
    values: { woonsituaties, persoonsgegevens }
  },
  formik
}: SelectedProps & {
  formik: FormikContextType<WoonsituatieListState>;
}): ReactElement => {
  const selectedWoonsituatie = woonsituaties ? woonsituaties[selected] : null;
  if (!selectedWoonsituatie) return <></>;

  const hasTweedeAanvrager = persoonsgegevens.naamPartner && persoonsgegevens.naamPartner.klantId;

  const createWozLink = (): string => {
    const getPostcodeCijfers = (postcode: string): string => postcode.slice(0, 4);
    const getPostcodeLetters = (postcode: string): string => postcode.slice(5, 7);
    if (selectedWoonsituatie.huidigPand.adres.postcode && selectedWoonsituatie.huidigPand.adres.huisnummer) {
      return `https://www.wozwaardeloket.nl/index.jsp?locatie=${getPostcodeCijfers(
        selectedWoonsituatie.huidigPand.adres.postcode
      )}%20${getPostcodeLetters(selectedWoonsituatie.huidigPand.adres.postcode)}%20${selectedWoonsituatie.huidigPand.adres.huisnummer
        }`;
    }
    if (
      selectedWoonsituatie.huidigeWoonsituatie.bewoners === Bewoners.Aanvrager2 &&
      persoonsgegevens.adresPartner.postcode &&
      persoonsgegevens.adresPartner.huisnummer
    ) {
      return `https://www.wozwaardeloket.nl/index.jsp?locatie=${getPostcodeCijfers(
        persoonsgegevens.adresPartner.postcode
      )}%20${getPostcodeLetters(persoonsgegevens.adresPartner.postcode)}%20${persoonsgegevens.adresPartner.huisnummer}`;
    }
    if (
      (selectedWoonsituatie.huidigeWoonsituatie.bewoners === Bewoners.Aanvrager1 ||
        selectedWoonsituatie.huidigeWoonsituatie.bewoners === Bewoners.Beiden) &&
      persoonsgegevens.adresAanvrager.postcode &&
      persoonsgegevens.adresAanvrager.huisnummer
    ) {
      return `https://www.wozwaardeloket.nl/index.jsp?locatie=${getPostcodeCijfers(
        persoonsgegevens.adresAanvrager.postcode
      )}%20${getPostcodeLetters(persoonsgegevens.adresAanvrager.postcode)}%20${persoonsgegevens.adresAanvrager.huisnummer
        }`;
    }
    return woningInEigendomSchema.default().wozWaardeloket;
  };

  return (
    <>
      <LabeledRadioInput
        caption="Gebruik woning"
        name={`woonsituaties[${selected}].woningInEigendom.gebruikWoning`}
        options={enum2options(GebruikPand)}
      />

      <LabeledCurrencyInput
        caption="Marktwaarde"
        name={`woonsituaties[${selected}].woningInEigendom.marktwaarde`}
        onBlur={(): void => {
          if (!selectedWoonsituatie.woningInEigendom.wozWaarde) {
            formik.setFieldValue(
              `woonsituaties[${selected}].woningInEigendom.wozWaarde`,
              selectedWoonsituatie.woningInEigendom.marktwaarde
            );
          }
        }}
      />

      <LabeledCurrencyInput caption="WOZ-waarde" name={`woonsituaties[${selected}].woningInEigendom.wozWaarde`} />

      {selectedWoonsituatie.woningInEigendom.gebruikWoning === GebruikPand.PrimaireWoning && (
        <BerekenCurrencyInput
          caption="Eigenwoningforfait"
          name={`woonsituaties[${selected}].woningInEigendom.eigenwoningforfait`}
        />
      )}

      <div className="form-group form-row pl-3 pt-2 pb-2">
        <div className="col-md-6 col-12">
          <a
            href={createWozLink()}
            className="link"
            target="_blank"
            rel="noopener noreferrer"
            id={`woonsituaties[${selected}].woningInEigendom.wozWaardeloket-link`}
          >
            <span>WOZ-waardeloket</span> <i className="icon externallink" />
          </a>
        </div>
      </div>

      <h2 className="ml-3">Eigendomsverhouding</h2>

      <LabeledPercentageInput
        caption={`Percentage eigendom ${getNaam(persoonsgegevens.naamAanvrager, "Aanvrager")}`}
        name={`woonsituaties[${selected}].woningInEigendom.percentageAanvrager`}
        decimalen={2}
      />

      {hasTweedeAanvrager && (
        <LabeledPercentageInput
          caption={`Percentage eigendom ${getNaam(persoonsgegevens.naamPartner, "Partner")}`}
          name={`woonsituaties[${selected}].woningInEigendom.percentagePartner`}
          decimalen={2}
        />
      )}
    </>
  );
};

export default connect<SelectedProps, WoonsituatieListState>(WoningInEigendom);
