import {
  createSpanWithId,
  DataGrid,
  LabeledBevestigingInput,
  LabeledCurrencyInput,
  LabeledDateInput,
  LabeledRadioInput,
  LabeledSelectInput,
  LabeledTextInput
} from "adviesbox-shared";
import React, { ReactElement, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Column } from "react-table-6";
import * as yup from "yup";
import { Algemeen } from "../../.generated/tarieven/tarieventypes";
import { Debug } from "../../shared/components/formik/Debug";
import { ISWSideEffects } from "../../shared/components/isw-side-effects/isw-side-effects";
import AdviesBoxFormik from "../../shared/utils/adviesbox-formik";
import { enum2options } from "../../shared/utils/enum-to-options";
import { getPersonaliaTextResources } from "../infra/personalia-resources";
import { AantalKeer, kindSchema, KindType, SchoolType } from "../infra/personalia-schema";
import { determineKindSideEffects } from "./determine-kind-side-effects";

const kinderenColumns: Column[] = [
  {
    Header: "Naam",
    id: "Naam",
    Cell: (c): ReactElement => createSpanWithId(c.index, 0, c.original.naam, c.original.naam)
  },
  {
    Cell: "DeleteButton"
  }
];

type KinderenModalType = { kinderen: KindType[] };

export type KinderenModalData = {
  data: KindType[];
  tarieven: Algemeen;
};

type KinderenModalProps = {
  onSave?: (data: KindType[]) => void;
  closeModal?: () => void;
  medeaanvrager: boolean;
};

const KinderenModal = ({
  data,
  tarieven,
  onSave,
  closeModal,
  medeaanvrager
}: KinderenModalData & KinderenModalProps): ReactElement => {
  const selectedState = useState(0);
  const [selected] = selectedState;
  return (
    <AdviesBoxFormik<KinderenModalType>
      initialValues={{
        kinderen: data
      }}
      validationSchema={yup.object({
        kinderen: yup.array(kindSchema)
      })}
      onSave={(values): void => onSave && onSave(values.kinderen)}
      closeModal={closeModal}
      render={({ values: { kinderen }, submitForm }): ReactElement => {
        return (
          <>
            <ISWSideEffects<{ kinderen: KindType[] }>
              runOnFirstRender
              sync={determineKindSideEffects({ selected, tarieven })}
            />
            <Modal.Header closeButton>
              <Modal.Title>Kinderen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <DataGrid
                masterDetail
                columns={kinderenColumns}
                name="kinderen"
                rowSelected={selectedState}
                validationSchema={kindSchema}
                rowCaption="kind"
                getNewRowValues={(): KindType => ({ ...kindSchema.default() })}
              />

              {kinderen.length > 0 && (
                <>
                  <LabeledTextInput caption="Naam" name={`kinderen[${selected}].naam`} />
                  <LabeledDateInput
                    caption="Geboortedatum"
                    name={`kinderen[${selected}].geboortedatum`}
                    tooltip={getPersonaliaTextResources("personaliaKindGeboorteDatumTooltip")}
                  />
                  { medeaanvrager && (
                    <LabeledBevestigingInput caption="Gezamenlijk kind" name={`kinderen[${selected}].gezamenlijkKind`} />
                  )}
                  <LabeledBevestigingInput caption="Inwonend" name={`kinderen[${selected}].inwonend`} />
                  <LabeledSelectInput
                    caption="Schooltype"
                    name={`kinderen[${selected}].schooltype`}
                    options={enum2options(SchoolType)}
                  />
                  <LabeledCurrencyInput
                    caption="Kinderbijslag per kwartaal"
                    name={`kinderen[${selected}].kinderbijslagPerKwartaal`}
                    readonly={true}
                  />
                  <LabeledRadioInput
                    caption="Aantal keer"
                    name={`kinderen[${selected}].aantalKeer`}
                    tooltip={getPersonaliaTextResources("personaliaKindAantalKeerTooltip")}
                    options={enum2options(AantalKeer)}
                  />
                  <LabeledBevestigingInput caption="Co-ouderschap" name={`kinderen[${selected}].coOuderschap`} />
                  <LabeledCurrencyInput
                    caption="Kinderbijslag per jaar"
                    name={`kinderen[${selected}].kinderbijslagPerJaar`}
                    readonly={true}
                  />
                </>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button data-testid="btnannuleren" id="btnannuleren" variant="link" onClick={closeModal}>
                Annuleren
              </Button>
              <Button data-testid="btnopslaan" id="btnopslaan" variant="primary" onClick={submitForm}>
                Bevestigen
              </Button>
            </Modal.Footer>
            <Debug />
          </>
        );
      }}
    />
  );
};

export default KinderenModal;
