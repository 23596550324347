import {
  HypotheekvergelijkerInput,
  HypotheekvergelijkerOutput,
  HypotheekvergelijkerQueueResult
} from "../../../.generated/hypotheek-vergelijker/hypotheek-vergelijkertypes";

import { OrganisatieEx } from "../../../.generated/licenties/licentiestypes";
import { SettingsType } from "adviesbox-shared";
import { User } from "oidc-client";
import { hasJSONResponse } from "../../../shared/utils/ajax";

export const initHypotheekVergelijkerQueue = async (
  user: User,
  settings: SettingsType,
  vestigingId: string,
  voorstelId: string,
  body: HypotheekvergelijkerInput,
  signal: AbortSignal
): Promise<HypotheekvergelijkerQueueResult | string | null> => {
  const url = `${settings.hypotheekvergelijkerOrigin}/Voorstellen/${voorstelId}/Hypotheekvergelijker/queue`;

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Ocp-Apim-Subscription-Key": `${settings.OcpApimSubscriptionKey}`,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      authorization: `${user!.token_type} ${user!.access_token}`,
      vestigingId,
      "Content-Type": "application/json;charset=UTF-8"
    },
    body: JSON.stringify(body),
    signal
  });

  if (response.ok && response.status === 204) {
    return "Geen data beschikbaar.";
  }

  if (!(response.ok && hasJSONResponse(response))) {
    const errorJson = await response.json();
    const firstErrorMessage = errorJson.validationResults.find(() => true).message;
    return firstErrorMessage;
  }

  const jsonResponse = (await response.json()) as HypotheekvergelijkerQueueResult;
  if (!jsonResponse.isValid) throw Error(`Fout bij aanroep van web-api '${url}'. Input is niet valid.`);

  return jsonResponse ?? null;
};

export const getHypotheekVergelijkerQueue = async (
  user: User,
  settings: SettingsType,
  vestigingId: string,
  queueId: string,
  signal: AbortSignal
): Promise<HypotheekvergelijkerOutput | null> => {
  const url = `${settings.hypotheekvergelijkerOrigin}/Hypotheekvergelijker/Hypotheekvergelijker/${queueId}`;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Ocp-Apim-Subscription-Key": `${settings.OcpApimSubscriptionKey}`,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      authorization: `${user!.token_type} ${user!.access_token}`,
      vestigingId,
      "Content-Type": "application/json;charset=UTF-8"
    },
    signal
  });

  if (!(response.ok && hasJSONResponse(response)))
    throw Error(`Fout bij aanroep van web-api '${url}'. Resultaat bevat geen JSON.`);

  const jsonResponse = (await response.json()) as HypotheekvergelijkerOutput;

  if (!jsonResponse.isValid) throw Error(`Fout bij aanroep van web-api '${url}'. Input is niet valid.`);

  return jsonResponse ?? null;
};

export const organisatieDataApi = async (
  settings: SettingsType,
  user: User | null,
  usersOrganisatieId: string | null
): Promise<OrganisatieEx> => {
  const url = `${settings?.licentiesOrigin}/Organisaties/${usersOrganisatieId}`;

  if (!usersOrganisatieId) throw Error(`Fout bij aanroep van web-api '${url}'. User bevat geen Organisatie-id.`);
  const rsp = await fetch(url, {
    method: "GET",
    headers: {
      authorization: `${user?.token_type} ${user?.access_token}`,
      "Ocp-Apim-Subscription-Key": settings.OcpApimSubscriptionKey,
      "Content-Type": "application/json;charset=UTF-8"
    }
  });

  if (!(rsp.ok && hasJSONResponse(rsp))) {
    throw Error(`Fout bij aanroep van web-api '${url}'. Resultaat bevat geen JSON.`);
  }

  const json = await rsp.json();

  if (!json.isValid) {
    throw Error(`Fout bij aanroep van web-api '${url}'. Input is niet valid.`);
  }

  return json.organisaties[usersOrganisatieId];
};
