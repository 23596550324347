import { CurrencyInput, LabeledCurrencyInput } from "adviesbox-shared";
import classNames from "classnames";
import { useFormikContext } from "formik";
import React, { ReactElement } from "react";
import { getKlantprofielResources } from "../../klantprofiel-resources";
import { QuestionSpecCombined, QuestionType } from "../../klantprofiel-schema";

type QuestionBedragProps = {
  data: QuestionSpecCombined;
};
export const QuestionBedrag = ({ data }: QuestionBedragProps): ReactElement => {
  const { setFieldValue } = useFormikContext();

  if (data.type !== QuestionType.bedrag) {
    return <>{`invalid QuestionType: ${data.type}`}</>;
  }
  const label = data?.schema?.antwoorden[0]?.omschrijving?.replace("<bedrag>", "").trim();

  if (label && label.length > 0) {
    return (
      <>
        <div className={classNames({ "mt-3": data.schema.omschrijving })}>
          {data.schema.omschrijving && <h2>{data.schema.omschrijving}</h2>}
          <span className="d-block font-italic px-1">{getKlantprofielResources("AlleenCijfers")}</span>
          <LabeledCurrencyInput
            caption={label}
            data-testid={`labeled-currency-${data.schema.code}-${data.index}`}
            name={`vragen[${data.index}].antwoorden[0].waarde1`}
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
              if (!e.target.value) {
                return setFieldValue(`vragen[${data.index}].antwoorden[0].waarde1`, null);
              }
            }}
            key={`key-${data.index}`}
          />
        </div>
      </>
    );
  }

  return (
    <>
      <div className={classNames({ "mt-3": data.schema.omschrijving })}>
        {data.schema.omschrijving && <h2>{data.schema.omschrijving}</h2>}
        <span className="d-block font-italic px-1">{getKlantprofielResources("EnkelBedrag")}</span>
        <CurrencyInput
          name={`vragen[${data.index}].antwoorden[0].waarde1`}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
            if (!e.target.value) {
              return setFieldValue(`vragen[${data.index}].antwoorden[0].waarde1`, null);
            }
          }}
          data-testid={`currency-${data.schema.code}-${data.index}`}
          fieldSize="xl"
          key={`key-${data.index}`}
        />
      </div>
    </>
  );
};
