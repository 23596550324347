import { voorwaardenSelectieSchema } from "../infra/hypotheek-schema";
import { HypothekenState } from "../infra/hypotheek-types";
import { HypotheekVergelijkenSchemaContextType } from "./hypotheek-vergelijken-modal";
import { hypotheekVergelijkenSchema } from "./hypotheek-vergelijken-schema";
import { HypotheekVergelijkenModalType } from "./hypotheek-vergelijken-types";

export const newHypotheekVergelijkenModalFactory = (hypotheek: HypothekenState): HypotheekVergelijkenModalType => {
  return {
    ...hypotheekVergelijkenSchema.default(),
    aanvangsDatum: hypotheek.aanvangsdatum,
    hypotheekSamenstelling: hypotheek.producten,
    panden: hypotheek.panden,
    modalOpen: true,
    fiscaleVoortzettingKeuzes: hypotheek.fiscaleVoortzettingKeuzes,
    energieKlasse: hypotheek.energieKlasse,
    meerwerkEBV: hypotheek.meerwerkEBV,
    soortFinanciering: hypotheek.soortFinanciering,
    aanvrager1: hypotheek.aanvrager1,
    aanvrager2: hypotheek.aanvrager2,
    voorwaardeSelectie: {
      ...voorwaardenSelectieSchema.default(),
      verklaringInkomen: hypotheek.voorwaardenSelectie?.verklaringInkomen ?? null
    },
    nhg: hypotheek.nhg,
    eigenwoningschuldBedrag: hypotheek.eigenwoningschuldBedrag,
    gewensteHypotheekBedrag: hypotheek.gewensteHypotheek
  };
};

export const checkTotalen = (values: HypotheekVergelijkenModalType): HypotheekVergelijkenSchemaContextType => {
  const result: HypotheekVergelijkenSchemaContextType = {
    leningdeelHoofdsomTotaalError: false
  };
  values.panden.forEach(pand => {
    const producten = values.hypotheekSamenstelling;

    if (
      pand.totaleHypotheekBedrag &&
      producten
        .filter(e => !e.product.doorlopend)
        .map(e => e.leningdeelgegevens.leningdeelHoofdsom.bedrag || 0)
        .reduce((a, b) => a + b) > pand.totaleHypotheekBedrag
    ) {
      result.leningdeelHoofdsomTotaalError = true;
    }
  });
  return result;
};
