import { createGetTextResources } from "../../shared/utils/text-resources-algemeen";

export const getWoonsituatieTextResources = createGetTextResources({
  eigenwoningreserveEwrVerleden:
    "De eigen woningreserve is het bedrag waarvoor bij aankoop van een nieuwe eigen woning geen eigenwoningschuld kan worden aangegaan. Hier wordt het bedrag bedoeld vóór toevoeging van het vervreemdingssaldo van de huidige eigen woning; het vervreemdingssaldo wordt berekend op het tabblad Financiering.",
  eigenwoningreserveEwsOvergangsrecht:
    "Dit is de hoogte van de eigenwoningschuld (EWS) die op 31-12-2012 aanwezig was en onder het overgangsrecht valt. Voor deze eigenwoningschuld geldt geen annuïtaire of lineaire aflossingsverplichting.",
  eigenwoningreserveEwsAflossing:
    "Dit is de hoogte van de eigenwoningschuld (EWS) die na 31-12-2012 is ontstaan. Voor deze eigenwoningschuld geldt een annuïtaire of lineaire aflossingsverplichting. Vul het eindsaldo van de eigenwoningschuld in.",
  modalEwsTekst:
    "Wanneer er eerder een eigenwoningschuld is geweest heeft dit invloed op de renteaftrek van de toekomstige hypotheek. In onderstaande tabel kan eerder genoten renteaftrek aangegeven worden.",
  hypotheekOorspronkelijk:
    "Verplichte invoer wanneer een hypotheek gekoppeld is aan deze woning. Indien de som van de eigendomsverhoudingen niet 100% is dient u hier slechts het gedeelte van de huidige hypotheek in te vullen dat bij deze eigendomsverhoudingen hoort.",
  TooltipEnergieLabel: `Voor een woning met energielabel E, F of G mag maximaal € 20.000 extra geleend worden. Bij een woning met energielabel C of D mag maximaal € 15.000 extra geleend worden. Bij alle andere energielabel of als er geen energielabel beschikbaar of mogelijk is, mag maximaal € 10.000 extra geleend worden.`,
  ValidatieEnergielabel: "Selecteer een energielabel"
});
