import React, { ReactElement, useContext, useEffect, useState } from "react";
import { connect, FormikContextType } from "formik";

import { Financieringsoort, GebruikPandSoort } from "../../.generated/forms/formstypes";
import { LabeledCurrencyInput, LabeledPercentageInput } from "adviesbox-shared";
import { FinancieringsbehoefteState } from "../infra/financieringsbehoefte-types";
import { AppDataContext } from "../../navigation/appdata-context";
import { getNaam } from "../../shared/utils/helpers";

type OnderpandProps = {
  readonly?: boolean;
};
const Onderpand = ({
  readonly = false,
  formik
}: OnderpandProps & {
  formik: FormikContextType<FinancieringsbehoefteState>;
}): ReactElement => {
  const { menuInfo } = useContext(AppDataContext);
  const [aanvrager1, setAanvrager1] = useState<string>("Aanvrager 1");
  const [aanvrager2, setAanvrager2] = useState<string | null>("Aanvrager 2");
  const soortFinanciering = formik.values.financiering.soortFinanciering;
  const gebruik = formik.values.onderpand.gebruik;
  const isNieuwbouw = soortFinanciering === Financieringsoort.AankoopNieuwbouw;
  const isAankoopNieuwbouwOrAankoopBestaandebouw =
    soortFinanciering === Financieringsoort.AankoopNieuwbouw ||
    soortFinanciering === Financieringsoort.AankoopBestaandeBouw;
  const showEigenWoningForfait =
    isAankoopNieuwbouwOrAankoopBestaandebouw && gebruik === GebruikPandSoort.PrimaireWoning;
  const showMarktwaardeNaVerbouwing =
    formik.values.financiering.soortFinanciering !== Financieringsoort.AankoopNieuwbouw;
  const waardeLoketUrl = formik.values.onderpand.wozWaardeloket;
  const heeftPartner = formik.values.onderpand.heeftPartner;
  const primaireWoning = formik.values.onderpand.gebruik === GebruikPandSoort.PrimaireWoning;

  useEffect(() => {
    if (menuInfo?.aanvrager1) {
      setAanvrager1(getNaam(menuInfo.aanvrager1, "Aanvrager 1"));
    }
    if (menuInfo?.aanvrager2) {
      setAanvrager2(getNaam(menuInfo.aanvrager2, "Aanvrager 2"));
    } else {
      setAanvrager2(null);
    }
  }, [menuInfo]);

  return (
    <>
      <LabeledCurrencyInput
        caption={isNieuwbouw ? "Marktwaarde" : "Marktwaarde voor verbouwing"}
        name="onderpand.marktwaardeVoorVerbouwing"
        readonly={readonly || !isAankoopNieuwbouwOrAankoopBestaandebouw}
        onBlur={(): void => {
          if (!formik.values.onderpand.wozWaarde) {
            formik.setFieldValue("onderpand.wozWaarde", formik.values.onderpand.marktwaardeVoorVerbouwing);
          }
        }}
      />
      {showMarktwaardeNaVerbouwing && (
        <LabeledCurrencyInput
          caption="Marktwaarde na verbouwing"
          name="onderpand.marktwaardeNaVerbouwing"
          readonly={readonly}
        />
      )}
      <LabeledCurrencyInput
        caption="WOZ-waarde"
        name="onderpand.wozWaarde"
        readonly={readonly || !isAankoopNieuwbouwOrAankoopBestaandebouw}
      />
      {primaireWoning && (
        <LabeledCurrencyInput
          caption="Eigenwoningforfait"
          name="onderpand.eigenwoningforfait"
          readonly={readonly || !showEigenWoningForfait}
        />
      )}
      <div className="form-group form-row ml-3 mt-2">
        <div className="col-md-6 col-12">
          <a href={waardeLoketUrl} className="link" target="_blank" rel="noopener noreferrer">
            <span>WOZ-waardeloket</span> <i className="icon externallink" />
          </a>
        </div>
      </div>

      <h2 className="ml-3">Eigendomsverhouding</h2>
      <LabeledPercentageInput
        caption={aanvrager1}
        name="onderpand.eigendomsverhoudingAanvrager"
        decimalen={2}
        readonly={readonly || !heeftPartner}
      />

      {aanvrager2 !== null && (
        <LabeledPercentageInput
          caption={aanvrager2}
          name="onderpand.eigendomsverhoudingPartner"
          decimalen={2}
          readonly={readonly || !heeftPartner}
        />
      )}
    </>
  );
};

export default connect<OnderpandProps, FinancieringsbehoefteState>(Onderpand);
