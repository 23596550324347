import { LocalDate } from "@js-joda/core";
import { GebruikPandSoort, AflossingsVormType } from "../../.generated/forms/formstypes";
import {
  HypothekenKenmerken,
  isKenmerkError,
  KenmerkenError
} from "../../producten-overzicht/infra/product-kenmerken-types";
import { productDraftSideEffects } from "../../producten-overzicht/product/product-side-effects";
import { createISWSideEffect, initISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";
import { addMonths, addYears } from "../../shared/utils/dates";
import { fiscaleGegevensSideEffects } from "./determine-hypotheek-fiscale-gegevens-side-effects";
import { kapitaalopbouwSideEffects } from "./determine-hypotheek-kapitaalopbouw-side-effects";
import { hypotheekOptiesIngPriceToolLeningdeelSchema, hypotheekOptiesIngPriceToolSchema } from "./hypotheek-schema";
import { isVoorstel } from "./hypotheek-typeguards";
import { FiscaleVoortzettingKeuzeType, HypotheekType, HypothekenState, PandType } from "./hypotheek-types";
import { hasValue } from "adviesbox-shared";
import { SoortOpnamesUitkering } from "../opnames/opnames-types";
import { HasChanged } from "../../shared/components/isw-side-effects/has-changed-helper";
import { Draft } from "immer";
import { SituatieSoort } from "../../producten-overzicht/infra/producten-overzicht-types";

export type Context = {
  selected: number;
  kenmerken: HypothekenKenmerken | null | KenmerkenError;
  nhg: boolean | null;
  situatie: SituatieSoort;
};

export type FiscaleGegevensContextType = {
  eigenWoningSchuld: number | null;
  fiscaleVoortzettingKeuzes: FiscaleVoortzettingKeuzeType[] | null;
  situatie: SituatieSoort;
  panden: PandType[];
};

const berekenLooptijd = (jaren: number, maanden: number, maximaleLooptijdMaandenKenmerk: number): number => {
  return Math.min(jaren * 12 + maanden, maximaleLooptijdMaandenKenmerk);
};

// vult, onder voorwaarden, het rentepercentage.bedrag met het berekende bedrag
export const leningdeelgegevensRentepercentageBedragVullen = (
  has: HasChanged<HypotheekType>,
  draft: Draft<HypotheekType>
): void => {
  if (
    !has.leningdeelgegevens.rentePercentage.berekendBedrag.changed &&
    !has.leningdeelgegevens.rentePercentage.berekenen.changed
  )
    return;
  if (!draft.leningdeelgegevens.rentePercentage?.berekenen) return;
  if (draft.leningdeelgegevens.rentePercentage.bedrag === draft.leningdeelgegevens.rentePercentage.berekendBedrag)
    return;
  draft.leningdeelgegevens.rentePercentage = {
    ...draft.leningdeelgegevens.rentePercentage,
    bedrag: draft.leningdeelgegevens.rentePercentage.berekendBedrag
  };
  has.leningdeelgegevens.rentePercentage.bedrag.changed = true;
};

// vult het rentescenario als het rentepercentage.bedrag wijzigt
export const leningdeelgegevensRenteScenarioModelVullen = (
  has: HasChanged<HypotheekType>,
  draft: Draft<HypotheekType>
): void => {
  if (!has.leningdeelgegevens.rentePercentage.bedrag.changed) return;
  draft.leningdeelgegevens.renteScenarioModal.renteScenario.forEach(x => {
    x.percentage = draft.leningdeelgegevens.rentePercentage?.bedrag ?? null;
  });
};

const leningdeelGegevensSideEffects = createISWSideEffect<HypotheekType>(({ has, draft }): void => {
  if (!draft) return;
  leningdeelgegevensRentepercentageBedragVullen(has, draft);
  leningdeelgegevensRenteScenarioModelVullen(has, draft);
  if (has.leningdeelgegevens.oorspronkelijkeHoofdsom.changed) {
    //TODO: minimum van Oorspronkelijke hypotheek en Oorspronkelijke hoofdsom => Oorspronkelijke hypotheek niet beschikbaar
    draft.leningdeelgegevens.leningdeelHoofdsom.berekendBedrag = draft.leningdeelgegevens.oorspronkelijkeHoofdsom;
    // Gehanteerde marktwaarde vullen in automatisch rentedaling modal
    draft.leningdeelgegevens.automatischeRentedalingModal.marktwaardeRenteBedrag =
      draft.leningdeelgegevens.leningdeelHoofdsom.bedrag;
  }
  // Berekenen datum opgave als ingangsdatum of berekenen hoofdsom wijzigt
  if (has.product.ingangsdatum.changed || has.leningdeelgegevens.leningdeelHoofdsom.berekenen.changed) {
    draft.leningdeelgegevens.datumOpgave = draft.leningdeelgegevens.leningdeelHoofdsom.berekenen
      ? draft.product.ingangsdatum || null
      : LocalDate.now().withDayOfMonth(1);
  }
  // Berekenen einddatum a.d.h.v. ingangsdatum, rentevastperiode en rentebedenktij
  if (
    has.leningdeelgegevens.rentevastPeriodeJaar.changed ||
    has.leningdeelgegevens.renteBedenktijdJaar.changed ||
    has.product.ingangsdatum.changed
  ) {
    draft.leningdeelgegevens.rentevastperiodeKeuze = draft.leningdeelgegevens.rentevastPeriodeJaar;
    draft.leningdeelgegevens.einddatum = draft.product.ingangsdatum
      ? addMonths(
          draft.product.ingangsdatum,
          (Number(draft.leningdeelgegevens.rentevastPeriodeJaar) * 12 || 0) +
            (Number(draft.leningdeelgegevens.renteBedenktijdJaar) * 12 || 0)
        )
      : null;
  }
  // DatumOpgave mag niet voor de ingangsdatum liggen
  if (
    has.leningdeelgegevens.datumOpgave.changed &&
    draft.leningdeelgegevens.datumOpgave &&
    draft.product.ingangsdatum &&
    draft.leningdeelgegevens.datumOpgave < draft.product.ingangsdatum
  ) {
    draft.leningdeelgegevens.datumOpgave = draft.product.ingangsdatum;
  }

  // Bij een starterslening zijn de eerste 3 jaren van het rentescenario leeg
  if (has.leningdeelgegevens.rentePercentage.changed) {
    draft.leningdeelgegevens.renteScenarioModal.renteScenario.forEach((x, index) => {
      x.percentage =
        draft.hypotheekVorm.isStartersLening && index < 3
          ? null
          : draft.leningdeelgegevens.rentePercentage?.bedrag ?? null;
    });
  } else if (
    has.leningdeelgegevens.renteScenarioModal.renteScenario[0].percentage.changed &&
    draft.leningdeelgegevens.rentePercentage
  ) {
    draft.leningdeelgegevens.rentePercentage.bedrag =
      draft.leningdeelgegevens.renteScenarioModal.renteScenario[0].percentage;
  }

  if (draft.premieGegevens) {
    // Side effects voor premiegegevens
    if (has.premieGegevens.hoogLaagLooptijd.changed) {
      /* istanbul ignore else */
      if (
        !draft.premieGegevens.hoogLaagLooptijd ||
        (!draft.premieGegevens.hoogLaagLooptijd?.jaren && !draft.premieGegevens.hoogLaagLooptijd?.maanden)
      ) {
        draft.premieGegevens.hoogLaagVerhouding = null;
        draft.premieGegevens.hoogLaagEinddatum = null;
      } else {
        draft.premieGegevens.hoogLaagEinddatum = draft.product.ingangsdatum
          ? addMonths(
              addYears(draft.product.ingangsdatum, draft.premieGegevens.hoogLaagLooptijd.jaren || 0),
              draft.premieGegevens.hoogLaagLooptijd.maanden || 0
            )
          : null;
      }
    }

    // Op het moment dat de looptijd wordt veranderd, looptijd premiegegevens aanpassen
    if (has.product.looptijd.changed && draft.product) {
      draft.premieGegevens.looptijd = draft.product.looptijd;
    }
  }
  // Rentescenario mag niet gevuld zijn, wanneer automatische rentedaling op 'Ja' staat
  if (has.leningdeelgegevens.automatischeRentedaling.changed && draft.leningdeelgegevens.automatischeRentedaling) {
    draft.leningdeelgegevens.renteScenarioModal.renteScenario = [];
  }

  // Wanneer automatische rentedaling op 'Nee' wordt gezet, alle indexeringen toevoegen en automatische rentedaling percentages legen
  if (has.leningdeelgegevens.automatischeRentedaling.changed && !draft.leningdeelgegevens.automatischeRentedaling) {
    for (let index = 0; index < 30; index++) {
      draft.leningdeelgegevens.renteScenarioModal.renteScenario.push({
        bedrag: null,
        percentage: draft.leningdeelgegevens.rentePercentage?.bedrag ?? null
      });
    }
    draft.leningdeelgegevens.automatischeRentedalingModal.rentedalingPercentages = [];
  }

  // Leeg opslag/afslag wanneer rentescenario op ja of nee wordt gezet
  if (
    has.leningdeelgegevens.automatischeRentedalingModal.renteScenario.changed &&
    !draft.leningdeelgegevens.automatischeRentedalingModal.renteScenario
  ) {
    draft.leningdeelgegevens.automatischeRentedalingModal.opslagAfslagNaRentevastperiode = null;
  }

  // Bij onafhankelijk default waardes instellen automatische rentedaling
  if (
    draft.partijCode === "XX" &&
    has.leningdeelgegevens.automatischeRentedaling.changed &&
    draft.leningdeelgegevens.automatischeRentedaling
  ) {
    draft.leningdeelgegevens.automatischeRentedalingModal.rentedalingPercentages = [
      {
        renteopslagPercentage: 0,
        marktwaardePercentageTotEnMet: 60
      },
      {
        renteopslagPercentage: 0.2,
        marktwaardePercentageTotEnMet: 80
      },
      {
        renteopslagPercentage: 0.4,
        marktwaardePercentageTotEnMet: 90
      },
      {
        renteopslagPercentage: 0.6,
        marktwaardePercentageTotEnMet: 99
      }
    ];
  }
});

const hypotheekOpWoningChangedSideEffects = createISWSideEffect<HypothekenState, Context>((bag): void => {
  const { draft, prev, subset } = bag;
  const prevLeningDelen = prev;
  const eigenWoningSchuld = draft.eigenwoningschuldBedrag;

  const selected = subset.producten[bag.context.selected].createWithContext({ prevLeningDelen, eigenWoningSchuld });
  if (
    prevLeningDelen &&
    selected.draft &&
    selected.draft.hypotheekProductDetails &&
    selected.has.hypotheekProductDetails.hypotheekOpWoning.changed
  ) {
    const pandId = selected.draft.hypotheekProductDetails.hypotheekOpWoning;
    const gelinktePand = prevLeningDelen.panden.find(c => c.pandId === pandId);
    const totaleHypotheekBedrag = gelinktePand ? gelinktePand.totaleHypotheekBedrag : null;
    const gerelateerdeLeningdelen = prevLeningDelen.producten.filter(
      c => c.hypotheekProductDetails && c.hypotheekProductDetails.hypotheekOpWoning === pandId
    );

    const opgeteldeLeningdeelBedrag = gerelateerdeLeningdelen
      .map(p => p.leningdeelgegevens.leningdeelHoofdsom.bedrag || 0)
      .reduce((a, b): number => a + b, 0);

    const oorspronkelijkeHoofdsom =
      totaleHypotheekBedrag && opgeteldeLeningdeelBedrag < totaleHypotheekBedrag
        ? totaleHypotheekBedrag - opgeteldeLeningdeelBedrag
        : null;
    selected.draft.leningdeelgegevens.oorspronkelijkeHoofdsom = oorspronkelijkeHoofdsom;
    selected.draft.leningdeelgegevens.leningdeelHoofdsom = {
      bedrag: oorspronkelijkeHoofdsom,
      berekendBedrag: oorspronkelijkeHoofdsom,
      berekenen: true
    };

    const isTweedeWoning = gelinktePand && gelinktePand.gebruikPand === GebruikPandSoort.TweedeWoning;
    selected.draft.fiscalegegevens.deelBox3Bedrag = isTweedeWoning
      ? selected.draft.leningdeelgegevens.oorspronkelijkeHoofdsom || 0
      : 0;
    selected.draft.fiscalegegevens.deelBox1Bedrag = isTweedeWoning
      ? 0
      : selected.draft.leningdeelgegevens.oorspronkelijkeHoofdsom || 0;
    selected.draft.fiscalegegevens.deelBox1Percentage = isTweedeWoning ? 0 : 100;
    selected.draft.fiscalegegevens.deelBox3Percentage = isTweedeWoning ? 100 : 0;
  }
});

const consumptiefBedragChangedSideEffects = createISWSideEffect<HypothekenState, Context>((bag): void => {
  const { draft, has } = bag;

  if (has.producten.changed) {
    draft.producten.forEach(prod => {
      if (!prod.fiscalegegevens.deelBox3Bedrag && prod.fiscalegegevens.consumptiefBedrag) {
        prod.fiscalegegevens.consumptiefBedrag = null;
      }
    });
  }
});

const hypotheekOptiesIngPriceToolDraftSideEffects = createISWSideEffect<HypothekenState, Context>((bag): void => {
  const { has, draft } = bag;
  // Vullen van gegevens benodigd voor de ING pricing tool
  if (isVoorstel(draft) && has.producten.changed) {
    const isIng = draft.producten.some(x => x.partijCode === "IN");

    draft.producten.forEach(prod => {
      prod.hypotheekOptiesIngPriceToolLeningdeel = isIng
        ? prod.hypotheekOptiesIngPriceToolLeningdeel ?? hypotheekOptiesIngPriceToolLeningdeelSchema.default()
        : null;
    });

    draft.hypotheekOptiesIngPriceTool = isIng
      ? draft.hypotheekOptiesIngPriceTool ?? hypotheekOptiesIngPriceToolSchema.default()
      : null;
  }
});

const bepalenOpnameUitgangspuntenSideEffects = createISWSideEffect<HypotheekType, Context>((bag): void => {
  const { has, draft, context } = bag;

  if (!context.kenmerken || isKenmerkError(context.kenmerken)) return;
  const maximaleLooptijdMaandenKenmerk = context.kenmerken?.leninggegeven.maximaleOpnameDuurMaanden || 1;

  // Als er een maximale looptijd is opgegeven in productkenmerken, dan is dit altijd de maximale looptijd van het product.
  // Anders is de maximale looptijd gelijk aan de opgegeven looptijd van het product of gelijk aan 1, wanneer het een eenmalig product betreft.
  const rekenLooptijd = berekenLooptijd(
    draft.product.looptijd.jaren || 0,
    draft.product.looptijd.maanden || 0,
    maximaleLooptijdMaandenKenmerk
  );

  /* begin resets */
  if (has.opnameSoort.changed) {
    if (draft.fiscalegegevens.bevatOpnames !== hasValue(draft.opnameSoort)) {
      draft.fiscalegegevens.bevatOpnames = hasValue(draft.opnameSoort);
      draft.product.betreftOpname = hasValue(draft.opnameSoort);
    }
  }

  // indien we wijzigen naar een niet opname hypotheek, opnames uitschakelen
  if (has.opnameSoort.changed && !hasValue(draft.opnameSoort)) {
    draft.fiscalegegevens.bevatOpnames = false;
    draft.product.betreftOpname = false;
    draft.opnameBedrag = null;
    draft.opnameMaanden = null;
    return;
  }

  //indien we wijzigen van periodieke naar eenmalige opname, opnameMaanden en bedrag aanpassen
  if (has.opnameSoort.changed && draft.opnameSoort === SoortOpnamesUitkering.Eenmalig && draft.opnameMaanden !== 1) {
    draft.opnameMaanden = 1;
    draft.opnameBedrag = draft.leningdeelgegevens.leningdeelHoofdsom.bedrag;
    return;
  }
  /* einde resets */

  // kenmerk bepaald of er periodieke opnames mogen zijn.
  if (
    draft.opnameSoort !== null &&
    context.kenmerken.leninggegeven.heeftPeriodiekeOpname &&
    draft.opnameSoort === SoortOpnamesUitkering.Eenmalig
  ) {
    draft.opnameSoort = SoortOpnamesUitkering.Periodiek;
  }

  // bepalen maximale looptijd
  if (hasValue(draft.opnameSoort) && (!draft.opnameMaanden || has.product.looptijd.changed)) {
    if (draft.opnameMaanden === null) {
      draft.opnameMaanden = rekenLooptijd;
    }
  }

  // bepalen maximale opnamebedrag en minimale opnamebedrag
  if (
    draft.opnameSoort !== null &&
    (has.leningdeelgegevens.leningdeelHoofdsom.changed || has.opnameMaanden.changed || has.product.looptijd.changed)
  ) {
    if (
      draft.opnameSoort !== null &&
      (!!draft.product.looptijd.jaren || !!draft.product.looptijd.maanden) &&
      !!draft.leningdeelgegevens.leningdeelHoofdsom.bedrag
    ) {
      const opnameMaanden = draft.opnameMaanden || 1;
      const maximaalPeriodeBedragInt = Math.floor(draft.leningdeelgegevens.leningdeelHoofdsom.bedrag / opnameMaanden);

      if (
        draft.opnameSoort === SoortOpnamesUitkering.Eenmalig &&
        draft.opnameBedrag !== draft.leningdeelgegevens.leningdeelHoofdsom.bedrag
      )
        draft.opnameBedrag = draft.leningdeelgegevens.leningdeelHoofdsom.bedrag;

      if (draft.opnameSoort === SoortOpnamesUitkering.Periodiek && draft.opnameBedrag !== maximaalPeriodeBedragInt)
        draft.opnameBedrag = maximaalPeriodeBedragInt;
    }
  }
});

const sanitizeSideEffects = createISWSideEffect<HypothekenState, Context>((bag): void => {
  const { prev, draft, context } = bag;

  // nog geen product geselecteerd, niks doen
  if (!draft.producten.length || !draft.producten[context.selected]) {
    return;
  }

  // er zijn geen leningdelen meer
  if (prev.producten.length && !draft.producten.length) {
    // zorg dat scherm geen hypotheekopties meer bevat.
    draft.hypotheekOptie.hypotheekOpties = [];
    return;
  }

  // leningdeel wordt verwijderd, we zijn klaar
  if (prev.producten.length > draft.producten.length) {
    return;
  }

  // leningdeel wordt toegevoegd; zorg dat producten van andere labels worden verwijderd.
  if (draft.producten.length > prev.producten.length && isVoorstel(draft)) {
    const addedEntry = draft.producten[draft.producten.length - 1];
    if (!addedEntry) {
      throw new Error("invalid change");
    }
    const rulingLable = addedEntry.labelCode;
    draft.producten = draft.producten.filter(
      c => c.labelCode === rulingLable || c.product.doorlopend || c.hypotheekVorm.isStartersLening
    );
    return;
  }

  if (
    draft.producten.length > 0 &&
    draft.producten[draft.producten.length - 1].isNieuw &&
    context.kenmerken &&
    !isKenmerkError(context.kenmerken)
  ) {
    const addedEntry = draft.producten[draft.producten.length - 1];
    addedEntry.leningdeelgegevens.automatischeRentedaling =
      context.kenmerken.leninggegeven.heeftScenarioKorting && !context.nhg;
    addedEntry.isNieuw = false;
    return;
  }
});

const hypotheekLabelChangedSideEffects = createISWSideEffect<HypothekenState, Context>((bag): void => {
  const { has, draft } = bag;
  // Eventueel aanpassen van de hypotheek label gegevens
  if (has.producten.changed && isVoorstel(draft)) {
    const nieuweHypotheek = draft.producten.find(x => !x.product.doorlopend && !x.hypotheekVorm.isStartersLening);
    if (
      nieuweHypotheek &&
      (draft.hypotheeklabel?.partijCode !== nieuweHypotheek?.partijCode ||
        draft.hypotheeklabel?.labelCode !== nieuweHypotheek?.labelCode)
    ) {
      draft.hypotheeklabel = {
        partijCode: nieuweHypotheek.partijCode,
        labelCode: nieuweHypotheek.labelCode,
        labelNaam: nieuweHypotheek.labelNaam
      };
    }
  }
});

const hypotheekDetailDraftSideEffects = createISWSideEffect<HypothekenState, Context>((bag): void => {
  const { draft, prev, subset, context } = bag;
  sanitizeSideEffects(bag);

  if (!subset.producten[context.selected] || !hasValue(context.selected)) return;

  hypotheekOptiesIngPriceToolDraftSideEffects(bag);
  hypotheekOpWoningChangedSideEffects(bag);
  hypotheekLabelChangedSideEffects(bag);
  productDraftSideEffects(subset.producten[context.selected].create().subset.product.create());
  leningdeelGegevensSideEffects(subset.producten[context.selected].create());
  fiscaleGegevensSideEffects(
    subset.producten[context.selected].createWithContext({
      prev,
      eigenWoningSchuld: draft.eigenwoningschuldBedrag,
      fiscaleVoortzettingKeuzes: draft.fiscaleVoortzettingKeuzes,
      situatie: context.situatie,
      panden: draft.panden
    })
  );

  if (draft.producten[context.selected]?.hypotheekVorm.aflossingsvorm === AflossingsVormType.Spaarrekening) {
    kapitaalopbouwSideEffects(subset.producten[context.selected].create());
  }

  // maximum opname periode bepalen
  if (draft.producten[context.selected]?.hypotheekVorm.aflossingsvorm === AflossingsVormType.KredietNoPay) {
    bepalenOpnameUitgangspuntenSideEffects(subset.producten[context.selected].create());
  }
  consumptiefBedragChangedSideEffects(bag);
});

export const determineHypotheekDetailsSideEffects = initISWSideEffect<HypothekenState, Context>(
  hypotheekDetailDraftSideEffects
);
