// istanbul verwijderen als het scherm verder uitgewerkt wordt
/* istanbul ignore file */
import { ReactElement } from "react";
import { SituatieSoort } from "../producten-overzicht/infra/producten-overzicht-types";
import { AdviesBoxColumn, createSpanWithId } from "adviesbox-shared";
import { bedragFormat, optellen } from "../shared/utils/currency";

export const aovColumns = (situatie: SituatieSoort): AdviesBoxColumn[] => [
  {
    Header: "Verzekering",
    id: "Verzekering",
    Cell: (c): ReactElement =>
      createSpanWithId(
        c.index,
        0,
        situatie === "huidig" ? "Bestaand" : c.original.product.doorlopend ? "Doorlopend" : "Nieuw",
        situatie === "huidig" ? "Bestaand" : c.original.product.doorlopend ? "Doorlopend" : "Nieuw"
      )
  },
  {
    Header: "Partij",
    id: "Partij",
    Cell: (c): ReactElement =>
      createSpanWithId(c.index, 1, c.original.product.partijNaam, c.original.product.partijNaam)
  },
  {
    Header: "Soort verzekering",
    id: "Soortverzekering",
    Cell: (c): ReactElement =>
      createSpanWithId(c.index, 2, c.original.product.productNaam, c.original.product.productNaam)
  },
  {
    Header: "Verzekerde(n)",
    id: "Verzekerden",
    Cell: (c): ReactElement =>
      createSpanWithId(c.index, 3, c.original.verzekerden.verzekerden, c.original.verzekerden.verzekerden)
  },
  {
    Header: "Dekking AO",
    id: "DekkingAo",
    Cell: (c): ReactElement => {
      const bedrag = bedragFormat(c.original.dekking.dekkingGegevensVerzekerde1.ao?.verzekerdBedrag);
      return createSpanWithId(c.index, 4, bedrag, bedrag);
    }
  },
  {
    Header: "Dekking WW",
    id: "DekkingWw",
    Cell: (c): ReactElement => {
      const bedrag = bedragFormat(c.original.dekking.dekkingGegevensVerzekerde1.ww?.verzekerdBedrag);
      return createSpanWithId(c.index, 5, bedrag, bedrag);
    }
  },
  {
    Header: "Dekking NBR",
    id: "DekkingNbr",
    Cell: (c): ReactElement => {
      const bedrag = bedragFormat(c.original.dekking.verzekerdKapitaalAanvrager2);
      return createSpanWithId(c.index, 6, bedrag, bedrag);
    }
  },
  {
    Header: "Premie",
    id: "Premie",
    Cell: (c): ReactElement => {
      const totaalbedrag = optellen([
          c.original.premiegegevens.premieAo,
          c.original.premiegegevens.premieWw,
          c.original.premiegegevens.premieTop,
          c.original.premiegegevens.premieAftrekbaarBedrag
        ]);
      const bedrag = bedragFormat(
         totaalbedrag ?? c.original.premiegegevens.premie,
        2
      );
      return createSpanWithId(c.index, 7, bedrag, bedrag);
    }
  },
  {
    Cell: "DeleteButton"
  }
];
