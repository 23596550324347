import { bedragFormat } from "adviesbox-shared";

enum Maand {
    "Januari" = 1,
    "Februari" = 2,
    "Maart" = 3,
    "April" = 4,
    "Mei" = 5,
    "Juni" = 6,
    "Juli" = 7,
    "Augustus" = 8,
    "September" = 9,
    "Oktober" = 10,
    "November" = 11,
    "December" = 12,
}

const createTooltipElement = (chart: any): any => {
    const tooltipEl = document.createElement('div');
    const tooltipStyles = {
        background: 'rgba(0, 0, 0, 0.7)',
        borderRadius: '3px',
        color: 'white',
        opacity: 1,
        pointerEvents: 'none',
        position: 'absolute',
        transform: 'translate(-50%, 0)',
        transition: 'all .1s ease'
    };

    Object.assign(tooltipEl.style, tooltipStyles);

    const table = document.createElement('table');
    table.style.margin = '10px';
    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
    
    return tooltipEl;
};

const updateTooltipContent = (tooltipEl: Element, tooltip: any): void => {
    const tableHead = document.createElement('thead');
    const tableBody = document.createElement('tbody');
    
    (tooltip.title || []).forEach((title: string) => {
        const tr = document.createElement('tr');
        const th = document.createElement('th');
        th.style.borderWidth = "0";
        th.appendChild(document.createTextNode(`${Maand[tooltip.dataPoints[0].raw.month]} ${title}`));
        tr.appendChild(th);
        tableHead.appendChild(tr);
    });

    tooltip.body?.forEach((body: any, i: number) => {
        const colors = tooltip.labelColors[i];
        const tr = document.createElement('tr');
        const td = document.createElement('td');

        const span = document.createElement('span');
        const spanStyles = {
            background: colors.borderColor,
            borderColor: colors.borderColor,
            borderWidth: '2px',
            marginRight: '10px',
            height: '10px',
            width: '10px',
            display: 'inline-block'
        };
        Object.assign(span.style, spanStyles);

        const label = tooltip.dataPoints[i].dataset.label;
        const currency = bedragFormat(tooltip.dataPoints[i].raw.value, 0);
        td.appendChild(span);
        td.appendChild(document.createTextNode(`${label}: ${currency}`));
        tr.appendChild(td);
        tableBody.appendChild(tr);
    });

    const addCalculationRow = (toegestaneLast: any, last: any, description: any): void => {
        if (toegestaneLast && last) {
            const calculatie = toegestaneLast.raw.value - last.raw.value;
            const tr = document.createElement('tr');
            const td = document.createElement('td');

            const span = document.createElement('span');
            const spanStyles = {
                background: 'rgba(0, 0, 0, 0)',
                borderColor: 'rgba(0, 0, 0, 0)',
                borderWidth: '2px',
                marginRight: '10px',
                height: '10px',
                width: '10px',
                display: 'inline-block'
            };
            Object.assign(span.style, spanStyles);

            td.style.fontWeight = "700";
            td.style.color = calculatie < 0 ? "#ff6767" : "#33ff36";
            td.appendChild(span);
            td.appendChild(document.createTextNode(`${description}: ${bedragFormat(calculatie, 0)}`));
            tr.appendChild(td);
            tableBody.appendChild(tr);
        }
    };

    const toegestaneLast = tooltip.dataPoints.find((c: any) => c.dataset.label === 'Toegestane last');
    const fictieveMaandlast = tooltip.dataPoints.find((c: any) => c.dataset.label === 'Fictieve maandlast');
    const werkelijkeMaandlast = tooltip.dataPoints.find((c: any) => c.dataset.label === 'Werkelijke maandlast');

    addCalculationRow(toegestaneLast, fictieveMaandlast, 'Ruimte fictieve maandlast');
    addCalculationRow(toegestaneLast, werkelijkeMaandlast, 'Ruimte werkelijke maandlast');

    if (!tooltip.dataPoints[1] && !tooltip.dataPoints[2]) {
        const tr = document.createElement('tr');
        const td = document.createElement('td');
        const span = document.createElement('span');
        const spanStyles = {
            background: 'rgba(0, 0, 0, 0)',
            borderColor: 'rgba(0, 0, 0, 0)',
            borderWidth: '2px',
            marginRight: '10px',
            height: '10px',
            width: '10px',
            display: 'inline-block'
        };
        Object.assign(span.style, spanStyles);
        td.appendChild(span);
        td.appendChild(document.createTextNode(`Selecteer minimaal de werkelijke maandlast of de`));
        tr.appendChild(td);
        tableBody.appendChild(tr);
        
        const tr2 = document.createElement('tr');
        const td2 = document.createElement('td');
        const span2 = document.createElement('span');

        Object.assign(span2.style, spanStyles);
        td2.appendChild(span2);
        td2.appendChild(document.createTextNode(`fictieve maandlast en de toegestane last.`));
        tr2.appendChild(td2);
        tableBody.appendChild(tr2);
    }

    const tableRoot = tooltipEl.querySelector('table');
    while (tableRoot?.firstChild) {
        tableRoot.firstChild.remove();
    }
    tableRoot?.appendChild(tableHead);
    tableRoot?.appendChild(tableBody);
};

export const externalTooltipHandler = (context: any): void => {
    const { chart, tooltip } = context;
    let tooltipEl = chart.canvas.parentNode.querySelector('div');

    if (!tooltipEl) {
        tooltipEl = createTooltipElement(chart);
    }

    if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
    }

    updateTooltipContent(tooltipEl, tooltip);

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
    const tooltipWidth = tooltipEl.offsetWidth;
    const tooltipHeight = tooltipEl.offsetHeight;
    const chartWidth = chart.width;
    const chartHeight = chart.height;

    let tooltipX = positionX + tooltip.caretX - tooltipWidth / 2;
    let tooltipY = positionY + tooltip.caretY;

    tooltipX = Math.max(positionX + 250, Math.min(tooltipX, positionX + chartWidth - tooltipWidth));
    tooltipY = Math.max(positionY, Math.min(tooltipY, positionY + chartHeight - tooltipHeight));

    const tooltipStyles = {
        opacity: 1,
        left: `${tooltipX}px`,
        top: `${tooltipY}px`,
        font: tooltip.options.bodyFont.string,
        whiteSpace: 'pre-line',
        padding: `${tooltip.options.padding}px ${tooltip.options.padding}px`
    };
    
    Object.assign(tooltipEl.style, tooltipStyles);
};
