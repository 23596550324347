import { RisicoprofielOutput, RisicoprofielOptions } from "../../../.generated/forms/formstypes";
import {
  createISWAsyncSideEffect,
  initISWAsyncSideEffect
} from "../../../shared/components/isw-side-effects/create-isw-helpers";
import { KlantprofielOptieType } from "../../infra/klantprofiel-schema";
import { mapKlantprofielUiToDl } from "../../infra/map-klantprofiel-scherm-ui-to-dl";

type AsyncContext = { alleVragenMetAntwoord: boolean };

export const risicoprofielAsyncSideEffects = createISWAsyncSideEffect<KlantprofielOptieType, AsyncContext>(
  async ({ draft, settings, params, fetchData }) => {
    const url = `${settings.klantdossiersFormsOrigin}/Adviesdossiers/${params.adviesdossier}/Klantprofiel/Risicoprofiel`;

    const res = await fetchData<RisicoprofielOutput>({
      method: "POST",
      url,
      body: JSON.stringify({ klantprofiel: mapKlantprofielUiToDl(draft) })
    });

    if (res.isValid && res.resultaat?.risicoprofiel) {
      draft.risicoprofielStatus = RisicoprofielOptions[res.resultaat?.risicoprofiel];
    }
  }
);

export const determineRisicoprofielAsyncSideEffects = initISWAsyncSideEffect<KlantprofielOptieType, AsyncContext>(
  ({ has, curr, prev, runAsync, context }) => {
    if (
      has.vragen.changed &&
      context.alleVragenMetAntwoord &&
      (prev.vragen.some((p, i) => p.gekozenAntwoord !== curr.vragen[i].gekozenAntwoord) ||
        prev.vragen.some((p, i) => p.antwoorden !== curr.vragen[i].antwoorden))
    ) {
      runAsync(risicoprofielAsyncSideEffects(context));
    }
  }
);
