import React, { ReactElement } from "react";
import { connect, FormikContextType } from "formik";
import { HypotheekOversluitenState } from "../infra/hypotheek-oversluiten-schema";
import { LabeledNumberInput } from "adviesbox-shared";

const Toetsrente = ({
  formik: {
    setFieldValue,
    values: { toetsrente }
  }
}: {
  formik: FormikContextType<HypotheekOversluitenState>;
}): ReactElement => {
  return (
    <>
      <LabeledNumberInput
        caption="Gewenste rentevastperiode"
        name="toetsrente.gewensteRentevastperiode"
        appendChildren={<span>jaar</span>}
      />
    </>
  );
};

export default connect<{}, HypotheekOversluitenState>(Toetsrente);
