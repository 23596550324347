import React, { ReactElement } from "react";
import { Modal, Button } from "react-bootstrap";
import { FormikProps } from "formik";

import { determineVerblijfsvergunningSideEffects } from "./determine-verblijfsvergunning-side-effects";

import { LabeledTextInput, LabeledDateInput } from "adviesbox-shared";
import AdviesBoxFormik from "../../shared/utils/adviesbox-formik";
import { hasValue } from "../../shared/utils/helpers";
import LabeledHdnKeuzelijst from "../../shared/components/hdn-keuzelijst/LabeledHdnKeuzelijst";
import { Verblijfsvergunning, verblijfsvergunningSchema, SoortVerblijfsvergunning } from "../infra/personalia-schema";
import { ISWSideEffects } from "../../shared/components/isw-side-effects/isw-side-effects";

export type VerblijfsvergunningModalData = {
  data: Verblijfsvergunning;
};

type VerblijfsvergunningModalProps = {
  onSave?: (data: Verblijfsvergunning) => void;
  closeModal?: () => void;
};

const VerblijfsvergunningModal = ({
  data,
  onSave,
  closeModal
}: VerblijfsvergunningModalData & VerblijfsvergunningModalProps): ReactElement => {
  return (
    <AdviesBoxFormik<Verblijfsvergunning>
      initialValues={{ ...data }}
      validationSchema={verblijfsvergunningSchema}
      onSave={onSave}
      closeModal={closeModal}
      render={({ values, submitForm }: FormikProps<Verblijfsvergunning>): ReactElement => {
        const hasVerblijfsvergunning =
          hasValue(values.soortVerblijfsvergunning) &&
          values.soortVerblijfsvergunning !== SoortVerblijfsvergunning.Geen;
        const isOnbepaald =
          values.soortVerblijfsvergunning === SoortVerblijfsvergunning.OnbepaaldeTijd ||
          values.soortVerblijfsvergunning === SoortVerblijfsvergunning.OnbepaaldeTijdAsielIV;
        return (
          <>
            <ISWSideEffects<Verblijfsvergunning> sync={determineVerblijfsvergunningSideEffects} />

            <Modal.Header closeButton>
              <Modal.Title>Verblijfsvergunning</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <LabeledHdnKeuzelijst
                caption="Soort verblijfsvergunning"
                name="soortVerblijfsvergunning"
                berichtSoortType="AX"
                keuzelijst="VerblijfsVergunningType"
                emptyValue="Geen"
                fieldSize="xl"
              />
              <LabeledTextInput
                caption="Nummer verblijfsvergunning"
                name="nummerVerblijfsvergunning"
                visible={hasVerblijfsvergunning}
              />
              <LabeledDateInput
                caption="Geldig tot"
                name="geldigTot"
                visible={hasVerblijfsvergunning && !isOnbepaald}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button data-testid="btnannuleren" variant="link" onClick={closeModal} id="btnannuleren">
                Annuleren
              </Button>
              <Button data-testid="btnopslaan" variant="primary" onClick={submitForm} id="btnopslaan">
                Bevestigen
              </Button>
            </Modal.Footer>
          </>
        );
      }}
    />
  );
};

export default VerblijfsvergunningModal;
