import React, { ReactElement } from "react";
import { connect, FormikContextType } from "formik";
import { LabeledBevestigingInput } from "adviesbox-shared";
import { PersonaliaState } from "../infra/personalia-schema";

const MedeAanvragerOpties = ({
  formik: {
    values: { medeAanvragerOpties }
  }
}: {
  formik: FormikContextType<PersonaliaState>;
}): ReactElement => {
  const hasMedeAanvrager = medeAanvragerOpties.medeAanvrager;
  return (
    <>
      <LabeledBevestigingInput caption="Medeaanvrager" name="medeAanvragerOpties.medeAanvrager" />
      {hasMedeAanvrager && (
        <LabeledBevestigingInput caption="Afwijkend adres" name="medeAanvragerOpties.afwijkendAdres" />
      )}
    </>
  );
};

export default connect<{}, PersonaliaState>(MedeAanvragerOpties);
