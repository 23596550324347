import { HypothekenKenmerken, hypothekenKenmerken } from "../../producten-overzicht/infra/product-kenmerken-types";

export const defaultProductkenmerkenStarterslening: HypothekenKenmerken = {
  ...hypothekenKenmerken,
  product: {
    logoTonen: false,
    productoptiesTonen: false,
    productnummerTonen: true,
    maatschappijkeuzeEnabled: true,
    ingangsdatumTonen: true,
    einddatumTonen: true,
    uwBemiddelingTonen: true,
    einddatumEnabled: true,
    looptijdTonen: true,
    looptijdJaarEnabled: true,
    looptijdMaandEnabled: true,
    productnaamTonen: true,
    productnaamEnabled: true
  }
};
