/* istanbul ignore file */

import { createContext } from "react";
import { SubmitResultTypes } from "../utils/save-validation";

export type SaveFormData = {
    isSaving: boolean;
    saveResult: SubmitResultTypes | null;
    version: number;
    saveData: () => void;
    setIsSaving: (_v: boolean) => void;
    setSaveResult: (_v: SubmitResultTypes | null) => void;
}

export const defaultSaveFormData: SaveFormData = {
    isSaving: false,
    saveResult: null,
    version: 0,
    saveData: (): void => { },
    setIsSaving: /* istanbul ignore next */ (_value: boolean): void => { },
    setSaveResult: /* istanbul ignore next */ (_value: SubmitResultTypes | null): void => { }
}

export const SaveFormContext = createContext(defaultSaveFormData);