import { original } from "immer";
import { WoonsituatieOutput } from "../../.generated/forms/formstypes";
import { EigenwoningforfaitInput, EigenwoningforfaitOutput } from "../../.generated/tarieven/tarieventypes";
import {
  createISWAsyncSideEffect,
  initISWAsyncSideEffect
} from "../../shared/components/isw-side-effects/create-isw-helpers";
import { berekenInputToDraftSideEffectResult, UiError } from "../../shared/types";
import { submit } from "../../shared/utils/save-validation";
import { mapWoonsituatieDlToUi } from "./map-woonsituatie-dl-to-ui";
import { WoonsituatieListState } from "./woonsituatie-schema";
import { createWoonsituatieUrl, getDossierId } from "./woonsituatie-url";

type Context = {
  selected: number;
  saveData: (values: any) => Promise<UiError[] | null>;
  opslaanRechten: boolean | undefined;
};

// Naar voorbeeld van aanleidingSaveAsync
export const woonsituatieSaveAsync = createISWAsyncSideEffect<WoonsituatieListState, Context>(
  async ({ draft, context, formik, fetchData, settings, params }) => {
    // Add sync sideeffect changes to the submit / saveData function
    const values = original(draft);
    if (values) formik.values = values;

    // Save screen using regular submit method
    const submitResult = await submit(context.saveData, formik);

    // Reload changes from platform using resetform instead of changing draft
    if (submitResult === "completed") {
      const id = getDossierId(params);
      const url = createWoonsituatieUrl(settings, params);
      const fetchResult = await fetchData<WoonsituatieOutput>({ method: "GET", url });
      const mappedResult = mapWoonsituatieDlToUi(null)(id, fetchResult);
      if (mappedResult) {
        formik.resetForm({ values: mappedResult });
      }
    }
  }
);

export const asyncWoonsituatieSideEffects = createISWAsyncSideEffect<WoonsituatieListState, Context>(
  async ({ fetchData, settings, draft, context }): Promise<void> => {
    const selectedWoonsituatie = draft.woonsituaties[context.selected];
    if (!selectedWoonsituatie.woningInEigendom.wozWaarde) return;
    const json = await fetchData<EigenwoningforfaitOutput, EigenwoningforfaitInput>({
      url: `${settings.accTarievenOrigin}/Berekeningen/Eigenwoningforfait`,
      body: {
        tarievenNorm: null,
        wozWaarde: selectedWoonsituatie.woningInEigendom.wozWaarde
      }
    });

    berekenInputToDraftSideEffectResult(
      draft.woonsituaties[context.selected].woningInEigendom.eigenwoningforfait,
      json.eigenwoningforfait
    );
  }
);

export const determineWoonsituatieAsyncSideEffects = initISWAsyncSideEffect<WoonsituatieListState, Context>(
  ({ context, curr, has, runAsync }) => {
    const hasSelectedWoonsituatie = has.woonsituaties[context.selected];
    const selectedWoonsituatie = curr.woonsituaties[context.selected];
    if (
      (selectedWoonsituatie.woningInEigendom.eigenwoningforfait.berekenen ||
        selectedWoonsituatie.woningInEigendom.eigenwoningforfait.berekenen === null) &&
      (hasSelectedWoonsituatie.woningInEigendom.eigenwoningforfait.berekenen.changed ||
        !selectedWoonsituatie.woningInEigendom.eigenwoningforfait.berekendBedrag ||
        hasSelectedWoonsituatie.woningInEigendom.wozWaarde.changed)
    ) {
      runAsync(asyncWoonsituatieSideEffects(context));
    }

    if (context.opslaanRechten && has.woonsituaties[context.selected].hypotheek.hasHypotheek.changed) {
      runAsync(woonsituatieSaveAsync(context));
    }
  }
);
