import {
  UitkeringOutput,
  UitkeringInput,
  UitkeringInputUitkeringstermijn
} from "../../.generated/vermogen/vermogentypes";

import {
  initISWAsyncSideEffect,
  createISWAsyncSideEffect
} from "../../shared/components/isw-side-effects/create-isw-helpers";

import { berekenInputToDraftSideEffectResult } from "../../shared/types";

import { createMapToDl } from "../../shared/utils/create-map-to-dl";
import { afronden } from "../../shared/utils/currency";
import { jaarMaandInMaanden } from "../../shared/generic-parts/jaar-maand/map-ui-2-dl";
import { indicatieveUitkerendeFaseModalSchema } from "../infra/producten-overzicht-schema";
import { IndicatieveUitkerendeFaseModalType } from "../infra/producten-overzicht-types";

type Context = { waardeopbouwBedrag: number | null };

export const mapIindicatieveUitkerendeFaseModalToUitkeringInput = createMapToDl(indicatieveUitkerendeFaseModalSchema)
  .with<Context>()
  .to<UitkeringInput>({
    koopsom: (_, { waardeopbouwBedrag }) => afronden(waardeopbouwBedrag, 0),
    uitkeringstermijn: ({ values }) => (values.termijn as unknown) as UitkeringInputUitkeringstermijn,
    lijfrentetarief: ({ values }) => values.lijfrenteTarief ?? 0,
    uitkeringsduurInMaanden: ({ values }) =>
      jaarMaandInMaanden(values.duurUitkering) === 0 ? null : jaarMaandInMaanden(values.duurUitkering),
    uitkeringsduurHoogInMaanden: ({ values }) => jaarMaandInMaanden(values.duurUitkeringHoog),
    verhoudingHoogLaag: ({ values }) => values.hoogLaagVerhouding
  });

export const indicatieveUitkerendeFaseModalUitkeringBerekenen = createISWAsyncSideEffect<
  IndicatieveUitkerendeFaseModalType,
  Context
>(async ({ draft, settings, fetchData, context, has }) => {
  const rekenen =
    has.values.termijn.changed ||
    has.values.lijfrenteTarief.changed ||
    has.values.lijfrenteUitkering.berekenen.changed ||
    has.values.duurUitkering.changed ||
    has.values.duurUitkeringHoog.changed ||
    has.values.hoogLaagVerhouding.changed;

  if (rekenen) {
    const { lijfrenteUitkering } = draft.values;

    if (context.waardeopbouwBedrag) {
      const result = await fetchData<UitkeringOutput, UitkeringInput>({
        url: `${settings.vermogenOrigin}/Lijfrente/Uitkering`,
        body: mapIindicatieveUitkerendeFaseModalToUitkeringInput(context)
      });

      if (result.isValid && result.resultaat) {
        const {
          resultaat: { uitkeringLijfrente }
        } = result;

        berekenInputToDraftSideEffectResult(lijfrenteUitkering, uitkeringLijfrente);
      }
    } else {
      berekenInputToDraftSideEffectResult(lijfrenteUitkering, 0);
    }
  }
});

export const determineIndicatieveUitkerendeFaseModalAsyncSideEffects = initISWAsyncSideEffect<
  IndicatieveUitkerendeFaseModalType,
  Context
>(async ({ runAsync, context }) => {
  runAsync(indicatieveUitkerendeFaseModalUitkeringBerekenen(context));
});
