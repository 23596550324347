import * as Yup from "yup";
import {
  ControleGewenstInkomenOptions,
  ControleGewenstNbiOptions,
  ControleMaandlastOptions
} from "../../../../.generated/forms/formstypes";
import { yupNullableEnum } from "../../../../shared/utils/yup-enum";

export const controleNormSchema = Yup.object({
  controleMaandlast: Yup.bool()
    .nullable()
    .default(false),
  controleMaandLastKeuze: yupNullableEnum(ControleMaandlastOptions).default(ControleMaandlastOptions.BenodigdInkomen),
  maximaleHypotheeksom: Yup.bool().default(true),
  controleGewenstInkomen: yupNullableEnum(ControleGewenstInkomenOptions).default(ControleGewenstInkomenOptions.Nbi),
  gewenstNettoBesteedbaarInkomen: yupNullableEnum(ControleGewenstNbiOptions).default(
    ControleGewenstNbiOptions.BehoudenHuidigNbi
  ),
  margeOndergrensPercentage: Yup.number()
    .nullable()
    .default(null),
  margeBovengrensPercentage: Yup.number()
    .nullable()
    .default(null),
  gewijzigdeJaren: Yup.bool()
    .nullable()
    .default(true),
  benodigdKapitaal: Yup.bool()
    .nullable()
    .default(true),

  gewenstPercentageHuidigNbi: Yup.number()
    .nullable()
    .default(null),
  gewenstVastBedrag: Yup.number()
    .nullable()
    .default(null),
  brutoInkomenswens: Yup.number()
    .nullable()
    .default(null)
});

export const controleNormVeldSchema = Yup.object({
  controleNorm: controleNormSchema
});

export type ControleNormType = Yup.InferType<typeof controleNormSchema>;
