import { ReactElement } from "react";
import { AdviesBoxColumn, createSpanWithId } from "adviesbox-shared";
import { jaarMaandInputNaarJaarMaand } from "../shared/generic-parts/jaar-maand/helpers";
import { AanvragerKeuze } from "../shared/types";
import { SituatieSoort } from "../producten-overzicht/infra/producten-overzicht-types";
import { bedragFormat } from "../shared/utils/currency";

const getContactanten = (aanvragers: AanvragerKeuze): string => {
  switch (aanvragers) {
    case AanvragerKeuze.Aanvrager1:
      return "Aanvrager 1";
    case AanvragerKeuze.Aanvrager2:
      return "Aanvrager 2";
    case AanvragerKeuze.Beiden:
      return "Beiden";
  }
};

export const bepaalKapitaalverzekeringKolommen = (situatie: SituatieSoort): AdviesBoxColumn[] => [
  {
    Header: "Verzekering",
    Cell: (c): ReactElement =>
      createSpanWithId(
        c.index,
        0,
        situatie === "huidig" ? "Bestaand" : c.original.product.doorlopend ? "Doorlopend" : "Nieuw"
      )
  },
  {
    Header: "Partij",
    Cell: (c): ReactElement =>
      createSpanWithId(c.index, 1, c.original.product.partijNaam, c.original.product.partijNaam)
  },
  {
    Header: "Soort verzekering",
    Cell: (c): ReactElement =>
      createSpanWithId(c.index, 2, c.original.product.productNaam, c.original.product.productNaam)
  },
  {
    Header: "Verzekerde(n)",
    Cell: (c): ReactElement => createSpanWithId(c.index, 3, getContactanten(c.original.verzekerden.verzekerden))
  },
  {
    Header: "Looptijd",
    Cell: (c): ReactElement => {
      const title = jaarMaandInputNaarJaarMaand(c.original.product.looptijd);
      return createSpanWithId(c.index, 4, title, title);
    }
  },
  {
    Header: "Doelkapitaal",
    Cell: (c): ReactElement =>
      createSpanWithId(
        c.index,
        5,
        bedragFormat(c.original.kapitaalopbouw.doelkapitaalBedrag || c.original.kapitaalopbouw.voorbeeldkapitaalBedrag)
      )
  },
  {
    Header: "Premie",
    Cell: (c): ReactElement => createSpanWithId(c.index, 6, bedragFormat(c.original.premieGegevens.totalePremieLaag))
  },
  {
    Header: "Premieduur",
    Cell: (c): ReactElement => {
      const title = jaarMaandInputNaarJaarMaand(c.original.premieGegevens.looptijd);
      return createSpanWithId(c.index, 7, title, title);
    }
  },
  {
    Header: "Verpand",
    Cell: (c): ReactElement =>
      createSpanWithId(c.index, 8, c.original.verpanding.verpandAanGeldverstrekker ? "Ja" : "Nee")
  },
  {
    Cell: "DeleteButton"
  }
];
