import { createContext } from "react"
import { dashboardSchema } from "./dashboard-schema";
import { DashboardState } from "./dashboard-types";

export type DashboardValuesType = {
    formikValues: DashboardState,
    initialized: boolean,
    isLoading: boolean,
    collapsed: boolean,
    berekenenFailed: boolean
}

export const defaultDashboardValues: DashboardValuesType = {
    formikValues: dashboardSchema.default(),
    initialized: false,
    isLoading: false,
    collapsed: false,
    berekenenFailed: false
}

export const defaultDashboardContextValues = {
    dashboardValues: {} as DashboardValuesType,
    setDashboardValues: (_: DashboardValuesType): void => { }
}

const DashboardContext = createContext(defaultDashboardContextValues);

export default DashboardContext;