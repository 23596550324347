import { logErrorToApplicationInsights, SettingsType } from "adviesbox-shared";
import { User } from "oidc-client";
import {  MotiveringOnderwerpsoort } from "../../.generated/forms/formstypes";
import { MotiveringOnderdeelOptions } from "../../.generated/rapportage/rapportagetypes";

export const getOnderwerpSoort = (type: MotiveringOnderwerpsoort): string | null => {
  switch (type) {
    case MotiveringOnderwerpsoort.Pensioenvoorziening:
      return "Pensioen";
    case MotiveringOnderwerpsoort.AfdekkenWW:
      return "Werkloosheid";
    case MotiveringOnderwerpsoort.AfdekkenAOWW:
      return "Arbeidsongeschiktheid";
    case MotiveringOnderwerpsoort.AfdekkenOverlijdensrisico:
      return "Overlijden";
    case MotiveringOnderwerpsoort.HoogteOpbouwDoelvermogen:
      return MotiveringOnderdeelOptions.OpbouwDoelvermogen;
    case MotiveringOnderwerpsoort.WijzeOpbouwDoelvermogen:
      return MotiveringOnderdeelOptions.OpbouwDoelvermogen;
    case MotiveringOnderwerpsoort.VerantwoordeWoonlasten:
      return MotiveringOnderdeelOptions.VerantwoordeWoonlasten;
    case MotiveringOnderwerpsoort.InbrengEigenGeld:
      return MotiveringOnderwerpsoort.InbrengEigenGeld;
    case MotiveringOnderwerpsoort.DubbeleWoonlasten:
      return MotiveringOnderwerpsoort.DubbeleWoonlasten;
    case MotiveringOnderwerpsoort.FiscaleAftrek:
      return MotiveringOnderwerpsoort.FiscaleAftrek;
    case MotiveringOnderwerpsoort.HypotheekAflossen:
      return MotiveringOnderdeelOptions.Aflossingsvorm;
    case MotiveringOnderwerpsoort.Rentevastperiode:
      return MotiveringOnderwerpsoort.Rentevastperiode;
    default:
      return null;
  }
};

export const motiveringRapportage = async (
  settings: SettingsType,
  user: User | null,
  vestigingId: string,
  adviesdossierId: string,
  signal: AbortSignal,
  motivering: MotiveringOnderwerpsoort,
  klantId?: string | null,
  voorstelId?: string
): Promise<Blob | null | void> => {
  const onderwerp = getOnderwerpSoort(motivering);
  if (!onderwerp) throw Error("kan onderwerp niet vaststellen voor inkomens-analyse-pdf");
  let url = voorstelId
    ? `${settings.rapportageOrigin}/Voorstellen/${voorstelId}/Motivering/${onderwerp}`
    : `${settings.rapportageOrigin}/Adviesdossiers/${adviesdossierId}/Motivering/${onderwerp}`;

  /* istanbul ignore next*/
  if (klantId) url += `?klantId=${klantId}`;

  const rsp = await fetch(url, {
    method: "POST",
    headers: {
      authorization: user ? `${user.token_type} ${user.access_token}` : "",
      "Ocp-Apim-Subscription-Key": settings.OcpApimSubscriptionKey,
      "Content-Type": "application/pdf",
      vestigingId
    },
    signal
  }).catch((e: Error) => {
    logErrorToApplicationInsights(Error(`Fout bij aanroep van web-api '${url}'. ${e.message}`));
    return null;
  });

  if (!rsp || !rsp.ok) {
    logErrorToApplicationInsights(Error(`Fout bij aanroep van web-api '${url}'. Resultaat bevat geen PDF.`));
    return null;
  }

  return await rsp.blob();
};
