import { SideEffects } from "use-formik-side-effects";
import { PoliswaardeModalType } from "../infra/kapitaalverzekering-types";


export const determinePoliswaardeModalSideEffects: SideEffects<PoliswaardeModalType> = (
  curr,
  prev
): PoliswaardeModalType | null => {
  if (curr !== prev) {
    return curr;
  }
  return null;
};
