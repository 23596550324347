import { Financieringsopzet, OverigeKostenSpecificatie } from "./financieringsbehoefte-types";
import { Belastingbox, NhgMogelijkOutput } from "../../.generated/forms/formstypes";
import { getFinancieringsbehoefteTextResources } from "./financieringsbehoefte-resources";
import { bedragFormat } from "adviesbox-shared";

export function mapOverigeKosten(financieringsopzet: Financieringsopzet): {} {
  const {
    bedrag: bouwkundigeKeuringBedrag,
    box: bouwkundigeKeuringBox,
    aftrekbaar: bouwkundigeKeuringEenmaligAftrekbaar
  } = getOverigeKosten(0, financieringsopzet);

  const {
    omschrijving: overigeKosten1Omschrijving,
    bedrag: overigeKosten1Bedrag,
    box: overigeKosten1Box,
    aftrekbaar: overigeKosten1EenmaligAftrekbaar
  } = getOverigeKosten(1, financieringsopzet);

  const {
    omschrijving: overigeKosten2Omschrijving,
    bedrag: overigeKosten2Bedrag,
    box: overigeKosten2Box,
    aftrekbaar: overigeKosten2EenmaligAftrekbaar
  } = getOverigeKosten(2, financieringsopzet);

  const {
    omschrijving: overigeKosten3Omschrijving,
    bedrag: overigeKosten3Bedrag,
    box: overigeKosten3Box,
    aftrekbaar: overigeKosten3EenmaligAftrekbaar
  } = getOverigeKosten(3, financieringsopzet);

  return {
    gewenstConsumptiefBedrag: financieringsopzet.overigeKostenInHypotheekModal.gewenstConsumptiefBedrag,
    royementskostenBedrag: financieringsopzet.overigeKostenInHypotheekModal.royementskostenBedrag,
    bouwkundigeKeuringBedrag,
    bouwkundigeKeuringBox: bouwkundigeKeuringBox as Belastingbox,
    bouwkundigeKeuringEenmaligAftrekbaar,
    overigeKosten1Omschrijving,
    overigeKosten1Bedrag,
    overigeKosten1Box: Belastingbox[overigeKosten1Box],
    overigeKosten1EenmaligAftrekbaar,
    overigeKosten2Omschrijving,
    overigeKosten2Bedrag,
    overigeKosten2Box: Belastingbox[overigeKosten2Box],
    overigeKosten2EenmaligAftrekbaar,
    overigeKosten3Omschrijving,
    overigeKosten3Bedrag,
    overigeKosten3Box: Belastingbox[overigeKosten3Box],
    overigeKosten3EenmaligAftrekbaar
  };
}

export function getOverigeKosten(
  index: number,
  financieringopzet: Financieringsopzet
): {
  omschrijving: string | null;
  bedrag: number | null;
  box: Belastingbox;
  aftrekbaar: boolean;
} {
  const { overigeKostenSpecificaties } = financieringopzet.overigeKostenInHypotheekModal;

  let omschrijving = null;
  let bedrag = null;
  let box = Belastingbox.Box3;
  let aftrekbaar = true;

  if (overigeKostenSpecificaties[index]) {
    omschrijving = overigeKostenSpecificaties[index].omschrijving;
    bedrag = overigeKostenSpecificaties[index].bedrag;
    box = overigeKostenSpecificaties[index].fiscalePlaatsing;
    aftrekbaar = overigeKostenSpecificaties[index].aftrekbaar === true;
  }

  return { omschrijving, bedrag, box, aftrekbaar };
}

export function getWozWaardeLink(postcode: string | null, huisnummer: number | null): string {
  if (postcode && huisnummer) {
    return `https://www.wozwaardeloket.nl/index.jsp?a=1&accept=true&locatie=${postcode} ${huisnummer}`;
  }
  return "https://www.wozwaardeloket.nl/index.jsp?a=1&accept=true";
}

export const berekenOverigeKosten1Bedrag = (overigeKostenSpecificaties: OverigeKostenSpecificatie[]): number => {
  const result = overigeKostenSpecificaties
    .filter(fp => fp.fiscalePlaatsing === "Box1")
    .map(fp => fp.bedrag || 0)
    .reduce((som, bedrag): number => som + bedrag, 0);

  return result;
};

export const mapNhgWarning = (nhgMogelijkResultaat: NhgMogelijkOutput | null, gewensteHypotheek: number): string => {
  const maxKostenInEigendomResult = nhgMogelijkResultaat?.maxKostenInEigendomResult;
  const maxLeningResult = nhgMogelijkResultaat?.maxLeningResult;
  const maxHypMarktwaardeBedrag = maxLeningResult?.maxHypMarktwaardeBedrag ?? 0;
  let result = "";

  if (maxKostenInEigendomResult && !maxKostenInEigendomResult.success) {
    result = getFinancieringsbehoefteTextResources("WarningMaximaleKostenNhg").replace(
      "{bedrag}",
      bedragFormat(maxKostenInEigendomResult.maxKostenInEigendomBedrag)
    );
  }
  if (maxLeningResult && maxLeningResult.success === false && gewensteHypotheek > maxHypMarktwaardeBedrag) {
    if (result !== "") result = result.concat("\n");
    result = `${result}${getFinancieringsbehoefteTextResources("WarningMaximaleLeningNhg").replace(
      "{bedrag}",
      bedragFormat(maxHypMarktwaardeBedrag)
    )}`;
  }
  if (result === "") {
    result = "Waarschuwing";
  }

  return result;
};
