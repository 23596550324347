import { UitkerendeLijfrenteKenmerken } from "../product-kenmerken-types";
import { productCodeLijfrenteVerzekering } from "../../../uitkerende-lijfrente/infra/uitkerende-lijfrente-types";

// TODO MdB: Verwijderen als UitkerendeLijfrente kenmerken beschikbaar zijn.
export const getUitkerendeLijfrenteKenmerken = (productCode: string): UitkerendeLijfrenteKenmerken => ({
  product: {
    productnaamTonen: true,
    productnaamEnabled: true,
    ingangsdatumTonen: true,
    einddatumTonen: false,
    einddatumEnabled: false,
    looptijdTonen: false,
    looptijdJaarEnabled: false,
    looptijdMaandEnabled: false,
    uwBemiddelingTonen: true,
    maatschappijkeuzeEnabled: true,
    logoTonen: false,
    productoptiesTonen: false,
    productnummerTonen: true
  },
  verzekeringnemer: {
    meerdereVerzekeringnemerOptiesMogelijk: productCode === productCodeLijfrenteVerzekering
  },
  verzekerden: {
    meerdereVerzekerdeOptiesMogelijk: productCode === productCodeLijfrenteVerzekering,
    premiesplitsingTonen: false
  },
  indicatieveUitkerendeFaseKenmerken: {
    belastingBoxTonen: true,
    soortLijfrenteUitkeringTonen: productCode === productCodeLijfrenteVerzekering,
    overgangOpTweedeVerzekerdeTonen: productCode === productCodeLijfrenteVerzekering
  }
});
