import {
  Krediet as KredietenDlEntry,
  KredietLeninggegevens,
  KredietPolis,
  KredietProduct
} from "../../.generated/forms/formstypes";
import { jaarMaandInMaanden } from "../../shared/generic-parts/jaar-maand/map-ui-2-dl";
import { mapKlantnaamUi2Dl } from "../../shared/generic-parts/klantnaam/map-ui-2-dl";
import { KlantnaamType } from "../../shared/generic-parts/klantnaam/schema";
import { AanvragerKeuze } from "../../shared/types";
import { createMapToDl } from "../../shared/utils/create-map-to-dl";
import { kredietenSchema, kredietSchema } from "./kredieten-schema";
import { GeldnemersType } from "./kredieten-types";
import { mapLocalDateToString } from "adviesbox-shared";

type AanvragerContext = {
  aanvrager1: KlantnaamType | null;
  aanvrager2: KlantnaamType | null;
};

const mapPolisUiToDl = createMapToDl(kredietSchema).to<KredietPolis>({
  productnummer: v => v.product.productNummer,
  maatschappijCode: v => v.partijCode,
  maatschappijOmschrijving: v => v.product.partijNaam,
  maatschappijCodeHdn: v => v.product.partijCodeSelectie,
  productnaam: v => v.product.productNaam,
  productcode: v => +v.productCode,
  ingangsdatum: v => mapLocalDateToString(v.product.ingangsdatum) ?? /* istanbul ignore next */ "",
  looptijdInMaanden: v => jaarMaandInMaanden(v.product.looptijd) ?? /* istanbul ignore next */ 0,
  uwBemiddeling: v => v.product.uwBemiddeling
});

const mapLeninggegevensUiToDl = createMapToDl(kredietSchema).to<KredietLeninggegevens>({
  hoofdsomBedrag: v =>
    v.leningGegevens.hoofdsom.berekenen ? /* istanbul ignore next*/ null : v.leningGegevens.hoofdsom.bedrag,
  hoofdsomBedragOvernemen: v => v.leningGegevens.hoofdsom.berekenen,
  berekendHoofdsomBedrag: v =>
    v.leningGegevens.hoofdsom.berekenen
      ? /* istanbul ignore next */ v.leningGegevens.hoofdsom.berekendBedrag
      : v.leningGegevens.hoofdsom.bedrag,
  garantie: v => v.leningGegevens.garantie,
  restantHoofdsomBedrag: v => v.leningGegevens.restantHoofdsom,
  opgaveDatum: v => mapLocalDateToString(v.leningGegevens.opgaveDatum),
  slottermijnBedrag: v => v.leningGegevens.slottermijn,
  aflossingPercentage: v => v.leningGegevens.aflossingPercentage,
  rentePercentage: v => v.leningGegevens.rentePercentage,
  maandlastBedrag: v => v.leningGegevens.maandlast.bedrag,
  maandlastOvernemen: v => v.leningGegevens.maandlast.berekenen,
  bestedingsdoelCode: v =>
    !!v.leningGegevens.bestedingsdoel && v.leningGegevens.bestedingsdoel !== "00"
      ? +v.leningGegevens.bestedingsdoel
      : /* istanbul ignore next*/ null,
  deelBox1Bedrag: v => v.fiscaleGegevens.deelBox1Bedrag,
  renteaftrekEinddatum: v => mapLocalDateToString(v.fiscaleGegevens.einddatumRenteaftrek),
  bestedingsdoelOmschrijving: v =>
    v.leningGegevens.bestedingsdoel === "99" ? v.leningGegevens.bestedingsdoelOmschrijving : null
});

export const mapGeldnemersUiToDl = (
  geldnemers: GeldnemersType,
  { aanvrager1, aanvrager2 }: AanvragerContext
): string[] => {
  const geldnemerKlantIds: string[] = [];

  switch (geldnemers.geldnemers) {
    case AanvragerKeuze.Aanvrager1:
      if (aanvrager1) {
        geldnemerKlantIds.push(aanvrager1.klantId);
      }
      break;

    case AanvragerKeuze.Aanvrager2:
      if (aanvrager2?.klantId) {
        geldnemerKlantIds.push(aanvrager2.klantId);
      }
      break;
    case AanvragerKeuze.Beiden:
      if (aanvrager1) {
        geldnemerKlantIds.push(aanvrager1.klantId);
      }
      if (aanvrager2?.klantId) {
        geldnemerKlantIds.push(aanvrager2.klantId);
      }
      break;
    default:
      /* istanbul ignore next */
      if (aanvrager1) {
        geldnemerKlantIds.push(aanvrager1.klantId);
      }
  }

  return geldnemerKlantIds;
};

const mapKredietUiToDl = createMapToDl(kredietSchema)
  .with<AanvragerContext>()
  .to<KredietProduct>({
    soortProduct: v => v.soortProduct,
    doorlopend: v => v.product.doorlopend,
    polis: v => mapPolisUiToDl(v),
    geldnemerKlantIds: (v, context) => mapGeldnemersUiToDl(v.geldnemers, context),
    leninggegevens: v => mapLeninggegevensUiToDl(v),
    productId: v => v.productId,
    wijzigingenInDoorlopendProductOvernemen: v => v.product.wijzigingenInDoorlopendProductOvernemen ?? true,
    meenemen: v => v.product.meenemen
  });

export const mapKredietenUiToDl = createMapToDl(kredietenSchema).to<KredietenDlEntry>({
  producten: v => v.producten.map(p => mapKredietUiToDl({ aanvrager1: v.aanvrager1, aanvrager2: v.aanvrager2 })(p)),
  aanvrager1: v => mapKlantnaamUi2Dl(v.aanvrager1),
  aanvrager2: v => mapKlantnaamUi2Dl(v.aanvrager2),
  ingangsdatumVoorstel: v => mapLocalDateToString(v.ingangsdatumVoorstel)
});
