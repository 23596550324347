import { LabeledTextInput, SettingsType, FormFirstFocus, PlatformFoutenSamenvatting } from "adviesbox-shared";
import classnames from "classnames";
import { FormikProps } from "formik";
import { User } from "oidc-client";
import React, { ReactElement, useState } from "react";
import * as Yup from "yup";
import AdviesBoxFormik from "../../../utils/adviesbox-formik";
import Modal from "../../modal/Modal";
import classes from "../dossier-balk.module.scss";
import { OmschrijvingType } from "../schema-and-mappers";
import { dossierKopierenModalApi } from "./dossier-kopiëren-modal-api";

type DossierKopierenModalProps = {
  inBemiddeling: boolean;
  settings: SettingsType;
  user: User | null;
  vestigingId: string;
  adviesdossierId: string;
  onSubmit?: (data: OmschrijvingType) => Promise<void>;
  closeModal?: () => void;
  bezigMetKopieren?: boolean;
};

const DossierKopierenModal = ({
  inBemiddeling,
  data,
  settings,
  user,
  vestigingId,
  adviesdossierId,
  onSubmit,
  closeModal,
  bezigMetKopieren
}: { data: OmschrijvingType } & DossierKopierenModalProps): ReactElement => {
  const [busyCopying, setBusyCopying] = useState(!!bezigMetKopieren);
  const body = (
    <>
      <PlatformFoutenSamenvatting className={classes.platform_errors} />
      <FormFirstFocus>
        {busyCopying && <div className={classnames("loader", classes.loader)} data-testid="loader" />}
        {inBemiddeling && (
          <div className="px-2 pb-3 text-justify">
            Het kopiëren van een dossier zorgt ook in de Aanvragen applicatie voor een nieuw dossier. Zijn er al
            aanvragen naar de Aanvragen applicatie doorgezet, dan is sterk aan te raden om alle aanvragen vanuit het
            eerdere dossier te doen en op te volgen. Hiervoor kan je de verschillende voorstellen binnen een dossier
            gebruiken.
          </div>
        )}
        <LabeledTextInput caption="Korte omschrijving kopie" name="korteOmschrijving" />
        <LabeledTextInput caption="Lange omschrijving kopie" name="langeOmschrijving" textarea={3} />
      </FormFirstFocus>
    </>
  );

  return (
    <AdviesBoxFormik<OmschrijvingType>
      initialValues={{
        ...data,
        korteOmschrijving: `Kopie - ${data.korteOmschrijving}`,
        langeOmschrijving: `Kopie - ${data.langeOmschrijving}`
      }}
      validationSchema={Yup.object()}
      onSave={closeModal}
      closeModal={closeModal}
      render={({ setFieldValue, submitForm, values }: FormikProps<OmschrijvingType>): ReactElement => (
        <>
          <Modal
            title="Dossier kopiëren"
            body={body}
            onSubmitClick={async () => {
              if (busyCopying === true) return;
              setBusyCopying(true);
              const resultaat = await dossierKopierenModalApi(
                settings,
                user,
                vestigingId,
                adviesdossierId,
                values,
                setFieldValue
              );
              const newAdviesdossierId = resultaat.adviesdossier?.adviesdossierId;
              if (newAdviesdossierId) {
                const url = `${window.location.origin}/vestiging/${vestigingId}/adviesdossier/${newAdviesdossierId}/personalia`;
                window.open(url, "_blank");
                setBusyCopying(false);
                await submitForm();
              }
            }}
            onCancelClick={closeModal}
            isSubmitting={!!busyCopying}
            saveButtonTekst="Dossier kopiëren en openen"
          />
        </>
      )}
    />
  );
};

export default DossierKopierenModal;
