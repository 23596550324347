/* istanbul ignore file */
import React, { ReactElement } from "react";
import { AdresType } from "../../types";

const APIKEY = "AIzaSyC7-hA3o70PlUzXpP-fg--ki2jmCyRz-HQ";
export const GoogleAPIbaseUrl = `//maps.googleapis.com/maps/api/streetview?size=600x300&key=${APIKEY}`;

export function GetAdresEncoded(address: AdresType): string {
  let url = "";
  // gebruik zoveel mogelijk beschikbare adres informatie achter elkaar met een + teken, in de volgende volgorde (google stript zelf overtollige '+' karakters).
  url = `${address.postcode}+`;
  url += `${address.plaats}+`;
  url += `${address.straat}+`;
  url += `${address.huisnummer}+`;
  url += `${address.toevoeging}+`;
  url += `${address.landCode === "NL" ? "Nederland" : address.landCode}+`; // landcode vertaling ontbreekt
  return url;
}

export function GetMapsImageUrl(location: string): string {
  return `${GoogleAPIbaseUrl}&location=${location}`;
}

const MapsImage = ({ address }: { address: AdresType }): ReactElement => {
  const locationStr = GetAdresEncoded(address);
  const mapsUrl = GetMapsImageUrl(locationStr);
  return <img className="maps-image" src={mapsUrl} alt="Het huis" />;
};

MapsImage.displayName = "MapsImage";

export default MapsImage;
