import React, { ReactElement } from "react";
import { FormikProps } from "formik";
import {
  inkomstenAanmerkelijkBelangModalSchema,
  InkomstenAanmerkelijkBelangModalType
} from "../infra/inkomen-en-fiscus-schema";
import Inkomensverleden, { WinstSoort } from "../infra/shared-components/inkomensverleden";
import ScenarioInstellingen from "../infra/shared-components/scenario-instellingen";
import AdviesBoxFormik from "../../shared/utils/adviesbox-formik";
import { Debug } from "../../shared/components/formik/Debug";
import Modal from "../../shared/components/modal/Modal";
import { LabeledBerekenDate } from "adviesbox-shared";
import { ISWSideEffects } from "../../shared/components/isw-side-effects/isw-side-effects";
import { inkomenstenAanmerkelijkBelangModalSideEffects } from "./inkomsten-aanmerkelijk-belang-side-effects";

export type InkomstenAanmerkelijkBelangModalData = {
  data: InkomstenAanmerkelijkBelangModalType;
};

type InkomstenAanmerkelijkBelangModalProps = {
  onSave?: (data: InkomstenAanmerkelijkBelangModalType) => void;
  closeModal?: () => void;
};

const InkomstenAanmerkelijkBelangModal = ({
  data,
  onSave,
  closeModal
}: InkomstenAanmerkelijkBelangModalData & InkomstenAanmerkelijkBelangModalProps): ReactElement => (
  <AdviesBoxFormik<InkomstenAanmerkelijkBelangModalType>
    initialValues={{ ...data }}
    validationSchema={inkomstenAanmerkelijkBelangModalSchema}
    onSave={onSave}
    closeModal={closeModal}
    render={({ submitForm }: FormikProps<InkomstenAanmerkelijkBelangModalType>): ReactElement => {
      const body = (
        <>
          <ISWSideEffects<InkomstenAanmerkelijkBelangModalType>
            sync={inkomenstenAanmerkelijkBelangModalSideEffects()}
          />

          <div className="input-to-right-with-button">
            <LabeledBerekenDate caption="Inkomen meetellen tot" name="inkomenMeetellenTot" />
          </div>

          <Inkomensverleden soort={WinstSoort.OverigeWerkzaamheden} name="inkomensverleden" />

          <ScenarioInstellingen name="scenarioInstellingen" />

          <Debug />
        </>
      );

      return (
        <>
          <Modal
            title="Inkomsten aanmerkelijk belang"
            body={body}
            onSubmitClick={submitForm}
            onCancelClick={closeModal}
          />
        </>
      );
    }}
  />
);

export default InkomstenAanmerkelijkBelangModal;
