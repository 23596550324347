import React from 'react'
import { Card } from "adviesbox-shared"
import { ReactElement } from 'react'

export const CategoryOnderdeel = ({ category }: { category: Categorie }): ReactElement => {
    return (
        <div className="mb-4">
            <h2 className="px-3 category-heading">Categorie: {category.omschrijving}</h2>
            {category.onderwerpen.map((topic) => (
                <div key={topic.code}>
                    <Card className="width100" >
                        <h3>Onderwerp: {topic.omschrijving}</h3>
                        {topic.vragen.map((question) => (
                            <div key={question.code} className="ml-2">
                                <strong>Vraag:</strong><br />
                                <i>&apos;{question.omschrijving}&apos;</i><br /><br />
                                <strong>Antwoord:</strong><br />
                                {question.antwoorden.map((answer) => (
                                    <p key={answer.code}><i>&apos;{answer.omschrijving}&apos;</i></p>
                                ))}
                            </div>
                        ))}
                        {!!topic.toelichting && (<div className="ml-2 mt-4"><h4>Toelichting:</h4><br /><p><i>&apos;{topic.toelichting}&apos;</i></p> </div>)}
                    </Card>
                </div>
            ))}
        </div>
    )
}