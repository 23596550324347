import React, { ReactElement } from "react";
import { Formik } from "formik";
import { Column } from "react-table-6";
import { createSpanWithId, DataGrid } from "adviesbox-shared";
import { maandenNaarJaarMaand } from "../../shared/generic-parts/jaar-maand/helpers";
import classnames from "classnames";
import classes from "./modal-huidige-situatie.module.scss";
import { bedragFormat } from "../../shared/utils/currency";
import { getFormattedDate } from "../../shared/utils/dates";
import Modal from "../../shared/components/modal/Modal";
import { UitgangspuntenType, modalHuidigeSituatieSchema } from "../infra/hypotheek-oversluiten-schema";

export type ModalHuidigeSituatieData = {
  data: UitgangspuntenType;
};

type ModalHuidigeSituatieProps = {
  closeModal?: () => void;
};

const ModalHuidigeSituatie = ({
  data,
  closeModal
}: ModalHuidigeSituatieData & ModalHuidigeSituatieProps): ReactElement => {
  const huidigeSituatieColumns: Column[] = [
    {
      Header: "Maatschappijnaam",
      id: "maatschappijnaam",
      Cell: (c): ReactElement => createSpanWithId(c.index, 0, c.original.maatschappijnaam)
    },
    {
      Header: "Hypotheekbedrag",
      id: "hypotheekbedrag",
      Cell: (c): ReactElement => createSpanWithId(c.index, 1, bedragFormat(c.original.hypotheekbedrag)),
      width: 105
    },
    {
      Header: "Hypotheekrente",
      id: "hypotheekrente",
      Cell: (c): ReactElement =>
        createSpanWithId(c.index, 2, c.original.hypotheekrente ? `${c.original.hypotheekrente} %` : null)
    },
    {
      Header: "Bruto rentelasten",
      id: "brutoRentelasten",
      Cell: (c): ReactElement => createSpanWithId(c.index, 3, bedragFormat(c.original.brutoRentelasten, 2)),
      width: 102
    },
    {
      Header: "Corr. bruto rentelasten",
      id: "corrBrutoRentelasten",
      Cell: (c): ReactElement => createSpanWithId(c.index, 4, bedragFormat(c.original.corrBrutoRentelasten, 2)),
      width: 130
    },
    {
      Header: "Rentevastperiode",
      id: "rentevastperiode",
      Cell: (c): ReactElement => createSpanWithId(c.index, 5, maandenNaarJaarMaand(c.original.rentevastperiode))
    },
    {
      Header: "Einde rvp",
      id: "eindeRvp",
      Cell: (c): ReactElement => createSpanWithId(c.index, 6, getFormattedDate(c.original.eindeRvp))
    }
  ];

  const HuidigeSituatie = (): ReactElement => {
    return (
      <div className="px-1">
        <DataGrid
          columns={huidigeSituatieColumns}
          name="huidigeSituatie"
          rowCaption="situatie"
          dataFromMapOnly
          className={classnames(classes.bedragen_rechts, "readonly")}
        />
      </div>
    );
  };

  return (
    <Formik initialValues={{ ...data }} validationSchema={modalHuidigeSituatieSchema} onSubmit={(): void => {}}>
      <Modal title="Huidige situatie" body={<HuidigeSituatie />} onCancelClick={closeModal} noEditableData />
    </Formik>
  );
};

ModalHuidigeSituatie.displayName = "ModalHuidigeSituatie";

export default ModalHuidigeSituatie;
