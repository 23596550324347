import * as Yup from "yup";
import { getGeneralTextResources } from "../../utils/text-resources-algemeen";

const jaarMaandInputSchemaYup = Yup.object({
  jaren: Yup.number()
    .nullable()
    .default(null)
    .max(99, getGeneralTextResources("ErrorAantalJaren"))
    .min(0, getGeneralTextResources("ErrorAantalJaren")),
  maanden: Yup.number()
    .nullable()
    .default(null)
    .max(11, getGeneralTextResources("ErrorAantalMaanden"))
    .min(0, getGeneralTextResources("ErrorAantalMaanden"))
});

export const jaarMaandInputSchema = jaarMaandInputSchemaYup.default(jaarMaandInputSchemaYup.default());

export type JaarMaandInputType = Yup.InferType<typeof jaarMaandInputSchema>;
