import classNames from "classnames";
import React, { ReactElement } from "react";
import { OrvUitkeringssoortOptions } from "../../../.generated/forms/formstypes";
import { createMapEnum } from "../../../shared/utils/create-map-enum";
import { bedragFormat } from "../../../shared/utils/currency";
import { ModalVerzekeringType } from "../../infra/orv-schema";
import classes from "./orv-vergelijker-pdf.module.scss";

type OrvPDFVerzekeringTableProps = {
  verzekering: ModalVerzekeringType;
  renderVerzekeringOnly: boolean;
};
export const verzekeringvormMapper = createMapEnum(OrvUitkeringssoortOptions).to({
  AnnuïtairDalend: "Annuïtair dalend",
  Gelijkblijvend: "Gelijkblijvend",
  LineairDalend: "Lineair dalend"
});

export const renderOrvPDFVerzekeringTable = ({
  renderVerzekeringOnly,
  verzekering
}: OrvPDFVerzekeringTableProps): ReactElement => {
  const verzekeringHeaders = ["Verzekeringsvorm", "Duur verzekering", "Verzekerd kapitaal", "Verpand"];
  const verzekeringContent = [
    verzekeringvormMapper(verzekering?.verzekeringsvorm),
    verzekering?.duurInJaren,
    bedragFormat(verzekering?.verzekerdKapitaalBedrag, 0),
    verzekering?.verpand ? "Ja" : "Nee"
  ];
  return (
    <>
      <table className={classNames({ [classes.pdf_padding]: renderVerzekeringOnly })}>
        <thead>
          <tr>
            <td className={classes.pdf_table_verzekering_header_left}>Uitgangspunten berekening</td>
            <td className={classes.pdf_table_verzekering_header_right}></td>
          </tr>
        </thead>
        <tbody>
          {verzekeringHeaders.map((v, i) => (
            <tr key={"vt_row_" + i}>
              <td className={classes.pdf_table_data_info}>{v}</td>
              <td className={`${classes.pdf_table_data}`} style={i % 2 ? {} : { backgroundColor: "#f0f0f0" }}>
                {verzekeringContent[i]}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};
