import { FormikContextType } from "formik";
import { OrvsState, huidigOrvProductSchema, voorstelOrvProductSchema } from "./orv-schema";
import { ProductSelectieType, SituatieSoort } from "../../producten-overzicht/infra/producten-overzicht-types";
import { partijOnafhankelijk, partijAnders } from "../../producten-overzicht/infra/product-constanten";
import { formikIsValidAsync } from "../../shared/utils/formik-is-valid-async";
import { UitkeringTypeOptions } from "../../.generated/producten/productentypes";
import { OrvUitkeringssoortOptions } from "../../.generated/forms/formstypes";

export const createAddProductCb = async (
  formikContext: FormikContextType<OrvsState>,
  selectedState: [number, React.Dispatch<React.SetStateAction<number>>],
  verzekeringSelectie: ProductSelectieType,
  situatie: SituatieSoort
): Promise<void> => {
  const product = (situatie === "huidig" ? huidigOrvProductSchema : voorstelOrvProductSchema).default();
  product.partijCode = verzekeringSelectie.codes.maatschappijCode;
  product.productCode = verzekeringSelectie.codes.productCode;
  product.product.uitkeringssoort = getUitkeringSoort(verzekeringSelectie.codes.productVorm as UitkeringTypeOptions);
  product.product.partijNaam = verzekeringSelectie.maatschappijNaam;
  product.product.productNaam = verzekeringSelectie.productNaam;
  product.product.partijCodeSelectie =
    verzekeringSelectie.codes.maatschappijCode === partijOnafhankelijk
      ? partijAnders
      : verzekeringSelectie.codes.maatschappijCode;

  const [, setSelected] = selectedState;
  if (await formikIsValidAsync(formikContext)) {
    const {
      setFieldValue,
      values: { producten }
    } = formikContext;
    setFieldValue("producten", [...producten, product]);

    setSelected(producten.length > 0 ? producten.length : 0);
  }
};

export const createDeleteProductCb = (
  formikContext: FormikContextType<OrvsState>,
  selectedState: [number, React.Dispatch<React.SetStateAction<number>>]
): ((index: number) => Promise<void>) =>
  async function(index): Promise<void> {
    const [selected, setSelected] = selectedState;
    if (selected === index || (await formikIsValidAsync(formikContext))) {
      const {
        setFieldValue,
        values: { producten }
      } = formikContext;

      const filteredProducten = producten.filter((_, _index): boolean => index !== _index);
      setFieldValue("producten", filteredProducten);

      setSelected(index > 0 ? index - 1 : 0);
    }
  };

export function getUitkeringSoort(productVorm: UitkeringTypeOptions): OrvUitkeringssoortOptions {
  switch (productVorm) {
    case UitkeringTypeOptions.AnnuitairDalend:
      return OrvUitkeringssoortOptions.AnnuïtairDalend;

    case UitkeringTypeOptions.LineairDalend:
      return OrvUitkeringssoortOptions.LineairDalend;

    case UitkeringTypeOptions.Gelijkblijvend:
      return OrvUitkeringssoortOptions.Gelijkblijvend;

    case UitkeringTypeOptions.AnwHiaatVerzekering:
      return OrvUitkeringssoortOptions.Gelijkblijvend;
  }
}
