import * as Yup from "yup";

import {
  BetalingsTermijnType,
  SoortCorrectieLastOptions,
  SoortCorrectieFiscaalOptions
} from "../../.generated/forms/formstypes";

import { klantnaamSchema } from "../../shared/generic-parts/klantnaam/schema";

import { productSchema } from "../../producten-overzicht/infra/producten-overzicht-schema";

import { getOverigePostenTextResources } from "./overige-posten-resources";
import { LocalDate } from "@js-joda/core";
import { scenarioCardSchema } from "../../shared/types";
import { hasValue } from "../../shared/utils/helpers";
import { nullableLocalDateSchema } from "adviesbox-shared";

export const heffingSchema = Yup.object({
  heffingBij: Yup.string()
});

export const correctieSchema = Yup.object({
  correctieLast: Yup.mixed<SoortCorrectieLastOptions>().default(SoortCorrectieLastOptions.Last),
  correctieFiscaal: Yup.mixed<SoortCorrectieFiscaalOptions>().default(SoortCorrectieFiscaalOptions.Aftrekpost)
});

export const bedragSpecificatieModalSchema = Yup.object({
  scenario: scenarioCardSchema.default([])
});

export const overigePostenGegevensSchema = Yup.object({
  bedrag: Yup.number()
    .nullable()
    .default(null),
  bedragScenario: bedragSpecificatieModalSchema,
  termijn: Yup.mixed<BetalingsTermijnType>().default(BetalingsTermijnType.Jaar),
  inkomenUitArbeid: Yup.boolean()
    .nullable()
    .default(null),
  onderdeelDagloon: Yup.boolean()
    .nullable()
    .default(null)
});

export const verrekenenSchema = Yup.object({
  langleven: Yup.boolean().default(true),
  arbeidsongeschiktheid: Yup.boolean().default(false),
  werkloosheid: Yup.boolean().default(true),
  overlijden: Yup.boolean().default(false),
  overgangOpPartnerPercentage: Yup.number()
    .nullable()
    .min(1, getOverigePostenTextResources("ErrorOvergangspercentage"))
    .max(100, getOverigePostenTextResources("ErrorOvergangspercentage")),
  overgangOpPartnerEinddatum: nullableLocalDateSchema.test({
    message: getOverigePostenTextResources("ErrorOvergangOpPartnerEinddatum"),
    test: function(value: LocalDate | null) {
      if (hasValue(value)) {
        return value > LocalDate.now();
      }
      return true;
    }
  }),
  eindleeftijd: Yup.number()
    .nullable()
    .test({
      message: getOverigePostenTextResources("ErrorOvergangOpPartnerEindleeftijd"),
      test: function() {
        // Note: overgangOpPartnerEinddatum is bijgewerkt in het side effect op basis van de eindleeftijd
        // Hier is de overgangOpPartnerEinddatum niet te berekenen omdat de 'selected' waarde niet beschikbaar is
        const { overgangOpPartnerEinddatum } = this.parent;
        if (hasValue(overgangOpPartnerEinddatum)) {
          return overgangOpPartnerEinddatum > LocalDate.now();
        }
        return true;
      }
    })
});

export const overigePostProductSchema = productSchema.shape({
  ingangsdatum: nullableLocalDateSchema.required(getOverigePostenTextResources("ErrorGeenAanvangsDatum")),
  einddatum: nullableLocalDateSchema.required(getOverigePostenTextResources("ErrorVerplichtEindDatum")).test({
    message: getOverigePostenTextResources("ErrorEindDatum"),
    test: function(value: LocalDate | null) {
      if (hasValue(value)) {
        const ingangsdatum: LocalDate | null = this.parent.ingangsdatum;
        if (hasValue(ingangsdatum)) {
          return value >= ingangsdatum;
        }
      }
      return true;
    }
  })
});

export const overigePostSchema = Yup.object({
  productId: Yup.string().nullable(),
  product: overigePostProductSchema,
  heffing: heffingSchema,
  correctie: correctieSchema,
  overigePostenGegevens: overigePostenGegevensSchema,
  verrekenen: verrekenenSchema
});

export const overigePostenSchema = Yup.object({
  producten: Yup.array(overigePostSchema).default([overigePostSchema.default()]),
  aanvrager1: klantnaamSchema.nullable(),
  aanvrager2: klantnaamSchema.nullable(),
  ingangsdatumVoorstel: nullableLocalDateSchema
});
