import React, { ReactElement } from "react";
import { connect, FormikContextType } from "formik";
import { LabeledSelectInput } from "adviesbox-shared";

import { bedragFormat } from "../../shared/utils/currency";
import { HypothekenState } from "../infra/hypotheek-types";

type HypotheekProductDetailsProps = {
  selected: number;
};

const HypotheekProductDetails = ({
  selected,
  formik: {
    values: { panden }
  }
}: HypotheekProductDetailsProps & {
  formik: FormikContextType<HypothekenState>;
}): ReactElement => {
  return (
    <>
      <LabeledSelectInput
        caption="Hypotheek op woning"
        name={`producten[${selected}].hypotheekProductDetails.hypotheekOpWoning`}
        options={panden.map((item, index) => {
          return {
            label: `Woning ${index + 1}, Marktwaarde: ${bedragFormat(item.marktwaardeRenteBedrag, 0)} `,
            value: item.pandId
          };
        })}
      />

      <LabeledSelectInput
        caption="Rangorde"
        name={`producten[${selected}].hypotheekProductDetails.rangorde`}
        options={[
          { label: "1", value: "1" },
          { label: "2", value: "2" },
          { label: "3", value: "3" },
          { label: "4", value: "4" },
          { label: "5", value: "5" }
        ]}
      />
    </>
  );
};

export default connect<HypotheekProductDetailsProps, HypothekenState>(HypotheekProductDetails);
