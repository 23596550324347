import { QuestionType, CardSpec, CheckboxType } from "../../infra/klantprofiel-schema";
import { KlantprofielVraagOptions } from "../../../.generated/forms/formstypes";
import { HoeveelEigenGeldInbrengenExtra } from "./hoeveeleigengeldinbrengen-extra";

export const eigenGeldQuestionSpecs: CardSpec[] = [
  {
    questions: [
      {
        question: KlantprofielVraagOptions.HoeveelEigenGeldBeschikbaar,
        type: QuestionType.bedrag
      }
    ]
  },
  {
    questions: [
      {
        question: KlantprofielVraagOptions.HoeveelEigenGeldInbrengen,
        type: QuestionType.radio
      },
      {
        question: KlantprofielVraagOptions.HoeveelGeldOverhouden,
        type: QuestionType.toggleCheckbox,
        extra: HoeveelEigenGeldInbrengenExtra,
        checkboxAnswers: [{ index: 5, type: CheckboxType.textEnbedrag }],
        condition: {
          question: KlantprofielVraagOptions.HoeveelEigenGeldInbrengen,
          answer: 70201
        }
      },
      {
        question: KlantprofielVraagOptions.WaarvoorEigenGeldGebruiken,
        type: QuestionType.checkbox,
        condition: {
          question: KlantprofielVraagOptions.HoeveelEigenGeldInbrengen,
          answer: 70202
        }
      }
    ]
  }
];
