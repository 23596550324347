import React, { ReactElement } from "react";
import { CheckBox, TextInput, TooltipWrap } from "adviesbox-shared";
import { connect, FormikContextType } from "formik";
import { FinancieringOptions } from "../../.generated/forms/formstypes";
import { AanleidingState } from "../infra/aanleiding-schema";
import { getAanleidingTextResources } from "../infra/aanleiding-resources";
import { Icon } from "adviesbox-shared";

const AanleidingGesprek = ({
  formik: {
    values: {
      aanleiding: { financiering },
      planningKeuze,
      aanleidingGesprek,
      hasTweedeAanvrager
    }
  }
}: {
  formik: FormikContextType<AanleidingState>;
}): ReactElement => {
  const toonVerbouwing: boolean =
    financiering === FinancieringOptions.AankoopBestaandeBouw ||
    financiering === FinancieringOptions.AankoopNieuwbouw ||
    financiering === FinancieringOptions.HuidigeHypotheekWijzigen;

  const toonAfkoopErfpacht: boolean = financiering === FinancieringOptions.HuidigeHypotheekWijzigen;

  const toonFinancieringWoning: boolean =
    financiering === FinancieringOptions.AankoopNieuwbouw || financiering === FinancieringOptions.AankoopBestaandeBouw;

  const toonExtraAflossen: boolean = financiering === FinancieringOptions.HuidigeHypotheekWijzigen;

  const toonEchtscheiding: boolean = financiering === FinancieringOptions.HuidigeHypotheekWijzigen;

  const toonEindeRentevastperiode: boolean = financiering === FinancieringOptions.HuidigeHypotheekWijzigen;

  const toonAflossingsvorm: boolean = financiering === FinancieringOptions.HuidigeHypotheekWijzigen;

  const toonMaandlastenverlichting: boolean = financiering === FinancieringOptions.HuidigeHypotheekWijzigen;

  const toonRisicoscan: boolean =
    planningKeuze.overlijden ||
    planningKeuze.arbeidsongeschiktheid ||
    planningKeuze.werkloosheid ||
    planningKeuze.pensioen;

  const toonVermogensadvies: boolean = planningKeuze.vermogensopbouw || planningKeuze.pensioen;

  const toonControlegesprek: boolean = financiering === FinancieringOptions.GeenFinanciering;

  return (
    <div className="px-1">
      {toonFinancieringWoning && (
        <CheckBox caption="Financiering van woning" name="aanleidingGesprek.financieringWoning" disabled />
      )}
      {toonVerbouwing && <CheckBox caption="Verbouwing / verbetering" name="aanleidingGesprek.verbouwing" />}
      {toonAfkoopErfpacht && <CheckBox caption="Afkoop erfpacht" name="aanleidingGesprek.afkooperfpacht" />}
      {toonExtraAflossen && <CheckBox caption="Extra aflossen" name="aanleidingGesprek.extraAflossen" />}
      {toonEchtscheiding && (
        <div style={{ display: "flex", justifyContent: "left", alignItems: "center" }}>
          <CheckBox
            caption="Echtscheiding / Beëindigde relatie"
            name="aanleidingGesprek.echtscheiding"
            tooltip={getAanleidingTextResources("ToolTipEchtScheiding")}
          />
          {aanleidingGesprek.echtscheiding &&
          hasTweedeAanvrager && ( // TO DO de true moet een check worden op een 2e aanvrager
              <TooltipWrap
                name="echtscheiding"
                warningText={getAanleidingTextResources("echtscheidingWarning")}
                iconType="waarschuwing"
                tooltipClasses="mx-1"
              >
                <div id={`echtscheiding-warning-button-wrapper`} className="mx-1">
                  <Icon name="waarschuwing" alt="waarschuwing" multiColor={true} />
                </div>
              </TooltipWrap>
            )}
        </div>
      )}

      {toonEindeRentevastperiode && (
        <CheckBox caption="Einde rentevastperiode" name="aanleidingGesprek.eindeRentevastperiode" />
      )}
      {toonAflossingsvorm && (
        <CheckBox caption="Aflossingsvorm(en) aanpassen" name="aanleidingGesprek.aflossingsVormen" />
      )}
      {toonMaandlastenverlichting && (
        <CheckBox caption="Maandlastenverlichting" name="aanleidingGesprek.maandlastenverlichting" />
      )}
      {toonRisicoscan && <CheckBox caption="Risicoscan" name="aanleidingGesprek.risicoscan" />}
      {toonVermogensadvies && <CheckBox caption="Vermogensadvies" name="aanleidingGesprek.vermogensAdvies" />}
      {toonControlegesprek && <CheckBox caption="Controlegesprek" name="aanleidingGesprek.controlegesprek" />}

      <CheckBox caption="Anders, namelijk:" name="aanleidingGesprek.anders" />

      <div className="pt-2">
        <TextInput name="aanleidingGesprek.andersTekst" />
      </div>
    </div>
  );
};

export default connect<{}, AanleidingState>(AanleidingGesprek);
