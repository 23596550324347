import { createGetTextResources } from "../../shared/utils/text-resources-algemeen";

export const getBankgarantieTextResources = createGetTextResources({
  pandGarantiebedrag: "Het bankgarantiebedrag wordt overgenomen uit het scherm Financieringsbehoefte.",
  textDeGarantie: "Een onvoorwaardelijke, onheroepelijke garantie van 10% van de koopsom.",
  textVergoedingKorting: "Nationale waarborg B.V., gevestigd te Bilt.",
  textDegarant: "N.V. Nationale Borg Maatschappij, gevestigd te Amsterdam.",
  tooltipAfgiftedatum: "De uiterste afgiftedatum van de bankgarantie volgens de koopakte.",
  textAanvragen: "Je kunt deze bankgarantie naar bemiddeling doorsturen voor aanvraag via het scherm 'aanvraag'",
  einddatumGarantieIsEerderDanIngangsDatum: "Einddatum garantie moet na ingangsdatum garantie liggen",
  tooltipNotarisKantoor:
    "Via de instellingen-app, scherm ‘Beheer partijen - Notarissen’ kun je aangeven met welke notariskantoren je samenwerkt. Deze worden in de keuzelijst weergegeven.",
  garantiebedrag: "Vul een bedrag in.",
  datumOndertekeningKoopakte: "Vul een datum in."
});
