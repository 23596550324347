import { createGetTextResources } from "../../shared/utils/text-resources-algemeen";

export const getSpecificatieAoUitkeringTextResources = createGetTextResources({
  deelnemerNoMoreDienstverbanden:
    "Voor de dienstverbanden van deze deelnemer is al een pensioenregeling toegevoegd. Er is geen dienstverband zonder pensioenregeling meer over.",

  invaliditeitsModalStaffelError: `Regel i+1 en regel i+2 van de staffel sluiten niet op elkaar aan`,

  ouderdomspensioenModalTooltipIngangsdatum:
    "In dit scenario wordt gerekend met hele maanden. Hierbij is de ingangsdatum bij aanvang van het ouderdomspensioen leidend.",

  pensioengrondslagTooltipFranchise:
    "De franchise is standaard gevuld op basis van fulltime dienstverband. Is er sprake van een parttime dienstverband? Verreken dan de franchise met het parttime percentage.",
  pensioengrondslagTooltipHuidigOpgebouwdBedrag:
    "Vul hier het huidig opgebouwde bruto jaarbedrag dat hoort bij deze pensioenregeling in. Dit is de jaarlijkse pensioenuitkering als deze niet verder opgebouwd wordt.",

  pensioentoezeggingenTooltipOuderdomspensioen:
    "Is er sprake van een variabel ouderdomspensioen? Dan houdt Adviesbox hier rekening mee in de berekening van de maximale hypotheek. Bij de aanvraag via HDN wordt alleen de hoogte van het ouderdomspensioen bij aanvang meegestuurd. Informeer de geldverstrekker bijvoorbeeld via een toelichting op het scherm 'Aanvraag - Lening' dat sprake is van een variabel ouderdomspensioen met wisselende pensioenbedragen tijdens de uitkeringsduur.",
  pensioentoezeggingenWarningIngangsdatum:
    "Let op: er is een periode waarin met zowel inkomen uit dienstverband als prepensioen gerekend wordt.",
  pensioentoezeggingenTooltipInvaliditeits:
    "Vul hier het te ontvangen invaliditeitspensioen in zonder eventuele IVA-uitkering.",

  afwijkendToggle:
    "Selecteer ‘Ja’ als het uitkeringspercentage afhankelijk is van het benutten van de restverdiencapaciteit. Vervolgens kun je hieronder het afwijkende uitkeringspercentage invullen en het percentage waarvoor het benutten van de restverdiencapaciteit geldt.",

  validatieAoModalMinPercentage: "Vul hetzelfde of een lager percentage in dan het 'vanaf percentage'.",
  validatieAoModalVanafPercentage:
    "Vul hetzelfde of een hoger percentage in dan het 'minimaal percentage'.",
  validatieAoModalProRataMinPercentage:
    "Vul hetzelfde of een lager percentage in dat het 'tot percentage'.",
  validatieAoModalProRataTotPercentage:
    "Vul hetzelfde of een lager percentage in dan het 'minimaal percentage'.",
  validatiePensioenaangroei: `Pensioenaangroei (factor A) mag maximaal het opbouwpercentage (-replacePercentage-%) vermenigvuldigd met de pensioengrondslag (-replaceIngevuldBedrag-) zijn (-replaceBerekendBedrag-).`,
  validatieBedragIsRequired: "Vul het bedrag in.",
  validatieprePensioenBedragIsRequired:
    "Het prepensioen is meer dan 85% (<bedrag>) van het pensioengevend inkomen. Weet je dit zeker?",
  validatieIngangsleeftijdOuderdomspensioenLeeftijd: "Vul een ingangsleeftijd tussen de 60 en 99 jaar in.",
  validatieIngangsleeftijdOuderdomspensioenIngangsdatum:
    "Vul een ingangsdatum in, waarbij de leeftijd tussen de 60 en 99 jaar ligt.",
  validatieIngangsleeftijdPrepensioenLeeftijd: "Vul een ingangsleeftijd tussen de 40 en 99 jaar in.",
  validatieIngangsleeftijdPrepensioenIngangsdatum:
    "Vul een ingangsdatum in, waarbij de leeftijd tussen de 40 en 99 jaar ligt.",
  validatieMaanden: "Het aantal maanden moet tussen 0 en 11 liggen.",
  validatieEindleeftijdWezenpensioen: "Vul een eindleeftijd tussen de 10 en 30 jaar in."
});
