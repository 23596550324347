import React, { ReactElement } from "react";
import AdviseurNaam from "../../../auth/AdviseurNaam";
import classes from "./hypotheek-vergelijker-pdf.module.scss";
import { getCurrentDateFormatted } from "./hypotheek-vergelijker-pdf-helpers/hypotheek-vergelijker-pdf-helpers";
type HypotheekVergelijkerPDFFooterProps = {
  organisatieNaam?: string;
};

export const renderHypotheekVergelijkerPDFFooter = ({
  organisatieNaam
}: HypotheekVergelijkerPDFFooterProps): ReactElement => {
  return (
    <>
      <div style={{ flexGrow: 1 }} />
      <div className={classes.pdf_footer}>
        <div className={classes.pdf_footer_header}>
          * Rente daalt na de rentevastperiode automatisch bij lagere schuld-marktwaarde verhouding
          <br />
          ** Rente daalt direct bij lagere schuld-marktwaardeverhouding
          <br />
        </div>
        <div className={classes.pdf_footer_header}>
          <span>
            Deze bedragen zijn slechts een indicatie van wat werkelijk mogelijk is. Controle van de acceptatienormen van
            de gekozen maatschappij dient te geschieden op het tabblad &apos;acceptatie&apos;. Definitieve aceptatie
            geschiedt altijd door de gekozen maatschappij zelf.
          </span>
        </div>
        <div className={classes.pdf_footer_sub}>
          <span>
            Overzicht verzorgd door&nbsp;
            <span>
              <AdviseurNaam />
            </span>
            &nbsp; van <span>{organisatieNaam}</span> op {getCurrentDateFormatted()}.
          </span>
          <span className={classes.pdf_footer_sub_rights}>Aan dit overzicht kunnen geen rechten worden ontleend.</span>
        </div>
      </div>
    </>
  );
};
