import React, { ReactElement, useContext, useEffect, useState } from "react";
import { connect, FormikContextType } from "formik";

import { PageLoading, Card, CardWrapper } from "adviesbox-shared";

import Product from "../../producten-overzicht/product/product";
import { isKenmerkError } from "../../producten-overzicht/infra/product-kenmerken-types";
import { ProductkenmerkenContext } from "../../producten-overzicht/infra/producten-context";

import Geldnemers from "../geldnemers/geldnemers";
import LeningGegevens from "../lening-gegevens/lening-gegevens";
import FiscaleGegevens from "../fiscale-gegevens/fiscale-gegevens";
import { SituatieSoort } from "../../producten-overzicht/infra/producten-overzicht-types";

import { determineKredietDetailsSideEffects } from "../infra/determine-krediet-details-side-effects";
import { SoortKredietProductOptions } from "../../.generated/forms/formstypes";
import { ISWSideEffects } from "../../shared/components/isw-side-effects/isw-side-effects";
import { kredietDetailsAsyncSideEffects } from "../infra/determine-krediet-details-async-side-effects";
import { ErrorPage } from "adviesbox-shared";
import { KredietenType } from "../infra/kredieten-types";
import { AppDataContext } from "../../navigation/appdata-context";

type Props = { selected: number; situatie: SituatieSoort };

const KredietDetails = ({
  selected,
  situatie,
  formik: {
    values: { producten }
  }
}: Props & { formik: FormikContextType<KredietenType> }): ReactElement => {
  const kenmerkenContext = useContext(ProductkenmerkenContext);
  const [kenmerken, setKenmerken] = useState<null | ReturnType<typeof kenmerkenContext.getProductKenmerken>>(null);
  const { setSErunning } = useContext(AppDataContext);

  const selectedProduct = producten && producten[selected];
  useEffect(() => {
    setKenmerken(
      kenmerkenContext.getProductKenmerken("Krediet", selectedProduct.partijCode, selectedProduct.productCode)
    );
  }, [setKenmerken, kenmerkenContext, selectedProduct.productCode, selectedProduct.partijCode]);

  if (!kenmerken) return <PageLoading />;

  if (kenmerken && isKenmerkError(kenmerken)) {
    return <ErrorPage error={new Error(`Fout bij het ophalen van producteigenschappen: ${kenmerken.reden}`)} />;
  }

  const fiscaleGegevensTonen =
    selectedProduct.soortProduct !== SoortKredietProductOptions.Studielening &&
    selectedProduct.soortProduct !== SoortKredietProductOptions.Privatelease;

  return (
    <CardWrapper className="px-3" maxRowCount={7}>
      <ISWSideEffects<KredietenType>
        sync={determineKredietDetailsSideEffects(selected)}
        async={kredietDetailsAsyncSideEffects({ selected })}
        asyncStartStopCallback={setSErunning}
      />

      <Card
        title={
          situatie === "voorstel"
            ? `${selectedProduct.product.doorlopend ? "Doorlopend" : "Nieuw"} product`
            : "Huidig product"
        }
      >
        <Product
          selected={selected}
          situatie={situatie}
          kenmerken={kenmerken.product}
          productSoort="krediet"
          isStarterslening={false}
        />
      </Card>

      <Card title="Geldnemer(s)">
        <Geldnemers selected={selected} />
      </Card>

      <Card title="Leninggegevens">
        <LeningGegevens selected={selected} kenmerken={kenmerken.leningGegevens} />
      </Card>

      {fiscaleGegevensTonen && (
        <Card title="Fiscale gegevens">
          <FiscaleGegevens selected={selected} />
        </Card>
      )}
    </CardWrapper>
  );
};

KredietDetails.displayName = "KredietDetails";

export default connect<Props, KredietenType>(KredietDetails);
