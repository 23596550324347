import React, { ReactElement } from "react";
import { FormikProps } from "formik";

import {
  inkomstenVerhuurOnroerendGoedModalSchema,
  InkomstenVerhuurOnroerendGoedModalType
} from "../infra/inkomen-en-fiscus-schema";

import ScenarioInstellingen from "../infra/shared-components/scenario-instellingen";
import { LabeledCurrencyInput, LabeledDateInput } from "adviesbox-shared";
import AdviesBoxFormik from "../../shared/utils/adviesbox-formik";
import Modal from "../../shared/components/modal/Modal";

export type InkomstenVerhuurOnroerendGoedModalData = {
  data: InkomstenVerhuurOnroerendGoedModalType;
};

type InkomstenVerhuurOnroerendGoedModalProps = {
  onSave?: (data: InkomstenVerhuurOnroerendGoedModalType) => void;
  closeModal?: () => void;
};

const InkomstenVerhuurOnroerendGoedModal = ({
  data,
  onSave,
  closeModal
}: InkomstenVerhuurOnroerendGoedModalData & InkomstenVerhuurOnroerendGoedModalProps): ReactElement => {
  const body = (
    <>
      <LabeledCurrencyInput
        caption="Inkomsten verhuur onroerend goed"
        name="inkomstenVerhuurOnroerendGoed"
        fieldSize="m"
      />
      <LabeledDateInput caption="Einddatum" name="eindDatum" />
      <ScenarioInstellingen name="scenarioInstellingen" />
    </>
  );

  return (
    <AdviesBoxFormik<InkomstenVerhuurOnroerendGoedModalType>
      initialValues={{ ...data }}
      validationSchema={inkomstenVerhuurOnroerendGoedModalSchema}
      onSave={onSave}
      closeModal={closeModal}
      render={({ submitForm }: FormikProps<InkomstenVerhuurOnroerendGoedModalType>): ReactElement => (
        <Modal
          title="Inkomsten verhuur onroerend goed"
          body={body}
          onSubmitClick={submitForm}
          onCancelClick={closeModal}
        />
      )}
    />
  );
};

export default InkomstenVerhuurOnroerendGoedModal;
