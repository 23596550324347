import { Medewerker } from "../../../../.generated/licenties/licentiestypes";

export const getNaamMedewerker = (persoonsgegevensState: Medewerker, fallbackNaam: string): string => {
    if (!persoonsgegevensState) {
      return fallbackNaam;
    }
    const voor = persoonsgegevensState.voorletters?.trim() || persoonsgegevensState.voornaam || "";
    const achternaam = persoonsgegevensState.achternaam;
    const tussenvoegsels = persoonsgegevensState.tussenvoegsels;
    
    if (achternaam) {
      if (voor && tussenvoegsels) {
        return `${voor} ${tussenvoegsels} ${achternaam}`;
      }
      if (voor) {
        return `${voor} ${achternaam}`;
      }
      if (tussenvoegsels) {
        return `${tussenvoegsels} ${achternaam}`;
      }
      return achternaam;
    }
  
    return fallbackNaam;
  };