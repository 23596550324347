import { LabelValuePairs } from "adviesbox-shared/utils/types";
import { TaxatieDoelOptions } from "../../.generated/forms/formstypes";

export const taxatieAlgemeenDoelOpties: LabelValuePairs = [
  {
    label: "Hypothecaire financiering met Nhg",
    value: TaxatieDoelOptions.HypothecaireFinancieringMetNhg
  },
  {
    label: "Hypothecaire financiering zonder Nhg",
    value: TaxatieDoelOptions.HypothecaireFinancieringZonderNhg
  },
  {
    label: "Inzicht waarde ten behoeve van aan-/verkoop beslissing",
    value: TaxatieDoelOptions.InzichtWaardeTenBehoeveBeslissing
  },
  {
    label: "Aanvraag hypothecaire geldlening",
    value: TaxatieDoelOptions.AanvraagHypothecaireGeldlening
  },
  {
    label: "Aankoop beslissing",
    value: TaxatieDoelOptions.AankoopBeslissing
  },
  {
    label: "Aanvraag overbruggings financiering",
    value: TaxatieDoelOptions.AanvraagOverbruggingsfinanciering
  },
  {
    label: "Bijzonder beheer",
    value: TaxatieDoelOptions.BijzonderBeheer
  },
  {
    label: "Boedel splitsing",
    value: TaxatieDoelOptions.BoedelSplitsing
  },
  {
    label: "Brabantse verkoopgarantie",
    value: TaxatieDoelOptions.BrabantseVerkoopgarantie
  },
  {
    label: "Hertaxatie",
    value: TaxatieDoelOptions.Hertaxatie
  },
  {
    label: "Minnelijke waardering",
    value: TaxatieDoelOptions.MinnelijkeWaardering
  },
  {
    label: "Ontpanding verzekeringspolis",
    value: TaxatieDoelOptions.OntpandingVerzekeringspolis
  },
  {
    label: "Uitwinning",
    value: TaxatieDoelOptions.Uitwinning
  },
  {
    label: "Verkoop beslissing",
    value: TaxatieDoelOptions.VerkoopBeslissing
  },
  {
    label: "Verkrijgen financiering",
    value: TaxatieDoelOptions.VerkrijgenFinanciering
  },
  {
    label: "Verkrijgen financiering met NHG",
    value: TaxatieDoelOptions.VerkrijgenFinancieringMetNhg
  },
  {
    label: "Verkrijgen inzicht waarde aankoopbeslissing",
    value: TaxatieDoelOptions.VerkrijgenInzichtWaardeAankoopbeslissing
  },
  {
    label: "Verkrijgen inzicht waarde verkoopbeslissing",
    value: TaxatieDoelOptions.VerkrijgenInzichtWaardeVerkoopbeslissing
  },
  {
    label: "Verkrijgen inzicht ten behoeve van executoriale verkoop",
    value: TaxatieDoelOptions.VerkrijgenInzichtTenBehoeveVanExecutorialeVerkoop
  },
  {
    label: "Woning financiering met validatie",
    value: TaxatieDoelOptions.WoningFinancieringMetValidatie
  },
  {
    label: "Woning financiering Nwwi",
    value: TaxatieDoelOptions.WoningFinancieringNwwi
  },
  {
    label: "Bepalen prijs bij aankoop",
    value: TaxatieDoelOptions.BepalenPrijsBijAankoop
  },
  {
    label: "Bepalen prijs bij verkoop",
    value: TaxatieDoelOptions.BepalenPrijsBijVerkoop
  },
  {
    label: "Bepalen prijs bij terugkoop",
    value: TaxatieDoelOptions.BepalenPrijsBijTerugkoop
  },
  {
    label: "Gedwongen verkoop",
    value: TaxatieDoelOptions.GedwongenVerkoop
  },
  {
    label: "Anders",
    value: TaxatieDoelOptions.Anders
  }
];
