import React, { ReactElement } from "react";
import { connect, FormikContextType } from "formik";
import { LabeledCurrencyInput, LabeledDateInput, PercentageInput, TooltipWrap } from "adviesbox-shared";
import { KredietenType } from "../infra/kredieten-types";
import { getKredietenTextResources } from "../infra/kredieten-resources";

type Props = { selected: number };

const FiscaleGegevens = ({
  selected,
  formik: {
    setFieldValue,
    values: { producten }
  }
}: Props & {
  formik: FormikContextType<KredietenType>;
}): ReactElement => {
  const selectedProduct = producten && producten[selected];
  const { restantHoofdsom } = selectedProduct.leningGegevens;
  const { deelBox1Bedrag } = selectedProduct.fiscaleGegevens;

  return (
    <>
      <LabeledCurrencyInput
        caption="Deel box 1"
        name={`producten[${selected}].fiscaleGegevens.deelBox1Bedrag`}
        appendChildren={
          <div className="d-inline-flex px-1">
            <PercentageInput
              name={`producten[${selected}].fiscaleGegevens.deelBox1Percentage`}
              decimalen={2}
              appendChildren={
                !restantHoofdsom && (
                  <TooltipWrap
                    name="hoofdsom-leeg"
                    warningText={getKredietenTextResources("TooltipGeenRestantHoofdsom")}
                    placement="bottom"
                    iconType="waarschuwing"
                    tooltipClasses="px-1 mt-1"
                  />
                )
              }
            />
          </div>
        }
      />

      <LabeledCurrencyInput
        caption="Deel box 3"
        name={`producten[${selected}].fiscaleGegevens.deelBox3Bedrag`}
        appendChildren={
          <div className="d-inline-flex px-1">
            <PercentageInput name={`producten[${selected}].fiscaleGegevens.deelBox3Percentage`} decimalen={2} />
          </div>
        }
      />

      {!!deelBox1Bedrag && (
        <LabeledDateInput
          caption="Einddatum renteaftrek"
          name={`producten[${selected}].fiscaleGegevens.einddatumRenteaftrek`}
        />
      )}
    </>
  );
};

export default connect<Props, KredietenType>(FiscaleGegevens);
