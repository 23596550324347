import { ErrorPage, PageLoading, SettingsType, withErrorBoundary } from "adviesbox-shared";
import React, { ReactElement, useCallback } from "react";
import { mapDlTargetToKredietenUiField, mapKredietenDlToUi } from "./infra/map-kredieten-dl-2-ui";

import { InvalidAdviesboxResultErrorPage } from "../shared/components/fetch-error-page/fetch-error-page";
import Kredieten from "./kredieten";
import { RouteParams } from "../shared/paramrouting/paramrouting-context";
import { SituatieSoort } from "../producten-overzicht/infra/producten-overzicht-types";
import { mapKredietenUiToDl } from "./infra/map-kredieten-ui-2-dl";
import { useAdviesboxData } from "../shared/hooks/use-adviesbox-data";

type KredietenAjaxProps = { situatie: SituatieSoort };

const KredietenAjax = ({ situatie }: KredietenAjaxProps): ReactElement => {
  const createUrl = useCallback(
    (s: SettingsType, p: RouteParams): string =>
      situatie === "huidig"
        ? `${s.klantdossiersFormsOrigin}/Adviesdossiers/${p.adviesdossier}/Krediet`
        : `${s.klantdossiersFormsOrigin}/Voorstellen/${p.voorstel}/Krediet`,
    [situatie]
  );

  const createDataId = useCallback((p: RouteParams) => (situatie === "huidig" ? p.adviesdossier : p.voorstel), [
    situatie
  ]);

  const { loading, error, data, platformData, saveData } = useAdviesboxData(
    createUrl,
    createDataId,
    mapKredietenDlToUi,
    mapKredietenUiToDl,
    mapDlTargetToKredietenUiField
  );

  if (loading) {
    return <PageLoading />;
  }

  if (error) {
    return <ErrorPage error={error} data={platformData} />;
  }

  if (!data) {
    return <InvalidAdviesboxResultErrorPage />;
  }

  return <Kredieten situatie={situatie} saveData={saveData} {...data} />;
};

KredietenAjax.displayName = "KredietenAjax";

export default withErrorBoundary(KredietenAjax);
