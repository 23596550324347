import {
  getAppLocation,
  getCookie,
  getOTAP,
  getPathname,
  LoginPage,
  SettingsContext,
  SigninOidc
} from "adviesbox-shared";
import React, { ReactElement, useContext } from "react";
import { Route } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import logo from "../assets/new-brand-logo.svg";
import { RouteParams } from "../shared/paramrouting/paramrouting-context";

const UnauthenticatedAdviesBoxApp = (): ReactElement => {
  const { baseUrls } = useContext(SettingsContext);
  const linkToPortal = getAppLocation(window.location.origin, "ADV", "POR", baseUrls);
  const vestigingIdCookie = getCookie(`${getOTAP(window.location.hostname)}_vestigingid`);

  return (
    <>
      <Route
        exact
        path={"/vestiging/:vestiging"}
        render={({ history }: RouteComponentProps<RouteParams>) => {
          let pathName = getPathname();
          if (!pathName.endsWith("/")) pathName = pathName + "/";
          history.push(`${pathName}zoeken`);
          return <></>;
        }}
      />
      <Route
        exact
        path={"/"}
        render={({ history }: RouteComponentProps) => {
          if (!vestigingIdCookie) {
            window.location.assign(linkToPortal);
            return <></>;
          }

          history.push(`/vestiging/${vestigingIdCookie}/zoeken`);
          return <></>;
        }}
      />
      <Route path="/signin-oidc" component={SigninOidc} />
      <LoginPage appLogo={logo} />
    </>
  );
};

UnauthenticatedAdviesBoxApp.displayName = "UnauthenticatedAdviesBoxApp";

export default UnauthenticatedAdviesBoxApp;
