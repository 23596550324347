import React, { ReactElement } from "react";
import { LabeledSelectInput } from "adviesbox-shared";
import { FormikContextType, connect } from "formik";
import { WoningState } from "../infra/woning-schema";
import { LabelValuePair } from "../../shared/types";
import { AdresInput } from "../../shared/components/adres/adres-input";

const numberFormat = Intl.NumberFormat("nl", {
  style: "currency",
  currency: "EUR",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
});

type BestaandeWoningProps = {};

const BestaandeWoning = ({
  formik
}: BestaandeWoningProps & {
  formik: FormikContextType<WoningState>;
}): ReactElement => {
  return (
    <>
      {formik.values.huidigeWoningen.length > 1 && (
        <LabeledSelectInput
          name="hypotheekOpWoning"
          caption="Hypotheek op woning"
          options={formik.values.huidigeWoningen.map(
            (bestaandeWoning): LabelValuePair => ({
              value: bestaandeWoning.pandId,
              label: `Woning ${bestaandeWoning.volgNummer}, Marktwaarde ${numberFormat.format(
                bestaandeWoning.marktwaardeBedrag ? bestaandeWoning.marktwaardeBedrag : 0
              )}`
            })
          )}
        />
      )}
      {formik.values.huidigeWoningen.length > 0 && (
        <AdresInput
          readonly={true}
          name={`huidigeWoningen.${
            formik.values.hypotheekOpWoning
              ? formik.values.huidigeWoningen.findIndex((x): boolean => x.pandId === formik.values.hypotheekOpWoning)
              : 0
          }.adres`}
        />
      )}
    </>
  );
};

export default connect<BestaandeWoningProps, WoningState>(BestaandeWoning);
