import { SideEffects } from "use-formik-side-effects";
import { ObjectWithScenarioInstellingenData } from "./scenario-instellingen";
import { LocalDate } from "@js-joda/core";
import { birthdate2age } from "../../../shared/utils/birthdate-to-age";

export const determineScenarioInstellingenSideEffects: (
  name: string
) => SideEffects<ObjectWithScenarioInstellingenData> = (name): SideEffects<ObjectWithScenarioInstellingenData> => (
  curr,
  prev
): ObjectWithScenarioInstellingenData | null => {
  const currScenario = curr[name];
  const prevScenario = prev[name];
  if (!currScenario || !prevScenario) {
    throw new Error(`determineInkomensverledenSideEffects: missing name (${name}) in curr / prev`);
  }
  if (
    currScenario.overgangBijOverlijden !== prevScenario.overgangBijOverlijden &&
    currScenario.overgangBijOverlijden &&
    curr[name] &&
    currScenario.einddatum &&
    currScenario.geboortedatum &&
    currScenario.leeftijd !== birthdate2age(LocalDate.now(), currScenario.einddatum)
  ) {
    const newCurr = { ...curr };
    newCurr[name] = {
      ...currScenario,
      leeftijd: birthdate2age(currScenario.geboortedatum, currScenario.einddatum)
    };
    return newCurr;
  }

  if (
    currScenario.einddatum &&
    prevScenario.einddatum &&
    !currScenario.einddatum.equals(prevScenario.einddatum) &&
    currScenario.geboortedatum &&
    currScenario.einddatum
  ) {
    const newCurr = { ...curr };

    newCurr[name] = {
      ...currScenario,
      leeftijd: birthdate2age(currScenario.geboortedatum, currScenario.einddatum)
    };
    return newCurr;
  }

  if (
    currScenario.leeftijd !== prevScenario.leeftijd &&
    currScenario.geboortedatum &&
    currScenario.leeftijd &&
    currScenario.overgangBijOverlijden &&
    currScenario.leeftijd !== birthdate2age(currScenario.geboortedatum, LocalDate.now())
  ) {
    const einddatum = currScenario.geboortedatum.plusYears(currScenario.leeftijd);
    const newCurr = { ...curr };

    newCurr[name] = {
      ...currScenario,
      einddatum
    };
    return newCurr;
  }

  return null;
};
