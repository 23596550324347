import { ErrorPage, beschikbareOrvProducten, useRequestInit, FetchDataButton } from "adviesbox-shared";
import { FormikContextType, FormikProps } from "formik";
import { OrvVergelijkenModalType, orvVergelijkenModalSchema } from "../infra/orv-schema";
import React, { ReactElement, useContext } from "react";
import { organisatieDataApi, orvVergelijkerModalApi } from "../infra/orv-api";

import AdviesBoxFormik from "../../shared/utils/adviesbox-formik";
import { ISWSideEffects } from "../../shared/components/isw-side-effects/isw-side-effects";
import Modal from "../../shared/components/modal/Modal";
import { OrganisatieEx } from "../../.generated/licenties/licentiestypes";
import OrvVergelijkerDataGrid from "../orv-vergelijker-modal-data-grid/orv-vergelijker-modal-data-grid";
import { OrvVergelijkerOutput } from "../../.generated/forms/formstypes";
import { OrvVergelijkerPDFGenerator } from "../orv-vergelijker-modal-data-grid/orv-vergelijker-pdf/orv-vergelijker-pdf-generator";
import UserDetailsContext from "../../shared/user-details/user-details-context";
import VerzekerdenPremievergelijker from "../verzekerden-premievergelijker/verzekerdenPremievergelijker";
import VerzekeringPremievergelijker from "../verzekering-premievergelijker/verzekeringPremievergelijker";
import classes from "./orv-vergelijken-modal.module.scss";
import { getOrvTextResources } from "../infra/orv-resources";
import { mapOrvModalUi2Dl } from "../infra/map-orv-ui-2-dl";
import { mapOrvVergelijkerResultaat2DataGridResultaat } from "../infra/map-orv-dl-2-ui";
import { syncOrvVergelijkenModalSideEffects } from "./determine-sync-orv-vergelijken-modal-side-effects";
import { useInstellingenBeheerPartijenData } from "../../shared/hooks/use-instellingen-beheer-partijen-data";

type OrvVergelijkenModalProps = {
  data: OrvVergelijkenModalType;
  onSave?: (data: OrvVergelijkenModalType) => void;
  closeModal?: () => void;
};

export const OrvVergelijkenModal = ({ data, onSave, closeModal }: OrvVergelijkenModalProps): ReactElement => {
  const { settings, params, user } = useRequestInit<{ vestiging: string; adviesdossier: string }>();
  const { userDetails } = useContext(UserDetailsContext);
  const usersOrganisatieId = userDetails.organisatieId ? userDetails.organisatieId : null;
  const { data: ingesteldeVerzekeraarsData, error: verzekeraarsError } = useInstellingenBeheerPartijenData(
    "Verzekeraars"
  );

  if (verzekeraarsError) {
    return <ErrorPage error={verzekeraarsError} data={ingesteldeVerzekeraarsData} />;
  }

  const reFetchData = (
    setFieldValue: FormikContextType<OrvVergelijkenModalType>["setFieldValue"],
    value: OrvVergelijkenModalType
  ) => async (): Promise<void> => {
    if (!user || !ingesteldeVerzekeraarsData) throw Error("Er is onvoldoende data om verder te gaan");
    const list: OrvVergelijkerOutput = await orvVergelijkerModalApi(
      settings,
      user,
      params.vestiging,
      params.adviesdossier,
      mapOrvModalUi2Dl(value)
    );

    const org: OrganisatieEx = await organisatieDataApi(settings, user, usersOrganisatieId);

    /* istanbul ignore next */
    if (list.resultaten?.length && org) {
      const sortedValues = mapOrvVergelijkerResultaat2DataGridResultaat(
        list.resultaten,
        ingesteldeVerzekeraarsData.verzekeraars?.partijen || [],
        beschikbareOrvProducten
      );

      setFieldValue("organisatieData", org);
      setFieldValue("orvVergelijkerResultaat", sortedValues);
      setFieldValue("sortedOrvVergelijkerResultaat", sortedValues);
      setFieldValue("selectedResultaat", sortedValues[0]);
    }

    setFieldValue("dataOutdated", false);
  };

  return (
    <AdviesBoxFormik<OrvVergelijkenModalType>
      initialValues={data}
      validationSchema={orvVergelijkenModalSchema}
      onSave={onSave}
      closeModal={closeModal}
      validateOnMount={true}
      render={({ submitForm, values, setFieldValue }: FormikProps<OrvVergelijkenModalType>): ReactElement => (
        <Modal
          onSubmitClick={submitForm}
          onCancelClick={closeModal}
          title="ORV premievergelijker"
          invalid={values.orvVergelijkerResultaat.length ? false : true}
          saveButtonTekst="Selectie toevoegen aan ORV"
          body={
            <>
              <ISWSideEffects<OrvVergelijkenModalType> sync={syncOrvVergelijkenModalSideEffects} />
              <div className={"d-flex"}>
                <div className={"w-100 border mr-1 mb-2 p-2 rounded"}>
                  <h2>Verzekerde(n)</h2>
                  <VerzekerdenPremievergelijker verzekerden={values.verzekerden} />
                </div>
                <div className={"w-100 border ml-1 mb-2 p-2 rounded"}>
                  <h2>Verzekering</h2>
                  <VerzekeringPremievergelijker verzekering={values.verzekering} />
                </div>
              </div>
              <div>
                {!!values.orvVergelijkerResultaat.length && (
                  <div className={classes.pdf}>
                    <h3 className={classes.pdf_txt}>Resultaat</h3>
                    <OrvVergelijkerPDFGenerator values={values} />
                  </div>
                )}
                <div className={"d-flex justify-content-center"}>
                  <FetchDataButton
                    className={"mt-4"}
                    onClick={reFetchData(setFieldValue, values)}
                    dataOutDated={values.dataOutdated}
                    initialText={"Vergelijker starten"}
                    invalid={!values.verzekering.duurInJaren || !values.verzekering.verzekerdKapitaalBedrag}
                  />
                </div>
                {!!values.orvVergelijkerResultaat.length && <OrvVergelijkerDataGrid />}
                {!values.orvVergelijkerResultaat.length && (
                  <div className="d-flex align-items-center justify-content-center">
                    <div className={classes.emptyGrid}>
                      <div className={classes.header}>{getOrvTextResources("GeenORV")}</div>
                      <div className={classes.text}>{getOrvTextResources("VergelijkerStarten")}</div>
                    </div>
                  </div>
                )}
              </div>
            </>
          }
        />
      )}
    />
  );
};
