import { AdviesBoxColumn, AdviesBoxFormik, createSpanWithId, DataGrid, FilterType, PlatformFoutenSamenvatting } from "adviesbox-shared";
import { FormikContextType, FormikProps } from "formik";
import React, { ReactElement, useContext, useState } from "react";
import { ISWSideEffects } from "../../../shared/components/isw-side-effects/isw-side-effects";
import Modal from "../../../shared/components/modal/Modal";
import {
  GeldverstrekkerNwwiModalSchema,
  GeldverstrekkerNwwiModalType,
  GeldverstrekkerNwwiResultaatType
} from "../../infra/taxatie-scherm-schema";
import { determineGeldverstrekkerAsyncSideEffects } from "./determine-geldverstrekker-async-side-effects";
import classes from "../algemeen-taxatie-data-grid.module.scss";
import { AppDataContext } from "../../../navigation/appdata-context";

type GeldverstrkkerModalProps = {
  data: GeldverstrekkerNwwiModalType;
  onSave?: (data: GeldverstrekkerNwwiModalType) => void;
  closeModal?: () => void;
};

const GeldverstrekkerModalColumns: AdviesBoxColumn[] = [
  {
    Header: "Geldverstrekker",
    accessor: "bedrijfsnaam",
    Cell: (c): ReactElement => createSpanWithId(c.index, 1, c.original.bedrijfsnaam, c.original.bedrijfsnaam),
    style: { justifyContent: "flex-start" },
    maxWidth: 350
  }
];

const GeldverstrekkerModal = ({ data, closeModal, onSave }: GeldverstrkkerModalProps): ReactElement => {
  const { setSErunning } = useContext(AppDataContext);
  const selectedRow = useState(0);
  const [, setSelectedRow] = selectedRow;
  const body = (
    resultaat: GeldverstrekkerNwwiResultaatType[],
    resultaatIsLeeg: boolean | null,
    setFieldValue: FormikContextType<GeldverstrekkerNwwiModalType>["setFieldValue"],
    selectedResultaat: GeldverstrekkerNwwiResultaatType | null,
    restObject: any
  ): ReactElement => {
    const onDataGridChange = (data?: GeldverstrekkerNwwiResultaatType[]): void => {
      if (!selectedResultaat || !data?.length) return;
      const indexOfSelected = data?.indexOf(selectedResultaat);
      if (indexOfSelected === -1) {
        setFieldValue("selectedResultaat", data[0]);
        setSelectedRow(0);
        return;
      }
      setSelectedRow(indexOfSelected);
    };

    return (
      <>
        <PlatformFoutenSamenvatting className={classes.platform_errors} />
        <div className="px-2">
          {!resultaat?.length && !resultaatIsLeeg && !restObject?.platformApiFouten?.length && (
            <div className="loader" data-testid="loader"></div>
          )}
          <DataGrid
            masterDetail
            className={classes.bordered_result}
            name="resultaat"
            columns={GeldverstrekkerModalColumns}
            filterable={true}
            rowSelected={selectedRow}
            filteredCallback={onDataGridChange}
            sortedCallback={onDataGridChange}
            filterConfig={{
              bedrijfsnaam: FilterType.Text
            }}
            getTrProps={(_: any, rowInfo: any) => {
              if (rowInfo && rowInfo.row) {
                return {
                  onClick: () => {
                    setFieldValue("selectedResultaat", rowInfo.original);
                  }
                };
              }
            }}
          />
        </div>
      </>
    );
  };

  return (
    <AdviesBoxFormik<GeldverstrekkerNwwiModalType>
      initialValues={{ ...data }}
      validationSchema={GeldverstrekkerNwwiModalSchema}
      closeModal={closeModal}
      onSave={(values): void => onSave && onSave(values)}
      render={({
        values: { agentnummer, postcode, doelTaxatie, resultaat, resultaatIsLeeg, selectedResultaat, ...restObject },
        setFieldValue,
        submitForm
      }: FormikProps<GeldverstrekkerNwwiModalType>): ReactElement => {
        return (
          <>
            <ISWSideEffects<GeldverstrekkerNwwiModalType>
              async={determineGeldverstrekkerAsyncSideEffects({ agentnummer, postcode, doelTaxatie })}
              runOnFirstRender
              asyncStartStopCallback={setSErunning}
            />
            <Modal
              title="Geldverstrekker"
              body={body(resultaat, resultaatIsLeeg, setFieldValue, selectedResultaat, restObject)}
              onSubmitClick={submitForm}
              onCancelClick={closeModal}
              saveButtonTekst="Geldverstrekker selecteren"
            />
          </>
        );
      }}
    />
  );
};

GeldverstrekkerModal.displayName = "GeldverstrekkerModal";
export default GeldverstrekkerModal;
