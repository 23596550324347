import { mapJaarMaandInputFromLooptijdDl2Ui } from "adviesbox-shared";
import { AovProduct, AovResultaat } from "../../.generated/forms/formstypes";
import {
  createISWAsyncSideEffect,
  initISWAsyncSideEffect
} from "../../shared/components/isw-side-effects/create-isw-helpers";
import { AovsType } from "./aov-schema";
import { mapProductUi2Dl } from "./map-aov-ui-to-dl";
import { AovKenmerken } from "../../producten-overzicht/infra/product-kenmerken-types";

/* TODO: remove these example types */
type ExampleOutput = { exampleResult: string };

type AovAsyncContext = {
  selected: number;
  productkenmerken: AovKenmerken | null;
};

export const berekenAovPremieAsync = createISWAsyncSideEffect<AovsType, AovAsyncContext>(
  async ({ draft, settings, fetchData, context, params, formik }) => {
    const { selected } = context;
    const input = mapProductUi2Dl({
      aanvrager1: draft.aanvrager1,
      aanvrager2: draft.aanvrager2,
      productkenmerken: context.productkenmerken
    })(draft.producten[selected]);
    let AovJson;
    try {
      AovJson = await fetchData<AovResultaat, AovProduct>({
        url: `${settings.klantdossiersFormsOrigin}/Voorstellen/${params.voorstel}/Aov`,
        body: JSON.stringify({ aovProduct: input })
      });  
    } catch (e) {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      })
      return;
    }
    let firstEmptyEncountered = false;

    if (AovJson.premies && AovJson.premies.length > 0) {
      if (AovJson.verzekerden && AovJson.verzekerden.length > 0) {
        draft.producten[selected].dekking.nettoMaandinkomen = AovJson.verzekerden[0].nettoInkomen;

        const ao = draft.producten[selected].dekking.dekkingGegevensVerzekerde1.ao;
        if (ao) {
          ao.verzekerdBedrag = AovJson.verzekerden[0].verzekerdBedragVolledigeAo;
          ao.verzekerdBedragGedeeltelijkAo = AovJson.verzekerden[0].verzekerdBedragGedeeltelijkeAo;
        }

        const ww = draft.producten[selected].dekking.dekkingGegevensVerzekerde1.ww;
        if (ww) {
          ww.verzekerdBedrag = AovJson.verzekerden[0].verzekerdBedragVolledigeAo;
        }
      }

      draft.producten[selected].premiegegevens.premieAo = AovJson.premies[0].aoBedrag;
      draft.producten[selected].premiegegevens.premieWw =
        AovJson.premies[0].wwBedrag !== 0 ? AovJson.premies[0].wwBedrag : null;
      draft.producten[selected].premiegegevens.premie = AovJson.premies[0].bedrag;
      if (draft.producten[selected].partijCode === "CA" && AovJson.premies[0].looptijdInMaanden !== null) {
        draft.producten[selected].product.looptijd = mapJaarMaandInputFromLooptijdDl2Ui(
          AovJson.premies[0].looptijdInMaanden
        );
      }

      if (AovJson.aoDekking?.verzekerdeBedragen && AovJson.aoDekking.verzekerdeBedragen.length > 0) {
        draft.producten[
          selected
        ].dekking.verzekerdBedragVerzekerdBedragModalValues.scenario = AovJson.aoDekking.verzekerdeBedragen
          .filter(x => {
            const encountered = firstEmptyEncountered;
            if (!x.bedrag) {
              firstEmptyEncountered = true;
            }

            return !encountered;
          })
          .map(x => {
            return { bedrag: x.bedrag, percentage: null, jaartal: x.jaartal };
          });
        const ao = draft.producten[selected].dekking.dekkingGegevensVerzekerde1.ao;
        if (ao) {
          ao.verzekerdBedrag = AovJson.aoDekking.verzekerdeBedragen[0].bedrag;
        }
      }

      const ww = draft.producten[selected].dekking.dekkingGegevensVerzekerde1.ww;
      if (
        ww &&
        (ww.verzekerdBedrag === null ||
          ww.verzekerdBedrag === 0 ||
          (AovJson.wwDekking?.verzekerdMaandBedrag != null && ww.verzekerdBedrag !== AovJson.wwDekking?.verzekerdMaandBedrag))
      ) {
        /* istanbul ignore next */
        ww.verzekerdBedrag = AovJson.wwDekking?.verzekerdMaandBedrag ?? 0;
      }
    }
  }
);

export const asyncAovSideEffects = initISWAsyncSideEffect<AovsType, AovAsyncContext>(
  ({ has, runAsync, context, curr }) => {
    if (has.producten[context.selected].toggleBerekenen.changed) {
      runAsync(berekenAovPremieAsync(context));
    }
  }
);
