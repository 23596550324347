import { useRequestInit } from "adviesbox-shared";
import React, { ReactElement, ReactNode, useCallback, useEffect, useState } from "react";
import { SituatieSoort } from "../../producten-overzicht/infra/producten-overzicht-types";
import { dashboardInit, OnderwerpenType } from "./bereken-scenarios-api";
import DashboardContext, { DashboardValuesType, defaultDashboardContextValues, defaultDashboardValues } from "./dashboard-context";

export type DashboardProviderProperties = {
    children: ReactNode,
    situatie: SituatieSoort
}

const dashboardProviderValueCreator = (
    dashboardValues: DashboardValuesType,
    setDashboardValues: React.Dispatch<React.SetStateAction<DashboardValuesType>>
): typeof defaultDashboardContextValues => ({
    dashboardValues,
    setDashboardValues
});

const DashboardProvider = ({ children }: DashboardProviderProperties): ReactElement => {
    const [dashboardValues, setDashboardValues] = useState<DashboardValuesType>({
        ...defaultDashboardValues
    });
    const { settings, params, requestInit } = useRequestInit<{
        vestiging: string;
        adviesdossier: string;
        voorstel: string;
    }>();

    const asyncGetOnderwerpen = useCallback(async (): Promise<string | OnderwerpenType | null> => {
        setDashboardValues({ ...dashboardValues, isLoading: true });
        const initResult = await dashboardInit(
            `${settings.klantdossiersFormsOrigin}/Adviesdossiers/${params.adviesdossier}/Dashboard/Onderwerpen`,
            requestInit
        );
        return initResult;
    }, [dashboardValues, setDashboardValues, settings, params, requestInit]);

    const updateFormikValues = useCallback((initResult: string | OnderwerpenType | null): void => {
        let formikValues = dashboardValues.formikValues;

        if (typeof initResult === "string") formikValues = {
            ...formikValues,
            initErrors: initResult
        };

        const onderwerpen = initResult as OnderwerpenType;

        /* istanbul ignore next */
        if (onderwerpen) {
            formikValues.overlijdenWeergeven = onderwerpen.overlijden;
            formikValues.arbeidsongeschiktheidWeergeven = onderwerpen.arbeidsongeschiktheid;
            formikValues.werkloosheidWeergeven = onderwerpen.werkloosheid;
            formikValues.pensioenWeergeven = onderwerpen.pensioen;
        }

        setDashboardValues({ ...dashboardValues, formikValues, isLoading: false, initialized: true });
    }, [dashboardValues, setDashboardValues]);

    const setGetError = useCallback((): void => {
        const formikValues = {
            ...dashboardValues.formikValues,
            initErrors: "Er is een uitzondering opgetreden bij het ophalen van de gegevens."
        }
        setDashboardValues({ ...dashboardValues, formikValues, isLoading: false, initialized: true });
    }, [dashboardValues, setDashboardValues]);

    useEffect((): void => {
        if (dashboardValues.initialized || dashboardValues.isLoading) return;
        asyncGetOnderwerpen().then((result) => { updateFormikValues(result) }).catch(() => { setGetError(); });
    }, [dashboardValues, asyncGetOnderwerpen, updateFormikValues, setGetError]);

    const val = dashboardProviderValueCreator(dashboardValues, setDashboardValues);
    return <DashboardContext.Provider value={val}>{children}</DashboardContext.Provider>;
}

export default DashboardProvider;