import { InferType } from "yup";
import { HypothekenKenmerken, KenmerkenError } from "../../producten-overzicht/infra/product-kenmerken-types";
import { SituatieSoort } from "../../producten-overzicht/infra/producten-overzicht-types";
import { Return } from "../../shared/hooks/use-map";
import { WithSaveData } from "../../shared/utils/save-data";
import {
  aflosProductSchema,
  extraAflossingenModalSchema,
  extraAflossingenSchema,
  fiscaleVoortzettingKeuzeSchema,
  gekoppeldProductSchema,
  hypotheekFiscaleRegelingSchema,
  hypotheekOptieSchema,
  hypotheekOptiesIngPriceToolLeningdeelSchema,
  hypotheekOptiesIngPriceToolSchema,
  hypotheekOptiesSchema,
  hypotheekProductDetailsSchema,
  hypotheekProductSchema,
  hypotheekSchema,
  hypotheekSelectieSamenstellingSchema,
  hypotheekVormSchema,
  hypothekenBaseSchema,
  kapitaalopbouwSchema,
  leningdeelgegevensSchema,
  leningdeelKeuzeModalSchema,
  leningdeelKeuzeVoorstelModalSchema,
  maatschappijProductSchema,
  pandHypotheekBedragenSchema,
  pandSchema,
  renteAftrekSchema,
  rentedalingObvMarktwaardePercentageModalSchema,
  rentedalingPercentagesSchema,
  renteScenarioModalSchema,
  scenarioCardInputSchema,
  schuldenaarsSchema,
  specificatieRenteaftrekModalSchema,
  verzekerdenSchema,
  verzekeringnemersSchema,
  hypotheeklabelSchema
} from "./hypotheek-schema";

export type HypothekenState = InferType<typeof hypothekenBaseSchema>;
export type HypothekenProps = HypothekenState &
  WithSaveData<HypothekenState> & { situatie: SituatieSoort } & {
    productKenmerkenMap: Return<string, HypothekenKenmerken | KenmerkenError>;
  };

export type HypotheekType = InferType<typeof hypotheekSchema>;

export type HypotheekSelectieSamenstellingType = InferType<typeof hypotheekSelectieSamenstellingSchema>;

export type HypotheekProductDetailsType = InferType<typeof hypotheekProductDetailsSchema>;

export type HypotheekProductOnderdeelType = InferType<typeof hypotheekProductSchema>;

export type SchuldenaarsType = InferType<typeof schuldenaarsSchema>;

export type VerzekerdenType = InferType<typeof verzekerdenSchema>;

export type VerzekeringnemersType = InferType<typeof verzekeringnemersSchema>;

export type LeningdeelgegevensType = InferType<typeof leningdeelgegevensSchema>;

export type GekoppeldProductType = InferType<typeof gekoppeldProductSchema>;

export type HypotheekVormType = InferType<typeof hypotheekVormSchema>;

export type RentedalingObvMarktwaardePercentageModalType = InferType<
  typeof rentedalingObvMarktwaardePercentageModalSchema
>;
export type RentedalingObvMarktwaardePercentagesModalType = InferType<typeof rentedalingPercentagesSchema>;

export type RenteScenarioModalType = InferType<typeof renteScenarioModalSchema>;
export type scenarioCardInputType = InferType<typeof scenarioCardInputSchema>;
export type RentedalingPercentagesType = InferType<typeof rentedalingPercentagesSchema>;

export type ExtraAflossingenModalType = InferType<typeof extraAflossingenModalSchema>;
export type ExtraAflossingen = InferType<typeof extraAflossingenSchema>;

export type HypotheekOptieType = InferType<typeof hypotheekOptieSchema>;
export type HypotheekOptiesType = InferType<typeof hypotheekOptiesSchema>;

export type HypotheekOptiesIngPriceToolType = InferType<typeof hypotheekOptiesIngPriceToolSchema>;
export type HypotheekOptiesIngPriceToolLeningdeelType = InferType<typeof hypotheekOptiesIngPriceToolLeningdeelSchema>;

export type SpecificatieRenteaftrekModalType = InferType<typeof specificatieRenteaftrekModalSchema>;
export type RenteAftrek = InferType<typeof renteAftrekSchema>;

export type PandType = InferType<typeof pandSchema>;
export type KapitaalopbouwType = InferType<typeof kapitaalopbouwSchema>;

export type FiscaleVoortzettingKeuzeType = InferType<typeof fiscaleVoortzettingKeuzeSchema>;

export type HypotheekProductSelectieStateType = InferType<typeof leningdeelKeuzeModalSchema>;
export type HypotheekProductSelectieVoorstelStateType = InferType<typeof leningdeelKeuzeVoorstelModalSchema>;
export type AflosProductType = InferType<typeof aflosProductSchema>;
export type MaatschappijProductType = InferType<typeof maatschappijProductSchema>;

export type showEdit = {
  visible: boolean;
  edit: boolean;
  productOnly: boolean;
  omzetten: boolean;
};

export enum HypotheekLeningdeelRentevariant {
  Rentevast = "Rentevast",
  Variabel = "Variabel"
}

export enum SoortOnderpand {
  Geen = 0,
  BestaandeBouw = 1,
  BestaandeVerbouw = 2,
  Nieuwbouw = 3
}

export type PandHypotheekBedrag = InferType<typeof pandHypotheekBedragenSchema>;

export type HypotheekFiscaleRegelingType = InferType<typeof hypotheekFiscaleRegelingSchema>;

export type PandRestantType = {
  gelinktePand: PandType;
  opgeteldeLeningdrag: number | null;
};

export type HypotheeklabelType = InferType<typeof hypotheeklabelSchema>;
