import React, { ReactElement } from "react";
import { connect, FormikContextType } from "formik";

import { SelectedProps } from "../../shared/types";
import { getPensioenTextResources } from "../infra/pensioen-resources";
import { LabeledCurrencyInput, LabeledDateInput, LabeledPercentageInput, BerekenCurrencyInput } from "adviesbox-shared";
import { PensioenenState, Pensioenregelingsoort } from "../infra/pensioen-schema";

const Pensioengrondslag = ({
  formik: {
    setFieldValue,
    values: { pensioenen }
  },
  selected
}: SelectedProps & {
  formik: FormikContextType<PensioenenState>;
}): ReactElement => {
  if (selected >= pensioenen.length) return <></>;

  return (
    <>
      <BerekenCurrencyInput
        caption="Pensioengevend salaris"
        name={`pensioenen[${selected}].pensioengrondslag.pensioengevendSalaris`}
      />

      {(pensioenen[selected].pensioenregeling.soortPensioenregeling === Pensioenregelingsoort.Eindloon ||
        pensioenen[selected].pensioenregeling.soortPensioenregeling === Pensioenregelingsoort.Middelloon) && (
        <BerekenCurrencyInput
          caption="Franchise"
          name={`pensioenen[${selected}].pensioengrondslag.franchise`}
          tooltip={getPensioenTextResources("pensioengrondslagTooltipFranchise")}
        />
      )}
      {(pensioenen[selected].pensioenregeling.soortPensioenregeling === Pensioenregelingsoort.Eindloon ||
        pensioenen[selected].pensioenregeling.soortPensioenregeling === Pensioenregelingsoort.Middelloon) && (
        <BerekenCurrencyInput
          caption="Pensioengrondslag"
          name={`pensioenen[${selected}].pensioengrondslag.pensioengrondslag`}
        />
      )}
      {pensioenen[selected].pensioenregeling.soortPensioenregeling === Pensioenregelingsoort.BeschikbarePremie && (
        <>
          <LabeledPercentageInput
            caption="Rekenrente opbouwfase"
            name={`pensioenen[${selected}].pensioengrondslag.rekenrenteOpbouwfase`}
            decimalen={3}
          />

          <LabeledPercentageInput
            caption="Rekenrente uitkeringsfase"
            name={`pensioenen[${selected}].pensioengrondslag.rekenrenteUitkeringfase`}
            decimalen={3}
          />

          <LabeledCurrencyInput
            caption="Doelvermogen"
            verplicht
            name={`pensioenen[${selected}].pensioengrondslag.doelvermogen`}
          />
        </>
      )}
      <BerekenCurrencyInput
        caption="Pensioenaangroei (factor A)"
        name={`pensioenen[${selected}].pensioengrondslag.pensioenaangroei`}
      />

      <LabeledCurrencyInput
        caption="Huidig opgebouwd bedrag"
        name={`pensioenen[${selected}].pensioengrondslag.huidigOpgebouwdBedrag`}
        tooltip={getPensioenTextResources("pensioengrondslagTooltipHuidigOpgebouwdBedrag")}
      />

      <LabeledDateInput caption="Datum overzicht" name={`pensioenen[${selected}].pensioengrondslag.datumOverzicht`} />
    </>
  );
};

export default connect<SelectedProps, PensioenenState>(Pensioengrondslag);
