import { AdviesBoxFormik, Card, CardWrapper, ErrorPage, PageLoading, SettingsType } from "adviesbox-shared";
import { FormikContextType, FormikHelpers, FormikProps, useFormikContext } from "formik";
import React, { ReactElement, useState } from "react";
import { Collapse } from "react-bootstrap";
import { InkomensAnalysePdf } from "../../inkomens-analyse/inkomens-analyse-pdf";
import { MotiveringOnderwerpsoort } from "../../.generated/forms/formstypes";
import { InvalidAdviesboxResultErrorPage } from "../../shared/components/fetch-error-page/fetch-error-page";
import { ISWSideEffects } from "../../shared/components/isw-side-effects/isw-side-effects";
import Modal from "../../shared/components/modal/Modal";
import { useAdviesboxData } from "../../shared/hooks/use-adviesbox-data";
import { useSaveBeforeLoad } from "../../shared/hooks/use-save-before-load";
import { RouteParams } from "../../shared/paramrouting/paramrouting-context";
import { UiError } from "../../shared/types";
import { getNaam } from "../../shared/utils/helpers";
import { setFormikUiErrors } from "../../shared/utils/set-formik-ui-errors";
import { ControleNorm } from "../components/controle-norm/controle-norm";
import { ScenarioVan } from "../components/scenario-van/scenario-van";
import { DashboardState } from "../infra/dashboard-types";
import { mapDashboardDlTargetToUiField, mapDashboardOutput } from "../infra/map-dashboard-dl-2-ui";
import { mapDashboardUiToDl } from "../infra/map-dashboard-ui-2-dl";
import { syncScenarioWerkloosheidModalSideEffects } from "./infra/determine-sync-scenario-werkloosheid-modal-side-effects";
import {
  ScenarioWerkloosheidModalProps,
  scenarioWerkloosheidModalSchema
} from "./infra/scenario-werkloosheid-modal-schema";

export const ScenarioWerkloosheidModal = (props: ScenarioWerkloosheidModalProps): ReactElement => {
  /* istanbul ignore next*/
  const createUrl = (invoorstel: boolean) => (s: SettingsType, p: RouteParams): string =>
    `${s.klantdossiersFormsOrigin}/${invoorstel ? "Voorstellen" : "Adviesdossiers"}/${invoorstel ? p.voorstel : p.adviesdossier
    }/Dashboard/Werkloosheid?berekenTekort=false`;

  const { loading, error, data, platformData, saveData } = useAdviesboxData(
    createUrl(props.inVoorstel || false),
    p => p.adviesdossier,
    mapDashboardOutput,
    (values: DashboardState): any => {
      const mapped = mapDashboardUiToDl(values);

      return {
        ...mapped,
        pensioen: null,
        arbeidsongeschiktheid: null,
        overlijden: null
      };
    },
    mapDashboardDlTargetToUiField,
    true
  );

  const { loading: saveBeforeLoading } = useSaveBeforeLoad(true);

  const ModalBody = (): ReactElement => {
    const [showInstellingen, setInstellingenVisible] = useState(true);
    const formik = useFormikContext<DashboardState>();
    const { values } = formik;

    /* istanbul ignore next */
    const saveAndSetErrors = async (): Promise<void> => {
      const result = await saveData(values);
      if (result !== null && result instanceof Array) {
        setFormikUiErrors(result, formik as FormikContextType<DashboardState>);
      } else {
        setFormikUiErrors([], formik as FormikContextType<DashboardState>);
      }
    };

    return (
      <>
        <ISWSideEffects<DashboardState> sync={syncScenarioWerkloosheidModalSideEffects} />

        <Collapse in={showInstellingen}>
          <div id="instellingen-ww" data-testid="instellingen-ww-collapse">
            <CardWrapper>
              <Card title="Controle op norm">
                <ControleNorm namePrefix="werkloosheid" />
              </Card>
              {values.aanvrager2 && (
                <Card title="Scenario van">
                  <ScenarioVan namePrefix="werkloosheid" beideTonen={!!values.aanvrager2} />
                </Card>
              )}
            </CardWrapper>
          </div>
        </Collapse>

        <CardWrapper className="px-3">
          <div className="text-container" style={{ textAlign: "right", width: "100%", minHeight: "70px" }}>
            <button
              id="opslaan"
              onClick={() => {
                setInstellingenVisible(!showInstellingen);
              }}
              className="btn btn-light ml-1"
              type="button"
              aria-controls="instellingen-ww"
              aria-expanded={showInstellingen}
            >
              {showInstellingen ? <>Instellingen verbergen</> : <>Instellingen tonen</>}
            </button>
          </div>
        </CardWrapper>

        {values.aanvrager1 && values.werkloosheid.scenarioVan.aanvrager1Geselecteerd && (
          <CardWrapper flexType={"flex-column"} maxRowCount={1}>
            <Card
              title="Inkomensanalyse"
              fullscreenTonen
              subtitle={`Jouw inkomen bij werkloosheid van ${getNaam(values.aanvrager1, "Aanvrager")}`}
              subtitleOnSeparateLine
              subtitleSplit
              className="full-width-card"
              collapseExpandOption
            >
              <InkomensAnalysePdf
                motivering={MotiveringOnderwerpsoort.AfdekkenWW}
                klantId={values.aanvrager1.klantId}
                onRefresh={saveAndSetErrors}
                dataOutdated={values.data1Outdated}
                setDataOutdated={/* istanbul ignore next */ value => formik.setFieldValue("data1Outdated", value)}
              />
            </Card>
          </CardWrapper>
        )}
        {values.aanvrager2 && values.werkloosheid.scenarioVan.aanvrager2Geselecteerd && (
          <CardWrapper flexType={"flex-column"} maxRowCount={1}>
            <Card
              title="Inkomensanalyse"
              fullscreenTonen
              subtitle={`Jouw inkomen bij werkloosheid van ${getNaam(values.aanvrager2, "Partner")}`}
              subtitleOnSeparateLine
              subtitleSplit
              className="full-width-card"
              collapseExpandOption
            >
              <InkomensAnalysePdf
                motivering={MotiveringOnderwerpsoort.AfdekkenWW}
                klantId={values.aanvrager2.klantId}
                onRefresh={saveAndSetErrors}
                dataOutdated={values.data2Outdated}
                setDataOutdated={/* istanbul ignore next */ value => formik.setFieldValue("data2Outdated", value)}
              />
            </Card>
          </CardWrapper>
        )}
        {values.werkloosheid.scenarioVan.beiden && values.aanvrager2 && (
          <CardWrapper flexType={"flex-column"} maxRowCount={1}>
            <Card
              title="Inkomensanalyse"
              fullscreenTonen
              subtitle={`Jouw inkomen bij werkloosheid van beide`}
              subtitleOnSeparateLine
              subtitleSplit
              className="full-width-card"
              collapseExpandOption
            >
              <InkomensAnalysePdf
                motivering={MotiveringOnderwerpsoort.AfdekkenWW}
                onRefresh={saveAndSetErrors}
                dataOutdated={values.dataBeideOutdated}
                setDataOutdated={/* istanbul ignore next */ value => formik.setFieldValue("dataBeideOutdated", value)}
              />
            </Card>
          </CardWrapper>
        )}
      </>
    );
  };

  if (saveBeforeLoading) {
    return <></>;
  }

  if (loading) {
    return <PageLoading />;
  }

  if (error) {
    return <ErrorPage error={error} data={platformData as any} />;
  }

  if (!data) {
    return <InvalidAdviesboxResultErrorPage />;
  }

  return (
    <AdviesBoxFormik<DashboardState>
      initialValues={data}
      validationSchema={scenarioWerkloosheidModalSchema}
      onSaveAsync={async (
        values: DashboardState,
        formikHelpers: FormikHelpers<
          DashboardState & {
            platformApiFouten?: UiError[] | null | undefined;
          }
        >
      ): Promise<boolean> => {
        const result = await saveData(values);
        if (result !== null && result instanceof Array)
          /* istanbul ignore next */
          setFormikUiErrors(result, formikHelpers as FormikContextType<DashboardState>);

        /* istanbul ignore next */ props.callbackOnClose && props.callbackOnClose();

        return result === null ? true : false;
      }}
      closeModal={props.closeModal}
      render={({ submitForm }: FormikProps<DashboardState>): ReactElement => (
        <div className="modal-with-cards">
          <Modal
            title="Scenario werkloosheid"
            body={<ModalBody />}
            onSubmitClick={submitForm}
            onCancelClick={props.closeModal}
          />
        </div>
      )}
    />
  );
};
