import React, { ReactElement } from "react";
import "./card-loading-spinner.scss";

export const CardLoadingSpinner = (): ReactElement => {
  return (
    <>
      <div className="loading__wrapper">
        <div className="loading__container">
          <div className="loading__spinner spinner-border text-custom" role="status">
            <span className="visually-hidden"></span>
          </div>
          <div className="loading__text">Laden ...</div>
        </div>
      </div>
    </>
  );
};
