import { Afbeelding } from "adviesbox-shared";
import React, { ReactElement } from "react";
import { GeslachtOpties } from "../../../.generated/forms/formstypes";
import { AanvragerKeuze } from "../../../shared/types";
import { getFormattedDate } from "../../../shared/utils/dates";
import { getNaam } from "../../../shared/utils/helpers";
import { ModalVerzekerdenType } from "../../infra/orv-schema";
import classes from "./orv-vergelijker-pdf.module.scss";

type OrvPDFHeaderProps = {
  bedrijfslogo: string | null | undefined;
  verzekerden: ModalVerzekerdenType;
};

export const renderOrvPDFHeader = ({ bedrijfslogo, verzekerden }: OrvPDFHeaderProps): ReactElement => {
  const displayAanvrager1 =
    verzekerden?.verzekerden === AanvragerKeuze.Aanvrager1 || verzekerden?.verzekerden === AanvragerKeuze.Beiden;
  const displayAanvrager2 =
    !!verzekerden?.aanvrager2 &&
    (verzekerden?.verzekerden === AanvragerKeuze.Aanvrager2 || verzekerden?.verzekerden === AanvragerKeuze.Beiden);

  const bedrijfsImg = new Image();
  if (bedrijfslogo) bedrijfsImg.src = `data:image/png;base64,${bedrijfslogo}`;

  return (
    <>
      <div className={classes.pdf_header_container}>
        <h1 className={classes.pdf_header}>Overlijdensrisicoverzekering vergelijker</h1>
        <div className={classes.pdf_header}>
          <Afbeelding name="orgData.logo" alt="bedrijfslogo" location={bedrijfsImg.src} style={{ maxHeight: 60 }} />
        </div>
      </div>
      <table className={classes.pdf_table}>
        <thead>
          <tr>
            <th className={classes.pdf_table_header}></th>
            {displayAanvrager1 && (
              <th className={`${classes.pdf_table_header_center}`}>
                {verzekerden?.aanvrager1?.geslacht === GeslachtOpties.Man ? "Dhr." : "Mevr."}&nbsp;
                {getNaam(verzekerden?.aanvrager1, "Aanvrager 1")}
              </th>
            )}
            {displayAanvrager2 && (
              <th className={classes.pdf_table_header}>
                {verzekerden?.aanvrager2?.geslacht === GeslachtOpties.Man ? "Dhr." : "Mevr."}&nbsp;
                {getNaam(verzekerden?.aanvrager2, "Aanvrager 2")}
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className={classes.pdf_table_data_info}>Geboortedatum</td>
            {displayAanvrager1 && (
              <td className={`${classes.pdf_table_data}`} style={{ backgroundColor: "#f0f0f0" }}>
                {!!verzekerden?.aanvrager1?.geboortedatum ? getFormattedDate(verzekerden.aanvrager1.geboortedatum) : ""}
              </td>
            )}
            {displayAanvrager2 && (
              <td className={`${classes.pdf_table_data}`} style={{ backgroundColor: "#f0f0f0" }}>
                {!!verzekerden?.aanvrager2?.geboortedatum ? getFormattedDate(verzekerden.aanvrager2.geboortedatum) : ""}
              </td>
            )}
          </tr>
          <tr>
            <td className={classes.pdf_table_data_info}>Roker</td>
            {displayAanvrager1 && (
              <td className={`${classes.pdf_table_data}`}>{verzekerden?.verzekerde1Roker ? "Ja" : "Nee"}</td>
            )}
            {displayAanvrager2 && (
              <td className={`${classes.pdf_table_data}`}>{verzekerden?.verzekerde2Roker ? "Ja" : "Nee"}</td>
            )}
          </tr>
        </tbody>
      </table>
    </>
  );
};
