import React, { ReactElement } from "react";

import { FormikProps } from "formik";
import { ScenarioCard } from "../../shared/components/scenario-input/scenario-card";
import AdviesBoxFormik from "../../shared/utils/adviesbox-formik";
import Modal from "../../shared/components/modal/Modal";
import { RenteScenarioModalType } from "../infra/hypotheek-types";
import { renteScenarioModalSchema } from "../infra/hypotheek-schema";
import { getHypotheekTextResources } from "../infra/hypotheek-resources";
import { LabeledBevestigingInput } from "adviesbox-shared";

export type RenteScenarioModalData = {
  data: RenteScenarioModalType;
};

type RenteScenarioModalProps = {
  onSave?: (data: RenteScenarioModalType) => void;
  closeModal?: () => void;
};

const RenteScenarioModal = ({
  onSave,
  closeModal,
  data
}: RenteScenarioModalProps & RenteScenarioModalData): ReactElement => {
  const body = (
    <>
      <LabeledBevestigingInput caption="Vervolgjaren automatisch invullen" name="vervolgjarenAutomatischInvullen" />
      <ScenarioCard
        name={`renteScenario`}
        percentageOnly={true}
        nameVervolgjarenAutomatischInvullen="vervolgjarenAutomatischInvullen"
        decimals={2}
      />
    </>
  );

  return (
    <AdviesBoxFormik<RenteScenarioModalType>
      initialValues={data}
      validationSchema={renteScenarioModalSchema}
      onSave={onSave}
      closeModal={closeModal}
      render={({ submitForm }: FormikProps<RenteScenarioModalType>): ReactElement => (
        <>
          <Modal
            title="Scenario"
            tooltip={getHypotheekTextResources("RenteScenarioTooltip")}
            body={body}
            onSubmitClick={submitForm}
            onCancelClick={closeModal}
          />
        </>
      )}
    />
  );
};

export default RenteScenarioModal;
