import React, { ReactElement } from "react";
import { Card, Button } from "react-bootstrap";
import { Column, CellInfo } from "react-table-6";

import { DataGrid, createSpanWithId, Icon } from "adviesbox-shared";
import { FormikContextType, connect } from "formik";
import { HypotheekVergelijkenModalType } from "../hypotheek-vergelijken-types";
import { bedragFormat } from "../../../shared/utils/currency";
import { jaarMaandInputNaarJaarMaand } from "../../../shared/generic-parts/jaar-maand/helpers";
import { RentevariantOptions } from "../../../.generated/forms/formstypes";
import { hypotheekVergelijkenSchema } from "../hypotheek-vergelijken-schema";
import { getAflosvormText } from "../hypotheek-vergelijken-helper";

export const DeleteButtonHypotheekVerlijken = (
  formik: FormikContextType<HypotheekVergelijkenModalType>,
  selectedState: [number, React.Dispatch<React.SetStateAction<number>>]
): ((c: CellInfo) => ReactElement) => (c: CellInfo): ReactElement => {
  const [selected, setSelected] = selectedState;
  const heeftRestschuld = c.original.hypotheekVorm.isRestschuldLening;
  const heeftStartersleningNietDoorlopend = c.original.hypotheekVorm.isStartersLening && !c.original.product.doorlopend;
  const { setFieldValue, values } = formik;

  const onClick = (): void => {
    const update = [...values.hypotheekSamenstelling];
    update.splice(c.index, 1);

    if (selected >= update.length) {
      setSelected(update.length - 1);
    }

    setFieldValue("hypotheekSamenstelling", update);
    setFieldValue("dirty", true);
  };

  return heeftRestschuld || heeftStartersleningNietDoorlopend ? (
    <></>
  ) : (
    <div className="d-flex align-items-center justify-content-center w-100 h-100">
      <Button
        data-testid={`btn-verwijder-${c.index}`}
        id={`btn-verwijder-${c.index}`}
        variant="outline-secondary"
        onMouseUp={onClick}
        aria-label="delete knop"
      >
        <Icon name="prullenbak" alt="Verwijderen" />
      </Button>
    </div>
  );
};

type HypotheekVergelijkerDataGridProps = {
  selected: [number, React.Dispatch<React.SetStateAction<number>>];
};

const hypotheekVergelijkenColumns: (
  formik: FormikContextType<HypotheekVergelijkenModalType>,
  selectedState: [number, React.Dispatch<React.SetStateAction<number>>]
) => Column[] = (formik, selectedState) => [
  {
    Header: "Hypotheek",
    id: "Hypotheek",
    Cell: (c): ReactElement => createSpanWithId(c.index, 0, c.original.product.doorlopend ? "Doorlopend" : "Nieuw")
  },
  {
    Header: "Hypotheekvorm",
    id: "hypotheekvorm",
    Cell: (c): ReactElement =>
      createSpanWithId(
        c.index,
        2,
        getAflosvormText(
          c.original.hypotheekVorm.aflossingsvorm,
          c.original.hypotheekVorm.isRestschuldLening,
          c.original.hypotheekVorm.isStartersLening
        )
      )
  },
  {
    Header: "Box 1",
    id: "Box 1",
    Cell: (c): ReactElement => createSpanWithId(c.index, 3, bedragFormat(c.original.fiscalegegevens.deelBox1Bedrag))
  },
  {
    Header: "Box 3",
    id: "Box 3",
    Cell: (c): ReactElement => createSpanWithId(c.index, 4, bedragFormat(c.original.fiscalegegevens.deelBox3Bedrag))
  },
  {
    Header: "Looptijd",
    id: "Looptijd",
    Cell: (c): ReactElement => createSpanWithId(c.index, 5, jaarMaandInputNaarJaarMaand(c.original.product.looptijd))
  },
  {
    Header: "Rentesoort",
    id: "Rentesoort",
    Cell: (c): ReactElement =>
      createSpanWithId(
        c.index,
        7,
        c.original.leningdeelgegevens.renteVariant === RentevariantOptions.Variabel
          ? c.original.leningdeelgegevens.renteVariant
          : `${c.original.leningdeelgegevens.renteVariant} ${c.original.leningdeelgegevens.rentevastPeriodeJaar} jr`
      )
  },
  {
    Cell: DeleteButtonHypotheekVerlijken(formik, selectedState),
    maxWidth: 20
  }
];

const HypotheekVergelijkerDataGrid = ({
  selected,
  formik
}: HypotheekVergelijkerDataGridProps & { formik: FormikContextType<HypotheekVergelijkenModalType> }): ReactElement => {
  return (
    <>
      <Card>
        <DataGrid
          masterDetail
          columns={hypotheekVergelijkenColumns(formik, selected)}
          name="hypotheekSamenstelling"
          validationSchema={hypotheekVergelijkenSchema}
          rowSelected={selected}
        />
      </Card>
    </>
  );
};

export default connect<HypotheekVergelijkerDataGridProps, HypotheekVergelijkenModalType>(HypotheekVergelijkerDataGrid);
