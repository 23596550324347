import { initISWSideEffect } from "../shared/components/isw-side-effects/create-isw-helpers";
import { MotiveringState } from "./infra/motivering-schema";

export const notEqual = (prev: string | null | undefined, draft: string | null | undefined): boolean => prev !== draft;

export const syncMotiveringSideEffects = initISWSideEffect<MotiveringState>(({ has, draft, prev }): void => {
  if (
    (has.uitgangspuntenArbeidsongeschiktheid?.changed &&
      notEqual(
        prev.uitgangspuntenArbeidsongeschiktheid?.motiveringVoorVoorstelId,
        draft.uitgangspuntenArbeidsongeschiktheid?.motiveringVoorVoorstelId
      )) ||
    (has.uitgangspuntenOverlijden?.changed &&
      notEqual(
        prev.uitgangspuntenOverlijden?.motiveringVoorVoorstelId,
        draft.uitgangspuntenOverlijden?.motiveringVoorVoorstelId
      )) ||
    (has.uitgangspuntenPensioen.changed &&
      notEqual(
        prev.uitgangspuntenPensioen?.motiveringVoorVoorstelId,
        draft.uitgangspuntenPensioen?.motiveringVoorVoorstelId
      )) ||
    (has.uitgangspuntenHypotheek?.changed &&
      notEqual(
        prev.uitgangspuntenHypotheek?.motiveringVoorVoorstelId,
        draft.uitgangspuntenHypotheek?.motiveringVoorVoorstelId
      )) ||
    (has.uitgangspuntenWerkloosheid?.changed &&
      notEqual(
        prev.uitgangspuntenWerkloosheid?.motiveringVoorVoorstelId,
        draft.uitgangspuntenWerkloosheid?.motiveringVoorVoorstelId
      ))
  ) {
    draft.dataHasChanged = true;
  }
});
