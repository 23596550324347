import * as Yup from "yup";
import { controleNormSchema, controleNormVeldSchema } from "../../components/controle-norm/infra/controle-norm-schema";
import { scenarioVanSchema } from "../../components/scenario-van/scenario-van-schema";

export const scenariosSchema = Yup.object({
  soort: Yup.mixed<"Standaard" | "Extra">()
    .nullable()
    .default("Standaard"),
  aoPercentage: Yup.number()
    .nullable()
    .default(null),
  duurzaamAo: Yup.bool()
    .nullable()
    .default(null),
  benuttingRvc: Yup.number()
    .default(100)
    .required()
});

export const scenarioDoorrekeningSchema = Yup.object({
  toetsjaarTekort: Yup.number().default(0),
  scenarios: Yup.array(scenariosSchema).default([scenariosSchema.default()])
});

export const scenarioArbeidsongeschiktheidModalSchema = controleNormVeldSchema.shape({
  scenarioVan: scenarioVanSchema,
  scenarioDoorrekening: scenarioDoorrekeningSchema,
  controleNorm: controleNormSchema
});

export type ScenarioArbeidsongeschiktheidModalType = Yup.InferType<typeof scenarioArbeidsongeschiktheidModalSchema>;

export type ScenarioArbeidsongeschiktheidModalProps = {
  inVoorstel?: boolean;
  closeModal?: () => void;
  callbackOnClose?: () => void;
};

export type ScenarioVanType = Yup.InferType<typeof scenarioVanSchema>;
export type ScenarioDoorrekeningType = Yup.InferType<typeof scenarioDoorrekeningSchema>;
export type ScenarioDoorrekeningScenariosType = Yup.InferType<typeof scenariosSchema>;
