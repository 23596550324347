import { UiName } from "adviesbox-shared/utils/types";
import { FieldMap } from "../../../shared/types";
import { target2field } from "../../../shared/utils/target-to-field";
import { NieuwKlantRequestBody } from "../../infra/zoekscherm-schema";

export function mapDlTargetToNewClientUiField(target: string): UiName | null {
  const map: FieldMap<NieuwKlantRequestBody> = {
    achternaamAanvrager1: { field: "nieuweKlant.aanvrager1.achternaam", label: "Aanvrager 1 achternaam" },
    achternaamAanvrager2: { field: "nieuweKlant.aanvrager2.achternaam", label: "Aanvrager 2 achternaam" },
    geboortedatumAanvrager1: { field: "nieuweKlant.aanvrager1.geboorteDatum", label: "Aanvrager 1 geboortedatum" },
    geboortedatumAanvrager2: { field: "nieuweKlant.aanvrager2.geboorteDatum", label: "Aanvrager 2 geboortedatum" },
    roepnaamAanvrager1: { field: "nieuweKlant.aanvrager1.voornaam", label: "Aanvrager 1 voornaam" },
    roepnaamAanvrager2: { field: "nieuweKlant.aanvrager2.voornaam", label: "Aanvrager 2 voornaam" },
    tussenvoegselAanvrager1: { field: "nieuweKlant.aanvrager1.tussenVoegsel", label: "Aanvrager 1 tussenVoegsel" },
    tussenvoegselAanvrager2: { field: "nieuweKlant.aanvrager2.tussenVoegsel", label: "Aanvrager 2 tussenVoegsel" }
  };

  return target2field(map, target);
}
