import { Algemeen } from "../../.generated/tarieven/tarieventypes";

import { birthdate2age } from "../../shared/utils/birthdate-to-age";
import { KindType, AantalKeer } from "../infra/personalia-schema";
import { createISWSideEffect, initISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";

type kindSideEffectsContext = { selected: number; tarieven: Algemeen };

export const kindModalSideEffects = createISWSideEffect<{ kinderen: KindType[] }, kindSideEffectsContext>(
  (bag): void => {
    const { draft, prev, context } = bag;
    const { kinderen: currentList } = draft;
    const { kinderen: previousList } = prev;
    const currentValues = currentList[context.selected];
    const previousValues = previousList[context.selected];
    if (!currentValues || !previousValues) return;

    const geboortedatumChanged =
      currentValues.geboortedatum && !currentValues.geboortedatum.equals(previousValues.geboortedatum);
    const aantalKeerChanged = currentValues.aantalKeer !== previousValues.aantalKeer;
    const bijslagIngevuld =
      typeof currentValues.kinderbijslagPerJaar === "number" &&
      typeof currentValues.kinderbijslagPerKwartaal === "number";

    if (currentValues.geboortedatum && (geboortedatumChanged || aantalKeerChanged || !bijslagIngevuld)) {
      const kinderbijslagen = context.tarieven.kinderbijslagen ? context.tarieven.kinderbijslagen[0] : null;
      if (kinderbijslagen) {
        const leeftijd = birthdate2age(currentValues.geboortedatum);
        const aantalKeer = currentValues.aantalKeer === AantalKeer.x1 ? 1 : 2;
        const bijslag =
          ((leeftijd <= 5
            ? kinderbijslagen.bedragTm5Jaar
            : leeftijd <= 11
            ? kinderbijslagen.bedragTm11Jaar
            : leeftijd <= 17
            ? kinderbijslagen.bedragTm17Jaar
            : 0) || 0) * aantalKeer;
        currentValues.kinderbijslagPerKwartaal = bijslag;
        currentValues.kinderbijslagPerJaar = bijslag * 4;
      }
    }
  }
);

export const determineKindSideEffects = initISWSideEffect<{ kinderen: KindType[] }, kindSideEffectsContext>(
  kindModalSideEffects
);
