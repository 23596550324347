import { PageLoading } from "adviesbox-shared";
import React, { ReactElement } from "react";
import { BerekeningenOutput } from "../.generated/instellingen-forms/instellingen-formstypes";
import { ErrorPage } from "adviesbox-shared";
import { InvalidAdviesboxResultErrorPage } from "../shared/components/fetch-error-page/fetch-error-page";
import { useInstellingenTarievenData } from "../shared/hooks/use-instellingen-tarieven-data";
import FinancieringsbehoefteAjax from "./financieringsbehoefte-ajax";

const FinancieringsbehoefteTarievenAjax = ({
  bekeningenInstellingenData
}: {
  bekeningenInstellingenData: BerekeningenOutput;
}): ReactElement => {
  const {
    data: instellingenTarieven,
    error: errorInstellingenTarieven,
    loading: loadingInstellingenTarieven
  } = useInstellingenTarievenData();

  if (loadingInstellingenTarieven) {
    return <PageLoading />;
  }

  if (errorInstellingenTarieven) {
    return <ErrorPage error={errorInstellingenTarieven} data={JSON.stringify(instellingenTarieven)} />;
  }

  if (!instellingenTarieven) {
    return <InvalidAdviesboxResultErrorPage />;
  }

  return (
    <FinancieringsbehoefteAjax
      bekeningenInstellingenData={bekeningenInstellingenData}
      instellingenTarieven={instellingenTarieven}
    />
  );
};

FinancieringsbehoefteTarievenAjax.displayName = "FinancieringsbehoefteTarievenAjax";

export default FinancieringsbehoefteTarievenAjax;
