import { ErrorPage, PageLoading, SettingsType, withErrorBoundary } from "adviesbox-shared";
import React, { ReactElement } from "react";
import { InvalidAdviesboxResultErrorPage } from "../shared/components/fetch-error-page/fetch-error-page";
import { useAdviesboxData } from "../shared/hooks/use-adviesbox-data";
import { useInstellingenBeheerPartijenData } from "../shared/hooks/use-instellingen-beheer-partijen-data";
import Bankgarantie from "./bankgarantie";
import { mapDlTargetToBankgarantieUiField } from "./infra/map-bankgarantie-dl-target-to-ui-field";
import { mapBankgarantieDlToUi } from "./infra/map-bankgarantie-dl-to-ui";
import { mapBankgarantieUiToDl } from "./infra/map-bankgarantie-ui-to-dl";

type RouteParams = {
  vestiging: string;
  adviesdossier: string;
};

const createUrl = (s: SettingsType, p: RouteParams): string =>
  `${s.klantdossiersFormsOrigin}/Adviesdossiers/${p.adviesdossier}/Bankgarantie`;

const BankgarantieAjax = (): ReactElement => {
  const { loading, error, data, platformData, saveData } = useAdviesboxData(
    createUrl,
    p => p.adviesdossier,
    mapBankgarantieDlToUi,
    mapBankgarantieUiToDl,
    mapDlTargetToBankgarantieUiField
  );

  const {
    data: instellingData,
    loading: instellingLoading,
    error: instellingError
  } = useInstellingenBeheerPartijenData("Bankgarantieinstellingen");

  const { data: notarisData, loading: notarisLoading, error: notarisError } = useInstellingenBeheerPartijenData(
    "Notarissen"
  );

  if (loading || notarisLoading || instellingLoading) {
    return <PageLoading />;
  }

  if (error) {
    return <ErrorPage error={error} data={platformData} />;
  }

  if (notarisError) {
    return <ErrorPage error={notarisError} data={notarisData} />;
  }

  if (instellingError) {
    return <ErrorPage error={instellingError} data={platformData} />;
  }

  if (!data || !notarisData || !instellingData || !instellingData?.bankgarantieinstellingen?.partijen?.length) {
    return <InvalidAdviesboxResultErrorPage />;
  }

  return (
    <Bankgarantie
      saveData={saveData}
      data={data}
      notarissenData={notarisData.notarissen}
      productInstellingOptions={instellingData.bankgarantieinstellingen.partijen}
    />
  );
};

BankgarantieAjax.displayName = "BankgarantieAjax";

export default withErrorBoundary(BankgarantieAjax);
