import { createGetTextResources } from "../../shared/utils/text-resources-algemeen";

export const getOverigePostenTextResources = createGetTextResources({
  TooltipVerrekenenLangleven:
    "Let op: als je 'Nee' selecteert dan wordt het bedrag in de overzichten (lasten en NBI) bij zowel langleven als bij pensioen niet meegeteld.",
  ErrorEindDatum: "Vul een geldige einddatum in of controleer de ingangsdatum.",
  ErrorVerplichtEindDatum: "Vul een geldige einddatum in.",
  ErrorGeenAanvangsDatum: "Vul een geldige datum in.",
  ErrorOvergangOpPartnerEinddatum: "Vul een datum in de toekomst in.",
  ErrorOvergangOpPartnerEindleeftijd: "Vul een leeftijd in de toekomst in.",
  ErrorOvergangspercentage: "Vul een percentage tussen 1 en 100% in."
});
