/* istanbul ignore file */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FormikContextType } from "formik";
import { createISWSideEffect, initISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";
import { AanvraagState, MaatschappijCode, ProductTypeName } from "./aanvraag-schema";
import { HasChanged } from "../../shared/components/isw-side-effects/has-changed-helper";
import { AanvraagType } from "../aanvraag-product/aanvraag-product";

type ContextType = {
  formik: FormikContextType<AanvraagState>;
};

const setMaatschappijCodes = createISWSideEffect<AanvraagState, ContextType>((bag): void => {
  const { draft } = bag;
  const data: MaatschappijCode[] = [];

  if (draft.hypotheek?.hdnOntvangerCode)
    data.push({
      maatschappijCode: draft.hypotheek.hdnOntvangerCode ?? "",
      productId: draft.aanvraagOpBasisVan ?? "",
      productType: ProductTypeName.hypotheek
    });
  if (draft.bankgarantieProduct?.hdnOntvangerCode)
    data.push({
      maatschappijCode: draft.bankgarantieProduct.hdnOntvangerCode ?? "",
      productId: draft.bankgarantieProduct.productId ?? "",
      productType: ProductTypeName.BG
    });
  if (draft.taxatieProduct?.hdnOntvangerCode)
    data.push({
      maatschappijCode: draft.taxatieProduct.hdnOntvangerCode ?? "",
      productId: draft.taxatieProduct.productId ?? "",
      productType: ProductTypeName.taxatie
    });

  draft.aovProduct?.forEach(product =>
    data.push({
      maatschappijCode: product.hdnOntvangerCode ?? "",
      productId: product.productId ?? "",
      productType: ProductTypeName.AOV
    })
  );
  draft.orvProduct?.forEach(product =>
    data.push({
      maatschappijCode: product.hdnOntvangerCode ?? "",
      productId: product.productId ?? "",
      productType: ProductTypeName.ORV
    })
  );

  if (data.length > 0) {
    draft.maatschappijCodes = data;
  }
});

const updateMeenemenInAanvraag = (draft: AanvraagState): void => {
  if (draft.hypotheek) {
    draft.hypotheek.meenemenNaarBemiddeling = draft.hypotheek.productId === draft.geselecteerdProduct;
  }
  draft.orvProduct?.forEach(item => {
    item.meenemenNaarBemiddeling = item.productId === draft.geselecteerdProduct;
  });
  draft.aovProduct?.forEach(item => {
    item.meenemenNaarBemiddeling = item.productId === draft.geselecteerdProduct;
  });
  if (draft.bankgarantieProduct) {
    draft.bankgarantieProduct.meenemenNaarBemiddeling =
      draft.bankgarantieProduct.productId === draft.geselecteerdProduct;
  }
  if (draft.taxatieProduct) {
    draft.taxatieProduct.meenemenNaarBemiddeling = draft.taxatieProduct.productId === draft.geselecteerdProduct;
  }
};

const updateVanwegeOverschrijven = (draft: AanvraagState, has: HasChanged<AanvraagState>): void => {
  if (has.hypotheek?.overschrijven.changed) {
    draft.geselecteerdProduct = draft.aanvraagOpBasisVan ?? /* istanbul ignore next */ null;
    updateMeenemenInAanvraag(draft);
    return;
  }
  draft.orvProduct?.forEach((item, index) => {
    if (has.orvProduct[index].overschrijven.changed) {
      draft.geselecteerdProduct = item.productId;
      updateMeenemenInAanvraag(draft);
      return;
    }
  });
  draft.aovProduct?.forEach((item, index) => {
    if (has.aovProduct[index].overschrijven.changed) {
      draft.geselecteerdProduct = item.productId;
      updateMeenemenInAanvraag(draft);
      return;
    }
  });
  if (has.bankgarantieProduct?.overschrijven.changed) {
    draft.geselecteerdProduct = draft.bankgarantieProduct?.productId ?? null;
  }
  if (has.taxatieProduct?.overschrijven.changed) {
    draft.geselecteerdProduct = draft.taxatieProduct?.productId ?? null;
  }
};

const updateAanvraagNaar = (draft: AanvraagState, has: HasChanged<AanvraagState>): void => {
  if (draft.hypotheek?.methodeIndienen && has.hypotheek?.methodeIndienen.changed) {
    const [aanvraagType, aanvraagNaar, serviceproviderId, aanvraagTypeId] = draft.hypotheek.methodeIndienen.split("**");
    const isServiceprovider = aanvraagType === AanvraagType.Serviceprovider;
    draft.hypotheek.aanvraagNaar = aanvraagNaar;
    draft.hypotheek.aanvraagNaarServiceproviderId = serviceproviderId;
    draft.hypotheek.aanvraagNaarHdnNummer = isServiceprovider ? aanvraagTypeId : null;
    draft.hypotheek.softwareKoppelingId = isServiceprovider ? null : aanvraagTypeId;
  }

  if (has.hypotheek?.methodeIndienen.changed && draft.hypotheek && !draft.hypotheek.methodeIndienen) {
    draft.hypotheek.aanvraagNaar = draft.hypotheek?.partijnaam;
    draft.hypotheek.aanvraagNaarServiceproviderId = null;
    draft.hypotheek.aanvraagNaarHdnNummer = draft.hypotheek?.hdnNummer?.toString() ?? null;
  }

  if (draft.bankgarantieProduct?.methodeIndienen && has.bankgarantieProduct?.methodeIndienen.changed) {
    const [
      aanvraagType,
      aanvraagNaar,
      serviceproviderId,
      aanvraagTypeId
    ] = draft.bankgarantieProduct.methodeIndienen.split("**");
    const isServiceprovider = aanvraagType === AanvraagType.Serviceprovider;
    draft.bankgarantieProduct.aanvraagNaar = aanvraagNaar;
    draft.bankgarantieProduct.aanvraagNaarServiceproviderId = serviceproviderId;
    draft.bankgarantieProduct.aanvraagNaarHdnNummer = isServiceprovider ? aanvraagTypeId : null;
    draft.bankgarantieProduct.softwareKoppelingId = isServiceprovider ? null : aanvraagTypeId;
  }

  if (has.bankgarantieProduct?.methodeIndienen.changed && !draft.bankgarantieProduct?.methodeIndienen) {
    draft.bankgarantieProduct!.aanvraagNaar = draft.bankgarantieProduct!.partijnaam;
    draft.bankgarantieProduct!.aanvraagNaarServiceproviderId = null;
    draft.bankgarantieProduct!.aanvraagNaarHdnNummer = draft.bankgarantieProduct!.hdnNummer?.toString() ?? null;
  }

  if (draft.taxatieProduct?.methodeIndienen && has.taxatieProduct?.methodeIndienen.changed) {
    const [aanvraagType, aanvraagNaar, serviceproviderId, aanvraagTypeId] = draft.taxatieProduct.methodeIndienen.split(
      "**"
    );
    const isServiceprovider = aanvraagType === AanvraagType.Serviceprovider;
    draft.taxatieProduct.aanvraagNaar = aanvraagNaar;
    draft.taxatieProduct.aanvraagNaarServiceproviderId = serviceproviderId;
    draft.taxatieProduct.aanvraagNaarHdnNummer = isServiceprovider ? aanvraagTypeId : null;
    draft.taxatieProduct.softwareKoppelingId = isServiceprovider ? null : aanvraagTypeId;
  }

  if (has.taxatieProduct?.methodeIndienen.changed && !draft.taxatieProduct?.methodeIndienen) {
    draft.taxatieProduct!.aanvraagNaar = draft.taxatieProduct!.partijnaam;
    draft.taxatieProduct!.aanvraagNaarServiceproviderId = null;
    draft.taxatieProduct!.aanvraagNaarHdnNummer = draft.taxatieProduct!.hdnNummer?.toString() ?? null;
  }

  draft.orvProduct?.forEach((product, index) => {
    if (product.methodeIndienen && has.orvProduct[index].methodeIndienen.changed) {
      const [aanvraagType, aanvraagNaar, serviceproviderId, aanvraagTypeId] = product.methodeIndienen.split("**");
      const isServiceprovider = aanvraagType === AanvraagType.Serviceprovider;
      product.aanvraagNaar = aanvraagNaar;
      product.aanvraagNaarServiceproviderId = serviceproviderId;
      product.aanvraagNaarHdnNummer = isServiceprovider ? aanvraagTypeId : null;
      product.softwareKoppelingId = isServiceprovider ? null : aanvraagTypeId;
    }
    if (has.orvProduct[index]?.methodeIndienen.changed && !draft.orvProduct[index]?.methodeIndienen) {
      draft.orvProduct[index].aanvraagNaar = draft.orvProduct[index].partijnaam;
      draft.orvProduct[index].aanvraagNaarServiceproviderId = null;
      draft.orvProduct[index].aanvraagNaarHdnNummer = draft.orvProduct[index].hdnNummer?.toString() ?? null;
    }
  });
  draft.aovProduct?.forEach((product, index) => {
    if (product.methodeIndienen && has.aovProduct[index].methodeIndienen.changed) {
      const [aanvraagType, aanvraagNaar, serviceproviderId, aanvraagTypeId] = product.methodeIndienen.split("**");
      const isServiceprovider = aanvraagType === AanvraagType.Serviceprovider;
      product.aanvraagNaar = aanvraagNaar;
      product.aanvraagNaarServiceproviderId = serviceproviderId;
      product.aanvraagNaarHdnNummer = isServiceprovider ? aanvraagTypeId : null;
      product.softwareKoppelingId = isServiceprovider ? null : aanvraagTypeId;
    }
    if (has.aovProduct[index]?.methodeIndienen.changed && !draft.aovProduct[index]?.methodeIndienen) {
      draft.aovProduct[index].aanvraagNaar = draft.aovProduct[index].partijnaam;
      draft.aovProduct[index].aanvraagNaarServiceproviderId = null;
      draft.aovProduct[index].aanvraagNaarHdnNummer = draft.aovProduct[index].hdnNummer?.toString() ?? null;
    }
  });
};

export const syncAanvraagSideEffects = initISWSideEffect<AanvraagState, ContextType>(bag => {
  const { draft, has } = bag;
  if (has.geselecteerdProduct.changed) {
    updateMeenemenInAanvraag(draft);
  }
  updateVanwegeOverschrijven(draft, has);

  updateAanvraagNaar(draft, has);

  if (draft.maatschappijCodes.length === 0) {
    setMaatschappijCodes(bag);
  }

  if (has.aanvraagOpBasisVan.changed) {
    draft.foutenVoorstelId = bag.prev.aanvraagOpBasisVan;
  }
});
