import { VerbouwingVerbeteringModal } from "../infra/financieringsbehoefte-types";
import { initISWSideEffect, createISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";

const verbouwingVerbeteringDraftSideEffects = createISWSideEffect<VerbouwingVerbeteringModal>((bag): void => {
  const { has, draft } = bag;

  if (has.verbouwingVerbeteringSpecificaties.changed) {
    const totaal =
      draft.verbouwingVerbeteringSpecificaties &&
      draft.verbouwingVerbeteringSpecificaties
        .map((item): number | null => {
          return item.werkzaamhedenBedrag;
        })
        .reduce(function(prev, curr): number | null {
          return (prev || 0) + (curr || 0);
        });
    draft.totaal = totaal;
  }
});

export const determineVerbouwingVerbeteringSideEffects = initISWSideEffect(verbouwingVerbeteringDraftSideEffects)();
