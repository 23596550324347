import { LabeledNumberInput, LabeledSelectInput, LabeledTextInput } from "adviesbox-shared";
import { connect, FormikContextType } from "formik";
import React, { ReactElement } from "react";
import { TaxatieTenBehoevenVanOptions } from "../../.generated/forms/formstypes";
import LabeledHdnKeuzelijst from "../../shared/components/hdn-keuzelijst/LabeledHdnKeuzelijst";
import { TaxatieKenmerkenType, TaxatieSchermType } from "../infra/taxatie-scherm-schema";
import {
  benodigdeKeuringOpties,
  bouwkundigeKeuringTenBehoeveOpties,
  bouwkundigeKeuringVveOpties,
  gestapeldeBouwOpties,
  inhoudWoningOpties,
  woningMonumentOpties
} from "./bouwkundige-keuring-helper";

const BouwkundigeKeuring = ({
  formik: {
    values: { taxatieKenmerken, bouwkundigeKeuring }
  }
}: {
  formik: FormikContextType<TaxatieSchermType>;
}): ReactElement => {
  const kenmerken = taxatieKenmerken as TaxatieKenmerkenType;
  const bouwjaarTonen = kenmerken?.BouwjaarTonen;
  const inhoudWoningTonen = kenmerken?.InhoudBenaderingTonen;
  const benodigdeKeuringTonen = kenmerken?.BenodigdeKeuringTonen;
  const monumentTonen = kenmerken?.WoningMonumentTonen;
  const vveTonen = kenmerken?.VveTonen;
  const gestapeldeBouwTonen = kenmerken?.GestapeldeBouwTonen;
  const bouwkundigeKeuringTenBehoevenTonen = kenmerken?.KeuringTbvTonen;
  const m2InhoudTonen = kenmerken?.InhoudInMetersTonen;
  const soortWoningTonen = kenmerken?.SoortWoningTonen;

  return (
    <>
      {bouwjaarTonen && <LabeledNumberInput caption="Bouwjaar" name="bouwkundigeKeuring.bouwjaar" />}
      {inhoudWoningTonen && (
        <LabeledSelectInput
          name="bouwkundigeKeuring.inhoudwoning"
          caption="Inhoud woning bij benadering"
          options={inhoudWoningOpties}
        />
      )}
      {bouwkundigeKeuringTenBehoevenTonen && (
        <LabeledSelectInput
          name="bouwkundigeKeuring.keuringTenBehoevenVan"
          caption="Bouwkundige keuring ten behoeve van"
          options={bouwkundigeKeuringTenBehoeveOpties}
        />
      )}
      {bouwkundigeKeuringTenBehoevenTonen &&
        bouwkundigeKeuring?.keuringTenBehoevenVan === TaxatieTenBehoevenVanOptions.Anders && (
          <LabeledTextInput name="bouwkundigeKeuring.keuringTenBehoevenVanAnders" caption="Anders" />
        )}
      {benodigdeKeuringTonen && (
        <LabeledSelectInput
          name="bouwkundigeKeuring.benodigdeKeuring"
          caption="Benodigde keuring"
          options={benodigdeKeuringOpties}
        />
      )}
      {monumentTonen && (
        <LabeledSelectInput
          name="bouwkundigeKeuring.monument"
          caption="Is de woning een monument"
          options={woningMonumentOpties}
        />
      )}
      {gestapeldeBouwTonen && (
        <LabeledSelectInput
          name="bouwkundigeKeuring.gestapeldeBouw"
          caption="Gestapelde bouw"
          options={gestapeldeBouwOpties}
        />
      )}
      {vveTonen && (
        <LabeledSelectInput
          name="bouwkundigeKeuring.vve"
          caption="Vereniging van eigenaren"
          options={bouwkundigeKeuringVveOpties}
        />
      )}
      {m2InhoudTonen && <LabeledTextInput caption="M2 inhoud" name="bouwkundigeKeuring.inhoud" />}
      {soortWoningTonen && (
        <LabeledHdnKeuzelijst
          caption="Soort woning"
          name="bouwkundigeKeuring.soortWoning"
          keuzelijst="ObjectSoortType"
          berichtSoortType="AX"
        />
      )}
    </>
  );
};

export default connect<{}, TaxatieSchermType>(BouwkundigeKeuring);
