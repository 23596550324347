import { assertNever } from "../../shared/utils/helpers";
import { ProductSoort, SituatieSoort } from "../infra/producten-overzicht-types";

type ProductSetting = { path: string; modalTitle: string; maatschappijTitel: string; productTitel: string };

export const getProductSettings = (productSoort: ProductSoort, situatie: SituatieSoort): ProductSetting => {
  switch (productSoort) {
    case "kapitaalverzekering":
      return {
        path: "Kapitaalverzekeringen",
        modalTitle: "Selecteer soort verzekering",
        maatschappijTitel: "Verzekeringsmaatschappij",
        productTitel: "Soort verzekering"
      };
    case "krediet":
      return {
        path: "Kredietvormen",
        modalTitle: "Selecteer een krediet",
        maatschappijTitel: "",
        productTitel: "Soort krediet"
      };
    case "orv":
      return {
        path: "Orv",
        modalTitle: "Selecteer soort verzekering",
        maatschappijTitel: "Verzekeringsmaatschappij",
        productTitel: situatie === "huidig" ? "Soort verzekering" : "Product"
      };
    case "aov":
      return {
        path: "Aov",
        modalTitle: "Selecteer soort verzekering",
        maatschappijTitel: "Verzekeringsmaatschappij",
        productTitel: situatie === "huidig" ? "Soort verzekering" : "Product"
      };
    case "uitkerende-lijfrente":
      return {
        path: "Lijfrentes",
        modalTitle: "Selecteer een uitkerende lijfrente",
        maatschappijTitel: "",
        productTitel: "Soort uitkerende lijfrente"
      };
    case "vermogen":
      return {
        path: "Vermogensproducten",
        modalTitle: "Selecteer soort vermogensproduct",
        maatschappijTitel: "Vermogensbeheerder",
        productTitel: situatie === "huidig" ? "Soort product" : "Product"
      };
    case "hypotheek":
    case "overige":
      throw new Error("Unexpected value: " + productSoort);
    default:
      assertNever(productSoort);
  }
};
