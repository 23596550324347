/* istanbul ignore file */
import { useRequestInit, UseRequestInitOptions, ForceRerenderContext } from "adviesbox-shared";
import { useContext, useEffect, useMemo } from "react";
import useAbortableFetch from "use-abortable-fetch";
import { HypotheeksamenstellingOutput } from "../../.generated/forms/formstypes";

export function useHypotheekSamenstellingGenereerData(
  maatschappijCode: string,
  labelCode: number
): {
  data: HypotheeksamenstellingOutput | null;
  error: Error | null;
  loading: boolean;
} {
  const loadingDone = useContext(ForceRerenderContext);

  const extraHeaders = useMemo((): UseRequestInitOptions => {
    return {
      method: "POST",
      extraHeaders: {
        "content-type": "application/json"
      },
      body: `{
        "maatschappijCode": "${maatschappijCode}",
        "hypotheeklabelCode": "${labelCode}"
      }`
    };
  }, [maatschappijCode, labelCode]);

  const {
    requestInit,
    params: { voorstel },
    settings: { klantdossiersFormsOrigin }
  } = useRequestInit(extraHeaders);
  const requestUrl = useMemo(
    () => `${klantdossiersFormsOrigin}/Voorstellen/${voorstel}/Hypotheek/Hypotheeksamenstelling`,
    [klantdossiersFormsOrigin, voorstel]
  );

  const { error, data, loading } = useAbortableFetch<HypotheeksamenstellingOutput>(requestUrl, requestInit);

  useEffect((): void => {
    if (!loading && !!data && loadingDone) {
      loadingDone();
    }
  }, [loading, data, loadingDone]);

  if (typeof data === "string") {
    return { error: new Error("Invalid data"), data: null, loading: false };
  }

  return { data, error, loading };
}
