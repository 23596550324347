import { LocalDate } from "@js-joda/core";

import { BetalingsTermijnType, SoortBerekeningOptionsKv } from "../../.generated/forms/formstypes";
import {
  ProductenBasisOutput,
  OrvBasisOutput,
  KredietvormBasisOutput,
  HypotheekvormenBasisOutput,
  LijfrenteBasisOutput,
  VermogensvormenOutput,
  AovBasisOutput
} from "../../.generated/producten/productentypes";

import { KlantnaamType } from "../../shared/generic-parts/klantnaam/schema";
import { LabelValuePairs, AanvragerKeuze } from "../../shared/types";

import { KapitaalverzekeringKenmerken, PremieKenmerken } from "./product-kenmerken-types";
import { assertNever, getNaam } from "../../shared/utils/helpers";
import { addYears } from "../../shared/utils/dates";
import { partijOnafhankelijk } from "./product-constanten";
import { AlleMogelijkeProductBasisTypes } from "./producten-overzicht-types";

export const getBetalingstermijnLabel = (termijn: BetalingsTermijnType): string => {
  switch (termijn) {
    case BetalingsTermijnType.Geen:
      return "Geen";
    case BetalingsTermijnType.Maand:
      return "Maand";
    case BetalingsTermijnType.TweeMaanden:
      return "Twee maanden";
    case BetalingsTermijnType.Kwartaal:
      return "Kwartaal";
    case BetalingsTermijnType.HalfJaar:
      return "Half jaar";
    case BetalingsTermijnType.Jaar:
      return "Jaar";
    case BetalingsTermijnType.Eenmalig:
      return "Eenmalig";
    case BetalingsTermijnType.Week:
      return "Week";
    case BetalingsTermijnType.Dag:
      return "Dag";
    default:
      return assertNever(termijn);
  }
};

export const getBetalingstermijnen = (kenmerken: PremieKenmerken): LabelValuePairs => {
  const Betalingstermijnen = [
    {
      label: getBetalingstermijnLabel(BetalingsTermijnType.Maand),
      value: BetalingsTermijnType.Maand
    },
    {
      label: "Twee maanden",
      value: BetalingsTermijnType.TweeMaanden
    },
    {
      label: "Kwartaal",
      value: BetalingsTermijnType.Kwartaal
    },
    {
      label: "Half jaar",
      value: BetalingsTermijnType.HalfJaar
    },
    {
      label: "Jaar",
      value: BetalingsTermijnType.Jaar
    }
    // {
    //   label: "Koopsom",
    //   value: "99"
    // }
  ];

  const filteredArray: string[] = [];

  kenmerken.heeftBetalingstermijnMaand && filteredArray.push(BetalingsTermijnType.Maand);
  kenmerken.heeftBetalingstermijnTweeMaanden && filteredArray.push(BetalingsTermijnType.TweeMaanden);
  kenmerken.heeftBetalingstermijnKwartaal && filteredArray.push(BetalingsTermijnType.Kwartaal);
  kenmerken.heeftBetalingstermijnHalfJaar && filteredArray.push(BetalingsTermijnType.HalfJaar);
  kenmerken.heeftBetalingstermijnJaar && filteredArray.push(BetalingsTermijnType.Jaar);
  // kenmerken.heeftBetalingstermijnKoopsom && filteredArray.push("99");

  return Betalingstermijnen.filter((c): boolean => filteredArray.includes(c.value));
};

export function getAanvragerOpties(
  aanvrager1: KlantnaamType | null,
  aanvrager2: KlantnaamType | null,
  meerdereAanvragersMogelijk: boolean
): LabelValuePairs {
  const aanvragerLijst = [
    {
      label: getNaam(aanvrager1, "Aanvrager 1"),
      value: AanvragerKeuze.Aanvrager1.toString()
    }
  ];

  aanvrager1 &&
    aanvrager2 &&
    aanvragerLijst.push({
      label: getNaam(aanvrager2, "Aanvrager 2"),
      value: AanvragerKeuze.Aanvrager2.toString()
    });

  meerdereAanvragersMogelijk &&
    aanvrager1 &&
    aanvrager2 &&
    aanvragerLijst.push({
      label: "Beiden",
      value: AanvragerKeuze.Beiden.toString()
    });

  return aanvragerLijst;
}

export function bepaalFiscaleGegevensEinddatum(beginDatum: LocalDate): LocalDate {
  const standaardDatum = LocalDate.of(2001, 1, 1); // Laagste datum in adviesbox om het einddatum te bepalen
  const maximaleBeginDatum = beginDatum < standaardDatum ? standaardDatum : beginDatum;
  return addYears(maximaleBeginDatum, 30);
}

export function bepaalFiscaleGegevensBegindatum(einddatum: LocalDate): LocalDate {
  const standaardDatum = LocalDate.of(2001, 1, 1); // Laagste datum in adviesbox om het begindatum te bepalen
  const maximaleEindDatum = einddatum < standaardDatum ? standaardDatum : einddatum;
  return addYears(maximaleEindDatum, -30);
}

export function adjustBedragToPercentage(
  percentage: number | null,
  bedrag: number | null,
  leningBedrag: number
): number {
  const currentPercentage = percentage || 0;
  const currentBedrag = bedrag || 0;
  const newPercentage = (currentBedrag / leningBedrag) * 100;
  return Math.abs(currentPercentage - newPercentage) >= 0.0001 ? newPercentage : currentPercentage;
}

export function adjustPercentageToBedrag(bedrag: number | null, percentage: number, leningBedrag: number): number {
  const currentBedrag = bedrag || 0;
  const newBedrag = (leningBedrag / 100) * percentage;
  return Math.abs(currentBedrag - newBedrag) >= 1 ? newBedrag : currentBedrag;
}

export function adjustPercentageToPercentage(percentage: number | null, percentage1: number): number {
  const currentPercentage = percentage || 0;
  const newPercentage = 100 - percentage1;
  return Math.abs(currentPercentage - newPercentage) >= 0.0001 ? newPercentage : currentPercentage;
}

export function isProductenBasisOutput(data: any): data is ProductenBasisOutput {
  if (typeof data.producten === "object") {
    const eersteKey = Object.keys(data.producten)[0];
    return typeof data.producten[eersteKey].soortProduct === "undefined";
  }

  return false;
}

export function isVermogensvormenOutput(data: any): data is VermogensvormenOutput {
  if (typeof data.producten === "object") {
    const eersteKey = Object.keys(data.producten)[0];
    return typeof data.producten[eersteKey].soortProduct === "string";
  }

  return false;
}

export function isOrvBasisOutput(data: any): data is OrvBasisOutput {
  return typeof data.orvProducten === "object";
}

export function isAOVBasisOutput(data: any): data is AovBasisOutput {
  return typeof data.aovProducten === "object";
}

export function isKredietvormBasisOutput(data: any): data is KredietvormBasisOutput {
  return typeof data.kredietvormen === "object";
}

export function isHypotheekvormenBasisOutput(data: any): data is HypotheekvormenBasisOutput {
  return typeof data.hypotheekvormen === "object";
}

export function isLijfrenteBasisOutput(data: any): data is LijfrenteBasisOutput {
  return typeof data.lijfrenteProducten === "object";
}

export const partijOnafhankelijkFilter = (product: AlleMogelijkeProductBasisTypes): boolean =>
  product.maatschappijCode === partijOnafhankelijk;

export const isLevensverzekering = (partijcode: string, productcode: string): boolean => {
  return partijcode === "XX" && productcode === "01";
};
export const isUniversalLife = (partijcode: string, productcode: string): boolean => {
  return partijcode === "XX" && productcode === "02";
};

export const getKapitaalverzekeringKenmerkenVanSoortBerekening = (
  kenmerken: KapitaalverzekeringKenmerken | null,
  partijcode: string,
  productcode: string,
  soortBerekening: SoortBerekeningOptionsKv | null
): KapitaalverzekeringKenmerken | null => {
  if (!kenmerken) return null;
  const result = {
    ...kenmerken
  } as KapitaalverzekeringKenmerken;
  if (result) {
    if (soortBerekening !== SoortBerekeningOptionsKv.Premie) {
      result.kapitaalopbouw.doelkapitaalBedragEnabled = true;
      result.kapitaalopbouw.doelrendementPercentageEnabled = true;
    }
    if (isLevensverzekering(partijcode, productcode)) {
      result.premie.spaarpremieEnabled = soortBerekening !== SoortBerekeningOptionsKv.Premie;
      result.premie.premieHoogEnabled = soortBerekening !== SoortBerekeningOptionsKv.Premie;
    } else if (isUniversalLife(partijcode, productcode)) {
      result.premie.totalePremieEnabled = soortBerekening !== SoortBerekeningOptionsKv.Premie;
    }
    switch (soortBerekening) {
      case SoortBerekeningOptionsKv.EigenInvoer:
        result.kapitaalopbouw.voorbeeldkapitaalBedragEnabled = true;
        result.kapitaalopbouw.voorbeeldkapitaalPercentageEnabled = true;
        break;
      case SoortBerekeningOptionsKv.Premie:
        result.kapitaalopbouw.voorbeeldkapitaalBedragEnabled = false;
        result.kapitaalopbouw.voorbeeldkapitaalPercentageEnabled = false;
        break;
      case SoortBerekeningOptionsKv.Voorbeeldkapitaal:
        result.kapitaalopbouw.voorbeeldkapitaalBedragEnabled = false;
        result.kapitaalopbouw.voorbeeldkapitaalPercentageEnabled = !isUniversalLife(partijcode, productcode);
        break;
      case SoortBerekeningOptionsKv.Voorbeeldrendement:
        result.kapitaalopbouw.voorbeeldkapitaalBedragEnabled = !isUniversalLife(partijcode, productcode);
        result.kapitaalopbouw.voorbeeldkapitaalPercentageEnabled = false;
        break;
    }
  }
  return result;
};
