import { Motivering as MotiveringAlgemeenDlEntry } from "../../.generated/forms/formstypes";
import { createMapToDl } from "../../shared/utils/create-map-to-dl";
import { motiveringSchema } from "./motivering-schema";
export const mapMotiveringUiToDl = createMapToDl(motiveringSchema).to<MotiveringAlgemeenDlEntry>({
  afwijkendeKeuze: v => v.afwijkendeKeuze.afwijkendeKeuzeAanvrager1,
  onsAdvies: v => v.advies.adviesAanvrager1,
  uitgangspuntenArbeidsongeschiktheid: v => v.uitgangspuntenArbeidsongeschiktheid,
  uitgangspuntenDubbeleWoonlasten: v => v.uitgangspuntenDubbeleWoonlasten,
  uitgangspuntenOverlijden: v => v.uitgangspuntenOverlijden,
  uitgangspuntenPensioen: v => v.uitgangspuntenPensioen,
  uitgangspuntenWerkloosheid: v => v.uitgangspuntenWerkloosheid,
  uitgangspuntenHypotheek: v => v.uitgangspuntenHypotheek,
  uitgangspuntenVermogen: v => v.uitgangspuntenVermogen,
  afwijkendeKeuze2: v => v.afwijkendeKeuze.afwijkendeKeuzeAanvrager2,
  onsAdvies2: v => v.advies.adviesAanvrager2,
  aanvrager1: v => null, //TODO CONTROLEREN!!
  aanvrager2: v => null, //TODO CONTROLEREN!!
  tabellenResourceUrl: v => null, //TODO CONTROLEREN!!
  wensenAanvrager1: v => null, //TODO CONTROLEREN!!
  wensenAanvrager2: v => null, //TODO CONTROLEREN!!
  wensenAlgemeen: v => null //TODO CONTROLEREN!!
});
