import { InferType } from "yup";

import {
  depotSchema,
  extraInlegJaarSchema,
  extraInlegJarenSchema,
  extraOpnameSchema,
  extraOpnamesSchema,
  vermogenFiscaleRegelingSchema,
  inleggegevensSchema,
  onttrekkingenSchema,
  vermogenSchema,
  vermogensSchema,
  waardeopbouwJaarSchema,
  waardeopbouwMaandSchema
} from "./vermogen-schema";

export type VermogensType = InferType<typeof vermogensSchema>;
export type VermogenType = InferType<typeof vermogenSchema>;

export type VermogenFiscaleRegelingType = InferType<typeof vermogenFiscaleRegelingSchema>;

export type WaardeopbouwMaandType = InferType<typeof waardeopbouwMaandSchema>;
export type WaardeopbouwJaarType = InferType<typeof waardeopbouwJaarSchema>;
export type DepotType = InferType<typeof depotSchema>;

export type InleggegevensType = InferType<typeof inleggegevensSchema>;
export type ExtraInlegJaarType = InferType<typeof extraInlegJaarSchema>;
export type ExtraInlegSpecificatieModalType = InferType<typeof extraInlegJarenSchema>;

export type OnttrekkingenType = InferType<typeof onttrekkingenSchema>;
export type ExtraOpnameType = InferType<typeof extraOpnameSchema>;
export type ExtraOpnamesSpecificatieModalType = InferType<typeof extraOpnamesSchema>;

export const productCodeSpaarrekening = "01";
export const productCodeBeleggingsdepot = "02";
export const productCodeEffectenlease = "03";
export const productCodeBetaalrekening = "05";
export const partijCodeAEGON = "AE";
export const partijcodeING = "IN";
export const partijcodeBND = "BD";
