import { KredietType } from "./schema";

import { KredietReadOnly } from "../../../.generated/forms/formstypes";

export function mapKredietenUi2Dl(values: KredietType[]): KredietReadOnly[] {
  return values.map(mapKredietUi2DL);
}

export function mapKredietUi2DL(values: KredietType): KredietReadOnly {
  return {
    kredietId: values.kredietId,
    doorlopend: values.doorlopend,
    productnaam: values.productnaam
  };
}
