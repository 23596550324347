import { createISWSideEffect, initISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";
import { HypothekenState } from "../../hypotheek/infra/hypotheek-types";
import {
  FiscaleVoortzettingOptions,
} from "../../.generated/forms/formstypes";
import { hypotheekFiscaleRegelingSchema } from "../../hypotheek/infra/hypotheek-schema";

const FiscaleRegelingDraftSideEffects = createISWSideEffect<HypothekenState, number>((bag): void => {
  const { has, draft, context } = bag;

  if (
    has.producten[context].fiscaleRegeling.productId.changed &&
    draft.producten[context].fiscaleRegeling &&
    draft.producten[context].fiscaleRegeling?.productId
  ) {
    const key = draft.producten[context].fiscaleRegeling?.productId as FiscaleVoortzettingOptions;
    draft.producten[context].fiscaleRegeling = {
      ...hypotheekFiscaleRegelingSchema.default(),
      fiscaleVoortzetting: key,
      productId:
        draft.producten[context].fiscaleRegeling?.productId || hypotheekFiscaleRegelingSchema.default().productId
    };

    if (!FiscaleVoortzettingOptions[key]) {
      const selectedOption = draft.fiscaleVoortzettingKeuzes
        ? draft.fiscaleVoortzettingKeuzes.find(
            keuze => keuze.productId === draft.producten[context].fiscaleRegeling?.productId
          )
        : null;
      const fiscaleRegeling = draft.producten[context].fiscaleRegeling;
      if (selectedOption && selectedOption.productkenmerken && fiscaleRegeling) {
        const kenmerken = selectedOption.productkenmerken;

        fiscaleRegeling.productId = kenmerken.productId || "";
        fiscaleRegeling.fiscaleVoortzetting = FiscaleVoortzettingOptions.VoortgezetProduct;
        fiscaleRegeling.fiscaleTypering = kenmerken.fiscaleTypering;
        fiscaleRegeling.einddatum = kenmerken.einddatum;
        fiscaleRegeling.premieLopendJaarBedrag = kenmerken.premieLopendJaarBedrag;
        fiscaleRegeling.originelePolisnummer = kenmerken.originelePolisnummer;
        fiscaleRegeling.polisnummer = kenmerken.polisnummer;
        fiscaleRegeling.oorspronkelijkeIngangsdatum = kenmerken.oorspronkelijkeIngangsdatum;
        fiscaleRegeling.lijfrenteclausuleOrigineel = kenmerken.lijfrenteclausuleOrigineel;
        fiscaleRegeling.laagstePremieooitBedrag = kenmerken.laagstePremieooitBedrag;
        fiscaleRegeling.kapitaalopbouw = kenmerken.kapitaalopbouw;
        fiscaleRegeling.ingebrachteWaardeBedrag = kenmerken.ingebrachteWaardeBedrag;
        fiscaleRegeling.ingangsdatumBox1 = kenmerken.ingangsdatumBox1;
        fiscaleRegeling.huidigeJaarPremieBedrag = kenmerken.huidigeJaarPremieBedrag;
        fiscaleRegeling.hoogstePremieOoitBedrag = kenmerken.hoogstePremieOoitBedrag;
        fiscaleRegeling.garantieverzekering = kenmerken.garantieverzekering;
        fiscaleRegeling.fiscaalRegime = kenmerken.fiscaalRegime;
        fiscaleRegeling.externeMaatschappijOmschrijving = kenmerken.externeMaatschappijOmschrijving;
        fiscaleRegeling.externeMaatschappijCode = kenmerken.externeMaatschappijCode;
        fiscaleRegeling.eerdereUitkeringenBedrag = kenmerken.eerdereUitkeringenBedrag;
        fiscaleRegeling.doelkapitaalBedrag = kenmerken.doelkapitaalBedrag;
      }
    }
  }
});

export const FiscaleRegelingSideEffects = initISWSideEffect<HypothekenState, number>(bag => {
  FiscaleRegelingDraftSideEffects(bag.subset.createWithContext(bag.context));
});
