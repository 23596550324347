/* istanbul ignore file */
import React, { ReactElement, useState, useContext, useEffect } from "react";
import { Form, FormikContextType } from "formik";

import VerzekeringNemers from "./verzekering-nemers/verzekering-nemers";
import Product from "./product/product";
import Dekking from "./dekking/dekking";

import Verzekerden from "./verzekerden/verzekerden";
import PremieGegevens from "./premie-gegevens/premie-gegevens";
import Verpanding from "./verpanding/verpanding";
import InformatieVoorVerzendingAanvraag from "./informatie-voor-verzending-aanvraag/informatie-voor-verzending-aanvraag";
import Fiscalegegevens from "./fiscalegegevens/fiscalegegevens";

import { PageLoading, CheckBox, Card, CardWrapper, DataGrid, createSpanWithId, insightsReactPlugin } from "adviesbox-shared";
import { ProductkenmerkenContext } from "./infra/producten-context";
import { isKenmerkError, generiekeKapitaalopbouwKenmerken } from "./infra/product-kenmerken-types";
import { ProductWrapper } from "./productwrapper/productwrapper-component";
import { jaarMaandInputNaarJaarMaand } from "../shared/generic-parts/jaar-maand/helpers";
import { ISWSideEffects } from "../shared/components/isw-side-effects/isw-side-effects";
import Kapitaalopbouw from "./kapitaalopbouw/kapitaalopbouw";
import { DevDebug } from "../shared/components/dev-debug/dev-debug";
import { WithSaveData } from "../shared/utils/save-data";
import { ErrorPage, FormFirstFocus, PlatformFoutenSamenvatting } from "adviesbox-shared";

import { SaveButton } from "../shared/components/save-button/save-button";
import { withAdviesboxFormik } from "../shared/utils/with-adviesbox-formik";
import { ProductenState, ProductenProps } from "./infra/producten-overzicht-types";
import { determineProductenOverzichtSideEffects } from "./infra/determine-producten-overzicht-side-effects";
import { productenSchema } from "./infra/producten-overzicht-schema";
import { withAITracking } from "@microsoft/applicationinsights-react-js";

const ProductenOverzicht = (
  formikContext: FormikContextType<ProductenState> & WithSaveData<ProductenState>
): ReactElement => {
  const selectedState = useState(0);
  const [selected] = selectedState;
  const { setFieldValue, values: producten } = formikContext;
  const kenmerkenContext = useContext(ProductkenmerkenContext);
  const kenmerken = kenmerkenContext.getProductKenmerken("Orv", "AE", "01");

  // alleen op het product-overzicht gebruiken!, kenmerken plaatsen we in de state om
  // met de productwrapper te kunnen updaten.
  useEffect((): void => {
    if (kenmerken) {
      setFieldValue("kenmerken", kenmerken);
    }
  }, [kenmerken, setFieldValue]);
  if (!kenmerken) return <PageLoading />;
  if (kenmerken && isKenmerkError(kenmerken)) {
    return <ErrorPage error={new Error(`Fout bij het ophalen van producteigenschappen: ${kenmerken.reden}`)} />;
  }
  return (
    <>
      <FormFirstFocus>
        <Form>
          <ISWSideEffects<ProductenState> sync={determineProductenOverzichtSideEffects} />

          <CardWrapper className="px-3">
            <div className="text-container">
              <h2>Productenoverzicht</h2>
              <div className="save-btn-position">
                <div className="button-container">
                  <SaveButton context={formikContext} />{" "}
                  {/* TODO: files={filesRef.current} build in save functionality for files */}
                </div>
              </div>
            </div>
          </CardWrapper>

          <PlatformFoutenSamenvatting />
          <div className="d-flex flex-wrap flex-row flex-grow-1">
            <CardWrapper className="px-3 master-detail-card flex-grow-1" maxRowCount={4}>
              <Card className="w-xl-100 w-lg-100 w-md-50 w-50">
                <DataGrid
                  masterDetail
                  rowCaption="Product"
                  rowSelected={selectedState}
                  name={"producten"}
                  columns={[
                    {
                      Header: "Verzekering",
                      id: "Verzekering",
                      Cell: (c): ReactElement =>
                        createSpanWithId(c.index, 0, c.original.product.partijNaam, c.original.product.partijNaam)
                    },
                    {
                      Header: "Looptijd",
                      id: "Looptijd",
                      Cell: (c): ReactElement =>
                        createSpanWithId(c.index, 0, jaarMaandInputNaarJaarMaand(c.original.product.looptijd))
                    }
                  ]}
                />
              </Card>
            </CardWrapper>

            <CardWrapper className="px-3">
              <Card>
                <CheckBox caption="Meerdere aanvragers" name="meerdereAanvragersCheck" />
                <CheckBox caption="Situatie: 'voorstel' " name="situatieVoorstel" />
              </Card>
            </CardWrapper>
            {producten && kenmerken && (
              <CardWrapper className="px-3" maxRowCount={12}>
                <Card title={producten.situatieVoorstel ? "Nieuw product" : "Huidig product"}>
                  <ProductWrapper kenmerken={kenmerken.product} prefix={"kenmerken.product"}></ProductWrapper>
                  <Product
                    selected={selected}
                    situatie={producten.situatieVoorstel ? "voorstel" : "huidig"}
                    kenmerken={kenmerken.product}
                    productSoort="orv"
                  />
                </Card>
                <Card title={producten.meerdereAanvragersCheck ? "Verzekeringnemers" : "Verzekeringnemer"}>
                  <ProductWrapper
                    kenmerken={kenmerken.verzekeringnemer}
                    prefix={"kenmerken.verzekeringnemer"}
                  ></ProductWrapper>
                  <VerzekeringNemers selected={selected} kenmerken={kenmerken.verzekeringnemer} />
                </Card>
                <Card title={producten.meerdereAanvragersCheck ? "Verzekerden" : "Verzekerde"}>
                  <ProductWrapper kenmerken={kenmerken.verzekerden} prefix={"kenmerken.verzekerden"}></ProductWrapper>
                  <Verzekerden selected={selected} kenmerken={kenmerken.verzekerden} />
                </Card>
                <Card title="Dekking">
                  <ProductWrapper kenmerken={kenmerken.dekking} prefix={"kenmerken.dekking"}></ProductWrapper>
                  <Dekking selected={selected} kenmerken={kenmerken.dekking} />
                </Card>
                <Card title="Premiegegevens">
                  <ProductWrapper kenmerken={kenmerken.premie} prefix={"kenmerken.premie"}></ProductWrapper>
                  <PremieGegevens
                    selected={selected}
                    kenmerken={kenmerken.premie}
                    validatieKenmerken={kenmerken.validaties}
                    calculate={(): Promise<number> => Promise.resolve(Math.random() * 100)}
                  />
                </Card>

                {/* Kenmerk kenmerken.verpanding.verpandingTonen moet op deze regel gedaan worden als er daarwerkelijk aan een scherm gewerkt wordt.
                De reden waarom het nu binnen het kaart gedaan wordt, is vanwege de productenwrapper. */}
                <Card title="Verpanding">
                  <ProductWrapper kenmerken={kenmerken.verpanding} prefix={"kenmerken.verpanding"}></ProductWrapper>
                  {kenmerken.verpanding.verpandingTonen && (
                    <Verpanding
                      selected={selected}
                      waardeopbouwBedrag={null}
                      verpandAanGeldverstrekkerTonen
                      kenmerken={kenmerken.verpanding}
                    />
                  )}
                </Card>
                <Card title="Informatie voor verzending aanvraag">
                  <ProductWrapper kenmerken={kenmerken.aanvraag} prefix={"kenmerken.aanvraag"}></ProductWrapper>
                  <InformatieVoorVerzendingAanvraag selected={selected} kenmerken={kenmerken.aanvraag} />
                </Card>

                <Card title="Fiscale gegevens">
                  <Fiscalegegevens
                    selected={selected}
                    aflosvormIsAnnuiteitBlok={false}
                    isTweedeWoning={false}
                    eigenWoningSchuldBedrag={0}
                    leningdeelBedrag={0}
                    situatie={"voorstel"}
                  />
                </Card>

                <Card title="Kapitaalopbouw">
                  <Kapitaalopbouw gebruik="vermogen" selected={selected} kenmerken={generiekeKapitaalopbouwKenmerken} />
                </Card>
              </CardWrapper>
            )}
          </div>
          <DevDebug />
        </Form>
      </FormFirstFocus>
    </>
  );
};

ProductenOverzicht.displayName = "ProductenOverzicht";

export default withAdviesboxFormik<ProductenProps & WithSaveData<ProductenState>, ProductenState>({
  // Transform outer props into form values
  mapPropsToValues: (e: ProductenProps): ProductenState => e,
  validationSchema: productenSchema
})(withAITracking(insightsReactPlugin, ProductenOverzicht));
