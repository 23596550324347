import React, { ReactElement } from "react";
import { connect, FormikContextType } from "formik";

import { Label, TextInput } from "adviesbox-shared";
import { AanvraagLeningState } from "../infra/aanvraag-lening-schema";

const AanvullendeGegevens = ({
  formik: {
    values: { aanvullendeGegevens }
  }
}: {
  formik: FormikContextType<AanvraagLeningState>;
}): ReactElement => {
  const aanvullendeGegevensLength = aanvullendeGegevens.omschrijving.length;
  const orangeText = aanvullendeGegevensLength > 345 ? { color: "orange" } : undefined;
  return (
    <>
      <div className={"form-group form-row"}>
        <div className={`col-12`}>
          <Label
            caption="Let op! Als de geldgever dit ondersteunt komt de tekst ook in het HDN bericht te staan. Het HDN bericht heeft ruimte voor maximaal 345 tekens."
            name="aanvullendeGegevens.omschrijving"
          />
        </div>
        <div className={`col-12`}>
          <TextInput name="aanvullendeGegevens.omschrijving" textarea={5} fullWidth />
          <div style={{ textAlign: "right" }}>
            <span
              data-testid="aanvullendeGegevens-counter"
              style={orangeText}
            >{`${aanvullendeGegevensLength}/345`}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect<{}, AanvraagLeningState>(AanvullendeGegevens);
