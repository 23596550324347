import {
  Adres,
  KlantgegevensReadOnly,
  GeslachtOpties,
  Taxatie,
  TaxatieAanvullendeInformatie,
  TaxatieAlgemeen,
  TaxatieBouwkundigeKeuring,
  Taxatiebureau,
  TaxatieContactpersoon,
  TaxatieObject,
  TaxatiePersoon,
  TaxatieSoortAanvraagOptions
} from "../../.generated/forms/formstypes";
import adresSchema from "../../shared/generic-parts/adres/schema";
import { klantnaamSchema } from "../../shared/generic-parts/klantnaam/schema";
import { createMapToDl } from "../../shared/utils/create-map-to-dl";
import {
  AanvullendeInformatieSchema,
  BouwkundigeKeuringSchema,
  ContactpersoonSchema,
  OpdrachtgeverSchema,
  PartnerSchema,
  TaxatieAlgemeenSchema,
  TaxatiebureauSchema,
  TaxatieObjectSchema,
  TaxatieSchermType
} from "./taxatie-scherm-schema";
import { taxatieObjectFactoryByKenmerken } from "./taxatie-scherm-update-using-kenmerken";
import { mapLocalDateToString } from "adviesbox-shared";

type TaxatieContext = { maatschappijCode: string | null };

const mapAanvrager = createMapToDl(klantnaamSchema).to<KlantgegevensReadOnly>({
  klantId: v => v.klantId,
  achternaam: v => v.achternaam,
  voorletters: v => v.voorletters,
  voorvoegsel: v => v.voorvoegsel,
  geboortedatum: v => mapLocalDateToString(v.geboortedatum || "1900-01-01"),
  roker: v => v.roker,
  geslacht: v => v.geslacht as GeslachtOpties | null,
  aowdatum: v => mapLocalDateToString(v.aowdatum)
});

export const mapTaxatiebureauUi2Dl = createMapToDl(TaxatiebureauSchema).to<Taxatiebureau>({
  maatschappijCode: v => v.maatschappijCode,
  soortAanvraag: v => v.soortAanvraag,
  andersTeWeten: v => (v.soortAanvraag === TaxatieSoortAanvraagOptions.Anders ? v.andersTeWeten : null),
  agentnummer: v => v.agentnummer,
  productCode: v => v.productCode,
  taxatieProductNaam: v => v.taxatieProductNaam
});

export const mapTaxatieAlgemeenUi2Dl = createMapToDl(TaxatieAlgemeenSchema).to<TaxatieAlgemeen>({
  doelTaxatie: v => v.doelTaxatie,
  geldverstrekker: v => v.geldverstrekker,
  referentieGeldverstrekker: v => v.referentieGeldverstrekker,
  nhg: v => v.nhg,
  herbouwwaarde: v => v.herbouwwaarde,
  nwwiAanvraag: v => v.nwwiAanvraag,
  validatieVerplicht: v => v.validatieVerplicht,
  energiePrestatieAdvies: v => v.energiePrestatieAdvies,
  bouwtechnischeKeuring: v => v.bouwtechnischeKeuring,
  aanvraagdatum: v => mapLocalDateToString(v.aanvraagdatum),
  gereedDatum: v => mapLocalDateToString(v.gereedDatum),
  passeerdatum: v => mapLocalDateToString(v.passeerdatum),
  voortaxatieGewenst: v => v.voortaxatieGewenst,
  faxVerstuurd: v => v.faxVerstuurd,
  recenteVerkoop: v => v.recenteVerkoop,
  bemiddelingsKostenBedrag: v => v.bemiddelingsKostenBedrag,
  beinvloedingWaarde: v => v.beinvloedingWaarde,
  toelichtingBeinvloedingWaarde: v => (v.beinvloedingWaarde ? v.toelichtingBeinvloedingWaarde : null),
  verbouwingSpecificaties: v => v.verbouwingSpecificaties,
  betreftAankoop: v => v.betreftAankoop,
  verbouwingBedrag: v => null, // todo?
  verbouwing: v => v.verbouwing,
  voorVerkrijgenLening: v => v.voorVerkrijgenLening
});

export const mapContactpersoonUi2Dl = createMapToDl(ContactpersoonSchema)
  .with<TaxatieContext>()
  .to<TaxatieContactpersoon>({
    typeContactpersoon: v => v.typeContactpersoon,
    naam: v => v.naam,
    naamMakelaar: v => v.naamMakelaar,
    telefoonPrive: v => v.telefoonPrive,
    telefoonWerk: v => v.telefoonWerk,
    telefoonMobiel: v => v.telefoonMobiel,
    woonplaats: v => v.woonplaats,
    geslacht: v => v.geslacht,
    postcode: v => v.postcode,
    email: v => v.email
  });

export const mapAanvullendeInformatieUi2Dl = createMapToDl(AanvullendeInformatieSchema)
  .with<TaxatieContext>()
  .to<TaxatieAanvullendeInformatie>({
    opmerkingen: v => v.opmerkingen,
    spoed: v => v.spoed,
    naamEigenaar: v => v.naamEigenaar,
    telefoonnummer: v => v.telefoonnummer,
    taxateurnummer: v => v.taxateurnummer,
    geldverstrekker: v => v.geldverstrekker,
    taxateur: v => v.taxateur,
    opdrachtnummer: v => v.opdrachtnummer,
    externReferentienummer: v => v.externReferentienummer,
    betalingViaNotaris: v => v.betalingViaNotaris,
    tussenpersoonNaam: v => v.tussenpersoonNaam,
    tussenpersoonNummer: v => v.tussenpersoonNummer
  });

export const mapAdresPandTaxatie = createMapToDl(adresSchema).to<Adres>({
  huisnummer: v => v.huisnummer,
  landCode: v => v.landCode,
  locatie: v => v.locatie,
  plaats: v => v.plaats,
  postcode: v => {
    if (v.postcode && v.landCode === "NL") {
      const postcode = v.postcode.toUpperCase().replace(/\s/g, "");
      const letters = postcode.slice(4, 6);
      const numbers = postcode.slice(0, 4);
      if (numbers.charAt(0) !== "") {
        return `${numbers} ${letters}`;
      }
    }
    return v.postcode;
  },
  straat: v => v.straat,
  toevoeging: v => v.toevoeging
});

export const mapTaxatieObjectUi2Dl = createMapToDl(TaxatieObjectSchema)
  .with<TaxatieContext>()
  .to<TaxatieObject>({
    aankoopprijsBedrag: v => v.aankoopprijsBedrag,
    adresPand: v => mapAdresPandTaxatie(v.adresPand),
    nieuwbouw: v => v.nieuwbouw,
    bouwplan: v => v.bouwplan,
    bouwnummer: v => v.bouwnummer,
    gebruikMomenteel: v => v.gebruikMomenteel,
    gebruikToekomst: v => v.gebruikToekomst,
    gebruikToekomstAnders: v => (v.gebruikToekomst ? v.gebruikToekomstAnders : null),
    huurovereenkomst: v => v.huurovereenkomst,
    opdrachtgever: v => v.opdrachtgever
  });

export const mapOpdrachtgeverUi2Dl = createMapToDl(OpdrachtgeverSchema)
  .with<TaxatieContext>()
  .to<TaxatiePersoon>({
    adres: v => v.adres,
    achternaam: v => v.achternaam,
    email: v => v.emailAdres,
    geboortedatum: v => mapLocalDateToString(v.geboortedatum),
    geboortelandCode: v => v.geboortelandCode,
    geslacht: v => v.geslacht,
    roepnaam: v => v.roepnaam,
    telefoonMobiel: v => v.telefoonnummerMobiel?.replace(/-/g, "") || null,
    telefoonPrive: v => v.telefoonnummerPrive?.replace(/-/g, "") || null,
    telefoonWerk: v => v.telefoonnummerWerk?.replace(/-/g, "") || null,
    voorletters: v => v.voorletters,
    voornamen: v => v.voornamen,
    voorvoegsel: v => v.voorvoegsel,
    iban: v => v.iban
  });

export const mapPartnerUi2Dl = createMapToDl(PartnerSchema).to<TaxatiePersoon>({
  adres: v => v.adres,
  achternaam: v => v.achternaam,
  email: v => v.emailAdres,
  geboortedatum: v => mapLocalDateToString(v.geboortedatum),
  geboortelandCode: v => v.geboortelandCode,
  geslacht: v => v.geslacht,
  roepnaam: v => v.roepnaam,
  telefoonMobiel: v => v.telefoonnummerMobiel,
  telefoonPrive: v => v.telefoonnummerPrive,
  telefoonWerk: v => v.telefoonnummerWerk,
  voorletters: v => v.voorletters,
  voornamen: v => v.voornamen,
  voorvoegsel: v => v.voorvoegsel,
  iban: v => v.iban
});

export const mapBouwkundigeKeuringUi2Dl = createMapToDl(BouwkundigeKeuringSchema)
  .with<TaxatieContext>()
  .to<TaxatieBouwkundigeKeuring>({
    bouwjaar: v => v.bouwjaar,
    inhoudwoning: v => v.inhoudwoning,
    keuringTenBehoevenVan: v => v.keuringTenBehoevenVan,
    keuringTenBehoevenVanAnders: v => v.keuringTenBehoevenVanAnders,
    benodigdeKeuring: v => v.benodigdeKeuring,
    monument: v => v.monument,
    gestapeldeBouw: v => v.gestapeldeBouw,
    vve: v => v.vve,
    inhoud: v => v.inhoud,
    soortWoning: v => v.soortWoning
  });

export const mapTaxatieUiToDl = (taxatie: TaxatieSchermType): Taxatie => {
  const v = taxatieObjectFactoryByKenmerken(taxatie);

  return {
    taxatiebureau: v.taxatiebureau ? mapTaxatiebureauUi2Dl(v.taxatiebureau) : null,
    opdrachtgever: v.taxatiebureau ? mapOpdrachtgeverUi2Dl(v.taxatiebureau)(v.opdrachtgever) : null,
    taxatieObject: v.taxatieObject && v.taxatiebureau ? mapTaxatieObjectUi2Dl(v.taxatiebureau)(v.taxatieObject) : null,
    partner: v.partner && v.taxatiebureau ? mapPartnerUi2Dl(v.partner) : null,
    bouwkundigeKeuring:
      v.bouwkundigeKeuring && v.taxatiebureau
        ? mapBouwkundigeKeuringUi2Dl(v.taxatiebureau)(v.bouwkundigeKeuring)
        : null,
    taxatieAlgemeen: v.taxatieAlgemeen && v.taxatiebureau ? mapTaxatieAlgemeenUi2Dl(v.taxatieAlgemeen) : null,
    contactpersoon:
      v.contactpersoon && v.taxatiebureau ? mapContactpersoonUi2Dl(v.taxatiebureau)(v.contactpersoon) : null,
    aanvullendeInformatie:
      v.aanvullendeInformatie && v.taxatiebureau
        ? mapAanvullendeInformatieUi2Dl(v.taxatiebureau)(v.aanvullendeInformatie)
        : v.aanvullendeInformatie,
    aanvrager1: v.aanvrager1 ? mapAanvrager(v.aanvrager1) : v.aanvrager1,
    aanvrager2: (v.aanvrager2 ? mapAanvrager(v.aanvrager2) : v.aanvrager2) || null,

    taxatieObjectReadOnly: null,
    taxatieAlgemeenReadOnly: null
  };
};
