import { ErrorPage, PageLoading, SettingsType, withErrorBoundary } from "adviesbox-shared";
import React, { ReactElement } from "react";
import { mapAanvraagDlToUi } from "../aanvraag/infra/map-aanvraag-dl-to-ui";
import { MotiveringOnderwerpsoort } from "../.generated/forms/formstypes";
import { InvalidAdviesboxResultErrorPage } from "../shared/components/fetch-error-page/fetch-error-page";
import { useAdviesboxData } from "../shared/hooks/use-adviesbox-data";
import { MotiveringProps } from "./infra/motivering-schema";
import { Motivering } from "./motivering";

type RouteParams = {
  vestiging: string;
  adviesdossier: string;
};

const MotiveringAanleidingAjaxComponent = ({
  motiveringType,
  data,
  instellingenUitgangspuntenAdviesData,
  saveData
}: { motiveringType: MotiveringOnderwerpsoort } & MotiveringProps): ReactElement => {
  const createUrl = (s: SettingsType, p: RouteParams): string =>
    `${s.klantdossiersFormsOrigin}/Adviesdossiers/${p.adviesdossier}/Aanleiding`;

  const { loading, error, data: aanleiding } = useAdviesboxData(
    createUrl,
    p => p.adviesdossier,
    mapAanvraagDlToUi,
    /* istanbul ignore next */ () => null,
    /* istanbul ignore next */ () => null
  );

  if (loading) {
    return <PageLoading />;
  }

  if (error) {
    return <ErrorPage error={error} />;
  }

  if (!aanleiding) {
    return <InvalidAdviesboxResultErrorPage />;
  }

  return (
    <Motivering
      saveData={saveData}
      motiveringType={motiveringType}
      data={{
        ...data,
        financiering: aanleiding?.financiering
      }}
      instellingenUitgangspuntenAdviesData={instellingenUitgangspuntenAdviesData}
    />
  );
};

export const MotiveringAanleidingAjax = withErrorBoundary(MotiveringAanleidingAjaxComponent);
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production")
  MotiveringAanleidingAjax.displayName = "MotiveringAanleidingAjax";
