import { LabeledSelectInput, LabeledCurrencyInput, LabeledRadioInput, hasValue, ErrorPage } from "adviesbox-shared";
import { useFormikContext } from "formik";
import React, { ReactElement, useEffect, useState } from "react";
import { AovVerzekerdeDekkingenOptions } from "../../.generated/forms/formstypes";
import { LabelValuePairs } from "../../shared/types";
import { AovsType } from "../infra/aov-schema";
import { DekkingProps, eigenRisicoOptions, uitkeringsduurOptions } from "./dekking";
import { useBeroepData } from "../../shared/hooks/use-aov-beroepen-data";
import { hasChanged } from "../../shared/components/isw-side-effects/has-changed-helper";

export const DekkingTafMlbz = ({ selected, kenmerken, verzekerde }: DekkingProps): ReactElement => {
  const { values, setFieldValue } = useFormikContext<AovsType>();
  const dekkingGegevens = values.producten[selected].dekking.dekkingGegevensVerzekerde1;
  const { loading: loadingBeroepen, error: errorBeroepen, data: beroepen } = useBeroepData(12);
  const [beroepOptions, setBeroepOptions] = useState<LabelValuePairs>([]);

  useEffect(() => {
    if (loadingBeroepen) return;
    const list = [];
    list.push({ label: "", value: "" });

    /* istanbul ignore next */
    beroepen?.forEach(x => {
      /* istanbul ignore next */
      if (x.naam !== null && x.id !== null) {
        list.push({ label: x.naam, value: x.id.toString() });
      }
    });

    setBeroepOptions(list);
  }, [loadingBeroepen, beroepen, setBeroepOptions]);

  const ao = values.producten[selected].dekking.dekkingGegevensVerzekerde1.ao;
  const beroepid = ao?.beroep;

  /* istanbul ignore next */
  useEffect(() => {
    if (
      hasChanged(beroepid, beroepid) ||
      (hasValue(ao?.beroep) &&
        !hasValue(ao?.risicoklasse) &&
        !hasValue(ao?.beroepnaam))
    ) {
      const beroepData = beroepen?.find(
        c => `${c.id}` === ao?.beroep
      );
      if (beroepData) {
        if (ao?.risicoklasse !== beroepData.risicoklasse) {
          setFieldValue(
            `producten[${selected}].dekking.dekkingGegevensVerzekerde1.ao.risicoklasse`,
            beroepData.risicoklasse
          );
        }
        if (ao?.beroepnaam !== beroepData.naam) {
          setFieldValue(`producten[${selected}].dekking.dekkingGegevensVerzekerde1.ao.beroepnaam`, beroepData.naam);
        }
      }
    }
  }, [beroepid, selected, setFieldValue, beroepen, ao]);

  if (errorBeroepen) {
    return <ErrorPage error={errorBeroepen} />
  }

  return (
    <>
      <h2>Arbeidsongeschiktheid(AO)</h2>
      {kenmerken.heeftVerzekerdeDekkingAo &&
        (dekkingGegevens.dekking === AovVerzekerdeDekkingenOptions.Ao ||
          dekkingGegevens.dekking === AovVerzekerdeDekkingenOptions.AoWw) && (
          <div>
            <LabeledSelectInput
              caption="Beroep"
              name={`producten[${selected}].dekking.dekkingGegevensVerzekerde${verzekerde}.ao.beroep`}
              options={beroepOptions}
            />
            <LabeledCurrencyInput
              caption="Te verzekeren maandlast"
              name={`producten[${selected}].dekking.dekkingGegevensVerzekerde${verzekerde}.ao.verzekerdBedrag`}
              readonly={!kenmerken.verzekerdBedragAoEnabled}
            />
            {kenmerken.eigenRisicoAoTonen && (
              <LabeledRadioInput
                caption="Wachttijd"
                name={`producten[${selected}].dekking.dekkingGegevensVerzekerde${verzekerde}.ao.eigenRisico`}
                options={eigenRisicoOptions(kenmerken)}
              />
            )}
            {kenmerken.aoUitkeringsduurTonen && (
              <LabeledRadioInput
                caption="Uitkeringsduur"
                name={`producten[${selected}].dekking.dekkingGegevensVerzekerde${verzekerde}.ao.uitkeringsduurAo`}
                options={uitkeringsduurOptions(kenmerken)}
              />
            )}
          </div>
        )}
    </>
  );
};
