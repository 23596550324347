import {
  Lijfrente as UitkerendeLijfrenteDlEntry,
  LijfrenteProduct,
  LijfrentePolis,
  LijfrenteKapitaal,
  LijfrenteUitkerendeFase,
  BelastingBoxOptions,
  BetalingsTermijnType,
  SoortLijfrenteUitkeringOptions
} from "../../.generated/forms/formstypes";

import { UitkerendeLijfrentesType } from "./uitkerende-lijfrente-types";
import { jaarMaandInMaanden } from "../../shared/generic-parts/jaar-maand/map-ui-2-dl";
import { KlantnaamType } from "../../shared/generic-parts/klantnaam/schema";
import { mapAanvragerKeuzeNaarKlanten, mapAanvragerKeuzeNaarVerzekeringnemers } from "../../shared/generic-parts/klantnaam/map-dl-2-ui";
import { mapKlantnaamUi2Dl } from "../../shared/generic-parts/klantnaam/map-ui-2-dl";
import { createMapToDl } from "../../shared/utils/create-map-to-dl";
import { uitkerendeLijfrenteSchema, kapitaalSchema } from "./uitkerende-lijfrente-schema";
import { indicatieveUitkerendeFaseSchema } from "../../producten-overzicht/infra/producten-overzicht-schema";
import { mapLocalDateToString } from "adviesbox-shared";

type AanvragerContext = {
  aanvrager1: KlantnaamType | null;
  aanvrager2: KlantnaamType | null;
};

const mapPolisUiToDl = createMapToDl(uitkerendeLijfrenteSchema).to<LijfrentePolis>({
  maatschappijCode: v => v.partijCode,
  productcode: v => +v.productCode,
  productnummer: v => v.product.productNummer,
  maatschappijOmschrijving: v => v.product.partijNaam,
  maatschappijCodeHdn: v => v.product.partijCodeSelectie,
  productnaam: v => v.product.productNaam,
  ingangsdatum: v => mapLocalDateToString(v.product.ingangsdatum) ?? "",
  looptijdInMaanden: v => jaarMaandInMaanden(v.product.looptijd) ?? 0,
  uwBemiddeling: v => v.product.uwBemiddeling
});

const mapKapitaalUiToDl = createMapToDl(kapitaalSchema).to<LijfrenteKapitaal>({
  uitgangspuntBerekening: v => v.uitgangspuntBerekening,
  beschikbareKoopsomBedrag: v => v.beschikbareKoopsom
});

const mapUitkerendeFaseUiToDl = createMapToDl(indicatieveUitkerendeFaseSchema).to<LijfrenteUitkerendeFase>({
  belastingBox: v => v.belastingBox as BelastingBoxOptions,
  soortLijfrenteUitkering: v => v.soortLijfrenteUitkering as SoortLijfrenteUitkeringOptions,
  duurUitkeringInMaanden: v =>
    v.soortLijfrenteUitkering !== SoortLijfrenteUitkeringOptions.Levenslang
      ? jaarMaandInMaanden(v.duurUitkering)
      : null,
  duurUitkeringHoogInMaanden: v => jaarMaandInMaanden(v.duurUitkeringHoog),
  hooglaagVerhouding: v => v.hoogLaagVerhouding,
  lijfrenteTariefPercentage: v => v.lijfrenteTarief,
  overgangOp2eVerzekerde: v => v.overgangOpTweedeVerzekerde,
  overgangOp2eVerzekerdePercentage: v => v.overgangOpTweedeVerzekerdePercentage,
  lijfrenteUitkeringBedrag: v => v.lijfrenteUitkering.bedrag,
  lijfrenteUitkeringOvernemen: v => v.lijfrenteUitkering.berekenen ?? false,
  termijn: v => v.termijn as BetalingsTermijnType
});

const mapUitkerendeLijfrenteUiToDl = createMapToDl(uitkerendeLijfrenteSchema)
  .with<AanvragerContext>()
  .to<LijfrenteProduct>({
    productId: v => v.productId,
    doorlopend: v => v.product.doorlopend,
    soortProduct: v => v.soortProduct,
    polis: v => mapPolisUiToDl(v),
    verzekeringnemerKlantIds: (v, context) => 
      mapAanvragerKeuzeNaarVerzekeringnemers(v.verzekeringnemers.verzekeringnemers, context.aanvrager1, context.aanvrager2, v.soortProduct, v.verzekerden.verzekerden),
    verzekerdeKlantIds: (v, context) =>
      mapAanvragerKeuzeNaarKlanten(v.verzekerden.verzekerden, context.aanvrager1, context.aanvrager2),
    kapitaal: v => mapKapitaalUiToDl(v.kapitaal),
    uitkerendeFase: v => mapUitkerendeFaseUiToDl(v.indicatieveUitkerendeFase),
    wijzigingenInDoorlopendeProductenOvernemen: v => v.product.wijzigingenInDoorlopendProductOvernemen ?? true,
    meenemen: v => v.product.meenemen
  });

export function mapUitkerendeLijfrentesUiToDl(
  uitkerendeLijfrentes: UitkerendeLijfrentesType
): UitkerendeLijfrenteDlEntry {
  return {
    producten: uitkerendeLijfrentes.producten.map(uitkerendeLijfrente =>
      mapUitkerendeLijfrenteUiToDl({
        aanvrager1: uitkerendeLijfrentes.aanvrager1,
        aanvrager2: uitkerendeLijfrentes.aanvrager2
      })(uitkerendeLijfrente)
    ),
    aanvrager1: mapKlantnaamUi2Dl(uitkerendeLijfrentes.aanvrager1),
    aanvrager2: mapKlantnaamUi2Dl(uitkerendeLijfrentes.aanvrager2),
    ingangsdatumVoorstel: mapLocalDateToString(uitkerendeLijfrentes.ingangsdatumVoorstel)
  };
}
