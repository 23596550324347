import { AanvraagLeningState } from "./aanvraag-lening-schema";
import { createISWSideEffect, initISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";

export const aanvraagLeningSideEffects = createISWSideEffect<AanvraagLeningState>(({ has, draft }): void => {
  const geselecteerdVoorstel = draft.voorstellen.voorstellen.find(
    voorstel => voorstel.voorstelId === draft.lening.gewensteHypotheek
  );

  if (has.lening.gewensteHypotheek.changed) {
    draft.lening.geselecteerdVoorstel = geselecteerdVoorstel ?? draft.voorstellen.voorstellen[0];

    draft.lening.inschrijvingsbedrag = geselecteerdVoorstel?.inschrijvingBedrag
      ? geselecteerdVoorstel.inschrijvingBedrag
      : 0;
  }

  // indien er binnen inventarisatieStudielening een code voor een aanvrager staat, moeten ze ook wel daadwerkelijk een bijbehorende aanvrager hebben.
  // anders preventief schonen
  if (draft.aanvrager1 == null && draft.inventarisatieStudielening?.aanvrager1HdnCode) {
    draft.inventarisatieStudielening.aanvrager1HdnCode = null;
  }
  if (draft.aanvrager2 == null && draft.inventarisatieStudielening?.aanvrager2HdnCode) {
    draft.inventarisatieStudielening.aanvrager2HdnCode = null;
  }
});

export const determineAanvraagLeningSideEffects = initISWSideEffect(aanvraagLeningSideEffects)();
