import {
  Lening as AanvraagLeningDlEntry,
  LeningAanvullendeGegevens,
  LeningAlgemeen,
  BetaalwijzeAdvieskostenOptions,
  LeningDataLeverancier,
  LeningErfpacht,
  LeningFinanciering,
  RedenAankoopOptions,
  SoortOversluitingOptions,
  LeningInventarisatieStudielening,
  LeningMaatwerkoplossing,
  LeningMaatwerkreden,
  LeningNotaris,
  PersoonBasis,
  GeslachtOpties
} from "../../.generated/forms/formstypes";
import { mapAdresUiNaarDl } from "../../shared/generic-parts/adres/map-ui-2-dl";
import { createMapEnum } from "../../shared/utils/create-map-enum";
import { createMapToDl } from "../../shared/utils/create-map-to-dl";
import {
  aanvraagLeningSchema,
  aanvullendeGegevensSchema,
  BetaalwijzeAdvieskosten,
  dataleverancierSchema,
  InventarisatieStudieleningSchema,
  leningSchema,
  maatwerkoplossingenEntrySchema,
  maatwerkoplossingSchema,
  notarisSchema,
  RedenAankoop,
  SoortFinanciering,
  SoortOversluiting
} from "./aanvraag-lening-schema";
import { mapLocalDateToString } from "adviesbox-shared";

const mapBetaalwijzeAdvieskostenEnum = createMapEnum(BetaalwijzeAdvieskosten).to({
  MeefinancierenInHypotheek: BetaalwijzeAdvieskostenOptions.MeefinancierenInHypotheek,
  VoldoenUitEigenMiddelen: BetaalwijzeAdvieskostenOptions.VoldoenUitEigenMiddelen,
  Geen: null
});

// const mapGeslachtEnum = createMapEnum(Geslacht).to({
//   Man: PersoonBasisGeslacht.Man,
//   Vrouw: PersoonBasisGeslacht.Vrouw,
//   Geen: PersoonBasisGeslacht.Geen
// });

const mapRedenAankoop = createMapEnum(RedenAankoop).to({
  WoningEigenGebruik: RedenAankoopOptions.WoningEigenGebruik,
  TweedeWoning: RedenAankoopOptions.TweedeWoning,
  DeelsVerhuurd: RedenAankoopOptions.DeelsVerhuurd,
  GeheelVerhuurd: RedenAankoopOptions.GeheelVerhuurd
});

const mapSoortOversluiting = createMapEnum(SoortOversluiting).to({
  InterneOversluiting: SoortOversluitingOptions.InterneOversluiting,
  OversluitingAndereGeldgever: SoortOversluitingOptions.OversluitingAndereGeldgever
});

const mapLeningAlgemeen = createMapToDl(leningSchema).to<LeningAlgemeen>({
  bespreekzaak: v => v.bespreekzaak,
  betaalwijzeAdvieskosten: v =>
    v.geselecteerdVoorstel?.maatschappijCode === "IN"
      ? mapBetaalwijzeAdvieskostenEnum(v.betaalwijzeAdvieskosten)
      : null,
  gewensteHypotheekVoorstelId: v => v.geselecteerdVoorstel?.voorstelId,
  nettoAdvieskostenBedrag: v => (v.geselecteerdVoorstel?.maatschappijCode === "IN" ? v.nettoAdvieskosten : null),
  soortAdvies: v => parseInt(v.soortAdvies),
  verduurzamingBesproken: v => v.verduurzamingBesproken
});

const mapMaatwerkoplossingen = createMapToDl(maatwerkoplossingenEntrySchema)
  .with<number>()
  .to<LeningMaatwerkreden>({
    betrekkingOpKlantId: v => v.betrekkingOpKlantId,
    maatwerkRedenId: v => v.maatwerkRedenId,
    reden: v => v.reden,
    toelichting: v => v.toelichting,
    volgnummer: (_, i) => i + 1,
    redentypeCode: v => (v.seniorType ? Number(v.seniorType) : 0)
  });

const mapMaatwerkoplossing = createMapToDl(maatwerkoplossingSchema).to<LeningMaatwerkoplossing>({
  maatwerkredenen: v => (v.maatwerkoplossing ? v.maatwerkredenen.map((v, i) => mapMaatwerkoplossingen(i)(v)) : [])
});

const mapPersoonBasis = createMapToDl(notarisSchema).to<PersoonBasis>({
  achternaam: v => v.persoonsgegevens.achternaam,
  email: () => null,
  geboortedatum: v => mapLocalDateToString(v.persoonsgegevens.geboortedatum),
  geslacht: v => v.geslacht ?? /* istanbul ignore next */ GeslachtOpties.Geen,
  telefoonPrive: v => v.telefoonnummer,
  voorletters: v => v.persoonsgegevens.voorletters,
  voornamen: v => v.persoonsgegevens.voornamen,
  voorvoegsel: v => v.persoonsgegevens.voorvoegsel
});

const mapNotaris = createMapToDl(notarisSchema).to<LeningNotaris>({
  gekozenNotariskantoorId: v => (v.notariskantoorId === "" ? null : v.notariskantoorId),
  kantoornaam: v => v.kantoornaam,
  typeNotaris: v => parseInt(v.typeNotaris) ?? /* istanbul ignore next */ null,
  adres: v => mapAdresUiNaarDl(v.adres),
  contactpersoon: v => mapPersoonBasis(v),
  telefoonnummer: v => v.telefoonnummer
});

const mapFinanciering = createMapToDl(aanvraagLeningSchema).to<LeningFinanciering>({
  redenAankoop: v => mapRedenAankoop(v.financiering.redenAankoop),
  redenAanvraag: v => (v.lening.geselecteerdVoorstel?.nhg ? parseInt(v.financiering.redenAanvraag) : null),
  soortOversluiting: v =>
    v.lening.geselecteerdVoorstel?.soortFinanciering === SoortFinanciering.Oversluiten
      ? mapSoortOversluiting(v.financiering.soortOversluiting)
      : null,
  aanleidingGesprek: () => null,
  verbouwingskosten: () => null,
  // TODO: deze moet eruit
  seniorenVerhuisregeling: () => null
});

const mapErfpacht = createMapToDl(aanvraagLeningSchema).to<LeningErfpacht>({
  ingangsdatum: v => (v.lening.geselecteerdVoorstel?.erfpacht ? mapLocalDateToString(v.erfpacht.ingangsdatum) : null)
});

const mapAanvullendeGegevens = createMapToDl(aanvullendeGegevensSchema).to<LeningAanvullendeGegevens>({
  toelichting: v => v.omschrijving
});

const mapDataleverancier = createMapToDl(dataleverancierSchema).to<LeningDataLeverancier>({
  dataLeveranciers: v =>
    v.dataleveranciers?.map(dataleverancier => {
      return {
        hdnCode: parseInt(dataleverancier.hdnCode),
        geselecteerd: dataleverancier.geselecteerd
      };
    })
});

const mapInventarisatieStudieLening = createMapToDl(InventarisatieStudieleningSchema).to<
  LeningInventarisatieStudielening
>({
  hdnCodeAanvrager1: v => (v.aanvrager1HdnCode ? parseInt(v.aanvrager1HdnCode) : null),
  hdnCodeAanvrager2: v => (v.aanvrager2HdnCode ? parseInt(v.aanvrager2HdnCode) : null)
});

export const mapAanvraagLeningUiToDl = createMapToDl(aanvraagLeningSchema).to<AanvraagLeningDlEntry>({
  algemeen: v => mapLeningAlgemeen(v.lening),
  maatwerkoplossing: v => mapMaatwerkoplossing(v.maatwerkoplossing),
  notaris: v => mapNotaris(v.notaris),
  financiering: v => mapFinanciering(v),
  erfpacht: v => mapErfpacht(v),
  dataLeverancier: v => mapDataleverancier(v.dataleverancier), // TO DO
  aanvullendeGegevens: v => mapAanvullendeGegevens(v.aanvullendeGegevens),
  voorstellen: () => null,
  inventarisatieStudielening: v =>
    v.inventarisatieStudielening?.aanvrager1HdnCode || v.inventarisatieStudielening?.aanvrager2HdnCode
      ? mapInventarisatieStudieLening(v.inventarisatieStudielening)
      : null,
  aanvrager1: _ => null, //TODO CONTROLEREN!!
  aanvrager2: _ => null //TODO CONTROLEREN!!
});
