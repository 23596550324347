import React, { ReactElement, useContext } from "react";
import { FormikProps, FormikErrors } from "formik";

import { getFinancieringsbehoefteTextResources } from "../infra/financieringsbehoefte-resources";
import { ISWSideEffects } from "../../shared/components/isw-side-effects/isw-side-effects";
import { determineRenteverliesTijdensBouwSideEffects } from "./determine-renteverlies-tijdens-bouw-side-effects";
import { determineRenteverliesTijdensBouwAsyncSideEffects } from "./determine-async-renteverlies-tijdens-bouw-side-effects";
import {
  LabeledRadioInput,
  LabeledCurrencyInput,
  Label,
  LabeledNumberInput,
  LabeledPercentageInput,
  LabeledBevestigingInput
} from "adviesbox-shared";
import AdviesBoxFormik from "../../shared/utils/adviesbox-formik";
import Modal from "../../shared/components/modal/Modal";
import { RenteverliesTijdensBouwModal as RenteverliesTijdensBouwState } from "../infra/financieringsbehoefte-types";
import { renteverliesTijdensBouwModalSchema } from "../infra/financieringsbehoefte-schema";
import { AppDataContext } from "../../navigation/appdata-context";

export type RenteverliesTijdensBouwData = {
  data: RenteverliesTijdensBouwState;
};

type RenteverliesTijdensBouwProps = {
  onSave?: (data: RenteverliesTijdensBouwState) => void;
  closeModal?: () => void;
};

const colCount = (totaalAantal: number): string => {
  const col = totaalAantal < 12 ? 12 : 6;
  return `col-${col}`;
};

type Slice = {
  start: number;
  end: number;
};

const aantalCol = (aantal: number): number => {
  if (aantal > 0 && aantal <= 12) return 1;
  if (aantal > 12 && aantal <= 24) return 2;
  return 3;
};

const slice = (aantal: number): Slice[] => {
  const slice = [];
  if (aantal > 0)
    aantal < 30 ? slice.push({ start: 0, end: 12 }) : slice.push({ start: 0, end: Math.ceil((aantal + 1) / 3) });

  if (aantal > 10)
    aantal < 30
      ? slice.push({ start: 12, end: 24 })
      : slice.push({
          start: Math.ceil((aantal + 1) / 3),
          end: Math.ceil((aantal + 1) / 3) * 2
        });

  if (aantal > 24)
    aantal < 30
      ? slice.push({ start: 24, end: 30 })
      : slice.push({
          start: Math.ceil((aantal + 1) / 3) * 2,
          end: Math.ceil((aantal + 1) / 3) * 3
        });

  return slice;
};

const RenteverliesTijdensBouwModal = ({
  data,
  onSave,
  closeModal
}: RenteverliesTijdensBouwData & RenteverliesTijdensBouwProps): ReactElement => {
  const { setSErunning } = useContext(AppDataContext);

  const body = (
    values: RenteverliesTijdensBouwState,
    errors: FormikErrors<RenteverliesTijdensBouwState>
  ): ReactElement => (
    <>
      <LabeledCurrencyInput
        caption="Grondkosten"
        name="grondkosten"
        readonly={true}
        tooltip={getFinancieringsbehoefteTextResources("TooltipGrondkosten")}
      />

      <LabeledBevestigingInput caption="Grond reeds in bezit" readonly={true} name="grondReedsInBezit" />

      <LabeledCurrencyInput
        caption="Stichtingskosten"
        name="stichtingskosten"
        readonly={values.soortBerekening !== "Geavanceerd"}
      />
      <LabeledCurrencyInput caption="Hypotheekbedrag" name="hypotheekbedrag" />
      <LabeledCurrencyInput
        caption="Hoogte bouwdepot bij aanvang"
        name="bouwdepotbedrag"
        readonly={true}
        tooltip="De hoogte van het bouwdepot bij aanvang is de stichtingskosten verminderd met de grondkosten. Dit is wel gemaximeerd op het hypotheekbedrag."
      />
      <LabeledPercentageInput caption="Hypotheekrente" name="hypotheekrente" decimalen={2} />
      <LabeledPercentageInput caption="Rentevergoeding" name="depotvergoeding" decimalen={2} />
      <LabeledNumberInput caption="Duur van de bouw" name="duurVanDeBouw" appendChildren={<span>maand(en)</span>} />

      <LabeledRadioInput
        caption="Soort berekening"
        name="soortBerekening"
        options={[
          { label: "Standaard", value: "Standaard" },
          { label: "Geavanceerd", value: "Geavanceerd" }
        ]}
      />
      {values.soortBerekening === "Geavanceerd" && (
        <div className="form-group form-row mt-4">
          <div className="col-md-6 col-6">
            <Label caption="Maandelijks renteverlies" fontWeight="bold" />
          </div>
        </div>
      )}

      {values.renteverliesMaandelijks.length > 0 && (
        <div className="form-row">
        {slice(values.renteverliesMaandelijks.length).map(
          (number, index): ReactElement => (
            <div key={index} className={colCount(values.renteverliesMaandelijks.length)}>
              {values.soortBerekening === "Geavanceerd" &&
                values.renteverliesMaandelijks.length > 0 &&
                values.renteverliesMaandelijks.slice(number.start, number.end).map(
                  (_, index): ReactElement => {
                    return (
                      <LabeledPercentageInput
                        caption={`${index + 1 + number.start}e maand`}
                        key={`percentage-${index}`}
                        name={`renteverliesMaandelijks[${index + number.start}].percentage`}
                        decimalen={2}
                      />
                    );
                  }
                )}
              {index + 1 === aantalCol(values.renteverliesMaandelijks.length) &&
                values.renteverliesMaandelijks.length !== 10 &&
                values.renteverliesMaandelijks.length !== 20 && (
                  <LabeledPercentageInput caption="Tot" name={`renteverliesMaandelijksTotaal`} decimalen={2} readonly />
                )}
            </div>
          )
        )}
        {(values.renteverliesMaandelijks.length === 10 || values.renteverliesMaandelijks.length === 20) && (
          <div className={colCount(values.renteverliesMaandelijks.length)}>
            <LabeledPercentageInput caption="Tot" name={`renteverliesMaandelijksTotaal`} decimalen={2} readonly />
          </div>
        )}
        {!!values.renteverliesMaandelijks.length &&
          !values.renteverliesMaandelijks.find(r => !r.percentage) &&
          !!errors.renteverliesMaandelijksTotaal && (
            <div className="col-12">
              <span className="foutmelding">{errors.renteverliesMaandelijksTotaal}</span>
            </div>
          )}
      </div>
      )}

      <LabeledCurrencyInput caption="Renteverlies" name="renteverlies" readonly fontWeight="bold" />
    </>
  );

  return (
    <AdviesBoxFormik<RenteverliesTijdensBouwState>
      initialValues={{ ...data }}
      validationSchema={renteverliesTijdensBouwModalSchema}
      onSave={onSave}
      closeModal={closeModal}
      render={({ ...props }: FormikProps<RenteverliesTijdensBouwState>): ReactElement => (
        <>
          <ISWSideEffects<RenteverliesTijdensBouwState>
            sync={determineRenteverliesTijdensBouwSideEffects}
            async={determineRenteverliesTijdensBouwAsyncSideEffects}
            asyncStartStopCallback={setSErunning}
          />
          <Modal
            title="Renteverlies tijdens bouw"
            body={body(props.values, props.errors)}
            onSubmitClick={props.submitForm}
            onCancelClick={closeModal}
          />
        </>
      )}
    />
  );
};

export default RenteverliesTijdensBouwModal;
