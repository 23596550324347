import { ErrorPage, PageLoading, SettingsType, withErrorBoundary } from "adviesbox-shared";
import React, { ReactElement } from "react";
import { mapDlTargetToPensioenUiField, mapPensioenenDlToUi } from "./infra/map-pensioen-dl-to-ui";

import { InvalidAdviesboxResultErrorPage } from "../shared/components/fetch-error-page/fetch-error-page";
import Pensioen from "./pensioen";
import { mapPensioenUiToDl } from "./infra/map-pensioen-ui-to-dl";
import { useAdviesboxData } from "../shared/hooks/use-adviesbox-data";

type RouteParams = {
  vestiging: string;
  adviesdossier: string;
};

const createUrl = (s: SettingsType, p: RouteParams): string =>
  `${s.klantdossiersFormsOrigin}/Adviesdossiers/${p.adviesdossier}/Pensioen`;

const PensioenAjax = (): ReactElement => {
  const { loading, error, data, platformData, saveData } = useAdviesboxData(
    createUrl,
    p => p.adviesdossier,
    mapPensioenenDlToUi,
    mapPensioenUiToDl,
    mapDlTargetToPensioenUiField
  );

  if (loading) {
    return <PageLoading />;
  }

  if (error) {
    return <ErrorPage error={error} data={platformData} />;
  }

  if (!data) {
    return <InvalidAdviesboxResultErrorPage />;
  }

  return <Pensioen saveData={saveData} {...data} />;
};

PensioenAjax.displayName = "PensioenAjax";

export default withErrorBoundary(PensioenAjax);
