import React, { ReactElement } from "react";

import { ZoekenConfirmDeleteClientModal } from "./zoeken-delete-client-modal";

import { Icon } from "adviesbox-shared";
import { ModalButton } from "adviesbox-shared";
import { DeleteClientButtonProps } from "../../infra/zoekscherm-schema";

export const ZoekenDeleteClientButton = ({ cellInfo, deleteClientFunc }: DeleteClientButtonProps): ReactElement => (
  <ModalButton
    parent={`zoeken.zoekresultatenMetToegangsrechten[${cellInfo.index}]`}
    validate={false}
    content={
      <div data-testid={`client-open-delete-modal-icon-${cellInfo.index}`}>
        <Icon name="prullenbak" alt="Verwijderen" multiColor={false} />
      </div>
    }
  >
    <ZoekenConfirmDeleteClientModal deleteClient={deleteClientFunc} cellInfo={cellInfo} />
  </ModalButton>
);
