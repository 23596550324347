import classNames from "classnames";
import React, { ReactElement } from "react";
import { maandenNaarJaarMaand } from "../../shared/generic-parts/jaar-maand/helpers";
import { bedragFormat } from "../../shared/utils/currency";
import { getFormattedDate } from "../../shared/utils/dates";
import { HuidigeSituatieType } from "../infra/hypotheek-oversluiten-schema";
import classes from "./hypotheek-oversluiten-pdf.module.scss";

type HypotheekLeningdelenPDFTableProps = {
  huidigeSituatie: HuidigeSituatieType[];
  renderLeningdelenOnly: boolean;
};

export const RenderHypotheekOversluitenLeningdelenTable = ({
  renderLeningdelenOnly,
  huidigeSituatie
}: HypotheekLeningdelenPDFTableProps): ReactElement => {
  return (
    <>
      <table
        className={classNames(classes.pdf_table, classes.pdf_table_dataTable, {
          [classes.pdf_padding]: renderLeningdelenOnly
        })}
      >
        <thead>
          <tr>
            <th className={`${classes.pdf_table_data_header} ${classes.pdf_table_data_header_L}`}>Maatschappijnaam</th>
            <th className={`${classes.pdf_table_data_header} ${classes.pdf_table_data_header_S}`}>Hypotheekbedrag</th>
            <th className={`${classes.pdf_table_data_header} ${classes.pdf_table_data_header_S}`}>Hypotheekrente</th>
            <th className={`${classes.pdf_table_data_header} ${classes.pdf_table_data_header_S}`}>Bruto rentelasten</th>
            <th className={`${classes.pdf_table_data_header} ${classes.pdf_table_data_header_M}`}>
              Corr. bruto rentelasten
            </th>
            <th className={`${classes.pdf_table_data_header} ${classes.pdf_table_data_header_S}`}>Rentevastperiode</th>
            <th className={`${classes.pdf_table_data_header} ${classes.pdf_table_data_header_S}`}>Einde rvp</th>
          </tr>
        </thead>
        <tbody>
          {huidigeSituatie?.map((v, i) => (
            <tr key={"lening_row" + i}>
              <td>{v.maatschappijnaam}</td>
              <td className={classes.pdf_table_right_align}>{bedragFormat(v.hypotheekbedrag)}</td>
              <td className={classes.pdf_table_right_align}>{`${v.hypotheekrente ?? 0} %`}</td>
              <td className={classes.pdf_table_right_align}>{bedragFormat(v.brutoRentelasten, 2)}</td>
              <td className={classes.pdf_table_right_align}>{bedragFormat(v.corrBrutoRentelasten, 2)}</td>
              <td>{maandenNaarJaarMaand(v.rentevastperiode ?? 0)}</td>
              <td>{v.eindeRvp ? getFormattedDate(v.eindeRvp) : ""}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};
