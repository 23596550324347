import React, { InputHTMLAttributes, ReactElement, useState } from "react";
import { Field, connect, getIn, FormikContextType } from "formik";
import MotiveringTextInputModal from "./MotiveringTextInput-Modal";
import classes from "./MotiveringTextInput.module.scss";
import { Icon, WarningPopup } from "adviesbox-shared";
import { ModalButton } from "adviesbox-shared";
import { StandaardteksenOnderwerpen, useInstellingenStandaardTeksten } from "../../hooks/use-instellingen-teksten-data";
import { ErrorPage } from "adviesbox-shared";
import { InvalidAdviesboxResultErrorPage } from "../fetch-error-page/fetch-error-page";
import { useParams } from "react-router-dom";

export type MotiveringTextInputProps = {
  name: string;
  title?: string;
  rows?: number;
  withModal?: boolean;
  afwijkendeKeuze?: boolean;
};

const MotiveringTextInput = ({
  name,
  title,
  rows = 5,
  formik,
  withModal = true,
  afwijkendeKeuze,
  ...props
}: MotiveringTextInputProps & { formik: FormikContextType<{}> } & InputHTMLAttributes<
    HTMLInputElement
  >): ReactElement => {
  const currentValue = getIn(formik.values, name);
  const locatieStandaardTeksten = `${name.slice(0, name.indexOf("."))}.standaardTeksten`;
  const standaardTeksten: string[] = getIn(formik.values, locatieStandaardTeksten) ?? [];
  const geenStandaardTeksten = "Geen standaardteksten beschikbaar";
  const [showDropdown, setShowDropdown] = useState(false);
  const [warning, setWarning] = useState(false);
  const { onderwerp } = useParams<{ onderwerp: StandaardteksenOnderwerpen }>();

  const {
    data: instellingenStandaardTeksten,
    error: errorInstellingenStandaardTeksten,
    loading: loadingInstellingenStandaardTeksten
  } = useInstellingenStandaardTeksten(onderwerp as StandaardteksenOnderwerpen); //TODO

  const dropdownItems = standaardTeksten?.map((tekst, index) => {
    return (
      <li
        key={`dropDownOptie-${index + 1}`}
        id={`dropDownOptie-${index + 1}`}
        data-testid={`dropDownOptie-${index + 1}`}
      >
        <button
          type="button"
          className={classes.dropdownbutton}
          onClick={/* istanbul ignore next */() => {
            formik.setFieldValue(
              name,
              `${currentValue ? currentValue : /* istanbul ignore next */ ""}${
                currentValue ? `\n` : /* istanbul ignore next */ ""
              }${tekst}`
            );
            /* istanbul ignore next */
            setShowDropdown(false);
          }}
        >
          {tekst}
        </button>
      </li>
    );
  });

  const geenStandaardtekstenItem = (
    <li id={`geen_standaardteksten`} data-testid={`geen_standaardteksten`} className={classes.geen_standaardteksten}>
      {geenStandaardTeksten}
    </li>
  );

  const dropdownStandaardTeksten = instellingenStandaardTeksten?.standaardTekstenMotiveringOnderwerp
    .filter(
      c =>
        (c.standaardtekstVoor === "AfwijkendeKeuze" && afwijkendeKeuze) ||
        (c.standaardtekstVoor === "OnsAdvies" && !afwijkendeKeuze)
    )
    .map((stdtekst, index) => {
      return (
        <li
          key={`dropDownOptie-${standaardTeksten.length + index + 1}`}
          id={`dropDownOptie-${standaardTeksten.length + index + 1}`}
          data-testid={`dropDownOptie-${standaardTeksten.length + index + 1}`}
        >
          <button
            type="button"
            className={classes.dropdownbutton}
            onClick={/* istanbul ignore next */() => {
              formik.setFieldValue(
                name,
                `${currentValue ? currentValue : /* istanbul ignore next */ ""}${
                  currentValue ? `\n` : /* istanbul ignore next */ ""
                }${stdtekst.tekst ?? ""}`
              );
              /* istanbul ignore next */ setShowDropdown(false);
            }}
          >
            {stdtekst.korteOmschrijving ?? `Geen omschrijving`}
          </button>
        </li>
      );
    });

  const dropDown = (
    <div className={`${classes.dropdown}`}>
      <button
        type="button"
        className={`${classes.button}`}
        id={`${name}-dropdown-toggle`}
        data-testid={`${name}-dropdown-toggle`}
        onClick={() => {
          setShowDropdown(!showDropdown);
        }}
      >
        <Icon name="specificatie" alt="Standaard teksten" iconSize="l" />
      </button>

      {showDropdown && (
        <ul>
          {!dropdownStandaardTeksten?.length && geenStandaardtekstenItem}
          {dropdownItems}
          {dropdownStandaardTeksten}
        </ul>
      )}
    </div>
  );

  if (loadingInstellingenStandaardTeksten) {
    return <p>loading...</p>;
  }

  if (errorInstellingenStandaardTeksten) {
    return <ErrorPage error={errorInstellingenStandaardTeksten} data={JSON.stringify(instellingenStandaardTeksten)} />;
  }

  if (!instellingenStandaardTeksten) {
    return <InvalidAdviesboxResultErrorPage />;
  }

  return (
    <>
      {warning && (
        <WarningPopup
          infotekst="Voordat u een standaardtekst toe kunt voegen moet u eerst een tekst invoeren"
          title="Standaardtekst opslaan niet mogelijk"
          show={true}
          onHide={() => setWarning(false)}
        />
      )}

      <div className={"form-group form-row pl-2 pr-2"}>
        <div className={`col-12`}>
          <div style={{ display: "flex", justifyContent: "right" }}>
            {dropDown}
            {withModal ? (
              <ModalButton parent={name} size="lg" content={<Icon name="fullscreen" alt="Groter scherm" />}>
                <MotiveringTextInputModal title={title} name={name} afwijkendeKeuze={!!afwijkendeKeuze} />
              </ModalButton>
            ) : (
              <div></div>
            )}
          </div>
          <div className="input-group w-100">
            <Field
              placeholder="Klik hier om jouw advies in te voeren"
              type="text"
              id={name}
              data-testid={name}
              name={name}
              component="textarea"
              rows={rows}
              className={`w-100 ${classes.input_control} ${classes.is_textarea}`}
              role="textbox"
              {...props}
              value={currentValue === null ? "" : currentValue}
            />
          </div>
        </div>
      </div>
    </>
  );
};

MotiveringTextInput.displayName = "MotiveringTextInput";

export default connect<MotiveringTextInputProps & InputHTMLAttributes<HTMLInputElement>>(MotiveringTextInput);
