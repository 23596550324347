import * as Yup from "yup";
import { InferType } from "yup";
import { Leningdeel, AflossingsVormType, ToetsrenteBerekeningOptions } from "../../.generated/forms/formstypes";
import { jaarMaandInputSchema } from "../../shared/generic-parts/jaar-maand/schema";
import { klantnaamSchema } from "../../shared/generic-parts/klantnaam/schema";
import { berekenInputSchema } from "../../shared/types";
import { yupEnum } from "../../shared/utils/yup-enum";
import { getMaximaleHypotheekTextResources } from "./maximale-hypotheek-resources";

export enum MaximaleHypotheekDataGridTemplates {
  uitslag,
  productnaam,
  toetsrente,
  extraAflossing,
  looptijd,
  maximaleHypotheek,
  maximaleHypotheekOnderpand,
  inkomstenVerklaring1,
  inkomstenVerklaring2
}

export enum MaximaleHypotheekUitslagTypes {
  rood = "Rood",
  groen = "Groen",
  oranje = "Oranje"
}

export type MaximaleHypotheekSortConfig = {
  reversed: boolean;
  field: MaximaleHypotheekDataGridTemplates | null;
};

export type MaximaleHypotheekSortFunc = (
  template: MaximaleHypotheekDataGridTemplates,
  advies: MappedBerekenMaximaleHypotheekResponse[],
  sorted: MaximaleHypotheekSortConfig,
  initialSort?: boolean
) => () => void;

export type MaximaleHypotheekSortFuncResponse = {
  sorted: MaximaleHypotheekSortConfig;
  advies: MappedBerekenMaximaleHypotheekResponse[];
};

export enum ToetsrenteBerekeningObv {
  SpecificatieToetsrente = "Specificatie toetsrente",
  GewensteRentevastperiode = "Gewenste rentevastperiode"
}

export enum ModalAflossingsVormType {
  Geen = "Geen",
  Aflosvrij = "Aflosvrij",
  Annuïteit = "Annuïteit",
  Spaarrekening = "Bankspaarhypotheek",
  Belegging = "Belegging",
  Hybride = "Hybride",
  Krediet = "Krediethypotheek",
  Levensverzekering = "Leven",
  Lineair = "Lineair",
  AnnuïteitUitgesteld = "Restschuld Annuïtair",
  AnnuïteitBlok = "Restschuld Lineair",
  Spaar = "Spaar",
  UnitLinked = "Starterslening",
  KredietNoPay = "KredietNoPay"
}

const berekenDefaults = Yup.object({
  bedrag: Yup.number()
    .nullable()
    .default(0),
  berekenen: Yup.boolean().default(false),
  berekendBedrag: Yup.number()
    .nullable()
    .default(null)
}).default({
  bedrag: 0,
  berekenen: null,
  berekendBedrag: null
});

export const mapToetsrenteBerekenenObv: Record<ToetsrenteBerekeningOptions, ToetsrenteBerekeningObv> &
  Record<ToetsrenteBerekeningObv, ToetsrenteBerekeningOptions> = {
  SpecificatieToetsrente: ToetsrenteBerekeningObv.SpecificatieToetsrente,
  GewensteRentevastperiode: ToetsrenteBerekeningObv.GewensteRentevastperiode,
  "Gewenste rentevastperiode": ToetsrenteBerekeningOptions.GewensteRentevastperiode,
  "Specificatie toetsrente": ToetsrenteBerekeningOptions.SpecificatieToetsrente
};

export const mapLeningdeelVorm: Record<ModalAflossingsVormType, AflossingsVormType> = {
  "Restschuld Annuïtair": AflossingsVormType.AnnuïteitUitgesteld,
  "Restschuld Lineair": AflossingsVormType.AnnuïteitBlok,
  Aflosvrij: AflossingsVormType.Aflosvrij,
  Annuïteit: AflossingsVormType.Annuïteit,
  Bankspaarhypotheek: AflossingsVormType.Spaarrekening,
  Belegging: AflossingsVormType.Belegging,
  Geen: AflossingsVormType.Geen,
  Hybride: AflossingsVormType.Hybride,
  Krediethypotheek: AflossingsVormType.Krediet,
  Leven: AflossingsVormType.Levensverzekering,
  Lineair: AflossingsVormType.Lineair,
  Spaar: AflossingsVormType.Spaar,
  Starterslening: AflossingsVormType.UnitLinked,
  KredietNoPay: AflossingsVormType.KredietNoPay
};

export const modalLeningdelenSchema = Yup.object({
  leningdeelId: Yup.string()
    .nullable()
    .default(null),
  hypotheekvorm: yupEnum(ModalAflossingsVormType)
    .default(ModalAflossingsVormType.Geen)
    .meta({ testdata: ModalAflossingsVormType }),
  opgebouwdeWaarde: Yup.number()
    .nullable()
    .default(null),
  hypotheekbedrag: Yup.number()
    .nullable()
    .default(null),
  looptijd: jaarMaandInputSchema.nullable().default(null),
  editable: Yup.boolean().default(true),
  deelBox3: Yup.number()
    .nullable()
    .default(null)
});
export type ModalLeningdelenEntryType = InferType<typeof modalLeningdelenSchema>;
export type ModalLeningdelenType = InferType<typeof modalLeningdelenSchema>[];

export const uitgangspuntenSchema = Yup.object({
  nhg: Yup.boolean()
    .nullable()
    .default(null),
  looptijd: Yup.number()
    .nullable()
    .test({
      message: getMaximaleHypotheekTextResources("validatieUitgangspuntenLooptijd"),
      test: function(val: number): boolean {
        return val <= 50 && val >= 5;
      }
    })
    .test({
      message: getMaximaleHypotheekTextResources("validatieUitgangspuntenLooptijdRentevastperiode"),
      test: function(val: number): boolean {
        const gewensteRentevastperiode = this.parent.gewensteRentevastperiode;
        return val >= gewensteRentevastperiode;
      }
    })
    .default(null),
  marktwaarde: Yup.number()
    .nullable()
    .default(null),
  gewensteHypotheek: Yup.number().default(0),
  andereFinanciering: Yup.number().default(0),
  totaleLening: Yup.number().default(0),
  objectSoort: Yup.string()
    .nullable()
    .default(null),
  recreatieveBewoning: Yup.boolean()
    .nullable()
    .default(false),
  gewensteRentevastperiode: Yup.number()
    .nullable()
    .default(null)
});
export type UitgangspuntenType = InferType<typeof uitgangspuntenSchema>;

export const leningdelenUitHetVerledenSchema = Yup.object({
  leningdelenMeenemen: Yup.boolean().default(false),
  meeTeNemenLeningdelen: Yup.array(modalLeningdelenSchema).default([]),
  readOnlyLeningDelen: Yup.array(modalLeningdelenSchema).default([])
});

export type LeningdelenUitHetVerledenType = InferType<typeof leningdelenUitHetVerledenSchema>;

export const toetsrenteSchema = Yup.object({
  berekeningOpBasisVan: yupEnum(ToetsrenteBerekeningObv)
    .default(ToetsrenteBerekeningObv.GewensteRentevastperiode)
    .meta({ testdata: ToetsrenteBerekeningObv }),
  toetsrente: Yup.number()
    .nullable()
    .test({
      message: getMaximaleHypotheekTextResources("errorToetsrente"),
      test: function(val: number): boolean {
        return this.parent.berekeningOpBasisVan === ToetsrenteBerekeningObv.SpecificatieToetsrente ? val > 0 : true;
      }
    })
    .default(null),
  gewensteRentevastperiode: Yup.number()
    .nullable()
    .test({
      message: getMaximaleHypotheekTextResources("validatieToetsrenteGewensteRentevastperiode"),
      test: function(val: number): boolean {
        const looptijd = this.parent.looptijd;
        return val <= looptijd;
      }
    })
    .default(null),
  vrijVermogen: Yup.number()
    .nullable()
    .default(null),
  kredietToetslast: berekenInputSchema.default(berekenDefaults.default()),
  looptijd: Yup.number()
    .nullable()
    .default(null)
});
export type ToetsrenteType = InferType<typeof toetsrenteSchema>;

export const maximaleHypothekenSchema = Yup.array(
  Yup.object({
    uitslag: Yup.string(),
    looptijd: Yup.number(),
    toetsrente: Yup.number(),
    productnaam: Yup.string(),
    maximaleHypotheek: Yup.number(),
    maximaleHypotheekOnderpand: Yup.number(),
    inkomstenVerklaring1: Yup.string().nullable(),
    inkomstenVerklaring2: Yup.string().nullable()
  })
);

export type MaximaleHypothekenType = InferType<typeof maximaleHypothekenSchema>;

export const inkomenSchema = Yup.object({
  toetsinkomenAanvrager: berekenInputSchema.default(berekenDefaults.default()),
  pensioeninkomenAanvrager: berekenInputSchema.default(berekenDefaults.default()),
  aowleeftijdAanvrager: Yup.string().default(""),
  toetsinkomenPartner: berekenInputSchema.default(berekenDefaults.default()),
  pensioeninkomenPartner: berekenInputSchema.default(berekenDefaults.default()),
  aowleeftijdPartner: Yup.string().default("")
});
export type InkomenType = InferType<typeof inkomenSchema>;

export const persoonsgegevensSchema = Yup.object({
  naamAanvrager: klantnaamSchema.nullable(),
  naamPartner: klantnaamSchema.nullable()
});
export type PersoonsgegevensType = InferType<typeof persoonsgegevensSchema>;

export const organisatieSchema = Yup.object({
  id: Yup.string()
    .nullable()
    .default(null),
  logo: Yup.string()
    .nullable()
    .default(null),
  platformId: Yup.string()
    .nullable()
    .default(null),
  bedrijfsnaam: Yup.string()
    .nullable()
    .default(null),
  kvkNummer: Yup.string()
    .nullable()
    .default(null),
  iban: Yup.string()
    .nullable()
    .default(null)
});

export type OrganisatieState = Yup.InferType<typeof organisatieSchema>;

export const maximaleHypotheekSchema = Yup.object({
  uitgangspunten: uitgangspuntenSchema,
  leningdelenUitHetVerleden: leningdelenUitHetVerledenSchema,
  toetsrente: toetsrenteSchema,
  inkomen: inkomenSchema,
  persoonsgegevens: persoonsgegevensSchema,
  maximaleHypotheken: maximaleHypothekenSchema.default([]),
  sortedMaximaleHypotheken: maximaleHypothekenSchema.default([]),
  dataHasChanged: Yup.boolean().default(false),
  orgData: organisatieSchema.nullable().default(null)
});
export type MaximaleHypotheekState = InferType<typeof maximaleHypotheekSchema>;
export type MaximaleHypotheekProps = InferType<typeof maximaleHypotheekSchema>;

export interface MappedBerekenMaximaleHypotheekResponse {
  uitslag: string;
  toetsrente: number;
  productnaam: string;
  looptijd: number;
  maximaleHypotheek: number;
  maximaleHypotheekOnderpand: number;
  inkomstenVerklaring1: string | null;
  inkomstenVerklaring2: string | null;
}

export interface MaximaleHypotheekDataGridState {
  values: MaximaleHypotheekState;
  setValues: (data: MaximaleHypotheekState) => void;
  errors: Record<string, any>;
}

export interface BerekenMaximaleHypotheekInput {
  hypotheekLooptijdInMaanden: number | null;
  toetsrenteBerekeningOpBasisVan: string | null;
  specificatieToetsrentePercentage: number | null;
  vrijVermogenBedrag: number | null;
  kredietToetslastBedrag: number | null;
  kredietToetslastOvernemen: boolean;
  gewensteRentevastperiodeInMaanden: number | null;
  leningdelenUitVerledenMeenemen: boolean;
  leningdelenUitVerleden: Leningdeel[];
  aanvrager1: InkomenAanvrager;
  aanvrager2: InkomenAanvrager;
}

export interface BerekenMaximaleHypotheekResponse {
  maatschappijCode: string;
  maatschappijNaam: string;
  maximaleHypotheek: number;
  maximaleHypotheekOnderpand: number;
  productCode: number;
  productnaam: string;
  toetsinkomenVerklaringAanvrager1: ToetsInkomenVeklaring | null;
  toetsinkomenVerklaringAanvrager2: ToetsInkomenVeklaring | null;
  toetsrente: number;
  uitslag: string;
}

export type TariefEntry = {
  jaar: number;
  maand: number;
};

type InkomenAanvrager = {
  toetsinkomenBedrag: number | null;
  toetsinkomenOvernemen: boolean;
  pensioeninkomenBedrag: number | null;
  pensioeninkomenOvernemen: boolean;
  aowLeeftijdInMaanden: number | null;
};

type ToetsInkomenVeklaring = {
  inkomensverklaring: string;
  toetsInkomen: number;
};
