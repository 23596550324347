import { InferType } from "yup";
import {
  kredietenSchema,
  geldnemersSchema,
  leningGegevensSchema,
  fiscaleGegevensSchema,
  kredietSchema,
  kredietProductSchema
} from "./kredieten-schema";

export type KredietenType = InferType<typeof kredietenSchema>;
export type KredietType = InferType<typeof kredietSchema>;
export type KredietProductType = InferType<typeof kredietProductSchema>;
export type GeldnemersType = InferType<typeof geldnemersSchema>;
export type LeningGegevensType = InferType<typeof leningGegevensSchema>;
export type FiscaleGegevensType = InferType<typeof fiscaleGegevensSchema>;

export type KredietenSchemaContextType = {
  values: KredietenType;
};

export const productCodeAflopendKrediet = "01";
export const productCodeDoorlopendKrediet = "02";
export const productCodeStudielening = "03";
export const productCodeRestschuldlening = "04";
export const productCodeWerkgeverslening = "05";
export const productCodePrivateLease = "06";
export const bestedingsDoelAuto = "02";
