/* istanbul ignore file */
import { createContext } from "react";
import { ContextType } from "./producten-provider";
import { ProductSoort } from "./product-kenmerken-types";

export const ProductkenmerkenContext = createContext<ContextType>({
  getProductKenmerken: (soort: ProductSoort, maatschappijCode: string, productCode: string): null => {
    return null;
  },
  resetProductKenmerken: () => null
});
