import { createISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";

import { FiscalegegevensType } from "../../producten-overzicht/infra/producten-overzicht-types";
import { HypotheekType } from "./hypotheek-types";
import { FiscaleGegevensContextType } from "./determine-hypotheek-side-effects";
import {
  bepaalFiscaleGegevensEinddatum
} from "../../producten-overzicht/infra/producten-helper";
import { optellen } from "../../shared/utils/currency";
import { GebruikPandSoort } from "../../.generated/forms/formstypes";
import { getGelinktPand } from "./hypotheek-utils";
import { hasValue } from "adviesbox-shared";
import { getRenteaftrekAanvangstdatum, getRenteaftrekEinddatum } from "../../producten-overzicht/infra/determine-producten-overzicht-side-effects";

const calculateDeelBedrag = (
  fiscalegegevens: FiscalegegevensType,
  eigenWoningSchuld: number | null,
  leningdeelBedrag: number,
  isTweedeWoning: boolean
): void => {
  const bedrag = eigenWoningSchuld && leningdeelBedrag > eigenWoningSchuld ? eigenWoningSchuld : leningdeelBedrag;
  fiscalegegevens.deelBox3Bedrag = isTweedeWoning ? bedrag : fiscalegegevens.deelBox3Bedrag;
  fiscalegegevens.deelBox1Bedrag = isTweedeWoning ? 0 : bedrag;
};

const calculatePercentage = (teller: number, noemer: number): number => {
  if (noemer === 0) return 0;
  const percentage = (teller / noemer) * 100;
  return percentage;
};

const calculateEersteWoning = (fiscalegegevens: FiscalegegevensType, leningdeelBedrag: number): void => {
  fiscalegegevens.deelBox1Bedrag = Math.min(Math.max(fiscalegegevens.deelBox1Bedrag, 0), leningdeelBedrag);
  fiscalegegevens.deelBox1Percentage = calculatePercentage(fiscalegegevens.deelBox1Bedrag, leningdeelBedrag);
  fiscalegegevens.deelBox3Bedrag = leningdeelBedrag - fiscalegegevens.deelBox1Bedrag;
  fiscalegegevens.deelBox3Percentage = 100 - fiscalegegevens.deelBox1Percentage;
};

const calculateTweedeWoning = (fiscalegegevens: FiscalegegevensType, leningdeelBedrag: number): void => {
  fiscalegegevens.deelBox3Bedrag = Math.min(Math.max(fiscalegegevens.deelBox3Bedrag, 0), leningdeelBedrag);
  fiscalegegevens.deelBox3Percentage = calculatePercentage(fiscalegegevens.deelBox3Bedrag, leningdeelBedrag);
  fiscalegegevens.deelBox1Bedrag = 0;
  fiscalegegevens.deelBox1Percentage = 0;
};

const calcBox1Box3 = (
  fiscalegegevens: FiscalegegevensType,
  leningdeelBedrag: number,
  isTweedeWoning: boolean
): void => {
  // TweedeWoning altijd box 3
  // EersteWoning box 1 en box 3 kan eventueel handmatig aangepast worden
  if (isTweedeWoning) calculateTweedeWoning(fiscalegegevens, leningdeelBedrag);
  else calculateEersteWoning(fiscalegegevens, leningdeelBedrag);
};

const fiscaleGegevensSideEffectsBedragen = createISWSideEffect<HypotheekType, FiscaleGegevensContextType>(
  ({ has, draft, context }): void => {
    const leningdeelBedrag = draft.leningdeelgegevens.leningdeelHoofdsom.bedrag || 0;

    if (has.fiscalegegevens.renteaftrekSpecificatie.renteAftrekken.changed) {
      const opgeteldeRenteAftrekken =
        optellen(draft.fiscalegegevens.renteaftrekSpecificatie.renteAftrekken.map(c => c.bedrag)) || 0;
      draft.fiscalegegevens.deelBox1Bedrag =
        draft.fiscalegegevens.deelBox1Bedrag !== opgeteldeRenteAftrekken && opgeteldeRenteAftrekken > 0
          ? opgeteldeRenteAftrekken
          : draft.fiscalegegevens.deelBox1Bedrag;
    }
    // Aanpassen deelBox1Bedrag a.d.h.v. de gewijzigde gegevens
    const gelinktePand = getGelinktPand(context.situatie, draft, context.panden);
    const gebruikPand = gelinktePand?.gebruikPand;
    const isTweedeWoning = gebruikPand === GebruikPandSoort.TweedeWoning;
    if (has.leningdeelgegevens.leningdeelHoofdsom.bedrag.changed) {
      // Bij een overwaarde hypotheek met opnames, dient het gehele bedrag van dit leningdeel in Box-3 geplaatst te zijn
      const deelbox = !!draft.opnameSoort && !gebruikPand ? true : isTweedeWoning;
      calculateDeelBedrag(draft.fiscalegegevens, context.eigenWoningSchuld, leningdeelBedrag, deelbox);
      calcBox1Box3(draft.fiscalegegevens, leningdeelBedrag, deelbox);
    } else {
      if (has.fiscalegegevens.deelBox1Bedrag.changed) {
        if (draft.fiscalegegevens.deelBox1Bedrag > leningdeelBedrag) {
          draft.fiscalegegevens.deelBox1Bedrag = leningdeelBedrag;
        }
        draft.fiscalegegevens.deelBox1Percentage = (draft.fiscalegegevens.deelBox1Bedrag / leningdeelBedrag) * 100.0;
        draft.fiscalegegevens.deelBox3Bedrag = leningdeelBedrag - draft.fiscalegegevens.deelBox1Bedrag;
        draft.fiscalegegevens.deelBox3Percentage = 100 - draft.fiscalegegevens.deelBox1Percentage;
      } else if (has.fiscalegegevens.deelBox1Percentage.changed) {
        draft.fiscalegegevens.deelBox1Bedrag = (leningdeelBedrag * draft.fiscalegegevens.deelBox1Percentage) / 100.0;
        draft.fiscalegegevens.deelBox3Bedrag = leningdeelBedrag - draft.fiscalegegevens.deelBox1Bedrag;
        draft.fiscalegegevens.deelBox3Percentage = 100 - draft.fiscalegegevens.deelBox1Percentage;
      } else if (has.fiscalegegevens.deelBox3Bedrag.changed) {
        if (draft.fiscalegegevens.deelBox3Bedrag > leningdeelBedrag) {
          draft.fiscalegegevens.deelBox3Bedrag = leningdeelBedrag;
        }
        draft.fiscalegegevens.deelBox3Percentage = (draft.fiscalegegevens.deelBox3Bedrag / leningdeelBedrag) * 100.0;
        draft.fiscalegegevens.deelBox1Bedrag = leningdeelBedrag - draft.fiscalegegevens.deelBox3Bedrag;
        draft.fiscalegegevens.deelBox1Percentage = 100 - draft.fiscalegegevens.deelBox3Percentage;
      } else if (has.fiscalegegevens.deelBox3Percentage.changed) {
        draft.fiscalegegevens.deelBox3Bedrag = (leningdeelBedrag * draft.fiscalegegevens.deelBox3Percentage) / 100.0;
        draft.fiscalegegevens.deelBox1Bedrag = leningdeelBedrag - draft.fiscalegegevens.deelBox3Bedrag;
        draft.fiscalegegevens.deelBox1Percentage = 100 - draft.fiscalegegevens.deelBox3Percentage;
      }
    }
    if (
      has.fiscalegegevens.deelBox1Bedrag.changed &&
      draft.fiscalegegevens.renteaftrekSpecificatie.renteAftrekken.length === 1
    ) {
      draft.fiscalegegevens.renteaftrekSpecificatie.renteAftrekken[0].bedrag = draft.fiscalegegevens.deelBox1Bedrag;
    }
  }
);

const fiscaleGegevensSideEffectsDatums = createISWSideEffect<HypotheekType>(({ has, draft, prev }): void => {
  if (draft.fiscalegegevens.renteaftrekSpecificatie.renteAftrekken) {
    const aantalRenteaftrekken = draft.fiscalegegevens.renteaftrekSpecificatie.renteAftrekken.length;
    if (aantalRenteaftrekken === 0) {
      if (prev.fiscalegegevens.deelBox1Bedrag === 0 && has.fiscalegegevens.deelBox1Bedrag.changed) {
        const ingangsdatum = draft.product.ingangsdatum;
        const einddatum = ingangsdatum ? bepaalFiscaleGegevensEinddatum(ingangsdatum) : null;
        draft.fiscalegegevens.renteaftrekSpecificatie.renteAftrekken.push({
          aanvangsdatum: ingangsdatum,
          einddatum: einddatum,
          bedrag: draft.fiscalegegevens.deelBox1Bedrag || 0
        });
        draft.fiscalegegevens.begindatumRenteaftrek =
          draft.fiscalegegevens.renteaftrekSpecificatie.renteAftrekken[0].aanvangsdatum;
        draft.fiscalegegevens.einddatumRenteaftrek =
          draft.fiscalegegevens.renteaftrekSpecificatie.renteAftrekken[0].einddatum;
      }
    } else if (aantalRenteaftrekken === 1) {
      // RESET: product looptijd wijzigt, neem ingangsdatum en einddatum product over als zijnde renteaftrekperiode
      if (((has.product.looptijd.changed || has.product.ingangsdatum.changed) && draft.product.ingangsdatum && hasValue(draft.product.looptijd.jaren))) {
        const ingangsdatum = draft.product.ingangsdatum;
        const einddatum = draft.product.ingangsdatum.plusYears(draft.product.looptijd.jaren).plusMonths(draft.product.looptijd.maanden || 0);
        draft.fiscalegegevens.begindatumRenteaftrek = ingangsdatum;
        draft.fiscalegegevens.einddatumRenteaftrek = einddatum;
        draft.fiscalegegevens.renteaftrekSpecificatie.renteAftrekken[0].aanvangsdatum = ingangsdatum;
        draft.fiscalegegevens.renteaftrekSpecificatie.renteAftrekken[0].einddatum = einddatum;
      }

      // renteaftrek duur - einddatum van renteaftrek buiten bereik van product, product einddatum wordt einddatum renteaftrek
      else if (has.fiscalegegevens.einddatumRenteaftrek.changed && draft.fiscalegegevens.einddatumRenteaftrek) {
        if (draft.product.einddatum && draft.fiscalegegevens.einddatumRenteaftrek > draft.product.einddatum) {
          draft.fiscalegegevens.einddatumRenteaftrek = draft.product.einddatum;
        }

        const eersteRenteAftrek = draft.fiscalegegevens.renteaftrekSpecificatie.renteAftrekken[0];
        if (eersteRenteAftrek.einddatum !== draft.fiscalegegevens.einddatumRenteaftrek) {
          eersteRenteAftrek.einddatum = draft.fiscalegegevens.einddatumRenteaftrek;
        }
      }

      // begindatum renteaftrek wijzigt -> bereken einddatum
      else if ((has.product.ingangsdatum.changed || has.fiscalegegevens.begindatumRenteaftrek.changed) && draft.fiscalegegevens.begindatumRenteaftrek) {
        if (draft.product.ingangsdatum && draft.fiscalegegevens.begindatumRenteaftrek < draft.product.ingangsdatum) {
          draft.fiscalegegevens.begindatumRenteaftrek = draft.product.ingangsdatum;
        }
        const eersteRenteAftrek = draft.fiscalegegevens.renteaftrekSpecificatie.renteAftrekken[0];
        if (eersteRenteAftrek.aanvangsdatum !== draft.fiscalegegevens.begindatumRenteaftrek) {
          eersteRenteAftrek.aanvangsdatum = draft.fiscalegegevens.begindatumRenteaftrek;

          // pas de einddatum aan en zorg dat deze binnen bereik van de looptijd van het product valt
          let eindDt = bepaalFiscaleGegevensEinddatum(draft.fiscalegegevens.begindatumRenteaftrek);
          if (draft.product.einddatum) {
            const productEindDt = draft.product.einddatum;
            if (eindDt > productEindDt) {
              eindDt = productEindDt;
            }
            eersteRenteAftrek.einddatum = draft.fiscalegegevens.einddatumRenteaftrek = eindDt;
          }
        }
      }
    }

    if (
      draft.fiscalegegevens.deelBox1Bedrag === 0 &&
      has.fiscalegegevens.deelBox1Bedrag.changed &&
      aantalRenteaftrekken > 0
    ) {
      draft.fiscalegegevens.renteaftrekSpecificatie.renteAftrekken = [];
      draft.fiscalegegevens.begindatumRenteaftrek = null;
      draft.fiscalegegevens.einddatumRenteaftrek = null;
    }

    if (
      has.fiscalegegevens.renteaftrekSpecificatie.renteAftrekken.changed &&
      draft?.fiscalegegevens.renteaftrekSpecificatie.renteAftrekken
    ) {
      draft.fiscalegegevens.begindatumRenteaftrek = getRenteaftrekAanvangstdatum(
        draft.fiscalegegevens.renteaftrekSpecificatie.renteAftrekken
      );
      draft.fiscalegegevens.einddatumRenteaftrek = getRenteaftrekEinddatum(
        draft.fiscalegegevens.renteaftrekSpecificatie.renteAftrekken
      );
    }
  }
});

export const fiscaleGegevensSideEffects = createISWSideEffect<HypotheekType, FiscaleGegevensContextType>(
  (bag): void => {
    const { has, draft, prev, context } = bag;
    if (context === undefined || prev === undefined || draft === undefined) {
      return;
    }

    if (has.fiscaleRegeling.fiscaleVoortzetting.changed) {
      const productKenmerken = context.fiscaleVoortzettingKeuzes?.find(
        x => x.productId === draft.fiscaleRegeling?.fiscaleVoortzetting
      )?.productkenmerken;

      if (productKenmerken && draft.fiscaleRegeling) {
        draft.fiscaleRegeling.polisnummer = productKenmerken.polisnummer;
        draft.fiscaleRegeling.originelePolisnummer = productKenmerken.originelePolisnummer;
        draft.fiscaleRegeling.kapitaalopbouw = productKenmerken.kapitaalopbouw;
        draft.fiscaleRegeling.externeMaatschappijCode = productKenmerken.externeMaatschappijCode;
        draft.fiscaleRegeling.externeMaatschappijOmschrijving = productKenmerken.externeMaatschappijOmschrijving;
        draft.fiscaleRegeling.lijfrenteclausuleOrigineel = productKenmerken.lijfrenteclausuleOrigineel;
        draft.fiscaleRegeling.fiscaleTypering = productKenmerken.fiscaleTypering;
        draft.fiscaleRegeling.garantieverzekering = productKenmerken.garantieverzekering;
        draft.fiscaleRegeling.fiscaalRegime = productKenmerken.fiscaalRegime;
        draft.fiscaleRegeling.oorspronkelijkeIngangsdatum = productKenmerken.oorspronkelijkeIngangsdatum;
        draft.fiscaleRegeling.ingangsdatumBox1 = productKenmerken.ingangsdatumBox1;
        draft.fiscaleRegeling.einddatum = productKenmerken.einddatum;
        draft.fiscaleRegeling.doelkapitaalBedrag = productKenmerken.doelkapitaalBedrag;
        draft.fiscaleRegeling.laagstePremieooitBedrag = productKenmerken.laagstePremieooitBedrag;
        draft.fiscaleRegeling.hoogstePremieOoitBedrag = productKenmerken.hoogstePremieOoitBedrag;
        draft.fiscaleRegeling.huidigeJaarPremieBedrag = productKenmerken.huidigeJaarPremieBedrag;
        draft.fiscaleRegeling.premieLopendJaarBedrag = productKenmerken.premieLopendJaarBedrag;
        draft.fiscaleRegeling.eerdereUitkeringenBedrag = productKenmerken.eerdereUitkeringenBedrag;
        draft.fiscaleRegeling.ingebrachteWaardeBedrag = productKenmerken.ingebrachteWaardeBedrag;
      }

      if (draft.premieGegevens) {
        draft.premieGegevens.aanvangExtraPremieStortingenBedrag = null;
      }
    }

    // Wijzigen van bedragen door wijziging van hypotheekOpWoning wordt wordt eerder afgehandeld
    if (!has.hypotheekProductDetails.hypotheekOpWoning.changed) {
      fiscaleGegevensSideEffectsBedragen(bag);
    }
    fiscaleGegevensSideEffectsDatums(bag);
  }
);
