import { TotaleRestschuldModalData } from "./totale-restschuld-modal";
import { initISWSideEffect, createISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";

const totaleRestschuldDraftSideEffects = createISWSideEffect<TotaleRestschuldModalData>((bag): void => {
  const { has, draft } = bag;
  if (
    has.data.restschuldHuidigeWoning.bedrag.changed ||
    has.data.extraKostenHuidigeHypotheek.changed ||
    has.data.inbrengEigenGeldVoorVerlagenRestschuld.changed
  ) {
    const restschuldInbrengOpgebouwdeWaardeTotaal = Math.max(
      (draft.data.restschuldHuidigeWoning.bedrag || 0) +
        (draft.data.extraKostenHuidigeHypotheek || 0) -
        (draft.data.inbrengEigenGeldVoorVerlagenRestschuld || 0),
      0
    );

    if (draft.eigenGeldInbrengOpgebouwdeWaardeTotaal > restschuldInbrengOpgebouwdeWaardeTotaal) {
      draft.data.inbrengOpgebouwdeWaardeRestschuld.berekendBedrag = restschuldInbrengOpgebouwdeWaardeTotaal;
      draft.data.inbrengOpgebouwdeWaarde = restschuldInbrengOpgebouwdeWaardeTotaal;
    } else {
      draft.data.inbrengOpgebouwdeWaardeRestschuld.berekendBedrag = draft.eigenGeldInbrengOpgebouwdeWaardeTotaal;
      draft.data.inbrengOpgebouwdeWaarde = draft.eigenGeldInbrengOpgebouwdeWaardeTotaal;
    }
  }
});

export const totaleRestschuldSideEffects = initISWSideEffect<TotaleRestschuldModalData>(
  totaleRestschuldDraftSideEffects
)();
