import { LocalDate } from "@js-joda/core";
import {
  hasValue,
  Icon,
  LabeledBevestigingInput,
  LabeledCurrencyInput,
  LabeledNumberInput,
  LabeledRadioInput,
  LabeledSelectInput,
  LabeledJaarMaandInput,
  ModalButton,
  FetchDataButton,
  LabeledResult,
  TooltipWrap
} from "adviesbox-shared";
import { connect, FormikContextType } from "formik";
import React, { ReactElement, useCallback, useMemo} from "react";
import { OrvPremieGegevenType, OrvProductType, OrvType } from "../../orv/infra/orv-schema";
import {
  BetalingsTermijnType,
  Indexatiesoort,
  PremieverloopOptions,
  SoortOrvProductOptions,
  FiscaleVoortzettingOptions
} from "../../.generated/forms/formstypes";
import { jaarMaandInMaanden } from "../../shared/generic-parts/jaar-maand/map-ui-2-dl";
import { bindSaveFunction } from "../../shared/utils/bind-save-function";
import { bedragFormat, optellen } from "../../shared/utils/currency";
import { getFormattedDate } from "../../shared/utils/dates";
import AanvangExtraPremieStortingenModal from "../aanvang-extra-premie-stortingen-modal/aanvang-extra-premie-stortingen-modal";
import { PremieKenmerken, ValidatieKenmerken } from "../infra/product-kenmerken-types";
import { getBetalingstermijnen } from "../infra/producten-helper";
import { getProductenOverzichtTextResources } from "../infra/producten-overzicht-resources";
import { extraStortingenJaarSchema } from "../infra/producten-overzicht-schema";
import {
  ExtraStortingenJaarType,
  PremieGegevensType,
  ProductDetailState,
  ProductenState,
  PremieDepotModalType,
} from "../infra/producten-overzicht-types";
import PremieDepotModal from "../premie-depot-modal/premie-depot-modal";
import PremieSpecificatieModal from "../premie-specificatie-modal/premie-specificatie-modal";
import { HypotheekFiscaleRegelingType } from "../../hypotheek/infra/hypotheek-types";
import verhoudingStylingClass from "../verhouding.module.scss";
import { premiedepotModalSchema } from "../../kapitaalverzekering/infra/kapitaalverzekering-schema";

type PremieGegevensProps = {
  selected: number;
  kenmerken: PremieKenmerken;
  validatieKenmerken: ValidatieKenmerken | null;
  calculate?: (input: any) => Promise<number | null>;
  isSpaarProduct?: boolean;
  isBeleggingProduct?: boolean;
  isHybrideProduct?: boolean;
  spaarPremieLaagEnabled?: boolean;
  premieNietAftrekbaar?: boolean;
  loading?: boolean;
  berekenKnopText?: string;
  showPremieBerekenenBtn?: boolean;
};

export const gethoogLaagConstructie = (selectedProduct: ProductDetailState): string | null => {
  let hoogLaagConstructieEinddatum: LocalDate;
  const ingangsdatum = selectedProduct.product.ingangsdatum || null;
  const hoogLaagLooptijd = selectedProduct.premieGegevens.hoogLaagLooptijd;
  if (ingangsdatum && (hoogLaagLooptijd?.jaren || hoogLaagLooptijd?.maanden)) {
    hoogLaagConstructieEinddatum = ingangsdatum.plusMonths(jaarMaandInMaanden(hoogLaagLooptijd) || 0);
    return getFormattedDate(hoogLaagConstructieEinddatum);
  }
  return null;
};
export const gethoogLaagEinddatum = (selectedProduct: ProductDetailState): string | null => {
  let hoogLaagEinddatum: LocalDate;
  const ingangsdatum = selectedProduct.product.ingangsdatum || null;
  if (ingangsdatum) {
    hoogLaagEinddatum = ingangsdatum.plusMonths(jaarMaandInMaanden(selectedProduct.premieGegevens.looptijd) || 0);
    return getFormattedDate(hoogLaagEinddatum);
  }
  return null;
};

function spaarPremieCaption(
  isSpaarProduct?: boolean,
  isBeleggingProduct?: boolean,
  isHybrideProduct?: boolean
): string {
  return isSpaarProduct
    ? "Spaarinleg"
    : isBeleggingProduct
    ? "Beleggingsinleg"
    : isHybrideProduct
    ? "Periodieke inleg"
    : "Spaarpremie";
}

const PremieGegevens = ({
  selected,
  kenmerken,
  validatieKenmerken,
  calculate,
  isSpaarProduct,
  isBeleggingProduct,
  isHybrideProduct,
  premieNietAftrekbaar,
  spaarPremieLaagEnabled = true,
  loading,
  berekenKnopText = "Premie berekenen",
  showPremieBerekenenBtn,
  formik: {
    setFieldValue,
    values: { producten },
    dirty,
    isValid
  }
}: PremieGegevensProps & {
  formik: FormikContextType<ProductenState>;
}): ReactElement => {
  const createSaveFunction = useCallback(bindSaveFunction(setFieldValue), [setFieldValue]);
  const { product } = producten[selected];
  const premieGegevens: PremieGegevensType | OrvPremieGegevenType = producten[selected].premieGegevens;
  const fiscaleRegeling: HypotheekFiscaleRegelingType = (producten[selected] as any).fiscaleRegeling;
  const premieBerekenKnopTonen = hasValue(showPremieBerekenenBtn) ? showPremieBerekenenBtn : true;

  let scenario: ExtraStortingenJaarType[] = [];
  if ("aanvangExtraPremieStortingen" in premieGegevens) {
    scenario = premieGegevens.aanvangExtraPremieStortingen
      ? premieGegevens.aanvangExtraPremieStortingen.scenario
      : [extraStortingenJaarSchema.default()];
  }
  const totaalExtraInleg = useMemo(() => bedragFormat(optellen(scenario.map(e => e.bedrag))), [scenario]);

  // scherm afhankelijkheden
  const hoogLaagDuurAanwezig = premieGegevens.hoogLaagLooptijd
    ? (premieGegevens.hoogLaagLooptijd.jaren || 0) * 12 + (premieGegevens.hoogLaagLooptijd.maanden || 0) > 0
    : false;

  const spaarPremieAftrekbaarTonen =
    kenmerken.aftrekbarePremieTonen &&
    kenmerken.spaarpremieTonen &&
    premieGegevens.betalingstermijn !== BetalingsTermijnType.Geen &&
    !premieNietAftrekbaar;

  const hoogLaagVerhoudingEnabled =
    !premieGegevens.hoogLaagLooptijd ||
    (!premieGegevens.hoogLaagLooptijd.jaren && !premieGegevens.hoogLaagLooptijd.maanden);

  const dekking = producten[selected]?.dekking;
  const isVerzekerdBedrag = dekking?.basisdekking === Indexatiesoort.VerzekerdBedrag;

  // Wordt gebruikt om gegevens van scherm naar premiedepot popup door te geven.
  /* istanbul ignore next */
  const premieDepotFactory = useCallback((depot: PremieDepotModalType): PremieDepotModalType => {
    return {
      ...depot,
      lagePremies: (!!premieGegevens.looptijd?.jaren || !!premieGegevens.looptijd?.maanden) && !!premieGegevens.totalePremieLaag,
      hogePremies: (!!premieGegevens.hoogLaagLooptijd?.jaren || !!premieGegevens.hoogLaagLooptijd?.maanden) && !!premieGegevens.totalePremieHoog,
      premieDuur: premieGegevens.looptijd,
      hoogInlegBedrag: premieGegevens.totalePremieHoog,
      hoogLaagDuur: premieGegevens.hoogLaagLooptijd,
      inlegBedrag: premieGegevens.totalePremieLaag,
      aanvangsstortingBedrag: premieGegevens.aanvangExtraPremieStortingenBedrag,
      extraPremieStortingen: premieGegevens.aanvangExtraPremieStortingen.scenario,
    };
  }, [premieGegevens]); 

  // onSave wordt vanuit modalbutton geinject.
  /* eslint-disable react/display-name */
  /* istanbul ignore next */
  const CurrentPremieDepotModal = useMemo(() => {
    return ({ onSave, ...props }: {onSave?:(data: any) => void}) => (
      <PremieDepotModal
        {...props}
        data={premieDepotFactory(premieGegevens.premiedepot || premiedepotModalSchema.default() as PremieDepotModalType)}
        looptijd={product.looptijd}
        onSave={(data) => {
          setFieldValue(`producten[${selected}].premieGegevens.premiedepotBedrag`, data.bedrag);
          setFieldValue(`producten[${selected}].premieGegevens.premiedepot`, data);
          onSave?.(data);
        }}
      />
    );
  }, [premieDepotFactory, premieGegevens, product.looptijd, selected, setFieldValue]);

  return (
    <>
      {kenmerken.verkortePremieduurTonen && (
        <LabeledBevestigingInput
          caption="Verkorte premieduur"
          name={`producten[${selected}].premieGegevens.verkortePremieduur`}
        />
      )}

      {kenmerken.einddatumPremiebetalingTonen && (
        <LabeledResult
          caption={isSpaarProduct ? "Einddatum" : "Einddatum premiebetaling"}
          name={`producten[${selected}].premieGegevens.einddatumPremieBetaling`}
          readonly={!kenmerken.einddatumPremiebetalingEnabled}
          fieldSize="no-size"
          tooltip={
            producten[selected].premieGegevens.hoogLaagLooptijd?.jaren ||
            producten[selected].premieGegevens.hoogLaagLooptijd?.maanden
              ? getProductenOverzichtTextResources("premieGegevensEinddatumPremieBetaling")
              : ""
          }
          result={(): string => gethoogLaagEinddatum(producten[selected]) || ""}
        />
      )}

      {kenmerken.duurPremiebetalingTonen && (
        <LabeledJaarMaandInput
          caption="Duur"
          name={`producten[${selected}].premieGegevens.looptijd`}
          readonly={!kenmerken.duurPremiebetalingEnabled}
        />
      )}

      {kenmerken.hoogLaagConstructieTonen && (
        <>
          <LabeledResult
            caption="Hoog / Laag-constructie tot"
            name={`producten[${selected}].premieGegevens.hoogLaagEinddatum`}
            readonly={isSpaarProduct ? true : !kenmerken.duurHoogLaagConstructieEnabled}
            result={(): string => gethoogLaagConstructie(producten[selected]) || ""}
          />
          <LabeledJaarMaandInput
            caption="Duur"
            name={`producten[${selected}].premieGegevens.hoogLaagLooptijd`}
            readonly={!kenmerken.duurHoogLaagConstructieEnabled}
          />
        </>
      )}

      {kenmerken.hoogLaagVerhoudingTonen && (
        <LabeledNumberInput
          prependChildren={
            <input type="text" className={`${verhoudingStylingClass.input_control}`} value="1 : " readOnly={true} />
          }
          caption="Hoog / Laag-verhouding"
          name={`producten[${selected}].premieGegevens.hoogLaagVerhouding`}
          readonly={hoogLaagVerhoudingEnabled}
          decimalen={2}
        />
      )}

      {kenmerken.betalingstermijnTonen && (
        <LabeledSelectInput
          caption={isSpaarProduct ? "Stortingstermijn" : "Betalingstermijn"}
          name={`producten[${selected}].premieGegevens.betalingstermijn`}
          readonly={!kenmerken.betalingstermijnEnabled}
          options={getBetalingstermijnen(kenmerken)}
        />
      )}

      {((!isSpaarProduct && kenmerken.spaarpremieTonen) || kenmerken.totalePremieTonen) && (
        <h2 className="ml-2">Spaarpremie</h2>
      )}
      {kenmerken.premieverloopTonen && (
        <LabeledRadioInput
          caption="Premieverloop"
          name={`producten[${selected}].premieverloop`}
          options={[
            {
              label: PremieverloopOptions.Gelijkblijvend,
              value: PremieverloopOptions.Gelijkblijvend
            },
            {
              label: PremieverloopOptions.Variabel,
              value: PremieverloopOptions.Variabel
            }
          ]}
          readOnly={kenmerken.heeftPremieverloopGelijkblijvend && kenmerken.heeftPremieverloopVariabel}
        />
      )}
      {kenmerken.spaarpremieTonen && !hoogLaagDuurAanwezig && (
        <LabeledCurrencyInput
          caption={spaarPremieCaption(isSpaarProduct, isBeleggingProduct, isHybrideProduct)}
          name={`producten[${selected}].premieGegevens.spaarPremieLaag`}
          readonly={!kenmerken.spaarpremieEnabled || !spaarPremieLaagEnabled}
          decimalen={2}
        />
      )}
      {kenmerken.spaarpremieTonen && hoogLaagDuurAanwezig && (
        <LabeledCurrencyInput
          caption={`${spaarPremieCaption(isSpaarProduct, isBeleggingProduct, isHybrideProduct)} laag`}
          name={`producten[${selected}].premieGegevens.spaarPremieLaag`}
          readonly={!kenmerken.premieHoogEnabled || !spaarPremieLaagEnabled}
          decimalen={2}
        />
      )}
      {kenmerken.spaarpremieTonen && hoogLaagDuurAanwezig && (
        <LabeledCurrencyInput
          caption={`${spaarPremieCaption(isSpaarProduct, isBeleggingProduct, isHybrideProduct)} hoog`}
          name={`producten[${selected}].premieGegevens.spaarPremieHoog`}
          readonly={!kenmerken.premieHoogEnabled}
          decimalen={2}
        />
      )}
      {kenmerken.spaarpremieTonen && spaarPremieAftrekbaarTonen && (
        <LabeledCurrencyInput
          caption="Aftrekbaar"
          name={`producten[${selected}].premieGegevens.spaarPremieAftrekbaar`}
          decimalen={2}
        />
      )}

      {kenmerken.risicopremieTonen && (
        <>
          {(kenmerken.spaarpremieTonen || kenmerken.totalePremieTonen) && <h2 className="ml-2">Risicopremie</h2>}
          <LabeledCurrencyInput
            caption={hoogLaagDuurAanwezig ? "Risicopremie laag" : "Risicopremie"}
            name={`producten[${selected}].premieGegevens.risicoPremieLaag`}
            readonly={!kenmerken.risicopremieEnabled}
            decimalen={2}
            tooltip={
              kenmerken.hintRisicopremiespecificatieTonen
                ? getProductenOverzichtTextResources("premieGegevensRisicoPremieLaag")
                : undefined
            }
            appendChildren={
              (kenmerken.risicopremiespecificatieTonen && (producten[selected].product as OrvProductType).soortProduct === SoortOrvProductOptions.Orv &&
              producten[selected].premieGegevens.premiespecificatie.termijnPremie.length > 1 &&
              ((producten[selected] as unknown) as OrvType).premieverloop === PremieverloopOptions.Variabel && (
                <ModalButton
                  parent={`producten[${selected}].premieGegevens.premiespecificatie`}
                  size="lg"
                  content={<Icon name="specificatie" alt="Premiespecificatie" />}
                >
                  <PremieSpecificatieModal
                    selected={selected}
                    data={premieGegevens.premiespecificatie}
                    onSave={() => {}}
                  />
                </ModalButton>
              ))
            }
          />
          {isVerzekerdBedrag && !premieNietAftrekbaar && (
            <LabeledBevestigingInput
              caption="Aftrekbaar"
              name={`producten[${selected}].premieGegevens.premieAftrekbaar`}
            />
          )}
          {hoogLaagDuurAanwezig && (
            <LabeledCurrencyInput
              caption="Risicopremie hoog"
              name={`producten[${selected}].premieGegevens.risicoPremieHoog`}
              readonly={!kenmerken.risicopremieEnabled}
              decimalen={2}
              appendChildren={
               (kenmerken.risicopremiespecificatieTonen &&
               (producten[selected].product as OrvProductType).soortProduct === SoortOrvProductOptions.Orv &&
               producten[selected].premieGegevens.premiespecificatie.termijnPremie.length > 1 &&
               ((producten[selected] as unknown) as OrvType).premieverloop === PremieverloopOptions.Variabel && 
                 (
                  <ModalButton
                    parent={`producten[${selected}].premieGegevens.risicoPremieHoog`}
                    size="lg"
                    content={<Icon name="specificatie" alt="Premie specificatie" />}
                  >
                    <PremieSpecificatieModal
                      selected={selected}
                      data={premieGegevens.premiespecificatie}
                      onSave={createSaveFunction(`producten[${selected}].premieGegevens.risicoPremieHoog`)}
                    />
                  </ModalButton>
                ))
              }
            />
          )}
        </>
      )}

      {kenmerken.totalePremieTonen && (
        <>
          {(kenmerken.spaarpremieTonen || kenmerken.risicopremieTonen) && <h2 className="ml-2">Totale premie</h2>}
          <LabeledCurrencyInput
            caption={hoogLaagDuurAanwezig ? "Totale premie laag" : "Totale premie"}
            name={`producten[${selected}].premieGegevens.totalePremieLaag`}
            readonly={!kenmerken.totalePremieEnabled}
            decimalen={2}
          />

          {hoogLaagDuurAanwezig && (
            <LabeledCurrencyInput
              caption="Totale premie hoog"
              name={`producten[${selected}].premieGegevens.totalePremieHoog`}
              readonly={!kenmerken.totalePremieEnabled}
              decimalen={2}
              appendChildren={
                true && (
                  <TooltipWrap
                    name="totale-premie-hoog"
                    warningText={getProductenOverzichtTextResources("totalePremieHoogWarning")}
                    placement="bottom"
                    iconType="waarschuwing"
                    tooltipClasses="px-1 mt-1"
                  />
                )
              }
            />
          )}

          {!premieNietAftrekbaar && (
            <LabeledCurrencyInput
              caption="Aftrekbaar"
              name={`producten[${selected}].premieGegevens.totalePremieAftrekbaar`}
              decimalen={2}
            />
          )}
        </>
      )}

      {kenmerken.aanvangstortingTonen &&
        (fiscaleRegeling?.fiscaleVoortzetting === null ||
          fiscaleRegeling?.fiscaleVoortzetting === FiscaleVoortzettingOptions.Geen ||
          validatieKenmerken?.HogeInlegFgvToegestaan === true) && (
          <LabeledCurrencyInput
            caption="Eerste inleg"
            decimalen={2}
            name={`producten[${selected}].premieGegevens.aanvangExtraPremieStortingenBedrag`}
            readonly={!kenmerken.aanvangstortingEnabled}
          />
        )}

      {kenmerken.extraStortingTonen && (
        <LabeledResult
          caption="Extra inleg"
          name={`producten[${selected}].premieGegevens.extraStorting`}
          result={() => totaalExtraInleg}
          alignRight={true}
          appendChildren={
            kenmerken.extraStortingTonen && (
              <ModalButton
                parent={`producten[${selected}].premieGegevens.aanvangExtraPremieStortingen`}
                size="lg"
                content={<Icon name="specificatie" alt="Aanvang extra premie stortingen" />}
              >
                <AanvangExtraPremieStortingenModal
                  data={premieGegevens.aanvangExtraPremieStortingen}
                  entries={product.looptijd.jaren}
                  onSave={createSaveFunction(`producten[${selected}].premieGegevens.aanvangExtraPremieStortingen`)}
                />
              </ModalButton>
            )
          }
        />
      )}

      {!isSpaarProduct && kenmerken.extraStortingTonen && !premieNietAftrekbaar && (
        <LabeledCurrencyInput
          caption="Aftrekbaar"
          name={`producten[${selected}].premieGegevens.aanvangExtraPremieStortingenAftrekbaar`}
          decimalen={2}
        />
      )}


{kenmerken.premiedepotTonen && (
        <LabeledCurrencyInput
          caption={isSpaarProduct ? "Inlegdepot" : "Premiedepot"}
          name={`producten[${selected}].premieGegevens.premiedepotBedrag`}
          decimalen={2}
          readonly={isSpaarProduct}
          appendChildren={
            <ModalButton
              parent={`producten[${selected}].premieGegevens.premiedepot`}
              size="lg"
              content={<Icon name="specificatie" alt="Premiedepot" />}
            >
              <CurrentPremieDepotModal />
            </ModalButton>
          }
        />
      )}

      {kenmerken.berekenPremieKnopTonen && premieBerekenKnopTonen && (
        <div className="button-container">
          <FetchDataButton
            keepVisible
            loading={loading}
            onClick={async (): Promise<void> => {
              if (calculate) {
                await calculate({ selected });
              }
            }}
            dataOutDated={dirty}
            initialText={berekenKnopText}
            invalid={!isValid}
            disabled={hoogLaagDuurAanwezig && !premieGegevens.hoogLaagVerhouding}
            data-testid={"btn-premie-berekenen"}
          />
        </div>
      )}
    </>
  );
};

export default connect<PremieGegevensProps, ProductenState>(PremieGegevens);
