import {
  aanleidingSchema,
  voorstelSchema,
  GebruikWoning,
  PlanningKeuzeType,
  FinancieringOptions,
  AanleidingGesprekKeuzeType
} from "./aanleiding-schema";
import {
  Aanleiding,
  AanleidingVoorstel,
  FinancielePlanningKeuze,
  AanleidingBasis,
  FinancielePlanningOptions,
  AanleidingGesprekOptions
} from "../../.generated/forms/formstypes";
import { createMapToDl } from "../../shared/utils/create-map-to-dl";

const mapFinancielePlanningKeuzes = (values: PlanningKeuzeType): FinancielePlanningKeuze[] => {
  const financielePlanningKeuzes = new Array<FinancielePlanningKeuze>();

  values.overlijden &&
    financielePlanningKeuzes.push({
      keuze: FinancielePlanningOptions.Overlijden
    });
  values.arbeidsongeschiktheid &&
    financielePlanningKeuzes.push({
      keuze: FinancielePlanningOptions.Arbeidsongeschiktheid
    });
  values.werkloosheid &&
    financielePlanningKeuzes.push({
      keuze: FinancielePlanningOptions.Werkloosheid
    });
  values.pensioen &&
    financielePlanningKeuzes.push({
      keuze: FinancielePlanningOptions.Pensioen
    });
  values.vermogensopbouw &&
    financielePlanningKeuzes.push({
      keuze: FinancielePlanningOptions.Vermogensopbouw
    });

  return financielePlanningKeuzes;
};

const mapAanleidingen = (values: AanleidingGesprekKeuzeType): AanleidingBasis[] => {
  const aanleidingBasis = new Array<AanleidingBasis>();
  values.afkooperfpacht &&
    aanleidingBasis.push({
      aanleidingGesprek: AanleidingGesprekOptions.AfkoopErfpacht,
      omschrijving: null
    });
  values.aflossingsVormen &&
    aanleidingBasis.push({
      aanleidingGesprek: AanleidingGesprekOptions.AflossingsvormAanpassen,
      omschrijving: null
    });
  values.echtscheiding &&
    aanleidingBasis.push({
      aanleidingGesprek: AanleidingGesprekOptions.Echtscheiding,
      omschrijving: null
    });
  values.eindeRentevastperiode &&
    aanleidingBasis.push({
      aanleidingGesprek: AanleidingGesprekOptions.EindeRentevastperiode,
      omschrijving: null
    });
  values.extraAflossen &&
    aanleidingBasis.push({
      aanleidingGesprek: AanleidingGesprekOptions.ExtraAflossen,
      omschrijving: null
    });
  values.maandlastenverlichting &&
    aanleidingBasis.push({
      aanleidingGesprek: AanleidingGesprekOptions.MaandlastenVerlichting,
      omschrijving: null
    });
  values.risicoscan &&
    aanleidingBasis.push({
      aanleidingGesprek: AanleidingGesprekOptions.Risicoscan,
      omschrijving: null
    });
  values.verbouwing &&
    aanleidingBasis.push({
      aanleidingGesprek: AanleidingGesprekOptions.Verbouwing,
      omschrijving: null
    });
  values.vermogensAdvies &&
    aanleidingBasis.push({
      aanleidingGesprek: AanleidingGesprekOptions.Vermogensadvies,
      omschrijving: null
    });
  values.controlegesprek &&
    aanleidingBasis.push({
      aanleidingGesprek: AanleidingGesprekOptions.Controlegesprek,
      omschrijving: null
    });
  values.anders &&
    aanleidingBasis.push({
      aanleidingGesprek: AanleidingGesprekOptions.Overig,
      omschrijving: values.andersTekst
    });
  return aanleidingBasis;
};

const mapVoorstel = createMapToDl(voorstelSchema).to<AanleidingVoorstel>({
  voorstelId: v => v.id,
  omschrijving: v => v.omschrijving,
  kopieVanVoorstelId: v => v.kopieVoorstelId
});

export const mapAanleidingUiNaarDl = createMapToDl(aanleidingSchema).to<Aanleiding>({
  financiering: v => v.aanleiding.financiering,
  financielePlanningKeuzes: v => mapFinancielePlanningKeuzes(v.planningKeuze),
  aanleidingen: v => mapAanleidingen(v.aanleidingGesprek),
  tweedeWoning: v =>
    v.aanleiding.financiering === FinancieringOptions.AankoopBestaandeBouw ||
    v.aanleiding.financiering === FinancieringOptions.AankoopNieuwbouw
      ? v.aanleiding.gebruikWoning === GebruikWoning.TweedeWoning
      : null,
  voorstellen: v => v.voorstellen.filter(voorstel => !voorstel.deleted).map(voorstel => mapVoorstel(voorstel)),
  aanvrager1: _ => null,
  aanvrager2: _ => null,
  huidigeWoningenInEigendomAanwezig: _ => false // TODO CONTROLEREN!!!
});
