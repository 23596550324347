import { useContext, useEffect, useState } from "react";
import { AppDataContext } from "../../navigation/appdata-context";

export const useSaveBeforeLoad = (preventReloading?: boolean): { loading: boolean } => {
  const { saveContext } = useContext(AppDataContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (saveContext) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      saveContext.saveDataFn(preventReloading).finally(() => {
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { loading };
};
