import { LabeledCurrencyInput, LabeledRadioInput, LabeledSelectInput, BerekenCurrencyInput } from "adviesbox-shared";
import { useFormikContext } from "formik";
import React, { ReactElement } from "react";
import { AovVerzekerdeDekkingenOptions, AovVoornaamsteInkomstenbronOptions } from "../../.generated/forms/formstypes";
import { AovsType, dekkingGegevensVerzekerdeSchema } from "../infra/aov-schema";
import { mapPartijProduct } from "../infra/map-aov-dl-to-ui";
import { dekkingOptions, DekkingProps, eigenRisicoOptions } from "./dekking";

export const DekkingDazureInkomensbeschermer = ({ selected, kenmerken, verzekerde }: DekkingProps): ReactElement => {
  const { values } = useFormikContext<AovsType>();
  const dekkingGegevens =
    verzekerde === 1
      ? values.producten[selected].dekking.dekkingGegevensVerzekerde1
      : values.producten[selected].dekking.dekkingGegevensVerzekerde2 ??
        /* istanbul ignore next*/ dekkingGegevensVerzekerdeSchema.default(); //TODO: test schrijven

  const partijProductCode = mapPartijProduct(
    values.producten[selected].partijCode,
    values.producten[selected].productCode
  );

  return (
    <>
      {kenmerken.verzekerdeDekkingTonen && (
        <LabeledRadioInput
          caption={"Dekking"}
          name={`producten[${selected}].dekking.dekkingGegevensVerzekerde${verzekerde}.dekking`}
          options={dekkingOptions(
            kenmerken,
            dekkingGegevens.voornaamsteInkomstenbron !== AovVoornaamsteInkomstenbronOptions.Zelfstandige,
            partijProductCode
          )}
        />
      )}

      <BerekenCurrencyInput
        caption="Bruto maandinkomen"
        name={`producten[${selected}].dekking.maandinkomen`}
        readonly={kenmerken.maandinkomenEnabled}
      />
      <LabeledCurrencyInput
        caption="Berekend netto maandinkomen"
        name={`producten[${selected}].dekking.nettoMaandinkomen`}
        readonly={true}
      />

      <LabeledCurrencyInput
        name={`producten[${selected}].dekking.dekkingGegevensVerzekerde${verzekerde}.ao.verzekerdBedrag`}
        caption="Verzekerd bedrag volledig AO"
        readonly={true}
      />

      <LabeledCurrencyInput
        name={`producten[${selected}].dekking.dekkingGegevensVerzekerde${verzekerde}.ao.verzekerdBedragGedeeltelijkAo`}
        caption="Verzekerd bedrag gedeeltelijk AO"
        readonly={true}
      />

      <LabeledSelectInput
        options={eigenRisicoOptions(kenmerken)}
        readonly={false}
        caption="Eigen risico"
        name={`producten[${selected}].dekking.dekkingGegevensVerzekerde${verzekerde}.ao.eigenRisico`}
      />

      {dekkingGegevens.dekking === AovVerzekerdeDekkingenOptions.AoWw && (
        <LabeledCurrencyInput
          readonly={true}
          name={`producten[${selected}].dekking.dekkingGegevensVerzekerde${verzekerde}.ww.verzekerdBedrag`}
          caption="Verzekerd bedrag WW"
        />
      )}
    </>
  );
};
