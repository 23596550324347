import { createContext } from "react";
import {
  MedewerkerKlantdossierRechtenOrganisatie,
  MedewerkerKlantdossierRechtenVestigingen
} from "../../.generated/licenties/licentiestypes";

export type logger = {
  setAdviesDossierId: (
    adviesDossierId: string | null,
    klantdossierId: string | null,
    vestigingId: string | null
  ) => void;
  lezenEnSchrijvenRechtenVestigingen: MedewerkerKlantdossierRechtenVestigingen | null;
  lezenEnSchrijvenRechtenOrganisatie: MedewerkerKlantdossierRechtenOrganisatie | null;
  setLezenEnSchrijvenRechtenVestigingen: (id: MedewerkerKlantdossierRechtenVestigingen | null) => void;
  setLezenEnSchrijvenRechtenOrganisatie: (id: MedewerkerKlantdossierRechtenOrganisatie | null) => void;
  getAdviseurIds: (vestigingId: string | null) => void;
  adviseurIds: string[];
  adviseurIdsOphalen: boolean;
};

export const contextData: logger = {
  setAdviesDossierId: () => {},
  lezenEnSchrijvenRechtenVestigingen: null,
  lezenEnSchrijvenRechtenOrganisatie: null,
  setLezenEnSchrijvenRechtenVestigingen: () => {},
  setLezenEnSchrijvenRechtenOrganisatie: () => {},
  getAdviseurIds: () => [],
  adviseurIds: [],
  adviseurIdsOphalen: false
};

const OpenDossierLogContext = createContext(contextData);
export default OpenDossierLogContext;
