import {
  LabeledBerekenDate,
  LabeledBevestigingInput,
  LabeledCurrencyInput,
  LabeledDateInput,
  LabeledNumberInput,
  LabeledPercentageInput,
  LabeledRadioInput,
  LabeledSelectInput,
  LabeledTextInput
} from "adviesbox-shared";
import { FormikProps } from "formik";
import React, { ReactElement } from "react";
import { Button, Modal } from "react-bootstrap";
import { DevDebug } from "../../shared/components/dev-debug/dev-debug";
import LabeledHdnKeuzelijst from "../../shared/components/hdn-keuzelijst/LabeledHdnKeuzelijst";
import { ISWSideEffects } from "../../shared/components/isw-side-effects/isw-side-effects";
import AdviesBoxFormik from "../../shared/utils/adviesbox-formik";
import { enum2options } from "../../shared/utils/enum-to-options";
import { getInkomenEnFiscusTextResources } from "../infra/inkomen-en-fiscus-resources";
import {
  ondernemingModalSchema,
  OndernemingModalType,
  RechtsVorm,
  SoortInkomenverklaring,
  SoortOnderneming
} from "../infra/inkomen-en-fiscus-schema";
import Inkomensverleden, { WinstCaption, WinstSoort } from "../infra/shared-components/inkomensverleden";
import ScenarioInstellingen from "../infra/shared-components/scenario-instellingen";
import { ondernemingModalSideEffects } from "./onderneming-modal-side-effects";

export type OndernemingModalData = {
  data: OndernemingModalType;
};
type OndernemingModalProps = {
  onSave?: (data: OndernemingModalType) => void;
  closeModal?: () => void;
};

const OndernemingModal = ({ data, onSave, closeModal }: OndernemingModalData & OndernemingModalProps): ReactElement => {
  return (
    <AdviesBoxFormik<OndernemingModalType>
      initialValues={{ ...data }}
      validationSchema={ondernemingModalSchema}
      onSave={onSave}
      closeModal={closeModal}
      render={({ values: { dga, verklaringInkomen }, submitForm }: FormikProps<OndernemingModalType>): ReactElement => {
        const winstSoort = dga ? WinstSoort.Arbeid : WinstSoort.Onderneming;
        const showAccountantFields = verklaringInkomen === SoortInkomenverklaring.Accountantsverklaring;
        return (
          <>
            <ISWSideEffects<OndernemingModalType> sync={ondernemingModalSideEffects()} />
            <Modal.Header closeButton>
              <Modal.Title>Onderneming</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h3>
                <b>Gegevens (hoofd)onderneming</b>
              </h3>
              <LabeledSelectInput
                caption="Soort onderneming"
                name="soortOnderneming"
                options={enum2options(SoortOnderneming)}
              />
              <LabeledSelectInput caption="Rechtsvorm" name="rechtsvorm" options={enum2options(RechtsVorm)} />
              <LabeledDateInput
                caption="Ingangsdatum onderneming"
                name="ingangsdatumOnderneming"
                tooltip={getInkomenEnFiscusTextResources("TooltipOndernemingIngangsdatum")}
              />
              <LabeledBerekenDate caption="Inkomen meetellen tot" name="inkomenMeetellenTot" />
              <LabeledTextInput caption="KvK-nummer" name="kvkNummer" fieldSize="xl" />
              <LabeledTextInput caption="SBI-code" name="sbiCode" />
              <LabeledTextInput caption="RSIN-nummer" name="rsinNummer" fieldSize="xl" />
              <LabeledBevestigingInput
                caption="DGA"
                name="dga"
                tooltip={getInkomenEnFiscusTextResources("TooltipOndernemingDga")}
              />
              {dga && <LabeledPercentageInput caption="Aandelen percentage" name="aandelenPercentage" decimalen={2} />}
              <h3 className="mt-4">
                <b>Verklaring inkomen</b>
              </h3>
              <LabeledRadioInput
                caption="Verklaring inkomen"
                name="verklaringInkomen"
                options={[
                  {
                    label: "IB stukken",
                    value: SoortInkomenverklaring.IbStukken
                  },
                  {
                    label: "Accountantsverklaring",
                    value: SoortInkomenverklaring.Accountantsverklaring
                  }
                ]}
              />
              {showAccountantFields && (
                <>
                  <LabeledCurrencyInput caption="Toetsinkomen" name="toetsinkomen" fieldSize="m" />
                  <LabeledNumberInput caption="Aantal ondernemingen" name="aantalOndernemingen" />
                  <LabeledTextInput
                    caption="BOI"
                    name="boi"
                    tooltip={getInkomenEnFiscusTextResources("TooltipOndernemingBoi")}
                  />
                  <LabeledTextInput caption="Dossiernummer" name="dossiernummer" />
                  <LabeledDateInput caption="Datum uitgifte" name="uitgifteDatum" />
                  <LabeledHdnKeuzelijst
                    caption="Rekenexpert"
                    name="rekenexpert"
                    berichtSoortType="AX"
                    keuzelijst="RekenexpertType"
                  />
                </>
              )}

              <h3 className="mt-3">
                <b>{WinstCaption(winstSoort)}</b>
              </h3>
              <Inkomensverleden soort={winstSoort} name="inkomensverleden" />
              <ScenarioInstellingen name="scenarioInstellingen" />

              <DevDebug />
            </Modal.Body>
            <Modal.Footer>
              <Button data-testid="btnannuleren" variant="link" onClick={closeModal} id="btnannuleren">
                Annuleren
              </Button>
              <Button data-testid="btnopslaan" variant="primary" onClick={submitForm} id="btnopslaan">
                Bevestigen
              </Button>
            </Modal.Footer>
          </>
        );
      }}
    />
  );
};

export default OndernemingModal;
