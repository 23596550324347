import React, { ReactElement } from "react";
import { FormikProps } from "formik";
import Modal from "../../shared/components/modal/Modal";

import { BelastingBoxOptions, Financieringsoort } from "../../.generated/forms/formstypes";
import { getFinancieringsbehoefteTextResources } from "../infra/financieringsbehoefte-resources";
import { CellInfo } from "react-table-6";
import { LabeledCurrencyInput, Label, DataGrid, AdviesBoxColumn } from "adviesbox-shared";
import AdviesBoxFormik from "../../shared/utils/adviesbox-formik";
import { OverigeKostenInHypotheekModal as OverigeKostenInHypotheekModalState } from "../infra/financieringsbehoefte-types";
import { overigeKostenInHypotheekModalSchema } from "../infra/financieringsbehoefte-schema";

export type OverigeKostenInHypotheekModalData = {
  data: OverigeKostenInHypotheekModalState;
};

type OverigeKostenInHypotheekModalProps = {
  onSave?: (data: OverigeKostenInHypotheekModalState) => void;
  closeModal?: () => void;
};

const Boxes = [
  {
    value: BelastingBoxOptions.Box1,
    label: "Box 1"
  },
  {
    value: BelastingBoxOptions.Box3,
    label: "Box 3"
  }
];

const overigeKostenSpecificatiesColumns: AdviesBoxColumn[] = [
  {
    Header: "Omschrijving",
    accessor: "omschrijving",
    Cell: "TextInput",
    width: 135,
    config: {
      readonly: (cell: CellInfo): boolean => cell.index === 0,
      className: (cell: CellInfo): string => (cell.index === 0 ? "not-underlined" : "")
    }
  },
  {
    Header: "Bedrag",
    accessor: "bedrag",
    Cell: "CurrencyInput",
    width: 100
  },
  {
    Header: "Fiscale plaatsing",
    accessor: "fiscalePlaatsing",
    Cell: "SelectInput",
    width: 100,
    config: {
      options: Boxes
    }
  },
  {
    Header: (
      <Label
        caption="Aftrekbaar"
        name="aftrekbaarHeader"
        tooltip={getFinancieringsbehoefteTextResources("TooltipAftrekbaar")}
      />
    ),
    accessor: "aftrekbaar",
    Cell: "CheckBox",
    width: 100
  }
];

const OverigeKostenInHypotheekModal = ({
  data,
  onSave,
  closeModal
}: OverigeKostenInHypotheekModalData & OverigeKostenInHypotheekModalProps): ReactElement => {
  const body = (values: OverigeKostenInHypotheekModalState): ReactElement => (
    <>
      <LabeledCurrencyInput
        caption="Lasten overbruggingskrediet"
        name="financieringsopzet.overigeKostenInHypotheekModal.lastenOverbruggingskrediet"
        readonly={true}
        tooltip={getFinancieringsbehoefteTextResources("TooltipLastenOverbruggingskrediet")}
      />
      <LabeledCurrencyInput caption="Consumptief te besteden" name="gewenstConsumptiefBedrag" />

      {values.soortFinanciering === Financieringsoort.Oversluiten && (
        <LabeledCurrencyInput caption="Royementskosten" name="royementskostenBedrag" />
      )}

      <DataGrid editable columns={overigeKostenSpecificatiesColumns} name="overigeKostenSpecificaties" />
    </>
  );
  return (
    <AdviesBoxFormik<OverigeKostenInHypotheekModalState>
      initialValues={{ ...data }}
      validationSchema={overigeKostenInHypotheekModalSchema}
      onSave={onSave}
      closeModal={closeModal}
      render={({ values, submitForm }: FormikProps<OverigeKostenInHypotheekModalState>): ReactElement => (
        <>
          <Modal
            title="Overige kosten in hypotheek"
            body={body(values)}
            onSubmitClick={submitForm}
            onCancelClick={closeModal}
          />
        </>
      )}
    />
  );
};

export default OverigeKostenInHypotheekModal;
