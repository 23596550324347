import { initISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";
import { DashboardState } from "./dashboard-types";

export type DashboardSyncSideEffectsContext = {
    setDashboardValues: (_: DashboardState) => void,
    updateVersion: () => void
}

export const dashboardSyncSideEffects = initISWSideEffect<DashboardState, DashboardSyncSideEffectsContext>(({ has, prev, draft, context }): void => {
    if (has.version.changed) {
        context.setDashboardValues(prev);
    }
    else if (has.changed) {
        context.updateVersion();
    }
});