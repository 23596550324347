import "adviesbox-shared";
import { hasValue, Icon, LabeledCurrencyInput, ModalButton, TooltipWrap } from "adviesbox-shared";
import { useFormikContext } from "formik";
import React, { ReactElement } from "react";
import { AcceptatieState } from "../infra/acceptatie-schema";
import { SpecificatieExtraAflossingModal } from "../specificatie-extra-aflossing-modal/specificatie-extra-aflossing";
import { SpecificatieHypotheekModal } from "../specificatie-gevraagde-hypotheek-modal/specificatie-hypotheek";
import { SpecificatieIndicatieModal } from "../specificatie-indicatie-meerminder-lenen/specificatie-indicatie";

export const HypotheekBijCard = (): ReactElement => {
  const { values } = useFormikContext<AcceptatieState>();
  const showToetslast =
    hasValue(values.hypotheekBijCard.kredietToetslast) && values.hypotheekBijCard.kredietToetslast > 0;
  const showErfpachtCanon =
    hasValue(values.hypotheekBijCard.erfpachtCanon) && values.hypotheekBijCard.erfpachtCanon > 0;

  return (
    <>
      {values.hypotheekBijCard.aanvrager1Naam && values.hypotheekBijCard.brutoInkomenAanvrager1 !== null && (
        <LabeledCurrencyInput
          readonly={true}
          caption={"Bruto inkomen " + values.hypotheekBijCard.aanvrager1Naam}
          name="hypotheekBijCard.brutoInkomenAanvrager1"
          dashForZero
          tooltip={values.hypotheekBijCard.hintTextAanvrager1 ? values.hypotheekBijCard.hintTextAanvrager1 : <></>}
        />
      )}

      {values.hypotheekBijCard.aanvrager2Naam && values.hypotheekBijCard.brutoInkomenAanvrager2 !== null && (
        <LabeledCurrencyInput
          readonly={true}
          caption={"Bruto inkomen " + values.hypotheekBijCard.aanvrager2Naam}
          name="hypotheekBijCard.brutoInkomenAanvrager2"
          tooltip={values.hypotheekBijCard.hintTextAanvrager2 ? values.hypotheekBijCard.hintTextAanvrager2 : <></>}
          dashForZero
        />
      )}

      {showToetslast && (
        <LabeledCurrencyInput
          readonly={true}
          caption="Krediet toetslast"
          name="hypotheekBijCard.kredietToetslast"
          appendChildren={<small className={"pt-2 pl-2"}>Per jaar</small>}
        />
      )}

      <LabeledCurrencyInput readonly={true} caption="Marktwaarde" name="hypotheekBijCard.marktwaarde" />

      {showErfpachtCanon && (
        <LabeledCurrencyInput readonly={true} caption="Erfpachtcanon" name="hypotheekBijCard.erfpachtCanon" />
      )}

      <LabeledCurrencyInput
        readonly={true}
        caption="Totaal gevraagde hypotheek"
        name="hypotheekBijCard.totaalGevraagdeHypotheek"
        appendChildren={
          values.hypotheekBijCard &&
          values.hypotheekBijCard.totaalGevraagdeHypotheek &&
          values.hypotheekBijCard.totaalGevraagdeHypotheek > 0 ? (
            <ModalButton
              parent="specificatieTotaalGevraagdeHypotheek"
              aria-label="Modal currency veld button"
              size="lg"
              content={<Icon name="specificatie" />}
            >
              <SpecificatieHypotheekModal data={values.specificatieTotaalGevraagdeHypotheek} />
            </ModalButton>
          ) : (
            <></>
          )
        }
      />

      <LabeledCurrencyInput
        readonly={true}
        caption="Indicatie meer/minder lenen"
        name="hypotheekBijCard.indicatieMeerMinderLenen"
        appendChildren={
          values.hypotheekBijCard &&
          values.hypotheekBijCard.indicatieMeerMinderLenen &&
          values.hypotheekBijCard.indicatieMeerMinderLenen !== 0 ? (
            <ModalButton
              parent="specificatieIndicatieMeerMinder"
              aria-label="Modal currency veld button"
              size="lg"
              content={<Icon name="specificatie" />}
            >
              <SpecificatieIndicatieModal data={values.specificatieIndicatieMeerMinder} />
            </ModalButton>
          ) : (
            <></>
          )
        }
      />

      {!!values.hypotheekBijCard.verplichteExtraAflossing && (
        <LabeledCurrencyInput
          readonly={true}
          caption="Verplichte extra aflossing"
          name="hypotheekBijCard.verplichteExtraAflossing"
          appendChildren={
            <ModalButton
              parent="specificatieExtraAflossing"
              aria-label="Modal currency veld button"
              size="lg"
              content={<Icon name="specificatie" />}
            >
              <SpecificatieExtraAflossingModal data={values.specificatieExtraAflossing} />
            </ModalButton>
          }
        />
      )}

      <LabeledCurrencyInput
        readonly={true}
        caption="Maximale hypotheek o.b.v. inkomen en lasten"
        name="hypotheekBijCard.maximaalObvInkomenLasten"
        appendChildren={
          <>
          <div className="pt-1 pl-2">
            {<Icon name={values.hypotheekBijCard.maximaalObvInkomenLastenIndicatie ? "vink" : "kruis"} />}
          </div>
          {values.hypotheekBijCard.toonWaarschuwingMaxHypotheekIsMaxHypotheekBox3  && (
            <TooltipWrap
            name="maxhypotheekbox3"
            warningText="Gemaximeerd op de 'maximale lening op basis van inkomen box 3' (zie toelichting)."
            placement="bottom"
            iconType="waarschuwing"
            tooltipClasses="pt-1 ml-1"
          />
          )}
          </>
        }
      />
      <LabeledCurrencyInput
        readonly={true}
        caption="Maximale hypotheek o.b.v. onderpand en lening"
        name="hypotheekBijCard.maximaalObvOnderpand"
        appendChildren={
          <div className="pt-1 pl-2">
            {<Icon name={values.hypotheekBijCard.maximaalObvOnderpandIndicatie ? "vink" : "kruis"} />}
          </div>
        }
      />
      {values.hypotheekBijCard.opNHGBasis && (
        <LabeledCurrencyInput
          readonly={true}
          caption="Maximale hypotheek o.b.v. NHG voorwaarden"
          name="hypotheekBijCard.maximaalObvNHGVoorwaarden"
          appendChildren={
            <div className="pt-1 pl-2">
              <Icon name={values.hypotheekBijCard.maximaalObvNHGVoorwaardenIndicatie ? "vink" : "kruis"} />
            </div>
          }
        />
      )}
    </>
  );
};
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production") HypotheekBijCard.displayName = "HypotheekBijCard";
