import * as Yup from "yup";
import { ValidationError } from "yup";
import { SpecificatieRenteaftrekModalType } from "../../hypotheek/infra/hypotheek-types";
import {
  SoortAflosproductOptions,
  FiscaalRegimeOptions,
  FiscaleTyperingOptions,
  FiscaleVoortzettingOptions,
  KapitaalopbouwOptions,
  PremiedepotUitgangspunt,
  BelastingBoxOptions,
  SoortLijfrenteUitkeringOptions,
  BetalingsTermijnType,
  Verzekeringsmotief,
  UitkeringBijOverlijdenSoort,
  SoortVermogensrekeningOptions,
  SoortBerekeningOptions,
  BeleggersprofielOptions,
  SoortKapitaalverzekeringsrekeningOptions,
  Indexatiesoort,
  SoortBerekeningOptionsKv,
  DoelVerzekeringOptions
} from "../../.generated/forms/formstypes";
import {
  PeriodiekBedragInputTermijn,
  PeriodiekeOnttrekkingInputTermijn
} from "../../.generated/vermogen/vermogentypes";
import { jaarMaandInMaanden } from "../../shared/generic-parts/jaar-maand/map-ui-2-dl";
import { jaarMaandInputSchema, JaarMaandInputType } from "../../shared/generic-parts/jaar-maand/schema";
import { klantnaamSchema } from "../../shared/generic-parts/klantnaam/schema";
import { AanvragerKeuze, berekenInputSchema, scenarioCardSchema } from "../../shared/types";
import { optellen } from "../../shared/utils/currency";
import { hasValue } from "../../shared/utils/helpers";
import { getGeneralTextResources } from "../../shared/utils/text-resources-algemeen";
import { yupEnum, yupNullableEnum } from "../../shared/utils/yup-enum";
import { OverzichtKenmerken } from "./product-kenmerken-types";
import { bepaalFiscaleGegevensEinddatum } from "./producten-helper";
import { getProductenOverzichtTextResources } from "./producten-overzicht-resources";
import { FiscalegegevensType } from "./producten-overzicht-types";
import { nullableLocalDateSchema } from "adviesbox-shared";

export const productKeuzeModalSchema = Yup.object({
  maatschappijCode: Yup.string()
    .min(2)
    .max(3)
    .default("XX"),
  productCode: Yup.string()
    .length(2)
    .default("01"),
  // unkown omdat er veel verschillende types enum in kunnen komen te staan en die toch eerst gecast moeten worden
  productVorm: Yup.mixed<unknown>()
    .nullable()
    .default(null)
});

const errorHoogLaagVerhouding = getProductenOverzichtTextResources("ErrorHoogLaagVerhouding");
const errorDuurUitkeringMoetGevuldZijn = getProductenOverzichtTextResources("ErrorDuurUitkeringMoetGevuldZijn");
const errorDuurUitkeringHoogMinderDanTotaal = getProductenOverzichtTextResources(
  "ErrorDuurUitkeringHoogMinderDanTotaal"
);

export const indicatieveUitkerendeFaseSchema = Yup.object({
  belastingBox: Yup.mixed<BelastingBoxOptions>()
    .nullable()
    .oneOf([...Object.values(BelastingBoxOptions), null])
    .default(null),
  soortLijfrenteUitkering: Yup.mixed<SoortLijfrenteUitkeringOptions>()
    .nullable()
    .oneOf([...Object.values(SoortLijfrenteUitkeringOptions), null])
    .default(null),
  duurUitkering: jaarMaandInputSchema
    .default({
      ...jaarMaandInputSchema.default(),
      jaren: 0,
      maanden: null
    })
    .test({
      test: function(value: JaarMaandInputType): boolean | Yup.ValidationError {
        const indicatieveUitkerendeFase = this.parent as IndicatieveUitkerendeFaseType;
        const { soortLijfrenteUitkering } = indicatieveUitkerendeFase;

        if (soortLijfrenteUitkering !== SoortLijfrenteUitkeringOptions.Levenslang) {
          const duurUitkeringHoog = jaarMaandInMaanden(indicatieveUitkerendeFase.duurUitkeringHoog) ?? -1;
          const duurUitkering = jaarMaandInMaanden(value);

          if (duurUitkering === null) {
            // Note: Een duurUitkering van null is ongelding volgens platform maar 0 mag wel
            return this.createError({ path: `${this.path}.jaren`, message: errorDuurUitkeringMoetGevuldZijn });
          }

          if (duurUitkeringHoog !== -1 && duurUitkeringHoog >= duurUitkering) {
            return this.createError({ path: `${this.path}.jaren`, message: errorDuurUitkeringHoogMinderDanTotaal });
          }
        }

        return true;
      }
    }),
  duurUitkeringHoog: jaarMaandInputSchema.test({
    message: errorDuurUitkeringHoogMinderDanTotaal,
    test: function(value: JaarMaandInputType): boolean | Yup.ValidationError {
      const indicatieveUitkerendeFase = this.parent as IndicatieveUitkerendeFaseType;

      const { soortLijfrenteUitkering } = indicatieveUitkerendeFase;

      if (soortLijfrenteUitkering !== SoortLijfrenteUitkeringOptions.Levenslang) {
        const duurUitkeringHoog = jaarMaandInMaanden(value) ?? -1;
        const duurUitkering = jaarMaandInMaanden(indicatieveUitkerendeFase.duurUitkering) ?? 0;

        if (duurUitkering !== -1 && duurUitkeringHoog >= duurUitkering) {
          return this.createError({ path: `${this.path}.jaren` });
        }
      }

      return true;
    }
  }),
  hoogLaagVerhouding: Yup.number()
    .nullable()
    .default(null)
    .min(1, errorHoogLaagVerhouding)
    .max(25, errorHoogLaagVerhouding),
  lijfrenteTarief: Yup.number()
    .nullable()
    .default(null),
  overgangOpTweedeVerzekerde: Yup.boolean()
    .nullable()
    .default(null),
  overgangOpTweedeVerzekerdePercentage: Yup.number()
    .nullable()
    .default(null),
  lijfrenteUitkering: berekenInputSchema,
  termijn: Yup.mixed<BetalingsTermijnType>()
    .nullable()
    .oneOf([...Object.values(BetalingsTermijnType), null])
    .default(BetalingsTermijnType.Maand)
});

export type IndicatieveUitkerendeFaseType = Yup.InferType<typeof indicatieveUitkerendeFaseSchema>;

export const indicatieveUitkerendeFaseModalSchema = Yup.object({
  values: indicatieveUitkerendeFaseSchema
});

const gewensteUitkeringPerPeriodeAanvragerSchema = yupEnum(BetalingsTermijnType).default(BetalingsTermijnType.Maand);

const errorOngeldigGehanteerdTariefAanvragerMessage = getProductenOverzichtTextResources(
  "ErrorOngeldigGehanteerdTariefAanvrager"
);

export const aanvullingInkomenBijOverlijdenModalSchema = Yup.object({
  aanvullingInkomenBijOverlijdenAanvrager1: Yup.boolean().default(false),
  gehanteerdTariefAanvrager1: Yup.number()
    .min(0, errorOngeldigGehanteerdTariefAanvragerMessage)
    .max(20, errorOngeldigGehanteerdTariefAanvragerMessage)
    .nullable()
    .default(null),
  gewensteUitkeringAanvrager1: Yup.number()
    .nullable()
    .default(null),
  gewensteUitkeringPerPeriodeAanvrager1: gewensteUitkeringPerPeriodeAanvragerSchema.default(
    gewensteUitkeringPerPeriodeAanvragerSchema.default()
  ),
  duurUitkeringAanvrager1: jaarMaandInputSchema.nullable().default(null),
  aanvullingInkomenBijOverlijdenAanvrager2: Yup.boolean().default(false),
  gehanteerdTariefAanvrager2: Yup.number()
    .min(0, errorOngeldigGehanteerdTariefAanvragerMessage)
    .max(20, errorOngeldigGehanteerdTariefAanvragerMessage)
    .nullable()
    .default(null),
  gewensteUitkeringAanvrager2: Yup.number()
    .nullable()
    .default(null),
  gewensteUitkeringPerPeriodeAanvrager2: gewensteUitkeringPerPeriodeAanvragerSchema.default(
    gewensteUitkeringPerPeriodeAanvragerSchema.default()
  ),
  duurUitkeringAanvrager2: jaarMaandInputSchema.nullable().default(null),
  verzekerdKapitaalAanvrager1: Yup.number()
    .nullable()
    .default(null),
  verzekerdKapitaalAanvrager2: Yup.number()
    .nullable()
    .default(null)
});

export const leningdeelReadOnlySchema = Yup.object({
  leningdeelBedrag: Yup.number()
    .nullable()
    .default(undefined),
  leningdeelId: Yup.string().default(""),
  doorlopend: Yup.boolean().default(false),
  productnaam: Yup.string()
    .nullable()
    .default(null),
  maatschappijCode: Yup.string()
    .nullable()
    .default(null),
  productcode: Yup.number().default(0),
  aanvangsdatum: Yup.string().default(""),
  looptijdInMaanden: Yup.number().default(0),
  volgnummer: Yup.number().default(0)
});

export const kredietReadOnlySchema = Yup.object({
  kredietId: Yup.string().default(""),
  doorlopend: Yup.boolean().default(false),
  productnaam: Yup.string()
    .nullable()
    .default(null)
});

export const aflosproductSchema = Yup.object({
  leningdeelBedrag: Yup.number()
    .nullable()
    .default(undefined),
  soortAflosproduct: yupNullableEnum(SoortAflosproductOptions).default(SoortAflosproductOptions.Leningdeel),
  index: Yup.number(),
  productId: Yup.string(),
  omschrijving: Yup.string(),
  aflossen: Yup.boolean().default(false),
  prioriteit: Yup.number()
    .nullable()
    .min(0)
    .max(255)
    .default(null),
  ingangsdatum: nullableLocalDateSchema,
  leningdeelNummer: Yup.number()
    .nullable()
    .default(null),
  looptijd: Yup.number()
    .nullable()
    .default(null),
  doorlopend: Yup.boolean()
    .nullable()
    .default(null),
  maatschappijCode: Yup.string()
    .nullable()
    .default(null),
  volgnummer: Yup.number().default(null) // todo: CONTROLEREN, verplicht volgnummer gaan gebruiken??
});

export const afTeLossenLeningdelenModalSchema = Yup.object({
  aflosproducten: Yup.array()
    .of(aflosproductSchema)
    .default([])
});

export type PremiedepotModalContextType = {
  looptijd: JaarMaandInputType;
};

export const extraStortingenJaarSchema = Yup.object({
  bedrag: Yup.number()
    .nullable()
    .default(null)
});

export const premiedepotModalSchema = Yup.object({
  uitgangspunt: Yup.mixed<PremiedepotUitgangspunt | null>()
    .oneOf([...Object.values(PremiedepotUitgangspunt), null])
    .default(PremiedepotUitgangspunt.Geen),
  lagePremies: Yup.boolean()
    .nullable()
    .default(false),
  hogePremies: Yup.boolean()
    .nullable()
    .default(false),
  aanvangsstorting: Yup.boolean()
    .nullable()
    .default(false),
  extraStortingen: Yup.boolean()
    .nullable()
    .default(false),
  vergoeding: Yup.number()
    .nullable()
    .default(null),
  bedrag: Yup.number()
    .nullable()
    .default(null),
  duur: Yup.number()
    .nullable()
    .default(null)
    .test("max-duur", getProductenOverzichtTextResources("ErrorDuurPremiedepot"), function(
      duur: number | number
    ): boolean {
      const context = this.options.context as PremiedepotModalContextType | null;
      const uitgangspunt = this.parent.uitgangspunt as PremiedepotUitgangspunt | null;

      if (uitgangspunt !== null || /* istanbul ignore next */ uitgangspunt !== PremiedepotUitgangspunt.Geen) {
        if (duur && context) {
          const looptijd = jaarMaandInMaanden(context.looptijd);
          if (looptijd && duur > looptijd) {
            return false;
          }
        }
      }

      return true;
    }),
  hoogLaagDuur: jaarMaandInputSchema.nullable().default(null),
  premieDuur: jaarMaandInputSchema.nullable().default(null),
  inlegBedrag: Yup.number()
    .nullable()
    .default(null),
  hoogInlegBedrag: Yup.number()
    .nullable()
    .default(null),
  aanvangsstortingBedrag: Yup.number()
    .nullable()
    .default(null),
  extraPremieStortingen: Yup.array(extraStortingenJaarSchema)
    .default(null)
    .nullable()
});

export const aanvangExtraPremieStortingenModalSchema = Yup.object({
  scenario: Yup.array(extraStortingenJaarSchema).default([])
});

export const premieSpecificatieModalSchema = Yup.object({
  termijnPremie: scenarioCardSchema.default([])
});

const productLooptijdDefault: JaarMaandInputType = { jaren: 30, maanden: 0 };

export const productSchemaYup = Yup.object({
  doorlopend: Yup.boolean().default(false),
  productNummer: Yup.string().default(""),
  partijCodeSelectie: Yup.string().default(""),
  partijNaam: Yup.string().default(""),
  omschrijving: Yup.string().default(""),
  productNaam: Yup.string()
    .nullable()
    .default(null),
  ingangsdatum: nullableLocalDateSchema,
  einddatum: nullableLocalDateSchema,
  looptijd: jaarMaandInputSchema.default(productLooptijdDefault),
  uwBemiddeling: Yup.boolean().default(false),
  meenemen: Yup.boolean().default(false),
  renteboxCode: Yup.number()
    .nullable()
    .default(null),
  wijzigingenInDoorlopendProductOvernemen: Yup.boolean()
    .nullable()
    .default(null)
});

export const productSchema = productSchemaYup;

export const verzekeringNemersSchema = Yup.object({
  verzekeringnemers: Yup.mixed<AanvragerKeuze>()
    .oneOf(Object.values(AanvragerKeuze), getProductenOverzichtTextResources("ErrorOnbekendeWaarde"))
    .default(AanvragerKeuze.Aanvrager1)
});

export const verzekerdenSchema = Yup.object({
  verzekerden: Yup.mixed<AanvragerKeuze>()
    .oneOf(Object.values(AanvragerKeuze), getProductenOverzichtTextResources("ErrorOnbekendeWaarde"))
    .default(AanvragerKeuze.Aanvrager1),
  premiesplitsing: Yup.boolean()
    .nullable()
    .default(null)
});

export const dekkingSchema = Yup.object({
  annuiteitspercentage1: Yup.number()
    .nullable()
    .default(null),
  annuiteitspercentage2: Yup.number()
    .nullable()
    .default(null),

  verzekeringsmotief: Yup.mixed<Verzekeringsmotief | null>()
    .oneOf([...Object.values(Verzekeringsmotief), null], getProductenOverzichtTextResources("ErrorOnbekendeWaarde"))
    .nullable()
    .default(null),
  doelVerzekering: Yup.mixed<DoelVerzekeringOptions | null>()
    .oneOf([...Object.values(DoelVerzekeringOptions), null], getProductenOverzichtTextResources("ErrorOnbekendeWaarde"))
    .nullable()
    .default(null),
  verzekerdKapitaalAanvrager1: Yup.number()
    .nullable()
    .default(null),
  dekkingDaaltTotAanvrager1: Yup.number()
    .nullable()
    .test(
      "dekking daalt tot lager dan verzekerdkapitaal",
      getProductenOverzichtTextResources("dekkingDaaltTotHogerDanVerzekerdKapitaal"),
      function(value): boolean {
        if (!value) return true;
        return value < this.parent.verzekerdKapitaalAanvrager1;
      }
    )
    .default(null),
  verzekerdKapitaalAanvrager2: Yup.number()
    .nullable()
    .default(null),
  dekkingDaaltTotAanvrager2: Yup.number()
    .nullable()
    .default(null)
    .test(
      "dekking daalt tot lager dan verzekerdkapitaal",
      getProductenOverzichtTextResources("dekkingDaaltTotHogerDanVerzekerdKapitaal"),
      function(value): boolean {
        if (!value) return true;
        return value < this.parent.verzekerdKapitaalAanvrager2;
      }
    ),
  duurDalingInJaren1: Yup.number()
    .nullable()
    .default(null)
    .max(99, getGeneralTextResources("ErrorAantalJaren"))
    .min(0, getGeneralTextResources("ErrorAantalJaren")),
  duurDalingInJaren2: Yup.number()
    .nullable()
    .default(null)
    .max(99, getGeneralTextResources("ErrorAantalJaren"))
    .min(0, getGeneralTextResources("ErrorAantalJaren")),
  reservewaarde: Yup.boolean()
    .nullable()
    .default(null),
  basisdekking: Yup.mixed<Indexatiesoort | null>()
    .oneOf([...Object.values(Indexatiesoort), null])
    .nullable()
    .default(null),
  einddatum: nullableLocalDateSchema.default(null),
  verzekerdMaandbedrag: Yup.number()
    .nullable()
    .default(null),
  eindleeftijd: Yup.number().nullable(),
  uitkeringBij: yupEnum(UitkeringBijOverlijdenSoort).default(UitkeringBijOverlijdenSoort.MaandNaOverlijden)
});

export const premieGegevensSchema = Yup.object({
  verkortePremieduur: Yup.boolean()
    .nullable()
    .default(null),
  einddatumPremieBetaling: nullableLocalDateSchema,
  looptijd: jaarMaandInputSchema,
  hoogLaagEinddatum: nullableLocalDateSchema,
  hoogLaagLooptijd: jaarMaandInputSchema.nullable().default(jaarMaandInputSchema.default()),
  hoogLaagVerhouding: Yup.number()
    .nullable()
    .test("min-max-check", getProductenOverzichtTextResources("hoogLaagVerhoudingError"), function(value): boolean {
      if (this.parent.validatieEnabled) {
        return value <= 10;
      }
      return true;
    })
    .default(null),
  betalingstermijn: Yup.mixed<
    BetalingsTermijnType | PeriodiekBedragInputTermijn | PeriodiekeOnttrekkingInputTermijn | null
  >()
    .oneOf(
      [
        ...Object.values(BetalingsTermijnType),
        ...Object.values(PeriodiekBedragInputTermijn),
        ...Object.values(PeriodiekeOnttrekkingInputTermijn),
        null
      ],
      getProductenOverzichtTextResources("ErrorOnbekendeWaarde")
    )
    .default(BetalingsTermijnType.Maand),
  spaarPremieLaag: Yup.number()
    .nullable()
    .default(null),
  spaarPremieHoog: Yup.number()
    .nullable()
    .default(null),
  spaarPremieAftrekbaar: Yup.number()
    .nullable()
    .test("max-check", "Aftrekbaar deel maximaal gelijk aan premie.", function(): boolean {
      const max = Math.max(this.parent.spaarPremieHoog, this.parent.spaarPremieLaag);

      return this.parent.spaarPremieAftrekbaar <= max;
    })
    .default(null),
  risicoPremieLaag: Yup.number()
    .nullable()
    .default(null),
  risicoPremieHoog: Yup.number()
    .nullable()
    .default(null),
  premiespecificatie: premieSpecificatieModalSchema,
  totalePremieLaag: Yup.number()
    .nullable()
    .default(null),
  totalePremieHoog: Yup.number()
    .nullable()
    .default(null),
  totalePremieAftrekbaar: Yup.number()
    .nullable()
    .test("max-check", "Aftrekbaar deel maximaal gelijk aan premie.", function(): boolean {
      const max = Math.max(this.parent.totalePremieHoog, this.parent.totalePremieLaag);

      return this.parent.totalePremieAftrekbaar <= max;
    })
    .default(null),
  aanvangExtraPremieStortingenBedrag: Yup.number()
    .nullable()
    .default(null),
  aanvangExtraPremieStortingen: aanvangExtraPremieStortingenModalSchema.default(
    aanvangExtraPremieStortingenModalSchema.default()
  ),
  aanvangExtraPremieStortingenAftrekbaar: Yup.number()
    .nullable()
    .max(Yup.ref("aanvangExtraPremieStortingenBedrag"), "Aftrekbaar deel maximaal gelijk aan premie.")
    .default(null),
  premiedepotBedrag: Yup.number()
    .nullable()
    .default(null),
  premiedepot: premiedepotModalSchema.nullable().default(null),
  validatieEnabled: Yup.boolean().default(false)
});

export const verpandingSchema = Yup.object({
  verpandAanGeldverstrekker: Yup.boolean().default(false),
  bedoeldVoorAflossing: Yup.boolean().default(false),
  indicatieveUitkerendeFase: Yup.boolean().default(false),
  inkomensaanvulling: Yup.boolean().default(false),
  bedoeldVoorAflossingSpecificatie: afTeLossenLeningdelenModalSchema,
  indicatieveUitkerendeFaseSpecificatie: indicatieveUitkerendeFaseModalSchema.default(
    indicatieveUitkerendeFaseModalSchema.default()
  ),
  inkomensaanvullingSpecificatie: aanvullingInkomenBijOverlijdenModalSchema
});

export const informatieVoorVerzendingAanvraagSchema = Yup.object({
  toelichtingGelezenAkkoordMetSlotverklaring: Yup.boolean()
    .nullable()
    .default(null),
  akkoordMetDigitaleCommunicatie: Yup.boolean()
    .nullable()
    .default(null),
  akkoordMetDigitalePolis: Yup.boolean()
    .nullable()
    .default(null),
  strafrechtelijkVerledenAanvrager1: Yup.boolean()
    .nullable()
    .default(null),
  strafrechtelijkVerledenAanvrager2: Yup.boolean()
    .nullable()
    .default(null)
});

export const renteAftrekSchema = Yup.object({
  aanvangsdatum: nullableLocalDateSchema,
  einddatum: nullableLocalDateSchema,
  bedrag: Yup.number()
    .nullable()
    .default(null)
});

export const specificatieRenteaftrekModalSchema = Yup.object().shape({
  renteAftrekken: Yup.array(renteAftrekSchema).default([])
});

const fiscalegegevensSchemaYup = Yup.object({
  deelBox1Bedrag: Yup.number().default(0),
  deelBox1Percentage: Yup.number()
    .max(100)
    .default(100),
  deelBox3Bedrag: Yup.number()
    .default(0)
    .test("hypotheek-opnames-volledig-box3", "", function(value: number): true | ValidationError {
      const valid = !this.parent.bevatOpnames || (!!this.parent.bevatOpnames && !this.parent.deelBox1Bedrag);

      /* istanbul ignore else */
      if (valid) {
        return true;
      } else {
        return this.createError({
          path: `${this.path}`,
          message:
            "Bij een overwaarde hypotheek met opnames, dient het gehele bedrag van dit leningdeel in Box-3 geplaatst te zijn."
        });
      }
    }),
  deelBox3Percentage: Yup.number()
    .max(100)
    .default(0),
  bevatOpnames: Yup.boolean().default(false), // nodig voor deelBox3Bedrag validaties
  consumptiefBedrag: Yup.number()
    .nullable()
    .default(null)
    .test("veld verplicht", getProductenOverzichtTextResources("ConsumptiefKredietTeHoog"), function(value): boolean {
      if (value && this.parent.deelBox3Bedrag && value > this.parent.deelBox3Bedrag) {
        return false;
      }
      return true;
    }),
  renteaftrekSpecificatie: specificatieRenteaftrekModalSchema.test(
    "max-check",
    getProductenOverzichtTextResources("ErrorRenteAftrekkenBedragGelijkaanBox1"),
    function(): boolean {
      const renteAftrekken = (this.parent as FiscalegegevensType)
        .renteaftrekSpecificatie as SpecificatieRenteaftrekModalType;
      const totaalRenteAftrekken = optellen(renteAftrekken.renteAftrekken.map((x): number | null => x.bedrag));
      if (totaalRenteAftrekken) {
        return totaalRenteAftrekken === (this.parent as FiscalegegevensType).deelBox1Bedrag;
      }
      return true;
    }
  ),
  begindatumRenteaftrek: nullableLocalDateSchema.test(
    "veld verplicht",
    getProductenOverzichtTextResources("ErrorDatumVerplicht"),
    function(value): boolean {
      if (this.parent.deelBox1Bedrag > 0) {
        return value !== null;
      }
      return true;
    }
  ),
  einddatumRenteaftrek: nullableLocalDateSchema.test(
    "min-max-check",
    getProductenOverzichtTextResources("ErrorEinddatumMax"),
    function(value): boolean {
      if (this.parent.begindatumRenteaftrek === null) return true;
      const beginDatum = this.parent.begindatumRenteaftrek;
      const maximaleBeginDatum = bepaalFiscaleGegevensEinddatum(beginDatum);

      return value <= maximaleBeginDatum && value >= this.parent.begindatumRenteaftrek;
    }
  )
});
export const fiscalegegevensSchema = fiscalegegevensSchemaYup;

export const kapitaalopbouwSchemaYup = Yup.object({
  soortRekening: Yup.mixed<SoortVermogensrekeningOptions | SoortKapitaalverzekeringsrekeningOptions>()
    .oneOf([
      ...Object.values(SoortVermogensrekeningOptions),
      ...Object.values(SoortKapitaalverzekeringsrekeningOptions),
      null
    ])
    .nullable()
    .default(null),
  soortBerekening: Yup.mixed<SoortBerekeningOptions | SoortBerekeningOptionsKv>()
    .oneOf([...Object.values(SoortBerekeningOptions), ...Object.values(SoortBerekeningOptionsKv), null])
    .nullable()
    .default(null),
  beleggersprofiel: Yup.mixed<BeleggersprofielOptions>()
    .oneOf([...Object.values(BeleggersprofielOptions), null])
    .nullable()
    .default(null),
  doelkapitaalBedrag: Yup.number()
    .nullable()
    .default(null)
    .test("value-check", "", function(): true | ValidationError {
      if (!hasValue(this.parent.doelkapitaalBedrag) && this.parent.bedoeldVoorAflossing) {
        const message = getProductenOverzichtTextResources("leningdeelAflossenError");
        return this.createError({
          message
        });
      } else {
        return true;
      }
    }),
  doelrendementPercentage: Yup.number()
    .min(0)
    .max(100)
    .nullable()
    .default(null),
  voorbeeldkapitaalBedrag: Yup.number()
    .nullable()
    .default(null),
  voorbeeldrendementPercentage: Yup.number()
    .min(0)
    .max(100)
    .nullable()
    .default(null),
  garantiekapitaalBedrag: Yup.number()
    .nullable()
    .default(null),
  garantierendementPercentage: Yup.number()
    .min(0)
    .max(100)
    .nullable()
    .default(null),
  bedoeldVoorAflossing: Yup.boolean().default(false)
});

export const kapitaalopbouwSchema = kapitaalopbouwSchemaYup;

export const productDetailSchema = Yup.object({
  partijCode: Yup.string(),
  productCode: Yup.string(),
  labelCode: Yup.string()
    .default(null)
    .nullable(),
  labelNaam: Yup.string(),
  soortProduct: Yup.string(),
  product: productSchema,
  verzekeringnemers: verzekeringNemersSchema,
  verzekerden: verzekerdenSchema,
  dekking: dekkingSchema,
  premieGegevens: premieGegevensSchema,
  verpanding: verpandingSchema,
  informatieVoorVerzendingAanvraag: informatieVoorVerzendingAanvraagSchema.nullable().default(null),
  fiscalegegevens: fiscalegegevensSchema,
  kapitaalopbouw: kapitaalopbouwSchema
});

export const productenSchema = Yup.object({
  producten: Yup.array(productDetailSchema).default([]),
  aanvrager1: klantnaamSchema.nullable(),
  aanvrager2: klantnaamSchema.nullable(),
  kenmerken: Yup.mixed<OverzichtKenmerken>(),
  meerdereAanvragersCheck: Yup.boolean(),
  situatieVoorstel: Yup.boolean()
});

export const fiscaleRegelingSchema = Yup.object({
  productId: Yup.string()
    .nullable()
    .default(null),
  polisnummer: Yup.string()
    .nullable()
    .default(null),
  originelePolisnummer: Yup.string()
    .nullable()
    .default(null),
  kapitaalopbouw: Yup.mixed<KapitaalopbouwOptions>()
    .nullable()
    .default(KapitaalopbouwOptions.Box3),
  fiscaleVoortzetting: Yup.mixed<FiscaleVoortzettingOptions | string>()
    .nullable()
    .default(FiscaleVoortzettingOptions.Geen),
  externeMaatschappijCode: Yup.string().nullable(),
  externeMaatschappijOmschrijving: Yup.string().nullable(),
  lijfrenteclausuleOrigineel: Yup.boolean().nullable(),
  fiscaleTypering: Yup.mixed<FiscaleTyperingOptions>().nullable(),
  garantieverzekering: Yup.boolean().nullable(),
  fiscaalRegime: Yup.mixed<FiscaalRegimeOptions>().nullable(),
  oorspronkelijkeIngangsdatum: nullableLocalDateSchema.test(
    "required-check",
    getProductenOverzichtTextResources("RequiredOorspronkelijkeIngangsdatum"),
    function(this: Yup.TestContext): ValidationError | boolean {
      if (this.parent.fiscaleVoortzetting === FiscaleVoortzettingOptions.Geen) {
        return true;
      }
      return hasValue(this.parent.oorspronkelijkeIngangsdatum);
    }
  ),
  ingangsdatumBox1: nullableLocalDateSchema.test(
    "required-check",
    getProductenOverzichtTextResources("RequiredingangsdatumBox1"),
    function(): ValidationError | boolean {
      if (this.parent.fiscaleVoortzetting === FiscaleVoortzettingOptions.Geen) {
        return true;
      }
      return hasValue(this.parent.ingangsdatumBox1);
    }
  ),
  einddatum: nullableLocalDateSchema,
  doelkapitaalBedrag: Yup.number().nullable(),
  hoogstePremieOoitBedrag: Yup.number().nullable(),
  laagstePremieooitBedrag: Yup.number()
    .nullable()
    .test("max-check", "", function(): true | ValidationError {
      const valid =
        this.parent.hoogstePremieOoitBedrag >= this.parent.laagstePremieooitBedrag ||
        !hasValue(this.parent.hoogstePremieOoitBedrag) ||
        !hasValue(this.parent.laagstePremieooitBedrag);
      if (valid) {
        return true;
      } else {
        const message = getProductenOverzichtTextResources("LaagsteJaarpremieIsHoog");
        return this.createError({
          message
        });
      }
    }),
  huidigeJaarPremieBedrag: Yup.number()
    .nullable()
    .test("max-check", "", function(): true | ValidationError {
      const valid =
        this.parent.hoogstePremieOoitBedrag >= this.parent.huidigeJaarPremieBedrag ||
        !hasValue(this.parent.hoogstePremieOoitBedrag) ||
        !hasValue(this.parent.huidigeJaarPremieBedrag);
      if (valid) {
        return true;
      } else {
        const message = getProductenOverzichtTextResources("HuidigeJaarpremieIsHoog");
        return this.createError({
          message
        });
      }
    }),
  premieLopendJaarBedrag: Yup.number()
    .nullable()
    .test("max-check", "", function(): true | ValidationError {
      const valid =
        this.parent.hoogstePremieOoitBedrag >= this.parent.premieLopendJaarBedrag ||
        !hasValue(this.parent.hoogstePremieOoitBedrag) ||
        !hasValue(this.parent.premieLopendJaarBedrag);
      /* istanbul ignore else */
      if (valid) {
        return true;
      } else {
        const message = getProductenOverzichtTextResources("LopendeJaarpremieIsHoog");
        return this.createError({
          message
        });
      }
    }),
  eerdereUitkeringenBedrag: Yup.number().nullable(),
  ingebrachteWaardeBedrag: Yup.number().nullable()
});

export type FiscaleRegelingType = Yup.InferType<typeof fiscaleRegelingSchema>;
