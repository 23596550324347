/* istanbul ignore file */
/* eslint-disable */
import React from "react";
import { Table } from "react-bootstrap";
import { LabeledRadioInput } from "adviesbox-shared";
import "./scenario-table.scss";
import classNames from "classnames";
import {
  NbiResult,
  InkomensanalyseResultaat,
  Inkomensanalyse,
  ToelichtingRegel,
  ToelichtingOptions
} from "../../../.generated/rapportage/rapportagetypes";
import { TableNettoBesteedbaarInkomen } from "./nbi";
import { bedragFormatNull, monthDivision, getConclusieArcering, getMaandlastKeuzeTekst } from "./infra/scenario-utils";
import { Formik } from "formik";
import { Direction } from "../../../shared/types";
import { ScenarioBaseProps } from "./scenario-drawer";
import { ControleMaandlastOptions } from "../../../.generated/forms/formstypes";
import { CardLoadingSpinner } from "./card-loading-spinner";
import { getNaam } from "../../../shared/utils/helpers";
import { excludedFootnotes, CitationProvider, CitationRef, CitationNote } from "./citation-notes";

export type InkomensanalyseProps = {
  nbiWeergeven: boolean;
  titel: string;
  conclusie: string;
  nbi: NbiResult[];
  inkomensanalyseResultaten: InkomensanalyseResultaat[];
  motiveringInkomensanalyse: Inkomensanalyse | null;
  totaalHuidigBrutoJaarinkomenBedrag: number | null;
  inkomensData?: {
    naamPersoon: string;
    eersteUitkering: number | null;
    tweedeUitkering: number | null;
    restinkomen: number | null;
    aantalmaandenWwUitkering: number | null;
    totaalEerste: number | null;
    totaalTweede: number | null;
    totaalNa: number | null;
    inkomensverliesEerste: number | null;
    inkomensverliesTweede: number | null;
    inkomensverliesDerde: number | null;
    totaalInkomen: number | null;
    totaalInkomenTweede: number | null;
    totaalInkomenDerde: number | null;
    woonlastenEerstePeriode: number | null;
    woonlastenTweedePeriode: number | null;
    woonlastenDerdePeriode: number | null;
    inkomenPartnerEerstePeriode: number | null;
    inkomenPartnerTweedePeriode: number | null;
    inkomenPartnerDerdePeriode: number | null;
  };
  inkomensDataPartner?: {
    persoon: string;
    eersteUitkering: number | null;
    tweedeUitkering: number | null;
    restinkomen: number | null;
    aantalmaandenWwUitkering: number | null;
    totaalEerste: number | null;
    totaalTweede: number | null;
    totaalNa: number | null;
    tekortEerste: number | null;
    tekortTweede: number | null;
    tekortNa: number | null;
    totaalInkomen: number | null;
    totaalInkomenTweede: number | null;
    totaalInkomenDerde: number | null;
  };
  jaarlijkseAnalyse?: {
    gemiddeldeInkomen: number | null;
    gewenstInkomen: number | null;
    gewenstInkomenPercentage: number | null;
    maandelijksTekort: number | null;
  };
  financieleGegevens?: {
    toegestaneLast: number | null;
    werkelijkeBrutoLast: number | null;
    tekortWerkelijkeBrutolast: number | null;
    maximaleHypotheek: number | null;
    hypotheekSaldo: number | null;
    overschrijding: number | null;
    benodigdInkomenHypotheek: number | null;
    tekortOpInkomen: number | null;
  };
  voetnoten: ToelichtingRegel[];
  benodigdKapitaal: number | null;
  benodigdKapitaalOmschrijving: string;
};

export const InkomensanalyseTabelWerkloosheid: React.FC<InkomensanalyseProps & ScenarioBaseProps> = ({
  titel,
  conclusie,
  inkomensData,
  inkomensDataPartner,
  jaarlijkseAnalyse,
  financieleGegevens,
  voetnoten,
  nbi,
  benodigdKapitaal,
  benodigdKapitaalOmschrijving,
  nbiWeergeven,
  onKlantIdChange,
  selectedKlantId,
  weergevenOptions,
  benodigdKapitaalWeergeven,
  controleWerkelijkeLastenWeergeven,
  brutoInkomensWensenWeergeven,
  brutoInkomensWensPercentage,
  controleMaandlast,
  controleMaandlastKeuze,
  maximaleHypotheekWeergeven,
  loading,
  inkomensanalyseResultaten,
  totaalHuidigBrutoJaarinkomenBedrag,
  motiveringInkomensanalyse
}) => {
  const columnStyleLabel = {
    width: "40%"
  };
  const columnStyle = {
    width: "20%",
    textAlign: "right" as const
  };

  const aanvrager =
    motiveringInkomensanalyse?.aanvrager1?.klantId === selectedKlantId
      ? motiveringInkomensanalyse.aanvrager1
      : motiveringInkomensanalyse?.aanvrager2;
  const partner =
    motiveringInkomensanalyse?.aanvrager1?.klantId === selectedKlantId
      ? motiveringInkomensanalyse.aanvrager2
      : motiveringInkomensanalyse?.aanvrager1;

  return (
    <CitationProvider voetnoten={voetnoten} excludedFootnotes={excludedFootnotes}>
      <div className="container-fluid mt-2 px-2">
        <h2 className="mb-3">{titel}</h2>
        {loading && <CardLoadingSpinner />}
        {weergevenOptions.length > 0 && (
          <Formik initialValues={{ scenarioKlantId: selectedKlantId }} onSubmit={() => { }}>
            {({ setFieldValue }) => (
              <div className={"col-md-6 pb-3"}>
                <LabeledRadioInput
                  name={"scenarioKlantId"}
                  caption={"Weergeven scenario van"}
                  options={weergevenOptions}
                  onChange={event => {
                    onKlantIdChange(event.target.value);
                    setFieldValue("scenarioKlantId", event.target.value);
                  }}
                  layout={Direction.Horizontal}
                />
              </div>
            )}
          </Formik>
        )}

        <Table responsive striped bordered hover className="scenario__table mb-4">
          <colgroup>
            <col style={columnStyleLabel} />
            <col style={columnStyle} />
            <col style={columnStyle} />
            <col style={columnStyle} />
          </colgroup>
          <thead>
            <tr>
              <th colSpan={4}>Uw inkomen bij werkloosheid van {inkomensData?.naamPersoon}</th>
            </tr>
            <tr>
              <th></th>
              <th>1ste 2 maanden</th>
              <th>Na 2 t/m {inkomensData?.aantalmaandenWwUitkering} maanden</th>
              <th>Na WW uitkering</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Restinkomen {getNaam(aanvrager ?? null, "Aanvrager 1")}</td>
              <td>{bedragFormatNull(inkomensData?.restinkomen, monthDivision)}</td>
              <td>{bedragFormatNull(inkomensData?.restinkomen, monthDivision)}</td>
              <td>{bedragFormatNull(inkomensData?.restinkomen, monthDivision)}</td>
            </tr>
            <tr>
              <td>WW-Uitkering {getNaam(aanvrager ?? null, "Aanvrager 1")}</td>
              <td>{bedragFormatNull(inkomensData?.eersteUitkering, monthDivision)}</td>
              <td>{bedragFormatNull(inkomensData?.tweedeUitkering, monthDivision)}</td>
              <td>-</td>
            </tr>
            <tr>
              <td>Woonlastenverzekering <CitationRef type={ToelichtingOptions.WoonlastenVerzekering} /><CitationRef type={ToelichtingOptions.Brutering} /></td>
              <td>{bedragFormatNull(inkomensData?.woonlastenEerstePeriode, monthDivision)}</td>
              <td>{bedragFormatNull(inkomensData?.woonlastenTweedePeriode, monthDivision)}</td>
              <td>{bedragFormatNull(inkomensData?.woonlastenDerdePeriode, monthDivision)}</td>
            </tr>
            {motiveringInkomensanalyse?.aanvrager2 && (
              <tr>
                <td>Inkomen {getNaam(partner ?? null, "Aanvrager 2")}</td>
                <td>{bedragFormatNull(inkomensData?.inkomenPartnerEerstePeriode || 0, monthDivision)}</td>
                <td>{bedragFormatNull(inkomensData?.inkomenPartnerTweedePeriode || 0, monthDivision)}</td>
                <td>{bedragFormatNull(inkomensData?.inkomenPartnerDerdePeriode || 0, monthDivision)}</td>
              </tr>
            )}
            <tr className="font-weight-bold">
              <td>Inkomen totaal</td>
              <td>{bedragFormatNull(inkomensanalyseResultaten?.[0]?.totaalBrutoInkomenBedrag, monthDivision)}</td>
              <td>{bedragFormatNull(inkomensanalyseResultaten?.[1]?.totaalBrutoInkomenBedrag, monthDivision)}</td>
              <td>{bedragFormatNull(inkomensanalyseResultaten?.[2]?.totaalBrutoInkomenBedrag, monthDivision)}</td>
            </tr>
            <tr className="table-warning">
              <td>Inkomensverlies</td>
              <td>{bedragFormatNull(Math.abs(inkomensData?.inkomensverliesEerste || 0))}</td>
              <td>{bedragFormatNull(Math.abs(inkomensData?.inkomensverliesTweede || 0))}</td>
              <td>{bedragFormatNull(Math.abs(inkomensData?.inkomensverliesDerde || 0))}</td>
            </tr>
          </tbody>
        </Table>

        <Table responsive striped bordered hover className={"scenario__table mb-4"}>
          <colgroup>
            <col style={columnStyleLabel} />
            <col style={columnStyle} />
            <col style={columnStyle} />
            <col style={columnStyle} />
          </colgroup>
          <thead>
            <tr>
              <th colSpan={4}>
                <i>Wat gebeurt er het eerste volledige kalenderjaar bij werkloosheid?</i>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Uw gemiddelde inkomen</td>
              <td></td>
              <td>{bedragFormatNull(jaarlijkseAnalyse?.gemiddeldeInkomen, monthDivision)}</td>
              <td></td>
            </tr>
            {brutoInkomensWensenWeergeven && (
              <>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Gewenst inkomen van minimaal {brutoInkomensWensPercentage}% van huidig bruto inkomen</td>
                  <td></td>
                  <td>
                    {bedragFormatNull(
                      (totaalHuidigBrutoJaarinkomenBedrag || 0) * ((brutoInkomensWensPercentage || 0) / 100),
                      monthDivision
                    )}
                  </td>
                  <td></td>
                </tr>
                <tr className={"table-danger font-weight-bold"}>
                  <td>Maandelijks tekort aan gewenste inkomsten</td>
                  <td></td>
                  <td>{bedragFormatNull(Math.abs(jaarlijkseAnalyse?.maandelijksTekort || 0))}</td>
                  <td></td>
                </tr>
              </>
            )}
          </tbody>
        </Table>

        {controleWerkelijkeLastenWeergeven && (
          <Table responsive striped bordered hover className={"scenario__table mb-4"}>
            <colgroup>
              <col style={columnStyleLabel} />
              <col style={columnStyle} />
              <col style={columnStyle} />
              <col style={columnStyle} />
            </colgroup>
            <thead>
              <tr>
                <th colSpan={4}>Financiële gegevens</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Toegestane last o.b.v. inkomen**</td>
                <td></td>
                <td>{bedragFormatNull(inkomensanalyseResultaten?.[0].toegestaneLastBedrag)}</td>
                <td></td>
              </tr>
              <tr>
                <td>Werkelijke bruto last van hypotheek en verpande producten</td>
                <td></td>
                <td>{bedragFormatNull(inkomensanalyseResultaten?.[0].werkelijkeLastBedrag)}</td>
                <td></td>
              </tr>
              <tr className={"table-danger font-weight-bold"}>
                <td>Tekort o.b.v. werkelijke bruto last</td>
                <td></td>
                <td>{bedragFormatNull(inkomensanalyseResultaten?.[0].tekortToegestaneLastBedrag)}</td>
                <td></td>
              </tr>
            </tbody>
          </Table>
        )}

        {controleMaandlast && controleMaandlastKeuze === ControleMaandlastOptions.BenodigdInkomen && (
          <Table striped bordered hover responsive className="scenario__table  mb-4">
            <colgroup>
              <col style={columnStyleLabel} />
              <col style={columnStyle} />
              <col style={columnStyle} />
              <col style={columnStyle} />
            </colgroup>
            <tbody>
              <tr>
                <td>Minimaal benodigd inkomen voor uw hypotheek</td>
                <td></td>
                <td>{bedragFormatNull(inkomensanalyseResultaten?.[0].benodigdInkomenBedrag || 0, monthDivision)}</td>
                <td></td>{" "}
              </tr>
              <tr className={"table-danger font-weight-bold"}>
                <td>Maandelijks tekort aan inkomsten voor uw hypotheek</td>
                <td></td>
                <td>{bedragFormatNull(inkomensanalyseResultaten?.[0].tekortBenodigInkomenBedrag || 0, monthDivision)}</td>
                <td></td>
              </tr>
              <tr className={"table-danger font-weight-bold"}>
                <td>Netto maandelijks tekort voor uw hypotheek <CitationRef type={ToelichtingOptions.BrutoNettoTekort} /></td>
                <td></td>
                <td>{bedragFormatNull(inkomensanalyseResultaten?.[0].tekortNettoInkomenBedrag || 0, monthDivision)}</td>
                <td></td>
              </tr>
            </tbody>
          </Table>
        )}

        {maximaleHypotheekWeergeven && (
          <Table responsive striped bordered hover className={"scenario__table mb-4"}>
            <colgroup>
              <col style={columnStyleLabel} />
              <col style={columnStyle} />
              <col style={columnStyle} />
              <col style={columnStyle} />
            </colgroup>
            <tbody>
              <tr>
                <td>Maximale hypotheek**</td>
                <td></td>
                <td>{bedragFormatNull(inkomensanalyseResultaten?.[0].maximaleHypotheekBedrag)}</td>
                <td></td>
              </tr>
              <tr>
                <td>Hypotheeksaldo</td>
                <td></td>
                <td>{bedragFormatNull(inkomensanalyseResultaten?.[0].hypotheeksaldoBedrag)}</td>
                <td></td>
              </tr>
              <tr className={"table-danger font-weight-bold"}>
                <td>Overschrijding van uw maximale hypotheek</td>
                <td></td>
                <td>
                  {bedragFormatNull(
                    Math.abs(
                      Math.min(
                        (inkomensanalyseResultaten?.[0].maximaleHypotheekBedrag || 0) -
                        (inkomensanalyseResultaten?.[0].hypotheeksaldoBedrag || 0),
                        0
                      )
                    )
                  )}
                </td>
                <td></td>
              </tr>
            </tbody>
          </Table>
        )}

        {controleMaandlast && (
          <Table striped bordered hover responsive className={"scenario__table mb-4"}>
            <colgroup>
              <col style={columnStyleLabel} />
              <col style={columnStyle} />
              <col style={columnStyle} />
              <col style={columnStyle} />
            </colgroup>
            <tbody>
              <tr>
                <td>
                  De maandlast die overblijft voor deze situatie t.o.v. de{" "}
                  {getMaandlastKeuzeTekst(controleMaandlastKeuze)} ?
              </td>
                <td>&nbsp;</td>
                <td className={classNames("text-right", getConclusieArcering(conclusie))}>{conclusie}</td>
                <td>&nbsp;</td>
              </tr>
            </tbody>
          </Table>
        )}

        <p className="mt-3 px-2">
          {voetnoten?.map(footnote => (
            <CitationNote key={footnote.type} type={footnote.type} text={footnote.toelichting || ""} />
          ))}
        </p>

        <br />
        <br />

        {/* nbi */}
        {nbiWeergeven && <TableNettoBesteedbaarInkomen nbi={nbi} />}
        {benodigdKapitaalWeergeven && (
          <Table striped bordered hover responsive className={"scenario__table mb-4"}>
            <colgroup>
              <col style={{ width: "40%" }} />
              <col style={{ width: "20%" }} />
              <col style={{ width: "20%" }} />
              <col style={{ width: "20%" }} />
            </colgroup>
            <thead>
              <tr>
                <th>Uw risico o.b.v. uw gewenste inkomen</th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{benodigdKapitaalOmschrijving}</td>
                <td>{bedragFormatNull(benodigdKapitaal)}</td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </Table>
        )}
      </div>
    </CitationProvider>
  );
};
