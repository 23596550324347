import { VergoedingenOverzichtState, HuidigProductType } from "./vergoedingen-overzicht-schema";
import { initISWSideEffect, createISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";
import { FinancieringOptions } from "../../.generated/forms/formstypes";

const vergoedingenSideEffects = createISWSideEffect<VergoedingenOverzichtState>((bag): void => {
  const { draft, has } = bag;

  if (has.vergoedingenOverzicht.advieskostenBedrag.changed && draft.vergoedingenOverzicht.advieskostenBedrag == null) {
    draft.vergoedingenOverzicht.advieskostenBedrag = 0;
  }
  if (
    has.vergoedingenOverzicht.bemiddelingsvergoedingGeldleningBedrag.changed &&
    draft.vergoedingenOverzicht.bemiddelingsvergoedingGeldleningBedrag == null
  ) {
    draft.vergoedingenOverzicht.bemiddelingsvergoedingGeldleningBedrag = 0;
  }
  if (
    has.vergoedingenOverzicht.overigeBemiddelingsvergoedingBedrag.changed &&
    draft.vergoedingenOverzicht.overigeBemiddelingsvergoedingBedrag == null
  ) {
    draft.vergoedingenOverzicht.overigeBemiddelingsvergoedingBedrag = 0;
  }
  if (
    has.vergoedingenOverzicht.dossiervergoedingBedrag.changed &&
    draft.vergoedingenOverzicht.dossiervergoedingBedrag == null
  ) {
    draft.vergoedingenOverzicht.dossiervergoedingBedrag = 0;
  }

  // Controleert of bedragvelden berekend worden en past het bedragveld en totaalbedrag aan.
  if (
    has.vergoedingenOverzicht.advieskostenOvernemen.changed ||
    (draft.vergoedingenOverzicht.advieskostenOvernemen && has.vergoedingenOverzicht.advieskostenAantalUur.changed)
  ) {
    if (typeof draft.vergoedingenOverzicht.advieskostenAantalUur === "number") {
      const metFinanciering = draft.financiering !== FinancieringOptions.GeenFinanciering;
      const bedragPerUur = metFinanciering
        ? draft.vergoedingenOverzicht.vergoedingenOverzichtInstellingenTarieven.hypotheekAdvieskostenUurTarief
        : draft.vergoedingenOverzicht.vergoedingenOverzichtInstellingenTarieven.advieskostenUurTarief;
      draft.vergoedingenOverzicht.advieskostenBedrag =
        draft.vergoedingenOverzicht.advieskostenAantalUur * (bedragPerUur || 0);
    }
  }

  if (
    has.vergoedingenOverzicht.bemiddelingsvergoedingGeldleningOvernemen.changed ||
    (draft.vergoedingenOverzicht.bemiddelingsvergoedingGeldleningOvernemen &&
      has.vergoedingenOverzicht.bemiddelingsvergoedingGeldleningAantalUur.changed)
  ) {
    draft.vergoedingenOverzicht.bemiddelingsvergoedingGeldleningBedrag =
      typeof draft.vergoedingenOverzicht.bemiddelingsvergoedingGeldleningAantalUur === "number"
        ? draft.vergoedingenOverzicht.bemiddelingsvergoedingGeldleningAantalUur *
          (draft.vergoedingenOverzicht.vergoedingenOverzichtInstellingenTarieven
            .bemiddelingsvergoedingGeldleningUurTarief || 0)
        : null;
  }

  if (
    has.vergoedingenOverzicht.overigeBemiddelingsvergoedingOvernemen.changed ||
    (draft.vergoedingenOverzicht.overigeBemiddelingsvergoedingOvernemen &&
      has.vergoedingenOverzicht.overigeBemiddelingsvergoedingAantalUur.changed)
  ) {
    draft.vergoedingenOverzicht.overigeBemiddelingsvergoedingBedrag =
      typeof draft.vergoedingenOverzicht.overigeBemiddelingsvergoedingAantalUur === "number"
        ? draft.vergoedingenOverzicht.overigeBemiddelingsvergoedingAantalUur *
          (draft.vergoedingenOverzicht.vergoedingenOverzichtInstellingenTarieven
            .overigeBemiddelingsvergoedingUurTarief || 0)
        : null;
  }

  if (has.vergoedingenOverzicht.hypotheekNormJaarMaand.changed) {
    const jaarEnMaandArray = draft.vergoedingenOverzicht.hypotheekNormJaarMaand.split(",");

    draft.vergoedingenOverzicht.hypotheekNorm = {
      jaar: parseInt(jaarEnMaandArray[0]),
      maand: parseInt(jaarEnMaandArray[1])
    };
  }
});

const huidigProductSideEffects = createISWSideEffect<HuidigProductType>((bag): void => {
  const { draft } = bag;
  // Als 'meenemen' wijzigt mogen de in/uit-financiering checkboxes niet meer aan staan.
  if (draft.meenemen) {
    draft.inFinanciering = null;
    draft.uitFinanciering = null;
  }
});

// Dit zorgt dat bij het wijzigen de checkboxes voor producten, eventuele andere opties disabled worden gemaakt:
const huidigeProductenSideEffects = createISWSideEffect<VergoedingenOverzichtState>((bag): void => {
  const { subset, draft } = bag;

  draft.huidigProducten.forEach((_, index: number) => {
    huidigProductSideEffects(subset.huidigProducten[index].create());
  });
});

const vergoedingenOverzichtDraftSideEffects = createISWSideEffect<VergoedingenOverzichtState>((bag): void => {
  vergoedingenSideEffects(bag);
  huidigeProductenSideEffects(bag);
});

export const determineVergoedingenOverzichtSideEffects = initISWSideEffect<VergoedingenOverzichtState>(
  vergoedingenOverzichtDraftSideEffects
);
