import { FormikContextType, connect } from "formik";

import React, { ReactElement } from "react";
import EigenwoningschuldModal from "../eigenwoningschuld-modal/eigenwoningschuld-modal";
import { LabeledCurrencyInput, Icon } from "adviesbox-shared";
import { ModalButton } from "adviesbox-shared";
import { FinancieringsbehoefteState } from "../infra/financieringsbehoefte-types";
import { mapToEigenwoninschuldModal } from "../infra/map-financieringsbehoefte-ui-to-dl";

const Eigenwoningschuld = ({
  formik: { values, setFieldValue }
}: {
  formik: FormikContextType<FinancieringsbehoefteState>;
}): ReactElement => {
  return (
    <LabeledCurrencyInput
      caption="Eigenwoningschuld"
      name="financieringsopzet.eigenwoningschuldModal.eigenwoningschuld.bedrag"
      readonly={true}
      appendChildren={
        <ModalButton
          parent="financieringsopzet.eigenwoningschuldModal"
          aria-label="Eigenwoningschuld button"
          content={<Icon name="specificatie" alt="Eigenwoningschuld" />}
          size="lg"
        >
          <EigenwoningschuldModal
            data={mapToEigenwoninschuldModal(values)}
            formValues={values}
            onSave={(result): void => {
              setFieldValue("financieringsopzet.eigenwoningschuldModal", result);
            }}
          />
        </ModalButton>
      }
    />
  );
};

export default connect<{}, FinancieringsbehoefteState>(Eigenwoningschuld);
