import { ChronoUnit, LocalDate } from "@js-joda/core";
import * as Yup from "yup";
import { InferType } from "yup";
import { InkomenLoondienstInput } from "../../.generated/forms/formstypes";
import { adresSchema } from "../../shared/generic-parts/adres/schema";
import { berekenDateSchema, BerekenDateType } from "../../shared/generic-parts/bereken-date/schema";
import { scenarioCardInputSchema, scenarioCardSchema } from "../../shared/types";
import { WithSaveData } from "../../shared/utils/save-data";
import { yupEnum, yupNullableEnum } from "../../shared/utils/yup-enum";
import { getInkomenEnFiscusTextResources } from "./inkomen-en-fiscus-resources";
import { nullableLocalDateSchema, localDateSchema } from "adviesbox-shared";

export enum SoortInkomenverklaring {
  Geen = "Geen",
  IbStukken = "IbStukken",
  Accountantsverklaring = "Accountantsverklaring"
}

export enum BrutoSalarisPeriode {
  Geen = "",
  PerWeek = "Week",
  Per4Weken = "4 Weken",
  PerMaand = "Maand",
  PerJaar = "Jaar"
}

export enum SoortDienstverband {
  Geen = "",
  FlexibeleArbeidsrelatieMetPerspectiefverklaring = "Flexibele arbeidsrelatie met perspectiefverklaring",
  FlexibeleArbeidsrelatieZonderPerspectiefverklaring = "Flexibele arbeidsrelatie zonder perspectiefverklaring",
  LoondienstVast = "Loondienst vast",
  LoondienstTijdelijkMetIntentie = "Loondienst tijdelijk met intentie",
  LoondienstTijdelijkZonderIntentie = "Loondienst tijdelijk zonder intentie"
}

export enum InkomenVerklaring {
  Werkgeversverklaring = "Werkgeversverklaring",
  UWVdocument = "UWV document",
  ArbeidScan = "Arbeidsmarktscan"
}

export enum InkomenUitArbeidApiControllerAction {
  JaarNaarPeriode = "JaarNaarPeriode",
  PeriodeNaarJaar = "PeriodeNaarJaar"
}

export enum AOWSyncHeightIndex {
  All = 0,
  Korting,
  Ingangsdatum
}

export enum SociaalSyncHeightIndex {
  All = 0,
  Arbeidsverleden
}

export enum InkomenSyncHeightIndex {
  All = 0,
  BrutoSalarisUitDienstverband = 1,
  InkomstenBox3 = 2
}

export enum RechtsVorm {
  Geen = "",
  Eenmanszaak = "Eenmanszaak",
  Maatschap = "Maatschap",
  VennootschapOnderFirma = "Vennootschap onder firma",
  CommanditaireVennootschap = "Commanditaire vennootschap",
  BeslotenVennootschap = "Besloten vennootschap",
  NaamlozeVennootschap = "Naamloze vennootschap",
  Stichting = "Stichting"
}

export enum SoortOnderneming {
  ZelfstandigeZonderPersoneel = "Zelfstandige zonder personeel",
  ZelfstandigeMetPersoneel = "Zelfstandige met personeel"
}

export const inkomensverledenSchema = Yup.object({
  jaar: Yup.number()
    .nullable()
    .default(LocalDate.now().year() - 1)
    .test({
      message: getInkomenEnFiscusTextResources("ErrorInkomensverledenJaar"),
      test: function(value: number): boolean {
        const jaar = LocalDate.now().year();
        return !!value ? value > jaar - 6 && value <= jaar : true;
      }
    }),
  resultaat1: Yup.number()
    .nullable()
    .default(null),
  resultaat2: Yup.number()
    .nullable()
    .default(null),
  resultaat3: Yup.number()
    .nullable()
    .default(null),
  gemiddeld: Yup.number()
    .nullable()
    .default(null)
});

export type InkomensverledenType = InferType<typeof inkomensverledenSchema>;

export const inkomenUitArbeidModalSchema = Yup.object({
  periodeBedragLastChanged: Yup.boolean().default(false),
  loondienstId: Yup.string()
    .nullable()
    .default(null),
  soortDienstverband: yupEnum(SoortDienstverband).default(SoortDienstverband.LoondienstVast),
  faseUitzendcontract: Yup.string()
    .nullable()
    .default(null),
  aantalUrenPerWeek: Yup.number()
    .nullable()
    .default(null),
  datumInDienst: nullableLocalDateSchema.test({
    message: getInkomenEnFiscusTextResources("ErrorDatumInDienst"),
    test: function(value: LocalDate): boolean {
      return this.parent.geboortedatum && value ? value > this.parent.geboortedatum : true;
    }
  }),
  einddatumContract: nullableLocalDateSchema.test({
    message: getInkomenEnFiscusTextResources("ErrorEinddatumContract"),
    test: function(value: LocalDate): boolean {
      return this.parent.datumInDienst && value ? value > this.parent.datumInDienst : true;
    }
  }),
  inkomenMeetellenTot: berekenDateSchema.test({
    message: getInkomenEnFiscusTextResources("ErrorDateLeeg"),
    test: function(value: BerekenDateType): boolean {
      if (value?.berekenen)
        return (
          (this.parent.soortDienstverband !== SoortDienstverband.LoondienstTijdelijkZonderIntentie &&
            this.parent.soortDienstverband !== SoortDienstverband.LoondienstTijdelijkMetIntentie) ||
          (!!value.berekendeDatum &&
            (this.parent.soortDienstverband === SoortDienstverband.LoondienstTijdelijkZonderIntentie ||
              this.parent.soortDienstverband === SoortDienstverband.LoondienstTijdelijkMetIntentie))
        );
      if (!value?.berekenen)
        return (
          (this.parent.soortDienstverband !== SoortDienstverband.LoondienstTijdelijkZonderIntentie &&
            this.parent.soortDienstverband !== SoortDienstverband.LoondienstTijdelijkMetIntentie) ||
          (!!value.datum &&
            (this.parent.soortDienstverband === SoortDienstverband.LoondienstTijdelijkZonderIntentie ||
              this.parent.soortDienstverband === SoortDienstverband.LoondienstTijdelijkMetIntentie))
        );
      return false;
    }
  }),
  beroep: Yup.string().default("00"),
  beroepsfunctie: Yup.string().default(""),
  naam: Yup.string().default(""),
  adres: adresSchema,
  werkgeverIsFamilie: Yup.boolean().default(false),
  buitenlandsInkomen: Yup.boolean().default(false),
  brutoSalaris: Yup.number()
    .nullable()
    .default(null),
  brutoSalarisPer: yupEnum(BrutoSalarisPeriode).default(BrutoSalarisPeriode.PerMaand),
  brutoSalarisPerJaar: Yup.number()
    .nullable()
    .default(null),
  vakantieToeslagBedrag: Yup.number()
    .nullable()
    .default(null),
  vakantieToeslagPercentage: Yup.number()
    .nullable()
    .default(8),
  dertiendeMaandToggle: Yup.boolean().default(false),
  dertiendeMaand: Yup.number()
    .nullable()
    .default(null),
  vasteEindejaarsuitkering: Yup.number()
    .nullable()
    .default(null),
  onregelmatigheidstoeslag: Yup.number()
    .nullable()
    .default(null),
  inkomstentoeslag: Yup.number()
    .nullable()
    .default(null),
  vergoedingBeslaglegging: Yup.number()
    .nullable()
    .default(null),
  structureelOverwerk: Yup.number()
    .nullable()
    .default(null),
  ingangsdatumStructureelOverwerk: nullableLocalDateSchema,
  einddatumStructureelOverwerk: nullableLocalDateSchema.test({
    message: getInkomenEnFiscusTextResources("ErrorEinddatumVoorBegindatumOverwerk"),
    test: function(val: LocalDate): boolean {
      return val && this.parent.ingangsdatumStructureelOverwerk
        ? val > this.parent.ingangsdatumStructureelOverwerk
        : true;
    }
  }),
  provisie: Yup.number()
    .nullable()
    .default(null),
  ingangsdatumProvisie: nullableLocalDateSchema,
  einddatumProvisie: nullableLocalDateSchema.test({
    message: getInkomenEnFiscusTextResources("ErrorEinddatumVoorBegindatumProvisie"),
    test: function(val: LocalDate): boolean {
      return val && this.parent.ingangsdatumProvisie ? val > this.parent.ingangsdatumProvisie : true;
    }
  }),
  vastFlexibelBudget: Yup.number()
    .nullable()
    .default(null),
  totaalBruto: Yup.number()
    .nullable()
    .default(null),
  inkomensverleden: inkomensverledenSchema,
  toetsinkomenPerspectiefVerklaring: Yup.number()
    .positive()
    .nullable()
    .default(null),
  jaar1: Yup.number()
    .nullable()
    .default(100),
  jaar2: Yup.number()
    .nullable()
    .default(70),
  scenario: scenarioCardSchema.default([]),
  geboortedatum: nullableLocalDateSchema
});
export type InkomenUitArbeidModalType = InferType<typeof inkomenUitArbeidModalSchema>;

export const inkomenUitArbeidModalValuesSchema = Yup.object({
  modal: Yup.array(inkomenUitArbeidModalSchema)
});
export type InkomenUitArbeidModalValuesType = Yup.InferType<typeof inkomenUitArbeidModalValuesSchema>;

export const scenarioInstellingenSchema = Yup.object({
  geboortedatum: nullableLocalDateSchema,
  eindeBijArbeidsongeschiktheid: Yup.boolean().default(true),
  regelingArbeidsongeschiktheid: Yup.boolean().default(true),
  eindeWerkloosheid: Yup.boolean().default(true),
  overgangBijOverlijden: Yup.boolean().default(false),
  percentage: Yup.number()
    .nullable()
    .default(null)
    .when("overgangBijOverlijden", {
      is: (val: boolean): boolean => val,
      then: Yup.number()
        .nullable()
        .required(getInkomenEnFiscusTextResources("ErrorOvergangBijOverlijdenPercentage")),
      otherwise: Yup.number().nullable()
    }),
  einddatum: nullableLocalDateSchema.when("overgangBijOverlijden", {
    is: (val: boolean): boolean => val,
    then: nullableLocalDateSchema.required(getInkomenEnFiscusTextResources("ErrorEinddatumRequired")).test({
      message: getInkomenEnFiscusTextResources("ErrorDateCheckToekomstMin"),
      test: function(value: LocalDate): boolean {
        return !!value ? value > LocalDate.now() : true;
      }
    }),
    otherwise: nullableLocalDateSchema
  }),
  leeftijd: Yup.number()
    .nullable()
    .default(null),
  hasAanvrager2: Yup.boolean().default(false)
});
export type ScenarioInstellingenType = InferType<typeof scenarioInstellingenSchema>;

export const ondernemingModalSchema = Yup.object({
  volgnummer: Yup.number().default(1),
  soortOnderneming: yupEnum(SoortOnderneming)
    .default(SoortOnderneming.ZelfstandigeZonderPersoneel),
  rechtsvorm: yupEnum(RechtsVorm).default(RechtsVorm.Geen),
  ingangsdatumOnderneming: nullableLocalDateSchema.test({
    message: getInkomenEnFiscusTextResources("ErrorEinddatumOnderneming"),
    test: function(value: LocalDate): boolean {
      return !!value ? value <= LocalDate.now() : true;
    }
  }),
  inkomenMeetellenTot: berekenDateSchema,
  kvkNummer: Yup.string()
    .test('is-kvk-valid', getInkomenEnFiscusTextResources("ErrorKvkNummer"), (value) => {
      if (!value || value.trim() === '') return true; // Return valid if field is empty or null
      return /^[0-9]+$/.test(value) && value.length === 8; // Check if value contains only digits and has length 8
  }),
  sbiCode: Yup.string().default(""),
  rsinNummer: Yup.string().default(""),
  dga: Yup.boolean().default(false),
  aandelenPercentage: Yup.number()
    .nullable()
    .default(null),
  verklaringInkomen: yupEnum(SoortInkomenverklaring).default(SoortInkomenverklaring.IbStukken),
  toetsinkomen: Yup.number()
    .nullable()
    .default(null),
  aantalOndernemingen: Yup.number()
    .nullable()
    .default(null),
  boi: Yup.string().default(""),
  scenarioInstellingen: scenarioInstellingenSchema,
  inkomensverleden: inkomensverledenSchema,
  scenario: Yup.array(scenarioCardInputSchema).default([]),
  dossiernummer: Yup.string().default(""),
  uitgifteDatum: nullableLocalDateSchema,
  rekenexpert: Yup.string()
    .nullable()
    .default(null)
});

export type OndernemingModalType = InferType<typeof ondernemingModalSchema>;

export const overigeWerkzaamhedenModalSchema = Yup.object({
  inkomenMeetellenTot: berekenDateSchema,
  omschrijving: Yup.string(),
  scenarioInstellingen: scenarioInstellingenSchema,
  inkomensverleden: inkomensverledenSchema,
  scenario: scenarioCardSchema
});
export type OverigeWerkzaamhedenModalType = InferType<typeof overigeWerkzaamhedenModalSchema>;

export const overigeInkomstenModalSchema = Yup.object({
  totaal: Yup.number()
    .nullable()
    .default(null),
  calculatedDate: nullableLocalDateSchema,
  alimentatieExBedrag: Yup.number()
    .nullable()
    .default(null),
  alimentatieExTotDatum: berekenDateSchema,
  anwUitkeringBedrag: Yup.number()
    .nullable()
    .default(null),
  anwUitkeringTotDatum: berekenDateSchema,
  wgaUitkeringBedrag: Yup.number()
    .nullable()
    .default(null),
  wgaUitkeringTotDatum: berekenDateSchema,
  ivaUitkeringBedrag: Yup.number()
    .nullable()
    .default(null),
  ivaUitkeringTotDatum: berekenDateSchema,
  wazUitkeringBedrag: Yup.number()
    .nullable()
    .default(null),
  wazUitkeringTotDatum: berekenDateSchema,
  waoUitkeringTijdelijkBedrag: Yup.number()
    .nullable()
    .default(null),
  waoUitkeringTijdelijkTotDatum: berekenDateSchema,
  waoUitkeringBlijvendBedrag: Yup.number()
    .nullable()
    .default(null),
  waoUitkeringBlijvendTotDatum: berekenDateSchema,
  wajongUitkeringBedrag: Yup.number()
    .nullable()
    .default(null),
  wajongUitkeringTotDatum: berekenDateSchema,
  wwUitkeringBedrag: Yup.number()
    .nullable()
    .default(null),
  wwUitkeringTotDatum: berekenDateSchema,
  rwwUitkeringBedrag: Yup.number()
    .nullable()
    .default(null),
  rwwUitkeringTotDatum: berekenDateSchema,
  wachtgeldBedrag: Yup.number()
    .nullable()
    .default(null),
  wachtgeldTotDatum: berekenDateSchema,
  nabestaandenpensioenVoorAowBedrag: Yup.number()
    .nullable()
    .default(null),
  nabestaandenpensioenNaAowBedrag: Yup.number()
    .nullable()
    .default(null),
  overigeInkomsten1Omschrijving: Yup.string().default(""),
  overigeInkomsten1TotDatum: berekenDateSchema,
  overigeInkomsten1Bedrag: Yup.number()
    .nullable()
    .default(null),
  overigeInkomsten2Omschrijving: Yup.string().default(""),
  overigeInkomsten2TotDatum: berekenDateSchema,
  overigeInkomsten2Bedrag: Yup.number()
    .nullable()
    .default(null)
});
export type OverigeInkomstenModalType = InferType<typeof overigeInkomstenModalSchema>;

export const inkomstenAanmerkelijkBelangModalSchema = Yup.object({
  inkomenMeetellenTot: berekenDateSchema,
  scenarioInstellingen: scenarioInstellingenSchema,
  inkomensverleden: inkomensverledenSchema,
  scenario: scenarioCardSchema
});
export type InkomstenAanmerkelijkBelangModalType = InferType<typeof inkomstenAanmerkelijkBelangModalSchema>;

export const inkomstenVerhuurOnroerendGoedModalSchema = Yup.object({
  inkomstenVerhuurOnroerendGoed: Yup.number()
    .nullable()
    .default(null),
  eindDatum: nullableLocalDateSchema,
  scenarioInstellingen: scenarioInstellingenSchema
});
export type InkomstenVerhuurOnroerendGoedModalType = InferType<typeof inkomstenVerhuurOnroerendGoedModalSchema>;

export const inkomstenUitBeleggingenModalSchema = Yup.object({
  inkomstenUitBeleggingen: Yup.number()
    .nullable()
    .default(null),
  eindDatum: nullableLocalDateSchema,
  scenarioInstellingen: scenarioInstellingenSchema
});
export type InkomstenUitBeleggingenModalType = InferType<typeof inkomstenUitBeleggingenModalSchema>;

export const aowSchema = Yup.object({
  bedrag: Yup.number()
    .nullable()
    .default(null),
  originalBedrag: Yup.number()
    .nullable()
    .default(null),
  korting: Yup.number()
    .default(0)
    .nullable()
    .test({
      message: getInkomenEnFiscusTextResources("ErrorAowKortingsjaren"),
      test: function(value: number): boolean {
        return !!value ? value >= 0 && value <= 50 : true;
      }
    }),
  ingangsdatum: Yup.object({
    datum: localDateSchema,
    berekenen: Yup.boolean().default(false),
    berekendeDatum: localDateSchema
  }).test({
    message: getInkomenEnFiscusTextResources("ErrorAowIngangsdatum"),
    test: function(val: BerekenDateType): boolean {
      if (val.berekenen) {
        return true;
      }

      if (!this.parent.geboorteDatum) {
        return true;
      }

      const minDagen = 23741; // 65 jaar
      const maxDagen = 29220; // 80 jaar
      const dagen = this.parent.geboorteDatum.until(val.datum, ChronoUnit.DAYS);
      return dagen >= minDagen && dagen <= maxDagen;
    }
  }),

  geboorteDatum: nullableLocalDateSchema
});
export type AOWType = InferType<typeof aowSchema>;

export const fiscaleAftrekpostenModalSchema = Yup.object({
  teBetalenAlimentatie: Yup.number()
    .nullable()
    .default(0),
  eindDatum: nullableLocalDateSchema
});
export type FiscaleAftrekpostenModalType = InferType<typeof fiscaleAftrekpostenModalSchema>;

export const fiscaleBijtelpostenModalSchema = Yup.object({
  bijtellingBedraagt: Yup.number()
    .nullable()
    .default(null),
  eigenBijdrage: Yup.number()
    .nullable()
    .default(0),
  bijtellingOpInkomen: Yup.number()
    .nullable()
    .default(null)
});
export type FiscaleBijtelpostenModalType = InferType<typeof fiscaleBijtelpostenModalSchema>;

export const sociaalSchema = Yup.object({
  voldoetWekeneis: Yup.boolean().default(true),
  voldoetJareneis: Yup.boolean().default(true),
  feitelijkArbeidsverleden: Yup.number()
    .nullable()
    .default(null)
    .test({
      message: getInkomenEnFiscusTextResources("ErrorFeitelijkArbeidsverleden"),
      test: function(value: number): boolean {
        const currYear = LocalDate.now().year();
        return !!value ? value + 1998 <= currYear : true;
      }
    }),
  fictiefArbeidsverleden: Yup.number()
    .nullable()
    .default(null)
});
export type SociaalType = InferType<typeof sociaalSchema>;

export const fiscaleVerdelingSchema = Yup.object({
  optimaleVerdeling: Yup.boolean().default(true),
  aanvrager1: Yup.number()
    .nullable()
    .default(null),
  aanvrager2: Yup.number()
    .nullable()
    .default(null)
});
export type FiscaleVerdelingType = InferType<typeof fiscaleVerdelingSchema>;

export const inkomenSchema = Yup.object({
  inkomstenBox3IsEnabled: Yup.boolean().default(false),
  brutoSalarisUitDienstverbandIsEnabled: Yup.boolean().default(false),
  brutoSalarisUitDienstverband: Yup.number()
    .nullable()
    .default(null),
  inkomenUitArbeid: Yup.array(inkomenUitArbeidModalSchema)
    .min(1)
    .default([inkomenUitArbeidModalSchema.default()]),
  verklaringInkomen: yupNullableEnum(InkomenVerklaring).default(InkomenVerklaring.Werkgeversverklaring),
  toetsinkomenUwv: Yup.number()
    .nullable()
    .default(null),
  ondernemingIsEnabled: Yup.boolean().default(false),
  onderneming: ondernemingModalSchema,
  overigeWerkzaamhedenIsEnabled: Yup.boolean().default(false),
  overigeWerkzaamheden: overigeWerkzaamhedenModalSchema,
  overigeInkomstenBox1IsEnabled: Yup.boolean().default(false),
  overigeInkomstenBox1: overigeInkomstenModalSchema,
  inkomstenAanmerkelijkBelangIsEnabled: Yup.boolean().default(false),
  inkomstenAanmerkelijkBelang: inkomstenAanmerkelijkBelangModalSchema,
  inkomstenVerhuurOnroerendGoed: inkomstenVerhuurOnroerendGoedModalSchema,
  inkomstenUitBeleggingen: inkomstenUitBeleggingenModalSchema,
  arbeidsscanVerdiencapaciteit: Yup.number()
    .nullable()
    .default(null),
  arbeidsscanScore: Yup.number()
    .nullable()
    .default(null),
  arbeidsscanRapportnummer: Yup.string().nullable()
});
export type InkomenType = InferType<typeof inkomenSchema>;

export const fiscaliteitSchema = Yup.object({
  toggleFiscaleAftrekposten: Yup.boolean().default(false),
  toggleFiscaleBijtelposten: Yup.boolean().default(false),
  fiscaleAftrekposten: fiscaleAftrekpostenModalSchema,
  fiscaleBijtelposten: fiscaleBijtelpostenModalSchema,
  beleggingskorting: Yup.number().default(0),
  zelfstandigenaftrek: Yup.boolean().default(false),
  meewerkaftrek: Yup.boolean().default(false),
  dotatieFiscaleOudedagsReserve: Yup.boolean().default(false),
  mkbWinstvrijstelling: Yup.boolean().default(false)
});
export type FiscaliteitType = InferType<typeof fiscaliteitSchema>;

export const inkomenEnFiscusSchema = Yup.object({
  aanvrager1KlantId: Yup.string().default(""),
  aanvrager2KlantId: Yup.string().default(""),
  hasAanvrager2: Yup.boolean().default(false),
  aanvrager1Inkomen: inkomenSchema,
  aanvrager2Inkomen: inkomenSchema,
  aanvrager1Aow: aowSchema,
  aanvrager2Aow: aowSchema,
  aanvrager1Fiscaliteit: fiscaliteitSchema,
  aanvrager2Fiscaliteit: fiscaliteitSchema,
  aanvrager1Sociaal: sociaalSchema,
  aanvrager2Sociaal: sociaalSchema,
  fiscaleVerdeling: fiscaleVerdelingSchema
});

export type InkomenEnFiscusState = InferType<typeof inkomenEnFiscusSchema>;
export type InkomenEnFiscusProps = InkomenEnFiscusState & WithSaveData<InkomenEnFiscusState>;

export type InputValuesInkomenEnFiscusBerekening = {
  totaalBruto: number | null;
};

export type InputValuesInkomenEnFiscusModalBerekening = {
  loondienstInput: InkomenLoondienstInput | null;
  periodeinkomenBedrag: number | null;
  inkomenPeriodeCode: BrutoSalarisPeriode;
  jaarinkomenBedrag: number | null;
  totaalBruto: number | null;
};
