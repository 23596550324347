import React, { ReactElement } from "react";
import { FormikContextType, connect } from "formik";
import { LabeledCurrencyInput, BerekenCurrencyButton, TooltipWrap } from "adviesbox-shared";
import { FinancieringsbehoefteState } from "../infra/financieringsbehoefte-types";
import { GebruikPandSoort } from "../../.generated/forms/formstypes";

type NationaleHypotheekGarantieProps = {
  setShowModal: React.Dispatch<React.SetStateAction<boolean | null>>;
  showModal: boolean | null;
};

const NationaleHypotheekGarantie = ({
  formik,
  formik: { values },
  setShowModal,
  showModal
}: NationaleHypotheekGarantieProps & {
  formik: FormikContextType<FinancieringsbehoefteState>;
}): ReactElement => {
  return (
    <LabeledCurrencyInput
      caption="Nationale Hypotheek Garantie"
      name="financieringsopzet.nationaleHypotheekGarantie"
      onBlur={(): void => {
        if (formik.initialValues.financieringsopzet.nationaleHypotheekGarantieBerekenen &&
          !values.financieringsopzet.nationaleHypotheekGarantie &&
          showModal === null) {
            setShowModal(true);
        }
      }}
      disabled={
        values.onderpand.gebruik !== GebruikPandSoort.PrimaireWoning ||
        values.financieringsopzet.nationaleHypotheekGarantieBerekenen
      }
      appendChildren={
        <>
          <BerekenCurrencyButton
            name="financieringsopzet.nationaleHypotheekGarantieBerekenen"
            berekenen={values.financieringsopzet.nationaleHypotheekGarantieBerekenen}
          />
          {!values.financieringsopzet.nhgMogelijk && (
             <TooltipWrap
             name="nhg"
             warningText={values.financieringsopzet.nationaleHypotheekGarantieWarning}
             placement="bottom"
             iconType="waarschuwing"
             tooltipClasses="mt-1"
           />
          )}
        </>
      }
    />
  );
};

export default connect<NationaleHypotheekGarantieProps, FinancieringsbehoefteState>(NationaleHypotheekGarantie);
