/* istanbul ignore file */ //TODO: temporarily ignore because screen has no save so round tripping is not posible
import {
  Acceptatie,
  Acceptatie as AcceptatieDlEntry,
  AcceptatieExtraAflossingPeriode,
  AcceptatieHypotheek,
  AcceptatieMeerLenenPeriode,
  AcceptatieOutput as AcceptatieDlOutput,
  AcceptatieToelichtingRegel
} from "../../../../../.generated/forms/formstypes";
import { createMapToUi } from "../../../../../shared/utils/create-map-to-ui";
import { getNaam } from "../../../../../shared/utils/helpers";
import {
  acceptatieCardSchema,
  acceptatieSchema,
  AcceptatieState,
  AcceptatieVoetnootType,
  extraAflossingSchema,
  ExtraAflossingType,
  hypotheekBijCardSchema,
  periodeSchema,
  specificatieExtraAflossingschema,
  specificatieGevraagdeHypotheekSchema,
  specificatieIndicatieMeerMinderchema,
  toelichtingRegelSchema
} from "./acceptatie-schema";
import { LocalDate } from "@js-joda/core";
import { mapStringToLocalDate } from "adviesbox-shared";

const mapToelichtingRegels = createMapToUi(toelichtingRegelSchema).from<AcceptatieToelichtingRegel>({
  regel: v => v.toelichting
});

const mapHypotheekBijCard = createMapToUi(hypotheekBijCardSchema).from<AcceptatieDlEntry>({
  aanvrager1Naam: v => (v.aanvrager1 ? getNaam(v.aanvrager1, "naam-aanvrager-1") : null),
  aanvrager2Naam: v => (v.aanvrager2 ? getNaam(v.aanvrager2, "naam-aanvrager-2") : null),
  brutoInkomenAanvrager1: v => v.aanvrager1?.brutoInkomenBedrag || 0,
  brutoInkomenAanvrager2: v => v.aanvrager2?.brutoInkomenBedrag || 0,
  kredietToetslast: v => v.hypotheek?.kredietToetslastBedrag,
  marktwaarde: v => v.hypotheek?.marktwaardeBedrag,
  erfpachtCanon: v => v.hypotheek?.erfpachtBedrag,
  totaalGevraagdeHypotheek: v =>
    v.zuiverNhg ? v.zuiverNhg?.totaalGevraagdeHypotheekBedrag : v.hypotheek?.totaalGevraagdeHypotheekBedrag,
  indicatieMeerMinderLenen: v => v.hypotheek?.indicatieMeerLenenBedrag,
  verplichteExtraAflossing: v =>
    v.hypotheek?.extraAflossingPeriodes?.length
      ? v.hypotheek?.extraAflossingPeriodes?.map(c => c.verplichteAflossingBedrag).reduce((a, b) => a + b)
      : null,
  maximaalObvInkomenLasten: v => v.hypotheek?.maximaleHypotheekInkomenBedrag,
  maximaalObvOnderpand: v => v.hypotheek?.maximaleHypotheekOnderpandBedrag,
  maximaalObvNHGVoorwaarden: v =>
    v.zuiverNhg
      ? Math.min(v.zuiverNhg.maximaleHypotheekNhgBedrag || 0, v.zuiverNhg.maximaleHypotheekOnderpandBedrag || 0)
      : Math.min(v.hypotheek?.maximaleHypotheekNhgBedrag || 0, v.hypotheek?.maximaleHypotheekOnderpandBedrag || 0),
  maximaalObvInkomenLastenIndicatie: v => v.hypotheek?.maximaleHypotheekInkomenIndicatie,
  maximaalObvOnderpandIndicatie: v => v.hypotheek?.maximaleHypotheekOnderpandIndicatie,
  maximaalObvNHGVoorwaardenIndicatie: v => v.hypotheek?.maximaleHypotheekNhgIndicatie,
  opNHGBasis: v => !!v.zuiverNhg,
  hintTextAanvrager1: v => v.aanvrager1?.hintTekst,
  hintTextAanvrager2: v => v.aanvrager2?.hintTekst,
  geldverstrekkerNaam: v => v.geldverstrekkerNaam,
  toonWaarschuwingMaxHypotheekIsMaxHypotheekBox3: v => v.indicatie?.toonWaarschuwingMaxHypotheekIsMaxHypotheekBox3
});

const mapAcceptatieCard = createMapToUi(acceptatieCardSchema).from<AcceptatieDlEntry>({
  acceptatieIndicatie: v => v.indicatie?.indicatie,
  toelichtingRegels: v => v.indicatie?.toelichtingRegels?.map(c => mapToelichtingRegels(c)),
  voetnoten: v => v.indicatie?.voetnoten as AcceptatieVoetnootType[]
});

const mapExtraAflossing = createMapToUi(extraAflossingSchema).from<AcceptatieExtraAflossingPeriode>({
  looptijd: v => v.looptijdInMaanden,
  mogelijkeExtraAflossing: v => v.mogelijkeAflossingBedrag,
  periode: v => v.periode,
  rente: v => v.rentePercentage,
  verplichteExtraAflossing: v => v.verplichteAflossingBedrag
});

const mapSpecificatieExtraAflossing = createMapToUi(specificatieExtraAflossingschema).from<
  AcceptatieExtraAflossingPeriode[]
>({
  specificaties: v => v.map<ExtraAflossingType>(c => mapExtraAflossing(c))
});

const mapUitwerking = createMapToUi(periodeSchema).from<AcceptatieMeerLenenPeriode>({
  beschikbareRuimte: v => v.ruimteBedrag,
  inkomenAanvrager1: v => v.inkomenAanvrager1Bedrag,
  inkomenAanvrager2: v => v.inkomenAanvrager2Bedrag,
  maandlastVoorstelBox1: v => v.maandlastVoorstelBox1Bedrag,
  maandlastVoorstelBox3: v => v.maandlastVoorstelBox3Bedrag,
  onverpandVermogen: v => v.vrijVermogenBedrag,
  periode: v => v.periode,
  startDatum: v=> mapStringToLocalDate(v.startDatum) || LocalDate.of(2000,1,1), // todo: placeholder weghalen
  eindDatum: v=> mapStringToLocalDate(v.eindDatum) || LocalDate.of(2010,1,1), // todo: placeholder 2010 weghalen
  toegestaneMaandlast: v => v.toegestaneMaandlastBedrag,
  toetsrente: v => v.toetsrentePercentage,
  totaleMaandlastVoorstel: v => v.maandlastVoorstelBox1Bedrag + v.maandlastVoorstelBox3Bedrag,
  woonquoteBox1: v => v.woonquoteBox1Percentage,
  woonquoteBox3: v => v.woonquoteBox3Percentage,
  werkelijkeMaandlast: v => v.werkelijkeMaandlast
});

const mapSpecificatieIndicatie = createMapToUi(specificatieIndicatieMeerMinderchema).from<Acceptatie>({
  constanteWaardeMeerMinderLenen: v => v.hypotheek?.indicatieMeerLenenBedrag,
  fictieveJaarlast: v => v.hypotheek?.fictieveJaarlastBedrag,
  toegestaneJaarlast: v => v.hypotheek?.toegestaneJaarlastBedrag,
  toetsrente: v => v.hypotheek?.toetsrentePercentage,
  uitwerkingen: v => v.hypotheek?.meerLenenPeriodes?.map(c => mapUitwerking(c)),
  woonquote: v => v.hypotheek?.woonquotePercentage,
  naamAanvrager: v => (v.aanvrager1 ? getNaam(v.aanvrager1, "naam-aanvrager-1") : null),
  naamPartner: v => (v.aanvrager2 ? getNaam(v.aanvrager2, "naam-aanvrager-2") : null)
});

const mapSpecificatieGevraagdeHypotheek = createMapToUi(specificatieGevraagdeHypotheekSchema).from<AcceptatieHypotheek>(
  {
    doorlopendeHypotheken: v => v.doorlopendeHypothekenBedrag,
    gewensteHypotheek: v => v.gewensteHypotheekBedrag,
    totaalGevraagd: v => v.doorlopendeHypothekenBedrag + (v.gewensteHypotheekBedrag || 0)
  }
);

const mapAcceptatie = createMapToUi(acceptatieSchema).from<AcceptatieDlEntry>({
  showPlaceholderCard: v => null,
  placeholderTitle: v => null,
  placeholderMessage: v => null,
  hypotheekBijCard: v => mapHypotheekBijCard(v),
  acceptatieCard: v => mapAcceptatieCard(v),
  specificatieExtraAflossing: v => mapSpecificatieExtraAflossing(v.hypotheek?.extraAflossingPeriodes),
  specificatieIndicatieMeerMinder: v => mapSpecificatieIndicatie(v),
  specificatieTotaalGevraagdeHypotheek: v => mapSpecificatieGevraagdeHypotheek(v.hypotheek)
});

const mortgageUnavailable = (data: AcceptatieDlOutput): boolean => {
  const hasMortgageError = data.validationResults?.find(c => c.code === "EF302");
  const hasMaatschappijError = data.validationResults?.find(c => c.code === "EF303");
  const hasArrangementError = data.validationResults?.find(c => c.code === "EF305");

  return !!hasMaatschappijError || !!hasArrangementError || !!hasMortgageError;
};

const acceptanceUnavailable = (data: AcceptatieDlOutput): boolean => {
  return !!data.validationResults?.find(c => c.code === "AC001");
};

export function mapAcceptatieDlToUi(acceptatieId: string, data: AcceptatieDlOutput): AcceptatieState | null {
  const acceptatie = data && data.isValid && data.acceptaties ? data.acceptaties[acceptatieId] : null;

  if (mortgageUnavailable(data)) {
    return {
      ...acceptatieSchema.default(),
      showPlaceholderCard: true,
      placeholderTitle: "Er is nog geen hypotheek aanwezig in dit dossier",
      placeholderMessage: "Ga naar het scherm Producten - Hypotheek, om een hypotheek toe te voegen."
    };
  }

  if (acceptanceUnavailable(data)) {
    return {
      ...acceptatieSchema.default(),
      showPlaceholderCard: true,
      placeholderTitle: "Er zijn geen acceptatienormen beschikbaar",
      placeholderMessage: "Voor de gekozen geldverstrekker zijn geen acceptatienormen beschikbaar."
    };
  }

  if (acceptatie) {
    return mapAcceptatie(acceptatie);
  }

  return null;
}
