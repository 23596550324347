import { LocalDate } from "@js-joda/core";
import {
  Card,
  CardWrapper,
  createSpanWithId,
  CurrencyInput,
  DataGrid,
  Icon,
  LabeledBerekenDate,
  LabeledBevestigingInput,
  LabeledCurrencyInput,
  LabeledDateInput,
  LabeledNumberInput,
  LabeledPercentageInput,
  LabeledSelectInput,
  LabeledTextInput,
  getFormattedDate,
  TooltipWrap
} from "adviesbox-shared";
import { FormikProps } from "formik";
import React, { ReactElement, useContext, useState } from "react";
import { Column } from "react-table-6";
import * as Yup from "yup";
import { AppDataContext } from "../../navigation/appdata-context";
import { AdresInput } from "../../shared/components/adres/adres-input";
import LabeledHdnKeuzelijst from "../../shared/components/hdn-keuzelijst/LabeledHdnKeuzelijst";
import { ISWSideEffects } from "../../shared/components/isw-side-effects/isw-side-effects";
import Modal from "../../shared/components/modal/Modal";
import AdviesBoxFormik from "../../shared/utils/adviesbox-formik";
import { bedragFormat } from "../../shared/utils/currency";
import { enum2options } from "../../shared/utils/enum-to-options";
import { getInkomenEnFiscusTextResources } from "../infra/inkomen-en-fiscus-resources";
import {
  BrutoSalarisPeriode,
  inkomenUitArbeidModalSchema,
  InkomenUitArbeidModalType,
  inkomenUitArbeidModalValuesSchema,
  InkomenUitArbeidModalValuesType,
  SoortDienstverband
} from "../infra/inkomen-en-fiscus-schema";
import Inkomensverleden, { WinstSoort } from "../infra/shared-components/inkomensverleden";
import {
  inkomenUitArbeidModalAsyncSideEffects,
  inkomenUitArbeidModalSideEffects
} from "./determine-inkomen-uit-arbeid-modal-side-effects";
import { berekenDateSchema } from "../../shared/generic-parts/bereken-date/schema";

export type InkomenUitArbeidModalData = {
  geboorteDatum: LocalDate | null | undefined;
  data: InkomenUitArbeidModalType[];
};

type InkomenUitArbeidModalProps = {
  onSave?: (data: InkomenUitArbeidModalType[]) => void;
  closeModal?: () => void;
};

const InkomenUitArbeidModal = ({
  data,
  onSave,
  closeModal
}: InkomenUitArbeidModalData & InkomenUitArbeidModalProps): ReactElement => {
  const selectedState = useState(0);
  const [selected] = selectedState;
  const { setSErunning } = useContext(AppDataContext);
  return (
    <AdviesBoxFormik<InkomenUitArbeidModalValuesType>
      initialValues={{ modal: data }}
      validationSchema={inkomenUitArbeidModalValuesSchema}
      onSave={(values): void => onSave && onSave(values.modal)}
      closeModal={closeModal}
      render={(formik: FormikProps<InkomenUitArbeidModalValuesType>): ReactElement => {
        const {
          values: { modal }
        } = formik;
        const { setFieldValue } = formik;
        const currentSelected = modal[selected] || {};
        const prefix = `modal[${selected}]`;
        const overFourtyHoursPerWeek = currentSelected.aantalUrenPerWeek && currentSelected.aantalUrenPerWeek > 40;

        const showInkomensverleden =
          currentSelected.soortDienstverband === SoortDienstverband.FlexibeleArbeidsrelatieMetPerspectiefverklaring ||
          currentSelected.soortDienstverband ===
            SoortDienstverband.FlexibeleArbeidsrelatieZonderPerspectiefverklaring ||
          currentSelected.soortDienstverband === SoortDienstverband.LoondienstTijdelijkZonderIntentie;
        const showPerspectiefverklaring =
          currentSelected.soortDienstverband === SoortDienstverband.FlexibeleArbeidsrelatieMetPerspectiefverklaring;
        const buitenlandsInkomen = currentSelected.buitenlandsInkomen;
        const uitzendkrachtFaseTonen =
          currentSelected.soortDienstverband === SoortDienstverband.FlexibeleArbeidsrelatieZonderPerspectiefverklaring;

        const columns: Column[] = [
          {
            Header: "Werkgever",
            id: "Werkgever",
            Cell: (c): ReactElement => createSpanWithId(c.index, 0, c.original.naam, c.original.naam)
          },
          {
            Header: "Inkomen per jaar",
            id: "inkomenPerJaar",
            Cell: (c): ReactElement => createSpanWithId(c.index, 1, bedragFormat(c.original.totaalBruto))
          },
          {
            Header: "Soort dienstverband",
            id: "soortDienstverband",
            Cell: (c): ReactElement => createSpanWithId(c.index, 2, c.original.soortDienstverband)
          },
          {
            Header: "Aantal uur",
            id: "aantalUrenPerWeek",
            Cell: (c): ReactElement =>
              createSpanWithId(
                c.index,
                3,
                c.original.aantalUrenPerWeek || (0).toLocaleString("nl-NL", { minimumFractionDigits: 2 })
              )
          },
          {
            Header: "Datum in dienst",
            id: "datumInDienst",
            Cell: (c): ReactElement =>
              createSpanWithId(c.index, 4, c.original.datumInDienst ? getFormattedDate(c.original.datumInDienst) : null)
          },
          {
            Header: "Einddatum contract",
            id: "eindDatumContract",
            Cell: (c): ReactElement =>
              createSpanWithId(
                c.index,
                5,
                c.original.einddatumContract ? getFormattedDate(c.original.einddatumContract) : null
              )
          }
        ];

        if (modal.length > 1) {
          columns.push({
            Cell: "DeleteButton"
          });
        }

        const list = (
          <DataGrid
            masterDetail
            columns={columns}
            name="modal"
            rowSelected={selectedState}
            validationSchema={Yup.object({
              modal: Yup.array(inkomenUitArbeidModalSchema)
            })}
            rowCaption="inkomen"
            getNewRowValues={(): InkomenUitArbeidModalType => ({
              ...inkomenUitArbeidModalSchema.default(),
              inkomenMeetellenTot: {
                ...berekenDateSchema.default(),
                berekendeDatum: modal[0].inkomenMeetellenTot.berekendeDatum
              }
            })}
          />
        );

        const entry = (
          <div className="d-flex flex-wrap flex-row">
            <CardWrapper flexType="flex-column" maxRowCount={0}>
              <Card title="Dienstverband / beroep">
                <LabeledSelectInput
                  fieldSize="l"
                  caption="Soort dienstverband"
                  name={`${prefix}.soortDienstverband`}
                  options={enum2options(SoortDienstverband)}
                />
                {uitzendkrachtFaseTonen && (
                  <LabeledHdnKeuzelijst
                    berichtSoortType="AX"
                    keuzelijst="SoortUitzendcontractType"
                    caption="Fase uitzendcontract"
                    name={`${prefix}.faseUitzendcontract`}
                  />
                )}

                <LabeledNumberInput
                  caption="Aantal uren per week"
                  name={`${prefix}.aantalUrenPerWeek`}
                  decimalen={2}
                  displayDecimals={true}
                  appendChildren={
                    overFourtyHoursPerWeek ? (
                      <TooltipWrap
                        name={`${prefix}.aantalUrenPerWeek`}
                        warningText={getInkomenEnFiscusTextResources("TooltipInkomenUitArbeidAantalUrenPerWeek")}
                        placement="bottom"
                        iconType="waarschuwing"
                        tooltipClasses="mx-1"
                      />
                    ) : null
                  }
                  fieldSize="m"
                />
                <LabeledDateInput caption="Datum in dienst" name={`${prefix}.datumInDienst`} />
                <LabeledDateInput
                  caption="Einddatum contract"
                  name={`${prefix}.einddatumContract`}
                  appendChildren={
                    modal[selected] && modal[selected].einddatumContract ? (
                      <TooltipWrap
                        name={`${prefix}.einddatumContract`}
                        warningText={getInkomenEnFiscusTextResources("TooltipInkomenUitArbeidEinddatumContract")}
                        placement="bottom"
                        iconType="waarschuwing"
                        tooltipClasses="mx-1"
                      >
                        <div id={`${prefix}.einddatumContract-tooltip-button-wrapper`} className="mx-1">
                          <Icon name="waarschuwing" alt="waarschuwing" multiColor={true} />
                        </div>
                      </TooltipWrap>
                    ) : null
                  }
                />
                <LabeledBerekenDate caption="Inkomen meetellen tot" name={`${prefix}.inkomenMeetellenTot`} />
                <LabeledHdnKeuzelijst
                  caption="Beroep"
                  name={`${prefix}.beroep`}
                  berichtSoortType="AX"
                  keuzelijst="BeroepType"
                  emptyValue="Selecteer een optie"
                  fieldSize="l"
                />
                <LabeledTextInput caption="Beroepsfunctie" name={`${prefix}.beroepsfunctie`} />
              </Card>

              <Card title="Werkgever">
                <LabeledTextInput caption="Naam" name={`${prefix}.naam`} autoComplete="new-password" />
                <AdresInput name={`${prefix}.adres`} landCaption={"Vestigingsland"} />
                <LabeledBevestigingInput caption="Werkgever is familie" name={`${prefix}.werkgeverIsFamilie`} />
              </Card>
            </CardWrapper>
            <CardWrapper flexType="flex-column" maxRowCount={0}>
              <Card title="Inkomen uit arbeid">
                <LabeledBevestigingInput
                  caption="Buitenlands inkomen"
                  name={`${prefix}.buitenlandsInkomen`}
                  appendChildren={
                    buitenlandsInkomen && (
                      <TooltipWrap
                        name={`${prefix}.buitenlandsInkomen`}
                        warningText={getInkomenEnFiscusTextResources("WarningInkomenUitArbeidBuitenlandsInkomen")}
                        placement="bottom"
                        iconType="waarschuwing"
                        tooltipClasses="d-inline-block"
                      />
                    )
                  }
                />
                <LabeledCurrencyInput caption="Bruto salaris" name={`${prefix}.brutoSalaris`} decimalen={2} />
                <LabeledSelectInput
                  caption="Bruto salaris betaald per"
                  name={`${prefix}.brutoSalarisPer`}
                  options={enum2options(BrutoSalarisPeriode)}
                  fieldSize="m"
                />
                <div className="input-field-right">
                  <LabeledCurrencyInput
                    caption="Bruto salaris per jaar"
                    name={`${prefix}.brutoSalarisPerJaar`}
                    decimalen={0}
                    disabled={false}
                  />
                </div>
                <LabeledPercentageInput
                  caption="Vakantietoeslag"
                  fieldSize="s"
                  name={`${prefix}.vakantieToeslagPercentage`}
                  decimalen={2}
                  appendChildren={
                    <div className="d-flex">
                      <div className="px-1 mt-1">=</div>
                      <div className="px-1 d-flex justify-content-end flex-wrap">
                        <CurrencyInput name={`${prefix}.vakantieToeslagBedrag`} />
                      </div>
                    </div>
                  }
                />
                <LabeledBevestigingInput caption="13e maand" name={`${prefix}.dertiendeMaandToggle`} />
                <div className="input-field-right">
                  <LabeledCurrencyInput readonly caption="" name={`${prefix}.dertiendeMaand`} />
                </div>
                <div className="input-field-right">
                  <LabeledCurrencyInput
                    caption="Vaste eindejaarsuitkering"
                    name={`${prefix}.vasteEindejaarsuitkering`}
                    decimalen={0}
                  />
                </div>
                <div className="input-field-right">
                  <LabeledCurrencyInput
                    caption="Onregelmatigheidstoeslag"
                    name={`${prefix}.onregelmatigheidstoeslag`}
                    decimalen={0}
                  />
                </div>
                <div className="input-field-right">
                  <LabeledCurrencyInput
                    caption="Inkomstentoeslag"
                    name={`${prefix}.inkomstentoeslag`}
                    decimalen={0}
                    tooltip={getInkomenEnFiscusTextResources("TooltipInkomenUitArbeidInkomstentoeslag")}
                  />
                </div>
                <div className="input-field-right">
                  <LabeledCurrencyInput
                    caption="Vergoeding extra beslaglegging"
                    name={`${prefix}.vergoedingBeslaglegging`}
                    decimalen={0}
                  />
                </div>
                <div className="input-field-right">
                  <LabeledCurrencyInput
                    caption="Structureel overwerk"
                    name={`${prefix}.structureelOverwerk`}
                    decimalen={0}
                  />
                </div>
                {(currentSelected.structureelOverwerk === 0 || currentSelected.structureelOverwerk) && (
                  <>
                    <LabeledDateInput
                      caption="Ingangsdatum structureel overwerk"
                      name={`${prefix}.ingangsdatumStructureelOverwerk`}
                    />
                    <LabeledDateInput
                      caption="Einddatum structureel overwerk"
                      name={`${prefix}.einddatumStructureelOverwerk`}
                    />
                  </>
                )}
                <div className="input-field-right">
                  <LabeledCurrencyInput caption="Provisie" name={`${prefix}.provisie`} decimalen={0} />
                </div>
                {(currentSelected.provisie === 0 || currentSelected.provisie) && (
                  <>
                    <LabeledDateInput caption="Ingangsdatum provisie" name={`${prefix}.ingangsdatumProvisie`} />
                    <LabeledDateInput caption="Einddatum provisie" name={`${prefix}.einddatumProvisie`} />
                  </>
                )}
                <div className="input-field-right">
                  <LabeledCurrencyInput
                    caption="Vast flexibel budget"
                    name={`${prefix}.vastFlexibelBudget`}
                    decimalen={0}
                  />
                </div>
                <div className="input-field-right">
                  <LabeledCurrencyInput
                    caption="Totaal bruto jaarsalaris"
                    name={`${prefix}.totaalBruto`}
                    decimalen={0}
                  />
                </div>
              </Card>
              {showInkomensverleden && (
                <Card
                  title="Inkomensverleden"
                  className=""
                  tooltip={getInkomenEnFiscusTextResources("TooltipInkomenUitArbeidInkomensverleden")}
                >
                  <Inkomensverleden soort={WinstSoort.BrutoJaarinkomen} name={`${prefix}.inkomensverleden`} />
                </Card>
              )}

              {showPerspectiefverklaring ? (
                <Card title={"Verklaring inkomen"}>
                  <LabeledCurrencyInput
                    caption={"Toetsinkomen bij perspectiefverklaring"}
                    name={`${prefix}.toetsinkomenPerspectiefVerklaring`}
                  />
                  <h3>Loondoorbetaling bij AO</h3>
                  <LabeledPercentageInput caption="Jaar 1" name={`${prefix}.jaar1`} decimalen={2} />
                  <LabeledPercentageInput caption="Jaar 2" name={`${prefix}.jaar2`} decimalen={2} />
                </Card>
              ) : (
                <Card title={"Loondoorbetaling bij AO"}>
                  <LabeledPercentageInput caption="Jaar 1" name={`${prefix}.jaar1`} decimalen={2} />
                  <LabeledPercentageInput caption="Jaar 2" name={`${prefix}.jaar2`} decimalen={2} />
                </Card>
              )}
            </CardWrapper>
          </div>
        );

        const body = (
          <>
            {list}
            {modal.length > 0 ? entry : null}
          </>
        );

        return (
          <>
            {modal.length > 0 ? (
              <ISWSideEffects<InkomenUitArbeidModalValuesType>
                sync={inkomenUitArbeidModalSideEffects({ selected, setFieldValue })}
                async={inkomenUitArbeidModalAsyncSideEffects({ selected, setFieldValue })}
                asyncStartStopCallback={setSErunning}
              />
            ) : null}
            <div className="modal-with-cards">
              <Modal
                title="Inkomen uit arbeid"
                body={body}
                onSubmitClick={formik.submitForm}
                onCancelClick={closeModal}
              />
            </div>
          </>
        );
      }}
    />
  );
};

export default InkomenUitArbeidModal;
