/* eslint react/prop-types: 0 */

import { AuthContext, createSpanWithId, DataGrid, Icon, ModalButton, SettingsContext } from "adviesbox-shared";
import classnames from "classnames";
import { useFormikContext } from "formik";
import moment from "moment";
import React, { ReactElement, useContext, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import isEqual from "react-fast-compare";
import { RouteComponentProps, withRouter } from "react-router";
import { CellInfo, Column } from "react-table-6";
import { ZoekenAdviesdossier } from "../../.generated/forms/formstypes";
import ParamRouteContext from "../../shared/paramrouting/paramrouting-context";
import { createNewClientDossier } from "../infra/zoekscherm-api";
import { TableColumnId, ZoekschermState } from "../infra/zoekscherm-schema";
import classes from "./selected-client.module.scss";
import { ConfirmDeleteDossierModal } from "./confirm-delete-dossier-modal/confirm-delete-dossier-modal";
type SelectedClientModalProps = {
  setModalOpen: (val: boolean) => void;
  isModalOpen: boolean;
};

const SelectedClientModal = ({
  history,
  setModalOpen,
  isModalOpen
}: SelectedClientModalProps & RouteComponentProps): ReactElement => {
  const { values, setFieldValue } = useFormikContext<ZoekschermState>();
  const selectedClient = values.zoeken.selectedClient;
  const zoekresultaten = values.zoeken.zoekresultaten;
  const params = useContext(ParamRouteContext);
  const { user } = useContext(AuthContext);
  const settings = useContext(SettingsContext);
  const [loading, setLoading] = useState(false);
  const [errorDifferentVestiging, setErrorDifferentVestiging] = useState(false);
  const prevSelectedAdviesDossier = useRef(selectedClient?.adviesDossiers);

  useEffect(() => {
    if (
      selectedClient?.vestigingId &&
      selectedClient?.adviesDossiers &&
      !isEqual(prevSelectedAdviesDossier.current, selectedClient?.adviesDossiers)
    ) {
      prevSelectedAdviesDossier.current = selectedClient?.adviesDossiers;
      if (errorDifferentVestiging) setErrorDifferentVestiging(false);
    }
  }, [prevSelectedAdviesDossier, selectedClient, errorDifferentVestiging]);

  const closeSelectedClientModal = (): void => {
    if (!selectedClient) return;
    const relevantZoekresultaat = zoekresultaten.find(kd => kd.klantdossierId === selectedClient.klantdossierId);
    if (!relevantZoekresultaat) return;
    const index = zoekresultaten.indexOf(relevantZoekresultaat);
    zoekresultaten.splice(index, 1, { ...selectedClient });
    setFieldValue("zoeken.selectedClient", null);
    setFieldValue("zoeken.zoekresultaten", [...zoekresultaten]);
    setModalOpen(false);
  };

  const addDossier = async (): Promise<void> => {
    if (!selectedClient || !user || loading) return;

    if (selectedClient?.vestigingId !== params?.vestiging) {
      return setErrorDifferentVestiging(true);
    }
    setLoading(true);
    try {
      const res = await createNewClientDossier(selectedClient.klantdossierId, params.vestiging, settings, user);
      history.push(`/vestiging/${params.vestiging}/adviesdossier/${res.adviesdossierId}/personalia`);
    } catch {
      setLoading(false);
    }
  };

  const DeleteDossierButton = (cellInfo: CellInfo): ReactElement => (
    <div className={classes.delete}>
      <ModalButton
        parent=""
        validate={false}
        modalIndex={1}
        content={
          <div
            id={`dossier-open-delete-modal-icon-${cellInfo.index}`}
            data-testid={`dossier-open-delete-modal-icon-${cellInfo.index}`}
          >
            <Icon name="prullenbak" alt="Verwijderen" multiColor={false} />
          </div>
        }
      >
        <ConfirmDeleteDossierModal cellInfo={cellInfo} setErrorDifferentVestiging={setErrorDifferentVestiging} />
      </ModalButton>
    </div>
  );

  const adviesDossiersRowProps = (
    adviesDossier: ZoekenAdviesdossier,
    col: Column
  ): {
    onClick: () => void;
  } => ({
    onClick: (): void => {
      if (col.id === TableColumnId.DeleteDossierButton || !adviesDossier.adviesdossierId) return;
      history.push(
        `/vestiging/${selectedClient?.vestigingId}/adviesdossier/${adviesDossier.adviesdossierId}/personalia`
      );
    }
  });

  const clientColumns: Column[] = [
    {
      Header: "Laatst geopend",
      id: "laatstBewerktDatum",
      Cell: (c): ReactElement => {
        const date = c.original.laatstBewerktDatum;
        const time = c.original.laatstBewerktTijd;
        const dateTime = date && time ? moment(date + time, "YYYY-MM-DDHH:mm").format("DD-MM-YYYY HH:mm") : "";
        return createSpanWithId(c.index, 0, dateTime, dateTime, "selected-client");
      }
    },
    {
      Header: "Aanleiding",
      Cell: (c): ReactElement =>
        createSpanWithId(c.index, 1, c.original.aanleiding, c.original.aanleiding, "selected-client")
    },
    {
      Header: "Korte omschrijving",
      id: "omschrijving",
      Cell: (c): ReactElement =>
        createSpanWithId(c.index, 2, c.original.omschrijving || "-", c.original.omschrijving || "-", "selected-client")
    },
    {
      Header: "Status",
      Cell: (c): ReactElement => createSpanWithId(c.index, 3, c.original.status, c.original.status, "selected-client")
    },
    {
      width: 30,
      Cell: DeleteDossierButton,
      id: TableColumnId.DeleteDossierButton
    }
  ];

  return (
    <Modal show={isModalOpen} backdrop={"static"} size={"lg"}>
      {selectedClient && selectedClient.naamAanvrager && (
        <div>
          <div className="px-4 pb-2 pt-4 d-flex">
            <h4>
              Dossiers van {selectedClient.naamAanvrager}
              {selectedClient.naamPartner ? "\xa0en\xa0" + selectedClient.naamPartner : ""}
            </h4>
            <div className={"ml-auto"}>
              <button
                type="button"
                className={"close"}
                onClick={closeSelectedClientModal}
                id={"close-selected-client-modal-icon"}
              >
                <span aria-hidden="true">×</span>
                <span className="sr-only">Close</span>
              </button>
            </div>
          </div>
          <div className="px-4">
            {selectedClient.adviesDossiers && selectedClient.adviesDossiers.length > 0 && (
              <DataGrid
                name="zoeken.selectedClient.adviesDossiers"
                className={`search-table`}
                sortable={false}
                showPagination={false}
                minRows={selectedClient.adviesDossiers.length < 10 ? selectedClient.adviesDossiers.length : 10}
                getTdProps={(_state: any, row: any, col: any): { onClick: () => void } => {
                  return adviesDossiersRowProps(row.original, col);
                }}
                columns={clientColumns}
              />
            )}
            {(!selectedClient.adviesDossiers || selectedClient.adviesDossiers.length < 1) && (
              <div className="mb-4 mt-3">
                <h4 className="text-center w-100">
                  Er zijn nog geen dossiers van {selectedClient.naamAanvrager}
                  {selectedClient.naamPartner ? "\xa0en\xa0" + selectedClient.naamPartner : ""}
                </h4>
                <span className="d-block text-center w-100">
                  Gebruik onderstaande knop om een nieuw dossier aan te maken.
                </span>
              </div>
            )}
          </div>
          <div className={"d-flex justify-content-center py-3 px-2"}>
            <button
              data-testid="add-dossier-selected-client-modal-button"
              id={`add-dossier-selected-client-modal-button`}
              className="btn btn-primary"
              onClick={addDossier}
            >
              + Nieuw dossier
            </button>
          </div>
          {errorDifferentVestiging && (
            <div className={"d-flex py-3"}>
              <div className="d-flex justify-content-center foutmelding col-12">
                Je kunt een dossier dat aan een andere vestiging toebehoort niet verwijderen of aanmaken.
              </div>
            </div>
          )}
          <div className={classnames("d-flex justify-content-end px-3", classes.modal_footer)}>
            <button
              id={"close-selected-client-modal-button"}
              data-testid="close-selected-client-modal-button"
              className="btn btn-link"
              onClick={closeSelectedClientModal}
            >
              Sluiten
            </button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default withRouter(SelectedClientModal);
