import {
  ErfpachtOpWoning,
  EwrOfEws,
  HuidigeWoonsituatie,
  BewonersOpties,
  Woonsituatiesoort,
  HuidigPand,
  Huurwoning,
  WoningInEigendom,
  GebruikPandSoort,
  Woonsituatie as WoonsituatieDlEntry,
  WoonsituatieOutput as WoonsituatieDlOutput,
  WoonsituatieRenteaftrek
} from "../../.generated/forms/formstypes";
import { Berekeningen } from "../../.generated/instellingen-forms/instellingen-formstypes";
import { mapAdresDlNaarUi } from "../../shared/generic-parts/adres/map-dl-2-ui";
import { mapKlantnaamDl2Ui } from "../../shared/generic-parts/klantnaam/map-dl-2-ui";
import { onderpandSchema } from "../../shared/generic-parts/onderpand/schema";
import { FieldMap, mapBerekenInput, UiName } from "../../shared/types";
import { createMapEnum } from "../../shared/utils/create-map-enum";
import { createMapToUi } from "../../shared/utils/create-map-to-ui";
import { hasValue, prefixWithZero } from "../../shared/utils/helpers";
import { target2field } from "../../shared/utils/target-to-field";
import {
  Bewoners,
  eigenwoningreserveSchema,
  eigenwoningschuldModalEntrySchema,
  eigenwoningschuldModalSchema,
  erfpachtSchema,
  GebruikPand,
  huidigeWoonsituatieSchema,
  huidigPandSchema,
  huurwoningSchema,
  hypotheekSchema,
  persoonsgegevensSchema,
  SoortWoonsituatie,
  woningInEigendomSchema,
  woonsituatieListSchema,
  WoonsituatieListState,
  woonsituatieSchema
} from "./woonsituatie-schema";
import { mapStringToLocalDate } from "adviesbox-shared";

const mapWoonsituatiesoort = createMapEnum(Woonsituatiesoort).to<SoortWoonsituatie>({
  Geen: SoortWoonsituatie.Inwonend,
  Eigendom: SoortWoonsituatie.Eigendom,
  Huur: SoortWoonsituatie.Huur,
  Inwonend: SoortWoonsituatie.Inwonend
});

const mapHuidigeWoonsituatieBewoners = createMapEnum(BewonersOpties).to<Bewoners>({
  Aanvrager1: Bewoners.Aanvrager1,
  Aanvrager2: Bewoners.Aanvrager2,
  Beiden: Bewoners.Beiden
});

const mapGebruikPandSoort = createMapEnum(GebruikPandSoort).to<GebruikPand>({
  Geen: GebruikPand.PrimaireWoning,
  DeelsVerhuurd: GebruikPand.PrimaireWoning,
  GeheelVerhuurd: GebruikPand.PrimaireWoning,
  PrimaireWoning: GebruikPand.PrimaireWoning,
  TweedeWoning: GebruikPand.TweedeWoning
});

const mapHuidigeWoonsituatie = createMapToUi(huidigeWoonsituatieSchema).from<HuidigeWoonsituatie>({
  bewoners: v => mapHuidigeWoonsituatieBewoners(v.bewoners),
  woonsituatie: v => mapWoonsituatiesoort(v.soortWoonsituatie)
});

const mapHuurwoning = createMapToUi(huurwoningSchema)
  .with<Berekeningen>()
  .from<Huurwoning>({
    huurPerMaand: v => v.huurPerMaandBedrag,
    stijging: v => v.stijgingPercentage,
    stijgingPercentageDefault: (v, c) => c.algemeen.huurstijgingPercentage
  });

const mapOnderpandModal = createMapToUi(onderpandSchema).from<HuidigPand>({
  appartementsRecht: v => v.appartement,
  bouwNummer: v => v.bouwnummer,
  bouwPlan: v => v.bouwplan,
  bouwaard: v => prefixWithZero(v.bouwaard),
  bouwjaar: v => v.bouwjaar,
  constructie: v => prefixWithZero(v.verkoopOnderVoorwaardenConstructie),
  constructieOmschrijving: v => v.verkoopOnderVoorwaardenConstructieOmschrijving,
  erfpachtConstructieAnders: v => prefixWithZero(v.soortConstructieAnders),
  gedeeltelijkePerceel: v => v.gedeeld,
  kadastraleGemeente: v => v.kadasterGemeente,
  kadastraleNummer: v => v.kadasterNummer,
  kadastraleSectie: v => v.kadasterSectie,
  koopAannemingsOvMetVormAfbouwGarantie: v => v.afbouwgarantie,
  ontbindendeVoorwaardenTot: v => mapStringToLocalDate(v.ontbindendeVoorwaardenDatum),
  opleverDatum: v => mapStringToLocalDate(v.opleverdatum),
  serviceKosten: v => v.servicekosten,
  staatVanOnderhoud: v => prefixWithZero(v.staatOnderhoud),
  taxatieAanwezig: v => v.taxatieAanwezig,
  taxatieDatum: v => mapStringToLocalDate(v.taxatiedatum),
  verkoopOnderVoorwaarden: v => v.verkoopOnderVoorwaarden,
  gebruikPand: v => null,
  eigendomsVerhoudingAanvrager: _ => null,
  eigendomsVerhoudingPartner: _ => null,
  objectSoort: v => prefixWithZero(v.objectSoort),
  bijzonderObjectSoort: v => prefixWithZero(v.bijzonderObjectSoort),
  heeftGarage: v => v.heeftGarage,
  recreatieveBewoning: v => v.recreatieveBewoning
});

const mapHuidigPand = createMapToUi(huidigPandSchema).from<HuidigPand>({
  adresIsWoonadres: v => v.gelijkAanWoonadres,
  energielabel: v => prefixWithZero(v.energieklasse),
  energieklasseDatum: v => mapStringToLocalDate(v.energieklasseDatum),
  onderpand: v => mapOnderpandModal(v),
  adres: v => mapAdresDlNaarUi(v.adres)
});

const mapWoningInEigendom = createMapToUi(woningInEigendomSchema).from<WoningInEigendom>({
  eigenwoningforfait: v =>
    mapBerekenInput(v.eigenWoningForfaitBedrag, v.eigenWoningForfaitOvernemen, v.eigenWoningForfaitBedrag),
  gebruikWoning: v => mapGebruikPandSoort(v.gebruikPand),
  gebruikWoningVoorstel: v => null,
  marktwaarde: v => v.marktwaardeBedrag,
  percentageAanvrager: v => v.eigendom1Percentage,
  percentagePartner: v => v.eigendom2Percentage,
  wozWaarde: v => v.wozWaarde,
  wozWaardeloket: () => null
});

const mapEigenwoningschuldModalEntry = createMapToUi(eigenwoningschuldModalEntrySchema).from<WoonsituatieRenteaftrek>({
  bedrag: v => v.bedrag,
  begindatum: v => mapStringToLocalDate(v.aanvangsdatum),
  einddatum: v => mapStringToLocalDate(v.einddatum),
  renteaftrekId: v => v.renteaftrekId
});

const mapEigenwoningschuldModal = createMapToUi(eigenwoningschuldModalSchema).from<WoonsituatieRenteaftrek[]>({
  entries: v => v.map(e => mapEigenwoningschuldModalEntry(e))
});

const mapEigenwoningreserve = createMapToUi(eigenwoningreserveSchema).from<EwrOfEws>({
  ewrVerleden: v => v.bestaandeEwrBedrag,
  ewsOvergangsrecht: v => v.ewsOvergangsrechtBedrag,
  ewsOvergangsrechtModal: v => mapEigenwoningschuldModal(v.ewsOvergangsrechtRenteaftrekken),
  ewsAflossing: v => v.ewsVerplichteAflossingBedrag,
  ewsAflossingModal: v => mapEigenwoningschuldModal(v.ewsVerplichteAflossingRenteaftrekken),
  eigenwoningreserveToggle: v =>
    !!v.bestaandeEwrBedrag || !!v.ewsOvergangsrechtBedrag || !!v.ewsVerplichteAflossingBedrag
});

const mapHypotheek = createMapToUi(hypotheekSchema).from<WoningInEigendom>({
  hasHypotheek: v => v.heeftHypotheekOpWoning,
  oorspronkelijk: v => v.hypotheekOpWoning?.oorspronkelijkHypotheekbedrag,
  inschrijving: v =>
    mapBerekenInput(v.hypotheekOpWoning?.inschrijvingBedrag, v.hypotheekOpWoning?.inschrijvingOvernemen)
});

const mapErfpacht = createMapToUi(erfpachtSchema).from<ErfpachtOpWoning>({
  canon: v => v.erfpachtBedrag,
  eeuwigdurendErfpacht: v => v.erfpachtEeuwigdurend,
  einddatum: v => mapStringToLocalDate(v.erfpachtEinddatum),
  particulierErfpacht: v => v.erfpachtParticulier,
  hasErfpacht: v => hasValue(v.erfpachtBedrag) || hasValue(v.erfpachtEinddatum) || v.erfpachtParticulier
});

const mapWoonsituatieEntry = createMapToUi(woonsituatieSchema)
  .with<Berekeningen>()
  .from<HuidigeWoonsituatie>({
    eigenwoningreserve: v => mapEigenwoningreserve(v.ewrOfEws || ({} as EwrOfEws)),
    erfpacht: v => mapErfpacht(v.woningInEigendom?.erfpachtOpWoning || ({} as ErfpachtOpWoning)),
    huidigPand: v => mapHuidigPand(v.woningInEigendom?.huidigPand || ({} as HuidigPand)),
    huidigeWoonsituatie: v => mapHuidigeWoonsituatie(v),
    huurwoning: (v, c) => mapHuurwoning(c)(v.huurwoning || ({} as Huurwoning)),
    hypotheek: v => mapHypotheek(v.woningInEigendom || ({} as WoningInEigendom)),
    woningInEigendom: v => mapWoningInEigendom(v.woningInEigendom || ({} as WoningInEigendom)),
    woonsituatieId: v => v.woonsituatieId
  });

const mapPersoonsgegevens = createMapToUi(persoonsgegevensSchema).from<WoonsituatieDlEntry>({
  naamAanvrager: v => mapKlantnaamDl2Ui(v.aanvrager1),
  naamPartner: v => mapKlantnaamDl2Ui(v.aanvrager2),
  adresAanvrager: v => mapAdresDlNaarUi(v.adresVan1eKlant),
  adresPartner: v => mapAdresDlNaarUi(v.adresVan2eKlant)
});

const mapWoonsituatie = createMapToUi(woonsituatieListSchema)
  .with<Berekeningen>()
  .from<WoonsituatieDlEntry>({
    persoonsgegevens: v => mapPersoonsgegevens(v),
    woonsituaties: (v, c) =>
      v.huidigeWoonsituaties ? v.huidigeWoonsituaties.map(e => mapWoonsituatieEntry(c)(e)) : null
  });

export const mapWoonsituatieDlToUi = (berekeningenInstellingenData: Berekeningen | null) => (
  woonsituatieId: string,
  data: WoonsituatieDlOutput
): WoonsituatieListState | null => {
  const woonsituatie = data && data.woonsituaties ? data.woonsituaties[woonsituatieId] : null;

  if (!woonsituatie || !berekeningenInstellingenData) {
    return null;
  }
  return woonsituatieListSchema.cast(mapWoonsituatie(berekeningenInstellingenData)(woonsituatie));
};

export function mapDlTargetToWoonsituatieUiField(target: string): UiName | null {
  const map: FieldMap<WoonsituatieDlEntry> = {
    huidigeWoonsituaties: {
      ewrOfEws: {
        ewsOvergangsrechtRenteaftrekken: {
          einddatum: {
            label: "Eigenwoningschuld (EWS) overgangsrecht",
            field: "woonsituaties[i].eigenwoningreserve.ewsOvergangsrechtModal.entries[i].einddatum"
          }
        },
        ewsVerplichteAflossingRenteaftrekken: {
          einddatum: {
            label: "Eigenwoningschuld (EWS) verplichte aflossing",
            field: "woonsituaties[i].eigenwoningreserve.ewsAflossingModal.entries[i].einddatum"
          }
        }
      },
      woningInEigendom: {
        hypotheekOpWoning: {
          oorspronkelijkHypotheekbedrag: {
            label: "Oorspronkelijke hypotheek",
            field: "woonsituaties[i].hypotheek.oorspronkelijk"
          }
        }
      }
    }
  };

  return target2field(map, target);
}
