import { createISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";
import { HypotheekType } from "./hypotheek-types";
import { AanvragerKeuze } from "../../shared/types";

export const kapitaalopbouwSideEffects = createISWSideEffect<HypotheekType>((bag): void => {
  const { has, draft } = bag;

  if (!draft) return;

  const leningdeelBedrag = draft.leningdeelgegevens.leningdeelHoofdsom.bedrag || null;
  const rentePercentage = draft.leningdeelgegevens.rentePercentage;
  const verzekerde = draft.verzekerde.verzekerde;

  if (has.verzekerde.verzekerde.changed) {
    if (draft.kapitaalopbouw) {
      if (verzekerde === AanvragerKeuze.Aanvrager1) {
        draft.kapitaalopbouw.doelkapitaal1Bedrag = leningdeelBedrag;
        draft.kapitaalopbouw.doelkapitaal2Bedrag = null;
        draft.kapitaalopbouw.doelkapitaal1Percentage = rentePercentage?.bedrag ?? null;
        draft.kapitaalopbouw.doelkapitaal2Percentage = null;
      }
      if (verzekerde === AanvragerKeuze.Aanvrager2) {
        draft.kapitaalopbouw.doelkapitaal2Bedrag = leningdeelBedrag;
        draft.kapitaalopbouw.doelkapitaal1Bedrag = null;
        draft.kapitaalopbouw.doelkapitaal2Percentage = rentePercentage?.bedrag ?? null;
        draft.kapitaalopbouw.doelkapitaal1Percentage = null;
      }
      if (verzekerde === AanvragerKeuze.Beiden) {
        draft.kapitaalopbouw.doelkapitaal1Bedrag = draft.kapitaalopbouw.doelkapitaal2Bedrag = leningdeelBedrag;
        draft.kapitaalopbouw.doelkapitaal1Percentage = draft.kapitaalopbouw.doelkapitaal2Percentage =
          rentePercentage?.bedrag ?? null;
      }
    }
  } else {
    if (has.leningdeelgegevens.leningdeelHoofdsom.bedrag.changed && draft.kapitaalopbouw) {
      if (verzekerde === AanvragerKeuze.Aanvrager1) {
        draft.kapitaalopbouw.doelkapitaal1Bedrag = leningdeelBedrag;
      }
      if (verzekerde === AanvragerKeuze.Aanvrager2) {
        draft.kapitaalopbouw.doelkapitaal2Bedrag = leningdeelBedrag;
      }
      if (verzekerde === AanvragerKeuze.Beiden)
        draft.kapitaalopbouw.doelkapitaal1Bedrag = draft.kapitaalopbouw.doelkapitaal2Bedrag = leningdeelBedrag;
    }

    if (has.leningdeelgegevens.rentePercentage.changed && draft.kapitaalopbouw) {
      if (verzekerde === AanvragerKeuze.Aanvrager1) {
        draft.kapitaalopbouw.doelkapitaal1Percentage = rentePercentage?.bedrag ?? null;
      }
      if (verzekerde === AanvragerKeuze.Aanvrager2) {
        draft.kapitaalopbouw.doelkapitaal2Percentage = rentePercentage?.bedrag ?? null;
      }
      if (verzekerde === AanvragerKeuze.Beiden)
        draft.kapitaalopbouw.doelkapitaal1Percentage = draft.kapitaalopbouw.doelkapitaal2Percentage =
          rentePercentage?.bedrag ?? null;
    }
  }
});
