import { createGetTextResources } from "../../shared/utils/text-resources-algemeen";

export const getKredietenTextResources = createGetTextResources({
  TooltipHoofdsom:
    "De hoofdsom is het bedrag dat bij BKR geregistreerd staat. Dit is in principe 65% van de totale leasekosten. Oftewel 65% van de maandlast * de looptijd in maanden. De toetslast per maand is 2% van de hoofdsom.",
  TooltipSlottermijn:
    "Je kunt een deel van de financiering 'bevriezen'. Dat heet de slottermijn. Dit is een vooraf vastgesteld restbedrag dat aan het einde van het contract nog openstaat. De inruilwaarde van de auto van je klant staat ruim garant voor dit restbedrag. Zo profiteert je klant van aanzienlijk lagere maandlasten. De slottermijn wordt behoudend vastgesteld, zodat er na inlossing meestal geld overblijft. Dit bedrag kan dan als aanbetaling gebruikt worden voor een volgende auto.",
  TooltipGeenRestantHoofdsom: "Het restant van de hoofdsom is nog € 0.",
  ErrorGeenIngangsDatum: "Vul een geldige ingangsdatum in.",
  ErrorGeenOpgaveDatum: "Vul een geldige opgavedatum in.",
  ErrorStudieleningLooptijd: "Vul een looptijd van 15 of 35 jaar in.",
  ErrorEinddatumRenteaftrekRestschuldlening:
    "Vul een einddatum in dat maximaal 15 jaar na de ingangsdatum ligt.",
  ErrorEinddatumRenteaftrekOverige: "Vul een einddatum in dat maximaal 30 jaar na de ingangsdatum ligt."
});
